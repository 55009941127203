import { Card, Col, Descriptions, Input, Modal, Row, Timeline } from "antd";
import TableReportVirtual from "components/otros/TableReportVirtual";
import { useProductos } from "hooks/auditoriaProcesos/useProductos";

const Productos = () => {
  const {
    filterData,
    filteredDataHistory,
    handleSearch,
    titleModal,
    columnsTable,
    productos,
    openModal,
    closeModal,
    searchTerm,
  } = useProductos();

  return (
    <>
      <Card style={{ margin: "2px" }}>
        <Row gutter={[5, 5]}>
          <Col xs={24}>
            <TableReportVirtual
              title='Productos'
              headTable={columnsTable}
              columnTable={productos}
              rowSelection={() => null}
              exportToExcel={() => null}
              isSelect={false}
              muestra={false}
              y={`57vh`}
              isTotal={false}
            />
          </Col>
        </Row>
      </Card>
      <Modal
        title={titleModal}
        open={openModal}
        footer={null}
        width='90%'
        style={{ height: "75%" }}
        onOk={closeModal}
        onCancel={closeModal}
        destroyOnClose
      >
        <Input
          placeholder='Escriba la propiedad a filtrar'
          value={searchTerm}
          onChange={handleSearch}
          style={{ marginBottom: "1rem" }}
        />

        <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
          <Timeline mode='left'>
            {filteredDataHistory.map((el, index) => {
              const filteredDataJson = filterData(el.data_json);
              const filteredDataJsonNueva = el.cod_motivo === 2 ? filterData(el.data_json_nueva || {}) : {};
              return (
                <Timeline.Item
                  key={index}
                  color={el.cod_motivo === 1 ? "green" : el.cod_motivo === 2 ? "#0000ff" : "#0000ff"}
                >
                  {el.cod_motivo === 1 ? (
                    <Descriptions
                      title={
                        <span style={{ fontWeight: 400 }}>
                          <strong>Fecha Creación:</strong> {el.fecha} <strong>, Usuario: </strong>
                          {el.usuario}
                        </span>
                      }
                      column={1}
                    >
                      <Descriptions.Item>
                        <strong style={{ color: "green" }}>Información de creación</strong>
                      </Descriptions.Item>
                      {Object.keys(filteredDataJson).map((key) => (
                        <Descriptions.Item label={key} key={`${el.key}-${key}`} className='scroll-column-scroll-250'>
                          {filteredDataJson[key]}
                        </Descriptions.Item>
                      ))}
                    </Descriptions>
                  ) : (
                    <Row>
                      <Col span={12}>
                        <Descriptions
                          title={
                            <span style={{ fontWeight: 400 }}>
                              <strong>Fecha Modificación: </strong> {el.fecha} <strong>, Usuario: </strong>
                              {el.usuario}
                            </span>
                          }
                          column={1}
                          style={{ fontWeight: "300" }}
                        >
                          <Descriptions.Item>
                            <strong style={{ color: "red" }}>Información previa</strong>
                          </Descriptions.Item>
                          {Object.keys(filteredDataJson).map((key) => (
                            <Descriptions.Item label={key} key={`${el.key}-${key}`} className='scroll-column-scroll-250'>
                              {key === "Descuento" || key === "PVP" || key === "PVF"
                                ? (filteredDataJson[key] / 1).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : filteredDataJson[key]}
                            </Descriptions.Item>
                          ))}
                        </Descriptions>
                      </Col>
                      <Col span={12}>
                        <div style={{ minHeight: "44.1px" }}></div> {/* Espacio para alinear */}
                        <Descriptions column={1} style={{ fontWeight: "300" }}>
                          <Descriptions.Item>
                            <strong style={{ color: "green" }}>Información modificada</strong>
                          </Descriptions.Item>
                          {Object.keys(filteredDataJsonNueva).map((key) => (
                            <Descriptions.Item
                              label={key}
                              key={`${el.key}-${key}-nueva`}
                              className='scroll-column-scroll-250'
                            >
                              {key === "Descuento" || key === "PVP" || key === "PVF"
                                ? (filteredDataJsonNueva[key] / 1).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : filteredDataJsonNueva[key]}
                            </Descriptions.Item>
                          ))}
                        </Descriptions>
                      </Col>
                    </Row>
                  )}
                </Timeline.Item>
              );
            })}
          </Timeline>
        </div>
      </Modal>
    </>
  );
};

export default Productos;
