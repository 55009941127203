import { Col, Row, DatePicker, Table, Card } from "antd";
import "moment/locale/es";

import moment from "moment";
import { ButtonExcel } from "components/otros/buttons";
import { useNotasCredito } from "hooks/inventarios/useNotasCredito";

export function NotasCredito() {
  const { columns, mes, setMes, filteredData, handleClickDownloadData, estadoBoton } = useNotasCredito();
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <DatePicker
          value={mes}
          picker="month"
          placeholder="Seleccionar mes"
          format="MMMM [del] YYYY"
          onChange={(value) => setMes(value ?? moment())}
          inputReadOnly
        />
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Row gutter={[10, 10]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
          <Col xs={24} md={10}>
            {estadoBoton && <ButtonExcel buttonProps={{ onClick: handleClickDownloadData }}></ButtonExcel>}
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <Table
            columns={columns}
            dataSource={filteredData}
            scroll={{ x: true }}
            size="small"
            pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
            /*expandable={{
              expandedRowRender: (record) => {
                console.log(record.estado);

                return (
                  <Table
                    style={{ width: "100%", margin: "10px" }}
                    columns={columsDataDependiente}
                    //dataSource={detalle}
                    pagination={false}
                  />
                );
              },
            
            }}*/
          />
        </Card>
      </Col>
    </Row>
  );
}

export default NotasCredito;
