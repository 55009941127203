import { Col, DatePicker, Row, Space, Table } from "antd"
import { ModalReport } from "components/otros/ModalReport"
import { PdfViewer } from "components/otros/pdf-viewer"
import { GenerateReport } from "components/rrhh/componentsllamadosatencion/generate-report"
import { useBitacoraRecibidos } from "hooks/rrhh/llamadosatencion/useBitacoraRecibidos"

export function BitacoraRecibidos() {
  const {
    fechas,
    setFechas,
    openModalPdf,
    openModalReport,
    columnsTable,
    vitacora,
    pageSize,
    setPageSize,
    selecteds,
    setOpenModalPdf,
    llamadoAtencion,
    setOpenModalReport,
  } = useBitacoraRecibidos()

  return (
    <>
      <Row
        wrap
        style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff", display: "flex", alignItems: "center" }}
        gutter={[16, 16]}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Space>
            <>Fecha recibido:</>
            <DatePicker.RangePicker
              value={fechas}
              onChange={dates => dates && setFechas(dates)}
              format="DD/MM/YYYY"
              allowClear={false}
            />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            columns={columnsTable}
            scroll={{ x: true }}
            dataSource={vitacora}
            size="small"
            pagination={{
              pageSize,
              size: "default",
              onShowSizeChange: (_, size) => setPageSize(size),
              pageSizeOptions: [7, 10, 20, 50, 100],
            }}
          />
        </Col>
      </Row>
      <PdfViewer
        visible={openModalPdf}
        onCancel={() => setOpenModalPdf(false)}
        src={selecteds.enlacePdf}
        title="Llamado de atención recibido"
      />
      {llamadoAtencion && (
        <ModalReport
          openModal={openModalReport}
          setOpenModal={setOpenModalReport}
          titleModal="Reporte de Llamado de Atención"
          pdfName={`Llamado de atención #${
            vitacora.find(({ empleado, solicitud }) => empleado === selecteds.empleado && solicitud === selecteds.solicitud)
              ?.numeracion
          }`}
        >
          <GenerateReport
            numeracion={
              vitacora.find(
                ({ empleado, solicitud }) => empleado === selecteds.empleado && solicitud === selecteds.solicitud
              )?.numeracion ?? ""
            }
            llamadoAtencion={llamadoAtencion}
          />
        </ModalReport>
      )}
    </>
  )
}
