import Swal from "sweetalert2"

import { useNavigate } from "react-router-dom"

import { useAuth } from "hooks/otros/useAuth"

import type { userData } from "hooks/otros/useAuth"

export function useLogin() {
  const navigate = useNavigate()

  const { login } = useAuth()

  const autenticarUsuario = async (values: userData) => {
    const { status, msg, token } = await login(values)
    // console.log(status);

    if (!status) {
      const title = msg === "no_conexion" ? "No hay conexión con el servidor" : "Datos Incorrectos"
      const text =
        msg === "no_conexion"
          ? "No se pudo establecer conexión con el servidor"
          : "No pudo iniciar sesión porque los datos de acceso no son correctos"
      Swal.fire({
        title,
        text,
        icon: "info",
        timer: 3000,
        showConfirmButton: false,
      })
      return
    }

    Swal.fire({
      title: "Usuario Verificado",
      text: "Datos de acceso correctos",
      icon: "success",
      timer: 3000,
      showConfirmButton: false,
    })

    localStorage.setItem("token", token)
    navigate("/")
  }

  return { autenticarUsuario }
}
