import { Card, Table } from "antd";

import { useBilleteatePV } from "hooks/indicadores/useBilleteatePV";
import moment from "moment";

function BilleteatePV() {
  const {
    calcularTotales,
    headTableBilleate,
    dataBilleteate,
    headTableBilleateProductos,
    handleExpandChange,
    calcularTotalesProductos,
    expandedData,
  } = useBilleteatePV();
  const { total, porcentaje } = calcularTotales();

  return (
    <Card>
      <Table
        // components={vt}
        title={() => <strong>{`Billetéate periodo: ${moment().subtract(1, "days").format("YYYYMM")}`}</strong>}
        columns={headTableBilleate}
        bordered
        dataSource={dataBilleteate}
        size='small'
        pagination={false}
        scroll={{ x: 400, y: 420 }}
        // summary={() => {
        //   const { total, porcentaje } = calcularTotales();
        //   return (
        //     <Table.Summary.Row style={{ backgroundColor: "#f0f0f0" }}>
        //       <Table.Summary.Cell align='left' index={1}></Table.Summary.Cell>
        //       <Table.Summary.Cell align='left' index={2}>
        //         Total
        //       </Table.Summary.Cell>
        //       <Table.Summary.Cell index={3} align='right'>
        //         {total}
        //       </Table.Summary.Cell>
        //       <Table.Summary.Cell index={4} align='right'>
        //         {porcentaje + "%"}
        //       </Table.Summary.Cell>
        //     </Table.Summary.Row>
        //   );
        // }}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <Table
                bordered
                size='small'
                pagination={false}
                className='tabla-interna'
                columns={headTableBilleateProductos}
                dataSource={expandedData[record.key]?.data || []}
                // dataSource={dataBilleteateProductos}
                scroll={{ x: 400, y: 420 }}
                summary={() => {
                  const { total, porcentaje } = calcularTotalesProductos(expandedData[record.key]?.data || []);
                  return (
                    <Table.Summary.Row style={{ backgroundColor: "#f0f0f0" }}>
                      {/* <Table.Summary.Cell align='left' index={1}></Table.Summary.Cell> */}
                      <Table.Summary.Cell align='left' index={2}>
                        Total
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3} align='right'>
                        {total}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} align='right'>
                        {porcentaje + "%"}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                }}
              ></Table>
            );
          },
          onExpand: handleExpandChange,
        }}
      ></Table>

      <div style={{ backgroundColor: "#f0f0f0", padding: "8px 4px", marginTop: "8px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Total</span>
          <span></span>
          <span> </span>

          <span>{total}</span>
          <span>{porcentaje}%</span>
        </div>
      </div>
    </Card>
  );
}

export default BilleteatePV;
