import { createContext } from "react"

interface ListContextType {
  setOpenKeys: React.Dispatch<React.SetStateAction<string[]>>
  setSelectedKeys: React.Dispatch<React.SetStateAction<string[]>>
  setShowBack: React.Dispatch<React.SetStateAction<boolean>>
  setBreadCrumbs: React.Dispatch<React.SetStateAction<{ Label: string | JSX.Element | JSX.Element[], Url?: string }[]>>
}

interface ContextListProps extends ListContextType {
  children: JSX.Element | Array<JSX.Element>
}

export const LayoutContext = createContext<ListContextType>({} as ListContextType)

export function ContextLayout({ children, setOpenKeys, setSelectedKeys, setShowBack, setBreadCrumbs }: ContextListProps) {

  return (
    <LayoutContext.Provider value={{ setOpenKeys, setSelectedKeys, setShowBack, setBreadCrumbs }}>
      { children }
    </LayoutContext.Provider>
  )
}