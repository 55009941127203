import { Modal } from "antd";

interface PropsModal {
  visible: any;
  setVisible: any;
  llave: string;
}

export const ImgViewer = ({ visible, setVisible, llave }: PropsModal) => {
  return (
    <>
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width='300px'
        transitionName=''
        style={{
          // top: 64,
          position: "relative",
        }}
        title='Visor de Imagenes'
      >
        <img
          src={llave}
          width='100%'
          height='300px'
          alt='foto carnet'
          style={{ objectFit: "fill" }}
        ></img>
      </Modal>
    </>
  );
};
