import {
  Col,
  Row,
  Form,
  Input,
  Typography,
  Radio,
  InputNumber,
  Select,
  DatePicker,
  Card,
  Divider,
  FormInstance,
  Tooltip,
} from "antd";
import type { RadioChangeEvent } from "antd";
import { useContext, useEffect, useState } from "react";
import { dataC, dateFormat } from "./MiInformacion";
import { Residentes } from "./Residentes";
import { useFormularioActualizacion } from "../../../hooks/rrhh/actualizacion/useFormularioActualizacion";
import { UserContext } from "context/UserContext";

export interface data {
  validaConyugue: boolean | undefined;
  form: FormInstance<any>;
  hidden: boolean;
  hidden2: boolean;
  hijos: boolean;
  handleChangeIngreso: (value: RadioChangeEvent) => void;
  handleChangeParentezco: (value: RadioChangeEvent) => void;
  handleChangeHijos: (value: RadioChangeEvent) => void;
}

const OtrosDatos = ({
  validaConyugue,
  form,
  hidden,
  hidden2,
  hijos,
  handleChangeIngreso,
  handleChangeParentezco,
  handleChangeHijos,
}: data) => {
  // const [hijos, setHijos] = useState<boolean>(true);
  const [cargos, setCargos] = useState<dataC[]>([]);
  const [transporte, setTransporte] = useState<dataC[]>([]);
  const [residen, setResiden] = useState<number>(0);
  const { userData } = useContext(UserContext);

  let { getCargos, getTransporte } = useFormularioActualizacion();

  useEffect(() => {
    const fnCargos = async () => {
      let res = await getCargos();
      setCargos(res.data);
    };

    const fnTrasnporte = async () => {
      let res = await getTransporte();
      setTransporte(res.data);
    };

    fnTrasnporte();
    fnCargos();
    // setHijos(!form.getFieldValue("es_hijos"));
    // setHidden(!form.getFieldValue("es_ingreso_adicional"));
    // setHidden2(!form.getFieldValue("es_relacion_parentesco"));
  }, [getCargos, getTransporte, form]);

  const handleChangeResiden = (value: any) => {
    if (!value) return;

    let residentesHistory = form.getFieldsValue().residentes || [];

    if (value > residentesHistory.length) {
      for (let i = residentesHistory.length; i < value; i++) {
        residentesHistory.push({
          nombres_residente: "",
          fecha_nacimiento_residente: "",
          ocupacion_residente: "",
        });
      }
    } else if (value < residentesHistory.length) {
      residentesHistory = residentesHistory.slice(0, value);
    }

    let data = JSON.parse(localStorage.getItem("G" + userData?.id) || "{}");
    let data2 = { ...data, residentes: residentesHistory };
    localStorage.setItem("G" + userData?.id, JSON.stringify(data2));

    form.setFieldsValue({ residentes: residentesHistory });
    setResiden(value);
  };

  return (
    <Row wrap={true} style={{ width: "100%" }}>
      <Col xs={24} md={24} lg={24}>
        <Card title='Datos Familiares' type='inner' size='small'>
          <Input.Group>
            <Row wrap={true}>
              <Col xs={12} xl={12}>
                <Form.Item
                  style={{ width: "99%" }}
                  label={
                    <Typography.Text ellipsis={{ tooltip: "Nombres de su madre" }}>Nombres de su madre</Typography.Text>
                  }
                  name={["madre", "nombres_madre"]}
                  rules={[{ required: true, message: "Escriba los nombres de su madre o xxxxx" }]}
                >
                  <Input style={{ width: "98%" }} placeholder='Escriba los nombres de su madre' />
                </Form.Item>
              </Col>
              <Col xs={12} xl={6}>
                <Form.Item label='Ocupación' name={["madre", "ocupacion_madre"]}>
                  <Input placeholder='Escriba la ocupación' style={{ width: "98%" }} />
                </Form.Item>
              </Col>
              <Col xs={12} xl={3}>
                <Form.Item
                  label={<Typography.Text ellipsis={{ tooltip: "¿Es fallecida madre?" }}>¿Es fallecida?</Typography.Text>}
                  style={{ width: "100%" }}
                  name={["madre", "es_fallecida_mama"]}
                  rules={[{ required: true, message: "Escoja una opción" }]}
                >
                  <Radio.Group style={{ width: "95%" }}>
                    <Radio value={true}> Si </Radio>
                    <Radio value={false}> No </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col xs={12} xl={3}>
                <Form.Item
                  label={
                    <Typography.Text ellipsis={{ tooltip: "¿Es desconocida madre?" }}>¿Es desconocida?</Typography.Text>
                  }
                  style={{ width: "100%" }}
                  name={["madre", "es_desconocida_mama"]}
                  rules={[{ required: true, message: "Escoja una opción" }]}
                >
                  <Radio.Group style={{ width: "95%" }}>
                    <Radio value={true}> Si </Radio>
                    <Radio value={false}> No </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>

          <Input.Group>
            <Row wrap={true}>
              <Col xs={12} lg={12}>
                <Form.Item
                  style={{ width: "99%" }}
                  label={
                    <Typography.Text ellipsis={{ tooltip: "Nombres de su padre" }}>Nombres de su padre</Typography.Text>
                  }
                  name={["padre", "nombres_padres"]}
                  rules={[{ required: true, message: "Escriba los nombres completos de su padre o xxxx" }]}
                >
                  <Input style={{ width: "100%" }} placeholder='Escriba los nombres de su padre' />
                </Form.Item>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Item label='Ocupación' name={["padre", "ocupacion_padre"]}>
                  <Input placeholder='Escriba la ocupación' style={{ width: "98%" }} />
                </Form.Item>
              </Col>
              <Col xs={12} lg={3}>
                <Form.Item
                  label={<Typography.Text ellipsis={{ tooltip: "¿Es fallecido padre?" }}>¿Es fallecido?</Typography.Text>}
                  style={{ width: "100%" }}
                  name={["padre", "es_fallecido_papa"]}
                  rules={[{ required: true, message: "Escoja una opción" }]}
                >
                  <Radio.Group style={{ width: "95%" }}>
                    <Radio value={true}> Si </Radio>
                    <Radio value={false}> No </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={12} lg={3}>
                <Form.Item
                  label={<Typography.Text ellipsis={{ tooltip: "¿Es desconocido padre" }}>¿Es desconocido?</Typography.Text>}
                  style={{ width: "100%" }}
                  name={["padre", "es_desconocido_papa"]}
                  rules={[{ required: true, message: "Escoja una opción" }]}
                >
                  <Radio.Group style={{ width: "95%" }}>
                    <Radio value={true}> Si </Radio>
                    <Radio value={false}> No </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>

          <Input.Group>
            <Row wrap={true}>
              <Col xs={24} xl={8}>
                <Form.Item
                  label={
                    <Typography.Text ellipsis={{ tooltip: "Cedula del cónyugue/pareja" }}>
                      Nombres del cónyugue/pareja
                    </Typography.Text>
                  }
                  name={["conyugue", "nombres_conyugue"]}
                  rules={[{ required: validaConyugue }]}
                >
                  <Input placeholder='Escriba los nombres completos de su Cónyugue' style={{ width: "98%" }} />
                </Form.Item>
              </Col>
              <Col xs={12} xl={8}>
                <Form.Item
                  label={
                    <Typography.Text ellipsis={{ tooltip: "Cedula del cónyugue/pareja" }}>
                      Cedula del cónyugue/pareja
                    </Typography.Text>
                  }
                  name={["conyugue", "cedula_conyugue"]}
                  rules={[
                    {
                      required: validaConyugue,
                      message: "Cedula_conyuge es obligatorio",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Ingrese un número válido",
                    },
                  ]}
                >
                  <Input placeholder='Escriba la Cedula del Conyugue' style={{ width: "98%" }} />
                </Form.Item>
              </Col>
              <Col xs={12} xl={8}>
                <Form.Item
                  label={
                    <Typography.Text ellipsis={{ tooltip: "Empresa donde labora su cónyugue/pareja" }}>
                      Empresa donde labora su cónyugue/pareja
                    </Typography.Text>
                  }
                  style={{ width: "100%" }}
                  name={["conyugue", "empresa_conyugue"]}
                >
                  <Input style={{ width: "98%" }} placeholder='Escriba la empresa donde labora su cónyugue/pareja' />
                </Form.Item>
              </Col>
            </Row>

            <Row wrap={true}>
              <Col xs={24} xl={12}>
                <Form.Item
                  label={
                    <Typography.Text ellipsis={{ tooltip: "Telefono de la Oficina de su cónyugue/pareja" }}>
                      Teléfono de la oficina de su cónyugue/pareja
                    </Typography.Text>
                  }
                  style={{ width: "99%" }}
                  name={["conyugue", "telefono_oficina_conyugue"]}
                >
                  <InputNumber placeholder='Escriba el número de teléfono' style={{ width: "98%" }} maxLength={10} />
                </Form.Item>
              </Col>
              <Col xs={12} xl={6}>
                <Form.Item
                  label={
                    <Typography.Text ellipsis={{ tooltip: "Ocupación de su cónyugue/pareja" }}>
                      Ocupación de su cónyugue/pareja
                    </Typography.Text>
                  }
                  style={{ width: "99%" }}
                  name={["conyugue", "ocupacion_conyugue"]}
                >
                  <Input placeholder='Escriba ocupación de su cónyugue' />
                </Form.Item>
              </Col>
              <Col xs={12} xl={6}>
                <Form.Item
                  label={
                    <Typography.Text ellipsis={{ tooltip: "Sueldo de su cónyugue/pareja" }}>
                      Sueldo de su cónyugue/pareja
                    </Typography.Text>
                  }
                  style={{ width: "99%" }}
                  name={["conyugue", "sueldo_conyugue"]}
                >
                  <InputNumber placeholder='Sueldo Cónyugue' style={{ width: "97%" }} min={0} />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>

          <Row wrap={true}>
            <Col xs={8} xl={8}>
              <Form.Item
                label={
                  <Typography.Text ellipsis={{ tooltip: "¿Recibe Ud. O su cónyugue algún ingreso adicional?" }}>
                    ¿Recibe Ud. O su cónyugue algún ingreso adicional?
                  </Typography.Text>
                }
                style={{ width: "100%" }}
                name='es_ingreso_adicional'
                rules={[{ required: true, message: "Escoja una opción" }]}
              >
                <Radio.Group style={{ width: "97%" }} onChange={handleChangeIngreso}>
                  <Radio value={true}> Si </Radio>
                  <Radio value={false}> No </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={8} xl={8}>
              <Form.Item
                label={
                  <Typography.Text ellipsis={{ tooltip: "¿Cual es ese ingreso?" }}>¿Cúal es ese ingreso?</Typography.Text>
                }
                style={{ width: "98%" }}
                name='cual_ingreso'
                rules={[{ required: !hidden }]}
              >
                <Input placeholder='Cual es ese ingreso' style={{ width: "97%" }} disabled={hidden} />
              </Form.Item>
            </Col>

            <Col xs={8} xl={8}>
              <Form.Item
                label={<Typography.Text ellipsis={{ tooltip: "Monto del ingreso" }}>Monto del ingreso</Typography.Text>}
                style={{ width: "97%" }}
                name='monto_ingreso'
                rules={[{ required: !hidden }]}
              >
                <InputNumber placeholder='Cantidad del ingreso' style={{ width: "97%" }} disabled={hidden} min={0} />
              </Form.Item>
            </Col>
          </Row>

          <Row wrap={true}>
            <Col xs={10} xl={12}>
              <Form.Item
                label={
                  <Typography.Text ellipsis={{ tooltip: "Cantidad de personas que comparten la casa con Ud." }}>
                    Cantidad de personas que comparten la casa con Ud.
                  </Typography.Text>
                }
                style={{ width: "99%" }}
                name='personas_residen'
                rules={[{ required: true, message: "Escriba el # de personas" }]}
              >
                <InputNumber style={{ width: "98%" }} onChange={handleChangeResiden} min={0} max={6} />
              </Form.Item>
            </Col>
            <Col xs={6} xl={4}>
              <Form.Item
                label={<Typography.Text ellipsis={{ tooltip: "¿Tiene hijo(s)?" }}>¿Tiene hijo(s)?</Typography.Text>}
                style={{ width: "100%" }}
                name='es_hijos'
                rules={[{ required: true, message: "Escoja una opción" }]}
              >
                <Radio.Group style={{ width: "95%" }} onChange={handleChangeHijos}>
                  <Radio value={true}> Si </Radio>
                  <Radio value={false}> No </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={8} xl={8}>
              <Form.Item
                label={<Typography.Text ellipsis={{ tooltip: "Cantidad de hijos" }}>Cantidad de hijos</Typography.Text>}
                style={{ width: "99%" }}
                name='cantidad_hijos'
                rules={[{ required: hijos }]}
              >
                <InputNumber style={{ width: "98%" }} min={0} disabled={!hijos} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Divider />
        <Card title='Información Laboral' type='inner' size='small'>
          <Row wrap={true}>
            <Col xs={24} xl={12}>
              <Form.Item
                label={
                  <Tooltip title='Cuál es el medio de transporte que utiliza para llegar a su lugar de trabajo'>
                    <Typography.Text>
                      Cuál es el medio de transporte que utiliza para llegar a su lugar de trabajo
                    </Typography.Text>
                  </Tooltip>
                }
                style={{ width: "99%" }}
                name='transporte'
                rules={[{ required: true, message: "Escriba el # de buses" }]}
              >
                <Select
                  style={{ width: "98%" }}
                  options={transporte}
                  showSearch
                  optionFilterProp='label'
                  placeholder='SELECCIONE UN MEDIO DE TRANSPORTE'
                ></Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label={
                  <Tooltip title='Cuántos minutos les toma desplazarse desde su residencia a su lugar de trabajo'>
                    <Typography.Text>
                      Cuántos minutos les toma desplazarse desde su residencia a su lugar de trabajo
                    </Typography.Text>
                  </Tooltip>
                }
                style={{ width: "99%" }}
                name='minutos_empresa'
                rules={[{ required: true, message: "Escriba el # de Kms" }]}
              >
                <InputNumber style={{ width: "98%" }} min={0} />
              </Form.Item>
            </Col>
          </Row>

          <Row wrap={true}>
            <Col xs={24} xl={12}>
              <Form.Item
                label='Fecha de Ingreso a la empresa'
                style={{ width: "99%" }}
                name='fecha'
                rules={[{ required: true, message: "Fecha es obligatoria" }]}
              >
                <DatePicker style={{ width: "98%" }} format={dateFormat} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label='Cargo que ocupa actualmente'
                style={{ width: "99%" }}
                name='cargo'
                rules={[{ required: true, message: "Cargo es obligatorio" }]}
              >
                <Select
                  style={{ width: "98%" }}
                  options={cargos}
                  showSearch
                  optionFilterProp='label'
                  placeholder='SELECCIONE UN CARGO EN LA EMPRESA'
                ></Select>
              </Form.Item>
            </Col>
          </Row>

          <Row wrap={true}>
            <Col xs={23} xl={12}>
              <Form.Item
                style={{ width: "98%" }}
                name='es_relacion_parentesco'
                label={
                  <Typography.Text
                    ellipsis={{
                      tooltip: "Tiene parentesco con algún empleado en la empresa",
                    }}
                  >
                    Tiene parentesco con algún empleado en la empresa
                  </Typography.Text>
                }
                rules={[{ required: true, message: "Escoja un opción" }]}
              >
                <Radio.Group onChange={handleChangeParentezco}>
                  <Radio value={true}> Si </Radio>
                  <Radio value={false}> No </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Input.Group>
            <Row wrap={true}>
              <Col xs={12} xl={12}>
                <Form.Item
                  name={["parientes", "nombres_relacion_parentesco"]}
                  rules={[{ required: !hidden2 }]}
                  label={
                    <Typography.Text ellipsis={{ tooltip: "Nombres y apellidos del pariente en la empresa" }}>
                      Nombres y apellidos del pariente en la empresa
                    </Typography.Text>
                  }
                  style={{ width: "98%" }}
                >
                  <Input style={{ width: "99%" }} placeholder='Nombres del pariente' disabled={hidden2} />
                </Form.Item>
              </Col>

              <Col xs={12} xl={12}>
                <Form.Item
                  label='Ingrese el parentesco '
                  rules={[{ required: !hidden2 }]}
                  style={{ width: "99%" }}
                  name={["parientes", "tipo_parentesco"]}
                >
                  <Input style={{ width: "98%" }} disabled={hidden2} />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
        </Card>
        <Divider />
        <Card title='Informacion de personas que viven con Ud.' type='inner' size='small'>
          <Residentes residen={residen} form={form} />
        </Card>
      </Col>
    </Row>
  );
};
export default OtrosDatos;
