import { CaretUpOutlined } from "@ant-design/icons";
import { BackTop } from "antd";
const style: React.CSSProperties = {
  height: 40,
  width: 40,
  lineHeight: "40px",
  borderRadius: 9,
  backgroundColor: "#6a2831",
  color: "#fff",
  textAlign: "center",
  fontSize: 14,
};

export const BackTopComponent = () => {
  return (
    <BackTop duration={1}>
      <div style={style}>
        <CaretUpOutlined />
      </div>
    </BackTop>
  );
};
