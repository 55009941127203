import { useState } from "react";

export const useListCuotaOut = (form: any) => {
  // const [checkOut, setCheckOut] = useState<Record<string, boolean>>({});
  const [isLimited, setIsLimited] = useState(true);

  const toggleLimit = (checked: boolean, name: number) => {
    setIsLimited(!isLimited);

    if (checked) {
      form.setFieldsValue({
        distribucionOut: form
          .getFieldValue("distribucionOut")
          .map((item: any, index: any) => (index === name ? { ...item, valor: 0 } : item)),
      });
    }
  };

  return {
    toggleLimit,
    isLimited,
    setIsLimited,
  };
};
