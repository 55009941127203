import { Card, Form, Select, InputNumber, Row, Col, Button, Input, Spin } from "antd"
import { PropsTable, useSinMovimiento } from "hooks/productos/useSinMovimiento"
import TableReportVirtual from "components/otros/TableReportVirtual"
import { SaveOutlined } from "@ant-design/icons"

export const SinMovimiento = () => {
  const {
    tipos,
    laboratorios,
    form,
    columnTable,
    changeLaboratorio,
    changeDias,
    changeTipo,
    selectTipo,
    columnsProducto,
    columnsBodega,
    columnsProductoDetails,
    expandedData,
    sendInfo,
    handleInnerTableSelectChange,
    innerSelectedRowKeys,
    expandedRowKeys,
    handleExpandChange,
    selectLaboratorio,
    handleTableSelectChangeMain,
    selectedRowMain,
    loadingCheckboxes,
    loading,
    handleDropdownVisibleChange,
    exportToExcel,
  } = useSinMovimiento()

  return (
    <div>
      <Card>
        <Row gutter={[0, 10]}>
          <Col md={24} xs={24}>
            <Card>
              <Form form={form} initialValues={{ dias: 150, tipo: selectTipo, cod_laboratorio: [0] }}>
                <Row gutter={[5, 0]}>
                  <Col md={5} xs={24}>
                    <Form.Item label="Tipo" name="tipo" required>
                      <Select
                        placeholder="Seleccione un tipo"
                        options={tipos}
                        value={selectTipo}
                        onChange={changeTipo}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item label="Días con sobre stock" name="dias" required>
                      <InputNumber placeholder="" min={30} style={{ width: "100%" }} onChange={changeDias}></InputNumber>
                    </Form.Item>
                  </Col>
                  <Col md={13} xs={24}>
                    <Form.Item
                      label="Observación"
                      name="observacion"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese una observación!",
                        },
                      ]}
                    >
                      <Input placeholder="Ingrese una observación" style={{ width: "100%" }}></Input>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[5, 0]}>
                  <Col md={24} xs={24}>
                    <Form.Item label="Laboratorios" name="cod_laboratorio" required>
                      <Select
                        allowClear
                        showSearch
                        optionFilterProp="label"
                        placeholder="Escoja un laboratorio"
                        mode="multiple"
                        options={laboratorios}
                        onChange={changeLaboratorio}
                        onDropdownVisibleChange={handleDropdownVisibleChange}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>

                <TableReportVirtual
                  headTable={selectTipo === 0 && columnTable.length > 0 ? columnsProducto : columnsBodega}
                  columnTable={columnTable}
                  rowSelection={{
                    selectedRowKeys: selectedRowMain,
                    onChange: (selectedRowKeysInner: number[], selectedRowRecord: PropsTable[]) =>
                      handleTableSelectChangeMain(selectedRowRecord, selectedRowKeysInner),
                    getCheckboxProps: (record: PropsTable) => ({
                      disabled:
                        loadingCheckboxes.includes(record.key) ||
                        record.disabled ||
                        loadingCheckboxes.length === 1 ||
                        loading,
                      className: loadingCheckboxes.includes(record.key) ? "row-loading" : "",
                    }),
                  }}
                  exportToExcel={exportToExcel}
                  isSelect={
                    columnTable.length > 0 && (selectLaboratorio[0] === 0 || selectLaboratorio.length === 0) ? false : true
                  }
                  muestra={true}
                  y={320}
                  expandable={{
                    expandedRowRender: (record: PropsTable) => {
                      const currentData = expandedData[record.key]
                      return currentData ? (
                        <TableReportVirtual
                          headTable={
                            selectTipo === 0
                              ? currentData.length > 0
                                ? columnsProductoDetails
                                : columnsBodega
                              : columnsProducto
                          }
                          columnTable={currentData}
                          rowSelection={{
                            selectedRowKeys: innerSelectedRowKeys[record.key] || [],
                            onChange: (selectedRowKeysInner: number[], selectedRowRecord: PropsTable[]) =>
                              handleInnerTableSelectChange(record, selectedRowRecord, selectedRowKeysInner),
                            getCheckboxProps: (record: PropsTable) => ({
                              disabled: record.disabled || loading,
                            }),
                          }}
                          exportToExcel={() => null}
                          isSelect={selectLaboratorio[0] === 0 ? false : true}
                          muestra={false}
                          color="tabla-interna"
                          isTotal={false}
                          expandable={null}
                          y={230}
                        />
                      ) : (
                        <Spin />
                      )
                    },
                    onExpand: handleExpandChange,
                    expandedRowKeys: expandedRowKeys,
                  }}
                />
                {selectLaboratorio[0] > 0 && (
                  <Button
                    onClick={sendInfo}
                    type="primary"
                    style={{
                      width: "100%",
                      marginTop: "10px",
                    }}
                    loading={loading}
                    // disabled={loadingCheckboxes.length > 0}
                    icon={<SaveOutlined className="iconbutton" />}
                  >
                    GUARDAR
                  </Button>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  )
}
