import { type TableColumnsType, Tag } from "antd"
import { url } from "config/constants"
import { useExport } from "hooks/otros/useExport"
import { useLayout } from "hooks/otros/useLayout"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSearchTable } from "hooks/otros/useSearchTable"
import { useSwal } from "hooks/otros/useSwal"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import moment from "moment"
import type { Moment } from "moment"
import { useCallback, useEffect, useRef, useState } from "react"

type DataLlamadosAtencion = {
  numeracion: string
  cedula: string
  colaborador: string
  multa: number
  solicitante: string
  fecha_solicitud: string
  fecha_falta: string
  fecha_aprobacion: string
  fecha_recibido: string
}

const breadcrumbs = [
  { Label: "Recursos Humanos", Url: "/rrhh" },
  { Label: "Llamados de atención", Url: "/rrhh/llamados-de-atencion" },
  { Label: "Reporte de recibidos" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export function useReporteRecibidos() {
  useLayout(breadcrumbs, openKeys, selectKeySider)

  const [fechaRevision, setFechaRevision] = useState<Moment>(moment().subtract(1, "month"))
  const [llamadosAtencion, setLlamadosAtencion] = useState<DataLlamadosAtencion[]>([])
  const [pageSize, setPageSize] = useState(5)

  const soloMulta = useRef(true)

  const { exportToExcel } = useExport()

  const { getColumnSearchProps } = useSearchTable<DataLlamadosAtencion>()

  const { optionsPost } = useLocalStorage()

  const { swalError } = useSwal()

  const { isError } = useVerifyResponse()

  const reFetcher = useCallback(async () => {
    try {
      const response = await fetch(
        `${url}apifsg-pr/recursos-humanos/listar-llamados-atencion-recibidos`,
        optionsPost({ fecha: fechaRevision.format("DD/MM/YYYY HH:mm:ss") + ".999" })
      )
      if (isError(response)) {
        throw new Error()
      }
      const result = await response.json()

      setLlamadosAtencion(result)
    } catch (e) {
      swalError({ text: "No se pudo obtener la lista de llamados de atención recibidos" })
    }
  }, [isError, optionsPost, swalError, fechaRevision])

  useEffect(() => {
    reFetcher()
  }, [reFetcher])

  const columnsTable: TableColumnsType<DataLlamadosAtencion> = [
    {
      title: "Numeracion",
      dataIndex: "numeracion",
      key: "numeracion",
      ...getColumnSearchProps("numeracion"),
    },
    {
      title: "Cedula",
      dataIndex: "cedula",
      key: "cedula",
      ...getColumnSearchProps("cedula"),
    },
    {
      title: "Colaborador",
      dataIndex: "colaborador",
      key: "colaborador",
      ...getColumnSearchProps("colaborador"),
    },
    {
      title: "Solicitante",
      dataIndex: "solicitante",
      key: "solicitante",
      ...getColumnSearchProps("solicitante"),
    },
    {
      title: "Multa",
      dataIndex: "multa",
      key: "multa",
      render: (value: number) => <Tag color={value === 0 ? "default" : value === 5 ? "blue" : "gold"}>{value}%</Tag>,
      filters: [
        {
          text: "0%",
          value: 0,
        },
        {
          text: "5%",
          value: 5,
        },
        {
          text: "10%",
          value: 10,
        },
      ],
      onFilter: (value, record) => record.multa === value,
    },
    { title: "Fecha de recibido", dataIndex: "fecha_recibido", key: "Fecha_recibido" },
    { title: "Fecha de aprobación", dataIndex: "fecha_aprobacion", key: "Fecha_aprobacion" },
    { title: "Fecha de la falta", dataIndex: "fecha_falta", key: "Fecha_Falta" },
    { title: "Fecha solicitud", dataIndex: "fecha_solicitud", key: "Fecha_solicitud" },
  ]

  const handleDownload = () => {
    const dataExport = (soloMulta.current ? llamadosAtencion.filter(({ multa }) => multa > 0) : llamadosAtencion).map(
      ({
        numeracion,
        cedula,
        colaborador,
        multa,
        solicitante,
        fecha_solicitud,
        fecha_aprobacion,
        fecha_falta,
        fecha_recibido,
      }) => ({
        Numeracion: numeracion,
        Cedula: cedula,
        Colaborador: colaborador,
        Multa: multa,
        Solicitante: solicitante,
        "Fecha de solicitud": fecha_solicitud,
        "Fecha de la falta": fecha_falta,
        "Fecha de aprobación": fecha_aprobacion,
        "Fecha de recibido": fecha_recibido,
      })
    )
    exportToExcel(dataExport, `Llamados de atención recibidos hasta el ${moment().format("DDMMYYYY")}`)
  }

  return {
    fechaRevision,
    setFechaRevision,
    soloMulta,
    handleDownload,
    columnsTable,
    llamadosAtencion,
    pageSize,
    setPageSize,
  }
}
