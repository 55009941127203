import { Button } from "antd";
import { AiOutlineSetting } from "react-icons/ai";
import { useComponentChart } from "hooks/indicadores/useComponentChart";

import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { LayoutType } from "recharts/types/util/types";

interface PropsChart {
  columnTable: any | PropsColumn[];
  grafica: string;
  tamaño: number;
  angulo: number;
  graph: string[];
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  dataKey?: string;
  opciones?: boolean;
  layout?: LayoutType;
  id?: string;
}

interface PropsColumn {
  key: string;
  fecha: string;
  trabajadores: number;
  entradas: number;
  salidas: number;
  "rotación %": string;
}

const ComponentChart = ({
  columnTable,
  grafica,
  tamaño,
  angulo,
  graph,
  setShowModal,
  dataKey,
  opciones = true,
  layout = "horizontal",
  id = "myChart",
}: PropsChart) => {
  const { getBarColor } = useComponentChart();

  const CustomXAxisTick: React.FC<any> = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={40} y={0} dy={5} textAnchor='end' fill='#666'>
          {payload.value} {payload.dataKey}%
        </text>
      </g>
    );
  };

  return (
    <div className='chart-container'>
      {opciones && (
        <Button
          type='default'
          icon={<AiOutlineSetting />}
          style={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
          onClick={() => setShowModal && setShowModal(true)}
        ></Button>
      )}
      <ResponsiveContainer width='100%' height={tamaño} id={id}>
        <ComposedChart
          layout={layout}
          width={450}
          height={255}
          data={columnTable}
          margin={{
            top: 5,
            right: 0,
            left: -10,
            bottom: 99,
          }}
        >
          <CartesianGrid strokeDasharray='4 1 2' />
          <XAxis dataKey={grafica} angle={angulo} textAnchor='end' interval={0} />
          <XAxis dataKey='name' orientation='top' />
          <YAxis yAxisId='left' />
          <YAxis yAxisId='right' orientation='right' type='number' tick={<CustomXAxisTick />} />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign='top' height={33} />

          {graph?.map((el: string, index: number) => {
            return <Bar key={el} dataKey={el} barSize={15} fill={getBarColor(index)} />;
          })}
          {dataKey && <Line dataKey={dataKey} stroke={getBarColor(graph?.length)} yAxisId='right' />}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ComponentChart;
