import { AdministracionTareas } from "pages/contentmarketing/sherlock/administracionTareas";
import { Principal } from "pages/contentprincipal/Principal";
import { RouteObject } from "react-router-dom";

export const rutasMarketing: RouteObject[] = [
  {
    path: "/marketing",
    element: <Principal />,
  },
  {
    path: "/marketing/sherlock",
    element: <Principal />,
  },
  {
    path: "marketing/administracion_tareas",
    element: <AdministracionTareas />,
  },
];
