import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useEffect, useContext, useCallback, useState, useRef } from "react";
import { url_interna, optionsPOST, optionsGET } from "config/constants";
import Swal from "sweetalert2";
import { Form } from "antd";
import * as XLSX from "xlsx";

import { useSearchTable } from "hooks/otros/useSearchTable";

import type { ColumnsType } from "antd/es/table";
import { useScreenSize } from "hooks/otros/useScreenSize";
import { UserContext } from "context/UserContext";
const breadcrumbs = [{ Label: "Sobre stock" }];

interface PropsRespuesta {
  msg: true;
  data: PropsSelect[];
}
interface PropsJson {
  key: number;
  info: string;
}

interface PropsLoadSeleccion {
  key: number;
  arr: number[];
}
interface PropsRespuestaTableSeleccion {
  seleccion: string;
}

interface PropsRespuestaTable {
  msg: true;
  data: PropsTable[];
  data2: PropsRespuestaTableSeleccion;
}

interface PropsSelect {
  bodegas: string;
  laboratorios: string;
}

export interface PropsTable {
  key: number;
  bodega: string;
  cod_Producto: number;
  Producto: string;
  lab: string;
  cajas: number;
  fracciones: number;
  minimo: number;
  dias: number;
  ultima_Venta: string;
  ultimo_Movimiento: string;
  costo: number;
  n_farmacias: number;
  establecimiento?: string;
  totalCosto: number;
  relatedField: string;
  disabled: boolean;
}

export const useSinMovimiento = () => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const [laboratorios, setLaboratorios] = useState<PropsSelect[]>([]);
  const [selectTipo, setSelectTipo] = useState<number>(1);
  const [selectLaboratorio, setSelectLaboratorio] = useState<number[]>([0]);
  const [columnTable, setColumnTable] = useState<PropsTable[]>([]);
  const [expandedData, setExpandedData] = useState<any>({});
  const [innerSelectedRowKeys, setInnerSelectedRowKeys] = useState<{ [key: string]: number[] }>({});
  const [innerSelectedRowKeysMain, setInnerSelectedRowKeysMain] = useState<{ [key: string]: number[] }>({});
  const [selectedRowsMain, setSelectedRowsMain] = useState<{ [key: string]: { mainKey: string; selected: PropsTable[] } }>(
    {}
  );
  const [selectedHistory, setSelectedHistory] = useState<PropsLoadSeleccion[]>([]);
  const { getColumnSearchProps } = useSearchTable<PropsTable>();
  const [selectedRowMain, setSelectedRowMain] = useState<number[]>([]);
  const [loadingCheckboxes, setLoadingCheckboxes] = useState<number[]>([]);
  const prevSelectedValuesRef = useRef<number[]>();

  const [loading, setLoading] = useState<boolean>(false);

  const { userData } = useContext(UserContext);

  const { width } = useScreenSize();
  const [form] = Form.useForm();
  // let { optionsPost, optionsGet } = useLocalStorage();
  useBreadCrumbs(breadcrumbs);

  const resetState = () => {
    setColumnTable([]);
    setSelectedRowsMain({});
    setInnerSelectedRowKeys({});
    setInnerSelectedRowKeysMain({});
    setExpandedRowKeys([]);
    setSelectedRowMain([]);
  };

  const handleInnerTableSelectChange = (
    record: PropsTable,
    selectedRowRecord: PropsTable[],
    selectedRowKeysInner: number[]
  ) => {
    setSelectedRowsMain((prevState) => ({
      ...prevState,
      [record.key]: { mainKey: record.key, selected: selectedRowRecord },
    }));
    setInnerSelectedRowKeys((prevInnerSelectedRowKeys) => ({
      ...prevInnerSelectedRowKeys,
      [record.key]: selectedRowKeysInner,
    }));
  };

  const handleTableSelectChangeMain = async (_: PropsTable[], selectedRowKeysInner: number[]) => {
    if (selectedRowKeysInner.length === 0) {
      setInnerSelectedRowKeys({});
      setExpandedData([]);
      setSelectedRowsMain({});
      setExpandedRowKeys([]);

      return setSelectedRowMain(selectedRowKeysInner);
    } else {
      if (selectedRowKeysInner[0] === 0) {
        setInnerSelectedRowKeys({});
        setExpandedData({});
        setSelectedRowsMain({});
        return setSelectedRowMain(selectedRowKeysInner);
      }
    }

    if (Object.keys(innerSelectedRowKeys).length === 0) {
      await sendDataMain(selectedRowKeysInner);
    } else if (Object.keys(innerSelectedRowKeys).length > selectedRowKeysInner.length) {
      const clavesFaltantes = Object.keys(innerSelectedRowKeys).filter((key) => !selectedRowKeysInner.includes(Number(key)));
      setInnerSelectedRowKeys((prevInnerSelectedRowKeys) => {
        const updatedState = { ...prevInnerSelectedRowKeys };
        clavesFaltantes.forEach((item) => delete updatedState[item]);
        return updatedState;
      });

      setSelectedRowsMain((prevInnerSelectedRowKeys) => {
        const updatedState = { ...prevInnerSelectedRowKeys };
        clavesFaltantes.forEach((item) => delete updatedState[item]);
        return updatedState;
      });

      setExpandedData((prevState: { [key: number]: { mainKey: string; selected: PropsTable[] } }) => {
        const updatedState = { ...prevState };
        clavesFaltantes.forEach((item) => delete updatedState[parseInt(item)]);
        return updatedState;
      });

      setExpandedRowKeys([]);

      return setSelectedRowMain(selectedRowKeysInner);
    } else {
      const clavesNuevas = selectedRowKeysInner.filter(
        (keyNumber) => !Object.keys(innerSelectedRowKeys).includes(String(keyNumber))
      );
      await sendDataMain(clavesNuevas);
    }
    setSelectedRowMain(selectedRowKeysInner);
  };

  const handleTableMain = useCallback(async (dataParse: PropsJson[], parseSelect: PropsLoadSeleccion[] = []) => {
    try {
      for (let el of dataParse) {
        let newSelection: number[] = [];
        let loadTableSelection: PropsTable[] = [];

        let dataTbl2: PropsTable[] = JSON.parse(el.info);

        for (let item of parseSelect) {
          if (item.key === el.key) {
            for (let item2 of dataTbl2) {
              if (item.arr.includes(item2.key)) {
                item2.disabled = true;
                setSelectedRowsMain((prevState: any) => {
                  const existingObj = prevState[el.key];
                  if (existingObj) {
                    const isAlreadyAdded = existingObj.selected.some((selectedItem: any) => selectedItem.key === item2.key);
                    if (!isAlreadyAdded) {
                      existingObj.selected.push(item2);
                    }
                    return {
                      ...prevState,
                      [el.key]: existingObj,
                    };
                  } else {
                    return {
                      ...prevState,
                      [el.key]: { mainKey: el.key, selected: [item2] },
                    };
                  }
                });
              }
            }
          }
        }

        setExpandedData((prevState: { [key: number]: { mainKey: string; selected: PropsTable[] } }) => ({
          ...prevState,
          [el.key]: dataTbl2,
        }));

        // Modificación #1
        setInnerSelectedRowKeys((prevInnerSelectedRowKeys: any) => {
          if (parseSelect.length > 0 && !prevInnerSelectedRowKeys[el.key]) {
            for (let el of parseSelect) {
              prevInnerSelectedRowKeys[el.key] = el.arr;
            }
          } else if (!prevInnerSelectedRowKeys[el.key]) {
            // Esta línea cambia también
            for (let row of JSON.parse(el.info)) {
              loadTableSelection.push(row);
              newSelection.push(row.key);
            }
            prevInnerSelectedRowKeys[el.key] = newSelection;
          }
          return { ...prevInnerSelectedRowKeys };
        });

        setSelectedRowsMain((prevState: any) => ({
          ...prevState,
          [el.key]: { mainKey: el.key, selected: loadTableSelection },
        }));
      }
      setLoadingCheckboxes([]);
    } catch (error) {
      Swal.fire("Error", "Ocurrio un error, intente nuevamente", "error");
    }
  }, []);

  const sendDataMain = useCallback(
    async (cod_products: number[], parseSelect: PropsLoadSeleccion[] = []) => {
      setLoadingCheckboxes(cod_products);
      if (selectTipo === 0) {
        // const respuesta = await fetch(
        //   // url_interna + "apifsg-pr/productos/sin-movimiento-id",
        //   url_interna + "api/productos/sin-movimiento-id",
        //   optionsPost({ cod_producto: cod_products, dias: form.getFieldsValue().dias })
        // ).then((data) => data.json());

        const respuesta = await fetch(
          `${url_interna}api/productos/sin-movimiento-id`,
          optionsPOST(JSON.stringify({ cod_producto: cod_products, dias: form.getFieldsValue().dias }), {
            "api-token": "apitoken",
          })
        ).then((data) => data.json());

        if (respuesta.msg) {
          let dataParse = JSON.parse(respuesta.data[0].data);
          await handleTableMain(dataParse, parseSelect);
        }
      } else if (selectTipo === 1) {
        // const respuesta = await fetch(
        //   // url_interna + "apifsg-pr/productos/sin-movimiento-productos",
        //   url_interna + "productos/sin-movimiento-productos",

        //   optionsPost({
        //     cod_bodega: JSON.stringify(cod_products),
        //     dias: form.getFieldsValue().dias,
        //     cod_laboratorio: form.getFieldsValue().cod_laboratorio,
        //   })
        // ).then((data) => data.json());

        const respuesta = await fetch(
          `${url_interna}api/productos/sin-movimiento-productos`,
          optionsPOST(
            JSON.stringify({
              cod_bodega: JSON.stringify(cod_products),
              dias: form.getFieldsValue().dias,
              cod_laboratorio: form.getFieldsValue().cod_laboratorio,
            }),
            { "api-token": "apitoken" }
          )
        ).then((data) => data.json());

        if (respuesta.msg) {
          let dataParse = JSON.parse(respuesta.data[0].data);
          await handleTableMain(dataParse, parseSelect);
        }
      }
    },
    [form, selectTipo, handleTableMain]
  );

  const handleExpandChange = async (expanded: boolean, record: PropsTable) => {
    if (expanded && selectLaboratorio.includes(0)) {
      setExpandedRowKeys([record.key]);
      await sendDataMain([record.key]);
      return;
    } else if (selectLaboratorio.includes(0)) {
      return setExpandedRowKeys([]);
    } else if (!selectedRowMain.includes(record.key))
      return Swal.fire("Advertencia", "Debe estar seleccionada la casilla del producto!", "warning");
    else if (expanded) {
      setExpandedRowKeys([record.key]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const columnsProducto: ColumnsType<PropsTable> = [
    {
      title: "Producto",
      dataIndex: "Producto",
      key: "Producto",
      width: "140px",
      ...getColumnSearchProps("Producto"),
    },
    {
      title: "Lab",
      dataIndex: "lab",
      key: "lab",
      width: `${width > 768 ? "47px" : "57px"}`,
      ...getColumnSearchProps("lab"),
    },
    {
      title: "Cajas",
      dataIndex: "cajas",
      key: "cajas",
      width: `${width > 768 ? "40px" : "57px"}`,
      sorter: (a, b) => a.cajas - b.cajas,
    },
    {
      title: "Fracciones",
      dataIndex: "fracciones",
      key: "fracciones",
      width: `${width > 768 ? "49px" : "89px"}`,
      sorter: (a, b) => a.fracciones - b.fracciones,
    },
    {
      title: "Minimo",
      dataIndex: "minimo",
      key: "minimo",
      width: `${width > 768 ? "47px" : "89px"}`,
      sorter: (a, b) => a.minimo - b.minimo,
    },
    {
      title: "Dias",
      dataIndex: "dias",
      width: `${width > 768 ? "44px" : "59px"}`,
      sorter: (a, b) => a.dias - b.dias,
    },
    {
      title: "Ultima_venta",
      dataIndex: "ultima_venta",
      key: "ultima_venta",
      width: `${width > 768 ? "53px" : "121px"}`,
    },
    {
      title: "Costo",
      dataIndex: "costo",
      key: "costo",
      width: `${width > 768 ? "57px" : "88px"}`,
      align: "right",
      sorter: (a, b) => a.costo - b.costo,
      render: (costo) =>
        (costo / 1).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      title: "N_Farmacias",
      dataIndex: "n_farmacias",
      key: "n_farmacias",
      width: `${width > 768 ? "58px" : "89px"}`,
      align: "right",
      sorter: (a, b) => a.n_farmacias - b.n_farmacias,
    },
  ];

  const columnsProductoDetails: ColumnsType<PropsTable> = [
    {
      title: "Bodega",
      dataIndex: "bodega",
      key: "bodega",
      width: "140px",
      ...getColumnSearchProps("bodega"),
    },
    {
      title: "Cajas",
      dataIndex: "cajas",
      key: "cajas",
      width: `${width > 768 ? "40px" : "57px"}`,
      sorter: (a, b) => a.cajas - b.cajas,
    },
    {
      title: "Fracciones",
      dataIndex: "fracciones",
      key: "fracciones",
      width: `${width > 768 ? "49px" : "89px"}`,
      sorter: (a, b) => a.fracciones - b.fracciones,
    },
    {
      title: "Minimo",
      dataIndex: "minimo",
      key: "minimo",
      width: `${width > 768 ? "47px" : "89px"}`,
      sorter: (a, b) => a.minimo - b.minimo,
    },
    {
      title: "Dias",
      dataIndex: "dias",
      width: `${width > 768 ? "49px" : "59px"}`,
      sorter: (a, b) => a.dias - b.dias,
    },
    {
      title: "Ultima_venta",
      dataIndex: "ultima_venta",
      key: "ultima_venta",
      width: `${width > 768 ? "50px" : "119px"}`,
    },
    {
      title: "Costo",
      dataIndex: "costo",
      key: "costo",
      width: `${width > 768 ? "57px" : "89px"}`,
      align: "right",
      sorter: (a, b) => a.costo - b.costo,
      render: (costo) =>
        (costo / 1).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
  ];

  const columnsBodega: ColumnsType<PropsTable> = [
    {
      title: "Establecimiento",
      dataIndex: "establecimiento",
      key: "establecimiento",
      width: "80px",
      ...getColumnSearchProps("establecimiento"),
    },
    {
      title: "Bodega",
      dataIndex: "bodega",
      key: "bodega",
      width: "140px",
      ...getColumnSearchProps("bodega"),
    },
    {
      title: "Costo",
      dataIndex: "costo",
      key: "costo",
      width: "57px",
      align: "right",
      sorter: (a, b) => a.costo - b.costo,
      render: (costo) =>
        (costo / 1).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
  ];

  const tipos = [
    {
      value: 0,
      label: "PRODUCTOS",
    },
    {
      value: 1,
      label: "BODEGA",
    },
  ];

  const changeTipo = (value: number) => {
    resetState();
    setSelectLaboratorio(form.getFieldsValue().cod_laboratorio);
    setSelectTipo(value);
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    if (open) {
      prevSelectedValuesRef.current = selectLaboratorio;
    } else {
      if (prevSelectedValuesRef.current) {
        const changed = JSON.stringify(prevSelectedValuesRef.current) !== JSON.stringify(selectLaboratorio);
        if (changed) {
          setColumnTable([]);
          getDataTable(-1, form.getFieldsValue().dias, selectLaboratorio);
        }
      }
    }
  };

  const changeLaboratorio = async (value: number[]) => {
    resetState();
    if (value.includes(0) && value.length > 1) {
      if (form.getFieldValue("cod_laboratorio").includes(0)) {
        let dataFilter = value.filter((v) => v !== 0);
        form.setFieldsValue({
          cod_laboratorio: dataFilter,
        });
        setSelectLaboratorio(dataFilter);
      } else {
        setSelectLaboratorio([0]);
        form.setFieldsValue({
          cod_laboratorio: [0],
        });
      }
    } else {
      if (value.length >= selectLaboratorio.length) {
        setSelectLaboratorio(value);
      } else {
        setSelectLaboratorio(value);
        prevSelectedValuesRef.current = value;
        setColumnTable([]);
        await getDataTable(-1, form.getFieldsValue().dias, selectLaboratorio);
      }
    }
  };

  const changeDias = (value: number | null) => {
    setColumnTable([]);
    value && getDataTable(-1, value, selectLaboratorio);
  };

  const getDataTable = useCallback(
    async (cod_bodega: number, dias: number, cod_laboratorio: number[]) => {
      try {
        if (selectTipo === 0) {
          // const respuesta: PropsRespuestaTable = await fetch(
          //   // url_interna + "apifsg-pr/productos/sin-movimiento-productos",
          //   url_interna + "productos/sin-movimiento-productos",
          //   optionsPost({ cod_bodega: JSON.stringify([cod_bodega]), dias, cod_laboratorio })
          // ).then((data) => data.json());

          const respuesta: PropsRespuestaTable = await fetch(
            `${url_interna}api/productos/sin-movimiento-productos`,
            optionsPOST(JSON.stringify({ cod_bodega: JSON.stringify([cod_bodega]), dias, cod_laboratorio }), {
              "api-token": "apitoken",
            })
          ).then((data) => data.json());

          if (respuesta.msg && respuesta.data?.length > 0) {
            let loadSeleccion: number[] = [];
            let parseSelect: PropsLoadSeleccion[] = JSON.parse(respuesta.data2.seleccion);

            respuesta.data.forEach((el) => {
              if (parseSelect.find((item2: PropsLoadSeleccion) => el.key === item2.key)) {
                el.disabled = true;
                loadSeleccion.push(el.key);
              }
            });

            setSelectedHistory(parseSelect);
            await sendDataMain(loadSeleccion, parseSelect);
            setColumnTable(respuesta.data);
            setSelectedRowMain(loadSeleccion);
          } else {
            Swal.fire("Advertencia", "No posee información", "warning");
          }
        } else if (selectTipo === 1) {
          // const respuesta: PropsRespuestaTable = await fetch(
          //   // url_interna + "apifsg-pr/productos/sin-movimiento-bodegas",
          //   url_interna + "productos/sin-movimiento-bodegas",

          //   optionsPost({ dias, cod_laboratorio })
          // ).then((data) => data.json());

          const respuesta: PropsRespuestaTable = await fetch(
            `${url_interna}api/productos/sin-movimiento-bodegas`,
            optionsPOST(JSON.stringify({ dias, cod_laboratorio }), { "api-token": "apitoken" })
          ).then((data) => data.json());

          if (respuesta.msg) {
            let loadSeleccion: number[] = [];
            let parseSelect: PropsLoadSeleccion[] = JSON.parse(respuesta.data2.seleccion);

            respuesta.data.forEach((el) => {
              // if (parseSelect.find((item2: PropsLoadSeleccion) => el.key === item2.key)) {

              if ([].find((item2: PropsLoadSeleccion) => el.key === item2.key)) {
                el.disabled = true;
                loadSeleccion.push(el.key);
              }
            });
            setSelectedHistory(parseSelect);
            await sendDataMain(loadSeleccion, parseSelect);
            setColumnTable(respuesta.data);
            setSelectedRowMain(loadSeleccion);
          } else {
            Swal.fire("Advertencia", "No posee información", "warning");
          }
        }
      } catch (e) {
        return { msg: "no_conexion", status: false };
      }
    },
    [selectTipo, sendDataMain]
  );

  const sendInfo = async () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        const newSelectedRowsMain = { ...selectedRowsMain };

        selectedHistory.forEach((item) => {
          if (newSelectedRowsMain[item.key]) {
            item.arr.forEach((id) => {
              newSelectedRowsMain[item.key].selected = newSelectedRowsMain[item.key].selected.filter(
                (selectedItem) => selectedItem.key !== id
              );
            });
            if (newSelectedRowsMain[item.key].selected.length === 0) {
              delete newSelectedRowsMain[item.key];
            }
          }
        });

        if (Object.keys(newSelectedRowsMain).length === 0) {
          setLoading(false);
          return Swal.fire("Advertencia", "No tienes ninguna seleccion nueva por guardar", "warning");
        } else if (Object.values(newSelectedRowsMain).some((entry) => entry.selected.length === 0)) {
          setLoading(false);
          return Swal.fire(
            "Advertencia",
            "Tienes seleccionado un elemento de la tabla prinicipal, sin selecciones internas!",
            "warning"
          );
        } else {
          // const respuesta: PropsRespuestaTable = await fetch(
          //   url_interna + "apifsg-pr/productos/guarda-detalles",
          //   url_interna + "productos/guarda-detalles",
          //   optionsPOST({ selectDetalles: newSelectedRowsMain, tipo: selectTipo, observacion: values.observacion })
          // ).then((data) => data.json());

          const respuesta = await fetch(
            `${url_interna}api/productos/guarda-detalles`,
            optionsPOST(
              JSON.stringify({
                cod_usuario: userData?.id,
                selectDetalles: newSelectedRowsMain,
                tipo: selectTipo,
                observacion: values.observacion,
              }),
              {
                "api-token": "apitoken",
              }
            )
          ).then((data) => data.json());

          if (respuesta.msg) {
            await getDataTable(-1, form.getFieldsValue().dias, form.getFieldsValue().cod_laboratorio);
            Swal.fire("Ok", "Se guardó correctamente la información!", "success");
          } else {
            Swal.fire("Error", "Ocurrio un error al guardar", "error");
          }
          setLoading(false);
        }
      })
      .catch((error) => Swal.fire("Error", "Faltan parametros por completar", "error"));
  };

  const handleData = useCallback(async () => {
    try {
      const respuesta: PropsRespuesta = await fetch(url_interna + "api/productos/lab-bode", optionsGET).then((data) =>
        data.json()
      );
      resetState();
      if (respuesta.msg) {
        let lab = JSON.parse(respuesta.data[0].laboratorios);
        lab.unshift({ value: 0, label: "TODOS" });
        setLaboratorios(lab);
        await getDataTable(-1, form.getFieldsValue().dias, form.getFieldsValue().cod_laboratorio);
        setSelectLaboratorio(form.getFieldsValue().cod_laboratorio);
      } else {
        Swal.fire("Error", "Ocurrio un error en la información", "error");
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [getDataTable, form]);

  const convertToJsonString = (value: string) => {
    try {
      if (typeof value === "string") {
        return value;
      } else {
        return JSON.stringify(value);
      }
    } catch (error) {
      return value;
    }
  };

  const exportToExcel = (tableData: any[], fileName: string) => {
    const worksheet = XLSX.utils.json_to_sheet(
      tableData.map((row: any) => {
        return Object.keys(row).reduce((acc: any, key: any) => {
          acc[key] = convertToJsonString(row[key]);
          return acc;
        }, {});
      })
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  useEffect(() => {
    handleData();
  }, [handleData]);

  useEffect(() => {
    setOpenKeys(["Productos", "Productos"]);
    setSelectedKeys(["Productos/Sobre stock"]);
  }, [setOpenKeys, setSelectedKeys]);

  return {
    exportToExcel,
    tipos,
    laboratorios,
    selectTipo,
    selectLaboratorio,
    form,
    columnTable,
    changeLaboratorio,
    changeDias,
    changeTipo,
    columnsProducto,
    columnsBodega,
    columnsProductoDetails,
    expandedData,
    sendInfo,
    setInnerSelectedRowKeys,
    innerSelectedRowKeys,
    handleInnerTableSelectChange,
    expandedRowKeys,
    setExpandedRowKeys,
    handleExpandChange,
    innerSelectedRowKeysMain,
    setInnerSelectedRowKeysMain,
    handleTableSelectChangeMain,
    selectedRowMain,
    loadingCheckboxes,
    loading,
    handleDropdownVisibleChange,
  };
};
