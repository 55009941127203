import { Form, type FormProps, Modal, Input, Switch } from "antd"
import { url } from "config/constants"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSwal } from "hooks/otros/useSwal"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { useEffect } from "react"

import { type PerfilesType } from "hooks/configuraciones/usePerfiles"

type ModalMenuProps = {
  open: boolean
  onCancel(): void
  data?: PerfilesType
  onOk(): void
}

type ValuesFormPerfiles = {
  perfil: string
  estado: boolean
}

export function ModalPerfiles({ open, onCancel, data, onOk }: ModalMenuProps) {
  const [form] = Form.useForm<ValuesFormPerfiles>()

  const { isError } = useVerifyResponse()

  const { swalSuccess, swalError } = useSwal()

  const { optionsPost } = useLocalStorage()

  useEffect(() => {
    if (!data) return
    const { nombre, estado } = data
    form.setFieldsValue({
      perfil: nombre,
      estado,
    })
  }, [data, form])

  const handleOkModal = async () => {
    try {
      await form.validateFields()
      form.submit()
    } catch (e) {
      console.error(e)
      // swalError({ text: "No se pudo guardar los datos del menú" })
    }
  }

  const handleFinishForm: FormProps<ValuesFormPerfiles>["onFinish"] = async values => {
    try {
      const ruta = data ? "editar" : "crear"
      const res = await fetch(`${url}configuraciones/${ruta}-perfil`, optionsPost({ ...values, id: data?.key }))
      if (isError(res)) throw new Error()
      const text = await res.json()
      swalSuccess({ text })
      onOk()
    } catch (e) {
      swalError({ text: "No se pudo crear el perfil" })
    } finally {
      form.resetFields()
      onCancel()
    }
  }

  return (
    <Modal
      open={open}
      transitionName=""
      title={data ? "Crear perfil" : "Editar perfil"}
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
      okText="Guardar"
      onOk={handleOkModal}
      style={{ top: "10px" }}
      width={750}
    >
      <Form
        form={form}
        onFinish={handleFinishForm}
        requiredMark="optional"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        className="scrollableDiv"
      >
        <Form.Item name="perfil" label="Perfil" rules={[{ required: true, message: "Ingrese un nombre para el perfil" }]}>
          <Input maxLength={50} />
        </Form.Item>
        <Form.Item name="estado" label="Estado" valuePropName="checked">
          <Switch defaultChecked disabled={!data} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
