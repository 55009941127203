import { useAuth } from "hooks/otros/useAuth"

import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom"
// import {Space, Spin } from 'antd'
import { useContext, useEffect, useState } from "react"
import { UserContext } from "context/UserContext"
import { Loading } from "components/otros/Loading"
// import { message } from "antd"
import { verifyAccess } from "config/methods"
import { Unauthorized } from "pages/unauthorized"
// import type { menuSistema } from 'types'

export function ProtectedRoute() {
  const { obtenerDatosUsuario } = useAuth()
  // const { data: dataUser, error } = useSWR('/datosUsuario', obtenerDatosUsuario, { revalidateIfStale: false })

  const { userData, setUserData } = useContext(UserContext)
  const [tieneAcceso, setTieneAcceso] = useState(true)

  const location = useLocation()

  const navigate = useNavigate()

  // const matchPath = useMatch("/reembolsos/:estado")

  useEffect(() => {
    // console.log(matchPath)
    // const matchRoute = matchPath("/crear-reembolso", location.pathname)
    // console.log(matchRoute)
    if (userData) return
    const token = localStorage.getItem("token")
    if (!token) {
      navigate("/login")
      return
    }
    ;(async () => {
      
      const data = await obtenerDatosUsuario()
      data && setUserData(data)
    })()
  }, [obtenerDatosUsuario, setUserData, userData, navigate])

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (!userData && token) return

    if (location.pathname === "/") {
      setTieneAcceso(true)
      return
    }

    if (verifyAccess(userData?.menu ?? [], location.pathname)) {
      setTieneAcceso(true)
      return
    }
    setTieneAcceso(false)
    // message.error("No tiene permisos para acceder a esta página")
    // navigate("/")
  }, [location, userData, navigate])

  if (!tieneAcceso) return <Unauthorized />

  if (!userData) {
    const token = localStorage.getItem("token")
    if (!token) return <Navigate to="/login" />

    return <Loading />
  }

  // if(error) {
  //   localStorage.removeItem('token')
  //   return <Navigate to={ '/login' } />
  // }

  // if(dataUser?.tieneAcceso === -1) return <Navigate to={ '/login' } />

  return <Outlet />
}
