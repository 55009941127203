import { Button, type ButtonProps } from "antd"
import { FileExcelFilled, SaveOutlined, ReloadOutlined, DeleteOutlined } from "@ant-design/icons"
import { MdOutlineAddCircle } from "react-icons/md"
import { ImSortNumericAsc } from "react-icons/im"
import { FaFilePdf } from "react-icons/fa"

type Props = {
  buttonProps: ButtonProps
  children?: string | JSX.Element | JSX.Element[]
}

export const ButtonExcel = ({ buttonProps, children }: Props) => (
  <Button
    type="primary"
    block
    style={{ backgroundColor: "#00B050", borderColor: "#00B050" }}
    icon={<FileExcelFilled className="iconbutton" />}
    {...buttonProps}
  >
    {children ?? "Descargar"}
  </Button>
)

export const ButtonAdd = ({ buttonProps, children }: Props) => (
  <Button type="primary" block icon={<MdOutlineAddCircle className="iconbutton" />} {...buttonProps}>
    {children ?? "Añadir"}
  </Button>
)

export const ButtonRemove = ({ buttonProps, children }: Props) => (
  <Button block icon={<DeleteOutlined className="iconbutton" />} {...buttonProps}>
    {children}
  </Button>
)

export const ButtonSave = ({ buttonProps, children }: Props) => (
  <Button type="primary" block icon={<SaveOutlined className="iconbutton" />} {...buttonProps}>
    {children ?? "Guardar"}
  </Button>
)

export const ButtonChangeOrder = ({ buttonProps, children }: Props) => (
  <Button type="default" block icon={<ImSortNumericAsc className="iconbutton" />} {...buttonProps}>
    {children ?? "Modificar orden"}
  </Button>
)

export const ButtonPDF = ({ buttonProps, children }: Props) => (
  <Button type="primary" block icon={<FaFilePdf className="iconbutton" />} {...buttonProps}>
    {children ?? "Imprimir"}
  </Button>
)

export const ButtonReload = ({ buttonProps, children }: Props) => (
  <Button block type="default" icon={<ReloadOutlined className="iconbutton" />} {...buttonProps}>
    {children ?? "Actualizar"}
  </Button>
)
