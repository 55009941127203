import { Button, Popconfirm } from "antd"

import { useScreenSize } from "hooks/otros/useScreenSize"
// import { useSwal } from "hooks/otros/useSwal"

import type { ButtonProps, PopconfirmProps } from "antd"
import type { SweetAlertOptions } from "sweetalert2"
import { swalQuestion } from "config/methods"

interface Props {
  buttonProps: ButtonProps
  popConfirmProps: PopconfirmProps
  swalProps: SweetAlertOptions
  accion: () => void
  children?: string
}

export function ButtonWithQuestion({ buttonProps, popConfirmProps, swalProps, accion, children }: Props) {
  // const { swalQuestion } = useSwal()

  const { width } = useScreenSize()

  const isWeb = width >= 778

  return isWeb ? (
    <Popconfirm {...popConfirmProps} onConfirm={accion}>
      <Button {...buttonProps}>{children}</Button>
    </Popconfirm>
  ) : (
    <Button {...buttonProps} onClick={() => swalQuestion(swalProps).then(result => result.isConfirmed && accion())}>
      {children}
    </Button>
  )
}
