import { Button } from "antd";
import { FilePdfFilled } from "@ant-design/icons";

export const ButtonUpload = () => {
  return (
    <Button
      style={{ width: "99%" }}
      icon={
        <FilePdfFilled
          style={{
            cursor: "pointer",
            fontSize: "15px",
            color: "#B22222",
          }}
        />
      }
    >
      Seleccionar archivo
    </Button>
  );
};
