import { Modal, ModalProps } from "antd"

interface PropsModal {
  visible: boolean
  onCancel: ModalProps["onCancel"]
  src: string
  title?: ModalProps["title"]
}

export const PdfViewer = ({ visible, onCancel, src, title }: PropsModal) => {
  return (
    <>
      <Modal
        open={visible}
        title={title}
        onCancel={onCancel}
        footer={null}
        width="85%"
        transitionName=""
        style={{
          top: 10,
          position: "relative",
        }}
      >
        <embed
          src={src}
          type="application/pdf"
          width="100%"
          style={{ paddingTop: title ? "0" : "20px", height: title ? "75vh" : "80vh" }}
        />
      </Modal>
    </>
  )
}
