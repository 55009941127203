import { Button, Form } from "antd";
import { ColumnsType } from "antd/lib/table";
import { optionsPOST, url_interna } from "config/constants";
import { swalError, swalWarning } from "config/methods";
import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useExport } from "hooks/otros/useExport";
import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
import { ColumnProps } from "antd/es/table";
import { EyeFilled } from "@ant-design/icons";
import { useSearchTableNew } from "hooks/otros/useSearchTableNew";

const breadcrumbs = [{ Label: "Facturas Multiples" }];

interface PropsTableReport {
  key: number;
  bodega: string;
  num_clientes: number;
  num_facturas: number;
}

interface PropsTableReportDetails {
  key: number;
  cliente: string;
  ruc_cliente: string;
  dependiente: string;
  num_clientes: number;
  num_facturas: number;
}

export function useFacturasMultiples() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);

  const [form] = Form.useForm();
  const [dataFiltrada, setDataFiltrada] = useState<PropsTableReport[]>([]);
  const [dataFiltradaDetails, setDataFiltradaDetails] = useState<PropsTableReportDetails[]>([]);
  const { exportToExcel } = useExport();

  const [loader, setLoader] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [bodegaSelect, setBodegaSelect] = useState<string>("");
  const [bodegaId, setBodegaId] = useState<number>();
  const [loaderDetails, setLoaderDetails] = useState<boolean>(false);
  const [loaderDetailsFile, setLoaderDetailsFile] = useState<boolean>(false);
  const { getColumnSearchPropsNew } = useSearchTableNew<PropsTableReport>();

  const { getColumnSearchPropsNew: getColumnSearchPropsNew2 } = useSearchTableNew<PropsTableReportDetails>();

  /** */
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > moment().endOf("day").subtract(1, "days");
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  useBreadCrumbs(breadcrumbs);

  const renderActionsHistory: ColumnProps<any>["render"] = (_, record) => (
    <Button
      icon={<EyeFilled />}
      type='ghost'
      style={{ border: "none", color: "rgb(106 40 49)" }}
      onClick={() => handleClickActionEdit(record.key, record.bodega)}
    />
  );

  const handleClickActionEdit = async (cod_bodega: number, bodega: string) => {
    try {
      setLoaderDetails(true);
      setOpenModal(true);
      setBodegaSelect(bodega);
      setDataFiltradaDetails([]);
      setBodegaId(cod_bodega);

      let values = { fecha: form.getFieldsValue().fecha, cod_bodega, facturas: form.getFieldsValue().facturas };
      const respuesta = await fetch(
        `${url_interna}api/inventarios/facturas_multiples_detalles`,
        optionsPOST(JSON.stringify({ values }), {
          "api-token": "apitoken",
        })
      ).then((data) => data.json());

      if (!respuesta.msg) {
        Swal.fire("Error", "No existe información!", "error");
        setLoader(false);
        setLoaderDetails(false);
        return;
      }
      setLoaderDetails(false);
      setDataFiltradaDetails(respuesta.data);
    } catch (error) {
      swalWarning({ text: "Ocurrio un error" });
    }
  };

  const headTableReport: ColumnsType<PropsTableReport> = [
    {
      title: "Bodega",
      dataIndex: "bodega",
      key: "bodega",
      ...getColumnSearchPropsNew("bodega"),
    },
    {
      title: "#Clientes",
      dataIndex: "num_clientes",
      key: "num_clientes",
      sorter: (a, b) => a.num_clientes - b.num_clientes,
      align: "right",
    },
    {
      title: "#Facturas",
      dataIndex: "num_facturas",
      key: "num_facturas",
      sorter: (a, b) => a.num_facturas - b.num_facturas,
      align: "right",
    },
    {
      title: "Ver",
      align: "center",
      render: renderActionsHistory,
    },
  ];

  const headTableReportDetails: ColumnsType<PropsTableReportDetails> = [
    {
      title: "Cliente",
      dataIndex: "cliente",
      key: "cliente",
      ...getColumnSearchPropsNew2("cliente"),
    },
    {
      title: "Ruc Cliente",
      dataIndex: "ruc_cliente",
      key: "ruc_cliente",
      ...getColumnSearchPropsNew2("ruc_cliente"),
    },

    {
      title: "Dependiente",
      dataIndex: "dependiente",
      key: "dependiente",
      ...getColumnSearchPropsNew2("dependiente"),
    },
    {
      title: "#Facturas",
      dataIndex: "num_facturas",
      key: "num_facturas",
      sorter: (a, b) => a.num_facturas - b.num_facturas,
      align: "right",
    },
  ];

  const handleClickGenerar = () => {
    setLoader(true);
    form
      .validateFields()
      .then(async (values) => {
        setLoader(true);
        setDataFiltrada([]);

        const respuesta = await fetch(
          `${url_interna}api/inventarios/facturas_multiples`,
          optionsPOST(JSON.stringify({ values }), {
            "api-token": "apitoken",
          })
        ).then((data) => data.json());
        if (!respuesta.msg) {
          Swal.fire("Error", "No existe información!", "error");
          setLoader(false);
          return;
        }
        setDataFiltrada(respuesta.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        Swal.fire("Error", "Complete los campos requeridos!", "error");
      });
  };

  const handleClickDownload = () => {
    if (!dataFiltradaDetails) return;
    const datosParaExportar = dataFiltradaDetails.map(({ key, ...resto }) => resto);
    exportToExcel(datosParaExportar, ` Facturas multiples_${bodegaSelect}_${moment().format("YYYY-MM-DD_HH-mm-ss")}`);
  };
  const handleClickDownloadDataPrincipal = () => {
    if (!dataFiltrada) return;
    const datosParaExportar = dataFiltrada.map(({ key, ...resto }) => resto);
    exportToExcel(datosParaExportar, ` Facturas multiples data_${moment().format("YYYY-MM-DD_HH-mm-ss")}`);
  };

  const handleClickDownloadDetails = async () => {
    try {
      setLoaderDetailsFile(true);
      let values = { fecha: form.getFieldsValue().fecha, bodegaId, facturas: form.getFieldsValue().facturas };
      const respuesta = await fetch(
        `${url_interna}api/inventarios/facturas_multiples_detalles_2`,
        optionsPOST(JSON.stringify({ values }), {
          "api-token": "apitoken",
        })
      ).then((data) => data.json());

      respuesta.msg
        ? exportToExcel(
            respuesta.data,
            `Facturas multiples_detalles_${bodegaSelect}_${moment().format("YYYY-MM-DD_HH-mm-ss")}`
          )
        : swalError({ text: "Ocurrio un error!" });
      setLoaderDetailsFile(false);
    } catch (error) {
      setLoaderDetailsFile(false);

      swalError({ text: "Ocurrio un error!" });
    }
  };

  useEffect(() => {
    setOpenKeys(["Inventarios", "Facturas Multiples"]);
    setSelectedKeys(["Inventarios/Facturas Multiples"]);
  }, [setOpenKeys, setSelectedKeys]);

  return {
    disabledDate,
    handleClickGenerar,
    headTableReport,
    form,
    handleClickDownload,
    dataFiltrada,
    loader,
    closeModal,
    openModal,
    headTableReportDetails,
    dataFiltradaDetails,
    handleClickDownloadDetails,
    bodegaSelect,
    loaderDetails,
    loaderDetailsFile,
    handleClickDownloadDataPrincipal,
  };
}
