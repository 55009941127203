import { Alert, Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Table} from "antd"
import { useLayout } from "hooks/otros/useLayout"
import useRegistro from "hooks/sistemas/useRegistro"
import { useEffect} from "react"
import { Outlet } from "react-router-dom"
import { llave } from "config/constants"
import AES from "crypto-js/aes"

const breadcrumbs = [{ Label: "Sistemas", Url: "/sistemas" }, { Label: "Registro Vouchers" }]

const openKeys = [breadcrumbs[0].Label]

export function RegistroVouchers() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label)

/*   const bodegas = useLoaderData() as DefaultOptionType[]
  const params = useParams()
 */
  //const navigate = useNavigate()

    const{ desde,
      bodega, setBodega,
      valor, setValor,
      bodegas,
      onchagedate, params,
      navigate}=useRegistro()

  useEffect(() => {
    params.desde && !bodega && navigate(`/sistemas/registro-vouchers/${desde.format("YYYY-MM-DD")}/0/0`)
  }, [params.desde, navigate, desde, bodega])

  useEffect(() => {
    const encBodega = bodega ? encodeURIComponent(AES.encrypt(bodega.toString(), llave).toString()) : 0
    navigate(`/sistemas/registro-vouchers/${desde.format("YYYY-MM-DD")}/${valor ?? 0}/${encBodega}`)
    //params.desde && !bodega && navigate(`/sistemas/registro-vouchers/${desde.format("YYYY-MM-DD")}/0/0`)
  }, [desde, bodega, valor, navigate])
 
  const handleFilter = (input:any, option:any) => {
    return option.label.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form>
      <Row wrap style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
          <Form.Item label="Desde">
            <DatePicker
              format="DD/MM/YYYY"
              allowClear={false}
              value={desde}
              onChange={onchagedate}
              showToday={false}
            />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
          <Form.Item label="Valor">
            <InputNumber step={0.01} value={valor} onChange={value => setValor(value)} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label="Bodega">
            <Select showSearch options={bodegas ?? []} onChange={value => setBodega(value)} 
            filterOption={handleFilter}
              />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Outlet />
        </Col>
      </Row>
    </Form>
  )
}


export function TablaRegistro() {
 
  const{
    columnsVouchers,
    vouchers,
    openModal,
    setOpenModal,
    valueLote,
    valueNumAprobacion,
    valueReferencia,
    disabled,
    handleInputChange,
    setvalueLote,
    setvalueNumAprobacion,
    setvalueReferencia,
    MostrarAlert,
    message,
    btn_Guardar,
    valueFarmacia,
    valueFecha,
    valueValor,
    valueEstado,
    disabledEstado,
    onchangeEstado,
    disabledButton
  }=useRegistro()

  return (
    <>
      <Table
        columns={columnsVouchers}
        dataSource={vouchers}
        scroll={{ x: true }}
        size="small"
        pagination={{ size: "default" }}
      />
  {    <Modal open={openModal}
        onCancel={() => setOpenModal(false)} 
        title="Registro de Voucher"
        footer={[
          <Button key="back" onClick={() => setOpenModal(false)}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" disabled={disabledButton}  onClick={btn_Guardar} /*  loading={loading} */>
            Guardar
          </Button>
        ]}
        >
          {MostrarAlert&&<Alert message={message} type="warning" showIcon closable></Alert>}
          <Row>
            <Col sm={12} xs={12} md={12} style={{paddingRight:'3px'}}>
              <Row>Fecha</Row>
              <Row><Input value={valueFecha} style={{background:'white', color:'#808B96'}}  disabled/></Row>
            </Col>
            <Col sm={12} xs={12} md={12}>
              <Row>Valor</Row>
              <Row><Input value={valueValor} style={{background:'#FDEDEC', borderColor:'#E6B0AA', textAlign:'end', color:'#808B96'}} disabled/></Row>
            </Col>
          </Row>
          <Row>
          <Col sm={24} xs={24} md={12} style={{paddingRight:'3px'}}>
              <Row>Bodega</Row>
              <Row><Input value={ valueFarmacia} style={{background:'white', color:'#808B96'}} disabled/></Row>
            </Col>
            <Col sm={24} xs={24} md={12}>
              <Row>Estado</Row>
              <Row><Select value={ valueEstado} onChange={onchangeEstado} options={[{ label: "Pendiente", value: "PE", disabled:true},
                  { label: "Proceso parcial", value: "PP" },
                  { label: "Procesado", value: "PR",disabled:true },
                  { label: "Anulado", value: "AN" },
                  { label: "Rechazado", value: "RE", disabled:true },
                  { label: "Cambiado", value: "CM", disabled:true },
              ] } style={{background:'white', width:'100%'}} disabled={disabledEstado}/></Row>
            </Col>
          </Row>
          <Row>Lote</Row>
          <Row><Input value={valueLote}  maxLength={10} style={{textAlign:'right'}} onChange={(e)=>handleInputChange(e, setvalueLote)} disabled={disabled}/></Row>
          <Row style={{marginTop:'15px'}} >Referencia</Row>
          <Row><Input value={valueReferencia} maxLength={10} style={{textAlign:'right'}} onChange={(e)=>handleInputChange(e, setvalueReferencia)} disabled={disabled}/></Row>
          <Row style={{marginTop:'15px'}}>Número de aprobación</Row>
          <Row><Input value={valueNumAprobacion} maxLength={10} style={{textAlign:'right'}} onChange={(e)=>handleInputChange(e, setvalueNumAprobacion)} disabled={disabled}/></Row>
      </Modal>}
{     /*  <Modal transitionName="" open={openModal} onCancel={() => setOpenModal(false)} title="Registro de voucher">
        <Form layout="vertical" form={form}>
          <Form.Item name="lote" label="Lote">
            <Input />
          </Form.Item>
          <Form.Item name="referencia" label="Referencia">
            <Input />
          </Form.Item>
          <Form.Item name="numero_aprobacion" label="Número de aprobación">
            <Input />
          </Form.Item>
        </Form>
      </Modal> */}
    </>
  )
}
