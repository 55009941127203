import { Collapse, Input, InputNumber, Statistic } from "antd"
import { type DefaultOptionType } from "antd/lib/select"
import { ModalListSelect } from "components/otros/ModalListSelect"
import { url } from "config/constants"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSwal } from "hooks/otros/useSwal"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { useState } from "react"
import { type Bonos, CalculosFinales } from "./calculos-finales"
import { type ColumnsTableFormListType, TableFormList } from "components/otros/table-form-list"
import { AddButtons } from "./add-buttons"

import { useContext } from "react"

import { FormPagoContext } from "context/franquiciados/form-pago-context"
import {
  DescriptionsListformItems,
  type DescriptionsListformItemsProps,
} from "components/otros/descriptions-list-form-items"

type Props = {
  loading: boolean
  onRemove(): void
  onChangeValue(): void
}

export function ObtenerBonos({ loading, onRemove, onChangeValue }: Props) {
  const { form } = useContext(FormPagoContext)

  const [bonos, setBonos] = useState<DefaultOptionType[]>()
  const [openModal, setOpenModal] = useState(false)

  const { optionsPost } = useLocalStorage()
  const { isError } = useVerifyResponse()
  const { swalError } = useSwal()

  const fetchBonosForSelect = async () => {
    try {
      const body = form.getFieldValue("bonos")
        ? { bonos: [...(form.getFieldValue("bonos") as CalculosFinales["bonos"]).map(({ cod_bono }) => cod_bono)] }
        : { bonos: [] }
      // console.log(body);
      const result = await fetch(`${url}fapifsg-pr/franquiciados/listar-bonos-para-select`, optionsPost(body))
      if (isError(result)) throw new Error()
      const res = await result.json()
      setBonos(res)
    } catch (e) {
      swalError({ text: "No se pudo obtener los bonos" })
    }
  }

  const handleChooseBono = async (value: string | number) => {
    try {
      const res = await fetch(`${url}fapifsg-pr/franquiciados/obtener-bono`, optionsPost({ cod_bono: value }))
      if (isError(res)) throw new Error()

      const response = await res.json()

      form.setFieldValue("bonos", [...form.getFieldValue("bonos"), { ...response }])

      setOpenModal(false)
    } catch (e) {
      swalError({ text: "No se pudo obtener los datos del bono" })
    }
  }

  const handleAddBonoInBlank = () => {
    form.setFieldValue("bonos", [...form.getFieldValue("bonos"), { cod_bono: 0, bono: undefined, valor: undefined }])
    // console.log(form.getFieldsValue());
  }

  const columnBonos: ColumnsTableFormListType<Bonos>[] = [
    {
      title: "Descripción",
      name: "bono",
      rules: [{ required: true, message: "Ingrese el nombre del bono" }],
      render: index =>
        form.getFieldValue(["bonos", index, "cod_bono"]) === 0 ? (
          <Input.TextArea autoSize size="small" />
        ) : (
          <Statistic valueStyle={{ fontSize: "inherit" }} />
        ),
    },
    {
      title: "Valor",
      name: "valor",
      rules: [{ required: true, message: "Ingrese el valor del bono" }],
      render: () => (
        // form.getFieldValue(["bonos", index, "cod_bono"]) === 0 ? (
        <InputNumber
          onChange={() => {
            onChangeValue()
          }}
          min={0}
          step={0.01}
          size="small"
          controls={false}
          placeholder="0.00"
        />
      ),
      // ) : (
      //   <Statistic valueStyle={{ fontSize: "inherit" }} precision={2} />
      // ),
    },
  ]

  const itemsTotales: DescriptionsListformItemsProps["items"] = [
    {
      label: "Total",
      itemName: "total_bonos",
      render: () => <Statistic valueStyle={{ fontSize: "inherit", textAlign: "right", fontWeight: "bold" }} precision={2} />,
    },
  ]

  return (
    <>
      <Collapse defaultActiveKey={["1"]} style={{ marginBottom: "16px", padding: 0 }}>
        <Collapse.Panel key={"1"} header="Valores adicionales">
          <TableFormList
            columns={columnBonos}
            nameList="bonos"
            loading={loading}
            canRemove
            onRemove={onRemove}
            questionRemove="¿Está seguro de quitar el valor adicional?"
          />
          <DescriptionsListformItems items={itemsTotales} loading={loading} />
          <AddButtons
            handleAdd={async () => {
              await fetchBonosForSelect()
              setOpenModal(true)
            }}
            handleAddInBlank={handleAddBonoInBlank}
          />
        </Collapse.Panel>
      </Collapse>
      <ModalListSelect
        modalProps={{ open: openModal, title: "Bonos", onCancel: () => setOpenModal(false) }}
        onChange={handleChooseBono}
        dataSource={bonos ?? []}
      />
    </>
  )
}
