import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  Row,
  Select,
  Switch,
  Image,
  DatePicker,
  Tree,
} from "antd";
import { useEffect, useState } from "react";
import { useScreenSize } from "hooks/otros/useScreenSize";

import { url } from "config/constants";

import type { FormInstance, FormProps, ModalProps } from "antd";
import type { Moment } from "moment";
import type { dataLlamadosAtencion } from "hooks/rrhh/llamadosatencion/useDataForTable";
import type { DefaultOptionType } from "antd/lib/select";
import { useSwal } from "hooks/otros/useSwal";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import { DataNode } from "antd/lib/tree";

const { TextArea } = Input;

export interface valuesFormLlamadosAtencion {
  bodega: string;
  departamento: string;
  multa: boolean;
  porcentaje: number;
  reglamentos: Array<number>;
  responsable: string;
  fecha_falta: string;
  fecha_creacion: string;
  observacion: string;
  periodo_actividad: Moment;
  tipo_actividad: string;
  motivo: string;
  descripcion: string;
}

interface Props {
  openModal: boolean;
  handleCloseModal: () => void;
  form?: FormInstance<valuesFormLlamadosAtencion>;
  handleFinishForm?: FormProps["onFinish"];
  llamadosAtencion: dataLlamadosAtencion[];
  selectedIndex: number;
  disabledPorcentajeMulta?: boolean;
  setDisabledPorcentajeMulta?: React.Dispatch<React.SetStateAction<boolean>>;
  modalFooter?: ModalProps["footer"][];
  titleModal: string;
  disabledAll?: boolean;
  showEmpleados?: boolean;
  showDataLlamados?: boolean;
  textFirstButton?: string;
}

interface responseSelect {
  msg: "ok" | "error" | "err";
  data: DefaultOptionType[];
}

export function FormInModal({
  openModal,
  handleCloseModal,
  form,
  handleFinishForm,
  llamadosAtencion,
  selectedIndex,
  disabledPorcentajeMulta,
  setDisabledPorcentajeMulta,
  modalFooter = [],
  titleModal,
  disabledAll = false,
  showEmpleados = true,
  showDataLlamados = true,
  textFirstButton = "Cancelar",
}: Props) {
  const [reglamentos, setReglamentos] = useState<Array<DefaultOptionType>>([]);
  const { swalError } = useSwal();

  const { optionsGet } = useLocalStorage();

  const { wasInvalidToken, hadError } = useVerifyResponse();

  const { width } = useScreenSize();

  const isWeb = width >= 778;

  useEffect(() => {
    const fetcher = async () => {
      try {
        const res = await fetch(`${url}apifsg-pr/recursos-humanos/listar-reglamento`, optionsGet);

        const response: responseSelect = await res.json();

        if (wasInvalidToken(response)) return;
        if (hadError(response, "No se pudo obtener el reglamento interno")) return;

        const { data } = response;

        setReglamentos(data);
      } catch (e) {
        console.error(e);
        swalError({ text: "No se pudo obtener el reglamento interno" });
      }
    };
    fetcher();
  }, [optionsGet, wasInvalidToken, hadError, swalError]);

  return (
    <Modal
      // { ...modalProps }
      onCancel={handleCloseModal}
      title={titleModal}
      open={openModal}
      transitionName=''
      width={800}
      style={{ top: 64 }}
      footer={[
        <Button key='cancel' onClick={handleCloseModal}>
          {textFirstButton}
        </Button>,
        ...modalFooter,
      ]}
    >
      <Form
        layout='vertical'
        form={form}
        requiredMark={disabledAll ? undefined : "optional"}
        style={isWeb ? { padding: "16px" } : { padding: 0 }}
        onFinish={handleFinishForm}
      >
        <Row
          wrap
          style={{ padding: "10px", marginRight: 0, marginLeft: 0, backgroundColor: "#fff" }}
          gutter={[16, 16]}
        >
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label='Departamento' name='departamento' required={!disabledAll}>
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label='Bodega' name='bodega' required={!disabledAll}>
              <Input readOnly />
            </Form.Item>
          </Col>
          {showEmpleados && (
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label='Empleados' requiredMark={undefined}>
                <List
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontWeight: "bold",
                      }}
                    >
                      {isWeb ? <div>Cédula</div> : undefined}
                      <div>Nombres</div>
                    </div>
                  }
                  dataSource={llamadosAtencion[selectedIndex]?.empleados}
                  renderItem={({ cedula, nombres }, index) => (
                    <List.Item key={index}>
                      {/* <List.Item.Meta description={ cedula } /> */}
                      {isWeb ? <div>{cedula}</div> : undefined}
                      <div>{nombres}</div>
                    </List.Item>
                  )}
                />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item label='Responsables del proceso' requiredMark={undefined}>
              <List
                header={
                  <div
                    style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold" }}
                  >
                    {isWeb ? <div>Cédula</div> : undefined}
                    <div>Nombres</div>
                  </div>
                }
                dataSource={llamadosAtencion[selectedIndex]?.responsables}
                renderItem={({ cedula, nombres }, index) => (
                  <List.Item key={index}>
                    {isWeb ? <div>{cedula}</div> : undefined}
                    <div>{nombres}</div>
                  </List.Item>
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {disabledAll ? (
              <Form.Item label='Reglamentos' name='reglamentos'>
                <Tree
                  treeData={llamadosAtencion[selectedIndex]?.reglamentos as DataNode[]}
                  defaultExpandAll={true}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label='Reglamentos'
                name='reglamentos'
                rules={[{ required: true, message: "Escoja los reglamentos" }]}
              >
                <Select
                  options={reglamentos}
                  mode='multiple'
                  optionFilterProp='label'
                  showArrow
                  getPopupContainer={() =>
                    document.getElementsByClassName("ant-modal-body")[0] as HTMLElement
                  }
                />
              </Form.Item>
            )}
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Form.Item label='Fecha de solicitud' name='fecha_creacion' required={!disabledAll}>
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={7} lg={7} xl={7}>
            <Form.Item label='Fecha de la falta' name='fecha_falta' required={!disabledAll}>
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3} xl={3}>
            <Form.Item label='Multa' name='multa' valuePropName='checked' required={!disabledAll}>
              <Switch
                disabled={disabledAll}
                onChange={
                  // disabledAll
                    // ? 
                    (checked) => {
                        setDisabledPorcentajeMulta && setDisabledPorcentajeMulta(!checked);
                        form && form.setFieldValue("porcentaje", checked ? 10 : 0);
                      }
                    // : undefined
                }
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Form.Item label='Porcentaje de multa' name='porcentaje' required={!disabledAll}>
              <InputNumber
                min={0}
                max={100}
                step={5}
                disabled={disabledPorcentajeMulta || disabledAll}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item label='Descripción de la falta' name='observacion' required={!disabledAll}>
              <TextArea
                autoSize={{ minRows: 5, maxRows: 20 }}
                maxLength={5000}
                showCount
                readOnly={disabledAll}
              />
            </Form.Item>
          </Col>
          {showDataLlamados && (
            <>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label='Motivo'
                  name='motivo'
                  required={!disabledAll}
                  initialValue='CONTRAVENCION A LAS NORMAS Y DISPOSICICONES DEL REGLAMENTO INTERNO DE TRABAJO EN GENERAL.'
                >
                  <TextArea
                    autoSize={{ minRows: 2, maxRows: 4 }}
                    maxLength={200}
                    showCount
                    readOnly={disabledAll}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label='Periodo de actividad'
                  name='periodo_actividad'
                  required={!disabledAll}
                >
                  <DatePicker picker='month' format='YYYYMM' disabled={disabledAll} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label='Tipo de actividad' name='tipo_actividad' required={!disabledAll}>
                  <TextArea
                    autoSize={{ minRows: 2, maxRows: 2 }}
                    maxLength={200}
                    showCount
                    readOnly={disabledAll}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label='Descripción corta' name='descripcion' required={!disabledAll}>
                  <TextArea
                    autoSize={{ minRows: 2, maxRows: 4 }}
                    maxLength={250}
                    showCount
                    readOnly={disabledAll}
                  />
                </Form.Item>
              </Col>
            </>
          )}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {llamadosAtencion[selectedIndex]?.evidencias?.map(({ llave_imagen }, index) => (
              <Image
                src={`${url}apiallku-public/api/${llave_imagen}`}
                preview={false}
                key={index}
              />
            ))}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
