import { Table, TableColumnsType } from "antd"
import { useSearchTable } from "hooks/otros/useSearchTable"

import { MinusCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { ButtonWithQuestion } from "components/otros/ButtonWithQuestion"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { useSwal } from "hooks/otros/useSwal"
import { url } from "config/constants"

export type Perfiles = {
  key: number
  perfil: string
}

type Props = {
  perfiles: Perfiles[]
  afterDelete(): void
  usuario: number
}

export function SubTableUsuarioPerfil({ perfiles, afterDelete, usuario }: Props) {
  const { getColumnSearchProps } = useSearchTable<Perfiles>()
  const { optionsPost } = useLocalStorage()

  const { isError } = useVerifyResponse()

  const { swalError, swalSuccess } = useSwal()

  const eliminarPerfilUsuario = async (perfil: number) => {
    try {
      const res = await fetch(`${url}configuraciones/desasignar-perfil-usuario`, optionsPost({ perfil, usuario }))
      if (isError(res)) throw new Error()

      const text = await res.json()

      swalSuccess({ text })
    } catch (e) {
      swalError({ text: "No se pudo obtener los perfiles para el usuario" })
    }
  }

  const columns: TableColumnsType<Perfiles> = [
    {
      title: "Perfil",
      dataIndex: "perfil",
      key: "perfil",
      ...getColumnSearchProps("perfil"),
    },
    {
      title: "",
      dataIndex: "key",
      key: "key",
      fixed: "right",
      align: "center",
      width: 100,
      render: (id: number) => (
        <ButtonWithQuestion
          buttonProps={{
            type: "text",
            block: true,
            icon: <MinusCircleOutlined style={{ fontSize: "1.3rem" }} />,
          }}
          popConfirmProps={{
            title: "¿Desasignar perfil?",
            icon: <QuestionCircleOutlined style={{ color: "var(--primary)" }} />,
            okText: "Si",
            cancelText: "No",
            placement: "right",
          }}
          swalProps={{ title: "¿Desasignar perfil?", text: "El usuario ya no será parte del perfil" }}
          accion={async () => {
            await eliminarPerfilUsuario(id)
            afterDelete()
          }}
        />
      ),
    },
  ]

  return <Table columns={columns} dataSource={perfiles} size="small" pagination={false} scroll={{ x: true }} />
}
