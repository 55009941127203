import { Row, Empty, Typography, Modal, Form, Col, Input, Select, Divider, Space, Button, Collapse, Checkbox, DatePicker } from "antd";
import { ColumCard, TypeCard } from "../../../components/otros/ColumCard";
import { useAdminTareasSherlock } from "hooks/marketing/useAdministracionTareas";
import { PlusOutlined, FileImageOutlined, SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";
import "./stilos.css";
const { Text, Paragraph } = Typography;
const { Panel } = Collapse;

export function AdministracionTareas() {
  const {
    filteredData,
    estadoBoton,
    actions,
    form,
    showModal,
    isModalOpen,
    handleOk,
    handleCancel,
    onChange,
    onOk,
    inputRef,
    onNameChange,
    onNameChangePreguntas,
    addItem,
    disabledDate,
    onCalendarChange,
    tarea,
    onValuesChange,
    handleSeccionChange,
    addItemPregunta,
    addItemCategorias,
    onNameChangeCategorias,
    agregarPregunta,
    setCategoriaSeleccionada,
    categoriaSeleccionada,
    setTipoPreguntaSeleccionada,
    tipoPreguntaSeleccionada,
    setRespuestasSeleccionadas,
    respuestasSeleccionadas,
    preguntaSeleccionada,
    handlePreguntaChange,
    datosCuestionario,
    onNameChangeRespuestas,
    addItemRespuesta,
    handleGuardar
  } = useAdminTareasSherlock();

  return (
    <>
      <Row wrap gutter={[16, 16]} style={{ padding: "0px 16px", marginLeft: 0, marginRight: 0, paddingBottom: "75.59px" }}>
        <ColumCard cardType={TypeCard.New} handleClick={showModal} colProps={{ xs: 24, sm: 12, md: 8, lg: 8, xl: 6 }} />
        {!estadoBoton ? (
          <ColumCard
            cardType={TypeCard.Loading}
            cardProps={{ actions: [] }}
            colProps={{ xs: 24, sm: 12, md: 8, lg: 8, xl: 7 }}
          />
        ) : (
          filteredData.map(({ id_cuestionario, idss, titulo, descripcion, activo, fecha_asignacion, fecha_limite }) => (
            <ColumCard
              cardType={TypeCard.Normal}
              colProps={{ xs: 24, sm: 12, md: 8, lg: 8, xl: 6 }}
              cardProps={{
                actions: actions(id_cuestionario),
                title: (
                  <div style={{ display: "grid", justifyContent: "space-between" }}>
                    <Text>{`Cuestionario # ${id_cuestionario}`}</Text>
                    <Paragraph ellipsis={{ tooltip: titulo }}>{titulo}</Paragraph>
                  </div>
                ),
                size: "small",
                style: { height: "269.19px" },
              }}
              key={"Col" + idss}
              ContentCard={[
                { type: "Text", text: "Fecha inicio:", span: 9 },
                { type: "Paragraph", text: fecha_asignacion.toString(), span: 15 },

                { type: "Text", text: "Fecha fin:", span: 9 },
                { type: "Paragraph", text: fecha_limite.toString(), span: 15 },
                { type: "Text", text: "Descripción:", span: 9 },
                { type: "Paragraph", text: descripcion, span: 15 },
              ]}
              // actions={actions(id_cuestionario)}
            />
          ))
        )}
      </Row>
      {filteredData?.length === 0 && <Empty />}
      <Modal
        footer={true}
        transitionName=""
        title="Crear nueva tarea"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Guardar"}
        width={"90wh"}
      >
        <Form form={form} onValuesChange={onValuesChange}>
          <Row gutter={[16, 16]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-start" }}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Item label="Fecha" name="rangoFecha" style={{ width: "100%" }}>
                <DatePicker.RangePicker
                  style={{ width: "100%" }}
                  showTime={{ format: "HH:mm" }}
                  format="YYYY-MM-DD HH:mm"
                  onChange={onChange}
                  onOk={onOk}
                  disabledDate={disabledDate}
                  onCalendarChange={onCalendarChange}
                />
              </Form.Item>
              <Form.Item label="Título" name="titulo">
                <Input placeholder="Ingrese el nombre del cuestionario" />
              </Form.Item>
              <Form.Item label="Descripción" name="descripcion">
                <Input placeholder="Descripción del cuestionario" />
              </Form.Item>
              <Collapse defaultActiveKey={["1"]} >
                <Panel header="Sección" key="1">
                  <Form.Item name="seccion" style={{ width: "100%" }}>
                    <Select
                      onChange={handleSeccionChange}
                      placeholder="Seleccione la sección"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <Space style={{ padding: "0 8px 4px" }}>
                            <Input
                              style={{ width: "100%" }}
                              placeholder="Añadir nueva sección"
                              ref={inputRef}
                              onChange={onNameChange}
                            />
                            <Button style={{ width: "100%" }} type="text" icon={<PlusOutlined />} onClick={addItem}>
                              Agregar
                            </Button>
                          </Space>
                        </>
                      )}
                      //value={tarea.seccionId} // undefined será tratado como un estado sin selección
                      options={datosCuestionario.secciones.map((item) => ({
                        label: item.nombre_seccion,
                        value: item.id_seccion,
                      }))}
                    />
                  </Form.Item>
                </Panel>
                <Panel header="Preguntas" key="2">
                  <Row gutter={[16, 16]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-start" }}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item label="Categoria" name="categoria">
                        <Select
                          value={categoriaSeleccionada}
                          onChange={(value) => setCategoriaSeleccionada(value)}
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="Selecione una categoria"
                          optionFilterProp="children"
                          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                          options={datosCuestionario.categorias.map((item) => ({ label: item.label, value: item.value }))}
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider style={{ margin: "8px 0" }} />
                              <Space style={{ padding: "0 8px 4px" }}>
                                <Input
                                  style={{ width: "100%" }}
                                  placeholder="Añadir nueva pregunta"
                                  ref={inputRef}
                                  onChange={onNameChangeCategorias}
                                />
                                <Button
                                  style={{ width: "100%" }}
                                  type="text"
                                  icon={<PlusOutlined />}
                                  onClick={addItemCategorias}
                                >
                                  Agregar
                                </Button>
                              </Space>
                            </>
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item label="Tipo pregunta" name="tipoPregunta">
                        <Select
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="Seleccione un tipo"
                          optionFilterProp="children"
                          value={tipoPreguntaSeleccionada}
                          onChange={setTipoPreguntaSeleccionada}
                          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                          options={datosCuestionario.tipos_pregunta.map((item) => ({
                            label: item.label,
                            value: item.value,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {categoriaSeleccionada !== -1 && tipoPreguntaSeleccionada !== -1 ? (
                    <Form.Item name="pregunta" style={{ width: "100%" }}>
                      <Select
                        showSearch
                        onChange={handlePreguntaChange}
                        placeholder="Seleccione una pregunta"
                        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: "8px 0" }} />
                            <Space style={{ padding: "0 8px 4px" }}>
                              <Input
                                style={{ width: "100%" }}
                                placeholder="Añadir nueva pregunta"
                                ref={inputRef}
                                onChange={onNameChangePreguntas}
                              />
                              <Button
                                disabled={categoriaSeleccionada === -1 && tipoPreguntaSeleccionada === -1}
                                style={{ width: "100%" }}
                                type="text"
                                icon={<PlusOutlined />}
                                onClick={addItemPregunta}
                              >
                                Agregar
                              </Button>
                            </Space>
                          </>
                        )}
                        options={datosCuestionario.preguntas
                          .filter((pregunta) => pregunta.tipo_pregunta === tipoPreguntaSeleccionada)
                          .map((pregunta) => ({
                            label: pregunta.nombre_pregunta,
                            value: pregunta.id_pregunta,
                          }))}
                      />
                    </Form.Item>
                  ) : null}
                  {tipoPreguntaSeleccionada === 1 && (
                    <Form.Item label="Seleccione las respuestas" name="respuestas">
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Seleccione respuestas"
                        onChange={setRespuestasSeleccionadas}
                        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        options={datosCuestionario.opciones.map((item) => ({
                          label: item.nombre_opcion,
                          value: item.id_opcion,
                        }))}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: "8px 0" }} />
                            <Space style={{ padding: "0 8px 4px" }}>
                              <Input
                                style={{ width: "100%" }}
                                placeholder="Añadir nueva respuesta"
                                ref={inputRef}
                                onChange={onNameChangeRespuestas}
                              />
                              <Button
                                style={{ width: "100%" }}
                                type="text"
                                icon={<PlusOutlined />}
                                onClick={addItemRespuesta}
                              >
                                Agregar
                              </Button>
                            </Space>
                          </>
                        )}
                      />
                    </Form.Item>
                  )}
                  {categoriaSeleccionada !== -1 && tipoPreguntaSeleccionada !== -1 && preguntaSeleccionada && (
                    <Row gutter={[16, 16]} style={{ marginBottom: "20px", justifyContent: "end" }}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button
                          disabled={tipoPreguntaSeleccionada === 1 && respuestasSeleccionadas.length <= 1} // Desactiva el botón si no se han seleccionado respuestas para el tipo 1
                          style={{ width: "100%" }}
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={agregarPregunta}
                        >
                          Agregar pregunta
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Panel>
              </Collapse>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="phone-container">
                <div className="phone">
                  <div className="screen">
                    <Text strong style={{ color: "#000", fontSize: "10px", display: "block" }}>
                      {tarea.titulo || "titulo"}
                    </Text>
                    <Text strong style={{ color: "#000", fontSize: "8px" }}>
                      {tarea.fechaInicio?.format("YYYY-MM-DD HH:mm:ss")} /{" "}
                      {tarea.fechaFin?.format("YYYY-MM-DD HH:mm:ss") || "Fecha"}
                    </Text>
                    <p></p>
                    <Text strong style={{ color: "#7B241C", fontWeight: "bold", fontSize: "10px", display: "block" }}>
                      {tarea.seccionNombre || "Seccion"}
                    </Text>
                    <div className="questions-container">
                      {" "}
                      {/* Contenedor con scroll */}
                      {tarea.preguntas.map((pregunta, index) => (
                        <div key={index} style={{ marginBottom: "10px" }}>
                          <Text strong style={{ color: "#000", fontSize: "12px", display: "block", marginBottom: "4px" }}>
                            {pregunta.descripcion || "Pregunta sin descripción"}
                          </Text>
                          {pregunta.tipoPreguntaId === 2 && (!pregunta.respuestas || pregunta.respuestas.length >= 0) ? (
                            <Input.TextArea
                              placeholder="Ingrese su respuesta aquí..."
                              style={{ minHeight: "15px" }} // Ajusta el tamaño según necesites
                            />
                          ) : pregunta.tipoPreguntaId === 3 && (!pregunta.respuestas || pregunta.respuestas.length >= 0) ? (
                            <Button className="customButton" type="primary" icon={<FileImageOutlined />}>
                              Agregar Anexo
                            </Button>
                          ) : pregunta.tipoPreguntaId === 1 && pregunta.respuestas ? (
                            pregunta.respuestas.map((respuestaId) => {
                              const respuesta = datosCuestionario.opciones.find((r) => r.id_opcion === respuestaId);
                              if (respuesta) {
                                return (
                                  <div
                                    key={respuestaId}
                                    style={{ display: "flex", alignItems: "center", marginBottom: "2px" }}
                                  >
                                    <Checkbox checked={false} disabled style={{ fontSize: "10px" }}>
                                      {respuesta.nombre_opcion}
                                    </Checkbox>
                                  </div>
                                );
                              }
                              return null; // Devolver null si no se encuentra la respuesta
                            })
                          ) : null}
                        </div>
                      ))}
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "10px" }}>
                      <Button className="customButtoncancel" icon={<CloseCircleOutlined className="customButtonIcon2" />}>
                        Cancelar
                      </Button>
                      <Button
                        className="customButtonok"
                        type="primary"
                        icon={<SaveOutlined className="customButtonIcon2" />}
                      >
                        Guardar
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-start" }}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button block icon={<CloseCircleOutlined />} onClick={handleCancel}>
                Cancelar
              </Button>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                disabled={
                  tarea.preguntas.length === 0 ||
                  tarea.titulo === "" ||
                  tarea.seccionId === undefined ||
                  tarea.fechaFin === undefined ||
                  tarea.fechaInicio === undefined
                }
                type="primary"
                block
                onClick={handleGuardar}
                icon={<SaveOutlined />}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
