import { notification, Tag, Upload, Form } from "antd";
import { ColumnsType } from "antd/es/table";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { PropsLlaves } from "components/rrhh/componentsActualizacion/VerArchivos";
import { useEffect, useState, useMemo } from "react";
import { FormInstance } from "antd/es/form";
import { useLocalStorage } from "../../otros/useLocalStorage";
import { url as url2 } from "../../../config/constants";
import Swal from "sweetalert2";
import { ButtonUploadImg } from "../../../components/otros/ButtonUploadImg";
import { ButtonUpload } from "../../../components/otros/ButtonUpload";
import { UploadFile } from "antd/lib/upload/interface";

interface DataType {
  key: string;
  descripcion: string;
  llave: string;
  conyugue: boolean;
  status: number;
}

interface DataState {
  conyugue: boolean;
  descripcion: string;
  key: string;
  llave: string;
  status: number;
}

interface arrayFiles {
  estado: number;
  id_archivo: number;
  llave: string;
  text: string;
}

export function useVerArchivosEdit(
  archivos: PropsLlaves[] | undefined,
  form: FormInstance<any>,
  setEstado2: React.Dispatch<React.SetStateAction<boolean>>
) {
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleImg, setVisibleImg] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");
  const [estado, setEstado] = useState<DataState[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { optionsPost, getItem } = useLocalStorage();
  const [loading, setLoading] = useState<boolean>(false);

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    const fileList = e && e.fileList;
    if (fileList) {
      const newFileList = fileList.filter((file: any) => {
        if (file.status === "removed") {
          return false;
        }
        return true;
      });
      e.fileList = newFileList;
    }
    return e;
  };

  const handleChange =
    (recordIndex: string) =>
    ({ fileList }: { fileList: UploadFile<any>[] }) => {
      // actualizar la lista de archivos solamente para la fila correspondiente
      setFileList((prevFileLists) => ({
        ...prevFileLists,
        [recordIndex]: fileList,
      }));
    };

  const saveData = async (formulario: any) => {
    try {
      const respuesta = await fetch(url2 + "rrhh/empleado-editar", optionsPost(formulario)).then((data) => data.json());

      if (respuesta.ok) {
        return { respuesta, status: true };
      } else {
        return { respuesta: "Ocurrio un error al guardar", status: false };
      }
    } catch (error) {
      return { msg: "no_conexion", status: false };
    }
  };

  const helperLlaves = (files: FileList[]): Promise<{ ok: boolean; llaves?: string[]; msg?: string }> => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = Object.values(files);
        let llaves: any[] = [];

        const requests = data.map(async (el: any) => {
          const body = new FormData();
          body.append("uploadfile", el[0].originFileObj);
          body.append("archivo", JSON.stringify(el[0].originFileObj.data));

          const requestSettings = {
            body,
            method: "POST",
            headers: {
              Accept: "application/json",
              "access-token": getItem("token") ?? "",
            },
          };

          return fetch(`${url2}apiallku-private/api/replace`, requestSettings).then((data) => data.json());
        });

        const result = await Promise.all(requests);
        result.map((item) => llaves.push({ llave: item.llave, status: item.a }));
        resolve({ ok: true, llaves });
      } catch (error) {
        reject({ ok: false, msg: error });
      }
    });
  };

  const createFiles = (files: any, filesOld: any) => {
    for (let prop in files) {
      switch (prop) {
        case "1":
          filesOld.forEach((el: arrayFiles) => {
            if (el.text === "foto") {
              if (el.llave === "rrhh_foto_2023_02_08_f25691e8-7688-4fd5-853e-9bfdaf15ba94.jpeg") el.llave = "";

              files[prop][0].originFileObj.data = el;
            }
          });
          break;
        case "2":
          filesOld.forEach((el: arrayFiles) => {
            if (el.text === "cedula") {
              if (el.llave === "rrhh_cedula_2023_03_02_d1be6c69-d73d-46c3-87fc-f821e8c1fff3.pdf") el.llave = "";

              files[prop][0].originFileObj.data = el;
            }
          });
          break;
        case "3":
          filesOld.forEach((el: arrayFiles) => {
            if (el.text === "cedula-hijos") {
              if (el.llave === "rrhh_cedula-hijos_2023_03_02_b059d189-e77b-4d07-bb97-e510f705c565.pdf") el.llave = "";

              files[prop][0].originFileObj.data = el;
            }
          });
          break;
        case "4":
          filesOld.forEach((el: arrayFiles) => {
            if (el.text === "cedula-conyugue") {
              if (el.llave === "rrhh_cedula-conyugue_2023_03_02_6e50b91a-3cfc-4b3c-ab32-be1447352a50.pdf") el.llave = "";

              files[prop][0].originFileObj.data = el;
            }
          });
          break;
        case "5":
          //rrhh_hijos-discapacidad_2023_03_02_2cbfec8b-33e8-44a0-88a7-0e0980b45c75.pdf
          filesOld.forEach((el: arrayFiles) => {
            if (el.text === "hijos-discapacidad") {
              if (el.llave === "rrhh_hijos-discapacidad_2023_03_02_2cbfec8b-33e8-44a0-88a7-0e0980b45c75.pdf") el.llave = "";
              files[prop][0].originFileObj.data = el;
            }
          });
          break;
        case "6":
          //rrhh_licencia_2023_03_02_c9c6d883-e71d-44f5-ae47-639efdf3286b.pdf
          filesOld.forEach((el: arrayFiles) => {
            if (el.text === "licencia") {
              if (el.llave === "rrhh_licencia_2023_03_02_c9c6d883-e71d-44f5-ae47-639efdf3286b.pdf") el.llave = "";

              files[prop][0].originFileObj.data = el;
            }
          });
          break;
      }
    }

    return files;
  };

  const handleClickData = async (form: FormInstance<any>) => {
    try {
      setLoading(true);
      let array: any[] = [];
      let requireded: string[] = [];

      archivos?.forEach((el) => {
        let parts = el.llave?.split("_");
        if (parts) {
          let text = parts[1];
          array.push({ estado: el.estado, text, llave: el.llave, id_archivo: el.key });
        }
        if (el.estado === 2) {
          let text = parts[1];
          requireded.push(text);
        }
      });

      if (requireded.length !== Object.keys(fileList).length) {
        notification.error({
          message: "Error",
          description:
            "Por favor, asegúrate de reemplazar todos los archivos que estén en error, ya que es obligatorio hacerlo !",
        });
        setLoading(false);
        return;
      }

      Object.keys(fileList).forEach((key: any) => {
        if (!`${fileList[key]}`) {
          notification.error({
            message: "Error",
            description:
              "Por favor, asegúrate de reemplazar todos los archivos que estén en error, ya que es obligatorio hacerlo !",
          });
          setLoading(false);

          throw new Error();
        }
      });

      form
        .validateFields()
        .then(async (values: any) => {
          let files = createFiles(fileList, array);

          let a = await helperLlaves(files);
          if (!a.ok) {
            notification.error({
              message: "Error",
              description: "Ocurrio un error en los archivos , intente nuevamente !",
            });
            setLoading(false);

            throw new Error();
          }

          let res = await saveData({ data: form.getFieldsValue(), archivos: a.llaves });
          if (res.status) {
            setLoading(false);
            setEstado2(false);

            return notification.success({
              message: "Ok",
              description: "Se envio correctamente su actualizacion!",
            });
          }
        })
        .catch((err) => {
          setLoading(false);

          return notification.error({
            message: "Error",
            description: "Ocurrio un error , verifique la información!",
          });
        });
    } catch (error) {
      setLoading(false);
    }
  };
  const handleClickDataEdit = async (form: FormInstance<any>) => {
    let valida = estado.some((elemento) => elemento.status === 1);
    if (valida) {
      notification.error({
        message: "Error",
        description: "No se puede enviar a edición porque tienes archivos que aún estan pendientes.",
      });
      return;
    }
    if (!edit) {
      notification.error({
        message: "Error",
        description: "La operación de guardar no puede ser completada porque no tienes activado la edición",
      });
      return;
    }

    await saveData({ data: form.getFieldsValue(), estado });
  };

  const handleModalArchive = (llave: string) => {
    setUrl("");
    const extension = llave.substring(llave.lastIndexOf(".") + 1);

    // setUrl(`http://localhost:8083/allku/archivos/${llave}`);
    setUrl(`https://infofsg.com/allku/archivos/${llave}`);

    if (extension.toLowerCase() === "pdf") {
      setVisible(true);
    } else {
      setVisibleImg(true);
    }
  };

  const data: DataType[] = useMemo(() => {
    let array: any[] = [];

    archivos?.forEach((el) => {
      let parts = el.llave?.split("_");
      if (parts) {
        let text = parts[1];
        array.push({ estado: el.estado, text, llave: el.llave, id_archivo: el.key });
      }
    });

    return [
      {
        key: "1",
        descripcion: "FOTO TAMAÑO CARNET",
        llave: array.find((elemento) => elemento.text === "foto")?.llave || "",
        conyugue: array.some((elemento) => elemento.text === "foto"),
        status: array.find((elemento) => elemento.text === "foto")?.estado || 3,
        id_archivo: array.find((elemento) => elemento.text === "foto")?.id_archivo || null,
        name: "rrhh_foto_2023_02_08_f25691e8-7688-4fd5-853e-9bfdaf15ba94.jpeg",
      },
      {
        key: "2",
        descripcion: "CEDULA Y CERTIFICADO DE VOTACION",
        llave: array.find((elemento) => elemento.text === "cedula")?.llave || "",
        conyugue: array.some((elemento) => elemento.text === "cedula"),
        status: array.find((elemento) => elemento.text === "cedula")?.estado || 3,
        id_archivo: array.find((elemento) => elemento.text === "cedula")?.id_archivo || null,
        name: "rrhh_cedula_2023_03_02_d1be6c69-d73d-46c3-87fc-f821e8c1fff3.pdf",
      },
      {
        key: "3",
        descripcion: "CEDULA O PARTIDA NACIMIENTO HIJOS",
        llave: array.find((elemento) => elemento.text === "cedula-hijos")?.llave || "",
        conyugue: array.some((elemento) => elemento.text === "cedula-hijos"),
        status: array.find((elemento) => elemento.text === "cedula-hijos")?.estado || 3,
        id_archivo: array.find((elemento) => elemento.text === "cedula-hijos")?.id_archivo || null,
        name: "rrhh_cedula-hijos_2023_03_02_b059d189-e77b-4d07-bb97-e510f705c565.pdf",
      },
      {
        key: "4",
        descripcion: "CEDULA O CERTIFICADO DE UNION DE HECHO",
        llave: array.find((elemento) => elemento.text === "cedula-conyugue")?.llave || "",
        conyugue: array.some((elemento) => elemento.text === "cedula-conyugue"),
        status: array.find((elemento) => elemento.text === "cedula-conyugue")?.estado || 3,
        id_archivo: array.find((elemento) => elemento.text === "cedula-conyugue")?.id_archivo || null,
        name: "rrhh_cedula-conyugue_2023_03_02_6e50b91a-3cfc-4b3c-ab32-be1447352a50.pdf",
      },
      {
        key: "5",
        descripcion: "HIJOS CON DISCAPACIDAD",
        llave: array.find((elemento) => elemento.text === "hijos-discapacidad")?.llave || "",
        conyugue: array.some((elemento) => elemento.text === "hijos-discapacidad"),
        status: array.find((elemento) => elemento.text === "hijos-discapacidad")?.estado || 3,
        id_archivo: array.find((elemento) => elemento.text === "hijos-discapacidad")?.id_archivo || null,
        name: "rrhh_hijos-discapacidad_2023_03_02_2cbfec8b-33e8-44a0-88a7-0e0980b45c75.pdf",
      },
      {
        key: "6",
        descripcion: "LICENCIA DE CONDUCIR",
        llave: array.find((elemento) => elemento.text === "licencia")?.llave || "",
        conyugue: array.some((elemento) => elemento.text === "licencia"),
        status: array.find((elemento) => elemento.text === "licencia")?.estado || 3,
        id_archivo: array.find((elemento) => elemento.text === "licencia")?.id_archivo || null,
        name: "rrhh_licencia_2023_03_02_c9c6d883-e71d-44f5-ae47-639efdf3286b.pdf",
      },
    ];
  }, [archivos]);

  useEffect(() => {
    setEstado(data);
  }, [data]);

  const columns: ColumnsType<DataType> = [
    {
      title: "DESCRIPCION",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "30%",
    },
    {
      title: "VER",
      dataIndex: "llave",
      key: "llave",
      width: "10%",
      render: (_, { llave }) => {
        return llave.length > 5 ? <EyeOutlined onClick={() => handleModalArchive(llave)} /> : <EyeInvisibleOutlined />;
      },
    },
    {
      title: "ESTADO ACTUAL",
      key: "status",
      dataIndex: "status",
      width: "20%",

      render: (_, { key, status }) => {
        return (
          <>
            {(status === 3 && <Tag color='success'>APROBADO</Tag>) ||
              (status === 2 && <Tag color='error'>CON ERROR</Tag>) ||
              (status === 1 && <Tag color='warning'>PENDIENTE</Tag>)}
          </>
        );
      },
    },
    {
      title: "REEMPLAZAR",
      key: "replace",
      dataIndex: "replace",
      width: "40%",

      render: (_, record) => {
        const extension = record.llave.substring(record.llave.lastIndexOf(".") + 1).toLowerCase();

        return (
          <>
            {record.status === 2 ? (
              <Form.Item
                getValueFromEvent={normFile}
                // name={`upload_${record.key}`}
                valuePropName={`upload_${record.key}`}
                // label='Foto Digital(Tamaño carnet)'
                style={{ marginBottom: "10px" }}
              >
                <Upload
                  accept={extension === "pdf" ? ".pdf" : ".png,.jpg,.jpeg,.svg,.tiff,.psd,.webp"}
                  maxCount={1}
                  disabled={record.status === 2 ? false : true}
                  // fileList={fileList}
                  onChange={handleChange(record.key)}
                  // beforeUpload={beforeUpload}
                  beforeUpload={(file, fileList) => {
                    return new Promise((resolve, reject) => {
                      const isLt5M = file.size / 1024 / 1024 <= 4;
                      if (!isLt5M) {
                        Swal.fire("Error", "El archivo excede el tamaño permitido(4MB)", "warning");
                        fileList.splice(-1, 1);
                        resolve(true);
                        return;
                      }
                      if (extension === "pdf") {
                        if (!file.name.toLowerCase().endsWith(".pdf")) {
                          Swal.fire("Error", "El tipo de archivo no es pdf.", "warning");
                          fileList.splice(-1, 1);
                          resolve(true);
                          return;
                        }
                      } else {
                        //.png,.jpg,.jpeg,.svg,.tiff,.psd,.webp
                        if (
                          !file.name.toLowerCase().endsWith(".png") &&
                          !file.name.toLowerCase().endsWith(".jpg") &&
                          !file.name.toLowerCase().endsWith(".jpeg") &&
                          !file.name.toLowerCase().endsWith(".svg") &&
                          !file.name.toLowerCase().endsWith(".tiff") &&
                          !file.name.toLowerCase().endsWith(".psd") &&
                          !file.name.toLowerCase().endsWith(".webp")
                        ) {
                          Swal.fire("Error", "El tipo de archivo no es valido.", "warning");
                          fileList.splice(-1, 1);
                          resolve(true);
                          return;
                        }
                      }
                      resolve(false);
                    });
                  }}
                  name={`upload_${record.key}`}
                >
                  {extension === "pdf" ? <ButtonUpload /> : <ButtonUploadImg />}
                </Upload>
              </Form.Item>
            ) : (
              <p></p>
            )}
          </>
        );
      },
    },
  ];

  return {
    columns,
    data,
    visible,
    url,
    setVisible,
    visibleImg,
    setVisibleImg,
    handleClickData,
    setEdit,
    edit,
    handleClickDataEdit,
    loading,
  };
}
