import { Col, Row, Table } from "antd"
import { useListaSolicitudesAprobadasJefeInmediato } from "hooks/rrhh/vacaciones/useListaSolicitudesAprobadasJefeInmediato"

export function ListaSolicitudesAprobadasJefeInmediato() {
  const { solicitudes, isWeb, columnsTable, pageSize, setPageSize } = useListaSolicitudesAprobadasJefeInmediato()

  return (
    <>
      <Row wrap style={{ padding: "16px", marginRight: "0", marginLeft: "0" }} gutter={isWeb ? [16, 16] : [5, 5]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            dataSource={solicitudes}
            columns={columnsTable}
            scroll={{ x: true }}
            size="small"
            pagination={{
              pageSize,
              size: "default",
              onShowSizeChange: (_, size) => setPageSize(size),
              pageSizeOptions: [7, 10, 20, 50, 100],
            }}
          />
        </Col>
      </Row>
    </>
  )
}
