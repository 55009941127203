import { View, Text } from "@react-pdf/renderer";

import type { Style } from "@react-pdf/types";

export interface columnsTablePDF {
  dataIndex: string;
  title: string;
  styleColumnHeader?: Style;
  styleColumn?: Style;
  key?: React.Key;
}

export interface tableProps {
  columns: columnsTablePDF[];
  dataSource: {
    [index: string]: string;
  }[];
  styleRow?: Style;
  styleRowHeader?: Style;
}

const RowTable: Style = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  borderBottom: "1px solid #f0f0f0",
};

const RowHeader: Style = {
  ...RowTable,
  backgroundColor: "#fafafa",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
};

export function Table({ columns, dataSource, styleRow, styleRowHeader }: tableProps) {
  // const styles = {
  // const Row = RowTable,
  // const RowHeader =,
  const columnTable: Style = {
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Helvetica",
    display: "flex",
    flexBasis: `calc(100% / ${columns.length})`,
    padding: 10,
    alignItems: "stretch",
  };
  const columnHeader: Style = {
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    display: "flex",
    flexBasis: `calc(100% / ${columns.length})`,
    // border: '1px solid #000',
    padding: 10,
  };
  // }

  return (
    <View key="container">
      <View style={styleRowHeader ? { ...RowHeader, ...styleRowHeader } : RowHeader} key="row header">
        {columns.map(({ title, styleColumnHeader }, index) => {
          // console.log({key: index})
          return (
            <Text key={index} style={styleColumnHeader ? { ...columnHeader, ...styleColumnHeader } : columnHeader}>
              {title}
            </Text>
          );
        })}
      </View>
      {dataSource.map((data, index) => (
        <View style={styleRow ? { ...RowTable, ...styleRow } : RowTable} key={"row " + index}>
          {columns.map(({ dataIndex, styleColumn }, index2) => (
            <Text style={styleColumn ? { ...columnTable, ...styleColumn } : columnTable} key={"column " + index2}>
              {(data as any)[dataIndex]}
            </Text>
          ))}
        </View>
      ))}
    </View>
  );
}
