import { Button, Col, DatePicker, List, Row, Select, Tag, Typography } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { useLayout } from "hooks/otros/useLayout"
import { useRegistroTareasCompleta } from "hooks/sistemas/useRegistroTareasCompleta"
import {DeleteOutlined} from "@ant-design/icons"

const { Title } = Typography
const breadcrumbs = [{ Label: "Sistemas", Url: "/sistemas" }, { Label: "Registro de Tarea Completa" }]

const openKeys = [breadcrumbs[0].Label]
const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`
export function RegistroTareasCompleto() {
  useLayout(breadcrumbs, openKeys, selectKeySider)
  const {
    onchangeBodega,
    databodega,
    dataIncidente,
    dataSolicitante,
    Cod_Bodega,
    cod_solicitante,
    CodIncidente,
    onchangeTipoIncidente,
    onchangeSolicitante,
    onchagefechainicio,
    onchagefechafin,
    fecha_inicio,
    fecha_fin,
    descripcion,
    onchangeDescripcion,
    tipo_atencion,
    onchangeTipoatencion,
    descripcion_his,
    onchangeDescripcionHis,
    dataTareahis,
    btn_Agregar,
    disableFutureDates,
    disableFutureTimes,
    btn_Guardar,
    btn_Delete_his
  } = useRegistroTareasCompleta()

  return (
    <>
      <Row style={{ background: "white", padding: "5px" }} align="middle" justify="center">
        <Col xs={18} sm={18} md={12} style={{ paddingLeft: "20px" }}>
          <Title level={4}>Registro de Tareas para Soporte</Title>
        </Col>
        <Col xs={6} sm={6} md={12}>
          <Row justify="end">
            <Button type="primary" onClick={btn_Guardar}>Guardar</Button>
          </Row>
        </Col>
      </Row>
      <Row style={{ background: "white", paddingLeft: "5px" }}>
        <Col md={8} sm={24} xs={24} style={{ paddingRight: "5px", paddingTop: "8px" }}>
          <Row>
            <Title style={{ fontSize: "13px" }}>Fecha inicio</Title>
          </Row>
          <Row>
            <DatePicker
              size="small"
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              style={{ width: "100%" }}
              onChange={onchagefechainicio}
              value={fecha_inicio}
              disabledDate={disableFutureDates}
              disabledTime={disableFutureTimes}
            />
          </Row>
        </Col>
        <Col md={8} sm={24} xs={24} style={{ paddingRight: "5px", paddingTop: "8px" }}>
          <Row>
            <Title style={{ fontSize: "13px" }}>Fecha fin</Title>
          </Row>
          <Row>
            <DatePicker
              size="small"
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              style={{ width: "100%" }}
              onChange={onchagefechafin}
              value={fecha_fin}
              disabledDate={disableFutureDates}
              disabledTime={disableFutureTimes}
            />
          </Row>
        </Col>
        <Col md={8} sm={24} xs={24} style={{ paddingRight: "5px", paddingTop: "8px" }}>
          <Row>
            <Title style={{ fontSize: "13px" }}>Farmacia</Title>
          </Row>
          <Row>
            <Select
              size="small"
              onChange={onchangeBodega}
              options={databodega}
              value={Cod_Bodega}
              style={{ width: "100%" }}
            />
          </Row>
        </Col>
      </Row>
      <Row style={{ background: "white", paddingLeft: "5px" }}>
        <Col md={8} sm={24} xs={24} style={{ paddingRight: "5px", paddingTop: "8px" }}>
          <Row>
            <Title style={{ fontSize: "13px" }}>Solicitante</Title>
          </Row>
          <Row>
            <Select
              options={dataSolicitante}
              value={cod_solicitante}
              onChange={onchangeSolicitante}
              size="small"
              style={{ width: "100%" }}
            ></Select>
          </Row>
        </Col>
        <Col md={16} sm={24} xs={24} style={{ paddingRight: "5px", paddingTop: "8px" }}>
          <Row>
            <Title style={{ fontSize: "13px" }}>Tipo Incidente</Title>
          </Row>
          <Row>
            <Select
              options={dataIncidente}
              value={CodIncidente}
              onChange={onchangeTipoIncidente}
              size="small"
              style={{ width: "100%" }}
            ></Select>
          </Row>
        </Col>
      </Row>
      <Row style={{ background: "white", paddingLeft: "5px" }}>
        <Row style={{ marginTop: "8px", paddingLeft: "5px" }}>
          <Title style={{fontSize:'13px'}}>Descripción General</Title>
        </Row>
        <TextArea rows={2} style={{ marginRight: "5px" }} value={descripcion} onChange={(e) => onchangeDescripcion(e)} />
      </Row>
      <Row style={{ background: "white", paddingInline: "5px", paddingTop: "10px" }}>
        <Title style={{ fontSize: "20px", marginLeft: "5px" }}>Historial</Title>
      </Row>
      <Row style={{ background: "white", paddingInline: "8px", paddingTop: "10px" }}>
        <Col xs={24} sm={24} md={24}>
          <Row style={{ border: "1px solid #D9D9D9", width: "100%", borderRadius: "5px", padding: "5px" }} align={"middle"}>
            <Col sm={16} md={6} xs={16}>
              <Row>
                <Title style={{ fontSize: "12px" }}>Tipo de Atención</Title>
              </Row>
              <Select
                onChange={onchangeTipoatencion}
                value={tipo_atencion}
                size="small"
                style={{ width: "100%" }}
                options={[
                  { value: "", label: "SELECCIONE UN TIPO DE ATENCIÓN" },
                  { value: "VIRTUAL", label: "VIRTUAL" },
                  { value: "PRESENCIAL", label: "PRESENCIAL" },
                ]}
              />
            </Col>
            <Col sm={8} md={18} xs={8}>
              <Row justify="end">
                <Button type="primary" onClick={btn_Agregar} /* disabled={disabledButton} */>
                  Agregar
                </Button>
              </Row>
            </Col>
            <Row style={{ marginTop: "10px" }}>
              <Row>
                <Title style={{ fontSize: "12px" }}>Descripción</Title>
              </Row>
            </Row>
            <TextArea
              onChange={(e) => {
                onchangeDescripcionHis(e)
              }}
              value={descripcion_his}
            ></TextArea>
          </Row>
        </Col>
      </Row>
      {dataTareahis.length > 0 && (
        <>
          <Row style={{ background: "white", paddingInline: "8px", paddingTop: "10px" }}>
            <List
              style={{ width: "100%" }}
              itemLayout="vertical"
              size="small"
              dataSource={dataTareahis}
              renderItem={(item) => (
                <Row
                  style={{
                    border: "1px solid #D9D9D9",
                    width: "100%",
                    borderRadius: "5px",
                    padding: "5px",
                    marginBottom: "2px",
                  }}
                  align={"middle"}
                >
                  <Col sm={11} md={12} xs={11}>
                    <Row align={"middle"}>
                      <Title style={{ fontSize: "12px", paddingTop: "0px", paddingRight: "4px" }}>Tipo de Atención: </Title>
                      <Tag color="blue" style={{ fontSize: "8px" }}>
                        {item.tipo_atencion}
                      </Tag>
                    </Row>
                  </Col>
                  <Col sm={13} md={12} xs={13}>
                    <Row align={"middle"} justify={"end"}>
                      <Col sm={17} md={18} xs={17}>
                        <Row></Row>
                      </Col>
                      {<Col><Button type="primary" onClick={()=>btn_Delete_his(item.key)}><DeleteOutlined /></Button></Col>}
                    </Row>
                  </Col>
                  {/* <Col sm={10} md={18} xs={10}>
                      <Row justify="end">
                        <Button type="primary" onClick={btn_Agregar}>Agregar</Button>
                      </Row>
                    </Col> */}
                  <Row style={{ marginTop: "5px" }}>
                    <Row>
                      <Title style={{ fontSize: "12px" }}>Descripción</Title>
                    </Row>
                  </Row>
                  <TextArea
                    style={{ background: "white", color: "#303030" }}
                    onChange={(e) => onchangeDescripcion(e)}
                    value={item.descripcion}
                    disabled
                  ></TextArea>
                </Row>
              )}
            />
          </Row>
        </>
      )}
    </>
  )
}
