import { Button, SelectProps } from "antd"

import { LayoutContext } from "context/LayoutContext"

import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs"
import { useDataForTable } from "hooks/rrhh/llamadosatencion/useDataForTable"
import { useContext, useEffect, useMemo, useState } from "react"

import { GrDocumentPdf } from "react-icons/gr"

import moment from "moment"

import type { ColumnProps } from "antd/lib/table"
import type { dataLlamadosAtencion } from "hooks/rrhh/llamadosatencion/useDataForTable"
import type { Moment } from "moment"
import type { RangePickerProps } from "antd/lib/date-picker"

import "moment/locale/es"

const breadcrumbs = [
  { Label: "Recursos humanos", Url: "/rrhh" },
  { Label: "Llamados de atención", Url: "/rrhh/llamados-de-atencion" },
  { Label: "Reporte de aprobados" },
]

export function useReporteLlamadosAtencion() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext)

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedLlamadoAtencion, setSelectedLlamadoAtencion] = useState<number>(0)
  const [selectedEmpleado, setSelectedEmpleado] = useState<number>(0)
  const [selectedDates, setSelectedDates] = useState<[Moment, Moment]>([moment().subtract(1, "months"), moment()])
  const [selectedTipoConsulta, setSelectedTipoConsulta] = useState<number>(0)

  useEffect(() => {
    setOpenKeys(["Recursos Humanos", "Recursos Humanos/Llamados de atención"])
    setSelectedKeys(["Llamados de atención/Reporte de aprobados"])
  }, [setOpenKeys, setSelectedKeys])

  useBreadCrumbs(breadcrumbs)

  const handleClickAction = (value: number) => {
    setSelectedLlamadoAtencion(llamadosAtencion.findIndex(ll => ll.key === value))
    setOpenModal(true)
  }

  const renderActions: ColumnProps<dataLlamadosAtencion>["render"] = value => (
    <Button
      icon={<GrDocumentPdf style={{ fontSize: "1.3rem" }} />}
      type="text"
      onClick={() => handleClickAction(value)}
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      block
    />
  )

  const bodyGetData = useMemo(
    () => ({
      tipo_consulta: selectedTipoConsulta,
      fecha_inicio: selectedDates[0].format("DD/MM/YYYY"),
      fecha_fin: selectedDates[1].format("DD/MM/YYYY"),
    }),
    [selectedDates, selectedTipoConsulta]
  )

  const { llamadosAtencion, columnsTable } = useDataForTable(
    "apifsg-pr/recursos-humanos/listar-llamados-atencion-aprobados",
    renderActions,
    bodyGetData
  )

  const handleChangeEmpleado: SelectProps<number>["onChange"] = value => {
    setSelectedEmpleado(value)
  }

  const handleChangeDates: RangePickerProps["onChange"] = values => {
    setSelectedDates(values as [Moment, Moment])
  }

  // const handleClickConsultar = () => {
  //   listarLlamadosAtencion(bodyGetData)
  // }

  return {
    llamadosAtencion,
    columnsTable,
    selectedLlamadoAtencion,
    openModal,
    setOpenModal,
    selectedEmpleado,
    handleChangeEmpleado,
    selectedDates,
    handleChangeDates,
    // handleClickConsultar,
    // widthScreen: width,
    selectedTipoConsulta,
    setSelectedTipoConsulta,
  }
}
