import { Button, Col, Row, Select, DatePicker, Space, Typography, Table, Tooltip } from "antd"
import { useLayout } from "hooks/otros/useLayout"
import { FilePdfOutlined, FileExcelOutlined} from "@ant-design/icons"
import React from "react"
import { DataTypeReportFundas, useReportFundas } from "hooks/bodega/useReportFundas"
import { DataDetSolicitud } from "hooks/bodega/useSolicitudesFundas"

import './ReporteFundas.css'
import { ModalReport } from "components/otros/ModalReport"
import { ReportSolicitudFundas } from "components/bodega/ReportSolicitudFundas"

const { Title } = Typography
const { RangePicker} = DatePicker

const breadcrumbs = [
  { Label: "Bodega", Url: "/bodega" },
  { Label: "Reporte de Fundas", Url: "/bodega/reporte-fundas" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`

export function ReporteFundas() {
  useLayout(breadcrumbs, openKeys, selectKeySider)
  
  const {
    columnsreport,
    data,
    columnsdetsolicitud,
    disabledDate,
    handleDateChange,
    fechasSeleccionadas,
    farmacias,
    handleChangeFarmacia,
    handleChangeEstado,
    //btn_Buscar,
    handleDownload,
    openModal, 
    setopenModal,
    DataPdf,
    rowSelection, btn_PDF
  } = useReportFundas()

    const onSearch = (value: string) => {
      //console.log('search:', value);
    };
  return (
    <>
      <Row style={{ background: "white", padding: "5px" }} align="middle" >
        <Col sm={24} md={9} style={{ paddingLeft: "20px" }}>
          <Title level={4}>Reporte de Fundas</Title>
        </Col>
        <Col sm={24} md={15} >
          <Row justify="end">
          <Col xs={15} sm={12} md={7}>
              <Row style={{ fontSize: "10px", marginRight: "5px" }}>Farmacia:</Row>
              <Row style={{ fontSize: "9px", marginRight: "0px" }} >
                <Select
                  size="small"
                  showSearch
                  popupClassName="my-custom-select"
                  defaultValue=""
                  style={{ width:'205px', marginLeft: "0px", marginTop: "5px", fontSize:'12px' }}
                  onChange={handleChangeFarmacia}
                  options={farmacias}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Row>
            </Col>
            <Col xs={9} sm={12} md={5}>
              <Row style={{ fontSize: "10px", marginRight: "5px" }}>Estado:</Row>
              <Row>
                <Select
                size="small"
                  defaultValue=""
                  popupClassName="my-custom-select"
                  style={{ width: 150, marginLeft: "0px", marginTop: "5px", fontSize:'12px'}}
                  onChange={handleChangeEstado}
                  options={[
                    {
                      value: "",
                      label: "Todos",
                    },
                    {
                      value: "PENDIENTE",
                      label: "PENDIENTE",
                    },
                    {
                      value: "APROBADO",
                      label: "APROBADO",
                    },
                    {
                      value: "RECHAZADO",
                      label: "RECHAZADO",
                    },
                  ]}
                />
              </Row>
            </Col>
            <Col xs={17} sm={12} md={8}>
              <Row style={{ fontSize: "10px", marginRight: "5px" }}>Fechas:</Row>
              <Row style={{ marginTop: "5px" }}>
                <Space>
                  <RangePicker
                    size="small"
                    defaultValue={fechasSeleccionadas}
                    format={'YYYY/MM/DD'}
                    disabledDate={disabledDate}
                    onChange={handleDateChange}
                    style={{ marginLeft: "2px"}}
                  />
                </Space>
              </Row>
            </Col>
            <Col  style={{ marginTop: "12px" }}>
            <Tooltip title="Generar PDF" overlayClassName="custom-tooltip">
              <Button
                type="primary"
                style={{ marginLeft: "2px" }}
                onClick={btn_PDF}
              >
                <FilePdfOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Generar Excel" overlayClassName="custom-tooltip">
              <Button
                type="primary"
                style={{ marginLeft: "4px", marginTop: "1px", backgroundColor: "#00B050", borderColor: "#00B050" }}
                onClick={handleDownload}
              >
                <FileExcelOutlined />
              </Button>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ background: "white" }} className="table-container">
        <Table  style={{ width: "100%", margin: "5px"}}
        columns={columnsreport as DataTypeReportFundas[]}
            rowSelection={rowSelection}
            dataSource={data}
            expandable={{
            expandedRowRender: (record) => {
              let detalle: any = record.detalle
              return (
                <>
                {record.estado==='RECHAZADO'&&<><Row>
                  <Col sm={24} md={8}>{
                    <Row>
                      <Col md={6} className="fontsize"><b>Rechazado</b></Col>
                      <Col md={18} className="fontsize">{record.usuario_rechaza}</Col>
                    </Row>
                  }</Col>
                   <Col sm={24} md={8}>{
                    <Row>
                      <Col md={4} className="fontsize"><b>Tipo</b></Col>
                      <Col md={20} className="fontsize">{''+record.tipo_solicitud}</Col>
                    </Row>
                  }</Col>
                  <Col sm={24} md={8}>{
                    <Row>
                      <Col md={7} className="fontsize"><b>Fecha de Rechazado</b></Col>
                      <Col md={17} className="fontsize">{''+record.fecha_rechaza}</Col>
                    </Row>
                  }</Col>
                  </Row>
                  <Row><Col md={2} className="fontsize"><label><b>{"Observación:   "}</b></label></Col><Col className="fontsize">{record.observacion}</Col></Row></>
                  }
                <Table
                  //style={{ width: "80%"}}
                  columns={columnsdetsolicitud as DataDetSolicitud[]}
                  dataSource={detalle}
                  pagination={false}
                />
                </>
              )
            },
          }}>
        </Table>
      </Row>

      <ModalReport openModal={openModal}
      setOpenModal={setopenModal}
      titleModal="Reporte de Solicitud de Fundas"
      pdfName={`REPORTE SOLICITUD`}
      >
        <ReportSolicitudFundas DataSolicitud={DataPdf}/>
      </ModalReport>
    </>
  )
}
