import { Tag } from "antd";
import { ColumnProps, ColumnsType } from "antd/lib/table";
import { optionsGET, url_interna } from "config/constants";
import { useSearchTable } from "hooks/otros/useSearchTable";
import { useSwal } from "hooks/otros/useSwal";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import { useCallback, useEffect, useState } from "react";

export interface PropsProductos {
  key: number;
  id: number;
  producto: string;
  laboratorio: string;
  modificaciones: number;
  fecha: string;
  motivo: string;
  usuario: string;
  cod_motivo: number;
  data_json: any;
  data_json_nueva: any;
}

interface responseCobros {
  msg: "ok" | "error" | "err";
  data: PropsProductos[];
}

export function useTableProductos(dataUrl: string, renderActionsHistory?: ColumnProps<any>["render"]) {
  const [productos, setProductos] = useState<PropsProductos[]>([]);

  const { swalError } = useSwal();

  const { wasInvalidToken, hadError } = useVerifyResponse();

  const { getColumnSearchProps } = useSearchTable<PropsProductos>();

  const listarProductos = useCallback(async () => {
    try {
      const res = await fetch(url_interna + dataUrl, optionsGET);

      const response: responseCobros = await res.json();

      if (wasInvalidToken(response)) return;
      if (hadError(response, "No se pudo obtener los productos")) return;

      const { data } = response;

      setProductos(data ? data : []);
    } catch (e) {
      swalError({ text: "No se pudo obtener los productos" });
    }
  }, [wasInvalidToken, hadError, swalError, dataUrl]);

  useEffect(() => {
    listarProductos();
  }, [listarProductos]);

  const columnsTable: ColumnsType<PropsProductos> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: "5%",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Producto",
      dataIndex: "producto",
      key: "producto",
      width: "20%",
      ...getColumnSearchProps("producto"),
    },
    {
      title: "Laboratorio",
      dataIndex: "laboratorio",
      key: "laboratorio",
      width: "19%",
      ...getColumnSearchProps("laboratorio"),
    },
    {
      title: "Modificaciones",
      dataIndex: "modificaciones",
      key: "modificaciones",
      width: "12%",
      align: "right",
      sorter: (a, b) => a.modificaciones - b.modificaciones,
      render: (modificaciones) =>
        modificaciones === 0 ? <Tag color='success'>{modificaciones}</Tag> : <Tag color='blue'>{modificaciones}</Tag>,
    },
    {
      title: "Año de Creación",
      dataIndex: "fecha",
      key: "fecha",
      align: "right",
      ...getColumnSearchProps("fecha"),
      width: "15%",
    },
    {
      title: "Ver Historial",
      width: "20%",
      align: "center",
      render: renderActionsHistory,
    },
  ];

  return {
    productos,
    setProductos,
    listarProductos,
    columnsTable,
  };
}
