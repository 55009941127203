import { Button, Col, Empty, Row, Table, type TableColumnsType } from "antd"
import { useSearchTable } from "hooks/otros/useSearchTable"
import { useCallback, useEffect, useState } from "react"

import { PlusCircleOutlined } from "@ant-design/icons"
import { ModalListSelect } from "components/otros/ModalListSelect"
import { url } from "config/constants"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { useSwal } from "hooks/otros/useSwal"
import { useLayout } from "hooks/otros/useLayout"
import { type Perfiles, SubTableUsuarioPerfil } from "components/configuraciones/sub-table-usuario-perfil"

type Usuarios = {
  key: React.Key
  cedula: `${number}${number}${number}${number}${number}${number}${number}${number}${number}${number}`
  nombres: string
  departamento: string
  bodega: string
  perfiles_asignados?: Perfiles[]
}

const breadcrumbs = [{ Label: "Configuraciones", Url: "/configuraciones" }, { Label: "Asignar perfil a usuario" }]

const openKeys = [breadcrumbs[0].Label]

export function AsignarUsuarioPerfil() {
  useLayout(breadcrumbs, openKeys, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`)

  const [pageSize, setPageSize] = useState(7)
  const [usuarios, setUsuarios] = useState<Usuarios[]>([])
  const [perfiles, setPerfiles] = useState<{ value: number; label: string }[]>([])
  const [usuarioSelected, setUsuarioSelected] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  const { getColumnSearchProps } = useSearchTable<Usuarios>()

  const { optionsGet, optionsPost } = useLocalStorage()

  const { isError } = useVerifyResponse()

  const { swalError, swalSuccess } = useSwal()

  const getPerfilesParaSelect = async (usuario: number) => {
    try {
      const res = await fetch(`${url}configuraciones/perfiles-para-select/${usuario}`, optionsGet)
      if (isError(res)) throw new Error()

      const result = await res.json()

      setPerfiles(result)
    } catch (e) {
      swalError({ text: "No se pudo listar los perfiles" })
    }
  }

  const columns: TableColumnsType<Usuarios> = [
    {
      title: "Cédula",
      dataIndex: "cedula",
      key: "cedula",
      ...getColumnSearchProps("cedula"),
    },
    {
      title: "Usuario",
      dataIndex: "nombres",
      key: "nombres",
      ...getColumnSearchProps("nombres"),
    },
    {
      title: "Departamento",
      dataIndex: "departamento",
      key: "departamento",
      filters: [...new Set(usuarios.map(({ departamento }) => departamento))].sort().map(departamento => ({
        text: departamento,
        value: departamento,
      })),
      onFilter: (value, record) => record.departamento === value,
      filterSearch: true,
    },
    {
      title: "Bodega",
      dataIndex: "bodega",
      key: "bodega",
      filters: [...new Set(usuarios.map(({ bodega }) => bodega))].sort().map(bodega => ({ text: bodega, value: bodega })),
      onFilter: (value, record) => record.bodega === value,
      filterSearch: true,
    },
    {
      title: "Acción",
      dataIndex: "key",
      key: "key",
      fixed: "right",
      align: "center",
      render: (id: number) => (
        <Button
          type="text"
          icon={<PlusCircleOutlined style={{ fontSize: "1.3rem" }} />}
          onClick={async () => {
            setUsuarioSelected(id)
            await getPerfilesParaSelect(id)
            setOpenModal(true)
          }}
        />
      ),
    },
  ]

  const getUsuarios = useCallback(async () => {
    try {
      const res = await fetch(`${url}fapifsg-pr/configuraciones-externas/listar-usuarios`, optionsGet)
      if (isError(res)) throw new Error()

      const result = await res.json()

      setUsuarios(result)
    } catch (e) {
      swalError({ text: "No se pudo obtener los usuarios" })
    }
  }, [isError, optionsGet, swalError])

  useEffect(() => {
    getUsuarios()
  }, [getUsuarios])

  const getPerfilesPorUsuario = async (usuario: number) => {
    try {
      const res = await fetch(`${url}configuraciones/perfiles-por-usuario/${usuario}`, optionsGet)
      if (isError(res)) throw new Error()

      const result = await res.json()

      setUsuarios(state => {
        return state.map(user => ({ ...user, perfiles_asignados: user.key === usuario ? result : user.perfiles_asignados }))
      })
    } catch (e) {
      swalError({ text: "No se pudo obtener los perfiles para el usuario" })
    }
  }

  const handleChangePerfiles = async (perfil: number) => {
    try {
      const res = await fetch(
        `${url}configuraciones/asignar-perfil-usuario`,
        optionsPost({ perfil, usuario: usuarioSelected })
      )
      if (isError(res)) throw new Error()

      const text = await res.json()

      setOpenModal(false)

      swalSuccess({ text })

      await getPerfilesPorUsuario(usuarioSelected)
    } catch (e) {
      swalError({ text: "No se pudo obtener los perfiles para el usuario" })
    }
  }

  return (
    <>
      <Row style={{ padding: "16px", marginLeft: 0, marginRight: 0 }} justify="end" gutter={[16, 16]}>
        {/* <Col xs={24} sm={24} md={5} lg={5} xl={5}>
          <ButtonAdd buttonProps={{ onClick: handleAddButton }}>Añadir Perfil</ButtonAdd>
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            dataSource={usuarios}
            columns={columns}
            scroll={{ x: true }}
            size="small"
            pagination={{
              pageSize: pageSize,
              pageSizeOptions: [7, 10, 20, 50, 100],
              size: "default",
              showSizeChanger: true,
              onShowSizeChange: (_, size) => setPageSize(size),
            }}
            expandable={{
              // rowExpandable: ({ perfiles_asignados }) => (perfiles_asignados?.length ?? 0) > 0,
              onExpand: (expanded, record) => {
                expanded && (record.perfiles_asignados?.length ?? 0) <= 0 && getPerfilesPorUsuario(record.key as number)
              },
              expandedRowRender: ({ perfiles_asignados, key }) =>
                (perfiles_asignados?.length ?? 0) > 0 ? (
                  <SubTableUsuarioPerfil
                    perfiles={perfiles_asignados ?? []}
                    afterDelete={() => getPerfilesPorUsuario(key as number)}
                    usuario={key as number}
                  />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ),
            }}
          />
        </Col>
      </Row>
      <ModalListSelect
        modalProps={{ open: openModal, onCancel: () => setOpenModal(false), title: "Asignar perfil" }}
        onChange={handleChangePerfiles}
        dataSource={perfiles}
      />
    </>
  )
}
