import { Card, Col, DatePicker, Row, Table } from "antd";
import { useReporteCuestionario } from "hooks/inventarios/reportes/useReporteCuestionario";
import moment from "moment";
import { useVT } from "virtualizedtableforantd4";
import "./estiloCuestionario.css";
const ReporteCuestionario = () => {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);

  const {
    columns,
    filteredData,
    modifiedRowSelection,
    onRowClick,
    columnsBodega,
    filteredDataBodega,
    tableContainerRef,
    disabledDate,
    getDataCuestionario,
  } = useReporteCuestionario();
  const fechaInicio = moment().startOf("month");
  // Ajustar la fecha de fin al último día del mes actual
  const fechaFin = moment().endOf("day");
  return (
    <>
      <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <DatePicker.RangePicker
            defaultValue={[fechaInicio, fechaFin]}
            onChange={(value) => {
              if (value && value.length === 2 && value[0] && value[1]) {
                getDataCuestionario(value[0], value[1]);
                //setFechas([value[0], value[1]]);
              }
            }}
            disabledDate={disabledDate}
            style={{ width: "100%" }}
          />
        </Col>
        <Card style={{ margin: "5px" }}>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={14} lg={14} xl={14}>
              <div ref={tableContainerRef} style={{ maxHeight: "435px" }}>
                {
                  <Table
                    ref={tableContainerRef}
                    components={vt}
                    pagination={false}
                    columns={columns}
                    scroll={{ y: 450 }}
                    size="small"
                    dataSource={filteredData ? filteredData : []}
                    bordered
                    rowSelection={{
                      type: "radio",
                      ...modifiedRowSelection,
                    }}
                    onRow={(record) => ({
                      onClick: () => onRowClick(record),
                    })}
                  />
                }
              </div>
            </Col>
            <Col xs={24} sm={12} md={10} lg={10} xl={10}>
              <Table /*style={{border: "2px solid #d9d9d9"}}*/
                pagination={false}
                columns={columnsBodega}
                scroll={{ y: 450 }}
                size="small"
                dataSource={filteredDataBodega ? filteredDataBodega : []}
                bordered
              />
            </Col>
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default ReporteCuestionario;
