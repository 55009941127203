import { Col, Row, DatePicker, Table, Card, Modal } from "antd";
import "moment/locale/es";

import { ButtonExcel } from "components/otros/buttons";
import { useVT } from "virtualizedtableforantd4";
import { useReporteCaducado } from "hooks/inventarios/reportes/useReporteCaducados";

export function InventariosCobrosCaducados() {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);

  const {
    columns,
    columsDataDependiente,
    setFechas,
    filteredData,
    handleClickDownloadData,
    estadoBoton,
    disabledDate,
    columsDataProductos,
    isModalOpen,
    data,
    handleOk,
    handleCancel,
  } = useReporteCaducado();
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <DatePicker.RangePicker
          onChange={(value) => {
            if (value && value.length === 2 && value[0] && value[1]) {
              setFechas([value[0], value[1]]);
            }
          }}
          disabledDate={disabledDate}
          style={{ width: "100%" }}
        />
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Row gutter={[10, 10]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
          <Col xs={24} md={10}>
            {estadoBoton && <ButtonExcel buttonProps={{ onClick: handleClickDownloadData }}></ButtonExcel>}
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <Table
            columns={columns}
            components={vt}
            dataSource={filteredData ? filteredData : []}
            scroll={{ x: 900, y: 450 }}
            size="small"
            pagination={false}
            bordered
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <Table
                    style={{ width: "100%", margin: "10px" }}
                    columns={columsDataDependiente}
                    dataSource={record.detalleCaducados}
                    pagination={false}
                  />
                );
              },
            }}
          />
        </Card>
      </Col>
      <Modal
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        transitionName=""
        title="Productos cobrados"
        open={isModalOpen}
        width={"90wh"}
      >
        <Table
          style={{ marginBottom: "10px" }}
          pagination={false}
          loading={columsDataProductos ? false : true}
          scroll={{ x: 900, y: "55vh" }}
          size="small"
          bordered
          columns={columsDataProductos}
          dataSource={data}
        />
      </Modal>
    </Row>
  );
}

export default InventariosCobrosCaducados;
