import { DatePicker, Form, type FormProps, InputNumber, Modal, Select, Switch, Input } from "antd"
import { url } from "config/constants"
import { type BonosFijosPorFranquiciado } from "hooks/Franquiciados/useBonosFranquiciados"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSwal } from "hooks/otros/useSwal"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import moment from "moment"
import { type Moment } from "moment"
import { useEffect, useState } from "react"

type ModalAsignarBodegaProps = {
  open: boolean
  bono: number
  handleClose(): void
  data?: BonosFijosPorFranquiciado
  reFetcher(): void
}

type ValuesFormAsignacion = {
  bodega: number
  franquiciado: number
  valor: number
  estado: boolean
  fecha: Moment
  nombre_franquiciado: string
}

type Franquiciados = {
  value: number
  label: string
  cod_franquiciado: number
  franquiciado: string
}

export function ModalAsignarBonoFranquiciado({ open, bono, handleClose, data, reFetcher }: ModalAsignarBodegaProps) {
  const [form] = Form.useForm<ValuesFormAsignacion>()
  const [franquiciados, setFranquiciados] = useState<Franquiciados[]>([])

  const { optionsPost } = useLocalStorage()

  const { swalError, swalSuccess } = useSwal()

  const { isError } = useVerifyResponse()

  useEffect(() => {
    ;(async () => {
      try {
        const body = {
          bono,
          incluye_franquiciado: data?.cod_franquiciado,
        }
        const res = await fetch(`${url}fapifsg-pr/franquiciados/franquiciados-para-bonos-fijos`, optionsPost(body))
        if (isError(res)) throw new Error()
        const response = await res.json()
        setFranquiciados(response)
      } catch (e) {
        swalError({ text: `No se pudo obtener las bodegas franquiciadas` })
      }
    })()
  }, [bono, isError, optionsPost, swalError, data])

  useEffect(() => {
    if (!data) return
    if (!franquiciados || franquiciados.length === 0) return

    const { cod_franquiciado: codigo, valor, estado, fecha } = data

    form.setFieldsValue({
      bodega: franquiciados.find(({ cod_franquiciado }) => cod_franquiciado === codigo)?.value,
      franquiciado: codigo,
      nombre_franquiciado: franquiciados.find(({ cod_franquiciado }) => cod_franquiciado === codigo)?.franquiciado,
      valor,
      estado,
      fecha: moment(fecha, "DD/MM/YYYY HH:mm:ss"),
    })
  }, [data, form, franquiciados])

  const handleGuardarAsignacion: FormProps<ValuesFormAsignacion>["onFinish"] = async values => {
    handleClose()
    let tipoRuta = data ? "modificar" : "registrar"
    try {
      const res = await fetch(
        `${url}fapifsg-pr/franquiciados/${tipoRuta}-bono-fijo-por-franquiciado`,
        optionsPost({
          ...values,
          bono,
          franquiciado: franquiciados.find(({ value }) => value === values.bodega)?.cod_franquiciado,
        })
      )
      if (isError(res)) throw new Error()
      const text = await res.json()
      swalSuccess({ text })
      form.resetFields()
      reFetcher()
    } catch (e) {
      swalError({ text: `No se pudo ${tipoRuta} el bono fijo` })
    }
  }

  const handleOkModal = async () => {
    try {
      await form.validateFields()
      form.submit()
    } catch (e) {
      // swalError({ text: "Error al guardar la asignación del bono al franquiciado" })
      console.error(e)
    }
  }

  return (
    <Modal
      open={open}
      title="Bonos fijos por franquiciado"
      okText={data ? "Modificar" : "Registrar"}
      cancelText="Cancelar"
      onCancel={() => {
        form.resetFields()
        handleClose()
      }}
      transitionName=""
      // style={{ top: '50px' }}
      onOk={handleOkModal}
    >
      <Form
        form={form}
        requiredMark="optional"
        onFinish={handleGuardarAsignacion}
        wrapperCol={{ xs: 18, sm: 18, md: 18, lg: 18, xl: 18 }}
        labelCol={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
      >
        <Form.Item label="Bodega" name="bodega" rules={[{ required: true, message: "Escoja una bodega" }]}>
          <Select
            showSearch
            options={franquiciados.map(({ value, label }) => ({ value, label }))}
            getPopupContainer={() => document.getElementsByClassName("ant-modal-body")[0] as HTMLElement}
            disabled={Boolean(data)}
            optionFilterProp="label"
            onChange={bodega => {
              form.setFieldValue("nombre_franquiciado", franquiciados.find(({ value }) => value === bodega)?.franquiciado)
            }}
          />
        </Form.Item>
        <Form.Item label="Franquiciado" name="nombre_franquiciado" required>
          <Input readOnly />
        </Form.Item>
        <Form.Item label="Valor" name="valor" rules={[{ required: true, message: "Ingrese el valor" }]}>
          <InputNumber min={0} controls={false} placeholder="0.00" />
        </Form.Item>
        {data && (
          <>
            <Form.Item label="Estado" name="estado" valuePropName="checked" required>
              <Switch defaultChecked />
            </Form.Item>
            <Form.Item label="Fecha creación" name="fecha" required>
              <DatePicker disabled showTime format="DD/MM/YYYY HH:mm:ss" />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  )
}
