import { Button, Space } from "antd";
import { Link } from "react-router-dom";

import { useCallback, useContext, useEffect, useState } from "react";
import { useScreenSize } from "hooks/otros/useScreenSize";

import { LayoutContext } from "context/LayoutContext";

import Swal from "sweetalert2";
import AES from "crypto-js/aes";

import { llave } from "config/constants";
import { optionsPOST, url_interna } from "config/constants";

import { EditOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { UserContext } from "context/UserContext";

interface dataReembolsos {
  reembolsos: {
    Cod_Reembolso: number;
    Fecha: string;
    Justificacion: string;
    numeroFacturas: number;
    numeroNotaVentas: number;
    numeroRecibos: number;
    Estado: string;
  }[];
}

const breadCrumbs = [
  { Label: "Reembolsos", Url: "/reembolsos/pendientes" },
  { Label: "Ver todos los reembolsos" },
];

export function useVerTodos() {
  const { userData: dataUser } = useContext(UserContext);

  const [reembolsos, setReembolsos] = useState<dataReembolsos>({ reembolsos: [] });

  const [actualPage, setActualPage] = useState(1);

  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);

  const { width } = useScreenSize();

  const isWeb = width > 768;

  const [selectedState, setSelectedState] = useState<"all" | "PE" | "AP" | "RE">("all");

  const [selectedDates, setSelectedDates] = useState<[string, string]>(["", ""]);

  const filteredData = () => {
    let reembolsosCopy = [...reembolsos.reembolsos];

    if (selectedState !== "all")
      reembolsosCopy = reembolsosCopy.filter((reembolso) => reembolso.Estado === selectedState);
    if (selectedDates[0] !== "")
      reembolsosCopy = reembolsosCopy.filter(
        (reembolso) =>
          moment(reembolso.Fecha, "DD/MM/YYYY") >= moment(selectedDates[0], "DD/MM/YYYY")
      );
    if (selectedDates[1] !== "")
      reembolsosCopy = reembolsosCopy.filter(
        (reembolso) =>
          moment(reembolso.Fecha, "DD/MM/YYYY") <= moment(selectedDates[1], "DD/MM/YYYY")
      );

    // setReembolsosLength(reembolsosCopy.length)
    return reembolsosCopy;
  };

  useBreadCrumbs(breadCrumbs);

  useEffect(() => {
    // if(!isWeb) return

    setOpenKeys(["Reembolsos", "Reembolsos/Ver Reembolsos"]);
    setSelectedKeys([""]);
  }, [setOpenKeys, setSelectedKeys]);

  const fillReembolsos = useCallback(() => {
    fetch(
      `${url_interna}api/reembolsos/ver-reembolsos`,
      optionsPOST(JSON.stringify({ Id_Usuario: dataUser?.id }), { "api-token": "apitoken" })
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.msg === "ok") {
          setReembolsos(res.data);
          // setReembolsosLength(res.data.reembolsos.length)
        } else
          Swal.fire({
            title: "Error de consulta",
            text: "Error al obtener la lista de reembolsos de gastos",
            icon: "error",
            timer: 2000,
            showConfirmButton: false,
          });
      })
      .catch((_) => {
        Swal.fire({
          title: "Sin Conexión",
          text: "No hay conexión con el servidor",
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  }, [dataUser?.id]);

  useEffect(() => fillReembolsos(), [fillReembolsos]);

  const handleClickDelete = (Cod_Reembolso: number) => {
    Swal.fire({
      title: "¿Está seguro?",
      text: "Se eliminará para siempre",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "var(--primary)",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(
          `${url_interna}api/reembolsos/eliminar-reembolso`,
          optionsPOST(JSON.stringify({ Cod_Reembolso }), { "api-token": "apitoken" })
        )
          .then((_) => {
            Swal.fire("¡Eliminado!", "El reembolso de gastos se ha eliminado con éxito", "success");
            fillReembolsos();
          })
          .catch((e) => {
            Swal.fire("¡Error!", "No se pudo eliminar el reembolso", "error");
            console.error(e);
          });
      }
    });
  };

  const actions = (estado: string, Cod_Reembolso: number) => {
    if (estado === "PE") {
      return [
        <Link
          key='list-loadmore-edit'
          to={`/editar-reembolso/${encodeURIComponent(
            AES.encrypt(Cod_Reembolso.toString(), llave).toString()
          )}`}
        >
          <Space>
            <EditOutlined />
            Editar
          </Space>
        </Link>,
        <Button
          icon={<DeleteOutlined />}
          type='link'
          onClick={() => handleClickDelete(Cod_Reembolso)}
        >
          Eliminar
        </Button>,
        <Link
          key='list-loadmore-more'
          to={`/ver-reembolso/${encodeURIComponent(
            AES.encrypt(Cod_Reembolso.toString(), llave).toString()
          )}`}
        >
          <Space>
            <EyeOutlined />
            Ver
          </Space>
        </Link>,
      ];
    } else {
      return [
        <Link
          key='list-loadmore-more'
          to={`/ver-reembolso/${encodeURIComponent(
            AES.encrypt(Cod_Reembolso.toString(), llave).toString()
          )}`}
        >
          <Space>
            <EyeOutlined />
            Ver
          </Space>
        </Link>,
      ];
    }
  };

  return {
    isWeb,
    actions,
    filteredData,
    selectedState,
    setSelectedState,
    selectedDates,
    setSelectedDates,
    actualPage,
    setActualPage,
  };
}
