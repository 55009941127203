import { Card, Col, DatePicker, Form, Row, Select, Table, Divider, Spin, Button } from "antd";
import ComponentChart from "components/indicadores/ComponentChart";
import TableReport from "components/indicadores/TableReport";

import { useCumplimientoBilleteate } from "hooks/indicadores/billeteate/useCumplimientoBilleteate";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";

import "jspdf-autotable";

import { SiAdobeacrobatreader } from "react-icons/si";
import { ButtonExcel } from "../../components/otros/buttons";

const CumplimientoBilleteate = () => {
  const {
    handleClickDownload,
    handleTableChange,
    handleChangeType,
    dataBilleteateDetails,
    headTableDetails,
    form,
    handleChangeYear,
    rowSelection,
    tipos,
    dataInicial,
    disabledDate,
    dataBilleteate,
    headTable,
    dataBilleteateDetailsGraph,
    headTableDependiente,
    dataBilleteateUser,
    dataDetailsUser,
    dataDetailGraph,
    headTableBodega,
    headTableBodegHistory,
    fnHandleDownloadPdf,
    fnDownloadPDFBodegaGeneral,
    CustomXAxisTick,
    dataBilleteateGeneral,
    headTableGeneral,
    rowSelectionGeneral,
    fnDownloadPDFBodega,
  } = useCumplimientoBilleteate();

  return (
    <Card>
      <Form initialValues={dataInicial} form={form}>
        <Row gutter={[10, 10]}>
          <Col xs={24} md={12}>
            <Form.Item label='Filtrar por' name='tipos'>
              <Select options={tipos} onChange={handleChangeType} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label='Año' name='year'>
              <DatePicker
                onChange={handleChangeYear}
                style={{ width: "100%" }}
                picker='year'
                placeholder='Selecciona un mes'
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {!dataBilleteateGeneral && !dataBilleteateUser && (
        <div style={{ textAlign: "center" }}>
          <div style={{ margin: "0 auto" }}>
            <Spin />
          </div>
        </div>
      )}
      {dataBilleteateGeneral && (
        <Row>
          <Col xs={24} md={14}>
            <>
              <Table
                size='small'
                loading={dataBilleteateGeneral ? false : true}
                pagination={false}
                columns={headTableGeneral}
                dataSource={dataBilleteateGeneral ? dataBilleteateGeneral : []}
                bordered
                scroll={{ y: "36vh", x: 600 }}
                rowSelection={rowSelectionGeneral}
              />
              <Divider style={{ marginTop: "10px", marginBottom: "10px" }}></Divider>

              <Button onClick={() => fnDownloadPDFBodegaGeneral()} icon={<SiAdobeacrobatreader color='red' />}>
                DESCARGAR
              </Button>
            </>
          </Col>
          <Col xs={24} md={10}>
            <div>
              <h4 style={{ textAlign: "center" }}>
                <b>PROMEDIO DE CUMPLIMIENTO DE PLAN BILLETÉATE</b>
              </h4>
            </div>
            <ComponentChart
              columnTable={dataBilleteateGeneral && dataBilleteateGeneral}
              grafica={"mes"}
              tamaño={360}
              angulo={-45}
              graph={["ventas", "billetéate"]}
              setShowModal={() => null}
              dataKey={"cumplimiento"}
              opciones={false}
              id='myChart2'
            />
          </Col>
          <Divider style={{ marginTop: "0px", marginBottom: "0px" }}></Divider>
        </Row>
      )}
      {dataBilleteate && (
        <Row>
          <Col xs={24} md={24}>
            <>
              <Table
                size='small'
                loading={dataBilleteate ? false : true}
                pagination={false}
                columns={headTable}
                dataSource={dataBilleteate ? dataBilleteate : []}
                bordered
                scroll={{ y: "36vh", x: 900 }}
                rowSelection={rowSelection}
                onChange={handleTableChange}
              />
              <Divider style={{ marginTop: "10px", marginBottom: "10px" }}></Divider>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Button onClick={() => fnDownloadPDFBodega()} icon={<SiAdobeacrobatreader color='red' />}>
                  DESCARGAR
                </Button>
                <div style={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
                  <ButtonExcel buttonProps={{ onClick: handleClickDownload, style: { width: "auto", background: "green" } }}>
                    Descargar
                  </ButtonExcel>
                </div>
              </div>
            </>
          </Col>
        </Row>
      )}
      {dataBilleteateUser && (
        <Row gutter={[10, 10]}>
          <Col xs={24} md={12}>
            <>
              <Table
                size='small'
                loading={dataBilleteateUser ? false : true}
                pagination={false}
                columns={headTableDependiente}
                dataSource={dataBilleteateUser ? dataBilleteateUser : []}
                bordered
                scroll={{ y: "38vh", x: 40 }}
                rowSelection={rowSelection}
              />
              <Button onClick={() => fnHandleDownloadPdf("myChartUser")} icon={<SiAdobeacrobatreader color='red' />}>
                DESCARGAR
              </Button>
            </>
          </Col>
          <Col xs={24} md={12}>
            <div id='myChartUser'>
              <div>
                <h2 style={{ textAlign: "center" }}>Top en billetéate</h2>
              </div>
              <ResponsiveContainer width='100%' height={320}>
                <BarChart
                  data={dataBilleteateUser.slice(0, 7)}
                  layout='vertical'
                  margin={{ top: 0, right: 30, left: 35, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis type='number' tick={<CustomXAxisTick />} />
                  <YAxis
                    dataKey='nombres'
                    type='category'
                    tickFormatter={(value) => {
                      const palabras = value.split(" ");
                      if (palabras.length >= 2) return `${palabras[0]} ${palabras[1]}`;
                      return value;
                    }}
                  />
                  <Tooltip />
                  <Legend style={{ marginBottom: "0px" }} />
                  <Bar dataKey='cumplimiento' fill='#8884d8' barSize={15} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
      )}
      <Divider style={{ marginTop: "5px" }}></Divider>
      {dataBilleteateDetailsGraph && (
        <Row gutter={[10, 10]}>
          <Col xs={24} md={24} lg={12}>
            <>
              <TableReport
                headTable={headTableDetails}
                columnTable={dataBilleteateDetails}
                muestra={false}
                exportToExcel={() => null}
                isSelect={true}
              />
              <Divider style={{ marginTop: "10px", marginBottom: "10px" }}></Divider>

              <Button onClick={() => fnHandleDownloadPdf()} icon={<SiAdobeacrobatreader color='red' />}>
                DESCARGAR
              </Button>
            </>
          </Col>
          <Col xs={24} md={24} lg={12}>
            <div>
              <h4 style={{ textAlign: "center" }}>
                <b>PLAN BILLETÉATE DEPENDIENTES EN BODEGA</b>
              </h4>
            </div>
            <ComponentChart
              columnTable={dataBilleteateDetailsGraph && dataBilleteateDetailsGraph}
              grafica={"dependiente"}
              tamaño={330}
              angulo={-45}
              graph={["total", "billetéate"]}
              setShowModal={() => null}
              dataKey={"cumplimiento"}
              opciones={false}
            />
          </Col>
        </Row>
      )}
      {dataDetailsUser && (
        <Row gutter={[10, 10]}>
          <Col xs={24} md={24} lg={12}>
            <Table
              title={() => (
                <strong>
                  DETALLES BODEGA(S) {form.getFieldsValue().mes}-{form.getFieldsValue().year.format("YYYY")}
                </strong>
              )}
              size='small'
              loading={dataBilleteateUser ? false : true}
              pagination={false}
              columns={headTableBodega}
              dataSource={dataDetailsUser ? dataDetailsUser : []}
              bordered
              scroll={{ y: "38vh", x: 40 }}
            />
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }}></Divider>

            <TableReport
              title={`DETALLES HISTÓRICO ${form.getFieldsValue().year.format("YYYY")}`}
              headTable={headTableBodegHistory}
              columnTable={dataDetailGraph}
              muestra={false}
              exportToExcel={() => null}
              isSelect={true}
              y={250}
            />
            <Divider style={{ marginTop: "10px", marginBottom: "0" }}></Divider>

            <Button onClick={() => fnHandleDownloadPdf("myChartUserDetails")} icon={<SiAdobeacrobatreader color='red' />}>
              DESCARGAR
            </Button>
          </Col>

          <Col xs={24} md={24} lg={12}>
            <div id='myChartUserDetails'>
              <div>
                <h2 style={{ textAlign: "center" }}>
                  <b>HISTORIAL DEL {form.getFieldsValue().year.format("YYYY")}</b>
                </h2>
              </div>
              <ComponentChart
                columnTable={dataDetailGraph && dataDetailGraph}
                grafica={"mes"}
                tamaño={380}
                angulo={-45}
                graph={["ventas", "billetéate"]}
                setShowModal={() => null}
                dataKey={"cumplimiento"}
                opciones={false}
              />
            </div>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default CumplimientoBilleteate;
