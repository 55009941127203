import React from "react"
import { Report } from "components/reactreport/Report"
import { View, Text, StyleSheet } from "@react-pdf/renderer"
import { Table as TableReport } from "components/reactreport/Table"
import type { columnsTablePDF } from "components/reactreport/Table"
import { Title } from "components/reactreport/Title"

const styles = StyleSheet.create({
  title: { paddingTop: "3px" },
  logo: { width: "40%", margin: "0 auto 0 auto", objectFit: "scale-down" },
  columnTable: { borderRight: "1px solid #f0f0f0", fontSize: "9px" },
  row: { display: "flex", flexDirection: "row", alignItems: "flex-start", paddingBottom: "5px" },
  column: { fontSize: "8px", flexBasis: `20%` },
  columnTitle: { fontFamily: "Helvetica-Bold", fontSize: "8px", flexBasis: `10%` },
})

export function ReportSolicitudFundas(DataSolicitud:any){
  const data=DataSolicitud.DataSolicitud
  const columnsTable: columnsTablePDF[] = [
    {
      title: "N",
      dataIndex: "key",
      styleColumnHeader: { ...styles.columnTable, flexBasis: "10%", textAlign: "center",fontSize:'10px' },
      styleColumn: { ...styles.columnTable, flexBasis: "10%", textAlign: "right", backgroundColor: "white", fontSize:'10px' },
    },
    {
      title: "Descripción",
      dataIndex: "nombre_funda",
      styleColumnHeader: { ...styles.columnTable, flexBasis: "50%",fontSize:'10px' },
      styleColumn: { ...styles.columnTable, flexBasis: "50%", textAlign: "left", backgroundColor: "white", fontSize:'10px' },
    },
    {
      title: "Cantidad en Paquetes",
      dataIndex: "cantidad",
      styleColumnHeader: { ...styles.columnTable, flexBasis: "40%",fontSize:'10px' },
      styleColumn: { ...styles.columnTable, flexBasis: "40%", backgroundColor: "white",fontSize:'10px' },
    },
  ]

  return (
    <>
      <Report
        documentProps={{
          title: `Solicitud de Fundas por Farmacia`,
        }}
        pageProps={{ size: "A4" }}
      >
        <Title style={{ fontSize: "12px",marginBottom:'20px'}}>Reporte de Solicitudes Aprobadas</Title>
        {data.map((item: any, key:any) => 
          <>
            <View key={"view1"+item.key} style={{ ...styles.row, justifyContent: "center" }}>
              <Text key={"textnumfarmacia"+item.key} style={{ fontSize: "10px", fontFamily: "Helvetica-Bold", textAlign: "center" }}>
                 {'Farmacia San Gregorio N° '+item.key}
              </Text>
            </View>
{/*             <View key={"datos"+item.key} style={styles.row}>
              <Text key={"datos1"+item.key} style={styles.columnTitle}>Fecha:</Text>
              <Text key={"datos2"+item.key} style={{ ...styles.column, flexBasis: "15%" }}>{item.fecha_solicitud}</Text>
              <Text key={"datos3"+item.key} style={{ ...styles.columnTitle, flexBasis: "15%" }}>Solicitado por:</Text>
              <Text key={"datos4"+item.key} style={{ ...styles.column, flexBasis: "35%" }}>{item.usuario_solicita}</Text>
              <Text key={"datos5"+item.key} style={{ ...styles.columnTitle, flexBasis: "20%" }}>Total de Paquetes:</Text>
              <Text key={"datos6"+item.key} style={{ ...styles.column, flexBasis: "10%" }}>{item.total_paquete}</Text>
            </View> */}
            <TableReport key={"table"+item.key} styleRow={{ width: "100%", fontSize:'10px', padding:'0px'}} styleRowHeader={{fontSize:'10px'}} columns={columnsTable} dataSource={item.detalle} />
            <View key={"espacio"+item.key} style={{...styles.row, marginBottom:'25px'}}>
              <Text key={"datos1"+item.key} style={styles.columnTitle}>Estado:</Text>
              <Text key={"datos2"+item.key} style={{ ...styles.column, flexBasis: "15%" }}>{item.estado}</Text>
            </View>
          </>
        )}
      </Report>
    </>
  )
}
