import { Button, Result, ResultProps } from "antd"
import { Link } from "react-router-dom"

type Props = {
  title?: ResultProps["title"]
  subTitle?: ResultProps["subTitle"]
}

export const Unauthorized = ({ title, subTitle }: Props) => (
  <Result
    status="403"
    title={title ?? "No autorizado/a"}
    subTitle={subTitle ?? "Lo siento, no tiene acceso a ésta página."}
    extra={
      <Button type="primary">
        <Link to="/">Regresar a inicio</Link>
      </Button>
    }
  />
)
