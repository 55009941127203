import { Card, Col, DatePicker, Form, Row, Select, Spin, Divider } from "antd";
import ComponentChart from "components/indicadores/ComponentChart";
import TableReportVirtual from "components/otros/TableReportVirtual";
import { ButtonExcel } from "components/otros/buttons";
import { useVentasBilleteateProductos } from "hooks/indicadores/useVentasBilleteateProductos";
import moment from "moment";
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const VentasBilleteateProductos = () => {
  const {
    headTableDependientes,
    nameProduct,
    rowSelection,
    disabledDate,
    handleChangeDate,
    form,
    headTableMain,
    laboratorios,
    tableMain,
    handleSelectLaboratorio,
    loader,
    headTableBodegas,
    tableBodegas,
    dataProvincias,
    dataSupervisores,
    handleClickDownloadProductos,
    rowSelectionTblBodegas,
    tableDependientes,
    nameBodega,
    handleClickDownloadBodegas,
  } = useVentasBilleteateProductos();

  return (
    <Card>
      <Form
        form={form}
        initialValues={{
          cod_laboratorio: 0,
          fecha: [moment().subtract(1, "month").subtract(1, "days"), moment().subtract(1, "days")],
        }}
      >
        <Row gutter={[10, 5]}>
          <Col xs={24} md={12}>
            <Form.Item label='Seleccione una fecha:' name='fecha'>
              <DatePicker.RangePicker
                format='YYYY-MM-DD'
                disabledDate={disabledDate}
                style={{ width: "100%" }}
                onChange={handleChangeDate}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label='Laboratorios:' name='cod_laboratorio'>
              <Select
                onChange={handleSelectLaboratorio}
                showSearch
                options={laboratorios}
                optionFilterProp='label'
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row gutter={[10, 10]}>
        <Col xs={24} md={12}>
          {!loader ? (
            <>
              <TableReportVirtual
                title='Productos billetéate'
                headTable={headTableMain}
                columnTable={tableMain}
                rowSelection={rowSelection}
                exportToExcel={() => null}
                isSelect={true}
                muestra={false}
                x={500}
                y={260}
                isTotal={false}
              />
              <div style={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
                <ButtonExcel
                  buttonProps={{ onClick: handleClickDownloadProductos, style: { width: "auto", background: "green" } }}
                >
                  Descargar
                </ButtonExcel>
              </div>
            </>
          ) : (
            <Spin />
          )}
        </Col>
        <Col xs={24} md={12}>
          {!loader && (
            <>
              <h2 style={{ textAlign: "center" }}>Top en cantidad vendido</h2>
              <ResponsiveContainer>
                <ComponentChart
                  columnTable={tableMain.slice(0, 12)}
                  grafica={"key"}
                  tamaño={390}
                  angulo={-45}
                  graph={["cantidad_vendido", "valor_vendido"]}
                  dataKey={"farmacias"}
                  setShowModal={() => {}}
                />
              </ResponsiveContainer>
            </>
          )}
        </Col>
      </Row>
      <Divider style={{ marginTop: "-10px" }} />
      {tableBodegas.length > 0 && (
        <Row gutter={[5, 5]}>
          <Col xs={24} md={14}>
            <TableReportVirtual
              title={nameProduct}
              headTable={headTableBodegas}
              columnTable={tableBodegas}
              rowSelection={rowSelectionTblBodegas}
              exportToExcel={() => null}
              isSelect={true}
              muestra={false}
              x={500}
              y={370}
              isTotal={false}
            />
            <div style={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
              <ButtonExcel
                buttonProps={{ onClick: handleClickDownloadBodegas, style: { width: "auto", background: "green" } }}
              >
                Descargar
              </ButtonExcel>
            </div>
          </Col>
          <Col xs={24} md={10}>
            <Row gutter={[10, 0]} justify='center'>
              <Col xs={24}>
                <ResponsiveContainer>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "80%" }}>
                    <h2 style={{ textAlign: "center" }}>Supervisores</h2>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90%" }}>
                      <RadarChart outerRadius={75} width={480} height={220} data={dataSupervisores}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey='supervisor' tick={{ fontSize: "9px" }} />
                        <PolarRadiusAxis />
                        <Tooltip />
                        <Legend />
                        <Radar
                          name='Cantidad Vendida'
                          dataKey='cantidad'
                          stroke='#007bff'
                          fill='#007bff'
                          fillOpacity={0.5}
                        />
                        <Radar name='Valor Vendido' dataKey='total' stroke='#28a745' fill='#28a745' fillOpacity={0.5} />
                      </RadarChart>
                    </div>
                  </div>
                </ResponsiveContainer>
              </Col>
              <Col xs={24}>
                <ResponsiveContainer>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "80%" }}>
                    <h2 style={{ textAlign: "center" }}>Provincias</h2>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90%" }}>
                      <RadarChart outerRadius={75} width={480} height={220} data={dataProvincias}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey='provincia' tick={{ fontSize: "9px" }} />
                        <PolarRadiusAxis />
                        <Tooltip />
                        <Legend />
                        <Radar
                          name='Cantidad Vendido'
                          dataKey='cantidad'
                          stroke='#007bff'
                          fill='#007bff'
                          fillOpacity={0.5}
                        />
                        <Radar name='Valor Vendido' dataKey='total' stroke='#28a745' fill='#28a745' fillOpacity={0.5} />
                      </RadarChart>
                    </div>
                  </div>
                </ResponsiveContainer>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Divider style={{ marginTop: "0px" }} />
      {tableDependientes.length > 0 && (
        <Row gutter={[5, 5]}>
          <Col xs={24} md={14}>
            <TableReportVirtual
              title={nameBodega}
              headTable={headTableDependientes}
              columnTable={tableDependientes}
              exportToExcel={() => null}
              isSelect={false}
              muestra={false}
              x={500}
              y={450}
              isTotal={false}
            />
          </Col>
          <Col xs={24} md={10}>
            {!loader && (
              <>
                <h2 style={{ textAlign: "center" }}>Dependientes</h2>
                <ResponsiveContainer>
                  <ComponentChart
                    columnTable={tableDependientes}
                    grafica={"id"}
                    tamaño={220}
                    angulo={-25}
                    graph={["total", "cantidad"]}
                    dataKey={""}
                    setShowModal={() => {}}
                  />
                </ResponsiveContainer>
              </>
            )}
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default VentasBilleteateProductos;
