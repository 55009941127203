import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useEffect, useContext, useCallback, useState } from "react";
import { url } from "config/constants";
import type { ColumnsType } from "antd/es/table";
import { Form } from "antd";
import Swal from "sweetalert2";
import { useSearchTable } from "hooks/otros/useSearchTable";

interface PropRequest {
  msg: boolean;
  data: Variacion[];
  data2: ProsSelect[];
}

interface ProsSelect {
  value: number;
  label: string;
  orden: number;
}

interface Variacion {
  key: number;
  siglas: string;
  fecha: string;
  nombres: string;
  Descripcion: string;
  tipo: string;
  precio_anterior: number;
  precio_nuevo: number;
}

const options = [
  {
    value: 0,
    label: "PENDIENTES",
  },
  {
    value: 1,
    label: "APROBADOS",
  },
];

const breadcrumbs = [{ Label: "Cambio de precios" }];

export const useCambiosPrecios = () => {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const [dateTable, setDateTable] = useState<Variacion[]>([]);
  const [spinning, setSpinning] = useState<boolean>(false);
  const [valueSelect, setValueSelect] = useState<number>(0);
  const { getColumnSearchProps } = useSearchTable<Variacion>();
  const [laboratorios, setLaboratorios] = useState<ProsSelect[]>([]);
  const [selectKey, setSelectKey] = useState<(string | number)[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  let { optionsPost } = useLocalStorage();

  useBreadCrumbs(breadcrumbs);

  const changeSelect = (value: number) => {
    form.setFieldsValue({ cod_lab: 0 });
    setValueSelect(value);
    setSelectKey([]);
  };

  const handleData = useCallback(
    async (value: number, cod_laboratorio: number = 0) => {
      try {
        setSpinning(true);
        const respuesta: PropRequest = await fetch(
          url + "apifsg-pr/productos/cambio-precios",
          optionsPost({ tipo: value, cod_laboratorio })
        ).then((data) => data.json());

        if (respuesta.msg) {
          setDateTable(respuesta.data);
          setLaboratorios(respuesta.data2);
        } else {
          Swal.fire("Error", "Ocurrio un error en la información", "error");
          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        return { msg: "no_conexion", status: false };
      }
      setSpinning(false);
    },
    [optionsPost]
  );

  useEffect(() => {
    setOpenKeys(["Productos", "Productos"]);
    setSelectedKeys(["Productos/Cambio de precios"]);
  }, [setOpenKeys, setSelectedKeys]);

  const head: ColumnsType<Variacion> = [
    // {
    //   title: "ID",
    //   dataIndex: "key",
    //   key: "key",
    //   width: "56px",
    //   render: (key) => `P0${key}`,
    // },
    {
      title: "Producto",
      dataIndex: "Descripcion",
      key: "Descripcion",
      ...getColumnSearchProps("Descripcion"),
    },
    {
      title: "Lab",
      dataIndex: "siglas",
      key: "siglas",
      width: "67px",
      ...getColumnSearchProps("siglas"),
    },
    {
      title: "Fecha",
      dataIndex: "Fecha",
      key: "Fecha",
      width: "159px",
    },
    {
      title: valueSelect === 0 ? "Usuario" : "Usuario Aprobó",
      dataIndex: valueSelect === 0 ? "Nombres" : "Aprobó",
      key: "Nombres",
      width: "240px",
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
      width: "50px",
    },
    {
      title: "Precio Anterior",
      dataIndex: "precio_anterior",
      key: "precio_anterior",
      align: "right",
      width: "130px",
    },
    {
      title: "Precio Nuevo",
      dataIndex: "precio_nuevo",
      key: "precio_nuevo",
      align: "right",
      width: "130px",
    },
    // {
    //   title: "▢",
    //   dataIndex: "accion",
    //   key: "accion",
    //   align: "center",
    //   width: "50px",
    //   render: (_, record: Variacion) => (
    //     <Checkbox
    //       checked={valueSelect === 0 ? false : true}
    //       onChange={(e: CheckboxChangeEvent) => handleChange(e, record)}
    //       disabled={valueSelect === 0 ? false : true}
    //     ></Checkbox>
    //   ),
    // },
  ];

  const onChangeSelectKey = (selectedRowKeys: (string | number)[]) => {
    setSelectKey(selectedRowKeys);
  };

  const changeLaboratorio = (value: number) => {
    setDateTable([]);
    setSelectKey([]);
    handleData(valueSelect, value);
  };

  const sendInfo = async () => {
    try {
      if (selectKey.length <= 0) return Swal.fire("Advertencia", "No tienes selecciones por guardar", "warning");
      setLoading(true);
      const respuesta = await fetch(
        url + "apifsg-pr/productos/aprobar-cambio",
        optionsPost({ id_variacion: selectKey })
      ).then((data) => data.json());

      if (respuesta.msg) {
        Swal.fire("Correcto", "Se guardó correctamente la variación de precio", "success");

        if (selectKey.length < dateTable.length) handleData(valueSelect, form.getFieldsValue().cod_lab);
        else if (selectKey.length === dateTable.length) {
          form.setFieldsValue({ cod_lab: 0 });
          handleData(valueSelect);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire("Error", "Ocurrio un error al intentar guardar", "error");
    }
  };

  useEffect(() => {
    handleData(valueSelect, form.getFieldsValue().cod_lab);
  }, [handleData, valueSelect, form]);

  return {
    options,
    dateTable,
    head,
    spinning,
    changeSelect,
    laboratorios,
    changeLaboratorio,
    form,
    selectKey,
    onChangeSelectKey,
    sendInfo,
    valueSelect,
    loading,
  };
};
