import { Col, Row, Card, Divider, Button } from "antd";
import "moment/locale/es";
import { SaveOutlined } from "@ant-design/icons";
import { useControlBodegas } from "hooks/inventarios/useControlBodegas";
import TransferComponent from "components/otros/TransferComponent";

export function ControlBodegas() {
  const { dataTransfer, handleChange, productsKeys, handleSendInfo, loading } = useControlBodegas();

  return (
    <Row>
      <Col xs={24}>
        <div style={{ margin: "10px" }}>
          <Card title="Control de bodegas para ajuste de inventario">
            <Divider style={{ margin: "10px" }} />
            <TransferComponent
              dataTransfer={dataTransfer}
              productsKeys={productsKeys}
              handleChange={handleChange}
              height={390}
              titles={["Bloqueadas", "Activas"]}
            />
            <Divider style={{ margin: "10px" }} />
            <Button
              onClick={handleSendInfo}
              type="primary"
              style={{
                width: "100%",
              }}
              loading={loading}
              icon={<SaveOutlined className="iconbutton" />}
            >
              GUARDAR
            </Button>
          </Card>
        </div>
      </Col>
    </Row>
  );
}

export default ControlBodegas;
