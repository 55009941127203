import { useCallback, useEffect } from 'react'
import useSWR from 'swr'

type responseData<T> = {
  msg: 'ok' | 'err'
  data: T
}

type CallbackFetch<T> = ((data?: T,error?: Error) => void)

export function useFetchInEffect<T>(url: string, options: RequestInit | undefined = undefined, callback: CallbackFetch<T> ) {

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url,options)
        if (response.ok) {
          const json: responseData<T> = await response.json()

          if(json.msg === 'ok') 
            callback(json.data, undefined)
          else 
            callback(undefined,new Error(json.data as unknown as string))
            
        } else {
          callback(undefined, new Error(response.statusText))
        }
      } catch (error) {
        callback(undefined, error as Error)
      }
    }
    fetchData()
  },[url, options, callback])
}

export function useFetchInFunction<T>(url: string, options: ((value: string, token: HeadersInit) => RequestInit) | RequestInit | undefined, callback: CallbackFetch<T> ) {

  const fetchData = useCallback((data: any, tok: HeadersInit) => {
    const dataFetchAsync = async (value: any, token: HeadersInit) => {
      try {
        const response = await fetch(url,typeof options === 'function' ? options(value, token) : options)
        if (response.ok) {
          const json: responseData<T> = await response.json()

          if(json.msg === 'ok') 
            callback(json.data, undefined)
          else 
            callback(undefined,new Error(json.data as unknown as string))
        } else {
          callback(undefined, new Error(response.statusText))
        }
      } catch (error) {
        callback(undefined, error as Error)
      }
    }
    dataFetchAsync(data, tok)
  },[callback, options, url])

  return { fetchData }
}

export function useFetchSWR<T>(url: string, options: RequestInit) {
  const { data, error} = useSWR<responseData<T>, Error>([url, options])

  return { 
    data: data?.data,
    isLoading: !data && !error,
    isError: Boolean(error)
  }
}