import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useCallback, useContext, useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import { url_interna, optionsPOST } from "config/constants";

import { url } from "config/constants";
import { Button, Form, notification } from "antd";
import { GrView } from "react-icons/gr";
import { useSearchTable } from "hooks/otros/useSearchTable";
import { useExport } from "hooks/otros/useExport";

const breadcrumbs = [{ Label: "Tareas farmacias" }];

interface PropsResponseNovedades {
  msg: boolean;
  data: PropsDataTable[];
}

interface PropsDataTable {
  key: number;
  crea: string;
  observacion: string;
  fecha_guarda: string;
  render: any;
}

interface PropsDataTableDetails {
  key: number;
  bodega: string;
  bodegaDevolucion: number;
  bodegaDistribucion: number;
  cajasSolicitadas: number;
  cod_producto: number;
  descripcion: string;
  llegoCaducado: number;
  llegoMasCantidad: number;
  llegoMenosCantidad: number;
  novedades_agregadas: string;
  siglas: string;
  llegaron: number;
}

interface PropsModal {
  data: string;
}

interface PropsResponseModal {
  msg: boolean;
  data: PropsModal[];
}

interface PropsResponseDetails {
  msg: boolean;
  data: PropsDataTableDetails[];
}

export const useNovedadesBodegas = () => {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const [dataTableTask, setDataTableTask] = useState<PropsDataTable[] | null>(null);
  const [dataTableTaskDetails, setDataTableTaskDetails] = useState<PropsDataTableDetails[] | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dataLaboratorios, setDataLaboratorios] = useState([]);
  const [dataBodegas, setDataBodegas] = useState([]);
  const [dataNovedades, setDataNovedades] = useState([]);
  const [cod_tarea, setCod_tarea] = useState<number>();
  const { exportToExcel } = useExport();

  const [form] = Form.useForm();

  const { getColumnSearchProps } = useSearchTable<PropsDataTableDetails>();

  useBreadCrumbs(breadcrumbs);
  let { optionsGet } = useLocalStorage();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderActions: PropsDataTable["render"] = (record: PropsDataTable) => (
    <Button
      icon={<GrView style={{ fontSize: "1.3rem" }} />}
      type='text'
      onClick={() => handleClickAction(record)}
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      block
    />
  );

  const handleClickAction = async (record: PropsDataTable) => {
    try {
      setDataLaboratorios([]);
      setDataBodegas([]);
      setDataTableTaskDetails(null);
      setDataNovedades([]);
      showModal();
      setCod_tarea(record.key);
      const respuesta: PropsResponseModal = await fetch(
        `${url_interna}api/productos/novedades-data`,
        optionsPOST(JSON.stringify({ cod_tarea: record.key }), { "api-token": "apitoken" })
      ).then((data) => data.json());

      if (respuesta.msg) {
        let info = JSON.parse(respuesta.data[0].data);
        info[0].laboratorios.unshift({ value: 0, label: "TODOS" });
        info[0].bodegas.unshift({ value: -1, label: "TODAS" });
        info[0].tipos.unshift({ value: 0, label: "TODAS" });
        setDataLaboratorios(info[0].laboratorios);
        setDataBodegas(info[0].bodegas);
        setDataTableTaskDetails(info[0].novedades);
        setDataNovedades(info[0].tipos);
        form.setFieldsValue({ cod_laboratorio: 0, cod_bodega: -1, cod_novedad: 0 });
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  };

  const columnsTableTask: ColumnsType<PropsDataTable> = [
    {
      title: "Fecha Creación",
      dataIndex: "fecha_guarda",
      key: "fecha_guarda",
      width: "180px",
    },
    {
      title: "Usuario",
      dataIndex: "crea",
      key: "crea",
    },
    {
      title: "Observación",
      dataIndex: "observacion",
      key: "observacion",
    },
    {
      title: "Ver",
      align: "center",
      width: "50px",
      render: renderActions,
    },
  ];

  const columnsTableDetailsTask: ColumnsType<PropsDataTableDetails> = [
    {
      title: "Farmacia",
      dataIndex: "bodega",
      key: "bodega",
      width: "200px",
      ...getColumnSearchProps("bodega"),
      render: (text: string) => (
        <div className='ellipsis-column' title={text}>
          {text}
        </div>
      ),
    },
    {
      title: "Producto",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "230px",
      ...getColumnSearchProps("descripcion"),
      render: (text: string) => (
        <div className='ellipsis-column-250' title={text}>
          {text}
        </div>
      ),
    },
    {
      title: "Lab",
      dataIndex: "siglas",
      key: "siglas",
      width: "80px",
      ...getColumnSearchProps("siglas"),
    },
    {
      title: "Solicitadas",
      dataIndex: "cajasSolicitadas",
      key: "cajasSolicitadas",
      width: "70px",
    },
    {
      title: "Novedades",
      dataIndex: "novedades_agregadas",
      key: "novedades_agregadas",
      width: "120px",
      ...getColumnSearchProps("novedades_agregadas"),
      render: (text: string) => (
        <div className='scroll-column-scroll-150 ' title={text}>
          {text}
        </div>
      ),
    },
    {
      title: "Distribución",
      dataIndex: "bodegaDistribucion",
      key: "bodegaDistribucion",
      width: "80px",
      sorter: (a, b) => a.bodegaDistribucion - b.bodegaDistribucion,
    },
    {
      title: "Devolución",
      dataIndex: "bodegaDevolucion",
      key: "bodegaDevolucion",
      width: "75px",
      sorter: (a, b) => a.bodegaDevolucion - b.bodegaDevolucion,
    },
    {
      title: "Caducado",
      dataIndex: "llegoCaducado",
      key: "llegoCaducado",
      width: "68px",
      sorter: (a, b) => a.llegoCaducado - b.llegoCaducado,
    },
    {
      title: "Menos",
      dataIndex: "llegoMenosCantidad",
      key: "llegoMenosCantidad",
      width: "52px",
      sorter: (a, b) => a.llegoMenosCantidad - b.llegoMenosCantidad,
    },
    {
      title: "Más",
      dataIndex: "llegoMasCantidad",
      key: "llegoMasCantidad",
      width: "41px",
      sorter: (a, b) => a.llegoMasCantidad - b.llegoMasCantidad,
    },
  ];

  useEffect(() => {
    setOpenKeys(["Productos", "Productos"]);
    setSelectedKeys(["Productos/Tareas farmacias"]);
  }, [setOpenKeys, setSelectedKeys]);

  const loadDataTableInitial = useCallback(async () => {
    try {
      const respuesta: PropsResponseNovedades = await fetch(url + "apifsg-pr/productos/novedades", optionsGet).then((data) => data.json());
      if (respuesta.msg) {
        setDataTableTask(respuesta.data);
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsGet]);

  const onChangeOptionBogedas = (value: number) => {
    handleChangesOption(cod_tarea, form.getFieldsValue().cod_laboratorio, value, form.getFieldsValue().cod_novedad);
  };

  const onChangeOptionLaboratorios = (value: number) => {
    handleChangesOption(cod_tarea, value, form.getFieldsValue().cod_bodega, form.getFieldsValue().cod_novedad);
  };

  const onChangeOptionTipos = (value: number) => {
    handleChangesOption(cod_tarea, form.getFieldsValue().cod_laboratorio, form.getFieldsValue().cod_bodega, value);
  };

  const handleChangesOption = useCallback(
    async (cod_tarea: number | undefined, cod_laboratorio: number, cod_bodega: number, cod_novedad: number | string) => {
      try {
        setDataTableTaskDetails(null);
        const respuesta: PropsResponseDetails = await fetch(
          `${url_interna}api/productos/novedades-data-opciones`,
          optionsPOST(JSON.stringify({ cod_tarea, cod_laboratorio, cod_bodega, cod_novedad }), { "api-token": "apitoken" })
        ).then((data) => data.json());

        if (respuesta.msg) {
          if (!respuesta.data) {
            setDataTableTaskDetails([]);
            return notification.warning({
              message: "Advertencia",
              description: "No hay información para este filtro",
            });
          }
          setDataTableTaskDetails(respuesta.data);
        }
      } catch (error) {}
    },
    []
  );
  const handleClickDownloadNovedades = () => {
    dataTableTaskDetails && exportToExcel(dataTableTaskDetails, "Lista de novedades");
  };

  useEffect(() => {
    loadDataTableInitial();
  }, [loadDataTableInitial]);

  return {
    handleClickDownloadNovedades,
    onChangeOptionBogedas,
    dataTableTask,
    columnsTableTask,
    isModalOpen,
    handleOk,
    handleCancel,
    dataLaboratorios,
    dataBodegas,
    dataTableTaskDetails,
    columnsTableDetailsTask,
    dataNovedades,
    form,
    onChangeOptionLaboratorios,
    onChangeOptionTipos,
  };
};
