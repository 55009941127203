import { Col, Row, Tree, Select, SelectProps, Input } from "antd"
import { useCallback, useEffect, useMemo, useState } from "react"

import { url } from "config/constants"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { useSwal } from "hooks/otros/useSwal"
import { useLayout } from "hooks/otros/useLayout"
import { DataNode, TreeProps } from "antd/lib/tree"
import { ButtonSave } from "components/otros/buttons"
import { SearchProps } from "antd/lib/input"
// import { type Perfiles, SubTableUsuarioPerfil } from "components/configuraciones/sub-table-usuario-perfil"

type PerfilesConMenu = {
  value: number
  label: string
  menu_asignado?: number[]
}

const breadcrumbs = [{ Label: "Configuraciones", Url: "/configuraciones" }, { Label: "Asignar menu a perfil" }]

const openKeys = [breadcrumbs[0].Label]

const { Search } = Input

const getKeysMenu: (menu: DataNode[]) => any = menu => {
  return menu.map(({ key, children }) => [key as number, getKeysMenu((children as DataNode[]) ?? [])].flat()).flat()
}

const getParentKey = (key: React.Key, tree: DataNode[]): React.Key => {
  let parentKey: React.Key
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children)
      }
    }
  }
  return parentKey!
}

const dataList: { key: React.Key; title: string }[] = []
const generateList = (data: DataNode[]) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i]
    const { key, title } = node
    dataList.push({ key, title: title as string })
    if (node.children) {
      generateList(node.children)
    }
  }
}

// const addIconMenu = (menu: DataNode[]): DataNode[] => {
//   return menu.map(({ key, title, children }) => ({
//     key,
//     title,
//     children: children ? addIconMenu(children as DataNode[]) : undefined,
//     icon: (ICONS[title as keyof typeof ICONS] ?? (() => undefined))({ fill: "#000", width: "15px", height: "15px" }),
//   }))
// }

export function AsignarMenuPerfil() {
  useLayout(breadcrumbs, openKeys, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`)

  const [perfiles, setPerfiles] = useState<PerfilesConMenu[]>([])
  const [menu, setMenu] = useState<DataNode[]>([])
  const [perfilSelected, setPerfilSelected] = useState(0)
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([])
  const [searchValue, setSearchValue] = useState("")
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([])

  const { optionsGet, optionsPost } = useLocalStorage()

  const { isError } = useVerifyResponse()

  const { swalError, swalSuccess } = useSwal()

  const getPerfiles = useCallback(async () => {
    try {
      const res = await fetch(`${url}configuraciones/listar-perfiles-menu-asignado`, optionsGet)
      if (isError(res)) throw new Error()

      const result = await res.json()

      setPerfiles(result)
    } catch (e) {
      swalError({ text: "No se pudo obtener los perfiles" })
    }
  }, [isError, optionsGet, swalError])

  const getTreeMenu = useCallback(async () => {
    try {
      const res = await fetch(`${url}configuraciones/listar-tree-menu`, optionsGet)
      if (isError(res)) throw new Error()

      const result: DataNode[] = await res.json()

      setMenu(result)

      generateList(result)
    } catch (e) {
      swalError({ text: "No se pudo obtener el menú" })
    }
  }, [isError, optionsGet, swalError])

  useEffect(() => {
    getPerfiles()
    getTreeMenu()
  }, [getPerfiles, getTreeMenu])

  const handleChangePerfiles: SelectProps["onChange"] = async perfil => {
    setPerfilSelected(perfil)
    setCheckedKeys(perfiles.find(({ value }) => value === perfil)?.menu_asignado ?? [])
  }

  const handleCheck: TreeProps["onCheck"] = checkedKeysValue => {
    if (Array.isArray(checkedKeysValue)) setCheckedKeys(checkedKeysValue)
    else setCheckedKeys(checkedKeysValue.checked)
  }

  const handleChangeSearch: SearchProps["onChange"] = e => {
    const { value } = e.target
    const newExpandedKeys = dataList
      .map(item => {
        if (item.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          return getParentKey(item.key, menu)
        }
        return null
      })
      .filter((item, i, self) => item && self.indexOf(item) === i)

    setExpandedKeys(newExpandedKeys as React.Key[])
    setSearchValue(value)
  }

  const treeData = useMemo(() => {
    const loop = (data: DataNode[]): DataNode[] =>
      data.map(item => {
        const strTitle = item.title as string
        const index = strTitle.toLowerCase().indexOf(searchValue.toLowerCase())
        const beforeStr = strTitle.substring(0, index)
        const afterStr = strTitle.slice(index + searchValue.length)
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span style={{ color: "var(--primary)", fontWeight: "bold" }}>
                {strTitle.substring(index, index + searchValue.length)}
              </span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          )
        if (item.children) {
          return { title, key: item.key, children: loop(item.children) }
        }

        return {
          title,
          key: item.key,
        }
      })

    return loop(menu)
  }, [menu, searchValue])

  const asignarMenu = async () => {
    if (!perfilSelected) return
    try {
      const res = await fetch(
        `${url}configuraciones/asignar-menu-perfil`,
        optionsPost({ perfil: perfilSelected, menu: checkedKeys })
      )
      if (isError(res)) throw new Error()

      const text = await res.json()

      swalSuccess({ text })

      getPerfiles()
    } catch (e) {
      swalError({ text: "No se pudo asignar el menú" })
    }
  }

  return (
    <>
      <Row style={{ padding: "16px", marginLeft: 0, marginRight: 0 }} gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Select
            style={{ width: "100%" }}
            onChange={handleChangePerfiles}
            options={perfiles.map(({ value, label }) => ({ value, label }))}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <ButtonSave buttonProps={{ onClick: asignarMenu }} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Search placeholder="Buscar menú" onChange={handleChangeSearch} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Tree
            checkable
            checkStrictly
            autoExpandParent
            expandedKeys={searchValue ? expandedKeys : getKeysMenu(menu)}
            onCheck={handleCheck}
            selectable={false}
            checkedKeys={checkedKeys}
            treeData={treeData}
            showLine
          />
        </Col>
      </Row>
    </>
  )
}
