import { Button } from "antd";

import { FileAddOutlined,FileDoneOutlined } from "@ant-design/icons";
import { FaRegFilePdf } from "react-icons/fa";

export const ButtonUploadTableImage = () => {
  return (
    <Button
    style={{ fontSize: "1.3rem" }}
    type="text"
      icon={
        <FileAddOutlined 
          style={{
            cursor: "pointer",
            fontSize: "25px",
            color: "#00AAE4",
          }}
        />
      }
    >
    </Button>
  );
};
export const ButtonCheckValido = () => {
  return (
    <Button
    style={{ fontSize: "1.3rem" }}
    type="text"
      icon={
        <FileDoneOutlined
          style={{
            cursor: "pointer",
            fontSize: "25px",
            color: "#00913f",
          }}
        />
      }
    >
    </Button>
  );
};

export const ButtonUploadPDF = () => {
  return (
    <Button
    style={{ fontSize: "1.3rem" }}
  
      icon={
        <FaRegFilePdf 
          style={{
            cursor: "pointer",
            fontSize: "25px",
            color: "#00AAE4",
          }}
        />
      }
    >
    </Button>
  );
};
export const ButtonUploadPDF2 = () => {
  return (
    <Button
    style={{ fontSize: "1.3rem" }}
   
      icon={
        <FaRegFilePdf 
          style={{
            cursor: "pointer",
            fontSize: "25px",
            color: "#00913f",
          }}
        />
      }
    >
    </Button>
  );
};
