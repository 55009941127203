import { Button, Table } from "antd";
import { SiMicrosoftexcel } from "react-icons/si";

interface DataType {
  title: string;
  dataIndex: string;
}

interface TableReportProps {
  headTable: any;
  columnTable: any;
  rowSelection?: any;
  exportToExcel: (data: DataType[], fileName: string) => void;
  isSelect: boolean;
  tableRef?: React.RefObject<HTMLDivElement>;
  muestra?: boolean;
  title?: string;
  y?: number;
  x?: number;
}

const TableReport = ({
  headTable,
  columnTable,
  rowSelection,
  exportToExcel,
  isSelect,
  tableRef,
  muestra = true,
  title,
  y = 320,
  x = 335,
}: TableReportProps) => {
  return (
    <div ref={tableRef}>
      <Table
        title={
          title
            ? () => (
                <div className='scroll-column-scroll-250' title={title}>
                  <strong> {title}</strong>
                </div>
              )
            : undefined
        }
        columns={headTable}
        bordered
        dataSource={columnTable}
        size='small'
        pagination={false}
        scroll={{ y, x }}
        rowSelection={isSelect && rowSelection}
        loading={columnTable?.length >= 0 ? false : true}
        style={{ padding: "-15px" }}
      />
      {muestra && <Button onClick={() => exportToExcel(columnTable, "table-data")} icon={<SiMicrosoftexcel />}></Button>}
    </div>
  );
};

export default TableReport;
