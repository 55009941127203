import { Button, Form, notification } from "antd";
import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";

import { useCallback, useContext, useEffect, useState } from "react";

import type { ColumnProps } from "antd/lib/table";

import "moment/locale/es";
import { url, url_alku } from "config/constants";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { PropsCobros, useTableCobros } from "./useTableCobros";
import { IoAddCircleSharp } from "react-icons/io5";
import { RcFile } from "antd/lib/upload";
import moment from "moment";

const breadcrumbs = [{ Label: "Cobros" }];

export function useCobrosAddDocument() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const [form] = Form.useForm();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [fileListPdf, setFileListPdf] = useState<any>([]);
  const [previewPdf, setPreviewPdf] = useState<string>("");
  const [isModified, setIsModified] = useState(false);
  const [previewVisiblePdf, setPreviewVisiblePdf] = useState(false);
  const [llaveEdit, setLlaveEdit] = useState<string | null>(null);

  //Para modal editar
  const { optionsPost, getItem } = useLocalStorage();

  useEffect(() => {
    setOpenKeys(["Convenios", "Convenios/Administrar"]);
    setSelectedKeys(["Administrar/Cobros"]);
  }, [setOpenKeys, setSelectedKeys]);

  useBreadCrumbs(breadcrumbs);

  const handleClickActionEdit = async (record: PropsCobros) => {
    // if (record.cod_forma_pago === 3)
    //   return notification.warning({
    //     message: "Advertencia",
    //     description: `Cuando el convenio se paga mediante "NOTA DE CRÉDITO", no es necesario cargar el documento de cobro.`,
    //     placement: "bottomRight",
    //   });

    const respuesta1 = await fetch(
      url + "apifsg-pr/reportes/convenio-estado-cobro",
      optionsPost({ cod_cobro: record.key })
    ).then((data) => data.json());

    if (respuesta1.msg && !respuesta1.data[0].estado) {
      await handleLoadPdf(respuesta1.data[0].llave_documento);
      setOpenModal(true);
      form.setFieldsValue({
        ...respuesta1.data[0],
        cod_cobro: record.key,
        proveedor: record.proveedor,
        fecha: moment(respuesta1.data[0].fecha_recibido),
      });
      return;
    }
    if (respuesta1.data[0]?.estado)
      return notification.warning({
        message: "Advertencia",
        description: `El valor del convenio fue procesado`,
        placement: "bottomRight",
      });
    else {
      setOpenModal(true);
      form.setFieldsValue({ proveedor: record.proveedor, valor: record.valor, cod_cobro: record.key });
      return;
    }
  };

  async function fetchPdfAsBlob(url: string) {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  }

  const handleLoadPdf = useCallback(
    async (llave: string) => {
      const url2 = `${url_alku}allku/archivos/${llave}`;
      const pdf = [];
      const blob = await fetchPdfAsBlob(url2);
      const file = new File([blob], llave, { type: blob.type });
      setPreviewPdf(url2);
      setLlaveEdit(llave);
      pdf.push({
        uid: `pdf-${llave}`,
        name: llave,
        url2,
        status: "done",
        response: { llave },
        originFileObj: file,
      });
      setIsModified(false);
      setFileListPdf(pdf);
      form.setFieldsValue({ file: pdf });
    },
    [form]
  );

  const renderActionsEdit: ColumnProps<any>["render"] = (_, record) => (
    <Button
      icon={<IoAddCircleSharp />}
      type='ghost'
      style={{ border: "none", color: "rgb(106 40 49)" }}
      onClick={() => handleClickActionEdit(record)}
    />
  );

  const handleOkCobros = () => {
    setOpenModal(false);
    form.resetFields();
    setFileListPdf([]);
    setPreviewPdf("");
  };

  const handleCancelCobros = () => {
    setOpenModal(false);
    form.resetFields();
    form.setFieldsValue({ file: null });
    setPreviewPdf("");
    setFileListPdf([]);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = (file: RcFile, FileList: RcFile[]): boolean | Promise<void> => {
    return new Promise((resolve, reject) => {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        notification.warning({
          message: "Advertencia",
          description: `Solo se permiten archivos en formato PDF.`,
          placement: "bottomRight",
        });
        return false;
      }
      const isLt5M = file.size / 1024 / 1024 <= 4;
      if (!isLt5M) {
        notification.warning({
          message: "Advertencia",
          description: `El archivo excede el tamaño permitido(4MB)`,
          placement: "bottomRight",
        });
        return false;
      }
      const url = URL.createObjectURL(file);
      setPreviewPdf(url);
      reject(false);
    });
  };
  const handleChangePdf = (info: any) => {
    let fileList = info.fileList;

    const hasModification = fileList?.length > 0 && (fileListPdf?.length === 0 || fileList[0]?.uid !== fileListPdf[0]?.uid);

    if (fileList?.length === 0) {
      setPreviewPdf("");
      setFileListPdf([]);
      setIsModified(hasModification);
      return;
    }
    setFileListPdf(fileList);
    setIsModified(hasModification);
  };

  const sendFile = async (file: File, carpeta: string) => {
    const body = new FormData();
    try {
      body.append("uploadfile", file);
      body.append("archivo", carpeta);

      const requestSettings = {
        body,
        method: "POST",
        headers: {
          Accept: "application/json",
          "access-token": getItem("token") ?? "",
        },
      };

      let data = await fetch(`${url}apiallku-private/api/fromapiexterna2`, requestSettings).then((data) => data.json());

      return data.llave;
    } catch (error) {
      return error;
    }
  };

  const saveNewPay = () => {
    try {
      form
        .validateFields()
        .then(async (values) => {
          let llave;
          if (isModified) {
            llave = await sendFile(fileListPdf[0].originFileObj, "convenio-documentos");
          } else {
            llave = await sendFile(values.file[0].originFileObj, "convenio-documentos");
          }
          if (!llave) throw new Error();
          values.llave = llave;
          const respuesta1 = await fetch(url + "apifsg-pr/convenios/guardar-documento", optionsPost({ values })).then(
            (data) => data.json()
          );
          if (respuesta1.msg) {
            setOpenModal(false);
            if (isModified || llaveEdit)
              await fetch(`${url}apiallku-private-eliminar/api/deletefromapiexterna`, optionsPost({ files: [llaveEdit] }));
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {}
  };

  const { columnsTable, cobros } = useTableCobros("apifsg-pr/convenios/listar-cobros", renderActionsEdit);

  return {
    saveNewPay,
    form,
    previewVisiblePdf,
    setPreviewVisiblePdf,
    previewPdf,
    setPreviewPdf,
    handleChangePdf,
    fileListPdf,
    beforeUpload,
    normFile,
    handleOkCobros,
    handleCancelCobros,
    renderActionsEdit,
    openModal,
    setOpenModal,
    columnsTable,
    cobros,
  };
}
