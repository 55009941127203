import { Card, Form, Select, Divider, Button, Row, Col, Radio } from "antd";

import { SaveOutlined } from "@ant-design/icons";
import TransferComponent from "components/otros/TransferComponent";
import { DatosBodegasSupervidores, useAsignacionSupervisores } from "hooks/rrhh/useAsignacionSupervisores";
import { ButtonExcel } from "components/otros/buttons";
const { Option } = Select;
export const AsignacionSupervisores = () => {
  const {
    //  onSearchProducto,
    
    datos,
    //handleProducto,
    dataTransfer,
    handleChange,
    handleChangeSelect1,
    productsKeys,
    form,
    handleSendInfo,
    loading,
    valueselect,
    datosBodegas,
    handleChangeFarmaciaSupervisor,
    handleChangeFarmacia,
    handleClickDownloadData
  } = useAsignacionSupervisores();

  return (
    <Row>
      <Col xs={24}>
        <div style={{ margin: "10px" }}>
          <Card title="Administrar supervisores">
            <Form form={form}>
              <Row justify="end" style={{ marginBottom: "20px" }}>
                <Col>
                {valueselect==="1" &&<ButtonExcel buttonProps={{ onClick: handleClickDownloadData }} ></ButtonExcel>}
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col sm={12} xs={24}>
                  <Form.Item name="buscarPor" label="Buscar por" initialValue={"1"}>
                    <Radio.Group buttonStyle="solid" onChange={handleChangeSelect1}>
                      <Radio.Button value={"1"}>Supervisor</Radio.Button>
                      <Radio.Button value={"2"}>Farmacia</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col sm={12} xs={24}>
                  {valueselect === "1" ? (
                    <Form.Item label="Supervisor:" name="supervisor">
                      <Select
                        options={datos}
                        allowClear
                        showSearch
                        optionFilterProp="label"
                        placeholder="Seleccione una opción"
                        onChange={handleChangeFarmaciaSupervisor}
                      ></Select>
                    </Form.Item>
                  ) : (
                    <Form.Item label="Farmacia:" name="farmacias">
                      <Select
                        //options={datosBodegas}
                        allowClear
                        showSearch
                        optionFilterProp="label"
                        placeholder="Seleccione una opción"
                        onChange={handleChangeFarmacia}
                      >
                        {datosBodegas?.map((opcion: DatosBodegasSupervidores) => (
                          <Option
                            key={opcion.value}
                            value={opcion.value}
                            label={opcion.label}
                            style={{
                              fontWeight: opcion.supervisorAsignado.length > 0 ? "bold" : "normal",
                              color: opcion.supervisorAsignado.length > 0 ? "rgb(106 40 49)" : "",
                            }}
                          >
                            {opcion.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Divider style={{ margin: "10px" }} />
              <TransferComponent
                dataTransfer={dataTransfer}
                productsKeys={productsKeys}
                handleChange={handleChange}
                titles={["Libres", "Asisgnadas"]}
              />
              <Divider style={{ margin: "10px" }} />
              <Button
                onClick={handleSendInfo}
                type="primary"
                style={{
                  width: "100%",
                }}
                loading={loading}
                icon={<SaveOutlined className="iconbutton" />}
              >
                GUARDAR
              </Button>
            </Form>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default AsignacionSupervisores;
