import type { Style } from "@react-pdf/types"
import { ReportTable, type columnsTablePDF } from "components/reactreport/report-table"
import type { DependientesDataPrincipal } from "context/franquiciados/form-pago-context"

type Props = {
  dependientes: DependientesDataPrincipal[]
  commonColumnStyle: Style
  rowHeaderStyle: Style
}

const styleColumn: Style = {
  padding: 3,
  textAlign: "right",
  flexBasis: "12%",
}

export function DependientesTable({ dependientes, commonColumnStyle, rowHeaderStyle }: Props) {
  const columnsDependientes: columnsTablePDF<DependientesDataPrincipal>[] = [
    {
      title: "Dependiente",
      dataIndex: "dependiente",
      key: "dependiente",
      styleColumn: { ...commonColumnStyle, ...styleColumn, flexBasis: "40%", textAlign: "left" },
      styleColumnHeader: { ...commonColumnStyle, ...styleColumn, flexBasis: "40%", textAlign: "left", margin: "auto" },
    },
    {
      title: "Sueldo",
      dataIndex: "sueldo",
      key: "sueldo",
      styleColumn: { ...commonColumnStyle, ...styleColumn, ...styleColumn },
      styleColumnHeader: { ...commonColumnStyle, ...styleColumn, margin: "auto" },
      fixed: true,
    },
    {
      title: "Fondos reserva",
      dataIndex: "fondos_reserva",
      key: "fondos_reserva",
      styleColumn: { ...commonColumnStyle, ...styleColumn },
      styleColumnHeader: { ...commonColumnStyle, ...styleColumn },
      fixed: true,
    },
    {
      title: "IESS 12.15",
      dataIndex: "iess_12",
      key: "iess_12",
      styleColumn: { ...commonColumnStyle, ...styleColumn },
      styleColumnHeader: { ...commonColumnStyle, ...styleColumn, margin: "auto" },
      fixed: true,
    },
    {
      title: "Otros",
      dataIndex: "otros",
      key: "otros",
      styleColumn: { ...commonColumnStyle, ...styleColumn },
      styleColumnHeader: { ...commonColumnStyle, ...styleColumn, margin: "auto" },
      fixed: true,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      styleColumn: { ...commonColumnStyle, ...styleColumn },
      styleColumnHeader: { ...commonColumnStyle, ...styleColumn, margin: "auto" },
      fixed: true,
      styleCell: index => (index === dependientes?.length - 1 ? { borderBottom: "1.3px solid #000" } : {}),
    },
  ]

  return <ReportTable columns={columnsDependientes} dataSource={dependientes} styleRowHeader={rowHeaderStyle} />
}
