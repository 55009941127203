import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { optionsPOST, url_interna } from "../../../config/constants";

//import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useExport } from "hooks/otros/useExport";
import { FilterValue, SorterResult, TableCurrentDataSource, TablePaginationConfig } from "antd/lib/table/interface";

interface PropsDescripcionProductos {
  key: React.Key;
  Producto: string;
  precio: number;
  cajas: number;
  fraccion: number;
}

type InventarioReporteRealizados = {
  BodegaEnvia: string;
  Canton: string;
  fecha: string;
  Total: number;
  Dependiente: string;
  Tipo: string;
  BodegaRecibe: string;
  datos: PropsDescripcionProductos[];
  key: React.Key;
};

const breadcrumbs = [
  { Label: "Inventarios", Url: "/inventarios" },
  { Label: "Reportes", Url: "/inventarios/reportes" },
  { Label: "Reporte transferencia" },
];

const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];
export function useReporteTransferencias() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[1].Label + "/" + breadcrumbs[2].Label);
  //const [mes, setMes] = useState<Moment>(moment());
  const [datosInicial, setDatos] = useState<InventarioReporteRealizados[]>([]);
  //const [searchText] = useState("");
  
  const [dataEstado, setDataEstado] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<InventarioReporteRealizados[]>([]);

  const [estadoBoton, setBoton] = useState<boolean>(false);

  const { exportToExcel } = useExport();
  const { isError } = useVerifyResponse();

  const getBodegas = useCallback(
    async (fechaInicio: Moment, fechaFin: Moment) => {
      setDataEstado(false);
      try {
        const res = await fetch(
          url_interna + "api/reportes/obtener_transferencias",
          // optionsPost(JSON.stringify({ mes: mes.format("YYYYMM") }), { "api-token": "apitoken" })
          optionsPOST(
            JSON.stringify({ fechaInicio: fechaInicio!.format("DD/MM/YYYY"), fechaFin: fechaFin!.format("DD/MM/YYYY") }),
            {
              "api-token": "apitoken",
            }
          )
        );

        if (isError(res)) throw new Error();
        const respuesta = await res.json();
        if (respuesta.msg !== "err") {
          setBoton(true);
          setDataEstado(true);
          const respuestas: InventarioReporteRealizados[] = respuesta.data;
          setDatos(
            respuestas.map(({ BodegaEnvia, Canton, fecha, Dependiente, Total, Tipo, BodegaRecibe, datos, key }) => ({
              BodegaEnvia,
              Canton,
              fecha,
              Dependiente,
              Total,
              Tipo,
              BodegaRecibe,
              datos,
              key,
            }))
          );

          setFilteredData(respuesta.data);
        } else {
          setDatos([]);
          setBoton(false);
          setDataEstado(false);
          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        return { msg: "no_conexion", status: false };
      }
    },
    [isError]
  );

  useEffect(() => {
    const fechaInicio = moment().startOf("month");
    const fechaFin = moment().endOf("month");
    getBodegas(fechaInicio, fechaFin);
  }, [getBodegas]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<InventarioReporteRealizados> | SorterResult<InventarioReporteRealizados>[],
    extra: TableCurrentDataSource<InventarioReporteRealizados>
  ) => {
    if (extra.action === "filter") {
      setFilteredData(extra.currentDataSource);
    }
  };
  const columns: TableColumnsType<InventarioReporteRealizados> = [
    {
      title: "Farmacia envía",
      dataIndex: "BodegaEnvia",
      key: "BodegaEnvia",
      filters: [...new Set(datosInicial.map(({ BodegaEnvia }) => BodegaEnvia))].map((BodegaEnvia) => ({
        text: BodegaEnvia,
        value: BodegaEnvia,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.BodegaEnvia,
    },
    {
      title: "Canton",
      dataIndex: "Canton",
      key: "Canton",
      filters: [...new Set(datosInicial.map(({ Canton }) => Canton))].map((Canton) => ({
        text: Canton,
        value: Canton,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.Canton,
    },

    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
      sorter: (a, b) => moment(a.fecha).unix() - moment(b.fecha).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Dependiente",
      dataIndex: "Dependiente",
      key: "Dependiente",
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
    },
    {
      title: "Tipo",
      dataIndex: "Tipo",
      key: "Tipo",
    },
    {
      title: "Bodega Recibe",
      dataIndex: "BodegaRecibe",
      key: "BodegaRecibe",
      filters: [...new Set(datosInicial.map(({ BodegaRecibe }) => BodegaRecibe))].map((BodegaRecibe) => ({
        text: BodegaRecibe,
        value: BodegaRecibe,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.BodegaRecibe,
    },
  ];
  const columsDatatableDetalle: TableColumnsType<PropsDescripcionProductos> = [
    {
      title: "Producto",
      dataIndex: "Producto",
      key: "nombreEquipo",
      width: "280px",
    },
    {
      title: "Cajas",
      dataIndex: "cajas",
      key: "cajas",
      width: "128px",
    },
    {
      title: "Fraccion",
      dataIndex: "fraccion",
      key: "fraccion",
      width: "228px",
    },
    {
      title: "Valor",
      dataIndex: "precio",
      key: "precio",
    },
  ];

  const handleClickDownloadData = () => {
    const datosParaExportar = filteredData.map((item) => ({
      ...item,
      Dependientes: JSON.stringify(item.datos),
    }));

    const texto = "detalleTransferencias";
    exportToExcel(datosParaExportar ?? [], texto);
  };

  return {
    columns,
    columsDatatableDetalle,
    /*mes,
    setMes,*/
    datosInicial,
    handleClickDownloadData,
    handleTableChange,
    //calcularTotales,
    estadoBoton,
    getBodegas,
    dataEstado
  };
}
