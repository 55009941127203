import { Principal } from "pages/contentprincipal/Principal";
import { CambiosPrecios } from "pages/productos/CambiosPrecios";
import NovedadesBodegas from "pages/productos/NovedadesBodegas";
import { ProductosIncentivos } from "pages/productos/ProductosIncentivos";
import { ProductosIncentivosEditar } from "pages/productos/ProductosIncentivosEditar";
import { SinMovimiento } from "pages/productos/SinMovimiento";
import VizorMartillo from "pages/productos/VisorMartillo";
import { RouteObject } from "react-router-dom";
import NovedadesUltimaVenta from "../pages/productos/NovedadesUltimaVenta";
import { ProductosBilleteate } from "pages/productos/ProductosBilleteate";
import ProductosPermisosEditarMinimos from "pages/productos/ProductosPermisosEditarMinimos";
import ReportePromocionesMF from "pages/productos/ReportePromocionesMF";

export const rutasProductos: RouteObject[] = [
  {
    path: "/productos",
    element: <Principal />,
  },
  {
    path: "/productos/atc",
    element: <ProductosIncentivos />,
  },
  {
    path: "/productos/atc-editar",
    element: <ProductosIncentivosEditar />,
  },
  {
    path: "/productos/cambio-precios",
    element: <CambiosPrecios />,
  },
  {
    path: "/productos/sobre-stock",
    element: <SinMovimiento />,
  },
  {
    path: "/productos/tareas-farmacias",
    element: <NovedadesBodegas />,
  },
  {
    path: "/productos/visor-martillo",
    element: <VizorMartillo />,
  },
  {
    path: "/productos/fracciones-sin-movimiento",
    element: <NovedadesUltimaVenta />,
  },
  {
    path: "/productos/billeteate",
    element: <ProductosBilleteate />,
  },
  {
    path: "/productos/permisos-minimos",
    element: <ProductosPermisosEditarMinimos />,
  },
  {
    path: "/productos/promociones-mf",
    element: <ReportePromocionesMF />,
  },
];
