import { Table, Modal } from "antd";

import { useState } from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

interface dataCustom {
  active?: boolean;
  payload?: dataPayload[];
}

interface dataPayload {
  name: string;
  value: string;
  payload?: any;
}

interface PropsModal {
  name: string;
  personal?: string;
}

interface propsPie {
  column: any[];
  data: string;
  name?: string;
}

const ComponentPie = ({ column, data, name }: propsPie) => {
  const COLORS = [
    "rgb(31, 119, 180)",
    "rgb(255, 127, 14)",
    "rgb(44, 160, 44)",
    "rgb(214, 39, 40)",
    "rgb(148, 103, 189)",
    "rgb(140, 86, 75)",
    "rgb(227, 119, 194)",
    "rgb(127, 127, 127)",
    "rgb(188, 189, 34)",
    "rgb(23, 190, 207)",
  ];

  const columnsTable = [
    {
      title: "Nombres",
      dataIndex: "nombres",
      key: "nombres",
      className: "customTbl",
      width: "100px",
    },
    {
      title: "Cédula",
      dataIndex: "cedula",
      key: "cedula",
      className: "customTbl",
      width: "50px",
    },
  ];

  const CustomTooltip = ({ active, payload }: dataCustom) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip' style={{ backgroundColor: "#ffff", padding: "5px", border: "1px solid #cccc" }}>
          <label>{`${payload[0].name} : ${payload[0].value}`}</label>
          {/* {tabla.length > 0 && <Table columns={columnsTable} dataSource={tabla} scroll={{ x: "200px", y: "200px" }} />} */}
        </div>
      );
    }
    return null;
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill='white' textAnchor={x > cx ? "start" : "end"} dominantBaseline='central'>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const [modal, setModal] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [dataTable, setDataTable] = useState<dataPayload[]>([]);

  const handleClick = ({ name, personal }: PropsModal) => {
    let tabla = [];
    if (personal) {
      setTitle(name);
      tabla = JSON.parse(personal) || [];
      setDataTable(tabla);
    }
    if (tabla.length > 0) {
      setModal(true);
    }
  };

  const handleOk = () => {
    setModal(false);
  };

  const handleCancel = () => {
    setModal(false);
  };

  return (
    <>
      <ResponsiveContainer width='100%' aspect={2}>
        <PieChart width={400} height={400}>
          <Pie
            data={column}
            cx='50%'
            cy='50%'
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={95}
            fill='#8884d8'
            dataKey={data}
            nameKey={name}
            onClick={handleClick}
          >
            {column.map((entry: any, index: any) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>

          <Tooltip content={<CustomTooltip />} />
          <Legend height={1} iconSize={8} />
        </PieChart>
      </ResponsiveContainer>
      <Modal open={modal} footer={null} onOk={handleOk} onCancel={handleCancel} destroyOnClose transitionName=''>
        <Table
          pagination={false}
          columns={columnsTable}
          title={() => title}
          dataSource={dataTable}
          scroll={{ x: "100px", y: "200px" }}
        />
      </Modal>
    </>
  );
};

export default ComponentPie;
