import React, { useCallback, useEffect, useRef, useState } from "react"
import type { ColumnsType, ColumnType } from 'antd/es/table';
import { Button, Select, Table, Row,Input, Space, InputRef, Tooltip, Tag } from "antd"
import { url} from "config/constants"
import { optionsPost, optionsGet, swalWarning , swalSuccess} from "config/methods"
import { useExport} from "hooks/otros/useExport"
import { SaveOutlined, SearchOutlined,EyeOutlined} from "@ant-design/icons"
import type { FilterConfirmProps } from "antd/es/table/interface"
import Highlighter from "react-highlight-words"
import moment from "moment"
import './UseSolicitarUniforme.css'


export interface DataType {
  key: React.Key
  cod_usuario: string
  nombre: string
  provincia: string
  ciudad: string
  direccion: string
  cod_farmacia: string
  fecha_ingreso: Date
  fecha_solicitud: Date
  codarticulo:string
  cod_estado: string
  dias: number
  cod_articulo_reposicion: number,
  disabled: boolean,
  tipo_articulo: string,
  detalle: string[],
  imagellaves: string[],
  observacionrrhh: string,
  observacionaprobacion: string
}

type DataIndex = keyof DataType

export interface DataUniforme {
  key: React.Key
  codtipouniforme: number
  nombre_tipouniforme: string
  cantidad: number
  codtalla: string
  vunitario: number 
  keyrecord: string
  disabled: boolean,
}


export function useSolicitarUniformes(){
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [loading, setLoading] = useState(false)
  const [showSolicitud, setShowSolicitud] = useState(false)
  const [data, setData] = useState<DataType[]>([])
  const [itemselect, setitemSelect]=useState([{value:"", label: "seleccione un kit"}])
  const [itemselectTalla, setitemSelectTalla]=useState([{value:"", label: "Talla"}])
  const [Filtro, setFiltro]=useState("")
  const { exportToExcel } = useExport()
  const [searchText, setSearchText] = useState("")
  const [searchedColumn, setSearchedColumn] = useState("")
  const searchInput = useRef<InputRef>(null)
  const [visible, setVisible] = useState(false);
  const [llavesimages, setllavesimages]= useState<String[]>([])
  const [ExpandedRowKeys, setExpandedRowKeys]= useState<string[]>([])
  const [visibleButton, setVisibleButton] = useState(false);

  const getdetallesolcitud = useCallback(async(codarticulo: string,cod_articulo_reposicion:number) => {
    return await fetch(
      `${url}fapifsg-pr/rrhh/Uniforme/getDetalleSolicitud`,
      optionsPost({ cod_kit_uniforme: codarticulo, cod_solicitud: cod_articulo_reposicion })
    ).then((data) => data.json());
  },[]);

  const columnsuniforme: ColumnsType<DataUniforme>=[
    {
      title:'Nombres',
      dataIndex: "nombre_tipouniforme",
      className: 'nombre-column',
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
    },
    {
      title: "Talla",
      dataIndex: "codtalla",
      render: (item, key)=>{
        if(!key.codtalla){
          key.codtalla="";
        }
        
        return(<> <Select
        showSearch
        placeholder="Seleccione una talla"
        defaultValue={key.codtalla}
        //value={key.codtalla}
        onChange={(value) => handleTallaChange(value, key)}
        onSearch={onSearchselect}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        style={{ textAlign: "start", width: '100px', marginLeft: "10px" }}
        options={itemselectTalla}
        disabled={key.disabled}
      />
    </>)}
    },
  ]

  const handleExpand = (expanded:boolean, record:DataType) => {
    //console.log("record"+ record.cod_estado)
    let datanew:string[]=[]
    if(expanded){setExpandedRowKeys([...ExpandedRowKeys, record.key.toString()])}else{setExpandedRowKeys([])} 
    if (expanded && (record.codarticulo || record.tipo_articulo==='UF')) {
      getdetallesolcitud(record.codarticulo, record.cod_articulo_reposicion).then((res) => {
        datanew = res.map((item: any, key: any) => ({
          key: key,
          codtipouniforme: item.Cod_Tipo_Uniforme,
          nombre_tipouniforme: item.Nombre,
          cantidad: item.Cantidad,
          codtalla: item.Cod_Talla,
          vunitario:"0",
          keyrecord: record.key,
          disabled:record.cod_estado===''||record.cod_estado==='PE'||record.cod_estado===null||record.cod_estado==='RE'?false:true
        }));
/*         console.log("record12")
        console.log(datanew) */
       const datatable= data.map((item)=>{
          if(item.key===record.key){
            return{ ...record, detalle: datanew }
          }
          return item
        })
        setData(datatable)
      })
    }
  };
 


  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  })


  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText("")
  }

  const columns: ColumnsType<DataType> = [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      title: "Nombres",
      dataIndex: "nombre",
      width: "200px",
      ...getColumnSearchProps("nombre")
    },
    {
      title: "Provincia",
      dataIndex: "provincia",
      //width: "250px",
      sorter: (a, b) => a.provincia.localeCompare(b.provincia), 
    },
    {
      title: "Ciudad",
      dataIndex: "ciudad",
      //width: "250px",
      sorter: (a, b) => a.ciudad.localeCompare(b.ciudad), 
    },
    {
      title: "Farmacia",
      dataIndex: "cod_farmacia",
      render: (item)=>{
        let valorComoCadena = item.toString();
        // Calcular la cantidad de ceros que se deben agregar
        const cerosFaltantes = 3 - valorComoCadena.length;
        // Rellenar con ceros a la izquierda si es necesario
        if (cerosFaltantes > 0) {
          valorComoCadena = '0'.repeat(cerosFaltantes) + valorComoCadena;
        }
        return "FSG "+valorComoCadena}
    },
    {
      title: "F. Ingreso",
      dataIndex: "fecha_ingreso",
    },
    {
      title: "F. Solicitud",
      dataIndex: "fecha_solicitud",
      //width: '10px'
    },
    {
      title:"Kit",
      dataIndex:"",
      align:'center',
      render: (item, key)=>{
        if(!key.codarticulo){
          key.codarticulo="";
        }
        return(<> {key.tipo_articulo==='KT'|| key.tipo_articulo===null?<Select
        showSearch
        placeholder="Seleccione un Kit"
        value={key.codarticulo}
        defaultValue={key.codarticulo}
        onChange={(value) => handleKitChange(value, key)}
        onSearch={onSearchselect}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        style={{ textAlign: "start", width: "100%", marginLeft: "10px" }}
        options={itemselect}
        disabled={key.disabled}
      />:''}</>)
    }
    },
    {
      title: "Solicitud Reposición",
      dataIndex: "",
      align: 'center',
      render: (keys, key)=>{
        return (key.tipo_articulo==="UF"?'Si':'No')//<CheckOutlined />:'')
      }
    },
    {
      title: "Estado",
      dataIndex: "cod_estado",
      width:'80px',
      
      render: (cantidad, key) => {
        let estado, background, color, colorborder='', colortag='';
       // console.log(key);
        if(!key.cod_estado){
          estado = "SOLICITAR"
          if(key.dias>15){
            colortag='#27AE60'
            colorborder='#27AE60'
            background='#27AE60'
            color='white'
          }else if(key.dias>0  && key.dias<=15){
            colortag='#F4D03F'
            colorborder='#F4D03F'
            background='#F4D03F'
            color='black'
          } else {
            colortag='#A93226'
            colorborder='#F15545'
            background='#F15545'
            color='white'
          }
        }else if(key.cod_estado==='PE'){
          colortag='blue'
          colorborder = "#070E3B"//"#F15545"
          background="#D4DAFF"
            estado='PENDIENTE'
        } else if(key.cod_estado==='PP'){
          colortag='lime'
          colorborder = "#F4D03F"
          background='#FDFFBC'//'#F7FA92'
          estado='VALIDADO RRHH'
        }else if(key.cod_estado==='AP'){
          colortag='green'
          estado='APROBADO'
          background = "#D5F5E3 "
          colorborder="#27AE60"
        } else if(key.cod_estado==='RE'){
          colortag='red'
          colorborder ='#FC182D' //"#F99D3B"
          background= '#FFDCDF'//"#FFEAD4 "
          estado='RECHAZADO'
        }
        return (
          <>
            {key.cod_estado!=='PP'&&<Tag color={colortag} style={{color:color, fontSize:'12px'}}>{estado}</Tag>}
            {key.cod_estado==='PP'&& <label style={{fontSize: '11px',borderColor: colorborder,borderStyle: 'solid', color: color,borderRadius:'5px', padding:'2px', borderWidth:'1px', background: background}}> {estado}</label>}
          </>
        )
      }, //<Button icon={<DeleteOutlined />}></Button>,
    },
    {
      title: "",
      dataIndex: "",
      width:'100px',
      render: (cantidad, key) => {
        return (
          <>
           {!key.disabled? <Row style={{marginBottom: '2px'}}>
           <Tooltip title="Guardar"><Button type={"primary"} size="small" onClick={()=>savesolicitudKit(key)}><SaveOutlined /></Button></Tooltip>
            </Row>:''}
            {key.tipo_articulo==='UF'? <Tooltip title='Evidencia'><Button  size="small" type={"primary"} onClick={() => {setVisible(true); setllavesimages(key.imagellaves===null?[]:key.imagellaves)}}><EyeOutlined/></Button></Tooltip>:''}
            </>
        )
      }, //<Button icon={<DeleteOutlined />}></Button>,
    },
  ]

  const handleKitChange=(value:string, key:any)=>{
    let datanew:any;
    if(value!==""){
        getdetallesolcitud(value, key.cod_articulo_reposicion===""?'KT':key.cod_articulo_reposicion).then((res) => {
          datanew = res.map((item: any, num: any) => ({
            key: num,
            codtipouniforme: item.Cod_Tipo_Uniforme,
            nombre_tipouniforme: item.Nombre,
            cantidad: item.Cantidad,
            codtalla: item.Cod_Talla,
            vunitario:"0",
            keyrecord: key.key
          }));
         // console.log(datanew)

          const datatable= data.map((item)=>{
            if(item.key===key.key){
              return{ ...item, codarticulo: value, detalle: datanew }
            }
            return item
          })
          setData(datatable)
        })
      }
  }


  const handleChange=(value:string)=>{
    //console.log(value)
    setFiltro(value)
    getsolicitaruniforme(value);
    //console.log(Filtro)
  }

  const savesolicitudKit=async(key:any="")=>{
    let newdata:any =[];
    if (key){
      data.map((item1)=>{
        if(item1.key===key.key){
          const detalle=item1.detalle.filter((item1:any)=>!item1.codtalla)
          if(!item1.codarticulo&&item1.tipo_articulo!=='UF'){
            return swalWarning({text: "Por favor seleccione un kit"})
          }
          
          if(detalle.length>0|| item1.detalle.length<1){
            return swalWarning({text: "Por favor revise que todas las prendas tengan tallas"})
          }
          newdata.push({
            codsolicitudreposicion: item1.cod_articulo_reposicion, 
            tipoarticulo: 'KT', 
            codusuario: item1.cod_usuario, 
            codarticulo: item1.codarticulo, 
            estado: !item1.cod_estado||item1.cod_estado==='RE'?'PE': item1.cod_estado, 
            codusuariosolicitud: '', 
            codusuarioaprobacionrrhh:'', 
            codusuarioaprobacion:'',
            detalle:item1.detalle,
            nombre: item1.nombre,
            cod_farmacia:item1.cod_farmacia,
            nombrekit: itemselect.filter((item)=>item.value===item1.codarticulo),
            fecha: moment().format("DD/MM/YYYY")
          })
        }
        return newdata
      })
    }else{
      data.map((item:DataType)=> {
        if(selectedRowKeys.includes(item.key as string)){
          const detalle=item.detalle.filter((item1:any)=>item1.codtalla===""||item1.codtalla===null)
          //console.log("detalle:"+ detalle)
          if(item.codarticulo==="" &&item.tipo_articulo!=='UF'){
            //console.log("articulovacio")
            newdata=[]
            return swalWarning({text: "Por favor seleccione un kit"})
          }
          if(detalle.length>0||item.detalle.length<1){
            newdata=[]
            return swalWarning({text: "Por favor revise que todas las prendas tengan tallas"})
          }
          newdata.push({
            codsolicitudreposicion: item.cod_articulo_reposicion, 
            tipoarticulo: 'KT', 
            codusuario: item.cod_usuario, 
            codarticulo: item.codarticulo, 
            estado: !item.cod_estado?'PE': item.cod_estado, 
            codusuariosolicitud: '', 
            codusuarioaprobacionrrhh:'', 
            codusuarioaprobacion:'',
            detalle:item.detalle,
            nombre: item.nombre,
            cod_farmacia:item.cod_farmacia,
            nombrekit: itemselect.filter((item1)=>item1.value===item.codarticulo),
            fecha: moment().format("DD/MM/YYYY")
          })
        }
        return newdata
      })
      }
      if(newdata.length>0){
       // console.log("prueba")
        const res = await fetch(
          `${url}fapifsg-pr/rrhh/Uniforme/saveSolicitudUniformes`,
          optionsPost({ solicitudes: newdata, correo: "mariacedeno@sangregorio.com.ec" , opcion:"Validación Uniformes RRHH", url:`https://gestion.fsg.ec/rrhh/renovacion-uniformes/validacion-uniformes-rrhh`})
        )//.then((data) => data.json())

        if(res.status===200){
          getsolicitaruniforme(Filtro);
          swalSuccess({text:"Se guardaron los datos exitosamente"})
          setExpandedRowKeys([])
        }
        setSelectedRowKeys([])
      //data.filter((item)=>(if(selectedRowKeys.includes(item.key))
      }
  //console.log(newdata) 
  }

  const handleTallaChange = (value:string, key:any)=>{
    const result= data[+key.keyrecord].detalle
    if(Array.isArray(result)){
      const updatedData = result.map((item:any) => {
        if (item.key === key.key) {
          return { ...item, codtalla: value};
        }
        return item 
      })
      const datatable= data.map((item)=>{
        if(item.key===key.keyrecord){
          return{ ...item, detalle: updatedData }
        }else{
          return item
        }
      })
      //console.log(datatable)
      setData(datatable)

    }
  }


  const getsolicitaruniforme = async (value:String) => {
    const res = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/solicitar-uniformes`, optionsPost({estado:value})).then((data) => data.json())
    //console.log(!res)
  let newdata:any=[]
  if(res){
      newdata = Array.from(res).map((item: any, key: any) => ({
      key: key.toString(),
      cod_usuario: item.Cod_Usuario,
      nombre: item.Nombres,
      provincia: item.Provincia,
      ciudad: item.Ciudad, 
      direccion:item.Direccion,
      cod_farmacia: item.Cod_Farmacia,
      fecha_ingreso: item.Fecha_Ingreso,
      fecha_solicitud: item.Fecha_Solicitud,
      codarticulo: item.Cod_Articulo,
      cod_estado: item.Estado,
      dias: item.dias,
      cod_articulo_reposicion:item.Cod_Solicitud_Reposicion_Articulo,
      disabled: item.Estado===''||item.Estado==='PE'||item.Estado===null|| item.Estado==='RE'?false:true,
      detalle: item.Detalle!==null?JSON.parse(item.Detalle).map((item1: any, key: any) => ({
        key: key,
        codtipouniforme: item1.Cod_Tipo_Uniforme,
        nombre_tipouniforme: item1.Nombre,
        cantidad: item1.Cantidad,
        codtalla: item1.Cod_Talla,
        vunitario:item1.Valor_Unitario?item1.Valor_Unitario:'0',
        keyrecord:  key.toString(),
        disabled:item.Estado===''||item.Estado==='PE'||item.Estado===null || item.Estado==='RE'?false:true,
      })):[],
      tipo_articulo: item.Tipo_Articulo,
      imagellaves: item.Llaves!==null?JSON.parse(item.Llaves).map((item1:any, key:any)=>({
        key: key,
        Llave: item1.Llave
      })): [],
      observacionrrhh: item.Observacion_RRHH,
      observacionaprobacion: item.Observacion_aprobacion,
    }))
   }

   setData(newdata)
}

  const getKit = async () => {
    const res = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/getKit`, optionsGet()).then((data) => data.json())
    let respuesta=res.data;
    let newget =[{value:"", label: "seleccione un kit"}]
    respuesta.forEach((item: any)=>{  
    let add={
      value: item.Cod_Kit,
      label: item.Nombre,
    }
    newget= [...newget, add];
    }
    )
    setitemSelect(newget)
  }
  
  const getTalla = async () => {
    const res = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/getTalla`, optionsGet()).then((data) => data.json())
    let respuesta=res.data;
    let newget =[{value:"", label: "Talla"}]
    respuesta.forEach((item: any)=>{  
    let add={
      value: item.Cod_Talla,
      label: item.Talla,
    }
    newget= [...newget, add];
    }
    )
    setitemSelectTalla(newget)
  }
  
  useEffect(() => {
    getsolicitaruniforme(Filtro);
    getKit()
    getTalla()
  },[Filtro])

  const start = () => {
    setLoading(true)
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([])
      setLoading(false)
    }, 1000)
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
   // console.log("selectedRowKeys changed: ", newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
    if(newSelectedRowKeys.length>1){
      setVisibleButton(true)
    }else{
      setVisibleButton(false)
      
    }
  }

  const rowSelection = {
    getCheckboxProps: (item:any) => ({ disabled: item.disabled }),
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const SolicitudRenovacion = () => {
    setShowSolicitud(true)
  }

  const onSearchselect = (value: string) => {
    //console.log('search:', value);
  };


  const handleDownload = () => {
    const dataExport = data.map((item)=>{
      let addData: any;
      if(item.detalle!==null){
        item.detalle.forEach((item:any)=>{
          let nombre=item.nombre_tipouniforme.split(" ")
          //console.log(nombre.length);
          if(nombre.length>1){
            nombre=(nombre[0].charAt(0)+(nombre[1].charAt(0))).toUpperCase()
          }else{
            nombre=(nombre[0].charAt(0).toUpperCase())
          }
          
          const talla=itemselectTalla.filter((item1)=> {return item1.value === item.codtalla;})

          addData=({ ...addData,[item.nombre_tipouniforme]: item.cantidad, [nombre+"Talla"]: talla.length===0?'':talla[0].label});
        })
      }
       let  kit ="", estado
       
        itemselect.forEach((key) => {
          if (key.value === item.codarticulo && key.value !== "") {
            kit = key.label;
          }
        });

        let valorComoCadena = item.cod_farmacia.toString();
        const cerosFaltantes = 3 - valorComoCadena.length;
        if (cerosFaltantes > 0) {
          valorComoCadena = '0'.repeat(cerosFaltantes) + valorComoCadena;
        }

      if(item.cod_estado==='PE'){
          estado='PENDIENTE'
      } else if(item.cod_estado==='PP'){
        estado='APROBADO RRHH'
      }else if(item.cod_estado==='AP'){
        estado='APROBADO'
      } else if(item.cod_estado==='RE'){
        estado='RECHAZADO'
      }
        return{
          Nombres: item.nombre,
          Provincia: item.provincia,
          Ciudad: item.ciudad,
          Direccion: item.direccion,
          FarmaciaN: "FSG "+valorComoCadena,
          Estado: estado,
          "Fecha de Ingreso": item.fecha_ingreso,
          "Fecha Solicitud": item.fecha_solicitud,
          Kit:kit,
          ...addData, 
        }
    })
    exportToExcel(dataExport, `Reporte_Solicitud_Uniformes_${moment().format("DDMMYYYY")}`)
  }
  


  return {
    selectedRowKeys,
    setSelectedRowKeys,
    loading,
    setLoading,
    start,
    rowSelection,
    columns,
    data,
    showSolicitud,
    SolicitudRenovacion,
    savesolicitudKit,
    handleDownload,
    getdetallesolcitud,
    setData,
    //defaultExpandedKeys,
    columnsuniforme,
    handleExpand,
    handleChange,
    llavesimages,
    visible,
    setVisible,
    ExpandedRowKeys,
    visibleButton
  }
}
