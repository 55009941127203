import ReactPDF, { Text, StyleSheet } from "@react-pdf/renderer"

// interface subTitleProps {
//   text: string
// }

const styles = StyleSheet.create({
  subtitle: {
    fontSize: 13,
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
    marginBottom: 15
  }
})

export const SubTitle = ({ children, style }: ReactPDF.TextProps) => (
  <Text style={ style ? { ...styles.subtitle, ...style } : styles.subtitle }>{ children }</Text>
)