
import { useCallback, useEffect, useState} from "react"
import { useLocalStorage } from "../../hooks/otros/useLocalStorage";
import { url } from "../../config/constants";
import Swal from "sweetalert2"
import { llave } from "config/constants"
import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
import { useParams } from "react-router-dom";
import { Task } from "gantt-task-react";

const moment = require('moment');
interface ResponseData {
  cod_actividad: number;
  name: string;
  departamento: string;
  asignado: string;
  progress: number;
  isdisabled: boolean;
  fecha_inicio: string;
  fecha_fin: string;
  start: string;
  end_: string;
  duracion: string;
  tiempo_estimado: string;
  tags: string;
  dependencies: null | string[]; // Aquí asumo que las dependencias podrían ser null o un string, pero debes ajustarlo según tus necesidades.
  type: string;
  nombre_form:string;
  cod_actividad_proceso_iniciado:number
}

interface TablaGantt {
  id: string;
  name: string;
  departamento: string;
  asignado: string;
  tags: string[];
  fecha_inicio: string;
  fecha_fin: string;
  duracion: string;
  tiempo_estimado:string
}

export declare type TaskType = "task" | "milestone" | "project";
interface Task_Gantt {
  cod_actividad: string;
  start: Date;
  end_: Date;
  name: string;
  progress: number;
  type: TaskType;
  dependencies?: string; 
  tags: string;
};

export function useVerProceso() {

  const { procesos } = useParams()
  const {  optionsPost } = useLocalStorage();  
  const [ state, setState] = useState<TablaGantt[]>([]);   
  const [ stateGantt, setStateGantt] = useState<Task[]>([]); 
  const getProgressColor = (tag: string): string => {
    switch(tag) {
        case 'Atrasado': return 'red';
        case 'Ejecutado': return 'green';
        case 'Ejecutado con atraso': return 'yellow';
        case 'En progreso': return 'blue';
        default: return '#d9d9d9'; // o cualquier otro color por defecto que desees.
    }
}

  const fillEditarProcesos = useCallback(async () => {
    try {
          const respuesta = await fetch(url + 'procesos/ver-gantt', optionsPost({"cod_proceso_iniciado": AES.decrypt(decodeURIComponent(procesos ?? ''),llave).toString(Utf8)}))
          const res = await respuesta.json()
          
          // Verificamos si recibimos una respuesta válida
          if (res.msg !== 'ok') throw new Error();

          // Transformamos la data aquí
          const transformData = (data: ResponseData[]) => {
            return data.map(item => ({
                id: item.cod_actividad.toString(),
                name: item.name.trim(),
                departamento: item.departamento,
                asignado: item.asignado,
                tags: [item.tags],
                fecha_inicio: item.fecha_inicio,
                fecha_fin: item.fecha_fin,
                duracion: item.duracion,                
                tiempo_estimado: item.tiempo_estimado,
                nombre_form:item.nombre_form,
                cod_actividad_proceso_iniciado:item.cod_actividad_proceso_iniciado
            }));
          }
          const transformedData = transformData(res.data);
          // Luego puedes hacer lo que quieras con transformedData
          // Por ejemplo, establecerlo en el estado o pasarlo a otro componente, etc.
          setState(transformedData); 
          // console.log(res.data);
          const transformToTasks = (data: Task_Gantt[]): Task[] => {
            return data.map(item => {
              const task: Task = {
                id: item.cod_actividad.toString(),
                start: moment(item.start, "DD/MM/YYYY HH:mm").toDate(),
                end: moment(item.end_, "DD/MM/YYYY HH:mm").toDate(),
                name: item.name,
                progress: item.progress,
                type: item.type,
                project: "ProjectSample", 
                styles: {
                  progressColor: getProgressColor(item.tags) 
                }
              };
          
              if (item.dependencies && item.dependencies.length > 0) {
                task.dependencies = item.dependencies.split(',')
              }
          
              return task;
            });
          }         
          
          const transformedDataGantt = transformToTasks(res.data);    
          setStateGantt(transformedDataGantt)

    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Error de consulta',
        text: 'Error al obtener la lista de procesos',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      })
    }      
  },[ procesos,optionsPost ])


  useEffect(() => {
    fillEditarProcesos();
  },[ fillEditarProcesos ])

  return {
    state,
    stateGantt
  }
}