import { Radio, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { PropsLlaves } from "components/rrhh/componentsActualizacion/VerArchivos";
import { useEffect, useState, useMemo, useContext } from "react";
import { FormInstance } from "antd/es/form";
import { useLocalStorage } from "../../otros/useLocalStorage";
import { url as url2 } from "../../../config/constants";
import { UserContext } from "../../../context/UserContext";
import { swalError } from "config/methods";
import jsPDF from "jspdf";
import JsBarcode from "jsbarcode";
import moment from "moment";
import { PropsFormu } from "pages/contentrecursoshumanos/contentactualizacion/RevisarInformacion";

interface DataType {
  key: string;
  descripcion: string;
  llave: string;
  conyugue: boolean;
  status: number;
}

interface DataState {
  conyugue: boolean;
  descripcion: string;
  key: string;
  llave: string;
  status: number;
}

export function useVerArchivos(
  archivos: PropsLlaves[] | undefined,
  form: FormInstance<any>,
  handleOk: any,
  getEnviadas?: (value: number) => void,
  valuePeriodo?: number,
  enviadas?: PropsFormu[] | undefined,
  setEnviadas?: (data: PropsFormu[]) => void | undefined
) {
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleImg, setVisibleImg] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");
  const [estado, setEstado] = useState<DataState[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const { optionsPost } = useLocalStorage();
  const [loading, setLoading] = useState<boolean>(false);
  const [loading2, setLoading2] = useState<boolean>(false);
  const { userData } = useContext(UserContext);
  let id = userData?.id;

  let name_user = userData?.nombre;
  // console.log();
  const saveData = async (formulario: any) => {
    try {
      const respuesta = await fetch(url2 + "rrhh/editar", optionsPost(formulario)).then((data) => data.json());

      if (respuesta.ok) {
        return { respuesta, status: true };
      } else {
        return { respuesta: "Ocurrio un error al guardar", status: false };
      }
    } catch (error) {
      return { msg: "no_conexion", status: false };
    }
  };

  const downloadPDFReglamentos = async (userName: string, reglamentos: string[], groupName: string, id_luc: number) => {
    try {
      if (!form.getFieldsValue().es_interno || !form.getFieldsValue().es_higiene)
        return notification.warning({
          message: "Advertencia",
          description: "No existe aceptación de este empleado en este periodo!",
        });

      const canvas = document.createElement("canvas");

      const barcodeValue = "RI" + String(id_luc).padStart(10, "0");

      JsBarcode(canvas, barcodeValue, {
        format: "CODE128",
        lineColor: "#000",
        width: 2,
        height: 40,
        displayValue: true,
      });

      const barcodeData = canvas.toDataURL("image/png");

      const doc = new jsPDF();
      const img = new Image();
      img.src = "/imagen/images.jpg";

      img.onload = () => {
        doc.addImage(img, "JPG", 15, 10, 30, 15);

        doc.setDrawColor(0);
        doc.setLineWidth(0.5);
        doc.line(15, 25, 195, 25);

        // Título del documento
        doc.setFontSize(14);
        doc.setFont("times", "bold");
        doc.text(`ACTA DE ACEPTACIÓN DE REGLAMENTOS`, 105, 40, { align: "center" });

        // Cuerpo del texto
        doc.setFontSize(12);
        doc.setFont("times", "normal");
        const acceptanceText = `Yo, ${userName.toUpperCase()}, declaro haber recibido y aceptado los siguientes reglamentos proporcionados por ${groupName}:`;

        doc.text(acceptanceText, 15, 55, { maxWidth: 180 });

        let currentYPosition = 75;
        reglamentos.forEach((reglamento, index) => {
          doc.text(`${index + 1}. ${reglamento}`, 20, currentYPosition, { maxWidth: 180 });
          currentYPosition += 10;
        });

        doc.text(
          `Me comprometo a cumplir con todas las disposiciones establecidas en los documentos mencionados anteriormente.`,
          15,
          currentYPosition,
          { maxWidth: 180 }
        );

        // Cláusulas legales
        currentYPosition += 20;
        doc.setFontSize(10);
        doc.text(
          "Este documento es una confirmación digital de aceptación de los reglamentos mencionados y es legalmente vinculante.",
          15,
          currentYPosition,
          { maxWidth: 180 }
        );

        // Información de confirmación
        doc.setFontSize(10);
        currentYPosition += 15;
        doc.text(
          `Fecha y hora de descarga por ${name_user?.toUpperCase()}: ${new Date().toLocaleString()}`,
          15,
          currentYPosition
        );

        // Pie de página con información adicional
        doc.setFontSize(8);
        doc.text(
          `Generado automáticamente por: ${userName.toUpperCase()} en la fecha: ${moment(
            form.getFieldsValue().fecha_actualizacion
          ).format("DD/MM/YYYY")}. Firma manual no requerida.`,
          105,
          250,
          { align: "center" }
        );
        doc.addImage(barcodeData, "PNG", 60, 260, 90, 30);

        // Guarda el PDF
        doc.save(`Aceptacion_Reglamentos_${userName.toUpperCase().replace(/ /g, "_")}.pdf`);
      };
      img.onerror = () => {
        swalError({ text: "Ocurrio un error" });
      };
    } catch (error) {
      swalError({ text: "Ocurrio un error" });
    }
  };

  const downloadPDFMedios = async (userName: string, groupName: string, id_luc: number) => {
    try {
      if (!form.getFieldsValue().es_aceptacion_me)
        return notification.warning({
          message: "Advertencia",
          description: "No existe aceptación de este empleado en este periodo!",
        });
      const doc = new jsPDF();
      const img = new Image();
      img.src = "/imagen/images.jpg";

      const canvas = document.createElement("canvas");

      const barcodeValue = "ME" + String(id_luc).padStart(10, "0");

      JsBarcode(canvas, barcodeValue, {
        format: "CODE128",
        lineColor: "#000",
        width: 2,
        height: 40,
        displayValue: true,
      });
      const barcodeData = canvas.toDataURL("image/png");

      img.onload = () => {
        doc.addImage(img, "JPG", 15, 10, 30, 15);
        doc.setDrawColor(0);
        doc.setLineWidth(0.5);
        doc.line(15, 25, 195, 25);

        // Título del documento
        doc.setFontSize(14);
        doc.setFont("times", "bold");
        doc.text(`ACTA DE ACEPTACIÓN DE MEDIOS ELECTRÓNICOS`, 105, 40, { align: "center" });

        // Cuerpo del texto
        doc.setFontSize(12);
        doc.setFont("times", "normal");
        const acceptanceText =
          `Por este medio, yo ${userName?.toUpperCase()}, certifico que he otorgado mi consentimiento de forma ` +
          `libre, expresa, informada e inequívoca según lo dispuesto en la legislación aplicable sobre comercio ` +
          `electrónico, firmas digitales y mensajes de datos. Doy fe de que los datos personales proporcionados ` +
          `por mí han sido accesibles y sujetos a tratamiento por parte del departamento de Talento y Recursos ` +
          `Humanos de la ${groupName}. Acepto que mis datos personales y sensibles sean utilizados con el propósito ` +
          `de mantener actualizados los registros corporativos y recibir notificaciones pertinentes a través de ` +
          `medios electrónicos designados por la entidad, en conformidad con los Artículos 2, 3, 52 y 56 de la normativa vigente.` +
          `\n\nEste documento sirve como constancia irrevocable de mi aceptación y no podré objetar o retractarme ` +
          `del acceso o uso de mis datos personales y la aceptación de los medios electrónicos necesarios para ` +
          `los fines mencionados, salvo expresión contraria en los términos estipulados por la ley.`;

        doc.text(acceptanceText, 17, 50, { maxWidth: 180 });

        let currentYPosition = 115;

        currentYPosition += 20;
        doc.setFontSize(10);
        doc.text(
          "Este documento es una confirmación de consentimiento de aceptación de medios electrónicos",
          17,
          currentYPosition,
          { maxWidth: 180 }
        );

        // Información de confirmación
        doc.setFontSize(10);
        currentYPosition += 15;
        doc.text(
          `Fecha y hora de descarga por ${name_user?.toUpperCase()}: ${new Date().toLocaleString()}`,
          17,
          currentYPosition
        );

        // Pie de página con información adicional
        doc.setFontSize(8);
        doc.text(
          `Generado automáticamente por: ${userName.toUpperCase()} en la fecha: ${moment(
            form.getFieldsValue().fecha_actualizacion
          ).format("DD/MM/YYYY")}. Firma manual no requerida.`,
          105,
          250,
          { align: "center" }
        );
        doc.addImage(barcodeData, "PNG", 60, 260, 90, 30);

        // Guarda el PDF
        doc.save(`Aceptacion_Reglamentos_${userName.toUpperCase().replace(/ /g, "_")}.pdf`);
      };
      img.onerror = () => {
        swalError({ text: "Ocurrio un error" });
      };
    } catch (error) {
      swalError({ text: "Ocurrio un error" });
    }
  };

  const downloadPDFDatosPersonales = async (userName: string, groupName: string, id_luc: number) => {
    try {
      if (!form.getFieldsValue().es_aceptacion_dp)
        return notification.warning({
          message: "Advertencia",
          description: "No existe aceptación de este empleado en este periodo!",
        });
      const doc = new jsPDF();
      const img = new Image();
      img.src = "/imagen/images.jpg";

      const canvas = document.createElement("canvas");

      const barcodeValue = "DP" + String(id_luc).padStart(10, "0");

      JsBarcode(canvas, barcodeValue, {
        format: "CODE128",
        lineColor: "#000",
        width: 2,
        height: 40,
        displayValue: true,
      });
      const barcodeData = canvas.toDataURL("image/png");

      img.onload = () => {
        doc.addImage(img, "JPG", 15, 10, 30, 15);
        doc.setDrawColor(0);
        doc.setLineWidth(0.5);
        doc.line(15, 25, 195, 25);

        // Título del documento
        doc.setFontSize(14);
        doc.setFont("times", "bold");
        doc.text(`ACTA DE ACEPTACIÓN DEL TRATAMIENTO DE DATOS PERSONALES`, 105, 40, { align: "center" });

        // Cuerpo del texto
        doc.setFontSize(12);
        doc.setFont("times", "normal");
        const acceptanceText =
          `Yo, ${userName.toUpperCase()}, por la presente, manifiesto mi consentimiento de manera libre, ` +
          `expresa e informada, en cumplimiento de lo establecido por la Ley Orgánica de Protección de Datos Personales. ` +
          `Reconozco que los datos personales suministrados serán gestionados por el departamento de Recursos Humanos de ` +
          `la ${groupName}. Esta entidad utilizará mis datos personales y aquellos sensibles que resulten necesarios para ` +
          `los procesos de actualización de datos personales.\n\n` +
          `Estoy consciente de que mi negativa a proporcionar estos datos o a autorizar su tratamiento limitará la capacidad ` +
          `de la ${groupName} para alcanzar los objetivos previstos. Además, he sido debidamente informado de que mis datos ` +
          `serán parte de un registro llamado base de datos general, bajo la titularidad de la ${groupName}, y permanecerán ` +
          `en dicha base hasta que yo solicite su eliminación. Asimismo, he sido notificado de la transferencia de datos personales ` +
          `que se pueda realizar.\n\n` +
          `Adicionalmente, otorgo mi autorización para que la ${groupName} pueda emplear mis datos personales con el fin de: ` +
          `\n\n- Realizar encuestas de satisfacción que contribuyan a la mejora de la calidad de los servicios ofrecidos. ` +
          `\n\n- Enviar comunicaciones relacionadas con nuevos servicios y/o promociones que puedan ser de mi interés.`;

        doc.text(acceptanceText, 17, 50, { maxWidth: 180 });

        let currentYPosition = 137;

        currentYPosition += 20;
        doc.setFontSize(10);
        doc.text(
          "Este documento es una confirmación de consentimiento de aceptación del tratamiento de datos personales",
          17,
          currentYPosition,
          { maxWidth: 180 }
        );

        // Información de confirmación
        doc.setFontSize(10);
        currentYPosition += 15;
        doc.text(`Fecha y hora de descarga por ${name_user}: ${new Date().toLocaleString()}`, 17, currentYPosition);

        // Pie de página con información adicional
        doc.setFontSize(8);
        // doc.text(`Documento generado automáticamente. No se requiere firma manual.`, 105, 280, { align: "center" });
        doc.text(
          `Generado automáticamente por: ${userName.toUpperCase()} en la fecha: ${moment(
            form.getFieldsValue().fecha_actualizacion
          ).format("DD/MM/YYYY")}. Firma manual no requerida.`,
          105,
          250,
          { align: "center" }
        );
        doc.addImage(barcodeData, "PNG", 60, 260, 90, 30);
        // Guarda el PDF
        doc.save(`Aceptacion_Reglamentos_${userName.replace(/ /g, "_")}.pdf`);
      };
      img.onerror = () => {
        swalError({ text: "Ocurrio un error" });
      };
    } catch (error) {
      swalError({ text: "Ocurrio un error" });
    }
  };

  const saveDataSql = async (formulario: any) => {
    try {
      const respuesta = await fetch(url2 + "rrhh/guardar-sql", optionsPost(formulario)).then((data) => data.json());

      if (respuesta.ok) {
        return { respuesta, status: true };
      } else {
        return { respuesta: "Ocurrio un error al guardar", status: false };
      }
    } catch (error) {
      return { msg: "no_conexion", status: false };
    }
  };

  const handleClickData = async (form: FormInstance<any>) => {
    try {
      let { estado_civil, conyugue, estado: estado2 } = form.getFieldsValue();

      if (estado2 === "Aprobado") {
        notification.error({
          message: "Error",
          description: "La aprobación solo se puede realizar una vez en este periodo ",
        });
        return;
      }

      if (estado_civil === 2 || estado_civil === 5 || estado_civil === 6) {
        if (!conyugue.cedula_conyugue || !conyugue.nombres_conyugue || conyugue.nombres_conyugue?.trim()?.length < 1) {
          notification.error({
            message: "Error",
            description:
              "Para guardar como correcto es necesario que especifique la cedula y los nombres completos del conyuge del empleado/a",
          });
          return;
        }
      }

      setLoading2(true);
      let valida = estado.some((elemento) => elemento.status === 1 || elemento.status === 2);
      if (valida) {
        notification.error({
          message: "Error",
          description: "La operación de guardar no puede ser completada porque algunos archivos no han sido aprobados ",
        });
        setLoading2(false);
        return;
      }
      if (edit) {
        notification.error({
          message: "Error",
          description: "La operación de guardar no puede ser completada porque tienes activado la edición",
        });
        setLoading2(false);
        return;
      }
      form.validateFields().then(async (values) => {
        let cod_empleado = form.getFieldsValue().cod_empleado;

        let res = await saveDataSql({ data: form.getFieldsValue(), id, archivos });
        if (res.status) {
          const newEnviadas: any = enviadas?.map((el) => {
            if (el.key === cod_empleado) {
              return { ...el, estado: "Aprobado" };
            }
            return el;
          });

          notification.success({
            message: "Ok",
            description: "Se guardó correctamente la actualizacion del usuario",
          });
          // if (getEnviadas && valuePeriodo) getEnviadas(valuePeriodo);
          setEnviadas && setEnviadas(newEnviadas);
          handleOk();
          setLoading2(false);
        } else {
          notification.error({
            message: "Error",
            description: "Ocurrio un error al guardar",
          });
          setLoading2(false);
        }
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "La operación de guardar no puede ser completada porque existen errores",
      });
      setLoading2(false);
    }
  };

  const handleClickDataEdit = async (form: FormInstance<any>) => {
    let cod_empleado = form.getFieldsValue().cod_empleado;

    try {
      let { estado: estado2 } = form.getFieldsValue();
      if (estado2 === "Aprobado") {
        notification.error({
          message: "Error",
          description: "Una vez realizada la aprobación no se puede permitir la edición",
        });
        return;
      }

      setLoading(true);
      let valida = estado.some((elemento) => elemento.status === 1);
      if (valida) {
        notification.error({
          message: "Error",
          description: "No se puede enviar a edición porque tienes archivos que aún estan pendientes.",
        });
        setLoading(false);
        return;
      }
      if (!edit) {
        setLoading(false);
        notification.error({
          message: "Error",
          description: "La operación de guardar no puede ser completada porque no tienes activado la edición",
        });
        return;
      }
      let a = await saveData({ data: form.getFieldsValue(), estado });
      if (a.respuesta.ok) {
        const newEnviadas: any = enviadas?.map((el) => {
          if (el.key === cod_empleado) {
            return { ...el, estado: "Edicion" };
          }
          return el;
        });

        notification.success({
          message: "Ok",
          description:
            "Se notificó al empleado que se habilitó la edición de información debido a que su información contiene errores.",
        });

        setEnviadas && setEnviadas(newEnviadas);
        // if (getEnviadas && valuePeriodo) getEnviadas(valuePeriodo);
        setLoading(false);
        handleOk();
      }
    } catch (error) {}
  };

  const handleModalArchive = (llave: string) => {
    const extension = llave.substring(llave.lastIndexOf(".") + 1);
    // setUrl(`http://localhost:8083/allku/archivos/${llave}`);
    setUrl(`https://infofsg.com/allku/archivos/${llave}`);

    if (extension.toLowerCase() === "pdf") {
      setVisible(true);
    } else {
      setVisibleImg(true);
    }
  };

  const data: DataType[] = useMemo(() => {
    let array: any[] = [];

    archivos?.forEach((el) => {
      let parts = el.llave?.split("_");
      if (parts) {
        let text = parts[1];
        array.push({ estado: el.estado, text, llave: el.llave, id_archivo: el.key });
      }
    });

    return [
      {
        key: "1",
        descripcion: "FOTO TAMAÑO CARNET",
        llave: array.find((elemento) => elemento.text === "foto")?.llave || "",
        conyugue: array.some((elemento) => elemento.text === "foto"),
        status: array.find((elemento) => elemento.text === "foto")?.estado || 3,
        id_archivo: array.find((elemento) => elemento.text === "foto")?.id_archivo || null,
        name: "rrhh_foto_2023_02_08_f25691e8-7688-4fd5-853e-9bfdaf15ba94.jpeg",
      },
      {
        key: "2",
        descripcion: "CEDULA Y CERTIFICADO DE VOTACION",
        llave: array.find((elemento) => elemento.text === "cedula")?.llave || "",
        conyugue: array.some((elemento) => elemento.text === "cedula"),
        status: array.find((elemento) => elemento.text === "cedula")?.estado || 3,
        id_archivo: array.find((elemento) => elemento.text === "cedula")?.id_archivo || null,
        name: "rrhh_cedula_2023_03_02_d1be6c69-d73d-46c3-87fc-f821e8c1fff3.pdf",
      },
      {
        key: "3",
        descripcion: "CEDULA O PARTIDA NACIMIENTO HIJOS",
        llave: array.find((elemento) => elemento.text === "cedula-hijos")?.llave || "",
        conyugue: array.some((elemento) => elemento.text === "cedula-hijos"),
        status: array.find((elemento) => elemento.text === "cedula-hijos")?.estado || 3,
        id_archivo: array.find((elemento) => elemento.text === "cedula-hijos")?.id_archivo || null,
        name: "rrhh_cedula-hijos_2023_03_02_b059d189-e77b-4d07-bb97-e510f705c565.pdf",
      },
      {
        key: "4",
        descripcion: "CEDULA O CERTIFICADO DE UNION DE HECHO",
        llave: array.find((elemento) => elemento.text === "cedula-conyugue")?.llave || "",
        conyugue: array.some((elemento) => elemento.text === "cedula-conyugue"),
        status: array.find((elemento) => elemento.text === "cedula-conyugue")?.estado || 3,
        id_archivo: array.find((elemento) => elemento.text === "cedula-conyugue")?.id_archivo || null,
        name: "rrhh_cedula-conyugue_2023_03_02_6e50b91a-3cfc-4b3c-ab32-be1447352a50.pdf",
      },
      {
        key: "5",
        descripcion: "HIJOS CON DISCAPACIDAD",
        llave: array.find((elemento) => elemento.text === "hijos-discapacidad")?.llave || "",
        conyugue: array.some((elemento) => elemento.text === "hijos-discapacidad"),
        status: array.find((elemento) => elemento.text === "hijos-discapacidad")?.estado || 3,
        id_archivo: array.find((elemento) => elemento.text === "hijos-discapacidad")?.id_archivo || null,
        name: "rrhh_hijos-discapacidad_2023_03_02_2cbfec8b-33e8-44a0-88a7-0e0980b45c75.pdf",
      },
      {
        key: "6",
        descripcion: "LICENCIA DE CONDUCIR",
        llave: array.find((elemento) => elemento.text === "licencia")?.llave || "",
        conyugue: array.some((elemento) => elemento.text === "licencia"),
        status: array.find((elemento) => elemento.text === "licencia")?.estado || 3,
        id_archivo: array.find((elemento) => elemento.text === "licencia")?.id_archivo || null,
        name: "rrhh_licencia_2023_03_02_c9c6d883-e71d-44f5-ae47-639efdf3286b.pdf",
      },
    ];
  }, [archivos]);

  useEffect(() => {
    setEstado(data);
  }, [data]);

  const columns: ColumnsType<DataType> = [
    {
      title: "DESCRIPCION",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "30%",
      // render: (text) => <a>{text}</a>,
    },

    {
      title: "VER",
      dataIndex: "llave",
      key: "llave",
      width: "10%",
      render: (_, { llave }) => {
        return llave.length > 5 ? <EyeOutlined onClick={() => handleModalArchive(llave)} /> : <EyeInvisibleOutlined />;
      },
    },
    {
      title: "ESTADO",
      key: "status",
      dataIndex: "status",
      width: "40%",
      render: (_, { key, status }) => {
        return (
          <Radio.Group
            defaultValue={status}
            buttonStyle='solid'
            size='small'
            onChange={(e) => {
              let newData = estado.map(({ ...el }) => {
                if (el.key === key) {
                  el.status = e.target.value;
                }
                return el;
              });
              setEstado(newData);
            }}
          >
            <Radio.Button value={1} style={{ marginRight: "8px" }}>
              Pendiente
            </Radio.Button>
            <Radio.Button value={2} style={{ marginRight: "8px" }}>
              Con Error
            </Radio.Button>
            <Radio.Button value={3} style={{ marginRight: "8px" }}>
              Aprobado
            </Radio.Button>
          </Radio.Group>
        );
      },
    },
  ];

  return {
    columns,
    data,
    visible,
    url,
    setVisible,
    visibleImg,
    setVisibleImg,
    handleClickData,
    setEdit,
    edit,
    handleClickDataEdit,
    loading,
    loading2,
    downloadPDFReglamentos,
    downloadPDFMedios,
    downloadPDFDatosPersonales,
  };
}
