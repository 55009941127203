import { TabsProps } from "antd/lib/tabs";

import { Tabs } from "antd";
import { AgregarHijos } from "./AgregarHijos";
import MiInformacion from "./MiInformacion";
import OtrosDatos from "./OtrosDatos";
import { Extras } from "./Extras";
import { Props } from "../../../hooks/rrhh/actualizacion/useFormularioActualizacion";

export const DatosPersonales = ({
  setValidaOperacion,
  validaOperacion,
  changeCivil,
  validaConyugue,
  activeKey2,
  setActiveKey2,
  form,
  fileSelected,
  hidden,
  hidden2,
  handleChangeIngreso,
  handleChangeParentezco,
  hijos,
  handleChangeHijos,
  validaEstudio,
  handleChange2,
}: Props) => {
  const items: TabsProps = {
    items: [
      {
        label: "1",
        key: "1",
        tabKey: "1",
        forceRender: true,
        children: <MiInformacion changeCivil={changeCivil} />,
      },
      {
        label: "2",
        key: "2",
        tabKey: "2",
        forceRender: true,
        children: (
          <OtrosDatos
            hijos={hijos}
            handleChangeHijos={handleChangeHijos}
            validaConyugue={validaConyugue}
            form={form}
            hidden={hidden}
            hidden2={hidden2}
            handleChangeIngreso={handleChangeIngreso}
            handleChangeParentezco={handleChangeParentezco}
          />
        ),
      },
      {
        label: "3",
        tabKey: "3",
        key: "3",
        children: (
          <Extras
            validaOperacion={validaOperacion}
            setValidaOperacion={setValidaOperacion}
            form={form}
            validaEstudio={validaEstudio}
            handleChange2={handleChange2}
          />
        ),
        forceRender: true,
      },
      {
        label: "4",
        tabKey: "4",
        key: "4",
        children: <AgregarHijos fileSelected={fileSelected} />,
        forceRender: true,
      },
    ],
  };

  const handleChangeTab2 = (value: string) => {
    setActiveKey2(value);
  };
  return (
    <>
      <h3 style={{ textAlign: "center", color: "var(--primary)" }}>Complete los formularios con la información requerida</h3>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Tabs {...items} type='card' onChange={handleChangeTab2} centered={true} activeKey={activeKey2} />
      </div>
    </>
  );
};
