import { Page, Document, View, Text, StyleSheet } from "@react-pdf/renderer"
import type { BodegasDataPrincipal, Deudas, GastosFijos } from "context/franquiciados/form-pago-context"
import type { CalculosFinales } from "components/franquiciados/calculos-finales"
import { BodegasTable } from "components/franquiciados/reporte/bodegas-table"
import { VentasFarmaciaTable } from "components/franquiciados/reporte/ventas-farmacia-table"
import { BonosTable } from "components/franquiciados/reporte/bonos-table"
import { GastosTable } from "components/franquiciados/reporte/gastos-table"
import { TotalGastos } from "components/franquiciados/reporte/total-gastos"
import { TotalesFinales } from "components/franquiciados/reporte/totales-finales"
import { DatosFactura } from "components/franquiciados/reporte/datos-factura"
import { DeudasDetalle } from "./deudas-detalle"
import { VerticalTable } from "components/reactreport/vertical-table"
import { Moment } from "moment"

export type DatosPagoFranquiciado = {
  cod_pago: number;
  total: number;
  total_menos_gastos: number;
  retenciones: number;
  iva_12: number;
  valor_a_pagar: number;
  bodegas: BodegasDataPrincipal[];
  gastos_fijos: GastosFijos[];
  ventas_farmacias: CalculosFinales["ventas_farmacias"];
  bonos: CalculosFinales["bonos"];
  deudas: Deudas[];
  correo: string;
};

type Props = {
  dataPago?: DatosPagoFranquiciado
  mes: Moment
  franquiciado: string
  codigo: number
}

const styles = StyleSheet.create({
  body: {
    flexDirection: "row",
  },
  section: {
    margin: 10,
    padding: "10px 5px 10px 10px",
  },
  title: {
    fontSize: 14,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
    width: "100%",
    marginTop: "10px",
  },
  commonColumnStyle: {
    fontSize: 8,
  },
  rowHeaderStyle: {
    backgroundColor: "#dadada",
  },
});

export function ReportePDF({ dataPago, mes, franquiciado, codigo }: Props) {
  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <Text style={styles.title}>
          Liquidación del mes de {mes.format("MMMM [del] YYYY")} de {franquiciado}
        </Text>
        <View style={styles.body}>
          <View style={{ ...styles.section, width: "60%" }}>
            <BodegasTable
              bodegas={
                dataPago && dataPago?.bodegas.length >= 3
                  ? dataPago && dataPago.bodegas.slice(0, 3)
                  : dataPago?.bodegas ?? []
              }
              commonColumnStyle={styles.commonColumnStyle}
              rowHeaderStyle={styles.rowHeaderStyle}
              ventasFarmacias={dataPago?.ventas_farmacias ?? []}
              // franquiciado={codigo}
            />
            {dataPago && dataPago.bodegas.length < 3 && (
              <View>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <GastosTable
                    gastos={dataPago.gastos_fijos}
                    commonColumnStyle={styles.commonColumnStyle}
                    rowHeaderStyle={styles.rowHeaderStyle}
                  />
                  <TotalGastos bodegas={dataPago?.bodegas} gastos_fijos={dataPago?.gastos_fijos} cod_franquiciado={codigo} />
                </View>
                {dataPago.deudas && dataPago.deudas.length > 0 && (
                  <View style={{ marginTop: "20px" }}>
                    <DeudasDetalle deudas={dataPago.deudas} />
                  </View>
                )}
              </View>
            )}
          </View>
          <View style={{ ...styles.section, width: "40%" }}>
            <VentasFarmaciaTable
              ventas_farmacias={dataPago?.ventas_farmacias ?? []}
              commonColumnStyle={styles.commonColumnStyle}
              rowHeaderStyle={styles.rowHeaderStyle}
              borderBottom={(dataPago?.bonos?.length ?? 0) <= 0}
            />
            {dataPago && dataPago.bonos && dataPago.bonos.length > 0 && (
              <BonosTable
                bonos={dataPago.bonos}
                commonColumnStyle={styles.commonColumnStyle}
                rowHeaderStyle={styles.rowHeaderStyle}
              />
            )}
            <VerticalTable
              dataSource={[
                {
                  text: (
                    (dataPago?.ventas_farmacias?.reduce((acum, next) => acum + next.comision, 0) ?? 0) +
                    (dataPago?.bonos?.reduce((acum, next) => acum + next.valor, 0) ?? 0)
                  )
                    .toFixed(2)
                    .replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,"),
                  key: "total valores adicionales y ventas farmacias",
                  styleCell: { padding: 3 },
                  styleCellHeader: { padding: 3 },
                },
              ]}
              styleColumn={{ flexBasis: "100%" }}
              // styleColumnHeader={{ flexBasis: "70%", backgroundColor: "#dadada" }}
              containerStyle={{ marginLeft: "auto", width: "100%", alignSelf: "flex-end" }}
            />
            <TotalesFinales
              menos_gastos={dataPago?.total ?? 0}
              total_menos_gastos={dataPago?.total_menos_gastos ?? 0}
              iva_12={dataPago?.iva_12 ?? 0}
              retenciones={dataPago?.retenciones ?? 0}
              valor_a_pagar={dataPago?.valor_a_pagar ?? 0}
              mes={mes}
            />
            <DatosFactura
              commonColumnStyle={styles.commonColumnStyle}
              total_menos_gastos={dataPago?.total_menos_gastos ?? 0}
              retenciones={dataPago?.retenciones ?? 0}
              iva_12={dataPago?.iva_12 ?? 0}
              rowHeaderStyle={styles.rowHeaderStyle}
            />
          </View>
        </View>
        {dataPago && dataPago.bodegas.length < 3 && (
          <View style={styles.body}>
            <View style={styles.section}>
              <Text>——————————————</Text>
              <Text style={{ fontSize: 12, fontFamily: "Helvetica" }}>Ing. Fabrizzio Uscocovich Centeno</Text>
            </View>
            <Text style={styles.section}>——————————</Text>
          </View>
        )}
      </Page>
      {dataPago && dataPago.bodegas.length >= 3 && (
        <Page size="A4" orientation="landscape">
          <View style={styles.body}>
            <View style={{ ...styles.section, width: "60%" }}>
              <BodegasTable
                bodegas={dataPago?.bodegas.slice(3, dataPago?.bodegas.length) ?? []}
                commonColumnStyle={styles.commonColumnStyle}
                rowHeaderStyle={styles.rowHeaderStyle}
                ventasFarmacias={dataPago?.ventas_farmacias ?? []}
              />
              <View style={{ display: "flex", flexDirection: "row" }}>
                <GastosTable
                  gastos={dataPago?.gastos_fijos ?? []}
                  commonColumnStyle={styles.commonColumnStyle}
                  rowHeaderStyle={styles.rowHeaderStyle}
                />
                <TotalGastos
                  bodegas={dataPago?.bodegas ?? []}
                  gastos_fijos={dataPago?.gastos_fijos ?? []}
                  cod_franquiciado={codigo}
                />
              </View>
              {dataPago && dataPago.deudas && dataPago.deudas.length > 0 && (
                <View style={{ marginTop: "20px" }}>
                  <DeudasDetalle deudas={dataPago.deudas} />
                </View>
              )}
              <View style={{ ...styles.body, marginTop: "50px" }}>
                <View style={styles.section}>
                  <Text>——————————————</Text>
                  <Text style={{ fontSize: 12, fontFamily: "Helvetica" }}>Ing. Fabrizzio Uscocovich Centeno</Text>
                </View>
                <Text style={styles.section}>——————————</Text>
              </View>
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
}
