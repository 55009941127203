import { Badge, Button, TableColumnsType, Tooltip, Upload, UploadFile, UploadProps } from "antd";
import { useLayout } from "hooks/otros/useLayout";
import { useCallback, useState, useEffect, useRef } from "react";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import { url, url_alku } from "config/constants";
import { useLocalStorage } from "hooks/otros/useLocalStorage";

import { FileImageOutlined, FilePdfOutlined, FileExcelOutlined, CalendarOutlined, CheckOutlined } from "@ant-design/icons";
import { useExport } from "hooks/otros/useExport";
import { handleReportPdfMantenimientos } from "./useReportPdf";
import Swal from "sweetalert2";
import { getBase64, swalError, swalSuccess, swalWarning } from "config/methods";
import { RcFile } from "antd/lib/upload";
import "../inventarios/reportes/alertaEstilo.css";
const breadcrumbs = [{ Label: "Mantenimientos", Url: "/mantenimientos/aprobados" }, { Label: "Aprobados" }];

const openKeys = [breadcrumbs[0].Label];
export interface DetalleMantenimientoEnriquecido extends PropsDetalleMantenimientoRuta {
  Cod_Ruta: React.Key;
  Descripcion: string;
}
export interface PropsDetalleMantenimientoRuta {
  Cod_Mantenimiento_Solicitud: number;
  key: React.Key;
  Cod_Bodega: number;
  bodega: string;
  Provincia: string;
  Canton: string;
  Ciudad: string;
  Direccion: string;
  Latitude: string;
  Longitude: string;
  Detalle: string;
  DescripcionTipo: string;
  DescripcionDetalle: string;
  Fecha_Solicitud: string;
  Nombres: string;
  Cod_Tipo_Mantenimiento: number;
  TipoMantenimiento: string;
  imagenes: PropsDetalleImagenes[];
}
interface PropsDetalleImagenes {
  ruta_imagen: string;
}
type MantenimientosAprobadosData = {
  key: React.Key;
  Cod_Ruta: number;
  Descripcion: string;
  DetallesJSON: PropsDetalleMantenimientoRuta[];
};

export const useMantenimientoAprobados = () => {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);
  const [datos, setDatos] = useState<MantenimientosAprobadosData[]>([]);
  const { isError } = useVerifyResponse();

  const [previewImage, setPreviewImage] = useState("");
  const [searchText] = useState("");
  const { exportToExcel } = useExport();
  const [filteredData, setFilteredData] = useState<MantenimientosAprobadosData[]>([]);

  //const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<MantenimientosPendientesData>();
  const [files, setFiles] = useState<UploadFile[]>();

  const [previewTitle, setPreviewTitle] = useState("");
  const [codSolicitud, setCodSolicitud] = useState<React.Key>();
  const [previewOpen, setPreviewOpen] = useState(false);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [valueselect, setvalueselect] = useState("0");
  const [activeRowIndex] = useState<number>(0);
  const [selectedDetails, setSelectedDetails] = useState<DetalleMantenimientoEnriquecido[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSubir, setIsModalOpenAprobar] = useState(false);

  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  let { optionsPost } = useLocalStorage();

  const getDataMartillo = useCallback(async () => {
    try {
      const res = await fetch(url + "apifsg-pr/mantenimientos/aprobados", optionsPost({ valueselect }));

      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      // console.log(respuesta);
      if (respuesta.msg !== "err") {
        const respuestas: MantenimientosAprobadosData[] = respuesta.data;
        setDatos(
          respuestas.map(({ key, Cod_Ruta, Descripcion, DetallesJSON }) => ({
            key,
            Cod_Ruta,
            Descripcion,
            DetallesJSON,
          }))
        );
        setFilteredData(respuesta.data);
      } else {
        setFilteredData([]);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setFilteredData([]);
      return { msg: "no_conexion", status: false };
    }
  }, [isError, optionsPost, valueselect]);

  useEffect(() => {
    getDataMartillo();
  }, [getDataMartillo]);
  const rowSelection = (key: React.Key, Descripcion: string) => ({
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: PropsDetalleMantenimientoRuta[]) => {
      const newSelectedDetails = selectedRows.map((row) => ({ Cod_Ruta: key, Descripcion: Descripcion, ...row }));
      setSelectedDetails(newSelectedDetails);
    },
  });

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos);
    } else {
      const filtered = datos.filter(
        (item) => item.Descripcion && item.Descripcion.toString().toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  const handleChange = (value: string) => {
    setvalueselect(value);
  };
  const handleCancel = async () => {
    setIsModalOpenAprobar(false);

    const allResponses = files?.map(({ response }) => response[0]);

    // Verificamos si tenemos respuestas para enviar
    if (allResponses && allResponses.length > 0) {
      try {
        const res = await fetch(`${url_alku}allku/mantenimientos_eliminar`, optionsPost({ files: allResponses }));
        // Aquí manejas la respuesta de la solicitud
        if (!res.ok) throw new Error();
        setFiles([]); // Actualizamos el estado para reflejar que los archivos fueron eliminados
        localStorage.removeItem("mantenimientos"); // O actualizas según necesites
      } catch (error) {
        // Manejo de errores
        swalError({ text: "Error al eliminar archivos: " + error });
      }
    }
  };
  const handleClickDownloadData = (selectedRecords: PropsDetalleMantenimientoRuta[]) => {
    const detallesParaExportar = selectedRecords.flatMap((record) => {
      return {
        ...record,
        imagenes: JSON.stringify(record.imagenes),
      };
    });

    const texto = "mantenimientos_seleccionados";
    exportToExcel(detallesParaExportar, texto);
  };
  const checkFile: UploadProps["beforeUpload"] = (file) => {
    /*     console.log("checkfile")
        console.log(file) */
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      swalWarning({ text: "Solo se permiten imágenes." });
      return Upload.LIST_IGNORE;
    }
    return !isImage;
  };
  const onChangeFile: UploadProps["onChange"] = async ({ file, fileList }) => {
    if (file.status === "removed") return;
    //if (file.status !== "done" && file.status !== "success") return
    try {
      if (fileList.length < 16) {
        const formData = new FormData();
        formData.append("uploadfile", file as RcFile);
        formData.append("archivo", "mantenimientos-completados");
        const res = await fetch(`${url_alku}allku/mantenimientos`, {
          method: "POST",
          body: formData,
        });

        if (!res.ok) throw new Error();
        const { llave } = await res.json();
        const newFile = fileList.find((item) => item.uid === file.uid);
        const newFiles = [
          ...(files ?? []),
          { uid: newFile?.uid ?? "", name: newFile?.name ?? "", response: llave, originFileObj: newFile?.originFileObj },
        ];
        setFiles((state) => [
          ...(state ?? []),
          { uid: newFile?.uid ?? "", name: newFile?.name ?? "", response: llave, originFileObj: newFile?.originFileObj },
        ]);
        localStorage.setItem(
          "mantenimientos",
          JSON.stringify(newFiles.map(({ uid, name, response, originFileObj }) => ({ uid, name, response, originFileObj })))
        );
      } else {
        swalWarning({
          text: "No se puede subir mas de 15 imagenes",
          customClass: {
            container: "swal2-custom",
          },
        });
        return;
      }
    } catch (e) {
      swalError({
        text: "No se pudo subir la imagen",
        customClass: {
          container: "swal2-custom",
        },
      });
    }
  };
  const handleRemove: UploadProps["onRemove"] = async ({ uid }) => {
    let result = true;
    try {
      await fetch(
        `${url}apiallku-private-eliminar/api/deletefromapiexternamantenimiento`,
        optionsPost({ files: files?.filter(({ uid: uid2 }) => uid2 === uid).map(({ response }) => response) })
      );

      const newFiles = files?.filter(({ uid: uid2 }) => uid2 !== uid) ?? [];
      setFiles(newFiles);
      //console.log(newFiles)
      if (newFiles.length === 0) {
        localStorage.removeItem("mantenimientos");
        return;
      }
      localStorage.setItem(
        "mantenimientos",
        JSON.stringify(newFiles.map(({ uid, name, status, response }) => ({ uid, name, status, response })))
      );
    } catch (e) {
      result = false;
    } finally {
      return result;
    }
  };
  const handleok = async (observacion: string) => {
    if (files !== undefined && files.length >= 1) {
      try {
        console.log(observacion);
        console.log(files?.length);
        const res = await fetch(
          `${url}apifsg-pr/mantenimientos/completar_mantenimiento`,
          optionsPost({
            fotos: files?.map(({ response }) => response),
            descripcion: observacion,
            codSolicitud: codSolicitud,
          })
        );
        if (!res.ok) throw new Error();
        let text = await res.json();
        text = text["msg"];

        swalSuccess({
          text,
          customClass: {
            container: "swal2-custom",
          },
        });
        setFiles([]);
        setIsModalOpenAprobar(false);
        getDataMartillo();
      } catch (error) {
        swalWarning({
          text: "Ocurrió un error:" + error,
          customClass: {
            container: "swal2-custom",
          },
        });
      }
    } else {
      swalWarning({
        text: "Debe adjuntar las imágenes",
        customClass: {
          container: "swal2-custom",
        },
      });
    }
    // console.log(files?.map(({ response, uid }) => ({ response, uid })));
  };
  const onPreviewImage = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
  };
  const columns: TableColumnsType<MantenimientosAprobadosData> = [
    {
      title: "Rutas",
      dataIndex: "Descripcion",
      key: "Descripcion",
      filters: [...new Set(filteredData.map(({ Descripcion }) => Descripcion))].map((Descripcion) => ({
        text: Descripcion,
        value: Descripcion,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.Descripcion,
      render: (value, record) => (
        <span>
          {value} <Badge count={record.DetallesJSON.length} size="small" offset={[5, -15]} />
        </span>
      ),
    },
    {
      title: "EXCEL",
      dataIndex: "key",
      key: "key",
      render: (value, record) => (
        <Button
          icon={<FileExcelOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          disabled={!selectedDetails.some((detail) => detail.Cod_Ruta === value)}
          onClick={async () => {
            handleClickDownloadData(selectedDetails);
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
    },
    {
      title: "PDF",
      dataIndex: "key",
      key: "key",
      render: (value, record) => (
        <Button
          icon={<FilePdfOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          disabled={!selectedDetails.some((detail) => detail.Cod_Ruta === value)}
          onClick={async () => {
            fnDownloadPDFBodega(selectedDetails);
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
    },
  ];
  const fnDownloadPDFBodega = (selectedRecords: PropsDetalleMantenimientoRuta[]) => {
    handleReportPdfMantenimientos(selectedRecords);
  };
  const reprogramarMantenimiento = async (codSolicitud: React.Key, fecha: string, descripcion: string) => {
    try {
      const result = await fetch(
        url + "apifsg-pr/mantenimientos/reprograma_mantenimiento",
        optionsPost({
          codSolicitud,
          descripcion,
          fecha,
        })
      );
      if (isError(result)) throw new Error();
      let text = await result.json();
      text = text["msg"];

      swalSuccess({
        text,
      });
      getDataMartillo();
    } catch (e) {
      swalError({
        text: "No se pudo reprogramar el mantenimiento",
      });
    }
  };
  const columnsTable: TableColumnsType<PropsDetalleMantenimientoRuta> = [
    {
      title: "Bodega",
      dataIndex: "bodega",
      key: "bodega",
      width: "200px",
      fixed: "left",
    },
    {
      title: "Fecha solicitud",
      dataIndex: "Fecha_Solicitud",
      key: "Fecha_Solicitud",
      width: "130px",
      fixed: "left",

      render: (text, record) => {
        const partes = text.split("/");
        if (partes.length === 3) {
          const fechaSolicitud = new Date(partes[2], partes[1] - 1, partes[0]); // Meses son 0-indexados en JS
          const fechaActual = new Date();

          const añoSolicitud = fechaSolicitud.getFullYear();
          const mesSolicitud = fechaSolicitud.getMonth();
          const díaSolicitud = fechaSolicitud.getDate();

          const añoActual = fechaActual.getFullYear();
          const mesActual = fechaActual.getMonth();
          const díaActual = fechaActual.getDate();

          const esIgual = añoSolicitud === añoActual && mesSolicitud === mesActual && díaSolicitud === díaActual;
          const esMayor = fechaSolicitud > fechaActual; // Esto aún considera las horas, para "esMayor" podría ser necesario un ajuste similar si se requiere precisión solo por fecha

          let colorTexto = "red";
          if (esIgual) {
            colorTexto = "orange";
          } else if (esMayor) {
            colorTexto = "green";
          }

          return <div style={{ color: colorTexto }}>{text}</div>;
        } else {
          return <div>{text}</div>;
        }
      },
    },

    { title: "Descripción", dataIndex: "DescripcionTipo", key: "DescripcionTipo", width: "190px", fixed: "left" },
    { title: "Detalle", dataIndex: "DescripcionDetalle", key: "DescripcionDetalle", width: "200px", fixed: "left" },
    {
      title: "Solicita",
      dataIndex: "Nombres",
      key: "Nombres",
      width: "190px",
      fixed: "left",
    },
    {
      title: "Reprogramar",
      dataIndex: "rutaFinal",
      key: "rutaFinal",
      align: "center",
      render: (value, record) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const fechaSolicitudParts = record.Fecha_Solicitud.split("/");

        const year = parseInt(fechaSolicitudParts[2], 10);
        const month = parseInt(fechaSolicitudParts[1], 10) - 1; // Meses son 0-indexados en JS
        const day = parseInt(fechaSolicitudParts[0], 10);

        // Crear la fecha de solicitud con los valores numéricos
        const fechaSolicitud = new Date(year, month, day);

        // Ajustar las horas a 0 para comparaciones que ignoran la hora
        fechaSolicitud.setHours(0, 0, 0, 0);

        return (
          <Button
            icon={<CalendarOutlined style={{ fontSize: "1.3rem" }} />}
            type="text"
            disabled={fechaSolicitud > today}
            onClick={async () => {
              await Swal.fire({
                title: "Seleccione la fecha e ingrese un motivo",
                html: `
                  <input type="date" id="date" class="swal2-input" style="width:300px;">
                  <textarea id="reason" class="swal2-textarea" placeholder="Motivo de reprogramación" style="height: 100px;width:300px;"></textarea>
                `,
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#8b0505",
                preConfirm: () => {
                  const selectedDateString = (document.getElementById("date") as HTMLInputElement).value;

                  // Extraer año, mes y día de la cadena de fecha seleccionada
                  const [year, month, day] = selectedDateString.split("-").map(Number);

                  // Crear un nuevo objeto Date con la fecha seleccionada interpretada como medianoche en la zona horaria local
                  const selectedDateObj = new Date(year, month - 1, day); // Los meses en JavaScript son 0-indexados, por eso se resta 1

                  const currentDate = new Date();
                  currentDate.setHours(0, 0, 0, 0); // Ajustar la fecha actual a medianoche para la comparación

                  const solicitudDate = new Date(record.Fecha_Solicitud);
                  solicitudDate.setHours(0, 0, 0, 0); // Ajustar la fecha de solicitud a medianoche para la comparación

                  const reason = (document.getElementById("reason") as HTMLInputElement).value;

                  if (!selectedDateString || !reason) {
                    Swal.showValidationMessage("Por favor seleccione una fecha y especifique el motivo");
                    return false;
                  }

                  if (selectedDateObj < currentDate) {
                    Swal.showValidationMessage("La fecha debe ser mayor o igual a la fecha actual");
                    return false; // Detener si la fecha seleccionada es anterior o igual a la actual
                  }

                  if (selectedDateObj <= solicitudDate) {
                    Swal.showValidationMessage("La fecha debe ser mayor a la fecha programada");
                    return false; // Detener si la fecha seleccionada es anterior o igual a la fecha de solicitud
                  }

                  return { date: selectedDateString, reason };
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  const selectedDateString = (document.getElementById("date") as HTMLInputElement).value;

                  // Extraer año, mes y día de la cadena de fecha seleccionada
                  const [year, month, day] = selectedDateString.split("-").map(Number);
                  const selectedDateObj = new Date(year, month - 1, day);
                  const formattedDate = selectedDateObj.toLocaleDateString("es-ES", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  });
                  reprogramarMantenimiento(record.key, formattedDate, result.value.reason);
                }
              });
            }}
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            block
          />
        );
      },
    },
    {
      title: "Completar",
      dataIndex: "key",
      key: "key",
      align: "center",
      render: (value, record) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const fechaSolicitudParts = record.Fecha_Solicitud.split("/");

        const year = parseInt(fechaSolicitudParts[2], 10);
        const month = parseInt(fechaSolicitudParts[1], 10) - 1; // Meses son 0-indexados en JS
        const day = parseInt(fechaSolicitudParts[0], 10);

        // Crear la fecha de solicitud con los valores numéricos
        const fechaSolicitud = new Date(year, month, day);

        // Ajustar las horas a 0 para comparaciones que ignoran la hora
        fechaSolicitud.setHours(0, 0, 0, 0);

        return (
          <Tooltip title="Aprobar">
            <Button
              type={"default"}
              size="small"
              //style={{ marginRight: "3px" }}
              //type="text"
              onClick={() => {
                setCodSolicitud(record.key);
                setFiles([]);
                setIsModalOpenAprobar(true);
              }}
              style={{ alignItems: "center", justifyContent: "center", marginRight: "3px" }}
            >
              <CheckOutlined />
            </Button>
          </Tooltip>
        );
      },
    },
    {
      title: "IMG",
      dataIndex: "rutaFinal",
      key: "rutaFinal",
      align: "center",
      render: (value, record) => (
        <Button
          icon={<FileImageOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={() => {
            if (!record.imagenes || record.imagenes.length === 0) {
            } else {
              const imageUrls = record.imagenes.map((img) => `${url_alku}allku/api/${img.ruta_imagen}`);
              setSelectedImages(imageUrls);
              setIsModalOpen(true);
            }
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
    },
  ];

  return {
    columns,
    datos,
    columnsTable,
    filteredData,
    activeRowIndex,
    tableContainerRef,
    rowSelection,
    isModalOpen,
    isModalSubir,
    selectedImages,
    setIsModalOpen,
    handleChange,
    checkFile,
    onChangeFile,
    handleRemove,
    onPreviewImage,
    previewOpen,
    previewTitle,
    previewImage,
    setPreviewOpen,
    handleCancel,
    files,
    handleok,
  };
};
