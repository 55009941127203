import { createContext, useState } from "react"
import { datosUsuario } from "types"

interface UserContextType {
  userData?: datosUsuario
  setUserData: React.Dispatch<React.SetStateAction<datosUsuario | undefined>>
}

interface ContextUserProps {
  children: JSX.Element | Array<JSX.Element>
}

export const UserContext = createContext<UserContextType>({} as UserContextType)

export function ContextUser({ children }: ContextUserProps) {

  const [ userData, setUserData ] = useState<datosUsuario>()

  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      { children }
    </UserContext.Provider>
  )
}