import { useRevisarInformacion } from "hooks/rrhh/actualizacion/useRevisarInformacion";

import { Modal, Table, Button, Form, Select } from "antd";

import { FormulariosPorPasos } from "./FormulariosPorPasos";
import * as XLSX from "xlsx";

export interface PropFlag {
  row?: PropsFormu;
  flag?: number;
  handleOk?: any;
  getEnviadas?: (value: number) => void;
  valuePeriodo?: number;
  enviadas?: PropsFormu[];
  setEnviadas?: (data: PropsFormu[]) => void;
}

export interface PropsFormu {
  cod_periodo: number;
  array: any[];
  key: number;
  cedula_conyugue: string;
  nombres: string;
  apellidos: string;
  cedula: string;
  fecha_nacimiento: Date | any;
  correo_personal: string;
  telefono_1: string;
  telefono_2: null;
  direccion: string | any;
  direcciones: string;
  es_adicional: boolean;
  fecha: Date | any;
  cantidad_hijos: number;
  estatura: number;
  peso: number;
  residen_cantidad: number;
  fecha_ultimo_examen: Date | any;
  fecha_examen: Date | any;
  operacion: null;
  buses: number;
  distancia: number;
  cod_estado_civil: number;
  cod_tipo_sangre: number;
  cod_nacionalidad: number;
  cod_cargo: number;
  lugar_nacimiento: string;
  cod_otras_destrezas: number;
  cod_emergencia: number;
  es_parentezco: boolean;
  es_operacion: boolean;
  cual_ingreso: string;
  cual_operacion: null;
  coordenadas: string;
  cod_instruccion_academica: number;
  titulo: string;
  monto_ingreso: number;
  cod_estudias_actualidad: number;
  cod_instituciones: number;
  fecha_actualizacion: Date;
  primaria: string;
  secundaria: string;
  tercer: string;
  cuarto: string;
  es_computadora: boolean;
  maquinas_industriales: null;
  otros: null;
  cod_civil: number;
  estado_civil: string;
  que_estudias: null;
  donde_estudias: null;
  es_estudias: boolean;
  nivel_estudio: string;
  nacionalidad: string;
  tipo_sangre: number;
  referencias: Referencia[];
  cursos: PropCursos[] | [];
  idiomas: Idioma[];
  residentes: PropResidente[];
  hijos: any[];
  archivos: Archivo[];
  conyugue: conyugue;
  madre: Madre;
  programas: Programa[];
  padre: Madre;
  licencias: any[];
  vehiculos: any[];
  contacto_emergencia: string;
  parientes: PropsPariente;
  emergencia: ContactoEmergencia;
  telefono_emergencia: null | string | number;
  telefono_emergencia2: null | string | number;
  nombres_madre: string;
  ocupacion_madre: string;
  nombres_padres: string;
  ocupacion_padre: string;
  es_fallecida_mama: Boolean;
  es_desconocida_mama: Boolean;
  es_fallecido_papa: Boolean;
  es_desconocido_papa: Boolean;
  nombres_conyugue: string;
  empresa_conyugue: string;
  telefono_oficina_conyugue: string;
  ocupacion_conyugue: string;
  sueldo_conyugue: number;
  es_ingreso_adicional: string;
  personas_residen: string;
  nombres_relacion_parentesco: string;
  tipo_parentesco: string;
}

export interface PropResidente {
  key: 3;
  nombres_residente: string;
  ocupacion_residente: string;
  fecha_nacimiento_residente: Date | any;
}

export interface PropCursos {
  nombre_curso: string;
  fecha_inicio: Date | any;
  fecha_fin: Date | any;
}

export interface PropsPariente {
  nombres: string;
  parentesco: string;
}

export interface conyugue {
  key: number;
  nombres: string;
  telefono: string;
  ocupacion: string;
  empresa_labora: string;
  sueldo_conyugue: number;
  cedula_conyugue: string;
}

export interface Archivo {
  key: number;
  llave: string;
  estado: number;
}

export interface ContactoEmergencia {
  key: number;
  celular: null | string | number;
  nombres: string;
  convencional: string;
}

export interface Curso {
  key: number;
  fecha_fin: Date;
  fecha_inicio: Date;
  nombre_curso: string;
}

export interface Idioma {
  key: number;
  idioma: string;
  nivel_oral: number;
  nivel_escrito: number;
  nivel_lectura: number;
}

export interface Madre {
  key: number;
  nombres: string;
  ocupacion: string;
  fecha_nacimiento?: Date | any;
  es_fallecido: boolean;
  es_desconocido: boolean;
}

export interface Programa {
  nombre: string;
}

export interface Referencia {
  key: number;
  nombres: string;
  telefono: string;
  direccion: string;
}

export const RevisarInformacion = () => {
  let {
    enviadas,
    columns,
    isModalOpen,
    handleCancel,
    handleOk,
    row,
    periodos,
    handleChangePeriodo,
    loading,
    valuePeriodo,
    getEnviadas,
    setEnviadas,
  } = useRevisarInformacion();

  const convertToJsonString = (value: any) => {
    try {
      if (typeof value === "string") {
        // Si el valor es de tipo string, eliminamos las comillas dobles alrededor del valor
        return value;
      } else {
        // Si no es de tipo string, usamos JSON.stringify como antes
        return JSON.stringify(value);
      }
    } catch (error) {
      return value;
    }
  };
  const exportToExcel = (tableData: any, fileName: any) => {
    const worksheet = XLSX.utils.json_to_sheet(
      tableData.map((row: any) => {
        return Object.keys(row).reduce((acc: any, key: any) => {
          acc[key] = convertToJsonString(row[key]);
          return acc;
        }, {});
      })
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Form.Item label='Escoja el periódo'>
        <Select options={periodos} defaultValue={valuePeriodo} onChange={handleChangePeriodo} value={valuePeriodo} />
      </Form.Item>
      <Table
        loading={loading}
        size='small'
        columns={columns}
        dataSource={enviadas}
        scroll={{
          x: 1000,
        }}
      />
      <Modal
        title='Revisar Información'
        open={isModalOpen}
        destroyOnClose={true}
        onOk={handleOk}
        onCancel={handleCancel}
        width='90%'
        // zIndex={1000}
        style={{
          top: 64,
          // position: "relative",
          // height: "auto",
        }}
        footer={null}
        transitionName=''
        // forceRender
      >
        <FormulariosPorPasos
          flag={1}
          row={row}
          handleOk={handleOk}
          getEnviadas={getEnviadas}
          valuePeriodo={valuePeriodo}
          enviadas={enviadas}
          setEnviadas={setEnviadas}
        />
      </Modal>
      <Button type='primary' onClick={() => exportToExcel(enviadas, "table-data")}>
        Exportar a Excel
      </Button>
    </div>
  );
};
