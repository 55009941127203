import { Result, ResultProps } from "antd"
import { Link, LinkProps, isRouteErrorResponse, useRouteError } from "react-router-dom"

type Props = {
  title?: ResultProps["title"]
  subTitle?: ResultProps["subTitle"]
  textReturn?: string
  status?: "403" | "404" | "500"
}

type ErrorData = {
  title?: ResultProps["title"]
  subTitle?: ResultProps["subTitle"]
  link?: LinkProps["to"]
  textLink?: LinkProps["children"]
}

export function ErrorPage({ title, subTitle, status = "500", textReturn = "Regresar a inicio" }: Props) {
  const error = useRouteError()
  console.log("pasa por error-page")

  if (!isRouteErrorResponse(error)) {
    return (
      <article style={{ display: "grid", height: "100vh", placeContent: "center" }}>
        <Result
          status={status}
          title={title}
          subTitle={subTitle}
          extra={
            <Link to="/" className="ant-btn ant-btn-primary">
              {textReturn}
            </Link>
          }
        />
      </article>
    )
  }

  const { data }: { data: ErrorData } = error

  return (
    <article style={{ display: "grid", height: "100vh", placeContent: "center" }}>
      <Result
        status={error.status.toString() as Props["status"]}
        title={error.status === 403 ? "Sesión expirada" : data?.title ?? "Ha ocurrido un error"}
        subTitle={error.status === 403 ? "Debe volver a iniciar sesión" : data?.subTitle ?? "Intente de nuevo mas tarde"}
        extra={
          <Link to={error.status === 403 ? "/login" : data?.link ?? "/login"} className="ant-btn ant-btn-primary">
            {data.textLink || error.status === 403 ? "Ir a iniciar sesión" : textReturn}
          </Link>
        }
      />
    </article>
  )
}
