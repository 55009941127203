import styles from "pages/contentlogin/Login.module.css"

import { MdPerson, MdLock, MdSend } from "react-icons/md"
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import { Form, Input, Button, Card } from "antd"

import { useLogin } from "hooks/otros/useLogin"
import { Logo } from "components/svg/Logo"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export function Login() {
  const { autenticarUsuario } = useLogin()

  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem("token")
    token && navigate("/")
  }, [navigate])

  // const { obtenerDatosUsuario } = useAuth()

  // const token = localStorage.getItem('token')

  // const { userData, setUserData } = useContext(UserContext)

  // if(token && userData) return <Navigate to={ '/' } />

  return (
    <div className={styles.Container}>
      <Card className={styles.card}>
        <Form onFinish={autenticarUsuario}>
          <div className={styles.containerLogo}>
            <Logo width="80%" />
          </div>
          <h4 className={styles.titulo}>Portal Administrativo</h4>
          <Form.Item
            name="nombre_usuario"
            rules={[{ required: true, message: "¡Por favor, ingrese su nombre de usuario!" }]}
          >
            <Input size="large" placeholder="Nombre de Usuario" prefix={<MdPerson className={styles.icon} />} />
          </Form.Item>
          <Form.Item name="clave" initialValue="">
            <Input.Password
              size="large"
              placeholder="Contraseña"
              prefix={<MdLock className={styles.icon} />}
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" size="large" htmlType="submit" icon={<MdSend className="iconbutton" />} block>
              LOGIN
            </Button>
          </Form.Item>
          {/*<Link to="/recuperar-clave">¿No recuerda su contraseña?</Link>*/}
        </Form>
      </Card>
    </div>
  )
}
