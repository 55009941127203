import { Card, Col, Divider, Row } from "antd";
import { useAdministrarVenta } from "hooks/indicadores/useAdministrarVenta";
import TableReport from "./TableReport";
import ComponentChart from "./ComponentChart";
import { ResponsiveContainer } from "recharts";

const AdministrarVenta = () => {
  const {
    columnTable,
    headTable,
    FnExportToExcel,
    graph,
    columnTableG,
    rowSelection,
    columnTableDay,
    graphX2,
    headTableDay,
    columnTableGDay,
    tableRef,
    width,
    FnExportToExcelDetail,
  } = useAdministrarVenta();
  return (
    <Card style={{ margin: width < 576 ? "0px" : "10px" }}>
      <Divider orientation='left'>Detalles de ventas general</Divider>
      <Row gutter={[0, 0]}>
        <Col xs={24} md={24} lg={12}>
          <TableReport
            headTable={headTable}
            columnTable={columnTable}
            rowSelection={rowSelection}
            exportToExcel={FnExportToExcel}
            isSelect={true}
            tableRef={tableRef}
          />
        </Col>
        <Col xs={24} md={24} lg={12}>
          <ResponsiveContainer>
            <ComponentChart
              columnTable={columnTableG}
              grafica={"no"}
              tamaño={330}
              angulo={-45}
              graph={graph}
              dataKey={"margen"}
              setShowModal={() => {}}
            />
          </ResponsiveContainer>
        </Col>
      </Row>

      {columnTableDay?.length > 0 && (
        <Row gutter={[10, 10]}>
          <Divider orientation='left'>Detalles de ventas de la semana</Divider>
          <Col xs={24} md={24} lg={12}>
            <TableReport
              headTable={headTableDay}
              columnTable={columnTableGDay}
              // rowSelection={rowSelection}
              exportToExcel={FnExportToExcelDetail}
              isSelect={true}
            />
          </Col>
          <Col xs={24} md={24} lg={12}>
            <ComponentChart
              columnTable={columnTableDay}
              grafica={"dia"}
              tamaño={380}
              angulo={-45}
              graph={graphX2}
              dataKey={"margen"}
              setShowModal={() => {}}
            />
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default AdministrarVenta;
