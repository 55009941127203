import { SweetAlertOptions } from "sweetalert2";
import { Estados } from "types";

export const url = process.env.REACT_APP_URL;
export const url_interna = process.env.REACT_APP_URL_INTERNA;
export const url_alku = process.env.REACT_APP_URL_ALKU;
// let b = process.env.URL_INTERNA;
//export const url = a;
//export const url_interna = b;
//console.log(a);
// export const llave = "yS1DKVL4xPi2n8HKB8p1T1EwK9lx4V0K";
export const llave = process.env.REACT_APP_LLAVE;

export const arrEstados: Estados[] = ["pendientes", "aprobados", "rechazados"];

export const optionsGET: RequestInit = {
  method: "GET",
  headers: {
    "api-token": "apitoken",
  },
};

export const optionsPOST = (body: string, token: HeadersInit): RequestInit => ({
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    ...token,
  },
  body,
});

export const headersURL: HeadersInit = {
  "Content-Type": "application/json",
  "access-token": localStorage.getItem("token") ?? "",
};

export const Format = (date: Date, format: string) => {
  const map = {
    dd: "" + date.getDate(),
    mm: date.getMonth() + 1 + "",
    yy: "" + date.getFullYear().toString().slice(-2),
    yyyy: "" + date.getFullYear(),
  };
  return format.replace(/dd|mm|yy|yyyy/gi, (matched) => map[matched as keyof typeof map]);
};

export const formasPago = {
  "01": "SIN UTILIZACION DEL SISTEMA FINANCIERO",
  "16": "TARJETA DE DÉBITO",
  "17": "DINERO ELECTRÓNICO",
  "18": "TARJETA PREPAGO",
  "19": "TARJETA DE CRÉDITO",
  "20": "OTROS CON UTILIZACION DEL SISTEMA FINANCIERO",
  "21": "ENDOSO DE TÝTULOS",
};

export const verificarucAlgoritmo = (value: string) => {
  try {
    let cedula = value.split("", 9).map(Number);
    if (parseInt(value.slice(2, 3)) < 6)
      if (comoNatural(cedula, parseInt(value.slice(9, 10)))) return true;
    if (parseInt(value.slice(2, 3)) === 9)
      if (comoJuridico(cedula, parseInt(value.slice(9, 10)))) return true;
    cedula = value.split("", 8).map(Number);
    if (parseInt(value.slice(2, 3)) === 6)
      if (comoPublico(cedula, parseInt(value.slice(8, 9)))) return true;
  } catch (e) {
    return false;
  }
  return false;
};

const comoNatural = (cedula: Array<number>, checker: number) => {
  for (let i = 0; i < 9; i += 2) {
    cedula[i] *= 2;
    if (cedula[i] > 9) cedula[i] -= 9;
  }
  const suma = cedula.reduce((a, b) => a + b, 0);
  const resto = suma % 10;
  return checker === (resto === 0 ? resto : 10 - resto);
};
const comoPublico = (cedula: Array<number>, checker: number) => {
  const mask = Array.from("32765432", Number);
  for (let i = 0; i < 8; i++) {
    cedula[i] *= mask[i];
  }
  const suma = cedula.reduce((a, b) => a + b, 0);
  const resto = suma % 10;
  return checker === (resto === 0 ? resto : 11 - resto);
};
const comoJuridico = (cedula: Array<number>, checker: number) => {
  const mask = Array.from("432765432", Number);

  for (let i = 0; i < 9; i++) {
    cedula[i] *= mask[i];
  }
  const suma = cedula.reduce((a, b) => a + b, 0);
  const resto = suma % 10;
  return checker === (resto === 0 ? resto : 11 - resto);
};

export const optionsSuccess: SweetAlertOptions = {
  title: 'Datos almacenados',
  icon: 'success',
  timer: 2000,
  showConfirmButton: false
}

export const optionsWarning: SweetAlertOptions = {
  title: '¡Advertencia!',
  icon: 'warning',
  timer: 2000,
  showConfirmButton: false
}

export const optionsError: SweetAlertOptions = {
  title: '¡Error!',
  icon: 'error',
  timer: 2000,
  showConfirmButton: false
}

export const optionsQuestion: SweetAlertOptions = {
  icon: 'question',
  showCancelButton: true,
  confirmButtonColor: 'var(--primary)',
  confirmButtonText: 'Si',
  cancelButtonText: 'No'
}