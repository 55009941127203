import { LayoutContext } from "context/LayoutContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import moment, { Moment } from "moment";
import "moment/locale/es";
import { useLoaderData } from "react-router-dom";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { Form, Tag } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource, TableRowSelection } from "antd/lib/table/interface";
import { url } from "config/constants";
import { optionsPost, swalError } from "config/methods";
import { useSearchTable } from "hooks/otros/useSearchTable";
import { useScreenSize } from "hooks/otros/useScreenSize";
import { FormInstance } from "antd/lib/form";
import { useHandleReportPdf } from "./useHandleReportPdf";
import { useExport } from "hooks/otros/useExport";

moment.locale("es");

export interface PropsBilleteate {
  key: string;
  billetéate: number;
  cumplimiento: number;
  farmacia: string;
  periodo?: string;
  nombres?: string;
  supervisor: string;
  ventas: number;
  color: string;
  bodega: string;
  mes: string;
  mesAnterior: number;
  c_productos: number;
  c_laboratorios: number;
}

export interface PropsBilleteateDetails {
  key: string;
  billetéate: number;
  total: number;
  dependiente: string;
  cumplimiento: number;
  color: string;
  data: string;
}

interface PropsRequest {
  msg: true;
  data: PropsBilleteate[];
}

interface PropsRequestDetail {
  msg: true;
  data: PropsBilleteateDetails[];
}

interface PropsFilter {
  text: string;
  value: string;
}
type ChartIds = "myChart" | "myChartUser" | "myChartUserDetails";

const breadcrumbs = [{ Label: "Cumplimiento billetéate" }];

export const useCumplimientoBilleteate = () => {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const [dataBilleteateGeneral, setDataBilleteateGeneral] = useState<PropsBilleteate[] | null>(null);
  const [dataBilleteate, setDataBilleteate] = useState<PropsBilleteate[] | null>(null);
  const [dataBilleteateUser, setDataBilleteateUser] = useState<PropsBilleteate[] | null>(null);

  const [dataBilleteateDetails, setDataBilleteateDetails] = useState<PropsBilleteateDetails[] | null>(null);
  const [dataBilleteateDetailsGraph, setDataBilleteateDetailsGraph] = useState<PropsBilleteateDetails[] | null>(null);
  const [filters, setFilters] = useState<PropsFilter[]>([]);
  const { getColumnSearchProps } = useSearchTable<PropsBilleteate>();
  const [headSelect, setHeadSelect] = useState<PropsBilleteate>();

  const [dataDetailsUser, setDataDetailsUser] = useState<PropsBilleteate[] | null>(null);
  const [dataDetailGraph, setDataDetailsGraph] = useState<PropsBilleteate[] | null>(null);

  const databilleteate = useLoaderData() as PropsRequest;
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [selectedRowGeneral, setSelectedRowGeneral] = useState<string[]>([]);
  const { width } = useScreenSize();
  const [form]: FormInstance[] = Form.useForm();
  const { handleDownloadPdf, downloadPDFBodegaGeneral, downloadPDFBodegaDependienteGeneral } = useHandleReportPdf(form);
  const { exportToExcel } = useExport();
  /**Para el filtro */
  const [dataFiltrada, setDataFiltrada] = useState<PropsBilleteate[]>([]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PropsBilleteate> | SorterResult<PropsBilleteate>[],
    extra: TableCurrentDataSource<PropsBilleteate>
  ) => {
    if (extra.action === "filter") {
      setDataFiltrada(extra.currentDataSource);
    }
  };

  const CustomXAxisTick: React.FC<any> = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor='end' fill='#666'>
          {payload.value} {payload.cumplimiento}%
        </text>
      </g>
    );
  };

  useBreadCrumbs(breadcrumbs);

  const actualizarFiltros = useCallback((data: PropsBilleteate[]) => {
    const uniqueFilters: any = {};

    const nuevosFiltros = data.reduce((accumulator: PropsFilter[], item: PropsBilleteate) => {
      const key = truncarTexto(item.supervisor);
      if (!uniqueFilters[key]) {
        uniqueFilters[key] = true;
        accumulator.push({
          text: key,
          value: key,
        });
      }
      return accumulator;
    }, []);

    setFilters(nuevosFiltros);
  }, []);

  useEffect(() => {
    if (!databilleteate) return;
    if (databilleteate.data.length === 0) return;
    setDataBilleteateGeneral(databilleteate.data);
  }, [databilleteate]);

  const añoActual = moment().format("YYYY");
  const fechaInicial = moment(añoActual, "YYYY");

  const dataInicial = {
    tipos: 0,
    year: fechaInicial,
    mes: moment().subtract(1, "d").format("MM"),
  };

  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };

  function truncarTexto(texto: string) {
    const segundoEspacio = texto.indexOf(" ", texto.indexOf(" ") + 1);
    if (segundoEspacio !== -1) {
      return texto.substring(0, segundoEspacio);
    } else {
      return texto;
    }
  }

  const tipos = [
    {
      value: 0,
      label: "Bodegas",
    },
    {
      value: 1,
      label: "Dependientes",
    },
  ];

  const handleChangeYear = (fecha: Moment | null) => {
    let periodo = fecha?.format("YYYY") + form.getFieldsValue().mes;
    handleRequestOptionChange(periodo);
  };

  const handleChangeType = () => {
    let periodo = form.getFieldsValue().year.format("YYYY") + form.getFieldsValue().mes;
    handleRequestOptionChange(periodo);
  };

  const resetState = () => {
    setSelectedRowGeneral([]);
    setSelectedRow([]);
    setDataBilleteate(null);
    setDataBilleteateGeneral(null);
    setDataBilleteateUser(null);
    setDataBilleteateDetails(null);
    setDataBilleteateDetailsGraph(null);
    setDataDetailsUser(null);
    setDataDetailsGraph(null);
  };

  const handleRequestOptionChange = useCallback(
    async (periodo: string) => {
      resetState();
      try {
        const fechaActual = moment();
        const fechaCompararMoment = moment(periodo, "YYYYMM");
        if (fechaCompararMoment.isAfter(fechaActual)) {
          swalError({ text: "Periodo fuera de rango" });
        } else {
          const respuesta: PropsRequest = await fetch(
            url + "apifsg-pr/reportes/bodega-billeteate-general-options/",
            optionsPost({ periodo, tipos: form.getFieldsValue().tipos })
          ).then((data) => data.json());
          if (respuesta.data) {
            setDataBilleteateGeneral(respuesta.data);
            return;
          }
          swalError({ text: "Ocurrio un error" });
        }
      } catch (error) {
        swalError({ text: "Ocurrio un error" });
      }
    },
    [form]
  );

  const handleRowSelect = async (record: PropsBilleteate) => {
    setHeadSelect(record);
    handleRowSelectRequest(record);
    if (selectedRow && selectedRow[0] === record.key) {
      setSelectedRow([]);
    } else {
      let newSelection = [];
      newSelection.push(record.key);
      setSelectedRow(newSelection);
    }
  };

  const handleRowSelectGeneral = async (record: PropsBilleteate) => {
    setHeadSelect(record);
    handleRowSelectRequestGeneral(record);
    if (selectedRowGeneral && selectedRowGeneral[0] === record.key) {
      setSelectedRowGeneral([]);
    } else {
      let newSelection = [];
      newSelection.push(record.key);
      setSelectedRowGeneral(newSelection);
    }
  };

  const handleRowSelectRequestGeneral = async (record: PropsBilleteate) => {
    setSelectedRow([]);
    setDataBilleteateDetailsGraph(null);
    setDataBilleteateDetails(null);
    setDataBilleteate(null);
    setDataFiltrada([]);
    actualizarFiltros([]);
    setDataDetailsUser(null);
    setDataDetailsGraph(null);

    try {
      const respuesta: PropsRequest = await fetch(
        url + "apifsg-pr/reportes/bodega-billeteate/",
        optionsPost({ periodo: record.key, tipos: form.getFieldsValue().tipos })
      ).then((data) => data.json());
      if (respuesta.msg && form.getFieldsValue().tipos === 0) {
        setDataBilleteate(respuesta.data);
        setDataFiltrada(respuesta.data);
        actualizarFiltros(respuesta.data);
      } else {
        setDataBilleteateUser(respuesta.data);
      }
    } catch (error) {
      swalError({ text: "Ocurrio un error" });
    }
  };

  const handleRowSelectRequest = async (record: PropsBilleteate) => {
    try {
      const respuesta: PropsRequestDetail = await fetch(
        url + "apifsg-pr/reportes/bodega-billeteate-details/",
        optionsPost({ cod: record.key, periodo: record.periodo, tipos: form.getFieldsValue().tipos })
      ).then((data) => data.json());

      if (respuesta.msg) {
        if (form.getFieldsValue().tipos === 0) {
          let dataGraph = respuesta.data.map((el) => ({ ...el, dependiente: truncarTexto(el.dependiente) }));
          setDataBilleteateDetailsGraph(dataGraph);
          setDataBilleteateDetails(respuesta.data);
        } else if (form.getFieldsValue().tipos === 1) {
          let tableGraph = JSON.parse(respuesta.data[0].data);
          setDataDetailsUser(tableGraph[0].detalles_bodega);
          setDataDetailsGraph(tableGraph[0].detalles_historico);
        }
      }
    } catch (error) {
      swalError({ text: "Ocurrio un error" });
    }
  };

  const rowSelection: TableRowSelection<PropsBilleteate> = {
    type: "radio",
    onSelect: handleRowSelect,
    selectedRowKeys: selectedRow[0] ? [selectedRow[0]] : [],
  };

  /**CAMbio tabla general */
  const rowSelectionGeneral: TableRowSelection<PropsBilleteate> = {
    type: "radio",
    onSelect: handleRowSelectGeneral,
    selectedRowKeys: selectedRowGeneral[0] ? [selectedRowGeneral[0]] : [],
  };

  const headTable: ColumnsType<PropsBilleteate> = [
    {
      title: "Farmacia",
      dataIndex: "farmacia",
      key: "farmacia",
      ...getColumnSearchProps("farmacia"),
    },
    {
      title: "Supervisor",
      dataIndex: "supervisor",
      key: "supervisor",
      filters: filters,
      onFilter: (value: any, record) => record.supervisor?.indexOf(value) === 0,
    },
    {
      title: "Ventas",
      dataIndex: "ventas",
      key: "ventas",
      width: "95px",
      align: "right",
      render: (_, record) => <>{formateaCantidad(record.ventas)}</>,
      sorter: (a, b) => a.ventas - b.ventas,
    },
    {
      title: "Billetéate",
      dataIndex: "billetéate",
      key: "billetéate",
      width: "110px",
      align: "right",
      render: (_, record) => <>{formateaCantidad(record.billetéate)}</>,
      sorter: (a, b) => a.billetéate - b.billetéate,
    },
    {
      title: "Cumplimiento%",
      dataIndex: "cumplimiento",
      key: "cumplimiento",
      width: "135px",
      align: "right",
      sorter: (a, b) => a.cumplimiento - b.cumplimiento,
      render: (_, record) => (
        <Tag color={record.color} key={record.color} className={record.color}>
          {formateaCantidad(record.cumplimiento)}
        </Tag>
      ),
    },
  ];

  const headTableGeneral: ColumnsType<PropsBilleteate> = [
    {
      title: "Mes",
      dataIndex: "mes",
      key: "mes",
      width: "58px",
    },
    {
      title: "Ventas",
      dataIndex: "ventas",
      key: "ventas",
      width: "70px",
      align: "right",
      render: (_, record) => <>{formateaCantidad(record.ventas)}</>,
      sorter: (a, b) => a.ventas - b.ventas,
    },
    {
      title: "Billetéate",
      dataIndex: "billetéate",
      key: "billetéate",
      width: "65px",
      align: "right",
      render: (_, record) => <>{formateaCantidad(record.billetéate)}</>,
      sorter: (a, b) => a.billetéate - b.billetéate,
    },
    {
      title: "Cumplimiento%",
      dataIndex: "cumplimiento",
      key: "cumplimiento",
      width: "85px",
      align: "right",
      sorter: (a, b) => a.cumplimiento - b.cumplimiento,
      render: (_, record) => (
        <Tag color={record.color} key={record.color} className={record.color}>
          {formateaCantidad(record.cumplimiento)}
        </Tag>
      ),
    },
    {
      title: "%CrecMesAnt",
      dataIndex: "mesAnterior",
      key: "mesAnterior",
      width: "80px",
      align: "right",
      sorter: (a, b) => a.mesAnterior - b.mesAnterior,
      render: (_, record) => (
        <Tag
          color={record.mesAnterior > 0 ? "green" : "red"}
          key={record.mesAnterior > 0 ? "green" : "red"}
          className={record.mesAnterior > 0 ? "green" : "red"}
        >
          {formateaCantidad(record.mesAnterior)}
        </Tag>
      ),
    },
    {
      title: "P",
      dataIndex: "c_productos",
      key: "c_productos",
      width: "30px",
      align: "right",
      sorter: (a, b) => a.c_productos - b.c_productos,
      render: (_, record) => <>{record.c_productos}</>,
    },
    {
      title: "L",
      dataIndex: "c_laboratorios",
      key: "c_laboratorios",
      width: "30px",
      align: "right",
      sorter: (a, b) => a.c_laboratorios - b.c_laboratorios,
      render: (_, record) => <>{record.c_laboratorios}</>,
    },
  ];

  const headTableDependiente: ColumnsType<PropsBilleteate> = [
    {
      title: "Dependiente",
      dataIndex: "nombres",
      key: "nombres",
      width: width < 578 ? "99px" : "1fr",
      ...getColumnSearchProps("nombres"),
    },
    {
      title: "Ventas",
      dataIndex: "ventas",
      key: "ventas",
      width: "95px",
      align: "right",
      render: (_, record) => <>{formateaCantidad(record.ventas)}</>,
      sorter: (a, b) => a.ventas - b.ventas,
    },
    {
      title: "Billetéate",
      dataIndex: "billetéate",
      key: "billetéate",
      width: "110px",
      align: "right",
      render: (_, record) => <>{formateaCantidad(record.billetéate)}</>,
      sorter: (a, b) => a.billetéate - b.billetéate,
    },
    {
      title: "Cumplimiento%",
      dataIndex: "cumplimiento",
      key: "cumplimiento",
      width: "135px",
      align: "right",
      sorter: (a, b) => a.cumplimiento - b.cumplimiento,
      render: (_, record) => (
        <Tag color={record.color} key={record.color} className={record.color}>
          {formateaCantidad(record.cumplimiento)}
        </Tag>
      ),
    },
  ];

  const headTableBodega: ColumnsType<PropsBilleteate> = [
    {
      title: "Farmacia",
      dataIndex: "bodega",
      key: "bodega",
      width: width < 578 ? "99px" : "1fr",
      ...getColumnSearchProps("bodega"),
    },
    {
      title: "Ventas",
      dataIndex: "ventas",
      key: "ventas",
      width: "95px",
      align: "right",
      render: (_, record) => <>{formateaCantidad(record.ventas)}</>,
      sorter: (a, b) => a.ventas - b.ventas,
    },
    {
      title: "Billetéate",
      dataIndex: "billetéate",
      key: "billetéate",
      width: "110px",
      align: "right",
      render: (_, record) => <>{formateaCantidad(record.billetéate)}</>,
      sorter: (a, b) => a.billetéate - b.billetéate,
    },
    {
      title: "Cumplimiento%",
      dataIndex: "cumplimiento",
      key: "cumplimiento",
      width: "135px",
      align: "right",
      sorter: (a, b) => a.cumplimiento - b.cumplimiento,
      render: (_, record) => (
        <Tag color={record.color} key={record.color} className={record.color}>
          {formateaCantidad(record.cumplimiento)}
        </Tag>
      ),
    },
  ];

  const headTableBodegHistory: ColumnsType<PropsBilleteate> = [
    {
      title: "Mes",
      dataIndex: "mes",
      key: "mes",
      width: width < 578 ? "99px" : "1fr",
      ...getColumnSearchProps("mes"),
    },
    {
      title: "Ventas",
      dataIndex: "ventas",
      key: "ventas",
      width: "95px",
      align: "right",
      render: (_, record) => <>{formateaCantidad(record.ventas)}</>,
      sorter: (a, b) => a.ventas - b.ventas,
    },
    {
      title: "Billetéate",
      dataIndex: "billetéate",
      key: "billetéate",
      width: "110px",
      align: "right",
      render: (_, record) => <>{formateaCantidad(record.billetéate)}</>,
      sorter: (a, b) => a.billetéate - b.billetéate,
    },
    {
      title: "Cumplimiento%",
      dataIndex: "cumplimiento",
      key: "cumplimiento",
      width: "135px",
      align: "right",
      sorter: (a, b) => a.cumplimiento - b.cumplimiento,
      render: (_, record) => (
        <Tag color={record.color} key={record.color} className={record.color}>
          {formateaCantidad(record.cumplimiento)}
        </Tag>
      ),
    },
  ];

  const headTableDetails: ColumnsType<PropsBilleteateDetails> = [
    {
      title: "Dependiente",
      dataIndex: "dependiente",
      key: "dependiente",
      width: "155px",
    },
    {
      title: "Ventas",
      dataIndex: "total",
      key: "total",
      width: "85px",
      align: "right",
      render: (_, record) => <>{formateaCantidad(record.total)}</>,
    },
    {
      title: "Billetéate",
      dataIndex: "billetéate",
      key: "billetéate",
      width: "95px",
      align: "right",
      render: (_, record) => <>{formateaCantidad(record.billetéate)}</>,
    },
    {
      title: "Cumplimiento%",
      dataIndex: "cumplimiento",
      key: "cumplimiento",
      width: "135px",
      align: "right",
      render: (_, record) => (
        <Tag color={record.color} key={record.color} className={record.color}>
          {formateaCantidad(record.cumplimiento)}
        </Tag>
      ),
    },
  ];

  function disabledDate(current: moment.Moment) {
    if (current.year() < 2022) {
      return true;
    }
    return current > moment();
  }
  /** */
  const fnHandleDownloadPdf = (idChart: ChartIds = "myChart") => {
    headSelect &&
      handleDownloadPdf(
        idChart,
        headSelect,
        dataBilleteateDetails,
        dataBilleteateUser,
        dataDetailGraph,
        dataDetailsUser,
        dataFiltrada
      );
  };

  const fnDownloadPDFBodegaGeneral = () => {
    downloadPDFBodegaDependienteGeneral(
      "myChart2",
      "CUMPLIMIENTO BILLETÉATE",
      "REPORTE GENERAL",
      ["Mes", "Ventas", "Billetéate", "%Cumplimiento", "%CrecMesAnt"],
      ["key", "color"],
      ["ventas", "billetéate", "cumplimiento", "mesAnterior"],
      dataBilleteateGeneral
    );
  };

  const fnDownloadPDFBodega = () => {
    downloadPDFBodegaGeneral(dataFiltrada);
  };

  const handleClickDownload = () => {
    const dataExport = dataFiltrada?.map(({ farmacia, supervisor, ventas, billetéate, cumplimiento }: PropsBilleteate) => ({
      Farmacia: farmacia,
      Supervidor: supervisor,
      Ventas: ventas,
      Billeteate: billetéate,
      "Cumplimiento%": cumplimiento,
    }));
    dataExport && exportToExcel(dataExport, ` Billeteate por bodegas ${dataFiltrada?.[0].periodo}`);
  };

  useEffect(() => {
    setOpenKeys(["Indicadores", "Indicadores"]);
    setSelectedKeys(["Indicadores/Cumplimiento billetéate"]);
  }, [setOpenKeys, setSelectedKeys]);

  return {
    handleClickDownload,
    rowSelectionGeneral,
    headTableGeneral,
    dataBilleteateGeneral,
    fnHandleDownloadPdf,
    handleChangeType,
    form,
    tipos,
    dataInicial,
    disabledDate,
    dataBilleteate,
    headTable,
    rowSelection,
    handleChangeYear,
    dataBilleteateDetails,
    headTableDetails,
    dataBilleteateDetailsGraph,
    dataBilleteateUser,
    headTableDependiente,
    width,
    dataDetailsUser,
    dataDetailGraph,
    headTableBodega,
    headTableBodegHistory,
    headSelect,
    handleTableChange,
    fnDownloadPDFBodegaGeneral,
    fnDownloadPDFBodega,
    CustomXAxisTick,
  };
};
