import { Form, Upload, Row, Col, Checkbox, Divider, Card } from "antd";
import { ButtonUpload } from "../../otros/ButtonUpload";
import { ButtonUploadImg } from "../../otros/ButtonUploadImg";
import Swal from "sweetalert2";
import { DownloadOutlined } from "@ant-design/icons";

import { useArchivos } from "hooks/rrhh/actualizacion/useArchivos";
import { useContext } from "react";
import { UserContext } from "context/UserContext";

interface props {
  validaConyugue: boolean | undefined;
  setFileSelected: React.Dispatch<React.SetStateAction<boolean>>;
  // hidden: boolean;
  // setHidden: React.Dispatch<React.SetStateAction<boolean>>;
  // hidden2: boolean;
  // setHidden2: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Archivos = ({ validaConyugue, setFileSelected }: props) => {
  const {
    hidden,
    hidden2,
    hidden3,
    isReglamentoInternoChecked,
    isReglamentoHigieneChecked,
    handleReglamentoInternoClick,
    handleReglamentoHigieneClick,
    normFile,
    handleCheckboxChange,
    handleCheckboxChange2,
    handleCheckboxChange3,
  } = useArchivos();
  const { userData } = useContext(UserContext);
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Row wrap={true}>
          <Col xs={24} md={24} lg={24}>
            <Divider orientation='center'>DOCUMENTOS PERSONALES</Divider>
            <Row wrap={true} gutter={[10, 0]}>
              <Col xs={24} lg={8}>
                <Form.Item
                  getValueFromEvent={normFile}
                  name={`foto_carnet`}
                  valuePropName={`foto_carnet`}
                  label='Foto Digital(Tamaño carnet)'
                  rules={[{ required: true, message: "Foto es obligatoria" }]}
                >
                  <Upload
                    accept='.png,.jpg,.jpeg,.svg,.tiff,.psd,.webp'
                    maxCount={1}
                    beforeUpload={(file: any, fileList) => {
                      return new Promise((resolve, reject) => {
                        const isLt5M = file.size / 1024 / 1024 <= 4;
                        if (!isLt5M) {
                          Swal.fire("Error", "El archivo excede el tamaño permitido(4MB)", "warning");
                          resolve(true);
                          return;
                        }
                        if (
                          !file.name.toLowerCase().endsWith(".png") &&
                          !file.name.toLowerCase().endsWith(".jpg") &&
                          !file.name.toLowerCase().endsWith(".jpeg") &&
                          !file.name.toLowerCase().endsWith(".webp")
                        ) {
                          Swal.fire("Error", "Formato invalido de la imagen.Solo admite png, jpg, jpeg, webp.", "warning");
                          fileList.splice(-1, 1);
                          resolve(true);
                          return;
                        }

                        resolve(false);
                      });
                    }}
                    name={`foto_carnet`}
                  >
                    <ButtonUploadImg />
                  </Upload>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  getValueFromEvent={normFile}
                  name={`cedula_votacion`}
                  valuePropName={`cedula_votacion`}
                  label='Cédula y Certíficado de votación (Un solo archivo ambos lados)'
                  rules={[{ required: true, message: "Elija un documento" }]}
                >
                  <Upload
                    accept='.pdf'
                    maxCount={1}
                    beforeUpload={(file, fileList) => {
                      return new Promise((resolve, reject) => {
                        const isLt5M = file.size / 1024 / 1024 <= 4;
                        if (!isLt5M) {
                          Swal.fire("Error", "El archivo excede el tamaño permitido(4MB)", "warning");

                          fileList.splice(-1, 1);

                          resolve(true);
                          return;
                        }
                        if (!file.name.toLowerCase().endsWith(".pdf")) {
                          Swal.fire("Error", "El tipo de archivo no es pdf.", "warning");
                          fileList.splice(-1, 1);
                          resolve(true);
                          return;
                        }
                        resolve(false);
                      });
                    }}
                    name={`cedula_votacion`}
                  >
                    <ButtonUpload />
                  </Upload>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Row wrap={true}>
                  <Col xs={16} xl={18}>
                    <Form.Item
                      getValueFromEvent={normFile}
                      name={`licencia`}
                      valuePropName={`licencia`}
                      label='Licencia de conducir'
                    >
                      <Upload
                        disabled={hidden2}
                        accept='.pdf'
                        maxCount={1}
                        //beforeUpload={() => false}
                        beforeUpload={(file, fileList) => {
                          return new Promise((resolve, reject) => {
                            const isLt5M = file.size / 1024 / 1024 <= 4;
                            if (!isLt5M) {
                              Swal.fire("Error", "El archivo excede el tamaño permitido(4MB)", "warning");
                              resolve(true);
                              return;
                            }
                            if (!file.name.toLowerCase().endsWith(".pdf")) {
                              Swal.fire("Error", "El tipo de archivo no es pdf.", "warning");
                              fileList.splice(-1, 1);
                              resolve(true);
                              return;
                            }
                            resolve(false);
                          });
                        }}
                        name={`licencia`}
                      >
                        <ButtonUpload />
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col xs={8} xl={6}>
                    <Form.Item name='no_licencia' valuePropName='checked'>
                      <Checkbox onChange={handleCheckboxChange2}>No aplica</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider orientation='center'>DOCUMENTOS CONYUGUE E HIJOS</Divider>
            <Row wrap={true} gutter={[10, 0]}>
              <Col xs={24} lg={24}>
                <Form.Item
                  getValueFromEvent={normFile}
                  name={`cedulas_hijos`}
                  valuePropName={`cedulas_hijos`}
                  label='Cédula o partida de nacimiento de los hijos menores de 18 años (Un solo archivo)'
                >
                  <Upload
                    onChange={(info) => {
                      if (info.fileList.length > 0) {
                        setFileSelected(true);
                      } else {
                        setFileSelected(false);
                      }
                    }}
                    accept='.pdf'
                    maxCount={1}
                    //beforeUpload={() => false}
                    beforeUpload={(file, fileList) => {
                      return new Promise((resolve, reject) => {
                        const isLt5M = file.size / 1024 / 1024 <= 4;
                        if (!isLt5M) {
                          Swal.fire("Error", "El archivo excede el tamaño permitido(4MB)", "warning");
                          resolve(true);
                          return;
                        }
                        if (!file.name.toLowerCase().endsWith(".pdf")) {
                          Swal.fire("Error", "El tipo de archivo no es pdf.", "warning");
                          fileList.splice(-1, 1);
                          resolve(true);
                          return;
                        }
                        resolve(false);
                      });
                    }}
                    name={`cedulas_hijos`}
                  >
                    <ButtonUpload />
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Row wrap={true}>
                  <Col xs={validaConyugue ? 24 : 18} lg={19}>
                    <Form.Item
                      getValueFromEvent={normFile}
                      name={`cedula_conyugue`}
                      valuePropName={`cedula_conyugue`}
                      label='Cédula pareja/cónyugue o certificado de unión de hecho (Un solo archivo)'
                      rules={[{ required: validaConyugue }]}
                    >
                      <Upload
                        accept='.pdf'
                        maxCount={1}
                        disabled={hidden3}
                        //beforeUpload={() => false}
                        beforeUpload={(file, fileList) => {
                          return new Promise((resolve, reject) => {
                            const isLt5M = file.size / 1024 / 1024 <= 4;
                            if (!isLt5M) {
                              Swal.fire("Error", "El archivo excede el tamaño permitido(4MB)", "warning");
                              resolve(true);
                              return;
                            }
                            if (!file.name.toLowerCase().endsWith(".pdf")) {
                              Swal.fire("Error", "El tipo de archivo no es pdf.", "warning");
                              fileList.splice(-1, 1);
                              resolve(true);
                              return;
                            }
                            resolve(false);
                          });
                        }}
                        name={`cedula_conyugue`}
                      >
                        <ButtonUpload />
                      </Upload>
                    </Form.Item>
                  </Col>
                  {!validaConyugue && (
                    <Col xs={6} lg={5}>
                      <Form.Item name='no_pareja' valuePropName='checked'>
                        <Checkbox onChange={handleCheckboxChange3}>No aplica</Checkbox>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col xs={24} lg={12}>
                <Row wrap={true}>
                  <Col xs={18} xl={19}>
                    <Form.Item
                      getValueFromEvent={normFile}
                      name={`hijos_discapacidad`}
                      valuePropName={`hijos_discapacidad`}
                      label='En caso de hijos con discapacidad adjuntar certificado (Un solo archivo)'
                      style={{ width: "98%" }}
                    >
                      <Upload
                        accept='.pdf'
                        maxCount={1}
                        disabled={hidden}
                        beforeUpload={(file, fileList) => {
                          return new Promise((resolve, reject) => {
                            const isLt5M = file.size / 1024 / 1024 <= 4;
                            if (!isLt5M) {
                              Swal.fire("Error", "El archivo excede el tamaño permitido(4MB)", "warning");
                              resolve(true);
                              return;
                            }
                            if (!file.name.toLowerCase().endsWith(".pdf")) {
                              Swal.fire("Error", "El tipo de archivo no es pdf.", "warning");
                              fileList.splice(-1, 1);
                              resolve(true);
                              return;
                            }
                            resolve(false);
                          });
                        }}
                        name={`hijos_discapacidad`}
                      >
                        <ButtonUpload />
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col xs={6} xl={5}>
                    <Form.Item name='no_hijos_disc' valuePropName='checked'>
                      <Checkbox onChange={handleCheckboxChange}>No aplica</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider orientation='center'>REGLAMENTOS Y ACUERDOS</Divider>
            <Card style={{ borderRadius: "8px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", padding: "5px" }}>
              <Row gutter={[10, 10]}>
                <Col xs={24} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <Form.Item
                      name='rinterno'
                      valuePropName='checked'
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(new Error("Debe marcar que recibio el reglamento para continuar")),
                        },
                      ]}
                      style={{ marginBottom: 0, width: "100%" }}
                    >
                      <Checkbox
                        checked={isReglamentoInternoChecked}
                        onClick={handleReglamentoInternoClick}
                        className='download-checkbox'
                        style={{ width: "100%", height: "auto", padding: "5px" }}
                      >
                        <span>
                          Yo, <strong>{userData?.nombre}</strong>, he recibido el Reglamento Interno,por parte de la Sociedad
                          Civil de hecho denominada Grupo Uscocovich.
                        </span>
                        <DownloadOutlined style={{ position: "absolute", right: "5px", top: "9px" }} />
                      </Checkbox>
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <Form.Item
                      name='rhigiene'
                      valuePropName='checked'
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(new Error("Debe marcar que recibio el reglamento para continuar")),
                        },
                      ]}
                      style={{ marginBottom: 0, width: "100%" }}
                    >
                      <Checkbox
                        onClick={handleReglamentoHigieneClick}
                        checked={isReglamentoHigieneChecked}
                        className='download-checkbox'
                        style={{ width: "100%", height: "auto", padding: "5px" }}
                      >
                        <span>
                          Yo, <strong>{userData?.nombre}</strong>, he recibido el Reglamento de Higiene y Salud, por parte de
                          la Sociedad Civil de hecho denominada Grupo Uscocovich.
                        </span>
                        <DownloadOutlined style={{ position: "absolute", right: "5px", top: "9px" }} />
                      </Checkbox>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Card>

            <Row wrap={true} gutter={[10, 0]}>
              <Card style={{ margin: "10px", borderRadius: "8px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}>
                <Form.Item
                  name='es_aceptacion_md'
                  valuePropName='checked'
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("DEBES ACEPTAR EL CONSENTIMIENTO DE TRATAMIENTO DE MEDIOS ELECTRÓNICOS")
                            ),
                    },
                  ]}
                >
                  <Checkbox style={{ fontWeight: "bold", marginBottom: "10px" }}>ACEPTACIÓN DE MEDIOS ELECTRÓNICOS</Checkbox>
                </Form.Item>
                <div
                  style={{
                    textAlign: "justify",
                    marginTop: "10px",
                    padding: "10px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px",
                  }}
                >
                  <p>
                    Yo, <strong>{userData?.nombre}</strong>, he sido informado y autorizo de manera libre, expresa, informada
                    e inequívoca de conformidad con la Ley de comercio electrónico, firmas y mensajes de datos , que los
                    datos personales que brindo, son accedidos y tratados por las áreas de TALENTO Y RECURSOS HUMANOS de
                    Sociedad Civil de hecho denominada Grupo Uscocovich, los cuales utilizarán mis datos personales y
                    sensibles que sean necesarios con los objetivos mantener actualizada la base de datos, además acepto
                    recibir notificaciones de cualquier índole por medios electrónicos que la compañía designe para la
                    comunicación institucional tal como lo permite el Art.2, Art3, Art52 y Art 56 , De expresar mi negativa a
                    brindar mis datos y aceptación personales o de no autorizar el acceso a los datos personales y la
                    aceptación de medios electrónicos estrictamente pertinentes y necesarios, Sociedad Civil de hecho
                    denominada Grupo Uscocovich, no podrá cumplir con los fines expuestos.
                  </p>
                </div>
                <Divider />
              </Card>
            </Row>

            <Row wrap={true} gutter={[10, 0]}>
              <Card style={{ margin: "10px", borderRadius: "8px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}>
                <Form.Item
                  name='es_aceptacion_dp'
                  valuePropName='checked'
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(new Error("DEBES ACEPTAR EL CONSENTIMIENTO DE TRATAMIENTO DE DATOS PERSONALES")),
                    },
                  ]}
                >
                  <Checkbox style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    ACEPTACIÓN DE TRATAMIENTO DE DATOS PERSONALES
                  </Checkbox>
                </Form.Item>
                <div
                  style={{
                    textAlign: "justify",
                    marginTop: "10px",
                    padding: "10px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px",
                  }}
                >
                  <p>
                    Yo, <strong>{userData?.nombre}</strong>, he sido informado y autorizo de manera libre, expresa, informada
                    e inequívoca de conformidad con la Ley Orgánica de Protección de Datos Personales, que los datos
                    personales que brindo, son accedidos y tratados por las áreas de Recursos Humanos de Sociedad Civil de
                    hecho denominada Grupo Uscocovich, los cuales utilizarán mis datos personales y sensibles que sean
                    necesarios con los objetivos de actualización de datos personales. De expresar mi negativa a brindar mis
                    datos personales o de no autorizar el acceso a los datos personales y sensible estrictamente pertinentes
                    y necesarios, Sociedad Civil de hecho denominada Grupo Uscocovich, no podrá cumplir con los fines
                    expuestos.
                  </p>
                  <p>
                    He sido informado que mis datos personales serán incluidos en una base de datos denominada base de datos
                    general, registrada bajo la titularidad de Sociedad Civil de hecho denominada Grupo Uscocovich, mientras
                    no solicite su cancelación, y que se realiza transferencia de datos personales.
                  </p>
                  <p>
                    Adicionalmente, autorizo a Sociedad Civil de hecho denominada Grupo Uscocovich, a realizar las siguientes
                    finalidades distintas a las descritas en los párrafos precedentes:
                  </p>
                  <ul style={{ marginTop: "5px", paddingLeft: "20px" }}>
                    <li>Realizar encuestas de satisfacción para mejorar la calidad de los servicios brindados.</li>
                    <li>Envío de comunicaciones referidas a nuevos servicios y/o promociones.</li>
                  </ul>
                </div>
                <Divider />
              </Card>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};
