import { SVGProps } from "react"

export const LeaveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M510.371 226.513c-1.088-2.603-2.645-4.971-4.629-6.955l-63.979-63.979c-8.341-8.32-21.824-8.32-30.165 0-8.341 8.341-8.341 21.845 0 30.165l27.584 27.584H320.013c-11.797 0-21.333 9.557-21.333 21.333s9.536 21.333 21.333 21.333h119.168l-27.584 27.584c-8.341 8.341-8.341 21.845 0 30.165 4.16 4.181 9.621 6.251 15.083 6.251s10.923-2.069 15.083-6.251l63.979-63.979c1.984-1.963 3.541-4.331 4.629-6.955a21.356 21.356 0 0 0 0-16.296z" />
    <path d="M362.68 298.667c-11.797 0-21.333 9.557-21.333 21.333v106.667h-85.333V85.333c0-9.408-6.187-17.728-15.211-20.437l-74.091-22.229h174.635v106.667c0 11.776 9.536 21.333 21.333 21.333s21.333-9.557 21.333-21.333v-128C384.013 9.557 374.477 0 362.68 0H21.347c-.768 0-1.451.32-2.197.405-1.003.107-1.92.277-2.88.512a21.476 21.476 0 0 0-6.165 2.645c-.469.299-1.045.32-1.493.661-.172.129-.236.364-.407.492-2.325 1.834-4.266 4.074-5.674 6.741-.299.576-.363 1.195-.597 1.792-.683 1.621-1.429 3.2-1.685 4.992-.107.64.085 1.237.064 1.856-.021.427-.299.811-.299 1.237V448c0 10.176 7.189 18.923 17.152 20.907l213.333 42.667c1.387.299 2.795.427 4.181.427a21.31 21.31 0 0 0 13.525-4.843 21.354 21.354 0 0 0 7.808-16.491v-21.333H362.68c11.797 0 21.333-9.557 21.333-21.333V320c0-11.776-9.536-21.333-21.333-21.333z" />
  </svg>
)