import { Card, Col, Row, Table } from "antd";
import { useVisorMartillo } from "hooks/productos/useVisorMartillo";
import { useVT } from "virtualizedtableforantd4";
import "./VisorMartillo.css";
const VisorMartillo = () => {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);

  const { columns, filteredData, modifiedRowSelection, onRowClick, columnsBodega, filteredDataBodega, tableContainerRef } =
    useVisorMartillo();

  return (
    <>
      <Card style={{ margin: "5px" }}>
        <Row gutter={16}>
          <Col md={16}>
            <div ref={tableContainerRef} style={{ maxHeight: "435px" }}>
              {
                <Table
                  ref={tableContainerRef}
                  components={vt}
                  pagination={false}
                  columns={columns}
                  scroll={{ x: 900, y: 450 }}
                  size="small"
                  dataSource={filteredData ? filteredData : []}
                  bordered
                  rowSelection={{
                    type: "radio",
                    ...modifiedRowSelection,
                  }}
                  onRow={(record) => ({
                    onClick: () => onRowClick(record),
                  })}
                />
              }
            </div>
          </Col>
          <Col md={8}>
            <Table /*style={{border: "2px solid #d9d9d9"}}*/
              pagination={false}
              columns={columnsBodega}
              scroll={{ x: 900, y: 450 }}
              size="small"
              dataSource={filteredDataBodega ? filteredDataBodega : []}
              bordered
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default VisorMartillo;
