import { useEffect, useContext, useCallback, useState } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import { url, url_alku } from "../../config/constants";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useLocalStorage } from "../otros/useLocalStorage";
import { SelectProps, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import Swal from "sweetalert2";
import moment, { Moment } from "moment";
import { PropsTable } from "components/convenios/ViewTablaIn";
import { swalError } from "config/methods";
import { useListCuota } from "./useListCuota";
import { useListCuotaOut } from "./useListCuotaOut";
import { RcFile } from "antd/lib/upload";

export interface Convenio {
  key: number | undefined;
  descripcion: string;
  distribucion: Distribucion[];
  formas_pago: number;
  periodo: string | Moment;
  file: ConvenioFile;
  cod_tipo_convenio: number;
  cod_seleccion_tipo_convenio: number;
  llave: string;
  cod_opciones_por_productos_in: number;
  cod_opciones_por_productos_out: number;
  checkSellIn: boolean;
  checkSellOut: boolean;
}

export interface Distribucion {
  tipo: number;
  valor: number;
  rebate: number;
  es_divide: boolean;
}

export interface ConvenioFile {
  file: any;
  fileList: any;
}

export interface OriginFileObjClass {
  uid: string;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

export interface DataConvenios {
  value: number;
  label: string;
}

export interface PropsSeleccion {
  descripcion: string;
  key: number;
  laboratorio: string;
  porcentaje: number | null;
}

const breadCrumbs = [
  {
    Label: "Nuevo",
  },
];

async function fetchPdfAsBlob(url: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
}

export function useConvenios(flag?: number, modalRecord?: PropsTable) {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const [convenios, setConvenios] = useState<DataConvenios[]>([]);
  const [proveedores, setProveedores] = useState<DataConvenios[]>([]);
  const [tipoNegociacion, setTipoNegociacion] = useState<DataConvenios[]>([]);
  const [afterConvenios, setafterConvenios] = useState<DataConvenios[]>([]);
  const [opcionesProductos, setOpcionesProductos] = useState<DataConvenios[]>([]);
  const [opcionesProductosEdit, setOpcionesProductosEdit] = useState<DataConvenios[]>([]);
  const [tipoPagos, setTipoPagos] = useState<DataConvenios[]>([]);
  const [formaPagos, setFormaPagos] = useState<DataConvenios[]>([]);
  const [porcentaje, setPorcentaje] = useState<Record<number, null>>({});
  const [label, setLabel] = useState<string>(" _______________");
  const [hidden, setHidden] = useState<boolean>(true);
  const [checkIn, setCheckIn] = useState<Record<string, boolean>>({});
  const [opciones, setOpciones] = useState<Record<number, number>>({});
  const [id, setId] = useState<Number[]>([]);
  const [resto, setResto] = useState<Record<number, null>>({});
  const [dataTable, setDataTable] = useState<Record<number, []>>({});
  const [seleccion, setSeleccion] = useState<PropsSeleccion[]>([]);
  const [seleccionOut, setSeleccionOut] = useState<PropsSeleccion[]>([]);
  const [form] = useForm();
  const { optionsPost, getItem } = useLocalStorage();
  const [loading, setLoading] = useState<boolean>(false);
  const [selection, setSelection] = useState<number[]>([]);
  const [opciones2, setOpciones2] = useState<Record<number, null>>({});
  const [responsable, setResponsable] = useState<DataConvenios[]>([]);

  const [loaderGeneral, setLoaderGeneral] = useState<boolean>(true);

  const [subDivideSum, setSubDivideSum] = useState<Record<string, number>>({});

  let { optionsGet } = useLocalStorage();
  useBreadCrumbs(breadCrumbs);

  //Estados para el sellOut
  const [restoOut, setRestoOut] = useState<Record<number, null>>({});
  const [porcentajeOut, setPorcentajeOut] = useState<Record<number, null>>({});
  const [checkOut, setCheckOut] = useState<Record<string, boolean>>({});

  //estados check
  const [checkSellIn, setCheckSellIn] = useState<boolean>(false);
  const [checkSellOut, setCheckSellOut] = useState<boolean>(false);

  const { setRadioValue, isLimited, radioValue, toggleLimit, onChangeEsDevoluciones, validateCheckboxes, setIsLimited } =
    useListCuota(form);

  const [editKeyIn, setEditKeyIn] = useState<number[]>([]);
  const [editKeyOut, setEditKeyOut] = useState<number[]>([]);

  const { isLimited: isLimitedOut, toggleLimit: toggleLimitOut, setIsLimited: setIsLimitedOut } = useListCuotaOut(form);

  const [fileListPdf, setFileListPdf] = useState<any>([]);
  const [previewVisiblePdf, setPreviewVisiblePdf] = useState(false);
  const [previewPdf, setPreviewPdf] = useState<string>("");
  const [isModified, setIsModified] = useState(false);
  const [llaveEdit, setLlaveEdit] = useState<string>("");

  const beforeUpload = (file: RcFile, FileList: RcFile[]): boolean | Promise<void> => {
    return new Promise((resolve, reject) => {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        notification.warning({
          message: "Advertencia",
          description: `Solo se permiten archivos en formato PDF.`,
          placement: "bottomRight",
        });
        return false;
      }
      const isLt5M = file.size / 1024 / 1024 <= 4;
      if (!isLt5M) {
        notification.warning({
          message: "Advertencia",
          description: `El archivo excede el tamaño permitido(4MB)`,
          placement: "bottomRight",
        });
        return false;
      }
      const url = URL.createObjectURL(file);
      setPreviewPdf(url);
      reject(false);
    });
  };

  const handleChangePdf = (info: any) => {
    let fileList = info.fileList;

    const hasModification = fileList?.length > 0 && (fileListPdf?.length === 0 || fileList[0]?.uid !== fileListPdf[0]?.uid);

    if (fileList?.length === 0) {
      setPreviewPdf("");
      setFileListPdf([]);
      setIsModified(hasModification);
      return;
    }
    // fileList = fileList.slice(-1);
    setFileListPdf(fileList);
    setIsModified(hasModification);
  };

  useEffect(() => {
    /**Aqui cambie al editar */
    if (flag === 0) {
      setOpenKeys(["Convenios/Administrar", "Convenios"]);
      setSelectedKeys(["Administrar/Nuevo"]);
    } else if (flag === 1) {
      setOpenKeys(["Convenios/Administrar", "Convenios"]);
      setSelectedKeys(["Administrar/Enviados"]);
    }
  }, [setOpenKeys, setSelectedKeys, flag]);

  const handleLoadPdf = useCallback(async (llave: string) => {
    const url2 = `${url_alku}allku/archivos/${llave}`;
    const pdf = [];
    const blob = await fetchPdfAsBlob(url2);
    const file = new File([blob], llave, { type: blob.type });
    setPreviewPdf(url2);
    setLlaveEdit(llave);
    pdf.push({
      uid: `pdf-${llave}`,
      name: llave,
      url2,
      status: "done",
      response: { llave },
      originFileObj: file,
    });
    setIsModified(false);
    setFileListPdf(pdf);
  }, []);

  const handleLoadData = useCallback(
    async (data: any) => {
      setLoaderGeneral(true);
      let devoluciones: string[] = [];
      data.devolucion_otros && devoluciones.push("devolucion_otros");
      data.devolucion_vencimiento && devoluciones.push("devolucion_vencimiento");
      setRadioValue(devoluciones.length === 0 ? false : true);
      setProveedores(JSON.parse(data.proveedor_autorizado));
      setafterConvenios(JSON.parse(data.cod_seleccion_tipo_convenio));
      let data_seleccion: number[] = [];
      JSON.parse(data.data_seleccion).forEach((el: any) => data_seleccion.push(el.value));
      setLabel(data.cod_tipo_convenio === 1 ? "Proveedores" : "Laboratorios");
      setCheckSellIn(JSON.parse(data.cuota_in).length > 0 ? true : false);
      setCheckSellOut(JSON.parse(data.cuota_out).length > 0 ? true : false);
      setId(data_seleccion);
      setIsLimited(!data.es_monto_in);
      setIsLimitedOut(!data.es_monto_out);
      setHidden(data.cod_tipo_convenio === 2 ? false : true);

      JSON.parse(data.cuota_in).forEach((el: any, index: number) =>
        setCheckIn((state) => ({ ...state, [index]: el.es_divide }))
      );
      JSON.parse(data.cuota_in).forEach((el: any, index: number) =>
        setPorcentaje((state) => ({ ...state, [index]: el.rebate }))
      );
      JSON.parse(data.cuota_in).forEach((el: any, index: number) => {
        setResto((state) => ({ ...state, [index]: el.rebate }));
      });

      JSON.parse(data.cuota_out).forEach((el: any, index: number) =>
        setCheckOut((state) => ({ ...state, [index]: el.es_divide }))
      );
      JSON.parse(data.cuota_out).forEach((el: any, index: number) =>
        setPorcentajeOut((state) => ({ ...state, [index]: el.rebate }))
      );
      JSON.parse(data.cuota_out).forEach((el: any, index: number) =>
        setRestoOut((state) => ({ ...state, [index]: el.rebate }))
      );

      setOpciones((state) => ({ ...state, 0: data.cod_opciones_in }));
      setOpciones((state) => ({ ...state, 1: data.cod_opciones_out }));

      let dataSelecIn = JSON.parse(data.seleccionProductosIn)[0]?.seleccion_producto
        ? JSON.parse(JSON.parse(data.seleccionProductosIn)[0].seleccion_producto)
        : [];

      let dataSelecOut = JSON.parse(data.seleccionProductosOut)[0]?.seleccion_producto
        ? JSON.parse(JSON.parse(data.seleccionProductosOut)[0].seleccion_producto)
        : [];

      if (data.cod_opciones_in && data.cod_opciones_in > 1) {
        let keyin = dataSelecIn.map((item: any) => item.key);
        setEditKeyIn(keyin);
        const newData = JSON.parse(data.productosxLab).map((el: PropsTable) => {
          const selectedElement = dataSelecIn.find((item: PropsTable) => item.key === el.key);
          if (selectedElement) {
            el.porcentaje = selectedElement.porcentaje;
          } else {
            el.porcentaje = JSON.parse(data.cuota_in)[0].rebate;
          }
          return el;
        });
        setDataTable((state) => ({ ...state, 0: newData }));
      }

      setSeleccion(dataSelecIn);
      if (data.cod_opciones_out && data.cod_opciones_out > 1) {
        let keyOut = dataSelecOut.map((item: any) => item.key);
        setEditKeyOut(keyOut);
        const newData2 = JSON.parse(data.productosxLab).map((el: PropsTable) => {
          const selectedElement = dataSelecOut.find((item: PropsTable) => item.key === el.key);
          if (selectedElement) {
            el.porcentaje = selectedElement.porcentaje;
          } else {
            el.porcentaje = JSON.parse(data.cuota_out)[0].rebate;
          }
          return el;
        });

        setDataTable((state) => ({ ...state, 1: newData2 }));
      }

      setSeleccionOut(dataSelecOut);
      let llave = data.llave_convenio;
      await handleLoadPdf(llave);
      form.setFieldsValue({
        periodo: moment(data.periodo),
        range_periodo: [moment(data.fecha_inicio, "DD/MM/YYYY"), moment(data.fecha_fin, "DD/MM/YYYY")],
        aplica_devolucion: data.devolucion_otros || data.devolucion_vencimiento ? true : false,
        formas_pago: data.formas_pago,
        periodo_pago: data.periodo_pago,
        cod_responsable: data.cod_responsable,
        cod_tipo_convenio: data.cod_tipo_convenio,
        cod_seleccion_tipo_convenio: data_seleccion,
        cod_proveedor_autorizado: data.cod_proveedor_autorizado,
        observacion: data.observacion,
        distribucionIn:
          JSON.parse(data.cuota_in).length > 0
            ? JSON.parse(data.cuota_in)
            : [{ es_monto: false, valor: "", rebate: "", es_divide: false }],
        distribucionOut:
          JSON.parse(data.cuota_out).length > 0
            ? JSON.parse(data.cuota_out)
            : [{ es_monto: false, valor: "", rebate: "", es_divide: false }],
        cod_opciones_por_productos_in: data.cod_opciones_in,
        cod_opciones_por_productos_out: data.cod_opciones_out,
        devoluciones: devoluciones,
      });
      setLoaderGeneral(false);
    },
    [form, handleLoadPdf, setIsLimited, setIsLimitedOut, setRadioValue]
  );

  const handleDataInitial = useCallback(async () => {
    try {
      setLoaderGeneral(true);
      const respuesta = await fetch(
        url + "apifsg-pr/convenios/data-convenio-id",
        optionsPost({ id: modalRecord?.key })
      ).then((data) => data.json());
      if (respuesta.msg) {
        let data = respuesta.data[0];
        await handleLoadData(data);
      } else {
        swalError({ text: "Ocurrio un error" });
      }
      setLoaderGeneral(false);
    } catch (error) {
      setLoaderGeneral(false);
    }
  }, [modalRecord?.key, optionsPost, handleLoadData]);

  const getEstados = useCallback(async () => {
    try {
      setLoaderGeneral(true);
      const respuesta = await fetch(url + "apifsg-pr/convenios/tipos-convenios", optionsGet).then((data) => data.json());
      const respuesta2 = await fetch(url + "apifsg-pr/convenios/opciones-productos", optionsGet).then((data) => data.json());

      if (respuesta.msg) {
        setOpcionesProductos(respuesta2.data);
        const { data, data1, data2, data3, data4, data5 } = respuesta;
        setResponsable(data5);
        flag === 0 && form.setFieldsValue({ cod_responsable: data5[0].value });
        setConvenios(data);
        setTipoNegociacion(data1);
        setTipoPagos(data2);
        setFormaPagos(data3);
        setOpcionesProductosEdit(data4);
        setLoaderGeneral(false);
      } else {
        setLoaderGeneral(false);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setLoaderGeneral(false);
      return { msg: "no_conexion", status: false };
    }
  }, [flag, form, optionsGet]);

  const onChangeSelectConvenio: SelectProps["onChange"] = async (e) => {
    form.setFieldsValue({
      cod_seleccion_tipo_convenio: [],
      cod_opciones_por_productos: "",
      cod_proveedor_autorizado: "",
    });

    setOpciones({});
    setDataTable({});
    setSeleccion([]);

    try {
      if (e === 1) {
        setLabel("Proveedores");
        const respuesta = await fetch(url + "apifsg-pr/convenios/proveedores", optionsGet).then((data) => data.json());
        if (respuesta.msg) {
          const { data } = respuesta;

          setafterConvenios(data);

          setHidden(true);
        }
      } else if (e === 2) {
        setLabel("Laboratorios");
        const respuesta = await fetch(url + "apifsg-pr/convenios/laboratorios", optionsGet).then((data) => data.json());
        if (respuesta.msg) {
          const { data } = respuesta;

          setafterConvenios(data);
        }
      } else if (!e) {
        setLabel("________________");
        setafterConvenios([]);
        setHidden(true);
      }
    } catch (error) {}
  };

  const onChangeAfterConvenio: SelectProps["onChange"] = async (e) => {
    form.setFieldsValue({
      cod_opciones_por_productos: "",
      cod_proveedor_autorizado: "",
    });

    if (e.length > 1 && label === "Proveedores") {
      e.splice(1, e.length - 1);
      setSelection(e);
    }

    try {
      setOpciones({});
      setId(e);

      form.setFieldsValue({ after_convenio_productos: "" });
      if (label === "Laboratorios") {
        setHidden(false);
        const respuesta = await fetch(url + "apifsg-pr/convenios/opciones-productos", optionsGet).then((data) =>
          data.json()
        );
        if (respuesta.msg) {
          const { data } = respuesta;
          setOpcionesProductos(data);
        }

        const respuesta2 = await fetch(url + "apifsg-pr/convenios/proveedores", optionsGet).then((data) => data.json());
        if (respuesta2.msg) {
          const { data } = respuesta2;
          setProveedores(data);
        }
      }
    } catch (error) {}
  };

  const onChangeOpcionesProductos = useCallback(
    async (e: number, name: number) => {
      try {
        setOpciones((state) => ({ ...state, [name]: e }));

        if (e > 1 && e < 4) {
          const respuesta = await fetch(url + "apifsg-pr/convenios/productos-laboratorio", optionsPost({ id })).then(
            (data) => data.json()
          );

          if (name === 0) {
            respuesta.data.map((el: PropsSeleccion) => {
              el.porcentaje = porcentaje[0];
              return el;
            });
          }
          setDataTable((state) => ({ ...state, [name]: respuesta.data }));
        } else if (typeof e === "undefined") {
          setOpciones(() => ({}));
        } else if (e === 1) {
          // setSeleccion([]);
          setDataTable((state) => ({ ...state, [name]: [] }));
        }
      } catch (error) {}
    },
    [id, optionsPost, porcentaje]
  );

  const onChangeOpcionesProductosOut = useCallback(
    async (e: number, name: number) => {
      try {
        setOpciones((state) => ({ ...state, [name]: e }));

        if (e > 1 && e < 4) {
          const respuesta = await fetch(url + "apifsg-pr/convenios/productos-laboratorio", optionsPost({ id })).then(
            (data) => data.json()
          );

          if (name === 1) {
            respuesta.data.map((el: PropsSeleccion) => {
              el.porcentaje = porcentajeOut[0];
              return el;
            });
          }
          setDataTable((state) => ({ ...state, [name]: respuesta.data }));
        } else if (typeof e === "undefined") {
          setOpciones(() => ({}));
        } else if (e === 1) {
          // setSeleccion([]);
          setDataTable((state) => ({ ...state, [name]: [] }));
        }
      } catch (error) {}
    },
    [id, optionsPost, porcentajeOut]
  );

  const sendFile = async (file: File, carpeta: string) => {
    const body = new FormData();
    try {
      body.append("uploadfile", file);
      body.append("archivo", carpeta);

      const requestSettings = {
        body,
        method: "POST",
        headers: {
          Accept: "application/json",
          "access-token": getItem("token") ?? "",
        },
      };

      let data = await fetch(`${url}apiallku-private/api/fromapiexterna2`, requestSettings).then((data) => data.json());

      return data.llave;
    } catch (error) {
      return error;
    }
  };

  const handleClick = () => {
    let llave: string;
    try {
      form
        .validateFields()
        .then(async (values: Convenio) => {
          setLoading(true);
          if (!checkSellIn && !checkSellOut) throw new Error("Debe seleccionar un tipo de cuota");

          const updatedSelectedRows = dataTable[0]?.filter(({ ...selectedRow }: PropsSeleccion) => {
            return seleccion.find(({ ...item }: PropsSeleccion) => item.key === selectedRow.key);
          });

          const updatedSelectedRowsOut = dataTable[1]?.filter(({ ...selectedRow }: PropsSeleccion) => {
            return seleccionOut.find(({ ...item }: PropsSeleccion) => item.key === selectedRow.key);
          });

          llave = await sendFile(fileListPdf[0].originFileObj, "convenios");
          if (!llave) throw new Error();

          values.periodo = (values.periodo as Moment).format("YYYY");
          values.llave = llave;
          values.checkSellIn = checkSellIn;
          values.checkSellOut = checkSellOut;

          if (values.cod_tipo_convenio === 1) {
            const respuesta = await fetch(
              url + "apifsg-pr/convenios/guardar",
              optionsPost({ values, updatedSelectedRows, updatedSelectedRowsOut })
            ).then((data) => data.json());

            if (respuesta.msg === "ok") {
              notification.success({
                message: "OK",
                description: `Se guardó correctamente el convenio`,
                placement: "bottomRight",
              });
              form.resetFields();
              setOpciones({});
              setDataTable([]);
              setLoading(false);
              setLabel("________________");
              setafterConvenios([]);
              setHidden(true);
              setCheckIn({});
              setResto({});
              setCheckSellIn(false);
              setCheckSellOut(false);
              setFileListPdf([]);
            } else {
              await fetch(`${url}apiallku-private-eliminar/api/deletefromapiexterna`, optionsPost({ files: [llave] }));
              setLoading(false);
              notification.error({
                message: "Error",
                description: `Ocurrio un error , comunicar al departamento de sistemas`,
                placement: "bottomRight",
              });
            }
          } else if (values.cod_tipo_convenio === 2) {
            if (values.cod_opciones_por_productos_in > 1) {
              if (updatedSelectedRows.length === 0) {
                await fetch(`${url}apiallku-private-eliminar/api/deletefromapiexterna`, optionsPost({ files: [llave] }));
                throw new Error("Debes seleccionar elementos");
              }
            }
            if (values.cod_opciones_por_productos_out > 1) {
              if (updatedSelectedRowsOut.length === 0) {
                await fetch(`${url}apiallku-private-eliminar/api/deletefromapiexterna`, optionsPost({ files: [llave] }));
                throw new Error("Debes seleccionar elementos");
              }
            }

            const respuesta = await fetch(
              url + "apifsg-pr/convenios/guardar",
              optionsPost({ values, updatedSelectedRows, updatedSelectedRowsOut })
            ).then((data) => data.json());

            if (respuesta.msg === "ok") {
              notification.success({
                message: "OK",
                description: `Se guardó correctamente el convenio`,
                placement: "bottomRight",
              });
              form.resetFields();
              setDataTable([]);
              setOpciones({});
              setLoading(false);
              setSeleccion([]);
              setFileListPdf([]);
              setLabel("________________");
              setafterConvenios([]);
              setHidden(true);

              form.setFieldsValue({
                cod_seleccion_tipo_convenio: [],
                cod_opciones_por_productos: "",
                cod_proveedor_autorizado: "",
              });
              setCheckIn({});
              setResto({});
              setCheckSellIn(false);
              setCheckSellOut(false);
            } else {
              setLoading(false);
              notification.error({
                message: "Error",
                description: `Ocurrio un error`,
                placement: "bottomRight",
              });
            }
          }
        })
        .catch(async (er) => {
          setLoading(false);
          notification.error({
            message: "Error",
            description: `Ocurrio un error`,
            placement: "bottomRight",
          });
        });
    } catch (error: any) {
      Swal.fire("Error", error, "error");
      setLoading(false);
    }
  };

  const handleEdit = () => {
    let llave: string;
    try {
      form
        .validateFields()
        .then(async (values: Convenio) => {
          setLoading(true);
          values.key = modalRecord?.key;
          if (!checkSellIn && !checkSellOut) throw new Error("Debe seleccionar un tipo de cuota");

          const updatedSelectedRows = dataTable[0]?.filter(({ ...selectedRow }: PropsSeleccion) => {
            return seleccion.find(({ ...item }: PropsSeleccion) => item.key === selectedRow.key);
          });

          const updatedSelectedRowsOut = dataTable[1]?.filter(({ ...selectedRow }: PropsSeleccion) => {
            return seleccionOut.find(({ ...item }: PropsSeleccion) => item.key === selectedRow.key);
          });
          if (isModified) {
            llave = await sendFile(fileListPdf[0].originFileObj, "convenios");
          } else {
            llave = llaveEdit;
          }
          if (!llave) throw new Error();

          values.periodo = (values.periodo as Moment).format("YYYY");
          values.llave = llave;
          values.checkSellIn = checkSellIn;
          values.checkSellOut = checkSellOut;

          if (values.cod_tipo_convenio === 1) {
            const respuesta = await fetch(
              url + "apifsg-pr/convenios/editar",
              optionsPost({ values, updatedSelectedRows, updatedSelectedRowsOut })
            ).then((data) => data.json());

            if (respuesta.msg) {
              notification.success({
                message: "OK",
                description: `Se actualizo correctamente el convenio`,
                placement: "bottomRight",
              });
              setLoading(false);
            } else {
              await fetch(`${url}apiallku-private-eliminar/api/deletefromapiexterna`, optionsPost({ files: [llave] }));
              setLoading(false);
              notification.warning({
                message: "Advertencia",
                description: `Faltan datos por completar`,
                placement: "bottomRight",
              });
            }
          } else if (values.cod_tipo_convenio === 2) {
            if (values.cod_opciones_por_productos_in > 1) {
              if (updatedSelectedRows.length === 0) {
                await fetch(`${url}apiallku-private-eliminar/api/deletefromapiexterna`, optionsPost({ files: [llave] }));
                throw new Error("Debes seleccionar elementos");
              }
            }
            if (values.cod_opciones_por_productos_out > 1) {
              if (updatedSelectedRowsOut.length === 0) {
                await fetch(`${url}apiallku-private-eliminar/api/deletefromapiexterna`, optionsPost({ files: [llave] }));
                throw new Error("Debes seleccionar elementos");
              }
            }

            const respuesta = await fetch(
              url + "apifsg-pr/convenios/editar",
              optionsPost({ values, updatedSelectedRows, updatedSelectedRowsOut })
            ).then((data) => data.json());

            if (respuesta.msg) {
              notification.success({
                message: "OK",
                description: `Se actualizo correctamente el convenio`,
                placement: "bottomRight",
              });
              setLoading(false);
            } else {
              setLoading(false);
              notification.error({
                message: "Error",
                description: `Faltan datos por completar`,
                placement: "bottomRight",
              });
            }
          }
        })
        .catch(async (er) => {
          setLoading(false);
          notification.error({
            message: "Error",
            description: `Ocurrio un error al guardar`,
            placement: "bottomRight",
          });
        });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: `Ocurrio un error al guardar`,
        placement: "bottomRight",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getEstados();
    flag === 1 && handleDataInitial();
  }, [flag, getEstados, handleDataInitial]);

  const totalRebate = (key: number) => {
    let compras = form.getFieldValue("distribucionIn")[key].compras ? form.getFieldValue("distribucionIn")[key].compras : 0;

    let ventas = form.getFieldValue("distribucionIn")[key].ventas ? form.getFieldValue("distribucionIn")[key].ventas : 0;
    let mercadeo = form.getFieldValue("distribucionIn")[key].mercadeo
      ? form.getFieldValue("distribucionIn")[key].mercadeo
      : 0;
    let supervisor = form.getFieldValue("distribucionIn")[key].supervisor
      ? form.getFieldValue("distribucionIn")[key].supervisor
      : 0;
    let convencion = form.getFieldValue("distribucionIn")[key].convencion
      ? form.getFieldValue("distribucionIn")[key].convencion
      : 0;

    let total = compras + ventas + mercadeo + supervisor + convencion;

    return total;
  };
  const totalRebateOut = (key: number) => {
    let compras = form.getFieldValue("distribucionOut")[key].compras
      ? form.getFieldValue("distribucionOut")[key].compras
      : 0;

    let ventas = form.getFieldValue("distribucionOut")[key].ventas ? form.getFieldValue("distribucionOut")[key].ventas : 0;
    let mercadeo = form.getFieldValue("distribucionOut")[key].mercadeo
      ? form.getFieldValue("distribucionOut")[key].mercadeo
      : 0;
    let supervisor = form.getFieldValue("distribucionOut")[key].supervisor
      ? form.getFieldValue("distribucionOut")[key].supervisor
      : 0;
    let convencion = form.getFieldValue("distribucionOut")[key].convencion
      ? form.getFieldValue("distribucionOut")[key].convencion
      : 0;

    let total = compras + ventas + mercadeo + supervisor + convencion;
    return total;
  };

  return {
    totalRebate,
    totalRebateOut,
    form,
    convenios,
    onChangeSelectConvenio,
    afterConvenios,
    label,
    hidden,
    onChangeAfterConvenio,
    opcionesProductos,
    onChangeOpcionesProductos,
    onChangeOpcionesProductosOut,
    opciones,
    dataTable,
    porcentaje,
    tipoPagos,
    tipoNegociacion,
    proveedores,
    checkIn,
    setCheckIn,
    handleClick,
    subDivideSum,
    setSubDivideSum,
    setPorcentaje,
    resto,
    setResto,
    setDataTable,
    setSeleccion,
    seleccion,
    loading,
    selection,
    formaPagos,
    setOpciones,
    opciones2,
    setOpciones2,
    restoOut,
    setRestoOut,
    porcentajeOut,
    setPorcentajeOut,
    checkOut,
    setCheckOut,
    seleccionOut,
    setSeleccionOut,
    checkSellIn,
    setCheckSellIn,
    checkSellOut,
    setCheckSellOut,
    opcionesProductosEdit,
    responsable,
    isLimited,
    radioValue,
    toggleLimit,
    onChangeEsDevoluciones,
    validateCheckboxes,
    isLimitedOut,
    toggleLimitOut,
    editKeyIn,
    editKeyOut,
    setEditKeyIn,
    setEditKeyOut,
    beforeUpload,
    fileListPdf,
    handleChangePdf,
    previewVisiblePdf,
    setPreviewVisiblePdf,
    previewPdf,
    handleEdit,
    loaderGeneral,
  };
}
