import { Col, Row, Select, Table, Tag, type TableColumnsType, Form } from "antd"
import { type DefaultOptionType, type SelectProps } from "antd/lib/select"
import { llave } from "config/constants"
import AES from "crypto-js/aes"
import Utf8 from "crypto-js/enc-utf8"
import { useLayout } from "hooks/otros/useLayout"
import { useSearchTable } from "hooks/otros/useSearchTable"
import moment from "moment"
import { useEffect, useState } from "react"
import { Outlet, useLoaderData, useNavigate, useParams } from "react-router-dom"

const breadcrumbs = [
  { Label: "Recursos Humanos", Url: "/rrhh" },
  { Label: "Vacaciones", Url: "/rrhh/vacaciones" },
  { Label: "Historial por empleado" },
]

const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]

const selectKey = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export function HistorialVacaciones() {
  useLayout(breadcrumbs, openKeys, selectKey)

  const params = useParams()

  const empleados = useLoaderData() as DefaultOptionType[]

  const [empleado, setEmpleado] = useState<number>()

  const navigate = useNavigate()

  const handleChangeEmpleado: SelectProps["onChange"] = value => {
    setEmpleado(value)
    navigate(
      "/rrhh/vacaciones/historial-por-empleado/" + encodeURIComponent(AES.encrypt(value.toString(), llave).toString())
    )
  }

  useEffect(() => {
    if (!params.empleado) return

    const cod_empleado = AES.decrypt(decodeURIComponent(params.empleado ?? ""), llave).toString(Utf8)

    setEmpleado(parseInt(cod_empleado))
  }, [params.empleado])

  return (
    <Row wrap style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
        <Form>
          <Form.Item label="Empleado">
            <Select
              options={empleados}
              optionFilterProp="label"
              value={empleado}
              onChange={handleChangeEmpleado}
              style={{ width: "100%" }}
              showSearch
            />
          </Form.Item>
        </Form>
      </Col>
      <Outlet />
    </Row>
  )
}

type Solicitudes = {
  key: number
  periodo: string
  estado: "PE" | "PP" | "PR" | "RE"
  adelanto: boolean
  fecha_inicio: string
  fecha_fin: string
  tipo_aprobacion?: "JI" | "JS" | "RH"
  pagados?: number
}

const ESTADOS = {
  PE: "Pendiente",
  PP: "Proceso Parcial",
  PR: "Aprobada",
  RE: "Rechazada",
}

const COLORS = {
  PE: "blue",
  PP: "purple",
  PR: "green",
  RE: "error",
}

export function TablaHistorial() {
  const solicitudes = useLoaderData() as Solicitudes[]

  const { getColumnSearchProps } = useSearchTable<Solicitudes>()

  const columns: TableColumnsType<Solicitudes> = [
    {
      title: "Periodo",
      dataIndex: "periodo",
      key: "periodo",
      ...getColumnSearchProps("periodo"),
    },
    {
      title: "Fechas",
      key: "fechas",
      children: [
        {
          title: "Inicio",
          dataIndex: "fecha_inicio",
          key: "fecha_inicio",
          align: "center",
        },
        {
          title: "Fin",
          dataIndex: "fecha_fin",
          key: "fecha_fin",
          align: "center",
        },
      ],
    },
    {
      title: "Número de días",
      key: "fecha_inicio",
      children: [
        {
          title: "Solicitados",
          dataIndex: "fecha_inicio",
          key: "dias_solicitados",
          align: "right",
          render: (value, { fecha_fin }) => (
            <Tag>{moment(fecha_fin, "DD/MM/YYYY").diff(moment(value, "DD/MM/YYYY"), "days") + 1}</Tag>
          ),
        },
        {
          title: "A pagar",
          dataIndex: "pagados",
          key: "pagados",
          align: "right",
          render: value => value !== 0 && <Tag color={value > 0 ? "gold" : "default"}>{value}</Tag>,
        },
      ],
    },
    {
      title: "Solicitud",
      dataIndex: "adelanto",
      key: "adelanto",
      align: "center",
      render: value => <Tag color={value ? "red" : "blue"}>{value ? "Anticipo" : "Normal"}</Tag>,
      filters: [
        { text: "Anticipo", value: true },
        { text: "Normal", value: false },
      ],
      onFilter: (value, { adelanto }) => value === adelanto,
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      align: "center",
      filters: [
        { text: "PENDIENTE", value: "PE" },
        { text: "RECHAZADA", value: "RE" },
        { text: "PROCESO PARCIAL", value: "PP" },
        { text: "APROBADA", value: "PR" },
      ],
      onFilter: (value, { estado }) => value === estado,
      render: value => <Tag color={COLORS[value as keyof typeof COLORS]}>{ESTADOS[value as keyof typeof ESTADOS]}</Tag>,
    },
  ]

  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
      <Table dataSource={solicitudes} columns={columns} size="small" pagination={{ size: "default" }} />
    </Col>
  )
}
