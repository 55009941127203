import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import {
  Facturas,
  FormFacturas,
  FormNotaVentas,
  FormRecibos,
  NotaVentas,
  Recibos,
  useModalFormCrearReembolso,
} from "./useModalFormCrearReembolso";
import { useButtonRightListCrearReembolso } from "./useButtonRightListCrearReembolso";
import { useFetchInFunction } from "hooks/otros/useFetch";
import { optionsPOST, url_interna, llave } from "config/constants";

import Swal from "sweetalert2";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

import moment from "moment";
import "moment/locale/es";
import { useNavigate, useParams } from "react-router-dom";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { LayoutContext } from "context/LayoutContext";

interface fetchRecibos {
  Cod_Documento: number;
  Documento: string;
  Total: number;
  Fecha: string;
  Detalle: string;
  Cod_Pais: number;
  Cod_Provincia: number | null;
  Cod_Ciudad: number | null;
  Cod_Tipo_Pago: string;
  Cod_Tipo_Gasto: number;
  Razon_Social: string;
  Ruc: string;
}
interface fetchNotaVentas extends fetchRecibos {
  Clave_Acceso: string;
}

interface fetchFacturas extends fetchNotaVentas {
  Base_0: number;
  Base_12: number;
  Iva: number;
  Servicio_10: number;
}

interface fetchDataReembolso {
  msg: string;
  data: {
    Cod_Reembolso: number;
    Justificacion: string;
    Estado: "PE" | "AP" | "RE";
    Facturas: fetchFacturas[];
    NotaVentas: fetchNotaVentas[];
    Recibos: fetchRecibos[];
  };
}

const breadCrumbs = [
  {
    Label: "Reembolsos",
    Url: "/reembolsos/pendientes",
  },
  { Label: "Editar reembolso" },
];

export function useEditarReembolso() {
  const navigate = useNavigate();

  const { reembolso } = useParams();

  const {
    modalProps,
    setModalProps,
    initialValues,
    inputsState,
    forms,
    resetFields,
    cargarCiudades,
  } = useModalFormCrearReembolso();

  const { lists, setLists, contentDocuments, actionsList } = useButtonRightListCrearReembolso();

  const [JustificacionValue, setJustificacionValue] = useState("");

  const [setListFacturas, setListNotaVentas, setListRecibos] = setLists;

  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);

  useBreadCrumbs(breadCrumbs);

  useEffect(() => {
    setOpenKeys(["Reembolsos", "Reembolsos/Ver Reembolsos"]);
    setSelectedKeys([]);
  }, [setOpenKeys, setSelectedKeys]);

  // useEffect(() => setShowBack(true),[ setShowBack ])

  useEffect(() => {
    fetch(
      `${url_interna}api/reembolsos/ver-reembolso`,
      optionsPOST(
        JSON.stringify({
          Cod_Reembolso: AES.decrypt(decodeURIComponent(reembolso ?? ""), llave).toString(Utf8),
        }),
        { "api-token": "apitoken" }
      )
    )
      .then((res) => res.json())
      .then(({ msg, data }: fetchDataReembolso) => {
        if (msg === "ok") {
          data.Estado !== "PE" && navigate("/reembolsos/pendientes");
          setJustificacionValue(data.Justificacion);
          setListFacturas(
            data.Facturas.map(
              ({
                Cod_Documento,
                Documento,
                Detalle,
                Cod_Tipo_Pago,
                Servicio_10,
                Base_12,
                Iva,
                Base_0,
                Total,
                Fecha,
                Cod_Tipo_Gasto,
                Cod_Provincia,
                Cod_Ciudad,
                Clave_Acceso,
                Ruc,
                Razon_Social,
                Cod_Pais,
              }) => {
                return {
                  Cod_Documento: Cod_Documento,
                  documento: Documento,
                  concepto: Detalle,
                  valor: Total,
                  fecha: Fecha,
                  pais: Cod_Pais,
                  ruc: Ruc,
                  razonsocial: Razon_Social,
                  numeroautorizacion: Clave_Acceso,
                  tipoPago: Cod_Tipo_Pago,
                  tipoGasto: Cod_Tipo_Gasto,
                  provincia: Cod_Provincia ?? undefined,
                  ciudad: Cod_Ciudad ?? null,
                  servicio10: Servicio_10,
                  base0: Base_0,
                  base12: Base_12,
                  iva: Iva,
                };
              }
            )
          );
          (setListNotaVentas as React.Dispatch<React.SetStateAction<NotaVentas[]>>)(
            data.NotaVentas.map(
              ({
                Cod_Documento,
                Documento,
                Detalle,
                Cod_Tipo_Pago,
                Total,
                Fecha,
                Cod_Tipo_Gasto,
                Cod_Provincia,
                Cod_Ciudad,
                Clave_Acceso,
                Ruc,
                Razon_Social,
                Cod_Pais,
              }) => {
                return {
                  Cod_Documento: Cod_Documento,
                  documento: Documento,
                  concepto: Detalle,
                  valor: Total,
                  fecha: Fecha,
                  pais: Cod_Pais,
                  ruc: Ruc,
                  razonsocial: Razon_Social,
                  numeroautorizacion: Clave_Acceso,
                  tipoPago: Cod_Tipo_Pago,
                  tipoGasto: Cod_Tipo_Gasto,
                  provincia: Cod_Provincia ?? undefined,
                  ciudad: Cod_Ciudad ?? null,
                };
              }
            )
          );
          (setListRecibos as React.Dispatch<React.SetStateAction<Recibos[]>>)(
            data.Recibos.map(
              ({
                Ruc,
                Razon_Social,
                Cod_Documento,
                Documento,
                Detalle,
                Total,
                Fecha,
                Cod_Pais,
                Cod_Ciudad,
                Cod_Provincia,
                Cod_Tipo_Gasto,
                Cod_Tipo_Pago,
              }) => {
                return {
                  ruc: Ruc,
                  razonsocial: Razon_Social,
                  Cod_Documento: Cod_Documento,
                  documento: Documento,
                  concepto: Detalle,
                  valor: Total,
                  fecha: Fecha,
                  pais: Cod_Pais,
                  ciudad: Cod_Ciudad ?? null,
                  provincia: Cod_Provincia ?? undefined,
                  tipoGasto: Cod_Tipo_Gasto,
                  tipoPago: Cod_Tipo_Pago,
                };
              }
            )
          );
        } else {
          Swal.fire({
            title: "Error de consulta",
            text: "Error al obtener la lista de reembolsos de gastos",
            icon: "error",
            timer: 2000,
            showConfirmButton: false,
          });
        }
      })
      .catch((_) => {
        Swal.fire({
          title: "Sin Conexión",
          text: "No hay conexión con el servidor",
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  }, [reembolso, setListFacturas, setListNotaVentas, setListRecibos, navigate]);

  const handleOkModal = [
    (values: FormFacturas | FormNotaVentas | FormRecibos) => {
      resetFields[0]();
      (setListFacturas as React.Dispatch<React.SetStateAction<Facturas[]>>)((state) => {
        return [
          ...state,
          {
            Cod_Documento: null,
            numeroautorizacion: (values as FormFacturas).numeroautorizacion,
            razonsocial: (values as FormFacturas).razonsocial,
            ruc: (values as FormFacturas).ruc,
            documento: (values as FormFacturas).documento,
            base12: (values as FormFacturas).base12,
            iva: (values as FormFacturas).iva,
            base0: (values as FormFacturas).base0,
            servicio10: (values as FormFacturas).servicio10,
            tipoPago: (values as FormFacturas).tipoPago,
            tipoGasto: (values as FormFacturas).tipoGasto,
            fecha: (values as FormFacturas).fecha.format("DD/MM/YYYY"),
            concepto: (values as FormFacturas).concepto,
            pais: (values as FormFacturas).pais,
            provincia: (values as FormFacturas).provincia,
            ciudad: (values as FormFacturas).ciudad ?? null,
            valor: (values as FormFacturas).valor,
          },
        ] as Facturas[];
      });

      if (modalProps[0].okText === "Agregar")
        setModalProps[0]((state) => ({ ...state, open: false }));
      else setModalProps[0]({ title: "Agregar Factura", open: false, okText: "Agregar" });
    },
    (values: FormFacturas | FormNotaVentas | FormRecibos) => {
      resetFields[1]();
      (setListNotaVentas as React.Dispatch<React.SetStateAction<NotaVentas[]>>)((state) => {
        return [
          ...state,
          {
            Cod_Documento: null,
            numeroautorizacion: (values as FormNotaVentas).numeroautorizacion,
            razonsocial: (values as FormNotaVentas).razonsocial,
            ruc: (values as FormNotaVentas).ruc,
            documento: (values as FormNotaVentas).documento,
            tipoPago: (values as FormNotaVentas).tipoPago,
            tipoGasto: (values as FormNotaVentas).tipoGasto,
            fecha: (values as FormNotaVentas).fecha.format("DD/MM/YYYY"),
            concepto: (values as FormNotaVentas).concepto,
            pais: (values as FormNotaVentas).pais,
            provincia: (values as FormNotaVentas).provincia,
            ciudad: (values as FormNotaVentas).ciudad ?? null,
            valor: (values as FormNotaVentas).valor,
          } as NotaVentas,
        ];
      });

      if (modalProps[1].okText === "Agregar")
        setModalProps[1]((state) => ({ ...state, open: false }));
      else setModalProps[1]({ title: "Agregar Nota de venta", open: false, okText: "Agregar" });
    },
    (values: FormFacturas | FormNotaVentas | FormRecibos) => {
      (setListRecibos as React.Dispatch<React.SetStateAction<Recibos[]>>)((state) => {
        return [
          ...state,
          {
            Cod_Documento: null,
            ruc: (values as FormRecibos).ruc,
            razonsocial: (values as FormRecibos).razonsocial,
            documento: (values as FormRecibos).documento,
            fecha: (values as FormRecibos).fecha.format("DD/MM/YYYY"),
            concepto: (values as FormRecibos).concepto,
            pais: (values as FormRecibos).pais,
            valor: (values as FormRecibos).valor,
            tipoGasto: (values as FormRecibos).tipoGasto ?? 0,
            tipoPago: (values as FormRecibos).tipoPago,
            provincia: (values as FormRecibos).provincia,
            ciudad: (values as FormRecibos).ciudad ?? null,
          },
        ];
      });

      if (modalProps[2].okText === "Agregar")
        setModalProps[2]((state) => ({ ...state, open: false }));
      else setModalProps[2]({ title: "Agregar Recibo", open: false, okText: "Agregar" });
    },
  ];

  const okModals = useRef(handleOkModal);

  const resetOkModal = (index: number) => {
    okModals.current[index] = handleOkModal[index];
  };

  const DeleteDocument = async (index: number, indexDoc: number) => {
    try {
      if (lists[indexDoc][index].Cod_Documento) {
        if (lists[0].length + lists[1].length + lists[2].length <= 1) {
          Swal.fire(
            "¡Advertencia!",
            "Debe haber al menos un documento en la solicitud del reembolso",
            "warning"
          );
          return;
        }
        const result = await Swal.fire({
          title: "¿Está seguro?",
          text: `${
            indexDoc === 0
              ? "La factura está guardada"
              : indexDoc === 1
              ? "La nota de venta está guardada"
              : "El ticket está guardado"
          }, si continua se eliminará para siempre. ¿Desea continuar?`,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "var(--primary)",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        });

        if (!result.isConfirmed) return;

        const tipoDocumento = indexDoc === 0 ? "FA" : indexDoc === 1 ? "NV" : "RE";

        const res = await fetch(
          `${url_interna}api/reembolsos/eliminar-documento`,
          optionsPOST(
            JSON.stringify({
              Cod_Documento: lists[indexDoc][index].Cod_Documento,
              Tipo_Documento: tipoDocumento,
            }),
            { "api-token": "apitoken" }
          )
        );

        if (!res.ok) {
          Swal.fire(
            "¡Error!",
            `No se pudo eliminar ${
              indexDoc === 0 ? "la factura" : indexDoc === 1 ? "la nota de venta" : "el ticket"
            }`,
            "error"
          );
          return;
        }

        const data = await res.json();

        if (data.msg === "err") {
          Swal.fire(
            "¡Error!",
            `No se pudo eliminar ${
              indexDoc === 0 ? "la factura" : indexDoc === 1 ? "la nota de venta" : "el ticket"
            }`,
            "error"
          );
          return;
        }

        Swal.fire(
          "¡Eliminado!",
          `${
            indexDoc === 0 ? "La factura" : indexDoc === 1 ? "La nota de venta" : "El ticket"
          } se ha eliminado con éxito`,
          "success"
        );
      }

      setLists[indexDoc]((state: any[]) => {
        const newState = [...state];
        newState.splice(index, 1);
        return newState;
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnClickActionList = [
    {
      Editar: (index: number) => {
        forms[0].setFieldsValue({
          numeroautorizacion: (lists[0] as Facturas[])[index].numeroautorizacion,
          razonsocial: (lists[0] as Facturas[])[index].razonsocial,
          ruc: (lists[0] as Facturas[])[index].ruc,
          documento: (lists[0] as Facturas[])[index].documento,
          base12: (lists[0] as Facturas[])[index].base12,
          iva: (lists[0] as Facturas[])[index].iva,
          base0: (lists[0] as Facturas[])[index].base0,
          servicio10: (lists[0] as Facturas[])[index].servicio10,
          tipoPago: (lists[0] as Facturas[])[index].tipoPago,
          tipoGasto: (lists[0] as Facturas[])[index].tipoGasto,
          fecha: moment((lists[0] as Facturas[])[index].fecha.trim(), "DD MM YYYY"),
          concepto: (lists[0] as Facturas[])[index].concepto,
          pais: (lists[0] as Facturas[])[index].pais,
          provincia: (lists[0] as Facturas[])[index].provincia,
          ciudad: (lists[0] as Facturas[])[index].ciudad ?? undefined,
          valor: (lists[0] as Facturas[])[index].valor,
        });

        (lists[0] as Facturas[])[index].ciudad &&
          cargarCiudades[0](
            JSON.stringify({ Cod_Provincia: (lists[0] as Facturas[])[index].provincia }),
            { "api-token": "apitoken" }
          );

        setModalProps[0]({ title: "Modificar Factura", open: true, okText: "Modificar" });
        okModals.current[0] = (values: FormFacturas | FormNotaVentas | FormRecibos) => {
          resetFields[0]();
          (setListFacturas as React.Dispatch<React.SetStateAction<Facturas[]>>)((state) => {
            const newState = [...state];
            newState[index] = {
              Cod_Documento: newState[index].Cod_Documento,
              numeroautorizacion: (values as FormFacturas).numeroautorizacion,
              razonsocial: (values as FormFacturas).razonsocial,
              ruc: (values as FormFacturas).ruc,
              documento: (values as FormFacturas).documento,
              base12: (values as FormFacturas).base12,
              iva: (values as FormFacturas).iva,
              base0: (values as FormFacturas).base0,
              servicio10: (values as FormFacturas).servicio10,
              tipoPago: (values as FormFacturas).tipoPago,
              tipoGasto: (values as FormFacturas).tipoGasto ?? 0,
              fecha: (values as FormFacturas).fecha.format("DD/MM/YYYY"),
              concepto: (values as FormFacturas).concepto,
              pais: (values as FormFacturas).pais,
              provincia: (values as FormFacturas).provincia,
              ciudad: (values as FormFacturas).ciudad ?? null,
              valor: (values as FormFacturas).valor,
            };
            return [...newState];
          });
          okModals.current[0] = handleOkModal[0];
          setModalProps[0]({ title: "Agregar Factura", open: false, okText: "Agregar" });
        };
      },
      Eliminar: (index: number) => DeleteDocument(index, 0),
    },
    {
      Editar: (index: number) => {
        forms[1].setFieldsValue({
          numeroautorizacion: (lists[1] as NotaVentas[])[index].numeroautorizacion,
          razonsocial: (lists[1] as NotaVentas[])[index].razonsocial,
          ruc: (lists[1] as NotaVentas[])[index].ruc,
          documento: (lists[1] as NotaVentas[])[index].documento,
          tipoPago: (lists[1] as NotaVentas[])[index].tipoPago,
          tipoGasto: (lists[1] as NotaVentas[])[index].tipoGasto,
          fecha: moment((lists[1] as NotaVentas[])[index].fecha.trim(), "DD MM YYYY"),
          concepto: (lists[1] as NotaVentas[])[index].concepto,
          pais: (lists[1] as NotaVentas[])[index].pais,
          provincia: (lists[1] as any[])[index].provincia,
          ciudad: (lists[1] as NotaVentas[])[index].ciudad ?? undefined,
          valor: (lists[1] as NotaVentas[])[index].valor,
        });
        (lists[1] as NotaVentas[])[index].ciudad &&
          cargarCiudades[1](
            JSON.stringify({ Cod_Provincia: (lists[1] as NotaVentas[])[index].provincia }),
            { "api-token": "apitoken" }
          );

        setModalProps[1]({ title: "Modificar Nota de Venta", open: true, okText: "Modificar" });
        okModals.current[1] = (values: FormFacturas | FormNotaVentas | FormRecibos) => {
          console.log(lists[1]);

          resetFields[1]();
          (setListNotaVentas as React.Dispatch<React.SetStateAction<NotaVentas[]>>)((state) => {
            const newState = [...state];
            newState[index] = {
              Cod_Documento: newState[index].Cod_Documento,
              numeroautorizacion: (values as FormNotaVentas).numeroautorizacion,
              razonsocial: (values as FormNotaVentas).razonsocial,
              ruc: (values as FormNotaVentas).ruc,
              documento: (values as FormNotaVentas).documento,
              tipoPago: (values as FormNotaVentas).tipoPago,
              tipoGasto: (values as FormNotaVentas).tipoGasto ?? 0,
              fecha: (values as FormNotaVentas).fecha.format("DD/MM/YYYY"),
              concepto: (values as FormNotaVentas).concepto,
              pais: (values as FormNotaVentas).pais,
              provincia: (values as FormNotaVentas).provincia,
              ciudad: (values as FormNotaVentas).ciudad ?? null,
              valor: (values as FormNotaVentas).valor,
            };
            return [...newState];
          });
          okModals.current[1] = handleOkModal[1];
          setModalProps[1]({ title: "Agregar Nota de Venta", open: false, okText: "Agregar" });
        };
      },
      Eliminar: (index: number) => DeleteDocument(index, 1),
    },
    {
      Editar: (index: number) => {
        forms[2].setFieldsValue({
          ruc: (lists[2] as Recibos[])[index].ruc,
          razonsocial: (lists[2] as Recibos[])[index].razonsocial,
          documento: (lists[2] as Recibos[])[index].documento,
          fecha: moment((lists[2] as Recibos[])[index].fecha.trim(), "DD MM YYYY"),
          concepto: (lists[2] as Recibos[])[index].concepto,
          pais: (lists[2] as Recibos[])[index].pais,
          valor: (lists[2] as Recibos[])[index].valor,
          tipoPago: (lists[2] as Recibos[])[index].tipoPago,
          tipoGasto: (lists[2] as Recibos[])[index].tipoGasto,
          provincia: (lists[2] as Recibos[])[index].provincia,
          ciudad: (lists[2] as Recibos[])[index].ciudad,
        } as FormRecibos);

        (lists[2] as Recibos[])[index].ciudad &&
          cargarCiudades[2](
            JSON.stringify({ Cod_Provincia: (lists[2] as Recibos[])[index].provincia }),
            { "api-token": "apitoken" }
          );

        setModalProps[2]({ title: "Modificar Ticket", open: true, okText: "Modificar" });

        okModals.current[2] = (values: FormFacturas | FormNotaVentas | FormRecibos) => {
          (setListRecibos as React.Dispatch<React.SetStateAction<Recibos[]>>)((state) => {
            const newState = [...state];
            newState[index] = {
              Cod_Documento: newState[index].Cod_Documento,
              ruc: (values as FormRecibos).ruc,
              razonsocial: (values as FormRecibos).razonsocial,
              documento: (values as FormRecibos).documento,
              fecha: (values as FormRecibos).fecha.format("DD/MM/YYYY"),
              concepto: (values as FormRecibos).concepto,
              pais: (values as FormRecibos).pais,
              valor: (values as FormRecibos).valor,
              tipoPago: (values as FormRecibos).tipoPago,
              tipoGasto: (values as FormRecibos).tipoGasto ?? 0,
              provincia: (values as FormRecibos).provincia,
              ciudad: (values as FormRecibos).ciudad ?? null,
            };
            return [...newState];
          });
          okModals.current[2] = handleOkModal[2];
          setModalProps[2]({ title: "Agregar Ticket", open: false, okText: "Agregar" });
        };
      },
      Eliminar: (index: number) => DeleteDocument(index, 2),
    },
  ];

  const { fetchData: ModificarReembolso } = useFetchInFunction(
    `${url_interna}api/reembolsos/editar-reembolso`,
    optionsPOST,
    useCallback(
      (response: any, error?: Error) => {
        if (error) {
          console.error(error);
          return;
        }
        Swal.fire({
          title: "Datos almacenados",
          text: "Reembolso de gastos almacenado con éxito",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        setJustificacionValue("");
        setListFacturas([]);
        setListNotaVentas([]);
        setListRecibos([]);
        navigate("/reembolsos/pendientes");
      },
      [setListFacturas, setListNotaVentas, setListRecibos, navigate]
    )
  );

  return {
    handleOkModal: okModals.current,
    inputsState,
    handleOnClickActionList,
    modalProps,
    setModalProps,
    lists,
    setLists,
    contentDocuments,
    actionsList,
    ModificarReembolso,
    forms,
    initialValues,
    resetFields,
    JustificacionValue,
    setJustificacionValue,
    resetOkModal,
  };
}
