import AdministrarRotacionEmpleado from "components/indicadores/AdministrarRotacionEmpleado"
import { ContextEmpleado } from "context/indicadores/RotacionEmpleadoContext"

export const RotacionEmpleados = () => {
  return (
    <ContextEmpleado>
      <AdministrarRotacionEmpleado />
    </ContextEmpleado>
  )
}
