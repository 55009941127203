import { type DataVerticalTable, VerticalTable } from "components/reactreport/vertical-table"
import { type Deudas } from "context/franquiciados/form-pago-context"

type Props = {
  deudas: Deudas[]
}

export function DeudasDetalle({ deudas }: Props) {
  const dataDeudas: DataVerticalTable[] = deudas
    .map(({ deuda, monto, valor_a_pagar, cuota, cuotas }, index) => [
      {
        title: deuda,
        text: monto.toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,"),
        key: deuda,
        styleCell: { padding: 3, backgroundColor: "#FFCF00" },
        styleCellHeader: { padding: 3 },
      },
      {
        title: `Valor de la cuota ${cuota}/${cuotas}`,
        text: valor_a_pagar.toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,"),
        key: `cuota ${index}`,
        styleCell: { padding: 3, backgroundColor: "#DAF7A6" },
        styleCellHeader: { padding: 3 },
      },
    ])
    .flat()

  return (
    <VerticalTable
      dataSource={dataDeudas}
      styleColumn={{ flexBasis: "15%" }}
      styleColumnHeader={{ flexBasis: "65%", backgroundColor: "#FFC300" }}
      roundTop={true}
    />
  )
}
