import { llave } from "config/constants"
import { loaderRoute, sendAction } from "config/methods"
import AES from "crypto-js/aes"
import Utf8 from "crypto-js/enc-utf8"
import sha256 from "crypto-js/sha256"
import { Principal } from "pages/contentprincipal/Principal"
import { EstadoLlamadoAtencion } from "pages/contentrecursoshumanos/contentllamadosatencion/EstadoLlamadoAtencion"
import { LlamadoAtencion } from "pages/contentrecursoshumanos/contentllamadosatencion/LlamadoAtencion"
import { MarcarRecibidos } from "pages/contentrecursoshumanos/contentllamadosatencion/MarcarRecibidos"
import { ModificaLlamadoAtencion } from "pages/contentrecursoshumanos/contentllamadosatencion/ModificaLlamadoAtencion"
import { ReporteHistorico } from "pages/contentrecursoshumanos/contentllamadosatencion/ReporteHistorico"
import { ReporteHistoricoPorEmpleado } from "pages/contentrecursoshumanos/contentllamadosatencion/ReporteHistoricoPorEmpleado"
import { ReporteLlamadosAtencion } from "pages/contentrecursoshumanos/contentllamadosatencion/ReporteLlamadosAtencion"
import { RevisionLlamadosAtencion } from "pages/contentrecursoshumanos/contentllamadosatencion/RevisionLlamadosAtencion"
// import { RegistrarRecibido } from "pages/contentrecursoshumanos/contentllamadosatencion/registrar-recibido"
import { ReporteRecibidos } from "pages/contentrecursoshumanos/contentllamadosatencion/reporte-recibidos"
import { BitacoraRecibidos } from "pages/contentrecursoshumanos/contentllamadosatencion/bitacora-recibidos"
import { RouteObject, defer } from "react-router-dom"

export const rutasLlamadosAtencion: RouteObject[] = [
  {
    path: "/rrhh/llamados-de-atencion",
    element: <Principal />,
  },
  {
    path: "/rrhh/llamados-de-atencion/solicitud",
    element: <LlamadoAtencion />,
  },
  {
    path: "/rrhh/llamados-de-atencion/revision",
    element: <RevisionLlamadosAtencion />,
  },
  {
    path: "/rrhh/llamados-de-atencion/reporte-aprobados",
    element: <ReporteLlamadosAtencion />,
  },
  {
    path: "/rrhh/llamados-de-atencion/estado-solicitudes",
    element: <EstadoLlamadoAtencion />,
  },
  {
    path: "/rrhh/llamados-de-atencion/modificar-revision",
    element: <ModificaLlamadoAtencion />,
  },
  {
    path: "/rrhh/llamados-de-atencion/reporte-historico-por-empleado",
    element: <ReporteHistoricoPorEmpleado />,
  },
  {
    path: "/rrhh/llamados-de-atencion/marcar-recibidos",
    element: <MarcarRecibidos />,
    // loader: () => loaderRoute("apifsg-pr/recursos-humanos/listar-llamados-atencion-aprobados-por-fechas-recibidos"),
    // action: ({ request }) => sendAction("fapifsg-pr/recursos-humanos/marcar-recibidos", request),
  },
  {
    path: "/rrhh/llamados-de-atencion/reporte-recibidos",
    element: <ReporteRecibidos />,
  },
  {
    path: "/rrhh/llamados-de-atencion/reporte-historico",
    element: <ReporteHistorico />,
  },
  {
    path: "/rrhh/llamados-de-atencion/:llamadoatencion/registrar-recibido/:empleado",
    // element: <RegistrarRecibido />,
    lazy: async () => {
      let { RegistrarRecibido } = await import("pages/contentrecursoshumanos/contentllamadosatencion/registrar-recibido")
      return { Component: RegistrarRecibido }
    },
    loader: async ({ params }) => {
      const llamadoAtencion = AES.decrypt(decodeURIComponent(params.llamadoatencion ?? ""), llave).toString(Utf8)
      const cod_empleado = AES.decrypt(decodeURIComponent(params.empleado ?? ""), llave).toString(Utf8)

      const dataParams = new URLSearchParams({
        solicitud: llamadoAtencion,
        empleado: cod_empleado,
      })
      const dataAnterior = loaderRoute(`fapifsg-pr/recursos-humanos/verificar-llamado-atencion?${dataParams}`)
      const usuarios = await loaderRoute("fapifsg-pr/recursos-humanos/listar-usuarios-select")
      const bodegas = await loaderRoute("fapifsg-pr/recursos-humanos/listar-bodegas-select")
      const archivo = localStorage.getItem(
        "llamadoAtencionRecibido-" + sha256(`${llamadoAtencion}-${cod_empleado}`).toString()
      )
      if (!archivo)
        return defer({
          usuarios,
          bodegas,
          dataAnterior,
        })
      const dataUpload = JSON.parse(archivo)
      // const dataUpload = [archivoJson]
      return defer({
        usuarios,
        bodegas,
        dataUpload,
        dataAnterior,
      })
    },
    action: ({ request, params }) => {
      const llamadoAtencion = AES.decrypt(decodeURIComponent(params.llamadoatencion ?? ""), llave).toString(Utf8)
      const cod_empleado = AES.decrypt(decodeURIComponent(params.empleado ?? ""), llave).toString(Utf8)
      const dataParams = {
        cod_solicitud: llamadoAtencion,
        cod_empleado: cod_empleado,
      }
      const actionPromiseReturn = sendAction("fapifsg-pr/recursos-humanos/marcar-recibidos", request, dataParams)
      localStorage.removeItem("llamadoAtencionRecibido-" + sha256(`${llamadoAtencion}-${cod_empleado}`).toString())
      return actionPromiseReturn
    },
  },
  {
    path: "/rrhh/llamados-de-atencion/bitacora-recibidos",
    element: <BitacoraRecibidos />,
  },
]
