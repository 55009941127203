import { Button } from "antd";
import { AiOutlineSetting } from "react-icons/ai";
import { useComponentChart } from "hooks/indicadores/useComponentChart";

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { LayoutType } from "recharts/types/util/types";

interface PropsChart {
  columnTable: any | PropsColumn[];
  grafica: string;
  tamaño: number;
  angulo: number;
  graph: string[];
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  dataKey?: string;
  opciones?: boolean;
  layout?: LayoutType;
  id?: string;
}

interface PropsColumn {
  key: string;
  fecha: string;
  trabajadores: number;
  entradas: number;
  salidas: number;
  "rotación %": string;
}

const ComponentStackedBarChart = ({
  columnTable,
  grafica,
  tamaño,
  angulo,
  graph,
  setShowModal,
  dataKey,
  opciones = true,
  layout = "horizontal",
  id = "myChart",
}: PropsChart) => {
  const { getBarColor } = useComponentChart();


  return (
    <div className='chart-container'>
      {opciones && (
        <Button
          type='default'
          icon={<AiOutlineSetting />}
          style={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
          onClick={() => setShowModal && setShowModal(true)}
        ></Button>
      )}
      <ResponsiveContainer width='100%' height={tamaño} id={id}>
        <BarChart
          layout={layout}
          width={500}
          height={255}
          data={columnTable}
          margin={{
            top: 5,
            right: 0,
            left: -20,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey={grafica} angle={angulo} textAnchor='end' height={60}  tick={{ fontSize: '12px' }} />
          <YAxis />
          <Tooltip />
          <Legend />
          {graph.map((g, index) => (
            <Bar key={g} dataKey={g} stackId="a" fill={getBarColor(index)} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ComponentStackedBarChart;
