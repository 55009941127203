import { loaderRoute } from "config/methods";
import { Principal } from "pages/contentprincipal/Principal";
import CumplimientoBilleteate from "pages/indicadores/CumplimientoBilleteate";
import { CumplimientoConvenios } from "pages/indicadores/CumplimientoConvenios";
import { RotacionEmpleados } from "pages/indicadores/RotacionEmpleados";
import VentasBilleteateProductos from "pages/indicadores/VentasBilleteateProductos";
import { VentasBodega } from "pages/indicadores/VentasBodega";
import { ProyeccionesBodega } from "pages/indicadores/proyecciones-bodega";
import { RouteObject } from "react-router-dom";
import { RankingSupervisores } from "pages/indicadores/RankingSupervisores";
import ComisionCuotasFarmacias from "pages/indicadores/ComisionCuotasFarmacias";
import BilleteatePV from "pages/indicadores/BilleteatePV";

export const rutasIndicadores: RouteObject[] = [
  {
    path: "/indicadores",
    element: <Principal />,
  },
  {
    path: "/indicadores/ventas-bodegas",
    element: <VentasBodega />,
  },
  {
    path: "/indicadores/rotacion-empleados",
    element: <RotacionEmpleados />,
  },
  {
    path: "/indicadores/cumplimiento-convenios",
    element: <CumplimientoConvenios />,
  },
  {
    path: "/indicadores/ranking-supervisores",
    element: <RankingSupervisores />,
  },
  {
    path: "/indicadores/comision-cuotas-farmacia",
    element: <ComisionCuotasFarmacias />,
  },
  {
    path: "/indicadores/billeteate",
    element: <CumplimientoBilleteate />,
    loader: () => loaderRoute(`apifsg-pr/reportes/bodega-billeteate-general`),
  },
  {
    path: "/indicadores/proyecciones-bodega",
    element: <ProyeccionesBodega />,
    children: [
      {
        path: "/indicadores/proyecciones-bodega/:periodo",
        lazy: async () => {
          let { MiddleProjection } = await import("pages/indicadores/proyecciones-bodega");
          return { Component: MiddleProjection };
        },
        loader: async ({ params }) => {
          const { periodo } = params;
          return loaderRoute(`fapifsg-pr/reportes/proyeccion-bodega/${periodo}`);
        },
      },
    ],
  },
  {
    path: "/indicadores/ventas-billeteate",
    element: <VentasBilleteateProductos />,
  },
  {
    path: "/indicadores/billeteatePv",
    element: <BilleteatePV />,
  },
];
