import { Row, Col, Input, Typography, Button, message } from "antd";

import { SaveOutlined } from "@ant-design/icons";
import { MdOutlineAddCircle } from "react-icons/md";

import { ContextModalForm } from "context/ModalFormContext";
import { ContextList } from "context/ButtonRightwithListContext";

import { useEditarReembolso } from "hooks/reembolsos/useEditarReembolso";

import { ButtonRightWithListWithModalForm } from "components/otros/ButtonRightWithListWithModalForm";

import { useParams } from "react-router-dom";
import type { Facturas, NotaVentas, Recibos } from "hooks/reembolsos/useModalFormCrearReembolso";

import styles from "pages/contentreembolsogastos/reembolso.module.css";
import AES from "crypto-js/aes";
import { llave } from "config/constants";
import Utf8 from "crypto-js/enc-utf8";

const { TextArea } = Input;

const { Title } = Typography;

export function EditarReembolso() {
  const { reembolso } = useParams();

  // const { data: dataUser } = useSWR<dataUser>('/datosUsuario')

  const {
    handleOkModal,
    handleOnClickActionList,
    ModificarReembolso,
    forms,
    JustificacionValue,
    setJustificacionValue,
    resetOkModal, //useEditarReembolso
    inputsState,
    modalProps,
    setModalProps,
    initialValues,
    resetFields, //useModalFormCrearReembolso
    lists,
    contentDocuments,
    actionsList, //useButtonRightListCrearReembolso
  } = useEditarReembolso();

  return (
    <>
      <Title level={2} className={styles.titleStyle}>
        Reembolso de Gastos
      </Title>
      <Row wrap className={styles.rowStyle}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <TextArea
            maxLength={250}
            placeholder='Justificación'
            autoSize={{ minRows: 1, maxRows: 10 }}
            value={JustificacionValue}
            onChange={(e) => {
              setJustificacionValue(e.target.value);
            }}
          />
        </Col>
      </Row>
      {["Factura", "Nota de venta", "Ticket"].map((title, index) => (
        <ContextModalForm
          inputsState={inputsState[index]}
          key={index}
          modalProps={modalProps[index]}
          updateModalProps={setModalProps[index]}
          onOk={handleOkModal[index]}
          form={forms[index]}
          initialValues={initialValues[index]}
          onCancel={() => {
            resetOkModal(index);
            resetFields[index]();
          }}
        >
          <ContextList
            iconButton={<MdOutlineAddCircle className='iconbutton' />}
            textButton={title}
            actionsList={actionsList}
            actionsForActionsList={handleOnClickActionList[index]}
            listData={lists[index].map(contentDocuments[index])}
          >
            <ButtonRightWithListWithModalForm />
          </ContextList>
        </ContextModalForm>
      ))}
      <Row className={styles.rowStyle}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Button
            block
            type='primary'
            icon={<SaveOutlined className='iconbutton' />}
            onClick={() => {
              if (lists[0].length === 0 && lists[1].length === 0 && lists[2].length === 0) {
                message.error("No hay datos para guardar");
                return;
              }
              const FacturasCopy: Facturas[] = JSON.parse(JSON.stringify(lists[0]));
              const NotaVentasCopy: NotaVentas[] = JSON.parse(JSON.stringify(lists[1]));
              const RecibosCopy: Recibos[] = JSON.parse(JSON.stringify(lists[2]));
              FacturasCopy.forEach((element) => {
                element.provincia = undefined;
              });
              NotaVentasCopy.forEach((element) => {
                element.provincia = undefined;
              });
              ModificarReembolso(
                JSON.stringify({
                  Cod_Reembolso: AES.decrypt(decodeURIComponent(reembolso ?? ""), llave).toString(
                    Utf8
                  ),
                  justificacion: JustificacionValue,
                  facturas: FacturasCopy,
                  notaventas: NotaVentasCopy,
                  recibos: RecibosCopy,
                }),
                { "api-token": "apitoken" }
              );
            }}
          >
            Guardar
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default EditarReembolso