import { BackTop, Col, Row, Table } from "antd"
import { ModalFranquiciado } from "components/franquiciados/modal-franquiciado"
import { useFranquiciadosDatos } from "hooks/Franquiciados/useFranquiciadosDatos"
import { ButtonAdd, ButtonExcel } from "components/otros/buttons"

export function FranquiciadosDatos() {
  const {
    setSelectedData,
    setOpenModal,
    selectedData,
    openModal,
    data,
    columns,
    reFetcher,
    pageSize,
    setPageSize,
    handleClickDownload,
    handleClickDownloadBodegas,
  } = useFranquiciadosDatos()

  return (
    <>
      <Row gutter={[16, 16]} style={{ padding: "16px 16px", marginLeft: 0, marginRight: 0, backgroundColor: "#fff" }}>
        <Col xs={24} sm={24} md={8} lg={7} xl={5}>
          <ButtonExcel buttonProps={{ onClick: handleClickDownloadBodegas }}>Lista de franquicias</ButtonExcel>
        </Col>
        <Col xs={24} sm={24} md={8} lg={7} xl={5}>
          <ButtonExcel buttonProps={{ onClick: handleClickDownload }}>Lista de franquiciados</ButtonExcel>
        </Col>
        <Col xs={0} sm={0} md={0} lg={3} xl={9} />
        <Col xs={24} sm={24} md={8} lg={7} xl={5}>
          <ButtonAdd
            buttonProps={{
              onClick() {
                setSelectedData(undefined)
                setOpenModal(true)
              },
            }}
          >
            Registrar Franquiciado
          </ButtonAdd>
        </Col>
      </Row>
      <Row wrap style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            size="small"
            dataSource={data}
            columns={columns}
            pagination={{
              pageSize: pageSize,
              pageSizeOptions: [5, 10, 20, 50, 100],
              size: "default",
              showSizeChanger: true,
              onShowSizeChange: (_, size) => setPageSize(size),
            }}
            scroll={{ x: true }}
            rowKey="value"
          />
        </Col>
      </Row>
      <ModalFranquiciado
        open={openModal}
        onClose={() => {
          setOpenModal(false)
          setSelectedData(undefined)
        }}
        data={data.find(({ value }) => value === selectedData)}
        afterFinish={reFetcher}
      />
      <BackTop />
    </>
  )
}

export default FranquiciadosDatos
