import { Form, type FormProps, Modal, Input, InputNumber, Select, Button, Switch } from "antd";
import { type DefaultOptionType } from "antd/lib/select";
import { url } from "config/constants";
import { type MenuType } from "hooks/configuraciones/useMenu";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useSwal } from "hooks/otros/useSwal";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import { useCallback, useEffect, useState } from "react";

import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

import styles from "components/configuraciones/modal.menu.module.css";

type ModalMenuProps = {
  open: boolean;
  onCancel(): void;
  data?: MenuType;
  onOk(): void;
};

type ValuesFormMenu = {
  menu: string;
  nivel: number;
  padre?: number;
  orden: number;
  enlace?: string;
  otros_enlaces?: string[];
  estado: boolean;
};

export function ModalMenu({ open, onCancel, data, onOk }: ModalMenuProps) {
  const [form] = Form.useForm<ValuesFormMenu>();

  const [padres, setPadres] = useState<DefaultOptionType[]>([]);

  const [disabledPadres, setDisabledPadres] = useState(true);

  const { isError } = useVerifyResponse();

  const { swalSuccess, swalError } = useSwal();

  const { optionsPost } = useLocalStorage();

  const cargarPadres = useCallback(
    async (nivel: number) => {
      try {
        const res = await fetch(`${url}configuraciones/padres`, optionsPost({ nivel }));
        if (isError(res)) throw new Error();
        const result = await res.json();
        setPadres(result);
      } catch (e) {
        swalError({ text: "No se pudo cargar los padres de la ruta" });
      }
    },
    [isError, swalError, optionsPost]
  );

  useEffect(() => {
    if (!data) return;
    const { menu, nivel, padre, orden, enlace, otros_enlaces, estado } = data;
    if (nivel > 0) {
      setDisabledPadres(false);
      cargarPadres(nivel);
    } else {
      setDisabledPadres(true);
      setPadres([]);
    }
    form.setFieldsValue({
      menu,
      nivel,
      padre: padre === 0 ? undefined : padre,
      orden,
      enlace,
      otros_enlaces,
      estado,
    });
  }, [data, form, cargarPadres]);

  const handleOkModal = async () => {
    try {
      await form.validateFields();
      form.submit();
    } catch (e) {
      console.error(e);
      // swalError({ text: "No se pudo guardar los datos del menú" })
    }
  };

  const handleFinishForm: FormProps<ValuesFormMenu>["onFinish"] = async (values) => {
    try {
      const ruta = data ? "editar" : "crear";
      const res = await fetch(`${url}configuraciones/${ruta}-menu`, optionsPost({ ...values, id: data?.key }));
      if (isError(res)) throw new Error();
      const text = await res.json();
      swalSuccess({ text });
      onOk();
    } catch (e) {
      swalError({ text: "No se pudo guardar los datos del menú" });
    } finally {
      form.resetFields();
      onCancel();
    }
  };

  return (
    <Modal
      open={open}
      transitionName=''
      title={data ? "Crear menú" : "Editar menú"}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      okText='Guardar'
      onOk={handleOkModal}
      style={{ top: "10px" }}
      width={750}
    >
      <Form
        form={form}
        onFinish={handleFinishForm}
        requiredMark='optional'
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        className='scrollableDiv'
      >
        <Form.Item name='menu' label='Menú' rules={[{ required: true, message: "Ingrese un nombre para el menú" }]}>
          <Input maxLength={50} />
        </Form.Item>
        <Form.Item name='enlace' label='Enlace'>
          <Input maxLength={100} addonBefore='https://gestion.fsg.ec/' />
        </Form.Item>
        <Form.Item
          name='nivel'
          label='Nivel'
          initialValue={0}
          rules={[{ required: true, message: "Ingrese el nivel del menú" }]}
        >
          <InputNumber
            min={0}
            max={5}
            onChange={(value) => {
              setDisabledPadres(!value);
              cargarPadres(value as number);
            }}
          />
        </Form.Item>
        <Form.Item name='padre' label='Padre' required={!disabledPadres}>
          <Select
            options={padres}
            showSearch
            optionFilterProp='label'
            getPopupContainer={() => document.getElementsByClassName("ant-modal-body")[0] as HTMLElement}
            disabled={disabledPadres}
          />
        </Form.Item>
        {data && (
          <Form.Item name='estado' label='Estado' valuePropName='checked' required>
            <Switch defaultChecked />
          </Form.Item>
        )}
        <Form.List name='otros_enlaces'>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  key={field.key}
                  required={false}
                  label={index === 0 ? "Otros enlaces" : ""}
                  wrapperCol={{ span: 18, offset: index > 0 ? 6 : undefined }}
                >
                  <Form.Item {...field} noStyle rules={[{ required: true, message: "Ingrese el enlace" }]}>
                    <Input maxLength={100} style={{ width: "88%" }} addonBefore='https://gestion.fsg.ec/' />
                  </Form.Item>
                  {fields.length > 0 ? (
                    <MinusCircleOutlined className={styles["dynamic-delete-button"]} onClick={() => remove(field.name)} />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item wrapperCol={{ span: 18, offset: 6 }}>
                <Button type='dashed' block onClick={() => add()} icon={<PlusOutlined />}>
                  Añadir enlace
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
}
