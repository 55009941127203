import { Row, Col, Form, Input, Select, Typography, Radio, Checkbox, Card, Divider, FormInstance } from "antd";
import { useFormularioActualizacion } from "../../../hooks/rrhh/actualizacion/useFormularioActualizacion";
import { useEffect, useState } from "react";
import { dataC } from "./MiInformacion";
import { Idiomas } from "./Idiomas";

export interface Props {
  form: FormInstance<any>;
}
export const FormularioDestrezas = ({ form }: Props) => {
  const { getTipoLicencia, getTipoVehiculo, getDestrezas } = useFormularioActualizacion();
  const [licencias, setLicencias] = useState<dataC[]>([]);
  const [vehiculos, setVehiculos] = useState<dataC[]>([]);
  const [destrezas, setDestrezas] = useState<dataC[]>([]);
  const [hidden, setHidden] = useState<boolean>(false);

  const handleCheckboxChange = (event: any) => {
    form.setFieldsValue({ maquinas_industriales: "" });
    if (event.target.checked) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  };

  useEffect(() => {
    const fnLicencia = async () => {
      let res = await getTipoLicencia();
      setLicencias(res.data);
    };

    const fnVehiculos = async () => {
      let res = await getTipoVehiculo();
      setVehiculos(res.data);
    };

    const fnDestrezas = async () => {
      let res = await getDestrezas();
      setDestrezas(res.data);
    };

    fnLicencia();
    fnVehiculos();
    fnDestrezas();
    setHidden(form.getFieldValue("no_maquinas"));
  }, [getTipoLicencia, getTipoVehiculo, getDestrezas, form]);

  return (
    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
      <Card title='Información de sus destrezas y actitudes' type='inner' size='small'>
        <Row wrap={true}>
          <Col xs={24} md={24} lg={24}>
            <Row wrap={true}>
              <Col xs={24} xl={12}>
                <Form.Item label='Vehículos que sabe manejar' style={{ width: "95%" }} name='tipo_vehiculos'>
                  <Checkbox.Group options={vehiculos} />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label='Tipo de licencia que tiene' style={{ width: "97%" }} name='tipo_licencias'>
                  <Select
                    mode='multiple'
                    placeholder='ESCOJA UN TIPO DE LICENCIA'
                    options={licencias}
                    showSearch
                    optionFilterProp='label'
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row wrap={true}>
              <Col xs={24} xl={12}>
                <Form.Item
                  name='es_computadora'
                  label={
                    <Typography.Text ellipsis={{ tooltip: "¿Sabes manipular una computadora?" }}>
                      ¿Sabes manipular una computadora?
                    </Typography.Text>
                  }
                  style={{ width: "98%" }}
                  rules={[{ required: true, message: "Escoja una opción" }]}
                >
                  <Radio.Group>
                    <Radio value={true}> Si </Radio>
                    <Radio value={false}> No </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Row wrap={true}>
                  <Col xs={17} xl={16}>
                    <Form.Item
                      label='Máquinas industriales que puede operar'
                      style={{ width: "99%" }}
                      name='maquinas_industriales'
                    >
                      <Input
                        style={{ width: "98%" }}
                        placeholder='Escriba las máquinas industriales que puede operar'
                        disabled={hidden}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={7} xl={8}>
                    <Form.Item name='no_maquinas' valuePropName='checked'>
                      <Checkbox onChange={handleCheckboxChange}>No aplica</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row wrap={true}>
              <Col xs={24} xl={12}>
                <Form.Item
                  name='programas_habilidades'
                  // valuePropName='programas_habilidades'
                  label={
                    <Typography.Text ellipsis={{ tooltip: "Programas o sistemas que domina" }}>
                      Programas o sistemas que domina
                    </Typography.Text>
                  }
                  style={{ width: "100%" }}
                >
                  <Checkbox.Group
                    // onChange={(e) => {
                    //   setCheckboxSeleccionados(e);
                    // }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "200px",
                    }}
                    options={destrezas}
                    // options={destrezas.map((destreza: any, index) => ({
                    //   label: (
                    //     <div key={index}>
                    //       {destreza.label}
                    //       <Form.Item
                    //         name={`nivel_${index + 1}`}
                    //         style={{
                    //           display: checkboxSeleccionados.includes(destreza.value) ? "block" : "none",
                    //           paddingTop: "5px",
                    //         }}
                    //         rules={[
                    //           {
                    //             required: checkboxSeleccionados.includes(destreza.value) ? true : false,
                    //             message: "Escoja una opción",
                    //           },
                    //         ]}
                    //       >
                    //         <Radio.Group options={nivelesHabilidadOb}></Radio.Group>
                    //       </Form.Item>
                    //     </div>
                    //   ),
                    //   value: destreza.value,
                    // }))}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item
                  name='otro_programa'
                  label='Algún otro programa o sistema que no esté incluido'
                  style={{ width: "99%" }}
                >
                  <Input style={{ width: "98%" }} placeholder='Escriba algun sistema o programa que no este incluido' />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Divider />
      <Card title='Información Idiomas' type='inner' size='small'>
        <Idiomas form={form} />
      </Card>
    </div>
  );
};
