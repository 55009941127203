import { Button, Col, Row, Select, Typography, Card, DatePicker } from "antd"
//import { PlusCircleOutlined, EyeOutlined } from "@ant-design/icons"

import TextArea from "antd/lib/input/TextArea"
import { useRegistroTareas } from "hooks/sistemas/useRegistroTareas"
import { useLayout } from "hooks/otros/useLayout"

const { Title } = Typography

const breadcrumbs = [{ Label: "Sistemas", Url: "/sistemas" }, { Label: "Registro de Tareas" }]

const openKeys = [breadcrumbs[0].Label]
const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`;

export function RegistroTareas() {
  useLayout(breadcrumbs, openKeys, selectKeySider);
  
  const {
    databodega,
    dataUsuarios,
    onchangeBodega,
    codBodega,codUsuario,
    dataIncidente,CodIncidente,
    onchangeSolicitante,onchangeTipoIncidente,
    dataSoporte, onchangeUsuarioSoporte, codUsuarioSoporte,
    disableFutureDates,
    handleFilter,
    onchangeDescripcion,
    valueDescripcion,
    onchagedate,
    valueFecha,
    btn_Guardar
  }=useRegistroTareas()

  return (
    <>
      <Row style={{ background: "white", padding: "5px" }} align="middle" justify="center">
        <Col xs={18} sm={18} md={12} style={{ paddingLeft: "20px" }}>
          <Title level={4}>Registro de Tareas para Soporte</Title>
        </Col>
        <Col xs={6} sm={6} md={12}>
          <Row justify="end">
            <Button type="primary" size="small" onClick={btn_Guardar}>Guardar</Button>
          </Row>
        </Col>
      </Row>
      <Card style={{height:'100%'}}>
        <Row style={{ background: "white" }}>
          <Col xs={24} sm={24} md={12} style={{ marginTop: "10px" }}>
            <Row style={{ paddingLeft: "5px" }}>
              <Col xs={6} sm={18} md={4}>
                {" "}
                <Title level={5}>Fecha</Title>
              </Col>
              <Col xs={18} sm={18} md={20}>
                <DatePicker style={{ width: "100%" }} disabledDate={disableFutureDates} onChange={onchagedate} value={valueFecha}/>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} style={{ marginTop: "10px" }}>
            <Row style={{ paddingLeft: "5px" }}>
              <Col xs={6} sm={6} md={4}>
                {" "}
                <Title level={5}>Farmacia</Title>
              </Col>
              <Col xs={18} sm={18} md={20}>
                <Select showSearch style={{ width: "100%" }} options={databodega} onChange={onchangeBodega} value={codBodega}
                filterOption={handleFilter}/>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ background: "white" }}>
          <Col xs={24} sm={24} md={12} style={{ marginTop: "10px" }}>
            <Row style={{ paddingLeft: "5px" }}>
              <Col xs={6} sm={18} md={4}>
                {" "}
                <Title level={5}>Solicitante</Title>
              </Col>
              <Col xs={18} sm={18} md={20}>
                <Select showSearch style={{ width: "100%" }}  options={dataUsuarios} value={codUsuario} onChange={onchangeSolicitante} filterOption={handleFilter}/>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} style={{ marginTop: "10px" }}>
            <Row style={{ paddingLeft: "5px" }}>
              <Col xs={6} sm={6} md={4}>
                {" "}
                <Title level={5}>Asignar a</Title>
              </Col>
              <Col xs={18} sm={18} md={20}>
                <Select style={{ width: "100%" }} onChange={onchangeUsuarioSoporte} value={codUsuarioSoporte} options={dataSoporte}/>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{marginTop:'8px', paddingLeft:'5px'}} align={"middle"}>
          <Col xs={6} sm={6} md={3} >
          <Title level={5}>Tipo de Incidente</Title>
          </Col>
          <Col  xs={18} sm={18} md={21}>
          <Select showSearch style={{width:'100%'}} options={dataIncidente} value={CodIncidente} onChange={onchangeTipoIncidente} filterOption={handleFilter}/>
          </Col>
        </Row>
          <Row style={{marginTop:'8px', paddingLeft:'5px'}}> <Title level={5}>Descripción</Title></Row>
        <TextArea  rows={10} style={{marginLeft:'5px'}}  value={valueDescripcion} onChange={(e)=>onchangeDescripcion(e)}/>
      </Card>
    </>
  )
}
