import { Card, Col, Row, Table, Button, Form, Input } from "antd";

import { SaveOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/es/form";
import { PdfViewer } from "./PdfViewer";
import { ImgViewer } from "./ImgViewer";
import { useVerArchivosEdit } from "hooks/rrhh/actualizacion/useVerArchivosEdit";

export interface PropArchivos {
  archivos: PropsLlaves[] | undefined;
  form: FormInstance<any>;
  setEstado2: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface PropsLlaves {
  key: number;
  llave: string;
  estado: number;
}

const { Meta } = Card;

export const VerArchivosEdit = ({ archivos, form, setEstado2 }: PropArchivos) => {
  const { columns, data, visible, url, setVisible, visibleImg, setVisibleImg, handleClickData, loading } =
    useVerArchivosEdit(archivos, form, setEstado2);

  return (
    <div className='cards-container'>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={24}>
          <Meta title='Gestionar Archivos' description=' ' />
          <Card style={{ minHeight: "350px" }} hoverable>
            <Table dataSource={data} columns={columns} pagination={false} size={"small"} scroll={{ x: "500px", y: 460 }} />
          </Card>
        </Col>
        <PdfViewer setVisible={setVisible} visible={visible} llave={url} />
        <ImgViewer setVisible={setVisibleImg} visible={visibleImg} llave={url} />
        <div style={{ display: "flex", width: "100%" }}>
          <Button
            type='primary'
            style={{
              // display: `${buttonDisabled}`,
              width: "99%",
            }}
            icon={<SaveOutlined className='iconbutton' />}
            loading={loading}
            onClick={() => handleClickData(form)}
          >
            GUARDAR CAMBIOS
          </Button>
        </div>
      </Row>
      <Form.Item name='cod_empleado' style={{ display: "none" }}>
        <Input type='hidden' />
      </Form.Item>
      <Form.Item name='id_luc' style={{ visibility: "hidden" }}>
        <Input type='hidden' />
      </Form.Item>
    </div>
  );
};
