import { url } from "config/constants";
import { swalError } from "config/methods";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useLayout } from "hooks/otros/useLayout";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import { useEffect, useCallback, useState } from "react";
import Swal from "sweetalert2";

export interface dataSelect {
  value: number;
  label: string;
}

interface DataTransfer {
  key: string;
  label: string;
  descripcion?: String;
}
interface PropsDatosBodegasBloqueadas {
  key: React.Key;
  nombreBodega: string;
}
interface PropsBodegasActivas {
  key: React.Key;
}

type DatoPrincipal = {
  key: React.Key;
  DescripcionBodega: string;
  datosActivos: PropsBodegasActivas[];
  datosBloqueados: PropsDatosBodegasBloqueadas[];
};

const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Control bodegas" }];
const openKeys = [breadcrumbs[0].Label];
export const useControlBodegas = () => {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);

  const [dataTransfer, setDataTransfer] = useState<DataTransfer[]>([]);

  const [productsKeys, setProductsKeys] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [datos, setDatos] = useState<DatoPrincipal[]>([]);

  let { optionsPost } = useLocalStorage();

  const { isError } = useVerifyResponse();

  useBreadCrumbs(breadcrumbs);

  const handleChange = (targetKeys: string[]) => {
    setProductsKeys(targetKeys);
  };
  /*const handleChangeFarmaciaSupervisor = useCallback(
    (value: number) => {
      //setLoading(true);
      const supervisorSeleccionado = datos.find((supervisor) => supervisor.value === value);
      if (supervisorSeleccionado && supervisorSeleccionado.todasBodegas) {
        // Transforma la información de las bodegas asignadas para que se ajuste al formato esperado por el TransferComponent
        const bodegasParaTransfer = supervisorSeleccionado.todasBodegas.map((bodega) => ({
          key: bodega.Cod_Bodega.toString(), // Asegúrate de que la key sea un string, si es necesario
          label: bodega.Descripcion,
          descripcion: bodega.Descripcion, // O cualquier otro dato que necesites mostrar
        }));
        let data: string[] = [];
        supervisorSeleccionado.bodegasAsignadasArray.forEach((element) => {
          data.push(element.Cod_Bodega.toString());
        });
        setDataTransfer(bodegasParaTransfer);

        setProductsKeys(data);
      } else {
        setDataTransfer([]);
      }

      //setLoading(false);
    },
    [datos, setDataTransfer]
  );*/
  const getBodegas = useCallback(async () => {
    //setDatos([]);
    try {
      const res = await fetch(url + "apifsg-pr/inventarios/obtener-estadobodegas-controlajuste", optionsPost({}));

      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      console.log(respuesta);
      if (res.ok) {
        const respuestas: DatoPrincipal[] = respuesta.data;
        if (respuestas.length > 0) {
          setDatos(
            respuestas.map(({ key, DescripcionBodega, datosActivos, datosBloqueados }) => ({
              key,
              DescripcionBodega,
              datosActivos,
              datosBloqueados,
            }))
          );
          const farmaciaSeleccionada = respuestas.find((bodega) => bodega.key === 2000);
          console.log(farmaciaSeleccionada);
          if (farmaciaSeleccionada && farmaciaSeleccionada.datosBloqueados) {
            //const bodegasa= JSON.parse(farmaciaSeleccionada.datosBloqueados);
            const datosConvertidos = JSON.parse(farmaciaSeleccionada.datosBloqueados.toString());
            const datosConvertidosActivos = JSON.parse(farmaciaSeleccionada.datosActivos.toString());
            console.log();
            const bodegasParaTransfer = datosConvertidos.map((bodega: any) => ({
              key: bodega.key.toString(),
              label: bodega.nombreBodega,
            }));

            const keysActivos = datosConvertidosActivos.map((bodega: any) => bodega.key.toString());
            console.log(keysActivos);
            setDataTransfer(bodegasParaTransfer);
            setProductsKeys(keysActivos);
          } else {
            setDataTransfer([]);
            setProductsKeys([]);
          }
        } else {
          setDatos([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      console.log(e);
      swalError({ text: "No se pudo obtener datos" });
      return { msg: "no_conexion", status: false };
    }
  }, [optionsPost, isError]);

  useEffect(() => {
    getBodegas();
  }, [getBodegas]);
  /*const handleChangeFarmacia = useCallback(
    (value: number) => {
      const farmaciaSeleccionada = datos.find((bodega) => bodega.datosActivos === value);
      if (farmaciaSeleccionada) {
        // Transforma la información de las bodegas asignadas para que se ajuste al formato esperado por el TransferComponent
        const bodegasParaTransfer = farmaciaSeleccionada.supervisoresNoAsignados.map((supervi) => ({
          key: supervi.Cod_Usuario.toString(), // Asegúrate de que la key sea un string, si es necesario
          label: supervi.Nombres_Rol,
          descripcion: supervi.Nombres_Rol, // O cualquier otro dato que necesites mostrar
        }));
        let data: string[] = [];
        farmaciaSeleccionada.supervisorAsignado.forEach((element) => {
          data.push(element.Cod_Usuario.toString());
        });
       
      } else {
        setDataTransfer([]);
      }

      //setLoading(false);
    },
    [datosBodegas, setDataTransfer]
  );*/
  const handleSendInfo = async () => {
    setLoading(true);
    try {
      const productsKeysInt = productsKeys.map((key) => parseInt(key, 10));
      console.log(productsKeysInt);
      const respuesta = await fetch(
        url + "apifsg-pr/inventarios/enviar-estadobodegas-controlajuste",
        optionsPost({
          datos: productsKeysInt,
        })
      );
      if (isError(respuesta)) throw new Error();
      let text = await respuesta.json();
      if (text["msg"] !== "err") {
        Swal.fire("Ok", "Datos guardados correctamente", "success");
        //cambiarDatos();
        getBodegas();
        setLoading(false);
      } else {
        setLoading(false);
        Swal.fire("Error", "Ocurrió un error al guardar los datos", "error");
      }
      /*if (values.buscarPor === "2" && productsKeys.length <= 0) {
          setLoading(false);
          Swal.fire("Error", "La lista a emparejar no puede estar vacía!", "error");
          throw new Error("Error , lista vacía!");
        } else {
          
          
        }*/
    } catch (e) {
      setLoading(true);
      console.log(e);
      swalError({ text: "No se pudo enviar los datos" });
      return { msg: "no_conexion", status: false };
    }
  };

  return {
    dataTransfer,
    handleChange,
    productsKeys,
    handleSendInfo,
    loading,
  };
};
