import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";
import { url } from "config/constants";

type InventarioReporte = {
  Mes: number;
  Provincia: string;
  Canton: string;
  Ciudad: string;
  Establecimiento: string;
  Descripcion: string;
  Dependiente: string;
  Tipo_Dato: string;
  Total_Devolucion: number;
  N_Devoluciones: number;
  Tipo: string;
  Total_Venta_Neta: number;
  N_Productos_Devueltos: 3;
  Fecha: string;
  key: React.Key;
};

const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Notas de crédito" }];

const openKeys = [breadcrumbs[0].Label];
export function useNotasCredito() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);
  const [mes, setMes] = useState<Moment>(moment());
  const [datosInicial, setDatos] = useState<InventarioReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<InventarioReporte[]>([]);
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<InventarioReporte>();

  const [estadoBoton, setBoton] = useState<boolean>(false);
  const { exportToExcel } = useExport();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();

  const getBodegas = useCallback(async () => {
    try {
      const primerDiaMes = mes.clone().startOf("month").format("DD/MM/YYYY");
      const ultimoDiaMes = mes.clone().endOf("month").format("DD/MM/YYYY");


      const res = await fetch(
        url + "apifsg-pr/inventarios/obtener_reporte_notas_credito",
        optionsPost({ mes: mes.format("YYYYMM"), fechaInicio: primerDiaMes, fechaFin: ultimoDiaMes })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        setBoton(true);
        const respuestas: InventarioReporte[] = respuesta.data;
        setDatos(
          respuestas.map(
            ({
              Mes,
              Provincia,
              Canton,
              Ciudad,
              Establecimiento,
              Descripcion,
              Dependiente,
              Tipo_Dato,
              Total_Devolucion,
              N_Devoluciones,
              Tipo,
              Total_Venta_Neta,
              N_Productos_Devueltos,
              Fecha,
              key,
            }) => ({
              Mes,
              Provincia,
              Canton,
              Ciudad,
              Establecimiento,
              Descripcion,
              Dependiente,
              Tipo_Dato,
              Total_Devolucion,
              N_Devoluciones,
              Tipo,
              Total_Venta_Neta,
              N_Productos_Devueltos,
              Fecha,
              key,
            })
          )
        );
      } else {
        setDatos([]);
        setBoton(false);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setDatos([]);
      setBoton(false);
      return { msg: "no_conexion", status: false };
    } finally {
    }
  }, [optionsPost, isError, mes]);

  useEffect(() => {
    getBodegas();
  }, [getBodegas]);

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datosInicial); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datosInicial.filter((item) => item.Descripcion.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datosInicial]);

  const columns: TableColumnsType<InventarioReporte> = [
    {
      title: "Farmacia",
      dataIndex: "Descripcion",
      key: "Descripcion",
      ...getColumnSearchPropsNewVisorReporte("Descripcion"),
    },
    {
      title: "Fecha",
      dataIndex: "Fecha",
      key: "Fecha",
      sorter: (a, b) => moment(a.Fecha, "YYYY/MM/DD HH:mm").unix() - moment(b.Fecha, "YYYY/MM/DD HH:mm").unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Canton",
      dataIndex: "Canton",
      key: "Canton",
    },
    {
      title: "Provincia",
      dataIndex: "Provincia",
      key: "Provincia",
    },
    {
      title: "Dependiente",
      dataIndex: "Dependiente",
      key: "Dependiente",
      ...getColumnSearchPropsNewVisorReporte("Dependiente"),
    },
    {
      title: "Tipo",
      dataIndex: "Tipo_Dato",
      key: "Tipo_Dato",
    },
    {
      title: "Total",
      dataIndex: "Total_Devolucion",
      key: "Total_Devolucion",
      sorter: (a, b) => a.Total_Devolucion - b.Total_Devolucion,
    },
    {
      title: "Cant",
      dataIndex: "N_Devoluciones",
      key: "N_Devoluciones",
    },
    {
      title: "Venta N",
      dataIndex: "Total_Venta_Neta",
      key: "Total_Venta_Neta",
    },
    {
      title: "N_Productos",
      dataIndex: "N_Productos_Devueltos",
      key: "N_Productos_Devueltos",
    },
  ];

  const handleClickDownloadData = () => {
    const texto = "detalle_notas_credito_" + mes.format("YYYYMM");

    exportToExcel(datosInicial ?? [], texto);
  };

  return {
    columns,
    datosInicial,
    mes,
    setMes,
    filteredData,
    handleClickDownloadData,
    estadoBoton,
  };
}
