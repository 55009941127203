import { Form } from "antd";
import { ColumnsType } from "antd/lib/table";
import { url } from "config/constants";
import { optionsPost } from "config/methods";
import { LayoutContext } from "context/LayoutContext";
import { UserContext } from "context/UserContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useLayout } from "hooks/otros/useLayout";
import { useCallback, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";

export interface PropsDataBilleate {
  laboratorio: string;
  comision: string;
}

interface PropRequest {
  msg: boolean;
  data: BilleteateLaboratorio[];
}

interface PropRequestProductos {
  msg: boolean;
  data: BilleteateLaboratorioProductos[];
}

interface BilleteateLaboratorio {
  key: number;
  laboratorio: string;
  comision: number;
  porcentajecomision: number;
}
interface BilleteateLaboratorioProductos {
  key: number;
  producto: string;
  comision: number;
  porcentajecomision: number;
}

// const breadcrumbs = [{ Label: "Billetéate Pv" }];

const breadcrumbs = [
  { Label: "Indicadores", Url: "/indicadores" },
  { Label: "Billetéate Pv", Url: "/indicadores/billeteatePv" },
];
const openKeys = [breadcrumbs[0].Label];
const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`;

export function useBilleteatePV() {
  const { userData } = useContext(UserContext);
  // const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  useLayout(breadcrumbs, openKeys, selectKeySider);
  const [form] = Form.useForm();
  const [dataBilleteate, setDataBilleteate] = useState<BilleteateLaboratorio[]>([]);
  const [dataBilleteateProductos, setDataBilleteateProductos] = useState<BilleteateLaboratorioProductos[]>([]);
  const [expandedData, setExpandedData] = useState<{
    [key: number]: { data: BilleteateLaboratorioProductos[] };
  }>({});

  // useEffect(() => {
  //   setOpenKeys(["Indicadores", "Indicadores"]);
  //   setSelectedKeys(["Indicadores/Billetéate Pv"]);
  // }, [setOpenKeys, setSelectedKeys]);

  useBreadCrumbs(breadcrumbs);

  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };

  const formateaCantidadEntero = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return totalCostoFormateado;
  };

  const headTableBilleate: ColumnsType<BilleteateLaboratorio> = [
    {
      title: "Laboratorio",
      dataIndex: "laboratorio",
      key: "laboratorio",
      width: "45%",
      align: "left",
      // className: "customTbl",
    },
    {
      title: "Total Comisión",
      dataIndex: "comision",
      key: "comision",
      width: "25%",
      align: "right",
      sorter: (a, b) => a.comision - b.comision,
      render: (_, record) => (
        <span style={{ color: record.comision === 0 ? "lightgray" : "black" }}>
          {"$ " + formateaCantidad(record.comision)}
        </span>
      ),
    },
    {
      title: "Proporción del Total",
      dataIndex: "porcentajecomision",
      key: "porcentajecomision",
      width: "25%",
      align: "right",
      sorter: (a, b) => a.porcentajecomision - b.porcentajecomision,
      render: (_, record) => (
        <span style={{ color: record.porcentajecomision === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.porcentajecomision) + " %"}
        </span>
      ),
    },
  ];

  const headTableBilleateProductos: ColumnsType<BilleteateLaboratorioProductos> = [
    {
      title: "Producto",
      dataIndex: "producto",
      key: "producto",
      width: "45%",
      align: "left",
      // className: "customTbl",
    },
    {
      title: "Total Comisión",
      dataIndex: "comision",
      key: "comision",
      width: "25%",
      align: "right",
      sorter: (a, b) => a.comision - b.comision,
      render: (_, record) => (
        <span style={{ color: record.comision === 0 ? "lightgray" : "black" }}>
          {"$ " + formateaCantidad(record.comision)}
        </span>
      ),
    },
    {
      title: "Proporción del Total",
      dataIndex: "porcentajecomision",
      key: "porcentajecomision",
      width: "25%",
      align: "right",
      sorter: (a, b) => a.porcentajecomision - b.porcentajecomision,
      render: (_, record) => (
        <span style={{ color: record.porcentajecomision === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.porcentajecomision) + " %"}
        </span>
      ),
    },
  ];

  const calcularTotales = () => {
    let total = 0; // Asumiendo que el tiempo se puede sumar directamente
    let porcentaje = 0;

    dataBilleteate.forEach((item) => {
      total += item.comision;
      porcentaje += item.porcentajecomision;
    });
    return {
      total: formateaCantidad(total),
      porcentaje: formateaCantidadEntero(porcentaje),
    };
  };

  const calcularTotalesProductos = (productos: BilleteateLaboratorioProductos[]) => {
    let total = 0; // Asumiendo que el tiempo se puede sumar directamente
    let porcentaje = 0;

    productos.forEach((item) => {
      total += item.comision;
      porcentaje += item.porcentajecomision;
    });

    return {
      total: formateaCantidad(total),
      porcentaje: formateaCantidad(porcentaje),
    };
  };

  const handleData = useCallback(async (id_usuario: number) => {
    try {
      // setSpinning(true);
      const respuesta: PropRequest = await fetch(url + "apifsg-pr/reportes/billeteatePV", optionsPost({ id_usuario })).then(
        (data) => data.json()
      );

      if (respuesta.msg) {
        setDataBilleteate(respuesta.data);
        // setLaboratorios(respuesta.data2);
      } else {
        Swal.fire("Error", "Ocurrio un error en la información", "error");
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
    // setSpinning(false);
  }, []);

  const handleDataProductos = useCallback(
    async (cod_laboratorio: number) => {
      try {
        // setSpinning(true);
        const respuesta: PropRequestProductos = await fetch(
          url + "apifsg-pr/reportes/billeteatePVDetalles",
          optionsPost({ cod_laboratorio, id_usuario: userData?.id })
        ).then((data) => data.json());

        setExpandedData((prevState) => ({
          ...prevState,
          [cod_laboratorio]: { data: respuesta.data },
        }));

        if (respuesta.msg) {
          setDataBilleteateProductos(respuesta.data);
          // setLaboratorios(respuesta.data2);
        } else {
          Swal.fire("Error", "Ocurrio un error en la información", "error");
          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        return { msg: "no_conexion", status: false };
      }
      // setSpinning(false);
    },
    [optionsPost]
  );

  const handleExpandChange = async (expanded: boolean, record: BilleteateLaboratorio) => {
    if (expanded) {
      await handleDataProductos(record.key);
      return;
    }
  };

  useEffect(() => {
    userData?.id && handleData(userData?.id);
  }, [handleData, userData?.id]);

  return {
    calcularTotalesProductos,
    handleExpandChange,
    form,
    headTableBilleate,
    dataBilleteate,
    calcularTotales,
    headTableBilleateProductos,
    dataBilleteateProductos,
    expandedData,
  };
}
