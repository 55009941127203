import {  Button, Descriptions, Progress, Row, Tabs, Tag} from "antd"
import { FilePdfOutlined } from '@ant-design/icons';
import { useEditarProcesos } from "hooks/procesos/useEditarProcesos"
import { PdfViewer } from "components/rrhh/componentsActualizacion/PdfViewer";

const EditarProcesos = () => {

  const {
    actividades,
    onChange,
    tabKey,
    porcentaje,
    nombreproceso,
    descripcionproceso,
    fechaCreacion,
    estadoProceso,
    tagColor,
    openModalPDF,
    previewPDF,
    setOpenModalPDF,
    handlePreviewPDF,
    pdfList
  } = useEditarProcesos()
  return (
    <div
      style={{padding:'15px', width:'100%'}}
      
    >
        
        <Row wrap style={{ padding: '10px', marginRight: 0, marginLeft: 0, backgroundColor: '#fff' }} gutter={[ 16, 16 ]}>
        
        <Descriptions
            title="Información del Proceso:"
            bordered 
            column={1} 
            size="small"
            labelStyle={{width:"200px"}}
            style={{width:'100%'}}
          >
            <Descriptions.Item label="Proceso N°:">{nombreproceso}</Descriptions.Item>
            <Descriptions.Item label="Decripción:">{descripcionproceso}</Descriptions.Item>
            <Descriptions.Item label="Fecha de Creación:">{fechaCreacion}</Descriptions.Item>
            <Descriptions.Item label="Estado:">
              <Tag style={{ fontSize: '14px',marginLeft: '0%', display: 'inline-block' } } 
								color={tagColor}>
									{estadoProceso}
							</Tag></Descriptions.Item>
            <Descriptions.Item label="Ver el diagrama del proceso:">
              <Button type="primary" shape="round" icon={<FilePdfOutlined />} 
                 onClick={() => handlePreviewPDF(pdfList[0])} >
                Flujo
              </Button>
            </Descriptions.Item>
          </Descriptions>
          <Progress percent={porcentaje} strokeColor= '#6A2831'/>
          <Tabs style={{ width:'100%'}} items={actividades} activeKey={tabKey} onChange={onChange}  ></Tabs>
        </Row>
            
        <PdfViewer 
        visible={openModalPDF} 
        setVisible={setOpenModalPDF} 
        llave={previewPDF} 
        />       
    </div>
  );
};

export default EditarProcesos