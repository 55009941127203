import { Button, Col, List, Row } from "antd";

import { useContext } from "react";

import { ListContext } from "context/ButtonRightwithListContext";

interface ButtonRightWithListProps {
  onClick: () => void;
}

export function ButtonRightWithList({ onClick }: ButtonRightWithListProps) {
  const { listData, iconButton, textButton, actionsList, actionsForActionsList, visibleButton } =
    useContext(ListContext);

  return (
    <>
      <Row
        gutter={[0, 16]}
        style={{ padding: "16px 16px", marginLeft: 0, marginRight: 0 }}
        justify='end'
      >
        {(visibleButton || visibleButton === undefined) && (
          <Col xs={24} sm={24} md={5} lg={5}>
            <Button type='primary' block onClick={() => onClick()} icon={iconButton}>
              {textButton}
            </Button>
          </Col>
        )}
      </Row>
      <Row gutter={[0, 16]} style={{ padding: "16px 16px", marginLeft: 0, marginRight: 0 }}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <List
            style={{ backgroundColor: "#fff" }}
            dataSource={listData}
            bordered
            itemLayout='vertical'
            // locale={{ emptyText: <Empty description = 'No hay datos' image={ Empty.PRESENTED_IMAGE_SIMPLE } /> }}
            // rowKey={item => item.Key}
            renderItem={(item, index) => (
              <List.Item
                actions={actionsList?.map(({ icon, text }) => (
                  <Button
                    type='link'
                    key={index}
                    icon={icon}
                    onClick={() =>
                      actionsForActionsList[text as keyof typeof actionsForActionsList](index)
                    }
                  >
                    {text}
                  </Button>
                ))}
              >
                {item}
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
}
