import { Button, Col,  Row, Tag, Tooltip, Typography } from "antd"
import Table, { ColumnsType } from "antd/lib/table"
import React, { useEffect, useState } from "react"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { optionsPost, swalQuestion, swalSuccess, swalWarning } from "config/methods"
import { url } from "config/constants"
import { DataDetSolicitud } from "./useSolicitudesFundas"
import Swal from "sweetalert2"

const { Title } = Typography

export interface DataAutorizacion {
  key: React.Key
  cod_bodega: number
  nombre_farmacia: string
  codusuario: number
  nombre_usuario: string
  fecha: Date
  num_farmacia: string
  total_paquete: number
  detalle: string[]
}

export function useAutorizacionFundas() {
  const [data, setData] = useState<DataAutorizacion[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [visibleButton, setVisibleButton] = useState(false)

  const columnssolicitud: ColumnsType<DataAutorizacion> = [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      title: (
        <>
          <Row>
            <Col md={24}>
              <Title style={{ fontSize: "14px" }}>Fecha</Title>
            </Col>
          </Row>{" "}
        </>
      ),
      dataIndex: "fecha",
    },
    {
      title: (
        <>
          <Row>
            <Col md={24}>
              <Title style={{ fontSize: "14px" }}>Farmacia</Title>
            </Col>
          </Row>{" "}
        </>
      ),
      dataIndex: "nombre_farmacia",
      align: "center",
    },
    {
      title: (
        <>
          <Row>
            <Col md={24} style={{ textAlign: "center" }}>
              <Title style={{ fontSize: "14px" }}>Solicitante</Title>
            </Col>
          </Row>{" "}
        </>
      ),
      dataIndex: "nombre_usuario",
    },
    {
      title: (
        <>
          <Row>
            <Col md={24}>
              <Title style={{ fontSize: "14px" }}>Total de Paquetes</Title>
            </Col>
          </Row>{" "}
        </>
      ),
      dataIndex: "total_paquete",
      align: "center",
      render: (total_paquete, key: DataAutorizacion) => {
        //console.log(key.detalle)
        let total = key.detalle.reduce((total, fila: any) => total + parseInt(fila.cantidad ? fila.cantidad : 0), 0)
        //console.log(total)
        return <>{total_paquete && <Tag color={"gold"}>{total}</Tag>}</>
      },
    },
    {
      title: (
        <>
          <Row>
            <Col md={24}>
              <Title style={{ fontSize: "14px" }}>Acción</Title>
            </Col>
          </Row>{" "}
        </>
      ),
      dataIndex: "",
      width: "129px",
      align: "center",
      render: (key: DataAutorizacion) => {
        return (
          <>
            <Tooltip title="Aprobar">
              <Button
                type={"primary"}
                size="small"
                style={{ marginRight: "2px", marginTop: "1px" }}
                onClick={() => btn_Actualiza(key)}
              >
                <CheckOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Rechazar">
              <Button
                type={"primary"}
                size="small"
                style={{ marginRight: "2px", marginTop: "1px" }} onClick={()=>btn_Rechazar(key)}
              >
                <CloseOutlined />
              </Button>
            </Tooltip>
          </>
        )
      },
    },
  ]

  const columnsdetsolicitud: ColumnsType<DataDetSolicitud> = [
    {
      title: (
        <>
          <Row>
            <Col md={24}>
              <Title style={{ fontSize: "12px" }}>N</Title>
            </Col>
          </Row>{" "}
        </>
      ),
      dataIndex: "key",
      align: "center",
    },
    {
      title: (
        <>
          <Row justify={"center"}>
            <Col md={24} style={{ textAlign: "center" }}>
              <Title style={{ fontSize: "12px" }}>Descripción</Title>
            </Col>
          </Row>{" "}
        </>
      ),
      dataIndex: "nombre_funda",
    },
    {
      title: (
        <>
          <Row>
            <Col md={24}>
              <Title style={{ fontSize: "12px" }}>Cantidad en Paquetes</Title>
            </Col>
          </Row>{" "}
        </>
      ),
      dataIndex: "cantidad",
      align: "center",
    },
  ]

  const getsolicitudfundas = async () => {
    const res = await fetch(`${url}fapifsg-pr/bodegas/Fundas/getsolicitudfundas`, optionsPost({ tipo: 2 })).then((data) =>
      data.json()
    )
    let newdata: any = []
    if (res) {
      newdata = Array.from(res).map((item: any, key: any) => ({
        key: item.Cod_Solicitud,
        cod_bodega: item.Cod_Bodega,
        nombre_farmacia: item.Descripcion_Sri,
        codusuario: item.Cod_Usuario,
        nombre_usuario: item.Nombres,
        fecha: item.Fecha,
        num_farmacia: item.Cod_Farmacia,
        total_paquete: item.total_paquetes,
        detalle:
          item.detalle !== null
            ? JSON.parse(item.detalle).map((item1: any, key1: any) => ({
                key: (1 + key1).toString(),
                cod_solicitud: item.Cod_Solicitud,
                cod_funda: item1.Cod_Funda,
                nombre_funda: item1.Descripcion,
                cantidad: item1.Cantidad,
              }))
            : [],
      }))
    }

    setData(newdata)
  }

  useEffect(() => {
    getsolicitudfundas()
  }, [])

  const btn_Actualiza = async (solicitud: DataAutorizacion) => {
    swalQuestion({
      text: "¿Esta seguro de Aprobar la solicitud de la " + solicitud.nombre_farmacia + " ?",
      icon: "warning",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let inputvacios = solicitud.detalle.filter((item: any) => !item.cantidad || item.cantidad === "0")
        console.log(inputvacios)
        if (inputvacios.length > 0) {
          swalWarning({ text: "Existen cantidades vacias" })
          return
        }
        console.log(solicitud)
        const res = await fetch(
          `${url}fapifsg-pr/bodegas/Fundas/actualiza-estado`,
          optionsPost({ estado: "AP", codsolicitudes: [solicitud], tipo:2 })
        )
        if (res.status === 200) {
          swalSuccess({ text: "Se guardó correctamente" })
          //setDataPdf([solicitud])
          getsolicitudfundas()
          //setopenModal(true)
        } else {
          swalWarning({ text: "Ocurrio un error por favor vuelva a intentarlo" })
        }
      }
    })
  }

  const btn_Rechazar=async(solicitud:DataAutorizacion)=>{
    const { value: motivo } = await Swal.fire({
      title: "Rechazar Autorización de Fundas",
      input: "textarea",
      inputLabel: "¿Esta seguro de rechazar la Autorización? Ingrese un motivo",
      inputPlaceholder: "Ingrese un motivo",
      showCancelButton: true,
      confirmButtonColor: "#6A2831",
      preConfirm: (value) => {
        if (!value.trim()) {
          Swal.showValidationMessage('Ingrese un motivo para rechazar la Solicitud de Autorización');
        }
        return value.trim();
      }
    });

    if (motivo) {
      const res = await fetch(
        `${url}fapifsg-pr/bodegas/Fundas/actualiza-estado`,
        optionsPost({ estado: 'RE', codsolicitudes: [solicitud], observacion:motivo, tipo: 1})
      )
      if(res.status===200){
        swalSuccess({text:"Se guardó correctamente"})      
        getsolicitudfundas()
      }else{
        swalWarning({text: "Ocurrio un error por favor vuelva a intentarlo"})
      }
     
    }
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    //console.log("selectedRowKeys changed: ", newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
    if (newSelectedRowKeys.length > 1) {
      setVisibleButton(true)
    } else {
      setVisibleButton(false)
    }
  }

  const btn_AprobarSeleccionados = async () => {
    const datasend = data.filter(
      (item: DataAutorizacion, key: any) => selectedRowKeys.includes(item.key) && item.cod_bodega !== null
    )
    const res = await fetch(
      `${url}fapifsg-pr/bodegas/Fundas/actualiza-estado`,
      optionsPost({ estado: "AP", codsolicitudes: datasend })
    )
    if (res.status === 200) {
      //const datos=data.filter((item:DataAutorizacion)=>selectedRowKeys.includes(item.key))
      //console.log(datos)
      //setDataPdf(datos)
      //setopenModal(true)
      setSelectedRowKeys([])
      swalSuccess({ text: "Se guardó correctamente" })
      //getsolicitudfundas()
      setVisibleButton(false)
    } else {
      swalWarning({ text: "Ocurrio un error por favor vuelva a intentarlo" })
    }
  }

  const rowSelection = {
    getCheckboxProps: (item: any) => ({ disabled: item.disabled }),
    selectedRowKeys,
    onChange: onSelectChange,
  }

  return {
    columnssolicitud,
    visibleButton,
    btn_AprobarSeleccionados,
    rowSelection,
    data,
    columnsdetsolicitud,
  }
}
