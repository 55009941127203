import { Card, Col, Form, Modal, Row, Select, Table } from "antd";
import { ButtonExcel } from "components/otros/buttons";
import { useNovedadesBodegas } from "hooks/productos/useNovedadesBodegas";
import { Suspense } from "react";
import { useVT } from "virtualizedtableforantd4";

const NovedadesBodegas = () => {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);

  const {
    dataTableTaskDetails,
    columnsTableDetailsTask,
    dataBodegas,
    dataTableTask,
    columnsTableTask,
    isModalOpen,
    handleOk,
    handleCancel,
    dataLaboratorios,
    dataNovedades,
    form,
    onChangeOptionBogedas,
    onChangeOptionLaboratorios,
    onChangeOptionTipos,
    handleClickDownloadNovedades,
  } = useNovedadesBodegas();
  return (
    <>
      <Card style={{ margin: "5px" }}>
        <Table
          pagination={false}
          loading={dataTableTask ? false : true}
          scroll={{ x: 900, y: "60vh" }}
          size='small'
          bordered
          title={() => "LISTADO DE TAREAS"}
          columns={columnsTableTask}
          dataSource={dataTableTask ? dataTableTask : []}
        />
      </Card>
      <Suspense>
        <Modal
          footer={false}
          transitionName=''
          title='Detalles de la tarea'
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={"90wh"}
        >
          <Form form={form}>
            <Row gutter={[10, 0]}>
              <Col xs={24} md={7}>
                <Form.Item label='Seleccione un laboratorio' name='cod_laboratorio'>
                  <Select showSearch optionFilterProp='label' options={dataLaboratorios} onChange={onChangeOptionLaboratorios}></Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item label='Seleccione una bodega' name='cod_bodega'>
                  <Select showSearch optionFilterProp='label' options={dataBodegas} onChange={onChangeOptionBogedas}></Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item label='Seleccione una novedad' name='cod_novedad'>
                  <Select showSearch optionFilterProp='label' options={dataNovedades} onChange={onChangeOptionTipos}></Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={4}>
                <ButtonExcel buttonProps={{ onClick: handleClickDownloadNovedades }}></ButtonExcel>
              </Col>
            </Row>
          </Form>
          <Table
            components={vt}
            pagination={false}
            loading={dataTableTaskDetails ? false : true}
            scroll={{ x: 900, y: "55vh" }}
            size='small'
            bordered
            columns={columnsTableDetailsTask}
            dataSource={dataTableTaskDetails ? dataTableTaskDetails : []}
            // rowClassName={(record, index) => {
            //   return record.llegoCaducado > 0 ? "row-red" : "";
            // }}
          />
        </Modal>
      </Suspense>
    </>
  );
};

export default NovedadesBodegas;
