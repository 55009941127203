import { Row, Col, Radio, Space, Checkbox, Tabs, Typography, Button, Table } from "antd"
import { DatatypeLibreta, useComponentLibreta } from "hooks/compras/useComponentLibreta"
import "./ComponentLibretaContactos.css"

const { Title } = Typography

export function ComponentLibretaContactos() {

  const {Proveedores, 
    SanGregorio}= useComponentLibreta()
  return (
    <>
      <Row
        style={{ borderStyle: "solid", borderColor: "#D5D8DC", borderWidth: "1px", borderRadius: "5px", paddingLeft: "5px" }}
      >
        <Row style={{width:'100vh'}}>
          <Col sm={24} md={24} xl={24} lg={24}><Title style={{ fontSize: "11px", marginTop:'2px' }}>Libreta de contactos</Title></Col>
        </Row>
        <Row justify="space-around" align="middle" >
          <Col sm={16} md={7}>
            <Checkbox style={{}}>
              <Title style={{ fontSize: "9px" }}>Enviar orden por correo</Title>
            </Checkbox>
          </Col>
          <Col
            sm={8}
            md={9}
            style={{
              borderStyle: "solid",
              borderColor: "#D5D8DC",
              borderWidth: "1px",
              borderRadius: "5px",
              paddingLeft: "5px",
              marginBottom: "1px",
            }}
          >
            <Row justify="center">
              <Title style={{ fontSize: "8px" }}>Visualizar como:</Title>
            </Row>
            <Row>
              <Radio.Group>
                <Space direction="horizontal">
                  <Radio value={1}>
                    <Title style={{ fontSize: "8px" }}>Proveedor</Title>
                  </Radio>
                  <Radio value={2}>
                    <Title style={{ fontSize: "8px" }}>Laboratorio</Title>
                  </Radio>
                </Space>
              </Radio.Group>
            </Row>
          </Col>
          <Col sm={8} xs={8} md={5} style={{ marginLeft: "5px" }}>
            <Row justify="start">
              <Button type="primary" size="small">
                Actualizar Contactos
              </Button>
            </Row>
          </Col>
        </Row>
        <Row style={{height:'153px', width:'100%', marginRight:'5px'}}>
        <Tabs  style={{borderStyle: "solid", borderColor: "#D5D8DC", borderWidth: "1px", borderRadius: "2px", paddingLeft: "5px", marginBottom:'1px', width:'100%'}}
        centered
          defaultActiveKey="1"
          items={[
            {
              label: `Proveedores`,
              key: '1',
              children: <><Table 
              scroll={{y:80}}
              //style={{height:'100px'}}
              columns={Proveedores as DatatypeLibreta[]}
              size="small"
              //rowSelection={[]}
              //dataSource={}
              pagination={false}
              
              /></>,
            },
            {
              label: `San Gregorio`,
              key: '2',
              children: <Table 
              scroll={{y:80}}
              //style={{height:'100px'}}
              columns={SanGregorio as DatatypeLibreta[]}
              size="small"
              //rowSelection={"0"}
              //dataSource={}
              pagination={false}
              />,
            }
          ]}
        />
        </Row>

      </Row>
    </>
  )
}
