import { Col, Row, Table, Card, Select, Button } from "antd";
import "moment/locale/es";

import moment from "moment";
import { ButtonExcel } from "components/otros/buttons";
import { useComisionCuotasFarmacias } from "hooks/indicadores/useComisionCoutasFarmacias";

export function ComisionCuotasFarmacias() {
  const {
    columns,
    filteredData,
    handleClickDownloadData,
    datosPeriodos,
    setMes,
    mes,
    setDataEstado,
    dataEstado,
    estadoBotones,
    handleChangeButton,
    setRowClassName,
    tipos,
    tipoComision,
    handleChangeComition,
    columnsOther,
    datos2,
    setDatos,
    setDatos2,
  } = useComisionCuotasFarmacias();

  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
        <Select
          defaultValue={tipoComision}
          style={{ width: 250, marginRight: "10px", marginTop: "0px" }}
          onChange={handleChangeComition}
          options={tipos}
        />
        <Select
          value={mes.format("YYYYMM")}
          defaultValue={mes.format("YYYYMM")}
          style={{ width: 150, marginRight: "10px", marginTop: "0px" }}
          onChange={(value) => {
            setDataEstado(false);
            setDatos([]);
            setDatos2([]);
            setMes(moment(value, "YYYYMM") ?? moment());
          }}
          options={datosPeriodos}
        />

        <Button
          type='primary'
          style={{ marginLeft: "5px" }}
          //icon={<PoweroffOutlined />}
          loading={estadoBotones}
          onClick={handleChangeButton}
        >
          Generar reporte
        </Button>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
        <Row gutter={[5, 5]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
          <Col xs={24} md={10}>
            {dataEstado && <ButtonExcel buttonProps={{ onClick: handleClickDownloadData }} />}
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        {dataEstado && (
          <Card>
            {tipoComision === 1 ? (
              <Table
                columns={columns}
                dataSource={filteredData}
                scroll={{ x: true }}
                rowClassName={setRowClassName}
                size='small'
                pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
              />
            ) : (
              <Table
                columns={columnsOther}
                dataSource={datos2}
                scroll={{ x: true }}
                //rowClassName={setRowClassName}
                size='small'
                pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
              />
            )}
          </Card>
        )}
      </Col>
    </Row>
  );
}

export default ComisionCuotasFarmacias;
