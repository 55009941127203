import { Button, Tag } from "antd"
import { useLayout } from "hooks/otros/useLayout"
import { useCallback, useEffect, useState } from "react"

import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons"

import { useScreenSize } from "hooks/otros/useScreenSize"

import type { TableColumnsType } from "antd"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSwal } from "hooks/otros/useSwal"
import { url } from "config/constants"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { useSearchTable } from "hooks/otros/useSearchTable"

export type GastosFijosPorBodega = {
  cod_bodega: number
  bodega: string
  cod_gasto: number
  valor: number
  estado: boolean
  fecha: string
}

export type TipoGasto = {
  key: number
  gasto: string
  valor: number
  fijo: boolean
  estado: boolean
  bodegas_asignadas: GastosFijosPorBodega[]
}

const breadcrumbs = [{ Label: "Franquiciados", Url: "/franquiciados" }, { Label: "Registro de gastos" }]

const openKeys = [breadcrumbs[0].Label]

export function useGastosFranquiciados() {
  const { width } = useScreenSize()

  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label)

  const [data, setData] = useState<TipoGasto[]>([])
  const [openModal, setOpenModal] = useState(false)
  const [openModalAsignarBodega, setOpenModalAsignarBodega] = useState(false)
  const [selectedData, setSelectedData] = useState<number>()
  const [selectedBodega, setSelectedBodega] = useState<number>()

  const { getColumnSearchProps } = useSearchTable<TipoGasto>()

  const { optionsGet } = useLocalStorage()

  const { swalError } = useSwal()

  const { isError } = useVerifyResponse()

  const reFetcher = useCallback(async () => {
    try {
      const result = await fetch(`${url}fapifsg-pr/franquiciados/listar-tipo-gastos`, optionsGet)
      if (isError(result)) throw new Error()
      const response = await result.json()
      setData(response)
    } catch (e) {
      swalError({ text: "No se pudo obtener la lista de los gastos registrados" })
    }
  }, [isError, optionsGet, swalError])

  useEffect(() => {
    reFetcher()
  }, [reFetcher])

  const columns: TableColumnsType<TipoGasto> = [
    {
      title: "Gasto",
      dataIndex: "gasto",
      key: "gasto",
      ...getColumnSearchProps("gasto"),
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      width: width < 465 ? "20%" : "15%",
    },
    {
      title: "Fijo",
      dataIndex: "fijo",
      key: "fijo",
      align: "center",
      render: (value: boolean, record) =>
        record.bodegas_asignadas.length > 0 ? (
          <Tag color="warning">Por bodega</Tag>
        ) : value ? (
          <Tag color="blue">Sí</Tag>
        ) : (
          <Tag color="default">No</Tag>
        ),
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      align: "center",
      render: (value: boolean) => (value ? <Tag color="success">Activo</Tag> : <Tag color="default">Inactivo</Tag>),
      filters: [
        {
          text: "Activo",
          value: true,
        },
        {
          text: "Inactivo",
          value: false,
        },
      ],
      onFilter: (value, record) => value === record.estado,
    },
    {
      title: "Editar",
      dataIndex: "key",
      key: "editar",
      align: "center",
      render: (value: number) => (
        <Button
          icon={<EditOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          block
          // style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          onClick={() => {
            setSelectedData(data.findIndex(d => d.key === value))
            setOpenModal(true)
          }}
        />
      ),
    },
    {
      title: "Asignar bodega",
      dataIndex: "key",
      key: "asignar",
      align: "center",
      render: (value: number) => (
        <Button
          icon={<PlusCircleOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          block
          // style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          onClick={() => {
            setSelectedData(data.findIndex(d => d.key === value))
            setOpenModalAsignarBodega(true)
          }}
        />
      ),
    },
  ]

  return {
    setSelectedData,
    setOpenModal,
    selectedData,
    openModal,
    data,
    columns,
    reFetcher,
    openModalAsignarBodega,
    setOpenModalAsignarBodega,
    selectedBodega,
    setSelectedBodega,
  }
}
