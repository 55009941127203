import { Col, Row, Table } from "antd"
import { ModalPerfiles } from "components/configuraciones/modal-perfiles"
// import { ModalMenu } from "components/configuraciones/modal-menu"
import { ButtonAdd } from "components/otros/buttons"

import { usePerfiles } from "hooks/configuraciones/usePerfiles"

export function Perfiles() {
  const {
    perfiles,
    columns,
    handleAddButton,
    perfilSelected,
    openModal,
    setOpenModal,
    setPerfilSelected,
    getPerfiles,
    pageSize,
    setPageSize,
  } = usePerfiles()

  return (
    <>
      <Row style={{ padding: "16px", marginLeft: 0, marginRight: 0 }} justify="end" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={5} lg={5} xl={5}>
          <ButtonAdd buttonProps={{ onClick: handleAddButton }}>Añadir Perfil</ButtonAdd>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            dataSource={perfiles}
            columns={columns}
            scroll={{ x: true }}
            size="small"
            pagination={{
              pageSize: pageSize,
              pageSizeOptions: [6, 10, 20, 50, 100],
              size: "default",
              showSizeChanger: true,
              onShowSizeChange: (_, size) => setPageSize(size),
            }}
          />
        </Col>
      </Row>
      <ModalPerfiles
        open={openModal}
        onCancel={() => {
          setOpenModal(false)
          setPerfilSelected(undefined)
        }}
        data={perfilSelected ? perfiles?.find(({ key }) => key === perfilSelected) : undefined}
        onOk={getPerfiles}
      />
    </>
  )
}
