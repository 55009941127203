import { url } from "../../config/constants"
// import type { Estados } from "types"
import Swal from "sweetalert2"
// import { useFetchInEffect } from "hooks/useFetch"
import { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react"
/*import { useScreenSize } from "hooks/useScreenSize"*/
import { useLocalStorage } from "../../hooks/otros/useLocalStorage"
import { FaCheck } from "react-icons/fa"
import { llave } from "config/constants"
import AES from "crypto-js/aes"
import Utf8 from "crypto-js/enc-utf8"
import { useParams } from "react-router-dom"
import { useLayout } from "hooks/otros/useLayout"
import type { RcFile, UploadFile } from "antd/lib/upload"


interface Actividad {
  key: string
  label: string
  description: string
  children: string | JSX.Element
  disabled: boolean
  estado: string
  cod_actividad_proceso_iniciado: number
}

const ESTADOSPROCESOS={
  'En Ejecución':'En Ejecución',
  'Concluida':'Concluidas',
  'Suspendida':'Suspendidas',
  'Rechazada':'Rechazadas'
}


const ESTADOSPROCESOSMINUSCULA={
  'En Ejecución':'en_ejecución',
  'Concluida':'concluidas',
  'Suspendida':'suspendidas',
  'Rechazada':'rechazadas'
}


const openKeys=['Procesos','Procesos/Ver Procesos']

export function useEditarProcesos() {

  const { procesos } = useParams()
  const [actividades, setActividades] = useState<Actividad[]>([])
  const { optionsPost } = useLocalStorage()
  const [tabKey, setTabKey] = useState<string>()
  const [porcentaje, setPorcentaje] = useState<number>(0)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [nombreproceso, setNombreproceso] = useState<string>("")
  const [descripcionproceso, setDescripcionproceso] = useState<string>("")
  const [fechaCreacion, setFechaCreacion] = useState<string>("")
  const [estadoProceso, setEstadoProceso] = useState<string>("")
  const [tagColor, setTagColor] = useState<string>("")
  const [ openModalPDF, setOpenModalPDF ] = useState<boolean>(false)
  const [ previewPDF, setPreviewPDF ] = useState('')  
  const [ pdfList, setPdfList ] = useState<Array<UploadFile>>([])
  /*===========================================
  Funcion para convertir archivos en base 64
  ===========================================
*/
const getBase64 = (file: RcFile): Promise<string> => (
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })
)
  const breadCrumbss=useMemo(() => [
    { Label: 'Procesos', Url:'Procesos'},
    { 
      Label: ESTADOSPROCESOS[estadoProceso as keyof typeof ESTADOSPROCESOS], Url: `/Procesos/${ESTADOSPROCESOSMINUSCULA[estadoProceso as keyof typeof ESTADOSPROCESOSMINUSCULA]}`
    },
    { Label: estadoProceso !== 'En Ejecución' ? "Ver tarea" : "Realizar Tarea"}
  ], [ estadoProceso ])
  
  const selectedKeys=`Ver Procesos/${ESTADOSPROCESOS[estadoProceso as keyof typeof ESTADOSPROCESOS]}`

  useLayout(breadCrumbss, openKeys, selectedKeys)

  
  const handlePreviewPDF = async (file: any) => {
    if (!file.url && !file.preview)
      file.preview = await getBase64(file.originFileObj as RcFile)
    setPreviewPDF(file.url || (file.preview as string))
    setOpenModalPDF(true)
  }

  const onChange = (value: string) => {
    setTabKey(value)
  }

  const changeRefresh = () => {
    setRefresh(state => !state)
  }

  const fillEditarProcesos = useCallback(async () => {
    fetch(
      url + "procesos/ver-actividades-departamentos",
      optionsPost({ cod_proceso_iniciado: AES.decrypt(decodeURIComponent(procesos ?? ""), llave).toString(Utf8) })
    )
      .then(res => res.json())
      .then(res => {
        if (res.msg === "ok") {

          const name = "aperturalocales_2023_12_07_16c54c6a-d327-467c-96d0-66524f58b6fa.pdf";
          const llave =  "aperturalocales_2023_12_07_16c54c6a-d327-467c-96d0-66524f58b6fa.pdf";
          const urlBase = "https://infofsg.com/allku/api/"; // Asegúrate de que esta es la URL base correcta

          const pdfList: UploadFile = {
            uid: 'pdf-1',
            name: name,
            url: `${urlBase}${llave}`,
            status: 'done',
            response: { llave }
          };

          setPdfList([pdfList]); // Asegúrate de que setPdfList espera un array


          setPorcentaje(res.data[0].porcentaje_completado)
          const arr = res.data.map((elemento: Actividad) => {
            const Componente = lazy(() => import(`components/procesos/${elemento.children}`))
            if (!elemento.disabled && elemento.estado === "PE") {
              setTabKey(elemento.key)
            } else if (!elemento.disabled && elemento.estado !== "PE") {
              setTabKey(elemento.key)
            }

            return {
              ...elemento,
              label: (
                <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "6px" }}>
                  {elemento.estado === "PR" && <FaCheck style={{ color: "green" }} />}
                  {elemento.estado === "PP" && <FaCheck style={{ color: "silver" }} />}
                  {elemento.label}{" "}
                </span>
              ),
              children: (
                <Suspense>
                  <Componente
                    estado={elemento.estado}
                    cod_actividad_proceso_iniciado={elemento.cod_actividad_proceso_iniciado}
                    setRefresh={changeRefresh}
                    isContentEnabled={false}
                  />
                </Suspense>
              ),
              disabled: elemento.disabled,
            }
          })
          setActividades(arr)
        } else
          Swal.fire({
            title: "Error de consulta",
            text: "Error al obtener la lista de procesos",
            icon: "error",
            timer: 2000,
            showConfirmButton: false,
          })
      })
      .catch(_ => {
        Swal.fire({
          title: "Sin Conexión",
          text: "No hay conexión con el servidor",
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        })
      })
  }, [procesos, optionsPost])

  const determineTagColor = (estado: string): string => {
    switch (estado) {
      case "En Ejecución":
        return "blue"
      case "Concluida":
        return "green"
      case "Suspendida":
        return "yellow"
      case "Rechazada":
        return "red"
      default:
        return "grey"
    }
  }

  useEffect(() => {
    (async () => {
      const respuesta = await fetch(
        url + "procesos/ver-info-proceso",
        optionsPost({ cod_proceso_iniciado: AES.decrypt(decodeURIComponent(procesos ?? ""), llave).toString(Utf8) })
      )
      const res = await respuesta.json()
      if (res.msg === "ok") {
        const formattedNumber = res.data[0].cod_proceso_iniciado.toString().padStart(3, "0")
        const resultString = `${res.data[0].nombre_proceso} N° ${formattedNumber}`
        setNombreproceso(resultString)
        setDescripcionproceso(res.data[0].nombre_proceso_iniciado)
        setFechaCreacion(res.data[0].fecha_inicio)
        setEstadoProceso(res.data[0].estado_descripcion)
        setTagColor(determineTagColor(res.data[0].estado_descripcion))
      } else
        Swal.fire({
          title: "Error de consulta",
          text: "Error al obtener la lista de procesos",
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        })
    })()
  }, [optionsPost, procesos])

  useEffect(() => {
    fillEditarProcesos()
  }, [fillEditarProcesos, refresh])

  return {
    actividades,
    onChange,
    tabKey,
    porcentaje,
    setRefresh,
    nombreproceso,
    descripcionproceso,
    fechaCreacion,
    estadoProceso,
    tagColor,
    openModalPDF,
    previewPDF,
    pdfList,
    handlePreviewPDF,
    setOpenModalPDF
  }
}
