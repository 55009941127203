import { Button, Col, DatePicker, Input, List, Row, Select, Table, Tag, Typography } from "antd"
import { useLayout } from "hooks/otros/useLayout"
import { useTareasEnPrceso } from "hooks/sistemas/useTareasEnPrceso"
import { RollbackOutlined,DeleteOutlined } from "@ant-design/icons"
import React from "react"
import TextArea from "antd/lib/input/TextArea"
const { Title } = Typography

const breadcrumbs = [
  { Label: "Sistemas", Url: "/sistemas" },
  { Label: "Tareas de Soporte", Url: "/sistemas/ver" },
  { Label: "En Proceso" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export function TareasEnProceso() {
  useLayout(breadcrumbs, openKeys, selectKeySider)

  const {
    dataTareas,
    columnsTareas,
    disabled,
    setdisabled,
    Codtarea,
    Fecha,
    Estado,
    Fecha_Inicio,
    Farmacia,
    usuario_asignado,
    usuario_solicita,
    descripcion_general,
    tipo_atencion,
    onchangeTipoAtencion,
    onchangeDescripcion,
    onchangeEstado,
    descripcion,
    btn_Agregar,
    dataTareasHis,
    incidente,
    getTareas,
    disabledButton,
    btn_Delete_his
  } = useTareasEnPrceso()
  return (
    <>
      {!disabled && (
        <>
          <Row style={{ background: "white", padding: "5px" }} align="middle" justify="center">
            <Col xs={24} sm={24} md={24} style={{ paddingLeft: "20px" }}>
              <Title level={4}>Tareas en Proceso</Title>
            </Col>
          </Row>
          <Row style={{ background: "white", width: "100%", padding: "5px" }} className="table-container">
            <Table style={{ width: "100%" }} columns={columnsTareas} dataSource={dataTareas} />
          </Row>
        </>
      )}
      {disabled && (
        <>
          <Row style={{ background: "white", padding: "5px" }} align="middle" justify="center">
            <Col xs={18} sm={18} md={12} style={{ paddingLeft: "5px" }}>
              <Title level={4}>Tarea Número: {Codtarea}</Title>
            </Col>
            <Col xs={6} sm={6} md={12}>
              <Row justify="end">
                <Button
                  type="default"
                  size="small"
                  onClick={() =>{ setdisabled(false); getTareas()}}
                  style={{ marginRight: "2px", marginBottom: "2px" }}
                >
                  <RollbackOutlined />
                  Regresar
                </Button>
              </Row>
            </Col>
          </Row>
          <Row style={{ background: "white", paddingInline: "5px" }}>
            <Col xs={12} md={6} sm={12} style={{ paddingLeft: "4px", marginTop: "8px" }}>
              <Row>Fecha de Registro</Row>
              <DatePicker style={{ width: "100%" }} disabled size="small" value={Fecha} />
            </Col>
            <Col xs={12} md={6} sm={12} style={{ paddingLeft: "4px", marginTop: "8px" }}>
              <Row>Estado</Row>
              <Select
                style={{ width: "100%" }}
                size="small"
                value={Estado}
                onChange={onchangeEstado}
                options={[
                  { value: "", label: "PENDIENTE", disabled: true },
                  { value: "EN PROCESO", label: "EN PROCESO", disabled:true },
                  { value: "FINALIZADO", label: "FINALIZADO" },
                ]}
              />
            </Col>
            <Col xs={12} md={6} sm={12} style={{ paddingLeft: "4px", marginTop: "8px" }}>
              <Row>Fecha de Inicio</Row>
              <DatePicker style={{ width: "100%" }} size="small" value={Fecha_Inicio} disabled />
            </Col>
            <Col xs={12} md={6} sm={12} style={{ paddingLeft: "4px", marginTop: "8px" }}>
              <Row>Farmacia</Row>
              <Input
                style={{ width: "100%", background: "white", color: "#303030" }}
                size="small"
                value={Farmacia}
                disabled
              />
            </Col>
          </Row>
          <Row style={{ background: "white", paddingInline: "5px" }}>
            <Col sm={24} md={12} xs={24} style={{ paddingLeft: "4px", marginTop: "8px" }}>
              <Row>Asignado a</Row>
              <Input size="small" style={{ background: "white", color: "#303030" }} value={usuario_asignado} disabled />
            </Col>
            <Col sm={24} md={12} xs={24} style={{ paddingLeft: "2px", marginTop: "8px" }}>
              <Row>Solicitado por</Row>
              <Input size="small" style={{ background: "white", color: "#303030" }} value={usuario_solicita} disabled />
            </Col>
          </Row>
          <Row  style={{ background: "white", paddingInline: "5px" }} >
            <Row style={{ paddingLeft: "4px", marginTop: "8px" }}>Tipo de Incidente</Row>
            <Input value={incidente} style={{background:'white', color:'#303030'}} disabled/>
          </Row>
          <Row style={{ background: "white", paddingInline: "5px" }}>
            <Col sm={24} xs={24} md={24} style={{ marginTop: "8px" }}>
              <Row>Descripción General</Row>
              <TextArea rows={2} style={{ background: "white", color: "#303030" }} value={descripcion_general} disabled />
            </Col>
          </Row>
          <Row style={{ background: "white", paddingInline: "5px", paddingTop: "10px" }}>
            <Title style={{ fontSize: "20px", marginLeft:'5px' }}>Historial</Title>
          </Row>
          <Row style={{ background: "white", paddingInline: "8px", paddingTop: "10px" }}>
            <Col xs={24} sm={24} md={24}>
              <Row
                style={{ border: "1px solid #D9D9D9", width: "100%", borderRadius: "5px", padding: "5px" }}
                align={"middle"}
              >
                <Col sm={16} md={6} xs={16}>
                  <Row>
                    <Title style={{ fontSize: "12px" }}>Tipo de Atención</Title>
                  </Row>
                  <Select
                    onChange={onchangeTipoAtencion}
                    value={tipo_atencion}
                    size="small"
                    style={{ width: "100%" }}
                    options={[
                      { value: "", label: "SELECCIONE UN TIPO DE ATENCIÓN" },
                      { value: "VIRTUAL", label: "VIRTUAL" },
                      { value: "PRESENCIAL", label: "PRESENCIAL" },
                    ]}
                  />
                </Col>
                <Col sm={8} md={18} xs={8}>
                  <Row justify="end">
                    <Button type="primary" onClick={btn_Agregar} disabled={disabledButton}>Agregar</Button>
                  </Row>
                </Col>
                <Row style={{ marginTop: "10px" }}>
                  <Row>
                    <Title style={{ fontSize: "12px" }}>Descripción</Title>
                  </Row>
                </Row>
                <TextArea onChange={(e)=>onchangeDescripcion(e)} value={descripcion}></TextArea>
              </Row>
            </Col>
          </Row>
          {dataTareasHis.length>0&&<><Row style={{ background: "white", paddingInline: "8px", paddingTop: "10px"}}>
            <List
              style={{width:'100%'}}
               itemLayout="vertical"
               size="small"
               dataSource={dataTareasHis}
               renderItem={item => (
                  <Row
                    style={{ border: "1px solid #D9D9D9", width: "100%", borderRadius: "5px", padding: "5px", marginBottom:'2px' }}
                    align={"middle"}
                  >
                    <Col sm={11} md={12} xs={11}>
                      <Row align={"middle"}  >

                        <Title style={{ fontSize: "12px", paddingTop:'0px', paddingRight:'4px'}}>Tipo de Atención: </Title><Tag color="blue"  style={{fontSize:'8px',}}>{item.Tipo_Atencion}</Tag>
                        </Row>
                    </Col>
                    <Col sm={13} md={12} xs={13}>
                      <Row align={"middle"} justify={"end"}>
                        <Col sm={17} md={18} xs={17}><Row><Title style={{ fontSize: "12px", paddingTop:'0px', paddingRight:'4px'}}>Fecha: </Title><label style={{fontSize:'12px'}}>{(item.Fecha_Registra).toString()}</label></Row></Col>
                        <Col><Button type="primary" onClick={()=>btn_Delete_his(item)}><DeleteOutlined /></Button></Col>
                        </Row>
                    </Col>
                    {/* <Col sm={10} md={18} xs={10}>
                      <Row justify="end">
                        <Button type="primary" onClick={btn_Agregar}>Agregar</Button>
                      </Row>
                    </Col> */}
                    <Row style={{ marginTop: "5px" }}>
                      <Row>
                        <Title style={{ fontSize: "12px" }}>Descripción</Title>
                      </Row>
                    </Row>
                    <TextArea style={{background:'white', color:'#303030'}} onChange={(e)=>onchangeDescripcion(e)} value={item.Descripcion} disabled></TextArea>
                  </Row>
               )}
            />
          </Row></>}
        </>
      )}
    </>
  )
}
