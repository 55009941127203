import { Collapse, Input } from "antd"
import { type ColumnsTableFormListType, TableFormList } from "components/otros/table-form-list"
import { Deudas, FormPagoContext } from "context/franquiciados/form-pago-context"
import { useContext } from "react"

type Props = {
  handleRemove(): void
}

export function ObtenerDeudas({ handleRemove }: Props) {
  const { loading } = useContext(FormPagoContext)

  const columnDeudas: ColumnsTableFormListType<Deudas>[] = [
    {
      title: "Descripción",
      name: "deuda",
      width: 250,
    },
    {
      title: "Monto",
      name: "monto",
    },
    {
      title: "Cuotas",
      name: "cuotas",
    },
    {
      title: "Descripción de la cuota",
      name: "texto_deuda",
      rules: [{ required: true, message: "Ingrese una descripción de la cuota" }],
      render: () => <Input.TextArea maxLength={200} autoSize />,
    },
    {
      title: "Cuota a pagar",
      name: "cuota",
    },
    {
      title: "Valor a pagar",
      name: "valor_a_pagar",
    },
  ]

  return (
    <Collapse defaultActiveKey={["1"]} style={{ marginBottom: "16px", padding: 0 }}>
      <Collapse.Panel key={"1"} header="Préstamos">
        <TableFormList columns={columnDeudas} nameList="deudas" loading={loading} canRemove onRemove={handleRemove} />
      </Collapse.Panel>
    </Collapse>
  )
}
