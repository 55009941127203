import { useParams } from "react-router-dom"
import {  llave } from 'config/constants'

import { url } from "../../config/constants";
// import type { Estados } from "types"

import { EditOutlined, LineChartOutlined } from '@ant-design/icons'

import { useNavigate } from "react-router-dom"

import Swal from "sweetalert2"
import AES from 'crypto-js/aes'
// import { useFetchInEffect } from "hooks/useFetch"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { LayoutContext } from "context/LayoutContext"
import { useScreenSize } from "../../hooks/otros/useScreenSize"
import { useBreadCrumbs } from "../../hooks/otros/useBreadCrumbs"
import { UserContext } from "context/UserContext"
import { useLocalStorage } from "../../hooks/otros/useLocalStorage";

interface procesos {
  cod_proceso: number
  cod_proceso_iniciado: number
  fecha: Date
  descripcion: string
  proceso: string
  porcentaje_completado:number
  n_realizar:number
}

const ESTADOSPROCESOS={
  'PE':'En Ejecución',
  'CO':'Concluidas',
  'SU':'Suspendidas',
  'RE':'Rechazadas'
}

export function useProcesos() {

  const {  optionsPost } = useLocalStorage();

  const navigate = useNavigate()

  const { estado: estadoUrl } = useParams()

  const { width } = useScreenSize()

  const isWeb = width >= 778
  
  useEffect(() => {
    estadoUrl !== 'en_ejecucion' && estadoUrl !== 'concluidas' && estadoUrl !== 'suspendidas'&& estadoUrl !== 'rechazadas' && navigate('/procesos/en_ejecucion')
  },[estadoUrl, navigate])

  const { userData: dataUser } = useContext(UserContext)
  const Id_Usuario=dataUser?.id
  const estado = estadoUrl === 'en_ejecucion' ? 'PE' : estadoUrl === 'concluidas' ? 'CO': estadoUrl === 'suspendidas' ? 'SU' : 'RE'

  const [ procesos, setProcesos ] = useState<procesos[]>([])

  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext)

  useBreadCrumbs(useMemo(() => [
    { Label: 'Procesos', Url: estado !== 'PE' ? '/procesos' : undefined},
    { 
      Label: ESTADOSPROCESOS[estado as keyof typeof ESTADOSPROCESOS] 
    }
  ], [ estado ]))

  useEffect(() => {
    setOpenKeys(['Procesos','Procesos/Ver Procesos'])
    estado === 'PE' && setSelectedKeys(['Ver Procesos/En Ejecución'])
    estado === 'CO' && setSelectedKeys(['Ver Procesos/Concluidas'])
    estado === 'SU' && setSelectedKeys(['Ver Procesos/Suspendidas'])
    estado === 'RE' && setSelectedKeys(['Ver Procesos/Rechazadas'])
  },[ setOpenKeys, setSelectedKeys, estado, isWeb ])
  
  const verificar_actions=(procesos: number)=>{
    const enc_proceso = encodeURIComponent(AES.encrypt(procesos.toString(),llave).toString())
    fetch(url +'procesos/ver-actividades-departamentos', optionsPost({"cod_proceso_iniciado": procesos})).then(res => res.json()).then(res => {
      if(res.msg === 'ok'){
          if (!res.data[0].disabled){
            navigate(`/editar-procesos/${enc_proceso}`)
          }
          else
          {
            Swal.fire({
              title: 'Aún no puedes acceder a este proceso',
              text: 'Quizás aún falten tareas por completar antes de realizar la suya',
              icon: 'warning',
              timer: 2000,
              showConfirmButton: false
              })
          }
      }
      else 
          Swal.fire({
          title: 'Error de consulta',
          text: 'Error al obtener la lista de procesos',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
          })
      }).catch(_ => {
      Swal.fire({
          title: 'Sin Conexión',
          text: 'No hay conexión con el servidor',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
      })
      })
  }

  const actions = (procesos: number) => {
    const enc_proceso = encodeURIComponent(AES.encrypt(procesos.toString(),llave).toString())
    //verificar_actions(procesos);
    return estadoUrl === 'en_ejecucion'|| estadoUrl === 'concluidas'
    ? [
        <EditOutlined key="edit" onClick={ () => verificar_actions(procesos)} style={{padding: '8px'}} />,
        <LineChartOutlined key="see" onClick={ () => navigate(`/ver-procesos/${enc_proceso}`)} style={{padding: '8px'}} />,
      ]
    : [
        <LineChartOutlined key="see" onClick={ () => navigate(`/ver-procesos/${enc_proceso}`)} style={{padding: '8px'}} />
      ]
  }


  const fillProcesos = useCallback(async () => {
    fetch(url +'procesos/ver-procesos-por-usuario', optionsPost({"Id_Usuario": dataUser?.id,"estado": estado})).then(res => res.json()).then(res => {
    if(res.msg === 'ok') setProcesos(res.data)
    else 
        Swal.fire({
        title: 'Error de consulta',
        text: 'Error al obtener la lista de procesos',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
        })
    }).catch(_ => {
    Swal.fire({
        title: 'Sin Conexión',
        text: 'No hay conexión con el servidor',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
    })
    })
},[ dataUser?.id,estado,optionsPost ])

function ActualizarDescripcion(texto:String, cod_proceso_iniciado:number) {
   fetch(url +'procesos/actualizar-descripcion-proceso', optionsPost({"descripcion":texto ,"cod_proceso_iniciado": cod_proceso_iniciado})).then(res => res.json()).then(res => {
    if(res.msg === 'ok') 
    {
      Swal.fire({
        title: 'Actualizado con éxito.',
        text: 'Se actualizó la descripción del proceso con éxito',
        icon: 'info',
        timer: 2000,
        showConfirmButton: false
        })
        fillProcesos();
    }
    else 
        Swal.fire({
        title: 'Error de consulta',
        text: 'Error al obtener la lista de procesos',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
        })
    }).catch(_ => {
    Swal.fire({
        title: 'Sin Conexión',
        text: 'No hay conexión con el servidor',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
    })
    })
}

  useEffect(() => {
    fillProcesos()
  },[ fillProcesos ])


  return {
    procesos,
    isLoading: procesos.length === 0,
    estado: estadoUrl,
    actions,
    ActualizarDescripcion,
    Id_Usuario
  }
}