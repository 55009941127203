import {
  Tooltip,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Tag,
} from "antd";
import { OptionType, useProductosBilleteate } from "hooks/productos/useProductosBilleteate";
import TransferComponent from "../../components/otros/TransferComponent";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
const { Option } = Select;

export const ProductosBilleteate = () => {
  const {
    isDelete,
    handleDelete,
    desAtc4,
    desAtc5,
    handleChange,
    dataCategoria,
    options,
    handleSearch,
    handleChangeProducto,
    productsKeys,
    dataTransfer,
    form,
    handleChangeAtc5,
    porcentajeVenta,
    valorCaja,
    setValorCaja,
    setPorcentajeVenta,
    handleSubmit,
    codProducto,
    disabledDate,
  } = useProductosBilleteate();

  return (
    <Card>
      <Form form={form}>
        <Row gutter={[5, 5]}>
          <Col xs={24}>
            <Form.Item
              label='Buscar Producto:'
              name='cod_producto'
              rules={[{ required: true, message: "Seleccione un producto" }]}
            >
              <Select
                showSearch
                placeholder='Escribe para buscar...'
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                notFoundContent={null}
                allowClear
                onChange={handleChangeProducto}
              >
                {options?.map((opcion: OptionType) => (
                  <Option
                    key={opcion.value}
                    value={JSON.stringify({ cod_producto: opcion.value, es_existe: opcion.es_existe })}
                    // value={opcion.value}
                    style={{
                      fontWeight: opcion.es_existe ? "bold" : "normal",
                      color: opcion.es_existe ? "rgb(106 40 49)" : "",
                    }}
                  >
                    {opcion.es_existe ? `${opcion.label} (BILLETÉATE)` : opcion.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[5, 5]}>
          <Col xs={24} md={6}>
            <Form.Item label='Fecha:' name='fecha' rules={[{ required: true, message: "Seleccione el periodo" }]}>
              <DatePicker.RangePicker style={{ width: "100%" }} disabledDate={disabledDate} />
            </Form.Item>
          </Col>
          <Col xs={24} md={4}>
            <Form.Item label='Valor Caja' name='valor_caja' rules={[{ required: true, message: "Escriba el valor caja" }]}>
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                addonBefore='$'
                value={valorCaja}
                onChange={(value) => setValorCaja(value)}
                disabled={porcentajeVenta !== null && porcentajeVenta !== 0}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={5}>
            <Form.Item
              label='Porcentaje Venta'
              name='porcentaje_venta'
              rules={[{ required: true, message: "Escriba el porcentaje venta" }]}
            >
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                addonAfter='%'
                value={porcentajeVenta}
                onChange={(value) => setPorcentajeVenta(value)}
                disabled={valorCaja !== null && valorCaja !== 0}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={5}>
            <Form.Item label='Categoría' name='categoria' rules={[{ required: true, message: "Seleccione la categoría" }]}>
              <Select options={dataCategoria}></Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={4}>
            <Popconfirm
              title='¿Estás seguro de que deseas eliminar este producto del plan billetéate?'
              onConfirm={() => handleDelete(codProducto)}
              okText='Sí'
              cancelText='No'
            >
              {isDelete && (
                <Button type='primary' icon={<DeleteOutlined />} style={{ width: "100%" }}>
                  Eliminar
                </Button>
              )}
            </Popconfirm>
          </Col>
        </Row>
        <TransferComponent
          dataTransfer={dataTransfer}
          handleChange={handleChange}
          titles={[
            <Tooltip title='Clasificación Atc 4'>
              <Tag color='success'>{desAtc4}</Tag>
            </Tooltip>,
            <Tooltip title='Clasificación Atc 5 FSG'>
              <Tag color='default'>{desAtc5}</Tag>
            </Tooltip>,
          ]}
          productsKeys={productsKeys}
        ></TransferComponent>
        <Divider style={{ margin: "10px" }} />
        <Row gutter={[5, 0]}>
          <Col xs={24}>
            <Form.Item
              label='Descripción de la categoría atc:'
              name='desc_atc'
              rules={[{ required: true, message: "La descripción es obligatoria" }]}
            >
              <Input placeholder='Descripción atc personalizada' onChange={handleChangeAtc5} value={desAtc5} />
            </Form.Item>
          </Col>
        </Row>
        <Button
          onClick={handleSubmit}
          type='primary'
          style={{
            width: "100%",
          }}
          // loading={loading}
          icon={<SaveOutlined className='iconbutton' />}
        >
          GUARDAR
        </Button>
      </Form>
    </Card>
  );
};
