import { url } from "config/constants";
import { Table } from "antd";
import { useCallback } from "react";

export type EditableTableProps = Parameters<typeof Table>[0];

export interface DataType {
  key: string;
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
  6: number;
  7: number;
  hasta: string;
}

export interface Item {
  key: string;
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
  6: number;
  7: number;
}

export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export function usePuntoVenta() {
  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: "Categoría 1",
      dataIndex: "0",
      width: "200px",
      editable: true,
      align: "center",
    },
    {
      title: "Categoría 2",
      dataIndex: "1",
      width: "200px",
      editable: true,
      align: "center",
    },
    {
      title: "Categoría 3",
      dataIndex: "2",
      width: "200px",
      editable: true,
      align: "center",
    },
    {
      title: "Categoría 4",
      dataIndex: "3",
      width: "200px",
      align: "center",
      editable: true,
    },
    {
      title: "Categoría 5",
      dataIndex: "4",
      width: "200px",
      align: "center",
      editable: true,
    },
    {
      title: "Categoría 6",
      dataIndex: "5",
      width: "200px",
      align: "center",
      editable: true,
    },
    {
      title: "",
      dataIndex: "",
      width: "200px",
      align: "center",
    },
    {
      title: "Venta Mínima",
      dataIndex: "6",
      width: "200px",
      align: "right",
      editable: true,
    },
    {
      title: "",
      dataIndex: "hasta",
      width: "0px",
      align: "center",
    },
    {
      title: "Venta Máxima",
      dataIndex: "7",
      width: "200px",
      align: "left",
      editable: true,
    },
  ];

  const getPuntoVentas = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "politicas/rangos_minimos", {
        method: "GET",
      }).then((res) => res.json());
      if (respuesta.msg) {
        const { data } = respuesta;
        return { data, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, []);

  const putRangosMinimosVentas = async (form: DataType) => {
    try {
      const respuesta = await fetch(url + "politicas/actualiza_rangos_minimos_ventas", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      }).then((res) => res.json());

      if (respuesta.ok) {
        return { data: respuesta.msg, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexión", status: false };
    }
  };

  return { getPuntoVentas, defaultColumns, putRangosMinimosVentas };
}
