import { Gantt, ViewMode } from "gantt-task-react"
import { Button, Col, Modal, Row, Table, Tag } from "antd"
import type { ColumnsType } from "antd/es/table"
import { Suspense, lazy, useEffect, useRef, useState } from "react"
import { useVerProceso } from "../../hooks/procesos/useVerProceso"
import "gantt-task-react/dist/index.css"
interface DataType {
  id: string
  name: string
  departamento: string
  asignado: string
  tags: string[]
  fecha_inicio: string
  fecha_fin: string
  duracion: string
  tiempo_estimado: string
}
const currentDate = new Date()

// 2023-08-09 14:06:25.432
// 2023-08-09 14:52:36.161
export const VerProcesos = () => {
  const { state, stateGantt } = useVerProceso()
  const [isContentEnabled, setIsContentEnabled] = useState(true);


  const columns: ColumnsType<DataType> = [
    {
      title: "Tarea",
      dataIndex: "name",
      key: "name",
      // render: (text,record) => record.tags[0]==='Ejecutado'? <a  href="#" onClick={()=>showModal(record)}>{text}</a>:text,
      render: (text, record) =>
      record.tags[0] === "Ejecutado" || record.tags[0] === "Ejecutado con atraso"
      ? (
          <Button
            style={{ textOverflow: "ellipsis", padding: "0", margin: "0" }}
            type="link"
            onClick={() => showModal(record)}
          >
            {text}
          </Button>
        ) : (
          <Button type="text" style={{ cursor: "default", padding: "0", margin: "0" }}>
            {text}
          </Button>
        ),
      ellipsis: {
        showTitle: true,
      },
      width: "15%",
      fixed: "left",
    },
    {
      title: "Asignado",
      dataIndex: "asignado",
      key: "asignado",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Departamento",
      dataIndex: "departamento",
      key: "departamento",
      width: "12%",
      ellipsis: true,
    },
    {
      title: "Estado",
      key: "tags",
      dataIndex: "tags",
      width: "8%",
      ellipsis: true,
      render: (_, { tags }) => (
        <>
          {tags.map(tag => {
            let color
            if (tag === "Atrasado") {
              color = "red"
            }
            if (tag === "Ejecutado") {
              color = "green"
            }
            if (tag === "Ejecutado con atraso") {
              color = "yellow"
            }
            if (tag === "En progreso") {
              color = "blue"
            }
            return (
              <Tag color={color} key={tag} style={{ padding: 0, margin: -10 }}>
                {tag.toUpperCase()}
              </Tag>
            )
          })}
        </>
      ),
    },
    {
      title: "Fecha inicio",
      dataIndex: "fecha_inicio",
      key: "fecha_inicio",
      width: "12%",
      ellipsis: true,
    },
    {
      title: "Fecha fin",
      dataIndex: "fecha_fin",
      key: "fecha_fin",
      width: "12%",
      ellipsis: true,
    },
    {
      title: "Duración",
      dataIndex: "duracion",
      key: "duracion",
      width: "12%",
      ellipsis: true,
    },
    {
      title: "Tiempo Estimado",
      dataIndex: "tiempo_estimado",
      key: "tiempo_estimado",
      width: "12%",
      ellipsis: true,
    },
  ]

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [nombre_formulario, setNombre_formulario] = useState<JSX.Element>()

  const showModal = (record: any) => {
    setIsModalOpen(true)    
    setIsContentEnabled(true); 
    const Componente = lazy(() => import(`components/procesos/${record.nombre_form}`))
    setNombre_formulario(
      <Componente estado={"PR"} cod_actividad_proceso_iniciado={record.cod_actividad_proceso_iniciado} setRefresh={false} isContentEnabled={isContentEnabled} />
    )
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const tableRef = useRef<HTMLDivElement>(null)
  const [rowHeight, setRowHeight] = useState(54.7) // Alto por defecto
  useEffect(() => {
    if (tableRef.current) {
      const rows = tableRef.current.querySelectorAll(".ant-table-row")
      if (rows && rows.length > 0) {
        setRowHeight(rows[0].clientHeight)
      }
    }
  }, [state, stateGantt])
  return (
    <div>
      <Row>
        <Col xs={24} md={12}>
          <Table
            ref={tableRef}
            columns={columns}
            dataSource={state}
            pagination={false}
            bordered
            rowKey={"id"}
            rowClassName={(record, index) => (index % 2 === 0 ? "even-row" : "odd-row")}
            scroll={{ x: "calc(700px + 50%)" }}
          />
        </Col>
        <Col xs={24} md={12}>
          {stateGantt.length > 0 && (
            <Gantt
              locale={"es"}
              viewDate={currentDate}
              tasks={stateGantt}
              todayColor="rgba(144, 238, 144, 0.3)"
              viewMode={ViewMode.Day}
              columnWidth={120}
              headerHeight={55}
              rowHeight={rowHeight}
              listCellWidth={""}
            />
          )}
        </Col>
      </Row>

      <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={"100%"}>
        <Suspense fallback={<></>}>{nombre_formulario}</Suspense>
      </Modal>
    </div>
  )
}
