import { Button, Card, Col, Form, Row, Select, Table } from "antd"

import { useCambiosPrecios } from "hooks/productos/useCambioPrecios"
import styles from "./ProductosIncentivos.module.css"
import { SaveOutlined } from "@ant-design/icons"

export const CambiosPrecios = () => {
  const {
    onChangeSelectKey,
    valueSelect,
    selectKey,
    options,
    dateTable,
    head,
    spinning,
    changeSelect,
    laboratorios,
    sendInfo,
    changeLaboratorio,
    form,
    loading,
  } = useCambiosPrecios()
  return (
    <Card className={styles.card}>
      <Form form={form}>
        <Row gutter={[10, 10]}>
          <Col md={12} xs={24}>
            <Form.Item label="Variación de Precio" initialValue={0} name="tipo_variacion">
              <Select options={options} onChange={changeSelect} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label="Laboratorios" initialValue={0} name="cod_lab">
              <Select options={laboratorios} onChange={changeLaboratorio} />
            </Form.Item>
          </Col>
        </Row>

        <Table
          columns={head}
          loading={spinning}
          size="small"
          bordered
          dataSource={dateTable}
          pagination={false}
          scroll={{ x: 900, y: "64vh" }}
          rowSelection={{
            selectedRowKeys: selectKey,
            onChange: onChangeSelectKey,
            getCheckboxProps: () => ({
              disabled: valueSelect === 1 && true,
            }),
          }}
        />
      </Form>
      {valueSelect === 0 && (
        <Button
          onClick={sendInfo}
          type="primary"
          style={{
            width: "100%",
            marginTop: "10px",
          }}
          loading={loading}
          icon={<SaveOutlined className="iconbutton" />}
        >
          GUARDAR
        </Button>
      )}
    </Card>
  )
}
