import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useEffect, useContext, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import { Form } from "antd";

import { useVerifyResponse } from "hooks/otros/useVerifyReponse";

interface PropRequest {
  msg: boolean;
  data: PropsCrucePagos[];
}

interface PropsSelect {
  value: number;
  label: string;
  orden: number;
}

interface PropsCrucePagos {
  key: number;
  proveedor: string;
  descripcion: string;
  tipoPago: string;
}

const options = [
  {
    value: 0,
    label: "PENDIENTES",
  },
  {
    value: 1,
    label: "APROBADOS",
  },
];

const breadcrumbs = [{ Label: "Cruces de cobros" }];

export const useCobrosCruces = () => {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const [dataCobros, setDataCobros] = useState<PropsCrucePagos[]>([]);
  const { wasInvalidToken, hadError } = useVerifyResponse();

  const [form] = Form.useForm();

  let { optionsPost, optionsGet } = useLocalStorage();

  useBreadCrumbs(breadcrumbs);

  useEffect(() => {
    setOpenKeys(["Convenios", "Convenios/Administrar"]);
    setSelectedKeys(["Administrar/Cruce de cobros"]);
  }, [setOpenKeys, setSelectedKeys]);

  // const listarCobros = useCallback(async () => {
  //   try {
  //     const res = await fetch(`${url}${dataUrl}`, optionsGet);

  //     const response: PropRequest = await res.json();

  //     if (wasInvalidToken(response)) return;
  //     if (hadError(response, "No se pudo obtener los convenios")) return;

  //     const { data } = response;

  //     setDataCobros(data ? data : []);
  //   } catch (e) {
  //     swalError({ text: "No se pudo obtener los convenios" });
  //   }
  // }, [optionsGet, wasInvalidToken, hadError, swalError]);

  const headTablePayment: ColumnsType<PropsCrucePagos> = [
    {
      title: "Proveedor",
      dataIndex: "proveedor",
      key: "proveedor",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
    },
    {
      title: "Tipo Pago",
      dataIndex: "tipoPago",
      key: "tipoPago",
    },
    {
      title: "Acción",
      // dataIndex: "precio_nuevo",
      // key: "precio_nuevo",
      // align: "right",
      // width: "130px",
    },
  ];

  return {
    headTablePayment,
    form,
    dataCobros,
  };
};
