import { LatLngExpression } from "leaflet";
import { Maps } from "../../maps/Maps";
import MapsViews from "../../maps/MapsViews";

export interface PropsMaps {
  ubicacion?: React.MutableRefObject<LatLngExpression | null>;
  flag?: number;
  coordenadas?: string;
}

export const CroquisMap = ({ ubicacion, flag, coordenadas }: PropsMaps) => {
  return (
    <>
      {flag === 0 ? (
        <h3 style={{ textAlign: "center", color: "var(--primary)" }}>
          Seleccione la ubicación de su domicilio (Al seleccionar un punto en el mapa se guarda su
          ubicación)
        </h3>
      ) : (
        <h3 style={{ textAlign: "center", color: "var(--primary)" }}>
          Esta es la ubicación de la vivienda del empleado
        </h3>
      )}
      {flag === 0 ? <Maps ubicacion={ubicacion} /> : <MapsViews coordenadas={coordenadas} />}
    </>
  );
};
