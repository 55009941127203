import React, { useEffect, useState} from "react"
import Button from "antd/es/button"
import type { TransferDirection } from "antd/es/transfer"
import { SaveOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons"
import type { ColumnsType } from "antd/es/table/interface"
import { Input } from "antd"
import { url } from "config/constants"
import { optionsPost, optionsGet, swalSuccess, swalWarning, swalError, swalQuestion } from "config/methods"



interface RecordType {
  key: string
  title: string
  description: string
  disabled: boolean
  tag: string
  cantidad: string
  codtipouniforme: string
  valor: string
}

export interface DataType {
  key: string
  title: string
  description: string
  disabled: boolean
  tag: string
  cantidad: string
  codtipouniforme: string
  valor: string
}

let mockData: RecordType[]
let getitemselect:any=[{value:"", label: "seleccione un kit"}]

export function useCrearKit() {
  const [tableData, setTableData] = useState(mockData);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedText, setSelectedText] = useState(""); 
  const [count, setCount]=useState(0);
  const [itemselect, setitemSelect]=useState(getitemselect)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenKit, setIsModalOpenKit] = useState(false)
  const [value, setValue] = useState("")
  const [valor, setValor] = useState("")
  const [valueKit, setValueKit] = useState("")
  const [codTipoUniforme, setCodTipoUniforme] = useState("0")
  const [targetKeys, setTargetKeys] = useState<string[]>([])
  const [TipoUniformemsg, setTipoUniformemsg] = useState(false)
  const [Kitmsg, setKitmsg] = useState(false)

    useEffect(()=> {
      const getTipoUniforme = async () => {
          const res = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/getTipoUniforme`, optionsGet()).then((data) => data.json())
          const newdata=Array.from(res.data).map((item: any, key:any)=>(
            {
            key: key.toString(),
            title: item.Nombre,
            description: item.Nombre,
            disabled:false,
            tag: item.Nombre,
            cantidad: "0",
            codtipouniforme: item.Cod_Tipo_Uniforme,
            valor: item.Valor
          }
          ))
          setTableData(newdata)
      }

      const getKit = async () => {
        const res = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/getKit`, optionsGet()).then((data) => data.json())
        let respuesta=res.data;
        let newget =[{value:"", label: "seleccione un kit"}]
        if(respuesta.length>0){
        respuesta.forEach((item: any)=>{  
        let add={
          value: item.Cod_Kit,
          label: item.Nombre,
        }
        newget= [...newget, add];
        }
        )}
        setitemSelect(newget)
      }
      
      getTipoUniforme()
      getKit()

    },[]);

  const handleInputChange = (e : any, key:any) => {
    let newValue = e.target.value;
    
    const updatedData = tableData.map((item) => {
      if (item.key === key) {
        if (/^[0-9]{0,10}$/.test(newValue)) {
        return { ...item, cantidad: newValue};
      }
    }
      return item;
    });
    setTableData(updatedData);
  };

  const rightTableColumns: ColumnsType<DataType> = [
    {
      dataIndex: "title",
      title: "Nombre",
      key: "title",
    },
    {
      dataIndex: "cantidad",
      title: "Cantidad",
      render: (cantidad, key) =>{
        return(<Input  maxLength={2} onChange={(e) => handleInputChange(e, key.key)} value={cantidad}/>)}, //<Button icon={<DeleteOutlined />}></Button>,
    },
    {
      dataIndex: "",
      title: "",
      width: 50,
      render: (cantidad, key) =>{
        return(key.disabled&&<Button icon={<DeleteOutlined />} onClick={()=>deleteKitTipoUniforme(key)}></Button>)}, //<Button icon={<DeleteOutlined />}></Button>,
    }
  ]

  const leftTableColumns: ColumnsType<DataType> = [
    {
      dataIndex: "title",
      title: "Nombre",
    },
    {
      dataIndex: "acciones",
      title: "Acción",
      align: "right",
      render: (item, key) => {
       
        return (
          <>
            <Button icon={<EditOutlined />}  onClick={()=>editTipoUniforme(key)}></Button>
            <Button icon={<DeleteOutlined />} onClick={()=>deleteTipoUniforme(key)}></Button>
          </>
        )
      },
    },
  ]
  
  const handleInputValor = (e : any) => {
    let newValue = e.target.value;
      if (/^\d*\.?\d{0,2}$/.test(newValue)) {
        setValor(newValue)
      }
  };

  const editTipoUniforme=(key: any)=>{
    setIsModalOpen(true);
    setValue(key.description);
    setValor(key.valor);
    setCodTipoUniforme(key.codtipouniforme);
  }

  const deleteTipoUniforme=async(key:DataType)=>{
    swalQuestion({ text: "¿Esta seguro de Eliminar "+key.title+" de Tipo Uniforme ?",
    icon: "warning",
    showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {

        const cantidad = await fetch(
          `${url}fapifsg-pr/rrhh/Uniforme/cantidadtipouniforme`,
          optionsPost({ cod_tipo_uniforme: key.codtipouniforme})
        ).then((data) => data.json())
        if(cantidad[0].cantidad>0){
          swalWarning({text:"El Tipo de Uniforme no se puede Eliminar, porque se encuentra asignado a un kit"})
          return
        }
        const res = await fetch(
          `${url}fapifsg-pr/rrhh/Uniforme/saveTipoUniforme`,
          optionsPost({ cod_tipo_uniforme: key.codtipouniforme, nombre: key.description, estado: "AN", valor: key.valor})
        )
        //console.log(tableData);
        if(res.status===200){
          const newData=tableData.filter(item=> item.key!==key.key)
          setTableData(newData)
          swalSuccess({text: "Se eliminó correctamente"})
        }
      }
    });

  }

  const deleteKitTipoUniforme=async(key: any)=>{
    //console.log(key);
    swalQuestion({ 
        text: "¿Esta seguro de Eliminar "+key.title+" del Kit "+selectedText+"?",
        icon: "warning",
        showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await fetch(
          `${url}fapifsg-pr/rrhh/Uniforme/deleteKitTipoUniforme`,
          optionsPost({ cod_tipo_uniforme: key.codtipouniforme, cod_kit: selectedValue, estado: "AN"})
        )
        if(res.status===200){
          const newData=targetKeys.filter(item=> item!==key.key)
          getdetallekit(selectedValue);
          setTargetKeys(newData)
          swalSuccess({text:"Se eliminó con éxito"});
        }else{
          swalError({text:"Ocurrio un Error"})
        }
      }
    });
  }

  const onChange = (nextTargetKeys: string[]) => {
    setTargetKeys(nextTargetKeys)
    const NewData = tableData.map((item:any)=> {
      if(!nextTargetKeys.includes(item.key as string)){
        return{ ...item, cantidad: "0", disabled: false}
      } 
      return item
    })
    setTableData(NewData);    
  }

  const getdetallekit=async(value:string)=>{
    let keyselect:any=[]
    let newarray:any=[]
    let valueselect=+value
    if (value!=="" && valueselect>0){
    const res = await fetch(
      `${url}fapifsg-pr/rrhh/Uniforme/getDetalleKit/${value}`,
      optionsGet()
    ).then((data) => data.json())
    newarray=tableData.map((item)=>{ 
      let respuesta:any=item;
      res.map((item1:any) => {
        //console.log(item);
        if(item.codtipouniforme===item1.Cod_Tipo_Uniforme){
          keyselect.push(item.key);
          //console.log(item)
          respuesta= { ...item, cantidad: item1.Cantidad, disabled: true};
        }else if(!keyselect.includes(item.key as string)){
          respuesta= { ...item, cantidad: "0", disabled: false}
        } 
        return respuesta
      });
      return respuesta;
    })
    }else{
      newarray=tableData.map((item)=>{ 
        return{ ...item, cantidad: "0", disabled: false}
      })
    }
     setTableData(newarray);
     setTargetKeys(keyselect)
     //onChange(keyselect);
  }

  const handleChange = (value: string, label: any) => {
    //console.log("value hsndle")
    setSelectedValue(value);
    setSelectedText(label.label);
    getdetallekit(value);
  }

  const showModal = () => {
    setValue("")
    setValor("")
    setCodTipoUniforme("0")
    setIsModalOpen(true)
  }

  const showModalKit = () => {
    setValueKit("");
    setIsModalOpenKit(true)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const handleCancelKit = () => {
    setIsModalOpenKit(false)
  }

  const renderFooter = ({ direction }: { direction: TransferDirection }) => {
    if (direction === "right") {
      return (
        <Button icon={<SaveOutlined />} type="primary" style={{ float: "right", margin: 5 }} onClick={saveKit}>
          Guardar
        </Button>
      )
    }
  }

  const saveTipoUniforme = async () => {
    let exist:any=[]
    tableData.forEach((item)=>{if((item.title).trim()===value.trim()&& item.codtipouniforme!==codTipoUniforme){exist.push({...item})}})
    if(exist.length>0){
      setTipoUniformemsg(true)
      return
    }
    if (value && valor && exist.length<1) {
      const res = await fetch(
        `${url}fapifsg-pr/rrhh/Uniforme/saveTipoUniforme`,
        optionsPost({ cod_tipo_uniforme: codTipoUniforme, nombre: value.trim(), valor:valor ,estado: "AP"})
      )

      if(res.status===200){
        setIsModalOpen(false);
        setTipoUniformemsg(false);
        if(codTipoUniforme==="0"){
          swalSuccess({text:"Datos guardados exitosamente"})
        }else{
          swalSuccess({text:"Se actualizaron los datos correctamente"})
        }
        const res = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/getTipoUniforme`, optionsGet()).then((data) => data.json())
        const NewArray=Array.from(res.data).map((item: any, key:any)=>(
          {
          key: key.toString(),
          title: item.Nombre,
          description: item.Nombre,
          disabled:false,
          tag: item.Nombre,
          cantidad: "0",
          codtipouniforme: item.Cod_Tipo_Uniforme,
          valor: item.Valor
        }
        ));
        setTableData(NewArray);
      }
      //console.log(res.status)
    } else {
      setTipoUniformemsg(false)
      //console.log("No hay datos para guardar")
    }
  }

  const saveNameKit=()=>{
    //e.log(valueKit)
    let exist=itemselect.filter((item:any)=>(item.label).trim()===valueKit.trim())
    //console.log(exist.length)
    if(exist.length>0){
      //setIsModalOpenKit(false);
      setKitmsg(true);
      return
    }
    
    if(valueKit && exist.length<1){
      let addkit=[{
        value: count,
        label: valueKit.trim()
      }]
      let Newitemselect = [...itemselect, ...addkit];

      
      setitemSelect(Newitemselect);
      setIsModalOpenKit(false)
      swalSuccess({text:"Se guardo exitosamente"})
      setCount(count-1)
      onChange([]);
      setKitmsg(false);
 
    }
  }

  const saveKit=async()=>{
    let detallekit=tableData.filter((item)=>item.cantidad!=="0");
    let inputvacios=tableData.filter((item)=>(item.cantidad==="0"||item.cantidad==="")&&targetKeys.includes(item.key))
    if(inputvacios.length>0){
      //setKitmsg(true)
      swalWarning({text:"Faltan datos por ingresar en cantidad"})
      //console.log("Faltan datos por ingresar en cantidad")
      return
    }

    if (selectedValue!=="" && selectedText && detallekit.length>0){
      const res = await fetch(
        `${url}fapifsg-pr/rrhh/Uniforme/saveKit`,
        optionsPost({cod_kit_uniforme: selectedValue, nombre: selectedText, estado: "AP", detallekit: detallekit})
      )
      
      if(res.status===200){
        let codkit, label;
        const res1 = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/getKit`, optionsGet()).then((data) => data.json())
        //console.log(itemselect);
        let newarray:any=[{value:"", label: "Seleccione un Kit"}];
        newarray=itemselect.map((item: any)=>{ 
          let respuesta:any=item;
          res1.data.map((item1:any) => {
            //console.log(item);
            if(item.label===item1.Nombre){
              respuesta= { ...item, value: item1.Cod_Kit};
              codkit=+selectedValue<=0&&selectedValue!==''?item1.Cod_Kit:selectedValue 
              label=item        
            }else{
              respuesta=item
            }
            return respuesta    
          });
          return respuesta;
        })

        setitemSelect(newarray);
        swalSuccess({text: "El Kit se guardó exitosamente"})
        handleChange(codkit as any, label)
        //getdetallekit(codkit as any)
        setKitmsg(false)
      }
    } else {
     swalWarning({text: "Faltan Datos para Guardar"})
    }  
  }
  return {
    renderFooter,
    onChange,
    handleChange,
    showModal,
    showModalKit,
    handleCancel,
    handleCancelKit,
    targetKeys,
    rightTableColumns,
    isModalOpen,
    isModalOpenKit,
    leftTableColumns,
    value,
    setValue,
    saveTipoUniforme,
    codTipoUniforme,
    valueKit,
    setValueKit, 
    saveNameKit,
    itemselect,
    tableData,
    saveKit, 
    TipoUniformemsg,
    Kitmsg,
    valor,
    setValor,
    selectedValue,
    handleInputValor
  }
}
