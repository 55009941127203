import { Form, Space, Input, Button, Row, Col, Divider, DatePicker } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { dateFormat } from "./MiInformacion";
import { useState } from "react";

export const Cursos = () => {
  const [validation, setValidation] = useState(false);

  const handleChangeName = (e: any) => {
    if (e.target?.value.trim().length > 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };
  return (
    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
      <Form.List name='cursos' initialValue={[{ nombre_curso: "", fecha_inicio: "", fecha_fin: "" }]}>
        {(fields = [], { add, remove }) => (
          <>
            <h3 style={{ textAlign: "center", color: "var(--primary)" }}>
              Añada sus cursos culminados o en proceso que tenga actualmente
            </h3>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Row wrap={true} key={key}>
                  <Divider>{name + 1}</Divider>
                  <Col xs={24} md={24} lg={24}>
                    <Space key={key} style={{ display: "flex", marginBottom: 8 }} align='baseline'>
                      <Row wrap={true}>
                        <Col xs={24} xl={8}>
                          <Form.Item
                            {...restField}
                            name={[name, "nombre_curso"]}
                            label={`Nombre del curso #${name + 1}`}
                            style={{ width: "98%" }}
                          >
                            <Input placeholder='Escriba el nombre' onChange={handleChangeName} />
                          </Form.Item>
                        </Col>
                        <Col xs={12} xl={8}>
                          <Form.Item
                            {...restField}
                            name={[name, "fecha_inicio"]}
                            label={`Fecha inicio #${name + 1}`}
                            style={{ width: "98%" }}
                            rules={[{ required: validation, message: "Escriba la fecha" }]}
                          >
                            <DatePicker style={{ width: "99%" }} placeholder='Inicio' format={dateFormat} />
                          </Form.Item>
                        </Col>
                        <Col xs={12} xl={8}>
                          <Form.Item
                            {...restField}
                            name={[name, "fecha_fin"]}
                            label={`Fecha culminación #${name + 1}`}
                            style={{ width: "98%" }}
                            rules={[{ required: validation, message: "Escriba la fecha" }]}
                          >
                            <DatePicker style={{ width: "99%" }} placeholder='FIN' format={dateFormat} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <MinusCircleOutlined
                        onClick={() => {
                          if (name >= 0) return remove(name);
                        }}
                      />
                    </Space>
                  </Col>
                </Row>
              );
            })}
            <Form.Item>
              <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                Agregar un nuevo curso
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};
