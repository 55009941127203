import { Button, Table } from "antd";
import { SiMicrosoftexcel } from "react-icons/si";
import { useVT } from "virtualizedtableforantd4";

interface DataType {
  title: string;
  dataIndex: string;
}

interface TableReportProps {
  headTable: any;
  columnTable: any[];
  rowSelection?: any;
  exportToExcel: (data: DataType[], fileName: string) => void;
  isSelect: boolean;
  tableRef?: React.RefObject<HTMLDivElement>;
  muestra?: boolean;
  title?: string;
  y?: number | string;
  x?: number | string;
  expandable?: any;
  color?: string;
  isTotal?: boolean;
  loading2?: boolean;
}

const TableReportVirtual = ({
  headTable,
  columnTable,
  rowSelection,
  exportToExcel,
  isSelect,
  tableRef,
  muestra = true,
  title,
  y = 600,
  x = 400,
  expandable,
  color,
  isTotal = true,
  loading2 = false,
}: TableReportProps) => {
  const [vt] = useVT(() => ({ scroll: { y: 400 } }), []);

  const totalCosto = columnTable?.reduce((sum, row) => sum + row.costo, 0);

  const totalCostoFormateado = (totalCosto / 1).toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div ref={tableRef}>
      <Table
        components={vt}
        title={title ? () => <strong>{title}</strong> : undefined}
        columns={headTable}
        bordered
        dataSource={columnTable}
        size='small'
        pagination={false}
        scroll={{ y, x }}
        rowSelection={isSelect && rowSelection}
        loading={columnTable?.length >= 1 && !loading2 && columnTable ? false : true}
        // footer={() => (isTotal ? <div>Total costo: {totalCostoFormateado}</div> : undefined)}
        footer={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "2px 2px",
              fontSize: "12px",
            }}
          >
            {isTotal ? <div>Total: {totalCostoFormateado}</div> : <div></div>}
            {muestra && (
              <Button
                size='small'
                onClick={() => exportToExcel(columnTable, "table-data")}
                icon={<SiMicrosoftexcel />}
                style={{
                  marginLeft: "5px",
                  padding: "0 5px",
                  height: "20px",
                  lineHeight: "20px",
                }}
              ></Button>
            )}
          </div>
        )}
        expandable={expandable}
        className={color}
      />
      {/* <Button onClick={() => exportToExcel(columnTable, "table-data")} icon={<SiMicrosoftexcel />} size='small'></Button> */}
    </div>
  );
};

export default TableReportVirtual;
