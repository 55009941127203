import React from 'react'
import type { ColumnsType } from "antd/es/table/interface"
import type { TableRowSelection } from "antd/es/table/interface"
import type { TransferItem, TransferProps} from "antd/es/transfer"
import { Table, Transfer } from "antd"
import "./CrearKit.css"
import { DataType } from 'hooks/rrhh/renovacionuniforme/useCrearKit'

  interface TableTransferProps extends TransferProps<TransferItem> {
    dataSource: DataType[]
    leftColumns: ColumnsType<DataType>
    rightColumns: ColumnsType<DataType>
  }
  
export default function TransferCrearKit({ leftColumns, rightColumns, ...restProps }: TableTransferProps) {
let isInput= true;
  return (
    
    <Transfer {...restProps}  className='tranfer-table'>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === "left" ? leftColumns : rightColumns

      const rowSelection: TableRowSelection<TransferItem> = {
        getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key)
          const diffKeys = selected
            ? treeSelectedKeys.filter((item1) => !listSelectedKeys.includes(item1 as string)) //difference(treeSelectedKeys, listSelectedKeys)
            : listSelectedKeys.filter((item1) => !treeSelectedKeys.includes(item1 as string)) //difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys as string[], selected)
          //console.log(diffKeys);
        },
        
        onSelect({ key }, selected) {
          onItemSelect(key as string, selected)
        },
        selectedRowKeys: listSelectedKeys,
      }
          //console.log(listSelectedKeys)
      return (
        <Table
          scroll={{ y: 225 }}
          rowSelection={rowSelection}
          columns={columns as DataType[]}
          dataSource={filteredItems}
          size="small"
          pagination={false}
          style={{ pointerEvents: listDisabled ? "none" : undefined }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled || isInput) return
              onItemSelect(key as string, !listSelectedKeys.includes(key as string))
            },
          })}
        />
      )
    }}
  </Transfer>
  )
}
