import { useEffect, useContext, useCallback } from "react";
import { LayoutContext } from "../../../context/LayoutContext";
import { url } from "../../../config/constants";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useLocalStorage } from "../../otros/useLocalStorage";

const breadCrumbs = [
  {
    Label: "EMPLEADOS PENDIENTES EN LA ACTUALIZACIÓN DE INFORMACIÓN",
    Url: "rrhh/estado",
  },
];
export function useFormularioEstado() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);

  let { optionsGet } = useLocalStorage();
  useBreadCrumbs(breadCrumbs);
  useEffect(() => {
    setOpenKeys(["Recursos Humanos/Actualizar Información", "Recursos Humanos"]);
    setSelectedKeys(["Actualizar Información/Estado Actualización"]);
  }, [setOpenKeys, setSelectedKeys]);

  const getEstados = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "apifsg-pr/rrhh/estado-formulario", optionsGet).then((data) => data.json());
      if (respuesta.msg) {
        const { data } = respuesta;
        return { data, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      // console.log(e)
      return { msg: "no_conexion", status: false };
    }
  }, [optionsGet]);
  return {
    getEstados,
  };
}
