import { loaderRoute, sendAction } from "config/methods"
import { Principal } from "pages/contentprincipal/Principal"
// import { AdelantoVacaciones } from "pages/contentrecursoshumanos/contentvacaciones/AdelantoVacaciones"
import { ListaSolicitudes } from "pages/contentrecursoshumanos/contentvacaciones/lista-solicitudes"
import { ListaSolicitudesAprobadasJefeInmediato } from "pages/contentrecursoshumanos/contentvacaciones/lista-solicitudes-aprobadas-jefe-inmediato"
// import { HistorialPorEmpleado } from "pages/contentrecursoshumanos/contentvacaciones/HistorialPorEmpleado"
// import { RevisionSolicitudRRHH } from "pages/contentrecursoshumanos/contentvacaciones/revision-solicitud-rrhh"
// import { RevisionSolicitudJefeInmediato } from "pages/contentrecursoshumanos/contentvacaciones/revision-solicitud-jefe-inmediato"
// import { SolicitudVacaciones } from "pages/contentrecursoshumanos/contentvacaciones/SolicitudVacaciones"
// import { RevisionSolicitudJefeSupervision } from "pages/contentrecursoshumanos/contentvacaciones/revision-solicitud-jefe-supervision"
import { type RouteObject, defer } from "react-router-dom"
import AES from "crypto-js/aes"
import { llave } from "config/constants"
import Utf8 from "crypto-js/enc-utf8"
import { ListaSolicitudesParaJefeSupervision } from "pages/contentrecursoshumanos/contentvacaciones/lista-solicitudes-para-jefe-supervision"
import { PagoVacaciones } from "pages/contentrecursoshumanos/contentvacaciones/pago-vacaciones"
import { HistorialVacaciones, TablaHistorial } from "pages/contentrecursoshumanos/contentvacaciones/historial-vacaciones"

const rutaPadre = "fapifsg-pr/recursos-humanos/vacaciones/"

const initialPath = "/rrhh/vacaciones"

export const rutasVacaciones: RouteObject[] = [
  {
    path: `${initialPath}/`,
    element: <Principal />,
  },
  {
    path: `${initialPath}/solicitud`,
    lazy: async () => {
      let { SolicitudVacaciones } = await import("pages/contentrecursoshumanos/contentvacaciones/SolicitudVacaciones")
      return { Component: SolicitudVacaciones }
    },
    action: ({ request }) => sendAction(`${rutaPadre}grabar`, request),
    loader: async () => loaderRoute(`${rutaPadre}datos`),
  },
  {
    path: `${initialPath}/revision-jefe-inmediato`,
    element: <ListaSolicitudes />,
    loader: () => loaderRoute(`${rutaPadre}listar-pendientes`),
  },
  {
    path: `${initialPath}/revision-jefe-inmediato/:solicitud`,
    lazy: async () => {
      let { RevisionSolicitudJefeInmediato } = await import(
        "pages/contentrecursoshumanos/contentvacaciones/revision-solicitud-jefe-inmediato"
      )
      return { Component: RevisionSolicitudJefeInmediato }
    },
    loader: async ({ params }) => {
      const { solicitud } = params
      const cod_solicitud = AES.decrypt(decodeURIComponent(solicitud ?? ""), llave).toString(Utf8)
      const datosSolicitud = loaderRoute(`${rutaPadre}datos-solicitud/${cod_solicitud}`)
      const empleados = await loaderRoute(`${rutaPadre}empleados-por-supervisor`)
      return defer({
        datosSolicitud,
        empleados,
      })
    },
    // action: async ({ request, params }) => {
    //   console.log(request.body)
    // const formData = await request.formData()
    // const tipo = formData.get("tipo") as "AP" | "RE" //AP: APROBAR - RE: RECHAZAR
    // const data = formData.get("data")

    // const newFormData = new FormData()
    // newFormData.append("data", JSON.stringify(data))
    // const newRequest = new Request(request.url, {
    //   method: request.method,
    //   body: newFormData,
    // })
    // if (tipo === "AP")
    // return sendAction(`${rutaPadre}aprobacion-solicitud`, request, params)
    // return sendAction(`${rutaPadre}rechazar-solicitud`, newRequest, params)
    // },
  },
  {
    path: `${initialPath}/revision-jefe-supervision`,
    element: <ListaSolicitudesParaJefeSupervision />,
    loader: () => loaderRoute(`${rutaPadre}listar-pendientes-jefe-supervision`),
  },
  {
    path: `${initialPath}/revision-jefe-supervision/:solicitud`,
    lazy: async () => {
      let { RevisionSolicitudJefeSupervision } = await import(
        "pages/contentrecursoshumanos/contentvacaciones/revision-solicitud-jefe-supervision"
      )
      return { Component: RevisionSolicitudJefeSupervision }
    },
    loader: async ({ params }) => {
      const { solicitud } = params
      const cod_solicitud = AES.decrypt(decodeURIComponent(solicitud ?? ""), llave).toString(Utf8)
      const datosSolicitud = loaderRoute(`${rutaPadre}datos-solicitud-jefe-supervision/${cod_solicitud}`)
      const empleados = await loaderRoute(`${rutaPadre}empleados-por-supervisor`)
      return defer({
        datosSolicitud,
        empleados,
      })
    },
    // action: async ({ request, params }) => {
    //   const formData = await request.formData()
    //   const tipo = formData.get("tipo") as "AP" | "RE" //AP: APROBAR - RE: RECHAZAR
    //   if (tipo === "AP") return sendAction(`${rutaPadre}aprobacion-solicitud`, request, params)
    //   return sendAction(`${rutaPadre}rechazar-solicitud`, request, params)
    // },
  },
  {
    path: `${initialPath}/revision-rrhh`,
    element: <ListaSolicitudesAprobadasJefeInmediato />,
    loader: () => loaderRoute(`${rutaPadre}listar-aprobadas-parciales`),
  },
  {
    path: `${initialPath}/revision-rrhh/:solicitud`,
    // element: <RevisionSolicitudRRHH />,
    lazy: async () => {
      let { RevisionSolicitudRRHH } = await import("pages/contentrecursoshumanos/contentvacaciones/revision-solicitud-rrhh")
      return { Component: RevisionSolicitudRRHH }
    },
    loader: async ({ params }) => {
      const { solicitud } = params
      const cod_solicitud = AES.decrypt(decodeURIComponent(solicitud ?? ""), llave).toString(Utf8)
      const datosSolicitud = loaderRoute(`${rutaPadre}datos-solicitud-aprobadas-parciales/${cod_solicitud}`)
      const empleados = await loaderRoute(`${rutaPadre}empleados-por-supervisor`)
      return defer({
        datosSolicitud,
        empleados,
      })
    },
    // action: async ({ request, params }) => {
    //   const formData = await request.formData()
    //   const tipo = formData.get("tipo") as "AP" | "RE" //AP: APROBAR - RE: RECHAZAR
    //   if (tipo === "AP") return sendAction(`${rutaPadre}aprobacion-solicitud`, request, params)
    //   return sendAction(`${rutaPadre}rechazar-solicitud`, request, params)
    // },
    // shouldRevalidate: () => false,
    // children,
  },
  {
    path: `${initialPath}/revision-solicitudes-jefes-departamentales`,
    element: <ListaSolicitudes />,
    loader: () => loaderRoute(`${rutaPadre}listar-solicitudes-jefes-departamentales`),
  },
  {
    path: `${initialPath}/revision-solicitudes-jefes-departamentales/:solicitud`,
    lazy: async () => {
      let { RevisionSolicitudJefeInmediato } = await import(
        "pages/contentrecursoshumanos/contentvacaciones/revision-solicitud-jefe-inmediato"
      )
      return { Component: RevisionSolicitudJefeInmediato }
    },
    loader: async ({ params }) => {
      const { solicitud } = params
      const cod_solicitud = AES.decrypt(decodeURIComponent(solicitud ?? ""), llave).toString(Utf8)
      const datosSolicitud = loaderRoute(`${rutaPadre}datos-solicitud/${cod_solicitud}`)
      const empleados = await loaderRoute(`${rutaPadre}empleados-por-supervisor`)
      return defer({
        datosSolicitud,
        empleados,
      })
    },
    // action: async ({ request, params }) => {
    //   const formData = await request.formData()
    //   const tipo = formData.get("tipo") as "AP" | "RE" //AP: APROBAR - RE: RECHAZAR
    //   if (tipo === "AP") return sendAction(`${rutaPadre}aprobacion-solicitud`, request, params)
    //   return sendAction(`${rutaPadre}rechazar-solicitud`, request, params)
    // },
  },
  {
    path: `${initialPath}/pago`,
    element: <PagoVacaciones />,
    loader: () => loaderRoute(`${rutaPadre}solicitudes-a-pagar`),
    action: ({ request, params }) => sendAction(`${rutaPadre}pago`, request, params),
  },
  {
    path: `${initialPath}/estado-solicitudes`,
    lazy: async () => {
      let { EstadoSolicitudes } = await import("pages/contentrecursoshumanos/contentvacaciones/estado-solicitudes")
      return { Component: EstadoSolicitudes }
    },
    loader: async () => defer({ datosSolicitud: loaderRoute(`${rutaPadre}estado-solicitudes`) }),
  },
  {
    path: `${initialPath}/reporte`,
    lazy: async () => {
      let { EncabezadoFechas } = await import("pages/contentrecursoshumanos/contentvacaciones/reporte-vacaciones")
      return { Component: EncabezadoFechas }
    },
    children: [
      {
        path: ":fecha_inicio/:fecha_fin",
        lazy: async () => {
          let { ReporteVacaciones } = await import("pages/contentrecursoshumanos/contentvacaciones/reporte-vacaciones")
          return { Component: ReporteVacaciones }
        },
        loader: async ({ params }) => {
          const { fecha_inicio, fecha_fin } = params
          return defer({
            solicitudes: loaderRoute(`${rutaPadre}solicitudes-aprobadas/${fecha_inicio}/${fecha_fin}`),
          })
        },
      },
    ],
  },
  {
    path: `${initialPath}/historial-por-empleado`,
    element: <HistorialVacaciones />,
    loader: () => loaderRoute(`${rutaPadre}empleados-por-supervisor`),
    children: [
      {
        path: ":empleado",
        element: <TablaHistorial />,
        loader: ({ params }) => {
          const { empleado } = params
          const cod_empleado = AES.decrypt(decodeURIComponent(empleado ?? ""), llave).toString(Utf8)
          return loaderRoute(`${rutaPadre}solicitudes-por-empleado/${cod_empleado}`)
        },
      },
    ],
  },
  // {
  //   path: `${initialPath}/historial`,
  //   element: <HistorialPorEmpleado />,
  // },
]
