import { TableColumnsType, Tag } from "antd";
import { useLayout } from "hooks/otros/useLayout";
import { useCallback, useState, useEffect, useRef, useMemo } from "react";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import { optionsPOST, url_interna } from "config/constants";
import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { RangePickerProps } from "antd/lib/date-picker";
import moment, { Moment } from "moment";

type VisorCuestionario = {
  key: React.Key;
  Cod_Bodega: number;
  Descripcion: string;
  Fecha: string;
  Cod_Usuario: number;
  Nombres_Rol: string;
};
type bodegasReporte = {
  key: React.Key;
  Cod_Preguntas: number;
  Pregunta: string;
  Cod_Respuestas: number;
  Nombre: string;
};

const breadcrumbs = [
  { Label: "Inventarios", Url: "/inventario" },
  { Label: "Reportes", Url: "/inventarios/reportes" },
  { Label: "Reporte cuestionario" },
];
const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];

export const useReporteCuestionario = () => {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);
  const [datos, setDatos] = useState<VisorCuestionario[]>([]);
  const [datosBodega, setDatosBodega] = useState<bodegasReporte[]>([]);
  const { isError } = useVerifyResponse();

  const [searchText] = useState("");
  const [searchTextBodega] = useState("");


  const [filteredData, setFilteredData] = useState<VisorCuestionario[]>([]);
  const [filteredDataBodega, setFilteredDataBodega] = useState<bodegasReporte[]>([]);

  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<VisorCuestionario>();
  const { getColumnSearchPropsNew: getColumnSearchPropsNewBodegasReporte } = useSearchTableNew<bodegasReporte>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [isSecondTableVisible, setIsSecondTableVisible] = useState(false);

  const rowRefs = useRef<{ [key: string]: React.RefObject<HTMLTableRowElement> }>({});
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [activeRowIndex] = useState<number>(0);

  const getDataCuestionario = useCallback(
    async (fechaInicio: Moment, fechaFin: Moment) => {
      try {
        const res = await fetch(
          `${url_interna}api/inventarios/datos_cuestionario`,
          optionsPOST(
            JSON.stringify({ fechaInicio: fechaInicio!.format("DD/MM/YYYY"), fechaFin: fechaFin!.format("DD/MM/YYYY") }),
            {
              "api-token": "apitoken",
            }
          )
        );
        if (isError(res)) throw new Error();
        const respuesta = await res.json();

        if (respuesta.msg !== "err") {
          const respuestas: VisorCuestionario[] = respuesta.data;

          setDatos(
            respuestas.map(({ key, Descripcion, Fecha, Cod_Bodega, Cod_Usuario, Nombres_Rol }) => ({
              key,
              Descripcion,
              Fecha,
              Cod_Bodega,
              Cod_Usuario,
              Nombres_Rol,
            }))
          );
        } else {
          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        return { msg: "no_conexion", status: false };
      }
    },
    [isError]
  );
  useEffect(() => {
    const fechaInicio = moment().startOf("month");
    const fechaFin = moment().endOf("day");
    getDataCuestionario(fechaInicio, fechaFin);
  }, [getDataCuestionario]);

  const rowSelection = useMemo(
    () => ({
      onChange: (selectedRowKeys: React.Key[], selectedRows: VisorCuestionario[]) => {
        // Aquí puedes manejar cualquier lógica que necesites cuando cambia la selección
      },
    }),
    [
      /* dependencias si las hay */
    ]
  );

  const getDataBodega = useCallback(
    async (codCuestionario: React.Key) => {
      try {
        const res = await fetch(
          `${url_interna}api/inventarios/datos_cuestionario_respuestas`,
          optionsPOST(JSON.stringify({ codCuestionario: codCuestionario }), {
            "api-token": "apitoken",
          })
        );
        if (isError(res)) throw new Error();
        const respuesta = await res.json();
        if (respuesta.msg !== "err") {
          const respuestas: bodegasReporte[] = respuesta.data;

          setDatosBodega(
            respuestas.map(({ Cod_Preguntas, Pregunta, Cod_Respuestas, Nombre, key }) => ({
              Cod_Preguntas,
              Pregunta,
              Cod_Respuestas,
              Nombre,
              key,
            }))
          );
        } else {
          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        return { msg: "no_conexion", status: false };
      }
    },
    [isError]
  );
  const modifiedRowSelection = useMemo(
    () => ({
      ...rowSelection,
      selectedRowKeys,
      onChange: (newSelectedRowKeys: React.Key[]) => {
        console.log(newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
        getDataBodega(newSelectedRowKeys[0]);
        setIsSecondTableVisible(true);
        // Aquí puedes manejar cualquier otra lógica que necesites cuando cambia la selección
      },
    }),
    [rowSelection, selectedRowKeys, getDataBodega]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (tableContainerRef.current) {
        const tableScrollRect = tableContainerRef.current.getBoundingClientRect();
        let currentSelectedIndex = datos.findIndex((item) => item.key === selectedRowKeys[0]);
        let nextIndex = currentSelectedIndex + (event.key === "ArrowDown" ? 1 : -1);
        nextIndex = Math.max(0, Math.min(nextIndex, datos.length - 1));

        const nextRowKey = datos[nextIndex]?.key;
        if (nextRowKey !== undefined) {
          const nextRowRef = rowRefs.current[nextRowKey];

          if (nextRowRef && nextRowRef.current) {
            const rowRect = nextRowRef.current.getBoundingClientRect();
            const rowIsNotFullyVisible = rowRect.top < tableScrollRect.top || rowRect.bottom > tableScrollRect.bottom;

            if (rowIsNotFullyVisible) {
              const scrollPosition =
                tableContainerRef.current.scrollTop +
                (rowRect.top < tableScrollRect.top
                  ? rowRect.top - tableScrollRect.top
                  : rowRect.bottom - tableScrollRect.bottom);

              tableContainerRef.current.scrollTop = scrollPosition;
            }
          }

          modifiedRowSelection.onChange([nextRowKey]);
        }
      }
    },
    [selectedRowKeys, datos, modifiedRowSelection, rowRefs, tableContainerRef]
  );
  const renderTag = (value: string) => {
    switch (value) {
      case "SI":
        return <Tag color="success">SI</Tag>;
      case "NO":
        return <Tag color="error">NO</Tag>;
      default:
        return null; // O podrías retornar algo por defecto si esperas otros valores
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Deshabilita las fechas futuras respecto al final del día actual
    return current && current > moment().endOf("day");
  };
  // Asegúrate de actualizar la selección de filas cuando cambie activeRowIndex
  useEffect(() => {
    const newSelectedRowKey = datos[activeRowIndex]?.key;
    if (newSelectedRowKey !== undefined) {
      setSelectedRowKeys([datos[activeRowIndex]?.key]);
      //setSelectedRowKeys([newSelectedRowKey]);
      getDataBodega(newSelectedRowKey);
      setIsSecondTableVisible(true);
    }
  }, [activeRowIndex, datos, getDataBodega]);

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos);
    } else {
      const filtered = datos.filter(
        (item) => item.Descripcion && item.Descripcion.toString().toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  useEffect(() => {
    if (!searchTextBodega) {
      setFilteredDataBodega(datosBodega);
    } else {
      const filtered = datosBodega.filter(
        (item) => item.Pregunta && item.Pregunta.toString().toLowerCase().includes(searchTextBodega.toLowerCase())
      );
      setFilteredDataBodega(filtered);
    }
  }, [searchTextBodega, datosBodega]);

  const columns: TableColumnsType<VisorCuestionario> = [
    {
      title: "Farmacia",
      className: "letraPequenaVisor",
      dataIndex: "Descripcion",
      key: "Descripcion",
      ...getColumnSearchPropsNewVisorReporte("Descripcion"),
    },
    {
      title: "Fecha",
      dataIndex: "Fecha",
      className: "letraPequenaVisor",
      key: "Fecha",
    },
    {
      title: "Usuario realiza",
      dataIndex: "Nombres_Rol",
      className: "letraPequenaVisor",
      key: "PVP",
    },
  ];

  const onRowClick = useCallback(
    (record: VisorCuestionario) => {
      setSelectedRowKeys([record.key]);
      console.log(record.key);
      getDataBodega(record.key);
      setIsSecondTableVisible(true);
    },
    [getDataBodega]
  );
  const columnsBodega: TableColumnsType<bodegasReporte> = [
    {
      title: "Pregunta",
      dataIndex: "Pregunta",
      className: "letraPequenaVisor",
      key: "Pregunta",
      width: "200px",
      ...getColumnSearchPropsNewBodegasReporte("Pregunta"),
    },
    {
      title: "Respuesta",
      dataIndex: "Nombre",
      key: "Nombre",
      className: "letraPequenaVisor",
      render: (value) => {
        console.log(value);
        return renderTag(value);
      },
    },
  ];
  return {
    columns,
    filteredData,
    modifiedRowSelection,
    onRowClick,
    isSecondTableVisible,
    columnsBodega,
    filteredDataBodega,
    activeRowIndex,
    tableContainerRef,
    disabledDate,
    getDataCuestionario,
  };
};
