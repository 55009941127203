import { Col, Row, Table } from "antd"
import { useListaSolicitudesParaJefeSupervision } from "hooks/rrhh/vacaciones/useListaSolicitudesParaJefeSupervision"

export function ListaSolicitudesParaJefeSupervision() {
  const { isWeb, solicitudes, columnsTable, pageSize, setPageSize } = useListaSolicitudesParaJefeSupervision()

  return (
    <Row wrap style={{ padding: "16px", marginRight: "0", marginLeft: "0" }} gutter={isWeb ? [16, 16] : [5, 5]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Table
          dataSource={solicitudes}
          columns={columnsTable}
          scroll={{ x: true }}
          size="small"
          pagination={{
            pageSize,
            size: "default",
            onShowSizeChange: (_, size) => setPageSize(size),
            pageSizeOptions: [7, 10, 20, 50, 100],
          }}
        />
      </Col>
    </Row>
  )
}
