import { type DataVerticalTable, VerticalTable } from "components/reactreport/vertical-table"
import moment from "moment"
import { Moment } from "moment"

type Props = {
  menos_gastos: number
  total_menos_gastos: number
  iva_12: number
  retenciones: number
  valor_a_pagar: number
  mes: Moment
}

export function TotalesFinales({ menos_gastos, total_menos_gastos, iva_12, retenciones, valor_a_pagar, mes }: Props) {
  const dataTotalGastos: DataVerticalTable[] = [
    {
      title: "Menos gastos",
      text: (-menos_gastos).toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,"),
      key: "menos gastos",
      styleCell: { padding: 3, borderBottom: "1.3px solid #000" },
      styleCellHeader: { padding: 3 },
    },
    {
      title: "Total",
      text: total_menos_gastos.toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,"),
      key: "total menos gastos",
      styleCell: { padding: 3 },
      styleCellHeader: { padding: 3, fontSize: "9" },
    },
    {
      title:
        moment("01/" + mes?.format("MM/YYYY"), "DD/MM/YYYY") < moment("01/03/2024", "DD/MM/YYYY") ? "12% IVA" : "15% IVA",
      text: total_menos_gastos < 0 ? "-" : iva_12.toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,"),
      key: "iva_12",
      styleCell: { padding: 3, borderBottom: "1.3px solid #000" },
      styleCellHeader: { padding: 3 },
    },
    {
      title: "TOTAL",
      text: (total_menos_gastos + iva_12).toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,"),
      key: "total",
      styleCell: { padding: 3 },
      styleCellHeader: { padding: 3 },
    },
    {
      title: "Menos retenciones",
      text:
        total_menos_gastos < 0
          ? "-"
          : (-(retenciones + iva_12)).toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,"),
      key: "menos retenciones",
      styleCell: { padding: 3, borderBottom: "1.3px solid #000" },
      styleCellHeader: { padding: 3 },
    },
    {
      title: "Valor a pagar",
      text: valor_a_pagar.toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,"),
      key: "valor a pagar",
      styleCell: { padding: 3, fontWeight: "bold", backgroundColor: "#FFCF00" },
      styleCellHeader: { padding: 3, backgroundColor: "#FFC300" },
    },
  ]

  return (
    <VerticalTable
      dataSource={dataTotalGastos}
      styleColumn={{ flexBasis: "30%" }}
      styleColumnHeader={{ flexBasis: "70%", backgroundColor: "#dadada" }}
      containerStyle={{ marginTop: "15px", width: "75%", alignSelf: "flex-end" }}
      roundTop
      roundBottom
    />
  )
}
