import { PDFDownloadLink } from "@react-pdf/renderer"
import { Button } from "antd"

type FunctionDownloadButtons = (
  onClose: () => void,
  document: JSX.Element,
  filename: `${string}.${string}`
) => [JSX.Element, JSX.Element]

type ReturnHook = [FunctionDownloadButtons]

export function useFooterModal(): ReturnHook {
  const footerDownloadPDF: FunctionDownloadButtons = (onClose, document, filename) => [
    <Button onClick={onClose} key="cerrar">
      Cerrar
    </Button>,
    <PDFDownloadLink className="ant-btn ant-btn-primary" document={document} fileName={filename} key="descargar">
      {({ blob, url, loading }) => (loading ? "Cargando..." : "Descargar")}
    </PDFDownloadLink>,
  ]

  return [footerDownloadPDF]
}
