import { Modal, Form, Row, Col, Input, FormProps } from 'antd'
import { useContext } from 'react'

import { ModalFormContext, ModalFormInputsElements, ModalFormGroupInputs } from 'context/ModalFormContext'

interface ModalFormProps {
  onCancel?: () => void
  formProps: FormProps
}

export function ModalForm({ onCancel, formProps }: ModalFormProps) {

  const { elements, inputsState, modalProps, onOk, form, initialValues } = useContext(ModalFormContext)
  
  // const [ form ] = Form.useForm()

  const okAndCancel = onCancel ? {
    onCancel: () => {
      onCancel()
      form.resetFields()
    },
    onOk: () => {
      form.validateFields()
        .then(values => {
          onOk && onOk(values)
          form.resetFields()
        })
        .catch(info => {
          console.log('Validación Fallida:', info)
        })
    }
  } : {}

  return (
    <Modal 
      cancelText='Cancelar'
      { ...modalProps }
      transitionName=''
      centered={ true }
      className='modalForm'
      { ...okAndCancel }
    >
      <Form 
        initialValues={initialValues}
        form={form}
        labelWrap
        requiredMark='optional'
        { ...formProps }
        // style={{ maxHeight: '80vh', overflowY: 'scroll'}}
      >
        {
          inputsState.map((element, index) => (
            element.elementType 
            ?
              <Form.Item { ...element.elementProps } key={ index }>
                { elements[element.elementType](element.inputProps ?? {}) }
              </Form.Item>
            : 
              (element as unknown as ModalFormGroupInputs).elements ?
                <Form.Item key={ index } label={(element as unknown as ModalFormGroupInputs)?.label}>
                  <Input.Group compact>
                    {(element as unknown as ModalFormGroupInputs).elements.map(({elementType, elementProps, inputProps}, index2) => (
                      <Form.Item { ...elementProps } key={ index+'.'+index2 } noStyle>
                        { elements[elementType](inputProps ?? {}) }
                      </Form.Item>
                    ))}
                  </Input.Group>
                </Form.Item>
              :
                <Row key={ index } gutter={[ 16, 16 ]}>
                  {(element as unknown as Array<ModalFormInputsElements>).map(({elementType, elementProps, inputProps}, index2) => (
                    <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 } key={ index+'.'+index2 }>
                      <Form.Item { ...elementProps }>
                        { elements[elementType](inputProps ?? {}) }
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              
          ))
        }
      </Form>
    </Modal>
  )
}