import { Button, Col, Row, Typography } from 'antd'
import Table from 'antd/es/table'
import { DatatypeAnual, DatatypeTrimestre, useEstadoIngresoprueba } from 'hooks/contabilidad/useEstadoIngresoprueba'
import { useLayout } from 'hooks/otros/useLayout'
import "./EstadoIngreso.css"

const { Title } = Typography
const breadcrumbs = [
    { Label: "Contabilidad", Url: "/contabilidad" },
    { Label: "Estado de Pérdidas y Ganancias Prueba", Url: "/contabilidad/estado-perdida-ganancias-prueba" },
  ]
  const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
  const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`
export function EstadoIngresoprueba(){
    useLayout(breadcrumbs, openKeys, selectKeySider)
    const{
        t_anual,
        t_trimestral,
        visible,
        btnAnual,
        btnTrimestral,
        dataAnual,
        handleExpand,
        dataTrimestral,
        handleExpandTri
    }=useEstadoIngresoprueba()
  return (
    <>
       <Row style={{ background: 'white', padding: '5px', paddingLeft:'10px'}}>
            <Col xs={24} sm={24} md={12}><Title level={3}>Estado de Pérdidas y Ganancias Prueba</Title></Col>
            <Col xs={24} sm={24} md={12}>
                <Row justify={"end"}>
                    <Button type='primary' style={{marginRight:'2px'}} onClick={btnAnual}>Anual</Button>
                    <Button type='primary' onClick={btnTrimestral}>Trimestral</Button>
                </Row>
            </Col>
        </Row>
        {visible && <Row className='titlehead' style={{ background: 'white', padding: '5px'}}>
            <Table style={{width:'100%', height:'100%'}} columns={t_anual as DatatypeAnual[]}
              dataSource={dataAnual}
              rowClassName={(record, index) => 'miFilaSeleccionada'}  
              expandable={{
                onExpand:handleExpand}} 
            ></Table>
        </Row>}
        {!visible && <Row style={{ background: 'white', padding: '5px', height:'100%'}}>
            <Table style={{width:'100%', height:'100%'}} columns={t_trimestral as DatatypeTrimestre[]}
              dataSource={dataTrimestral}  
              rowClassName={(record, index) => 'miFilaSeleccionada'} 
              expandable={{
                onExpand:handleExpandTri}}         
            />
        </Row>}
    </>
  )
}
