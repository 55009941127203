import { loaderRoute } from "config/methods"
import { AsignarFranquiciadoFarmacia } from "pages/contentfranquiciados/AsignarFranquiciadoFarmacia"
import { GastosFranquiciados } from "pages/contentfranquiciados/GastosFranquiciados"
import { BonosFranquiciados } from "pages/contentfranquiciados/bonos-franquiciados"
import { Configuracion } from "pages/contentfranquiciados/configuracion"
import { Pago } from "pages/contentfranquiciados/contentpago/Pago"
import { DeudasFranquiciados } from "pages/contentfranquiciados/deudas-franquiciados"
import { FranquiciadosDatos } from "pages/contentfranquiciados/franquiciados-datos"
import { PagoReportes } from "pages/contentfranquiciados/pago-reportes"
import { Principal } from "pages/contentprincipal/Principal"
import { type RouteObject } from "react-router-dom"

export const rutasFranquiciados: RouteObject[] = [
  {
    path: "/franquiciados",
    element: <Principal />,
  },
  {
    path: "/franquiciados/datos",
    element: <FranquiciadosDatos />,
  },
  {
    path: "/franquiciados/asignacion",
    element: <AsignarFranquiciadoFarmacia />,
  },
  {
    path: "/franquiciados/tipo-gastos",
    element: <GastosFranquiciados />,
  },
  {
    path: "/franquiciados/pago",
    element: <Pago />,
  },
  {
    path: "/franquiciados/bonos",
    element: <BonosFranquiciados />,
  },
  {
    path: "/franquiciados/reportes",
    element: <PagoReportes />,
  },
  {
    path: "/franquiciados/deudas",
    element: <DeudasFranquiciados />,
  },
  {
    path: "/franquiciados/configuracion",
    element: <Configuracion />,
    loader: () => loaderRoute("fapifsg-pr/franquiciados/datos-iniciales-pago-franquiciados"),
  },
]
