import { Col, Row, Select, Typography, DatePicker, Space, Button, Table, Image } from "antd"
import { useReporteUniformes } from "hooks/rrhh/renovacionuniforme/useReporteUniformes"
import { SearchOutlined, FileExcelOutlined } from "@ant-design/icons"
import { useLayout } from "hooks/otros/useLayout"
import { url } from "config/constants"



const { Title } = Typography
const { RangePicker } = DatePicker

const breadcrumbs = [
  { Label: "Recursos Humanos", Url: "/rrhh" },
  { Label: "Renovación de Uniformes", Url: "/rrhh/renovacion-uniformes" },
  { Label: "Reporte de Uniformes" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

function ReporteUniformes() {
  useLayout(breadcrumbs, openKeys, selectKeySider)
  const { handleDateChange, 
          disabledDate, 
          btnBuscar, 
          columns, 
          data,  
          columnsuniforme, 
          llavesimages, 
          visible, 
          setVisible, 
          handleChange, 
          handleDownload,
          ExpandedRowKeys,
          handleExpand,
          fechasSeleccionadas
        } = useReporteUniformes()

  return (
    <>
      <Row style={{ background: "white", padding: "5px" }} justify="center" align="middle">
        <Col sm={24} md={13} style={{ paddingLeft: "20px" }}>
          <Title level={2}>Reporte Uniformes</Title>
        </Col>
        <Col sm={24} md={11} style={{ alignContent: "end" }}>
          <Row>
            <Col sm={24} md={6}>
              <Row style={{ fontSize: "10px", marginRight: "5px" }}>Estado:</Row>
              <Row>
                <Select
                  defaultValue=""
                  style={{ width: 130, marginLeft: "0px", marginTop: "5px" }}
                  onChange={handleChange}
                  options={[
                    {
                      value: "",
                      label: "Todos",
                    },
                    {
                      value: "PE",
                      label: "Solicitados",
                    },
                    {
                      value: "PP",
                      label: "Validado RRHH",
                    },
                    {
                      value: "AP",
                      label: "Aprobados",
                    },
                    {
                      value: "RE",
                      label: "Rechazados",
                    },
                  ]}
                />
              </Row>
            </Col>
            <Col sm={24} md={11}>
              <Row style={{ fontSize: "10px", marginRight: "5px" }}>Fechas:</Row>
              <Row style={{ marginTop: "5px" }}>
                <Space>
                  <RangePicker defaultValue={fechasSeleccionadas} format={'YYYY/MM/DD'} disabledDate={disabledDate} onChange={handleDateChange} style={{ marginLeft: "2px" }} />
                </Space>
              </Row>
            </Col>
            <Col style={{ marginTop: "20px" }}>
              <Button type="primary" style={{ marginLeft: "4px" }} onClick={btnBuscar}>
                <SearchOutlined />
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: "4px", marginTop: "1px", backgroundColor: "#00B050", borderColor: "#00B050" }}
                onClick={handleDownload}
              >
                Excel <FileExcelOutlined />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ background: "white" }}>
        <Table
          style={{ width: "100%", margin: "10px" }}
          columns={columns}
          dataSource={data}
          expandable={{
            expandedRowRender: (record) => {
              //console.log(record.detalle)
              let detalle: any = record.detalle
              return (
                <Table
                  style={{ width: "100%", margin: "10px" }}
                  columns={columnsuniforme}
                  dataSource={detalle}
                  pagination={false}
                />
              )
            },
            expandedRowKeys: ExpandedRowKeys,
            onExpand: handleExpand,
            // rowExpandable: (record) => record.detalle !==null,
          }}
        />
      </Row>
      {llavesimages && <div style={{ display: 'none' }}>
        <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis)}}>
          {llavesimages.map((item:any)=><Image src={`${url}apiallku-public/api/${item.Llave}`}/>)}
        </Image.PreviewGroup>
      </div>}
    </>
  )
}

export default ReporteUniformes
