import React from "react"
import { Button, Col, Input, Modal, Radio, Row, Select, Table, Typography} from "antd"
import { useLayout } from "hooks/otros/useLayout"
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { ComponentLibretaContactos } from "components/compras/ComponentLibretaContactos"
import {DataType, DataTypeUltCompras,DataTypeCambiarProveerdor, DataTypeStockG, DataTypeVM, useOrdenCompras, DataTypeEScalaProducto } from "hooks/compras/useOrdenCompras"
import "./OrdenCompras.css"

const { Title } = Typography

const breadcrumbs = [
  { Label: "Compras", Url: "/compras" },
  { Label: "Orden de Compras", Url: "/compras/orden-compras" },
  //{ Label: "Aprobación Uniformes" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`

export function OrdenCompras() {
  useLayout(breadcrumbs, openKeys, selectKeySider)
  const {
    t_productos,
    t_comprasultimas,
    t_ventasmensuales,
    t_StockGlobal,
    t_CambiarProveedorProd,
    itemselectProducto,
    itemselectProveedores,
    setvalueSelectProveedor,
    setvalueobservacion,
    setvalueselectProducto,
    btnAgregar,
    setvalueCantidadProd,
    setvalueBoniProd,
    setvaluePrecioProd,
    setvalueDescProd,
    soloNumeros,
    setvalueCantMultComp,
    setvalueDescCompr,
    setvalueBaseCuaBoniC,
    setvalueBoniCuaBoniC,
    valueCantidadProd,
    valueBoniProd,
    valuePrecioProd,
    valueDescProd,
    valueCantMultComp,
    valueDescCompr,
    valueBaseCuaBoniC,
    valueBoniCuaBoniC,
    valueRadioButton,
   // setvalueRadioButton,
    valueFacRepo, 
    setvalueFacRepo,
    itemselectLaboratorio,
    setvalueSelectLaboratorio,
    btnGenerarOrden,
    onchangeRadio,
    t_EscalaProducto,
    showModalEscala,
    btnEscalaProducto,
    CancelEscala,
    btnDescMasivo,
    showModalDescMasivo,
    CancelDescMasivo,
    titleModalEscala,
    btnEscalaLaboratorio
  }=useOrdenCompras();

  return (
    <>
      <Row justify="end" style={{ background: 'white', paddingRight: '8px' }}>
      <Button type="primary" size="small" icon={<CloseCircleOutlined />} style={{ marginRight: '2px' }}>
        Anular
      </Button>
      <Button size="small" type="primary" icon={<SaveOutlined />}>
        Guardar
      </Button>
    </Row>
      <Row justify="space-around" style={{ background: "white", paddingRight: "8px", paddingLeft:'8px', paddingTop:'3px' }}>
        <Col xs={24} sm={24} md={13} lg={13}>
          <Row align={"bottom"}>
            <Col xs={12} sm={12} md={12}>
              <Row><Radio.Group name="radiogroup" defaultValue={1} onChange={(value)=>onchangeRadio(value)}>
                  <Radio value={1}><Title style={{ fontSize: "11px" }}>Por Laboratorio</Title></Radio>
                  <Radio value={2}><Title style={{ fontSize: "11px" }}>Por Proveedor</Title></Radio>
              </Radio.Group></Row>
              {+valueRadioButton===2&&<Row align={"bottom"} style={{marginRight:'5px'}}>
              <Select
                  size="small"
                  //disabled={disabledDragger}
                  showSearch
                  style={{ textAlign: "start", width: "100%"}}
                  defaultValue=""
                  placeholder="Seleccione un Proveedor"
                  optionFilterProp="children"
                  onChange={(value)=>{setvalueSelectProveedor(value)}}
                  //onSearch={onSearch} 
                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                  options={itemselectProveedores}
                  />
              </Row>}
              {+valueRadioButton===1&&<Row align={"bottom"} style={{marginRight:'5px'}}>
              <Select
                  size="small"
                  //disabled={disabledDragger}
                  showSearch
                  style={{ textAlign: "start", width: "100%"}}
                  defaultValue=""
                  placeholder="Seleccione un Laboratorio"
                  optionFilterProp="children"
                  onChange={(value)=>{setvalueSelectLaboratorio(value)}}
                  //onSearch={onSearch}
                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                  options={itemselectLaboratorio}
                  />
              </Row>}
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Row justify={"center"}> <Title style={{ fontSize: "11px" }}>Factor Reposición</Title></Row>
              <Row><Input size="small" style={{textAlign:'end'}} onChange={(e)=>soloNumeros(e, setvalueFacRepo, 10)} value={valueFacRepo}></Input></Row>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Button type="primary" 
              size="small"
              style={{
                fontSize: "12px",
                textAlign: "center",
                whiteSpace: "normal",
                height: "auto",
                marginRight: "1px",
                marginLeft:'5px'
                //width: "55px",
              }}

              onClick={btnGenerarOrden}
              >Generar Orden de Compra</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} xl={24}>
            <Title style={{ fontSize: "11px", marginTop:'2px', paddingLeft:'5px' }}>Cambiar Proveedor</Title>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} style={{ marginRight: "1px" }}>
            <Select
                  size="small"
                  //disabled={disabledDragger}
                  showSearch
                  style={{ textAlign: "start", width: "100%", marginBottom: "5px" }}
                  //defaultValue=""
                  placeholder="Seleccione un Proveedor"
                  optionFilterProp="children"
                  onChange={(value)=>{setvalueSelectProveedor(value)}}
                  //onSearch={onSearch}
                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                  options={itemselectProveedores}
                />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} style={{width:'100%'}}>
            <Title style={{ fontSize: "11px", marginTop:'2px', paddingLeft:'5px' }}>Ingresar una observación</Title>
            </Col>
          </Row>
          <Row style={{marginBottom:'3px'}}>
            <Col xs={24} sm={24} style={{ marginRight: "3px" }}>
              <Input size="small"  onChange={(e)=>{setvalueobservacion(e.target.value)}}></Input>
            </Col>
          </Row>
          <Row  style={{borderStyle: "solid", borderColor: "#D5D8DC", borderWidth: "1px", borderRadius: "5px", paddingLeft: "1px", paddingBottom:'1px', marginTop:'1px', marginBottom:'3px'}}>
          <Row style={{width:'100%'}}>
            <Col xs={24} sm={24} md={24} >
            <Title style={{ fontSize: "11px", marginTop:'2px', paddingLeft:'5px'}}>Realizar cambios en la orden de compra</Title>
            </Col>
          </Row>
          <Row align="middle"  style={{width:'100%'}}>
            <Col xs={12} sm={12} md={7} style={{ marginTop: "1px" }}>
              <Row justify="space-around">
                <Col xs={8} sm={8} md={8}>
                <Button
                type="primary"
                size="small"
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  whiteSpace: "normal",
                  height: "auto",
                  marginRight: "1px",
                  //width: "55px",
                }}

                onClick={btnEscalaProducto}
              >
                Escala Producto
              </Button>
                </Col>
                <Col xs={8} sm={8} md={8}>
              <Button
                type="primary"
                size="small"
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  whiteSpace: "normal",
                  height: "auto",
                  marginRight: "1px",
                  //width: "55px",
                }}
                onClick={btnEscalaLaboratorio}
              >
                Escala Labotorio
              </Button>
                </Col>
                <Col sm={8} xs={8} md={8}>
                <Button
                type="primary"
                size="small"
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  whiteSpace: "normal",
                  height: "auto",
                  marginRight: "1px",
                  //width: "60px",
                }}
                onClick={btnDescMasivo}
              >
                Descuento Masivo
              </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={11} sm={11} md={5} style={{ marginTop: "1px", marginLeft:'4px' }}>
              <Row justify="space-around">
                <Col xs={16} md={16}>
                  <Button
                  type="primary"
                  size="small"
                  style={{ fontSize: "10px", textAlign: "center", whiteSpace: "normal", height: "auto", }}
                >
                  Cantidades Múltiplos de:
                </Button> </Col>
                <Col xs={8} md={8}>
                <Input size="middle" style={{marginLeft: "3px",  textAlign:"right" }}
                onChange={(e)=>soloNumeros(e, setvalueCantMultComp)} value={valueCantMultComp}
                />
                </Col>
              </Row>
            </Col>
            <Col xs={11} sm={12} md={5}style={{ marginTop: "1px"}}>
              <Row> 
                <Col sm={16} xs={16} md={16}>
                <Button
                  type="primary"
                  size="small"
                  style={{
                    fontSize: "10px",
                    textAlign: "center",
                    whiteSpace: "normal",
                    height: "auto",
                    marginLeft: "6px",
                  }}
                >
                  Descuentos igual a:
                </Button>{" "}
                </Col>
                <Col xs={8} sm={8} md={8} > 
                <Input size="middle" style={{ marginLeft: "2px" ,textAlign:"right"}}
                  onChange={(e)=>soloNumeros(e, setvalueDescCompr)} value={valueDescCompr}
                />
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={11} md={6} style={{ marginTop: "1px", marginLeft:'2px' }}>
              <Row align={"middle"}>
                <Col xs={12} sm={12} md={12}>
                  <Button
                    type="primary"
                    size="small"
                    style={{
                      fontSize: "10px",
                      textAlign: "center",
                      whiteSpace: "normal",
                      height: "auto",
                      marginLeft: "2px",
                    }}
                  >
                    Cuadrar Bonificación:
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={12}>
                  <Row justify="center">
                    <Col  xs={12} sm={12} md={12}>
                      <Row justify="center">
                        <Title style={{ fontSize: "7px", textAlign: "center" }}>BASE</Title>
                      </Row>
                      <Row justify="center">
                      <Col  md={24} style={{ marginLeft: "7px" }}>
                          <Input size="small" style={{textAlign:"right"}} onChange={(e)=>soloNumeros(e, setvalueBaseCuaBoniC)} value={valueBaseCuaBoniC}></Input>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                      <Row justify={"center"}>
                        <Title style={{ fontSize: "7px", textAlign: "center", marginLeft:'16px' }}>BONIFICACIÓN</Title>
                      </Row>
                      <Row>
                        <Col md={24} style={{ marginLeft: "6px"}}>
                          <Input size="small" style={{textAlign:"right"}} onChange={(e)=>soloNumeros(e, setvalueBoniCuaBoniC)} value={valueBoniCuaBoniC}/>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          </Row>
          <Row style={{borderStyle: "solid", borderColor: "#D5D8DC", borderWidth: "1px", borderRadius: "5px", paddingBottom:'2px'}}>
          <Row>
            <Col xs={24} sm={24} md={24}>
            <Title style={{ fontSize: "11px", marginTop:'2px', paddingLeft:'5px' }}>Agregar Producto a la orden de compra</Title>
            </Col>
          </Row>
          <Row  align={"bottom"}>
            <Col xs={18} sm={18} md={12} style={{marginLeft: '2px'}}>
            <Select
                  size="small"
                  //disabled={disabledDragger}
                  showSearch
                  style={{ textAlign: "start", width: "100%", marginTop:'12px'}}
                  //defaultValue=""
                  placeholder="Seleccione un Producto"
                  optionFilterProp="children"
                  onChange={(value)=>{setvalueselectProducto(value)}}
                  //onSearch={onSearch}
                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                  options={itemselectProducto}
                />
            </Col>
            <Col xs={5} sm={6} md={2} style={{ marginLeft: "4px" }}>
              <Row justify="center">
                <Title style={{ fontSize: "8px", textAlign: "center" }}>Cantidad</Title>
              </Row>
              <Row>
                <Input size="small" style={{textAlign:"right"}} onChange={(e)=>soloNumeros(e,setvalueCantidadProd, 20)} value={valueCantidadProd}></Input>
              </Row>
            </Col>
            <Col xs={6} sm={8} md={2} style={{ marginLeft: "4px" }}>
              <Row justify="center">
                <Title style={{ fontSize: "8px", textAlign: "center" }}>Bonificación</Title>
              </Row>
              <Row>
                <Input size="small" style={{textAlign:"right"}} onChange={(e)=>soloNumeros(e,setvalueBoniProd)} value={valueBoniProd}></Input>
              </Row>
            </Col>
            <Col xs={6} sm={9} md={2} style={{ marginLeft: "4px" }}>
              <Row justify="center">
                <Title style={{ fontSize: "8px", textAlign: "center" }}>Precio</Title>
              </Row>
              <Row>
                <Input size="small" style={{textAlign:"right"}} onChange={(e)=>soloNumeros(e, setvaluePrecioProd)} value={valuePrecioProd}></Input>
              </Row>
            </Col>
            <Col xs={6} sm={9} md={2} style={{ marginLeft: "4px" }}>
              <Row justify="center">
                <Title style={{ fontSize: "8px", textAlign: "center" }}>Descuento</Title>
              </Row>
              <Row>
                <Input size="small" style={{textAlign:"right"}} onChange={(e)=>soloNumeros(e, setvalueDescProd)} value={valueDescProd}></Input>
              </Row>
            </Col>
            <Col>
              <Button type="primary" size="small" style={{ marginTop: "12px", marginLeft: "15px" }} onClick={btnAgregar}>
                Agregar
              </Button>
            </Col>
          </Row>
          </Row> 
          <Row >
            <Col sm={24} md={24} style={{height: '500px',}} >
            <Table 
                 scroll={{y:500}}
                 style={{height: '100%',}}
                  columns={t_productos as DataType[]}
                  size="small"
                  //dataSource={}
                  pagination={false}
                  footer={(data) => {
                    let total = 0//dataTable.reduce((total, fila) => total + fila.vtotal, 0)
                    return (
                      <>
                        <Row style={{ textAlign: "right" }}>
                          <Col sm={18} md={20} style={{ padding: "5px" }}>
                            <Title level={4}>Total</Title>{" "}
                          </Col>
                          <Col sm={6} md={4} style={{padding: "5px" }}>
                            <Title level={4}>{total.toFixed(2)}</Title>
                          </Col>
                        </Row>
                      </>
                    )
                  }}
                />
            </Col>
          
          </Row>
        </Col>
        <Col sm={24} md={11}  style={{paddingLeft:'5px'}} >
          <ComponentLibretaContactos></ComponentLibretaContactos>
          <Row style={{ borderStyle: "solid", borderColor: "#D5D8DC", borderWidth: "1px", borderRadius: "5px", paddingLeft: "5px",marginTop:'2px', height:'100px' }}>
          <Row justify="start">
          <Title style={{ fontSize: "11px", marginTop:'2px' }}>Visualizar Convenios</Title>
        </Row>
          </Row>
          <Row style={{ borderStyle: "solid", borderColor: "#D5D8DC", borderWidth: "1px", borderRadius: "5px", paddingLeft: "5px",marginTop:'2px', height:'150px' }}>
          <Row justify="start">
          <Title style={{ fontSize: "11px", marginTop:'2px' }}>Visualizar ultimas 6 compras</Title>
        </Row>
          <Table
          scroll={{y:80}}
          style={{width: '100%',}}
          columns={t_comprasultimas as DataTypeUltCompras[]}
          size="small"
          />
          </Row>
          <Row justify="space-between" align={"top"}>
            <Col md={10}>
              <Row style={{ borderStyle: "solid", borderColor: "#D5D8DC", borderWidth: "1px", borderRadius: "5px", paddingLeft: "5px",marginTop:'2px', height:'300px' , marginRight:'2px'}}>
              <Title style={{ fontSize: "11px", marginTop:'2px' }}>Ventas Mensuales</Title>
              <Table
              //scroll={{y:'100%'}}
              style={{width: '100%', height:'100%'}}
              columns={t_ventasmensuales as DataTypeVM[]}
              size="small"
              />
              </Row>
            </Col>
            <Col md={14} >
              <Row style={{ borderStyle: "solid", borderColor: "#D5D8DC", borderWidth: "1px", borderRadius: "5px", paddingLeft: "5px",marginTop:'2px', height:'150px' }}>
              <Title style={{ fontSize: "11px", marginTop:'2px' }}>Stock Global</Title>
              <Table
              scroll={{y: 90}}
              style={{width: '100%'}}
              columns={t_StockGlobal as DataTypeStockG[]}
              size="small"
              />
              </Row>
              <Row style={{ borderStyle: "solid", borderColor: "#D5D8DC", borderWidth: "1px", borderRadius: "5px", paddingLeft: "5px",marginTop:'2px', height:'150px' }}>
              <Title style={{ fontSize: "11px", marginTop:'2px' }}>Cambiar de Proveedor a un Producto</Title>
              <Table
              scroll={{y: 90}}
              style={{width: '100%'}}
              columns={t_CambiarProveedorProd as DataTypeCambiarProveerdor[]}
              size="small"
              />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>


      <Modal
        title={`Escala ${titleModalEscala}`}
        open={showModalEscala}
        onCancel={CancelEscala}
        footer={[
        ]}
      >
        <p>{titleModalEscala}</p>
        <Input size="small" placeholder={`Nombre del ${titleModalEscala}`}/>
        <Row style={{marginTop:'6px'}}>
          <Col xs={6} sm={6} md={7} >
            <Row align={"middle"} style={{marginRight:'4px'}}>
              <Col xs={9} sm={9} md={9} style={{fontSize:'10px'}}> Cantidad</Col>
              <Col xs={14} sm={14} md={14} > <Input size="small"/></Col>
            </Row>
          </Col>
          <Col xs={6} sm={6} md={7}>
            <Row align={"middle"} style={{marginRight:'4px'}}>
              <Col xs={10} sm={10} md={10} style={{fontSize:'10px'}}>Bonificación</Col>
              <Col xs={13} sm={13} md={13} > <Input size="small"/></Col>
            </Row>
          </Col>
          <Col xs={6} sm={6} md={7}>
            <Row align={"middle"}>
              <Col xs={9} sm={9} md={9} style={{fontSize:'10px'}}>Descuento</Col>
              <Col xs={13} sm={13} md={13} > <Input size="small"/></Col>
            </Row>
          </Col>
          <Col xs={6} sm={6} md={3}>
            <Row justify={"end"}>
            <Button type="primary" size="small" >Agregar</Button>
          </Row>
            </Col>
        </Row>
        <Row style={{marginTop:'10px',  borderStyle: "solid", borderColor: "#D5D8DC", borderWidth: "1px", borderRadius: "5px",}}>
          <Table size="small"
          scroll={{y: 100}}
          style={{width:'100%', textAlign: 'end', height:'150px'}}
           columns={t_EscalaProducto as DataTypeEScalaProducto[]}
          />
        </Row>

       {/* {Kitmsg &&<label style={{color: 'red',fontSize: '10px'}}>*Ya exite el nombre del Kit. Por favor agregue otro</label>} */}
      </Modal>


      <Modal
        title="Descuento Masivo"
        open={showModalDescMasivo}
        onCancel={CancelDescMasivo}
        footer={[
          <Button key="back" onClick={CancelDescMasivo}>
            Cerrar
          </Button>,
          <Button type="primary" key="submit">
            Aceptar
          </Button>,
        ]}
      >
        <p>Ingrese el descuento</p>
        <Input />
       {/* {Kitmsg &&<label style={{color: 'red',fontSize: '10px'}}>*Ya exite el nombre del Kit. Por favor agregue otro</label>} */}
      </Modal>
    </>
  )
}
