import { Col, Row, DatePicker, Table, Card, Modal, Select, Button, Spin } from "antd";
import "moment/locale/es";

import moment from "moment";
import { useControlInventarioEspecial } from "hooks/inventarios/inventarioEspecial/useControlInventario";
import { useVT } from "virtualizedtableforantd4";

export function ControlInventarioEspecial() {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);
  const {
    columns,
    mes,
    setMes,
    filteredData,
    datosPeriodos,
    handleCancelHistorico,
    isModalOpenHistorico,
    columsDatatableDetalleHistorico,
    datosHistoricos,
    estadoBotonFinalizar,
    /*handleClickDownloadDataHistorico,
    setRowClassName,*/
    setBodega,
    estadoBotones,
    bodega,
    dataEstado,
    handleChangeButton,
    handleChangeButtonTerminar,
    estadoBotonesFin
  } = useControlInventarioEspecial();
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={12} md={14} lg={16} xl={18}>
        <Select
          placeholder="Selecione una bodega"
          style={{ width: 250, marginRight: "10px", marginTop: "0px" }}
          onChange={(value) => setBodega(value)}
          options={datosPeriodos}
        />
        <DatePicker
          value={mes}
          picker="month"
          placeholder="Seleccionar mes"
          format="MMMM [del] YYYY"
          onChange={(value) => setMes(value ?? moment())}
          inputReadOnly
        />
        <Button
          type="primary"
          style={{ marginLeft: "5px" }}
          //icon={<PoweroffOutlined />}
          loading={estadoBotones}
          disabled={bodega === undefined}
          onClick={handleChangeButton}
        >
          Generar reporte
        </Button>
      </Col>
      <Col xs={24} sm={12} md={10} lg={8} xl={6}>
        <Row gutter={[10, 10]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
          {dataEstado && (
            <Button
              type="primary"
              style={{ marginLeft: "5px" }}
              //icon={<PoweroffOutlined />}
              loading={estadoBotonesFin}
              disabled={estadoBotonFinalizar}
              onClick={handleChangeButtonTerminar}
            >
              Terminar inventario
            </Button>
          )}
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          {!dataEstado && estadoBotones && (
            <div style={{ textAlign: "center" }}>
              <div style={{ margin: "0 auto" }}>
                <Spin />
              </div>
            </div>
          )}
          {dataEstado && (
            <Table
              columns={columns}
              dataSource={filteredData}
              components={vt}
              size="small"
              scroll={{ x: 900, y: "60vh" }}
              pagination={false}
              //pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
            />
          )}
        </Card>
      </Col>
      <Modal
        footer={false}
        transitionName=""
        title="Reporte inventario HandHeld"
        open={isModalOpenHistorico}
        onCancel={handleCancelHistorico}
        width={"90wh"}
      >
        <Table
          pagination={false}
          loading={columsDatatableDetalleHistorico ? false : true}
          // rowClassName={setRowClassName}
          scroll={{ x: 900, y: "55vh" }}
          size="small"
          bordered
          columns={columsDatatableDetalleHistorico}
          dataSource={datosHistoricos}
        />
      </Modal>
    </Row>
  );
}

export default ControlInventarioEspecial;
