import { Col, Modal, Row, Table, Typography, type TableProps, Descriptions } from "antd"
import { Sueldos } from "hooks/rrhh/vacaciones/usePago"

import { usePago } from "hooks/rrhh/vacaciones/usePago"

import styles from "pages/contentrecursoshumanos/contentvacaciones/styles/pago.module.css"

const pageData: TableProps<Sueldos>["summary"] = pageData => {
  const sumaSueldos = pageData.reduce((acum, next) => acum + next.sueldo, 0)
  const promedio = sumaSueldos / pageData.length
  return (
    <>
      <Table.Summary.Row className={styles.rowSumaryStyle}>
        <Table.Summary.Cell index={0} className={styles.cellSumary}>
          <Typography.Text strong>Total</Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} className={styles.cellSumary + " " + styles.alignTextRight}>
          <Typography.Text strong>{sumaSueldos}</Typography.Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row className={styles.rowSumaryStyle}>
        <Table.Summary.Cell index={0} className={styles.cellSumary}>
          <Typography.Text strong>Promedio</Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} className={styles.cellSumary + " " + styles.alignTextRight}>
          <Typography.Text strong>
            {promedio.toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,")}
          </Typography.Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  )
}

export function PagoVacaciones() {
  const {
    isWeb,
    solicitudes,
    columnsTable,
    columnsSueldos,
    pageSize,
    setPageSize,
    openModal,
    handleOkModal,
    setOpenModal,
    selectedSolicitud,
    promedio,
  } = usePago()

  return (
    <>
      <Row wrap className={styles.rowStyle} gutter={isWeb ? [16, 16] : [5, 5]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            dataSource={solicitudes}
            columns={columnsTable}
            scroll={{ x: true }}
            size="small"
            pagination={{
              pageSize,
              size: "default",
              onShowSizeChange: (_, size) => setPageSize(size),
              pageSizeOptions: [7, 10, 20, 50, 100],
            }}
          />
        </Col>
      </Row>
      <Modal
        open={openModal}
        style={{ top: "5px" }}
        title="Pago de vacaciones"
        transitionName=""
        onCancel={() => setOpenModal(false)}
        okText="Guardar pago"
        onOk={handleOkModal}
      >
        <Row gutter={[16, 16]}>
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <Table
              columns={columnsSueldos}
              rowKey={"mes"}
              // size="small"
              bordered
              rowClassName={styles.rowTableStyle}
              pagination={false}
              dataSource={solicitudes.find(({ key }) => selectedSolicitud === key)?.sueldos}
              summary={pageData}
            />
          </Col>
          <Col xs={14} sm={14} md={14} lg={14} xl={14}>
            <Descriptions layout="vertical" column={1} bordered>
              <Descriptions.Item label="Empleado" labelStyle={{ fontWeight: "bold" }}>
                {solicitudes.find(({ key }) => selectedSolicitud === key)?.empleado}
              </Descriptions.Item>
              <Descriptions.Item
                label="Días a pagar"
                labelStyle={{ fontWeight: "bold" }}
                contentStyle={{ display: "block", textAlign: "right" }}
              >
                {solicitudes.find(({ key }) => selectedSolicitud === key)?.dias_pagar}
              </Descriptions.Item>
              <Descriptions.Item
                label="Valor de vacaciones por día"
                labelStyle={{ fontWeight: "bold" }}
                contentStyle={{ display: "block", textAlign: "right" }}
              >
                {(promedio / 15).toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,")}
              </Descriptions.Item>
              <Descriptions.Item
                label="Total pago"
                labelStyle={{ fontWeight: "bold" }}
                contentStyle={{ display: "block", textAlign: "right", fontWeight: "bolder" }}
              >
                {((promedio / 15) * (solicitudes.find(({ key }) => selectedSolicitud === key)?.dias_pagar ?? 0))
                  .toFixed(2)
                  .replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,")}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Modal>
    </>
  )
}
