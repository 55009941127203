import { SVGProps } from "react"

export function Logo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      preserveAspectRatio="none"
      xmlSpace="preserve"
      id="svg5296"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 388.3 77.2"
      { ...props }
    >
      <style type="text/css" id="style5204">
        {".st16{fill:#6a2831}"}
      </style>
      <g transform="translate(504.4 -9.3)" fill="#fff" id="g5294">
        <path
          className="st16"
          d="M-486 24.1c-2.1 0-3.7.6-5 1.8s-1.9 2.6-1.9 4.3.5 3 1.5 4.1 2.3 2 3.8 2.9 3.1 1.7 4.9 2.5c1.7.8 3.4 1.8 4.9 2.9s2.8 2.5 3.8 4.2c1 1.6 1.5 3.7 1.5 6.3 0 2.3-.4 4.3-1.3 6.2-.8 1.9-2.1 3.5-3.6 4.8-1.6 1.3-3.4 2.3-5.6 3s-4.6 1-7.3 1c-2.4 0-4.6-.3-6.5-.8s-3.3-1.2-4.3-1.9c.4-1.6.8-3 1.1-4.3s.6-2.7.8-4.3h1.3c.2 1 .5 1.9 1 2.8s1.1 1.7 1.9 2.3c.8.7 1.7 1.2 2.7 1.6 1.1.4 2.3.6 3.6.6 2.7 0 4.7-.7 5.9-2 1.3-1.3 1.9-3 1.9-4.9 0-1.7-.5-3.1-1.5-4.2s-2.2-2-3.7-2.9c-1.5-.8-3.1-1.6-4.8-2.4s-3.3-1.7-4.8-2.9c-1.5-1.1-2.7-2.5-3.7-4.2-1-1.6-1.5-3.7-1.5-6.3 0-2.3.4-4.4 1.2-6.2s1.9-3.3 3.3-4.5 3-2.1 4.8-2.8c1.8-.6 3.7-.9 5.7-.9 2.4 0 4.5.3 6.3.9s3.4 1.4 4.7 2.4c-.4 1-.7 2-1 3s-.7 2.2-1.1 3.7h-1c-1.3-3.9-4-5.8-8-5.8z"
          id="path5206"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-458.9 35c2.2-.8 4.3-1.2 6.5-1.2 1.8 0 3.4.2 4.9.6s2.8 1 3.9 1.9 1.9 2 2.5 3.4.9 3.1.9 5.1c0 2.9 0 5.5-.1 8s-.1 4.9-.1 7.3c0 1.1.1 1.9.2 2.5.2.6.4 1.1.7 1.4s.7.5 1.2.6 1.1.1 1.8.1v1.6c-1.3.5-2.4.9-3.3 1.2s-1.9.4-2.9.4c-1.7 0-3-.4-4-1.2s-1.8-2-2.3-3.5c-1 1.4-2.2 2.6-3.6 3.4-1.4.9-3.1 1.3-5.1 1.3-1.7 0-3.1-.2-4.4-.7-1.2-.4-2.2-1.1-3-1.9s-1.4-1.8-1.7-2.8c-.4-1.1-.5-2.1-.5-3.2 0-1.5.2-2.8.7-3.8.5-1.1 1.1-2 2-2.8s1.9-1.4 3.1-1.9 2.6-1 4.1-1.3c1.9-.4 3.4-.8 4.5-1.2 1.1-.3 1.9-.7 2.4-1s.9-.7 1-1.1c.2-.4.2-.9.2-1.5 0-1.6-.6-3-1.7-4.2-1.1-1.1-2.7-1.7-4.7-1.7-.8 0-1.5.1-2.3.3s-1.6.5-2.3.8c-.7.4-1.4.8-2 1.2s-1.1.9-1.4 1.4h-.5l-.9-4.1c2-1.4 4-2.6 6.2-3.4zm9.8 17.5v-2.7c-.9.5-1.9 1-3 1.3-1 .3-2 .8-2.9 1.3s-1.6 1.2-2.1 2.1c-.6.9-.8 2.2-.8 3.9s.3 2.9 1 3.8 1.6 1.3 2.8 1.3c1.5 0 2.7-.6 3.6-1.7s1.4-2.8 1.4-5v-4.3z"
          id="path5208"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-422.3 39.7c1.2-1.9 2.7-3.4 4.4-4.4s3.6-1.6 5.7-1.6c3.3 0 5.9 1 7.7 2.9 1.8 2 2.7 5.1 2.7 9.5 0 2.3 0 4.3-.1 6.1s-.1 3.5-.1 5.3c0 1.4 0 3 .1 4.7s.1 3.3.2 4.9c-.6-.1-1.3-.1-2.2-.1h-5.6c-.9 0-1.6 0-2.2.1.3-2.3.5-5.1.6-8.3s.1-6.8.1-10.9c0-2.8-.5-4.9-1.4-6.2-1-1.3-2.3-2-4.1-2-2 0-3.5.9-4.4 2.6-.9 1.8-1.4 4.2-1.4 7.3v2.7c0 2.7 0 5.3.1 7.8.1 2.4.2 4.8.4 7-.6-.1-1.3-.1-2.2-.1h-5.6c-.8 0-1.6 0-2.2.1.2-2.3.4-4.6.4-7 .1-2.4.1-5 .1-7.8v-2.7c0-2.7 0-5.3-.1-7.7s-.2-4.8-.4-7.3c.8.1 1.6.2 2.4.2.8.1 1.6.1 2.4.1s1.6 0 2.4-.1 1.6-.1 2.5-.2l-.3 5.1z"
          id="path5210"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-344.3 27.2c-2.1-1.2-4.8-1.9-7.8-1.9-2.1 0-4.1.4-6.1 1.3s-3.8 2.2-5.4 3.9c-1.6 1.8-2.9 3.9-3.9 6.6-1 2.6-1.5 5.7-1.5 9.3 0 2.7.4 5.2 1.1 7.5s1.8 4.3 3.2 6 3.1 3 5.1 3.9 4.3 1.4 6.9 1.4c.6 0 1.2 0 2-.1.7-.1 1.5-.1 2.1-.2.7-.1 1.3-.2 1.9-.4s1-.3 1.3-.5c.1-.5.1-1.2.2-2v-2.7c0-3.6-.1-6.3-.2-8.1-.1-1.7-.2-2.9-.3-3.5.4.1 1 .2 1.8.2.7.1 1.8.1 3.2.1 1.6 0 2.8 0 3.6-.1s1.4-.1 1.8-.2c-.1.4-.2.9-.2 1.8-.1.8-.1 1.7-.2 2.8 0 1.1-.1 2.2-.1 3.4v8.7c0 1 .1 1.7.2 2.1-2.9.9-5.9 1.5-8.9 2s-6 .7-8.9.7c-3.8 0-7.4-.5-10.6-1.5s-6-2.5-8.4-4.5c-2.3-2-4.2-4.3-5.5-7.1s-2-5.9-2-9.3c0-4.3.7-8 2.1-11.2s3.4-5.9 5.8-8c2.5-2.1 5.4-3.7 8.8-4.8s7.1-1.6 11-1.6c3.4 0 6.3.3 8.8 1s4.9 1.7 7.3 3c-.4 1.2-.7 2.3-1 3.4s-.6 2.3-.8 3.4h-.9c-1.6-2-3.4-3.6-5.5-4.8z"
          id="path5212"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-316.7 44.3c1.2-3.1 2.6-5.4 4.2-6.9 1.7-1.5 3.6-2.2 5.9-2.2h1.1c-.1.7-.2 1.4-.3 2.2s-.1 1.7-.1 2.7V43.4c0 .4 0 .8.1 1.1l-.6.5c-.4-.3-.9-.5-1.5-.7s-1.2-.3-1.9-.3c-2 0-3.6.7-4.8 2s-1.7 2.9-1.7 4.7v2.7c0 2.7 0 5.3.1 7.8.1 2.4.2 4.8.4 7-.6-.1-1.3-.1-2.2-.1h-5.6c-.8 0-1.6 0-2.2.1.2-2.3.3-4.6.4-7s.1-5 .1-7.8v-2.7c0-2.7 0-5.3-.1-7.7s-.2-4.8-.4-7.3c.8.1 1.5.2 2.3.2.8.1 1.5.1 2.3.1s1.5 0 2.3-.1 1.5-.1 2.3-.2l-.2 8.2z"
          id="path5214"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-289.9 57.3c.4 1.6.9 2.9 1.6 4.1.7 1.1 1.7 2 2.8 2.7s2.5 1 4 1c3.1 0 5.8-1 8.2-3l.8.6-1.5 3.8c-1.1.9-2.5 1.5-4 2.1-1.6.5-3.6.8-6.2.8-2.2 0-4.3-.3-6.2-.9-2-.6-3.7-1.6-5.2-3s-2.7-3.2-3.6-5.3-1.3-4.7-1.3-7.7c0-2.9.4-5.5 1.2-7.6.8-2.2 1.9-4 3.2-5.4 1.4-1.4 3-2.5 4.9-3.2s4-1.1 6.2-1.1c4.4 0 7.8 1.3 10.1 3.9s3.5 6.3 3.5 11.1v1.4c0 .4 0 .7-.1.8h-19.2c.3 1.6.5 3.3.8 4.9zm8.6-16.2c-.7-2-2-3-3.7-3-1 0-1.8.3-2.5 1s-1.2 1.5-1.6 2.6-.7 2.3-.9 3.6-.3 2.6-.4 3.9h10.3c-.1-3.4-.5-6.1-1.2-8.1z"
          id="path5216"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-242.7 38.5c1.1.8 2 1.8 2.6 3.1.6 1.2 1 2.7 1 4.4s-.3 3.2-.9 4.6-1.5 2.6-2.6 3.5c-1.1 1-2.5 1.8-4.1 2.3s-3.4.8-5.3.8h-3c-.5 0-1 .2-1.4.7s-.6 1.1-.6 1.7c0 1.1.4 1.9 1.2 2.6.8.6 2.1 1 3.9 1l5 .1c3.8 0 6.5.8 8.3 2.1 1.8 1.4 2.6 3.6 2.6 6.6 0 2.7-.5 5-1.6 6.8s-2.5 3.3-4.2 4.5c-1.7 1.1-3.6 2-5.7 2.5s-4.1.7-6.1.7c-4.2 0-7.5-.8-9.8-2.5-2.3-1.6-3.5-3.8-3.5-6.5 0-2.2.6-3.9 1.8-5.2s2.8-2.4 4.8-3.1v-.1c-1.2-.4-2.1-1-2.8-1.9-.7-.8-1-1.9-1-3.1 0-1.5.6-2.9 1.7-4.3s2.6-2.3 4.5-2.8v-.1c-2.7-.7-4.8-1.9-6.3-3.6-1.5-1.8-2.3-4.1-2.3-7 0-1.7.3-3.2 1-4.6.7-1.3 1.7-2.5 2.9-3.4s2.7-1.6 4.5-2.1c1.7-.5 3.7-.7 5.8-.7.8 0 1.6 0 2.5.1.8.1 1.7.1 2.5.2.6 0 1.2.1 1.8.1h9.1c0 .3-.1.5-.1.8v1.6c0 .3.1.5.1.8l-5.9-.3zm-1.8 33.8c-.4-.8-1.1-1.5-1.9-1.9s-1.8-.8-3-.9c-1.2-.2-2.6-.2-4.1-.2-2.3 0-3.9.2-4.8.7-.5.3-1.1.9-1.7 2-.6 1-.9 2.3-.9 3.9 0 1.3.2 2.4.7 3.4s1.1 1.8 1.9 2.4 1.7 1.1 2.8 1.5c1 .3 2.2.5 3.4.5 1.3 0 2.5-.2 3.5-.6s1.9-1 2.6-1.8c.7-.7 1.3-1.6 1.7-2.6s.6-2 .6-3.1c-.1-1.4-.3-2.5-.8-3.3zm-12.8-25.6c0 1.5.1 2.7.4 3.7s.6 1.8 1 2.5c.4.6.9 1.1 1.4 1.3.5.3 1 .4 1.6.4 1.5 0 2.6-.7 3.3-2s1.1-3.5 1.1-6.6-.4-5.2-1.2-6.4-1.8-1.9-3-1.9c-3 .1-4.6 3.1-4.6 9z"
          id="path5218"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-230.7 45.1c.9-2.2 2.1-4.1 3.7-5.6 1.5-1.5 3.3-2.6 5.3-3.4 2-.7 4-1.1 6.1-1.1 5.2 0 9.3 1.5 12.1 4.5 2.9 3 4.3 7 4.3 12.1 0 2.7-.4 5.2-1.2 7.4s-1.9 4.1-3.4 5.6c-1.5 1.6-3.2 2.7-5.2 3.5s-4.2 1.1-6.6 1.1c-2 0-4-.3-5.9-.8s-3.7-1.5-5.3-2.7c-1.6-1.3-2.8-3-3.8-5-1-2.1-1.4-4.6-1.4-7.6-.1-3.1.3-5.7 1.3-8zm20-3.8c-1-2.2-2.6-3.3-4.9-3.3-2.1 0-3.7 1.2-4.9 3.6s-1.8 6.2-1.8 11.4c0 1.9.1 3.6.3 5.3.2 1.6.6 3 1.1 4.2s1.2 2.1 2 2.8 1.9 1 3.1 1c4.3 0 6.4-5.3 6.4-15.9.2-3.8-.3-6.9-1.3-9.1z"
          id="path5220"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-183.9 44.3c1.2-3.1 2.6-5.4 4.2-6.9 1.7-1.5 3.6-2.2 5.9-2.2h1.1c-.1.7-.2 1.4-.3 2.2s-.1 1.7-.1 2.7V43.4c0 .4 0 .8.1 1.1l-.6.5c-.4-.3-.9-.5-1.5-.7s-1.2-.3-1.9-.3c-2 0-3.6.7-4.8 2s-1.7 2.9-1.7 4.7v2.7c0 2.7 0 5.3.1 7.8.1 2.4.2 4.8.4 7-.6-.1-1.3-.1-2.2-.1h-5.6c-.8 0-1.6 0-2.2.1.2-2.3.3-4.6.4-7s.1-5 .1-7.8v-2.7c0-2.7 0-5.3-.1-7.7s-.2-4.8-.4-7.3c.8.1 1.5.2 2.3.2.8.1 1.5.1 2.3.1s1.5 0 2.3-.1 1.5-.1 2.3-.2l-.2 8.2z"
          id="path5222"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-165.5 19.4c1-1 2.3-1.6 3.8-1.6s2.7.5 3.8 1.6c1 1 1.5 2.3 1.5 3.8s-.5 2.7-1.5 3.8c-1 1-2.3 1.6-3.8 1.6s-2.7-.5-3.8-1.6c-1-1-1.5-2.3-1.5-3.8s.5-2.8 1.5-3.8zm-.8 23.8c-.1-2.4-.2-4.8-.4-7.3.6.1 1.4.2 2.2.2.8.1 1.8.1 2.8.1 2.1 0 3.8-.1 5-.3-.2 2.4-.4 4.8-.4 7.3-.1 2.4-.1 5-.1 7.7v2.7c0 2.7 0 5.3.1 7.8.1 2.4.2 4.8.4 7-.6-.1-1.3-.1-2.2-.1h-5.6c-.8 0-1.6 0-2.2.1.2-2.3.4-4.6.4-7 .1-2.4.1-5 .1-7.8v-2.7c0-2.8 0-5.3-.1-7.7z"
          id="path5224"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-147.6 45.1c.9-2.2 2.1-4.1 3.7-5.6 1.5-1.5 3.3-2.6 5.3-3.4 2-.7 4-1.1 6.1-1.1 5.2 0 9.3 1.5 12.1 4.5 2.9 3 4.3 7 4.3 12.1 0 2.7-.4 5.2-1.2 7.4s-1.9 4.1-3.4 5.6c-1.5 1.6-3.2 2.7-5.2 3.5s-4.2 1.1-6.6 1.1c-2 0-4-.3-5.9-.8s-3.7-1.5-5.3-2.7c-1.6-1.3-2.8-3-3.8-5-1-2.1-1.4-4.6-1.4-7.6 0-3.1.4-5.7 1.3-8zm20.1-3.8c-1-2.2-2.6-3.3-4.9-3.3-2.1 0-3.7 1.2-4.9 3.6s-1.8 6.2-1.8 11.4c0 1.9.1 3.6.3 5.3.2 1.6.6 3 1.1 4.2s1.2 2.1 2 2.8 1.9 1 3.1 1c4.3 0 6.4-5.3 6.4-15.9.2-3.8-.3-6.9-1.3-9.1z"
          id="path5226"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-320.3 12.7c0-1-.1-2.1-.1-3.1.7 0 1.4 0 2.1.1H-313c.9 0 1.5-.1 1.9-.1-.1.3-.1.6-.1 1v.6c0 .1 0 .3.1.5-.5 0-1-.1-1.5-.1-.6 0-1.1-.1-1.6-.1s-1-.1-1.4-.1h-.9c-.1.9-.1 1.8-.1 2.6v2.6c1 0 2 0 2.8-.1.8 0 1.7-.1 2.6-.1 0 .3-.1.5-.1.6v.9c0 .1.1.3.1.6-1.8-.1-3.7-.2-5.5-.2V23c0 1 .1 2.1.1 3h-4c.1-1 .1-2 .1-3v-6.8c.2-1.3.2-2.5.2-3.5z"
          id="path5228"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-295.8 26.1h-4.1c-.3-.8-.5-1.6-.8-2.4s-.6-1.6-.9-2.5h-5.6c-.3.8-.6 1.6-.9 2.5-.3.8-.5 1.6-.7 2.4h-2.4c.2-.4.4-1 .8-1.7.3-.7.7-1.6 1.1-2.5s.8-1.9 1.2-3 .9-2.1 1.3-3.2.9-2.2 1.3-3.2c.4-1.1.8-2 1.2-3 .2 0 .3.1.5.1h1c.1 0 .3-.1.5-.1.5 1.4 1.1 2.8 1.6 4.4.6 1.6 1.2 3.1 1.8 4.6s1.2 2.9 1.7 4.3c.4 1.3 1 2.4 1.4 3.3zm-8.5-12c-.4 1-.8 1.9-1.2 2.9-.4.9-.7 1.9-1.1 2.9h4.4c-.4-1-.7-1.9-1-2.9s-.7-1.9-1.1-2.9z"
          id="path5230"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-289.7 18.6v4.5c0 1 .1 2.1.1 3h-4c.1-1 .1-2 .1-3V12.8c0-1-.1-2.1-.1-3.1.3 0 .6.1.9.1h1.1c.4 0 .9 0 1.5-.1.6 0 1.2-.1 2.1-.1h1.6c.4 0 .8.1 1.1.1.3.1.6.1.8.3.2.1.5.2.7.4.5.4.9.8 1.1 1.3.3.5.4 1.1.4 1.9 0 1.2-.3 2.1-1 2.9-.6.7-1.6 1.3-2.8 1.6.8 1.4 1.6 2.7 2.5 4.1s1.8 2.7 2.6 4h-4.4zm3.6-6.2c-.1-.4-.4-.7-.7-1-.3-.2-.7-.4-1.2-.4-.5-.1-1-.1-1.7-.1 0 2.2-.1 4.4-.1 6.5h.6c1.1 0 1.9-.3 2.4-.9.6-.6.8-1.4.8-2.6.1-.5 0-1-.1-1.5z"
          id="path5232"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-277.2 20.7c-.1 1.8-.3 3.6-.4 5.4h-2.4c.5-2.8 1-5.5 1.4-8.2s.8-5.5 1.1-8.3c.2 0 .3.1.5.1h1c.2 0 .3-.1.5-.1.4 1 .9 1.9 1.4 2.9s.9 2 1.4 3 .9 2 1.4 2.9.9 1.8 1.4 2.7c1-1.8 1.9-3.7 2.9-5.6 1-2 1.9-3.9 2.7-5.9.4.1.7.1 1 .1s.6 0 1-.1c.2 1.4.3 2.9.5 4.5s.4 3.1.5 4.7c.2 1.5.4 2.9.5 4.2.2 1.3.3 2.4.5 3.2h-4c-.1-1.7-.2-3.4-.4-5.4l-.6-5.7c-.9 1.9-1.8 3.8-2.7 5.6s-1.8 3.7-2.6 5.5h-.8c-.9-1.8-1.7-3.6-2.6-5.4s-1.7-3.6-2.7-5.5c-.2 1.8-.4 3.6-.5 5.4z"
          id="path5234"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-243.6 26.1h-4.1c-.3-.8-.5-1.6-.8-2.4s-.6-1.6-.9-2.5h-5.6c-.3.8-.6 1.6-.9 2.5-.3.8-.5 1.6-.7 2.4h-2.4c.2-.4.4-1 .8-1.7.3-.7.7-1.6 1.1-2.5s.8-1.9 1.2-3 .9-2.1 1.3-3.2.9-2.2 1.3-3.2c.4-1.1.8-2 1.2-3 .2 0 .3.1.5.1h1c.1 0 .3-.1.5-.1.5 1.4 1.1 2.8 1.6 4.4.6 1.6 1.2 3.1 1.8 4.6s1.2 2.9 1.7 4.3c.4 1.3.9 2.4 1.4 3.3zm-8.5-12c-.4 1-.8 1.9-1.2 2.9-.4.9-.7 1.9-1.1 2.9h4.4c-.4-1-.7-1.9-1-2.9-.4-1-.8-1.9-1.1-2.9z"
          id="path5236"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-229.2 24.4c-.1.3-.2.7-.2 1-1.3.7-2.9 1-4.9 1-1.4 0-2.6-.2-3.7-.6s-2-.9-2.8-1.7c-.8-.7-1.3-1.6-1.7-2.5-.4-1-.6-2-.6-3.2 0-1.7.3-3.1.8-4.2.5-1.2 1.2-2.1 2.1-2.8s1.9-1.3 3-1.6c1.2-.3 2.4-.5 3.6-.5.4 0 .9 0 1.3.1.5.1.9.1 1.3.3.4.1.8.2 1.2.4.4.1.7.3 1 .5-.2.4-.3.8-.4 1.2s-.1.8-.2 1.2h-.3c-.6-.8-1.2-1.3-1.9-1.6-.6-.3-1.4-.5-2.3-.5-.6 0-1.3.1-1.9.4s-1.2.7-1.8 1.3c-.5.6-1 1.4-1.3 2.3s-.5 2-.5 3.3c0 1.2.2 2.3.5 3.2s.8 1.6 1.3 2.1c.5.6 1.1 1 1.8 1.2.7.3 1.4.4 2.1.4.4 0 .8 0 1.2-.1s.8-.2 1.2-.4.7-.3 1.1-.6c.3-.2.6-.5.9-.7l.3.2c0 .2-.1.6-.2.9z"
          id="path5238"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-225.8 12.7c0-1-.1-2.1-.1-3.1.3 0 .6.1.9.1h2.2c.3 0 .6-.1.9-.1-.1 1-.1 2.1-.1 3.1V23c0 1 .1 2.1.1 3h-4c.1-1 .1-2 .1-3v-6.8c.1-1.3.1-2.5 0-3.5z"
          id="path5240"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-204.2 26.1h-4.1c-.3-.8-.5-1.6-.8-2.4s-.6-1.6-.9-2.5h-5.6c-.3.8-.6 1.6-.9 2.5-.3.8-.5 1.6-.7 2.4h-2.4c.2-.4.4-1 .8-1.7.3-.7.7-1.6 1.1-2.5s.8-1.9 1.2-3 .9-2.1 1.3-3.2.9-2.2 1.3-3.2c.4-1.1.8-2 1.2-3 .2 0 .3.1.5.1h1c.1 0 .3-.1.5-.1.5 1.4 1.1 2.8 1.6 4.4.6 1.6 1.2 3.1 1.8 4.6s1.2 2.9 1.7 4.3c.4 1.3.9 2.4 1.4 3.3zm-8.5-12c-.4 1-.8 1.9-1.2 2.9-.4.9-.7 1.9-1.1 2.9h4.4c-.4-1-.7-1.9-1-2.9-.4-1-.8-1.9-1.1-2.9z"
          id="path5242"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-197.6 10.8c-.7 0-1.3.2-1.8.7-.5.4-.7.9-.7 1.5s.2 1.1.5 1.5c.4.4.8.7 1.4 1 .5.3 1.1.6 1.8.9.6.3 1.2.6 1.8 1 .5.4 1 .9 1.4 1.5s.5 1.3.5 2.3c0 .8-.2 1.5-.5 2.2s-.7 1.2-1.3 1.7-1.2.8-2 1.1c-.8.2-1.6.4-2.6.4-.9 0-1.6-.1-2.3-.3s-1.2-.4-1.6-.7c.2-.6.3-1.1.4-1.5l.3-1.5h.5c.1.4.2.7.4 1s.4.6.7.8.6.4 1 .6c.4.1.8.2 1.3.2 1 0 1.7-.2 2.1-.7.5-.5.7-1.1.7-1.7s-.2-1.1-.5-1.5c-.4-.4-.8-.7-1.3-1s-1.1-.6-1.7-.9-1.2-.6-1.7-1-1-.9-1.3-1.5c-.4-.6-.5-1.3-.5-2.2 0-.8.1-1.6.4-2.2s.7-1.2 1.2-1.6 1.1-.8 1.7-1 1.3-.3 2.1-.3c.9 0 1.6.1 2.3.3s1.2.5 1.7.9c-.1.4-.3.7-.4 1.1-.1.3-.2.8-.4 1.3h-.4c-.8-1.7-1.7-2.4-3.2-2.4z"
          id="path5244"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          id="rect5246"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
          d="M-324.5 77.5h52.8v6.4h-52.8z"
        />
        <path
          className="st16"
          id="rect5248"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
          d="M-232.2 77.5H-119v6.4h-113.2z"
        />
        <path
          className="st16"
          id="rect5250"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
          d="M-504.4 77.5h11.4v6.4h-11.4z"
        />
        <ellipse
          cx={-433.4}
          cy={23.7}
          rx={31.5}
          ry={4.1}
          stroke="#fff"
          id="ellipse5252"
          style={{
            stroke: "#6a2831",
            strokeOpacity: 1,
            fill: "none",
            strokeWidth: 2.4569,
            strokeMiterlimit: 10,
          }}
        />
        <path
          className="st16"
          d="M-483.7 77.8c.5 0 .9.1 1.2.3s.4.4.4.6c0 .1-.1.3-.2.4s-.3.2-.4.2-.2 0-.3-.1c-.1 0-.2-.2-.3-.3-.3-.4-.6-.5-.8-.5s-.4.1-.6.3-.3.4-.3.6.1.4.2.6.4.5.9 1c.6.6 1 1.2 1 1.8s-.3 1.1-.8 1.5-1.2.6-2 .6c-.7 0-1.2-.1-1.5-.4s-.5-.6-.5-1c0-.2.1-.3.2-.5.1-.1.3-.2.5-.2s.3 0 .4.1.2.2.2.4v.4c0 .2.1.3.2.5.2.1.4.2.6.2.3 0 .6-.1.8-.1.2-.1.4-.3.5-.5s.2-.4.2-.6-.1-.4-.2-.6-.4-.5-.8-1c-.6-.6-.9-1.2-.9-1.8 0-.5.2-1 .7-1.4.3-.3.9-.5 1.6-.5z"
          id="path5254"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-476 78h1.2l-.8 2.8c-.5 1.6-.7 2.6-.7 2.8 0 .1 0 .2.1.2.1.1.1.1.2.1s.2 0 .3-.1c.2-.1.5-.4 1.1-.9l.3.3c-1.2 1.1-2 1.7-2.6 1.7-.2 0-.3-.1-.5-.2-.1-.1-.2-.3-.2-.6s.1-.7.2-1.1c-.5.5-1.1 1-1.9 1.4-.5.3-.9.4-1.3.4-.2 0-.4-.1-.5-.2s-.2-.3-.2-.6c0-.2 0-.4.1-.7s.3-1 .6-2c.4-1.3.6-2.1.6-2.3s-.1-.2-.2-.2-.2.1-.4.1c-.2.1-.5.3-.9.7l-.4-.3c.8-.6 1.4-1.1 1.8-1.3s.7-.3 1-.3c.1 0 .2 0 .3.1s.1.2.1.4c0 .4-.2 1.3-.6 2.7-.4 1.3-.6 2.1-.6 2.2s0 .2.1.3.2.1.3.1c.3 0 .8-.2 1.4-.7s1-1.1 1.2-1.7z"
          id="path5256"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-466.1 77.8c.5 0 .9.1 1.2.3s.4.4.4.6c0 .1-.1.3-.2.4s-.3.2-.4.2-.2 0-.3-.1c-.1 0-.2-.2-.3-.3-.3-.4-.6-.5-.8-.5s-.4.1-.6.3-.3.4-.3.6.1.4.2.6.4.5.9 1c.6.6 1 1.2 1 1.8s-.3 1.1-.8 1.5-1.2.6-2 .6c-.7 0-1.2-.1-1.5-.4s-.5-.6-.5-1c0-.2.1-.3.2-.5.1-.1.3-.2.5-.2s.3 0 .4.1.2.2.2.4v.4c0 .2.1.3.2.5.2.1.4.2.6.2.3 0 .6-.1.8-.1.2-.1.4-.3.5-.5s.2-.4.2-.6-.1-.4-.2-.6-.4-.5-.8-1c-.6-.6-.9-1.2-.9-1.8 0-.5.2-1 .7-1.4.4-.3.9-.5 1.6-.5z"
          id="path5258"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="m-458.3 77.6.6-.1-.6 2c-.3.8-.5 1.8-.7 2.8-.1.6-.2.9-.2 1.1s.1.3.2.3.2-.1.4-.2c.4-.2.7-.4 1-.7l.3.3c-.7.6-1.1.9-1.3 1-.4.2-.7.4-1.1.5-.2.1-.4.1-.6.1s-.3 0-.3-.1c-.1-.1-.1-.2-.1-.3s0-.2.1-.3l.3-1.1c-.5.6-1.1 1-1.8 1.4-.4.2-.8.4-1.2.4-.3 0-.6-.1-.8-.3-.2-.3-.4-.7-.4-1.2 0-.6.2-1.2.5-1.9s.8-1.3 1.3-1.9 1.1-1 1.7-1.3c.5-.2 1.1-.3 1.8-.3h.6zm-1.1 1.3c-.3-.1-.6-.1-.8-.1-.5 0-1 .1-1.4.4s-.7.7-1.1 1.4c-.3.7-.5 1.4-.5 2.1 0 .4.1.6.2.8s.3.2.5.2c.4 0 .8-.2 1.3-.6s.8-1 1-1.7z"
          id="path5260"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="m-453.2 82.6.3.3c-.4.5-1 .9-1.6 1.3s-1.1.6-1.5.6c-.2 0-.3-.1-.4-.2s-.2-.3-.2-.5c0-.4.2-1.3.5-2.7l.9-3.5c.3-1.3.6-2.1.9-2.6.4-.7.9-1.2 1.5-1.5.7-.4 1.3-.5 2.1-.5.2 0 .4.1.6.2.1.1.2.2.2.4s-.1.3-.2.4-.3.2-.6.2c-.2 0-.4 0-.7-.1-.3 0-.5-.1-.8-.1-.2 0-.4 0-.5.1-.2.1-.3.4-.5.8-.1.4-.4 1.2-.7 2.4l-.9 3.4c-.4 1.7-.6 2.6-.6 2.7 0 .2.1.3.2.3s.3-.1.6-.2c.4-.4.9-.8 1.4-1.2z"
          id="path5262"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-446.7 78h1.2l-.8 2.8c-.5 1.6-.7 2.6-.7 2.8 0 .1 0 .2.1.2.1.1.1.1.2.1s.2 0 .3-.1c.2-.1.5-.4 1.1-.9l.3.3c-1.2 1.1-2 1.7-2.5 1.7-.2 0-.3-.1-.5-.2-.1-.1-.2-.3-.2-.6s.1-.7.2-1.1c-.5.5-1.1 1-1.9 1.4-.5.3-.9.4-1.3.4-.2 0-.4-.1-.5-.2s-.2-.3-.2-.6c0-.2 0-.4.1-.7s.3-1 .6-2c.4-1.3.6-2.1.6-2.3s-.1-.2-.2-.2-.2.1-.4.1c-.2.1-.5.3-.9.7l-.4-.3c.8-.6 1.4-1.1 1.8-1.3s.7-.3 1-.3c.1 0 .2 0 .3.1s.1.2.1.4c0 .4-.2 1.3-.6 2.7-.4 1.3-.6 2.1-.6 2.2s0 .2.1.3.2.1.3.1c.3 0 .8-.2 1.4-.7s1-1.1 1.2-1.7z"
          id="path5264"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="m-437.8 82.9.2.3c-.8.6-1.3 1-1.7 1.2-.5.3-.9.4-1.2.4-.1 0-.2 0-.3-.1s-.1-.2-.1-.2c0-.1 0-.2.1-.5l.3-1c-.7.7-1.3 1.2-1.7 1.4-.5.2-.9.4-1.2.4s-.6-.1-.8-.4-.3-.6-.3-1.1c0-.8.3-1.6.8-2.6.6-1 1.2-1.7 1.9-2.2s1.3-.7 1.9-.7c.1 0 .3 0 .6.1.3-1 .5-1.8.8-2.3.2-.5.6-.9 1-1.2s.8-.6 1.3-.8 1-.3 1.4-.3c.2 0 .4.1.6.2.1.1.2.3.2.4 0 .2-.1.3-.2.4s-.3.2-.5.2c-.1 0-.4 0-.8-.1s-.7-.1-.8-.1c-.2 0-.4.1-.5.2-.2.1-.3.4-.4.7-.1.2-.3 1-.6 2.3l-1.2 4.9c-.1.6-.2 1-.2 1.1 0 .2.1.3.2.3s.2 0 .4-.1c-.2-.2.2-.4.8-.8zm-1.8-4.2c-.5-.1-.8-.1-1-.1-.4 0-.8.1-1.1.4-.4.3-.7.8-1.1 1.6-.3.8-.5 1.5-.5 2.1 0 .3.1.5.2.7.2.2.3.3.5.3s.5-.1.8-.3c.4-.3.8-.7 1-1.1.2-.3.4-.9.6-2z"
          id="path5266"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="m-428.3 82.5.3.4c-1.4 1.3-2.6 1.9-3.6 1.9-.4 0-.7-.1-.9-.4s-.3-.7-.3-1.2c0-1.2.4-2.3 1.1-3.4.8-1.3 1.8-1.9 3-1.9.4 0 .7.1.9.3s.3.5.3.8c0 .4-.1.7-.4 1s-.7.7-1.4 1.1c-.3.2-1 .5-2.1 1-.1.3-.1.6-.1.8 0 .4.1.6.2.8s.3.2.6.2c.2 0 .5-.1.8-.2.3-.3.9-.7 1.6-1.2zm-3-1.1c.9-.4 1.6-.8 2.1-1.3.3-.3.5-.6.5-.9 0-.2-.1-.4-.2-.6-.1-.1-.3-.2-.5-.2s-.4.1-.6.2c-.3.2-.5.5-.7.9-.3.6-.5 1.2-.6 1.9z"
          id="path5268"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="m-426.6 79.7-.3-.3c.7-.7 1.3-1.1 1.8-1.3.4-.2.8-.3 1.1-.3.1 0 .2 0 .3.1.1 0 .1.1.1.2v.3l-.4 1.6c.6-.6 1.3-1.2 2.2-1.7.5-.3.9-.4 1.2-.4.2 0 .4.1.5.2s.2.3.2.6c0 .2 0 .4-.1.6-.1.4-.3 1.1-.7 2.3-.4 1.1-.5 1.8-.5 2.2 0 .1 0 .2.1.2 0 .1.1.1.1.1.2 0 .4-.1.8-.5.3-.2.5-.4.8-.6l.2.3c-.5.5-1.1 1-1.6 1.3-.4.2-.7.3-.9.3s-.4-.1-.5-.2-.2-.4-.2-.7c0-.5.3-1.7 1-3.6.2-.7.3-1.1.3-1.2 0-.2-.1-.3-.3-.3s-.5.1-.9.3c-.5.3-1 .7-1.4 1.2-.2.3-.4.8-.6 1.4l-.7 2.8h-1.3c.2-.8.5-1.6.6-2.3l.8-3.1v-.3c0-.1-.1-.2-.2-.2s-.2.1-.4.2c-.4.2-.8.4-1.1.8z"
          id="path5270"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-412.2 79.2c.5-.5.9-.8 1.4-1.1.5-.2.8-.3 1.2-.3s.7.2 1 .5c.2.3.4.8.4 1.4 0 .9-.2 1.7-.7 2.6-.4.8-1 1.5-1.6 1.9-.7.4-1.3.7-1.9.7-.5 0-.9-.1-1.3-.4-.3-.3-.5-.7-.5-1.1s.1-.9.2-1.6l1-4.3c.2-1 .6-1.8.9-2.4.4-.5.9-1 1.5-1.3s1.2-.5 1.7-.5c.3 0 .6.1.7.2.2.1.2.2.2.4s-.1.3-.2.4-.3.2-.6.2c-.2 0-.5 0-1-.1-.2 0-.4-.1-.6-.1-.3 0-.5.1-.7.4-.1.2-.3.8-.5 1.7zm1.7-.5c-.3 0-.6.2-1 .5s-.8.9-1.1 1.7-.5 1.6-.5 2.4c0 .3.1.5.3.7s.4.3.7.3c.6 0 1.2-.5 1.7-1.4s.7-1.9.7-2.8c0-.4-.1-.8-.3-1-.1-.3-.3-.4-.5-.4z"
          id="path5272"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-401.6 78h1.2l-.8 2.8c-.5 1.6-.7 2.6-.7 2.8 0 .1 0 .2.1.2.1.1.1.1.2.1s.2 0 .3-.1c.2-.1.5-.4 1.1-.9l.3.3c-1.2 1.1-2 1.7-2.6 1.7-.2 0-.3-.1-.5-.2-.1-.1-.2-.3-.2-.6s.1-.7.2-1.1c-.5.5-1.1 1-1.9 1.4-.5.3-.9.4-1.3.4-.2 0-.4-.1-.5-.2s-.2-.3-.2-.6c0-.2 0-.4.1-.7s.3-1 .6-2c.4-1.3.6-2.1.6-2.3s-.1-.2-.2-.2-.2.1-.4.1c-.2.1-.5.3-.9.7l-.4-.3c.8-.6 1.4-1.1 1.8-1.3s.7-.3 1-.3c.1 0 .2 0 .3.1s.1.2.1.4c0 .4-.2 1.3-.6 2.7-.4 1.3-.6 2.1-.6 2.2s0 .2.1.3.2.1.3.1c.3 0 .8-.2 1.4-.7s1-1.1 1.2-1.7z"
          id="path5274"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="m-394.8 82.5.3.4c-1.4 1.3-2.6 1.9-3.6 1.9-.4 0-.7-.1-.9-.4s-.3-.7-.3-1.2c0-1.2.4-2.3 1.1-3.4.8-1.3 1.8-1.9 3-1.9.4 0 .7.1.9.3s.3.5.3.8c0 .4-.1.7-.4 1s-.7.7-1.4 1.1c-.3.2-1 .5-2.1 1-.1.3-.1.6-.1.8 0 .4.1.6.2.8s.3.2.6.2c.2 0 .5-.1.8-.2.3-.3.9-.7 1.6-1.2zm-3-1.1c.9-.4 1.6-.8 2.1-1.3.3-.3.5-.6.5-.9 0-.2-.1-.4-.2-.6-.1-.1-.3-.2-.5-.2s-.4.1-.6.2c-.3.2-.5.5-.7.9-.3.6-.5 1.2-.6 1.9z"
          id="path5276"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="m-393.1 79.7-.3-.3c.7-.7 1.3-1.1 1.8-1.3.4-.2.8-.3 1.1-.3.1 0 .2 0 .3.1.1 0 .1.1.1.2v.3l-.4 1.6c.6-.6 1.3-1.2 2.2-1.7.5-.3.9-.4 1.2-.4.2 0 .4.1.5.2s.2.3.2.6c0 .2 0 .4-.1.6-.1.4-.3 1.1-.7 2.3-.4 1.1-.5 1.8-.5 2.2 0 .1 0 .2.1.2 0 .1.1.1.1.1.2 0 .4-.1.8-.5.3-.2.5-.4.8-.6l.2.3c-.5.5-1.1 1-1.6 1.3-.4.2-.7.3-.9.3s-.4-.1-.5-.2-.2-.4-.2-.7c0-.5.3-1.7 1-3.6.2-.7.3-1.1.3-1.2 0-.2-.1-.3-.3-.3s-.5.1-.9.3c-.5.3-1 .7-1.4 1.2-.2.3-.4.8-.6 1.4l-.7 2.8h-1.3c.2-.8.5-1.6.6-2.3l.8-3.1v-.3c0-.1-.1-.2-.2-.2s-.2.1-.4.2c-.4.2-.8.4-1.1.8z"
          id="path5278"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="m-379.3 77.6.6-.1-.6 2c-.3.8-.5 1.8-.7 2.8-.1.6-.2.9-.2 1.1s.1.3.2.3.2-.1.4-.2c.4-.2.7-.4 1-.7l.3.3c-.7.6-1.1.9-1.3 1-.4.2-.7.4-1.1.5-.2.1-.4.1-.6.1s-.3 0-.3-.1c-.1-.1-.1-.2-.1-.3s0-.2.1-.3l.3-1.1c-.5.6-1.1 1-1.8 1.4-.4.2-.8.4-1.2.4-.3 0-.6-.1-.8-.3-.2-.3-.4-.7-.4-1.2 0-.6.2-1.2.5-1.9s.8-1.3 1.3-1.9 1.1-1 1.7-1.3c.5-.2 1.1-.3 1.8-.3h.6zm-1.1 1.3c-.3-.1-.6-.1-.8-.1-.5 0-1 .1-1.4.4s-.7.7-1.1 1.4c-.3.7-.5 1.4-.5 2.1 0 .4.1.6.2.8s.3.2.5.2c.4 0 .8-.2 1.3-.6s.8-1 1-1.7z"
          id="path5280"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-374 77.8c.5 0 .9.1 1.2.3s.4.4.4.6c0 .1-.1.3-.2.4s-.3.2-.4.2-.2 0-.3-.1c-.1 0-.2-.2-.3-.3-.3-.4-.6-.5-.8-.5s-.4.1-.6.3-.3.4-.3.6.1.4.2.6.4.5.9 1c.6.6 1 1.2 1 1.8s-.3 1.1-.8 1.5-1.2.6-2 .6c-.7 0-1.2-.1-1.5-.4s-.5-.6-.5-1c0-.2.1-.3.2-.5.1-.1.3-.2.5-.2s.3 0 .4.1.2.2.2.4v.4c0 .2.1.3.2.5.2.1.4.2.6.2.3 0 .6-.1.8-.1.2-.1.4-.3.5-.5s.2-.4.2-.6-.1-.4-.2-.6-.4-.5-.8-1c-.6-.6-.9-1.2-.9-1.8 0-.5.2-1 .7-1.4.3-.3.9-.5 1.6-.5z"
          id="path5282"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="m-368.1 79.6-.3-.3c.6-.5 1.1-.9 1.6-1.2s.9-.4 1.2-.4c.1 0 .2 0 .3.1.1 0 .1.1.1.2s0 .3-.1.6c-.1.5-.2.8-.3 1.1 1.4-1.3 2.4-2 3.2-2 .2 0 .3 0 .4.1s.1.3.1.5-.1.6-.3 1.2c.7-.6 1.2-1.1 1.7-1.4s.9-.4 1.1-.4.4.1.6.2c.1.1.2.3.2.6 0 .2 0 .4-.1.6s-.3.9-.6 2c-.5 1.4-.7 2.2-.7 2.5 0 .2.1.3.2.3s.2 0 .3-.1c.3-.2.7-.5 1-.8l.3.3c-.1.1-.3.3-.7.6-.5.4-.8.6-.9.7-.3.2-.6.3-.8.3s-.3-.1-.5-.2c-.1-.2-.2-.4-.2-.7s0-.6.1-.9.3-1.1.7-2.3c.3-.9.4-1.5.4-1.7s-.1-.3-.3-.3c-.1 0-.3.1-.6.2-.5.3-.9.6-1.2 1-.2.3-.4.7-.6 1.3-.4 1.3-.7 2.4-.9 3.3h-1.2l1.3-4.9c.1-.4.1-.6.1-.6 0-.1 0-.2-.1-.2 0 0-.1-.1-.2-.1-.2 0-.4.1-.7.3-.6.4-1 .8-1.2 1.1-.2.4-.5.9-.6 1.6l-.7 2.7h-1.2l1-3.9c.3-1.1.4-1.7.4-1.8s-.1-.2-.2-.2c.1.1-.4.4-1.1 1z"
          id="path5284"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="m-351.5 77.6.6-.1-.6 2c-.3.8-.5 1.8-.7 2.8-.1.6-.2.9-.2 1.1s.1.3.2.3.2-.1.4-.2c.4-.2.7-.4 1-.7l.3.3c-.7.6-1.1.9-1.3 1-.4.2-.7.4-1.1.5-.2.1-.4.1-.6.1-.1 0-.3 0-.3-.1-.1-.1-.1-.2-.1-.3s0-.2.1-.3l.3-1.1c-.5.6-1.1 1-1.8 1.4-.4.2-.8.4-1.2.4-.3 0-.6-.1-.8-.3-.2-.3-.4-.7-.4-1.2 0-.6.2-1.2.5-1.9s.8-1.3 1.3-1.9 1.1-1 1.7-1.3c.5-.2 1.1-.3 1.8-.3h.6zm-1 1.3c-.3-.1-.6-.1-.8-.1-.5 0-1 .1-1.4.4s-.7.7-1.1 1.4c-.3.7-.5 1.4-.5 2.1 0 .4.1.6.2.8s.3.2.5.2c.4 0 .8-.2 1.3-.6s.8-1 1-1.7z"
          id="path5286"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="m-349.7 79.7-.3-.3c.7-.7 1.3-1.1 1.8-1.3.4-.2.8-.3 1.1-.3.1 0 .2 0 .3.1.1 0 .1.1.1.2v.3l-.4 1.6c.6-.6 1.3-1.2 2.2-1.7.5-.3.9-.4 1.2-.4.2 0 .4.1.5.2s.2.3.2.6c0 .2 0 .4-.1.6-.1.4-.3 1.1-.7 2.3-.4 1.1-.5 1.8-.5 2.2 0 .1 0 .2.1.2 0 .1.1.1.1.1.2 0 .4-.1.8-.5.3-.2.5-.4.8-.6l.2.3c-.5.5-1.1 1-1.6 1.3-.4.2-.7.3-.9.3s-.4-.1-.5-.2-.2-.4-.2-.7c0-.5.3-1.7 1-3.6.2-.7.3-1.1.3-1.2 0-.2-.1-.3-.3-.3s-.5.1-.9.3c-.5.3-1 .7-1.4 1.2-.2.3-.4.8-.6 1.4l-.7 2.8h-1.3c.2-.8.5-1.6.6-2.3l.8-3.1v-.3c0-.1-.1-.2-.2-.2s-.2.1-.4.2c-.4.2-.8.4-1.1.8z"
          id="path5288"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-337.6 77.8c.6 0 1 .2 1.4.6s.6 1 .6 1.8c0 1.2-.4 2.3-1.3 3.2-.9 1-1.8 1.4-2.8 1.4-.6 0-1.1-.2-1.5-.6s-.6-.9-.6-1.5c0-1.1.3-2.2 1-3.1.9-1.3 1.9-1.8 3.2-1.8zm-.7.6c-.5 0-1 .3-1.4 1-.5.7-.7 1.6-.7 2.7 0 .7.1 1.3.4 1.7.2.3.5.4.8.4.5 0 .9-.3 1.3-.8.5-.7.8-1.6.8-2.7 0-.8-.1-1.4-.3-1.7-.2-.5-.5-.6-.9-.6z"
          id="path5290"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
        <path
          className="st16"
          d="M-331 77.8c.5 0 .9.1 1.2.3s.4.4.4.6c0 .1-.1.3-.2.4s-.3.2-.4.2-.2 0-.3-.1c-.1 0-.2-.2-.3-.3-.3-.4-.6-.5-.8-.5s-.4.1-.6.3-.3.4-.3.6.1.4.2.6.4.5.9 1c.6.6 1 1.2 1 1.8s-.3 1.1-.8 1.5-1.2.6-2 .6c-.7 0-1.2-.1-1.5-.4s-.5-.6-.5-1c0-.2.1-.3.2-.5.1-.1.3-.2.5-.2s.3 0 .4.1.2.2.2.4v.4c0 .2.1.3.2.5.2.1.4.2.6.2.3 0 .6-.1.8-.1.2-.1.4-.3.5-.5s.2-.4.2-.6-.1-.4-.2-.6-.4-.5-.8-1c-.6-.6-.9-1.2-.9-1.8 0-.5.2-1 .7-1.4.3-.3.9-.5 1.6-.5z"
          id="path5292"
          style={{
            fill: "#6a2831",
            fillOpacity: 1,
          }}
        />
      </g>
    </svg>
  )
}
