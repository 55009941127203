import { Principal } from "pages/contentprincipal/Principal"
import { CrearProcesos } from "pages/contentprocesos/CrearProcesos"
import EditarProcesos from "pages/contentprocesos/EditarProcesos"
import { Procesos } from "pages/contentprocesos/Procesos"
import { VerProcesos } from "pages/contentprocesos/VerProcesos"
import { VerTodosProcesos } from "pages/contentprocesos/contentvertodos/VerTodoProcesos"
import { type RouteObject } from "react-router-dom"

export const rutasProcesos: RouteObject[] = [
  {
    path: "/procesos",
    element: <Principal />,
  },
  {
    path: "/procesos/ver",
    element: <Principal />,
  },
  {
    path: "/crear-procesos",
    element: <CrearProcesos />,
  },
  {
    path: "/procesos/:estado",
    element: <Procesos />,
  },
  {
    path: "/ver-todos-procesos",
    element: <VerTodosProcesos />,
  },
  {
    path: "/ver-procesos/:procesos",
    element: <VerProcesos />,
  },
  {
    path: "/editar-procesos/:procesos",
    element: <EditarProcesos />,
  },
]
