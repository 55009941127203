import { AsignarMenuPerfil } from "pages/contentconfiguraciones/asignar-menu-perfil"
import { AsignarUsuarioPerfil } from "pages/contentconfiguraciones/asignar-usuario-perfil"
import { Menus } from "pages/contentconfiguraciones/menus"
import { Perfiles } from "pages/contentconfiguraciones/perfiles"
// import { Rutas } from "pages/contentconfiguraciones/rutas"
import { Principal } from "pages/contentprincipal/Principal"
import { type RouteObject } from "react-router-dom"

export const rutasMenu: RouteObject[] = [
  {
    path: "/configuraciones",
    element: <Principal />,
  },
  {
    path: "/configuraciones/menu",
    element: <Menus />,
  },
  {
    path: "/configuraciones/perfiles",
    element: <Perfiles />,
  },
  {
    path: "/configuraciones/asignar-perfil-usuario",
    element: <AsignarUsuarioPerfil />,
  },
  {
    path: "/configuraciones/asignar-menu-perfil",
    element: <AsignarMenuPerfil />,
  },
  // {
  //   path: "/configuraciones/rutas",
  //   element: <Rutas />,
  // },
]
