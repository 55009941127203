// Importaciones
import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import styles from "pages/contentinventario/inventariosCalendario.module.css";
import { url } from "../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { useSwal } from "hooks/otros/useSwal";
import { Form } from "antd";
import { useLayout } from "hooks/otros/useLayout";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

// Tipos de interfaz
export interface DataBodegas {
  value: number;
  label: string;
}
export interface DataPersonal {
  value: number;
  label: string;
  correo: string;
  token: string;
}
export interface DataMotivos {
  value: number;
  label: string;
}
export interface DataLaboratorios {
  value: number;
  label: string;
}
export interface DataTipo {
  value: number;
  label: string;
}
export type Evento = {
  Cod_Tipo: number;
  type: string;
  content: string;
  codInventario: number;
  motivos: number;
  EquipoInventario: { Cod_Usuario: number }[];
  laboratorios: { Cod_Laboratorio: number }[];
  observacion: String;
};
export type Eventos = { [key: string]: Evento[] };

type ValuesForm = {
  onClose(): void;
  bodega: number;
  personal: number[];
  motivos: number;
  tipo: 1 | 2 | 3;
  laboratorios: number[];
  observacion: String;
  calendario: Moment;
  notificacr: number;
};

// Funciones de utilidad
export const getMonthData = (value: Moment) => {
  if (value.month() === 8) {
    return 1394;
  }
};

type ItemData = {
  EquipoInventario: { Cod_Usuario: number }[];
  laboratorios: { Cod_Laboratorio: number }[];
  observacion: String;
  CodInventario: number;
  motivos: number;
  Nombre: string;
  Cod_Tipo: number;
  Fecha_Inicio: Date;
  Cod_Estado: number;
};
export const getListData = (value: Moment, events: Eventos) => {
  const date = value.format("DD-MM-YYYY");
  return events[date] || [];
};

// Hook principal
const breadcrumbs = [{ Label: "Inventarios", Url: "/inventarios" }, { Label: "Crear Inventario" }];

const openKeys = [breadcrumbs[0].Label];

export function useEventosCalendario() {
  // Estado local
  const [events, setEvents] = useState<Eventos>({});
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [editingEvent, setEditingEvent] = useState<Evento | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [notificars, setNotificar] = useState<number>(0);
  const [bodegas, setBodegas] = useState<DataBodegas[]>([]);
  const [personal, setPersonal] = useState<DataPersonal[]>([]);

  const [motivo, setMotivos] = useState<DataMotivos[]>([]);
  const [laboratorios, setLaboratorio] = useState<DataLaboratorios[]>([]);
  const [tipos, setTipo] = useState<DataTipo[]>([]);
  const { swalError } = useSwal();

  const [form] = Form.useForm<ValuesForm>();
  // Llamada al hook personalizado
  let { optionsGet, optionsPost } = useLocalStorage();
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);
  const { isError } = useVerifyResponse();
  const disabledDates = (value: Moment) => {
    const today = moment().startOf("day"); // El inicio del día actual
    const sevenDaysFromNow = moment().add(7, "days").endOf("day"); // El final del día que está a 7 días del actual

    // Verificamos si la fecha está fuera del rango que queremos
    return value.isBefore(today) || value.isAfter(sevenDaysFromNow);
  };
  // Manejadores de eventos
  const showModal = (date: Moment) => {
    form.resetFields(["personal", "laboratorios", "observacion", "cantidad", "tipo", "notificars", "motivos"]);

    const codBodega = form.getFieldValue("bodega");
    if (!codBodega) {
      swalError({ text: "Seleccione una bodega" });
      return;
    }

    if (disabledDates(date)) return;

    const dateStr = date.format("DD-MM-YYYY");

    const existingEvent = events[dateStr] && events[dateStr].length > 0 ? events[dateStr][0] : null;

    // Si existe un evento, prepara el modal para editar ese evento
    if (existingEvent) {
      const equipoInventarioIds = existingEvent.EquipoInventario.map((item) => item.Cod_Usuario);
      let tipoValido: 1 | 2 | 3 | undefined;
      if ([1, 2, 3].includes(existingEvent.Cod_Tipo)) {
        tipoValido = existingEvent.Cod_Tipo as 1 | 2 | 3;
      } else {
        tipoValido = undefined;
      }
      const laboratorios = existingEvent.laboratorios.map((item) => item.Cod_Laboratorio);
      getPersonalAll(codBodega);
      getMotivos();
      form.setFieldsValue({
        calendario: date,
        personal: equipoInventarioIds,
        tipo: tipoValido,
        laboratorios: laboratorios,
        observacion: existingEvent.observacion,
        motivos: existingEvent.motivos,
        // Configura aquí otros valores del formulario según los datos del evento existente
      });
      setEditingEvent(existingEvent); // Establece el evento existente para la edición
    } else {
      // Modo de creación: no hay evento existente
      form.resetFields(["personal", "laboratorios", "observacion", "cantidad", "tipo", "notificars"]);
      form.setFieldsValue({ calendario: date });
      getPersonal(codBodega, date.format("DD/MM/YYYY"));
      getMotivos();
      setEditingEvent(null); // Importante: resetea el estado de edición
    }

    setSelectedDate(dateStr);
    //
    setNotificar(0);
    setIsModalOpen(true);
  };
  const resetEvents = () => {
    setEvents({}); // Establece los eventos a un objeto vacío
  };
  const cambiarLista = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setNotificar(1);
    } else {
      setNotificar(0);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const addEvent = (
    description: string,
    codInventarioCab: number,
    EquipoInventario: { Cod_Usuario: number }[],
    laboratorios: { Cod_Laboratorio: number }[],
    observacion: String,
    Cod_Tipo: number,
    motivos: number
  ) => {
    if (selectedDate) {
      setEvents((prevEvents) => ({
        ...prevEvents,
        [selectedDate]: [
          ...(prevEvents[selectedDate] || []),
          {
            type: "success",
            content: description,
            codInventario: codInventarioCab,
            EquipoInventario: EquipoInventario,
            laboratorios: laboratorios,
            motivos: motivos,
            observacion: observacion,
            Cod_Tipo: Cod_Tipo,
          },
        ],
      }));
    }
    setIsModalOpen(false);
  };

  const monthCellRender = (value: Moment) => {
    const num = getMonthData(value);
    return num ? (
      <div className={styles.notes_month}>
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };
  const getEventType = (codEstado: number) => {
    switch (codEstado) {
      case 1:
        return "warning"; // Verde
      case 2:
        return "success"; // Amarillo
      case 3:
        return "error"; // Rojo
      default:
        return "default"; // Algún color por defecto
    }
  };
  const getDataBodegaCalendario = useCallback(
    async (codBodega: number, fechaInicio: String, fechaFinal: String) => {
      try {
        const res = await fetch(
          url + "apifsg-pr/inventarios/obtener_data_bodega_calendario",
          optionsPost({ codBodega, fechaInicio, fechaFinal })
        );
        if (isError(res)) throw new Error();
        const respuesta = await res.json();
        if (respuesta.msg !== "err") {
          const { data } = respuesta;

          const newEvents = data.reduce((acc: Eventos, item: ItemData) => {
            const formattedDate = moment(item.Fecha_Inicio, "DD/MM/YYYY").format("DD-MM-YYYY");
            const eventType = getEventType(item.Cod_Estado);
            if (!acc[formattedDate]) acc[formattedDate] = [];
            acc[formattedDate].push({
              type: eventType,
              content: item.Nombre,
              Cod_Tipo: item.Cod_Tipo,
              codInventario: item.CodInventario,
              EquipoInventario: item.EquipoInventario,
              motivos: item.motivos,
              laboratorios: item.laboratorios,
              observacion: item.observacion,
            });
            return acc;
          }, {});

          setEvents((prevEvents) => ({
            ...prevEvents,
            ...newEvents,
          }));
        } else {
          setEvents({});
          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        return { msg: "no_conexion", status: false };
      }
    },
    [optionsPost, isError]
  );

  const getBodegas = useCallback(async () => {
    try {
      const res = await fetch(url + "apifsg-pr/bodegas/listar-bodegas-inventario", optionsGet);
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        const { data } = respuesta;
        setBodegas(data);
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsGet, isError]);

  const getPersonal = useCallback(
    async (codBodega: number, fecha: String) => {
      try {
        const res = await fetch(
          url + "apifsg-pr/creedenciales/gestion-personal-inventario",
          optionsPost({ codBodega, fecha })
        );
        if (isError(res)) throw new Error();
        const respuesta = await res.json();
        if (respuesta.msg !== "err") {
          const { data } = respuesta;

          setPersonal(data);
        } else {
          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        return { msg: "no_conexion", status: false };
      }
    },
    [optionsPost, isError]
  );
  const getMotivos = useCallback(async () => {
    try {
      const res = await fetch(url + "apifsg-pr/creedenciales/gestion-motivos-inventario", optionsPost({}));
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        const { data } = respuesta;

        setMotivos(data);
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsPost, isError]);
  const getPersonalAll = useCallback(
    async (codBodega: number) => {
      try {
        const res = await fetch(url + "apifsg-pr/creedenciales/gestion-personal-inventario-all", optionsPost({ codBodega }));
        if (isError(res)) throw new Error();
        const respuesta = await res.json();
        if (respuesta.msg !== "err") {
          const { data } = respuesta;

          setPersonal(data);
        } else {
          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        return { msg: "no_conexion", status: false };
      }
    },
    [optionsPost, isError]
  );

  const getLaboratorio = useCallback(async () => {
    try {
      const res = await fetch(url + "apifsg-pr/inventarios/obtener_laboratorios", optionsGet);
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        const { data } = respuesta;

        setLaboratorio(data);
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsGet, isError]);
  const getTipoInventario = useCallback(async () => {
    try {
      const res = await fetch(url + "apifsg-pr/inventarios/obtener_tipos", optionsGet);
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        const { data } = respuesta;
        setTipo(data);
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsGet, isError]);
  useEffect(() => {
    getBodegas();
    getLaboratorio();
    getTipoInventario();
  }, [getBodegas, getLaboratorio, getTipoInventario]);
  // Asegúrate de llamar a getBodegas en algún lugar, probablemente en un efecto secundario de useEffect.

  // Retorno del hook
  return {
    showModal,
    handleCancel,
    monthCellRender,
    isModalOpen,
    addEvent,
    events,
    notificars,
    bodegas,
    personal,
    motivo,
    laboratorios,
    tipos,
    getDataBodegaCalendario,
    getPersonal,
    setIsModalOpen,
    form,
    editingEvent,
    setNotificar,
    resetEvents,
    cambiarLista,
  };
}
