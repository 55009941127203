import { Button, Card, Col, Form, Row, Select, Typography } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { useCrearProcesos } from "hooks/procesos/useCrearProcesos"
import styles from "pages/contentprocesos/procesos.module.css"
import { SaveOutlined } from "@ant-design/icons"

const { Title } = Typography

export const CrearProcesos = () => {
  const { procesos, form, sendData, loader } = useCrearProcesos()
  return (
    <>
      <Title level={2} className={styles.titleStyle}>
        Creación de procesos BPMN
      </Title>
      <Row wrap gutter={[16, 16]} className={styles.rowStyle}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card type="inner" size="small">
            <Form form={form} style={{ marginTop: "2%" }}>
              <Form.Item
                label="Tipo de Proceso"
                name="tipo_procesos"
                rules={[{ required: true, message: "Por favor seleccione un tipo de proceso." }]}
              >
                <Select options={procesos} allowClear></Select>
              </Form.Item>
              <Form.Item
                label="Descripción del Proceso"
                name="descripcion_procesos"
                rules={[{ required: true, message: "Por favor ingrese una descripción." }]}
              >
                <TextArea maxLength={250} placeholder="Descripción del Proceso" autoSize={{ minRows: 1, maxRows: 10 }} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={sendData}
                  loading={loader}
                  icon={<SaveOutlined className="iconbutton" />}
                  htmlType="submit"
                  block
                >
                  Iniciar Proceso
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}
