import { Button, Col, Row, Typography, Table, Image, Modal, Input } from "antd"
import { useLayout } from "hooks/otros/useLayout"
import { CheckOutlined} from "@ant-design/icons"
import { useAprobacionUniformesRRHH} from "hooks/rrhh/renovacionuniforme/useAprobacionUniformesRRHH"
import { url } from "config/constants"
const { TextArea } = Input;
const { Title } = Typography
const breadcrumbs = [
  { Label: "Recursos Humanos", Url: "/rrhh" },
  { Label: "Renovación de Uniformes", Url: "/rrhh/renovacion-uniformes" },
  { Label: "Validación de Uniformes RRHH" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export function AprobacionUniformesRRHH() {
  useLayout(breadcrumbs, openKeys, selectKeySider)
  const { rowSelection, 
    columns, 
    data, 
    columnsuniforme, 
    savesolicitudKit,
    llavesimages,
    visible,
    setVisible,
    openModal, 
    setOpenModal,
    observacion,
    handleInputChange,
    saveObservacion,
    mensaje,
    visibleButton
    } =
    useAprobacionUniformesRRHH()

  return (
    <>
      <Row style={{ background: "white", padding: "5px" }} align="middle" justify="center">
        <Col sm={24} md={10} style={{ paddingLeft: "20px" }}>
          <Title level={2}>Validación de Uniformes RRHH</Title>
        </Col>

        <Col sm={24} md={14}>
          <Row justify="end" >
    {/*             <Button
              type="primary"
              style={{ marginRight: "2px", marginTop: "1px", backgroundColor: "#00B050", borderColor: "#00B050" }}
              onClick={handleDownload}
            >
              Excel <FileExcelOutlined />
            </Button> */}
            {visibleButton&&<Button type="primary"  style={{ marginTop: "1px" }} onClick={() => savesolicitudKit()}>
              Aprobar Seleccionados
              <CheckOutlined />
            </Button>}
          </Row>
        </Col>
      </Row>
      <Row style={{ background: "white" }}>
        <Table
          style={{ width: "100%", margin: "10px"}}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          onRow={({disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled) return
            },
          })}
          expandable={{
            expandedRowRender: (record) => {
              console.log(record.detalle)
              let detalle: any = record.detalle
              return (
                <Table
                  style={{ width: "100%", margin: "10px" }}
                  columns={columnsuniforme}
                  dataSource={detalle}
                  pagination={false}
                />
              )
            },
            //onExpand: handleExpand,
            // rowExpandable: (record) => record.detalle !==null,
          }}
        />
      </Row>
      {llavesimages && <div style={{ display: 'none' }}>
        <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
          {llavesimages.map((item:any)=><Image key={item.key} src={`${url}apiallku-public/api/${item.Llave}`}/>)}
        </Image.PreviewGroup>
      </div>}
      <Modal title="Observación" open={openModal} onCancel={()=>setOpenModal(false)} onOk={saveObservacion}>
        <TextArea  rows={4} placeholder="Observación" value={observacion} onChange={(e)=>handleInputChange(e)}></TextArea>
        {mensaje&&<label style={{color: 'red', fontSize: '10px'}}>Por favor agregue la Observación para guardar</label>}
      </Modal>
    </>
  )
}
