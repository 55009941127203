import { BackTop, Button, Col, Descriptions, Form, InputNumber, Modal, Radio, Row, Select, Table } from "antd"
import { ModalFranquiciado } from "components/franquiciados/modal-franquiciado"

import { useScreenSize } from "hooks/otros/useScreenSize"
import { TipoAsignacion, useAsignarFranquiciadoFarmacia } from "hooks/Franquiciados/useAsignarFranquiciadoFarmacia"
import { useSwal } from "hooks/otros/useSwal"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"

import { url } from "config/constants"

import { MdOutlineAddCircle } from "react-icons/md"

import type { FormItemProps, FormProps, TableColumnsType, TableProps } from "antd"
import type { DatosAsignacionBodega, DatosAsignacionFranquiciado } from "hooks/Franquiciados/useAsignarFranquiciadoFarmacia"
import type { SelectProps } from "antd/lib/select"
import { useState } from "react"

export function AsignarFranquiciadoFarmacia() {
  const {
    data,
    valueSelected,
    openModalList,
    setOpenModalList,
    dataModalList,
    tipoAsignacion,
    setTipoAsignacion,
    columnsBodega,
    expandedBodega,
    columnsFranquiciado,
    expandedFranquiciado,
    // onChangeModalListSelect,
    openModalCrearFranquiciado,
    setOpenModalCrearFranquiciado,
    reFetcher,
  } = useAsignarFranquiciadoFarmacia()

  const { width } = useScreenSize()

  return (
    <>
      <Row
        wrap
        style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff", minHeight: "83vh" }}
        gutter={[16, 16]}
      >
        <Col xs={24} sm={24} md={15} lg={17} xl={19}>
          <Descriptions layout={width >= 465 ? "horizontal" : "vertical"}>
            <Descriptions.Item label="Asignar">
              <Radio.Group
                onChange={e => setTipoAsignacion(e.target.value)}
                defaultValue={TipoAsignacion.bodegaFranquiciado}
                buttonStyle="solid"
                size={width >= 400 ? "middle" : "small"}
              >
                <Radio.Button value={TipoAsignacion.bodegaFranquiciado}>Franquiciado a bodega</Radio.Button>
                <Radio.Button value={TipoAsignacion.franquiciadoBodega}>Bodega a franquiciado</Radio.Button>
              </Radio.Group>
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col xs={24} sm={24} md={9} lg={7} xl={5}>
          <Button
            type="primary"
            block
            onClick={() => setOpenModalCrearFranquiciado(true)}
            icon={<MdOutlineAddCircle className="iconbutton" />}
          >
            Registrar franquiciado
          </Button>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <TableTipoAsignacion
            tipoAsignacion={tipoAsignacion}
            columnsBodega={columnsBodega}
            expandedBodega={expandedBodega}
            data={data}
            columnsFranquiciado={columnsFranquiciado}
            expandedFranquiciado={expandedFranquiciado}
          />
        </Col>
      </Row>
      <ModalAsignar
        tipoAsignacion={tipoAsignacion}
        openModal={openModalList}
        dataSelect={dataModalList}
        onClose={() => setOpenModalList(false)}
        codSelected={valueSelected.current}
        reFetcher={reFetcher}
      />
      <ModalFranquiciado open={openModalCrearFranquiciado} onClose={() => setOpenModalCrearFranquiciado(false)} />
      <BackTop />
    </>
  )
}

export default AsignarFranquiciadoFarmacia

type TableTipoAsignacionProps = {
  tipoAsignacion: TipoAsignacion
  columnsBodega: TableColumnsType<DatosAsignacionBodega>
  expandedBodega: TableProps<DatosAsignacionBodega>["expandable"]
  data: (DatosAsignacionBodega | DatosAsignacionFranquiciado)[]
  columnsFranquiciado: TableColumnsType<DatosAsignacionFranquiciado>
  expandedFranquiciado: TableProps<DatosAsignacionFranquiciado>["expandable"]
}

function TableTipoAsignacion({
  tipoAsignacion,
  columnsBodega,
  expandedBodega,
  data,
  columnsFranquiciado,
  expandedFranquiciado,
}: TableTipoAsignacionProps) {
  if (tipoAsignacion === TipoAsignacion.bodegaFranquiciado) {
    return <TableAssign columns={columnsBodega} expanded={expandedBodega} data={data as DatosAsignacionBodega[]} />
  } else {
    return (
      <TableAssign
        columns={columnsFranquiciado}
        expanded={expandedFranquiciado}
        data={data as DatosAsignacionFranquiciado[]}
      />
    )
  }
}

type PropsTableAssign<T> = {
  columns: TableColumnsType<T>
  expanded: TableProps<T>["expandable"]
  data: T[]
}

function TableAssign<T extends object>({ columns, expanded, data }: PropsTableAssign<T>) {
  const [pageSize, setPageSize] = useState(5)
  return (
    <Table
      columns={columns}
      expandable={expanded}
      dataSource={data}
      scroll={{ x: true }}
      size="small"
      pagination={{
        pageSize,
        size: "default",
        onShowSizeChange: (_, size) => setPageSize(size),
        pageSizeOptions: [5, 10, 20, 50, 100],
      }}
    />
  )
}

// Modal para asignar franquiciado a bodega o bodega a franquiciado
type ModalAsignarProps = {
  openModal: boolean
  onClose(): void
  dataSelect: SelectProps["options"]
  tipoAsignacion: TipoAsignacion
  codSelected?: number
  reFetcher(): void
}

function ModalAsignar({ openModal, onClose, dataSelect, tipoAsignacion, codSelected, reFetcher }: ModalAsignarProps) {
  const [form] = Form.useForm()

  const propsItemSelect: FormItemProps =
    tipoAsignacion === TipoAsignacion.bodegaFranquiciado
      ? {
          name: "cod_usuario",
          label: "Franquiciado",
          rules: [{ required: true, message: "Escoja un franquiciado" }],
        }
      : {
          name: "cod_bodega",
          label: "Farmacia",
          rules: [{ required: true, message: "Escoja una farmacia" }],
        }

  const { optionsPost } = useLocalStorage()

  const { swalError, swalSuccess } = useSwal()

  const { isError } = useVerifyResponse()

  const onFinishForm: FormProps["onFinish"] = async values => {
    onClose()
    const datos =
      tipoAsignacion === TipoAsignacion.bodegaFranquiciado
        ? {
            ...values,
            cod_bodega: codSelected,
          }
        : {
            ...values,
            cod_usuario: codSelected,
          }
    try {
      const result = await fetch(`${url}fapifsg-pr/franquiciados/asignar-bodega-franquiciado`, optionsPost(datos))
      if (isError(result)) throw new Error()
      const text = await result.json()
      swalSuccess({ text })
      reFetcher()
    } catch (e) {
      swalError({ text: "No se pudo eliminar la bodega como franquicia" })
    }
  }

  const handleOk = async () => {
    await form.validateFields()
    form.submit()
  }

  return (
    <Modal
      title={
        tipoAsignacion === TipoAsignacion.bodegaFranquiciado
          ? "Asignar franquiciado a farmacia"
          : "Asignar farmacia a franquiciado"
      }
      transitionName=""
      open={openModal}
      onCancel={onClose}
      onOk={handleOk}
    >
      <Form onFinish={onFinishForm} form={form} requiredMark="optional">
        <Form.Item {...propsItemSelect}>
          <Select
            options={dataSelect}
            showSearch
            optionFilterProp="label"
            getPopupContainer={() => document.getElementsByClassName("ant-modal-body")[0] as HTMLElement}
          />
        </Form.Item>
        <Form.Item name="cargo_empleados" label="Asume empleados" initialValue={1} required>
          <InputNumber min={0} max={10} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
