import { Button, Form, Tag } from "antd"
import { useCallback, useEffect, useState } from "react"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { useLayout } from "hooks/otros/useLayout"

import { url } from "config/constants"
import { TipoSeleccion } from "hooks/Franquiciados/usePago"

import { optionsGet, optionsPost, swalError, swalQuestion, swalSuccess, swalWarning } from "config/methods"

import { CheckCircleOutlined, ClockCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"

import type { DefaultOptionType } from "antd/lib/select"
import type { TableColumnsType } from "antd"

export type Deudas = {
  key: React.Key
  deuda: string
  cuotas: number
  cuotas_pagadas: number
  monto: number
  fecha_creacion: string
  fecha_fin_pago: string
  estado: boolean
}

type ValuesForm = {
  buscarPor: TipoSeleccion
  bodega?: React.Key
  franquiciado?: React.Key
}

type Bodega = {
  value: number
  label: string
  franquiciado: number
}

const breadcrumbs = [{ Label: "Franquiciados", Url: "/franquiciados" }, { Label: "Préstamos" }]

const openKeys = [breadcrumbs[0].Label]

export function useDeudasFranquiciados() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label)

  const [deudas, setDeudas] = useState<Deudas[]>([])
  const [bodegas, setBodegas] = useState<Bodega[]>([])
  const [franquiciados, setFranquiciados] = useState<DefaultOptionType[]>([])
  const [openModal, setOpenModal] = useState(false)
  const [tipoSeleccion, setTipoSeleccion] = useState<TipoSeleccion>(TipoSeleccion.Bodega)

  const [selectedDeuda, setSelectedDeuda] = useState<Deudas>()

  const [form] = Form.useForm<ValuesForm>()

  const { isError } = useVerifyResponse()

  useEffect(() => {
    ;(async () => {
      try {
        const result = await fetch(`${url}fapifsg-pr/franquiciados/obtener-bodegas-y-franquiciados`, optionsGet())
        if (isError(result)) throw new Error()
        const res = await result.json()
        setBodegas(res.bodegas)
        setFranquiciados(res.franquiciados)
      } catch (e) {
        swalError({ text: "No se pudo obtener los datos" })
      }
    })()
  }, [isError])

  useEffect(() => {
    if (bodegas.length === 0) return
    if (franquiciados.length === 0) return
    if (tipoSeleccion === TipoSeleccion.Bodega) {
      form.setFieldValue("bodega", bodegas[0].value)
    } else {
      form.setFieldValue("franquiciado", franquiciados[0].value)
    }
  }, [tipoSeleccion, bodegas, franquiciados, form])

  const getDeudas = useCallback(
    async (codigo: number) => {
      try {
        const body = {
          franquiciado: codigo,
        }
        const result = await fetch(`${url}fapifsg-pr/franquiciados/obtener-deudas-por-franquiciado`, optionsPost(body))
        if (isError(result)) throw new Error()
        const res = await result.json()
        setDeudas(res)
      } catch (e) {
        swalError({ text: "No se pudo obtener los préstamos del franquiciado" })
      }
    },
    [isError]
  )

  const eliminarPrestamo = useCallback(
    async (cod_deuda: number) => {
      try {
        const respuesta = await swalQuestion({ title: "¿Desea eliminar el préstamo?" })
        if (!respuesta.isConfirmed) return
        const body = {
          cod_deuda,
        }
        const result = await fetch(`${url}fapifsg-pr/franquiciados/eliminar-deuda`, optionsPost(body))
        if (isError(result)) throw new Error()
        const { ok, mensaje } = await result.json()
        if (ok) {
          swalSuccess({ text: mensaje })
          getDeudas(
            tipoSeleccion === TipoSeleccion.Bodega
              ? bodegas.find(({ value }) => value === form.getFieldValue("bodega"))?.franquiciado
              : form.getFieldValue("franquiciado")
          )
        } else swalWarning({ text: mensaje })
      } catch (e) {
        swalError({ text: "No se pudo eliminar el préstamo del franquiciado" })
      }
    },
    [isError]
  )

  useEffect(() => {
    getDeudas(
      tipoSeleccion === TipoSeleccion.Bodega
        ? bodegas.find(({ value }) => value === form.getFieldValue("bodega"))?.franquiciado
        : form.getFieldValue("franquiciado")
    )
  }, [getDeudas, bodegas, form, tipoSeleccion])

  const columns: TableColumnsType<Deudas> = [
    {
      title: "Descripcion",
      dataIndex: "deuda",
      key: "deuda",
    },
    {
      title: "Fecha inicio",
      dataIndex: "fecha_creacion",
      key: "fecha",
    },
    {
      title: "Monto",
      dataIndex: "monto",
      key: "monto",
    },
    {
      title: "Cuotas",
      dataIndex: "cuotas",
      key: "cuotas",
    },
    {
      title: "Cuotas pagadas",
      dataIndex: "cuotas_pagadas",
      key: "pagadas",
    },
    {
      title: "Periodo fin",
      dataIndex: "fecha_fin_pago",
      key: "fecha_fin",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      render: (value: boolean) =>
        value ? (
          <Tag icon={<ClockCircleOutlined />} color="blue">
            Pendiente
          </Tag>
        ) : (
          <Tag icon={<CheckCircleOutlined />} color="default">
            Pagada
          </Tag>
        ),
    },
    {
      title: <EditOutlined style={{ fontSize: "1.3rem" }} />,
      dataIndex: "key",
      render: (_, record) => (
        <Button
          icon={<EditOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          block
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          onClick={() => {
            setSelectedDeuda(record)
            setOpenModal(true)
          }}
        />
      ),
    },
    {
      title: <DeleteOutlined style={{ fontSize: "1.3rem" }} />,
      dataIndex: "key",
      render: value => (
        <Button
          icon={<DeleteOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          block
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          onClick={() => eliminarPrestamo(value)}
        />
      ),
    },
  ]

  return {
    form,
    tipoSeleccion,
    setTipoSeleccion,
    getDeudas,
    bodegas,
    setSelectedDeuda,
    openModal,
    setOpenModal,
    selectedDeuda,
    deudas,
    columns,
    franquiciados,
  }
}
