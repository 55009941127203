import { Button, Col, Row, Space, Table, Typography, DatePicker, Spin } from 'antd'
import { useLayout } from 'hooks/otros/useLayout'
import { FileExcelOutlined} from "@ant-design/icons"

import UseCuentaporCobrar, { DataTypeCxC } from 'hooks/contabilidad/UseCuentaporCobrar'

const { Title } = Typography
const { RangePicker } = DatePicker;

const breadcrumbs = [
    { Label: "Contabilidad", Url: "/contabilidad" },
    { Label: "Cuentas por Cobrar", Url: "/contabilidad/cuentas-por-cobrar" },
  ]
  const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
  const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`

export default function CuentasPorCobrar() {
    useLayout(breadcrumbs, openKeys, selectKeySider)

    const{columns, dataCxC, handleDownload, 
      fechasSeleccionadas, handleDateChange,dataEstado}=UseCuentaporCobrar()
    
  return (
    <>
           <Row style={{ background: 'white', padding: '5px', paddingLeft:'10px'}}>
            <Col xs={24} sm={24} md={12}><Title level={3}>Cuentas por Cobrar</Title></Col>
            <Col xs={24} sm={24} md={12}>
                <Row justify={"end"}>
                <Col sm={24} md={11}>
              <Row style={{ fontSize: "10px", marginRight: "5px" }}>Fechas:</Row>
              <Row style={{ marginTop: "5px" }}>
                <Space>
                  <RangePicker  size='small'   defaultValue={fechasSeleccionadas} format={'YYYY/MM/DD'} /* disabledDate={disabledDate} */ onChange={handleDateChange} style={{ marginLeft: "2px" }} />
                </Space>
              </Row>
            </Col>
            <Col style={{marginTop: '12px', marginLeft:'3px'}}>
            <Button
              type="primary"
              style={{ marginRight: "2px", marginTop: "1px", backgroundColor: "#00B050", borderColor: "#00B050" }}
              onClick={handleDownload}
            >
              Excel <FileExcelOutlined />
            </Button>
            </Col>
                </Row>
            </Col>
        </Row>
       
        {!dataEstado && ( 
          <Row style={{
            justifyContent: 'center', textAlign:'center',  display: 'flex',
          background: 'white', padding: '40px', height:'100%', paddingTop:'80px'}}>
                <Spin style={{fontSize:'20px'}}/>
            </Row>
          )}
         {dataEstado && (<Row style={{ alignItems:'center',background: 'white', padding: '5px', height:'100%'}}>         
            <Table style={{width:'100%', height:'100%'}} 
            columns={columns as DataTypeCxC[]}
            dataSource={dataCxC}  
              //rowClassName={(record, index) => 'miFilaSeleccionada'} 
            />
         
        </Row> )}
    </>
  )
}
