import { Tag } from "antd"
import { ColumnProps, ColumnsType } from "antd/lib/table"
import { DataNode } from "antd/lib/tree"
import { url } from "config/constants"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSearchTable } from "hooks/otros/useSearchTable"
import { useSwal } from "hooks/otros/useSwal"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import moment from "moment"
import { useCallback, useEffect, useState } from "react"

const filtrosEstados = [
  { text: "Pendientes", value: "PE" },
  { text: "Aprobados", value: "AP" },
  { text: "Recibidos", value: "RV" },
  { text: "Rechazados", value: "RE" },
]

export interface dataLlamadosAtencion {
  key: number
  empleados: {
    cedula: string
    nombres: string
    cargo?: string
  }[]
  empleado?: string
  solicitante: string
  cargo_solicitante?: string
  responsables: {
    cedula: string
    nombres: string
    cargo?: string
  }[]
  departamento: string
  fecha_falta: string
  fecha_creacion: string
  fecha_aprobacion?: string
  multa: boolean
  porcentaje: number
  observacion: string
  motivo?: string
  descripcion?: string
  nbodega?: number
  direccion?: string
  bodega: string
  periodo_actividad?: string
  tipo_actividad?: string
  estado?: "PE" | "AP" | "RE"
  reglamentos:
    | {
        cod_literal: number
      }[]
    | {
        capitulo: string
        articulos: {
          articulo: number
          descripcion: string
          apartado: string
          literales: {
            literal: number
            descripcion: string
          }[]
        }[]
      }[]
    | DataNode[]
  evidencias: {
    llave_imagen: string
  }[]
}

interface responseLlamadosAtencion {
  msg: "ok" | "error" | "err"
  data: dataLlamadosAtencion[]
}

export function useDataForTable(
  dataUrl: string,
  renderActions?: ColumnProps<dataLlamadosAtencion>["render"],
  dataBody?: any
) {
  const [llamadosAtencion, setllamadosAtencion] = useState<dataLlamadosAtencion[]>([])

  const { optionsGet, optionsPost } = useLocalStorage()

  const { swalError } = useSwal()

  const { wasInvalidToken, hadError } = useVerifyResponse()

  const { getColumnSearchProps } = useSearchTable<dataLlamadosAtencion>()

  const listarLlamadosAtencion = useCallback(
    async (body?: any) => {
      try {
        const res = await fetch(`${url}${dataUrl}`, body ? optionsPost(body) : optionsGet)

        const response: responseLlamadosAtencion = await res.json()

        if (wasInvalidToken(response)) return
        if (hadError(response, "No se pudo obtener los llamados de atención")) return

        const { data } = response

        setllamadosAtencion(data ? data : [])
      } catch (e) {
        console.error(e)
        swalError({ text: "No se pudo obtener los llamados de atención" })
      }
    },
    [optionsGet, optionsPost, wasInvalidToken, hadError, swalError, dataUrl]
  )

  useEffect(() => {
    listarLlamadosAtencion(dataBody)
  }, [listarLlamadosAtencion, dataBody])

  const columnsTable: ColumnsType<dataLlamadosAtencion> = [
    {
      title: "Solicitante",
      dataIndex: "solicitante",
      key: "solicitante",
      ...getColumnSearchProps("solicitante"),
    },
    {
      title: "Departamento",
      dataIndex: "departamento",
      key: "Departamento",
      filters: [...new Set(llamadosAtencion?.map(em => em.departamento))].map(departamento => ({
        text: departamento,
        value: departamento,
      })),
      onFilter: (value, record) => record.departamento === value,
    },
    { title: "Bodega", dataIndex: "bodega", key: "bodega", ...getColumnSearchProps("bodega") },
    { title: "Fecha falta", dataIndex: "fecha_falta", key: "Fecha_Falta" },
    { title: "Fecha de solicitud", dataIndex: "fecha_creacion", key: "Fecha" },
  ]

  if (renderActions) {
    columnsTable.push({
      title: "",
      dataIndex: "key",
      key: "acciones",
      align: "center",
      fixed: "right",
      render: renderActions,
    })
  }

  const columnsTableWithEstado: ColumnsType<dataLlamadosAtencion> = [
    // { title: 'Solicitante', dataIndex: 'solicitante', key: 'solicitante', ...getColumnSearchProps('solicitante') },
    {
      title: "Departamento",
      dataIndex: "departamento",
      key: "Departamento",
      filters: [...new Set(llamadosAtencion?.map(em => em.departamento))].map(departamento => ({
        text: departamento,
        value: departamento,
      })),
      onFilter: (value, record) => record.departamento === value,
    },
    { title: "Bodega", dataIndex: "bodega", key: "bodega", ...getColumnSearchProps("bodega") },
    {
      title: "Fecha falta",
      dataIndex: "fecha_falta",
      key: "Fecha_Falta",
      sorter: (a, b) =>
        parseInt(moment(a.fecha_falta, "DD/MM/YYYY").format("YYYYMMDD")) -
        parseInt(moment(b.fecha_falta, "DD/MM/YYYY").format("YYYYMMDD")),
      ...getColumnSearchProps("fecha_falta"),
    },
    {
      title: "Fecha de solicitud",
      dataIndex: "fecha_creacion",
      key: "Fecha",
      sorter: (a, b) =>
        parseInt(moment(a.fecha_creacion, "DD/MM/YYYY").format("YYYYMMDD")) -
        parseInt(moment(b.fecha_creacion, "DD/MM/YYYY").format("YYYYMMDD")),
      ...getColumnSearchProps("fecha_creacion"),
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "Estado",
      align: "center",
      fixed: "right",
      filters: filtrosEstados,
      onFilter: (value, record) => record.estado === value,
      render: value =>
        value === "PE" ? (
          <Tag color="blue">Pendiente</Tag>
        ) : value === "AP" ? (
          <Tag color="green">Aprobado</Tag>
        ) : value === "RE" ? (
          <Tag color="red">Rechazado</Tag>
        ) : (
          <Tag color="purple">Recibido</Tag>
        ),
    },
    {
      title: "",
      dataIndex: "key",
      key: "acciones",
      align: "center",
      fixed: "right",
      render: renderActions,
    },
  ]

  return {
    llamadosAtencion,
    setllamadosAtencion,
    listarLlamadosAtencion,
    columnsTable,
    columnsTableWithEstado,
  }
}
