import { Card, Form, DatePicker, Row, Col, Divider } from "antd";
import { useAdministrarRotacion } from "hooks/indicadores/useAdministrarRotacion";
import moment from "moment";
import { useContext } from "react";
import { RotacionEmpleadoContext } from "context/indicadores/RotacionEmpleadoContext";

import ModalOptions from "./ModalOptions";
import TableReport from "./TableReport";
import ComponentChart from "./ComponentChart";
import ComponentPie from "./ComponentPie";

const AdministrarRotacionEmpleado = () => {
  const { handleInitialData, exportToExcel, rowSelection, form } = useAdministrarRotacion();

  const {
    headTable,
    columnTable,
    setShowModal,
    headTableYdt,
    columnTableYdt,
    columnTableMotivos,
    columnTableTiempos,
    showModal,
    dataChart,
    handleChangeX1,
    setShowModalX2,
    showModalX2,
    dataChartLast,
    handleChangeX2,
    graphX2,
    graph,
  } = useContext(RotacionEmpleadoContext);

  return (
    <div>
      <Card title='Opciones Disponibles' style={{ margin: "5px" }}>
        <Form form={form}>
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <Form.Item label='Año' name='fecha' initialValue={moment(`${moment().year()}`, "YYYY")}>
                <DatePicker
                  picker='year'
                  placeholder='Seleccione año'
                  style={{ width: "100%" }}
                  format='YYYY'
                  onChange={handleInitialData}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} md={24} lg={11}>
              <TableReport
                headTable={headTable}
                columnTable={columnTable}
                rowSelection={rowSelection}
                exportToExcel={exportToExcel}
                isSelect={true}
              />
            </Col>
            <Col xs={24} md={24} lg={13}>
              <ComponentChart
                columnTable={columnTable}
                grafica={"Mes"}
                tamaño={380}
                angulo={-45}
                graph={graph}
                setShowModal={setShowModal}
                dataKey={"rotación %"}
              />
            </Col>
          </Row>
          {columnTableMotivos && columnTableMotivos?.length > 0 && (
            <div style={{ marginBottom: "80px" }}>
              <Divider orientation='left'>Motivos y tiempo de salidas</Divider>
              <Row gutter={[10, 170]}>
                <Col xs={24} md={24} lg={12}>
                  <ComponentPie column={columnTableMotivos} data='cantidad' name='motivo' />
                </Col>
                <Col xs={24} md={24} lg={12}>
                  <ComponentPie column={columnTableTiempos} data='total' name='categoria' />
                </Col>
              </Row>
            </div>
          )}
          {columnTableYdt && columnTableYdt?.length > 1 && (
            <div>
              <Divider orientation='left'>Mismo mes del año anterior</Divider>
              <Row gutter={[10, 10]}>
                <Col xs={24} md={24} lg={12}>
                  <TableReport
                    headTable={headTableYdt}
                    columnTable={columnTableYdt}
                    rowSelection={rowSelection}
                    exportToExcel={exportToExcel}
                    isSelect={false}
                  />
                </Col>
                <Col xs={24} md={24} lg={12}>
                  <ComponentChart
                    columnTable={columnTableYdt}
                    grafica={"fecha"}
                    tamaño={200}
                    angulo={0}
                    setShowModal={setShowModalX2}
                    graph={graphX2}
                  />
                </Col>
              </Row>
            </div>
          )}
          <ModalOptions
            setShowModal={setShowModal}
            showModal={showModal}
            dataChart={dataChart}
            handleChangeX1={handleChangeX1}
            variable={"opcionesx1"}
          />
          <ModalOptions
            setShowModal={setShowModalX2}
            showModal={showModalX2}
            dataChart={dataChartLast}
            handleChangeX1={handleChangeX2}
            variable={"opcionesx2"}
          />
        </Form>
      </Card>
    </div>
  );
};

export default AdministrarRotacionEmpleado;
