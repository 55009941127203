import { type DataVerticalTable, VerticalTable } from "components/reactreport/vertical-table"
import type { BodegasDataPrincipal } from "context/franquiciados/form-pago-context"
import { GastosFijos } from "components/franquiciados/obtener-gastos"

type Props = {
  bodegas: BodegasDataPrincipal[]
  gastos_fijos: GastosFijos[]
  cod_franquiciado: number
}

const getValorAsumido = ({ dependientes, cargo_empleados }: BodegasDataPrincipal, cod_franquiciado: number) =>
  dependientes?.length === 0
    ? 0
    : (dependientes?.reduce((acum, next) => acum + next.total, 0) /
        (cod_franquiciado === 845 ? 2.5 : dependientes?.length ?? 0) ?? 0) * cargo_empleados

export function TotalGastos({ bodegas, gastos_fijos, cod_franquiciado }: Props) {
  const totalBodegas = bodegas.reduce((acum, next) => acum + getValorAsumido(next, cod_franquiciado), 0)

  const totalGastos = gastos_fijos?.reduce((acum, next) => acum + next.total, 0) ?? 0

  const dataTotalGastos: DataVerticalTable[] = [
    {
      title: "Total valores asumidos",
      text: totalBodegas.toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,"),
      key: "total asumido",
      styleCell: { padding: 3 },
      styleCellHeader: { padding: 3 },
    },
    {
      title: "Total gastos",
      text: totalGastos.toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,"),
      key: "total gastos",
      styleCell: { padding: 3, borderBottom: "1.3px solid #000" },
      styleCellHeader: { padding: 3 },
    },
    {
      title: "Total",
      text: (totalBodegas + totalGastos).toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,"),
      key: "total",
      styleCell: { padding: 3 },
      styleCellHeader: { padding: 3 },
    },
  ]

  return (
    <VerticalTable
      dataSource={dataTotalGastos}
      styleColumn={{ flexBasis: "15%" }}
      styleColumnHeader={{ flexBasis: "35%", backgroundColor: "#dadada" }}
      containerStyle={{ alignSelf: "flex-end" }}
      roundTop={true}
    />
  )
}
