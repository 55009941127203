import { Button, TableColumnsType, Tag } from "antd"

import { useCallback, useEffect, useState } from "react"

import { useSearchTable } from "hooks/otros/useSearchTable"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSwal } from "hooks/otros/useSwal"
import { useLayout } from "hooks/otros/useLayout"

import { EditOutlined } from "@ant-design/icons"

import { url } from "config/constants"

export interface MenuType {
  key: number
  menu: string
  nivel: number
  padre?: number
  nombre_padre?: string
  orden: number
  enlace?: string
  otros_enlaces?: string[]
  estado: boolean
}

const breadcrumbs = [{ Label: "Configuraciones", Url: "/configuraciones" }, { Label: "Menú" }]

const openKeys = [breadcrumbs[0].Label]

export function useMenu() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label)

  const [pageSize, setPageSize] = useState(6)

  const { isError } = useVerifyResponse()

  const { swalError } = useSwal()

  const [menu, setMenu] = useState<MenuType[]>()

  const [openModalSort, setOpenModalSort] = useState<boolean>(false)

  const { getColumnSearchProps } = useSearchTable<MenuType>()

  const { optionsGet } = useLocalStorage()

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [menuSelected, setMenuSelected] = useState<number>()

  const getMenu = useCallback(async () => {
    try {
      const res = await fetch(`${url}configuraciones/menu-sistema`, optionsGet)
      if (isError(res)) throw new Error()

      const result = await res.json()

      setMenu(result)
    } catch (e) {
      swalError({ text: "No se pudo obtener el menú" })
    }
  }, [isError, optionsGet, swalError])

  useEffect(() => {
    getMenu()
  }, [getMenu])

  const handleAddButton = () => setOpenModal(true)

  const columns: TableColumnsType<MenuType> = [
    {
      title: "#",
      dataIndex: "key",
      key: "id",
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: "Menu",
      dataIndex: "menu",
      key: "menu",
      ...getColumnSearchProps("menu"),
    },
    {
      title: "Enlace",
      dataIndex: "enlace",
      key: "enlace",
      ...getColumnSearchProps("enlace"),
    },
    {
      title: "Nivel",
      dataIndex: "nivel",
      key: "nivel",
      sorter: (a, b) => a.nivel - b.nivel,
      filters: [...new Set(menu?.map(m => m.nivel))].map(nivel => ({
        text: `Nivel ${nivel}`,
        value: nivel,
      })),
      onFilter: (value, record) => record.nivel === value,
    },
    {
      title: "Padre",
      dataIndex: "nombre_padre",
      key: "padre",
      ...getColumnSearchProps("nombre_padre"),
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      render: (estado: boolean) => (estado ? <Tag color="green">Activo</Tag> : <Tag>Inactivo</Tag>),
      filters: [
        { text: "Activo", value: true },
        { text: "Inactivo", value: false },
      ],
      onFilter: (value, record) => record.estado === value,
      align: "center",
    },
    // {
    //   title: "Orden",
    //   dataIndex: "orden",
    //   key: "orden",
    //   align: "center",
    //   // render: (padre: number) => (
    //   //   <Button
    //   //     type="text"
    //   //     icon={<ImSortNumericAsc />}
    //   //     onClick={() => {
    //   //       setPadreSelected(padre)
    //   //       setOpenModalSort(true)
    //   //     }}
    //   //   />
    //   // ),
    // },
    {
      title: "Acción",
      dataIndex: "key",
      key: "key",
      fixed: "right",
      align: "center",
      render: (id: number) => (
        <Button
          type="text"
          icon={<EditOutlined style={{ fontSize: "1.3rem" }} />}
          onClick={() => {
            setMenuSelected(id)
            setOpenModal(true)
          }}
        />
      ),
    },
  ]

  return {
    menu,
    columns,
    openModal,
    setOpenModal,
    handleAddButton,
    openModalSort,
    setOpenModalSort,
    menuSelected,
    setMenuSelected,
    getMenu,
    pageSize,
    setPageSize,
  }
}
