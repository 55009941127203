import { url } from "config/constants";
import { LayoutContext } from "context/LayoutContext";
import { MargenVentaContext } from "context/indicadores/MargenVentaContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useExport } from "hooks/otros/useExport";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useScreenSize } from "hooks/otros/useScreenSize";
import { useCallback, useContext, useEffect, useRef } from "react";

interface CostData {
  // costo: number;
  dia_fin: string;
  dia_inicio: string;
  key: string;
  margen: number;
  semana: number;
  total: number;
}

interface PropsTable {
  dia: string;
  fecha: string;
  margen: number;
  total: number;
}

const breadcrumbs = [{ Label: "Indicador Ventas" }];

export const useAdministrarVenta = () => {
  const { optionsPost, optionsGet } = useLocalStorage();
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const { width } = useScreenSize();
  const { exportToExcel } = useExport();

  const {
    setHeadTable,
    setColumnTable,
    columnTable,
    headTable,
    setDataChart,
    setGraph,
    dataChart,
    graph,
    columnTableG,
    setColumnTableG,
    selectedRow,
    setSelectedRow,
    setHeadTableDay,
    graphX2,
    setGraphX2,
    setColumnTableDay,
    columnTableDay,
    headTableDay,
    columnTableGDay,
    setColumnTableGDay,
  } = useContext(MargenVentaContext);

  useBreadCrumbs(breadcrumbs);

  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tableRef.current) {
      const tableContainer = tableRef.current.getElementsByClassName("ant-table-body")[0];
      const lastRow = tableContainer.lastElementChild;
      if (lastRow instanceof HTMLElement) {
        lastRow.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }
  }, [columnTable]);

  const createHeadTable = (data: CostData) => {
    const columns = Object.keys(data)
      .map((key) => {
        return {
          title: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
          dataIndex: key,
          // width: (key === "dia_inicio" && "45px") || (key === "dia_fin" && "45px") || "30px",
          // (key === "total" && "30px") ||
          // (key === "margen" && "30px") ||
          // (key === "key" && "10px") ||
          // "10px",
          responsive: key === "no" && ["sm"],
          // className: "scroll-column-scroll",
          align: (key === "total" || key === "margen") && "right",
        };
      })
      .filter((column) => {
        return column.dataIndex !== "key";
      });
    return columns;
  };

  const createHeadTable2 = (data: PropsTable) => {
    const columns = Object.keys(data)
      .map((key) => {
        return {
          title: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
          dataIndex: key,
          align: (key === "total" || key === "margen") && "right",
        };
      })
      .filter((column) => {
        return column.dataIndex !== "key";
      });
    return columns;
  };

  const getVentas = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "apifsg-pr/reportes/ventas-bodega", optionsGet).then((data) => data.json());
      if (respuesta.msg) {
        const uniqueDataChart = createOptionSelect(respuesta.data[0]);
        setDataChart(uniqueDataChart);
        setGraph(uniqueDataChart);
        setHeadTable(createHeadTable(respuesta.data[0]));
        const dataConPorcentaje = respuesta.data.map((obj: CostData) => ({
          ...obj,
          margen: obj["margen"].toFixed(2) + "%",
          total: obj.total.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          totalSf: obj.total,
          // total: obj.total.toLocaleString(undefined, {
          //   minimumFractionDigits: 2,
          //   maximumFractionDigits: 2,
          // }),
          // costo: obj.costo.toLocaleString(undefined, {
          //   minimumFractionDigits: 2,
          //   maximumFractionDigits: 2,
          // }),
        }));

        setColumnTableG(respuesta.data);
        setColumnTable(dataConPorcentaje);
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsGet, setColumnTable, setColumnTableG, setDataChart, setGraph, setHeadTable]);

  // const convertToJsonString = (value: string) => {
  //   try {
  //     if (typeof value === "string") {
  //       return value;
  //     } else {
  //       return JSON.stringify(value);
  //     }
  //   } catch (error) {
  //     return value;
  //   }
  // };
  /** */
  // const exportToExcel = (tableData: any, fileName: string) => {
  //   const worksheet = XLSX.utils.json_to_sheet(
  //     tableData.map((row: []) => {
  //       return Object.keys(row).reduce((acc: any, key: any) => {
  //         acc[key] = convertToJsonString(row[key]);

  //         return acc;
  //       }, {});
  //     })
  //   );
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, `${fileName}.xlsx`);
  // };

  const FnExportToExcel = (tableDataFN: any, fileName: string) => {
    const dataExport = tableDataFN.map(({ no, dia_inicio, dia_fin, totalSf, margen }: any) => ({
      "#Semana": no,
      "Fecha inicio": dia_inicio,
      Fecha_fin: dia_fin,
      Total: totalSf,
      margen,
    }));
    exportToExcel(dataExport, "Ventas semanales");
  };

  const FnExportToExcelDetail = (tableDataFN: any, fileName: string) => {
    const dataExport = tableDataFN.map(({ fecha, dia, totalSf, margen }: any) => ({
      fecha,
      dia,
      Total: totalSf,
      margen,
    }));
    exportToExcel(dataExport, "Ventas semanales detalles");
  };

  const createOptionSelect = (data: CostData) => {
    const uniqueValues = new Set();
    Object.entries(data).forEach((value) => {
      if (typeof value[1] === "number") {
        if (value[0] !== "margen" && value[0] !== "key" && value[0] !== "semana") uniqueValues.add(value[0]);
      }
    });

    return Array.from(uniqueValues) as string[];
  };

  const createOptionSelect2 = (data: PropsTable) => {
    const uniqueValues = new Set();
    Object.entries(data).forEach((value) => {
      if (typeof value[1] === "number") {
        if (value[0] !== "margen" && value[0] !== "key") uniqueValues.add(value[0]);
      }
    });

    return Array.from(uniqueValues) as string[];
  };

  const sendData = async (dia_inicio: string, dia_fin: string) => {
    const respuesta = await fetch(url + "apifsg-pr/reportes/ventas-bodega-dia", optionsPost({ dia_inicio, dia_fin })).then(
      (data) => data.json()
    );
    if (respuesta.msg) {
      let data = createHeadTable2(respuesta.data[0]);
      setHeadTableDay(data);
      const uniqueDataChart = createOptionSelect2(respuesta.data[0]);
      setGraphX2(uniqueDataChart);

      const dataConPorcentaje = respuesta.data.map((obj: CostData) => ({
        ...obj,
        margen: obj["margen"].toFixed(2) + "%",
        totalSf: obj.total,
        total: obj.total.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      }));
      setColumnTableDay(respuesta.data);
      setColumnTableGDay(dataConPorcentaje);
    }
  };

  const handleRowSelect = async (record: CostData) => {
    await sendData(record.dia_inicio, record.dia_fin);
    if (selectedRow && selectedRow[0] === record.key) {
      setSelectedRow([]);
    } else {
      let newSelection = [];
      newSelection.push(record.key);
      setSelectedRow(newSelection);
    }
  };

  const rowSelection = {
    type: "radio",
    //  onChange: handleRowSelect,
    onSelect: handleRowSelect,
    selectedRowKeys: selectedRow[0] ? [selectedRow[0]] : [],
  };

  useEffect(() => {
    setOpenKeys(["Indicadores", "Indicadores"]);
    setSelectedKeys(["Indicadores/Ventas bodegas"]);
  }, [setOpenKeys, setSelectedKeys]);

  useEffect(() => {
    getVentas();
  }, [getVentas]);

  return {
    columnTable,
    headTable,
    FnExportToExcel,
    FnExportToExcelDetail,
    dataChart,
    graph,
    columnTableG,
    rowSelection,
    graphX2,
    columnTableDay,
    headTableDay,
    columnTableGDay,
    tableRef,
    width,
  };
};
