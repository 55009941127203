import { DatePicker, Form, type FormProps, InputNumber, Modal, Select, Switch } from "antd"
import { url } from "config/constants"
import { type GastosFijosPorBodega } from "hooks/Franquiciados/useGastosFranquiciados"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSwal } from "hooks/otros/useSwal"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import moment from "moment"
import { type Moment } from "moment"
import { useEffect, useState } from "react"

type ModalAsignarBodegaProps = {
  open: boolean
  gasto: number
  handleClose(): void
  data?: GastosFijosPorBodega
  reFetcher(): void
}

type ValuesFormAsignacion = {
  bodega: number
  valor: number
  estado: boolean
  fecha: Moment
}

export function ModalAsignarGastoBodega({ open, gasto, handleClose, data, reFetcher }: ModalAsignarBodegaProps) {
  const [form] = Form.useForm<ValuesFormAsignacion>()
  const [bodegas, setBodegas] = useState()

  const { optionsPost } = useLocalStorage()

  const { swalError, swalSuccess } = useSwal()

  const { isError } = useVerifyResponse()

  useEffect(() => {
    ;(async () => {
      try {
        const body = {
          gasto,
          incluye_bodega: data?.cod_bodega,
        }
        const res = await fetch(`${url}fapifsg-pr/franquiciados/bodegas-para-gastos-fijos`, optionsPost(body))
        if (isError(res)) throw new Error()
        const response = await res.json()
        setBodegas(response)
      } catch (e) {
        swalError({ text: `No se pudo obtener las bodegas` })
      }
    })()
  }, [gasto, isError, optionsPost, swalError, data])

  useEffect(() => {
    if (!data) return

    const { cod_bodega, valor, estado, fecha } = data

    form.setFieldsValue({
      bodega: cod_bodega,
      valor,
      estado,
      fecha: moment(fecha, "DD/MM/YYYY HH:mm:ss"),
    })
  }, [data, form])

  const handleGuardarAsignacion: FormProps<ValuesFormAsignacion>["onFinish"] = async values => {
    handleClose()
    let tipoRuta = data ? "modificar" : "registrar"
    try {
      const res = await fetch(
        `${url}fapifsg-pr/franquiciados/${tipoRuta}-gasto-fijo-por-bodega`,
        optionsPost({ ...values, gasto })
      )
      if (isError(res)) throw new Error()
      const text = await res.json()
      swalSuccess({ text })
      form.resetFields()
      reFetcher()
    } catch (e) {
      swalError({ text: `No se pudo ${tipoRuta} el tipo de gasto` })
    }
  }

  const handleOkModal = async () => {
    try {
      await form.validateFields()
      form.submit()
    } catch (e) {
      swalError({ text: "Error al guardar la asignación del gasto a la bodega" })
    }
  }

  return (
    <Modal
      open={open}
      title="Gastos fijos por bodega"
      okText={data ? "Modificar" : "Registrar"}
      cancelText="Cancelar"
      onCancel={() => {
        form.resetFields()
        handleClose()
      }}
      transitionName=""
      // style={{ top: '50px' }}
      onOk={handleOkModal}
    >
      <Form
        form={form}
        requiredMark="optional"
        onFinish={handleGuardarAsignacion}
        wrapperCol={{ xs: 18, sm: 18, md: 18, lg: 18, xl: 18 }}
        labelCol={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
      >
        <Form.Item label="Bodega" name="bodega" rules={[{ required: true, message: "Escoja una bodega" }]}>
          <Select
            showSearch
            options={bodegas}
            getPopupContainer={() => document.getElementsByClassName("ant-modal-body")[0] as HTMLElement}
            disabled={Boolean(data)}
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item label="Valor" name="valor" rules={[{ required: true, message: "Ingrese el valor" }]}>
          <InputNumber min={0} controls={false} />
        </Form.Item>
        {data && (
          <>
            <Form.Item label="Estado" name="estado" valuePropName="checked" required>
              <Switch defaultChecked />
            </Form.Item>
            <Form.Item label="Fecha creación" name="fecha" required>
              <DatePicker disabled showTime format="DD/MM/YYYY HH:mm:ss" />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  )
}
