import { ColumnProps, ColumnsType } from "antd/lib/table";
import { url } from "config/constants";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useSearchTable } from "hooks/otros/useSearchTable";
import { useSwal } from "hooks/otros/useSwal";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import { useCallback, useEffect, useState } from "react";

export interface PropsConvenio {
  key: number;
  observacion: string;
  detalles: string;
  forma_pago: string;
  periodo: string;
  llave_convenio: string;
  fecha_ingreso: any;
  fecha_ingreso_convenio: any;
}

interface responseConvenios {
  msg: "ok" | "error" | "err";
  data: PropsConvenio[];
}

export function useTableConvenios(
  dataUrl: string,
  renderActions?: ColumnProps<any>["render"],
  renderActionsEdit?: ColumnProps<any>["render"]
) {
  const [convenios, setConvenios] = useState<PropsConvenio[]>([]);

  const { optionsGet } = useLocalStorage();

  const { swalError } = useSwal();

  const { wasInvalidToken, hadError } = useVerifyResponse();

  const { getColumnSearchProps } = useSearchTable<PropsConvenio>();

  const listarConvenios = useCallback(async () => {
    try {
      const res = await fetch(`${url}${dataUrl}`, optionsGet);

      const response: responseConvenios = await res.json();

      if (wasInvalidToken(response)) return;
      if (hadError(response, "No se pudo obtener los convenios")) return;

      const { data } = response;

      setConvenios(data ? data : []);
    } catch (e) {
      swalError({ text: "No se pudo obtener los convenios" });
    }
  }, [optionsGet, wasInvalidToken, hadError, swalError, dataUrl]);

  useEffect(() => {
    listarConvenios();
  }, [listarConvenios]);

  const columnsTable: ColumnsType<any> = [
    {
      title: "Detalles",
      dataIndex: "detalles",
      key: "detalles",
      width: "40%",
      ...getColumnSearchProps("detalles"),
    },
    {
      title: "Forma de Pago",
      dataIndex: "formas_pago",
      key: "forma_pago",
    },
    {
      title: "Periodo",
      dataIndex: "periodo",
      key: "periodo",
      filters: [
        { text: "2023", value: "2023" },
        { text: "2024", value: "2024" },
        // ... más opciones de filtro
      ],
      onFilter: (value, record) => record.periodo.indexOf(value) === 0,
    },
    {
      title: "Fecha de Ingreso",
      dataIndex: "fecha_ingreso",
      key: "fecha_ingreso",
    },

    {
      title: "Editar",
      dataIndex: "key",
      key: "acciones",
      align: "center",
      fixed: "right",
      width: "7%",
      render: renderActionsEdit,
    },
    {
      title: "Eliminar",
      dataIndex: "key",
      key: "acciones",
      align: "center",
      fixed: "right",
      width: "7%",
      render: renderActions,
    },
  ];

  return {
    convenios,
    setConvenios,
    listarConvenios,
    columnsTable,
  };
}
