import { Button, Popconfirm, notification } from "antd";
import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";

import { useContext, useEffect, useState } from "react";
import { GrEdit, GrFormTrash } from "react-icons/gr";
import type { ColumnProps } from "antd/lib/table";

import "moment/locale/es";
import { useTableConvenios } from "./useTableConvenios";
import { PropsTable } from "../../components/convenios/ViewTablaIn";
import { url } from "config/constants";
import { useLocalStorage } from "hooks/otros/useLocalStorage";

const breadcrumbs = [{ Label: "Ver Convenios" }];

export function useListarConvenios() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);

  const [openModal, setOpenModal] = useState<boolean>(false);

  //Para modal editar
  const [modalRecordEdit, setModalRecordEdit] = useState<PropsTable>();
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);

  const { optionsPost } = useLocalStorage();

  useEffect(() => {
    setOpenKeys(["Convenios", "Convenios/Administrar"]);
    setSelectedKeys(["Administrar/Enviados"]);
  }, [setOpenKeys, setSelectedKeys]);

  useBreadCrumbs(breadcrumbs);

  const handleClickAction = async (id: number) => {
    const respuesta = await fetch(url + "apifsg-pr/convenios/eliminar", optionsPost({ cod_convenio: id })).then((data) =>
      data.json()
    );
    if (respuesta.msg) {
      let newData = convenios.filter((el) => el.key !== id);
      setConvenios(newData);
      notification.success({
        message: "OK",
        description: `Se elimino correctamente el convenio`,
        placement: "bottomRight",
      });
    } else {
      notification.info({
        message: "Error",
        description: `Ocurrio un error al realizar esta acción!`,
        placement: "bottomRight",
      });
    }
  };

  const handleClickActionEdit = (record: PropsTable) => {
    // setLlave(`https://infofsg.com/allku/archivos/${llave}`);
    setOpenModalEdit(true);

    setModalRecordEdit(record);
  };

  const renderActions: ColumnProps<any>["render"] = (_, record) => (
    <Popconfirm
      title='¿Estás seguro de eliminar este convenio?'
      onConfirm={() => handleClickAction(record.key)}
      okText='Sí'
      cancelText='No'
    >
      <Button type='link' icon={<GrFormTrash style={{ fontSize: "1.8rem" }} />}></Button>
    </Popconfirm>
  );

  const renderActionsEdit: ColumnProps<any>["render"] = (_, record) => (
    <Button
      icon={<GrEdit style={{ fontSize: "1.3rem" }} />}
      type='text'
      onClick={() => handleClickActionEdit(record)}
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      block
    />
  );

  const { columnsTable, convenios, setConvenios } = useTableConvenios(
    "apifsg-pr/convenios/listar",
    renderActions,
    renderActionsEdit
  );

  return {
    convenios,
    columnsTable,
    openModal,
    setOpenModal,
    modalRecordEdit,
    openModalEdit,
    setOpenModalEdit,
  };
}
