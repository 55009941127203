import { ColumnsType } from "antd/lib/table"
import React, { useEffect, useState } from "react"
import { url } from "config/constants"
import { optionsGet, swalWarning, optionsPost, swalQuestion } from "config/methods"

//Datatype de Productos de la Orden de Compras
export interface DataType {
  key: React.Key
  cod_producto: number
  producto: number
  lab: string
  precio: number
  bonifica: number
  desc: number
  parcial: number
  base: number
  boni: number
  descuento: number
  cant_FSG: number
  cant_MIA: number
}
//Datatype de 6 Ultimas compras
export interface DataTypeUltCompras {
  key: React.Key
  fecha: Date
  proveedor: string
  cantidad: number
  fracciones: number
  bonificacion: number
  Descuento: number
  PVF: number
  PVP: number
  costo: number
}
//Datatype de Ventas mensuales
export interface DataTypeVM {
  key: React.Key
  descripcion: string
  total_FSG: number
  total_MIA: number
}

//Datatype de Stock Global
export interface DataTypeStockG {
  key: React.Key
  tipo: string
  total_FSG: number
  total_MIA: number
}

//Datatype de cambiar proveedor a un producto
export interface DataTypeCambiarProveerdor {
  key: React.Key
  proveedor: string
  compras: number
  pedido: number
}


//Datatype de Escala Producto
export interface DataTypeEScalaProducto {
  key: React.Key
  cantidad: number
  bonificacion: number
  descuento: number
}

export function useOrdenCompras() {
  const [itemselectProveedores, setitemselectProveedores] = useState([{ value: "", label: "Seleccione un Proveedor" }])
  const [itemselectProducto, setitemselectProducto] = useState([{ value: "", label: "Seleccione un Producto" }])
  const [itemselectLaboratorio, setitemselectLaboratorio] = useState([{ value: "", label: "Seleccione un Laboratorio", cod_laboratorio: 0 }])
  const [valueSelectProveedor, setvalueSelectProveedor] = useState("")
  const [valueSelectLaboratorio, setvalueSelectLaboratorio] = useState("")
  const [valueselectProducto, setvalueselectProducto] = useState("")
  const [valueobservacion, setvalueobservacion] = useState("")
  const [valueCantidadProd, setvalueCantidadProd] = useState("")
  const [valueBoniProd, setvalueBoniProd] = useState("")
  const [valuePrecioProd, setvaluePrecioProd] = useState("")
  const [valueDescProd, setvalueDescProd] = useState("")
  const [valueCantMultComp, setvalueCantMultComp] = useState("")
  const [valueDescCompr, setvalueDescCompr] = useState("")
  const [valueBaseCuaBoniC, setvalueBaseCuaBoniC] = useState("")
  const [valueBoniCuaBoniC, setvalueBoniCuaBoniC] = useState("")
  const [valueRadioButton, setvalueRadioButton] = useState("1")
  const [valueFacRepo, setvalueFacRepo] = useState("")
  const [showModalEscala, setshowModalEscala]=useState(false)
  const [showModalDescMasivo, setshowModalDescMasivo]=useState(false)
  const [titleModalEscala, settitleModalEscala]=useState("")
  const [DataProductos, setDataProductos] = useState([])

  const getProveedores = async () => {
    const res = await fetch(`${url}fapifsg-pr/compras/ordencompra/getProveedores`, optionsGet()).then((data) => data.json())
    //console.log(res)
    let newget = [{ value: "", label: "Seleccione un Proveedor" }]
    res.forEach((item: any) => {
      let add = {
        value: item.Observacion,
        label: item.Observacion,
      }
      newget = [...newget, add]
    })
    setitemselectProveedores(newget)
  }

  const getLaboratorios = async () => {
    const res = await fetch(`${url}fapifsg-pr/compras/ordencompra/getLaboratorios`, optionsGet()).then((data) => data.json())
    //console.log(res)
    let newget = [{ value: "", label: "Seleccione un Laboratorio", cod_laboratorio:0 }]
    res.forEach((item: any) => {
      let add = {
        value: item.siglas,
        label: item.nombres + "-" + item.siglas,
        cod_laboratorio: item.cod_laboratorio,
      }
      newget = [...newget, add]
    })
    setitemselectLaboratorio(newget)
  }

  const getProducto = async () => {
    const res = await fetch(`${url}fapifsg-pr/compras/ordencompra/getProducto`, optionsGet()).then((data) => data.json())
    //console.log(res)
    let newget = [{ value: "", label: "Seleccione un Producto" }]
    res.forEach((item: any) => {
      let add = {
        value: item.Cod_Producto,
        label: item.Descripcion,
      }
      newget = [...newget, add]
    })
    setitemselectProducto(newget)
  }

  useEffect(() => {
    getProveedores()
    getProducto()
    getLaboratorios()
  }, [])

  const t_productos: ColumnsType<DataType> = [
    {
      title: "Productos",
      dataIndex: "producto ",
      className: "tableproducto",
      width: "100px",
    },
    {
      title: "Lab",
      className: "tableproducto",
      dataIndex: "lab ",
      //render: (key)=>{return +key+1}
      //width: "400px",
    },
    {
      title: "Precio",
      className: "tableproducto",
      dataIndex: "precio",
    },
    {
      title: "Cantidad",
      className: "tableproducto",
      dataIndex: "cantidad",
      width: "60px",
    },
    {
      title: "Bonifica",
      className: "tableproducto",
      dataIndex: "bonifica",
      width: "60px",
    },
    {
      title: "Desc.",
      className: "tableproducto",
      dataIndex: "desc",
    },
    {
      title: "Parcial",
      className: "tableproducto",
      dataIndex: "parcial",
      width: "45px",
    },
    {
      title: "Base",
      className: "tableproducto",
      dataIndex: "base",
    },
    {
      title: "Boni",
      className: "tableproducto",
      dataIndex: "boni",
    },
    {
      title: "Descu",
      className: "tableproducto",
      dataIndex: "desc",
    },
    {
      title: "Cant. FSG",
      className: "tableproducto",
      dataIndex: "cant_FSG",
    },
    {
      title: "Cant. MIA",
      className: "tableproducto",
      dataIndex: "cant_MIA",
    },
  ]

  const t_comprasultimas: ColumnsType<DataTypeUltCompras> = [
    {
      title: "Fecha",
      className: "tableproducto",
      dataIndex: "fecha",
    },
    {
      title: "Prov",
      className: "tableproducto",
      dataIndex: "Prov",
    },
    {
      title: "Cantidad",
      className: "tableproducto",
      dataIndex: "cantidad",
    },

    {
      title: "Frac",
      className: "tableproducto",
      dataIndex: "fracciones",
    },
    {
      title: "Boni",
      className: "tableproducto",
      dataIndex: "bonificacion",
    },
    {
      title: "Descu",
      className: "tableproducto",
      dataIndex: "descuento",
    },
    {
      title: "PVF",
      className: "tableproducto",
      dataIndex: "PVF",
    },
    {
      title: "PVP",
      className: "tableproducto",
      dataIndex: "PVP",
    },
    {
      title: "Costo",
      className: "tableproducto",
      dataIndex: "costo",
    },
  ]

  const t_ventasmensuales: ColumnsType<DataTypeVM> = [
    {
      title: "Descripción",
      className: "tableproducto",
      dataIndex: "descripcion",
    },
    {
      title: "Total FSG",
      className: "tableproducto",
      dataIndex: "total_FSG",
    },
    {
      title: "Total MIA",
      className: "tableproducto",
      dataIndex: "total_MIA",
    },
  ]

  const t_StockGlobal: ColumnsType<DataTypeStockG> = [
    {
      title: "Tipo",
      className: "tableproducto",
      dataIndex: "tipo",
    },
    {
      title: "Cant. FSG",
      className: "tableproducto",
      dataIndex: "cant_FSG",
    },
    {
      title: "Cant. MIA",
      className: "tableproducto",
      dataIndex: "cant_MIA",
    },
  ]

  const t_CambiarProveedorProd: ColumnsType<DataTypeCambiarProveerdor> = [
    {
      title: "Proveedor",
      className: "tableproducto",
      dataIndex: "proveedor",
    },
    {
      title: "Compras",
      className: "tableproducto",
      dataIndex: "compras",
    },
    {
      title: "Pedido",
      className: "tableproducto",
      dataIndex: "pedido",
    },
  ]

  const t_EscalaProducto: ColumnsType<DataTypeEScalaProducto> = [
    {
      title: "Cantidad",
      className: "tableproducto",
      dataIndex: "cantidad",
    },
    {
      title: "Bonificación",
      className: "tableproducto",
      dataIndex: "bonificacion",
    },
    {
      title: "Descuento",
      className: "tableproducto",
      dataIndex: "descuento",
    },
  ]


  const btnAgregar = () => {
    console.log("valueselectProducto" + valueselectProducto)
    console.log("valueCantidadProd" + valueCantidadProd)
    console.log("valueBoniProd" + valueBoniProd)
    console.log("valuePrecioProd" + valuePrecioProd)
    console.log("valueDescProd" + valueDescProd)

    if (
      valueselectProducto === "" ||
      valueCantidadProd === "" ||
      valueBoniProd === "" ||
      valuePrecioProd === "" ||
      valueDescProd === ""
    ) {
      swalWarning({ text: "Faltan datos para agregar el Productos" })
    }
  }

  const btnGenerarOrden = async () => {
    if (+valueRadioButton === 1 && valueSelectLaboratorio === "") {
      swalWarning({ text: "Debe Escoger un laboratorio." })
      return
    }
    if (+valueRadioButton === 2 && valueSelectProveedor === "") {
      swalWarning({ text: "Debe Escoger un Proveedor." })
      return
    }

    if (valueFacRepo === ""|| +valueFacRepo<=0) {
      swalWarning({ text: "Ingrese el factor de reposición" })
      return
    }
    //Por laboratorio=1  Por Proveedor=2
    if (+valueRadioButton === 1) {
      let laboratorio= itemselectLaboratorio.find((item)=>item.value===valueSelectLaboratorio)
      console.log(laboratorio)
      const res = await fetch(
        `${url}fapifsg-pr/compras/ordencompra/getTempPedidosLab`,
        optionsPost({ cod_laboratorio: laboratorio?.cod_laboratorio})
      ).then((data) => data.json())
      if (res && res.length > 0) {
        swalQuestion({
          text: "Existen productos de este laboratorio yá en la orden de compra, ¿Desea volver a generar la orden de compra?",
          icon: "warning",
          showCancelButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            return
          }
        })
      }
      const savelaboratorio=await fetch(`${url}fapifsg-pr/compras/ordencompra/saveProductosOrden`,
      optionsPost({tipo: 0, factor: valueFacRepo, SIGLAS:valueSelectLaboratorio })).then((data) => data.json())
      console.log(res)
    }else{
      const res = await fetch(
        `${url}fapifsg-pr/compras/ordencompra/getTempPedidosProv`,
        optionsPost({ observacion: valueSelectProveedor })
      ).then((data) => data.json())
      console.log(res)
      if (res&& res.length > 0) {
        swalQuestion({
          text: "Existen productos de este proveedor yá en la orden de compra,¿Desea volver a generar la orden de compra?",
          icon: "warning",
          showCancelButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            return
          }
        })
      }
      const saveproveedor=await fetch(`${url}fapifsg-pr/compras/ordencompra/saveProductosOrden`,
      optionsPost({ tipo: 1, factor: valueFacRepo, SIGLAS:valueSelectProveedor})).then((data) => data.json())

    }
  }

  const btnCuadrarBonificacion=()=>{
    if(!valueBoniCuaBoniC && !valueBaseCuaBoniC){
      swalWarning({text: "Por favor ingrese los datos en Base y Bonificación"})
    }
  }


  const btnEscalaProducto=()=>{
    settitleModalEscala("Producto")
    setshowModalEscala(true)
  }

  const btnEscalaLaboratorio=()=>{
    settitleModalEscala("Laboratorio")
    setshowModalEscala(true)
  }

  const btnDescMasivo=()=>{
    setshowModalDescMasivo(true)
  }

  const onchangeRadio = (value: any) => {
    setvalueRadioButton(value.target.value)
    setvalueSelectLaboratorio("")
    setvalueSelectProveedor("")
  }
  //e=input nombrefunction= nombredel set y a= máximo de caracteres
  const soloNumeros = (e: any, nombrefuntion: Function, a: number = 6, point: boolean = false) => {
    const regex = new RegExp(`^[0-9]${point === true ? "*\\.?[0-9]" : ""}{0,${a}}$`)
    let newValue = e.target.value
    if (regex.test(newValue)) {
      nombrefuntion(newValue)
    }
  }

  const CancelEscala=()=>{setshowModalEscala(false)}
  const CancelDescMasivo=()=>{setshowModalDescMasivo(false)}
  return {
    t_productos,
    t_comprasultimas,
    t_ventasmensuales,
    t_StockGlobal,
    t_CambiarProveedorProd,
    itemselectProducto,
    itemselectProveedores,
    setvalueSelectProveedor,
    setvalueobservacion,
    setvalueselectProducto,
    btnAgregar,
    setvalueCantidadProd,
    setvalueBoniProd,
    setvaluePrecioProd,
    setvalueDescProd,
    soloNumeros,
    setvalueCantMultComp,
    setvalueDescCompr,
    setvalueBaseCuaBoniC,
    setvalueBoniCuaBoniC,
    valueCantidadProd,
    valueBoniProd,
    valuePrecioProd,
    valueDescProd,
    valueCantMultComp,
    valueDescCompr,
    valueBaseCuaBoniC,
    valueBoniCuaBoniC,
    valueRadioButton,
    setvalueRadioButton,
    valueFacRepo,
    setvalueFacRepo,
    itemselectLaboratorio,
    setvalueSelectLaboratorio,
    btnGenerarOrden,
    onchangeRadio,
    t_EscalaProducto,
    showModalEscala,
    btnEscalaProducto,
    CancelEscala,
    showModalDescMasivo,
    btnDescMasivo,
    CancelDescMasivo,
    titleModalEscala,
    btnEscalaLaboratorio
  }
}
