import { Col, Row, DatePicker, Table, Card, Select, Button, Spin, Upload } from "antd";
import "moment/locale/es";

import { SaveOutlined } from "@ant-design/icons";
import moment from "moment";
import { DatosPeriodo, useCrearInventarioBodega } from "hooks/inventarios/useCrearInventarioBodega";
import { useVT } from "virtualizedtableforantd4";
import { ButtonExcel } from "components/otros/buttons";
import { UploadOutlined } from "@ant-design/icons";
const { Option } = Select;
export function CrearInventarioBodega() {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);
  const {
    columns,
    mes,
    setMes,
    filteredData,
    datosPeriodos,
    /*handleClickDownloadDataHistorico,
    setRowClassName,*/
    setBodega,
    bodega,
    estadoBotones,
    dataEstado,
    handleClickDownloadData,
    handleBeforeUpload,
    disabledDate,
    estadoBotonNuevo,
    handleChangeButtonGuardar,
  } = useCrearInventarioBodega();
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={8} md={6} lg={5} xl={5}>
        <Select
          allowClear
          showSearch
          placeholder="Seleccione una bodega"
          style={{ width: "100%" }}
          optionFilterProp="label"
          onChange={(value) => setBodega(value)}
          //options={datosPeriodos}
        >
          {datosPeriodos?.map((opcion: DatosPeriodo) => (
            <Option
              key={opcion.value}
              value={opcion.value}
              label={opcion.label}
              style={{
                fontWeight: opcion.estado === 1 ? "bold" : "normal",
                color: opcion.estado === 1 ? "rgb(106 40 49)" : "",
              }}
            >
              {opcion.label}
            </Option>
          ))}
        </Select>
      </Col>
      <Col xs={24} sm={8} md={6} lg={5} xl={5}>
        <DatePicker
          style={{ width: "100%" }}
          defaultValue={mes}
          value={mes}
          placeholder="Seleccione un día"
          onChange={(value) => setMes(value ?? moment())}
          inputReadOnly
          disabledDate={disabledDate}
        />
      </Col>
      <Col xs={24} sm={8} md={6} lg={5} xl={5}>
        <ButtonExcel buttonProps={{ onClick: handleClickDownloadData }}>Exportar plantilla</ButtonExcel>
      </Col>
      <Col xs={24} sm={8} md={6} lg={4} xl={4}>
        <Upload name="file" beforeUpload={handleBeforeUpload} maxCount={1} style={{ width: "100%" }}>
          <Button block icon={<UploadOutlined />} className="iconbutton">
            Cargar inventario
          </Button>
        </Upload>
      </Col>
      <Col xs={24} sm={8} md={6} lg={5} xl={5}>
        <Button
          type="primary"
          style={{ marginLeft: "5px" }}
          icon={<SaveOutlined className="iconbutton" />}
          loading={estadoBotones}
          disabled={bodega === undefined || filteredData.length <= 0 || estadoBotonNuevo}
          onClick={handleChangeButtonGuardar}
        >
          Guardar Inventario
        </Button>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          {!dataEstado && estadoBotones && (
            <div style={{ textAlign: "center" }}>
              <div style={{ margin: "0 auto" }}>
                <Spin />
              </div>
            </div>
          )}
          <Table
            columns={columns}
            dataSource={filteredData}
            components={vt}
            size="small"
            scroll={{ x: 900, y: "60vh" }}
            pagination={false}
            
            //pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default CrearInventarioBodega;
