import { Convenios } from "pages/contentconvenios/Convenios";
import CrucesCobros from "pages/contentconvenios/CrucesCobros";
import ListarCobros from "pages/contentconvenios/ListarCobros";
import { ListarConvenios } from "pages/contentconvenios/ListarConvenios";
import { RouteObject } from "react-router-dom";

export const rutasConvenios: RouteObject[] = [
  {
    path: "/convenios/ingresar",
    element: <Convenios />,
  },
  {
    path: "/convenios/enviados",
    element: <ListarConvenios />,
  },
  {
    path: "/convenios/cobros",
    element: <ListarCobros />,
  },
  {
    path: "/convenios/cruces",
    element: <CrucesCobros />,
  },
];
