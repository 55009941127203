import { url } from "config/constants";
import { useEffect, useContext, useCallback } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import { Table } from "antd";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";

export interface DataType {
  key: number;
  porcentaje: number | string;
  margen_m_c: number | string;
  margen_l_p: number | string;
  divi: string;
}

const breadCrumbs = [
  { Label: "Tabla de Nuevos Descuentos por laboratorio", Url: "politicas/nuevos" },
];

export type EditableTableProps = Parameters<typeof Table>[0];
export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export function useNuevosDescuentos() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  useBreadCrumbs(breadCrumbs);
  useEffect(() => {
    // if (window.screen.width <= 992) return;

    setOpenKeys(["Políticas Comerciales/Descuentos y Rentabilidad", "Políticas Comerciales"]);
    setSelectedKeys(["Descuentos y Rentabilidad/Nuevos Descuentos"]);
  }, [setOpenKeys, setSelectedKeys]);

  const getNuevosDescuentos = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "politicas/nuevos_descuentos", {
        method: "GET",
      }).then((res) => res.json());

      if (respuesta.msg) {
        const { data } = respuesta;
        return { data, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, []);

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: "%",
      dataIndex: "porcentaje",
      width: "200px",
      editable: true,
      align: "center",
    },
    {
      title: "",
      dataIndex: "divi",
      width: "20px",
      align: "center",
    },
    {
      title: "Medicina y Consumo",
      dataIndex: "margen_m_c",
      width: "200px",
      editable: true,
      align: "center",
    },
    {
      title: "",
      dataIndex: "divi",
      width: "20px",
      align: "center",
    },
    {
      title: "Leches y Pañales",
      dataIndex: "margen_l_p",
      width: "200px",
      editable: true,
      align: "center",
    },
  ];

  type Data = {
    key: number | string;
    porcentaje: number | string;
    margen_l_p: number | string;
    margen_m_c: number | string;
  };

  const putNuevosDescuentos = async (form: Data) => {
    try {
      const respuesta = await fetch(url + "politicas/actualiza_nuevos_descuentos", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      }).then((res) => res.json());
      if (respuesta.ok) {
        return { data: respuesta.msg, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexión", status: false };
    }
  };

  type DataForm = {
    Genericos: number;
    A: number;
    Varios: number;
    Porcentaje: number;
  };

  const putOtrosDescuentos = async (form: DataForm) => {
    try {
      const respuesta = await fetch(url + "politicas/actualiza_otros_descuentos", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      }).then((res) => res.json());
      if (respuesta.ok) {
        return { data: respuesta.msg, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexión", status: false };
    }
  };

  return { getNuevosDescuentos, defaultColumns, putNuevosDescuentos, putOtrosDescuentos };
}
