import { Col, Collapse, Form, Row, Statistic, StatisticProps, Table, type TableColumnsType } from "antd"
import { type ColumnsTableFormListType, TableFormList } from "components/otros/table-form-list"
import { ObtenerBonos } from "./obtener-bonos"
import { useContext } from "react"
import { FormPagoContext } from "context/franquiciados/form-pago-context"
import {
  DescriptionsListformItems,
  type DescriptionsListformItemsProps,
} from "components/otros/descriptions-list-form-items"
import moment, { Moment } from "moment"

export type VentasFarmacia = {
  cod_bodega: number
  farmacia: string
  ventas: number
  comision: number
}

export type Bonos = {
  cod_bono: number
  bono: string
  valor: number
}

type TotalesFranquiciado = {
  total_comision: number
  total_bonos: number
  total_menos_gastos: number
  retenciones: number
  iva_12: number
  total_mas_iva: number
  total_retenciones_mas_iva: number
  valor_a_pagar: number
}

type DataCalculosFinales = {
  key: React.Key
  datos: string
  factura: JSX.Element
  retencion: JSX.Element
}

export type CalculosFinales = { ventas_farmacias: VentasFarmacia[] } & { bonos: Bonos[] } & TotalesFranquiciado

const statisticStyle: StatisticProps["valueStyle"] = { fontSize: "inherit", textAlign: "right", fontWeight: "bold" }

export function CalculosFinalesFactura({
  onRemoveBono,
  onChangeValuebono,
  mesActual,
}: {
  onRemoveBono(): void
  onChangeValuebono(): void
  mesActual: Moment
}) {
  // console.log(
  //   moment("01/" + mesActual.format("MM/YYYY"), "DD/MM/YYYY").format("DD/MM/YYYY"),
  //   moment("01/" + mesActual.format("MM/YYYY"), "DD/MM/YYYY") < moment("01/03/2024", "DD/MM/YYYY")
  // )
  const { loading } = useContext(FormPagoContext)

  const columnsVentasFarmacia: ColumnsTableFormListType<VentasFarmacia>[] = [
    {
      title: "Farmacia",
      name: "farmacia",
    },
    {
      title: "Venta",
      name: "ventas",
      render: () => <Statistic valueStyle={{ fontSize: "inherit" }} precision={2} />,
    },
    {
      title: "Comisión",
      name: "comision",
      render: () => <Statistic valueStyle={{ fontSize: "inherit" }} precision={2} />,
    },
  ]

  const dataCalculoRetenciones: DataCalculosFinales[] = [
    {
      key: 1,
      datos: "Valor neto",
      factura: (
        <Form.Item name="total_menos_gastos" noStyle>
          <Statistic valueStyle={{ fontSize: "inherit" }} precision={2} />
        </Form.Item>
      ),
      retencion: (
        <Form.Item name="retenciones" noStyle>
          <Statistic valueStyle={{ fontSize: "inherit" }} precision={2} />
        </Form.Item>
      ),
    },
    {
      key: 2,
      datos: "IVA",
      factura: (
        <Form.Item name="iva_12" noStyle>
          <Statistic valueStyle={{ fontSize: "inherit" }} precision={2} />
        </Form.Item>
      ),
      retencion: (
        <Form.Item name="iva_12" noStyle>
          <Statistic valueStyle={{ fontSize: "inherit" }} precision={2} />
        </Form.Item>
      ),
    },
    {
      key: 3,
      datos: "Total",
      factura: (
        <Form.Item name="total_mas_iva" noStyle>
          <Statistic valueStyle={{ fontSize: "inherit", fontWeight: "bold" }} precision={2} />
        </Form.Item>
      ),
      retencion: (
        <Form.Item name="total_retenciones_mas_iva" noStyle>
          <Statistic valueStyle={{ fontSize: "inherit", fontWeight: "bold" }} precision={2} />
        </Form.Item>
      ),
    },
  ]

  const columnsCalculoRetenciones: TableColumnsType<DataCalculosFinales> = [
    {
      title: "Datos",
      dataIndex: "datos",
      key: "datos",
    },
    {
      title: "Factura",
      dataIndex: "factura",
      key: "factura",
    },
    {
      title: "Retención",
      dataIndex: "retencion",
      key: "retencion",
    },
  ]

  const itemsFactura: DescriptionsListformItemsProps["items"] = [
    {
      label: "Total comisión",
      itemName: "total_comision",
    },
    {
      label: "Total valores adicionales",
      itemName: "total_bonos",
    },
    {
      label: "Menos gastos",
      itemName: "total",
    },
    {
      label: "TOTAL",
      itemName: "total_menos_gastos",
      render: () => <Statistic valueStyle={statisticStyle} precision={2} />,
    },
    {
      label:
        moment("01/" + mesActual?.format("MM/YYYY"), "DD/MM/YYYY") < moment("01/03/2024", "DD/MM/YYYY")
          ? "12% IVA"
          : "15% IVA",
      itemName: "iva_12",
    },
    {
      label: "TOTAL",
      itemName: "total_mas_iva",
      render: () => <Statistic valueStyle={statisticStyle} precision={2} />,
    },
    {
      label: "menos_retenciones",
      itemName: "total_retenciones_mas_iva",
    },
    {
      label: "Valor a pagar",
      itemName: "valor_a_pagar",
      render: () => <Statistic valueStyle={{ textAlign: "right", fontWeight: "bold" }} precision={2} />,
    },
  ]

  const itemsTotales: DescriptionsListformItemsProps["items"] = [
    {
      label: "Total",
      itemName: "total_comision",
      render: () => <Statistic valueStyle={{ fontSize: "inherit", textAlign: "right", fontWeight: "bold" }} precision={2} />,
    },
  ]

  return (
    <Row wrap style={{ padding: "0 16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={24} md={14} lg={14} xl={14}>
        <Collapse defaultActiveKey={["1"]} style={{ marginBottom: "16px", padding: 0 }}>
          <Collapse.Panel key={"1"} header="Ventas farmacia">
            <TableFormList columns={columnsVentasFarmacia} nameList="ventas_farmacias" loading={loading} />
            <DescriptionsListformItems descriptionsProps={{ column: 2 }} items={itemsTotales} loading={loading} />
          </Collapse.Panel>
        </Collapse>
      </Col>
      <Col xs={24} sm={24} md={10} lg={10} xl={10}>
        <ObtenerBonos loading={loading} onRemove={onRemoveBono} onChangeValue={onChangeValuebono} />
      </Col>
      <Col xs={24} sm={24} md={10} lg={10} xl={10}>
        <Collapse defaultActiveKey={["1"]} style={{ marginBottom: "16px", padding: 0 }}>
          <Collapse.Panel key={"1"} header="Valores finales">
            <DescriptionsListformItems
              descriptionsProps={{ style: { paddingTop: 0 } }}
              items={itemsFactura}
              loading={loading}
            />
          </Collapse.Panel>
        </Collapse>
      </Col>
      <Col xs={24} sm={24} md={14} lg={14} xl={14}>
        <Collapse defaultActiveKey={["1"]} style={{ marginBottom: "16px", padding: 0 }}>
          <Collapse.Panel key={"1"} header="Factura">
            <Table
              loading={loading}
              pagination={false}
              columns={columnsCalculoRetenciones}
              dataSource={dataCalculoRetenciones}
            />
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  )
}
