import type { RcFile } from "antd/lib/upload"
import { json, matchPath } from "react-router-dom"
import Swal, { SweetAlertOptions } from "sweetalert2"
import type { menuSistema } from "types"
import { optionsError, optionsQuestion, optionsSuccess, optionsWarning, url, url_alku } from "./constants"
import moment, { Moment } from "moment"
import JsBarcode from "jsbarcode"
import jsPDF from "jspdf"

type ReturnNames = {
  apellidos: string
  nombres: string
}
// La función recibe como argumento los nombres completos como apellidos y nombres
export function divideFullName(fullName: string, format?: "lowerCase" | "upperCase"): ReturnNames {
  const tokens = fullName.trim().split(" ")

  let names = []

  const specialTokens = ["da", "de", "del", "la", "las", "los", "mac", "mc", "van", "von", "y", "i", "san", "santa", "yen"]

  let prev = ""
  for (let token of tokens) {
    if (specialTokens.includes(token.toLowerCase())) {
      prev += token
    } else {
      names.push(prev + token)
      prev = ""
    }
  }

  let returnNames = {
    apellidos: "",
    nombres: "",
  }

  switch (names.length) {
    case 0:
      break
    case 1:
      returnNames.apellidos = names[0]
      break
    case 2:
      returnNames.apellidos = names[0]
      returnNames.nombres = names[1]
      break
    case 3:
      returnNames.apellidos = `${names[0]} ${names[1]}`
      returnNames.nombres = names[2]
      break
    default:
      returnNames.apellidos = `${names[0]} ${names[1]}`

      names.splice(0, 1)
      names.splice(0, 1)

      returnNames.nombres = names.join(" ")
      break
  }

  if (format === "upperCase") {
    returnNames.nombres = returnNames.nombres.toUpperCase()
    returnNames.apellidos = returnNames.apellidos.toUpperCase()
  }

  return returnNames
}

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

export const verifyAccess = (menu: menuSistema[], path: string): boolean => {
  let retorno
  for (let { enlace, otros_enlaces, children } of menu) {
    let newEnlace = enlace
    newEnlace = enlace?.charAt(0) === "/" ? enlace : `/${enlace}`

    if (matchPath(newEnlace, path)) {
      retorno = true
      break
    }

    if (
      otros_enlaces?.some(e => {
        let newEnlace = e
        newEnlace = e?.charAt(0) === "/" ? e : `/${e}`
        return Boolean(matchPath(newEnlace, path))
      })
    ) {
      retorno = true
      break
    }

    retorno = verifyAccess(children ?? [], path)
    if (retorno) break

    // if((enlace && (enlace === path || `/${enlace}` === path)) || otros_enlaces?.some(e => e === `/${path}`)) {
    //   retorno = true
    //   break
    // } else {
    //   retorno = verifyAccess(children ?? [], path)
    //   if(retorno) break
    // }
  }
  return retorno ?? false
}

export const swalSuccess = (options: SweetAlertOptions) => Swal.fire({ ...optionsSuccess, ...options })

export const swalWarning = (options: SweetAlertOptions) => Swal.fire({ ...optionsWarning, ...options })

export const swalError = (options: SweetAlertOptions) => Swal.fire({ ...optionsError, ...options })

export const swalQuestion = (options: SweetAlertOptions) => Swal.fire({ ...optionsQuestion, ...options })

const headersWithToken = (): HeadersInit => ({
  "Content-Type": "application/json",
  "access-token": localStorage.getItem("token") ?? "",
})

export const optionsGet = (): RequestInit => ({
  method: "GET",
  headers: headersWithToken(),
})

export const optionsPost = <T extends object>(body: T): RequestInit => ({
  method: "POST",
  headers: headersWithToken(),
  body: JSON.stringify(body),
})

export const optionsPostForm = (body: FormData): RequestInit => ({
  method: "POST",
  headers: headersWithToken(),
  body,
})

export const verifyResponseRoutes = async (res: Response) => {
  if (res.ok) return false
  if (res.status === 403)
    throw json(
      {
        title: "Error",
        subTitle: "No está autorizado para acceder este recurso",
        link: "/",
        textLink: "Regresar a inicio",
      },
      { status: 403 }
    )
  if (res.status === 401) throw json("Unauthorized", { status: 403 })
  if (res.status === 500) {
    const result = await res.json()
    throw json(
      {
        title: "Error",
        subTitle: result,
      },
      { status: 500 }
    )
  }
  if (res.status === 404) {
    // console.log(res);
    const result = await res.json()
    throw json(
      {
        title: "404",
        subTitle: result,
      },
      { status: 404 }
    )
  }
  return false
}

export const sendAction = async (ruta: string, request: Request, params?: object) => {
  try {
    const formData = await request.formData()
    const data = formData.get("data")
    let allData = JSON.parse(data as string)
    if (params) allData = { ...allData, ...params }
    const res = await fetch(`${url}${ruta}`, optionsPost(allData))
    if (!res.ok) return { msg: await res.json(), ok: false }
    return { msg: await res.json(), ok: true }
  } catch (e) {
    console.error(e)
    throw json({ title: "Error", subTitle: "Un error ha ocurrido. Intente de nuevo más tarde" }, { status: 500 })
  }
}

export const sendActionFile = async (ruta: string, request: Request) => {
  const formData = await request.formData()
  const res = await fetch(`${url_alku}${ruta}`, optionsPostForm(formData))
  await verifyResponseRoutes(res.clone())
  swalSuccess({ text: await res.clone().json() })
  return res.json()
}

export const loaderRoute = async (ruta: string) => {
  const res = await fetch(`${url}${ruta}`, optionsGet())
  await verifyResponseRoutes(res.clone())
  return res.json()
}

//Funcion que retorna el periodo de vacaciones en formato MMMM [del] YYYY
export const createPeriodo = (fecha: string, años_periodo: `${number}-${number}`) => {
  const fecha_ingreso = moment(fecha, "DD/MM/YYYY")
  // if (moment().month() < fecha_ingreso.month())
  return `${fecha_ingreso.format("MMMM [del] ")} ${años_periodo.split("-")[0]} - ${fecha_ingreso
    .subtract(1, "months")
    .format("MMMM [del] ")} ${años_periodo.split("-")[1]}`
}

export const adicionalesGozados = (dias_vacaciones: number, dias_ocupados: number, pagados: number) => {
  // if (!periodo) return 0
  // const { dias_vacaciones, dias_ocupados, pagados } = periodo
  if (!pagados) return dias_ocupados
  if (pagados <= dias_vacaciones - 15) {
    if (dias_ocupados > dias_vacaciones - 15) {
      return dias_ocupados - (dias_vacaciones - 15)
    } else {
      return pagados
    }
  } else {
    return pagados - (dias_vacaciones - 15)
  }
}

export const adicionalesRestantes = (dias_vacaciones: number, dias_ocupados: number, pagados: number) => {
  // if (!periodo) return 0
  // const { dias_vacaciones } = periodo
  return dias_vacaciones - 15 - adicionalesGozados(dias_vacaciones, dias_ocupados, pagados)
}

export const normalesGozados = (dias_ocupados: number, pagados: number) => {
  // if (!periodo) return 0
  // const { dias_ocupados, pagados } = periodo

  if (dias_ocupados - (pagados ?? 0) > 15) {
    return 15
  } else {
    return dias_ocupados - (pagados ?? 0)
  }
}

export const normalesRestantes = (dias_ocupados: number, pagados: number) => {
  return 15 - normalesGozados(dias_ocupados, pagados)
}

export const round2 = (value: number | string) => {
  if (typeof value === "string") {
    if (isNaN(Number(value))) return value
    else
      parseFloat(value)
        .toFixed(2)
        .replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,")
  } else {
    return value.toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,")
  }
}

type DownloadMediosProps = {
  certificante: string
  usuario: string
  groupName?: string
  id_luc: number
  fecha: string | Moment
}

export const downloadPDFMedios = async ({
  certificante,
  usuario,
  groupName = "SOCIEDAD CIVIL DE HECHO DENOMINADO GRUPO USCOCOVICH",
  id_luc,
  fecha,
}: DownloadMediosProps) => {
  try {
    const doc = new jsPDF()
    const img = new Image()
    img.src = "/imagen/images.jpg"

    const canvas = document.createElement("canvas")

    const barcodeValue = "ME" + String(id_luc).padStart(10, "0")

    JsBarcode(canvas, barcodeValue, {
      format: "CODE128",
      lineColor: "#000",
      width: 2,
      height: 40,
      displayValue: true,
    })
    const barcodeData = canvas.toDataURL("image/png")

    img.onload = () => {
      doc.addImage(img, "JPG", 15, 10, 30, 15)
      doc.setDrawColor(0)
      doc.setLineWidth(0.5)
      doc.line(15, 25, 195, 25)

      // Título del documento
      doc.setFontSize(14)
      doc.setFont("times", "bold")
      doc.text(`ACTA DE ACEPTACIÓN DE MEDIOS ELECTRÓNICOS`, 105, 40, { align: "center" })

      // Cuerpo del texto
      doc.setFontSize(12)
      doc.setFont("times", "normal")
      const acceptanceText =
        `Por este medio, yo ${certificante?.toUpperCase()}, certifico que he otorgado mi consentimiento de forma ` +
        `libre, expresa, informada e inequívoca según lo dispuesto en la legislación aplicable sobre comercio ` +
        `electrónico, firmas digitales y mensajes de datos. Doy fe de que los datos personales proporcionados ` +
        `por mí han sido accesibles y sujetos a tratamiento por parte del departamento de Talento y Recursos ` +
        `Humanos de la ${groupName}. Acepto que mis datos personales y sensibles sean utilizados con el propósito ` +
        `de mantener actualizados los registros corporativos y recibir notificaciones pertinentes a través de ` +
        `medios electrónicos designados por la entidad, en conformidad con los Artículos 2, 3, 52 y 56 de la normativa vigente.` +
        `\n\nEste documento sirve como constancia irrevocable de mi aceptación y no podré objetar o retractarme ` +
        `del acceso o uso de mis datos personales y la aceptación de los medios electrónicos necesarios para ` +
        `los fines mencionados, salvo expresión contraria en los términos estipulados por la ley.`

      doc.text(acceptanceText, 17, 50, { maxWidth: 180 })

      let currentYPosition = 115

      currentYPosition += 20
      doc.setFontSize(10)
      doc.text(
        "Este documento es una confirmación de consentimiento de aceptación de medios electrónicos",
        17,
        currentYPosition,
        { maxWidth: 180 }
      )

      // Información de confirmación
      doc.setFontSize(10)
      currentYPosition += 15
      doc.text(
        `Fecha y hora de descarga por ${usuario?.toUpperCase()}: ${new Date().toLocaleString()}`,
        17,
        currentYPosition
      )

      // Pie de página con información adicional
      doc.setFontSize(8)
      doc.text(
        `Generado automáticamente por: ${certificante.toUpperCase()} en la fecha: ${typeof fecha === "string" ? fecha : fecha.format(
          "DD/MM/YYYY"
        )}. Firma manual no requerida.`,
        105,
        250,
        { align: "center" }
      )
      doc.addImage(barcodeData, "PNG", 60, 260, 90, 30)

      // Guarda el PDF
      doc.save(`Aceptacion_Reglamentos_${certificante.toUpperCase().replace(/ /g, "_")}.pdf`)
    }
    img.onerror = () => {
      swalError({ text: "Ocurrio un error" })
    }
  } catch (error) {
    swalError({ text: "Ocurrio un error" })
  }
}
