import { Principal } from "pages/contentprincipal/Principal";
import { Inventarios } from "pages/contentinventario/inventarios";
import { ReporteGeneral } from "pages/contentinventario/contentreporte/reporteGeneral";
import { InventariosRealizados } from "pages/contentinventario/contentreporte/reporteInventariosRealizados";
import { RouteObject } from "react-router-dom";
import { Productividad } from "pages/contentinventario/contentproductividad/productividadGeneral";
import { CuentasPendientes } from "pages/contentinventario/contentactas/cuentasPendientes";
import ActualizacionArchivos from "pages/contentinventario/contentactas/actualizarArchivo";
import NotasCredito from "pages/contentinventario/contentnotascreditos/notasCredito";
import ControlCaducados from "pages/contentinventario/contentcontrolcaducados/controlCaducados";
import ReporteTransferencias from "pages/contentinventario/contentreporte/reporteTransferencias";
import InventariosCobrosCaducados from "pages/contentinventario/contentreporte/reporteCaducados";
import EstadisticasInventario from "pages/contentinventario/contentreporte/estadisticas";
import ProductividadUsuario from "pages/contentinventario/contentproductividad/productividadUsuarios";
import ReporteCuestionario from "pages/contentinventario/contentreporte/reporteCuestionario";
import ReporteGrupo from "pages/contentinventario/contentreporte/reporteGrupo";
import InventarioActual from "pages/contentinventario/contentHistoricos/inventarioActual";
import InventarioHandHeld from "pages/contentinventario/contentHistoricos/inventarioHandeld";
import ControlBodegas from "pages/contentinventario/controlBodegas";
import { ControlInventarioEspecial } from "pages/contentinventario/contentInventarioEspecial/controlInventario";
import FacturasMultiples from "pages/contentinventario/contentFacturasMultiples/FacturasMultiples";
import RotacionProductos from "pages/contentinventario/contentrotacionproductos/RotacionProductos";
import { InconsistenciasBodegas } from "pages/contentinventario/contentreporte/inconsistenciasBodegas";
import CrearInventarioBodega from "pages/contentinventario/contentCrearInventarioBodega/crearInventarioBodega";

export const rutasInventarios: RouteObject[] = [
  {
    path: "/inventarios",
    element: <Principal />,
  },
  {
    path: "/inventarios/reportes",
    element: <Principal />,
  },
  {
    path: "/inventarios/productividad",
    element: <Principal />,
  },
  {
    path: "/inventarios/crear",
    element: <Inventarios />,
  },
  {
    path: "/inventarios/reporte-inventario",
    element: <ReporteGeneral />,
  },
  {
    path: "/inventarios/grupo-inventario",
    element: <ReporteGrupo />,
  },
  {
    path: "/inventarios/productividad-general",
    element: <Productividad />,
  },
  {
    path: "/inventarios/inconsistencias-bodega",
    element: <InconsistenciasBodegas />,
  },
  {
    path: "/inventarios/productividad-usuarios",
    element: <ProductividadUsuario />,
  },
  {
    path: "inventarios/cuentas_pendientes",
    element: <CuentasPendientes />,
  },
  {
    path: "inventarios/actualizacion_archivo",
    element: <ActualizacionArchivos />,
  },
  {
    path: "inventarios/reporte-realizados",
    element: <InventariosRealizados />,
  },
  {
    path: "inventarios/reportes-transferencias",
    element: <ReporteTransferencias />,
  },
  {
    path: "inventarios/reporte-caducados",
    element: <InventariosCobrosCaducados />,
  },
  {
    path: "inventarios/reporte-cuestionario",
    element: <ReporteCuestionario />,
  },
  {
    path: "inventarios/notas_creditos",
    element: <NotasCredito />,
  },
  {
    path: "inventarios/control_caducados",
    element: <ControlCaducados />,
  },
  {
    path: "inventarios/estadisticas",
    element: <EstadisticasInventario />,
  },
  {
    path: "inventarios/control-bodegas",
    element: <ControlBodegas />,
  },
  {
    path: "inventarios/control-inventario",
    element: <ControlInventarioEspecial />,
  },
  {
    path: "inventarios/crear-inventario-bodega",
    element: <CrearInventarioBodega />,
  },
  {
    path: "/inventarios/inventario-actual",
    element: <InventarioActual />,
  },
  {
    path: "/inventarios/inventario-handheld",
    element: <InventarioHandHeld />,
  },
  {
    path: "/inventarios/facturas-multiples",
    element: <FacturasMultiples />,
  },
  /*{
    path: "/indicadores/cumplimiento-convenios",
    element: <CumplimientoConvenios />,
  },*/
  {
    path: "/inventarios/rotacion-productos",
    element: <RotacionProductos />,
  },
];
