import { Key, useCallback, useContext, useEffect, useState } from "react";
import { url } from "../../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";

import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { notification, TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import Swal from "sweetalert2";
import { UserContext } from "context/UserContext";
import { optionsGet } from "config/methods";

type ConciliacionDatos = {
  Bodega: string;
  Descripcion: string;
  Fecha: string;
  Dependiente: string;
  usuarioSolicita: string;
  valor: number;
  key: React.Key;
};

const breadcrumbs = [
  { Label: "Cajas", Url: "/cajas" },
  { Label: "Pago trámites", Url: "/cajas/pago-tramites" },
  { Label: "Revisión solicitudes" },
];

const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];
export function useRevision() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[1].Label + "/" + breadcrumbs[2].Label);

  const [datos, setDatos] = useState<ConciliacionDatos[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<ConciliacionDatos[]>([]);
  const { getColumnSearchPropsNew } = useSearchTableNew<ConciliacionDatos>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]); // Aquí especificamos que es un array de `Key`

  const { userData } = useContext(UserContext);
  const [estadoGuardar, setBottonGuardar] = useState<boolean>(false);
  const [estadoBoton, setBoton] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSave = async () => {
    try {
      console.log(selectedRowKeys);
      const codUsuario = userData?.id;
      setBottonGuardar(true);
      const selectedRowKeysAsIntegers = selectedRowKeys.map((key) => parseInt(key as string, 10));

      const res = await fetch(
        url + "apifsg-pr/cajas/pagos-tramites/procesar-conciliaciones",
        optionsPost({ jsonDato: selectedRowKeysAsIntegers, codUsuario: codUsuario })
      );

      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg === "ok") {
        setDatos([]);
        await getConciliaciones();
        notification.success({
          message: "OK",
          description: `Datos procesados correctamente`,
          placement: "bottomRight",
        });
      } else {
        notification.error({
          message: "Error",
          description: `Ocurrio un error,comunicar al departamento de sistemas, ${respuesta}`,
          placement: "bottomRight",
        });
      }
    } catch (error: any) {
      Swal.fire("Error", error.toString(), "error");
    } finally {
      setBottonGuardar(false);
    }
  };
  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };
  const getConciliaciones = useCallback(async () => {
    //const shouldHideUpdateColumn = userData?.id === 7;

    setBoton(false);
    setDatos([]);
    try {
      const res = await fetch(url + "apifsg-pr/cajas/pagos-tramites/obtener-solicitudes", optionsGet());
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: ConciliacionDatos[] = respuesta.data;
        if (respuestas.length > 0) {
          setDatos(
            respuestas.map(({ key, Bodega, Descripcion, Fecha, Dependiente, usuarioSolicita, valor }) => ({
              key,
              Bodega,
              Descripcion,
              Fecha,
              Dependiente,
              usuarioSolicita,
              valor,
            }))
          );
        } else {
          setDatos([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [isError]);

  useEffect(() => {
    getConciliaciones();
  }, [getConciliaciones]);

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos);
    } else {
      const filtered = datos.filter((item) => item.Bodega.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys: Key[]) => {
      setSelectedRowKeys(selectedKeys);
      setBoton(selectedKeys.length > 0);
    },
  };
  const onRowClick = (record: ConciliacionDatos) => {
    const selectedKeys = [...selectedRowKeys];
    const idx = selectedKeys.indexOf(record.key);
    if (idx > -1) {
      selectedKeys.splice(idx, 1);
    } else {
      selectedKeys.push(record.key);
    }
    setSelectedRowKeys(selectedKeys);
    setBoton(selectedKeys.length > 0);
  };
  const columns: TableColumnsType<ConciliacionDatos> = [
    {
      title: "Farmacia",
      dataIndex: "Bodega",
      key: "Bodega",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNew("Bodega"),
    },
    {
      title: "Descripcion",
      dataIndex: "Descripcion",
      key: "Descripcion",
      className: "letraPequenaVisor2",
    },
    {
      title: "Fecha solicitud",
      dataIndex: "Fecha",
      className: "letraPequenaVisor2",
      key: "Fecha",
      sorter: (a, b) => moment(a.Fecha).unix() - moment(b.Fecha).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Usuario solicita",
      dataIndex: "usuarioSolicita",
      className: "letraPequenaVisor2",
      key: "usuarioSolicita",
      ...getColumnSearchPropsNew("usuarioSolicita"),
    },
    {
      title: "Dependiente",
      dataIndex: "Dependiente",
      className: "letraPequenaVisor2",
      key: "Dependiente",
      ...getColumnSearchPropsNew("Dependiente"),
    },
    {
      title: "Valor",
      dataIndex: "valor",
      className: "letraPequenaVisor2",
      key: "valor",
      align: "right",
      render: (_, record) => (
        <span style={{ color: record.valor === 0 ? "lightgray" : "black" }}>{`${formateaCantidad(record.valor)}`}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
  ];

  return {
    columns,
    isModalOpen,
    rowSelection,
    filteredData,
    handleOk,
    handleCancel,
    handleSave,
    estadoGuardar,
    estadoBoton,
    onRowClick,
  };
}
