import {
  Row,
  Col,
  Card,
  Divider,
  Form,
  Select,
  Input,
  Typography,
  Radio,
  DatePicker,
  RadioChangeEvent,
  FormInstance,
} from "antd";
import { dataC, dateFormat } from "./MiInformacion";
import { Cursos } from "./Cursos";
import { useEffect, useState } from "react";
import { useFormularioActualizacion } from "hooks/rrhh/actualizacion/useFormularioActualizacion";

export interface Props {
  setValidaOperacion: React.Dispatch<React.SetStateAction<boolean>>;
  validaOperacion: boolean;
  form: FormInstance<any>;
  validaEstudio: boolean;
  handleChange2: (value: RadioChangeEvent) => void;
}

const modalidad = [
  {
    value: 1,
    label: "Online",
  },
  {
    value: 2,
    label: "Presencial",
  },
  {
    value: 3,
    label: "Semipresencial",
  },
];

export const Extras = ({ validaOperacion, setValidaOperacion, form, validaEstudio, handleChange2 }: Props) => {
  let { getSangre, getInstruccion } = useFormularioActualizacion();
  const [nivelAcademico, setNivelAcademico] = useState<dataC[]>([]);
  const [tipoSangre, setTipoSangre] = useState<dataC[]>([]);

  useEffect(() => {
    const fnSangre = async () => {
      let res = await getSangre();
      setTipoSangre(res.data);
    };

    const fnInstruccion = async () => {
      let res = await getInstruccion();
      setNivelAcademico(res.data);
    };

    fnInstruccion();
    fnSangre();
  }, [getSangre, getInstruccion, form]);

  const handleChange = ({ target: { value } }: RadioChangeEvent) => {
    form.setFieldsValue({ cual_cirugia: "" });
    if (value === true) {
      setValidaOperacion(true);
    } else {
      setValidaOperacion(false);
    }
  };

  return (
    <div>
      <Row wrap={true}>
        <Col xs={24} md={24} lg={24}>
          <Card title='Datos Médicos' type='inner' size='small'>
            <Row wrap={true}>
              <Col xs={12} xl={12}>
                <Form.Item
                  label='Tipo de sangre'
                  style={{ width: "98%" }}
                  name='cod_tipo_sangre'
                  rules={[{ required: true, message: "Tipo de Sangre de obligatorio" }]}
                >
                  <Select placeholder='ESCOJA UN TIPO DE SANGRE' options={tipoSangre} showSearch></Select>
                </Form.Item>
              </Col>
              <Col xs={12} xl={12}>
                <Form.Item
                  label={
                    <Typography.Text ellipsis={{ tooltip: "Fecha de su Último Medico" }}>
                      Fecha de su último médico
                    </Typography.Text>
                  }
                  style={{ width: "98%" }}
                  name='fecha_examen'
                  rules={[{ required: true, message: "Seleccione la fecha" }]}
                >
                  <DatePicker placeholder='Seleccione una opción' style={{ width: "97%" }} format={dateFormat} />
                </Form.Item>
              </Col>
            </Row>

            <Row wrap={true}>
              <Col xs={12} xl={12}>
                <Form.Item
                  label={
                    <Typography.Text ellipsis={{ tooltip: "¿Operaciones o cirugías realizadas?" }}>
                      ¿Operaciones o cirugías realizadas?
                    </Typography.Text>
                  }
                  style={{ width: "95%" }}
                  name='es_cirugias'
                  rules={[{ required: true, message: "Seleccione una opción" }]}
                >
                  <Radio.Group onChange={handleChange}>
                    <Radio value={true}> Si </Radio>
                    <Radio value={false}> No </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={12} xl={12}>
                <Form.Item
                  label={<Typography.Text ellipsis={{ tooltip: "¿ Cual  ?" }}>¿ Cúal ?</Typography.Text>}
                  style={{ width: "95%" }}
                  name='cual_cirugia'
                  rules={[{ required: validaOperacion, message: "Cual cirugia obligatorio" }]}
                >
                  <Input placeholder='Ingrese cúal fue esa cirugía u operación' disabled={!validaOperacion} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Divider />
          <Card title='Datos Académicos|Educación' type='inner' size='small'>
            <Divider orientation='center'>Estudios</Divider>
            <Row wrap={true}>
              <Col xs={24} xl={12}>
                <Form.Item
                  label='Nivel académico alcanzado'
                  style={{ width: "97%" }}
                  name='cod_instruccion_academica'
                  rules={[{ required: true, message: "Nivel de instrucción es obligatoria" }]}
                >
                  <Select placeholder='SELECCIONE SU NIVEL MÁS ALTO' options={nivelAcademico} showSearch></Select>
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item
                  label='Título académico obtenido'
                  style={{ width: "98%" }}
                  name='titulo'
                  rules={[{ required: true, message: "Título académico es obligatorio" }]}
                >
                  <Input style={{ width: "98%" }} placeholder='Escriba su título' />
                </Form.Item>
              </Col>
            </Row>
            <Row wrap={true}>
              <Col xs={24} xl={6}>
                <Form.Item
                  label={
                    <Typography.Text ellipsis={{ tooltip: "¿Estudias en la actualidad?" }}>
                      ¿Estudias en la actualidad?
                    </Typography.Text>
                  }
                  style={{ width: "98%" }}
                  name='es_estudias'
                  rules={[{ required: true, message: "Seleccione una opción" }]}
                >
                  <Radio.Group onChange={handleChange2}>
                    <Radio value={true}> Si </Radio>
                    <Radio value={false}> No </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={8} xl={6}>
                <Form.Item
                  label='¿Qué estudias?'
                  style={{ width: "98%" }}
                  name='que_estudias'
                  rules={[{ required: validaEstudio, message: "Qué estudias es obligatorio" }]}
                >
                  <Input style={{ width: "98%" }} placeholder='Escriba que estudias' disabled={!validaEstudio} />
                </Form.Item>
              </Col>
              <Col xs={8} xl={6}>
                <Form.Item
                  label='¿Dónde estudias?'
                  style={{ width: "98%" }}
                  name='donde_estudias'
                  rules={[{ required: validaEstudio, message: "Donde estudias es obligatorio" }]}
                >
                  <Input style={{ width: "98%" }} placeholder='Escriba que estudias' disabled={!validaEstudio} />
                </Form.Item>
              </Col>
              <Col xs={8} xl={6}>
                <Form.Item
                  label='Modalidad'
                  style={{ width: "98%" }}
                  name='modalidad'
                  rules={[{ required: validaEstudio, message: "Modalidad es obligatoria" }]}
                >
                  <Select
                    placeholder='SELECCIONE UNA MODALIDAD'
                    options={modalidad}
                    showSearch
                    style={{ width: "98%" }}
                    disabled={!validaEstudio}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation='center'>Formación</Divider>
            <Row wrap={true}>
              <Col xs={24} xl={24}>
                <Form.Item label='Primaria(Nombre de la Institución)' style={{ width: "99%" }}>
                  <Input.Group compact>
                    <Form.Item
                      name={["primaria", "institución"]}
                      noStyle
                      rules={[{ required: true, message: "Nombre Institución" }]}
                    >
                      <Input style={{ width: "50%" }} placeholder='Institución' autoComplete='off' />
                    </Form.Item>
                    <Form.Item
                      name={["primaria", "fin"]}
                      noStyle
                      rules={[{ required: true, message: "Fecha fin es obligatoria" }]}
                    >
                      <DatePicker placeholder='Año que culminó' style={{ width: "50%" }} picker='year' />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row wrap={true}>
              <Col xs={24} xl={24}>
                <Form.Item label='Secundaria(Nombre de la Institución)' style={{ width: "99%" }}>
                  <Input.Group compact>
                    <Form.Item name={["secundaria", "institución"]} noStyle>
                      <Input style={{ width: "50%" }} placeholder='Institución' autoComplete='off' />
                    </Form.Item>
                    <Form.Item name={["secundaria", "fin"]} noStyle>
                      <DatePicker placeholder='Año que culminó' style={{ width: "50%" }} picker='year' />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row wrap={true}>
              <Col xs={24} xl={24}>
                <Form.Item label='Tercer Nivel(Nombre de la Institución)' style={{ width: "99%" }}>
                  <Input.Group compact>
                    <Form.Item name={["tercer", "institución"]} noStyle>
                      <Input style={{ width: "50%" }} placeholder='Institución' autoComplete='off' />
                    </Form.Item>

                    <Form.Item name={["tercer", "fin"]} noStyle>
                      <DatePicker
                        placeholder='Año que culminó'
                        style={{ width: "50%" }}
                        // format={dateFormat}
                        picker='year'
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row wrap={true}>
              <Col xs={24} xl={24}>
                <Form.Item label='Cuarto Nivel(Nombre de la Institución)' style={{ width: "99%" }}>
                  <Input.Group>
                    <Form.Item name={["cuarto", "institución"]} noStyle>
                      <Input style={{ width: "50%" }} placeholder='Institución' autoComplete='off' />
                    </Form.Item>
                    <Form.Item name={["cuarto", "fin"]} noStyle>
                      <DatePicker placeholder='Año que culminó' style={{ width: "50%" }} picker='year' />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation='center'>Cursos</Divider>
            <Cursos />
          </Card>
          <Divider />
        </Col>
      </Row>
    </div>
  );
};
