import * as XLSX from "xlsx";

export function useExport() {
  const exportToExcel = <T extends object>(tableData: T[], fileName: string) => {
    const worksheet = XLSX.utils.json_to_sheet(
      tableData.map((row) =>
        Object.keys(row).reduce((acc: any, key: string) => {
          if (key === "fecha") {
            // Asume que 'date' es el nombre de la propiedad de fecha
            acc[key] = XLSX.SSF.format("yyyy-mm-dd", row[key as keyof T]); // Formato de fecha
          } else {
            acc[key] = row[key as keyof T];
          }
          return acc;
        }, {})
      )
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return {
    exportToExcel,
  };
}
