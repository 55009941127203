import { ModalListSelect } from "components/otros/ModalListSelect"
import { GastosTable, type GastosTableProps } from "components/franquiciados/gastos-table"

import { useState, useContext } from "react"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSwal } from "hooks/otros/useSwal"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"

import { FormPagoContext } from "context/franquiciados/form-pago-context"

import { url } from "config/constants"

import { type FormInstance } from "antd"
import { type DefaultOptionType } from "antd/lib/select"

export type GastosFijos = {
  cod_gasto: number
  gasto: string
  valor: number
  n_bodegas: number
  total: number
}

type Props = {
  n_bodegas: number
  calcularTotalGastosFijos(form: FormInstance): void
  calcularTotalFinal(form: FormInstance): void
  calculosFinales(form: FormInstance): void
}

export function ObtenerGastos({ n_bodegas, calcularTotalGastosFijos, calcularTotalFinal, calculosFinales }: Props) {
  const { form, loading } = useContext(FormPagoContext)

  const [gastos, setGastos] = useState<DefaultOptionType[]>()
  const [openModal, setOpenModal] = useState(false)

  const { optionsPost } = useLocalStorage()
  const { isError } = useVerifyResponse()
  const { swalError } = useSwal()

  const fetchGastosForSelect = async () => {
    try {
      const body = form.getFieldValue("gastos_fijos")
        ? { gastos: [...(form.getFieldValue("gastos_fijos") as GastosFijos[]).map(({ cod_gasto }) => cod_gasto)] }
        : { gastos: [] }
      const result = await fetch(`${url}fapifsg-pr/franquiciados/listar-tipo-gastos-para-select`, optionsPost(body))
      if (isError(result)) throw new Error()
      const res = await result.json()
      setGastos(res)
    } catch (e) {
      swalError({ text: "No se pudo obtener los gastos" })
    }
  }

  const handleChooseGasto = async (value: string | number) => {
    try {
      const res = await fetch(`${url}fapifsg-pr/franquiciados/obtener-gasto`, optionsPost({ cod_gasto: value }))
      if (isError(res)) throw new Error()

      const response = await res.json()

      form.setFieldValue("gastos_fijos", [
        ...form.getFieldValue("gastos_fijos"),
        {
          ...response,
          valor: response.valor === 0 ? undefined : response.valor,
          n_bodegas,
          total: response.valor * n_bodegas,
        },
      ])

      setOpenModal(false)
    } catch (e) {
      swalError({ text: "No se pudo obtener los datos del gasto" })
    }
  }

  const handleAddGastoInBlank = () => {
    form.setFieldValue("gastos_fijos", [
      ...form.getFieldValue("gastos_fijos"),
      { cod_gasto: 0, gasto: undefined, valor: undefined, n_bodegas, total: 0 },
    ])
  }

  const handleChangeValor: GastosTableProps["handleChangeValor"] = (newValue, itemIndex) => {
    const n_bodegas = form.getFieldValue(["gastos_fijos", itemIndex, "n_bodegas"])
    form.setFieldValue(["gastos_fijos", itemIndex, "total"], n_bodegas * newValue)
    calcularTotalGastosFijos(form)
    calcularTotalFinal(form)
    calculosFinales(form)
  }

  const handleChangeNumeroBodegas: GastosTableProps["handleChangeNumeroBodegas"] = (nBodegas, itemIndex) => {
    const value = form.getFieldValue(["gastos_fijos", itemIndex, "valor"])
    form.setFieldValue(["gastos_fijos", itemIndex, "total"], nBodegas * value)
    calcularTotalGastosFijos(form)
    calcularTotalFinal(form)
    calculosFinales(form)
  }

  const handleRemove = () => {
    calcularTotalGastosFijos(form)
    calcularTotalFinal(form)
    calculosFinales(form)
  }

  return (
    <>
      <GastosTable
        numeroBodegas={n_bodegas}
        handleAddGasto={async () => {
          await fetchGastosForSelect()
          setOpenModal(true)
        }}
        handleAddGastoInBlank={handleAddGastoInBlank}
        handleChangeNumeroBodegas={handleChangeNumeroBodegas}
        handleChangeValor={handleChangeValor}
        loading={loading}
        onRemoveItemTable={handleRemove}
        // indexSaldoEnContra={indexSaldoEnContra}
      />
      <ModalListSelect
        modalProps={{ open: openModal, title: "Gastos", onCancel: () => setOpenModal(false) }}
        onChange={handleChooseGasto}
        dataSource={gastos ?? []}
      />
    </>
  )
}
