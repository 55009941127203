import { Button, Col, Row, Table, Typography } from "antd"
import { ModalReport } from "components/otros/ModalReport"
import { DataDetSolicitud, DataTypeSolicitud, useSolicitudesFundas } from "hooks/bodega/useSolicitudesFundas"
import { useLayout } from "hooks/otros/useLayout"
import { ReportSolicitudFundas } from "components/bodega/ReportSolicitudFundas"
import "./ReporteFundas.css"
const { Title } = Typography

const breadcrumbs = [
  { Label: "Bodega", Url: "/bodega" },
  { Label: "Solicitud de Fundas", Url: "/bodega/solicitud-fundas" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`

export function SolicitudesFundas() {
  useLayout(breadcrumbs, openKeys, selectKeySider)
  const {
    columnssolicitud,
    columnsdetsolicitud,
    data,
    rowSelection,
    visibleButton,
    DataPdf,
    btn_AprobarSeleccionados,
    openModal,
    setopenModal,
  } = useSolicitudesFundas()
  return (
    <>
      <Row style={{background: "white", padding: "5px" }} align="middle" justify="center">
        <Col sm={24} md={12} style={{ paddingLeft: "20px" }}>
          <Title level={4}>Solicitudes de Fundas por Aprobar</Title>
        </Col>
        <Col sm={24} md={12} style={{ paddingLeft: "20px" }}>
          <Row justify="end">
            {visibleButton && (
              <Button type="primary" onClick={() => btn_AprobarSeleccionados()}>
                Aprobar seleccionadas
              </Button>
            )}
          </Row>
        </Col>
      </Row>
      <Row style={{ background: "white" }}  className="table-container">
        <Table
          style={{ width: "100%", margin: "5px" }}
          columns={columnssolicitud as DataTypeSolicitud[]}
          rowSelection={rowSelection}
          dataSource={data}
          expandable={{
            expandedRowRender: (record) => {
              let detalle: any = record.detalle
              return (
                <>
                  <Table
                    style={{ width: "100%", margin: "10px" }}
                    columns={columnsdetsolicitud as DataDetSolicitud[]}
                    dataSource={detalle}
                    pagination={false}
                  />
                </>
              )
            },
          }}
        />
      </Row>
      <ModalReport
        openModal={openModal}
        setOpenModal={setopenModal}
        titleModal="Reporte de Solicitud de Fundas"
        pdfName={`REPORTE SOLICITUD`}
      >
        <ReportSolicitudFundas DataSolicitud={DataPdf} />
      </ModalReport>
    </>
  )
}
