import { Col, DatePicker, Row, Space, Switch, Table } from "antd"
import { ButtonExcel } from "components/otros/buttons"
import { useReporteRecibidos } from "hooks/rrhh/llamadosatencion/useReporteRecibidos"

export function ReporteRecibidos() {
  const {
    fechaRevision,
    setFechaRevision,
    soloMulta,
    handleDownload,
    columnsTable,
    llamadosAtencion,
    pageSize,
    setPageSize,
  } = useReporteRecibidos()

  return (
    <Row
      wrap
      style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff", display: "flex", alignItems: "center" }}
      gutter={[16, 16]}
    >
      <Col xs={24} sm={12} md={12} lg={10} xl={8}>
        <Space>
          <>Fecha recibido:</>
          <DatePicker
            value={fechaRevision}
            onChange={date => date && setFechaRevision(date)}
            showTime
            format="DD/MM/YYYY HH:mm:ss"
            allowClear={false}
          />
        </Space>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6} xl={8}>
        <Space>
          <>Solo con multa?:</>
          <Switch defaultChecked onChange={checked => (soloMulta.current = checked)} />
        </Space>
      </Col>
      <Col xs={24} sm={24} md={6} lg={8} xl={8}>
        <ButtonExcel buttonProps={{ onClick: handleDownload }} />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Table
          columns={columnsTable}
          scroll={{ x: true }}
          dataSource={llamadosAtencion}
          rowKey="numeracion"
          pagination={{
            pageSize,
            size: "default",
            onShowSizeChange: (_, size) => setPageSize(size),
            pageSizeOptions: [5, 10, 20, 50, 100],
          }}
        />
      </Col>
    </Row>
  )
}
