import { Button, Form, FormProps, Popconfirm } from "antd"
import { useDataForTable } from "hooks/rrhh/llamadosatencion/useDataForTable"

import { EditOutlined, QuestionCircleOutlined, CheckOutlined } from "@ant-design/icons"
import { FormInModal } from "components/rrhh/componentsllamadosatencion/FormInModal"
import { useContext, useEffect, useMemo, useState } from "react"
import { LayoutContext } from "context/LayoutContext"
// import { useScreenSize } from "hooks/useScreenSize"
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs"

import moment from "moment"

import type { valuesFormLlamadosAtencion } from "components/rrhh/componentsllamadosatencion/FormInModal"
import type { dataLlamadosAtencion } from "hooks/rrhh/llamadosatencion/useDataForTable"
import type { ColumnProps } from "antd/lib/table"
import type { Moment } from "moment"
import { RangePickerProps } from "antd/lib/date-picker"
import { FormTipoConsultaAndTable } from "components/rrhh/componentsllamadosatencion/FormTipoConsultaAndTable"
import { url } from "config/constants"
import { useSwal } from "hooks/otros/useSwal"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"

const breadcrumbs = [
  { Label: "Recursos humanos", Url: "/rrhh" },
  { Label: "Llamados de atención", Url: "/rrhh/llamados-de-atencion" },
  { Label: "Modificar revisión" },
]

export function ModificaLlamadoAtencion() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext)

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [disabledPorcentajeMulta, setDisabledPorcentajeMulta] = useState<boolean>(true)
  const [selectedDates, setSelectedDates] = useState<[Moment, Moment]>([moment().subtract(1, "months"), moment()])
  const [selectedTipoConsulta, setSelectedTipoConsulta] = useState<number>(2)

  const [form] = Form.useForm<valuesFormLlamadosAtencion>()

  const { swalError, swalSuccess } = useSwal()

  const { optionsPost } = useLocalStorage()

  const { wasInvalidToken, hadError } = useVerifyResponse()

  // const { width } = useScreenSize()

  // const isWeb = width >= 778

  useBreadCrumbs(breadcrumbs)

  useEffect(() => {
    setOpenKeys(["Recursos Humanos", "Recursos Humanos/Llamados de atención"])
    setSelectedKeys(["Llamados de atención/Modificar Revisión"])
  }, [setOpenKeys, setSelectedKeys])

  const renderButtonEdit: ColumnProps<dataLlamadosAtencion>["render"] = (
    value,
    {
      multa,
      porcentaje,
      reglamentos,
      bodega,
      departamento,
      observacion,
      fecha_falta,
      fecha_creacion,
      periodo_actividad,
      descripcion,
      tipo_actividad,
    }
  ) => (
    <Button
      icon={<EditOutlined style={{ fontSize: "1.3rem" }} />}
      type="text"
      block
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      onClick={() => {
        setSelectedIndex(llamadosAtencion.findIndex(ll => ll.key === value))
        form.setFieldsValue({
          multa,
          porcentaje,
          reglamentos: (reglamentos as Array<{ cod_literal: number }>).map(reg => reg.cod_literal),
          bodega,
          departamento,
          observacion,
          fecha_falta,
          fecha_creacion,
          periodo_actividad: moment(periodo_actividad, "YYYYMM"),
          descripcion,
          tipo_actividad,
        })
        setDisabledPorcentajeMulta(!multa)
        setOpenModal(true)
      }}
    />
  )

  const bodyGetData = useMemo(() => {
    const fecha_fin = moment(selectedDates[1].format("DD/MM/YYYY"), "DD/MM/YYYY")
    return {
      tipo_consulta: selectedTipoConsulta,
      fecha_inicio: selectedDates[0].format("DD/MM/YYYY"),
      fecha_fin: fecha_fin.add(1, "days").format("DD/MM/YYYY"),
    }
  }, [selectedDates, selectedTipoConsulta])

  const { llamadosAtencion, columnsTable, setllamadosAtencion, listarLlamadosAtencion } = useDataForTable(
    "apifsg-pr/recursos-humanos/listar-llamados-atencion-para-modificar-revision",
    renderButtonEdit,
    bodyGetData
  )

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleChangeDates: RangePickerProps["onChange"] = values => {
    setSelectedDates(values as [Moment, Moment])
  }

  const handleFinishForm: FormProps["onFinish"] = async ({
    multa,
    porcentaje,
    motivo,
    reglamentos,
    descripcion,
    periodo_actividad,
    tipo_actividad,
    observacion,
  }: valuesFormLlamadosAtencion) => {
    try {
      const data = {
        cod_llamado_atencion: llamadosAtencion[selectedIndex].key,
        multa,
        porcentaje,
        motivo,
        reglamentos,
        descripcion,
        periodo_actividad: periodo_actividad.format("YYYYMM"),
        tipo_actividad,
        descripcion_solicitud: observacion,
      }
      const res = await fetch(`${url}apifsg-pr/recursos-humanos/modificar-llamado-atencion`, optionsPost(data))
      if (res.status !== 200) throw new Error()
      const response = res.json()

      if (wasInvalidToken(response)) return
      if (hadError(response, "No se pudo actualizar el estado del llamado de atención")) return
      setOpenModal(false)

      llamadosAtencion.length === 1 ? setllamadosAtencion([]) : listarLlamadosAtencion(bodyGetData)
      swalSuccess({ title: "Aprobado con éxito!" })
    } catch (e) {
      swalError({ text: "No se pudo actualizar el estado del llamado de atención" })
    }
  }

  return (
    <>
      <FormTipoConsultaAndTable
        selectedTipoConsulta={selectedTipoConsulta}
        setSelectedTipoConsulta={setSelectedTipoConsulta}
        selectedDates={selectedDates}
        handleChangeDates={handleChangeDates}
        columnsTable={columnsTable}
        data={llamadosAtencion}
      />
      <FormInModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        form={form}
        handleFinishForm={handleFinishForm}
        llamadosAtencion={llamadosAtencion}
        selectedIndex={selectedIndex}
        disabledPorcentajeMulta={disabledPorcentajeMulta}
        setDisabledPorcentajeMulta={setDisabledPorcentajeMulta}
        modalFooter={[
          <Popconfirm
            key="modificar"
            title="¿Modificar Revisión?"
            onConfirm={() => form.submit()}
            icon={<QuestionCircleOutlined style={{ color: "var(--primary)" }} />}
            okText="Si"
            cancelText="No"
          >
            <Button icon={<CheckOutlined />} type="primary">
              Modificar
            </Button>
          </Popconfirm>,
        ]}
        titleModal="Modificar Revisión"
      />
    </>
  )
}
