import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { url } from "../../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { Button, TableColumnsType } from "antd";
import { EyeOutlined } from "@ant-design/icons";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";
import { RangePickerProps } from "antd/lib/date-picker";

interface PropsDescripcionDependientes {
  key: React.Key;
  Nombre: string;
  total: string;
  FechaCobro: string;
  productos:PropsDescripcionProductos[];
}
interface PropsDescripcionProductos {
  key: React.Key;
  Descripcion: string;
  Parcial: number;
  Cajas: number;
  Fracciones: number;
}
type InventarioReporteCobroCaducados = {
  Farmacia: string;
  Cod_Bodega: number;
  FechaInicio: string;
  FechaFin: string;
  CobroCaducado: number;
  detalleCaducados: PropsDescripcionDependientes[];
  key: React.Key;
};

const breadcrumbs = [
  { Label: "Inventarios", Url: "/inventarios" },
  { Label: "Reportes", Url: "/inventarios/reportes" },
  { Label: "Reporte caducados" },
];

const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];
export function useReporteCaducado() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[1].Label + "/" + breadcrumbs[2].Label);
  const [[fechaInicio, fechaFin], setFechas] = useState<[Moment | null, Moment | null]>([null, null]);
  const [datosInicial, setDatos] = useState<InventarioReporteCobroCaducados[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<InventarioReporteCobroCaducados[]>([]);
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } =
    useSearchTableNew<InventarioReporteCobroCaducados>();

  const [estadoBoton, setBoton] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    
  const [data, setDatakey] = useState<PropsDescripcionProductos[]>([]);
  const { exportToExcel } = useExport();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();
  const getBodegas = useCallback(async () => {
    try {
      const res = await fetch(
        url + "apifsg-pr/inventarios/obtener_reporte_cobros_realizados",
        optionsPost({ fechaInicio: fechaInicio!.format("DD/MM/YYYY"), fechaFin: fechaFin!.format("DD/MM/YYYY") })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        setBoton(true);
        const respuestas: InventarioReporteCobroCaducados[] = respuesta.data;
        setDatos(
          respuestas.map(({ Farmacia, Cod_Bodega, FechaInicio, FechaFin, CobroCaducado, detalleCaducados, key }) => ({
            Farmacia,
            Cod_Bodega,
            FechaInicio,
            FechaFin,
            CobroCaducado,
            detalleCaducados,
            key,
          }))
        );
      } else {
        setDatos([]);
        setBoton(false);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setDatos([]);
      setBoton(false);
      return { msg: "no_conexion", status: false };
    }
  }, [optionsPost, isError, fechaInicio, fechaFin]);

  useEffect(() => {
    getBodegas();
  }, [getBodegas]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (!searchText) {
      setFilteredData(datosInicial);
    } else {
      const filtered = datosInicial.filter((item) => item.Farmacia.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datosInicial]);

  const columns: TableColumnsType<InventarioReporteCobroCaducados> = [
    {
      title: "Farmacia",
      dataIndex: "Farmacia",
      key: "Farmacia",
      ...getColumnSearchPropsNewVisorReporte("Farmacia"),
    },
    {
      title: "Fecia Inicio",
      dataIndex: "FechaInicio",
      key: "FechaInicio",
      sorter: (a, b) => moment(a.FechaInicio).unix() - moment(b.FechaInicio).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Fecha Fin",
      dataIndex: "FechaFin",
      key: "FechaFin",
      sorter: (a, b) => moment(a.FechaFin).unix() - moment(b.FechaFin).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Total caducado",
      dataIndex: "CobroCaducado",
      key: "CobroCaducado",
    },
  ];

  const columsDataDependiente: TableColumnsType<PropsDescripcionDependientes> = [
    {
      title: "Dependiente",
      dataIndex: "Nombre",
      key: "Nombre",
      width: "445px",
      fixed: "left",
      //...getColumnSearchPropsNewBodegasReporte("usuario"),
    },
    {
      title: "Fecha Cobro",
      dataIndex: "FechaCobro",
      key: "FechaCobro",
      //sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Total cobrado",
      dataIndex: "total",
      key: "total",
      //sorter: (a, b) => a.total - b.total,
    },
    {
        title: "Productos",
        dataIndex: "productos",
        key: "productos",
        render: (value, record) => (
          <Button
            icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
            type="text"
            onClick={() => {
                console.log(value);
              setDatakey(value);
       
              showModal();
          
            }}
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            block
          />
        ),
        fixed: "right",
      },
  ];

  const columsDataProductos: TableColumnsType<PropsDescripcionProductos> = [
    {
      title: "Descripcion",
      dataIndex: "Descripcion",
      key: "Descripcion",
      fixed: "left",
      //...getColumnSearchPropsNewBodegasReporte("usuario"),
    },
    {
      title: "Cajas",
      dataIndex: "Cajas",
      key: "Cajas",
      //sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Fracciones",
      dataIndex: "Fracciones",
      key: "Fracciones",
      //sorter: (a, b) => a.total - b.total,
    },
    {
        title: "Parcial",
        dataIndex: "Parcial",
        key: "Parcial",
        //sorter: (a, b) => a.total - b.total,
      },
  ];

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Deshabilita las fechas futuras respecto al final del día actual
    return current && current > moment().endOf("day");
  };
  const handleClickDownloadData = () => {
    // Transforma cada entrada de datosInicial para incluir Dependientes y Usuarios como strings
    const datosParaExportar = datosInicial.map((item) => ({
      ...item,
      /*  Dependientes: JSON.stringify(item.Dependientes),
      Usuarios: JSON.stringify(item.Usuarios),*/
    }));

    const texto = "detalleInventario" + fechaInicio!.format("YYYYMM");
    exportToExcel(datosParaExportar ?? [], texto);
  };

  return {
    columns,
    columsDataDependiente,
    columsDataProductos,
    setFechas,
    filteredData,
    handleClickDownloadData,
    estadoBoton,
    disabledDate,
    isModalOpen,
    data,
    handleOk,
    handleCancel,
  };
}
