import { Card, Carousel, Col, Modal, Row, Select, Table } from "antd";
import { useMantenimientoPenientes } from "hooks/mantenimientos/useMantenimientosPendientes";
import { useVT } from "virtualizedtableforantd4";
//import "./VisorMartillo.css";
const MantenimientosPendientes = () => {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);

  const {
    columns,
    columnsTable,
    filteredData,
    tableContainerRef,
   // rowSelection,
    isModalOpen,
    selectedImages,
    setIsModalOpen,
    handleChange,
  } = useMantenimientoPenientes();

  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 16 }}>
          <Select
            defaultValue="0"
            style={{ width: 130, marginRight: "10px", marginTop: "0px" }}
            onChange={handleChange}
            options={[
              {
                value: "0",
                label: "Todos",
              },
              {
                value: "1",
                label: "Urgentes",
              },
              {
                value: "2",
                label: "No urgentes",
              },
            ]}
          />
        </div>
        <Card style={{ margin: "5px" }}>
          <Row gutter={16}>
            <Col md={24}>
              <div ref={tableContainerRef} style={{ maxHeight: "435px" }}>
                {
                  <Table
                    ref={tableContainerRef}
                    components={vt}
                    pagination={false}
                    columns={columns}
                    scroll={{ x: 900, y: 450 }}
                    size="small"
                    dataSource={filteredData ? filteredData : []}
                    bordered
                    expandable={{
                      expandedRowRender: (record) => {
                        return (
                          <Table
                            style={{ width: "100%", margin: "10px" }}
                            columns={columnsTable}
                            dataSource={record.DetallesJSON}
                            pagination={false}
                            //rowSelection={rowSelection(record.key, record.Descripcion)}
                          />
                        );
                      },
                    }}
                  />
                }
              </div>
            </Col>
          </Row>
        </Card>
        <Modal
          title="EVIDENCIAS"
          centered
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={null} // Quita el pie de página del modal si no necesitas botones allí
          // Ajusta el tamaño según necesites
          bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }} // Limita la altura máxima y agrega desbordamiento si es necesario
        >
          <Carousel autoplay dotPosition="bottom" autoplaySpeed={2000}>
            {selectedImages.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Imagen ${index + 1}`} style={{ width: "100%", height: "500px" }} />{" "}
                {/* Ajusta el estilo según necesites */}
              </div>
            ))}
          </Carousel>
        </Modal>
      </Col>
    </Row>
  );
};

export default MantenimientosPendientes;
