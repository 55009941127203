import { Col, Form, Row, Table, Modal, Input, InputNumber, DatePicker, Switch } from "antd"
import { BusquedaBodegaFranquiciado } from "components/franquiciados/busqueda-bodega-franquiciado"
import { ButtonAdd } from "components/otros/buttons"

import { useEffect } from "react"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { type Deudas, useDeudasFranquiciados } from "hooks/Franquiciados/useDeudasFranquiciados"

import { optionsPost, swalError, swalSuccess } from "config/methods"

import { url } from "config/constants"
import moment from "moment"
import { TipoSeleccion } from "hooks/Franquiciados/usePago"

import type { Moment } from "moment"
import type { FormProps } from "antd"

export function DeudasFranquiciados() {
  const {
    form,
    tipoSeleccion,
    setTipoSeleccion,
    getDeudas,
    bodegas,
    setSelectedDeuda,
    openModal,
    setOpenModal,
    selectedDeuda,
    deudas,
    columns,
    franquiciados,
  } = useDeudasFranquiciados()

  return (
    <>
      <Form form={form}>
        <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
          <BusquedaBodegaFranquiciado
            tipoSeleccion={tipoSeleccion}
            onChangeTipoSeleccion={e => setTipoSeleccion(e.target.value)}
            onChangeSelect={codigo =>
              getDeudas(
                tipoSeleccion === TipoSeleccion.Bodega ? bodegas.find(({ value }) => value === codigo)?.franquiciado : codigo
              )
            }
            bodegas={bodegas.map(({ value, label }) => ({ value, label }))}
            franquiciados={franquiciados}
            radioColProps={{ sm: 13, md: 10, lg: 10 }}
            selectColProps={{ sm: 11, md: 9, lg: 9 }}
          />
          <Col xs={24} sm={24} md={5} lg={5} xl={9}>
            <ButtonAdd
              buttonProps={{
                onClick() {
                  setSelectedDeuda(undefined)
                  setOpenModal(true)
                },
              }}
            >
              Crear préstamo
            </ButtonAdd>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table columns={columns} dataSource={deudas} scroll={{ x: true }} />
          </Col>
        </Row>
      </Form>
      <ModalFormDeudas
        openModal={openModal}
        onCloseModal={() => setOpenModal(false)}
        datos={selectedDeuda}
        franquiciado={
          tipoSeleccion === TipoSeleccion.Bodega
            ? bodegas.find(({ value }) => value === form.getFieldValue("bodega"))?.franquiciado
            : form.getFieldValue("franquiciado")
        }
        reFetcherDeudas={() =>
          getDeudas(
            tipoSeleccion === TipoSeleccion.Bodega
              ? bodegas.find(({ value }) => value === form.getFieldValue("bodega"))?.franquiciado
              : form.getFieldValue("franquiciado")
          )
        }
      />
    </>
  )
}

export default DeudasFranquiciados

type Props = {
  openModal: boolean
  onCloseModal(): void
  datos?: Deudas
  franquiciado?: number
  reFetcherDeudas?: () => void
}

type ValuesFormDeudas = {
  deuda: string
  monto: number
  cuotas: number
  fecha_creacion: Moment
  fecha_fin_pago: Moment
  cuotas_pagadas: number
  estado: boolean
}

export function ModalFormDeudas({ openModal, onCloseModal, datos, franquiciado, reFetcherDeudas }: Props) {
  const [form] = Form.useForm<ValuesFormDeudas>()

  const { isError } = useVerifyResponse()

  useEffect(() => {
    if (!datos) return
    const { deuda, monto, cuotas, cuotas_pagadas, fecha_creacion, fecha_fin_pago, estado } = datos
    form.setFieldsValue({
      deuda,
      monto,
      cuotas,
      fecha_creacion: fecha_creacion ? moment(fecha_creacion, "DD/MM/YYYY hh:mm:ss") : undefined,
      fecha_fin_pago: fecha_fin_pago ? moment(fecha_fin_pago, "DD/MM/YYYY hh:mm:ss") : undefined,
      cuotas_pagadas,
      estado,
    })
  }, [datos, form])

  const handleFinishForm: FormProps<ValuesFormDeudas>["onFinish"] = async ({
    deuda,
    monto,
    cuotas,
    cuotas_pagadas,
    estado,
  }) => {
    try {
      const data = {
        cod_deuda: datos?.key,
        franquiciado,
        deuda,
        monto,
        cuotas,
        cuotas_pagadas,
        estado,
      }
      const ruta = datos ? "modificar" : "registrar"
      const result = await fetch(`${url}fapifsg-pr/franquiciados/${ruta}-deuda`, optionsPost(data))
      if (isError(result)) throw new Error()
      onCloseModal()
      form.resetFields()
      const text = await result.json()
      swalSuccess({ text })
      reFetcherDeudas && reFetcherDeudas()
    } catch (e) {
      onCloseModal()
      swalError({ text: `No se pudo ${datos ? "modificar" : "registrar"} la deuda` })
    }
  }

  const handleOkModal = async () => {
    try {
      await form.validateFields()
      form.submit()
    } catch (e) {
      swalError({ text: `Error al guardar los datos` })
    }
  }

  return (
    <Form form={form} requiredMark="optional" onFinish={handleFinishForm} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
      <Modal title="Registrar préstamos" open={openModal} transitionName="" onCancel={onCloseModal} onOk={handleOkModal}>
        <Form.Item
          name="deuda"
          label="Descripción"
          rules={[{ required: true, message: "Ingrese una breve descripción de la deuda" }]}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Input maxLength={150} />
        </Form.Item>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name="cuotas" label="Cuotas" rules={[{ required: true, message: "Ingrese el número de cuotas" }]}>
              <InputNumber min={1} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name="cuotas_pagadas" label="Pagadas" labelCol={{ span: 14 }} wrapperCol={{ span: 10 }}>
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name="monto" label="Monto" rules={[{ required: true, message: "Ingrese el monto del préstamo" }]}>
              <InputNumber min={1} step={0.01} controls={false} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="estado"
              label="Estado"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 10 }}
              required
              valuePropName="checked"
            >
              <Switch defaultChecked disabled={!Boolean(datos)} />
            </Form.Item>
          </Col>
        </Row>
        {datos && (
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="fecha_creacion"
                label="Fecha creación"
                required
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
              >
                <DatePicker disabled format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
            {datos.fecha_fin_pago && (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="fecha_fin_pago" label="Fecha fin" required labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                  <DatePicker disabled format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
            )}
          </Row>
        )}
      </Modal>
    </Form>
  )
}
