import { Row, Col, Input, Typography } from "antd";
import { MdOutlineAddCircle } from "react-icons/md";

import { ContextModalForm } from "context/ModalFormContext";
import { ContextList } from "context/ButtonRightwithListContext";

import { useVerReembolso } from "hooks/reembolsos/useVerReembolso";

import { ButtonRightWithListWithModalForm } from "components/otros/ButtonRightWithListWithModalForm";

import styles from "pages/contentreembolsogastos/reembolso.module.css";

const { TextArea } = Input;

const { Title } = Typography;

export function VerReembolsoGastos() {
  const {
    handleOnClickActionList,
    forms,
    JustificacionValue, //useVerReembolso
    inputsState,
    modalProps,
    setModalProps, //useModalFormCrearReembolso
    lists,
    contentDocuments,
    actionsList, //useButtonRightListCrearReembolso
  } = useVerReembolso();

  return (
    <>
      <Title level={2} className={styles.titleStyle}>
        Reembolso de Gastos
      </Title>
      <Row wrap className={styles.rowStyle}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <TextArea
            maxLength={250}
            placeholder='Justificación'
            autoSize={{ minRows: 1, maxRows: 10 }}
            value={JustificacionValue}
            readOnly
          />
        </Col>
      </Row>
      {["Facturas", "Notas de venta", "Tickets"].map((title, index) => (
        <ContextModalForm
          inputsState={inputsState[index]}
          key={index}
          modalProps={modalProps[index]}
          updateModalProps={setModalProps[index]}
          form={forms[index]}
        >
          <ContextList
            iconButton={<MdOutlineAddCircle className='iconbutton' />}
            actionsList={actionsList}
            actionsForActionsList={handleOnClickActionList[index]}
            listData={lists[index].map(contentDocuments[index])}
            visibleButton={false}
          >
            <Title style={{ textAlign: "center" }} level={3}>
              {title}
            </Title>
            <ButtonRightWithListWithModalForm />
          </ContextList>
        </ContextModalForm>
      ))}
    </>
  );
}

export default VerReembolsoGastos