import { Button, Col, Row, Table, Typography } from "antd"
import { DataAutorizacion, useAutorizacionFundas } from "hooks/bodega/useAutorizacionFundas"
import { DataDetSolicitud } from "hooks/bodega/useSolicitudesFundas"
import { useLayout } from "hooks/otros/useLayout"
import React from "react"
const { Title } = Typography

const breadcrumbs = [
  { Label: "Bodega", Url: "/bodega" },
  { Label: "Autorización de Fundas", Url: "/bodega/autorizacion-fundas" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`

export function AutorizacionFundas() {
  useLayout(breadcrumbs, openKeys, selectKeySider)

  const { columnssolicitud,
    visibleButton,
    btn_AprobarSeleccionados,
    rowSelection,
    data,
    columnsdetsolicitud
  } = useAutorizacionFundas()

  return (
    <>
      <Row style={{ background: "white", padding: "5px" }} align="middle" justify="center">
        <Col sm={24} md={24} style={{ paddingLeft: "20px" }}>
          <Title level={4}>Solicitudes de Autorización de Fundas</Title>
        </Col>
        <Col sm={24} md={12} style={{ paddingLeft: "20px" }}>
          <Row justify="end">
          {visibleButton && (
              <Button type="primary" onClick={() => btn_AprobarSeleccionados()}>
                Aprobar seleccionadas
              </Button>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <Table 
        style={{ width: "100%" }} 
        columns={columnssolicitud as DataAutorizacion[]} 
        rowSelection={rowSelection}
        dataSource={data}
        expandable={{
          expandedRowRender: (record) => {
            let detalle: any = record.detalle
            return (
              <>
                <Table
                  style={{ width: "100%", margin: "10px" }}
                  columns={columnsdetsolicitud as DataDetSolicitud[]}
                  dataSource={detalle}
                  pagination={false}
                />
              </>
            )
          },
        }}
        />
      </Row>
    </>
  )
}
