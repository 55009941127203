import React from 'react';
import { Col, Row, Table, Card } from 'antd';
import { ColumnsType } from 'antd/lib/table'; // Importa ColumnsType para usar en las columns de la tabla
import ComponentStackedBarChart from 'components/indicadores/ComponenteInventario';

// Actualiza la definición de SegmentoEstadisticaProps para usar ColumnsType
interface SegmentoEstadisticaProps {
  titulo: string;
  columns: ColumnsType<any>; // Usa ColumnsType con any o un tipo específico para tus datos
  dataSource: any[]; // Especifica un tipo más específico si es posible
  graphData: any; // Especifica un tipo más específico si es posible
  graphConfig: {
    size: number;
    angle: number;
  };
}

const SegmentoEstadistica: React.FC<SegmentoEstadisticaProps> = ({ titulo, columns, dataSource, graphData, graphConfig }) => {
  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      <Card>
        <Row gutter={[5, 5]}>
          <Col md={11} xs={24}>
            <Table
              columns={columns}
              dataSource={dataSource}
              size="small"
              bordered
              pagination={false}
              title={() => <strong>{titulo}</strong>}
              scroll={{ x: 500, y: 300 }}
            />
          </Col>
          <Col md={13} xs={24}>
            <ComponentStackedBarChart
              columnTable={dataSource}
              grafica={"key"}
              tamaño={graphConfig.size}
              angulo={graphConfig.angle}
              graph={graphData}
              dataKey={"key"}
              opciones={false}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default SegmentoEstadistica;
