import { useCallback, useEffect, useRef, useState } from "react"
import { useSearchTable } from "hooks/otros/useSearchTable"
import { useLayout } from "hooks/otros/useLayout"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSwal } from "hooks/otros/useSwal"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { useSendPDF } from "hooks/otros/useSendPDF"
import { useFooterModal } from "hooks/otros/useFooterModal"

import { Button, Tag, type TableColumnsType } from "antd"
import { type DatosPagoFranquiciado, ReportePDF } from "components/franquiciados/reporte/reporte-pdf"

import { GrDocumentPdf } from "react-icons/gr"
import { RiMailSendLine } from "react-icons/ri"
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons"
import { MdPending } from "react-icons/md"

import moment, { type Moment } from "moment"
import "moment/locale/es"
import { url } from "config/constants"

type PagosReporte = {
  franquiciado: string
  cod_franquiciado: number
  bodegas: {
    bodega: string
  }[]
  valor_a_pagar: number
  status: "pendiente" | "enviado" | "error"
  key: React.Key
}

const breadcrumbs = [{ Label: "Franquiciados", Url: "/franquiciados" }, { Label: "Reportes" }]

const openKeys = [breadcrumbs[0].Label]

export function usePagosReporte() {
  const [pagos, setPagos] = useState<PagosReporte[]>([])
  const [mes, setMes] = useState<Moment>(moment().subtract(1, "months"))
  const [openModal, setOpenModal] = useState(false)
  const [pagoSelected, setPagoSelected] = useState<React.Key>(0)

  const [loadingButton, setLoadingButton] = useState<React.Key>()

  const dataPago = useRef<DatosPagoFranquiciado[]>([])

  const [footerDownload] = useFooterModal()

  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label)

  const { sendPDFReport } = useSendPDF()

  const { getColumnSearchProps } = useSearchTable<PagosReporte>()

  const { optionsPost } = useLocalStorage()

  const { swalError } = useSwal()

  const { isError } = useVerifyResponse()

  useEffect(() => {
    ;(async () => {
      try {
        const result = await fetch(
          `${url}fapifsg-pr/franquiciados/listar-pagos-por-mes`,
          optionsPost({ mes: mes.format("YYYYMM") })
        )
        if (isError(result)) throw new Error()
        const res: (PagosReporte & DatosPagoFranquiciado)[] = await result.json()
        dataPago.current = res.map(
          ({
            key,
            total,
            total_menos_gastos,
            retenciones,
            iva_12,
            valor_a_pagar,
            bodegas,
            gastos_fijos,
            ventas_farmacias,
            bonos,
            deudas,
            correo,
          }) =>
            ({
              cod_pago: key,
              total,
              total_menos_gastos,
              retenciones,
              iva_12,
              valor_a_pagar,
              bodegas,
              gastos_fijos: [
                ...gastos_fijos,
                ...deudas.map(({ texto_deuda, valor_a_pagar }) => ({ gasto: texto_deuda, total: valor_a_pagar })),
              ],
              ventas_farmacias,
              bonos,
              deudas,
              correo,
            } as DatosPagoFranquiciado)
        )
        setPagos(
          res.map(({ key, franquiciado, bodegas, valor_a_pagar, status, cod_franquiciado }) => ({
            key,
            franquiciado,
            cod_franquiciado,
            valor_a_pagar,
            status,
            bodegas: (bodegas as DatosPagoFranquiciado["bodegas"]).map(({ bodega }) => ({ bodega })),
          }))
        )
      } catch (e) {
        console.error(e)
        swalError({ text: "No se pudo obtener los datos" })
      }
    })()
  }, [isError, swalError, optionsPost, mes])

  const enviarAlCorreo = useCallback(
    async (selectedPago: React.Key) => {
      setLoadingButton(selectedPago)
      const result = await sendPDFReport(
        <ReportePDF
          dataPago={dataPago.current.find(pago => pago.cod_pago === selectedPago)}
          mes={mes}
          franquiciado={pagos?.find(pago => pago.key === selectedPago)?.franquiciado ?? ""}
          codigo={(selectedPago ?? 0) as number}
        />,
        "pago-franquiciados",
        `liquidación mes de ${mes.format("MMMM [del] YYYY")}.pdf`,
        dataPago.current.find(pago => pago.cod_pago === selectedPago)?.correo ?? "",
        {
          franquiciado: pagos?.find(pago => pago.key === selectedPago)?.franquiciado ?? "",
          mes: mes.format("MMMM [del] YYYY"),
        }
      )
      const newPagos: PagosReporte[] = JSON.parse(JSON.stringify(pagos))
      newPagos.forEach(pago => {
        if (pago.key === selectedPago) pago.status = result ? "enviado" : "error"
      })
      setPagos(newPagos)
      setLoadingButton(undefined)
    },
    [mes, pagos, sendPDFReport]
  )

  const columns: TableColumnsType<PagosReporte> = [
    {
      title: "Franquiciado",
      dataIndex: "franquiciado",
      key: "franquiciado",
      ...getColumnSearchProps("franquiciado"),
    },
    {
      title: "Farmacias",
      dataIndex: "bodegas",
      key: "bodega",
      render: (bodegas: PagosReporte["bodegas"]) => bodegas.map(({ bodega }, index) => <Tag key={index}>{bodega}</Tag>),
      filters: pagos
        .map(({ bodegas }) => bodegas)
        .flat()
        .map(({ bodega }) => ({
          value: bodega,
          text: bodega,
        })),
      filterSearch: true,
      onFilter: (value, record) => record.bodegas.some(({ bodega }) => bodega.startsWith(value as string)),
    },
    {
      title: "Valor a Pagar",
      dataIndex: "valor_a_pagar",
      key: "valor_a_pagar",
      render: (value: number) => value.toFixed(2),
    },
    {
      title: "Ver",
      dataIndex: "key",
      key: "acciones",
      render: value => (
        <Button
          icon={<GrDocumentPdf style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={() => {
            setPagoSelected(value)
            setOpenModal(true)
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
      fixed: "right",
    },
    {
      title: "Enviar",
      dataIndex: "key",
      key: "enviar",
      render: value => (
        <Button
          icon={<RiMailSendLine style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={() => enviarAlCorreo(value)}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
          loading={loadingButton === value}
          disabled={Boolean(loadingButton) || loadingButton === 0}
        />
      ),
      fixed: "right",
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (status: PagosReporte["status"]) => (
        <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
          {status === "enviado" ? (
            <CheckCircleFilled className="ant-tag-success" style={{ fontSize: "1.3rem" }} />
          ) : status === "error" ? (
            <CloseCircleFilled className="ant-tag-error" style={{ fontSize: "1.3rem" }} />
          ) : (
            <MdPending className="ant-tag-default" style={{ fontSize: "1.5rem" }} />
          )}
        </div>
      ),
      fixed: "right",
    },
  ]

  const enviarCorreosMasivo = async () => {
    for (let pago of pagos) {
      await enviarAlCorreo(pago.key)
    }
  }

  return {
    enviarCorreosMasivo,
    mes,
    setMes,
    columns,
    pagos,
    openModal,
    setOpenModal,
    footerDownload,
    dataPago,
    pagoSelected,
  }
}
