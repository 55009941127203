import { Button ,Tag,Tooltip} from "antd"
import { ColumnsType } from "antd/lib/table"
import { url } from "config/constants"
import { optionsGet, optionsPost, swalQuestion, swalSuccess, swalWarning } from "config/methods"
import { useEffect, useState } from "react"
import { DeleteOutlined, EditOutlined} from "@ant-design/icons"
import moment, { Moment } from "moment"
import { useSearchTableNew } from "hooks/otros/useSearchTableNew"
import { useExport } from "hooks/otros/useExport"

export interface DataTypeTareas {
    key: React.Key
    cod_tipo_Incidente: string
    incidente: string
    fecha: Date
    cod_bodega: string
    bodega: string
    cod_usuario_solicita: string
    usuario_solicita: string
    cod_usuario_asignado: string
    usuario_asignado: string
    cod_usuario_registra: string
    usuario_registro: string
    descripcion: string
    estado: string
  }

export  function UseTareasPendientes() {
    const[dataTareas, setdataTareas]=useState<DataTypeTareas[]>([])
    const [databodega, setdatabodega] = useState([{ label: "Seleccione una Farmacia", value: "" }])
    const [codBodega, setcodBodega] = useState("")
    const [dataUsuarios, setdataUsuarios] = useState([{ label: "Seleccione un Solicitante", value: "" }])
    const [codUsuario, setCodUsuario] = useState("")
    const [dataIncidente, setdataIncidente] = useState([{ label: "Seleccione un Tipo de Incidente", value: "" }])
    const [CodIncidente, setCodIncidente] = useState("")
    const [dataSoporte, setdataSoporte] = useState([{ label: "Seleccione un Usuario", value: "" }])
    const [codUsuarioSoporte, setcodUsuarioSoporte] = useState("")
    const [valueDescripcion, setvalueDescripcion] = useState('')
    const [valueFecha, setvalueFecha] = useState(moment())
    const [disabled, setdisabled]=useState(false)
    const [cod_Tarea, setCod_Tarea]=useState("")
    const { exportToExcel } = useExport()
    const { getColumnSearchPropsNew } = useSearchTableNew<DataTypeTareas>()

    const columnsTareas: ColumnsType<DataTypeTareas>=[
        {
          title:'Tipo Incidente',
          dataIndex: "incidente",
          ...getColumnSearchPropsNew("incidente")
        },
        {
          title: "Farmacia",
          dataIndex: "bodega",
          ...getColumnSearchPropsNew("bodega")
        },
        {
           title:'Fecha',
           dataIndex:"fecha",
           render:(key)=>{
            return key!==null?moment.parseZone(key).format("DD/MM/YYYY"):''
           },
           ...getColumnSearchPropsNew("fecha")
        },
        {
            title:'Descripcion',
            dataIndex:'descripcion',
            ...getColumnSearchPropsNew("descripcion")
        },
        {
            title:'Estado',
            dataIndex:'estado',
            render:(key)=>{
              return <Tag color="orange">PENDIENTE</Tag>
             }
        },
        {
            title:'Acción',
            fixed:'right',
            width: '100PX',
            render:(key)=>{
                return(
                    <>
                      <Tooltip title="Editar"><Button type={"primary"} size="small" style={{ marginRight: "2px", marginBottom:'1px' }} onClick={() => btn_Edit(key)}>
                        <EditOutlined/>
                    </Button></Tooltip>
                    <Tooltip title="Eliminar"><Button type={"primary"} size="small" style={{ marginRight: "2px" }} onClick={() => btn_Delete(key)}>
                        <DeleteOutlined/>
                    </Button></Tooltip>
                    </>
                )
            }
        }
      ]

      const getTareasPendiente = async () => {
        const res = await fetch(`${url}fapifsg-pr/sistemas/getTareasPendientes`, optionsGet()).then((data) => data.json())
        //console.log(!res)
      let newdata:any=[]
      if(res){
          newdata = Array.from(res).map((item: any, key: any) => ({
            key: item.Cod_Tarea_Soporte,
            cod_tipo_Incidente: item.Cod_Tipo_Incidente,
            incidente: item.Incidente,
            fecha:  moment(item.Fecha).format("DD/MM/YYYY"),
            cod_bodega: item.Cod_Bodega,
            bodega: item.Bodega,
            cod_usuario_solicita: item.Cod_Usuario_Solicita,
            usuario_solicita: item.usuario_solicita,
            cod_usuario_asignado: item.Cod_Usuario_Asignado,
            usuario_asignado: item.usuario_asignado,
            cod_usuario_registra: item.Cod_Usuario_Registra,
            usuario_registro: item.usuario_registra,
            descripcion: item.Descripcion,
            estado: item.Estado
        }))
        //console.log(newdata)
       }
    
       setdataTareas(newdata)
    }


    const getbodegas = async () => {
      try {
        const res = await fetch(`${url}fapifsg-pr/sistemas/getFarmacias`, optionsGet()).then((data) => data.json())
        //let respuesta=res.data;
        let newget = [{ value: "", label: "Seleccione una Farmacia" }]
        res.forEach((item: any) => {
          let add = {
            value: item.Cod_Bodega,
            label: item.Descripcion,
          }
          newget = [...newget, add]
        })
        setdatabodega(newget)
      } catch (error) {
        console.log(error)
      }
    }

    useEffect(()=>{
        getTareasPendiente()
        getbodegas()
        getUsuarioSoporte()
    },[])


  

  const getUsuarioSoporte = async () => {
    try {
      const res = await fetch(`${url}fapifsg-pr/sistemas/getUsuarioSoporte`, optionsGet()).then((data) => data.json())
      //let respuesta=res.data;
      let newget = [{ value: "", label: "Seleccione un Usuario" }]
      res.forEach((item: any) => {
        let add = {
          value: item.Cod_Usuario,
          label: item.Nombres,
        }
        newget = [...newget, add]
      })
      setdataSoporte(newget)
    } catch (error) {
      console.log(error)
    }
  }
  const onchangeSolicitante = (value: string) => {
    setCodUsuario(value)
  }

  const onchangeTipoIncidente = (value: string) => {
    setCodIncidente(value)
  }

  const onchangeUsuarioSoporte = (value: string) => {
    setcodUsuarioSoporte(value)
  }

  const onchangeBodega = async (value: string) => {
    setcodBodega(value)
    try {
      const res = await fetch(`${url}fapifsg-pr/sistemas/getUsuarioBodega`, optionsPost({ cod_bodega: value })).then(
        (data) => data.json()
      )
      //let respuesta=res.data;
      let newget = [{ value: "", label: "Seleccione un Solicitante" }]
      res.forEach((item: any) => {
        let add = {
          value: item.Cod_Usuario,
          label: item.Nombres,
        }
        newget = [...newget, add]
      })
      setdataUsuarios(newget)
    } catch (error) {
      console.log(error)
    }
  }

  const tiposIncidentes = async () => {
    try {
      const res = await fetch(`${url}fapifsg-pr/sistemas/getTipoIncidentes`, optionsGet()).then((data) => data.json())
      //let respuesta=res.data;
      let newget = [{ value: "", label: "Seleccione un Tipo de Incidente" }]
      res.forEach((item: any) => {
        let add = {
          value: item.Cod_Tipo_Incidente,
          label: item.Descripcion,
        }
        newget = [...newget, add]
      })
      setdataIncidente(newget)
    } catch (error) {
      console.log(error)
    }
  }

  const disableFutureDates = (current: Moment) => {
    // Deshabilitar fechas después de hoy
    return current && current > moment().endOf("day")
  }

  useEffect(() => {
    getbodegas()
    tiposIncidentes()
    getUsuarioSoporte()
  }, [])

  const handleFilter = (input: any, option: any) => {
    return option.label.toLowerCase().includes(input.toLowerCase())
  }

  const onchangeDescripcion = (event: any) => {
    let newValue = event.target.value
    setvalueDescripcion(newValue)
  }

  const onchagedate = (date: any, dates: any) => {
    setvalueFecha(date ?? moment())
  }

  const btn_Guardar = () => {
    try {
      if (
        codBodega === "" ||
        codUsuario === "" ||
        codUsuarioSoporte === "" ||
        valueFecha === null ||
        valueDescripcion === "" ||
        CodIncidente === ""
      ) {
        swalWarning({ text: "Faltan Datos para guardar" })
        return
      }

      swalQuestion({
        text: `¿Esta seguro guardar los cambios de la tarea?`,
        icon: "warning",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await fetch(
            `${url}fapifsg-pr/sistemas/saveTareasSoporte`,
            optionsPost({
              cod_tarea: cod_Tarea,
              fecha: valueFecha,
              cod_tipo_incidente: CodIncidente,
              cod_usuario_solicita: codUsuario,
              cod_bodega: codBodega,
              descripcion: valueDescripcion,
              estado: codUsuarioSoporte ? "EN PROCESO" : "",
              usuario_asignado: codUsuarioSoporte,
            })
          ) //.then((data) => data.json())
          
          if (res.status === 200) {
            swalSuccess({ text: "Tarea Registrada con éxito" })
            setvalueFecha(moment())
            setCodIncidente('')
            setCodUsuario('')
            setcodBodega('')
            setvalueDescripcion("")
            setcodUsuarioSoporte('')
            getTareasPendiente()
            setdisabled(false)
          }
            
        }})
    } catch (error) {
      console.log(error)
    }
  }

  const btn_Edit=(key:DataTypeTareas)=>{
    setdisabled(true)
    setCodIncidente(key.cod_tipo_Incidente)
    onchangeBodega(key.cod_bodega)
    setCodUsuario(key.cod_usuario_solicita)
    setvalueDescripcion(key.descripcion)
    setcodUsuarioSoporte(Number(key.cod_usuario_asignado)!==0?key.cod_usuario_asignado:'')
    setCod_Tarea((key.key).toString())
    //onchagedate(key.fecha, moment(key.fecha))
    setvalueFecha(moment(key.fecha))
  }
   
  const btn_Delete=(key:DataTypeTareas)=>{
    try{
    swalQuestion({
      text: `¿Esta seguro de eliminar la tarea de la ${key.bodega}?`,
      icon: "warning",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await fetch(
          `${url}fapifsg-pr/sistemas/deleteTareas`,
          optionsPost({cod_tarea: key.key})
        )
        if (res.status===200){
          swalSuccess({text:"Tarea eliminada con éxito"})
          getTareasPendiente()
        }
      }
    
    })}catch(error){
      console.log(error)
    }
  }


  
const btn_Excel = () => {
  const dataExport = dataTareas.map((item)=>{
      return{
        Num_Tarea: item.key,
        Cod_Bodega: item.cod_bodega,
        Farmacia: item.bodega,
        Incidente: item.incidente,
        Descripción:item.descripcion,
        "Usuario Asignado": item.usuario_asignado,
        "Fecha de Registro": item.fecha!==null?moment.parseZone(item.fecha).format("DD/MM/YYYY"):''
      }
  })
  exportToExcel(dataExport, `Reporte_Tareas_Pendientes${moment().format("DDMMYYYY")}`)
}


    
  return{
    columnsTareas, 
    dataTareas,
    databodega,
    dataUsuarios,
    onchangeBodega,
    codBodega,
    codUsuario,
    dataIncidente,
    CodIncidente,
    dataSoporte,
    codUsuarioSoporte,
    onchangeSolicitante,
    onchangeTipoIncidente,
    onchangeUsuarioSoporte,
    disableFutureDates,
    handleFilter,
    onchangeDescripcion,
    valueDescripcion,
    onchagedate,
    valueFecha,
    btn_Guardar,
    disabled,
    setdisabled,
    btn_Excel
  }
}
