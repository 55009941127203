import { SVGProps } from "react"

export function Reembolsos(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      {...props}
    >
      <path d="M6.926 12.487A5.74 5.74 0 0 1 4.131 11.4l-.393-.29.354-.403c.195-.222.364-.403.377-.403.013 0 .213.13.445.29.835.575 1.527.791 2.542.795.819 0 1.27-.102 2.028-.472.431-.212.635-.365 1.094-.826.458-.46.613-.667.823-1.097.359-.736.47-1.209.47-1.996 0-.782-.11-1.253-.467-1.995-.21-.437-.352-.627-.822-1.094-.617-.613-1.106-.913-1.865-1.146-2.335-.714-4.864.71-5.53 3.112-.195.704-.203.686.308.686.24 0 .437.02.437.041 0 .046-1.455 2.582-1.47 2.561A122.832 122.832 0 0 1 1 6.595c0-.018.208-.033.461-.033h.462l.071-.421c.378-2.236 1.986-3.98 4.165-4.519.694-.171 1.888-.185 2.534-.029 3.203.776 5.047 4.046 4.024 7.133-.431 1.3-1.365 2.416-2.611 3.122-.47.266-1.379.561-1.87.606l-.624.057c-.12.011-.429 0-.686-.025zm0-2.583c0-.335-.002-.339-.232-.414-.36-.12-.86-.66-.98-1.059-.171-.578-.19-.559.554-.559.649 0 .651 0 .733.172.12.25.49.333.721.16a.46.46 0 0 0 .19-.319c.023-.225-.067-.284-.768-.512a5.914 5.914 0 0 1-.655-.253c-.291-.148-.657-.535-.776-.82-.12-.29-.125-.798-.01-1.076.127-.303.536-.686.868-.813l.286-.11.019-.35.018-.352.546-.018.546-.018v.748l.265.118c.146.065.357.195.47.29.264.224.55.707.467.79-.034.034-.318.142-.633.24-.536.166-.575.17-.63.067-.134-.251-.751-.32-.93-.104-.111.133.101.271.732.476.885.287 1.337.643 1.498 1.18.235.786-.103 1.594-.842 2.011l-.335.189v.674H6.926v-.338z" />
    </svg>
  )
}

export function Facturas(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Layer 1"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M206.32 292.76a51.69 51.69 0 0 0 23.28 7.4v4.72a7.22 7.22 0 0 0 2.46 5.76 7.63 7.63 0 0 0 4.89 1.79 8.19 8.19 0 0 0 4.92-1.69 7.11 7.11 0 0 0 2.75-5.86V300c8.56-1.22 15.12-4.28 19.5-9.12 4.91-5.42 7.63-11.41 8.08-17.74a37.48 37.48 0 0 0-.57-10.53 29.13 29.13 0 0 0-4.33-10.95 31.2 31.2 0 0 0-9.51-8.93 41.32 41.32 0 0 0-13.17-5.16v-40.29a15.18 15.18 0 0 1 2.91 1.53 26.81 26.81 0 0 1 5.65 5.12 18.26 18.26 0 0 1 3.22 5.81 30.15 30.15 0 0 1 1.41 6.74c.51 4.53 3.78 6.29 6.92 5.92s5.5-2.73 6.34-7.24c-.24-9.17-3.21-16.66-8.84-22.22a34 34 0 0 0-17.61-9.14v-6.07a7.53 7.53 0 1 0-15.05 0V183a48.38 48.38 0 0 0-11.89 2.74 35.07 35.07 0 0 0-12.26 7.51 32 32 0 0 0-7.6 11.18 30 30 0 0 0-1.88 13.75 32.35 32.35 0 0 0 11 21.44c5.93 4.94 13.75 8 22.6 9v38.6c-6.87-.71-12.26-2.53-16.06-5.41a21.35 21.35 0 0 1-8.37-12c-.31-2.71-2.3-5.65-6.77-5.78a5.7 5.7 0 0 0-6.38 7.21 31.33 31.33 0 0 0 14.36 21.52Zm8-64.87a13.31 13.31 0 0 1-3.3-4.76 25.15 25.15 0 0 1-1.27-5.71 19.26 19.26 0 0 1 5.6-14.94c3.31-3.47 8.08-5.66 14.19-6.54v39.41a30.48 30.48 0 0 1-7.9-2.52 29.13 29.13 0 0 1-7.29-4.94Zm41.48 53a18.68 18.68 0 0 1-5.46 4.22 19.13 19.13 0 0 1-5.75 1.85v-35.89c4.58 1.39 8 3.37 10.26 6 3 3.47 4.36 8.57 4.19 15.6v.1a12 12 0 0 1-.8 3.63 14.51 14.51 0 0 1-2.41 4.48ZM330.58 146.35h42.15l-42.15-42.13v42.13z" />
      <path d="M311.47 155.91V90.69H77.94v375.65h308.31V165.47H321a9.56 9.56 0 0 1-9.53-9.56Zm-79.38 6a79.43 79.43 0 1 1-79.43 79.43 79.43 79.43 0 0 1 79.43-79.48Zm100 256.63H132a9.57 9.57 0 0 1 0-19.13h200.1a9.57 9.57 0 0 1 0 19.13Zm9.55-51.85a9.56 9.56 0 0 1-9.55 9.56H132a9.56 9.56 0 1 1 0-19.12h200.1a9.56 9.56 0 0 1 9.59 9.51Z" />
      <path d="M125.73 45.66v25.9H321a8.11 8.11 0 0 1 1.21.25 7.69 7.69 0 0 1 1.25.25 9.46 9.46 0 0 1 4.31 2.31l74.77 74.77a9.56 9.56 0 0 1 2.31 4.31 9.06 9.06 0 0 1 .25 1.26 9.44 9.44 0 0 1 .24 1.2V421h28.7V45.66Z" />
    </svg>
  )
}

export function NotaVenta(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="-5 -5 42 42" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g data-name="18. Bill">
        <path d="M16 7h2a1 1 0 0 0 0-2h-1a1 1 0 0 0-2 0v.18A3 3 0 0 0 16 11a1 1 0 0 1 0 2h-2a1 1 0 0 0 0 2h1a1 1 0 0 0 2 0v-.18A3 3 0 0 0 16 9a1 1 0 0 1 0-2Z" />
        <path d="M31 24h-3V3a3 3 0 0 0-3-3H3a3 3 0 0 0-3 3v6a1 1 0 0 0 1 1h3v19a3 3 0 0 0 3 3h22a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1ZM2 3a1 1 0 0 1 2 0v5H2Zm6 22v4a1 1 0 0 1-.31.71A.93.93 0 0 1 7 30a1 1 0 0 1-1-1V3a3 3 0 0 0-.18-1H25a1 1 0 0 1 1 1v21H9a1 1 0 0 0-1 1Zm22 4a1 1 0 0 1-.31.71.93.93 0 0 1-.69.29H9.83a3 3 0 0 0 .17-1v-3h20Z" />
        <path d="M17 19H9a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2ZM23 19h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2Z" />
      </g>
    </svg>
  )
}

export function Recibos(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Layer 1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title />
      <path d="M8 13h8v-2H8Zm0-4h8V7H8Zm5 8h3v-2h-3Zm6.11-15a3.06 3.06 0 0 0-1.75.57 1 1 0 0 1-1.25 0 3 3 0 0 0-3.5 0 1.14 1.14 0 0 1-.61.23 1.06 1.06 0 0 1-.6-.22A3 3 0 0 0 9.64 2a3 3 0 0 0-1.75.57 1 1 0 0 1-1.25 0A3.06 3.06 0 0 0 4.89 2H4v20h.89a3.06 3.06 0 0 0 1.75-.57 1 1 0 0 1 1.25 0 3 3 0 0 0 3.5 0 1.14 1.14 0 0 1 .61-.23 1.06 1.06 0 0 1 .6.22 3 3 0 0 0 1.74.58 3 3 0 0 0 1.75-.57 1 1 0 0 1 1.25 0 3.06 3.06 0 0 0 1.75.57H20V2ZM18 19.5a3 3 0 0 0-3 .28 1.09 1.09 0 0 1-.62.22 1 1 0 0 1-.6-.22A3 3 0 0 0 12 19.2a3.11 3.11 0 0 0-1.76.58 1 1 0 0 1-1.24 0 3 3 0 0 0-3-.28v-15a3 3 0 0 0 1.26.3A3.11 3.11 0 0 0 9 4.22 1.09 1.09 0 0 1 9.64 4a1 1 0 0 1 .6.22A3 3 0 0 0 12 4.8a3.11 3.11 0 0 0 1.76-.58 1 1 0 0 1 1.24 0 3.11 3.11 0 0 0 1.76.58A3 3 0 0 0 18 4.5Z" />
    </svg>
  )
}