import { Button, Table } from "antd"
import { ButtonWithQuestion } from "components/otros/ButtonWithQuestion"

import { useState, Key, useRef, useCallback, useEffect } from "react"

import { useNavigate } from "react-router-dom"
import { useSearchTable } from "hooks/otros/useSearchTable"
import { useLayout } from "hooks/otros/useLayout"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSwal } from "hooks/otros/useSwal"

import { PlusCircleOutlined, MinusSquareOutlined } from "@ant-design/icons"

import type { TableColumnsType, TableProps } from "antd"
import { url } from "config/constants"

type Bodegas = {
  key: Key
  bodega: string
  direccion: string
  cargo_empleados: number
}

type Franquiciados = {
  key: Key
  cod_bodega: number
  cedula: string
  franquiciado: string
  cargo_empleados: number
}

export type DatosAsignacionFranquiciado = Franquiciados & {
  bodegas: Bodegas[]
}

export type DatosAsignacionBodega = Bodegas & {
  franquiciados: Franquiciados[]
}

export type DataModalList = {
  label: string
  value: number | string
}

export const enum TipoAsignacion {
  franquiciadoBodega,
  bodegaFranquiciado,
}

const breadcrumbs = [{ Label: "Franquiciados", Url: "/franquiciados" }, { Label: "Asignación" }]

const openKeys = ["Franquiciados"]

export function useAsignarFranquiciadoFarmacia() {
  useLayout(breadcrumbs, openKeys, "Franquiciados/Asignación")

  const [data, setData] = useState<(DatosAsignacionBodega | DatosAsignacionFranquiciado)[]>([])
  const [tipoAsignacion, setTipoAsignacion] = useState<TipoAsignacion>(TipoAsignacion.bodegaFranquiciado)
  const [openModalList, setOpenModalList] = useState(false)
  const [dataModalList, setDataModalList] = useState<DataModalList[]>([])
  const [openModalCrearFranquiciado, setOpenModalCrearFranquiciado] = useState(false)

  const valueSelected = useRef<number>()

  const { optionsGet, optionsPost } = useLocalStorage()

  const { swalError, swalSuccess } = useSwal()

  const navigate = useNavigate()

  const urlPart = tipoAsignacion === TipoAsignacion.bodegaFranquiciado ? "bodegas-franquiciados" : "franquiciados-bodegas"

  const reFetcher = useCallback(async () => {
    try {
      const result = await fetch(`${url}fapifsg-pr/franquiciados/listar-${urlPart}`, optionsGet)
      if (!result.ok) {
        if (result.status === 401) {
          navigate("/login")
          return
        }
        throw new Error()
      }
      const response = await result.json()
      setData(response)
    } catch (e) {
      swalError({ text: "No se pudo obtener la lista de bodegas y franquiciados" })
    }
  }, [navigate, optionsGet, swalError, urlPart])

  useEffect(() => {
    reFetcher()
  }, [reFetcher])

  const { getColumnSearchProps: buscarBodega } = useSearchTable<DatosAsignacionBodega>()
  const { getColumnSearchProps: buscarFranquiciado } = useSearchTable<DatosAsignacionFranquiciado>()

  const cargarDataModalList = async (value: number) => {
    valueSelected.current = value
    const urlAsignacion = tipoAsignacion === TipoAsignacion.bodegaFranquiciado ? "franquiciados" : "bodegas-no-franquiciadas"
    try {
      const result = await fetch(`${url}fapifsg-pr/franquiciados/listar-${urlAsignacion}`, optionsGet)
      if (!result.ok) {
        if (result.status === 401) {
          navigate("/login")
          return false
        }
        throw new Error()
      }
      const res = await result.json()

      setDataModalList(res)
      setOpenModalList(true)
    } catch (e) {
      swalError({ text: "No se pudo obtener los datos de las bodegas" })
    }
  }

  const columnsBodega: TableColumnsType<DatosAsignacionBodega> = [
    { title: "Farmacia", dataIndex: "bodega", key: "bodega", ...buscarBodega("bodega") },
    { title: "Dirección", dataIndex: "direccion", key: "direccion", responsive: ["lg"] },
    {
      title: "",
      dataIndex: "franquiciados",
      key: "accion",
      render: (value: Franquiciados[], record) => (
        <Button
          icon={<PlusCircleOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          block
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          disabled={value?.length > 0}
          onClick={() => cargarDataModalList(record.key as number)}
        />
      ),
    },
  ]

  const columnsFranquiciado: TableColumnsType<DatosAsignacionFranquiciado> = [
    { title: "Cédula", dataIndex: "cedula", key: "cedula", responsive: ["lg"] },
    { title: "Franquiciado", dataIndex: "franquiciado", key: "franquiciado", ...buscarFranquiciado("franquiciado") },
    {
      title: "",
      dataIndex: "key",
      key: "accion",
      render: (value: number) => (
        <Button
          icon={<PlusCircleOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          block
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          onClick={() => cargarDataModalList(value)}
        />
      ),
    },
  ]

  const handleDeleteBodegaFranquiciada = async (value: number) => {
    const body = { cod_bodega: value }
    try {
      const result = await fetch(`${url}fapifsg-pr/franquiciados/eliminar-bodega-como-franquicia`, optionsPost(body))
      if (!result.ok) {
        if (result.status === 401) {
          navigate("/login")
          return false
        }
        throw new Error()
      }
      const text = await result.json()
      swalSuccess({ text })
      reFetcher()
    } catch (e) {
      swalError({ text: "No se pudo eliminar la bodega como franquicia" })
    }
  }

  const columnsBodegaFranquiciado: TableColumnsType<Franquiciados> = [
    { title: "Cédula", dataIndex: "cedula", key: "cedula", responsive: ["lg"] },
    { title: "Franquiciado", dataIndex: "franquiciado", key: "franquiciado" },
    { title: "Asume empleados", dataIndex: "cargo_empleados", key: "cargo_empleados" },
    {
      title: "",
      dataIndex: "cod_bodega",
      key: "accion",
      render: value => (
        <ButtonWithQuestion
          swalProps={{ title: "¿Desea quitar el franquiciado?", text: "La farmacia ya no será una franquicia" }}
          accion={() => handleDeleteBodegaFranquiciada(value)}
          buttonProps={{
            icon: <MinusSquareOutlined style={{ fontSize: "1.3rem" }} />,
            type: "text",
            block: true,
            style: { display: "flex", alignItems: "center", justifyContent: "center" },
          }}
          popConfirmProps={{
            title: "La farmacia ya no será una franquicia ¿Desea continuar?",
            okText: "Si",
            cancelText: "No",
          }}
        />
      ),
    },
  ]

  const columnsFranquiciadoBodega: TableColumnsType<Bodegas> = [
    { title: "Farmacia", dataIndex: "bodega", key: "bodega" },
    { title: "Dirección", dataIndex: "direccion", key: "direccion", responsive: ["lg"] },
    { title: "Asume empleados", dataIndex: "cargo_empleados", key: "cargo_empleados" },
    {
      title: "",
      dataIndex: "key",
      key: "accion",
      render: value => (
        <ButtonWithQuestion
          swalProps={{ title: "¿Desea quitar la farmacia?", text: "La farmacia ya no será una franquicia" }}
          accion={() => handleDeleteBodegaFranquiciada(value)}
          buttonProps={{
            icon: <MinusSquareOutlined style={{ fontSize: "1.3rem" }} />,
            type: "text",
            block: true,
            style: { display: "flex", alignItems: "center", justifyContent: "center" },
          }}
          popConfirmProps={{
            title: "¿Desea quitar la farmacia?",
            okText: "Si",
            cancelText: "No",
          }}
        />
      ),
    },
  ]

  const expandedBodega: TableProps<DatosAsignacionBodega>["expandable"] = {
    expandedRowRender: ({ franquiciados, key }) => (
      <Table columns={columnsBodegaFranquiciado} dataSource={franquiciados} pagination={false} />
    ),
    rowExpandable: record => record.franquiciados?.length > 0,
    defaultExpandAllRows: true,
  }

  const expandedFranquiciado: TableProps<DatosAsignacionFranquiciado>["expandable"] = {
    expandedRowRender: ({ bodegas }) => (
      <Table columns={columnsFranquiciadoBodega} dataSource={bodegas} pagination={false} />
    ),
    rowExpandable: record => record.bodegas?.length > 0,
    defaultExpandAllRows: true,
  }

  return {
    data,
    valueSelected,
    openModalList,
    setOpenModalList,
    dataModalList,
    tipoAsignacion,
    setTipoAsignacion,
    columnsBodega,
    expandedBodega,
    columnsFranquiciado,
    expandedFranquiciado,
    openModalCrearFranquiciado,
    setOpenModalCrearFranquiciado,
    reFetcher,
  }
}
