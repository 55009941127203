import { Button, type ButtonProps, Col, Row } from "antd";

type Props = {
  handleAdd: ButtonProps['onClick']
  handleAddInBlank: ButtonProps['onClick']
}

export function AddButtons({ handleAdd, handleAddInBlank }: Props) {
  
  return (
    <Row gutter={[16, 16]}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
        <Button block type="dashed" style={{ marginTop: '16px' }} onClick={ handleAdd }>
          Añadir
        </Button>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
        <Button 
          block type="dashed" 
          style={{ marginTop: '16px' }} 
          onClick={ 
            handleAddInBlank
          }
        >
          Añadir en blanco
        </Button>
      </Col>
    </Row>
  )
}