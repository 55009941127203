import { useCallback, useMemo } from "react"

export function useLocalStorage() {
  
  const getItem = useCallback((key: string) => localStorage.getItem(key), [])

  const setItem = useCallback((key: string, value: any) => localStorage.setItem(key, value), [])

  const headersWithToken = useMemo((): HeadersInit => ({
    'Content-Type': 'application/json',
    'access-token': getItem('token') ?? ''
  }), [ getItem ])

  const optionsPostBodyString = useCallback((body: string): RequestInit => ({
    method: 'POST',
    headers: headersWithToken,
    body
  }),[ headersWithToken ])

  const optionsPost = useCallback((body: any): RequestInit => ({
    method: 'POST',
    headers: headersWithToken,
    body: JSON.stringify(body)
  }),[ headersWithToken ])

  const optionsGet = useMemo((): RequestInit => ({
    method: 'GET',
    headers: headersWithToken
  }),[ headersWithToken ])
  
  return { getItem, setItem, headersWithToken , optionsPost, optionsPostBodyString, optionsGet }
}