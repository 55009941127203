import { Col, Form, Row, Select, DatePicker } from "antd"
import { Image, PDFViewer, StyleSheet } from "@react-pdf/renderer"
import { useContext, useEffect, useMemo, useState } from "react"

import moment from "moment"

import type { RangePickerProps } from "antd/lib/date-picker"
import { useDataForTable } from "hooks/rrhh/llamadosatencion/useDataForTable"
import type { Moment } from "moment"
import { LayoutContext } from "context/LayoutContext"
import { columnsTablePDF, Table } from "components/reactreport/Table"
import { Report } from "components/reactreport/Report"
import { Title } from "components/reactreport/Title"
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs"

const { RangePicker } = DatePicker

const breadcrumbs = [
  { Label: "Recursos humanos", Url: "/rrhh" },
  { Label: "Llamados de atención", Url: "/rrhh/llamados-de-atencion" },
  { Label: "Reporte histórico" },
]

const styles = StyleSheet.create({
  // text: { fontSize: 10, textAlign: 'justify', marginTop: '15px', lineHeight: 1.3 },
  // textBold: { fontSize: 10, textAlign: 'justify', marginTop: '15px', lineHeight: 1.3, fontFamily: 'Helvetica-Bold' },
  // numeration: { textAlign: 'right', fontFamily: 'Helvetica-Bold', fontSize: 10 },
  title: { paddingTop: "3px" },
  // row: { display: 'flex', flexDirection: 'row', alignItems: 'flex-start', paddingBottom: '5px' },
  // columnTitle: { fontFamily: 'Helvetica-Bold', fontSize: 10, flexBasis: `15%` },
  // column: { fontSize: 10, flexBasis: `85%` },
  logo: { width: "40%", margin: "0 auto 0 auto", objectFit: "scale-down" },
  columnTable: { borderRight: "1px solid #f0f0f0" },
})

export function ReporteHistorico() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext)

  const [selectedDates, setSelectedDates] = useState<[Moment, Moment]>([moment().subtract(1, "months"), moment()])
  const [selectedTipoConsulta, setSelectedTipoConsulta] = useState<number>(2)

  useBreadCrumbs(breadcrumbs)

  useEffect(() => {
    setOpenKeys(["Recursos Humanos", "Recursos Humanos/Llamados de atención"])
    setSelectedKeys(["Llamados de atención/Reporte histórico"])
  }, [setOpenKeys, setSelectedKeys])

  const handleChangeDates: RangePickerProps["onChange"] = values => {
    setSelectedDates(values as [Moment, Moment])
  }

  const columnsTable: columnsTablePDF[] = [
    {
      title: "Solicitante",
      dataIndex: "solicitante",
      styleColumnHeader: { ...styles.columnTable, flexBasis: "20%", fontSize: 10 },
      styleColumn: { ...styles.columnTable, flexBasis: "20%", fontSize: 10, height: "35px" },
    },
    {
      title: "Empleado",
      dataIndex: "empleado",
      styleColumnHeader: { ...styles.columnTable, flexBasis: "25%", fontSize: 10 },
      styleColumn: { ...styles.columnTable, flexBasis: "25%", fontSize: 10, height: "35px" },
    },
    {
      title: "Bodega",
      dataIndex: "bodega",
      styleColumnHeader: { ...styles.columnTable, flexBasis: "25%", fontSize: 10 },
      styleColumn: { ...styles.columnTable, flexBasis: "25%", fontSize: 10, height: "35px" },
    },
    {
      title: "Fecha de la falta",
      dataIndex: "fecha_falta",
      styleColumnHeader: { ...styles.columnTable, flexBasis: "14%", fontSize: 10 },
      styleColumn: { ...styles.columnTable, flexBasis: "14%", fontSize: 10, height: "35px" },
    },
    {
      title: "Fecha de solicitud",
      dataIndex: "fecha_creacion",
      styleColumnHeader: { flexBasis: "16%", fontSize: 10 },
      styleColumn: { flexBasis: "16%", fontSize: 10, height: "35px" },
    },
  ]

  const bodyGetData = useMemo(
    () => ({
      tipo_consulta: selectedTipoConsulta,
      fecha_inicio: selectedDates[0].format("DD/MM/YYYY"),
      fecha_fin: selectedDates[1].format("DD/MM/YYYY"),
    }),
    [selectedDates, selectedTipoConsulta]
  )

  const { llamadosAtencion } = useDataForTable(
    "apifsg-pr/recursos-humanos/listar-llamados-atencion-aprobados-por-fechas",
    undefined,
    bodyGetData
  )

  return (
    <Row wrap style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Form layout="horizontal">
          <Row gutter={[16, 16]} style={{ marginRight: 0 }}>
            <Col xs={24} sm={13} md={10} lg={9} xl={8}>
              <Form.Item label="Consultar por">
                <Select
                  options={[
                    { value: 0, label: "Fecha de solicitud" },
                    { value: 1, label: "Fecha de la falta" },
                    { value: 2, label: "Fecha de aprobación" },
                  ]}
                  value={selectedTipoConsulta}
                  onChange={value => setSelectedTipoConsulta(value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={11} md={9} lg={8} xl={8}>
              <Form.Item>
                <RangePicker value={selectedDates} onChange={handleChangeDates} format="DD/MM/YYYY" allowClear={false} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <PDFViewer style={{ width: "100%", height: "70vh" }}>
          <Report
            documentProps={{
              title: `llamados de atencion del ${selectedDates[0].format("DD-MM-YYYY")} al ${selectedDates[1].format(
                "DD-MM-YYYY"
              )}`,
            }}
            pageProps={{ size: "A4", orientation: "landscape" }}
          >
            <Image src="/imagen/logoFSG.png" style={styles.logo} />
            <Title style={styles.title}>
              Historial de llamados de atención del {selectedDates[0].format("DD/MM/YYYY")} al{" "}
              {selectedDates[1].format("DD/MM/YYYY")}
            </Title>
            <Table
              columns={columnsTable}
              dataSource={llamadosAtencion.map(({ solicitante, empleado, bodega, fecha_falta, fecha_creacion }) => ({
                solicitante,
                empleado: empleado ?? "",
                bodega,
                fecha_falta,
                fecha_creacion,
              }))}
            />
          </Report>
        </PDFViewer>
      </Col>
    </Row>
  )
}
