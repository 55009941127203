import { Button, Col, Row, Table, Card } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import "moment/locale/es";
import { useConciliaciones } from "hooks/cajas/pagoTramites/useConciliaciones";

export function Conciliaciones() {
  const { columns, filteredData, rowSelection, handleSave, estadoGuardar, estadoBoton } = useConciliaciones();

  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 16 }}>
          {estadoBoton && (
            <Button
              type="primary"
              style={{
                width: "190px",
              }}
              onClick={handleSave}
              loading={estadoGuardar}
              icon={<SaveOutlined className="iconbutton" />}
            >
              Aprobar
            </Button>
          )}
        </div>
        <Card>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredData}
            scroll={{ x: true }}
            size="small"
            pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default Conciliaciones;
