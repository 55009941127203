import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useCallback, useEffect } from "react";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import { useSwal } from "hooks/otros/useSwal";
import { useNavigate } from "react-router-dom";

export function useFillStateWithFetch<T>(
  ruta: string,
  setState: React.Dispatch<React.SetStateAction<T>>,
  errorMsg: string,
  data?: any
) {
  const { optionsGet, optionsPost } = useLocalStorage();

  const { hadError } = useVerifyResponse();

  const { swalError } = useSwal();

  const navigate = useNavigate()

  const fetcher = useCallback(async () => {
    try {
      const response = await fetch(ruta, data ? optionsPost(data) : optionsGet)
      if(!response.ok) {
        if(response.status === 401) {
          navigate('/login')
          return
        }
        throw new Error(errorMsg)
      }
      const res = await response.json()
      // console.log(res)
      // if(wasInvalidToken(res)) return
      if(hadError(res, errorMsg)) return
      
      const datos = res.hasOwnProperty('data') ? res.data : res
      
      setState(datos)
    } catch(e) {
      swalError({ text: errorMsg })
    }
  }, [
    ruta,
    setState,
    hadError,
    optionsGet,
    errorMsg,
    swalError,
    data,
    optionsPost,
    navigate
  ]);

  useEffect(() => { fetcher() }, [fetcher])

  return fetcher;
}
