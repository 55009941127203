import { Button, Col, Collapse, Form, Input, InputNumber, Row, Space, Statistic, Typography } from "antd"
import { DescriptionsListformItems, DescriptionsListformItemsProps } from "components/otros/descriptions-list-form-items"
import { type ColumnsTableFormListType, TableFormList } from "components/otros/table-form-list"
import type { DependientesDataPrincipal } from "context/franquiciados/form-pago-context"
import { useScreenSize } from "hooks/otros/useScreenSize"

import { ReloadOutlined } from "@ant-design/icons"
import { ButtonReload } from "components/otros/buttons"

type Props = {
  handleChangeOtros(newValue: number, index1: number, index2: number): void
  loading: boolean
  reload?: boolean
  onReloadBodega(bodegaIndex: number): void
  onReloadCargoEmpleados(bodegaIndex: number): void
  handleChangeSueldo(newValue: number, index1: number, index2: number): void
  onDeleteEmpleado(): void
}

export function BodegasTables({
  handleChangeOtros,
  handleChangeSueldo,
  loading,
  reload = false,
  onReloadBodega,
  onReloadCargoEmpleados,
  onDeleteEmpleado,
}: Props) {
  const { width } = useScreenSize()

  const columnsTableDependientes: (index: number) => ColumnsTableFormListType<DependientesDataPrincipal>[] = index1 => [
    {
      title: "Dependientes",
      name: "dependiente",
      render: () => <Input.TextArea bordered={false} readOnly autoSize size="small" />,
    },
    {
      title: "Días",
      name: "dias",
      render: () => <Statistic valueStyle={{ fontSize: "inherit" }} />,
    },
    {
      title: "Sueldo",
      name: "sueldo",
      render: index2 => (
        <InputNumber
          min={0}
          step={0.01}
          controls={false}
          size="small"
          onChange={value => handleChangeSueldo(value ?? 0, index1, index2)}
        />
      ),
    },
    {
      title: "Fondos de reserva",
      name: "fondos_reserva",
      render: () => <Statistic valueStyle={{ fontSize: "inherit" }} precision={2} />,
    },
    {
      title: "Iess 12.15%",
      name: "iess_12",
      render: () => <Statistic valueStyle={{ fontSize: "inherit" }} precision={2} />,
    },
    {
      title: "Otros",
      name: "otros",
      render: index2 => (
        <InputNumber
          onChange={value => handleChangeOtros(value ?? 0, index1, index2)}
          min={0}
          step={0.01}
          size="small"
          controls={false}
        />
      ),
    },
    {
      title: "Total",
      name: "total",
      render: () => <Statistic valueStyle={{ fontSize: "inherit" }} precision={2} />,
    },
  ]

  const itemsTotales = (prevName: number): DescriptionsListformItemsProps["items"] => [
    {
      label: "Total",
      itemName: [prevName, "total"],
      render: () => <Statistic valueStyle={{ fontSize: "inherit", textAlign: "right" }} precision={2} />,
    },
    {
      label: "Valor asumido",
      itemName: [prevName, "valor_asumido"],
      render: () => (
        <Statistic
          valueStyle={{
            fontSize: "inherit",
            textAlign: "right",
            fontWeight: "bold",
          }}
          precision={2}
        />
      ),
    },
  ]

  return (
    <Form.List name="bodegas">
      {fields =>
        fields.map(({ key, name }) => (
          <Collapse key={key} defaultActiveKey={["1"]} style={{ marginBottom: "16px" }}>
            <Collapse.Panel
              key={"1"}
              header={
                <Form.Item name={[name, "bodega"]} style={{ margin: 0, padding: 0 }}>
                  <Statistic style={{ margin: 0, cursor: "pointer", padding: 0 }} valueStyle={{ fontSize: "inherit" }} />
                </Form.Item>
              }
              style={{ padding: 0 }}
              extra={
                <Space style={{ display: "flex" }} align="baseline">
                  <Form.Item
                    label={
                      width >= 576 ? (
                        <Typography.Text style={{ cursor: "pointer" }}>Empleados a su cargo</Typography.Text>
                      ) : undefined
                    }
                    name={[name, "cargo_empleados"]}
                    style={{ margin: 0, padding: 0 }}
                  >
                    <Statistic valueStyle={{ fontSize: "14px" }} style={{ padding: 0, margin: 0 }} />
                  </Form.Item>
                  {reload && (
                    <Button type="default" icon={<ReloadOutlined />} onClick={() => onReloadCargoEmpleados(name)} />
                  )}
                </Space>
              }
            >
              {reload && (
                <Row style={{ display: "flex", justifyContent: "center", paddingBottom: "10px" }}>
                  <Col lg={8} md={12} xs={24}>
                    <ButtonReload buttonProps={{ onClick: () => onReloadBodega(name) }}>
                      Actualizar dependientes
                    </ButtonReload>
                  </Col>
                </Row>
              )}
              <TableFormList
                columns={columnsTableDependientes(name)}
                nameList={[name, "dependientes"]}
                loading={loading}
                canRemove
                onRemove={onDeleteEmpleado}
                questionRemove="¿Está seguro de quitar el dependiente?"
              />
              <DescriptionsListformItems items={itemsTotales(name)} loading={loading} />
            </Collapse.Panel>
          </Collapse>
        ))
      }
    </Form.List>
  )
}
