import { swalError } from "config/methods";
import jsPDF from "jspdf";
import { PropsDetalleMantenimientoRuta } from "./useMantenimientosAprobados";
import moment from "moment";

export const handleReportPdfMantenimientos = (selectedRecords: PropsDetalleMantenimientoRuta[]) => {
  const downloadPDFBodegaGeneral = async (selectedRecords: PropsDetalleMantenimientoRuta[]) => {
    try {
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
      });
      const img = new Image();
      img.src = "/imagen/images.jpg";

      img.onload = () => {
        doc.setFontSize(10);
        doc.text("MANTENIMIENTOS", 15, 10);
        doc.line(15, 11, 260, 11);

        doc.addImage(img, "JPG", 250, 3, 30, 10);
        doc.setFontSize(25);
        doc.text(`REPORTE POR BODEGAS`, 160, 25, { align: "center" });
        doc.setFontSize(12);
        doc.text(`FECHA EMISIÓN: ${moment().format("YYYY-MM-DD")}`, 160, 32, { align: "center" });

        // Preparación de los datos para la tabla
        const body = selectedRecords.map((record) => [
          record.bodega,
          record.Provincia,
          record.Canton,
          record.Ciudad,
          record.Direccion,
          record.Latitude,
          record.Longitude,
          record.DescripcionTipo,
          record.DescripcionDetalle,
          record.Detalle,
        ]);
        // @ts-ignore
        doc.autoTable({
          startY: 39,
          body: body,
          columns: [
            { header: "Bodega", dataKey: "bodega" },
            { header: "Provincia", dataKey: "Provincia" },
            { header: "Canton", dataKey: "Canton" },
            { header: "Ciudad", dataKey: "Ciudad" },
            { header: "Direccion", dataKey: "Direccion" },
            { header: "Latitude", dataKey: "Latitude" },
            { header: "Longitude", dataKey: "Longitude" },
            { header: "Descripción", dataKey: "DescripcionTipo" },
            { header: "Detalle", dataKey: "DescripcionDetalle" },
            { header: "Comentario", dataKey: "Detalle" },
          ],
          margin: { horizontal: 7 },
          theme: "striped",
        });

        doc.save(`reporte_bodegas_detalles.pdf`);
      };

      img.onerror = () => {
        console.error("Ocurrió un error al cargar la imagen.");
        // Asumiendo que swalError es una función definida para mostrar errores.
        swalError({ text: "No se pudo cargar la imagen para el reporte." });
      };
    } catch (error) {
      console.error("Ocurrió un error al generar el PDF.", error);
      swalError({ text: "Ocurrió un error al generar el PDF." });
    }
  };

  downloadPDFBodegaGeneral(selectedRecords);
};
