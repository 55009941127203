import { InputNumber, type InputNumberProps, Modal, Select, Tree, Row, Col } from "antd"
import { useDragAndDropTree } from "hooks/otros/useDragAndDropTree"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSwal } from "hooks/otros/useSwal"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { useEffect, useState } from "react"

import { url } from "config/constants"

import { type DefaultOptionType } from "antd/lib/select"
import { type MenuType } from "hooks/configuraciones/useMenu"

type Props = {
  open: boolean
  onCancel(): void
  menu: MenuType[]
  onOk(): void
}

type ValuesForm = {
  nivel: number
  padre?: number
}

export function ModalSortMenu({ open, onCancel, menu, onOk }: Props) {
  const [valuesForm, setValuesForm] = useState<ValuesForm>({
    nivel: 0,
    padre: undefined,
  })

  const [padres, setPadres] = useState<DefaultOptionType[]>([])

  const [disabledPadres, setDisabledPadres] = useState(true)

  const { gData, onDrop, setGData } = useDragAndDropTree([])

  const { isError } = useVerifyResponse()

  const { swalSuccess, swalError } = useSwal()

  const { optionsPost } = useLocalStorage()

  // useEffect(() => {
  //   console.log(gData)
  // }, [gData])

  useEffect(() => {
    const { nivel, padre } = valuesForm
    if (nivel === 0) {
      setGData(
        menu
          .filter(({ nivel }) => nivel === 0)
          .sort((a, b) => a.orden - b.orden)
          .map(({ key, menu }) => ({ title: menu, key }))
      )
    } else {
      if (!padre) {
        setGData([])
      } else {
        setGData(
          menu
            .filter(m => m.padre === padre)
            .sort((a, b) => a.orden - b.orden)
            .map(({ key, menu }) => ({ title: menu, key }))
        )
      }
    }
  }, [menu, setGData, valuesForm])

  const handleSaveOrderMenu = async () => {
    try {
      const response = await fetch(
        `${url}configuraciones/cambiar-orden-menu`,
        optionsPost({ orden_menu: gData.map((d, index) => ({ id: d.key, orden: index })) })
      )
      if (isError(response)) throw new Error()
      const text = await response.json()

      swalSuccess({ text })

      onOk()
    } catch (e) {
      swalError({ text: "No se pudo modificar el orden" })
    } finally {
      onCancel()
    }
  }

  const cargarPadres: InputNumberProps["onChange"] = async value => {
    try {
      if (value === 0) return
      const res = await fetch(`${url}configuraciones/padres`, optionsPost({ nivel: value }))
      if (isError(res)) throw new Error()
      const result = await res.json()
      setPadres(result)
    } catch (e) {
      swalError({ text: "No se pudo cargar los padres de la ruta" })
    }
  }

  return (
    <Modal
      open={open}
      transitionName=""
      title="Orden del menú"
      style={{ top: "10px" }}
      onCancel={() => {
        setValuesForm({
          nivel: 0,
          padre: undefined,
        })
        setDisabledPadres(true)
        onCancel()
      }}
      okText="Guardar"
      onOk={() => {
        handleSaveOrderMenu()
      }}
    >
      <div className="scrollableDiv">
        <Row gutter={[16, 16]} style={{ marginRight: 0 }}>
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            Nivel:{" "}
            <InputNumber
              min={0}
              max={5}
              onChange={value => {
                setDisabledPadres(!value || value === 0)
                cargarPadres(value)
                ;(value || value === 0) && setValuesForm(state => ({ ...state, nivel: value }))
              }}
              value={valuesForm.nivel}
            />
          </Col>
          <Col xs={16} sm={16} md={16} lg={16} xl={16}>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                Padre:{" "}
              </Col>
              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                <Select
                  options={padres}
                  showSearch
                  optionFilterProp="label"
                  getPopupContainer={() => document.getElementsByClassName("ant-modal-body")[0] as HTMLElement}
                  disabled={disabledPadres}
                  style={{ width: "100%" }}
                  onChange={value => setValuesForm(state => ({ ...state, padre: value }))}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Tree
          draggable
          blockNode
          // style={{ padding: '10px'}}
          className="treeMenuSort"
          treeData={gData}
          onDrop={onDrop}
        />
      </div>
    </Modal>
  )
}
