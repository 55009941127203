import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { optionsPOST, url, url_alku, url_interna } from "../../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { Button, Form, notification, TableColumnsType, Tag } from "antd";

import { useLayout } from "hooks/otros/useLayout";
import { EyeOutlined, DeleteOutlined, FilePdfOutlined, ReloadOutlined, UsergroupAddOutlined } from "@ant-design/icons";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";
import Swal from "sweetalert2";
import "./alertaEstilo.css";
import { swalError, swalSuccess, swalWarning } from "config/methods";
interface PropsDescripcionInventario {
  key: React.Key;
  laboratorios: string;
  usuario: string;
  Fecha_Inicio: string;
  Fecha_Fin: string;
  tiempo: string;
  tiempoFiltro: number;
  estado: string;
}
interface PropsDatosPersonal {
  key: React.Key;
  nombres: string;
  total: number;
  tiempo: string;
  porcentaje: number;
}
interface PropsDatosUsuarios {
  label: string;
  value: number;
}
interface PropsPersonalInventario {
  Cod_Usuario: number;
}
type InventarioReporte = {
  farmacia: string;
  Cod_Bodega: number;
  tipo: string;
  fechaInicio: string;
  fechaFin: string;
  total: number;
  procesados: number;
  faltantes: number;
  avance: number;
  laboratorios: PropsDescripcionInventario[];
  datos: PropsDatosPersonal[];
  equipo: {
    equipo: string;
  }[];
  estado: string;
  rutaInicio: string;
  rutaConciliatoria: string;
  rutaFinal: string;
  personalInventario: PropsPersonalInventario[];
  key: React.Key;
};

const breadcrumbs = [
  { Label: "Inventarios", Url: "/inventario" },
  { Label: "Reportes", Url: "/inventarios/reportes" },
  { Label: "Reporte general" },
];

const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];
export function useReporteInventario() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[1].Label + "/" + breadcrumbs[2].Label);
  const [mes, setMes] = useState<Moment>(moment());
  const [dataEstado, setDataEstado] = useState<boolean>(false);
  const [datosInicial, setDatos] = useState<InventarioReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<InventarioReporte[]>([]);
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<InventarioReporte>();
  const [isOkButtonDisabled, setOkButtonDisabled] = useState<boolean>(false);
  const { getColumnSearchPropsNew: getColumnSearchPropsNewBodegasReporte } = useSearchTableNew<PropsDescripcionInventario>();
  //const { getColumnSearchPropsNew } = useSearchTableNew<InventarioReporte>();
  //const [detalle, setDetalle] = useState<PropsDescripcionInventario[]>([]);
  const [datakey, setDatakey] = useState<React.Key>();
  const [detallePersonal, setDetallePersonal] = useState<PropsDatosPersonal[]>([]);
  const [codInventarioActualiza, setInventario] = useState<React.Key>();
  const [detalleUsuarios, setDetalleUsuarios] = useState<PropsDatosUsuarios[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalOpenUsuario, setIsModalOpenUsuario] = useState<boolean>(false);

  const [estadoBotones, setDataBoton] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { exportToExcel } = useExport();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModalPersonal = () => {
    setIsModalOpenUsuario(true);
  };
  const handleCancelUsuario = () => {
    setIsModalOpenUsuario(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const guardar = async () => {
    const values = await form.validateFields();
    if (codInventarioActualiza) actualiarPersonalInventario(codInventarioActualiza, values);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [selecteds, setSelecteds] = useState<{ enlacePdf: string }>({
    enlacePdf: "",
  });
  const [openModalPdf, setOpenModalPdf] = useState(false);

  const actualiarPersonalInventario = async (codInventario: React.Key, jsonUsuario: any) => {
    setOkButtonDisabled(true);
    try {
      const res = await fetch(
        url + "apifsg-pr/inventarios/actualiar_inventario_personal",
        optionsPost({ codInventario, jsonUsuario })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        notification.success({
          message: "Correcto",
          description: `Personal de inventario actualizado con éxito`,
          placement: "bottomRight",
        });
        handleCancelUsuario();
        getBodegas(mes);
      } else {
        notification.warning({
          message: "Error",
          description: `Ocurió un error al actualizar al personal de inventario`,
          placement: "bottomRight",
        });
        return { msg: "Ocurrio un error", status: false };
      }
      setOkButtonDisabled(false);
    } catch (e) {
      console.log(e);
      return { msg: "no_conexion", status: false };
    }
  };
  const getBodegas = 
    async (mes: Moment) => {
      setDataEstado(false);
      setDataBoton(true);
      try {
        const res = await fetch(
          url_interna + "api/inventarios/obtener_reporte_general",
          optionsPOST(JSON.stringify({ mes: mes.format("YYYYMM") }), { "api-token": "apitoken" })
        );
        if (isError(res)) throw new Error();
        const respuesta = await res.json();
        if (respuesta.msg !== "err") {
          setDataBoton(false);
          const respuestas: InventarioReporte[] = respuesta.data;
          setDataEstado(true);
          setDatos(
            respuestas.map(
              ({
                key,
                farmacia,
                Cod_Bodega,
                tipo,
                fechaInicio,
                fechaFin,
                total,
                procesados,
                faltantes,
                avance,
                laboratorios,
                datos,
                equipo,
                estado,
                rutaInicio,
                rutaConciliatoria,
                rutaFinal,
                personalInventario,
              }) => ({
                key,
                farmacia,
                Cod_Bodega,
                tipo,
                fechaInicio,
                fechaFin,
                total,
                procesados,
                faltantes,
                avance,
                laboratorios,
                datos,
                equipo,
                estado,
                rutaInicio,
                rutaConciliatoria,
                rutaFinal,
                personalInventario,
              })
            )
          );
        } else {
          setDataBoton(false);
          setDataEstado(true);
          setDatos([]);
          notification.warning({
            message: "Error",
            description: `No hay datos`,
            placement: "bottomRight",
          });
          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        setDatos([]);
        setDataBoton(false);
        return { msg: "no_conexion", status: false };
      }
    };

  const getDatos = useCallback(
    async (codInventario: React.Key) => {
      try {
        const res = await fetch(
          url + "apifsg-pr/inventarios/obtener_reporte_general_especifico",
          optionsPost({ codInventario: codInventario })
        );
        if (isError(res)) throw new Error();
        const respuesta = await res.json();
        if (respuesta.msg !== "err") {
          const datosDecodificados: PropsDatosPersonal[] = JSON.parse(respuesta.data[0].datos);
          setDetallePersonal(
            datosDecodificados.map(({ porcentaje, tiempo, total, nombres, key }) => ({
              porcentaje,
              tiempo,
              total,
              nombres,
              key,
            }))
          );
          showModal();
        } else {
          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        return { msg: "no_conexion", status: false };
      }
    },
    [optionsPost, isError]
  );
  /* useEffect(() => {
    getBodegas();
  }, [getBodegas]);*/
  const getDatosPersonal = async (codInventario: React.Key, codBodega: number) => {
    try {
      const res = await fetch(
        url + "apifsg-pr/inventarios/obtener_grupo_inventario_personal_bodega",
        optionsPost({ codBodega: codBodega })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const datosDecodificados: PropsDatosUsuarios[] = respuesta.data;

        setDetalleUsuarios(
          datosDecodificados.map(({ label, value }) => ({
            label,
            value,
          }))
        );
        //console.log(datosInicial.find((datos) => datos.key === codInventario));
        form.setFieldValue(
          "personal",
          datosInicial.find((datos) => datos.key === codInventario)?.personalInventario.map(({ Cod_Usuario }) => Cod_Usuario)
        );
        setInventario(codInventario);
        showModalPersonal();
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      console.log(e);
      return { msg: "no_conexion", status: false };
    }
  };
  const suspenderInventario = async (codInventario: React.Key, descripcion: String) => {
    try {
      const result = await fetch(
        `${url}apifsg-pr/inventarios/suspender_inventario`,
        optionsPost({
          codInventario,
          descripcion,
        })
      );
      if (isError(result)) throw new Error();
      let text = await result.json();

      text = text["msg"];

      swalSuccess({ text });
      getBodegas(mes);
    } catch (e) {
      swalError({ text: "No se pudo suspender el inventario" });
    }
  };

  const restablecerLaboratorio = async (codInventarioDet: React.Key, descripcion: String) => {
    try {
      const result = await fetch(
        `${url}apifsg-pr/inventarios/restablecer_labotario`,
        optionsPost({
          codInventarioDet,
          descripcion,
        })
      );
      if (isError(result)) throw new Error();
      let text = await result.json();
      text = text["msg"];

      swalSuccess({
        text,
        customClass: {
          container: "swal2-custom",
        },
      });
      handleOk();
      getBodegas(mes);
    } catch (e) {
      swalError({
        text: "No se pudo suspender el inventario",
        customClass: {
          container: "swal2-custom",
        },
      });
    }
  };
  const handleChangeButton = () => {
    try {
      if (mes) {
        getBodegas(mes);
      } //
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!searchText) {
      setFilteredData(datosInicial); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datosInicial.filter((item) => item.farmacia.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datosInicial]);

  const columns: TableColumnsType<InventarioReporte> = [
    {
      title: "Farmacia",
      dataIndex: "farmacia",
      key: "farmacia",
      ...getColumnSearchPropsNewVisorReporte("farmacia"),
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
    },
    {
      title: "Fecha inicio",
      dataIndex: "fechaInicio",
      key: "fechaInicio",
      sorter: (a, b) => moment(a.fechaInicio).unix() - moment(b.fechaInicio).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Fecha fin",
      dataIndex: "fechaFin",
      key: "fechaFin",
      sorter: (a, b) => moment(a.fechaFin).unix() - moment(b.fechaFin).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Total productos",
      dataIndex: "total",
      align: "right",
    },
    {
      title: "Procesados",
      dataIndex: "procesados",
      align: "right",
    },
    {
      title: "Faltantes",
      dataIndex: "faltantes",
      align: "right",
    },
    {
      title: "%",
      dataIndex: "avance",
      align: "right",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      render: (value: String) =>
        value === "Terminado" ? (
          <Tag color="success">Terminado</Tag>
        ) : value === "Suspendido" ? (
          <Tag color="red">Suspendido</Tag>
        ) : (
          <Tag color="warning">Pendiente</Tag>
        ),

      sorter: (a, b) => a.estado.localeCompare(b.estado),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Laboratorios",
      dataIndex: "laboratorios",
      key: "laboratorios",
      render: (value, record) => (
        <Button
          icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={() => {
            if (record.estado !== "Suspendido") {
              setDatakey(record.key);
              getDatos(record.key);
              //setDetalle(value);
              //  setDetallePersonal(record.datos);
            } else {
              swalWarning({ text: "El inventario está suspendido" });
            }
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
      fixed: "right",
    },
    {
      title: "Accion",
      dataIndex: "key",
      key: "key",
      render: (value, record) => (
        <Button
          icon={<DeleteOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={async () => {
            /*if(record.estado==="Suspendido"){*/
            if (record.estado === "Pendiente" && record.laboratorios.every(({ usuario }) => !usuario)) {
              const { value: motivo } = await Swal.fire({
                title: "Suspender inventario",
                input: "textarea",
                inputLabel: "¿Está seguro de suspender este inventario?",
                inputPlaceholder: "Ingrese una descripción",
                showCancelButton: true,
                confirmButtonColor: "#8b0505",
              });
              if (motivo === null || motivo === undefined) {
              } else if (!motivo) {
                swalWarning({ text: "Ingrese un motivo" });
              } else {
                suspenderInventario(record.key, motivo);
              }
            }
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
      fixed: "right",
    },
    {
      title: "AI",
      dataIndex: "rutaInicio",
      fixed: "right",
      key: "rutaInicio",
      align: "center",
      render: (value, record) => (
        <Button
          icon={<FilePdfOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={async () => {
            if (!record.rutaInicio) {
              swalWarning({ text: "No hay archivo para mostrar" });
            } else {
              setSelecteds({ enlacePdf: `${url_alku}allku/api/${record.rutaInicio}` });
              setOpenModalPdf(true);
            }
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
    },
    {
      title: "AC",
      dataIndex: "rutaConciliatoria",
      fixed: "right",
      key: "rutaConciliatoria",
      align: "center",
      render: (value, record) => (
        <Button
          icon={<FilePdfOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={async () => {
            if (!record.rutaConciliatoria) {
              swalWarning({ text: "No hay archivo para mostrar" });
            } else {
              setSelecteds({ enlacePdf: `${url_alku}allku/api/${record.rutaConciliatoria}` });
              setOpenModalPdf(true);
            }
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
    },
    {
      title: "AF",
      dataIndex: "rutaFinal",
      fixed: "right",
      key: "rutaFinal",
      align: "center",
      render: (value, record) => (
        <Button
          icon={<FilePdfOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={async () => {
            if (!record.rutaFinal) {
              swalWarning({ text: "No hay archivo para mostrar" });
            } else {
              setSelecteds({ enlacePdf: `${url_alku}allku/api/${record.rutaFinal}` });
              setOpenModalPdf(true);
            }
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
    },
    {
      title: "ADD",
      fixed: "right",
      align: "center",
      render: (value, record) => (
        <Button
          icon={<UsergroupAddOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={async () => {
            if (record.estado === "Pendiente") {
              getDatosPersonal(record.key, record.Cod_Bodega);
            }
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
    },
  ];

  const columsDatatableDetalle: TableColumnsType<PropsDescripcionInventario> = [
    {
      title: "Laboratorios",
      dataIndex: "laboratorios",
      key: "laboratorios",
      ...getColumnSearchPropsNewBodegasReporte("laboratorios"),
    },
    {
      title: "Usuario",
      dataIndex: "usuario",
      key: "usuario",

      ...getColumnSearchPropsNewBodegasReporte("usuario"),
    },
    {
      title: "Fecha inicio",
      dataIndex: "Fecha_Inicio",
      key: "Fecha_Inicio",
      sorter: (a, b) =>
        moment(a.Fecha_Inicio, "YYYY/MM/DD HH:mm").unix() - moment(b.Fecha_Inicio, "YYYY/MM/DD HH:mm").unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Fecha fin",
      dataIndex: "Fecha_Fin",
      key: "Fecha_Fin",
      sorter: (a, b) => moment(a.Fecha_Fin, "YYYY/MM/DD HH:mm").unix() - moment(b.Fecha_Fin, "YYYY/MM/DD HH:mm").unix(),

      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Tiempo",
      dataIndex: "tiempo",
      key: "tiempo",
      sorter: (a, b) => a.tiempoFiltro - b.tiempoFiltro,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Estado",
      dataIndex: "Fecha_Fin",
      key: "Fecha_Fin",
      render: (value: String) => (value ? <Tag color="success">Terminado</Tag> : <Tag color="warning">Pendiente</Tag>),

      sorter: (a, b) => a.estado.localeCompare(b.estado),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Accion",
      dataIndex: "Fecha_Fin",
      key: "Fecha_Fin",
      render: (value, record) => (
        <Button
          icon={<ReloadOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={async () => {
            if (!value && record.Fecha_Inicio && record.usuario) {
              const { value: motivo } = await Swal.fire({
                title: "Restablecer laboratorio",
                input: "textarea",
                inputLabel: "¿Está seguro que desea restablecer este laboratorio?",
                inputPlaceholder: "Ingrese una descripción",
                showCancelButton: true,
                confirmButtonColor: "#8b0505",

                customClass: {
                  container: "swal2-custom",
                },
              });

              if (motivo === null || motivo === undefined) {
              } else if (!motivo) {
                swalWarning({
                  text: "Ingrese un motivo",
                  customClass: {
                    container: "swal2-custom",
                  },
                });
              } else {
                restablecerLaboratorio(record.key, motivo);
              }
            }
            /* }else{
            
            
          }*/
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
      fixed: "right",
    },
  ];
  const columsDatatableDetalleUsuario: TableColumnsType<PropsDatosPersonal> = [
    {
      title: "Personal",
      dataIndex: "nombres",
      key: "nombres",

      //...getColumnSearchPropsNewBodegasReporte("usuario"),
    },
    {
      title: "Productos contabilizados",
      dataIndex: "total",
      key: "total",
      sorter: (a, b) => a.total - b.total,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Tiempo",
      dataIndex: "tiempo",
      key: "tiempo",
      //sorter: (a, b) => a.tiempo - b.tiempo,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Porcentaje",
      dataIndex: "porcentaje",
      key: "porcentaje",
      //sorter: (a, b) => a.tiempoFiltro - b.tiempoFiltro,
      sortDirections: ["descend", "ascend"],
    },
  ];
  const handleClickDownloadData = () => {
    const datosParaExportar = datosInicial
      .find((e) => e.key === datakey)
      ?.laboratorios.map(({ key, tiempoFiltro, ...resto }) => resto);

    exportToExcel(datosParaExportar ?? [], "DetalleInventario");
  };

  return {
    columns,
    columsDatatableDetalle,
    columsDatatableDetalleUsuario,
    isModalOpen,
    isModalOpenUsuario,
    // detalle,
    // detallePersonal
    datakey,
    datosInicial,
    openModalPdf,
    selecteds,
    setOpenModalPdf,
    mes,
    setMes,
    filteredData,
    handleOk,
    handleCancel,
    form,
    handleClickDownloadData,
    dataEstado,
    detallePersonal,
    detalleUsuarios,
    handleCancelUsuario,
    guardar,
    isOkButtonDisabled,
    estadoBotones,
    handleChangeButton,
  };
}
