import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { url } from "../../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { Button, TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";
import { EyeOutlined } from "@ant-design/icons";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";

interface PropsDescripcionDependientes {
  key: React.Key;
  Nombre: string;
  Valor: string;
}
interface PropsDescripcionUsuarios {
  key: React.Key;
  nombreEquipo: string;
}
type InventarioReporteRealizados = {
  Farmacia: string;
  Motivo: string;
  Fecha: string;
  Valortotal: number;
  Grupo: string;
  ValortotalCaducado: number;
  Dependientes: PropsDescripcionDependientes[];
  Usuarios: PropsDescripcionUsuarios[];
  key: React.Key;
};

const breadcrumbs = [
  { Label: "Inventarios", Url: "/inventarios" },
  { Label: "Reportes", Url: "/inventarios/reportes" },
  { Label: "Reporte realizados" },
];

const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];
export function useReporteRealizados() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[1].Label + "/" + breadcrumbs[2].Label);
  const [mes, setMes] = useState<Moment>(moment());
  const [datosInicial, setDatos] = useState<InventarioReporteRealizados[]>([]);
  const [searchText] = useState("");
  const [dataEstado, setDataEstado] = useState<boolean>(false);

  const [filteredData, setFilteredData] = useState<InventarioReporteRealizados[]>([]);
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<InventarioReporteRealizados>();

  const [estadoBoton, setBoton] = useState<boolean>(false);

  const [datakey, setDatakey] = useState<React.Key>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { exportToExcel } = useExport();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getBodegas = useCallback(async () => {
    try {
      setDataEstado(false);
      const res = await fetch(
        url + "apifsg-pr/inventarios/obtener_reporte_realizados",
        optionsPost({ mes: mes.format("YYYYMM") })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        setBoton(true);
        setDataEstado(true);
        const respuestas: InventarioReporteRealizados[] = respuesta.data;
        setDatos(
          respuestas.map(
            ({ Farmacia, Motivo, Fecha, Grupo, Valortotal, ValortotalCaducado, Dependientes, Usuarios, key }) => ({
              Farmacia,
              Motivo,
              Fecha,
              Grupo,
              Valortotal,
              ValortotalCaducado,
              Dependientes,
              Usuarios,
              key,
            })
          )
        );
      } else {
        setDataEstado(false);
        setDatos([]);
        setBoton(false);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsPost, isError, mes]);

  useEffect(() => {
    getBodegas();
  }, [getBodegas]);
  const calcularTotales = () => {
    let totalCobrado = 0.0;
    let totalCaducado = 0.0;

    datosInicial.forEach((item) => {
      totalCobrado += item.Valortotal;
      totalCaducado += item.ValortotalCaducado;
    });
    totalCobrado = parseFloat(totalCobrado.toFixed(2));
    totalCaducado = parseFloat(totalCaducado.toFixed(2));

    return { totalCobrado, totalCaducado };
  };

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datosInicial);
    } else {
      const filtered = datosInicial.filter((item) => item.Farmacia.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datosInicial]);

  const columns: TableColumnsType<InventarioReporteRealizados> = [
    {
      title: "Farmacia",
      dataIndex: "Farmacia",
      key: "Farmacia",
      ...getColumnSearchPropsNewVisorReporte("Farmacia"),
    },
    {
      title: "Motivo",
      dataIndex: "Motivo",
      key: "Motivo",
    },
    {
      title: "Grupo",
      dataIndex: "Grupo",
      key: "Grupo",
    },
    {
      title: "Fecha",
      dataIndex: "Fecha",
      key: "Fecha",
      sorter: (a, b) => moment(a.Fecha).unix() - moment(b.Fecha).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Diferencia",
      dataIndex: "Valortotal",
      key: "Valortotal",
    },
    {
      title: "Total Caducado",
      dataIndex: "ValortotalCaducado",
      key: "ValortotalCaducado",
    },
    {
      title: "Equipo",
      dataIndex: "Usuarios",
      key: "Usuarios",
      render: (value, record) => (
        <Button
          icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={() => {
            /*if (record.estado !== "Suspendido") {*/
            setDatakey(record.key);
            //setDetalle(value);
            //  setDetallePersonal(record.datos);
            showModal();
            /* } else {
              swalWarning({ text: "El inventario está suspendido" });
            }*/
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
      fixed: "right",
    },
  ];

  const columsDatatableDetalle: TableColumnsType<PropsDescripcionUsuarios> = [
    {
      title: "Nombre",
      dataIndex: "nombreEquipo",
      key: "nombreEquipo",

      //...getColumnSearchPropsNewBodegasReporte("usuario"),
    },
  ];
  const columsDataDependiente: TableColumnsType<PropsDescripcionDependientes> = [
    {
      title: "Dependiente",
      dataIndex: "Nombre",
      key: "Nombre",
      width: "545px",
      fixed: "left",
      //...getColumnSearchPropsNewBodegasReporte("usuario"),
    },
    {
      title: "Total cobrado",
      dataIndex: "Valor",
      key: "Valor",
      //sorter: (a, b) => a.total - b.total,
    },
  ];
  const handleClickDownloadData = () => {
    // Transforma cada entrada de datosInicial para incluir Dependientes y Usuarios como strings
    const datosParaExportar = datosInicial.map((item) => ({
      ...item,
      Dependientes: JSON.stringify(item.Dependientes),
      Usuarios: JSON.stringify(item.Usuarios),
    }));

    const texto = "detalleInventario" + mes.format("YYYYMM");
    exportToExcel(datosParaExportar ?? [], texto);
  };

  return {
    columns,
    columsDatatableDetalle,
    columsDataDependiente,
    isModalOpen,
    datakey,
    datosInicial,
    mes,
    setMes,
    filteredData,
    handleOk,
    handleCancel,
    handleClickDownloadData,
    calcularTotales,
    estadoBoton,
    dataEstado,
  };
}
