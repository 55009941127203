import { Input, Checkbox, InputNumber, Radio, Select, Switch, FormInstance, Upload, Button, DatePicker, AutoComplete } from "antd"
import type { FormItemProps, InputNumberProps, InputProps, ModalProps, RadioGroupProps, SelectProps, SwitchProps, UploadProps, DatePickerProps, CheckboxProps, AutoCompleteProps } from 'antd'
import type { TextAreaProps } from "antd/lib/input"
import { createContext } from "react"

import { UploadOutlined } from '@ant-design/icons'

type propsElement = InputProps | InputNumberProps | SelectProps | SwitchProps | RadioGroupProps | CheckboxProps | TextAreaProps | UploadProps | DatePickerProps| AutoCompleteProps

const elements = {
  'text': (options: propsElement) => <Input {...options as InputProps} />,
  'textarea': (options: propsElement) => <Input.TextArea {...options as TextAreaProps} />,
  'number': (options: propsElement) => <InputNumber {...options as InputNumberProps} />,
  'select': (options: propsElement) => (
    <Select 
      {...options as SelectProps} 
      showSearch 
      getPopupContainer={() => document.getElementsByClassName('ant-modal-body')[0] as HTMLElement}
      // notFoundContent={ <Empty description = 'No hay datos' image={Empty.PRESENTED_IMAGE_SIMPLE} /> } 
      filterOption = { (input, option) => (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())}
    />
  ),
  'switch': (options: propsElement) => <Switch {...options as SwitchProps} />,
  'radio': (options: propsElement) => <Radio.Group {...options as RadioGroupProps} />,
  'check': (options: propsElement) => <Checkbox {...options as CheckboxProps} />,
  'upload': (options: propsElement) => (
    <Upload { ...options as UploadProps } style={{ display: 'relative', width: '100%'}} beforeUpload={() => false}>
      <Button icon={<UploadOutlined />} block>Cargar XML</Button>
    </Upload>
  ),
  'datePicker': (options: propsElement) => <DatePicker { ...options as DatePickerProps } format={ 'DD/MM/YYYY' } getPopupContainer={() => document.getElementsByClassName('ant-modal-body')[0] as HTMLElement}/>,
  'autocomplete':(options:propsElement)=><AutoComplete {...options as AutoCompleteProps} showSearch  filterOption = { (input, option) => (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())}/>
}

type ModalFormNamesElement = keyof typeof elements

export interface ModalFormInputsElements {
  elementType: ModalFormNamesElement
  elementProps: FormItemProps
  inputProps?: propsElement
}



export interface ModalFormGroupInputs {
  label?: string,
  elements: Array<ModalFormInputsElements>
}

interface ContextModalFormProps {
  children: JSX.Element | Array<JSX.Element>
  inputsState: Array<ModalFormInputsElements>
  modalProps: ModalProps
  updateModalProps: React.Dispatch<React.SetStateAction<ModalProps>>
  // values: { [key: string]: any}
  onOk?: (values: any) => void
  form: FormInstance
  initialValues?: any,
  onCancel?: () => void
}

type ModalFormContextType = {
  elements: typeof elements
  inputsState: Array<ModalFormInputsElements>
  modalProps: ModalProps
  updateModalProps: React.Dispatch<React.SetStateAction<ModalProps>>
  // values: { [key: string]: any}
  onOk?: (values: any) => void
  form: FormInstance
  initialValues?: any,
  onCancel?: () => void
}

export const ModalFormContext = createContext<ModalFormContextType>({} as ModalFormContextType)

export function ContextModalForm({ children, inputsState, modalProps, updateModalProps, onOk, form, initialValues, onCancel }: ContextModalFormProps) {

  return (
    <ModalFormContext.Provider value={{ elements, inputsState, modalProps, updateModalProps, onOk, form, initialValues, onCancel }}>
      { children }
    </ModalFormContext.Provider>
  )
}