import { Col, Form, Row, Table, DatePicker, Select, Tag, Button } from "antd"
import { useDataForTable } from "hooks/rrhh/llamadosatencion/useDataForTable"
import { useMemo, useState } from "react"
import { useSearchTable } from "hooks/otros/useSearchTable"
import { useLayout } from "hooks/otros/useLayout"

import moment from "moment"

import { CheckCircleOutlined } from "@ant-design/icons"

import type { RangePickerProps } from "antd/lib/date-picker"
import type { ColumnsType } from "antd/lib/table"
import type { Moment } from "moment"

import { llave } from "config/constants"
import { Link } from "react-router-dom"
import AES from "crypto-js/aes"

interface dataLlamadosAtencion {
  key: string
  cod_solicitud: number
  solicitante: string
  departamento: string
  fecha_falta: string
  fecha_aprobacion: string
  porcentaje: number
  observacion: string
  motivo?: string
  descripcion?: string
  bodega: string
  periodo_actividad?: string
  cod_empleado: number
  empleado: string
  codigo: string
  estado: "AP" | "RV"
}

const { RangePicker } = DatePicker

const breadcrumbs = [
  { Label: "Recursos Humanos", Url: "/rrhh" },
  { Label: "Llamados de atención", Url: "/rrhh/llamados-de-atencion" },
  { Label: "Marcar recibidos" },
]
const openKeys = [`${breadcrumbs[0].Label}`, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export function MarcarRecibidos() {
  useLayout(breadcrumbs, openKeys, selectKeySider)

  const [selectedDates, setSelectedDates] = useState<[Moment, Moment]>([moment().subtract(1, "months"), moment()])
  const [selectedTipoConsulta, setSelectedTipoConsulta] = useState<number>(2)
  const [disableButtonView] = useState(false)

  const { getColumnSearchProps } = useSearchTable<dataLlamadosAtencion>()

  // const { optionsPost } = useLocalStorage()

  // const { wasInvalidToken, hadError } = useVerifyResponse()

  // const { swalError, swalSuccess } = useSwal()

  const handleChangeDates: RangePickerProps["onChange"] = values => {
    setSelectedDates(values as [Moment, Moment])
  }

  const bodyGetData = useMemo(
    () => ({
      tipo_consulta: selectedTipoConsulta,
      fecha_inicio: selectedDates[0].format("DD/MM/YYYY"),
      fecha_fin: selectedDates[1].format("DD/MM/YYYY"),
    }),
    [selectedDates, selectedTipoConsulta]
  )

  const { llamadosAtencion } = useDataForTable(
    "apifsg-pr/recursos-humanos/listar-llamados-atencion-aprobados-por-fechas-recibidos",
    undefined,
    bodyGetData
  )

  // const handleClickMarcar = async (cod_solicitud: number, cod_empleado: number) => {
  //   try {
  //     const res = await fetch(
  //       `${url}apifsg-pr/recursos-humanos/marcar-revisados`,
  //       optionsPost({ cod_solicitud, cod_empleado })
  //     )
  //     const response = await res.json()

  //     if (wasInvalidToken(response)) return
  //     if (hadError(response, "No se pudo actualizar el estado. Intente de nuevo mas tarde.")) return

  //     swalSuccess({ text: "Llamado de atención creado con éxito" }).then(() => {
  //       listarLlamadosAtencion(bodyGetData)
  //     })
  //   } catch (e) {
  //     swalError({ text: "No se pudo actualizar el estado. Intente de nuevo mas tarde." })
  //   }
  // }

  const columnsTable: ColumnsType<dataLlamadosAtencion> = [
    {
      title: "Solicitante",
      dataIndex: "solicitante",
      key: "solicitante",
      ...getColumnSearchProps("solicitante"),
    },
    {
      title: "Departamento",
      dataIndex: "departamento",
      key: "Departamento",
      filters: [...new Set(llamadosAtencion?.map(em => em.departamento))].map(departamento => ({
        text: departamento,
        value: departamento,
      })),
      onFilter: (value, record) => record.departamento === value,
    },
    { title: "Bodega", dataIndex: "bodega", key: "bodega", ...getColumnSearchProps("bodega") },
    { title: "Codigo", dataIndex: "codigo", key: "codigo", ...getColumnSearchProps("codigo") },
    {
      title: "Empleado",
      dataIndex: "empleado",
      key: "empleado",
      ...getColumnSearchProps("empleado"),
    },
    { title: "Fecha falta", dataIndex: "fecha_falta", key: "Fecha_Falta" },
    { title: "Fecha de aprobación", dataIndex: "fecha_aprobacion", key: "Fecha" },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      render: value => (value === "AP" ? <Tag color="blue">APROBADO</Tag> : <Tag color="purple">RECIBIDO</Tag>),
      filters: [
        { text: "APROBADO", value: "AP" },
        { text: "RECIBIDO", value: "RV" },
      ],
      onFilter: (value, { estado }) => value === estado,
    },
    {
      title: "",
      dataIndex: "key",
      key: "codigo",
      align: "center",
      fixed: "right",
      render: (_, { cod_solicitud, cod_empleado }) =>
        disableButtonView ? (
          <Button disabled block type="text" icon={<CheckCircleOutlined style={{ fontSize: "1.3rem" }} />} />
        ) : (
          <Link
            to={`/rrhh/llamados-de-atencion/${encodeURIComponent(
              AES.encrypt(cod_solicitud.toString(), llave).toString()
            )}/registrar-recibido/${encodeURIComponent(AES.encrypt(cod_empleado.toString(), llave).toString())}`}
            className="ant-btn ant-btn-text ant-btn-icon-only ant-btn-block"
          >
            <CheckCircleOutlined style={{ fontSize: "1.3rem" }} />
          </Link>
        ),
    },
  ]

  return (
    <Row wrap style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Form layout="horizontal" style={{ width: "100%" }}>
        <Col xs={24} sm={13} md={10} lg={9} xl={8}>
          <Form.Item label="Consultar por">
            <Select
              options={[
                { value: 0, label: "Fecha de solicitud" },
                { value: 1, label: "Fecha de la falta" },
                { value: 2, label: "Fecha de aprobación" },
              ]}
              value={selectedTipoConsulta}
              onChange={value => setSelectedTipoConsulta(value)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={11} md={9} lg={8} xl={8}>
          <Form.Item>
            <RangePicker value={selectedDates} onChange={handleChangeDates} format="DD/MM/YYYY" />
          </Form.Item>
        </Col>
      </Form>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Table
          columns={columnsTable}
          scroll={{ x: true }}
          dataSource={llamadosAtencion as unknown as dataLlamadosAtencion[]}
        />
      </Col>
    </Row>
  )
}
