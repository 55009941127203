import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { url } from "../../../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";

type InventarioReporte = {
  key: React.Key;
  nombresRol: string;
  totalCantidad: number;
  produccion: number;
  tiempo: string;
  tiempoNormal: number;
  farmacias: number;
  descripcionGrupo: string;
};

const breadcrumbs = [
  { Label: "Inventarios", Url: "/inventario" },
  { Label: "Productividad", Url: "/inventarios/productividad" },
  { Label: "Personal inv" },
];
//const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Productividad" }];

const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];
export function useReporteInventarioProductividadUsuario() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[1].Label + "/" + breadcrumbs[2].Label);
  const [mes, setMes] = useState<Moment>(moment());
  const [datos, setDatos] = useState<InventarioReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<InventarioReporte[]>([]);
  const { getColumnSearchPropsNew } = useSearchTableNew<InventarioReporte>();
  const { exportToExcel } = useExport();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();

  const getBodegas = useCallback(async () => {
    
    setDatos([]);
    try {
      const res = await fetch(
        url + "apifsg-pr/inventarios/obtener_productividad_usuarios",
        optionsPost({ mes: mes.format("YYYYMM") })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: InventarioReporte[] = respuesta.data;

        setDatos(
          respuestas.map(
            ({ key, nombresRol, totalCantidad, produccion, tiempo, tiempoNormal, farmacias, descripcionGrupo }) => ({
              key,
              nombresRol,
              totalCantidad,
              produccion,
              tiempo,
              tiempoNormal,
              farmacias,
              descripcionGrupo,
            })
          )
        );
      } else {
        setDatos([]);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsPost, isError, mes]);
  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };
  useEffect(() => {
    getBodegas();
  }, [getBodegas]);

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.nombresRol.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  const columns: TableColumnsType<InventarioReporte> = [
    {
      title: "Usuarios",
      dataIndex: "nombresRol",
      key: "Nombres_Rol",
      ...getColumnSearchPropsNew("nombresRol"),
    },
    {
      title: "Tiempo",
      dataIndex: "tiempo",
      key: "tiempo",
    },
    {
      title: "Unidades",
      dataIndex: "totalCantidad",
      key: "totalCantidad",
      align: "right",
      sorter: (a, b) => a.totalCantidad - b.totalCantidad,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (
        <span style={{ color: record.totalCantidad === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.totalCantidad)}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Productividad",
      dataIndex: "produccion",
      key: "produccion",

      align: "right",
      sorter: (a, b) => a.produccion - b.produccion,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (
        <span style={{ color: record.produccion === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.produccion)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Cantidad farmacias",
      dataIndex: "farmacias",
      key: "farmacias",
      align: "right",
      sorter: (a, b) => a.farmacias - b.farmacias,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Grupo",
      dataIndex: "descripcionGrupo",
      key: "descripcionGrupo",
    },
  ];
  const formatearTiempo = (totalMinutos: number) => {
    const horas = Math.floor(totalMinutos / 60);
    const minutos = totalMinutos % 60;
    return `${horas} horas ${minutos} minutos`;
  };
  const calcularTotales = () => {
    let totalTiempo = 0; // Asumiendo que el tiempo se puede sumar directamente
    let totalUnidades = 0;

    datos.forEach((item) => {
      totalUnidades += item.totalCantidad;
      
      totalTiempo += item.tiempoNormal;
      // Calcula el total de tiempo si es necesario. Asegúrate de convertirlo al formato correcto.
    });
    const promedioProduccion = formateaCantidad(totalUnidades / totalTiempo);
    let unid = formateaCantidad(totalUnidades);
    const tiempoFormateado = formatearTiempo(totalTiempo);
    
    return { tiempoFormateado, unid, promedioProduccion };
  };

  const handleClickDownloadData = () => {
    const datosParaExportar = datos.map(({ key, ...resto }) => resto);
    exportToExcel(datosParaExportar, "productividadInventario" + mes.format("YYYYMM"));
  };

  return {
    columns,
    mes,
    setMes,
    filteredData,

    handleClickDownloadData,
    calcularTotales,
  };
}
