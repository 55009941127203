import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { url } from "../../../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { Button, Form, TableColumnsType, Tag } from "antd";

import { useLayout } from "hooks/otros/useLayout";
import { EyeOutlined, FileExcelOutlined } from "@ant-design/icons";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";
import { swalWarning } from "config/methods";
interface PropsDescripcionInventario {
  key: React.Key;
  nombresRol: string;
  totalCantidad: number;
  produccion: number;
  tiempo: string;
  tiempoNormal: number;
}
type InventarioReporte = {
  farmacia: string;
  tipo: string;
  fechaInicio: string;
  fechaFin: string;
  produccionGeneral: number;
  datos: PropsDescripcionInventario[];
  estado: string;
  key: React.Key;
};

type DatosInventariosAjustados = {
  Codigo: number;
  Producto: string;
  Laboratorio: string;
  Cod_Barra: string;
  Costo: number;
  UnidadInventarioInicial: number;
  FraccionInventarioInicial: number;
  Fraccion: number;
  UnidadAuditada: number;
  FraccionesAuditadas: number;
  DiferenciaReal: number;
  DiferenciaUnidades: number;
  DiferenciaFracciones: number;
  ValorDiferencia: number;
  Nombre: string;
  key: React.Key;
};

const breadcrumbs = [
  { Label: "Inventarios", Url: "/inventario" },
  { Label: "Productividad", Url: "/inventarios/productividad" },
  { Label: "General" },
];

const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];
export function useReporteInventarioProductividad() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[1].Label + "/" + breadcrumbs[2].Label);
  const [mes, setMes] = useState<Moment>(moment());
  const [datos, setDatos] = useState<InventarioReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<InventarioReporte[]>([]);

  const [filteredDataHistorico, setFilteredDataHistorico] = useState<DatosInventariosAjustados[]>([]);

  const [datosHistoricos, setDatosHistoricos] = useState<DatosInventariosAjustados[]>([]);

  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<InventarioReporte>();
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporteHistorial } =
    useSearchTableNew<DatosInventariosAjustados>();
  const [detalle, setDetalle] = useState<PropsDescripcionInventario[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalOpenHistorico, setIsModalOpenHistorico] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { exportToExcel } = useExport();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModalHistorico = () => {
    setIsModalOpenHistorico(true);
  };
  const handleCancelHistorico = () => {
    setIsModalOpenHistorico(false);
  };
  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };
  const getBodegas = useCallback(async () => {
    try {
      const res = await fetch(
        url + "apifsg-pr/inventarios/obtener_productividad",
        optionsPost({ mes: mes.format("YYYYMM") })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: InventarioReporte[] = respuesta.data;

        setDatos(
          respuestas.map(({ key, farmacia, tipo, fechaInicio, fechaFin, produccionGeneral, datos, estado }) => ({
            key,
            farmacia,
            tipo,
            fechaInicio,
            fechaFin,
            produccionGeneral,
            datos,
            estado,
          }))
        );
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsPost, isError, mes]);
  const getDatosHistoricoInventario = async (codInventario: React.Key) => {
    try {
      const res = await fetch(
        url + "apifsg-pr/inventarios/obtener_datos_inventario_historico",
        optionsPost({ codInventario: codInventario })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const datosDecodificados: DatosInventariosAjustados[] = respuesta.data;

        setDatosHistoricos(
          datosDecodificados.map(
            ({
              Codigo,
              Producto,
              Laboratorio,
              Cod_Barra,
              Costo,
              UnidadInventarioInicial,
              FraccionInventarioInicial,
              Fraccion,
              UnidadAuditada,
              FraccionesAuditadas,
              DiferenciaReal,
              DiferenciaUnidades,
              DiferenciaFracciones,
              ValorDiferencia,
              Nombre,
              key,
            }) => ({
              Codigo,
              Producto,
              Laboratorio,
              Cod_Barra,
              Costo,
              UnidadInventarioInicial,
              FraccionInventarioInicial,
              Fraccion,
              UnidadAuditada,
              FraccionesAuditadas,
              DiferenciaReal,
              DiferenciaUnidades,
              DiferenciaFracciones,
              ValorDiferencia,
              Nombre,
              key,
            })
          )
        );
        showModalHistorico();
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      console.log(e);
      return { msg: "no_conexion", status: false };
    }
  };
  useEffect(() => {
    getBodegas();
  }, [getBodegas]);
  const setRowClassName = (record: DatosInventariosAjustados) => {
    if (record.ValorDiferencia > 0) {
      return 'highlight-row'; // Esta es la clase CSS que se aplicará
    }
    return ''; // No se aplica ninguna clase especial si no se cumplen las condiciones
  };
  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.farmacia.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  useEffect(() => {
    if (!searchText) {
      setFilteredDataHistorico(datosHistoricos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datosHistoricos.filter((item) => item.Producto.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredDataHistorico(filtered);
    }
  }, [searchText, datosHistoricos]);
  const columns: TableColumnsType<InventarioReporte> = [
    {
      title: "Farmacia",
      dataIndex: "farmacia",
      key: "farmacia",
      ...getColumnSearchPropsNewVisorReporte("farmacia"),
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
    },
    {
      title: "Fecha inicio",
      dataIndex: "fechaInicio",
      key: "fechaInicio",
      sorter: (a, b) => moment(a.fechaInicio).unix() - moment(b.fechaInicio).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Fecha fin",
      dataIndex: "fechaFin",
      key: "fechaFin",
      sorter: (a, b) => moment(a.fechaFin).unix() - moment(b.fechaFin).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Productividad",
      dataIndex: "produccionGeneral",
      key: "produccionGeneral",
      align: "right",
      sorter: (a, b) => a.produccionGeneral - b.produccionGeneral,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (
        <span style={{ color: record.produccionGeneral === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.produccionGeneral)}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      render: (value: String) =>
        value === "Terminado" ? (
          <Tag color="success">Terminado</Tag>
        ) : value === "Suspendido" ? (
          <Tag color="red">Suspendido</Tag>
        ) : (
          <Tag color="warning">Pendiente</Tag>
        ),

      sorter: (a, b) => a.estado.localeCompare(b.estado),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Datos",
      dataIndex: "datos",
      key: "datos",
      render: (value, record) => (
        <Button
          icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={() => {
            if (record.datos && record.estado !== "Suspendido") {
              setDetalle(value);
              showModal();
            } else {
              swalWarning({ text: "Sin datos para calcular" });
            }
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
      fixed: "right",
    },
    {
      title: "HandHeld",
      render: (value, record) => (
        <Button
          icon={<FileExcelOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={() => {
            if (record.datos && record.estado !== "Suspendido") {
              getDatosHistoricoInventario(record.key);
            } else {
              swalWarning({ text: "Sin datos para mostrar" });
            }
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
      fixed: "right",
    },
  ];
  const formatearTiempo = (totalMinutos: number) => {
    const horas = Math.floor(totalMinutos / 60);
    const minutos = totalMinutos % 60;
    return `${horas} horas ${minutos} minutos`;
  };
  const calcularTotales = () => {
    let totalTiempo = 0; // Asumiendo que el tiempo se puede sumar directamente
    let totalUnidades = 0;

    detalle.forEach((item) => {
      totalUnidades += item.totalCantidad;
      totalTiempo += item.tiempoNormal;
    });
    const calculo = totalUnidades / totalTiempo;
    const promedioProduccion = formateaCantidad(calculo);
    let unid = formateaCantidad(totalUnidades);
    const tiempoFormateado = formatearTiempo(totalTiempo);
    return { tiempoFormateado, unid, promedioProduccion };
  };
  const columsDatatableDetalle: TableColumnsType<PropsDescripcionInventario> = [
    {
      title: "Usuarios",
      dataIndex: "nombresRol",
      key: "Nombres_Rol",
      //...getColumnSearchPropsNew("laboratorios"),
    },
    {
      title: "Tiempo",
      dataIndex: "tiempo",
      key: "tiempo",
    },
    {
      title: "Unidades",
      dataIndex: "totalCantidad",
      key: "totalCantidad",
      render: (_, record) => (
        <span style={{ color: record.totalCantidad === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.totalCantidad)}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Productividad",
      dataIndex: "produccion",
      key: "produccion",
      render: (_, record) => (
        <span style={{ color: record.produccion === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.produccion)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
  ];
  const columsDatatableDetalleHistorico: TableColumnsType<DatosInventariosAjustados> = [
    {
      title: "Laboratorio",
      dataIndex: "Laboratorio",
      key: "Laboratorio",
      className: "letraPequenaVisor2",
      filters: [...new Set(filteredDataHistorico.map(({ Laboratorio }) => Laboratorio))].map((Laboratorio) => ({
        text: Laboratorio,
        value: Laboratorio,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.Laboratorio,
      //...getColumnSearchPropsNew("laboratorios"),
    },
    {
      title: "Producto",
      dataIndex: "Producto",
      key: "Producto",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNewVisorReporteHistorial("Producto"),
    },
    {
      title: "Costo",
      dataIndex: "Costo",
      key: "Costo",
      align: "right",
      width: "100px",
      render: (_, record) => (
        <span style={{ color: record.Costo === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.Costo)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Unidades iniciales",
      dataIndex: "UnidadInventarioInicial",
      key: "UnidadInventarioInicial",
      className: "letraPequenaVisor2",
      align: "right",
      width: "120px",
    },
    {
      title: "Fracciones iniciales",
      dataIndex: "FraccionInventarioInicial",
      key: "FraccionInventarioInicial",
      className: "letraPequenaVisor2",
      align: "right",
      width: "120px",
    },
    {
      title: "Unidades auditadas",
      dataIndex: "UnidadAuditada",
      key: "UnidadAuditada",
      className: "letraPequenaVisor2",
      align: "right",
      width: "125px",
    },
    {
      title: "Fracciones auditadas",
      dataIndex: "FraccionesAuditadas",
      key: "FraccionesAuditadas",
      className: "letraPequenaVisor2",
      align: "right",
      width: "130px",
    },
    {
      title: "Nombre auditor",
      dataIndex: "Nombre",
      key: "Nombre",
      className: "letraPequenaVisor2",
      filters: [...new Set(filteredDataHistorico.map(({ Nombre }) => Nombre))].map((Nombre) => ({
        text: Nombre,
        value: Nombre,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.Nombre,
    },
  ];
  const handleClickDownloadData = () => {
    const datosParaExportar = detalle.map(({ key, ...resto }) => resto);
    exportToExcel(datosParaExportar, "productividadInventario");
  };
  const handleClickDownloadDataHistorico = () => {
    const datosParaExportar = filteredDataHistorico.map(({ key,Fraccion,DiferenciaReal, ...resto }) => resto);
    exportToExcel(datosParaExportar, "Reporte Inventario HandHeld");
  };

  return {
    columns,
    columsDatatableDetalle,
    isModalOpen,
    detalle,
    mes,
    setMes,
    filteredData,
    handleOk,
    handleCancel,
    handleCancelHistorico,
    isModalOpenHistorico,
    form,
    handleClickDownloadData,
    calcularTotales,
    columsDatatableDetalleHistorico,
    filteredDataHistorico,
    handleClickDownloadDataHistorico,
    setRowClassName
  };
}
