import { Col, Row, DatePicker, Table, Card } from "antd";
import "moment/locale/es";

import moment from "moment";
import { useControlCaducados } from "hooks/inventarios/useControlCaducados";

export function ControlCaducados() {
  const {
    columns,

    mes,
    setMes,
    filteredData,
    columsDatatableDetalleProductos
  } = useControlCaducados();
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={12} md={14} lg={16} xl={18}>
        <DatePicker
          value={mes}
          picker="month"
          placeholder="Seleccionar mes"
          format="MMMM [del] YYYY"
          onChange={(value) => setMes(value ?? moment())}
          inputReadOnly
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <Table
            columns={columns}
            dataSource={filteredData}
            scroll={{ x: true }}
            size="small"
            pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
            expandable={{
                expandedRowRender: (record) => {
                  return (
                    <>
                      <Table
                        style={{ width: "100%", margin: "10px" }}
                        columns={columsDatatableDetalleProductos}
                        dataSource={record.productos}
                        pagination={false}
                      />
                    </>
                  )
                },
              }}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default ControlCaducados;
