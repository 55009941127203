import AdministrarConvenio from "components/indicadores/AdministrarConvenio"
import { ContextConvenios } from "context/indicadores/CumplimientoConveniosContext"

export const CumplimientoConvenios = () => {
  return (
    <ContextConvenios>
      <AdministrarConvenio />
    </ContextConvenios>
  )
}
