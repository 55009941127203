import { LayoutContext } from "context/LayoutContext";
import { useContext, useEffect } from "react";

export function useSelectMenuItemSideBar(openKeys: string[], selectKey: string) {
  
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext)

  useEffect(() => {
    setOpenKeys(openKeys)
    setSelectedKeys([ selectKey ])
  }, [ setOpenKeys, setSelectedKeys, openKeys, selectKey ])

}