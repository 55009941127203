import {
  Avatar,
  Badge,
  CalendarProps,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  List,
  Pagination,
  Radio,
  Row,
  Space,
  Tag,
} from "antd";
import { Link } from "react-router-dom";
import { Facturas, NotaVenta, Recibos } from "components/svg/Reembolso";
import { ModalCustom } from "components/otros/ModalCustom";

import { useEffect, useState } from "react";
import { useVerTodos } from "hooks/reembolsos/useVerTodos";

import { llave } from "config/constants";

import AES from "crypto-js/aes";
import moment from "moment";

import { CalendarOutlined, FilterOutlined } from "@ant-design/icons";

//import style from "pages/reembolsogastos/VerTodos/VerTodos.module.css"
import style from "pages/contentreembolsogastos/contentvertodos/VerTodos.module.css";
import type { ModalProps } from "antd";
import type { Moment } from "moment";

const pageSize = 20;

export function VerTodos() {
  const {
    isWeb,
    actions,
    filteredData,
    selectedState,
    setSelectedState,
    selectedDates,
    setSelectedDates,
    actualPage,
    setActualPage,
  } = useVerTodos();

  const filteredDataSorce = filteredData();

  return (
    <>
      <Row className={style.RowFilters}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Collapse defaultActiveKey={isWeb ? ["1"] : undefined}>
            <Collapse.Panel
              header='Aplicar Filtros'
              key='1'
              extra={isWeb ? undefined : <FilterOutlined />}
            >
              {isWeb ? (
                <FiltersInWeb
                  selectedState={selectedState}
                  setSelectedState={setSelectedState}
                  selectedDates={selectedDates}
                  setSelectedDates={setSelectedDates}
                />
              ) : (
                <FiltersInMovil
                  selectedState={selectedState}
                  setSelectedState={setSelectedState}
                  selectedDates={selectedDates}
                  setSelectedDates={setSelectedDates}
                />
              )}
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
      <Row className={style.RowFilters} gutter={[0, 16]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <List
            size='small'
            bordered
            itemLayout={isWeb ? "horizontal" : "vertical"}
            style={{ backgroundColor: "#fff" }}
            dataSource={filteredDataSorce.slice(actualPage - 1, pageSize + actualPage - 1)}
            renderItem={({
              Cod_Reembolso,
              Fecha,
              Justificacion,
              numeroFacturas,
              numeroNotaVentas,
              numeroRecibos,
              Estado,
            }) => (
              <List.Item actions={actions(Estado, Cod_Reembolso)}>
                <List.Item.Meta
                  title={
                    <Link
                      key='list-loadmore-more'
                      to={`/ver-reembolso/${encodeURIComponent(
                        AES.encrypt(Cod_Reembolso.toString(), llave).toString()
                      )}`}
                    >
                      Reembolso del {Fecha}{" "}
                    </Link>
                  }
                  description={Justificacion}
                />
                <Space size={"large"}>
                  <Badge count={numeroFacturas} color='var(--tertiary)'>
                    <Avatar src={<Facturas fill='var(--primary)' />} />
                  </Badge>
                  <Badge count={numeroNotaVentas} color='var(--tertiary)'>
                    <Avatar src={<NotaVenta fill='var(--primary)' />} />
                  </Badge>
                  <Badge count={numeroRecibos} color='var(--tertiary)'>
                    <Avatar src={<Recibos fill='var(--primary)' />} />
                  </Badge>
                  {Estado === "PE" ? (
                    <Tag color='blue'>Pendiente</Tag>
                  ) : Estado === "AP" ? (
                    <Tag color='success'>Aprobado</Tag>
                  ) : (
                    <Tag color='error'>Rechazado</Tag>
                  )}
                </Space>
              </List.Item>
            )}
          />
        </Col>
        {filteredDataSorce.length > 20 && (
          <Col xs={24} sm={24} md={24} lg={24}>
            <Pagination
              current={actualPage}
              pageSize={pageSize}
              total={filteredDataSorce.length}
              style={{ float: "right" }}
              onChange={(page) => {
                setActualPage(page);
              }}
            />
          </Col>
        )}
      </Row>
    </>
  );
}

interface FilterProps {
  selectedState: "all" | "PE" | "AP" | "RE";
  setSelectedState: React.Dispatch<React.SetStateAction<"all" | "PE" | "AP" | "RE">>;
  setSelectedDates: React.Dispatch<React.SetStateAction<[string, string]>>;
  selectedDates: [string, string];
}

const FiltersInMovil = ({
  selectedState,
  setSelectedState,
  selectedDates,
  setSelectedDates,
}: FilterProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    try {
      form.setFieldValue("Fecha_Inicio", selectedDates[0]);
      form.setFieldValue("Fecha_Fin", selectedDates[1]);
    } catch (e) {}
  }, [selectedDates, form]);

  const calendarPropsFechaInicio: CalendarProps<Moment> = {
    fullscreen: false,
    onChange: (date) => {
      setSelectedDates((state) => [date.format("DD/MM/YYYY"), state[1]]);
    },
  };

  const calendarPropsFechaFin: CalendarProps<Moment> = {
    fullscreen: false,
    onChange: (date) => {
      setSelectedDates((state) => [state[0], date.format("DD/MM/YYYY")]);
    },
  };

  const [modalCalendarInicioProps, setModalCalendarInicioProps] = useState({
    open: false,
    onCancel: () => setModalCalendarInicioProps((state) => ({ ...state, open: false })),
  } as ModalProps);

  const [modalCalendarFinProps, setModalCalendarFinProps] = useState({
    open: false,
    onCancel: () => setModalCalendarFinProps((state) => ({ ...state, open: false })),
  } as ModalProps);

  return (
    <>
      <Form layout='vertical' form={form}>
        <Form.Item label='Por fecha'>
          <Space direction='vertical' style={{ width: "100%" }}>
            <Form.Item name='Fecha_Inicio' noStyle>
              <Input
                readOnly
                allowClear
                placeholder='Fecha de Inicio'
                suffix={<CalendarOutlined />}
                onClick={() => {
                  setModalCalendarInicioProps((state) => ({
                    ...state,
                    open: true,
                    onOk: () => {
                      setModalCalendarInicioProps((state) => ({ ...state, open: false }));
                    },
                  }));
                }}
                onChange={(e) => {
                  form.setFieldValue("Fecha_Inicio", e.target.value);
                  setSelectedDates((state) => [e.target.value, state[1]]);
                }}
              />
            </Form.Item>
            <Form.Item name='Fecha_Fin' noStyle>
              <Input
                readOnly
                allowClear
                placeholder='Fecha Fin'
                suffix={<CalendarOutlined />}
                onClick={() => {
                  setModalCalendarFinProps((state) => ({
                    ...state,
                    open: true,
                    onOk: () => setModalCalendarFinProps((state) => ({ ...state, open: false })),
                  }));
                }}
                onChange={(e) => {
                  form.setFieldValue("Fecha_Fin", e.target.value);
                  setSelectedDates((state) => [state[0], e.target.value]);
                }}
              />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label='Por Estado'>
          <Radio.Group
            size='large'
            buttonStyle='solid'
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
          >
            <Radio.Button value='all'>Todos</Radio.Button>
            <Radio.Button value='PE'>En Ejecución</Radio.Button>
            <Radio.Button value='AP'>Aprobados</Radio.Button>
            <Radio.Button value='RE'>Rechazados</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>

      <ModalCustom
        modalProps={modalCalendarInicioProps}
        elements={{ elementType: "calendar", elementProps: calendarPropsFechaInicio }}
      />
      <ModalCustom
        modalProps={modalCalendarFinProps}
        elements={{ elementType: "calendar", elementProps: calendarPropsFechaFin }}
      />
    </>
  );
};

const FiltersInWeb = ({
  selectedState,
  setSelectedState,
  selectedDates,
  setSelectedDates,
}: FilterProps) => (
  <Form layout='inline'>
    <Form.Item label='Por fecha'>
      <DatePicker.RangePicker
        format='DD/MM/YYYY'
        onCalendarChange={(_, dates) => setSelectedDates(dates)}
        value={
          selectedDates[0] !== "" && selectedDates[1] !== ""
            ? [moment(selectedDates[0], "DD/MM/YYYY"), moment(selectedDates[1], "DD/MM/YYYY")]
            : undefined
        }
      />
    </Form.Item>
    <Form.Item label='Por Estado'>
      <Radio.Group
        buttonStyle='solid'
        value={selectedState}
        onChange={(e) => setSelectedState(e.target.value)}
      >
        <Radio.Button value='all'>Todos</Radio.Button>
        <Radio.Button value='PE'>Pendientes</Radio.Button>
        <Radio.Button value='AP'>Aprobados</Radio.Button>
        <Radio.Button value='RE'>Rechazados</Radio.Button>
      </Radio.Group>
    </Form.Item>
  </Form>
)

export default VerTodos