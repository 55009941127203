import { Col, Row, Table, Button, Popconfirm } from "antd"
import { useRevisionLlamadosAtencion } from "hooks/rrhh/llamadosatencion/useRevisionLlamadosAtencion"

import { CheckOutlined, CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { FormInModal } from "components/rrhh/componentsllamadosatencion/FormInModal"

export function RevisionLlamadosAtencion() {
  const {
    llamadosAtencion,
    columnsTable,
    openModal,
    form,
    disabledPorcentajeMulta,
    setDisabledPorcentajeMulta,
    selectedIndex,
    handleCloseModal,
    handleRechazar,
    handleFinishForm,
  } = useRevisionLlamadosAtencion()

  return (
    <>
      <Row wrap style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            // size="small"
            // style={{ width: '2000px'}}
            scroll={{ x: true }}
            columns={columnsTable}
            dataSource={llamadosAtencion}
            // expandable={{
            //   expandedRowRender: record => <p style={{ margin: 0 }}>{record.observacion}</p>
            // }}
          />
        </Col>
      </Row>
      <FormInModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        form={form}
        handleFinishForm={handleFinishForm}
        llamadosAtencion={llamadosAtencion}
        selectedIndex={selectedIndex}
        disabledPorcentajeMulta={disabledPorcentajeMulta}
        setDisabledPorcentajeMulta={setDisabledPorcentajeMulta}
        modalFooter={[
          <Popconfirm
            key="rechazar"
            title="¿Rechazar solicitud de llamado de atención?"
            onConfirm={handleRechazar}
            icon={<QuestionCircleOutlined style={{ color: "var(--primary)" }} />}
            okText="Si"
            cancelText="No"
          >
            <Button icon={<CloseOutlined />} danger>
              Rechazar
            </Button>
          </Popconfirm>,
          <Popconfirm
            key="aprobar"
            title="Aprobar solicitud de llamado de atención?"
            onConfirm={() => form.submit()}
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            okText="Si"
            cancelText="No"
          >
            <Button icon={<CheckOutlined />} type="primary">
              Aprobar
            </Button>
          </Popconfirm>,
        ]}
        titleModal="Solicitud de llamado de atención"
      />
    </>
  )
}
