import { Alert } from "antd";

interface tipo {
  title: string;
}

const Parrafos = ({ title }: tipo) => {
  return <Alert style={{ margin: "10px" }} description={title} type='success' />;
};

export default Parrafos;
