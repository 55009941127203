import {
  Layout as AntdLayout,
  PageHeader,
  Dropdown,
  Menu,
  Button,
  Avatar,
  Typography,
  Breadcrumb,
  Row,
  Space,
  Drawer,
} from "antd";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { MdAccountCircle } from "react-icons/md";
import { FaBars } from "react-icons/fa";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import { LogoWhite } from "components/svg/LogoWhite";

import { ContextLayout } from "context/LayoutContext";
import { useAuth } from "hooks/otros/useAuth";
import { useContext, useEffect, useState } from "react";
import { useScreenSize } from "hooks/otros/useScreenSize";

import styles from "layouts/Layout.module.css";

import type { MenuProps } from "antd";
import { LeaveIcon } from "components/svg/Icons";
import type { menuSistema } from "types.d";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { ICONS } from "pages/contentprincipal/Principal";
import { UserContext } from "context/UserContext";
import { NoImage } from "components/svg/Menu";

const { Sider, Content } = AntdLayout;
const { Text, Title } = Typography;

// const itemsSider = [
//   { key: '1', label: 'Reembolso de gastos', children: [
//     { key: 's1', label: 'Ver Reembolsos', children: [
//       { key: 'ss1', label: <Link to={'/reembolsos/pendientes'}>Pendientes</Link> },
//       { key: 'ss2', label: <Link to={'/reembolsos/aprobados'}>Aprobados</Link> },
//       { key: 'ss3', label: <Link to={'/reembolsos/rechazados'}>Rechazados</Link> }
//     ]},
//     { key: 's2', label: <Link to={'/crear-reembolso'}>Crear Reembolso</Link> },
//     // { key: 's3', label: 'Rechazadas' },
//   ]}
// ]

const propsSvg = { fill: "#000", width: "15px", height: "15px" };

const renderItemsSider = (menu: menuSistema[]): ItemType[] => {
  return menu.map(({ key, label, enlace, children, nivel }) => ({
    key: key,
    label: enlace ? children ? label : <Link to={enlace}>{label}</Link> : label,
    children: children ? renderItemsSider(children) : undefined,
    icon: nivel === 0 && (ICONS[label as keyof typeof ICONS] ?? (() => <NoImage {...propsSvg} />))(propsSvg),
  }));
};

const getOpenedKeys = (openKeys: Array<string>, menu: menuSistema[]) => {
  // menus.forEach(menu => {
  if (menu[0].children) {
    openKeys.push(menu[0].key);
    getOpenedKeys(openKeys, menu[0].children);
  }
  // })
};

const getSelectedKey = (selectKey: string[], menu: menuSistema[]) => {
  // menus.forEach(menu => {
  if (menu[0].children) {
    getSelectedKey(selectKey, menu[0].children);
  } else {
    selectKey.push(menu[0].key);
  }
  // })
};

export function Layout() {
  const { userData: dataUser } = useContext(UserContext);

  const { logout } = useAuth();

  const navigate = useNavigate();

  const { width } = useScreenSize();

  const isWeb = width > 992;

  const [openKeys, setOpenKeys] = useState<Array<string>>([]);
  const [selectKeys, setSelectKeys] = useState<Array<string>>([]);
  const [showBack, setShowBack] = useState(false);

  const [breadCrumbs, setBreadCrumbs] = useState<{ Label: string | JSX.Element | JSX.Element[]; Url?: string }[]>([]);

  const [showDrawer, setShowDrawer] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (!dataUser) return;
    let openKeys: Array<string> = [];
    getOpenedKeys(openKeys, dataUser.menu);
    setOpenKeys((state) => (state ? state : openKeys));

    let selectKey: Array<string> = [];
    getSelectedKey(selectKey, dataUser.menu);
    setSelectKeys((state) => (state ? state : selectKey));
  }, [dataUser]);

  useEffect(() => {
    location.pathname === "/" ? setShowBack(false) : setShowBack(true);
    setShowDrawer(false);
  }, [location, breadCrumbs]);

  const items = [
    {
      key: "avatar",
      label: (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Avatar style={{ backgroundColor: "var(--primary)" }}>{dataUser?.iniciales}</Avatar>
        </div>
      ),
    },
    {
      key: dataUser?.usuario || 0,
      // icon: <Avatar style={{backgroundColor: 'var(--primary)'}}>{ dataUser?.data[0].iniciales }</Avatar>,
      label: (
        <div style={{ width: "100%", textAlign: "center" }}>
          {/* <Avatar style={{ backgroundColor: 'var(--primary)' }}>{ dataUser?.datosUsuario.iniciales }</Avatar> */}
          <Text style={{ textAlign: "center" }}>{dataUser?.nombre}</Text>
        </div>
      ),
    },
    {
      key: "Cerrar Sesion",
      label: (
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          <Text>Cerrar Sesión</Text>
          <LeaveIcon width='1.5em' fill='var(--primary)' style={{ float: "right" }} />
        </Space>
      ),
      onClick: () => {
        localStorage.removeItem("token");
        // localStorage.removeItem("G" + dataUser?.id);
        navigate("/login");
        logout();
      },
    },
  ];

  const handleOpenChange: MenuProps["onOpenChange"] = (keys) => setOpenKeys(keys);

  // const handleSelect: MenuProps['onSelect'] = keys => {
  //   setSelectKeys(keys.selectedKeys)
  // }

  return (
    <>
      <PageHeader
        onBack={() => navigate(-1)}
        backIcon={showBack && <ArrowLeftOutlined />}
        // title={ <Image loading='lazy' preview={false} width={'65%'} src={'/imagen/logoFSGWhite.svg'} alt='logo de farmacias san gregorio' style={{userSelect: 'none'}} /> }
        title={<LogoWhite width='100%' style={{ padding: "0", margin: "0" }} />}
        style={{ position: "fixed", width: "100%", zIndex: "1500" }}
        ghost={false}
        extra={
          isWeb ? (
            <Dropdown key='more' menu={{ items, style: { width: "200px" } }} placement='bottomRight'>
              <Space>
                <Button type='text' icon={<MdAccountCircle className={styles.iconUser} />} />
                <Text style={{ color: "#fff" }}>{dataUser?.usuario}</Text>
              </Space>
            </Dropdown>
          ) : (
            <>
              <Button
                icon={<FaBars style={{ color: "var(--primary)" }} />}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                onClick={() => setShowDrawer(true)}
              />
              <Drawer
                open={showDrawer}
                placement='left'
                onClose={() => setShowDrawer(false)}
                // closable={false}
                // size='large'
                closeIcon={<></>}
                extra={<Button icon={<CloseOutlined />} onClick={() => setShowDrawer(false)} />}
                footer={
                  <Button
                    type='text'
                    style={{ width: "100%", display: "flex", justifyContent: "space-between" }}
                    onClick={() => {
                      localStorage.removeItem("token");
                      navigate("/login");
                      logout();
                    }}
                  >
                    <Title level={5}>Cerrar Sesión</Title>
                    <LeaveIcon width='1.5em' fill='var(--primary)' />
                  </Button>
                }
              >
                {/* <Menu items={ items }/> */}
                <Space direction='vertical' style={{ width: "100%", textAlign: "center" }}>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <Avatar style={{ backgroundColor: "var(--primary)" }}>{dataUser?.iniciales}</Avatar>
                  </div>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <Title level={5}>{dataUser?.nombre}</Title>
                  </div>
                  <Menu
                    mode='inline'
                    selectedKeys={selectKeys}
                    openKeys={openKeys}
                    // className={ styles.menuSider }
                    items={dataUser ? renderItemsSider(dataUser.menu) : undefined}
                    onOpenChange={handleOpenChange}
                    // onSelect={ handleSelect }
                  />
                </Space>
              </Drawer>
            </>
          )
        }
      />
      <AntdLayout>
        {isWeb && (
          <Sider breakpoint='lg' collapsedWidth={1} className={styles.sider} theme='light'>
            <Menu
              mode='inline'
              selectedKeys={selectKeys}
              openKeys={openKeys}
              className={styles.menuSider}
              items={dataUser ? renderItemsSider(dataUser.menu) : undefined}
              onOpenChange={handleOpenChange}
              // onSelect={ handleSelect }
            />
          </Sider>
        )}
        <AntdLayout className={styles.ContentContentPage}>
          <Content className={styles.ContentPage}>
            <Row style={{ backgroundColor: "#fff" }}>
              <Breadcrumb className={styles.breadcrumbScroll}>
                {breadCrumbs.map(({ Label, Url }, index) => {
                  return Url ? (
                    <Breadcrumb.Item key={index}>
                      <Link to={Url ?? "/"}>{Label}</Link>
                    </Breadcrumb.Item>
                  ) : (
                    <Breadcrumb.Item key={index}>{Label}</Breadcrumb.Item>
                  );
                })}
              </Breadcrumb>
            </Row>
            <ContextLayout
              setOpenKeys={setOpenKeys}
              setSelectedKeys={setSelectKeys}
              setShowBack={setShowBack}
              setBreadCrumbs={setBreadCrumbs}
            >
              <Outlet />
            </ContextLayout>
          </Content>
        </AntdLayout>
      </AntdLayout>
    </>
  );
}
