// import { useState } from 'react'

import { url } from "config/constants"

import { datosUsuario } from "types"

// import { mutate } from "swr";
// import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
// import { useVerifyResponse } from "./useVerifyReponse";
import { useCallback, useContext } from "react"
import { UserContext } from "context/UserContext"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"

export interface userData {
  nombre_usuario: string
  clave: string
}

// const initialState = {
//   tieneAcceso: -1,
//   idSistema: 1,
//   datosUsuario: []
// }

// const wasInvalidToken = ({ msg, mensaje }: any): boolean => {
//   let text = ''
//   if(msg === 'err') {
//     if(mensaje === 'INVALID_TOKEN_AC') text = 'Sin token o token inválido'
//     if(mensaje === 'INVALID_TOKEN_AC_VERIFY') text = 'Token inválido'
//     if(mensaje === 'INVALID_TOKEN_AC_ERR') text = 'Error al verificar el token'

//     if (text !== '') {
//       // swalError({ text })
//       console.error(text)
//       localStorage.removeItem('token')
//       redirect('/login')
//       return true
//     }
//     return false
//   }
//   return false
// }

export function useAuth() {
  // PROVEDOR
  // const [state, setState] = useState(initialState);
  const { setUserData } = useContext(UserContext)
  // const navigate = useNavigate()

  // const { wasInvalidToken, is } = useVerifyResponse();
  const { isError } = useVerifyResponse()

  const login = async (datos: userData) => {
    try {
      const respuesta = await fetch(`${url}atencion_cliente/autenticar-usuario`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datos),
      }).then(res => res.json())
      if (respuesta.msg === "ok") {
        const { token } = respuesta
        return { token, status: true }
      } else {
        return { msg: "datos_erroneos", status: false }
      }
    } catch (e) {
      // console.log(e)
      return { msg: "no_conexion", status: false }
    }
  }

  const logout = () => {
    // localStorage.removeItem("token")
    // redirect("/")
    setUserData(undefined)
  }

  const obtenerDatosUsuario = useCallback(async () => {
    let token = localStorage.getItem("token")
    // if (!token) {
    //   redirect("/login");
    //   return;
    // }
    try {
      const res = await fetch(`${url}atencion_cliente/datos-usuarios`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "access-token": token ?? "",
        },
      })

      if (isError(res)) {
        if (res.status === 401) throw new Error("Sesión expirada. Vuelva a iniciar sesión")
        throw new Error("No se pudo obtener los datos del usuario")
      }
      // console.log('si pasa el was invalid token')
      const datosUsuario: datosUsuario = await res.json()
      
      // let usuario = {...datosUsuario.data[0], sistemas: datosUsuario.sistemas, permisos: datosUsuario.permisos}
      // let sistema = Object.keys(usuario.sistemas).length > 0 ? usuario.sistemas[0].id_sistema : 0
      // let controlAcceso = Object.keys(usuario.permisos).length > 0 ? usuario.permisos[0].id_permiso : 0

      // const opcionesPermisos = datosUsuario.permisos.filter((permiso)=>permiso.sistema_permiso.includes(sistema))

      return datosUsuario
    } catch (e: any) {
      Swal.fire({
        title: "Error",
        text: e.message,
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      })
      return undefined
    }
  }, [isError])

  return { login, logout, obtenerDatosUsuario }
}
