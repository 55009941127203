import {
  Col,
  Form,
  Input,
  InputNumber,
  type InputRef,
  Row,
  Table,
  type TableColumnsType,
  type InputNumberProps,
  Upload,
  Button,
  type UploadFile,
  type UploadProps,
} from "antd"
import { useLayout } from "hooks/otros/useLayout"
import moment from "moment"
import { Suspense, useEffect, useRef, useState } from "react"
import { Await, Outlet, useAsyncValue, useLoaderData, useNavigate, useParams } from "react-router-dom"

import style from "pages/indicadores/proyecciones-bodega.module.css"
import { optionsPost, round2, swalError, swalQuestion, swalSuccess } from "config/methods"
import { useSearchTableNew } from "hooks/otros/useSearchTableNew"
import { ButtonExcel, ButtonSave } from "components/otros/buttons"
import { UploadOutlined } from "@ant-design/icons"
import { useExport } from "hooks/otros/useExport"
import * as XLSX from "xlsx"
import { Loading } from "components/otros/Loading"
import { url } from "config/constants"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"

const breadcrumbs = [{ Label: "Indicadores", Url: "/indicadores" }, { Label: "Proyecciones bodega" }]

const openKeys = [breadcrumbs[0].Label]

const selectKey = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`

export function ProyeccionesBodega() {
  useLayout(breadcrumbs, openKeys, selectKey)

  const navigate = useNavigate()

  const { periodo } = useParams()

  const [selectedPeriodo, setSelectedPeriodo] = useState<number>(
    isNaN(Number(periodo ?? "")) ? parseInt(moment().format("YYYYMM")) : Number(periodo)
  )

  useEffect(() => {
    if (periodo) return
    setSelectedPeriodo(parseInt(moment().format("YYYYMM")))
    navigate(`/indicadores/proyecciones-bodega/${moment().format("YYYYMM")}`)
  }, [periodo, navigate])

  const handleChangePeriodo: InputNumberProps["onChange"] = value => {
    if (!value) return
    if (value.toString().length !== 6) return
    if (value.toString().substring(4, 6) === "13") return
    if (value.toString().substring(4, 6) === "00") return
    setSelectedPeriodo(value as number)
    navigate(`/indicadores/proyecciones-bodega/${value}`)
  }

  const handleStepPeriodo: InputNumberProps["onStep"] = value => {
    if (value.toString().length !== 6) return
    const year = value.toString().substring(0, 4)
    const month = value.toString().substring(4, 6)
    if (month === "13") {
      setSelectedPeriodo(parseInt((parseInt(year) + 1).toString() + "01"))
      navigate(`/indicadores/proyecciones-bodega/${(parseInt(year) + 1).toString() + "01"}`)
    }
    if (month === "00") {
      setSelectedPeriodo(parseInt((parseInt(year) - 1).toString() + "12"))
      navigate(`/indicadores/proyecciones-bodega/${(parseInt(year) - 1).toString() + "12"}`)
    }
  }

  return (
    <Row
      wrap
      style={{ padding: "16px", marginRight: "0px", marginBottom: 0, marginLeft: 0, backgroundColor: "#fff" }}
      gutter={[16, 16]}
    >
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Form style={{ padding: 0 }}>
          <Form.Item label="Periodo">
            <InputNumber value={selectedPeriodo} onChange={handleChangePeriodo} onStep={handleStepPeriodo} />
          </Form.Item>
        </Form>
      </Col>
      <Outlet />
    </Row>
  )
}

export function MiddleProjection() {
  const datos = useLoaderData()

  return (
    <Suspense fallback={<Loading />}>
      <Await resolve={datos}>
        <ProjectionTable />
      </Await>
    </Suspense>
  )
}

type Proyeccion = {
  key: number
  bodega: string
  mes_año_anterior: number
  tercer_mes_anterior: number
  segundo_mes_anterior: number
  primer_mes_anterior: number
  primer_periodo_anterior: string
  segundo_periodo_anterior: string
  tercer_periodo_anterior: string
  real: number
  proyeccion: number
  resta: number
  cuota?: number
}

function ProjectionTable() {
  const { periodo } = useParams()

  const [cuotaEdit, setCuotaEdit] = useState<number>()

  const [fileCuotas, setFileCuotas] = useState<UploadFile>()

  const inputRef = useRef<InputRef>(null)

  const dataTable = useAsyncValue() as Proyeccion[]

  const [datosTabla, setDatosTabla] = useState<Proyeccion[]>(dataTable)

  const { exportToExcel } = useExport()

  const { isError } = useVerifyResponse()

  const { getColumnSearchPropsNew } = useSearchTableNew<Proyeccion>()

  const handleCuotaEdit = (cuota: number) => {
    setCuotaEdit(cuota)
  }

  useEffect(() => {
    setDatosTabla(dataTable)
  }, [dataTable])

  useEffect(() => {
    if (!fileCuotas) return

    const reader = new FileReader()
    reader.onload = e => {
      const data = e.target?.result
      const workbook = XLSX.read(data, { type: "binary" })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      const codigoIndex = (sheetData[0] as string[]).findIndex(c => c.toLowerCase() === "codigo")
      const cuotaIndex = (sheetData[0] as string[]).findIndex(c => c.toLowerCase() === "cuota")
      setDatosTabla(state => {
        return state.map(({ key, cuota, ...s }) => {
          const index = sheetData.findIndex(d => (d as number[])[codigoIndex] === key)
          return {
            key,
            cuota: (sheetData[index] as number[])[cuotaIndex],
            ...s,
          }
        })
      })
    }
    reader.readAsBinaryString(fileCuotas as unknown as Blob)
  }, [fileCuotas])

  const handleChangeCuota = (cuota: number, valor: number) => {
    if (!valor) setDatosTabla(state => state.map(data => (data.key === cuota ? { ...data, cuota: undefined } : data)))
    if (isNaN(valor)) return
    setDatosTabla(state => state.map(data => (data.key === cuota ? { ...data, cuota: valor } : data)))
  }

  useEffect(() => {
    if (!cuotaEdit) return
    inputRef.current!.focus()
  }, [cuotaEdit])

  const columns: TableColumnsType<Proyeccion> = [
    {
      title: "Bodega",
      dataIndex: "bodega",
      key: "bodega",
      filters: datosTabla.map(({ bodega }) => ({ text: bodega, value: bodega })),
      filterSearch: true,
    },
    {
      title: moment(periodo + "01", "YYYYMMDD")
        .subtract(1, "years")
        .format("MMMM [del] YYYY"),
      dataIndex: "mes_año_anterior",
      key: "mes_año_anterior",
      align: "right",
      ...getColumnSearchPropsNew("mes_año_anterior"),
      render: value => round2(value ?? 0),
    },
    {
      title: moment(datosTabla[0].tercer_periodo_anterior + "01", "YYYYMMDD").format("MMMM [del] YYYY"),
      dataIndex: "tercer_mes_anterior",
      key: "tercer_mes_anterior",
      align: "right",
      ...getColumnSearchPropsNew("tercer_mes_anterior"),
      render: value => round2(value ?? 0),
    },
    {
      title: moment(datosTabla[0].segundo_periodo_anterior + "01", "YYYYMMDD").format("MMMM [del] YYYY"),
      dataIndex: "segundo_mes_anterior",
      key: "segundo_mes_anterior",
      align: "right",
      ...getColumnSearchPropsNew("segundo_mes_anterior"),
      render: value => round2(value ?? 0),
    },
    {
      title: moment(datosTabla[0].primer_periodo_anterior + "01", "YYYYMMDD").format("MMMM [del] YYYY"),
      dataIndex: "primer_mes_anterior",
      key: "primer_mes_anterior",
      align: "right",
      ...getColumnSearchPropsNew("primer_mes_anterior"),
      render: value => round2(value ?? 0),
    },
    {
      title: "Proyección",
      dataIndex: "proyeccion",
      key: "proyeccion",
      align: "right",
      ...getColumnSearchPropsNew("proyeccion"),
      render: value => round2(value ?? 0),
    },
    {
      title: "Real",
      dataIndex: "real",
      key: "real",
      align: "right",
      ...getColumnSearchPropsNew("real"),
      render: value => round2(value ?? 0),
    },
    {
      title: "MSE",
      dataIndex: "resta",
      key: "resta",
      align: "right",
      ...getColumnSearchPropsNew("resta"),
      render: value => round2(value ?? 0),
    },
    {
      title: "Cuota",
      dataIndex: "cuota",
      key: "cuota",
      align: "right",
      ...getColumnSearchPropsNew("cuota"),
      render: (value, { key }) =>
        cuotaEdit && cuotaEdit === key ? (
          <Input
            ref={inputRef}
            onPressEnter={() => setCuotaEdit(undefined)}
            onBlur={() => setCuotaEdit(undefined)}
            onChange={value => handleChangeCuota(key, parseFloat(value.target.value))}
            value={value}
          />
        ) : (
          <div
            className={style["editable-cell-value-wrap"]}
            style={{ paddingRight: 24 }}
            onClick={() => handleCuotaEdit(key)}
          >
            {round2(value ?? 0)}
          </div>
        ),
    },
  ]

  const handleClickExport = () => {
    const datos = datosTabla.map(dato => ({
      Codigo: dato.key,
      Farmacia: dato.bodega,
      [moment(periodo + "01", "YYYYMMDD")
        .subtract(1, "years")
        .format("MMMM [del] YYYY")]: dato.mes_año_anterior,
      [moment(periodo + "01", "YYYYMMDD")
        .subtract(3, "months")
        .format("MMMM [del] YYYY")]: dato.tercer_mes_anterior,
      [moment(periodo + "01", "YYYYMMDD")
        .subtract(2, "months")
        .format("MMMM [del] YYYY")]: dato.segundo_mes_anterior,
      [moment(periodo + "01", "YYYYMMDD")
        .subtract(1, "months")
        .format("MMMM [del] YYYY")]: dato.primer_mes_anterior,
      Proyeccion: dato.proyeccion,
      Real: dato.real,
      MSE: dato.resta,
      Cuota: dato.cuota,
    }))
    exportToExcel(datos, `Proyeccion de bodegas del periodo ${periodo}`)
  }

  const handleBeforeUpload: UploadProps["beforeUpload"] = file => {
    setFileCuotas(file)
    return Upload.LIST_IGNORE
  }

  const handleGuardarCuotas = async () => {
    if (dataTable.some(({ cuota }) => (cuota ?? 0) > 0)) {
      const result = await swalQuestion({ title: "Ya existe una cuota almacenada", text: "¿Desea reemplazarla?" })
      if (!result.isConfirmed) return
    }
    try {
      const cuotas = datosTabla.map(({ key, cuota }) => ({ cod_bodega: key, cuota }))
      const res = await fetch(`${url}fapifsg-pr/reportes/guardar-cuota`, optionsPost({ cuotas, periodo }))
      if (isError(res)) throw new Error()
      const text = await res.json()
      swalSuccess({ text })
    } catch (e) {
      swalError({ text: "No se pudo guardar las cuotas" })
    }
  }

  return (
    <>
      <Col xs={12} sm={12} md={12} lg={12} xl={4}>
        <ButtonExcel buttonProps={{ onClick: handleClickExport }}>Exportar</ButtonExcel>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={4}>
        <Upload name="file" beforeUpload={handleBeforeUpload} maxCount={1}>
          <Button block icon={<UploadOutlined />} className="iconbutton">
            Cargar cuotas
          </Button>
        </Upload>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={4}>
        <ButtonSave buttonProps={{ onClick: handleGuardarCuotas }}>Guardar cuotas</ButtonSave>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Table
          columns={columns}
          dataSource={datosTabla}
          rowClassName={() => style["editable-row"]}
          scroll={{ x: true }}
          pagination={false}
          summary={pageData => (
            <Table.Summary fixed>
              <Table.Summary.Row style={{ backgroundColor: "#fafafa" }}>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="right">
                  {round2(pageData.reduce((acum, { mes_año_anterior }) => acum + (mes_año_anterior ?? 0), 0))}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="right">
                  {round2(pageData.reduce((acum, { tercer_mes_anterior }) => acum + (tercer_mes_anterior ?? 0), 0))}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="right">
                  {round2(pageData.reduce((acum, { segundo_mes_anterior }) => acum + (segundo_mes_anterior ?? 0), 0))}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="right">
                  {round2(pageData.reduce((acum, { primer_mes_anterior }) => acum + (primer_mes_anterior ?? 0), 0))}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} align="right">
                  {round2(pageData.reduce((acum, { proyeccion }) => acum + (proyeccion ?? 0), 0))}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} align="right">
                  {round2(pageData.reduce((acum, { real }) => acum + (real ?? 0), 0))}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} align="right">
                  {round2(pageData.reduce((acum, { resta }) => acum + (resta ?? 0), 0))}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} align="right">
                  {round2(pageData.reduce((acum, { cuota }) => acum + (cuota ?? 0), 0))}
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row style={{ backgroundColor: "#fafafa" }}>
                <Table.Summary.Cell index={0}>MSE</Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="right">
                  {round2(pageData.reduce((acum, { resta }) => acum + (resta ?? 0), 0) / pageData.length)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={6}></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </Col>
    </>
  )
}
