import { Col, Row, DatePicker, Table, Card, Select, Button, Spin } from "antd";
import "moment/locale/es";

import { useVT } from "virtualizedtableforantd4";
import { useInconsistenciasBodegas } from "hooks/inventarios/reportes/useInconsistenciasBodegas";
import { ButtonExcel } from "components/otros/buttons";

export function InconsistenciasBodegas() {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);
  const {
    columns,
    setFechas,
    filteredData,
    datosPeriodos,
    setBodega,
    estadoBotones,
    bodega,
    dataEstado,
    handleChangeButton,
    disabledDate,
    fechaInicio,
    fechaFin,
    handleClickDownloadData,
    calcularTotales
  } = useInconsistenciasBodegas();
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={12} md={14} lg={16} xl={18}>
        <Select
          placeholder="Selecione una bodega"
          style={{ width: 250, marginRight: "10px", marginTop: "0px" }}
          onChange={(value) => setBodega(value)}
          options={datosPeriodos}
        />
        <DatePicker.RangePicker
          onChange={(value) => {
            if (value && value.length === 2 && value[0] && value[1]) {
              setFechas([value[0], value[1]]);
            }
          }}
          disabledDate={disabledDate}
        />
        <Button
          type="primary"
          style={{ marginLeft: "5px" }}
          //icon={<PoweroffOutlined />}
          loading={estadoBotones}
          disabled={bodega === undefined || fechaInicio === null || fechaFin === null}
          onClick={handleChangeButton}
        >
          Generar reporte
        </Button>
        
      </Col>
      <Col xs={24} sm={12} md={10} lg={8} xl={6}>
        <Row gutter={[10, 10]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
        {dataEstado && <ButtonExcel buttonProps={{ onClick: handleClickDownloadData }}></ButtonExcel>}
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          {!dataEstado && estadoBotones && (
            <div style={{ textAlign: "center" }}>
              <div style={{ margin: "0 auto" }}>
                <Spin />
              </div>
            </div>
          )}
          {dataEstado && (
            <Table
              columns={columns}
              dataSource={filteredData}
              components={vt}
              size="small"
              scroll={{ x: 900, y: "50vh" }}
              pagination={false}
              summary={() => {
                const {  valor } = calcularTotales();
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row style={{ backgroundColor: "#f0f0f0" }}>
                      <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}></Table.Summary.Cell>
                      <Table.Summary.Cell align="right" index={5}>Total</Table.Summary.Cell>
                      <Table.Summary.Cell align="right" index={6}>${`${valor}`}</Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
              //pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default InconsistenciasBodegas;
