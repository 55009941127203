import { Col, Row, Card, DatePicker, Table, Modal } from "antd";
import "moment/locale/es";
import moment from "moment";
import "./styles.css"; // Importa tus estilos
import { useVT } from "virtualizedtableforantd4";
import { useRankingSupervisores } from "hooks/indicadores/useRankingSupervisores";

export function RankingSupervisores() {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);

  const {
    columns,
    columsDataDependiente,
    columsDataProductos,
    filteredData,
    mes,
    setMes,
    isRowExpandable,
    getRowClassName,
    datakey,
    isModalOpen,
    calcularTotales,
    handleOk,
    handleCancel,
  } = useRankingSupervisores();
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <DatePicker
          value={mes}
          picker="month"
          placeholder="Seleccionar mes"
          format="MMMM [del] YYYY"
          onChange={(value) => setMes(value ?? moment())}
          inputReadOnly
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <Table
            columns={columns}
            
            dataSource={filteredData ? filteredData : []}
            scroll={{ x: 900, y: 450 }}
            size="small"
            pagination={false}
            rowClassName={getRowClassName}
            bordered
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <Table
                    style={{ width: "100%", margin: "10px" }}
                    columns={columsDataDependiente}
                    dataSource={record.datas}
                    pagination={false}
                  />
                );
              },
              rowExpandable: isRowExpandable,
            }}
          />
        </Card>
      </Col>
      <Modal
        footer={false}
        transitionName=""
        title="Ventas por cajero"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"90wh"}
      >
        <Table
          style={{ marginBottom: "10px" }}
          pagination={false}
          //loading={columsDatatableDetalle ? false : true}
          scroll={{ x: 900, y: "55vh" }}
          size="small"
          bordered
          components={vt}
          columns={columsDataProductos}
          dataSource={datakey}
          summary={() => {
            const { facturas, total } = calcularTotales();
            return (
              <Table.Summary fixed>
                <Table.Summary.Row style={{ backgroundColor: "#f0f0f0" }}>
                  <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>{facturas}</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>{total}</Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Modal>
    </Row>
  );
  
}

export default RankingSupervisores;
