import { Row, Col, Card, Button, Typography, Badge, Avatar } from "antd"
import { MdAdd } from "react-icons/md"

import type { BadgeProps, AvatarProps, CardProps, ColProps, ButtonProps } from "antd"

const { Text, Paragraph } = Typography

export enum TypeCard {
  Normal,
  New,
  Loading,
  OnlyButton,
}

interface ContentCard {
  type?: "Text" | "Paragraph" | "Avatar"
  span?: number
  badgeProps?: BadgeProps
  avatarProps?: AvatarProps
  text?: string
}

interface Props {
  cardType: TypeCard
  ContentCard?: ContentCard[]
  cardProps?: CardProps
  // data?: dataEstados
  // actions?: Array<React.ReactNode>
  handleClick?: () => void
  colProps?: ColProps
  buttonProps?: ButtonProps
  children?: JSX.Element | JSX.Element[]
}

export function ColumCard({
  cardType,
  ContentCard,
  cardProps,
  handleClick,
  colProps,
  buttonProps,
  children,
}: Props): JSX.Element {
  if (cardType === TypeCard.New) {
    return (
      <Col {...colProps}>
        <Card
          style={{
            backgroundColor: "var(--gray)",
            cursor: "pointer",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleClick}
        >
          <Button
            shape="circle"
            type="primary"
            icon={<MdAdd style={{ fontSize: "2rem" }} />}
            style={{ width: "75px", height: "75px", display: "flex", alignItems: "center", justifyContent: "center" }}
            // onClick={ handleClick }
          />
        </Card>
      </Col>
    )
  }

  if (cardType === TypeCard.OnlyButton) {
    return (
      <Col {...colProps}>
        <Card onClick={handleClick} {...cardProps}>
          {/* <Button { ...buttonProps } /> */}
          {children}
        </Card>
      </Col>
    )
  }

  if (cardType === TypeCard.Loading && ContentCard?.length === 1) {
    return (
      <Col {...colProps}>
        {/* <Card loading={true} actions={actions}> */}
        <Card {...cardProps} loading={true}>
          <p>
            Lorem ipsum dolor sit amet.
            <br />
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sit, ut!
            <br />
            Lorem, ipsum. <br />
            Lorem, ipsum.
          </p>
        </Card>
      </Col>
    )
  }

  if (cardType === TypeCard.Normal && ContentCard && ContentCard?.length > 0) {
    return (
      <Col {...colProps}>
        {/* <Card
          title={`Reembolso #${data.Cod_Reembolso}`}
          actions={actions}
          size='small'
        > */}

        <Card {...cardProps}>
          <Row>
            {ContentCard.map(({ type, span, text, badgeProps, avatarProps }, index) => {
              if (type === "Text")
                return (
                  <Col key={index} span={span}>
                    <Text strong>{text}</Text>
                  </Col>
                )
              if (type === "Paragraph")
                return (
                  <Col key={index} span={span}>
                    <Paragraph ellipsis={{ tooltip: text }}>{text}</Paragraph>
                  </Col>
                )
              if (type === "Avatar")
                return (
                  <Col
                    key={index}
                    span={span}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "1rem",
                      cursor: "pointer",
                      borderLeft: "1px solid var(--background)",
                    }}
                  >
                    <Badge {...badgeProps}>
                      <Avatar {...avatarProps} />
                    </Badge>
                    {text && <small style={{ width: "100%", textAlign: "center" }}>{text}</small>}
                  </Col>
                )
              return <></>
            })}
            {/* <Col span={9}><Text strong>Fecha:</Text></Col>
            <Col span={15}><Paragraph>{ data.Fecha.toString() }</Paragraph></Col>
            <Col span={9}><Text strong>Justificación:</Text></Col>
            <Col span={15}><Paragraph ellipsis={{tooltip: data.Justificacion }}>{ data.Justificacion }</Paragraph></Col>
            {/* <Col span={9}><Text strong>Facturas:</Text></Col> *}
            <Col span={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: '1rem', cursor: 'pointer', borderRight: '1px solid var(--background)'}}>
              <Badge count={data.numeroFacturas} showZero color={'var(--tertiary)'}>
                <Avatar src={<Facturas fill='var(--primary)'/>} />
              </Badge>
              <small>Facturas</small>
            </Col>
            {/* <Col span={2}></Col> *}
            <Col span={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: '1rem', cursor: 'pointer', borderLeft: '1px solid var(--background)'}}>
              <Badge count={data.numeroRecibos} showZero color={'var(--tertiary)'}>
                <Avatar src={<Recibos fill='var(--primary)' />} />
              </Badge>
              <small>Recibos</small>
            </Col> */}
          </Row>
            <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{ children }</Col>
        </Card>
        
      </Col>
    )
  }

  return <></>
}
