import { Form, RadioChangeEvent } from "antd";
import { url } from "config/constants";
import { swalError } from "config/methods";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useExport } from "hooks/otros/useExport";
import { useLayout } from "hooks/otros/useLayout";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import { useEffect, useCallback, useState } from "react";
import Swal from "sweetalert2";

export interface dataSelect {
  value: number;
  label: string;
}

interface DataTransfer {
  key: string;
  label: string;
  descripcion?: String;
}
interface PropsDatosBodegasAsignadasArray {
  Cod_Bodega: number;
  Descripcion: string;
  Establecimiento: string;
}
interface PropsDatosBodegasAll {
  Cod_Bodega: number;
  Descripcion: string;
  Establecimiento: string;
}

interface PropsDatosBodegasSupervisoresAsignados {
  key: React.Key;
  Cod_Usuario: number;
  Nombres_Rol: string;
}
interface PropsDatosBodegasSupervisoresLibres {
  key: React.Key;
  Cod_Usuario: number;
  Nombres_Rol: string;
}
type DatosSupervidores = {
  key: React.Key;
  Nombres: string;
  value: number;
  label: string;
  todasBodegas: PropsDatosBodegasAll[];
  bodegasAsignadasArray: PropsDatosBodegasAsignadasArray[];
};
export interface DatosBodegasSupervidores {
  key: React.Key;
  value: number;
  label: string;
  Descripcion: string;
  supervisorAsignado: PropsDatosBodegasSupervisoresAsignados[];
  supervisoresNoAsignados: PropsDatosBodegasSupervisoresLibres[];
}
const breadcrumbs = [{ Label: "Recursos Humanos", Url: "/asignacion-supervisores" }, { Label: "Asignación supervisores" }];
const openKeys = [breadcrumbs[0].Label];
export const useAsignacionSupervisores = () => {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);

  const [form] = Form.useForm();
  const [dataTransfer, setDataTransfer] = useState<DataTransfer[]>([]);
  const { exportToExcel } = useExport();
  const [productsKeys, setProductsKeys] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [valueselect, setvalueselect] = useState("1");

  const [datos, setDatos] = useState<DatosSupervidores[]>([]);

  const [datosBodegas, setDatosBodegas] = useState<DatosBodegasSupervidores[]>([]);

  let { optionsPost } = useLocalStorage();

  const { isError } = useVerifyResponse();

  useBreadCrumbs(breadcrumbs);

  const handleChange = (targetKeys: string[]) => {
    if (valueselect === "2" && targetKeys.length <= 1) {
      setProductsKeys(targetKeys);
    } else if (valueselect === "1") {
      setProductsKeys(targetKeys);
    }
  };
  const handleChangeSelect1 = ({ target: { value } }: RadioChangeEvent) => {
    setDataTransfer([]);
    setDatosBodegas([]);
    setDatos([]);
    setvalueselect(value);
    form.setFieldsValue({ supervisor: null });
    form.setFieldsValue({ farmacias: null });
  };

  const handleChangeFarmaciaSupervisor = useCallback(
    (value: number) => {
      //setLoading(true);
      const supervisorSeleccionado = datos.find((supervisor) => supervisor.value === value);
      if (supervisorSeleccionado && supervisorSeleccionado.todasBodegas) {
        // Transforma la información de las bodegas asignadas para que se ajuste al formato esperado por el TransferComponent
        const bodegasParaTransfer = supervisorSeleccionado.todasBodegas.map((bodega) => ({
          key: bodega.Cod_Bodega.toString(), // Asegúrate de que la key sea un string, si es necesario
          label: bodega.Descripcion,
          descripcion: bodega.Descripcion, // O cualquier otro dato que necesites mostrar
        }));
        let data: string[] = [];
        supervisorSeleccionado.bodegasAsignadasArray.forEach((element) => {
          data.push(element.Cod_Bodega.toString());
        });
        setDataTransfer(bodegasParaTransfer);

        setProductsKeys(data);
      } else {
        setDataTransfer([]);
      }

      //setLoading(false);
    },
    [datos, setDataTransfer]
  );

  const handleChangeFarmacia = useCallback(
    (value: number) => {
      const farmaciaSeleccionada = datosBodegas.find((bodega) => bodega.value === value);
      if (farmaciaSeleccionada) {
        // Transforma la información de las bodegas asignadas para que se ajuste al formato esperado por el TransferComponent
        const bodegasParaTransfer = farmaciaSeleccionada.supervisoresNoAsignados.map((supervi) => ({
          key: supervi.Cod_Usuario.toString(), // Asegúrate de que la key sea un string, si es necesario
          label: supervi.Nombres_Rol,
          descripcion: supervi.Nombres_Rol, // O cualquier otro dato que necesites mostrar
        }));
        let data: string[] = [];
        farmaciaSeleccionada.supervisorAsignado.forEach((element) => {
          data.push(element.Cod_Usuario.toString());
        });
        setDataTransfer(bodegasParaTransfer);

        setProductsKeys(data);
      } else {
        setDataTransfer([]);
      }

      //setLoading(false);
    },
    [datosBodegas, setDataTransfer]
  );

  const getBodegas = useCallback(async () => {
    //setDatos([]);
    try {
      const res = await fetch(url + "apifsg-pr/recursos-humanos/obtener-datos-supervisores", optionsPost({ valueselect }));

      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (res.ok) {
        if (valueselect === "1") {
          const respuestas: DatosSupervidores[] = respuesta.data;
          if (respuestas.length > 0) {
            setDatos(
              respuestas.map(({ key, Nombres, value, label, todasBodegas, bodegasAsignadasArray }) => ({
                key,
                Nombres,
                value,
                label,
                todasBodegas,
                bodegasAsignadasArray,
              }))
            );
          } else {
            setDatos([]);
          }
        } else {
          const respuestas: DatosBodegasSupervidores[] = respuesta.data;
          if (respuestas.length > 0) {
            setDatosBodegas(
              respuestas.map(({ key, value, label, Descripcion, supervisorAsignado, supervisoresNoAsignados }) => ({
                key,
                value,
                label,
                Descripcion,
                supervisorAsignado,
                supervisoresNoAsignados,
              }))
            );
          } else {
            setDatosBodegas([]);
          }
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      swalError({ text: "No se pudo obtener datos" });
      return { msg: "no_conexion", status: false };
    }
  }, [optionsPost, isError, valueselect]);

  useEffect(() => {
    getBodegas();
  }, [getBodegas]);
  const handleClickDownloadData = () => {
    let datosParaExportar: any[] = [];
    let todasLasBodegas = new Map<number, { descripcion: string; establecimiento: string }>();
    let bodegasAsignadas = new Set<number>();

    // Recolectar todas las bodegas disponibles en un mapa para facilitar el acceso por código
    datos.forEach((supervisor) => {
      supervisor.todasBodegas.forEach((bodega) => {
        todasLasBodegas.set(bodega.Cod_Bodega, { descripcion: bodega.Descripcion, establecimiento: bodega.Establecimiento });
      });
    });

    // Crear entradas para cada bodega asignada y registrar las bodegas asignadas
    datos.forEach((supervisor) => {
      supervisor.bodegasAsignadasArray.forEach((bodega) => {
        datosParaExportar.push({
          Nombres: supervisor.Nombres,
          Establecimiento: bodega.Establecimiento,
          DescripcionBodega: bodega.Descripcion,
        });
        bodegasAsignadas.add(bodega.Cod_Bodega); // Marcar como asignada
      });
    });

    // Añadir bodegas no asignadas comparando con el mapa de todas las bodegas
    todasLasBodegas.forEach((value, codBodega) => {
      if (!bodegasAsignadas.has(codBodega)) {
        datosParaExportar.push({
          Nombres: "", // Sin nombre de supervisor
          Establecimiento: value.establecimiento,
          DescripcionBodega: value.descripcion,
        });
      }
    });

    const texto = "detalleSupervisores";
    exportToExcel(datosParaExportar, texto);
  };

  const handleSendInfo = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        const productsKeysInt = productsKeys.map((key) => parseInt(key, 10));

        if (values.buscarPor === "2" && productsKeys.length <= 0) {
          setLoading(false);
          Swal.fire("Error", "La lista a emparejar no puede estar vacía!", "error");
          throw new Error("Error , lista vacía!");
        } else {
          const respuesta = await fetch(
            url + "apifsg-pr/recursos-humanos/enviar-data",
            optionsPost({
              valor: values.buscarPor,
              identificador: values.buscarPor === "1" ? values.supervisor : values.farmacias,
              datos: productsKeysInt,
            })
          );
          if (respuesta.status === 200) {
            Swal.fire("Ok", "Datos guardados correctamente", "success");
            //cambiarDatos();
            getBodegas();
            setLoading(false);
          } else {
            setLoading(false);
            Swal.fire("Error", "Ocurrió un error al guardar los datos", "error");
          }
        }
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  return {
    datos,
    //handleProducto,
    dataTransfer,
    handleChange,
    handleChangeSelect1,
    productsKeys,
    form,
    handleSendInfo,
    loading,
    valueselect,
    datosBodegas,
    handleChangeFarmaciaSupervisor,
    handleChangeFarmacia,
    handleClickDownloadData,
  };
};
