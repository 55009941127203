import { useCallback, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { useSwal } from "hooks/otros/useSwal"
import { UserContext } from "context/UserContext"

export function useVerifyResponse() {
  const navigate = useNavigate()

  const { swalError } = useSwal()
  const { setUserData } = useContext(UserContext)

  const wasInvalidToken = useCallback(
    ({ msg, mensaje }: any): boolean => {
      let text = ""
      if (msg === "err") {
        if (mensaje === "INVALID_TOKEN_AC") text = "Sin token o token inválido"
        if (mensaje === "INVALID_TOKEN_AC_VERIFY") text = "Token inválido"
        if (mensaje === "INVALID_TOKEN_AC_ERR") text = "Error al verificar el token"

        if (text !== "") {
          // swalError({ text })
          console.error(text)
          localStorage.removeItem("token")
          navigate("/login")
          return true
        }
        return false
      }
      return false
    },
    [navigate]
  )

  const hadError = useCallback(
    ({ msg, mensaje }: any, text: string) => {
      if (msg === "err" || msg === "error") {
        console.error(mensaje)
        swalError({ text })
        return true
      }
      return false
    },
    [swalError]
  )

  const isError = useCallback(
    (res: Response) => {
      if (res.ok) return false
      if (res.status === 401) {
        localStorage.removeItem("token")
        navigate("/login")
        setUserData(undefined)
      }
      return true
    },
    [navigate, setUserData]
  )

  return { wasInvalidToken, hadError, isError }
}
