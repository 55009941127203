import { useRef } from "react";

export const useComponentChart = () => {
  const generatedColors = useRef<Record<number, string>>({});

  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getBarColor = (index: number) => {
    if (!generatedColors.current[index]) {
      generatedColors.current[index] = generateRandomColor();
    }
    return generatedColors.current[index];
  };
  return {
    generateRandomColor,
    generatedColors,
    getBarColor,
  };
};
