import { Moment } from "moment";
import {  useState } from "react";
import { optionsPOST, url_interna } from "../../config/constants";

//import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { notification, TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useExport } from "hooks/otros/useExport";
import { RangePickerProps } from "antd/lib/date-picker";

type InventarioReporteRealizados = {
  Guia: string;
  Medicamento: string;
  Cantidad: number;
  PuntoPartida: string;
  PuntoDestino: string;
  Ruta: string;
  Fecha: string;
  MotivoTraslado: string;
  puntoOrigen: string;
  key: React.Key;
};

const breadcrumbs = [
  { Label: "Auditoría de Procesos", Url: "/reporte-psicotropicos-sisalem" },
  { Label: "Psicotrópicos Sisalem" },
];

const openKeys = [breadcrumbs[0].Label];
export function useReportePsicotropicos() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);
  //const [mes, setMes] = useState<Moment>(moment());
  const [datosInicial, setDatos] = useState<InventarioReporteRealizados[]>([]);
  //const [searchText] = useState("");
  const [[fechaInicios, fechaFins], setFechas] = useState<[Moment | null, Moment | null]>([null, null]);

  const [dataEstado, setDataEstado] = useState<boolean>(false);
  const [estadoBotones, setDataBoton] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<InventarioReporteRealizados[]>([]);

  const [estadoBoton, setBoton] = useState<boolean>(false);

  const { exportToExcel } = useExport();
  const { isError } = useVerifyResponse();

  const getBodegas = async (fechaInicio: Moment, fechaFin: Moment) => {
    setDataEstado(false);
    setDataBoton(true);
    try {
      const res = await fetch(
        url_interna + "api/inventarios/obtener_reporte_psicotropicos",
        // optionsPost(JSON.stringify({ mes: mes.format("YYYYMM") }), { "api-token": "apitoken" })
        optionsPOST(
          JSON.stringify({ fechaInicio: fechaInicio!.format("DD/MM/YYYY"), fechaFin: fechaFin!.format("DD/MM/YYYY") }),
          {
            "api-token": "apitoken",
          }
        )
      );

      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        setBoton(true);
        setDataEstado(true);
        setDataBoton(false);
        const respuestas: InventarioReporteRealizados[] = respuesta.data;
        setDatos(
          respuestas.map(
            ({
              Guia,
              Medicamento,
              Cantidad,
              PuntoPartida,
              PuntoDestino,
              Ruta,
              Fecha,
              MotivoTraslado,
              puntoOrigen,
              key,
            }) => ({
              Guia,
              Medicamento,
              Cantidad,
              PuntoPartida,
              PuntoDestino,
              Ruta,
              Fecha,
              MotivoTraslado,
              puntoOrigen,
              key,
            })
          )
        );

        setFilteredData(respuesta.data);
      } else {
        setDatos([]);
        setBoton(false);
        setDataEstado(true);
        setDataBoton(false);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Deshabilita las fechas futuras respecto al final del día actual
    const today = moment().endOf("day");
    const startOfLastMonth = moment().subtract(1, "month").startOf("month");

    // Verifica si 'current' es después de hoy o si 'current' es antes del inicio del mes actual.
    return current > today || current < startOfLastMonth;
  };

  const columns: TableColumnsType<InventarioReporteRealizados> = [
    {
      title: "Número de guia",
      dataIndex: "Guia",
      className: "letraPequenaVisor2",
      key: "Guia",
      width: "140px",
      /*filters: [...new Set(datosInicial.map(({ BodegaEnvia }) => BodegaEnvia))].map((BodegaEnvia) => ({
        text: BodegaEnvia,
        value: BodegaEnvia,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.BodegaEnvia,*/
    },
    {
      title: "Medicamento",
      dataIndex: "Medicamento",
      className: "letraPequenaVisor2",
      key: "Medicamento",

      //width:"200px"
      filters: [...new Set(datosInicial.map(({ Medicamento }) => Medicamento))].map((Medicamento) => ({
        text: Medicamento,
        value: Medicamento,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.Medicamento,
    },

    {
      title: "Cantidad",
      dataIndex: "Cantidad",
      className: "letraPequenaVisor2",
      key: "Cantidad:",
      width: "75px",
      sorter: (a, b) => a.Cantidad - b.Cantidad,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Punto de partida",
      dataIndex: "PuntoPartida",
      className: "letraPequenaVisor2",
      key: "PuntoPartida",
      width: "125px",
    },
    {
      title: "Destino",
      dataIndex: "PuntoDestino",
      className: "letraPequenaVisor2",
      width: "75px",
      key: "PuntoDestino",
    },
    {
      title: "Ruta",
      dataIndex: "Ruta",
      width: "85px",
      className: "letraPequenaVisor2",
      key: "Ruta",
      filters: [...new Set(datosInicial.map(({ Ruta }) => Ruta))].map((Ruta) => ({
        text: Ruta,
        value: Ruta,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.Ruta,
    },
    {
      title: "Fecha",
      dataIndex: "Fecha",
      width: "100px",
      className: "letraPequenaVisor2",
      key: "Fecha:",
      sorter: (a, b) => moment(a.Fecha).unix() - moment(b.Fecha).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Motivo del transporte",
      dataIndex: "MotivoTraslado",
      key: "MotivoTraslado",
      className: "letraPequenaVisor2",
      width: "190px",
    },
  ];

  const handleClickDownloadData = () => {
    const datosParaExportar = filteredData.map(({ key, ...resto }) => resto);

    const texto = "detallePsicotropicos";
    exportToExcel(datosParaExportar ?? [], texto);
  };
  const handleChangeButton = () => {
    if (fechaInicios && fechaFins) {
      if (fechaInicios.format("YYYYMM") === fechaFins.format("YYYYMM")) {
        getBodegas(fechaInicios, fechaFins);
      } else {
        notification.warning({
          message: "Error",
          description: `Solo se puede generar un reporte de un mismo mes`,
          placement: "bottomRight",
        });
      }
    }
  };
  return {
    columns,
    datosInicial,
    handleClickDownloadData,
    estadoBoton,
    setFechas,
    dataEstado,
    disabledDate,
    fechaInicios,
    fechaFins,
    handleChangeButton,
    estadoBotones,
  };
}
