import { Form } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import { ColumnsType } from "antd/lib/table";
import { url } from "config/constants";
import { optionsGet, optionsPost, swalError } from "config/methods";
import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useSearchTable } from "hooks/otros/useSearchTable";

import moment, { Moment } from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useExport } from "hooks/otros/useExport";

const breadcrumbs = [{ Label: "Ventas Billetéate" }];

export interface PropTableProductos {
  key: number;
  producto: string;
  laboratorio: string;
  valor_vendido: number;
  cantidad_vendido: number;
  farmacias: number;
}

export interface PropTableBodegas {
  key: number;
  bodega: string;
  supervisor: string;
  provincia: string;
  ciudad: string;
  total: number;
  cantidad: number;
}

export interface PropTableDependiente {
  key: number;
  dependiente: string;
  total: number;
  cantidad: number;
}

export interface OptionType {
  value: number;
  label: string;
}

export interface PropSupervisor {
  supervisor: string;
  total: number;
  cantidad: number;
}

export interface PropProvincia {
  provincia: string;
  total: number;
  cantidad: number;
}

export const useVentasBilleteateProductos = () => {
  const [form] = Form.useForm();
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const [laboratorios, setlaboratorios] = useState<OptionType[]>([]);
  const [tableMain, setTableMain] = useState<PropTableProductos[]>([]);
  const [tableBodegas, setTableBodegas] = useState<PropTableBodegas[]>([]);
  const [tableDependientes, setTableDependientes] = useState<PropTableDependiente[]>([]);
  const [dataSupervisores, setDataSupervisores] = useState<PropSupervisor[]>([]);
  const [dataProvincias, setDataProvincias] = useState<PropProvincia[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [nameProduct, setNameProduct] = useState<string>();
  const [nameBodega, setNameBodega] = useState<string>();
  const { getColumnSearchProps } = useSearchTable<PropTableProductos>();
  const { getColumnSearchProps: getColumnSearchPropsBodegas } = useSearchTable<PropTableBodegas>();
  const { exportToExcel } = useExport();

  const [selectedRow, setSelectedRow] = useState<number[]>([]);
  const [selectedRowTblBodegas, setSelectedRowBodegas] = useState<number[]>([]);

  const handleClear = () => {
    setTableBodegas([]);
    setTableDependientes([]);
    setDataSupervisores([]);
    setDataProvincias([]);
    setNameProduct("");
    setNameBodega("");
    setSelectedRow([]);
    setSelectedRowBodegas([]);
  };

  useBreadCrumbs(breadcrumbs);

  useEffect(() => {
    setOpenKeys(["Indicadores", "Indicadores"]);
    setSelectedKeys(["Indicadores/Ventas Billetéate"]);
  }, [setOpenKeys, setSelectedKeys]);

  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };

  const headTableMain: ColumnsType<PropTableProductos> = [
    {
      title: "Id",
      dataIndex: "key",
      key: "key",
      width: "45px",
      className: "customTbl",
    },
    {
      title: "Producto",
      dataIndex: "producto",
      key: "producto",
      width: "175px",
      className: "customTbl",
      ...getColumnSearchProps("producto"),
    },
    {
      title: "Laboratorio",
      dataIndex: "laboratorio",
      key: "laboratorio",
      className: "customTbl",
      ...getColumnSearchProps("laboratorio"),
    },
    {
      title: "Cantidad Vendido",
      dataIndex: "cantidad_vendido",
      key: "cantidad_vendido",
      align: "right",
      className: "customTbl",
      render: (_, record) => <>{formateaCantidad(record.cantidad_vendido)}</>,
      sorter: (a, b) => a.cantidad_vendido - b.cantidad_vendido,
    },
    {
      title: "Valor Vendido",
      dataIndex: "valor_vendido",
      key: "valor_vendido",
      align: "right",
      className: "customTbl",
      render: (_, record) => <>{formateaCantidad(record.valor_vendido)}</>,
      sorter: (a, b) => a.valor_vendido - b.valor_vendido,
    },
    {
      title: "%Farmacias Vendido",
      dataIndex: "farmacias",
      key: "farmacias",
      align: "right",
      className: "customTbl",
      render: (_, record) => <>{formateaCantidad(record.farmacias) + "%"}</>,
      // sorter: (a, b) => a.farmacias - b.farmacias,
    },
  ];

  const headTableBodegas: ColumnsType<PropTableBodegas> = [
    {
      title: "Bodega",
      dataIndex: "bodega",
      key: "bodega",
      width: "150px",
      className: "customTbl",
      ...getColumnSearchPropsBodegas("bodega"),
    },
    {
      title: "Supervisor",
      dataIndex: "supervisor",
      key: "supervisor",
      className: "customTbl",
      ...getColumnSearchPropsBodegas("supervisor"),
    },
    {
      title: "Provincia",
      dataIndex: "provincia",
      key: "provincia",
      className: "customTbl",
      ...getColumnSearchPropsBodegas("provincia"),
    },
    {
      title: "Ciudad",
      dataIndex: "ciudad",
      key: "ciudad",
      className: "customTbl",
      ...getColumnSearchPropsBodegas("ciudad"),
    },
    {
      title: "Cantidad Vendido",
      dataIndex: "cantidad",
      key: "cantidad",
      align: "right",
      className: "customTbl",
      render: (_, record) => <>{formateaCantidad(record.cantidad)}</>,
      sorter: (a, b) => a.cantidad - b.cantidad,
    },
    {
      title: "Valor Vendido",
      dataIndex: "total",
      key: "total",
      align: "right",
      className: "customTbl",
      render: (_, record) => <>{formateaCantidad(record.total)}</>,
      sorter: (a, b) => a.total - b.total,
    },
  ];

  const headTableDependientes: ColumnsType<PropTableDependiente> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      className: "customTbl",
      width: "50px",
    },
    {
      title: "Dependiente",
      dataIndex: "dependiente",
      key: "dependiente",
      className: "customTbl",
    },
    {
      title: "Cantidad Vendido",
      dataIndex: "cantidad_vendido",
      key: "cantidad_vendido",
      align: "right",
      className: "customTbl",
      render: (_, record) => <>{formateaCantidad(record.cantidad)}</>,
      sorter: (a, b) => a.cantidad - b.cantidad,
    },
    {
      title: "Valor Vendido",
      dataIndex: "valor_vendido",
      key: "valor_vendido",
      align: "right",
      className: "customTbl",
      render: (_, record) => <>{formateaCantidad(record.total)}</>,
      sorter: (a, b) => a.total - b.total,
    },
  ];

  const handleDataInicialLab = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "apifsg-pr/reportes/data-lab", optionsGet()).then((data) => data.json());
      if (respuesta.msg) {
        setlaboratorios(respuesta.data);
      } else {
        swalError({ text: "Ocurrio un error!" });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleDataInicialProducts = useCallback(async (fechaRango: [Moment, Moment] = [moment()
      .subtract(1, "month")
      .subtract(1, "days"), moment().add(1, "days")], cod_laboratorio: number = 0) => {
    try {
      setLoader(true);
      setTableMain([]);
      const respuesta = await fetch(
        url + "apifsg-pr/reportes/data-productos",
        optionsPost({ fechaRango, cod_laboratorio })
      ).then((data) => data.json());
      if (respuesta.msg) {
        setTableMain(respuesta.data);
        setLoader(false);
      } else {
        swalError({ text: "No existen datos!" });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      swalError({ text: "Ocurrio un error" });
    }
  }, []);

  const sendData = async (record: PropTableProductos) => {
    try {
      const respuesta = await fetch(
        url + "apifsg-pr/reportes/data-productos-xbodegas",
        optionsPost({ fecha: [form.getFieldsValue().fecha[0], form.getFieldsValue().fecha[1]], cod_producto: record.key })
      ).then((data) => data.json());

      if (respuesta.msg) {
        let dataTblaBodegas = JSON.parse(respuesta.data[0].data)[0].listaProductos;
        let dataTblaProvincias = JSON.parse(respuesta.data[0].data)[0].provincias;
        let dataTblaSupervisores = JSON.parse(respuesta.data[0].data)[0].supervisores;
        setTableBodegas(dataTblaBodegas);
        setDataProvincias(dataTblaProvincias);
        setDataSupervisores(dataTblaSupervisores);
      } else {
        swalError({ text: "Ocurrio un error!" });
      }
    } catch (error) {}
  };

  const sendDataTblDependiente = async (record: PropTableBodegas) => {
    try {
      const respuesta = await fetch(
        url + "apifsg-pr/reportes/data-productos-dependientes",
        optionsPost({
          fecha: [form.getFieldsValue().fecha[0], form.getFieldsValue().fecha[1]],
          cod_bodega: record.key,
          cod_producto: selectedRow[0],
        })
      ).then((data) => data.json());
      if (respuesta.msg) {
        setTableDependientes(respuesta.data);
      } else {
        swalError({ text: "No existe información!" });
      }
    } catch (error) {}
  };

  const handleRowSelect = async (record: PropTableProductos) => {
    if (selectedRow && selectedRow[0] === record.key) {
      setSelectedRow([]);
    } else {
      let newSelection = [];
      newSelection.push(record.key);
      setSelectedRow(newSelection);
      setNameProduct(record.producto);
      sendData(record);
      setSelectedRowBodegas([]);
      setTableDependientes([]);
    }
  };

  const handleRowSelectTblBodegas = async (record: PropTableBodegas) => {
    if (selectedRowTblBodegas && selectedRowTblBodegas[0] === record.key) {
      setSelectedRowBodegas([]);
    } else {
      let newSelection = [];
      setNameBodega(record.bodega);
      newSelection.push(record.key);
      setSelectedRowBodegas(newSelection);
      sendDataTblDependiente(record);
    }
  };

  const rowSelection = {
    type: "radio",
    onSelect: handleRowSelect,
    selectedRowKeys: selectedRow[0] ? [selectedRow[0]] : [],
  };

  const rowSelectionTblBodegas = {
    type: "radio",
    onSelect: handleRowSelectTblBodegas,
    selectedRowKeys: selectedRowTblBodegas[0] ? [selectedRowTblBodegas[0]] : [],
  };

  const handleSelectLaboratorio = (value: number) => {
    if (value >= 0) {
      handleDataInicialProducts([form.getFieldsValue().fecha[0], form.getFieldsValue().fecha[1]], value);
      handleClear();
    }
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > moment().endOf("day").subtract(1, "days");
  };

  const handleChangeDate: RangePickerProps["onChange"] = (date: any) => {
    if (date) {
      handleDataInicialProducts(date, form.getFieldsValue().cod_laboratorio);
      handleClear();
    }
  };

  const handleClickDownloadProductos = () => {
    tableMain &&
      exportToExcel(
        tableMain,
        `Billeteate: ${moment(form.getFieldsValue().fecha[0]).format("DD/MM/YYYY")}-${moment(
          form.getFieldsValue().fecha[1]
        ).format("DD/MM/YYYY")}-${laboratorios.find((lab) => lab.value === form.getFieldsValue().cod_laboratorio)?.label}`
      );
  };

  const handleClickDownloadBodegas = () => {
    const dataExport = tableBodegas.map(({ key, bodega, supervisor, provincia, ciudad, cantidad, total }) => ({
      key,
      bodega,
      supervisor,
      provincia,
      ciudad,
      cantidad_vendido: cantidad,
      valor_vendido: total,
    }));

    exportToExcel(
      dataExport,
      `Billeteate: ${nameProduct} ${moment(form.getFieldsValue().fecha[0]).format("DD/MM/YYYY")}-${moment(
        form.getFieldsValue().fecha[1]
      ).format("DD/MM/YYYY")}-BODEGAS`
    );
  };

  useEffect(() => {
    handleDataInicialLab();
    handleDataInicialProducts();
  }, [handleDataInicialLab, handleDataInicialProducts]);

  return {
    handleClickDownloadBodegas,
    nameBodega,
    rowSelectionTblBodegas,
    handleClickDownloadProductos,
    disabledDate,
    handleChangeDate,
    handleSelectLaboratorio,
    headTableMain,
    form,
    laboratorios,
    tableMain,
    loader,
    rowSelection,
    headTableBodegas,
    tableBodegas,
    dataProvincias,
    dataSupervisores,
    nameProduct,
    headTableDependientes,
    tableDependientes,
  };
};
