import { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";
import { CopyOutlined } from "@ant-design/icons";
import icon from "./constants";
import { Button, message } from "antd";

interface CoordenadasProps {
  coordenadas?: string;
}

export default function MapsViews({ coordenadas }: CoordenadasProps) {
  let ltn = coordenadas && JSON.parse(coordenadas);
  const position: LatLngExpression = ltn;

  const zoom: number = 17;

  // const copyToClipboard = () => {
  //   if (coordenadas) {
  //     navigator.clipboard
  //       .writeText(coordenadas)
  //       .then(() => {
  //         console.log("Ubicación copiada al portapapeles: ", coordenadas);
  //         // Puedes mostrar una notificación o realizar alguna otra acción aquí
  //         message.success("¡Coordenadas copiadas al portapapeles!");
  //       })
  //       .catch((error) => {
  //         console.error("Error al copiar la ubicación al portapapeles: ", error);
  //         // Puedes mostrar una notificación o realizar alguna otra acción aquí
  //         message.error("Error al copiar las coordenadas al portapapeles");
  //       });
  //   }
  // };

  const copyToClipboard = () => {
    if (coordenadas) {
      const { lat, lng } = JSON.parse(coordenadas);
      const formattedCoordinates = `${lat},${lng}`;
      navigator.clipboard.writeText(formattedCoordinates);
      message.success("Coordenadas copiadas al portapapeles");
    }
  };
  return (
    <MapContainer center={position} zoom={zoom} scrollWheelZoom={true}>
      <TileLayer
        attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {position && (
        <Marker position={position} icon={icon}>
          <Popup>
            {coordenadas && (
              <>
                <div style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center', marginBottom: '10px' }}>
                  Tus Coordenadas: 
                </div>                  
                {coordenadas}
                <br />
                <br />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    style={{
                      backgroundColor: 'rgb(106, 40, 49, 100)',
                      color: '#fff',
                      borderColor: 'rgb(106, 40, 49, 100)',
                      fontWeight: 'bold',
                      padding: '0px 20px',
                      borderRadius: '4px',
                      cursor: 'pointer',
                    }}
                    onClick={copyToClipboard}
                  >
                    <CopyOutlined /> Copiar Coordenadas
                  </Button>
                </div>
              </>
            )}
          </Popup>
        </Marker>
      )}
    </MapContainer>
  );
}
