import { Report } from "components/reactreport/Report"
import { View, Text, Image, StyleSheet } from "@react-pdf/renderer"
import { Title } from "components/reactreport/Title"
import { url } from "config/constants"

export interface LlamadoAtencion {
  key: number
  empleado: string
  cedula_empleado: string
  cargo_empleado: string
  solicitante: string
  cargo_solicitante?: string
  responsables: {
    cedula: string
    nombres: string
    cargo?: string
  }[]
  departamento: string
  fecha_falta: string
  fecha_creacion: string
  fecha_aprobacion?: string
  multa: boolean
  porcentaje: number
  observacion: string
  motivo?: string
  descripcion?: string
  nbodega?: number
  direccion?: string
  bodega: string
  periodo_actividad?: string
  tipo_actividad?: string
  estado?: "PE" | "AP" | "RE" | "RV"
  reglamentos: {
    capitulo: string
    articulos: {
      articulo: number
      descripcion: string
      apartado: string
      literales: {
        literal: number
        descripcion: string
      }[]
    }[]
  }[]
  evidencias: {
    llave_imagen: string
  }[]
}

interface propsReporte {
  numeracion: string
  llamadoAtencion: LlamadoAtencion
}

export const fillZeros = (n: number, len: number) => "0".repeat(len - n?.toString().length) + n?.toString()

const createPeriodo = (periodo?: string) => {
  if (!periodo) return ""
  const año = parseInt(periodo.substring(0, 4))
  const mes = parseInt(periodo.substring(4, 6)) - 1
  const fecha = new Date(año, mes, 1)
  return `${fecha.toLocaleString("default", { month: "long" })} del ${fecha.getFullYear()}`
}

const styles = StyleSheet.create({
  text: { fontSize: 10, textAlign: "justify", marginTop: "15px", lineHeight: 1.3 },
  textBold: { fontSize: 10, marginTop: "15px", lineHeight: 1.3, fontFamily: "Helvetica-Bold" },
  numeration: { textAlign: "right", fontFamily: "Helvetica-Bold", fontSize: 10 },
  title: { border: "2px solid #000", paddingTop: "3px" },
  row: { display: "flex", flexDirection: "row", alignItems: "flex-start", paddingBottom: "5px" },
  columnTitle: { fontFamily: "Helvetica-Bold", fontSize: 10, flexBasis: `15%` },
  column: { fontSize: 10, flexBasis: `85%` },
})

export function GenerateReport({ numeracion, llamadoAtencion }: propsReporte) {
  return (
    <Report
      documentProps={{
        title: `Llamado de atención #${numeracion}`,
      }}
      pageProps={{ size: "A4" }}
    >
      <Text style={styles.numeration}>{numeracion}</Text>
      <Title style={styles.title}>LLAMADO DE ATENCIÓN</Title>
      <View style={styles.row}>
        <Text style={styles.columnTitle}>FECHA:</Text>
        <Text style={styles.column}>
          {/* { moment().format('DD [de] MMMM [del] YYYY') } */}
          {llamadoAtencion.fecha_aprobacion}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.columnTitle}>PARA:</Text>
        <Text style={styles.column}>{llamadoAtencion.empleado}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.columnTitle}>CARGO:</Text>
        <Text style={styles.column}>{llamadoAtencion.cargo_empleado}</Text>
      </View>
      <View style={{ ...styles.row, borderBottom: "1px solid #000" }}>
        <Text style={styles.columnTitle}>MOTIVO:</Text>
        <Text style={styles.column}>{llamadoAtencion.motivo}</Text>
      </View>
      <Text style={styles.text}>
        La presente constituye un llamado de atención para el Sr(a).
        <Text style={{ fontFamily: "Helvetica-Bold", fontSize: 10 }}>&nbsp;{llamadoAtencion.empleado}</Text>, quien ha
        incumplido
        {llamadoAtencion.reglamentos.map(({ articulos }) =>
          articulos.map(
            ({ articulo, literales }) =>
              ` el ART. ${articulo} ${!isNaN(literales[0].literal) ? "numeral" : "literal"} ${literales
                .map(({ literal }) => literal)
                .join(", ")}, `
          )
        )}
        de acuerdo a información recibida en el Departamento de RRHH, por parte del Sr (a). {llamadoAtencion.solicitante},
        quien se desempeña como {llamadoAtencion.cargo_solicitante}, en la que menciona lo siguiente:
      </Text>
      <Text style={styles.text}>
        Durante el periodo de {createPeriodo(llamadoAtencion.periodo_actividad)}, se llevó a cabo una{" "}
        {llamadoAtencion.tipo_actividad} en el punto de venta #{llamadoAtencion.nbodega}, la misma que está ubicado en la
        localidad de {llamadoAtencion.direccion}, el personal que realizó el proceso es el siguiente:
      </Text>
      <View style={{ paddingLeft: "20px", marginTop: "15px" }}>
        {llamadoAtencion.responsables.map(({ nombres }, index) => (
          <Text style={{ fontSize: 10, textAlign: "justify", lineHeight: 1.3 }} key={index}>
            {`•    ${nombres}`}
          </Text>
        ))}
        {llamadoAtencion.descripcion && (
          <Text style={{ fontSize: 10, textAlign: "justify", lineHeight: 1.3 }}>
            {`-    ${llamadoAtencion.descripcion}`}
          </Text>
        )}
      </View>
      <Text style={{ ...styles.text, fontFamily: "Helvetica-BoldOblique" }}>
        Extracto de la solicitud de {llamadoAtencion.solicitante}
      </Text>
      <Text style={{ ...styles.text, fontFamily: "Helvetica-BoldOblique", textAlign: "center" }}>
        "{llamadoAtencion.observacion}"
      </Text>
      <Text style={styles.text}>
        Con lo expuesto, el Sr (a).
        <Text style={{ fontFamily: "Helvetica-Bold", fontSize: 10 }}>&nbsp;{llamadoAtencion.empleado}</Text>, ha incumplido
        lo estipulado en
        {llamadoAtencion.reglamentos.map(({ articulos }) =>
          articulos.map(
            ({ articulo, literales }) =>
              ` el ART. ${articulo} ${!isNaN(literales[0].literal) ? "numeral" : "literal"} ${literales
                .map(({ literal }) => literal)
                .join(", ")}, `
          )
        )}{" "}
        del Reglamento Interno de Trabajo de la Empresa.
      </Text>
      <View>
        {llamadoAtencion.reglamentos.map(({ capitulo, articulos }, index1) =>
          articulos.map(({ articulo, apartado, literales }, index2) =>
            literales.map(({ literal, descripcion }, index3) => (
              <View key={`${index1}${index2}${index3}`}>
                <Text style={styles.textBold}>
                  {`Capítulo ${capitulo}, Art. ${articulo}, ${isNaN(literal) ? "literal" : "numeral"} ${literal}.- ${
                    apartado.charAt(0) + apartado.substring(1).toLowerCase()
                  }`}
                </Text>
                <Text style={{ fontSize: 10, textAlign: "justify", lineHeight: 1.3 }}>{descripcion}</Text>
              </View>
            ))
          )
        )}
      </View>
      <View>
        {llamadoAtencion.reglamentos.map(({ capitulo, articulos }, index1) =>
          articulos.map(({ articulo, descripcion }, index2) => (
            <Text style={styles.text} key={`${index1}${index2}`}>
              De acuerdo a lo que menciona el Reglamento Interno de Trabajo de la Empresa, Capítulo {capitulo}, ART.{" "}
              {articulo}.- {descripcion}
            </Text>
          ))
        )}
      </View>
      <Text style={styles.text}>
        <Text
          style={{
            fontSize: 10,
            textAlign: "justify",
            lineHeight: 1.3,
            fontFamily: "Helvetica-Bold",
          }}
        >
          {llamadoAtencion.empleado}
        </Text>
        , con su falta lesiona nuestro Reglamento Interno, se le aplicará la(s) siguiente(s) medida(s) disciplinaria(s):
      </Text>
      <View style={{ paddingLeft: "20px", paddingTop: "15px" }}>
        <Text style={{ fontSize: 10, textAlign: "justify", lineHeight: 1.3 }}>
          • Un llamado de atención, el mismo que irá a su carpeta personal.
        </Text>
        <Text style={{ fontSize: 10, textAlign: "justify", lineHeight: 1.3 }}>
          {llamadoAtencion.multa &&
            `•    Multa máxima permitida por falta grave al incumplimiento del reglamento interno del ${llamadoAtencion.porcentaje}% de su remuneración.`}
        </Text>
      </View>
      {llamadoAtencion.evidencias &&
        (llamadoAtencion.evidencias?.length ? (
          <View style={{ marginTop: "15px" }}>
            {llamadoAtencion.evidencias?.map(({ llave_imagen }, index) => (
              <Image src={`${url}apiallku-public/api/${llave_imagen}`} key={index} />
            ))}
          </View>
        ) : (
          <></>
        ))}
      <Text
        style={{
          fontSize: 10,
          textAlign: "justify",
          lineHeight: 1.3,
          marginTop: "50px",
          fontFamily: "Helvetica-Bold",
        }}
      >
        Atentamente,
      </Text>
      <Text
        style={{
          fontSize: 10,
          textAlign: "justify",
          lineHeight: 1.3,
          marginTop: "50px",
          borderTop: "1px solid #000",
          width: "50%",
          paddingTop: "5px",
        }}
      >
        PSIC. GUILLERMO ASCENCIO ARREAGA
      </Text>
      <Text style={{ fontSize: 10, textAlign: "justify", lineHeight: 1.3 }}>DPTO. DE RRHH</Text>
      <Text
        style={{
          fontSize: 10,
          textAlign: "justify",
          lineHeight: 1.3,
          marginTop: "50px",
          fontFamily: "Helvetica-Bold",
        }}
      >
        Recibido por:
      </Text>
      <Text
        style={{
          fontSize: 10,
          textAlign: "justify",
          lineHeight: 1.3,
          marginTop: "70px",
          borderTop: "1px solid #000",
          width: "50%",
          paddingTop: "5px",
          fontFamily: "Helvetica-Bold",
        }}
      >
        {llamadoAtencion.empleado}
      </Text>
      <Text style={{ fontSize: 10, textAlign: "justify", lineHeight: 1.3 }}>CC: {llamadoAtencion.cedula_empleado}</Text>
      <Text style={{ fontSize: 10, textAlign: "justify", lineHeight: 1.3 }}>TRABAJADOR</Text>
    </Report>
  )
}
