import { Row, Col, Form, Input, Select, InputNumber, DatePicker, Typography, Radio, Card } from "antd";
import type { RadioChangeEvent } from "antd";

import { useFormularioActualizacion } from "../../../hooks/rrhh/actualizacion/useFormularioActualizacion";
import { useEffect, useState } from "react";

import "moment/locale/es";
import { Divider } from "antd";
export const dateFormat = "DD/MM/YYYY";

export interface dataC {
  value: number;
  label: string;
}

export interface dataProps {
  changeCivil: (value: RadioChangeEvent) => void;
}

const MiInformacion = ({ changeCivil }: dataProps) => {
  let { getNacionalides, getEstadoCivil } = useFormularioActualizacion();
  const [nacionalidades, setNacionalidades] = useState<dataC[]>([]);
  const [estadoCivil, setEstadoCivil] = useState<dataC[]>([]);

  useEffect(() => {
    const fnNacionalidades = async () => {
      let res = await getNacionalides();
      setNacionalidades(res.data);
    };

    const fnEstado = async () => {
      let res = await getEstadoCivil();
      setEstadoCivil(res.data);
    };

    fnNacionalidades();
    fnEstado();
  }, [getNacionalides, getEstadoCivil]);

  return (
    <Row wrap={true}>
      <Col xs={24} md={24} lg={24}>
        <Card title='Datos Personales' type='inner' size='small'>
          <Row wrap={true}>
            <Col xs={24} xl={12}>
              <Form.Item
                label='Cédula'
                style={{ width: "99%" }}
                name='cedula'
                rules={[
                  {
                    required: true,
                    message: "Escriba su número de cédula",
                  },
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject();
                      }
                      if (isNaN(value)) {
                        return Promise.reject("No es un número valido");
                      }

                      if (value.length < 10) {
                        return Promise.reject("Cédula Incorrecta");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                hasFeedback={true}
              >
                <Input
                  readOnly
                  maxLength={10}
                  placeholder='Escriba su numero de cédula'
                  style={{ width: "98%" }}
                  // onChange={handleChangeCedula}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row wrap={true}>
            <Col xs={24} xl={12}>
              <Form.Item
                label='Nombres'
                style={{ width: "99%" }}
                name='nombres'
                rules={[{ required: true, message: "Nombres es obligatorios" }]}
              >
                <Input placeholder='Escriba sus nombres' style={{ width: "98%" }} readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label='Apellidos'
                style={{ width: "99%" }}
                name='apellidos'
                rules={[{ required: true, message: "Apellidos es obligatorio" }]}
              >
                <Input placeholder='Escriba sus apellidos' style={{ width: "98%" }} name='apellidos' readOnly />
              </Form.Item>
            </Col>
          </Row>

          <Row wrap={true}>
            <Col xs={24} xl={12}>
              <Form.Item
                label='Correo electrónico personal'
                style={{ width: "99%" }}
                name='correo_personal'
                rules={[{ required: true, message: "Correo es obligatorio", type: "email" }]}
              >
                <Input style={{ width: "98%" }} placeholder='Escriba su correo personal' />
              </Form.Item>
            </Col>

            <Col xs={24} xl={12}>
              <Form.Item label='Dirección completa' style={{ width: "99%" }}>
                <Input.Group compact>
                  <Form.Item
                    name={["direccion", "principal"]}
                    noStyle
                    rules={[{ required: true, message: "Calle principal obligatoria" }]}
                  >
                    <Input style={{ width: "35%" }} placeholder='Calle principal' autoComplete='off' />
                  </Form.Item>
                  <Form.Item
                    name={["direccion", "secundaria"]}
                    noStyle
                    rules={[{ required: true, message: "Calle secundaria obligatoria" }]}
                  >
                    <Input style={{ width: "34%" }} placeholder='Calle secundaria' />
                  </Form.Item>
                  <Form.Item
                    name={["direccion", "otra"]}
                    noStyle
                    rules={[{ required: true, message: "Otra referencia de su direccion es obligatoria" }]}
                  >
                    <Input style={{ width: "29%" }} placeholder='Otra referencia' />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row wrap={true}>
            <Col xs={12} xl={12}>
              <Form.Item
                label='Fecha de nacimiento'
                style={{ width: "99%" }}
                name='fecha_nacimiento'
                rules={[{ required: true, message: "Fecha de nacimiento" }]}
              >
                <DatePicker style={{ width: "98%" }} format={dateFormat} />
              </Form.Item>
            </Col>

            <Col xs={12} xl={12}>
              <Form.Item
                label='Nacionalidad'
                style={{ width: "99%" }}
                name='nacionalidad'
                rules={[{ required: true, message: "Nacionalidad es obligatoria" }]}
              >
                <Select
                  style={{ width: "98%" }}
                  options={nacionalidades}
                  showSearch
                  optionFilterProp='label'
                  placeholder='SELECCIONE UNA NACIONALIDAD'
                ></Select>
              </Form.Item>
            </Col>
          </Row>

          <Row wrap={true}>
            <Col xs={7} xl={8}>
              <Form.Item label='Edad' style={{ width: "99%" }} name='edad'>
                <InputNumber style={{ width: "98%" }} placeholder='Escriba su edad' />
              </Form.Item>
            </Col>
            <Col xs={9} xl={8}>
              <Form.Item
                label='Estatura(metros)'
                style={{ width: "99%" }}
                name='estatura'
                rules={[
                  { required: true, message: "Estatura es obligatoria" },
                  {
                    validator: (_, value) =>
                      value > 2 ? Promise.reject(new Error("Estatura no valida ejemplo(1.78)")) : Promise.resolve(),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "98%" }}
                  placeholder='Escriba su estatura en metros'
                  maxLength={4}
                  // step={0.1}
                  // max={2.0}
                  // formatter={(value) => `${value}m`}
                  // parser={(value: any) => value!.replace("m", "")}
                />
              </Form.Item>
            </Col>
            <Col xs={8} xl={8}>
              <Form.Item
                label='Peso(Kg)'
                style={{ width: "98%" }}
                name='peso'
                rules={[{ required: true, message: "Peso es obligatorio" }]}
              >
                <InputNumber style={{ width: "98%" }} placeholder='Escriba su peso' min={0} step={0.1} />
              </Form.Item>
            </Col>
          </Row>

          <Row wrap={true}>
            <Col xs={12} xl={12}>
              <Form.Item
                label='Teléfono 1'
                style={{ width: "99%" }}
                name='telefono1'
                // rules={[{ required: true, message: "Teléfono es obligatorio" }]}
                rules={[
                  {
                    required: true,
                    message: "Teléfono es obligatorio",
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Ingrese un número de teléfono válido",
                  },
                  // {
                  //   validator: (_, value) =>
                  //     !value.includes(" ")
                  //       ? Promise.resolve()
                  //       : Promise.reject(new Error("Sin espacios")),
                  // },
                ]}
              >
                <Input style={{ width: "98%" }} placeholder='Escriba su número de telefono' maxLength={10} minLength={10} />
              </Form.Item>
            </Col>
            <Col xs={12} xl={12}>
              <Form.Item label='Teléfono 2' style={{ width: "99%" }} name='telefono2' requiredMark='optional'>
                <Input style={{ width: "98%" }} placeholder='Escriba su número de telefono 2' maxLength={10} />
              </Form.Item>
            </Col>
            {/*  */}
          </Row>

          <Row wrap={true}>
            <Col xs={11} xl={12}>
              <Form.Item
                label='Lugar de nacimiento'
                style={{ width: "99%" }}
                name='lugar_nacimiento'
                rules={[{ required: true, message: "Lugar de nacimiento obligatorio" }]}
              >
                <Input style={{ width: "98%" }} />
              </Form.Item>
            </Col>

            <Col xs={13} xl={12}>
              <Form.Item
                label={<Typography.Text ellipsis={{ tooltip: "Estado civil" }}>Estado civil</Typography.Text>}
                style={{ width: "100%" }}
                name='estado_civil'
                rules={[{ required: true, message: "Estado civil obligatorio" }]}
              >
                <Radio.Group style={{ width: "100%" }} options={estadoCivil} onChange={changeCivil}></Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Divider />
        <Card title='Contacto Emergencia' type='inner' size='small'>
          <Row wrap={true}>
            <Input.Group compact>
              <Col xs={24} xl={8}>
                <Form.Item
                  label={
                    <Typography.Text ellipsis={{ tooltip: "En caso de emergencia llamar a" }}>
                      En caso de emergencia llamar a
                    </Typography.Text>
                  }
                  name={["emergencia", "contacto_emergencia"]}
                  rules={[{ required: true, message: "Escriba el nombre" }]}
                >
                  <Input placeholder='Escriba el nombre del contacto de emergencia' />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8}>
                <Form.Item
                  label={
                    <Typography.Text ellipsis={{ tooltip: "Telefono convencional " }}>Teléfono convencional</Typography.Text>
                  }
                  name={["emergencia", "telefono_emergencia"]}
                >
                  <Input maxLength={10} placeholder='Escriba su número de telefono convencional' />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8}>
                <Form.Item
                  label={<Typography.Text ellipsis={{ tooltip: "Telefono celular" }}>Teléfono celular</Typography.Text>}
                  name={["emergencia", "telefono_emergencia2"]}
                  rules={[{ required: true, message: "Telefono obligatorio" }]}
                >
                  <Input placeholder='Escriba su número de telefono célular' maxLength={10} />
                </Form.Item>
              </Col>
            </Input.Group>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default MiInformacion;
