import { Button, Col, Form, Row, Table } from "antd"
import { useDataForTable } from "hooks/rrhh/llamadosatencion/useDataForTable"
import { FormInModal } from "components/rrhh/componentsllamadosatencion/FormInModal"

import { useContext, useEffect, useState } from "react"
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs"
import { LayoutContext } from "context/LayoutContext"

import moment from "moment"

import { EyeOutlined } from "@ant-design/icons"

import type { ColumnProps } from "antd/lib/table"
import type { dataLlamadosAtencion } from "hooks/rrhh/llamadosatencion/useDataForTable"
import type { valuesFormLlamadosAtencion } from "components/rrhh/componentsllamadosatencion/FormInModal"

const breadcrumbs = [
  { Label: "Recursos humanos", Url: "/rrhh" },
  { Label: "Llamados de atención", Url: "/rrhh/llamados-de-atencion" },
  { Label: "Estado de solicitudes" },
]

export function EstadoLlamadoAtencion() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext)

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const [form] = Form.useForm<valuesFormLlamadosAtencion>()

  useBreadCrumbs(breadcrumbs)

  useEffect(() => {
    setOpenKeys(["Recursos Humanos", "Recursos Humanos/Llamados de atención"])
    setSelectedKeys(["Llamados de atención/Estado de solicitudes"])
  }, [setOpenKeys, setSelectedKeys])

  const handleClickAction = (
    value: number,
    {
      multa,
      porcentaje,
      periodo_actividad,
      tipo_actividad,
      bodega,
      departamento,
      observacion,
      fecha_falta,
      fecha_creacion,
      motivo,
      descripcion,
    }: dataLlamadosAtencion
  ) => {
    setSelectedIndex(llamadosAtencion.findIndex(ll => ll.key === value))
    setOpenModal(true)
    form.setFieldsValue({
      multa,
      porcentaje,
      tipo_actividad,
      bodega,
      departamento,
      observacion,
      fecha_falta,
      fecha_creacion,
      motivo,
      descripcion,
      periodo_actividad: moment(periodo_actividad, "YYYYMM"),
    })
  }

  const renderButtonVer: ColumnProps<dataLlamadosAtencion>["render"] = (value, record) => (
    <Button
      icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
      type="text"
      block
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      onClick={() => handleClickAction(value, record)}
    />
  )

  const { llamadosAtencion, columnsTableWithEstado } = useDataForTable(
    "apifsg-pr/recursos-humanos/listar-solicitudes-por-usuario",
    renderButtonVer
  )

  return (
    <>
      <Row wrap style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table columns={columnsTableWithEstado} dataSource={llamadosAtencion} scroll={{ x: true }} />
        </Col>
      </Row>
      <FormInModal
        form={form}
        openModal={openModal}
        handleCloseModal={() => setOpenModal(false)}
        llamadosAtencion={llamadosAtencion}
        selectedIndex={selectedIndex}
        titleModal="Solicitud de llamado de atención"
        disabledAll
        showDataLlamados={false}
        textFirstButton="Cerrar"
      />
    </>
  )
}
