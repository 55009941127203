import { Col, Row, DatePicker, Table, Card, Modal, Spin } from "antd";
import "moment/locale/es";

import moment from "moment";
import { useReporteRealizados } from "hooks/inventarios/reportes/useReporteRealizados";
import { ButtonExcel } from "components/otros/buttons";
import { useVT } from "virtualizedtableforantd4";

export function InventariosRealizados() {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);
  const {
    columns,
    columsDatatableDetalle,
    columsDataDependiente,
    isModalOpen,
    mes,
    setMes,
    datakey,
    datosInicial,
    filteredData,
    handleOk,
    handleCancel,
    handleClickDownloadData,
    calcularTotales,
    estadoBoton,
    dataEstado
  } = useReporteRealizados();
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <DatePicker
          value={mes}
          picker="month"
          placeholder="Seleccionar mes"
          format="MMMM [del] YYYY"
          onChange={(value) => setMes(value ?? moment())}
          inputReadOnly
        />
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Row gutter={[10, 10]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
          <Col xs={24} md={10}>
            {estadoBoton && <ButtonExcel buttonProps={{ onClick: handleClickDownloadData }}></ButtonExcel>}
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>{!dataEstado && (
            <div style={{ textAlign: "center" }}>
              <div style={{ margin: "0 auto" }}>
                <Spin />
              </div>
            </div>
          )}
          {dataEstado && (
          <Table
            columns={columns}
            components={vt}
            dataSource={filteredData ? filteredData : []}
            scroll={{ x: 900, y: 450 }}
            size="small"
            pagination={false}
            bordered
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <Table
                    style={{ width: "100%", margin: "10px" }}
                    columns={columsDataDependiente}
                    dataSource={record.Dependientes}
                    pagination={false}
                  />
                );
              },
            }}
            summary={() => {
              if (!estadoBoton) {
                return null; // No muestra el summary si estadoBoton es falso
              }

              const { totalCobrado, totalCaducado } = calcularTotales();

              return (
                <Table.Summary fixed>
                  <Table.Summary.Row style={{ backgroundColor: "#f0f0f0" }}>
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>{totalCobrado}</Table.Summary.Cell>
                    <Table.Summary.Cell index={6}>{totalCaducado}</Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
          )}
        </Card>
      </Col>
      <Modal
        footer={false}
        transitionName=""
        title="Detalles de inventarios realizados"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"90wh"}
      >
        <Table
          style={{ marginBottom: "10px" }}
          pagination={false}
          loading={columsDatatableDetalle ? false : true}
          scroll={{ x: 900, y: "55vh" }}
          size="small"
          bordered
          columns={columsDatatableDetalle}
          dataSource={datosInicial.find((e) => e.key === datakey)?.Usuarios}
        />
      </Modal>
    </Row>
  );
}

export default InventariosRealizados;
