import { View, Text, StyleSheet } from "@react-pdf/renderer"
import type { Style } from "@react-pdf/types"

export type DataVerticalTable = {
  title?: string
  text: string | number
  styleCellHeader?: Style
  styleCell?: Style
  key?: React.Key
}

type Props = {
  containerStyle?: Style
  dataSource: DataVerticalTable[]
  styleColumnHeader?: Style
  styleColumn?: Style
  roundTop?: boolean
  roundBottom?: boolean
}

const styles = StyleSheet.create({
  cellHeader: {
    // backgroundColor: "#fafafa",
    padding: 10,
  },
  cell: {
    textAlign: "right",
    padding: 10,
  },
  columnHeader: {
    display: "flex",
    fontSize: 8,
    fontFamily: "Helvetica-Bold",
    flexBasis: "50%",
    borderBottom: "1px solid #f0f0f0",
    // alignContent: "center",
  },
  column: {
    display: "flex",
    fontSize: 8,
    fontFamily: "Helvetica",
    flexBasis: "50%",
    borderBottom: "1px solid #f0f0f0",
    // alignItems: "center",
  },
  containerStyle: { display: "flex", flexDirection: "column" },
})

export function VerticalTable({ containerStyle, dataSource, styleColumn, styleColumnHeader, roundBottom, roundTop }: Props) {
  return (
    <View style={{ ...styles.containerStyle, ...containerStyle }}>
      {dataSource.map(({ title, text, styleCell, styleCellHeader, key }, index) => (
        <View key={key ? `${key}${index}` : index} style={{ display: "flex", flexDirection: "row" }}>
          {title && (
            <View
              style={{
                ...styles.columnHeader,
                ...styleColumnHeader,
                borderTopLeftRadius: roundTop && index === 0 ? "5px" : undefined,
                borderBottomLeftRadius: roundBottom && index === dataSource.length - 1 ? "5px" : undefined,
              }}
            >
              <Text style={{ ...styles.cellHeader, ...styleCellHeader }}>{title}</Text>
            </View>
          )}
          <View style={{ ...styles.column, ...styleColumn }}>
            <Text style={{ ...styles.cell, ...styleCell }}>{text}</Text>
          </View>
        </View>
      ))}
    </View>
  )
}
