import { Button, Col, Row, Typography, Table, Select, Image,  } from "antd"
import { useLayout } from "hooks/otros/useLayout"
import { FileExcelOutlined ,SaveOutlined} from "@ant-design/icons"
import { useSolicitarUniformes } from "hooks/rrhh/renovacionuniforme/useSolicitarUniformes"
import "./SolicitarUniformes.css"
import { url } from "config/constants"

const { Title } = Typography
const breadcrumbs = [
  { Label: "Recursos Humanos", Url: "/rrhh" },
  { Label: "Renovación de Uniformes", Url: "/rrhh/renovacion-uniformes" },
  { Label: "Solicitar Uniformes" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export function SolicitarUniformes() {
  useLayout(breadcrumbs, openKeys, selectKeySider)
  const { rowSelection, 
    columns, 
    data, 
    columnsuniforme, 
    savesolicitudKit, 
    handleDownload, 
    handleExpand,
    handleChange,
    llavesimages,
    visible,
    setVisible,
    ExpandedRowKeys,
    visibleButton
  } = useSolicitarUniformes()

  return (
    <>
      <Row style={{ background: "white", padding: "5px" }} align="middle" justify="center">
        <Col sm={24} md={8} style={{ paddingLeft: "20px" }}>
          <Title level={2}>Solicitar Uniformes</Title>
        </Col>
        <Col sm={24} md={6}>
          <label style={{ marginTop: "14px" }}>Filtrar por: </label>
          <Select
            defaultValue=""
            style={{ width: 130, marginLeft: "0px", marginTop: "5px" }}
            onChange={handleChange}
            options={[
              {
                value: "",
                label: "Todos",
              },
              {
                value: "PSO",
                label: "Por Solicitar",
              },
              {
                value: "PE",
                label: "Solicitados",
              },
              {
                value: "PP",
                label: "Validado RRHH",
              },
              {
                value: "AP",
                label: "Aprobados",
              },
              {
                value: "RE",
                label: "Rechazados",
              }
            ]}
          />
        </Col>
        <Col sm={24} md={10}>
          <Row justify="end">
            <Button
              type="primary"
              style={{ marginRight: "2px", marginTop: "1px", backgroundColor: "#00B050", borderColor: "#00B050" }}
              onClick={handleDownload}
            >
              Excel <FileExcelOutlined />
            </Button>
           { visibleButton&&<Button type="primary" style={{ marginTop: "1px" }} onClick={() => savesolicitudKit()}>
              Guardar Seleccionados
              <SaveOutlined />
            </Button>}
          </Row>
        </Col>
      </Row>
      <Row style={{ background: "white", width: '100%' }}>
        <Table
          style={{ width: "100%", margin: "10px"}}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          onRow={({disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled) return
            },
          })}
          expandable={{
            expandedRowRender: (record) => {
              let detalle: any = record.detalle
              return (
                <>
                <Row><b>Dirección:&nbsp;&nbsp; </b>{record.direccion}</Row>
                {record.observacionrrhh&&<Row><b>Observación de Aprobación RRHH:&nbsp;&nbsp; </b>{record.observacionrrhh}</Row>}
                {record.observacionaprobacion&&<Row><b>Observación de Aprobación:&nbsp;&nbsp; </b>{record.observacionaprobacion}</Row>}
                <Table
                  style={{ width: "100%", margin: "10px" }}
                  columns={columnsuniforme}
                  dataSource={detalle}
                  pagination={false}
                />
                </>
              )
            },
            onExpand: handleExpand,
            expandedRowKeys: ExpandedRowKeys,
          }}
        />
      </Row>
      {llavesimages && <div style={{ display: 'none' }}>
        <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis)}}>
          {llavesimages.map((item:any)=><Image src={`${url}apiallku-public/api/${item.Llave}`}/>)}
        </Image.PreviewGroup>
      </div>}
    </>
  )
}
