import { llave } from "config/constants"
import { loaderRoute } from "config/methods"
import AES from "crypto-js/aes"
import Utf8 from "crypto-js/enc-utf8"
import { Principal } from "pages/contentprincipal/Principal"
import BodegaPOS from "pages/contentsistemas/BodegaPOS"
import { RegistroVouchers, TablaRegistro } from "pages/contentsistemas/registro"
import { RegistroTareas } from "pages/contentsistemas/RegistroTareas"
import { RegistroTareasCompleto } from "pages/contentsistemas/RegistroTareasCompleto"
import { TareasEnProceso } from "pages/contentsistemas/TareasEnProceso"
import { TareasFinalizadas } from "pages/contentsistemas/TareasFinalizadas"
import { TareasPendientes } from "pages/contentsistemas/TareasPendientes"
import { type RouteObject } from "react-router-dom"

export const rutasSistemas: RouteObject[] = [
  {
    path: "/sistemas",
    element: <Principal />,
  },
  {
    path: "/sistemas/registro-vouchers",
    element: <RegistroVouchers />,
    loader: () => loaderRoute("fapifsg-pr/vouchers/listar-bodegas"),
    children: [
      {
        path: ":desde/:valor/:bodega",
        element: <TablaRegistro />,
        loader: ({ params }) => {
          const { desde, bodega, valor } = params
          const urlParams = new URLSearchParams()

          urlParams.append("desde", desde ?? "")
          bodega &&
            bodega !== "0" &&
            urlParams.append("bodega", AES.decrypt(decodeURIComponent(bodega ?? ""), llave).toString(Utf8))
          valor && valor !== "0" && urlParams.append("valor", valor)

          return loaderRoute(`fapifsg-pr/vouchers/listar-vouchers?${new URLSearchParams(urlParams)}`)
        },
      },
    ],
  },
  {
    path: "/sistemas/bodega-POS",
    element: <BodegaPOS />,
  },
  {
    path: "/sistemas/registro-tareas",
    element: <RegistroTareas/>,
  },
  {
    path: "/sistemas/ver",
    element: <Principal/>,
  },
  {
    path: "/sistemas/ver/tareas-pendientes",
    element: <TareasPendientes/>,
  },
  {
    path: "/sistemas/ver/tareas-en-proceso",
    element: <TareasEnProceso/>,
  },
  {
    path: "/sistemas/ver/tareas-finalizadas",
    element: <TareasFinalizadas/>,
  },
  {
    path: "/sistemas/registro-tareas-completas",
    element: <RegistroTareasCompleto/>,
  },
]
