import { RadioChangeEvent } from "antd";
import { RuleObject } from "antd/lib/form";
import { useState } from "react";

export const useListCuota = (form: any) => {
  const [radioValue, setRadioValue] = useState<boolean>(true);
  const [isLimited, setIsLimited] = useState(true);

  const toggleLimit = (checked: boolean, name: number) => {
    setIsLimited(!checked); // Cambia el estado de isLimited

    if (checked) {
      form.setFieldsValue({
        distribucionIn: form
          .getFieldValue("distribucionIn")
          .map((item: any, index: any) => (index === name ? { ...item, valor: 0 } : item)),
      });
    }
  };

  const onChangeEsDevoluciones = (e: RadioChangeEvent) => {
    // if (!e.target.value) {
    //   form.setFieldsValue({ devoluciones: [] });
    // } else {
    //   form.setFieldsValue({ devoluciones: ["devolucion_vencimiento", "devolucion_otros"] });
    // }

    setRadioValue(e.target.value);
  };

  const validateCheckboxes = (_: RuleObject, value: string[]) => {
    if (radioValue && (!value || value.length === 0)) {
      return Promise.reject(new Error("Seleccione al menos una opción de devolución"));
    }
    return Promise.resolve();
  };
  return {
    toggleLimit,
    onChangeEsDevoluciones,
    validateCheckboxes,
    radioValue,
    isLimited,
    setIsLimited,
    setRadioValue,
  };
};
