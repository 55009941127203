import { Button, Col, Row, Table, Card, DatePicker, Select } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import "moment/locale/es";
import { useArchivosCuentasPendientes } from "hooks/inventarios/useArchivosCuentasPendientes";
import moment from "moment";
import { PdfViewer } from "components/otros/pdf-viewer";

export function ActualizacionArchivos() {
  const {
    columns,
    columnasVisual,
    estadouser,
    filteredData,
    rowSelection,
    handleUpdate,
    estadoBoton,
    estadoBotonUpdate,
    mes,
    setMes,
    setOpenModalPdf,
    openModalPdf,
    selecteds,
    handleChange
  } = useArchivosCuentasPendientes();

  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <PdfViewer
        visible={openModalPdf}
        onCancel={() => setOpenModalPdf(false)}
        src={selecteds.enlacePdf}
        title="Archivo"
      />
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Row style={{ marginBottom: "10px" }}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <DatePicker
              value={mes}
              picker="month"
              placeholder="Seleccione un mes"
              format="MMMM [del] YYYY"
              onChange={(value) => setMes(value ?? moment())}
              inputReadOnly
            />
            <Select
              defaultValue="1"
              style={{ width: 130, marginLeft: "10px", marginTop: "0px" }}
               onChange={handleChange}
              options={[
                {
                  value: "1",
                  label: "Inventario",
                },
                {
                  value: "2",
                  label: "Caducados",
                },
              /*  {
                  value: "3",
                  label: "Otros",
                },*/
              ]}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row justify={"end"}>
              {estadoBoton && (
                <Button
                  type="primary"
                  style={{
                    width: "200px",
                  }}
                  loading={estadoBotonUpdate}
                  onClick={handleUpdate}
                  icon={<SaveOutlined className="iconbutton" />}
                >
                  Actualizar
                </Button>
              )}
            </Row>
          </Col>
        </Row>

        <Card>
          <Table
            rowSelection={!estadouser ? rowSelection : undefined}
            columns={!estadouser ? columns : columnasVisual}
            dataSource={filteredData}
            scroll={{ x: true }}
            size="small"
            pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default ActualizacionArchivos;
