import Table, { ColumnsType } from 'antd/es/table'
import React from 'react'

export interface DatatypeLibreta{
    key: React.Key
    nombres: string
    correo: string
    celular: string
}

export function useComponentLibreta() {

    const Proveedores:ColumnsType<DatatypeLibreta>=[
      Table.SELECTION_COLUMN,
        {
            title: "Nombres",
            dataIndex: "nombres ", 
            className: "tableproducto", 
            width: "200px",
          },
          {
            title: "Correo",
            dataIndex: "correo ",
            className: "tableproducto", 
            width: "200px",
          },
          {
            title: "Celular",
            className: "tableproducto", 
            dataIndex: "Celular ",
          },
    ]

    const SanGregorio:ColumnsType<DatatypeLibreta>=[
      Table.SELECTION_COLUMN,
        {
            title: "Nombres",
            dataIndex: "nombres ", 
            className: "tableproducto", 
            width: "200px",
          },
          {
            title: "Correo",
            className: "tableproducto", 
            dataIndex: "correo ",
            width: "200px",
          },
          {
            title: "Celular",
            className: "tableproducto", 
            dataIndex: "Celular ",
          },
    ]

  return {
    Proveedores, 
    SanGregorio,
  }
}