import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { url } from "../../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
//import { useExport } from "hooks/otros/useExport";
import { RangePickerProps } from "antd/lib/date-picker";

type TopFarmaciasMasPerdidas = {
  Farmacia: string;
  key: React.Key;
  CantidadPerdida: number;
  ValorPerdido: number;
};
type TopProductosPerdidos = {
  Descripcion: string;
  key: React.Key;
  CantidadPerdida: number;
  ValorPerdido: number;
};
type TopProductosCaducados = {
  Descripcion: string;
  key: React.Key;
  CantidadPerdida: number;
  ValorPerdido: number;
};
const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Estadisticas" }];

const openKeys = [breadcrumbs[0].Label];
export function useInventarioEstadisticas() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);
  const [datosInicial, setDatos] = useState<TopFarmaciasMasPerdidas[]>([]);
  const [datosPerdidos, setDatosPerdidos] = useState<TopProductosPerdidos[]>([]);
  const [dataEstado, setDataEstado] = useState<boolean>(false);

  const [datosCaducados, setDatosCaducados] = useState<TopProductosCaducados[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<TopFarmaciasMasPerdidas[]>([]);
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<TopFarmaciasMasPerdidas>();
  const [graph, setGraph] = useState<string[]>([]);
  const [graphProductosPerdidos, setGraphProductosPerdidos] = useState<string[]>([]);

  const [graphProductosCaducados, setGraphProductosCaducados] = useState<string[]>([]);
  const [segmentoSeleccionado, setSegmentoSeleccionado] = useState("Farmacias perdidas");

  const [estadoBoton, setBoton] = useState<boolean>(false);

  // const { exportToExcel } = useExport();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();

  const getBodegas = useCallback(
    async (fechaInicio: Moment, fechaFin: Moment) => {
      setDataEstado(false);
      try {
        const res = await fetch(
          url + "apifsg-pr/inventarios/obtener_estadisticas",
          optionsPost({ fechaInicio: fechaInicio!.format("DD/MM/YYYY"), fechaFin: fechaFin!.format("DD/MM/YYYY") })
        );
        if (isError(res)) throw new Error();
        const respuesta = await res.json();
        if (respuesta.msg !== "err") {
          if (respuesta.data) {
            const uniqueDataChart = createOptionSelect(respuesta.data[0]);
            setGraph(uniqueDataChart);
            const respuestas: TopFarmaciasMasPerdidas[] = respuesta.data;
            setDatos(respuestas);
          } else {
            setGraph([]);
            setDatos([]);
          }

          if (respuesta.dataPerdidos) {
            const uniqueDataChartProductosPerdidos = createOptionSelectPerdidas(respuesta.dataPerdidos[0]);
            setGraphProductosPerdidos(uniqueDataChartProductosPerdidos);
            const respuestaPerdidos: TopProductosPerdidos[] = respuesta.dataPerdidos;
            setDatosPerdidos(respuestaPerdidos);
          } else {
            setGraphProductosPerdidos([]);
            setDatosPerdidos([]);
          }

          if (respuesta.datosCaducados) {
            const uniqueDataChartProductosCaducados = createOptionSelectCaducados(respuesta.datosCaducados[0]);
            setGraphProductosCaducados(uniqueDataChartProductosCaducados);
            const respuestaCaducados: TopProductosCaducados[] = respuesta.datosCaducados;
            setDatosCaducados(respuestaCaducados);
          } else {
            setGraphProductosCaducados([]);
            setDatosCaducados([]);
          }

          setDataEstado(true);
          setBoton(true);
        } else {
          setDataEstado(false);
          setDatos([]);
          setDatosPerdidos([]);
          setDatosCaducados([]);
          setBoton(false);

          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        setDataEstado(false);
        setDatos([]);
        setDatosPerdidos([]);
        setDatosCaducados([]);
        setBoton(false);
        return { msg: "no_conexion", status: false };
      }
    },
    [optionsPost, isError]
  );

  useEffect(() => {
    const fechaFin = moment(); // Fecha actual
    const fechaInicio = moment().subtract(1, "month"); // Un mes antes de la fecha actual
    //setFechas([fechaInicio,fechaFin]);
    getBodegas(fechaInicio, fechaFin);
  }, [getBodegas]);

  const createOptionSelect = (data: TopFarmaciasMasPerdidas) => {
    const uniqueValues = new Set();
    Object.entries(data).forEach((value) => {
      if (typeof value[1] === "number") {
        if (value[0] !== "margen" && value[0] !== "key" && value[0] !== "semana") uniqueValues.add(value[0]);
      }
    });

    return Array.from(uniqueValues) as string[];
  };
  const createOptionSelectPerdidas = (data: TopProductosPerdidos) => {
    const uniqueValues = new Set();
    Object.entries(data).forEach((value) => {
      if (typeof value[1] === "number") {
        if (value[0] !== "margen" && value[0] !== "key" && value[0] !== "semana") uniqueValues.add(value[0]);
      }
    });

    return Array.from(uniqueValues) as string[];
  };
  const createOptionSelectCaducados = (data: TopProductosCaducados) => {
    const uniqueValues = new Set();
    Object.entries(data).forEach((value) => {
      if (typeof value[1] === "number") {
        if (value[0] !== "margen" && value[0] !== "key" && value[0] !== "semana") uniqueValues.add(value[0]);
      }
    });

    return Array.from(uniqueValues) as string[];
  };
  const handleSegmentChange = (value: string | number) => {
    setSegmentoSeleccionado(value.toString());
  };

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datosInicial);
    } else {
      const filtered = datosInicial.filter((item) => item.Farmacia.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datosInicial]);

  const columns: TableColumnsType<TopFarmaciasMasPerdidas> = [
    {
      title: "Codigo",
      dataIndex: "key",
      key: "key",
      //sortDirections: ["descend", "ascend"],
      width: "75px",
    },
    {
      title: "Farmacia",
      dataIndex: "Farmacia",
      key: "Farmacia",
      width: "200px",
      ...getColumnSearchPropsNewVisorReporte("Farmacia"),
    },
    {
      title: "Cantidad perdida",
      dataIndex: "CantidadPerdida",
      key: "CantidadPerdida",
      sortDirections: ["descend", "ascend"],
      width: "115px",
    },
    {
      title: "Valor perdido",
      dataIndex: "ValorPerdido",
      key: "ValorPerdido",
      width: "115px",
      sortDirections: ["descend", "ascend"],
    },
  ];
  const columnProductosPerdidos: TableColumnsType<TopProductosPerdidos> = [
    {
      title: "Codigo",
      dataIndex: "key",
      key: "key",
      //sortDirections: ["descend", "ascend"],
      width: "75px",
    },
    {
      title: "Descripcion",
      dataIndex: "Descripcion",
      key: "Descripcion",
      width: "200px",
      //...getColumnSearchPropsNewVisorReporte("Descripcion"),
    },
    {
      title: "Cantidad perdida",
      dataIndex: "CantidadPerdida",
      key: "CantidadPerdida",
      sortDirections: ["descend", "ascend"],
      width: "115px",
    },
    {
      title: "Valor perdido",
      dataIndex: "ValorPerdido",
      key: "ValorPerdido",
      width: "115px",
      sortDirections: ["descend", "ascend"],
    },
  ];
  const columnProductosCaducados: TableColumnsType<TopProductosCaducados> = [
    {
      title: "Codigo",
      dataIndex: "key",
      key: "key",
      //sortDirections: ["descend", "ascend"],
      width: "75px",
    },
    {
      title: "Descripcion",
      dataIndex: "Descripcion",
      key: "Descripcion",
      width: "200px",
      //...getColumnSearchPropsNewVisorReporte("Descripcion"),
    },
    {
      title: "Cantidad perdida",
      dataIndex: "CantidadPerdida",
      key: "CantidadPerdida",
      sortDirections: ["descend", "ascend"],
      width: "115px",
    },
    {
      title: "Valor perdido",
      dataIndex: "ValorPerdido",
      key: "ValorPerdido",
      width: "110px",
      sortDirections: ["descend", "ascend"],
    },
  ];
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Deshabilita las fechas futuras respecto al final del día actual
    return current && current > moment().endOf("day");
  };
  /*const handleClickDownloadData = () => {
    // Transforma cada entrada de datosInicial para incluir Dependientes y Usuarios como strings
    const datosParaExportar = datosInicial.map((item) => ({
      ...item,
        Dependientes: JSON.stringify(item.Dependientes),
      Usuarios: JSON.stringify(item.Usuarios),
    }));

    const texto = "detalleInventario" + fechaInicio!.format("YYYYMM");
    exportToExcel(datosParaExportar ?? [], texto);
  };*/

  return {
    columns,
    columnProductosPerdidos,
    columnProductosCaducados,
    filteredData,
    datosPerdidos,
    datosCaducados,
    //handleClickDownloadData,
    estadoBoton,
    disabledDate,
    graph,
    getBodegas,
    graphProductosPerdidos,
    graphProductosCaducados,
    handleSegmentChange,
    segmentoSeleccionado,
    dataEstado,
  };
}
