import { Col, ColProps, Form, Radio, RadioGroupProps, Select } from "antd"
import type { SelectProps, DefaultOptionType } from "antd/lib/select"
import { TipoSeleccion } from "hooks/Franquiciados/usePago"

type Props = {
  tipoSeleccion: TipoSeleccion
  onChangeTipoSeleccion: RadioGroupProps["onChange"]
  onChangeSelect: SelectProps["onChange"]
  bodegas: DefaultOptionType[]
  franquiciados: DefaultOptionType[]
  radioColProps?: ColProps
  selectColProps?: ColProps
}

export function BusquedaBodegaFranquiciado({
  tipoSeleccion,
  onChangeTipoSeleccion,
  onChangeSelect,
  bodegas,
  franquiciados,
  radioColProps,
  selectColProps,
}: Props) {
  return (
    <>
      <Col xs={24} sm={16} md={12} lg={12} xl={7} {...radioColProps}>
        <Form.Item name="buscarPor" label="Buscar por" initialValue={TipoSeleccion.Bodega}>
          <Radio.Group buttonStyle="solid" onChange={onChangeTipoSeleccion}>
            <Radio.Button value={TipoSeleccion.Bodega}>Bodegas</Radio.Button>
            <Radio.Button value={TipoSeleccion.Franquiciado}>Franquiciados</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col xs={24} sm={16} md={12} lg={12} xl={8} {...selectColProps}>
        {tipoSeleccion === TipoSeleccion.Bodega ? (
          <Form.Item name="bodega" label="Bodega">
            <Select
              options={bodegas.map(({ value, label }) => ({ value, label }))}
              showSearch
              optionFilterProp="label"
              onChange={onChangeSelect}
            />
          </Form.Item>
        ) : (
          <Form.Item name="franquiciado" label="Franquiciado">
            <Select options={franquiciados} showSearch optionFilterProp="label" onChange={onChangeSelect} />
          </Form.Item>
        )}
      </Col>
    </>
  )
}
