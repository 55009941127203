import { Button, Card, Col, DatePicker, Form, InputNumber, Radio, Row, Select, Table } from "antd";
import { ButtonExcel } from "components/otros/buttons";
import { useReporteVentas } from "hooks/reportesGeneral/useReporteVentas";
import { useVT } from "virtualizedtableforantd4";

const ReporteVentas = () => {
  const {
    selectedItems,
    initialOptions,
    headTableReport,
    handleChangeType,
    selectOptions,
    form,
    bodegas,
    handleChangeBodegas,
    handleClickGenerar,
    columnsTableReport,
    handleClickDownload,
    handleTableChange,
    dataFiltrada,
    loader,
    selectedItemsOptions,
    handleChangeOption,
  } = useReporteVentas();
  const [vt] = useVT(() => ({ scroll: { y: "50vh" } }), []);

  return (
    <Card>
      <Form form={form}>
        <Row gutter={[10, 5]}>
          <Col xs={24} lg={6}>
            <Form.Item label='Fecha:' name='fecha' rules={[{ required: true, message: "Seleccione el periodo" }]}>
              <DatePicker.RangePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={5}>
            <Form.Item
              label='Reporte por:'
              name='tipo'
              rules={[{ required: true, message: "Seleccione un tipo de reporte" }]}
            >
              <Select options={initialOptions} onChange={handleChangeType}></Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={13}>
            <Form.Item
              label='Escoja las opciones:'
              name='optionSelectType'
              rules={[{ required: true, message: "Seleccione una o varias opciones" }]}
            >
              <Select
                allowClear
                value={selectedItemsOptions}
                optionFilterProp='label'
                mode='multiple'
                options={selectOptions}
                onChange={handleChangeOption}
                autoClearSearchValue={false}
                style={{ maxHeight: "55px", overflowY: "scroll" }}
                // dropdownRender={(menu) => <div style={{ maxHeight: "100px", overflowY: "scroll" }}>{menu}</div>}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col xs={24} md={12} lg={10}>
            <Form.Item label='Bodegas:' name='cod_bodegas' rules={[{ required: true, message: "Seleccione una opción" }]}>
              <Select
                allowClear
                optionFilterProp='label'
                mode='multiple'
                options={bodegas}
                onChange={handleChangeBodegas}
                value={selectedItems}
                style={{ maxHeight: "45px", overflowY: "scroll" }}
                autoClearSearchValue={false}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={5}>
            <Form.Item label='Consumidor Final:' name='es_final' rules={[{ required: true, message: "Marque una casilla" }]}>
              <Radio.Group>
                <Radio value={1}>Sí</Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} lg={3}>
            <Form.Item label='Cajas:' name='cajas'>
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={3}>
            <Form.Item label='Monto:' name='monto'>
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={3}>
            <Button type='primary' style={{ width: "100%" }} onClick={handleClickGenerar} disabled={loader}>
              Generar
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        onChange={handleTableChange}
        pagination={false}
        scroll={{ x: 1400, y: "50vh" }}
        components={vt}
        columns={headTableReport}
        size='small'
        dataSource={columnsTableReport}
        loading={loader}
      ></Table>

      {dataFiltrada.length > 0 && (
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
          <ButtonExcel buttonProps={{ onClick: handleClickDownload, style: { width: "auto", background: "green" } }}>
            Descargar
          </ButtonExcel>
        </div>
      )}
    </Card>
  );
};

export default ReporteVentas;
