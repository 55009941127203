import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";

import { optionsPOST, url_interna, url } from "../../config/constants";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { notification, TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";
import { optionsGet, swalWarning } from "config/methods";

type InventarioReporte = {
  Cedula: string;
  Nombres_Rol: string;
  VentaTotal: number;
  Dias_trabajados: number;
  Facturas: number;
  VentaFarmacia: number;
  Cod_Bodega: number;
  Descripcion: string;
  Ciudad: string;
  Cuota: number;
  Cumplimiento: number;
  Porcentaje: number;
  coordinador: number;
  Comision: number;
  key: React.Key;
};
type DatosPeriodo = {
  label: string;
  value: string;
};

type ComisionReporte = {
  Cedula: string;
  Nombres_Rol: string;
  Comision: number;
  key: React.Key;
};

const tipos = [
  {
    value: 1,
    label: "Comisión farmacia",
  },
  {
    value: 2,
    label: "Comisión 1%",
  },
  {
    value: 3,
    label: "Comisión billeteate",
  },
];
const breadcrumbs = [{ Label: "Indicadores", Url: "/comision-cuotas-farmacia" }, { Label: "Comisión farmacias" }];
//const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Productividad" }];

const openKeys = [breadcrumbs[0].Label];
export function useComisionCuotasFarmacias() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);
  const [datos, setDatos] = useState<InventarioReporte[]>([]);
  const [datos2, setDatos2] = useState<ComisionReporte[]>([]);
  const [mes, setMes] = useState<Moment>(moment().subtract(1, "months"));
  const [searchText] = useState("");

  const [estadoBotones, setDataBoton] = useState<boolean>(false);
  const [dataEstado, setDataEstado] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<InventarioReporte[]>([]);
  const { getColumnSearchPropsNew } = useSearchTableNew<InventarioReporte>();
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<ComisionReporte>();
  const [datosPeriodos, setDatosPeriodos] = useState<DatosPeriodo[]>([]);
  const [tipoComision, setTipoComision] = useState<number>(1);

  const { exportToExcel } = useExport();
  const { isError } = useVerifyResponse();

  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };

  const fechaInicio = moment().startOf("month");

  const getComisiones = async (fechaInicio: Moment, fechaFin: Moment) => {
    try {
      setDataEstado(false);
      setDataBoton(true);
      const res = await fetch(
        url_interna + "api/inventarios/obtener_comision_cuotas_bodega",
        optionsPOST(
          JSON.stringify({
            fechaInicio: fechaInicio!.format("DD/MM/YYYY"),
            fechaFin: fechaFin!.format("DD/MM/YYYY"),
            periodo: fechaInicio!.format("YYYYMM"),
            tipo: tipoComision,
          }),
          { "api-token": "apitoken" }
        )
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg) {
        // const respuestas: InventarioReporte[] = respuesta.data;
        if (respuesta.data) {
          setDataBoton(false);
          setDataEstado(true);
          if (tipoComision === 1) setDatos(respuesta.data);
          else if (tipoComision === 2 || tipoComision === 3) setDatos2(respuesta.data);
          if (fechaInicio.format("YYYYMM") !== moment().subtract(1, "months").format("YYYYMM")) {
            swalWarning({ text: "Estás generando un reporte de un período que ya ha expirado" });
          }
        } else {
          setDataBoton(false);
          notification.warning({
            message: "Error",
            description: `No hay registros`,
            placement: "bottomRight",
          });
          setDataEstado(false);
        }
      } else {
        setDataBoton(false);
        setDataEstado(false);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setDataBoton(false);
      setDataEstado(false);
      return { msg: "no_conexion", status: false };
    }
  };

  const handleChangeButton = () => {
    const inicioDelMes = mes.clone().startOf("month");
    const finDelMes = mes.clone().endOf("month");

    // Establece el día al final del mes

    if (inicioDelMes && finDelMes) {
      getComisiones(inicioDelMes, finDelMes);
    }
  };

  const getDatosIniciales = useCallback(async () => {
    setDatosPeriodos([]);
    try {
      const res = await fetch(url + "apifsg-pr/bodegas/listar-periodos", optionsGet());
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        const respuestas: DatosPeriodo[] = respuesta.data;
        if (respuestas.length > 0) {
          setDatosPeriodos(
            respuestas.map(({ label, value }) => ({
              label,
              value,
            }))
          );
        } else {
          setDatosPeriodos([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [isError]);

  useEffect(() => {
    getDatosIniciales();
  }, [getDatosIniciales]);

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.Descripcion.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  const setRowClassName = (record: InventarioReporte) => {
    if (record.coordinador > 0 && record.coordinador !== record.Cod_Bodega) {
      return "highlight-row"; // Esta es la clase CSS que se aplicará
    }
    return ""; // No se aplica ninguna clase especial si no se cumplen las condiciones
  };
  const columns: TableColumnsType<InventarioReporte> = [
    {
      title: "Cédula",
      dataIndex: "Cedula",
      key: "Cedula",

      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNew("Cedula"),
    },
    {
      title: "Dependiente",
      dataIndex: "Nombres_Rol",
      key: "Nombres_Rol",

      sorter: (a, b) => a.Nombres_Rol.localeCompare(b.Nombres_Rol),

      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNew("Nombres_Rol"),
    },
    {
      title: "Venta personal",
      align: "right",
      dataIndex: "VentaTotal",
      key: "VentaTotal",
      className: "letraPequenaVisor2",
      render: (_, record) => (
        <span style={{ color: record.VentaTotal === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.VentaTotal)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Días",
      dataIndex: "Dias_trabajados",
      key: "Dias_trabajados",
      className: "letraPequenaVisor2",
      align: "right",
      sorter: (a, b) => a.Porcentaje - b.Porcentaje,
    },
    {
      title: "Farmacia",
      dataIndex: "Descripcion",

      className: "letraPequenaVisor2",
      key: "Descripcion",
      ...getColumnSearchPropsNew("Descripcion"),
    },
    {
      title: "Cuota",
      dataIndex: "Cuota",
      key: "Cuota",
      align: "right",

      className: "letraPequenaVisor2",
      sorter: (a, b) => a.Cuota - b.Cuota,
      render: (_, record) => (
        <span style={{ color: record.Cuota === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.Cuota)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Venta",
      dataIndex: "Cumplimiento",
      key: "Cumplimiento",

      className: "letraPequenaVisor2",
      align: "right",
      sorter: (a, b) => a.Cumplimiento - b.Cumplimiento,
      render: (_, record) => (
        <span style={{ color: record.Cumplimiento === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.Cumplimiento)}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Porcentaje",
      dataIndex: "Porcentaje",
      key: "Porcentaje",
      className: "letraPequenaVisor2",
      align: "right",
      sorter: (a, b) => a.Porcentaje - b.Porcentaje,
      render: (_, record) => (
        <span style={{ color: record.Porcentaje === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.Porcentaje) + "%"}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Comision",
      dataIndex: "Comision",
      key: "Comision",
      className: "letraPequenaVisor2",
      align: "right",
      sorter: (a, b) => a.Porcentaje - b.Porcentaje,
    },
  ];

  const columnsOther: TableColumnsType<ComisionReporte> = [
    {
      title: "Cédula",
      dataIndex: "Cedula",
      key: "Cedula",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNewVisorReporte("Cedula"),
    },
    {
      title: "Dependiente",
      dataIndex: "Nombres_Rol",
      key: "Nombres_Rol",
      // sorter: (a, b) => a.Nombres_Rol.localeCompare(b.Nombres_Rol),
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNewVisorReporte("Nombres_Rol"),
    },
    {
      title: "Comision",
      dataIndex: "Comision",
      key: "Comision",
      className: "letraPequenaVisor2",
      align: "right",
      sorter: (a, b) => a.Comision - b.Comision,
      render: (_, record) => (
        <span style={{ color: record.Comision === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.Comision)}</span>
      ),
    },
  ];

  const handleClickDownloadData = () => {
    let datosParaExportar;
    if (tipoComision === 1) datosParaExportar = datos.map(({ key, ...resto }) => resto);
    else datosParaExportar = datos2.map(({ key, ...resto }) => resto);

    exportToExcel(
      datosParaExportar,
      `reporte-${
        tipoComision === 1 ? "Comisiones farmacia_" : tipoComision === 2 ? "Comision 1%_" : "Comision Billeteate_"
      }${mes.format("YYYYMM")} `
    );
  };

  const handleChangeComition = (value: number) => {
    setDataEstado(false);
    setDatos([]);
    setDatos2([]);
    setTipoComision(value);
  };

  return {
    datos2,
    columns,
    filteredData,
    handleClickDownloadData,
    datosPeriodos,
    fechaInicio,
    setMes,
    mes,
    dataEstado,
    estadoBotones,
    handleChangeButton,
    setRowClassName,
    tipos,
    tipoComision,
    handleChangeComition,
    columnsOther,
    setDatos,
    setDatos2,
    setDataEstado,
  };
}
