import { Col, Form, Input, InputNumber, Modal, Row, Switch, Table } from "antd"
import { useEffect } from "react"

import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSwal } from "hooks/otros/useSwal"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { type TipoGasto, useGastosFranquiciados } from "hooks/Franquiciados/useGastosFranquiciados"

import { url } from "config/constants"
import { SubTableGastos } from "components/franquiciados/sub-table-gastos"
import { ModalAsignarGastoBodega } from "components/franquiciados/modal-asignar-gasto-bodega"
import { ButtonAdd } from "components/otros/buttons"

export function GastosFranquiciados() {
  const {
    setSelectedData,
    setOpenModal,
    selectedData,
    openModal,
    data,
    columns,
    reFetcher,
    openModalAsignarBodega,
    setOpenModalAsignarBodega,
    selectedBodega,
    setSelectedBodega,
  } = useGastosFranquiciados()

  return (
    <>
      <Row
        gutter={[0, 16]}
        style={{ padding: "16px 16px", marginLeft: 0, marginRight: 0, backgroundColor: "#fff" }}
        justify="end"
      >
        <Col xs={24} sm={24} md={5} lg={5} xl={5}>
          <ButtonAdd
            buttonProps={{
              onClick() {
                setSelectedData(undefined)
                setOpenModal(true)
              },
            }}
          >
            Registrar Gasto
          </ButtonAdd>
        </Col>
      </Row>
      <Row wrap style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            size="small"
            dataSource={data}
            columns={columns}
            pagination={{
              pageSize: 5,
              size: "default",
            }}
            expandable={{
              rowExpandable: ({ bodegas_asignadas }) => bodegas_asignadas?.length > 0,
              expandedRowRender: ({ bodegas_asignadas }) => (
                <SubTableGastos
                  dataSource={bodegas_asignadas}
                  onClickButtonEdit={({ cod_bodega, cod_gasto }) => {
                    setSelectedData(data.findIndex(d => d.key === cod_gasto))
                    setSelectedBodega(cod_bodega)
                    setOpenModalAsignarBodega(true)
                  }}
                />
              ),
            }}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
      <ModalGastos
        open={openModal}
        onClose={() => setOpenModal(false)}
        data={selectedData || selectedData === 0 ? data[selectedData] : undefined}
        refetcher={reFetcher}
      />
      <ModalAsignarGastoBodega
        open={openModalAsignarBodega}
        gasto={selectedData || selectedData === 0 ? data[selectedData].key : 0}
        handleClose={() => {
          setOpenModalAsignarBodega(false)
          setSelectedBodega(undefined)
        }}
        reFetcher={reFetcher}
        data={
          selectedData || selectedData === 0
            ? data[selectedData]?.bodegas_asignadas.find(({ cod_bodega }) => cod_bodega === selectedBodega)
            : undefined
        }
      />
    </>
  )
}

type FormGasto = {
  gasto: string
  valor: number
  fijo: boolean
  estado: boolean
}

type ModalGastosProps = {
  open: boolean
  onClose(): void
  data?: TipoGasto
  refetcher(): void
}

function ModalGastos({ open, onClose, data, refetcher }: ModalGastosProps) {
  const [form] = Form.useForm<FormGasto>()

  const { optionsPost } = useLocalStorage()

  const { swalError, swalSuccess } = useSwal()

  const { isError } = useVerifyResponse()

  useEffect(() => {
    const datos = data
      ? {
          gasto: data.gasto,
          valor: data.valor,
          fijo: data.fijo,
          estado: data.estado,
        }
      : {
          gasto: undefined,
          valor: 0,
          fijo: false,
          estado: true,
        }
    form.setFieldsValue(datos)
  }, [data, form])

  const handleGuardarGasto = async () => {
    onClose()
    const values = await form.validateFields()
    let tipoRuta: "modificar" | "registrar", newValues: (FormGasto & { cod_gasto: number }) | FormGasto
    if (data) {
      newValues = { ...values, cod_gasto: data.key }
      tipoRuta = "modificar"
    } else {
      newValues = values
      tipoRuta = "registrar"
    }
    try {
      const res = await fetch(`${url}fapifsg-pr/franquiciados/${tipoRuta}-tipo-gasto`, optionsPost(newValues))
      if (isError(res)) throw new Error()
      const text = await res.json()
      swalSuccess({ text })
      form.resetFields()
      refetcher()
    } catch (e) {
      swalError({ text: `No se pudo ${tipoRuta} el tipo de gasto` })
    }
  }

  return (
    <Modal
      open={open}
      title="Gastos de franquiciados"
      okText={data ? "Modificar" : "Registrar"}
      cancelText="Cancelar"
      onCancel={onClose}
      transitionName=""
      // style={{ top: '50px' }}
      onOk={handleGuardarGasto}
    >
      <Form
        form={form}
        // layout="vertical"
        wrapperCol={{ xs: 21, sm: 21, md: 21, lg: 21, xl: 21 }}
        labelCol={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }}
        requiredMark="optional"
        // className="scrollableDiv"
        // style={{ height: '25vh' }}
      >
        <Form.Item name="gasto" label="Gasto" rules={[{ required: true, message: "Ingrese el nombre del gasto" }]}>
          <Input maxLength={150} />
        </Form.Item>
        <Row>
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <Form.Item
              name="valor"
              label="Valor"
              initialValue={0}
              rules={[{ required: true, message: "Ingrese el valor" }]}
              labelCol={{ xs: 7, sm: 7, md: 7, lg: 7, xl: 7 }}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <Form.Item
              name="fijo"
              label="Fijo"
              initialValue={false}
              valuePropName="checked"
              required
              labelCol={{ xs: 7, sm: 7, md: 7, lg: 7, xl: 7 }}
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <Form.Item
              name="estado"
              label="Estado"
              initialValue={true}
              valuePropName="checked"
              required
              labelCol={{ xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }}
            >
              <Switch disabled={!Boolean(data)} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default GastosFranquiciados
