import { Form } from "antd";
import { url } from "config/constants";
import { LayoutContext } from "context/LayoutContext";
import { RotacionEmpleadoContext } from "context/indicadores/RotacionEmpleadoContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import moment from "moment";
import { useEffect, useContext, useCallback } from "react";
import * as XLSX from "xlsx";

const breadcrumbs = [{ Label: "Indicador Empleados" }];

interface dataRow {
  Mes: string;
  fecha?: string;
  entradas: number;
  key: string;
  rotacion: number;
  salidas: number;
  trabajadores: number;
}

interface ResponseData {
  msg: boolean;
  data: dataRow[];
  data1: dataRow[];
  data2: dataRow[];
}

export function useAdministrarRotacion() {
  const [form] = Form.useForm();
  const { optionsPost } = useLocalStorage();
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);

  const {
    setHeadTable,
    setColumnTable,
    setDataChart,
    setSelectedRow,
    selectedRow,
    setHeadTableYdt,
    setColumnTableYdt,
    setColumnTableMotivos,
    setColumnTableTiempos,
    setGraph,
    setGraphX2,
    setDataChartLast,
  } = useContext(RotacionEmpleadoContext);

  const createHeadTable = (data: dataRow) => {
    const columns = Object.keys(data)
      .map((key) => {
        return {
          title: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
          dataIndex: key,
        };
      })
      .filter((column) => {
        return column.dataIndex !== "key";
      });
    return columns;
  };

  const createOptionSelect = (data: dataRow) => {
    const uniqueValues = new Set();
    Object.entries(data).forEach((value) => {
      if (typeof value[1] === "number") {
        if (value[0] !== "rotación %") uniqueValues.add(value[0]);
      }
    });

    return Array.from(uniqueValues) as string[];
  };

  const handleInitialData = useCallback(
    async (_: number, currentYear: number) => {
      setColumnTableMotivos([]);
      setColumnTableTiempos([]);
      setHeadTable([]);
      setColumnTable([]);
      form.setFieldsValue({ opcionesx1: [] });
      setDataChart([]);
      setGraph([]);
      setSelectedRow([]);

      setHeadTableYdt([]);
      setColumnTableYdt([]);
      const respuesta: ResponseData = await fetch(
        url + "apifsg-pr/reportes/ic-empleados/",
        optionsPost({ fecha: currentYear })
      ).then((data) => data.json());

      if (respuesta.msg) {
        let data = createHeadTable(respuesta.data[0]);
        setHeadTable(data);

        const uniqueDataChart = createOptionSelect(respuesta.data[0]);

        form.setFieldsValue({ opcionesx1: uniqueDataChart });
        setGraph(uniqueDataChart);
        setDataChart(uniqueDataChart);
        setColumnTable(respuesta.data);
      }
    },
    [
      optionsPost,
      setHeadTable,
      setColumnTable,
      setDataChart,
      form,
      setColumnTableMotivos,
      setColumnTableTiempos,
      setColumnTableYdt,
      setGraph,
      setHeadTableYdt,
      setSelectedRow,
    ]
  );

  useEffect(() => {
    handleInitialData(moment().year(), moment().year());
  }, [handleInitialData]);

  useBreadCrumbs(breadcrumbs);
  useEffect(() => {
    setOpenKeys(["Indicadores", "Indicadores"]);
    setSelectedKeys(["Indicadores/Empleados Rotacion"]);
  }, [setOpenKeys, setSelectedKeys]);

  const convertToJsonString = (value: string) => {
    try {
      if (typeof value === "string") {
        return value;
      } else {
        return JSON.stringify(value);
      }
    } catch (error) {
      return value;
    }
  };

  const sendDataMonthYear = async (year: string, mes: string) => {
    const respuesta: ResponseData = await fetch(
      url + "apifsg-pr/reportes/ic-empleados-salidas/",
      optionsPost({ year, mes })
    ).then((data) => data.json());

    if (respuesta.msg) {
      let data = createHeadTable(respuesta.data[0]);

      setHeadTableYdt(data);
      // console.log(respuesta);
      const uniqueDataChart = createOptionSelect(respuesta.data[0]);
      setGraphX2(uniqueDataChart);
      setDataChartLast(uniqueDataChart);
      form.setFieldsValue({ opcionesx2: uniqueDataChart });
      setColumnTableYdt(respuesta.data);
      setColumnTableMotivos(respuesta.data1);
      setColumnTableTiempos(respuesta.data2);
    }
  };

  const handleRowSelect = async (record: dataRow) => {
    await sendDataMonthYear(form.getFieldsValue().fecha.format("yyyy"), record.Mes);
    if (selectedRow && selectedRow[0] === record.key) {
      setSelectedRow([]);
    } else {
      let newSelection = [];
      newSelection.push(record.key);
      setSelectedRow(newSelection);
    }
  };

  const rowSelection = {
    type: "radio",
    //  onChange: handleRowSelect,
    onSelect: handleRowSelect,
    selectedRowKeys: selectedRow[0] ? [selectedRow[0]] : [],
  };

  const exportToExcel = (tableData: [], fileName: string) => {
    const worksheet = XLSX.utils.json_to_sheet(
      tableData.map((row: []) => {
        return Object.keys(row).reduce((acc: any, key: any) => {
          acc[key] = convertToJsonString(row[key]);
          return acc;
        }, {});
      })
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return {
    handleInitialData,
    exportToExcel,
    rowSelection,
    form,
  };
}
