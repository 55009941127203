import styles from "pages/productos/ProductosIncentivos.module.css";
import { Transfer } from "antd";

interface PropsTransfer {
  dataTransfer: PropsData[];
  productsKeys: string[];
  handleChange: (targeKey: string[]) => void;
  titles?: any[];
  height?: number; // Make height optional
}

interface PropsData {
  key: string;
  label: string;
}

const TransferComponent = ({ dataTransfer, productsKeys, handleChange, titles,height=285 }: PropsTransfer) => {
  return (
    <Transfer
      className={styles.transfer_container}
      listStyle={{ width: "100%", height: height }}
      dataSource={dataTransfer}
      targetKeys={productsKeys}
      onChange={handleChange}
      render={(item) => item.label}
      filterOption={(inputValue, option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1}
      showSearch
      titles={titles}
    />
  );
};

export default TransferComponent;
