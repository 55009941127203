import { CategoriaFarmacia } from "pages/contentpoliticas/CategoriaFarmacia"
import { NuevosDescuentos } from "pages/contentpoliticas/NuevoDescuento"
import { PromocionLunes } from "pages/contentpoliticas/PromocionLunes"
import { PromocionMC } from "pages/contentpoliticas/PromocionMC"
import { RouteObject } from "react-router-dom"

export const rutasPoliticasComerciales: RouteObject[] = [
  {
    path: "/politicas/nuevos",
    element: <NuevosDescuentos />,
  },
  {
    path: "/politicas/pmc",
    element: <PromocionMC />,
  },
  {
    path: "/politicas/categoria",
    element: <CategoriaFarmacia />,
  },
  {
    path: "/politicas/lunes",
    element: <PromocionLunes />,
  },
]
