import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { url, url_interna } from "../../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { optionsGet } from "config/methods";
import { RangePickerProps } from "antd/lib/date-picker";
import { useExport } from "hooks/otros/useExport";

type InventarioReporte = {
  Cod_Producto: number;
  Laboratorio: string;
  Producto: string;
  Lab: string;
  Precio: number;
  Unidad: number;
  Fraccion: number;
  Parcial: number;
  Bodega: string;
  key: React.Key;
};

type DatosPeriodo = {
  label: string;
  value: string;
};

const breadcrumbs = [
  { Label: "Inventarios", Url: "/inventario" },
  { Label: "Reportes", Url: "/inventarios/reportes" },
  { Label: "Inconsistencias punto de venta" },
];

const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];
export function useInconsistenciasBodegas() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[1].Label + "/" + breadcrumbs[2].Label);

  const [[fechaInicio, fechaFin], setFechas] = useState<[Moment | null, Moment | null]>([null, null]);
  const [datos, setDatos] = useState<InventarioReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<InventarioReporte[]>([]);

  const [dataEstado, setDataEstado] = useState<boolean>(false);

  const [bodega, setBodega] = useState<number>();
  const { exportToExcel } = useExport();
  const [estadoBotones, setDataBoton] = useState<boolean>(false);
  const [datosPeriodos, setDatosPeriodos] = useState<DatosPeriodo[]>([]);
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<InventarioReporte>();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();

  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };
  const getDatosBodegas = async (codBodega: number, fechaInicio: Moment, fechaFin: Moment) => {
    setDataEstado(false);
    setDataBoton(true);
    try {
      const res = await fetch(
        url + "apifsg-pr/inventarios/obtener_datos_bodegas_inconsistencia",
        optionsPost({
          codBodega: codBodega,
          fechaInicio: fechaInicio!.format("DD/MM/YYYY"),
          fechaFin: fechaFin!.format("DD/MM/YYYY"),
        })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: InventarioReporte[] = respuesta.data;
        setDataEstado(true);

        setDataBoton(false);

        setDatos(
          respuestas.map(({ Cod_Producto, Laboratorio, Producto, Lab, Precio, Unidad, Fraccion, Parcial, Bodega, key }) => ({
            Cod_Producto,
            Laboratorio,
            Producto,
            Lab,
            Precio,
            Unidad,
            Fraccion,
            Parcial,
            Bodega,
            key,
          }))
        );
      } else {
        setDataEstado(false);
        setDataBoton(false);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setDataEstado(false);
      setDataBoton(false);
      return { msg: "no_conexion", status: false };
    }
  };

  const getDatosIniciales = useCallback(async () => {
    setDatosPeriodos([]);
    try {
      const res = await fetch(url_interna + "api/inventarios/obtener_bodegas_piloto", optionsGet());
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: DatosPeriodo[] = respuesta.data;
        if (respuestas.length > 0) {
          setDatosPeriodos(
            respuestas.map(({ label, value }) => ({
              label,
              value,
            }))
          );
        } else {
          setDatosPeriodos([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [isError]);

  useEffect(() => {
    getDatosIniciales();
  }, [getDatosIniciales]);
  const calcularTotales = () => {
    let total = 0.0;

    filteredData.forEach((item) => {
      total += item.Parcial;
    });
    total = parseFloat(total.toFixed(2));
    let valor  =formateaCantidad(total);
    return { valor };
  };
  /* const setRowClassName = (record: DatosInventariosEspecial) => {
    if (record.ValorDiferencia > 0) {
      return "highlight-row"; // Esta es la clase CSS que se aplicará
    }
    return ""; // No se aplica ninguna clase especial si no se cumplen las condiciones
  };*/
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Deshabilita las fechas futuras respecto al final del día actual
    const startDate = moment("2024-08-01");
    const endDate = moment().endOf("day"); // Define el final del día actual
    return current && (current < startDate.startOf("day") || current > endDate);
  };
  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.Producto.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);
  const columns: TableColumnsType<InventarioReporte> = [
    {
      title: "Laboratorio",
      dataIndex: "Laboratorio",
      key: "Laboratorio",
      filters: [...new Set(filteredData.map(({ Laboratorio }) => Laboratorio))].map((NombreLaboratorio) => ({
        text: NombreLaboratorio,
        value: NombreLaboratorio,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.Laboratorio,
      className: "letraPequenaVisor2",
    },
    {
      title: "Cod Producto",
      dataIndex: "Cod_Producto",
      key: "Cod_Producto",
      className: "letraPequenaVisor2",
      width: "100px",
    },
    {
      title: "Producto",
      dataIndex: "Producto",
      key: "Producto",
      ...getColumnSearchPropsNewVisorReporte("Producto"),
      className: "letraPequenaVisor2",
    },

    {
      title: "Precio",
      dataIndex: "Precio",
      key: "Precio",
      //sorter: (a, b) => moment(a.FechaEscaneo).unix() - moment(b.FechaEscaneo).unix(),
      className: "letraPequenaVisor2",
      width: "130px",
      align: "right",
      render: (_, record) => (
        <span style={{ color: record.Precio === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.Precio)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Unidad",
      dataIndex: "Unidad",
      align: "right",
      className: "letraPequenaVisor2",
      width: "100px",
      /*heightrender: (_, record) => (
        <span style={{ color: record.CantReal === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.CantReal)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),*/
    },
    {
      title: "Fraccion",
      dataIndex: "Fraccion",
      align: "right",
      className: "letraPequenaVisor2",
      width: "100px",
      /*heightrender: (_, record) => (
        <span style={{ color: record.CantReal === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.CantReal)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),*/
    },
    {
      title: "Parcial",
      dataIndex: "Parcial",
      align: "right",
      className: "letraPequenaVisor2",
      width: "130px",
      render: (_, record) => (
        <span style={{ color: record.Parcial === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.Parcial)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
  ];

  const handleChangeButton = () => {
    try {
      if (bodega !== undefined && bodega >= 0 && fechaInicio && fechaFin) {
        getDatosBodegas(bodega, fechaInicio, fechaFin);
      } //
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickDownloadData = () => {
    // Transforma cada entrada de datosInicial para incluir Dependientes y Usuarios como strings
    const datosParaExportar = filteredData.map(({ key, Bodega, ...resto }) => resto);
    const bodega = datos[0].Bodega;
    exportToExcel(datosParaExportar, "Reporte ajustes de inventario " + bodega);
  };

  return {
    columns,
    setFechas,
    filteredData,
    datosPeriodos,
    setBodega,
    estadoBotones,
    bodega,
    handleChangeButton,
    dataEstado,
    disabledDate,
    fechaInicio,
    fechaFin,
    handleClickDownloadData,
    calcularTotales,
  };
}
