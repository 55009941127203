import { useRef, useState } from "react";
import { Input, InputRef } from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

export function useSearchTableNew<T>() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const getColumnSearchPropsNew = (dataIndex: keyof T): ColumnType<T> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${String(dataIndex)}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            const value = e.target.value;
            setSelectedKeys(value ? [value] : []);
            setSearchText(value.toLowerCase());
            setSearchedColumn(dataIndex as string);
            confirm({ closeDropdown: false }); // Mantiene el dropdown abierto
          }}
          style={{ marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) =>  <SearchOutlined style={{ color: filtered ? "#1890ff" : "", fontSize: '16px',opacity: 1 }}  />,
    onFilterDropdownOpenChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    onFilter: (value, record) =>
      ((record[dataIndex] ?? "") as unknown as string)
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),

    render: (text) =>
      searchedColumn === dataIndex && searchText ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "var(--primary)", padding: 0, color: "#fff" }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  return { getColumnSearchPropsNew };
}
