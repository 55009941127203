import AdministrarVenta from "components/indicadores/AdministrarVenta";
import { ContextVenta } from "context/indicadores/MargenVentaContext";

export const VentasBodega = () => {
  return (
    <ContextVenta>
      <AdministrarVenta />
    </ContextVenta>
  );
};

export default VentasBodega;
