import { Row, Empty, Divider, Typography, Progress, Badge, Card, Col } from "antd"
import { ColumCard, TypeCard } from "../../components/otros/ColumCard"
import { useProcesos } from "hooks/procesos/useProcesos"
import { Link } from "react-router-dom"

const { Text, Paragraph } = Typography
export function Procesos() {
  const { procesos, isLoading, actions/*, ActualizarDescripcion, Id_Usuario*/ } = useProcesos()    

  return (
    <>
      <Divider orientation="right">
        <Link to="/ver-todos-procesos">Ver Todos</Link>
      </Divider>
      <Row wrap gutter={[16, 16]} style={{ padding: "0px 16px", marginLeft: 0, marginRight: 0, paddingBottom: "75.59px" }}>
        {isLoading ? (
          <ColumCard
            cardType={TypeCard.Loading}
            cardProps={{ actions: [] }}
            colProps={{ xs: 24, sm: 12, md: 8, lg: 8, xl: 6 }}
          />
        ) : (
          procesos.map(
            ({ cod_proceso, cod_proceso_iniciado, fecha, descripcion, proceso, porcentaje_completado, n_realizar }) => (
                <Col span={6.5} key={cod_proceso_iniciado}>
                  <Badge count={n_realizar}>
                  <Card 
                      type="inner" 
                      title={`${proceso}  N°${cod_proceso_iniciado}`}
                      key={"Col" + cod_proceso_iniciado}
                      actions={actions(cod_proceso_iniciado)}
                      style={{
                        width: '300px',
                        height: '300.19px'
                      }}
                      size="small"
                    >               
                      <Row style={{ height: '130px' }}> {/* Ajusta la altura según sea necesario */}
                        <Col xs={24} md={24} lg={24} xl={24}>
                          <Text strong>Descripción: </Text>                        
                          <div style={{ height: '80px', overflowY: "auto" }}> {/* Altura fija para la descripción */}
                            <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'Ver más' }}>
                              {descripcion}
                            </Paragraph>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ height: '20px', position: 'absolute', bottom: '110px', width: '100%' }}> {/* Ajusta la altura y posición según sea necesario */}
                        <Text strong>Fecha creación: </Text>
                        <Paragraph style={{ textAlign: 'right' }}>                
                          {fecha.toString()}
                        </Paragraph>
                      </Row>
                      <Progress percent={porcentaje_completado} strokeColor="#6A2831" style={{ position: 'absolute', bottom: '70px', width: '95%' }} />
                    </Card>
                  </Badge>
                </Col>                
            )
          )
        )}
      </Row>
      {procesos?.length === 0 && <Empty />}
    </>
  )
}
