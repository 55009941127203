import { Button, Tag } from "antd"

import { useLoaderData, useLocation, useNavigate } from "react-router-dom"
import { useScreenSize } from "hooks/otros/useScreenSize"
import { useSearchTable } from "hooks/otros/useSearchTable"
import { useLayout } from "hooks/otros/useLayout"

import { llave } from "config/constants"

import { EyeOutlined } from "@ant-design/icons"

import type { ColumnsType } from "antd/lib/table"
import AES from "crypto-js/aes"
import { useMemo, useState } from "react"

interface Solicitudes {
  key: number
  empleado: string
  fecha_solicitud: string
  fecha_inicio: string
  fecha_fin: string
  periodo: string
  total_dias: number
  adelanto: boolean
}

// const breadcrumbs = [
//   { Label: "Recursos Humanos", Url: "/rrhh" },
//   { Label: "Vacaciones", Url: "/rrhh/vacaciones" },
//   { Label: "Revisión jefe inmediato" },
// ]

// const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]

// const selectKey = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export function useListaSolicitudes() {
  const location = useLocation()
  const breadCrumbs = useMemo(() => {
    return [
      { Label: "Recursos Humanos", Url: "/rrhh" },
      { Label: "Vacaciones", Url: "/rrhh/vacaciones" },
      {
        Label:
          location.pathname === "/rrhh/vacaciones/revision-jefe-inmediato"
            ? "Revisión jefe inmediato"
            : "Solicitudes de jefes departamentales",
      },
    ]
  }, [location])

  const openKeys = useMemo(() => [breadCrumbs[0].Label, `${breadCrumbs[0].Label}/${breadCrumbs[1].Label}`], [breadCrumbs])

  const selectKey = `${breadCrumbs[1].Label}/${breadCrumbs[2].Label}`

  useLayout(breadCrumbs, openKeys, selectKey)

  const [pageSize, setPageSize] = useState(7)

  const solicitudes = useLoaderData() as Solicitudes[]

  const navigate = useNavigate()

  const { getColumnSearchProps } = useSearchTable<Solicitudes>()

  const { width } = useScreenSize()

  const isWeb = width >= 778

  const columnsTable: ColumnsType<Solicitudes> = [
    {
      title: "Empleado",
      dataIndex: "empleado",
      key: "empleado",
      ...getColumnSearchProps("empleado"),
    },
    { title: "Fecha de solicitud", dataIndex: "fecha_solicitud", key: "fecha_solicitud" },
    { title: "Fecha Inicio", dataIndex: "fecha_inicio", key: "fecha_inicio" },
    { title: "Fecha fin", dataIndex: "fecha_fin", key: "fecha_fin" },
    { title: "Periodo", dataIndex: "periodo", key: "periodo" },
    {
      title: "Solicitud",
      dataIndex: "adelanto",
      key: "adelanto",
      render: value => <Tag color={value ? "red" : "blue"}>{value ? "Adelanto" : "Normal"}</Tag>,
      filters: [
        { text: "Adelanto", value: true },
        { text: "Normal", value: false },
      ],
      onFilter: (value, { adelanto }) => value === adelanto,
    },
    {
      title: "",
      dataIndex: "key",
      key: "acciones",
      align: "center",
      fixed: "right",
      render: value => (
        <Button
          icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
          onClick={() =>
            navigate(`${location.pathname}/${encodeURIComponent(AES.encrypt(value.toString(), llave).toString())}`)
          }
          type="text"
        />
      ),
    },
  ]

  return {
    isWeb,
    solicitudes,
    columnsTable,
    pageSize,
    setPageSize,
  }
}
