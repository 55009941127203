import { ColumnProps, ColumnsType } from "antd/lib/table";
import { url } from "config/constants";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useSearchTable } from "hooks/otros/useSearchTable";
import { useSwal } from "hooks/otros/useSwal";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import { useCallback, useEffect, useState } from "react";

export interface PropsCobros {
  key: number;
  proveedor: string;
  observacion: string;
  forma_pago: string;
  cod_forma_pago: number;
  valor: number;
}

interface responseCobros {
  msg: "ok" | "error" | "err";
  data: PropsCobros[];
}

export function useTableCobros(dataUrl: string, renderActionsEdit?: ColumnProps<any>["render"]) {
  const [cobros, setCobros] = useState<PropsCobros[]>([]);

  const { optionsGet } = useLocalStorage();

  const { swalError } = useSwal();

  const { wasInvalidToken, hadError } = useVerifyResponse();

  const { getColumnSearchProps } = useSearchTable<PropsCobros>();

  const listarCobros = useCallback(async () => {
    try {
      const res = await fetch(`${url}${dataUrl}`, optionsGet);

      const response: responseCobros = await res.json();

      if (wasInvalidToken(response)) return;
      if (hadError(response, "No se pudo obtener los convenios")) return;

      const { data } = response;

      setCobros(data ? data : []);
    } catch (e) {
      swalError({ text: "No se pudo obtener los convenios" });
    }
  }, [optionsGet, wasInvalidToken, hadError, swalError, dataUrl]);

  useEffect(() => {
    listarCobros();
  }, [listarCobros]);

  const columnsTable: ColumnsType<PropsCobros> = [
    {
      title: "Proveedor",
      dataIndex: "proveedor",
      key: "detproveedoralles",
      width: "30%",
      ...getColumnSearchProps("proveedor"),
    },
    {
      title: "Forma de pago",
      dataIndex: "forma_pago",
      key: "forma_pago",
      width: "15%",
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      width: "10%",
      align: "right",
    },
    {
      title: "Observación",
      dataIndex: "observacion",
      key: "observacion",
    },
    {
      title: "Agregar Documento",
      dataIndex: "key",
      key: "acciones",
      align: "center",
      fixed: "right",
      width: "10%",
      render: renderActionsEdit,
    },
  ];

  return {
    cobros,
    setCobros,
    listarCobros,
    columnsTable,
  };
}
