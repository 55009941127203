import { Form, Input, Space, DatePicker, Typography, Col, Row, FormInstance } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { dateFormat } from "./MiInformacion";

interface PropsResiden {
  residen: number;
  form: FormInstance<any>;
}

export const Residentes = ({ residen }: PropsResiden) => {
  residen ??= 0;

  return (
    <Form.List name='residentes'>
      {(field = [], { add, remove }) => {
        return (
          <>
            {field.map(({ key, name, ...restField }) => {
              return (
                <Row wrap={true} key={key}>
                  <Col xs={24} md={24} lg={24}>
                    <Space key={key} style={{ display: "flex", marginBottom: 8 }} align='baseline'>
                      <Row wrap={true}>
                        <Col xs={8} xl={8}>
                          <Form.Item
                            {...restField}
                            name={[name, "nombres_residente"]}
                            rules={[{ required: true, message: "Escriba los nombres completos" }]}
                            label={
                              <Typography.Text
                                ellipsis={{ tooltip: `Nombres del residente #${name + 1}` }}
                              >
                                {`Nombres del residente #${name + 1}`}
                              </Typography.Text>
                            }
                          >
                            <Input
                              placeholder='Escriba los nombres completos'
                              style={{ width: "98%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={8} xl={8}>
                          <Form.Item
                            label={
                              <Typography.Text
                                ellipsis={{
                                  tooltip: `Fecha de nacimiento del residente #${name + 1}`,
                                }}
                              >
                                {`Fecha de nacimiento del residente #${name + 1}`}
                              </Typography.Text>
                            }
                            {...restField}
                            name={[name, "fecha_nacimiento_residente"]}
                            rules={[{ required: true, message: "Escoja la fecha de nacimiento" }]}
                          >
                            <DatePicker style={{ width: "98%" }} format={dateFormat} />
                          </Form.Item>
                        </Col>
                        <Col xs={8} xl={8}>
                          <Form.Item
                            {...restField}
                            name={[name, "ocupacion_residente"]}
                            rules={[
                              { required: true, message: "Escriba la ocupación del residente" },
                            ]}
                            label={
                              <Typography.Text
                                ellipsis={{
                                  tooltip: `Ocupación del residente #${name + 1}`,
                                }}
                              >
                                {`Ocupación del residente #${name + 1}`}
                              </Typography.Text>
                            }
                          >
                            <Input placeholder='Escriba la ocupación' style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <MinusCircleOutlined
                        onClick={() => {
                          if (name >= residen) return remove(name);
                        }}
                      />
                    </Space>
                  </Col>
                </Row>
              );
            })}
            {/* <Form.Item>
              <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                Añadir Residente
              </Button>
            </Form.Item> */}
          </>
        );
      }}
    </Form.List>
  );
};
