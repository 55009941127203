import { Button, Form } from "antd"

import { LayoutContext } from "context/LayoutContext"

import { useContext, useEffect, useState } from "react"

import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSwal } from "hooks/otros/useSwal"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { useDataForTable } from "./useDataForTable"

import { url } from "config/constants"

import { EyeOutlined } from "@ant-design/icons"

import type { FormProps } from "antd"
import type { ColumnProps } from "antd/lib/table"
import type { dataLlamadosAtencion } from "./useDataForTable"
import type { valuesFormLlamadosAtencion } from "components/rrhh/componentsllamadosatencion/FormInModal"

const breadcrumbs = [
  { Label: "Recursos humanos", Url: "/rrhh" },
  { Label: "Llamados de atención", Url: "/rrhh/llamados-de-atencion" },
  { Label: "Revision de solicitudes" },
]

export function useRevisionLlamadosAtencion() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [disabledPorcentajeMulta, setDisabledPorcentajeMulta] = useState<boolean>(true)

  const [form] = Form.useForm<valuesFormLlamadosAtencion>()

  const { swalError, swalSuccess } = useSwal()

  const { optionsPost } = useLocalStorage()

  const { wasInvalidToken, hadError } = useVerifyResponse()

  useBreadCrumbs(breadcrumbs)

  useEffect(() => {
    setOpenKeys(["Recursos Humanos", "Recursos Humanos/Llamados de atención"])
    setSelectedKeys(["Llamados de atención/Revisión"])
  }, [setOpenKeys, setSelectedKeys])

  const handleClickButtonModal = (
    value: number,
    { multa, porcentaje, reglamentos, bodega, departamento, observacion, fecha_falta, fecha_creacion }: dataLlamadosAtencion
  ) => {
    setSelectedIndex(llamadosAtencion.findIndex(ll => ll.key === value))
    setOpenModal(true)
    form.setFieldsValue({
      multa,
      porcentaje,
      reglamentos: (reglamentos as Array<{ cod_literal: number }>).map(reg => reg.cod_literal),
      bodega,
      departamento,
      observacion,
      fecha_falta,
      fecha_creacion,
    })
    setDisabledPorcentajeMulta(!multa)
  }

  const renderActions: ColumnProps<dataLlamadosAtencion>["render"] = (value, record) => (
    <Button
      icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
      type="text"
      block
      onClick={() => handleClickButtonModal(value, record)}
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    />
  )

  const { llamadosAtencion, setllamadosAtencion, listarLlamadosAtencion, columnsTable } = useDataForTable(
    "apifsg-pr/recursos-humanos/listar-solicitudes-llamados-atencion",
    renderActions
  )

  useEffect(() => {
    listarLlamadosAtencion()
  }, [listarLlamadosAtencion])

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleRechazar = async () => {
    try {
      const res = await fetch(
        `${url}apifsg-pr/recursos-humanos/rechazar-llamado-atencion`,
        optionsPost({ cod_solicitud: llamadosAtencion[selectedIndex].key })
      )
      if (res.status !== 200) throw new Error()
      const response = res.json()

      if (wasInvalidToken(response)) return
      if (hadError(response, "No se pudo actualizar el estado del llamado de atención")) return
      setOpenModal(false)

      llamadosAtencion.length === 1 ? setllamadosAtencion([]) : listarLlamadosAtencion()
      swalSuccess({ title: "Rechazado con éxito!" })
    } catch (e) {
      swalError({ text: "No se pudo actualizar el estado del llamado de atención" })
    }
  }

  const handleFinishForm: FormProps["onFinish"] = async ({
    multa,
    porcentaje,
    motivo,
    reglamentos,
    descripcion,
    periodo_actividad,
    tipo_actividad,
    observacion,
  }: valuesFormLlamadosAtencion) => {
    try {
      const data = {
        cod_solicitud: llamadosAtencion[selectedIndex].key,
        multa,
        porcentaje,
        motivo,
        reglamentos,
        descripcion,
        periodo_actividad: periodo_actividad.format("YYYYMM"),
        tipo_actividad,
        descripcion_solicitud: observacion,
      }
      const res = await fetch(`${url}apifsg-pr/recursos-humanos/aprobar-llamado-atencion`, optionsPost(data))
      if (res.status !== 200) throw new Error()
      const response = res.json()

      if (wasInvalidToken(response)) return
      if (hadError(response, "No se pudo actualizar el estado del llamado de atención")) return
      setOpenModal(false)

      llamadosAtencion.length === 1 ? setllamadosAtencion([]) : listarLlamadosAtencion()
      swalSuccess({ title: "Aprobado con éxito!" })
    } catch (e) {
      swalError({ text: "No se pudo actualizar el estado del llamado de atención" })
    }
  }

  return {
    llamadosAtencion,
    columnsTable,
    // isWeb,
    openModal,
    form,
    disabledPorcentajeMulta,
    setDisabledPorcentajeMulta,
    // reglamentos,
    selectedIndex,
    handleCloseModal,
    handleRechazar,
    handleFinishForm,
  }
}
