import {Button, Card, Col, DatePicker, Row, Select, Table, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useLayout } from 'hooks/otros/useLayout'
import { UseTareasPendientes } from 'hooks/sistemas/UseTareasPendientes'
import { SaveOutlined, RollbackOutlined,FileExcelOutlined} from "@ant-design/icons"

const {Title}=Typography

const breadcrumbs = [
  { Label: "Sistemas", Url: "/sistemas" },
  { Label: "Tareas de Soporte", Url: "/sistemas/ver" },
  { Label: "Pendientes" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export  function TareasPendientes() {
  useLayout(breadcrumbs, openKeys, selectKeySider)

  const {columnsTareas,
    dataTareas,
    databodega,
    dataUsuarios,
    onchangeBodega,
    codBodega,
    codUsuario,
    dataIncidente,
    CodIncidente,
    dataSoporte,
    codUsuarioSoporte,
    onchangeSolicitante,
    onchangeTipoIncidente,
    onchangeUsuarioSoporte,
    disableFutureDates,
    handleFilter,
    onchangeDescripcion,
    valueDescripcion,
    onchagedate,
    valueFecha,
    btn_Guardar,
    disabled,
    setdisabled,
    btn_Excel
  }=UseTareasPendientes()
  return (
    <>
      {!disabled&&<><Row style={{ background: "white", padding: "5px" }} align="middle" justify="center">
        <Col xs={24} sm={24} md={12} style={{paddingLeft: "20px"}}>
          <Title level={4}>Tareas de Soporte Pendientes</Title>
        </Col>  
        <Col xs={8} sm={8} md={12}>
            <Row justify="end">
            <Button
              type="primary"
              style={{ marginRight: "2px", marginTop: "1px", backgroundColor: "#00B050", borderColor: "#00B050" }}
              onClick={btn_Excel}
            >
              Excel <FileExcelOutlined />
            </Button>
            </Row>
            </Col>
      </Row>
      <Row style={{ background: "white", width: '100%', padding:'5px' }} className="table-container">
        <Table style={{width:'100%'}} columns={columnsTareas} dataSource={dataTareas}/>
      </Row></>}
      {disabled&&<><Row style={{ background: "white", padding: "5px" }} align="middle" justify="center">
        <Col xs={18} sm={18} md={12} style={{ paddingLeft: "20px" }}>
          <Title level={4}>Registro de Tareas para Soporte</Title>
        </Col>
        <Col xs={6} sm={6} md={12}>
          <Row justify="end">
          <Button type="default" size="small" onClick={()=>setdisabled(false)} style={{marginRight:'2px', marginBottom:'2px'}}><RollbackOutlined />Regresar</Button>
            <Button type="primary" size="small" onClick={btn_Guardar} style={{marginBottom:'2px'}}><SaveOutlined />Guardar </Button>
          </Row>
        </Col>
      </Row>
      <Card style={{height:'100%'}}>
        <Row style={{ background: "white" }}>
          <Col xs={24} sm={24} md={12} style={{ marginTop: "10px" }}>
            <Row style={{ paddingLeft: "5px" }}>
              <Col xs={6} sm={18} md={4}>
                {" "}
                <Title level={5}>Fecha</Title>
              </Col>
              <Col xs={18} sm={18} md={20}>
                <DatePicker style={{ width: "100%" }} disabledDate={disableFutureDates} onChange={onchagedate} value={valueFecha}/>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} style={{ marginTop: "10px" }}>
            <Row style={{ paddingLeft: "5px" }}>
              <Col xs={6} sm={6} md={4}>
                {" "}
                <Title level={5}>Farmacia</Title>
              </Col>
              <Col xs={18} sm={18} md={20}>
                <Select showSearch style={{ width: "100%" }} options={databodega} onChange={onchangeBodega} value={codBodega}
                filterOption={handleFilter}/>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ background: "white" }}>
          <Col xs={24} sm={24} md={12} style={{ marginTop: "10px" }}>
            <Row style={{ paddingLeft: "5px" }}>
              <Col xs={6} sm={18} md={4}>
                {" "}
                <Title level={5}>Solicitante</Title>
              </Col>
              <Col xs={18} sm={18} md={20}>
                <Select showSearch style={{ width: "100%" }}  options={dataUsuarios} value={codUsuario} onChange={onchangeSolicitante} filterOption={handleFilter}/>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} style={{ marginTop: "10px" }}>
            <Row style={{ paddingLeft: "5px" }}>
              <Col xs={6} sm={6} md={4}>
                {" "}
                <Title level={5}>Asignar a</Title>
              </Col>
              <Col xs={18} sm={18} md={20}>
                <Select style={{ width: "100%" }} onChange={onchangeUsuarioSoporte} value={codUsuarioSoporte} options={dataSoporte}/>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{marginTop:'8px', paddingLeft:'5px'}} align={"middle"}>
          <Col xs={6} sm={6} md={3} >
          <Title level={5}>Tipo de Incidente</Title>
          </Col>
          <Col  xs={18} sm={18} md={21}>
          <Select showSearch style={{width:'100%'}} options={dataIncidente} value={CodIncidente} onChange={onchangeTipoIncidente} filterOption={handleFilter}/>
          </Col>
        </Row>
          <Row style={{marginTop:'8px', paddingLeft:'5px'}}> <Title level={5}>Descripción</Title></Row>
        <TextArea  rows={10} style={{marginLeft:'5px'}}  value={valueDescripcion} onChange={(e)=>onchangeDescripcion(e)}/>
      </Card></>}
    </>
  )
}
