import { Form, Divider, Row, Col, Input, InputNumber, Checkbox, Select, Tooltip } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import TablaConveniosProductosOut from "./TablaConveniosProductosOut";

const ListCuotaOut = ({
  porcentajeOut,
  restoOut,
  setRestoOut,
  setPorcentajeOut,
  checkOut,
  setCheckOut,
  totalRebateOut,
  hidden,
  opcionesProductos,
  onChangeOpcionesProductos,
  opciones,
  dataTable,
  setSeleccionOut,
  setDataTable,
  seleccionOut,
  isLimited,
  toggleLimit,
  setEditKeyOut,
  editKeyOut,
}: any) => {
  return (
    <>
      <Form.List
        name='distribucionOut'
        initialValue={[{ es_monto: false, valor: "", rebate: "", es_divide: false }]}
        key={1}
      >
        {(fields = [], { add, remove }) => {
          return (
            <>
              <Divider dashed orientation='left' orientationMargin='0' style={{ fontSize: "12px" }}>
                CUOTA NEGOCIACIÓN
              </Divider>

              {fields.map(({ key, name, ...restField }) => {
                let max: any = [];

                if (porcentajeOut[name] && restoOut !== null) {
                  max[name] = (porcentajeOut[name] ?? 0) - restoOut[name];
                  max[name] = max[name] + restoOut[name];
                }

                return (
                  <Row wrap={true} key={name}>
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item>
                        <Input.Group className='group_rebate'>
                          {fields.length === 1 && (
                            <Col xs={24} lg={3}>
                              <Tooltip title='Active esta opción si el convenio es sobre el monto total de las ventas o cuando no se establece un valor mínimo establecido.'>
                                <Form.Item name={[name, "es_monto"]} valuePropName='checked'>
                                  {/* <Checkbox checked={!isLimited} onChange={toggleLimit}>
                                   */}
                                  <Checkbox
                                    // checked={!isLimited}
                                    onChange={(e) => toggleLimit(e.target.checked, name)}
                                    defaultChecked={!isLimited}
                                  >
                                    Monto total
                                  </Checkbox>
                                </Form.Item>
                              </Tooltip>
                            </Col>
                          )}
                          <Row wrap={true} gutter={7} style={{ width: "100%" }}>
                            <Col xs={24} lg={7}>
                              <Form.Item
                                {...restField}
                                name={[name, "valor"]}
                                rules={[
                                  {
                                    required: isLimited,
                                    message: "El valor es obligatorio",
                                  },
                                ]}
                              >
                                <InputNumber
                                  disabled={!isLimited}
                                  addonBefore='Valor'
                                  style={{ width: "100%" }}
                                  min={0}
                                  step={0.1}
                                  placeholder='Escriba el valor del convenio'
                                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={7}>
                              <Form.Item
                                {...restField}
                                name={[name, "rebate"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "El % rebate es obligatorio",
                                  },
                                ]}
                              >
                                <InputNumber
                                  placeholder='Escriba el porcentaje de rebate'
                                  onChange={(e: any) => setPorcentajeOut({ ...porcentajeOut, [name]: e })}
                                  addonBefore='Rebate %'
                                  style={{ width: "100%" }}
                                  min={0}
                                  max={100}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} xl={8}>
                              <Form.Item {...restField} name={[name, "es_divide"]} valuePropName='checked'>
                                <Checkbox
                                  onChange={(e: CheckboxChangeEvent) =>
                                    setCheckOut({ ...checkOut, [name]: e.target.checked })
                                  }
                                  defaultChecked={checkOut[name]}
                                >
                                  El % de Rebate se subdivide
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            {fields.length === name + 1 && isLimited && (
                              <Col xs={24} lg={1}>
                                <PlusCircleOutlined onClick={() => add()} />
                              </Col>
                            )}
                            {fields.length === name + 1 && isLimited && (
                              <Col xs={24} lg={1}>
                                <MinusCircleOutlined
                                  onClick={() => {
                                    if (name > 0) {
                                      setCheckOut((prevObj: any) => {
                                        const newObj = { ...prevObj };
                                        delete newObj[name];
                                        return newObj;
                                      });
                                      return remove(name);
                                    }
                                  }}
                                />
                              </Col>
                            )}
                          </Row>
                        </Input.Group>
                      </Form.Item>
                    </Col>
                    <Row wrap={true} gutter={6}>
                      <Col xs={24} xl={24} style={{ display: !checkOut[name] ? "none" : "block" }}>
                        {/* <Col> */}
                        <Form.Item
                          label='Distribución Rebate'
                          style={{ width: "100%" }}
                          name={[name, "subdivide"]}
                          rules={[
                            {
                              validator: () => {
                                if (checkOut[name] && max[name] !== restoOut[name]) {
                                  return Promise.reject(
                                    "El rebate principal debe coincidir con el total de la distribución"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input.Group className='group_rebate'>
                            <Form.Item name={[name, "compras"]}>
                              <InputNumber
                                addonBefore='Compras'
                                style={{ width: "100%" }}
                                addonAfter='%'
                                min={0}
                                max={max[name]}
                                onChange={() => {
                                  setRestoOut({ ...restoOut, [name]: totalRebateOut(name) });
                                }}
                              />
                            </Form.Item>
                            <Form.Item name={[name, "ventas"]}>
                              <InputNumber
                                addonBefore='Ventas'
                                addonAfter='%'
                                style={{ width: "100%" }}
                                min={0}
                                max={max[name]}
                                onChange={() => {
                                  setRestoOut({ ...restoOut, [name]: totalRebateOut(name) });
                                }}
                              />
                            </Form.Item>
                            <Form.Item name={[name, "mercadeo"]}>
                              <InputNumber
                                addonBefore='Mercadeo'
                                addonAfter='%'
                                style={{ width: "100%" }}
                                min={0}
                                max={max[name]}
                                onChange={(values) => {
                                  setRestoOut({ ...restoOut, [name]: totalRebateOut(name) });
                                }}
                              />
                            </Form.Item>
                            <Form.Item name={[name, "supervisor"]}>
                              <InputNumber
                                addonBefore='Supervisores'
                                addonAfter='%'
                                style={{ width: "100%" }}
                                min={0}
                                max={max[name]}
                                onChange={() => {
                                  setRestoOut({ ...restoOut, [name]: totalRebateOut(name) });
                                }}
                              />
                            </Form.Item>
                            <Form.Item name={[name, "convencion"]}>
                              <InputNumber
                                addonBefore='Convencion'
                                addonAfter='%'
                                style={{ width: "100%" }}
                                min={0}
                                max={max[name]}
                                onChange={() => {
                                  setRestoOut({ ...restoOut, [name]: totalRebateOut(name) });
                                }}
                              />
                            </Form.Item>
                          </Input.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Divider style={{ marginTop: "-30px" }}></Divider>
                  </Row>
                );
              })}
            </>
          );
        }}
      </Form.List>
      <Col xs={24} xl={24}>
        {!hidden && (
          <Form.Item
            label={`Opciones por Productos`}
            // name='cod_opciones_por_productos'
            name={"cod_opciones_por_productos_out"}
            rules={[
              {
                required: !hidden,
                message: "Opciones por productos es obligatoria",
              },
            ]}
            style={{ width: "100%" }}
          >
            <Select
              options={opcionesProductos}
              allowClear
              // onChange={(e) => setOpciones2({ ...opciones2, [name]: e })}
              onChange={(e) => onChangeOpcionesProductos(e, 1)}
              showSearch
              optionFilterProp='label'
              placeholder='Seleccione una opcion'
            ></Select>
          </Form.Item>
        )}
      </Col>
      {opciones[1] !== 1 && opciones[1] && (
        <Form.Item name={"table"}>
          <TablaConveniosProductosOut
            dataTable={dataTable}
            opciones={opciones[1]}
            onChangeOpcionesProductos={onChangeOpcionesProductos}
            porcentajeOut={porcentajeOut[0]}
            setSeleccionOut={setSeleccionOut}
            setDataTable={setDataTable}
            seleccionOut={seleccionOut}
            name={1}
            key={1}
            editKeyOut={editKeyOut}
            setEditKeyOut={setEditKeyOut}
          />
        </Form.Item>
      )}
    </>
  );
};

export default ListCuotaOut;
