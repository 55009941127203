import { View, Text } from "@react-pdf/renderer"

import type { Style } from "@react-pdf/types"

export interface columnsTablePDF<T> {
  dataIndex: keyof T
  title: string
  styleColumnHeader?: Style
  styleColumn?: Style
  key?: React.Key
  fixed?: boolean
  styleCell?: (index: number, record: T) => Style
}

export interface tableProps<T> {
  columns: columnsTablePDF<T>[]
  dataSource: T[]
  styleRow?: Style
  styleRowHeader?: Style
  key?: React.Key
  styleContainer?: Style
  header?: boolean
}

const RowTable: Style = {
  display: "flex",
  flexDirection: "row",
  // alignItems: "center",
  width: "100%",
  borderBottom: "1px solid #f0f0f0",
}

const RowHeader: Style = {
  ...RowTable,
  backgroundColor: "#fafafa",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
}

export function ReportTable<T>({
  columns,
  dataSource,
  styleRow,
  styleRowHeader,
  styleContainer,
  header = true,
}: tableProps<T>) {
  // const styles = {
  // const Row = RowTable,
  // const RowHeader =,
  const columnTable: Style = {
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Helvetica",
    display: "flex",
    flexBasis: `calc(100% / ${columns.length})`,
    padding: 10,
    alignItems: "stretch",
  }
  const columnHeader: Style = {
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    display: "flex",
    flexBasis: `calc(100% / ${columns.length})`,
    // border: '1px solid #000',
    padding: 10,
  }
  // }

  return (
    <View key={"container"} style={styleContainer ? styleContainer : undefined}>
      {header && (
        <View style={styleRowHeader ? { ...RowHeader, ...styleRowHeader } : RowHeader} key="row header">
          {columns.map(({ title, styleColumnHeader }, index) => {
            return (
              <Text key={index} style={styleColumnHeader ? { ...columnHeader, ...styleColumnHeader } : columnHeader}>
                {title}
              </Text>
            )
          })}
        </View>
      )}
      {dataSource.map((data, index) => (
        <View style={styleRow ? { ...RowTable, ...styleRow } : RowTable} key={"row " + index}>
          {columns.map(({ dataIndex, styleColumn, fixed, key, styleCell }) => (
            <Text
              style={
                styleColumn ? { ...columnTable, ...styleColumn, ...(styleCell && styleCell(index, data)) } : columnTable
              }
              key={`${key ?? index}`}
            >
              {fixed
                ? (data as any)[dataIndex]?.toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,")
                : (data as any)[dataIndex]}
            </Text>
          ))}
        </View>
      ))}
    </View>
  )
}
