import { createContext, useState } from "react";

interface IcContextType<T> {
  headTable: T;
  setHeadTable: React.Dispatch<React.SetStateAction<T>>;
  columnTable: T;
  setColumnTable: React.Dispatch<React.SetStateAction<T>>;

  columnTableG: T;
  setColumnTableG: React.Dispatch<React.SetStateAction<T>>;
  columnTableGDay: T;
  setColumnTableGDay: React.Dispatch<React.SetStateAction<T>>;

  dataChart: string[];
  setDataChart: React.Dispatch<React.SetStateAction<string[]>>;
  graph: string[];
  setGraph: React.Dispatch<React.SetStateAction<string[]>>;

  headTableDay: T;
  setHeadTableDay: React.Dispatch<React.SetStateAction<T>>;

  selectedRow: string[];
  setSelectedRow: React.Dispatch<React.SetStateAction<string[]>>;

  graphX2: string[];
  setGraphX2: React.Dispatch<React.SetStateAction<string[]>>;

  columnTableDay: T;
  setColumnTableDay: React.Dispatch<React.SetStateAction<T>>;
}

interface ContextICProps<T> {
  children: JSX.Element | Array<JSX.Element>;
  value?: IcContextType<T>;
}

export const MargenVentaContext = createContext<IcContextType<any>>({} as IcContextType<any>);

export function ContextVenta<T>({ children }: ContextICProps<T>) {
  const [headTable, setHeadTable] = useState<T | undefined>(undefined);
  const [columnTable, setColumnTable] = useState<T | undefined>(undefined);
  const [graph, setGraph] = useState<string[]>([]);
  const [dataChart, setDataChart] = useState<string[]>([]);
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [headTableDay, setHeadTableDay] = useState<T | undefined>(undefined);
  const [graphX2, setGraphX2] = useState<string[]>([]);
  const [columnTableG, setColumnTableG] = useState<T | undefined>(undefined);
  const [columnTableDay, setColumnTableDay] = useState<T | undefined>(undefined);
  const [columnTableGDay, setColumnTableGDay] = useState<T | undefined>(undefined);

  return (
    <MargenVentaContext.Provider
      value={{
        headTable,
        setHeadTable,
        columnTable,
        setColumnTable,
        dataChart,
        setDataChart,
        graph,
        setGraph,
        columnTableG,
        setColumnTableG,
        selectedRow,
        setSelectedRow,
        headTableDay,
        setHeadTableDay,
        graphX2,
        setGraphX2,
        columnTableDay,
        setColumnTableDay,
        columnTableGDay,
        setColumnTableGDay,
      }}
    >
      {children}
    </MargenVentaContext.Provider>
  );
}
