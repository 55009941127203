import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer"
import { Button, Modal } from "antd"
import { JSXElementConstructor, ReactElement } from "react"
import { DocumentProps } from "react-pdf"

interface Props {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  titleModal: string
  pdfName: string
  children: ReactElement<DocumentProps, string | JSXElementConstructor<any>>
  extra?: JSX.Element | JSX.Element[]
}

export function ModalReport({ openModal, setOpenModal, titleModal, pdfName, children, extra }: Props) {
  return (
    <Modal
      title={titleModal}
      open={openModal}
      onCancel={() => setOpenModal(false)}
      width={1000}
      style={{ top: 64 }}
      transitionName=""
      footer={[
        <Button onClick={() => setOpenModal(false)} key="cerrar">
          Cerrar
        </Button>,
        <PDFDownloadLink key="descargar" className="ant-btn ant-btn-primary" document={children} fileName={pdfName}>
          {({ blob, url, loading, error }) => (loading ? "Cargando..." : "Descargar")}
        </PDFDownloadLink>,
      ]}
    >
      {extra}
      <PDFViewer style={{ width: "100%", height: "70vh" }}>{children}</PDFViewer>
    </Modal>
  )
}
