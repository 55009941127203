import { Button, Form, Tag, notification } from "antd";
import Velocimetro from "components/indicadores/Velocimetro";
import { url } from "config/constants";
import { LayoutContext } from "context/LayoutContext";
import { CumplimientoConveniosContext } from "context/indicadores/CumplimientoConveniosContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useContext, useEffect, useCallback, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useSearchTable } from "hooks/otros/useSearchTable";
import { swalError, swalWarning } from "config/methods";
import { FilePdfOutlined } from "@ant-design/icons";
import { useExport } from "hooks/otros/useExport";
import { IoAddCircleSharp } from "react-icons/io5";

const breadcrumbs = [{ Label: "Cumplimiento Convenios" }];

interface PropsResponse {
  msg: boolean;
  data: PropsTables[];
}

interface PropsTableQuota {
  valor: number;
  rebate: number;
  total: number;
  es_divide?: boolean;
  es_monto: boolean;
}

interface PropsTables {
  cod_proveedor: number;
  key: string;
  llave: string;
  fecha_inicio: string;
  fecha_fin: string;
  nombres?: string;
  descripcion?: string;
  faltaSellIn: number;
  compras: number;
  sellIn: number;
  ventas: number;
  sellOut: number;
  color: string;
  porcentaje: number;
  faltaSellOut: number;
  periodo: string;
  proveedor: string;
  rebateSellIn: number;
  rebateSellOut: number;
  frecuenciaP: string;
  forma_pago: string;
  comprasBrutas: number;
  devoluciones: number;
}

interface PropsTables2 {
  key: string;
  mes: string;
  total: number;
}

type RecordType = {
  faltaSellIn: number;
  faltaSellOut: number;
};

interface PropsResumenCompras {
  key: number;
  proveedor: string;
  comprasBrutas: number;
  devoluciones: number;
  comprasNetas: number;
}

interface propsSelect {
  value: number;
  label: string;
}

export function useAdministrarConvenio() {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const { optionsPost, optionsGet } = useLocalStorage();
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const [descripcion, setDescripcion] = useState<string | undefined>("");
  const { getColumnSearchProps } = useSearchTable<PropsTables>();
  const [openModalPdf, setOpenModalPdf] = useState<boolean>(false);
  const [llave, setLlave] = useState<string>("");
  const [proyeccionC, setProyeccionC] = useState<number>(0);
  const [resumenCompras, setResumenCompras] = useState<PropsResumenCompras[]>([]);
  const [openModalFormValor, setOpenModalFormValor] = useState<boolean>(false);
  const [cuotaC, setCuotaC] = useState<number>(0);
  const { exportToExcel } = useExport();
  const [proveedores, setProveedores] = useState<propsSelect[]>([]);

  const [proyeccionV, setProyeccionV] = useState<number>(0);
  const [cuotaV, setCuotaV] = useState<number>(0);

  const [loaderModal, setLoaderModal] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);

  const {
    setDataFilterConvenio,
    dataFilterConvenio,
    option,
    setOption,
    setHeadTable,
    headTable,
    columnTable,
    setColumnTable,
    selectedRow,
    setSelectedRow,
    columnTableMonth,
    setColumnTableMonth,
    graph,
    setGraph,
    columnTableQuota,
    setColumnTableQuota,
    selectedRowQuota,
    setSelectedRowQuota,
    setColumnTableMonthV,
    columnTableQuotaV,
    columnTableMonthV,
    setColumnTableQuotaV,
  } = useContext(CumplimientoConveniosContext);

  const filterTableType = [
    { text: "Cumplido", value: "cumplido" },
    { text: "Renegociar", value: "renegociar" },
    { text: "En proceso", value: "en_proceso" },
  ];

  const customFilterFunction = (value: string | number | boolean, record: PropsTables, flag: keyof RecordType) => {
    if (value === "cumplido") {
      return record[flag] < 0;
    } else if (value === "renegociar") {
      return record[flag] > 0 && !moment(record.periodo?.split("\r\n")[1], "DD-MM-YYYY").isAfter(moment());
    } else if (value === "en_proceso") {
      return record[flag] > 0 && moment(record.periodo?.split("\r\n")[1], "DD-MM-YYYY").isAfter(moment());
    }
    return false;
  };

  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };

  const handleClickAction = (record: string) => {
    setLlave(`https://infofsg.com/allku/archivos/${record}`);
    setOpenModalPdf(true);
  };

  const calcularTotales = () => {
    let comprasBrutas = 0; // Asumiendo que el tiempo se puede sumar directamente
    let devoluciones = 0;
    let comprasNetas = 0;

    resumenCompras.forEach((item) => {
      comprasBrutas += item.comprasBrutas;
      devoluciones += item.devoluciones;
      comprasNetas += item.comprasNetas;
      // Calcula el total de tiempo si es necesario. Asegúrate de convertirlo al formato correcto.
    });

    return {
      comprasBrutas: formateaCantidad(comprasBrutas),
      devoluciones: formateaCantidad(devoluciones),
      comprasNetas: formateaCantidad(comprasNetas),
    };
  };

  const headTableResumenCompras: ColumnsType<PropsResumenCompras> = [
    {
      title: "Proveedor",
      dataIndex: "proveedor",
      key: "proveedor",
      width: "68px",
      className: "customTbl",
    },
    {
      title: "Compras Brutas",
      dataIndex: "comprasBrutas",
      key: "comprasBrutas",
      width: "68px",
      className: "customTbl",
      render: (_, record) => (
        <span style={{ color: record.comprasBrutas === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.comprasBrutas)}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "N/C Devoluciones",
      dataIndex: "devoluciones",
      key: "devoluciones",
      width: "68px",
      render: (_, record) => (
        <span style={{ color: record.devoluciones === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.devoluciones)}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
      className: "customTbl",
    },
    {
      title: "Compras Netas",
      dataIndex: "comprasNetas",
      key: "comprasNetas",
      width: "68px",
      render: (_, record) => (
        <span style={{ color: record.comprasNetas === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.comprasNetas)}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
      className: "customTbl",
    },
  ];

  const headTableLab: ColumnsType<PropsTables> = [
    {
      title: "Periodo",
      dataIndex: "periodo",
      key: "periodo",
      width: "68px",
      className: "customTbl",
      fixed: "left",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "168px",
      className: "customTbl",
      fixed: "left",
      ...getColumnSearchProps("descripcion"),
      render: (_, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            icon={<FilePdfOutlined />}
            type='ghost'
            style={{ border: "none", color: "red" }}
            onClick={() => handleClickAction(record.llave)}
          ></Button>
          <div className='scroll-column-scroll-250' title={record.descripcion}>
            {record.descripcion}
          </div>
        </div>
      ),
    },
    {
      title: "Frecuencia Pago",
      dataIndex: "frecuenciaP",
      key: "frecuenciaP",
      width: "65px",
      className: "customTbl",
      render: (_, record) => (
        <div className='scroll-column-scroll-250' title={record.frecuenciaP}>
          {record.frecuenciaP}
        </div>
      ),
    },
    {
      title: "Forma Pago",
      dataIndex: "forma_pago",
      key: "forma_pago",
      width: "57px",
      className: "customTbl",
    },
    {
      title: "Sell In Convenio",
      dataIndex: "sellIn",
      key: "sellIn",
      width: "76px",
      align: "right",
      className: "customTbl",
      render: (_, record) => (
        <span style={{ color: record.sellIn === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.sellIn)}</span>
      ),
    },
    {
      title: "Compras Brutas",
      dataIndex: "comprasBrutas",
      key: "comprasBrutas",
      width: "78px",
      align: "right",
      className: "customTbl",
      render: (_, record) => (
        <span style={{ color: record.comprasBrutas === 0 ? "lightgray" : "black", fontSize: "10px" }}>
          {formateaCantidad(record.comprasBrutas)}
        </span>
      ),
    },
    {
      title: "N/C",
      dataIndex: "devoluciones",
      key: "devoluciones",
      width: "78px",
      align: "right",
      className: "customTbl",
      render: (_, record) => (
        <span style={{ color: record.devoluciones === 0 ? "lightgray" : "black", fontSize: "10px" }}>
          {formateaCantidad(record.devoluciones)}
        </span>
      ),
    },
    {
      title: "Compras Netas",
      dataIndex: "compras",
      key: "compras",
      width: "78px",
      align: "right",
      className: "customTbl",
      render: (_, record) => (
        <span style={{ color: record.compras === 0 ? "lightgray" : "black", fontSize: "10px" }}>
          {formateaCantidad(record.compras)}
        </span>
      ),
    },

    {
      title: "Dif Sell In",
      dataIndex: "faltaSellIn",
      key: "faltaSellIn",
      width: "79px",
      align: "center",
      className: "customTbl",
      filters: filterTableType,
      onFilter: (value, record) => customFilterFunction(value, record, "faltaSellIn"),
      render: (_, record) => {
        const content =
          record.faltaSellIn >= 0 ? (
            record.faltaSellIn === 0 ? (
              <span style={{ color: "lightgray" }}>{formateaCantidad(record.faltaSellIn)}</span>
            ) : (
              <div className='center_container'>
                {moment(record.periodo?.split("\r\n")[1], "DD-MM-YYYY").isAfter(moment()) ? (
                  <span className='center_container'>
                    <Velocimetro porcentaje={(record.compras * 100) / record.sellIn} />
                    {formateaCantidad(record.faltaSellIn)}
                  </span>
                ) : (
                  <span className='center_container'>
                    <Tag color='red'>Renegociar</Tag>
                  </span>
                )}
              </div>
            )
          ) : (
            <span className='center_container'>
              <Tag color='green'>Cumplido</Tag>
            </span>
          );
        return <div>{content}</div>;
      },
    },
    {
      title: "Rebate Sell In",
      dataIndex: "rebateSellIn",
      key: "rebateSellIn",
      width: "52px",
      align: "right",
      className: "customTbl",
      render: (_, record) => (
        <span style={{ color: record.rebateSellIn === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.rebateSellIn)}
        </span>
      ),
    },
    {
      title: "TotalR Sell In",
      // dataIndex: "rebateSellIn",
      // key: "rebateSellIn",
      width: "74px",
      align: "right",
      className: "customTbl",
      render: (_, record) => (
        <span style={{ color: record.rebateSellIn * record.sellIn === 0 ? "lightgray" : "black" }}>
          {formateaCantidad((record.rebateSellIn * record.sellIn) / 100)}
        </span>
      ),
    },
    {
      title: "Sell Out Convenio",
      dataIndex: "sellOut",
      key: "sellIn",
      width: "73px",
      align: "right",
      className: "customTbl",
      render: (_, record) => (
        <span style={{ color: record.sellOut === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.sellOut)}</span>
      ),
    },
    {
      title: "Ventas",
      dataIndex: "ventas",
      key: "ventas",
      width: "73px",
      align: "right",
      className: "customTbl",
      render: (_, record) => (
        <span style={{ color: record.ventas === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.ventas)}</span>
      ),
    },

    {
      title: "Dif Sell Out",
      dataIndex: "faltaSellOut",
      key: "faltaSellOut",
      width: "79px",
      align: "right",
      className: "customTbl",
      filters: filterTableType,
      onFilter: (value, record) => customFilterFunction(value, record, "faltaSellOut"),
      render: (_, record) => {
        const content =
          record.faltaSellOut >= 0 ? (
            record.faltaSellOut === 0 ? (
              <span style={{ color: "lightgray" }}>{formateaCantidad(record.faltaSellOut)}</span>
            ) : (
              <div>
                {moment(record.periodo?.split("\r\n")[1], "DD-MM-YYYY").isAfter(moment()) ? (
                  <div className='center_container'>
                    <Velocimetro porcentaje={(record.ventas * 100) / record.sellOut} />
                    {formateaCantidad(record.faltaSellOut)}
                  </div>
                ) : (
                  <Tag color='red' className='center_container'>
                    Renegociar
                  </Tag>
                )}
              </div>
            )
          ) : (
            <Tag color='green' className='center_container'>
              Cumplido
            </Tag>
          );

        return <div>{content}</div>;
      },
    },
    {
      title: "Rebate Sell Out",
      dataIndex: "rebateSellOut",
      key: "rebateSellOut",
      width: "54px",
      align: "right",
      className: "customTbl",
      render: (_, record) => (
        <span style={{ color: record.rebateSellOut === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.rebateSellOut)}
        </span>
      ),
    },
    {
      title: "TotalR Sell Out",
      // dataIndex: "rebateSellIn",
      // key: "rebateSellIn",
      width: "74px",
      align: "right",
      className: "customTbl",
      render: (_, record) => (
        <span style={{ color: record.rebateSellOut * record.sellOut === 0 ? "lightgray" : "black" }}>
          {formateaCantidad((record.rebateSellOut * record.sellOut) / 100)}
        </span>
      ),
    },
    {
      title: "Proveedor Responsable",
      dataIndex: "proveedor",
      key: "proveedor",
      width: "100px",
      className: "customTbl",
      ...getColumnSearchProps("proveedor"),
      render: (_, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className='scroll-column-scroll-250' title={record.proveedor}>
            {record.proveedor}
          </div>
        </div>
      ),
    },
    {
      title: "Agregar Cobro",
      // dataIndex: "descripcion",
      // key: "descripcion",
      width: "53px",
      className: "customTbl",
      fixed: "right",
      render: (_, record) => (
        // <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          icon={<IoAddCircleSharp />}
          type='ghost'
          style={{ border: "none", color: "rgb(106 40 49)" }}
          onClick={() => handleClickAction2(record)}
        ></Button>
        // </div>
      ),
    },
  ];

  const headTableQuota: ColumnsType<PropsTableQuota> = [
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      // width: "84px",
      align: "right",
      className: "customTbl",
      render: (_, record) => (
        <span style={{ color: record.valor === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.valor)}</span>
      ),
    },
    {
      title: "Rebate",
      dataIndex: "rebate",
      key: "rebate",
      // width: "75px",
      align: "right",
      className: "customTbl",
      render: (_, record) => (
        <span style={{ color: record.rebate === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.rebate)}%</span>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      // width: "75px",
      align: "right",
      className: "customTbl",
      render: (_, record) => (
        <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.total)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
  ];

  const headTableMonth: ColumnsType<PropsTableQuota> = [
    {
      title: "Mes",
      dataIndex: "mes",
      key: "mes",
      width: "84px",
      align: "center",
      className: "customTbl",
    },

    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: "84px",
      align: "right",
      className: "customTbl",
      render: (_, record) => (
        <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.total)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
  ];

  const handleChangeFilter = useCallback(
    async (value: number) => {
      setHeadTable([]);
      setColumnTable(undefined);
      setSelectedRow([]);
      setColumnTableQuotaV([]);
      setColumnTableMonthV([]);
      setColumnTableQuota([]);
      setColumnTableMonth([]);
      setResumenCompras([]);
      setOption(form.getFieldsValue().filter);
      setDataFilterConvenio([]);
      if (form.getFieldsValue().filter === 1) {
        const respuesta: PropsResponse = await fetch(
          url + "apifsg-pr/reportes/filtro-proveedor/",
          optionsPost({ periodo: form.getFieldsValue().periodo })
        ).then((data) => data.json());

        if (!respuesta.msg) {
          setColumnTable([]);
          swalWarning({ text: "No se pudo cargar información de este periodo" });
        }
        respuesta.msg && setColumnTable(respuesta.data);
      } else if (form.getFieldsValue().filter === 2) {
        const respuesta: PropsResponse = await fetch(
          url + "apifsg-pr/reportes/filtro-laboratorio/",
          optionsPost({ periodo: form.getFieldsValue().periodo })
        ).then((data) => data.json());
        if (!respuesta.msg) {
          setColumnTable([]);
          swalWarning({ text: "No se pudo cargar información de este periodo" });
        } else {
          respuesta.msg && setColumnTable(respuesta.data);
        }
      }
    },
    [
      setColumnTableQuotaV,
      optionsPost,
      form,
      setColumnTableQuota,
      setColumnTableMonthV,
      setColumnTableMonth,
      setDataFilterConvenio,
      setOption,
      setColumnTable,
      setHeadTable,
      setSelectedRow,
    ]
  );

  const createOptionSelect = (data: PropsTables2[]) => {
    const uniqueValues = new Set();
    Object.entries(data).forEach((value) => {
      if (typeof value[1] === "number") {
        if (value[0] !== "margen" && value[0] !== "key" && value[0] !== "semana") uniqueValues.add(value[0]);
      }
    });

    return Array.from(uniqueValues) as string[];
  };

  const filterData = [
    {
      value: 1,
      label: "Proveedores",
    },
    {
      value: 2,
      label: "Laboratorios",
    },
  ];

  const filterDataPeriodo = [
    {
      value: 1,
      label: "2023",
    },
    {
      value: 2,
      label: "2024",
    },
  ];

  useBreadCrumbs(breadcrumbs);

  useEffect(() => {
    setOpenKeys(["Indicadores", "Indicadores"]);
    setSelectedKeys(["Indicadores/Cumplimiento Convenios"]);
  }, [setOpenKeys, setSelectedKeys]);

  useEffect(() => {
    handleChangeFilter(option);
  }, [handleChangeFilter, option]);

  const sendData = async (record: PropsTables) => {
    setColumnTableQuotaV([]);
    setColumnTableMonthV([]);
    setColumnTableQuota([]);
    setColumnTableMonth([]);
    setResumenCompras([]);

    try {
      setDescripcion(record.descripcion);
      const response = await fetch(
        option === 1 ? `${url}apifsg-pr/reportes/convenio-proveedor` : `${url}apifsg-pr/reportes/convenio-laboratorio`,
        optionsPost({ id: record.key })
      );
      const respuesta = await response.json();

      if (respuesta.msg) {
        setCuotaC(record.sellIn);
        setCuotaV(record.sellOut);

        setProyeccionC(parseFloat(JSON.parse(respuesta.data[0].comprasLast)[0]?.total));
        setProyeccionV(parseFloat(JSON.parse(respuesta.data[0].ventasLast)[0]?.total));

        const detallesSellIn = respuesta.data[0].detallesC.length > 0 ? JSON.parse(respuesta.data[0].detallesC) : [];
        const detallesSellOut = respuesta.data[0].detallesV.length > 0 ? JSON.parse(respuesta.data[0].detallesV) : [];
        const detallesResumenCopras =
          respuesta.data[0].resumenCompras.length > 0 ? JSON.parse(respuesta.data[0].resumenCompras) : [];
        setResumenCompras(detallesResumenCopras);
        setColumnTableMonth(detallesSellIn);
        setColumnTableMonthV(detallesSellOut);

        if (detallesSellIn.length === 0 && detallesSellOut.length > 0) {
          setGraph(createOptionSelect(detallesSellOut[0]));
        } else {
          setGraph(createOptionSelect(detallesSellIn[0]));
        }
        // setDescripcion(record.descripcion);
        let cuotasSellIn = respuesta.data[0].cuotasC.length > 0 ? JSON.parse(respuesta.data[0].cuotasC) : [];
        let cuotasSellOut = respuesta.data[0].cuotasV.length > 0 ? JSON.parse(respuesta.data[0].cuotasV) : [];

        let newArraySi = cuotasSellIn.map((el: PropsTableQuota, index: number) => {
          return {
            ...el,
            key: index + 1,
            rebate: el.rebate,
            total: el.es_monto ? (record.compras * el.rebate) / 100 : (el.valor * el.rebate) / 100,
            // total: (el.valor * el.rebate) / 100,
            ...(el.es_monto ? { valor: record.compras } : {}),
          };
        });

        let newArraySo = cuotasSellOut.map((el: PropsTableQuota, index: number) => {
          return {
            ...el,
            key: index + 1,
            rebate: el.rebate,
            total: el.es_monto ? (record.ventas * el.rebate) / 100 : (el.valor * el.rebate) / 100,
            // total: (el.valor * el.rebate) / 100,
            ...(el.es_monto ? { valor: record.ventas } : {}),
            // total: (el.valor * el.rebate) / 100,
          };
        });

        newArraySo.length > 0 &&
          setColumnTableQuotaV(newArraySo.sort((a: PropsTableQuota, b: PropsTableQuota) => a.valor - b.valor));
        newArraySi.length > 0 &&
          setColumnTableQuota(newArraySi.sort((a: PropsTableQuota, b: PropsTableQuota) => a.valor - b.valor));
        // handleRowSelectQuota(newArraySi[0]);
      }
    } catch (error) {
      swalError({ text: "Ocurrio un error" });
    }
  };

  const handleRowSelect = async (record: PropsTables) => {
    sendData(record);
    if (selectedRow && selectedRow[0] === record.key) {
      setSelectedRow([]);
    } else {
      let newSelection = [];
      newSelection.push(record.key);
      setSelectedRow(newSelection);
    }
  };

  const rowSelection = {
    type: "radio",
    onSelect: handleRowSelect,
    selectedRowKeys: selectedRow[0] ? [selectedRow[0]] : [],
  };

  const handleRowSelectQuota = async (record: PropsTables) => {
    // sendData(record);
    if (selectedRowQuota && selectedRowQuota[0] === record.key) {
      setSelectedRowQuota([]);
    } else {
      let newSelection = [];
      newSelection.push(record.key);
      setSelectedRowQuota(newSelection);
    }
  };

  const rowSelectionQuota = {
    type: "radio",
    onSelect: handleRowSelectQuota,
    selectedRowKeys: selectedRowQuota[0] ? [selectedRowQuota[0]] : [],
  };

  const handleClickDownload = () => {
    const dataExport = columnTable.map(
      ({
        periodo,
        descripcion,
        frecuenciaP,
        forma_pago,
        sellIn,
        compras,
        faltaSellIn,
        rebateSellIn,
        sellOut,
        ventas,
        faltaSellOut,
        rebateSellOut,
        proveedor,
        comprasBrutas,
        devoluciones,
      }: PropsTables) => ({
        Periodo: periodo,
        Descripcion: descripcion,
        "Frecuencia de Pago": frecuenciaP,
        "Forma de Pago": forma_pago,
        "Sell In Convenio": sellIn,
        "Compras Brutas": comprasBrutas,
        "N/C": devoluciones,
        "Compras Netas": compras,
        "Dif Sell In": faltaSellIn,
        "Rebate Sell In": rebateSellIn,
        "Total Rebate Sell In": (rebateSellIn * sellIn) / 100,
        "Sell Out": sellOut,
        Ventas: ventas,
        "Diferencia Sell Out": faltaSellOut,
        "Rebate Sell Out": rebateSellOut,
        "Total Rebate Sell Out": (rebateSellOut * sellOut) / 100,
        "Proveedor Autorizado": proveedor,
      })
    );
    exportToExcel(dataExport, "Lista de convenios");
  };

  const handleOkCobros = () => {
    setOpenModalFormValor(false);
  };

  const handleCancelCobros = () => {
    setOpenModalFormValor(false);
  };

  const handleClickAction2 = async (record: PropsTables) => {
    try {
      if (record.faltaSellIn >= 0 && record.faltaSellOut >= 0)
        return notification.warning({
          message: "Advertencia!",
          description: "No puedes agregar un cobro hasta que se hayan cumplido al menos una de las dos cuotas.!",
          placement: "bottomRight",
        });

      //verifica cobros de convenios
      const respuesta1 = await fetch(
        url + "apifsg-pr/reportes/convenio-estado",
        optionsPost({ cod_convenio: record.key })
      ).then((data) => data.json());
      if (respuesta1.msg && respuesta1.data[0].estado)
        return notification.warning({
          message: "Advertencia!",
          description: "El cobro de este convenio ya fue cruzado!",
          placement: "bottomRight",
        });

      setOpenModalFormValor(true);
      setLoaderModal(true);
      setProveedores([]);

      const respuesta = await fetch(url + "apifsg-pr/convenios/proveedores", optionsGet).then((data) => data.json());
      if (respuesta.msg) {
        const { data } = respuesta;
        setProveedores(data);
        let total: number = 0;
        if (record.faltaSellIn < 0) total += (record.rebateSellIn * record.sellIn) / 100;
        if (record.faltaSellOut < 0) total += (record.rebateSellOut * record.sellOut) / 100;
        setLoaderModal(false);
        if (respuesta1.msg && !respuesta1.data[0].estado) {
          form2.setFieldsValue({ ...respuesta1.data[0], cod_convenio: record.key });
          setLoaderModal(false);
          return;
        }
        form2.setFieldsValue({
          cod_proveedor: record.cod_proveedor,
          observacion: record.descripcion + " " + record.periodo,
          valor: total,
          cod_convenio: record.key,
        });
      }
      setLoaderModal(false);
    } catch (error) {}
  };

  const saveNewValue = async () => {
    try {
      form2
        .validateFields()
        .then(async (values) => {
          try {
            setLoadingModal(true);
            const respuesta: PropsResponse = await fetch(
              url + "apifsg-pr/reportes/convenio-registra-valor/",
              optionsPost({ values })
            ).then((data) => data.json());
            if (!respuesta.msg) {
              setLoadingModal(false);
              return notification.error({
                message: "Error!",
                description: "Ocurrio un error al guardar.!",
                placement: "bottomRight",
              });
            }
            notification.success({
              message: "Ok!",
              description: "Se guardó correctamente la información.!",
              placement: "bottomRight",
            });
            setOpenModalFormValor(false);
            setLoadingModal(false);
          } catch (error) {
            setLoadingModal(false);
            return notification.error({
              message: "Error!",
              description: "Ocurrio un error al procesar la información.!",
              placement: "bottomRight",
            });
          }
        })

        .catch();
    } catch (error) {}
  };

  return {
    loadingModal,
    saveNewValue,
    loaderModal,
    openModalFormValor,
    handleCancelCobros,
    handleOkCobros,
    filterData,
    handleChangeFilter,
    dataFilterConvenio,
    form,
    option,
    headTable,
    columnTable,
    rowSelection,
    headTableMonth,
    columnTableMonth,
    graph,
    descripcion,
    headTableQuota,
    columnTableQuota,
    rowSelectionQuota,
    headTableLab,
    columnTableQuotaV,
    columnTableMonthV,
    openModalPdf,
    setOpenModalPdf,
    llave,
    cuotaC,
    proyeccionC,
    cuotaV,
    proyeccionV,
    formateaCantidad,
    filterDataPeriodo,
    handleClickDownload,
    headTableResumenCompras,
    resumenCompras,
    calcularTotales,
    form2,
    proveedores,
  };
}
