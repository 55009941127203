import { Button, Tag, type TableColumnsType } from "antd"
import { useLayout } from "hooks/otros/useLayout"
import { useCallback, useEffect, useState } from "react"

import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons"

import { url } from "config/constants"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSwal } from "hooks/otros/useSwal"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"

export type BonosFijosPorFranquiciado = {
  cod_franquiciado: number
  cod_bono: number
  nombres: string
  fecha: string
  valor: number
  estado: boolean
}

export type Bonos = {
  key: number
  bono: string
  estado: boolean
  franquiciados_asignados: BonosFijosPorFranquiciado[]
}

const breadcrumbs = [{ Label: "Franquiciados", Url: "/franquiciados" }, { Label: "Valores adicionales" }]

const openKeys = [breadcrumbs[0].Label]

export function useBonosFranquiciados() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label)

  const [data, setData] = useState<Bonos[]>([])
  const [openModal, setOpenModal] = useState(false)
  const [selectedData, setSelectedData] = useState<number>()

  const [selectedFranquiciado, setSelectedFranquiciado] = useState<number>()
  const [openModalAsignarFranquiciado, setOpenModalAsignarFranquiciado] = useState(false)

  const { optionsGet } = useLocalStorage()

  const { swalError } = useSwal()

  const { isError } = useVerifyResponse()

  const reFetcher = useCallback(async () => {
    try {
      const result = await fetch(`${url}fapifsg-pr/franquiciados/listar-bonos`, optionsGet)
      if (isError(result)) throw new Error()
      const response = await result.json()
      setData(response)
    } catch (e) {
      swalError({ text: "No se pudo obtener la lista de los bonos registrados" })
    }
  }, [optionsGet, swalError, isError])

  useEffect(() => {
    reFetcher()
  }, [reFetcher])

  const columns: TableColumnsType<Bonos> = [
    {
      title: "Valor adicional",
      dataIndex: "bono",
      key: "bono",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      align: "center",
      render: (value: boolean) => (value ? <Tag color="success">Activo</Tag> : <Tag color="default">Inactivo</Tag>),
    },
    {
      title: "Modificar",
      dataIndex: "key",
      align: "center",
      render: (value: number) => {
        return (
          <Button
            icon={<EditOutlined style={{ fontSize: "1.3rem" }} />}
            type="text"
            block
            onClick={() => {
              setSelectedData(data.findIndex(d => d.key === value))
              setOpenModal(true)
            }}
          />
        )
      },
    },
    {
      title: "Asignar franquiciado",
      dataIndex: "key",
      key: "asignar",
      align: "center",
      render: (value: number) => (
        <Button
          icon={<PlusCircleOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          block
          onClick={() => {
            setSelectedData(data.findIndex(d => d.key === value))
            setOpenModalAsignarFranquiciado(true)
          }}
        />
      ),
    },
  ]

  return {
    setSelectedData,
    selectedData,
    setOpenModal,
    openModal,
    data,
    columns,
    reFetcher,
    selectedFranquiciado,
    setSelectedFranquiciado,
    openModalAsignarFranquiciado,
    setOpenModalAsignarFranquiciado,
  }
}
