import { Form } from "antd";

import { useCallback, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useScreenSize } from "hooks/otros/useScreenSize";
import { useSwal } from "hooks/otros/useSwal";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import { useLayout } from "hooks/otros/useLayout";

import { UserContext } from "context/UserContext";

import { url } from "config/constants";

import type { FormProps } from "antd";
import type { Moment } from "moment";
import type { DefaultOptionType } from "antd/lib/select";
import type { RcFile, UploadFile, UploadProps } from "antd/lib/upload";
import { getBase64 } from "config/methods";

interface valuesForm {
  multa: boolean;
  porcentaje: number;
  empleados: Array<number>;
  reglamentos: Array<number>;
  fecha_falta: Moment;
  departamento: number;
  bodega: number;
  descripcion: string;
  responsables: Array<number>;
}

interface responseSelect {
  msg: "ok" | "error" | "err";
  data: DefaultOptionType[];
}

const breadcrumbs = [
  { Label: "Recursos Humanos", Url: "/rrhh" },
  { Label: "Llamados de atención", Url: "/rrhh/llamados-de-atencion" },
  { Label: "Crear solicitud" },
];
const openKeys = [`${breadcrumbs[0].Label}`, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`];
const selectKeySider = `${breadcrumbs[1].Label}/Solicitud`;

export function useLlamadoAtencion() {
  useLayout(breadcrumbs, openKeys, selectKeySider);
  const { userData: dataUser } = useContext(UserContext);

  const [empleados, setEmpleados] = useState<Array<DefaultOptionType>>([]);
  const [usuarios, setUsuarios] = useState<Array<DefaultOptionType>>([]);
  const [departamentos, setDepartamentos] = useState<Array<DefaultOptionType>>([]);
  const [bodegas, setBodegas] = useState<Array<DefaultOptionType>>([]);
  const [reglamentos, setReglamentos] = useState<Array<DefaultOptionType>>([]);
  const [previewImage, setPreviewImage] = useState("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [fileList, setFileList] = useState<Array<UploadFile>>([]);
  const [disabledPorcentajeMulta, setDisabledPorcentajeMulta] = useState<boolean>(true);
  const [showRemoveIcon, setShowRemoveIcon] = useState<boolean>(true);
  const [disabledButtonSubmit, setDisabledButtonSubmit] = useState<boolean>(false);

  const [form] = Form.useForm<valuesForm>();

  const { wasInvalidToken, hadError } = useVerifyResponse();

  const { swalError, swalSuccess } = useSwal();

  const { optionsGet, optionsPost, getItem, setItem } = useLocalStorage();

  const { width } = useScreenSize();

  const isWeb = width >= 778;

  useEffect(() => {
    const usuario = dataUser?.id ?? 0;
    const images = getItem(usuario.toString() + "evidenciasLlamadoAtencion");

    images && setFileList(JSON.parse(images));
  }, [dataUser?.id, getItem]);

  const handleOnChangeDepartamento = useCallback(
    async (value: number) => {
      try {
        const res = await fetch(
          `${url}apifsg-pr/recursos-humanos/empleados-por-departamento-select`,
          optionsPost({ cod_departamento: value })
        );

        const response: responseSelect = await res.json();

        if (wasInvalidToken(response)) return;
        if (hadError(response, "No se pudo obtener los datos empleados")) return;

        const { data } = response;

        data && setEmpleados(data);
      } catch (e) {
        console.error(e);
        swalError({ text: "No se pudo obtener los datos empleados" });
      }
    },
    [optionsPost, wasInvalidToken, hadError, swalError]
  );

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(`${url}apifsg-pr/recursos-humanos/departamentos-select`, optionsGet);

        const response: responseSelect = await res.json();

        if (wasInvalidToken(response)) return;
        if (hadError(response, "No se pudo obtener los departamentos")) return;

        const { data } = response;

        data && setDepartamentos(data);
      } catch (e) {
        console.error(e);
        swalError({ text: "No se pudo obtener los departamentos" });
      }
    })();
    handleOnChangeDepartamento(5);
  }, [optionsGet, wasInvalidToken, hadError, swalError, handleOnChangeDepartamento]);

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(`${url}apifsg-pr/recursos-humanos/usuarios-select`, optionsGet);

        const response: responseSelect = await res.json();

        if (wasInvalidToken(response)) return;
        if (hadError(response, "No se pudo obtener los usuarios")) return;

        const { data } = response;

        data && setUsuarios(data);
      } catch (e) {
        console.error(e);
        swalError({ text: "No se pudo obtener los usuarios" });
      }
    })();
    handleOnChangeDepartamento(5);
  }, [optionsGet, wasInvalidToken, hadError, swalError, handleOnChangeDepartamento]);

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(`${url}apifsg-pr/bodegas/listar-bodegas-select`, optionsGet);

        const response: responseSelect = await res.json();

        if (wasInvalidToken(response)) return;
        if (hadError(response, "No se pudo obtener las bodegas")) return;

        const { data } = response;

        setBodegas(data);
      } catch (e) {
        console.error(e);
        swalError({ text: "No se pudo obtener las bodegas" });
      }
    })();
  }, [optionsGet, wasInvalidToken, hadError, swalError]);

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(`${url}apifsg-pr/recursos-humanos/listar-reglamento`, optionsGet);

        const response: responseSelect = await res.json();

        if (wasInvalidToken(response)) return;
        if (hadError(response, "No se pudo obtener el reglamento interno")) return;

        const { data } = response;

        setReglamentos(data);
      } catch (e) {
        console.error(e);
        swalError({ text: "No se pudo obtener el reglamento interno" });
      }
    })();
  }, [optionsGet, wasInvalidToken, hadError, swalError]);

  const handleCreate: FormProps["onFinish"] = async (values: valuesForm) => {
    setDisabledButtonSubmit(true);
    const { empleados, reglamentos, bodega, fecha_falta, multa, porcentaje, descripcion, departamento, responsables } =
      values;
    const data = {
      empleados,
      reglamentos,
      responsables,
      imagenes: fileList.map((file) => file.response.llave),
    };
    const res = await fetch(
      `${url}apifsg-pr/recursos-humanos/crear-solicitud-llamado-atencion`,
      optionsPost({
        bodega,
        departamento,
        fecha: fecha_falta.format("DD/MM/YYYY"),
        multa,
        porcentaje,
        descripcion,
        data,
      })
    );
    const response = await res.json();
    if (wasInvalidToken(response)) return;
    if (hadError(response, "No se pudo crear el llamado de atención")) return;
    swalSuccess({ text: "Llamado de atención creado con éxito" });
    form.setFieldsValue({
      departamento: 5,
      bodega: undefined,
      empleados: undefined,
      reglamentos: undefined,
      fecha_falta: undefined,
      multa: false,
      porcentaje: 0,
      descripcion: undefined,
      responsables: [dataUser?.id],
    });
    setFileList([]);
    const usuario = dataUser?.id ?? 0;
    localStorage.removeItem(usuario.toString() + "evidenciasLlamadoAtencion");
    setDisabledButtonSubmit(false);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) file.preview = await getBase64(file.originFileObj as RcFile);

    setPreviewImage(file.url || (file.preview as string));
    setOpenModal(true);
  };

  const handleRemoveImage = async ({ response }: UploadFile<any>) => {
    try {
      setShowRemoveIcon(false);

      const res = await fetch(
        `${url}apiallku-private-eliminar/api/deletefromapiexterna`,
        optionsPost({ files: [response.llave] })
      );

      const data = await res.json();

      // console.log({ res, data })

      if (wasInvalidToken(data)) return false;

      if (hadError(data, "No se pudo eliminar la imagen")) return false;

      if (!data.ok) {
        if (data.data === "FILE_NOT_FOUND") return true;
        return false;
      }

      return true;
    } catch (e) {
      return false;
    }
  };

  const handleChangeUpload: UploadProps["onChange"] = ({ fileList: newFileList }) => setFileList(newFileList);

  useEffect(() => {
    const usuario = dataUser?.id ?? 0;
    if (fileList.length === 0) {
      localStorage.removeItem(usuario.toString() + "evidenciasLlamadoAtencion");
      setShowRemoveIcon(true);
      return;
    }
    const files = fileList.filter(({ status }) => status === "success" || status === "done");
    files.length > 0 &&
      setItem(
        usuario.toString() + "evidenciasLlamadoAtencion",
        JSON.stringify(
          files.map(({ uid, name, status, response }) => ({
            uid,
            name,
            status,
            url: `${url}apiallku-public/api/${response?.llave}`,
            response,
          }))
        )
      );

    setShowRemoveIcon(true);
  }, [fileList, dataUser?.id, setItem]);

  return {
    empleados,
    departamentos,
    isWeb,
    form,
    bodegas,
    reglamentos,
    handleOnChangeDepartamento,
    handlePreview,
    previewImage,
    openModal,
    setOpenModal,
    getItem,
    handleRemoveImage,
    handleChangeUpload,
    fileList,
    handleCreate,
    disabledPorcentajeMulta,
    setDisabledPorcentajeMulta,
    usuarios,
    usuario: dataUser?.id,
    showRemoveIcon,
    disabledButtonSubmit,
  };
}
