import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useContext, useEffect, useState } from "react";
import { url } from "config/constants";
import { optionsPost, swalSuccess, swalWarning } from "config/methods";
import { DataTransfer } from "./useProductosIncentivosEditar";
import { Form } from "antd";
import moment, { Moment } from "moment";
import Swal from "sweetalert2";

const breadcrumbs = [{ Label: "Gestionar billetéate" }];

export interface OptionType {
  value: number;
  label: string;
  es_existe: number;
}

const dataCategoria = [
  {
    value: "Medio",
    label: "Medio",
  },
  {
    value: "Alto",
    label: "Alto",
  },
];

export const useProductosBilleteate = () => {
  const [form] = Form.useForm();
  useBreadCrumbs(breadcrumbs);

  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [dataTransfer, setDataTransfer] = useState<DataTransfer[]>([]);

  const [productsKeys, setProductsKeys] = useState<string[]>([]);

  const [desAtc4, setDesAtc4] = useState<string>("");
  const [desAtc5, setDesAtc5] = useState<string>("");
  const [isDelete, setIsDelete] = useState<boolean>(false);

  const [valorCaja, setValorCaja] = useState<number | null>(null);
  const [porcentajeVenta, setPorcentajeVenta] = useState<number | null>(null);
  const [codProducto, setCodProducto] = useState<number | null | undefined>();

  const disabledDate = (current: Moment | null): boolean => {
    return current ? current < moment().startOf("day").add(0, "day") : false;
  };

  // Validacion si uno de los valores se establece en algo distinto de null y no es 0, deshabilitar el otro campo
  useEffect(() => {
    if (valorCaja !== null && valorCaja !== 0) {
      setPorcentajeVenta(0);
      form.setFieldsValue({
        porcentaje_venta: 0,
      });
    } else if (porcentajeVenta !== null && porcentajeVenta !== 0) {
      setValorCaja(0);
      form.setFieldsValue({
        valor_caja: 0,
      });
    }
  }, [valorCaja, porcentajeVenta, form]);

  const handleChange = (targetKeys: string[]) => {
    setProductsKeys(targetKeys);
  };

  const resetItem = () => {
    setCodProducto(null);
    setIsDelete(false);
    setOptions([]);
    form.resetFields();
    setProductsKeys([]);
    setDesAtc5("");
    setDataTransfer([]);
    setValorCaja(null);
    setPorcentajeVenta(null);
    setDesAtc4("");
    setDesAtc5("");
  };

  const handleSubmit = async () => {
    try {
      form
        .validateFields()
        .then(async (values) => {
          // setLoading(false);
          // if (productsKeys.length <= 0) {
          //   Swal.fire("Error", `La lista de productos a emparejar no puede estar vacía!`, "warning");
          //   throw new Error("La lista de productos a emparejar no puede estar vacía!");
          // }
          const respuesta = await fetch(
            url + "apifsg-pr/productos/crear-billeteate/",
            optionsPost({
              values,
              id_productos: JSON.stringify(productsKeys),
              id_productos_n: JSON.stringify(dataTransfer),
            })
          ).then((data) => data.json());
          if (respuesta.msg) {
            resetItem();
            swalSuccess({ text: "Se guardó los cambios correctamente" });
          } else if (!respuesta.msg) {
            swalWarning({ text: "Ocurrio un error , intente nuevamante!" });
          }
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire("Error", `Complete los campos requeridos !`, "error");
        });
    } catch (error) {
      Swal.fire("Error", `${error}`, "warning");
    }
  };

  const handleChangeProducto = async (values: string) => {
    setProductsKeys([]);
    setDesAtc5("");
    setDataTransfer([]);
    setValorCaja(null);
    setPorcentajeVenta(null);
    form.setFieldsValue({
      categoria: null,
      valor_caja: null,
      porcentaje_venta: null,
      fecha: null,
      desc_atc: null,
    });

    try {
      if (values) {
        const { cod_producto, es_existe } = JSON.parse(values);
        setIsDelete(es_existe === 1 ? true : false);
        setCodProducto(cod_producto);
        const respuesta = await fetch(url + "apifsg-pr/productos/detalles-billeteate/", optionsPost({ cod_producto })).then(
          (data) => data.json()
        );

        if (respuesta.msg) {
          setProductsKeys(JSON.parse(JSON.parse(respuesta.data[0].data)[0].seleccionados));
          setDataTransfer(JSON.parse(respuesta.data[0].data)[0].productos);
          let dataInfo = JSON.parse(respuesta.data[0].data)[0].infoBilleteate[0];
          setDesAtc4(JSON.parse(respuesta.data[0].data)[0].descripcion4);
          setDesAtc5(JSON.parse(respuesta.data[0].data)[0].descripcion5);
          if (dataInfo) {
            form.setFieldsValue({
              categoria: dataInfo.categoria,
              valor_caja: dataInfo.valor_caja,
              porcentaje_venta: dataInfo.porcentaje_venta,
              fecha: [moment(dataInfo.fecha_inicio, "DD/MM/YYYY"), moment(dataInfo.fecha_fin, "DD/MM/YYYY")],
            });
            setValorCaja(dataInfo.valor_caja);
            setPorcentajeVenta(dataInfo.porcentaje_venta);
          }
          form.setFieldsValue({ desc_atc: JSON.parse(respuesta.data[0].data)[0].descripcion5 });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearch = async (value: string) => {
    if (value.length > 2) {
      try {
        const respuesta = await fetch(url + "apifsg-pr/productos/listar/", optionsPost({ value })).then((data) =>
          data.json()
        );
        if (respuesta.msg) {
          setOptions(respuesta.data);
        } else {
          setOptions([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOptions([]);
      }
    } else {
      setOptions([]);
    }
  };

  const handleChangeAtc5 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDesAtc5(e.target.value?.toUpperCase());
  };

  const handleDelete = async (cod_producto: number | null | undefined) => {
    if (cod_producto) {
      try {
        const respuesta = await fetch(url + "apifsg-pr/productos/eliminar-billeteate", optionsPost({ cod_producto })).then(
          (data) => data.json()
        );
        if (respuesta.msg) {
          resetItem();
        } else {
          Swal.fire("Error", `Ocurrio un error al intentar eliminar el producto del plan billetéate!`, "error");
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    setOpenKeys(["Productos", "Productos"]);
    setSelectedKeys(["Productos/Gestionar billetéate"]);
  }, [setOpenKeys, setSelectedKeys]);

  return {
    disabledDate,
    handleDelete,
    handleSubmit,
    setValorCaja,
    setPorcentajeVenta,
    porcentajeVenta,
    valorCaja,
    handleChangeAtc5,
    options,
    handleSearch,
    handleChangeProducto,
    dataTransfer,
    productsKeys,
    form,
    dataCategoria,
    handleChange,
    desAtc4,
    desAtc5,
    isDelete,
    codProducto,
  };
};
