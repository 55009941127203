import { Button, Col, Row, Table, Card, Form, Select } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import "moment/locale/es";
import { useSolicitud } from "hooks/cajas/pagoTramites/useSolicitud";
import Input from "antd/lib/input/Input";

export function Solicitud() {
  const {
    columns,
    filteredData,
    handleSave,
    estadoGuardar,
    estadoBoton,
    form,
    datosBodega,
    handleChangeBodega,
  } = useSolicitud();

  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <Form form={form}>
            <Form.Item label="Trámite" name="titulo"rules={[{ required: true, message: "La descripción es obligatoria" }]}>
              <Input required placeholder="Ingrese la descripción del trámite" />
            </Form.Item>
          
          <Form.Item label="Bodega" name="bodega">
            <Select
              options={datosBodega}
              allowClear
              showSearch
              //style={{ width: "40%", padding: "5px" }}
              optionFilterProp="label"
              placeholder="Seleccione una bodega"
              onChange={handleChangeBodega}
            ></Select>
          </Form.Item>
          </Form>
          <Table style={{ width: "100%", margin: "10px" }} columns={columns} dataSource={filteredData} pagination={false} />
          {estadoBoton && (
            <Button
              type="primary"
              style={{
                width: "100%",
              }}
              onClick={handleSave}
              loading={estadoGuardar}
              icon={<SaveOutlined className="iconbutton" />}
            >
              Solicitar
            </Button>
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default Solicitud;
