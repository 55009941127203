
import { Principal } from 'pages/contentprincipal/Principal'
import { RouteObject } from 'react-router-dom'
import { OrdenCompras } from "pages/contentcompras/OrdenCompras"

export const rutasCompras: RouteObject[] = [
    {
      path: "/compras",
      element: <Principal />,
    },
    {
      path: "/compras/orden-compras",
      element: <OrdenCompras />,
    },
]