import { Form, Input, Table, InputRef } from "antd"
import type { FormInstance } from "antd/es/form"
import React, { useContext, useEffect, useRef, useState } from "react"
import Swal from "sweetalert2"
import { ColumnTypes, DataType, useCategoriaFarmacia } from "../../hooks/politicas/useCategoriaFarmacia"
import { PuntoVenta } from "./PuntoVenta"
import Parrafos from "../../components/politicas/Parrafos"

const EditableContext = React.createContext<FormInstance<any> | null>(null)

interface Item {
  key: string
  porcentaje: number
  margen_m_c: number
  margen_l_c: number
}

interface EditableRowProps {
  index: number
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

interface EditableCellProps {
  title: React.ReactNode
  editable: boolean
  children: React.ReactNode
  dataIndex: keyof Item
  record: Item
  handleSave: (record: Item) => void
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef<InputRef>(null)
  const form = useContext(EditableContext)!

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({ [dataIndex]: record[dataIndex] })
  }

  const save = async () => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      console.log("Save failed:", errInfo)
    }
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <Input ref={inputRef} onPressEnter={save} onBlur={save} autoComplete="off" />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

export const CategoriaFarmacia: React.FC = () => {
  const [dataSource, setDataSource] = useState<DataType[]>([])

  let { getCategoriaFarmacia, defaultColumns, putVentaMensual } = useCategoriaFarmacia()

  useEffect(() => {
    const getCategorias = async () => {
      let res = await getCategoriaFarmacia()
      res.data.map((el: DataType) => (el.hasta = "---"))
      setDataSource(res.data)
    }
    getCategorias()
  }, [getCategoriaFarmacia])

  const handleSave = async (row: DataType) => {
    let res = await putVentaMensual(row)
    if (res.status) {
      Swal.fire("Ok", res.data, "success")
    }
    const newData = [...dataSource]
    const index = newData.findIndex(item => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row,
    })
    setDataSource(newData)
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    }
  })

  return (
    <div style={{ height: "83vh" }}>
      <Parrafos
        title={`• Farmacias nuevas empiezan en categoría 4 y después de 3 meses de funcionamiento se
        autorregulan los mínimos.`}
      />
      <Parrafos
        title={`• Se ajustan mínimos todos los domingos. Con arrastre de tres meses con pesos, el ultimo mes 40% y los otros dos 30% cada uno(40-30-30) farmacias nuevas
        durante los tres primeros meses suben los mínimos en base al último mes y luego se
        autorregulan.`}
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Table
          pagination={false}
          components={components}
          size="small"
          rowClassName={() => "editable-row"}
          dataSource={dataSource}
          columns={columns as ColumnTypes}
          style={{
            padding: "10px",
            width: "390px",
          }}
        />
      </div>
      <PuntoVenta />
    </div>
  )
}
