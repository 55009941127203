import { useFormularioEstado } from "hooks/rrhh/actualizacion/useFormularioEstado"
import { useEffect, useRef, useState } from "react"
import { SearchOutlined } from "@ant-design/icons"
import type { InputRef } from "antd"
import { Button, Input, Space, Table, Tag } from "antd"
import type { ColumnsType, ColumnType } from "antd/es/table"
import type { FilterConfirmProps } from "antd/es/table/interface"
import Highlighter from "react-highlight-words"

interface DataType {
  key: string
  nombres: string
  cedula: number
  telefono1: string
  correo_personal: String
  estado_actualizacion: boolean
}

type DataIndex = keyof DataType

export const FormularioEstado = () => {
  const [searchText, setSearchText] = useState("")
  const [estado, setEstado] = useState([])
  const [searchedColumn, setSearchedColumn] = useState("")
  const searchInput = useRef<InputRef>(null)
  let { getEstados } = useFormularioEstado()

  useEffect(() => {
    const fnEstados = async () => {
      let res = await getEstados()
      setEstado(res.data)
    }
    fnEstados()
  }, [getEstados])

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText("")
  }

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  })

  const columns: ColumnsType<DataType> = [
    {
      title: "Nombres",
      dataIndex: "nombres",
      key: "nombres",
      width: "25%",
      ...getColumnSearchProps("nombres"),
    },
    {
      title: "Cédula",
      dataIndex: "cedula",
      key: "cedula",
      width: "12%",
      ...getColumnSearchProps("cedula"),
    },
    {
      title: "Teléfono",
      dataIndex: "telefono1",
      key: "telefono1",
      width: "12%",
      ...getColumnSearchProps("telefono1"),
    },
    {
      title: "Correo Personal",
      dataIndex: "correo_personal",
      key: "correo_personal",
      width: "29%",
      ...getColumnSearchProps("correo_personal"),
    },
    {
      title: "Actualización",
      dataIndex: "estado_actualizacion",
      key: "estado_actualizacion",
      width: "10%",
      // ...getColumnSearchProps("estado_actualizacion"),
      render: (_, { estado_actualizacion }) => {
        return <>{estado_actualizacion === true ? <Tag color="green">Completada</Tag> : <Tag color="red">Pendiente</Tag>}</>
      },
    },
  ]

  return (
    <div style={{ padding: "20px" }}>
      <Table
        columns={columns}
        dataSource={estado}
        id="table"
        scroll={{
          x: 1000,
        }}
      />
    </div>
  )
}
