import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import icon from "./constants";
import { LatLngExpression } from "leaflet";
import { PropsMaps } from "../rrhh/componentsActualizacion/CroquisMap";

export const Maps = ({ ubicacion }: PropsMaps) => {
  function LocationMarker() {
    const [bbox, setBbox] = useState<string[]>([]);
    const [position, setPosition] = useState<LatLngExpression | null>(null);
  
    const map = useMap();
  
    useEffect(() => {
      ubicacion?.current && setPosition(ubicacion?.current);
      if (!ubicacion?.current) {
        map.locate().on("locationfound", function (e) {
          if (!ubicacion?.current) {
            setPosition(e.latlng);
            if (ubicacion) {
              ubicacion.current = e.latlng;
            }
            map.flyTo(e.latlng, map.getZoom());
            setBbox(e.bounds.toBBoxString().split(","));
            map.invalidateSize();
          } else {
            setPosition(ubicacion?.current);
          }
        });
      }
      map.locate().on("click", function (e) {
        setPosition(e.latlng);
        if (ubicacion) {
          ubicacion.current = e.latlng;
        }
      });
    }, [map]);

    return position === null ? null : (
      <Marker position={position} icon={icon}>
        <Popup>
          Ud. esta aqui <br />
          Detalles: <br />
          <b>Southwest lng</b>: {bbox[0]} <br />
          <b>Southwest lat</b>: {bbox[1]} <br />
          <b>Northeast lng</b>: {bbox[2]} <br />
          <b>Northeast lat</b>: {bbox[3]}
        </Popup>
      </Marker>
    );
  }
  
  const [mapInitialized, setMapInitialized] = useState(false);
  
  useEffect(() => {
    setMapInitialized(true);
    return () => {
      setMapInitialized(false);
    };
  }, []);
  
  return (
    <>
      {mapInitialized && (
        <MapContainer
          center={[-1.831239, -78.183406]}
          zoom={14}
          scrollWheelZoom
          style={{ height: "65vh" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <LocationMarker />
        </MapContainer>
      )}
    </>
  );
};