import ReactPDF, { Text, StyleSheet } from "@react-pdf/renderer"

// interface titleProps {
//   text: string
// }

const styles = StyleSheet.create({
  title: {
    fontSize: 14,
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
    marginTop: 30,
    // marginLeft: 100,
    // marginRight: 100,
    marginBottom: 30
  }
})

export const Title = ({ children, style }: ReactPDF.TextProps) => <Text style={ style ? { ...styles.title, ...style } : styles.title }>{ children }</Text>