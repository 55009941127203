import { ReportTable, columnsTablePDF } from "components/reactreport/report-table"
import type { Style } from "@react-pdf/types"

type Props = {
  commonColumnStyle: Style
  rowHeaderStyle: Style
  total_menos_gastos: number
  retenciones: number
  iva_12: number
}

type Data = {
  datos: string
  factura: number | string
  retencion: number | string
}

export function DatosFactura({ commonColumnStyle, rowHeaderStyle, total_menos_gastos, retenciones, iva_12 }: Props) {
  const data: Data[] = [
    {
      datos: "Valor neto",
      factura: total_menos_gastos < 0 ? "-" : total_menos_gastos,
      retencion: total_menos_gastos < 0 ? "-" : retenciones,
    },
    {
      datos: "IVA",
      factura: total_menos_gastos < 0 ? "-" : iva_12,
      retencion: total_menos_gastos < 0 ? "-" : iva_12,
    },
    {
      datos: "Total",
      factura: total_menos_gastos < 0 ? "-" : total_menos_gastos + iva_12,
      retencion: total_menos_gastos < 0 ? "-" : retenciones + iva_12,
    },
  ]
  const columnsData: columnsTablePDF<Data>[] = [
    {
      title: "Datos",
      dataIndex: "datos",
      key: "datos",
      styleColumn: { ...commonColumnStyle, padding: 3, textAlign: "left" },
      styleColumnHeader: { ...commonColumnStyle, padding: 3, textAlign: "left" },
    },
    {
      title: "Factura",
      dataIndex: "factura",
      key: "factura",
      styleColumn: { ...commonColumnStyle, padding: 3, textAlign: "right" },
      styleColumnHeader: { ...commonColumnStyle, padding: 3, textAlign: "right" },
      fixed: total_menos_gastos >= 0,
      styleCell: index => (index === data?.length - 2 ? { borderBottom: "1.3px solid #000" } : {}),
    },
    {
      title: "Retencion",
      dataIndex: "retencion",
      key: "retencion",
      styleColumn: { ...commonColumnStyle, padding: 3, textAlign: "right" },
      styleColumnHeader: { ...commonColumnStyle, padding: 3, textAlign: "right" },
      fixed: total_menos_gastos >= 0,
      styleCell: index => (index === data?.length - 2 ? { borderBottom: "1.3px solid #000" } : {}),
    },
  ]

  return (
    <ReportTable
      columns={columnsData}
      dataSource={data}
      styleRowHeader={{ ...rowHeaderStyle, backgroundColor: "#FFC300" }}
      styleContainer={{ marginTop: "15px" }}
    />
  )
}
