import { Collapse, Statistic, type StatisticProps } from "antd"
import {
  DescriptionsListformItems,
  type DescriptionsListformItemsProps,
} from "components/otros/descriptions-list-form-items"
import { FormPagoContext } from "context/franquiciados/form-pago-context"
import { useContext } from "react"

const statisticStyle: StatisticProps["valueStyle"] = {
  fontSize: "inherit",
  textAlign: "right",
  fontWeight: "bold",
}

const itemsTotales: DescriptionsListformItemsProps["items"] = [
  {
    label: "Total asumido",
    itemName: "total_asumido",
    render: () => <Statistic valueStyle={statisticStyle} precision={2} />,
  },
  {
    label: "Total gastos",
    itemName: "total_gastos_fijos",
    render: () => <Statistic valueStyle={statisticStyle} precision={2} />,
  },
  {
    label: "Total préstamos",
    itemName: "total_deudas",
    render: () => <Statistic valueStyle={statisticStyle} precision={2} />,
  },
  {
    label: "Total",
    itemName: "total",
    render: () => <Statistic valueStyle={statisticStyle} precision={2} />,
  },
]

export function TotalAsumido({ style }: { style: string }) {
  const { loading } = useContext(FormPagoContext)

  return (
    <Collapse defaultActiveKey={["totales"]} className={style}>
      <Collapse.Panel key={"totales"} header="Totales">
        <DescriptionsListformItems
          items={itemsTotales}
          descriptionsProps={{ size: "default", style: { paddingTop: 0 } }}
          loading={loading}
        />
      </Collapse.Panel>
    </Collapse>
  )
}
