import { Form, type FormProps, type FormInstance } from "antd"
import { type DefaultOptionType } from "antd/lib/select"
import { CalculosFinales, VentasFarmacia } from "components/franquiciados/calculos-finales"
import { url } from "config/constants"
import { optionsGet, optionsPost, swalError, swalSuccess } from "config/methods"
import type {
  BodegasDataPrincipal,
  GastosFijos,
  DependientesDataPrincipal,
  valuesForm,
  Deudas,
} from "context/franquiciados/form-pago-context"
import { useLayout } from "hooks/otros/useLayout"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import moment, { type Moment } from "moment"
import { useCallback, useEffect, useState } from "react"

type DataInicial = {
  mes: string
  franquiciados: DefaultOptionType[]
  bodegas: {
    value: number
    label: string
    franquiciado: number
  }[]
  iva: number
}

type DataPrincipal = {
  cod_pago: number
  total: number
  total_menos_gastos: number
  retenciones: number
  iva_12: number
  valor_a_pagar: number
  bodegas: BodegasDataPrincipal[]
  gastos_fijos: GastosFijos[]
  deudas: Deudas[]
  ventas_farmacias: CalculosFinales["ventas_farmacias"]
  bonos: CalculosFinales["bonos"]
}

export const enum TipoSeleccion {
  Bodega,
  Franquiciado,
}

let ivaActual: number

const calcularTotalValoresAsumidos = (form: FormInstance<valuesForm>) => {
  const total = (form.getFieldValue("bodegas") as valuesForm["bodegas"]).reduce((prev, next) => prev + next.valor_asumido, 0)
  form.setFieldValue("total_asumido", total)
}

export const calcularTotalGastosFijos = (form: FormInstance<valuesForm>) => {
  const total = (form.getFieldValue("gastos_fijos") as valuesForm["gastos_fijos"]).reduce(
    (prev, next) => prev + next.total,
    0
  )
  form.setFieldValue("total_gastos_fijos", total)
}

export const calcularTotalFinal = (form: FormInstance<valuesForm>) => {
  const total_gastos = form.getFieldValue("total_gastos_fijos")
  const total_asumido = form.getFieldValue("total_asumido")
  const total_deudas = form.getFieldValue("total_deudas")
  form.setFieldValue("total", total_gastos + total_asumido + total_deudas)
}

const calcularTotalPorBodega = (form: FormInstance<valuesForm>, index?: number) => {
  // const franquiciado = form.getFieldValue("franquiciado")
  if (index) {
    const codBodega: number = form.getFieldValue(["bodegas", index, "cod_bodega"])
    const hastaVentaMaxima: number = form.getFieldValue(["bodegas", index, "hasta_venta_maxima"])
    const ventasFarmacia = (form.getFieldValue("ventas_farmacias") as VentasFarmacia[]).find(
      ({ cod_bodega }) => cod_bodega === codBodega
    )?.ventas
    const dependientes: DependientesDataPrincipal[] = form.getFieldValue(["bodegas", index, "dependientes"])
    let total = dependientes.reduce((prev, next) => prev + next.total, 0)
    form.setFieldValue(["bodegas", index, "total"], total)

    const n_dependientes = form.getFieldValue(["bodegas", index, "multiplica_por"]) ?? dependientes.length
    const cargo_empleados = form.getFieldValue(["bodegas", index, "cargo_empleados"])

    if (hastaVentaMaxima) {
      if ((ventasFarmacia ?? 0) >= hastaVentaMaxima) {
        form.setFieldValue(["bodegas", index, "valor_asumido"], (total / n_dependientes) * cargo_empleados)
        return
      }
    }

    if (n_dependientes === 0) {
      form.setFieldValue(["bodegas", index, "valor_asumido"], 0)
    } else {
      // form.setFieldValue(["bodegas", index, "valor_asumido"], (total / n_dependientes) * (n_dependientes - cargo_empleados))
      if (dependientes.some(({ porcentaje_ingresos }) => porcentaje_ingresos !== 100)) {
        total = dependientes.reduce(
          (prev, next) => prev + (next.porcentaje_ingresos === 100 ? 0 : next.total * (next.porcentaje_ingresos / 100)),
          0
        )
        form.setFieldValue(["bodegas", index, "valor_asumido"], total)
      } else {
        form.setFieldValue(["bodegas", index, "valor_asumido"], (total / n_dependientes) * cargo_empleados)
      }
    }
    return
  }

  ;(form.getFieldValue("bodegas") as valuesForm["bodegas"]).forEach((element, index) => {
    const codBodega = element.cod_bodega
    const hastaVentaMaxima = element.hasta_venta_maxima
    const ventasFarmacia = (form.getFieldValue("ventas_farmacias") as VentasFarmacia[]).find(
      ({ cod_bodega }) => cod_bodega === codBodega
    )?.ventas
    const dependientes = element.dependientes
    let total = dependientes?.reduce((prev: number, next: DependientesDataPrincipal) => prev + next.total, 0)
    form.setFieldValue(["bodegas", index, "total"], total ?? 0)

    const n_dependientes = form.getFieldValue(["bodegas", index, "multiplica_por"]) ?? dependientes?.length ?? 0
    const cargo_empleados = element.cargo_empleados

    if (hastaVentaMaxima) {
      if ((ventasFarmacia ?? 0) >= hastaVentaMaxima) {
        form.setFieldValue(["bodegas", index, "valor_asumido"], (total / n_dependientes) * cargo_empleados)
        return
      }
    }

    if (n_dependientes === 0) {
      form.setFieldValue(["bodegas", index, "valor_asumido"], 0)
    } else {
      // form.setFieldValue(["bodegas", index, "valor_asumido"], (total / n_dependientes) * (n_dependientes - cargo_empleados))
      if (dependientes.some(({ porcentaje_ingresos }) => porcentaje_ingresos !== 100)) {
        total = dependientes.reduce(
          (prev, next) => prev + (next.porcentaje_ingresos === 100 ? 0 : next.total * (next.porcentaje_ingresos / 100)),
          0
        )
        form.setFieldValue(["bodegas", index, "valor_asumido"], total)
      } else {
        form.setFieldValue(["bodegas", index, "valor_asumido"], (total / n_dependientes) * cargo_empleados)
      }
    }
  })
}

export const hacerCalculosFinales = (form: FormInstance<valuesForm>) => {
  const iva =
    moment("01/" + form.getFieldValue("mes").format("MM/YYYY"), "DD/MM/YYYY") < moment("01/03/2024", "DD/MM/YYYY")
      ? 0.12
      : ivaActual

  let suma = (form.getFieldValue("ventas_farmacias") as valuesForm["ventas_farmacias"]).reduce(
    (acum, next) => acum + next.comision,
    0
  )
  form.setFieldValue("total_comision", suma)
  suma = 0
  suma = (form.getFieldValue("bonos") as valuesForm["bonos"]).reduce((acum, next) => acum + next.valor, 0)
  form.setFieldValue("total_bonos", suma)
  suma = 0
  suma = form.getFieldValue("total_comision") + form.getFieldValue("total_bonos") - form.getFieldValue("total")
  form.setFieldValue("total_menos_gastos", suma)
  form.setFieldValue("iva_12", suma > 0 ? suma * iva : 0)
  form.setFieldValue("total_mas_iva", suma + form.getFieldValue("iva_12"))

  form.setFieldValue("retenciones", suma > 0 ? suma * 0.1 : 0)
  form.setFieldValue("total_retenciones_mas_iva", form.getFieldValue("retenciones") + form.getFieldValue("iva_12"))
  form.setFieldValue("valor_a_pagar", form.getFieldValue("total_mas_iva") - form.getFieldValue("total_retenciones_mas_iva"))
}

export const calcularTotalBonos = (form: FormInstance<valuesForm>) => {
  const total = (form.getFieldValue("bonos") as valuesForm["bonos"]).reduce((prev, next) => prev + next.valor, 0)
  form.setFieldValue("total_bonos", total)
  hacerCalculosFinales(form)
}

export const calcularTotalDeudas = (form: FormInstance<valuesForm>) => {
  const total = (form.getFieldValue("deudas") as Deudas[]).reduce((prev, next) => prev + next.valor_a_pagar, 0)
  form.setFieldValue("total_deudas", total)
  hacerCalculosFinales(form)
}

const breadcrumbs = [{ Label: "Franquiciados", Url: "/franquiciados" }, { Label: "Liquidación" }]

const openKeys = [breadcrumbs[0].Label]

export function usePago() {
  const [form] = Form.useForm<valuesForm>()

  const [loading, setLoading] = useState(true)

  const [tipoSeleccion, setTipoSeleccion] = useState<TipoSeleccion>(TipoSeleccion.Bodega)
  const [disabledGrabar, setDisabledGrabar] = useState(true)

  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label)

  const [dataInicial, setDataInicial] = useState<DataInicial>({
    mes: moment().format("MM/YYYY"),
    franquiciados: [],
    bodegas: [],
    iva: 0.12,
  })

  const [dataPrincipal, setDataPrincipal] = useState<DataPrincipal>({
    cod_pago: 0,
    total: 0,
    total_menos_gastos: 0,
    retenciones: 0,
    iva_12: 0,
    valor_a_pagar: 0,
    gastos_fijos: [],
    bodegas: [],
    ventas_farmacias: [],
    bonos: [],
    deudas: [],
  })

  // const { optionsGet, optionsPost } = useLocalStorage()

  // const { swalError, swalSuccess } = useSwal()

  const { isError } = useVerifyResponse()

  useEffect(() => {
    ;(async () => {
      try {
        const result = await fetch(`${url}fapifsg-pr/franquiciados/datos-iniciales-pago-franquiciados`, optionsGet())
        if (isError(result)) throw new Error()
        const res = await result.json()
        setDataInicial(res)
        ivaActual = res.iva
      } catch (e) {
        swalError({ text: "No se pudo obtener los datos" })
      }
    })()
  }, [isError])

  const fillDataPrincipal = useCallback(
    async (tipo: TipoSeleccion) => {
      if (
        !Boolean(form.getFieldValue("mes")) ||
        !Boolean(form.getFieldValue(tipo === TipoSeleccion.Bodega ? "bodega" : "franquiciado"))
      )
        return
      setLoading(true)
      const body = {
        mes: (form.getFieldValue("mes") as Moment).format("YYYYMM"),
        franquiciado:
          form.getFieldValue("franquiciado") ??
          dataInicial.bodegas.find(({ value }) => value === form.getFieldValue("bodega"))?.franquiciado,
        // bodega: form.getFieldValue("bodega"),
      }
      try {
        const result = await fetch(`${url}fapifsg-pr/franquiciados/datos-principales-pago-franquiciados`, optionsPost(body))
        if (isError(result)) throw new Error()
        const res = await result.json()
        // console.log(res)
        setDataPrincipal(res)
        setDisabledGrabar(false)
      } catch (e) {
        swalError({ text: "No se pudo obtener los datos" })
      }
      setLoading(false)
    },
    [form, isError, dataInicial]
  )

  // const getFranquiciadoSeleccionado = useCallback(async () => {
  //   try {
  //     const result = await fetch(
  //       `${url}fapifsg-pr/franquiciados/obtener-franquiciado-por-bodega`,
  //       optionsPost({ bodega: form.getFieldValue("bodega") })
  //     )
  //     if (isError(result)) throw new Error()
  //     const res = await result.json()
  //     franquiciadoSeleccionado.current = res
  //   } catch (e) {
  //     swalError({ text: "Error al obtener el franquiciado escogido" })
  //   }
  // }, [])

  useEffect(() => {
    form.setFieldValue("mes", moment(`01/${dataInicial.mes}`, "DD/MM/YYYY").subtract(1, "days"))
    // dataInicial.bodegas.length > 0 && form.setFieldValue("bodega", dataInicial.bodegas[0].value)
    // fillDataPrincipal(TipoSeleccion.Bodega)
    // form.getFieldValue("bodega") && getFranquiciadoSeleccionado()
  }, [dataInicial, form, fillDataPrincipal])

  useEffect(() => {
    if (tipoSeleccion === TipoSeleccion.Bodega) {
      form.setFieldValue("franquiciado", undefined)
      // dataInicial.bodegas.length > 0 && form.setFieldValue("bodega", dataInicial.bodegas[0].value)
      // form.getFieldValue("bodega") && getFranquiciadoSeleccionado()
    } else {
      form.setFieldValue("bodega", undefined)
      form.setFieldValue("franquiciado", undefined)
      // dataInicial.franquiciados.length > 0 && form.setFieldValue("franquiciado", dataInicial.franquiciados[0].value)
    }
    // fillDataPrincipal(tipoSeleccion)
    setDataPrincipal({
      cod_pago: 0,
      total: 0,
      total_menos_gastos: 0,
      retenciones: 0,
      iva_12: 0,
      valor_a_pagar: 0,
      gastos_fijos: [],
      bodegas: [],
      ventas_farmacias: [],
      bonos: [],
      deudas: [],
    })
    setDisabledGrabar(true)
  }, [tipoSeleccion, form, fillDataPrincipal, dataInicial])

  useEffect(() => {
    // console.log(dataPrincipal)
    form.setFieldValue("bodegas", dataPrincipal.bodegas)
    form.setFieldValue(
      "gastos_fijos",
      dataPrincipal.gastos_fijos.map(gasto => ({
        ...gasto,
        gasto: gasto.gasto.includes("{MES}")
          ? gasto.gasto.replace("{MES}", (form.getFieldValue("mes") as Moment).format("MMMM [del] YYYY").toUpperCase())
          : gasto.gasto,
      }))
    )
    form.setFieldValue("ventas_farmacias", dataPrincipal.ventas_farmacias)
    form.setFieldValue("bonos", dataPrincipal.bonos)
    form.setFieldValue("deudas", dataPrincipal.deudas)
    calcularTotalPorBodega(form)
    calcularTotalValoresAsumidos(form)
    calcularTotalGastosFijos(form)

    form.setFieldValue(
      "total_deudas",
      dataPrincipal.deudas.reduce((sum, deuda) => sum + deuda.valor_a_pagar, 0)
    )

    if (dataPrincipal.cod_pago > 0) {
      form.setFieldValue("total", dataPrincipal.total)
      let suma = dataPrincipal.ventas_farmacias.reduce((acum, next) => acum + next.comision, 0)
      form.setFieldValue("total_comision", suma)
      suma = 0
      suma = dataPrincipal.bonos.reduce((acum, next) => acum + next.valor, 0)
      form.setFieldValue("total_bonos", suma)
      form.setFieldValue("total_menos_gastos", dataPrincipal.total_menos_gastos)
      form.setFieldValue("iva_12", dataPrincipal.iva_12)

      form.setFieldValue("total_mas_iva", dataPrincipal.total_menos_gastos + dataPrincipal.iva_12)

      form.setFieldValue("retenciones", dataPrincipal.retenciones)
      form.setFieldValue("total_retenciones_mas_iva", dataPrincipal.retenciones + dataPrincipal.iva_12)
      form.setFieldValue("valor_a_pagar", dataPrincipal.valor_a_pagar)
      // return
    }

    calcularTotalFinal(form)
    hacerCalculosFinales(form)

    // console.log(form.getFieldsValue());
    // console.log(dataPrincipal);
  }, [dataPrincipal, form])

  const handleChangeOtros = (newValue: number, index1: number, index2: number) => {
    let total = 0
    total += form.getFieldValue(["bodegas", index1, "dependientes", index2, "sueldo"])
    total += form.getFieldValue(["bodegas", index1, "dependientes", index2, "fondos_reserva"])
    total += form.getFieldValue(["bodegas", index1, "dependientes", index2, "iess_12"])
    form.setFieldValue(["bodegas", index1, "dependientes", index2, "total"], total + newValue)
    calcularTotalPorBodega(form, index1)
    calcularTotalValoresAsumidos(form)
    calcularTotalFinal(form)
    hacerCalculosFinales(form)
  }

  const handleChangeSueldo = (newValue: number, index1: number, index2: number) => {
    let total = 0
    if ((form.getFieldValue(["bodegas", index1, "dependientes", index2, "fondos_reserva"]) ?? 0) !== 0) {
      form.setFieldValue(["bodegas", index1, "dependientes", index2, "fondos_reserva"], (newValue * 8.33) / 100)
    }
    form.setFieldValue(["bodegas", index1, "dependientes", index2, "iess_12"], newValue * 0.1215)

    total += form.getFieldValue(["bodegas", index1, "dependientes", index2, "otros"])
    total += form.getFieldValue(["bodegas", index1, "dependientes", index2, "fondos_reserva"])
    total += form.getFieldValue(["bodegas", index1, "dependientes", index2, "iess_12"])
    form.setFieldValue(["bodegas", index1, "dependientes", index2, "total"], total + newValue)
    calcularTotalPorBodega(form, index1)
    calcularTotalValoresAsumidos(form)
    calcularTotalFinal(form)
    hacerCalculosFinales(form)
  }

  const handleRemoveBono = () => {
    calcularTotalFinal(form)
    hacerCalculosFinales(form)
  }

  const handleRemoveDeuda = () => {
    calcularTotalFinal(form)
    calcularTotalDeudas(form)
  }

  const handleFinishForm: FormProps<valuesForm>["onFinish"] = async values => {
    const data =
      dataPrincipal.cod_pago > 0
        ? {
            cod_pago: dataPrincipal.cod_pago,
            ...values,
            mes: values.mes.format("YYYYMM"),
            franquiciado:
              form.getFieldValue("franquiciado") ??
              dataInicial.bodegas.find(({ value }) => value === form.getFieldValue("bodega"))?.franquiciado,
          }
        : {
            ...values,
            mes: values.mes.format("YYYYMM"),
            franquiciado:
              form.getFieldValue("franquiciado") ??
              dataInicial.bodegas.find(({ value }) => value === form.getFieldValue("bodega"))?.franquiciado,
          }
    try {
      const result = await fetch(`${url}fapifsg-pr/franquiciados/registrar-pago`, optionsPost(data))
      if (isError(result)) throw new Error()
      const text = await result.json()
      swalSuccess({ text })
      result && fillDataPrincipal(tipoSeleccion)
    } catch (e) {
      swalError({ text: `No se pudo ${dataPrincipal.cod_pago > 0 ? "modificar" : "registrar"} el pago` })
    } finally {
      setDisabledGrabar(false)
    }
  }

  const handleOkButtonGrabar = async () => {
    try {
      setDisabledGrabar(true)
      await form.validateFields()
      form.submit()
    } catch (e) {
      console.error(e)
      await swalError({ text: "Faltan datos" })
      setDisabledGrabar(false)
    }
  }

  const handleReloadDependientes = async (bodegaIndex: number) => {
    try {
      const result = await fetch(
        `${url}fapifsg-pr/franquiciados/recargar-dependientes`,
        optionsPost({
          bodega: form.getFieldValue(["bodegas", bodegaIndex, "cod_bodega"]),
          mes: (form.getFieldValue("mes") as Moment).format("YYYYMM"),
        })
      )
      if (isError(result)) throw new Error()
      const dependientes: DependientesDataPrincipal[] = await result.json()

      const newDependientes = dependientes.map(dependiente => {
        let nextDependiente: DependientesDataPrincipal
        const prevDependiente = (
          form.getFieldValue(["bodegas", bodegaIndex, "dependientes"]) as DependientesDataPrincipal[]
        ).find(dependiente2 => dependiente.cod_usuario === dependiente2.cod_usuario)
        if (prevDependiente) {
          nextDependiente = {
            ...dependiente,
            otros: prevDependiente.otros,
            total: (prevDependiente.otros ?? 0) + dependiente.total,
          }
        } else {
          nextDependiente = { ...dependiente, otros: 0 }
        }
        return nextDependiente
      })
      form.setFieldValue(["bodegas", bodegaIndex, "dependientes"], newDependientes)
      calcularTotalPorBodega(form, bodegaIndex)
      calcularTotalValoresAsumidos(form)
      calcularTotalFinal(form)
      hacerCalculosFinales(form)
    } catch (e) {
      swalError({ text: `No se pudo actualizar los dependientes` })
    }
  }

  const handleReloadCargoEmpleados = async (bodegaIndex: number) => {
    try {
      const data = {
        bodega: form.getFieldValue(["bodegas", bodegaIndex, "cod_bodega"]),
        franquiciado:
          form.getFieldValue("franquiciado") ??
          dataInicial.bodegas.find(({ value }) => value === form.getFieldValue("bodega"))?.franquiciado,
      }
      const result = await fetch(`${url}fapifsg-pr/franquiciados/recargar-cargo-empleados`, optionsPost(data))
      if (isError(result)) throw new Error()
      const cargo_empleados = await result.json()
      form.setFieldValue(["bodegas", bodegaIndex, "cargo_empleados"], cargo_empleados)
      calcularTotalPorBodega(form, bodegaIndex)
      calcularTotalValoresAsumidos(form)
      calcularTotalFinal(form)
      hacerCalculosFinales(form)
    } catch (e) {
      swalError({ text: `No se pudo actualizar el numero de empleados a su cargo` })
    }
  }

  // antes llamados deudas
  const handleReloadPrestamos = async () => {
    try {
      const result = await fetch(
        `${url}fapifsg-pr/franquiciados/recargar-prestamos`,
        optionsPost({
          franquiciado:
            form.getFieldValue("franquiciado") ??
            dataInicial.bodegas.find(({ value }) => value === form.getFieldValue("bodega"))?.franquiciado,
          prestamos: JSON.stringify(dataPrincipal.deudas.map(deuda => deuda.cod_deuda) ?? []),
        })
      )
      if (isError(result)) throw new Error()
      const prestamos: Deudas[] = await result.json()

      if (prestamos.length <= 0) return
      setDataPrincipal(state => ({
        ...state,
        bodegas: form.getFieldValue("bodegas"),
        gastos_fijos: form.getFieldValue("gastos_fijos"),
        deudas: [...state.deudas, ...prestamos],
        ventas_farmacias: form.getFieldValue("ventas_farmacias"),
        bonos: form.getFieldValue("bonos"),
      }))
      // form.setFieldValue("deudas", prestamos)
      // form.setFieldValue(
      //   "total_deudas",
      //   prestamos.reduce((sum, deuda) => sum + deuda.valor_a_pagar, 0)
      // )
      // calcularTotalFinal(form)
      // hacerCalculosFinales(form)
    } catch (e) {
      swalError({ text: "No se pudo recargar los préstamos" })
    }
  }

  const handleReloadBodegas = async () => {
    try {
      const result = await fetch(
        `${url}fapifsg-pr/franquiciados/recargar-bodegas`,
        optionsPost({
          franquiciado:
            form.getFieldValue("franquiciado") ??
            dataInicial.bodegas.find(({ value }) => value === form.getFieldValue("bodega"))?.franquiciado,
          mes: (form.getFieldValue("mes") as Moment).format("YYYYMM"),
        })
      )
      if (isError(result)) throw new Error()
      const resultBodegas: BodegasDataPrincipal[] = await result.json()

      if (resultBodegas.length <= 0) return

      const newBodegas = resultBodegas.map(bodega => {
        const bodegaEncontrada = (form.getFieldValue("bodegas") as BodegasDataPrincipal[]).find(
          ({ cod_bodega }) => bodega.cod_bodega === cod_bodega
        )
        if (!bodegaEncontrada) return bodega
        return {
          ...bodega,
          dependientes: bodega.dependientes.map(dependiente => {
            const dependienteEncontrado = bodegaEncontrada.dependientes.find(
              ({ cod_usuario }) => cod_usuario === dependiente.cod_usuario
            )
            if (!dependienteEncontrado) return dependiente
            return {
              ...dependiente,
              otros: dependienteEncontrado?.otros ?? 0,
              total: dependiente.total + (dependienteEncontrado?.otros ?? 0),
            }
          }),
        }
      })

      form.setFieldValue("bodegas", newBodegas)
      calcularTotalPorBodega(form)
      calcularTotalValoresAsumidos(form)
      calcularTotalFinal(form)
      hacerCalculosFinales(form)
    } catch (e) {
      swalError({ text: "No se pudo recargar los préstamos" })
    }
  }

  const handleDeleteDependiente = () => {
    calcularTotalPorBodega(form)
    calcularTotalValoresAsumidos(form)
    calcularTotalFinal(form)
    hacerCalculosFinales(form)
  }

  return {
    loading,
    form,
    dataInicial,
    dataPrincipal,
    handleChangeOtros,
    handleRemoveBono,
    handleRemoveDeuda,
    handleFinishForm,
    fillDataPrincipal,
    handleOkButtonGrabar,
    tipoSeleccion,
    setTipoSeleccion,
    handleReloadDependientes,
    handleReloadCargoEmpleados,
    disabledGrabar,
    handleReloadPrestamos,
    handleChangeSueldo,
    setDisabledGrabar,
    handleReloadBodegas,
    handleDeleteDependiente,
  }
}
