import { Col, Form, Input, Modal, Row, Switch, Table } from "antd"
import { ButtonAdd } from "components/otros/buttons"

import { useEffect } from "react"

import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSwal } from "hooks/otros/useSwal"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { Bonos, useBonosFranquiciados } from "hooks/Franquiciados/useBonosFranquiciados"

import { url } from "config/constants"
import { SubTableBonos } from "components/franquiciados/sub-table-bonos"
import { ModalAsignarBonoFranquiciado } from "components/franquiciados/modal-asignar-bono-franquiciado"

export function BonosFranquiciados() {
  const {
    setSelectedData,
    selectedData,
    setOpenModal,
    openModal,
    data,
    columns,
    reFetcher,
    selectedFranquiciado,
    setSelectedFranquiciado,
    openModalAsignarFranquiciado,
    setOpenModalAsignarFranquiciado,
  } = useBonosFranquiciados()

  return (
    <>
      <Row
        gutter={[0, 16]}
        style={{ padding: "16px", marginLeft: 0, marginRight: 0, backgroundColor: "#fff" }}
        justify="end"
      >
        <Col xs={24} sm={24} md={7} lg={7} xl={7}>
          <ButtonAdd
            buttonProps={{
              onClick() {
                setSelectedData(undefined)
                setOpenModal(true)
              },
            }}
          >
            Registrar valor adicional
          </ButtonAdd>
        </Col>
      </Row>
      <Row wrap style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            size="small"
            dataSource={data}
            columns={columns}
            pagination={{
              pageSize: 5,
              size: "default",
            }}
            expandable={{
              rowExpandable: ({ franquiciados_asignados }) => franquiciados_asignados?.length > 0,
              expandedRowRender: ({ franquiciados_asignados }) => (
                <SubTableBonos
                  dataSource={franquiciados_asignados}
                  onClickButtonEdit={({ cod_franquiciado, cod_bono }) => {
                    setSelectedData(data?.findIndex(d => d.key === cod_bono))
                    setSelectedFranquiciado(cod_franquiciado)
                    setOpenModalAsignarFranquiciado(true)
                  }}
                />
              ),
            }}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
      <ModalBonos
        open={openModal}
        onClose={() => setOpenModal(false)}
        data={selectedData || selectedData === 0 ? data[selectedData] : undefined}
        refetcher={reFetcher}
      />
      <ModalAsignarBonoFranquiciado
        open={openModalAsignarFranquiciado}
        bono={selectedData || selectedData === 0 ? data[selectedData].key : 0}
        handleClose={() => {
          setOpenModalAsignarFranquiciado(false)
          setSelectedFranquiciado(undefined)
        }}
        reFetcher={reFetcher}
        data={
          selectedData || selectedData === 0
            ? data[selectedData]?.franquiciados_asignados?.find(
                ({ cod_franquiciado }) => cod_franquiciado === selectedFranquiciado
              )
            : undefined
        }
      />
    </>
  )
}

type FormBono = {
  bono: string
  estado: boolean
}

type ModalGastosProps = {
  open: boolean
  onClose(): void
  data?: Bonos
  refetcher(): void
}

function ModalBonos({ open, onClose, data, refetcher }: ModalGastosProps) {
  const [form] = Form.useForm<Bonos>()

  const { optionsPost } = useLocalStorage()

  const { swalError, swalSuccess } = useSwal()

  const { isError } = useVerifyResponse()

  useEffect(() => {
    const datos = data
      ? {
          bono: data.bono,
          estado: data.estado,
        }
      : {
          bono: undefined,
          estado: true,
        }
    form.setFieldsValue(datos)
  }, [data, form])

  const handleGuardarBono = async () => {
    onClose()
    const values = await form.validateFields()
    let tipoRuta: "modificar" | "registrar", newValues: (FormBono & { cod_gasto: number }) | FormBono
    if (data) {
      newValues = { ...values, cod_gasto: data.key }
      tipoRuta = "modificar"
    } else {
      newValues = values
      tipoRuta = "registrar"
    }
    try {
      const res = await fetch(`${url}fapifsg-pr/franquiciados/${tipoRuta}-bono`, optionsPost(newValues))
      if (isError(res)) throw new Error()
      const text = await res.json()
      swalSuccess({ text })
      form.resetFields()
      refetcher()
    } catch (e) {
      swalError({ text: `No se pudo ${tipoRuta} el bono` })
    }
  }

  return (
    <Modal
      open={open}
      title="Valores adicionales de los franquiciados"
      okText={data ? "Modificar" : "Registrar"}
      cancelText="Cancelar"
      onCancel={onClose}
      transitionName=""
      // style={{ top: '50px' }}
      onOk={handleGuardarBono}
    >
      <Form
        form={form}
        wrapperCol={{ xs: 21, sm: 21, md: 21, lg: 21, xl: 21 }}
        labelCol={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }}
        requiredMark="optional"
      >
        <Form.Item name="bono" label="Bono" rules={[{ required: true, message: "Ingrese el nombre del valor adicional" }]}>
          <Input maxLength={150} />
        </Form.Item>
        <Form.Item name="estado" label="Estado" initialValue={true} valuePropName="checked" required>
          <Switch disabled={!Boolean(data)} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default BonosFranquiciados
