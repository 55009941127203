import { Descriptions, Form, type DescriptionsProps, type FormItemProps, Statistic } from "antd";
import { type DescriptionsItemProps } from "antd/lib/descriptions/Item";

export type DescriptionsListformItemsProps = {
  descriptionsProps?: DescriptionsProps;
  loading?: boolean;
  items: {
    label: DescriptionsItemProps["label"];
    itemName: FormItemProps["name"];
    render?: () => JSX.Element | JSX.Element[];
  }[];
};

export function DescriptionsListformItems({ descriptionsProps, items, loading }: DescriptionsListformItemsProps) {
  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            margin: "auto",
          }}
        >
          <div className="ant-spin ant-spin-spinning" aria-live="polite" aria-busy="true">
            <span className="ant-spin-dot ant-spin-dot-spin">
              <i className="ant-spin-dot-item"></i>
              <i className="ant-spin-dot-item"></i>
              <i className="ant-spin-dot-item"></i>
              <i className="ant-spin-dot-item"></i>
            </span>
          </div>
        </div>
      )}
      <Descriptions
        bordered
        column={1}
        style={{ marginTop: "16px" }}
        size="small"
        {...descriptionsProps}
        className={`${loading && "ant-spin-blur"}`}
      >
        {items.map(({ label, itemName, render }, index) => (
          <Descriptions.Item label={label} key={index}>
            <Form.Item name={itemName} noStyle>
              {render ? render() : <Statistic valueStyle={{ fontSize: "inherit", textAlign: "right" }} precision={2} />}
            </Form.Item>
          </Descriptions.Item>
        ))}
      </Descriptions>
    </div>
  );
}
