import { NavLink } from "react-router-dom";

// import { Reembolsos, Facturas, Recibos } from 'components/svg/Reembolso'
import { Pendiente, Aprobado, Rechazado } from "components/svg/Estados";

import styles from "components/contentbottomnav/bottomnavbars.module.css";
import React from "react";

import { Estados } from "types.d";

const styleIcon = {
  width: "2rem",
  fill: "#fff",
};

interface Props {
  options: Array<Estados>;
}

type Icons = {
  [index in Estados]: React.ReactNode;
};

const icons: Icons = {
  pendientes: <Pendiente {...styleIcon} />,
  aprobados: <Aprobado {...styleIcon} />,
  rechazados: <Rechazado {...styleIcon} />,
};

export function BottomNavBar({ options }: Props) {
  return (
    <ul className={styles.ulBottom}>
      {options.map((option, index) => (
        <li key={index} className={styles.liBottom}>
          <NavLink
            to={`/reembolsos/${option}`}
            className={({ isActive }) =>
              isActive ? styles.linkBottomActive + " " + styles.linkBottom : styles.linkBottom
            }
          >
            {icons[option]}
            <small>{option[0].toUpperCase() + option.substring(1)}</small>
          </NavLink>
        </li>
      ))}
    </ul>
  );
}
