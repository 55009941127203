import { View, Text } from "@react-pdf/renderer"
import type { Style } from "@react-pdf/types"
import { type DataVerticalTable, VerticalTable } from "components/reactreport/vertical-table"
import type { BodegasDataPrincipal } from "context/franquiciados/form-pago-context"
import { DependientesTable } from "components/franquiciados/reporte/dependientes-table"
import { VentasFarmacia } from "../calculos-finales"

type Props = {
  bodegas: BodegasDataPrincipal[]
  commonColumnStyle: Style
  rowHeaderStyle: Style
  // franquiciado: number
  ventasFarmacias: VentasFarmacia[]
}

const tituloBodegaStyle: Style = {
  // textAlign: "center",
  // backgroundColor: "#dadada",
  marginBottom: "5px",
  padding: 3,
  borderRadius: "5px",
  flexDirection: "row",
  justifyContent: "space-between",
}

const textHeaderStyle: Style = {
  fontFamily: "Helvetica-Bold",
  fontSize: 10,
}

export function BodegasTable({ bodegas, commonColumnStyle, rowHeaderStyle, ventasFarmacias }: Props) {
  const dataTotales: (bodega: BodegasDataPrincipal) => DataVerticalTable[] = bodega => {
    const codBodega = bodega.cod_bodega
    const hastaVentaMaxima = bodega.hasta_venta_maxima
    const ventasFarmacia = ventasFarmacias.find(({ cod_bodega }) => cod_bodega === codBodega)?.ventas
    const n_dependientes = bodega.multiplica_por ?? bodega.dependientes?.length ?? 0
    return [
      {
        title: "Total",
        text: (bodega.dependientes?.reduce((acum, next) => acum + next.total, 0) ?? 0)
          .toFixed(2)
          .replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,"),
        key: "total",
        styleCell: { padding: 3 },
        styleCellHeader: { padding: 3 },
      },
      // {
      //   title: "Valor por empleado",
      //   text: (bodega.dependientes.reduce((acum, next) => acum + next.total, 0) / bodega.dependientes.length).toFixed(2),
      //   key: "valor empleado",
      //   styleCell: { padding: 5 },
      //   styleCellHeader: { padding: 5 },
      // },
      // {
      //   title: "Empleados asumidos por la empresa",
      //   text: bodega.dependientes.length - bodega.cargo_empleados,
      //   key: "empleados asumidos",
      //   styleCell: { padding: 5 },
      //   styleCellHeader: { padding: 5 },
      // },
      {
        title: "Valor asumido por el franquiciado",
        text: (bodega.dependientes.length === 0
          ? 0
          : hastaVentaMaxima && (ventasFarmacia ?? 0) >= hastaVentaMaxima
          ? ((bodega.dependientes?.reduce((acum, next) => acum + next.total, 0) ?? 0) / n_dependientes ?? 0) *
            bodega.cargo_empleados
          : bodega.dependientes.some(({ porcentaje_ingresos }) => porcentaje_ingresos !== 100)
          ? bodega.dependientes.reduce(
              (prev, next) => prev + (next.porcentaje_ingresos === 100 ? 0 : next.total * (next.porcentaje_ingresos / 100)),
              0
            )
          : ((bodega.dependientes?.reduce((acum, next) => acum + next.total, 0) ?? 0) / n_dependientes ?? 0) *
            bodega.cargo_empleados
        )
          .toFixed(2)
          .replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,"),
        key: "valor asumido",
        styleCell: { padding: 3 },
        styleCellHeader: { padding: 3 },
      },
    ]
  }

  return (
    <>
      {bodegas?.map(bodega => (
        <View key={bodega.cod_bodega} style={{ marginBottom: "5px" }}>
          <View style={{ ...tituloBodegaStyle, ...rowHeaderStyle, backgroundColor: "#FFC300" }}>
            <Text style={textHeaderStyle}>{bodega.bodega}</Text>
            <Text style={textHeaderStyle}>Empleados asumidos por el franquiciado: {bodega.cargo_empleados}</Text>
          </View>
          <DependientesTable
            dependientes={bodega.dependientes ?? []}
            commonColumnStyle={commonColumnStyle}
            rowHeaderStyle={rowHeaderStyle}
          />
          <VerticalTable
            containerStyle={{ width: "50%", alignSelf: "flex-end" }}
            dataSource={dataTotales(bodega)}
            styleColumn={{ flexBasis: "30%" }}
            styleColumnHeader={{ flexBasis: "70%", ...rowHeaderStyle }}
            roundBottom={true}
          />
        </View>
      ))}
    </>
  )
}
