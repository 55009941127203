import { Card, Form, InputNumber, Spin, Table } from "antd";
import { useNovedadesUltimaVenta } from "hooks/productos/useNovedadesUltimaVenta";

const NovedadesUltimaVenta = () => {
  const {
    form,
    columnsBodega,
    dataTableBodega,
    dataTableBodegaDetails,
    handleExpandChange,
    expandedRowKeys,
    columnsBodegaId,
    handleChangeDias,
    loaderTbl1,
    loaderTbl2,
    formateaCantidad,
  } = useNovedadesUltimaVenta();
  const totalCosto = dataTableBodega.reduce((sum, row) => sum + row.totalCosto, 0);
  return (
    <div>
      <Card title='Farmacias con productos fraccionados sin movimiento que se venden en un 75% de bodegas'>
        <Form initialValues={{ dias: 150 }} form={form}>
          <Form.Item label='Ultima venta mayores a' name='dias'>
            <InputNumber onChange={handleChangeDias} addonAfter='días' min={30}></InputNumber>
          </Form.Item>
        </Form>
        <Table
          loading={loaderTbl1}
          columns={columnsBodega}
          dataSource={dataTableBodega}
          bordered
          size='small'
          pagination={false}
          scroll={{ y: "53vh", x: 400 }}
          expandable={{
            expandedRowRender: () => {
              return dataTableBodegaDetails ? (
                <Table
                  loading={loaderTbl2}
                  columns={columnsBodegaId}
                  dataSource={dataTableBodegaDetails}
                  bordered
                  size='small'
                  pagination={false}
                  scroll={{ y: "300px", x: 400 }}
                  className='tabla-interna'
                />
              ) : (
                <Spin />
              );
            },
            onExpand: handleExpandChange,
            expandedRowKeys: expandedRowKeys,
          }}
          footer={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                padding: "2px 2px",
                fontSize: "12px",
              }}
            >
              <div>Total costo: {formateaCantidad(totalCosto)}</div>
            </div>
          )}
        />
      </Card>
    </div>
  );
};

export default NovedadesUltimaVenta;
