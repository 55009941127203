import { Principal } from "pages/contentprincipal/Principal"
import { SolicitarUniformes } from "pages/contentrecursoshumanos/contentrenovacionuniformes/SolicitarUniformes"
import { CrearKit } from "pages/contentrecursoshumanos/contentrenovacionuniformes/CrearKit"
import { type RouteObject } from "react-router-dom"
//import {SolicitudReposicion} from "pages/contentrecursoshumanos/contentrenovacionuniformes/SolicitudReposicion"
import { AprobacionUniformesRRHH } from "pages/contentrecursoshumanos/contentrenovacionuniformes/AprocbacionUniformesRRHH"
import { AprobacionUniformes} from "pages/contentrecursoshumanos/contentrenovacionuniformes/AprocbacionUniformes"
import ReporteUniformes from "pages/contentrecursoshumanos/contentrenovacionuniformes/ReporteUniformes"
import { SolicitudReposicion } from "pages/contentrecursoshumanos/contentrenovacionuniformes/SolicitudReposicion"

export const rutasUniformes: RouteObject[] = [
  {
    path: "/rrhh/renovacion-uniformes",
    element: <Principal />,
  },
  {
    path: "/rrhh/renovacion-uniformes/crear-kit",
    element: <CrearKit/>,
  },
  {
    path: "/rrhh/renovacion-uniformes/solicitar-uniformes",
    element: <SolicitarUniformes/>,
  },
  {
    path: "/rrhh/renovacion-uniformes/solicitud-reposicion-uniformes",
    element: <SolicitudReposicion/>,
  },
  {
    path: "/rrhh/renovacion-uniformes/validacion-uniformes-rrhh",
    element: <AprobacionUniformesRRHH/>,
  },
  {
    path: "/rrhh/renovacion-uniformes/aprobacion-uniformes",
    element: <AprobacionUniformes/>,
  },
  {
    path: "/rrhh/renovacion-uniformes/reporte-uniformes",
    element: <ReporteUniformes/>,
  }
]