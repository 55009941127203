import { Col, Modal, Row, Form } from "antd";

import { Select } from "antd";

const { Option } = Select;

const ModalOptions = ({ showModal, setShowModal, dataChart, handleChangeX1, variable }: any) => {
  // const { showModal, setShowModal, dataChart, handleChangeX1 } =
  //   useContext(RotacionEmpleadoContext);

  return (
    <Modal
      title='Opciones'
      open={showModal}
      onOk={() => setShowModal(false)}
      onCancel={() => setShowModal(false)}
      footer={null}
    >
      <Row>
        <Col xs={24}>
          <Form.Item label='Graficar eje x1:' name={variable}>
            <Select
              placeholder='Selecciona lo que desea graficar'
              mode='multiple'
              onChange={handleChangeX1}
            >
              {dataChart.map((el: string) => (
                <Option value={el} key={el}>
                  {el}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalOptions;
