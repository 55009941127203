import { Button, Col, Form, FormProps, Input, InputNumber, Modal, Row, Select, Switch } from "antd"
import { url } from "config/constants"
import { divideFullName } from "config/methods"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSwal } from "hooks/otros/useSwal"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { useCallback, useEffect, useState } from "react"

import { PlusOutlined } from "@ant-design/icons"

import { type DefaultOptionType } from "antd/lib/select"

export type Franquiciados = {
  value: React.Key
  cedula: string
  label: string
  correo: string
  estado: boolean
  bodegas: {
    cod_bodega: number
    bodega: string
    cargo_empleados: number
  }[]
}

type ValuesForm = {
  cedula: string
  apellidos: string
  nombres: string
  correo: string
  bodegas?: {
    cod_bodega: number
    cargo_empleados: number
  }[]
  cargo_empleados?: number
  estado: boolean
}

type ModalFranquiciadoProps = {
  open: boolean
  onClose(): void
  data?: Franquiciados
  afterFinish?: () => void
}

export function ModalFranquiciado({ open, onClose, data, afterFinish }: ModalFranquiciadoProps) {
  const [bodegas, setBodegas] = useState<DefaultOptionType[]>()
  const [confirmLoading, setConfirmLoading] = useState(false)

  const [form] = Form.useForm<ValuesForm>()

  const { optionsPost } = useLocalStorage()

  const { swalError, swalSuccess } = useSwal()

  const { isError } = useVerifyResponse()

  const listarBodegas = useCallback(async () => {
    try {
      const result = await fetch(
        `${url}fapifsg-pr/franquiciados/listar-bodegas-no-franquiciadas`,
        optionsPost({ bodegas: data?.bodegas.map(({ cod_bodega }) => cod_bodega) })
      )
      if (isError(result)) throw new Error()
      const response = await result.json()
      setBodegas(response)
    } catch (e) {
      swalError({ text: "No se pudo obtener los datos de las bodegas" })
    }
  }, [optionsPost, isError, swalError, data])

  useEffect(() => {
    ;(async () => {
      form.resetFields()
      await listarBodegas()

      if (data) {
        const { cedula, label, correo, estado, bodegas } = data
        const { nombres, apellidos } = divideFullName(label, "upperCase")
        form.setFieldsValue({
          cedula,
          apellidos,
          nombres,
          correo,
          estado,
          bodegas: bodegas.map(({ cod_bodega, cargo_empleados }) => ({ cod_bodega, cargo_empleados })),
        })
      }
    })()
  }, [data, form, listarBodegas])

  const handleRegistrarFranquiciado: FormProps<ValuesForm>["onFinish"] = async values => {
    try {
      const datos = Boolean(data)
        ? {
            ...values,
            cod_franquiciado: data?.value,
          }
        : values
      const ruta = Boolean(data) ? "modificar" : "registrar"
      const result = await fetch(`${url}fapifsg-pr/franquiciados/${ruta}-franquiciado`, optionsPost(datos))
      if (isError(result)) throw new Error()
      const text = await result.json()
      swalSuccess({ text })
      // !Boolean(data) && reFetcher(data)
      afterFinish && afterFinish()
      form.resetFields()
      listarBodegas()
    } catch (e) {
      swalError({ text: "No se pudo registrar el franquiciado" })
    } finally {
      setConfirmLoading(false)
      onClose()
    }
  }

  const handleOkModal = async () => {
    try {
      setConfirmLoading(true)
      await form.validateFields()
      form.submit()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal
      open={open}
      title={data ? "Modificar franquiciado" : "Registro de franquiciados"}
      okText={data ? "Modificar" : "Registrar"}
      cancelText="Cancelar"
      onCancel={() => {
        onClose()
        form.resetFields()
      }}
      confirmLoading={confirmLoading}
      transitionName=""
      style={{ top: "50px" }}
      onOk={handleOkModal}
      width={750}
    >
      <Form
        form={form}
        // layout="vertical"
        requiredMark="optional"
        className="scrollableDiv"
        onFinish={handleRegistrarFranquiciado}
        style={{ height: "55vh" }}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
      >
        <Form.Item
          name="cedula"
          label="Cédula"
          rules={[
            { required: true, message: "Ingrese la cédula" },
            { len: 10, message: "La cédula debe tener 10 caracteres" },
          ]}
        >
          <Input maxLength={10} readOnly={Boolean(data)} />
        </Form.Item>
        <Form.Item name="apellidos" label="Apellidos" rules={[{ required: true, message: "Ingrese los apellidos" }]}>
          <Input maxLength={50} readOnly={Boolean(data)} />
        </Form.Item>
        <Form.Item name="nombres" label="Nombres" rules={[{ required: true, message: "Ingrese los nombres" }]}>
          <Input maxLength={50} readOnly={Boolean(data)} />
        </Form.Item>
        <Form.Item name="correo" label="Correo" rules={[{ required: true, message: "Ingrese el correo" }]}>
          <Input maxLength={150} />
        </Form.Item>
        <Form.List name="bodegas">
          {(fields, { add, remove }) => (
            <>
              {fields.map(field => (
                <Row key={field.key} gutter={[16, 16]} style={{ paddingRight: 10, marginRight: 0 }}>
                  <Col xxl={15} xl={15} lg={15} md={15} xs={15}>
                    <Form.Item
                      name={[field.name, "cod_bodega"]}
                      label="Farmacia"
                      rules={[{ required: true, message: "Escoja una bodega" }]}
                      labelCol={{ span: 4 }}
                      wrapperCol={{ span: 20 }}
                    >
                      <Select
                        options={bodegas}
                        showSearch
                        optionFilterProp="label"
                        allowClear
                        // onChange={value => setBodegaSelected(Boolean(value) || value === 0)}
                        getPopupContainer={() => document.getElementsByClassName("ant-modal-body")[0] as HTMLElement}
                      />
                    </Form.Item>
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={8} xs={8}>
                    <Form.Item
                      name={[field.name, "cargo_empleados"]}
                      label="Asume empleados"
                      rules={[{ required: true, message: "Ingrese el número de empleados que asume el franquiciado" }]}
                      labelCol={{ span: 14 }}
                      wrapperCol={{ span: 10 }}
                    >
                      <InputNumber min={0} max={10} />
                    </Form.Item>
                  </Col>
                  {/* <Col xxl={1} xl={1} lg={1} md={1} xs={1}>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Col> */}
                </Row>
              ))}
              <Form.Item style={{ padding: 1 }} labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Añadir bodega
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item name="estado" label="Estado" required valuePropName="checked">
          <Switch defaultChecked disabled={!Boolean(data)} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
