import React, { useCallback, useEffect, useRef, useState } from "react"
import type { ColumnType, ColumnsType } from "antd/es/table"
import { Button,Table, Row, Input, InputRef, Space,Tooltip } from "antd"
import { url} from "config/constants"
import { optionsPost, optionsGet, swalSuccess, swalWarning, swalQuestion } from "config/methods"
import { useExport } from "hooks/otros/useExport"
import { SearchOutlined, CheckOutlined, EyeOutlined, CloseOutlined} from "@ant-design/icons"
import moment from "moment"
import { FilterConfirmProps } from "antd/lib/table/interface"
import Highlighter from "react-highlight-words"


export interface DataType {
  key: React.Key
  cod_usuario: string
  nombre: string
  provincia: string
  ciudad: string
  direccion: string
  cod_farmacia: string
  fecha_ingreso: Date
  fecha_solicitud: Date
  codarticulo:string
  cod_estado: string
  dias: number
  cod_articulo_reposicion: number,
  disabled: boolean,
  tipo_articulo: string,
  detalle: string[]
  imagellaves: string[]
}

type DataIndex = keyof DataType

export interface DataUniforme {
  key: React.Key
  codtipouniforme: number
  nombre_tipouniforme: string
  cantidad: number
  codtalla: string
  vunitario: number 
  keyrecord: string
}


export function useAprobacionUniformes(){
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [loading, setLoading] = useState(false)
  const [showSolicitud, setShowSolicitud] = useState(false)
  const [data, setData] = useState<DataType[]>([])
  const [itemselect, setitemSelect]=useState([{value:"", label: "seleccione un kit"}])
  const [itemselectTalla, setitemSelectTalla]=useState([{value:"", label: "Talla"}])
  const { exportToExcel } = useExport()
  const [searchText, setSearchText] = useState("")
  const [searchedColumn, setSearchedColumn] = useState("")
  const searchInput = useRef<InputRef>(null)
  const [llavesimages, setllavesimages]= useState<String[]>([])
  const [visible, setVisible] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [observacion, setObservacion] = useState("")
  const [codSolicitud, setcodSolicitud] = useState(0)
  const [mensaje, setmensaje] = useState(false)
  const [visibleButton, setVisibleButton] = useState(false);


  const getdetallesolcitud = useCallback(async(codarticulo: string,cod_articulo_reposicion:number) => {
    return await fetch(
      `${url}fapifsg-pr/rrhh/Uniforme/getDetalleSolicitud`,
      optionsPost({ cod_kit_uniforme: codarticulo, cod_solicitud: cod_articulo_reposicion })
    ).then((data) => data.json());
  },[]);

  const columnsuniforme: ColumnsType<DataUniforme>=[
    {
      title: "Nombres",
      dataIndex: "nombre_tipouniforme",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
    },
    {
      title: "Talla",
      dataIndex: "codtalla",
      render: (item, key)=>{
        let talla
          talla=itemselectTalla.filter((value)=>value.value===key.codtalla) 
        return(<> {<label>{talla.length===0?'':talla[0].label}</label>}</>)}
    },
  ]


  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  })


  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText("")
  }



  const columns: ColumnsType<DataType> = [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      title: "Nombres",
      dataIndex: "nombre",
      width: "200px",
      ...getColumnSearchProps('nombre')
    },
    {
      title: "Provincia",
      dataIndex: "provincia",
    },
    {
      title: "Ciudad",
      dataIndex: "ciudad",
      
    },
    {
      title: "Farmacia",
      dataIndex: "cod_farmacia",
      render: (item)=>{
        let valorComoCadena = item.toString();
        // Calcular la cantidad de ceros que se deben agregar
        const cerosFaltantes = 3 - valorComoCadena.length;
        // Rellenar con ceros a la izquierda si es necesario
        if (cerosFaltantes > 0) {
          valorComoCadena = '0'.repeat(cerosFaltantes) + valorComoCadena;
        }
        return "FSG "+valorComoCadena}
    },
    {
      title: "F. Ingreso",
      dataIndex: "fecha_ingreso",
      
    },
    {
      title: "F. Solicitud",
      dataIndex: "fecha_solicitud",
      //sorter: (a, b) => a.fecha_solicitud.getTime() - b.fecha_solicitud.getTime(),
    },
    {
      title: "Kit",
      dataIndex: "",
      render: (key) => {
        const nombrekit = itemselect.filter((item) => item.value === key.codarticulo)
       // console.log(nombrekit)
        return (
          <>
            <Row >{/*style={{ background: background, borderColor: colorborder,borderStyle: 'solid', borderRadius:'5px', borderWidth:'1px', color: color, paddingLeft: "5px",  paddingRight:'5px' }}*/}
              <label>{nombrekit.length === 0 ? "" : nombrekit[0].label}</label>
            </Row>
          </>
        )
      },
    },
    {
      title: "Solicitud Reposición",
      dataIndex: "",
      align: 'center',
      render: (keys, key)=>{
        return (key.tipo_articulo==="UF"?'Si':'No')//<CheckOutlined />:'')
      }
    },
    {
      title: "Acción",
      dataIndex: "",
      width:'129px',
      render: (key) => {
        return (
          <>
            <Tooltip title="Aprobar">
              {" "}
              <Button type={"primary"} size="small" style={{ marginRight: "2px" }} onClick={() => savesolicitudKit(key)}>
                <CheckOutlined />
              </Button>
            </Tooltip>
            {
              <Tooltip title="Rechazar">
                <Button
                  type={"primary"} size="small"
                  style={{ marginRight: "2px" }}
                  onClick={() => {
                    setOpenModal(true)
                    setObservacion("")
                    setmensaje(false)
                    setcodSolicitud(key.cod_articulo_reposicion)
                  }}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip>
            }
            {key.tipo_articulo === "UF" ? (
              <Tooltip title="Evidencia">
                <Button
                  type={"primary"} size="small"
                  onClick={() => {
                    setVisible(true)
                    setllavesimages(key.imagellaves === null ? [] : key.imagellaves)
                  }}
                >
                  <EyeOutlined/>
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
          </>
        )
        /* return (<> <Button type={"primary"} style={{marginRight:'3px'}} onClick={()=>savesolicitudKit(key)} disabled={disabled}><SendOutlined /></Button>
        {key.tipo_articulo==='UF'?<Button type={"primary"} onClick={() => {setVisible(true); setllavesimages(key.imagellaves===null?[]:key.imagellaves)}}><EyeOutlined/></Button>:''}
        </>
        ) */
      }, //<Button icon={<DeleteOutlined />}></Button>,
    },
  ]

  const savesolicitudKit=async(key:any="")=>{
    let newdata:any =[];
    swalQuestion({
      text: `¿Esta seguro de aprobar${key?" la solicitud de "+key.nombre:' las solicitudes?'}`,
      icon: "warning",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (key){
          data.forEach((item1)=>{
            if(item1.key===key.key){
              const detalle=item1.detalle.filter((item1:any)=>item1.codtalla==="")
              if(!item1.codarticulo && item1.tipo_articulo!=='UF'){
                swalWarning({text: "Por favor seleccione un kit"})
                return
              }
              if(detalle.length>0){
                swalWarning({text: "Por favor revise que todas las prendas tengan tallas"})
                return
              }
              newdata.push({
                codsolicitudreposicion: item1.cod_articulo_reposicion, 
                tipoarticulo: item1.tipo_articulo, 
                codusuario: item1.cod_usuario, 
                codarticulo: item1.codarticulo, 
                estado: 'AP', 
                codusuariosolicitud: '', 
                codusuarioaprobacionrrhh:'', 
                codusuarioaprobacion:'',
                detalle:item1.detalle,
                cod_farmacia:item1.cod_farmacia,
                nombre: item1.nombre,
                nombrekit: itemselect.filter((item)=>item.value===item1.codarticulo),
                fecha: moment().format("DD/MM/YYYY")
              })
            }
          })
        }else{
          data.forEach((item:DataType)=> {
            if(selectedRowKeys.includes(item.key as string)){
              const detalle=item.detalle.filter((item1:any)=>item1.codtalla==="")
              if(item.codarticulo===""){
                swalWarning({text: "Por favor seleccione un kit"})
                newdata=[]
                return
              }
              if(detalle.length>0 && item.tipo_articulo!=='UF'){
                swalWarning({text: "Por favor revise que todas las prendas tengan tallas"})
                newdata=[]
                return
              }
              newdata.push({
                codsolicitudreposicion: item.cod_articulo_reposicion, 
                tipoarticulo: 'KT', 
                codusuario: item.cod_usuario, 
                codarticulo: item.codarticulo, 
                estado: 'AP', 
                codusuariosolicitud: '', 
                codusuarioaprobacionrrhh:'', 
                codusuarioaprobacion:'',
                detalle:item.detalle,
                cod_farmacia:item.cod_farmacia,
                nombre: item.nombre,
                nombrekit: itemselect.filter((item1)=>item1.value===item.codarticulo),
                fecha: moment().format("DD/MM/YYYY")
              })
            }
          })
          }
          if(newdata.length>0){
            const res = await fetch(
              `${url}fapifsg-pr/rrhh/Uniforme/saveSolicitudUniformes`,
              optionsPost({ solicitudes: newdata, correo: "mariacedeno@sangregorio.com.ec" , opcion:"Solicitar Uniformes", url:`https://gestion.fsg.ec/rrhh/renovacion-uniformes/solicitud-reposicion-uniformes`})
            )//.then((data) => data.json())
            if(res.status===200){
              getsolicitaruniforme();
              swalSuccess({text:"Se guardaron los datos exitosamente"})
            }
            setSelectedRowKeys([])
          //data.filter((item)=>(if(selectedRowKeys.includes(item.key))
          }
      }})


  //console.log(newdata) 
  }


  const getsolicitaruniforme = async () => {
    const res = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/solicitar-uniformes`, optionsPost({estado:'PP'})).then((data) => data.json())
    //console.log(!res)
  let newdata:any=[]
  if(res){
      newdata = Array.from(res).map((item: any, key: any) => ({
      key: key.toString(),
      cod_usuario: item.Cod_Usuario,
      nombre: item.Nombres,
      provincia: item.Provincia,
      ciudad: item.Ciudad, 
      direccion:item.Direccion,
      cod_farmacia: item.Cod_Farmacia,
      fecha_ingreso: item.Fecha_Ingreso,
      fecha_solicitud: item.Fecha_Solicitud,
      codarticulo: item.Cod_Articulo,
      cod_estado: "AP",//aprobacion RRHH
      dias: item.dias,
      cod_articulo_reposicion:item.Cod_Solicitud_Reposicion_Articulo,
      disabled: item.Estado==='AP'?true:false,
      detalle: item.Detalle!==null?JSON.parse(item.Detalle).map((item: any, key: any) => ({
        key: key,
        codtipouniforme: item.Cod_Tipo_Uniforme,
        nombre_tipouniforme: item.Nombre,
        cantidad: item.Cantidad,
        codtalla: item.Cod_Talla,
        vunitario:"0",
        keyrecord:  key.toString()
      })):[],
      tipo_articulo: item.Tipo_Articulo,
      imagellaves:item.Llaves!==null?JSON.parse(item.Llaves).map((item1:any, key:any)=>({
        key: key,
        Llave: item1.Llave
      })): [],
    }))
    //console.log(newdata)
   }

   setData(newdata)
}

  const getKit = async () => {
    const res = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/getKit`, optionsGet()).then((data) => data.json())
    let respuesta=res.data;
    let newget =[{value:"", label: "seleccione un kit"}]
    respuesta.forEach((item: any)=>{  
    let add={
      value: item.Cod_Kit,
      label: item.Nombre,
    }
    newget= [...newget, add];
    }
    )
    setitemSelect(newget)
  }
  
  const getTalla = async () => {
    const res = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/getTalla`, optionsGet()).then((data) => data.json())
    let respuesta=res.data;
    let newget =[{value:"", label: "Talla"}]
    respuesta.forEach((item: any)=>{  
    let add={
      value: item.Cod_Talla,
      label: item.Talla,
    }
    newget= [...newget, add];
    }
    )
    setitemSelectTalla(newget)
  }
  
  useEffect(() => {
    getsolicitaruniforme()
    getKit()
    getTalla()
  },[])

  const start = () => {
    setLoading(true)
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([])
      setLoading(false)
    }, 1000)
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    //console.log("selectedRowKeys changed: ", newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
    if(newSelectedRowKeys.length>1){
      setVisibleButton(true)
    }else{
      setVisibleButton(false)
      
    }
  }

  const rowSelection = {
    getCheckboxProps: (item:any) => ({ disabled: item.disabled }),
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const SolicitudRenovacion = () => {
    setShowSolicitud(true)
  }


  const handleDownload = () => {    
    const dataExport = data.map((item)=>{
      let addData: any;
      if(item.detalle!==null){
        item.detalle.forEach((item:any)=>{
          let nombre=item.nombre_tipouniforme.split(" ")
          if(nombre.length>1){
            nombre=(nombre[0].charAt(0)+(nombre[1].charAt(0))).toUpperCase()
          }else{
            nombre=(nombre[0].charAt(0).toUpperCase())
          }
          let talla
          itemselectTalla.forEach((item1)=> {if(item1.value===item.codtalla){talla=item1.label}})
          addData=({ ...addData,[item.nombre_tipouniforme]: item.cantidad, [nombre+"Talla"]: talla});
        })
      }
        let  kit 
        //itemselectTalla.map((key)=>{if(key.value===item.cod_talla&&key.value!==""){talla= key.label}})
        itemselect.forEach((key)=>{if(key.value===item.codarticulo&&key.value!==""){kit= key.label}})

        let valorComoCadena = item.cod_farmacia.toString();
        const cerosFaltantes = 3 - valorComoCadena.length;
        if (cerosFaltantes > 0) {
          valorComoCadena = '0'.repeat(cerosFaltantes) + valorComoCadena;
        }
        return{
          Nombres: item.nombre,
          Provincia: item.provincia,
          Ciudad: item.ciudad,
          Direccion: item.direccion,
          FarmaciaN: "FSG "+valorComoCadena,
          Estado: item.cod_estado,
          Kit:kit,
          "Fecha de Ingreso": item.fecha_ingreso,
          "Fecha Solicitud": item.fecha_solicitud,
          ...addData,
        }
    })
    exportToExcel(dataExport, `Reporte_Solicitud_Uniformes_${moment().format("DDMMYYYY")}`)
  }

  const handleInputChange = (event: any) => {
    setmensaje(false)
    let newValue = event.target.value
    setObservacion(newValue)
  }

  const saveObservacion = async () => {
    if (!observacion) {
      setmensaje(true)
      return
    }

    const res = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/RechazarSolicitud`, optionsPost({ "cod_solicitud": codSolicitud, "observacionrrhh": '', "obsevacionaprobacion":observacion })).then((data) => data.json())
    if(res==='ok'){
      setOpenModal(false)
      swalSuccess({text: "Se guardó correctamente"})
      getsolicitaruniforme()
    }
  }

  return {
    selectedRowKeys,
    setSelectedRowKeys,
    loading,
    setLoading,
    start,
    rowSelection,
    columns,
    data,
    showSolicitud,
    SolicitudRenovacion,
    savesolicitudKit,
    handleDownload,
    getdetallesolcitud,
    setData,
    //defaultExpandedKeys,
    columnsuniforme,
    llavesimages,
    visible,
    setVisible,
    openModal,
    setOpenModal,
    observacion,
    setObservacion,
    handleInputChange,
    saveObservacion,
    mensaje,
    visibleButton
    //handleExpand,
  }
}
