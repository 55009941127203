import { Card } from "antd";
import TableReportVirtual from "components/otros/TableReportVirtual";
import { ButtonExcel } from "components/otros/buttons";
import { useReportePromocionesMF } from "hooks/productos/useReportePromocionesMF";

const ReportePromocionesMF = () => {
  const { headTable, dateTable, handleClickDownload } = useReportePromocionesMF();

  return (
    <Card>
      <TableReportVirtual
        headTable={headTable}
        columnTable={dateTable}
        rowSelection={() => null}
        exportToExcel={() => null}
        isSelect={false}
        muestra={false}
        x={800}
        y={490}
        isTotal={false}
      />
      {dateTable.length > 0 && (
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
          <ButtonExcel buttonProps={{ onClick: handleClickDownload, style: { width: "auto", background: "green" } }}>
            Descargar
          </ButtonExcel>
        </div>
      )}
    </Card>
  );
};

export default ReportePromocionesMF;
