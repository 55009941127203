import { Row, Col, Input, Typography, Button,  Select, DatePicker} from "antd"

import { SaveOutlined } from "@ant-design/icons"
import { MdOutlineAddCircle } from "react-icons/md"

import { ContextModalForm } from "context/ModalFormContext"
import { ContextList } from "context/ButtonRightwithListContext"

import { ButtonRightWithListWithModalForm } from "components/otros/ButtonRightWithListWithModalForm"


import styles from "pages/contentreembolsogastos/reembolso.module.css"
import { useCrearReembolsoOtros } from "hooks/reembolsos/useCrearReembolsoOtros"


const { TextArea } = Input

const { Title } = Typography

export function CrearReembolsoOtros() {

  const {
    handleOkModal,
    handleOnClickActionList,
    forms,
    JustificacionValue,
    setJustificacionValue,
    resetOkModal, //useCrearReembolso
    inputsState,
    modalProps,
    setModalProps,
    initialValues,
    resetFields, //useModalFormCrearReembolso
    lists,
    contentDocuments,
    actionsList, //useButtonRightListCrearReembolso
    dataSelect,
    handleChangeUsuario,
    valueUsuario,
    btn_Guardar,
    disabledDate,
    handleDateChange,
    FechaSelecionada
  } = useCrearReembolsoOtros()

  return (
    <>
      <Title level={2} className={styles.titleStyle}>
        Reembolso de Gastos
      </Title>
      <Row wrap className={styles.rowStyle} >
        <Col sm={24} xs={24} md={18} style={{paddingRight:'5px'}}>
        <Select
          showSearch
          options={dataSelect}
          value={valueUsuario}
          onChange={handleChangeUsuario}
          style={{ width: "100%"}}
          placeholder={"Seleccione una persona"}
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
        />
        </Col>

        <Col sm={24} xs={24} md={6}>
        <DatePicker placeholder={"Seleccione la fecha del Reembolso"} style={{ width: "100%",textAlign: 'right' }} defaultValue={FechaSelecionada} onChange={handleDateChange} disabledDate={disabledDate}/>
        </Col>
        
      </Row>
      <Row wrap className={styles.rowStyle}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <TextArea
            maxLength={250}
            placeholder="Justificación"
            autoSize={{ minRows: 1, maxRows: 10 }}
            value={JustificacionValue}
            onChange={(e) => {
              setJustificacionValue(e.target.value)
            }}
          />
        </Col>
      </Row>

      {["Factura", "Nota de venta", "Ticket"].map((title, index) => (
        
        <ContextModalForm
          inputsState={inputsState[index]}
          key={index}
          modalProps={modalProps[index]}
          updateModalProps={setModalProps[index]}
          onOk={handleOkModal[index]}
          form={forms[index]}
          initialValues={initialValues[index]}
          onCancel={() => {
            console.log(lists[index].map(contentDocuments[index]))
            resetOkModal(index)
            resetFields[index]()
          }}
        >
         
          <ContextList
            iconButton={<MdOutlineAddCircle className="iconbutton" />}
            textButton={title}
            actionsList={actionsList}
            actionsForActionsList={handleOnClickActionList[index]}
            listData={lists[index].map(contentDocuments[index])}
          >
            <ButtonRightWithListWithModalForm />
          </ContextList>
          
        </ContextModalForm>
        
      ))}
      <Row className={styles.rowStyle}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Button
            block
            type="primary"
            icon={<SaveOutlined className="iconbutton" />}
            onClick={btn_Guardar}
          >
            Guardar
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default CrearReembolsoOtros
