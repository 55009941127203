import { ReportTable, columnsTablePDF } from "components/reactreport/report-table"
import { Bonos } from "components/franquiciados/calculos-finales"
import type { Style } from "@react-pdf/types"

type Props = {
  bonos: Bonos[]
  commonColumnStyle: Style
  rowHeaderStyle: Style
}

export function BonosTable({ bonos, commonColumnStyle, rowHeaderStyle }: Props) {
  const columnsBonos: columnsTablePDF<Bonos>[] = [
    {
      title: "Descripción",
      dataIndex: "bono",
      key: "bono",
      styleColumn: { ...commonColumnStyle, padding: 3, textAlign: "left" },
      styleColumnHeader: { ...commonColumnStyle, padding: 3, textAlign: "left" },
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      styleColumn: { ...commonColumnStyle, padding: 3, textAlign: "right" },
      styleColumnHeader: { ...commonColumnStyle, padding: 3, textAlign: "right" },
      fixed: true,
      styleCell: index => (index === bonos.length - 1 ? { borderBottom: "1.3px solid #000" } : {}),
    },
  ]

  return (
    <ReportTable
      columns={columnsBonos}
      dataSource={bonos}
      styleContainer={{ marginTop: "5px" }}
      styleRowHeader={rowHeaderStyle}
      header={false}
    />
  )
}
