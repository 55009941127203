import { Button } from "antd";
import { FileImageFilled } from "@ant-design/icons";

export const ButtonUploadImg = () => {
  return (
    <Button
      style={{ width: "99%" }}
      icon={
        <FileImageFilled
          style={{
            cursor: "pointer",
            fontSize: "15px",
            color: "#1E90FF",
          }}
        />
      }
    >
      Seleccionar una foto
    </Button>
  );
};
