import { Button, Card, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Spin, Table } from "antd";
import { useAdministrarConvenio } from "hooks/indicadores/useAdministrarConvenio";
import styles from "./AdministrarConvenio.module.css";
import TableReport from "./TableReport";
import ComponentChart from "./ComponentChart";
import { PdfViewer } from "../rrhh/componentsActualizacion/PdfViewer";
import ChartPorcen from "./ChartPorcen";
import { ButtonExcel } from "../otros/buttons";
import TextArea from "antd/lib/input/TextArea";
import TableReportVirtual from "components/otros/TableReportVirtual";
import { SaveOutlined } from "@ant-design/icons";

const AdministrarConvenio = () => {
  const {
    saveNewValue,
    openModalFormValor,
    handleCancelCobros,
    handleOkCobros,
    filterData,
    handleChangeFilter,
    form,
    option,
    columnTable,
    rowSelection,
    headTableMonth,
    columnTableMonth,
    graph,
    headTableQuota,
    columnTableQuota,
    headTableLab,
    columnTableQuotaV,
    columnTableMonthV,
    openModalPdf,
    setOpenModalPdf,
    llave,
    cuotaC,
    proyeccionC,
    cuotaV,
    proyeccionV,
    filterDataPeriodo,
    handleClickDownload,
    headTableResumenCompras,
    resumenCompras,
    calcularTotales,
    form2,
    proveedores,
    loaderModal,
    loadingModal,
  } = useAdministrarConvenio();

  return (
    <Card className={styles.marginCard}>
      <Row>
        <Col xs={24}>
          <Form form={form} initialValues={{ periodo: 2 }}>
            <Row gutter={[5, 0]}>
              <Col md={8} xs={24}>
                <Form.Item label='Seleccione el periodo' name='periodo'>
                  <Select options={filterDataPeriodo} onChange={handleChangeFilter}></Select>
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item label='Filtrar por' name='filter' initialValue={option}>
                  <Select options={filterData} onChange={handleChangeFilter}></Select>
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item>
                  <ButtonExcel buttonProps={{ onClick: handleClickDownload }}>Descargar Convenios</ButtonExcel>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Row gutter={[5, 0]}>
            <Col xs={24}>
              <TableReportVirtual
                headTable={headTableLab}
                columnTable={columnTable}
                rowSelection={rowSelection}
                exportToExcel={() => null}
                isSelect={true}
                muestra={false}
                x={1000}
                y={320}
                isTotal={false}
              />
            </Col>
          </Row>

          <Row gutter={[5, 5]}>
            {(columnTableQuota?.length > 0 || columnTableMonthV?.length > 0) && (
              <Divider orientation='left'>DETALLES CONVENIOS</Divider>
            )}
            {columnTableQuota?.length > 0 && (
              <>
                <Row gutter={[5, 5]}>
                  <Col md={6}>
                    <Col xs={24}>
                      <Table
                        columns={headTableQuota}
                        dataSource={columnTableQuota}
                        size='small'
                        bordered
                        pagination={false}
                        title={() => <strong>CUOTAS SELL IN</strong>}
                        scroll={{ x: 290 }}
                      />
                    </Col>
                  </Col>

                  <Col md={18}>
                    <Row gutter={[5, 5]}>
                      <Col md={7} xs={24}>
                        <TableReport
                          headTable={headTableMonth}
                          columnTable={columnTableMonth}
                          rowSelection={false}
                          exportToExcel={() => null}
                          isSelect={false}
                          muestra={false}
                          // title={descripcion?.trim()}
                          x={205}
                          y={120}
                        />
                      </Col>
                      <Col md={12} xs={24}>
                        <ComponentChart
                          columnTable={columnTableMonth}
                          grafica={"mes"}
                          tamaño={270}
                          angulo={-45}
                          graph={graph}
                          dataKey={""}
                          opciones={false}
                        />
                      </Col>
                      <Col md={5} xs={24}>
                        <ChartPorcen valorPrincipal={cuotaC} valorSecundario={proyeccionC} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Row>
          <Row gutter={[5, 5]}>
            {columnTableMonthV?.length > 0 && (
              <>
                <Row gutter={[5, 5]}>
                  <Col md={6}>
                    <Table
                      columns={headTableQuota}
                      dataSource={columnTableQuotaV}
                      size='small'
                      bordered
                      pagination={false}
                      title={() => <strong>CUOTAS SELL OUT</strong>}
                      scroll={{ x: 290 }}
                    />
                  </Col>

                  <Col md={18}>
                    <Row gutter={[5, 0]}>
                      <Col md={7} xs={24}>
                        <TableReport
                          headTable={headTableMonth}
                          columnTable={columnTableMonthV}
                          rowSelection={false}
                          exportToExcel={() => null}
                          isSelect={false}
                          muestra={false}
                          // title={descripcion?.trim()}
                          x={205}
                          y={120}
                        />
                      </Col>
                      <Col md={12} xs={24}>
                        <ComponentChart
                          columnTable={columnTableMonthV}
                          grafica={"mes"}
                          tamaño={280}
                          angulo={-45}
                          graph={graph}
                          dataKey={""}
                          opciones={false}
                        />
                      </Col>
                      <Col md={5} xs={24}>
                        <ChartPorcen valorPrincipal={cuotaV} valorSecundario={proyeccionV} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Row>
          <Row gutter={[5, 5]}>
            {/* {(resumenCompras?.length > 0 || resumenCompras?.length > 0) && (
              <Divider orientation='left'>RESUMEN DE COMPRAS DEL CONVENIO</Divider>
            )} */}
            {resumenCompras?.length > 0 && (
              <>
                <Row gutter={[5, 15]}>
                  <Col md={24}>
                    <Col xs={24}>
                      <Table
                        columns={headTableResumenCompras}
                        dataSource={resumenCompras}
                        size='small'
                        bordered
                        pagination={false}
                        title={() => <strong>RESUMEN DE COMPRAS DEL CONVENIO</strong>}
                        scroll={{ x: 1200 }}
                        summary={() => {
                          const { comprasBrutas, devoluciones, comprasNetas } = calcularTotales();

                          return (
                            <Table.Summary.Row style={{ backgroundColor: "#f0f0f0" }}>
                              <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                              <Table.Summary.Cell index={1}>{comprasBrutas}</Table.Summary.Cell>
                              <Table.Summary.Cell index={2}>{devoluciones}</Table.Summary.Cell>
                              <Table.Summary.Cell index={3}>{comprasNetas}</Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        }}
                      />
                    </Col>
                  </Col>
                </Row>
              </>
            )}
          </Row>
        </Col>
      </Row>

      <PdfViewer visible={openModalPdf} setVisible={setOpenModalPdf} llave={llave} />
      <Modal
        footer={null}
        open={openModalFormValor}
        onOk={handleOkCobros}
        onCancel={handleCancelCobros}
        destroyOnClose
        transitionName=''
      >
        <Card title='Nuevo Valor Cobrado'>
          {!loaderModal ? (
            <Form form={form2} layout='horizontal' labelCol={{ span: 6 }} wrapperCol={{ span: 32 }}>
              <Row gutter={[5, 5]}>
                <Form.Item hidden name='cod_convenio'>
                  <Input></Input>
                </Form.Item>

                <Col xs={24}>
                  <Form.Item
                    label='Proveedor'
                    name='cod_proveedor'
                    rules={[{ required: true, message: "Por favor seleccione un proveedor!" }]}
                  >
                    <Select options={proveedores}></Select>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label='Observación'
                    name='observacion'
                    rules={[{ required: true, message: "Por favor ingrese una observación!" }]}
                  >
                    <TextArea></TextArea>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label='Valor a cobrar'
                    style={{ width: "100%" }}
                    name='valor'
                    rules={[{ required: true, message: "Por favor ingrese un valor!" }]}
                  >
                    <InputNumber style={{ width: "100%" }} min={1} precision={2}></InputNumber>
                  </Form.Item>
                </Col>
              </Row>
              <Button
                onClick={saveNewValue}
                type='primary'
                style={{
                  width: "100%",
                }}
                loading={loadingModal}
                icon={<SaveOutlined className='iconbutton' />}
              >
                GUARDAR
              </Button>
            </Form>
          ) : (
            <Spin />
          )}
        </Card>
      </Modal>
    </Card>
  );
};

export default AdministrarConvenio;
