import { Row, Empty, Layout, Divider, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";

import { ColumCard, TypeCard } from "components/otros/ColumCard";
import { BottomNavBar } from "components/contentbottomnav/BottomNavBar";

import { Facturas, NotaVenta, Recibos } from "components/svg/Reembolso";

//import { useReembolsos } from "hooks/reembolsogastos/useReembolsos"

import { useReembolsos } from "hooks/reembolsos/useReembolsos";

import { arrEstados } from "config/constants";
import { useScreenSize } from "hooks/otros/useScreenSize";
const { Footer } = Layout;
const { Text } = Typography;

// interface HookReembolso {
//   reembolsos: reembolsos[]
//   isLoading: boolean
//   estado?: string
// }

export function ReembolsoGastos() {
  const navigate = useNavigate();

  const handleClickButtonNew = () => navigate("/crear-reembolso");

  const { reembolsos, isLoading, estado, actions } = useReembolsos();

  const { width } = useScreenSize();

  const isMobile = width <= 778;

  return (
    <>
      <Divider orientation='right'>
        <Link to='/ver-todos'>Ver Todos</Link>
      </Divider>
      <Row
        wrap
        gutter={[16, 16]}
        style={{ padding: "0px 16px", marginLeft: 0, marginRight: 0, paddingBottom: "75.59px" }}
      >
        {estado?.toLowerCase() === "pendientes" && (
          <ColumCard
            cardType={TypeCard.New}
            handleClick={handleClickButtonNew}
            colProps={{ xs: 24, sm: 12, md: 8, lg: 8, xl: 6 }}
          />
        )}
        {isLoading ? (
          <ColumCard
            cardType={TypeCard.Loading}
            cardProps={{ actions: [] }}
            colProps={{ xs: 24, sm: 12, md: 8, lg: 8, xl: 6 }}
          />
        ) : (
          reembolsos.map(
            ({
              Cod_Reembolso,
              Fecha,
              Justificacion,
              numeroFacturas,
              numeroNotaVentas,
              numeroRecibos,
              total,
            }) => (
              <ColumCard
                key={"Col" + Cod_Reembolso}
                cardType={TypeCard.Normal}
                colProps={{ xs: 24, sm: 12, md: 8, lg: 8, xl: 6 }}
                cardProps={{
                  actions: actions(Cod_Reembolso),
                  title: (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <Text>{`Reembolso ${Cod_Reembolso}`}</Text>
                      <Text>{`$${(Math.round(total * 100) / 100).toFixed(2)}`}</Text>
                    </div>
                  ),
                  size: "small",
                  style: { height: "269.19px" },
                }}
                ContentCard={[
                  { type: "Text", text: "Fecha:", span: 9 },
                  { type: "Paragraph", text: Fecha.toString(), span: 15 },
                  { type: "Text", text: "Justificación:", span: 9 },
                  { type: "Paragraph", text: Justificacion, span: 15 },
                  {
                    type: "Avatar",
                    text: "Facturas",
                    span: 8,
                    badgeProps: { count: numeroFacturas, showZero: true, color: "var(--tertiary)" },
                    avatarProps: { src: <Facturas fill='var(--primary)' /> },
                  },
                  {
                    type: "Avatar",
                    text: "Nota de ventas",
                    span: 8,
                    badgeProps: {
                      count: numeroNotaVentas,
                      showZero: true,
                      color: "var(--tertiary)",
                    },
                    avatarProps: { src: <NotaVenta fill='var(--primary)' /> },
                  },
                  {
                    type: "Avatar",
                    text: "Tickets",
                    span: 8,
                    badgeProps: { count: numeroRecibos, showZero: true, color: "var(--tertiary)" },
                    avatarProps: { src: <Recibos fill='var(--primary)' /> },
                  },
                ]}
              />
            )
          )
        )}
      </Row>
      {reembolsos?.length === 0 && <Empty />}
      {isMobile && (
        <Footer className='bottomNav'>
          <BottomNavBar options={arrEstados} />
        </Footer>
      )}
    </>
  );
}

export default ReembolsoGastos;
