// import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { BrowserRouter } from "react-router-dom";

import esES from "antd/lib/locale/es_ES";

// import { SWRConfig } from 'swr'
import { ConfigProvider } from "antd";
import { ContextUser } from "context/UserContext";

import "leaflet/dist/leaflet.css";
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <>
    {/* <BrowserRouter> */}
    {/* <SWRConfig 
        value={{ 
          fetcher: (url:string, options: RequestInit) => fetch(url, options).then(res => res.json()),
          revalidateIfStale: false,
          revalidateOnFocus: false
        }}> */}
    <ConfigProvider locale={esES}>
      <ContextUser>
        <App />
      </ContextUser>
    </ConfigProvider>
    {/* </SWRConfig> */}
    {/* </BrowserRouter> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
