import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";
import { url } from "config/constants";
import { swalError } from "config/methods";

interface Detalles {
  Fecha: string;
  MaxFechaFin: string;
  MinFechaInicio: string;
  horasPorDia: number;
}
type DatosInventario = {
  key: React.Key;
  DescripcionGrupo: string;
  DescripcionBodega: string;
  fechaInicio: string;
  fechaFin: string;
  totalHoras: number;
  DetalleDias: Detalles[];
};

type GrupoDato = {
  value: number;
  label: string;
};

const breadcrumbs = [
  { Label: "Inventarios", Url: "/inventario" },
  { Label: "Reportes", Url: "/inventarios/grupo-inventario" },
  { Label: "Grupo inventario" },
];

const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];
export function useReportePorGrupo() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[1].Label + "/" + breadcrumbs[2].Label);
  const [mes, setMes] = useState<Moment>(moment());
  const [datos, setDatos] = useState<DatosInventario[]>([]);
  const [datosGrupo, setDatosGrupo] = useState<GrupoDato[]>([]);
  const [valor, setValor] = useState<number>();
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<DatosInventario[]>([]);
  const { getColumnSearchPropsNew } = useSearchTableNew<DatosInventario>();

  const [estadoBoton, setBoton] = useState<boolean>(false);
  const { exportToExcel } = useExport();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();

  const getGrupo = useCallback(async () => {
    setDatosGrupo([]);
    try {
      const res = await fetch(
        url + "apifsg-pr/inventarios/obtener_grupos_inventario",
        optionsPost({ mes: mes.format("YYYYMM") })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: GrupoDato[] = respuesta.data;

        setDatosGrupo(
          respuestas.map(({ label, value }) => ({
            label,
            value,
          }))
        );
      } else {
        setDatosGrupo([]);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsPost, isError, mes]);

  const handleChangeGrupo = (value: number) => {
    const grupoSeleccionado = datosGrupo.find((grupo) => grupo.value === value);

    if (grupoSeleccionado) {
      setValor(value);
      obtenerDatos(mes.format("YYYYMM"), value);
    } else {
      console.log("no encontrado");
    }
  };

  const handleChangeCalendar = (newDate: Moment | null) => {
    if (newDate) {
      setMes(newDate);
      if (valor !== undefined) {
        obtenerDatos(newDate.format("YYYYMM"), valor);
      } else {
        //  swalWarning({ text: "Debe seleccionar un grupo" });
      }
    }
  };

  const obtenerDatos = async (mes: string, codGrupo: number) => {
    try {
      const result = await fetch(
        url + "apifsg-pr/inventarios/obtener_datos_grupos_inventario",
        optionsPost({
          mes,
          codGrupo,
        })
      );
      if (isError(result)) throw new Error();
      const respuesta = await result.json();
      console.log(respuesta);
      if (respuesta.msg !== "err") {
        setBoton(true);
        const respuestas: DatosInventario[] = respuesta.data;
        setDatos(
          respuestas.map(({ key, DescripcionGrupo, DescripcionBodega, fechaInicio, fechaFin, totalHoras, DetalleDias }) => ({
            key,
            DescripcionGrupo,
            DescripcionBodega,
            fechaInicio,
            fechaFin,
            totalHoras,
            DetalleDias,
          }))
        );
      } else {
        setBoton(false);
        setDatos([]);
        return { msg: "Ocurrio un error", status: false };
      }

      /*swalSuccess({ text });
      getBodegas();*/
    } catch (e) {
      swalError({ text: "No se pudo obtener datos" });
    }
  };
  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };
  useEffect(() => {
    getGrupo();
  }, [getGrupo]);

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.DescripcionBodega.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  const columns: TableColumnsType<DatosInventario> = [
    {
      title: "Grupo",
      dataIndex: "DescripcionGrupo",
      key: "DescripcionGrupo",
    },
    {
      title: "Bodega",
      dataIndex: "DescripcionBodega",
      key: "DescripcionBodega",
      ...getColumnSearchPropsNew("DescripcionBodega"),
    },
    {
      title: "Fecha inicio",
      dataIndex: "fechaInicio",
      key: "fechaInicio",
    },
    {
      title: "Fecha fin",
      dataIndex: "fechaFin",
      key: "fechaFin",
    },
    {
      title: "Horas",
      dataIndex: "totalHoras",
      key: "totalHoras",
      align: "right",
      render: (_, record) => (
        <span style={{ color: record.totalHoras === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.totalHoras)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
  ];
  const columsDataExpanded: TableColumnsType<Detalles> = [
    {
      title: "Día",
      dataIndex: "Fecha",
      key: "Fecha",
    },
    {
      title: "Fecha y hora inicio",
      dataIndex: "MinFechaInicio",
      key: "MinFechaInicio",
    },
    {
      title: "Fecha y hora fin",
      dataIndex: "MaxFechaFin",
      key: "MaxFechaFin",
    },
    {
      title: "Horas diarias",
      dataIndex: "horasPorDia",
      key: "horasPorDia",

      render: (_, record) => (
        <span style={{ color: record.horasPorDia === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.horasPorDia)}
        </span>
      ),
    },
  ];
  /*const formatearTiempo = (totalMinutos: number) => {
    const horas = Math.floor(totalMinutos / 60);
    const minutos = totalMinutos % 60;
    return `${horas} horas ${minutos} minutos`;
  };*/

  const handleClickDownloadData = () => {
    const primerGrupo = datos[0].DescripcionGrupo; // Usar el DescripcionGrupo del primer elemento

    const datosParaExportar = datos.map(({ key, DetalleDias, ...resto }) => resto);
    exportToExcel(datosParaExportar, "inventario " + primerGrupo + mes.format("YYYYMM"));
  };

  return {
    columns,
    mes,
    setMes,
    filteredData,
    handleClickDownloadData,
    datosGrupo,
    handleChangeGrupo,
    handleChangeCalendar,
    columsDataExpanded,
    estadoBoton,
  };
}
