import { Button, Tag } from "antd"

import { useCallback, useEffect, useState } from "react"

import { useSearchTable } from "hooks/otros/useSearchTable"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSwal } from "hooks/otros/useSwal"
import { useLayout } from "hooks/otros/useLayout"

import { EditOutlined } from "@ant-design/icons"

import { url } from "config/constants"

import type { ColumnsType } from "antd/lib/table"

export interface PerfilesType {
  key: number
  nombre: string
  usuarios_asignados: number
  estado: boolean
}

const breadcrumbs = [{ Label: "Configuraciones", Url: "/configuraciones" }, { Label: "Perfiles" }]

const openKeys = [breadcrumbs[0].Label]

// breadcrumbs[0].Label = <Link to="/configuraciones">Configuraciones</Link>

export function usePerfiles() {
  useLayout(breadcrumbs, openKeys, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`)

  const { isError } = useVerifyResponse()

  const { swalError } = useSwal()

  const [perfiles, setPerfiles] = useState<PerfilesType[]>()

  const { getColumnSearchProps } = useSearchTable<PerfilesType>()

  const { optionsGet } = useLocalStorage()

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [perfilSelected, setPerfilSelected] = useState<number>()
  const [pageSize, setPageSize] = useState(6)

  const getPerfiles = useCallback(async () => {
    try {
      const res = await fetch(`${url}configuraciones/perfiles`, optionsGet)
      if (isError(res)) throw new Error()

      const result = await res.json()

      setPerfiles(result)
    } catch (e) {
      swalError({ text: "No se pudo obtener los perfiles" })
    }
  }, [isError, optionsGet, swalError])

  useEffect(() => {
    getPerfiles()
  }, [getPerfiles])

  const handleAddButton = () => setOpenModal(true)

  const columns: ColumnsType<PerfilesType> = [
    {
      title: "Perfil",
      dataIndex: "nombre",
      key: "nombre",
      ...getColumnSearchProps("nombre"),
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      render: (estado: boolean) => (estado ? <Tag color="green">Activo</Tag> : <Tag>Inactivo</Tag>),
      filters: [
        { text: "Activo", value: true },
        { text: "Inactivo", value: false },
      ],
      onFilter: (value, record) => record.estado === value,
      align: "center",
    },
    {
      title: "Usuarios asignados",
      dataIndex: "usuarios_asignados",
      key: "usuarios_asignados",
      render: usuarios => (usuarios === 0 ? <Tag color="default">{usuarios}</Tag> : <Tag color="blue">{usuarios}</Tag>),
      align: "center",
    },
    {
      title: "Acción",
      dataIndex: "key",
      key: "key",
      fixed: "right",
      align: "center",
      render: (id: number) => (
        <Button
          type="text"
          icon={<EditOutlined />}
          onClick={() => {
            setPerfilSelected(id)
            setOpenModal(true)
          }}
        />
      ),
    },
  ]

  return {
    perfiles,
    columns,
    openModal,
    setOpenModal,
    handleAddButton,
    perfilSelected,
    setPerfilSelected,
    getPerfiles,
    pageSize,
    setPageSize,
  }
}
