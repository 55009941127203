import { Button, TableColumnsType, Tooltip } from "antd";
import { useLayout } from "hooks/otros/useLayout";
import { useCallback, useState, useEffect, useRef } from "react";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import { url, url_alku } from "config/constants";
import { useLocalStorage } from "hooks/otros/useLocalStorage";

import { FileImageOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { swalWarning, swalSuccess, swalError } from "config/methods";
import Swal from "sweetalert2";
const breadcrumbs = [{ Label: "Mantenimientos", Url: "/mantenimientos/pendientes" }, { Label: "Pendientes" }];

const openKeys = [breadcrumbs[0].Label];
export interface DetalleMantenimientoEnriquecido extends PropsDetalleMantenimientoRuta {
  Cod_Ruta: React.Key;
  Descripcion: string;
}
export interface PropsDetalleMantenimientoRuta {
  Cod_Mantenimiento_Solicitud: number;
  key: React.Key;
  Cod_Bodega: number;
  bodega: string;
  Detalle: string;
  DescripcionTipo: string;
  DescripcionDetalle: string;
  Fecha_Solicitud: string;
  Nombres: string;
  Cod_Tipo_Mantenimiento: number;
  TipoMantenimiento: string;
  imagenes: PropsDetalleImagenes[];
}
interface PropsDetalleImagenes {
  ruta_imagen: string;
}
type MantenimientosPendientesData = {
  key: React.Key;
  Cod_Ruta: number;
  Descripcion: string;
  DetallesJSON: PropsDetalleMantenimientoRuta[];
};

export const useMantenimientoPenientes = () => {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);
  const [datos, setDatos] = useState<MantenimientosPendientesData[]>([]);
  const { isError } = useVerifyResponse();

  const [searchText] = useState("");
  //const { exportToExcel } = useExport();
  const [filteredData, setFilteredData] = useState<MantenimientosPendientesData[]>([]);

  //const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<MantenimientosPendientesData>();

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [valueselect, setvalueselect] = useState("0");
  const [activeRowIndex] = useState<number>(0);
  //const [selectedDetails, setSelectedDetails] = useState<DetalleMantenimientoEnriquecido[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  let { optionsPost } = useLocalStorage();

  const getDataMartillo = useCallback(async () => {
    try {
      const res = await fetch(url + "apifsg-pr/mantenimientos/pendientes", optionsPost({ valueselect }));

      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      // console.log(respuesta);
      if (respuesta.msg !== "err") {
        const respuestas: MantenimientosPendientesData[] = respuesta.data;
        setDatos(
          respuestas.map(({ key, Cod_Ruta, Descripcion, DetallesJSON }) => ({
            key,
            Cod_Ruta,
            Descripcion,
            DetallesJSON,
          }))
        );
        setFilteredData(respuesta.data);
      } else {
        setFilteredData([]);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setFilteredData([]);
      return { msg: "no_conexion", status: false };
    }
  }, [isError, optionsPost, valueselect]);

  useEffect(() => {
    getDataMartillo();
  }, [getDataMartillo]);
  /*const rowSelection = (key: React.Key, Descripcion: string) => ({
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: PropsDetalleMantenimientoRuta[]) => {
      const newSelectedDetails = selectedRows.map((row) => ({ Cod_Ruta: key, Descripcion: Descripcion, ...row }));
    //  setSelectedDetails(newSelectedDetails);
    },
  });*/

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos);
    } else {
      const filtered = datos.filter(
        (item) => item.Descripcion && item.Descripcion.toString().toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  const handleChange = (value: string) => {
    setvalueselect(value);
  };

  const columns: TableColumnsType<MantenimientosPendientesData> = [
    {
      title: "Rutas",
      dataIndex: "Descripcion",
      key: "Descripcion",
      fixed: "left",
      filters: [...new Set(filteredData.map(({ Descripcion }) => Descripcion))].map((Descripcion) => ({
        text: Descripcion,
        value: Descripcion,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.Descripcion,
    },
  ];
  const rechazarMantenimiento = async (codSolicitud: React.Key, descripcion: String) => {
    try {
      const result = await fetch(
        url + "apifsg-pr/mantenimientos/rechazar_mantenimiento",
        optionsPost({
          codSolicitud,
          descripcion,
        })
      );
      if (isError(result)) throw new Error();
      let text = await result.json();
      text = text["msg"];

      swalSuccess({
        text,
      });

      getDataMartillo();
    } catch (e) {
      swalError({
        text: "No se pudo rechazar el mantenimiento",
      });
    }
  };

  const aceptarMantenimiento = async (codSolicitud: React.Key, fecha: String) => {
    try {
      const result = await fetch(
        url + "apifsg-pr/mantenimientos/aceptar_mantenimiento",
        optionsPost({
          codSolicitud,
          fecha,
        })
      );
      if (isError(result)) throw new Error();
      let text = await result.json();
      text = text["msg"];

      swalSuccess({
        text,
      });

      getDataMartillo();
    } catch (e) {
      swalError({
        text: "No se pudo aceptar el mantenimiento",
      });
    }
  };

  const columnsTable: TableColumnsType<PropsDetalleMantenimientoRuta> = [
    {
      title: "Bodega",
      dataIndex: "bodega",
      key: "bodega",
      width: "200px",
      fixed: "left",
    },
    {
      title: "Fecha solicitud",
      dataIndex: "Fecha_Solicitud",
      key: "Fecha_Solicitud",
      width: "130px",
      fixed: "left",

      render: (text, record) => {
        const partes = text.split("/");
        if (partes.length === 3) {
          const fechaSolicitud = new Date(partes[2], partes[1] - 1, partes[0]); // Meses son 0-indexados en JS
          const fechaActual = new Date();

          const añoSolicitud = fechaSolicitud.getFullYear();
          const mesSolicitud = fechaSolicitud.getMonth();
          const díaSolicitud = fechaSolicitud.getDate();

          const añoActual = fechaActual.getFullYear();
          const mesActual = fechaActual.getMonth();
          const díaActual = fechaActual.getDate();

          const esIgual = añoSolicitud === añoActual && mesSolicitud === mesActual && díaSolicitud === díaActual;
          const esMayor = fechaSolicitud > fechaActual; // Esto aún considera las horas, para "esMayor" podría ser necesario un ajuste similar si se requiere precisión solo por fecha

          let colorTexto = "red";
          if (esIgual) {
            colorTexto = "yellow";
          } else if (esMayor) {
            colorTexto = "green";
          }

          return <div style={{ color: colorTexto }}>{text}</div>;
        } else {
          return <div>{text}</div>;
        }
      },
    },

    { title: "Descripción", dataIndex: "DescripcionTipo", key: "DescripcionTipo", width: "190px", fixed: "left" },
    { title: "Detalle", dataIndex: "DescripcionDetalle", key: "DescripcionDetalle", width: "200px", fixed: "left" },
    {
      title: "Solicita",
      dataIndex: "Nombres",
      key: "Nombres",
      width: "190px",
      fixed: "left",
    },
    {
      title: "Acción",
      dataIndex: "key",
      width: "110px",
      render: (key) => {
        return (
          <>
            <Tooltip title="Aprobar">
              <Button
                type={"default"}
                size="small"
                style={{ marginRight: "3px" }}
                onClick={async () => {
                  await Swal.fire({
                    title: "Seleccione una fecha para el mantenimiento",
                    html: `
                    <input type="date" id="date" class="swal2-input" style="width:300px;">`,
                    showCancelButton: true,
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cancelar",
                    confirmButtonColor: "#8b0505",
                    preConfirm: () => {
                      const selectedDateString = (document.getElementById("date") as HTMLInputElement).value;

                      // Extraer año, mes y día de la cadena de fecha seleccionada
                      const [year, month, day] = selectedDateString.split("-").map(Number);

                      const selectedDateObj = new Date(year, month - 1, day); // Los meses en JavaScript son 0-indexados, por eso se resta 1
                      // Obtener la fecha actual y resetear las horas, minutos, segundos y milisegundos a 0
                      const currentDate = new Date();
                      currentDate.setHours(0, 0, 0, 0);

                      // Validar si no se seleccionó una fecha
                      if (!selectedDateString) {
                        Swal.showValidationMessage("Por favor seleccione una fecha");
                        return false; // Detener aquí si no se ha seleccionado una fecha
                      }

                      // Validar si la fecha seleccionada es menor o igual a la fecha actual
                      if (selectedDateObj <= currentDate) {
                        Swal.showValidationMessage("La fecha debe ser mayor a la fecha actual");
                        return false; // Detener aquí si la validación falla
                      }

                      // Si pasa las validaciones, retornar la fecha seleccionada
                      return { date: selectedDateObj };
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      // Aquí puedes manejar la fecha seleccionada
                      const selectedDateString = (document.getElementById("date") as HTMLInputElement).value;

                      // Extraer año, mes y día de la cadena de fecha seleccionada
                      const [year, month, day] = selectedDateString.split("-").map(Number);
                      const selectedDateObj = new Date(year, month - 1, day);
                      const formattedDate = selectedDateObj.toLocaleDateString("es-ES", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      });

                      aceptarMantenimiento(key, formattedDate);
                    }
                  });
                  /*savesolicitudKit(key)*/
                }}
              >
                <CheckOutlined />
              </Button>
            </Tooltip>
            {
              <Tooltip title="Rechazar">
                <Button
                  type={"default"}
                  size="small"
                  style={{ marginRight: "3px" }}
                  onClick={async () => {
                    const { value: motivo } = await Swal.fire({
                      title: "Rechazar solicitud",
                      input: "textarea",
                      inputLabel: "¿Está seguro que desea rechazar la solicitud de este mantenimiento?",
                      inputPlaceholder: "Ingrese una descripción",
                      showCancelButton: true,
                      confirmButtonColor: "#8b0505",
                    });

                    if (motivo === null || motivo === undefined) {
                    } else if (!motivo) {
                      swalWarning({
                        text: "Ingrese un motivo",
                      });
                    } else {
                      rechazarMantenimiento(key, motivo);
                      // restablecerLaboratorio(record.key, motivo);
                    }
                    /*setOpenModal(true)
                    setObservacion("")
                    setmensaje(false)
                    setcodSolicitud(key.cod_articulo_reposicion)*/
                  }}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip>
            }
          </>
        );
        /* return (<> <Button type={"primary"} style={{marginRight:'3px'}} onClick={()=>savesolicitudKit(key)} disabled={disabled}><SendOutlined /></Button>
        {key.tipo_articulo==='UF'?<Button type={"primary"} onClick={() => {setVisible(true); setllavesimages(key.imagellaves===null?[]:key.imagellaves)}}><EyeOutlined/></Button>:''}
        </>
        ) */
      }, //<Button icon={<DeleteOutlined />}></Button>,
    },
    {
      title: "IMG",
      dataIndex: "rutaFinal",
      fixed: "right",
      key: "rutaFinal",
      align: "center",
      render: (value, record) => (
        <Button
          icon={<FileImageOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={() => {
            if (!record.imagenes || record.imagenes.length === 0) {
            } else {
              const imageUrls = record.imagenes.map((img) => `${url_alku}allku/api/${img.ruta_imagen}`);
              setSelectedImages(imageUrls);
              setIsModalOpen(true);
            }
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
    },
  ];

  return {
    columns,
    datos,
    columnsTable,
    filteredData,
    activeRowIndex,
    tableContainerRef,
    //rowSelection,
    isModalOpen,
    selectedImages,
    setIsModalOpen,
    handleChange,
  };
};
