import { AutorizacionFundas } from 'pages/contentbodega/AutorizacionFundas'
import { ReporteFundas } from 'pages/contentbodega/ReporteFundas'
import { SolicitudesFundas } from 'pages/contentbodega/SolicitudesFundas'
import { Principal } from 'pages/contentprincipal/Principal'
import { RouteObject } from 'react-router-dom'


export const rutasBodega: RouteObject[] = [
    {
      path: "/bodega",
      element: <Principal />,
    },
    {
      path: "/bodega/solicitud-fundas",
      element: <SolicitudesFundas/>,
    },
    {
      path: "/bodega/reporte-fundas",
      element: <ReporteFundas/>,
    },
    {
      path: "/bodega/autorizacion-fundas",
      element: <AutorizacionFundas/>,
    }
]