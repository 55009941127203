import { Col, Row, DatePicker, Table, Card, Modal, Form } from "antd";
import "moment/locale/es";

import moment from "moment";
import { ButtonExcel } from "components/otros/buttons";
import { useInventarioHandeld } from "hooks/inventarios/historicos/useInventarioHandeld";

export function InventarioHandHeld() {
  const {
    columns,
    columsDatatableDetalle,
    isModalOpen,
    mes,
    setMes,
    detalle,
    form,
    filteredData,
    handleOk,
    handleCancel,
    handleClickDownloadData,
  } = useInventarioHandeld();
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={12} md={14} lg={16} xl={18}>
        <DatePicker
          value={mes}
          picker="month"
          placeholder="Seleccionar mes"
          format="MMMM [del] YYYY"
          onChange={(value) => setMes(value ?? moment())}
          inputReadOnly
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <Table
            columns={columns}
            dataSource={filteredData}
            scroll={{ x: true }}
            size="small"
            pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
          />
        </Card>
      </Col>
      <Modal
        footer={false}
        transitionName=""
        title="Productividad de inventarios"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"90wh"}
      >
        <Form form={form}>
          <Row gutter={[10, 10]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
            <Col xs={24} md={4}>
              <ButtonExcel buttonProps={{ onClick: handleClickDownloadData }}></ButtonExcel>
            </Col>
          </Row>
        </Form>
        <Table
          pagination={false}
          loading={columsDatatableDetalle ? false : true}
          scroll={{ x: 900, y: "55vh" }}
          size="small"
          bordered
          columns={columsDatatableDetalle}
          dataSource={detalle}
        />
      </Modal>
    </Row>
  );
}

export default InventarioHandHeld;
