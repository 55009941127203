import { Tabs, Form, Button, Spin } from "antd";
import { useFormularioActualizacion } from "hooks/rrhh/actualizacion/useFormularioActualizacion";

import { BackTopComponent } from "../../../components/otros/BackTopComponent";
import { useContext, useState } from "react";
import Swal from "sweetalert2";
import { UserContext } from "../../../context/UserContext";
import { SaveOutlined } from "@ant-design/icons";
import { PropFlag } from "./RevisarInformacion";

import moment from "moment";
import { swalWarning } from "config/methods";
moment.suppressDeprecationWarnings = true;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
interface dataLLaves {
  ok: boolean;
  llaves: string[];
}

export const FormulariosPorPasos = ({
  row,
  flag = 0,
  handleOk,
  getEnviadas,
  valuePeriodo,
  enviadas,
  setEnviadas,
}: PropFlag) => {
  let coordenadas = row?.coordenadas;
  let archivos = row?.archivos;

  let {
    helperLlaves,
    validateFiles,
    saveData,
    array11,
    array12,
    array13,
    array2,
    array3,
    handleChange,
    update,
    form,
    items,
    setActiveKey,
    setActiveKey2,
    activeKey,
    ubicacion,
    estado,
    setEstado,
    array5,
    arrayterminos,
    loader,
    setLoader,
    loaderGeneral,
  } = useFormularioActualizacion(
    flag,
    coordenadas,
    archivos,
    handleOk,
    row,
    getEnviadas,
    valuePeriodo,
    enviadas,
    setEnviadas
  );

  const { userData } = useContext(UserContext);
  let id = userData?.id;

  const [buttonDisabled, setButtonDisabled] = useState<string>("none");

  const handleClick = async () => {
    let { hijos, referencias, tipo_vehiculos, tipo_licencias, programas_habilidades, residentes, cursos, idiomas, ...data } =
      form.getFieldsValue();
    setLoader(true);
    data.primaria = JSON.stringify(data.primaria);
    data.secundaria = JSON.stringify(data.secundaria);
    data.tercer = JSON.stringify(data.tercer);
    data.cuarto = JSON.stringify(data.cuarto);

    let coordenadas = JSON.stringify(ubicacion.current);
    data.coordenadas = coordenadas;
    data.direccion = JSON.stringify(data.direccion);
    data.cod_usuario = userData?.id;

    form
      .validateFields()
      .then(async (values) => {
        // const validarCedula = await fetch(
        //   `https://srienlinea.sri.gob.ec/movil-servicios/api/v1.0/deudas/porIdentificacion/${data.cedula}`
        // );

        // if (!validarCedula.ok) {
        //   setLoading(false);
        //   setButtonDisabled("none");
        //   setActiveKey("1");
        //   return Swal.fire("Error", "Por favor verifique, La cédula no es correcta", "error");
        // }

        if (!data.coordenadas || data.coordenadas === "null") {
          setLoader(false);
          setButtonDisabled("none");
          setActiveKey("4");
          return Swal.fire("Error", "Por favor seleccione su ubicación domiciliaria", "error");
        }

        let { cedula_conyugue, cedula_votacion, cedulas_hijos, foto_carnet, hijos_discapacidad, licencia } =
          form.getFieldsValue();

        const newData = validateFiles({
          cedula_conyugue,
          cedula_votacion,
          cedulas_hijos,
          foto_carnet,
          hijos_discapacidad,
          licencia,
        });

        try {
          newData.forEach((el) => {
            const isLt5M = el.size / 1024 / 1024 <= 4;
            if (!isLt5M) {
              Swal.fire("Error", "El archivo excede el tamaño permitido(4MB)", "warning");
              throw new Error("El archivo excede el tamaño permitido(4MB)");
            }
            if (el.archivo !== "foto") {
              if (!el.name.toLowerCase().endsWith(".pdf")) {
                Swal.fire("Error", "El tipo de archivo no es pdf.", "warning");
                throw new Error("Hay error en los archivos pdf");
              }
            }
            if (el.archivo === "foto") {
              if (
                !el.name.toLowerCase().endsWith(".jpg") &&
                !el.name.toLowerCase().endsWith(".jpeg") &&
                !el.name.toLowerCase().endsWith(".png") &&
                !el.name.toLowerCase().endsWith(".webp")
              ) {
                Swal.fire("Error", "El tipo de archivo no es imagen valido.", "warning");
                throw new Error("Hay error en el formato, imagen carnet");
              }
            }
          });

          let res = await helperLlaves(newData);

          if (res.llaves && res.ok) {
            if (res.llaves.length < 0) {
              setLoader(false);

              return Swal.fire("Error", "Ocurrio un error", "warning");
            } else {
              let send = await saveData({
                data,
                hijos,
                llaves: (res as dataLLaves).llaves,
                referencias,
                tipo_vehiculos,
                tipo_licencias,
                programas_habilidades,
                residentes,
                cursos,
                idiomas,
              });
              if (send.status) {
                setLoader(false);
                setEstado(false);
                Swal.fire("OK", "Se guardó su información correctamente", "success");
                form.resetFields();
                localStorage.removeItem("G" + id);
                setEstado(false);
              }
            }
          } else {
            setLoader(false);
            return Swal.fire("Error", "Ocurrio un error", "warning");
          }
        } catch (error: any) {
          setLoader(false);

          return Swal.fire("Error", `Ocurrio un Error,  ${JSON.stringify(error)}`, "warning");
        }
      })
      .catch((error) => {
        setLoader(false);

        for (const el of error.errorFields) {
          if (array3.includes(el.name[0])) {
            setButtonDisabled("none");
            setActiveKey("3");
            swalWarning({ text: "Debes completar toda la información requerida" });
            break; // Detener el bucle después de mostrar el mensaje
          } else if (array2.includes(el.name[0])) {
            setButtonDisabled("none");
            setActiveKey("2");
            swalWarning({ text: "Debes completar toda la información requerida" });
            break;
          } else if (array11.includes(el.name[0])) {
            setButtonDisabled("none");
            setActiveKey("1");
            setActiveKey2("1");
            swalWarning({ text: "Debes completar toda la información requerida" });
            break;
          } else if (array12.includes(el.name[0])) {
            setButtonDisabled("none");
            setActiveKey("1");
            setActiveKey2("2");
            swalWarning({ text: "Debes completar toda la información requerida" });
            break;
          } else if (array13.includes(el.name[0])) {
            setButtonDisabled("none");
            setActiveKey("1");
            setActiveKey2("3");
            swalWarning({ text: "Debes completar toda la información requerida" });
            break;
          } else if (["hijos"].includes(el.name[0])) {
            setButtonDisabled("none");
            setActiveKey("1");
            setActiveKey2("4");
            swalWarning({ text: "Debes completar toda la información requerida" });
            break;
          } else if (array5.includes(el.name[0])) {
            setActiveKey2("5");
            swalWarning({ text: "Debes completar toda la información requerida" });
            break;
          } else if (arrayterminos.includes(el.name[0])) {
            setActiveKey2("5");
            swalWarning({
              text: "Para poder guardar es necesario que confirmes la recepción de los reglamentos, y que aceptes el uso de medios electrónicos y el tratamiento de tus datos personales.",
            });
            break;
          }
        }
      });
  };

  const handleChangeTab = (e: string) => {
    setButtonDisabled("none");
    if (e === "5") {
      setButtonDisabled("block");
    }

    setActiveKey(e);
  };

  return (
    <>
      {loaderGeneral ? (
        <div className='center_spin'>
          <Spin />
        </div>
      ) : (
        <div style={{ margin: "12px", backgroundColor: "white" }}>
          {estado && flag === 0 && (update === 1 || update === 3 || update === 4) ? (
            update === 1 ? (
              <p>
                Usted ya completó este proceso y se encuentra en estado de revisión. Se le notificará por correo electrónico
                si hay alguna novedad.
              </p>
            ) : update === 3 ? (
              <p>Usted ha completado el proceso de corrección y su información está siendo revisada nuevamente.</p>
            ) : update === 4 ? (
              <p>Usted ya completó este proceso y su información fue procesada con éxito.</p>
            ) : null
          ) : (
            <Form
              {...layout}
              layout='horizontal'
              style={{ padding: "5px", width: "99%" }}
              form={form}
              className='formulario'
              onValuesChange={handleChange}
            >
              <Tabs {...items} onChange={handleChangeTab} activeKey={activeKey} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {flag === 0 && update !== 2 && (
                  <Button
                    loading={loader}
                    onClick={handleClick}
                    type='primary'
                    style={{
                      display: `${buttonDisabled}`,
                      width: "100%",
                    }}
                    icon={<SaveOutlined className='iconbutton' />}
                  >
                    ENVIAR TODO
                  </Button>
                )}
              </div>
            </Form>
          )}
        </div>
      )}

      <BackTopComponent />
    </>
  );
};
