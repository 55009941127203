import type { InputRef } from "antd";
import { Form, Input, Table } from "antd";
import type { FormInstance } from "antd/es/form";
import React, { useContext, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { ColumnTypes, DataType, usePromocionMc } from "../../hooks/politicas/usePromocionPmc";
import Parrafos from "../../components/politicas/Parrafos";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  cantidad: number;
  to: string;
  promocion: number;
  descuento: number;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      for (let clave in values) {
        values[`${clave}`] = parseFloat(values[clave]);
        if (isNaN(values[`${clave}`])) {
          return Swal.fire("Error!", "Solo Valores Numericos", "warning");
        }
      }
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} es obligatorio.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className='editable-cell-value-wrap' style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export const PromocionMC: React.FC = () => {
  const [dataSource, setDataSource] = useState<DataType[]>([]);

  let { getPromocionPmc, putDescuentosPmc, defaultColumns } = usePromocionMc();

  useEffect(() => {
    const getDescuentos = async () => {
      let res = await getPromocionPmc();
      res.data.map((el: DataType) => {
        el.to = "+";
        el.divi = el.key !== 1 ? "/" : "";
        return el;
      });
      setDataSource(res.data);
    };
    getDescuentos();
  }, [getPromocionPmc]);

  const handleSave = async (row: DataType) => {
    const newData = [...dataSource];
    let res = await putDescuentosPmc(row);
    Swal.fire("OK", res.data, "success");
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <Parrafos
        title={`• De acuerdo al tipo de bonificación se dividirá la rentabilidad para la columna indicada que dará como resultado el descuento al público en los producto del plan PMC. `}
      />
      <div style={{ display: "flex", justifyContent: "center", height: "83vh" }}>
        <Table
          pagination={false}
          components={components}
          size='small'
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource}
          columns={columns as ColumnTypes}
          style={{
            padding: "10px",
            width: "350px",
          }}
        />
      </div>
    </div>
  );
};
