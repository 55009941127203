import { url } from 'config/constants'
import { optionsGet, optionsPost, swalSuccess, swalWarning } from 'config/methods'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'

export function useRegistroTareasCompleta() {

    const [databodega, setdatabodega]=useState([{label:'seleccione una Farmacia', value:''}])
    const [dataSolicitante, setdataSolicitante]=useState([{label:'Seleccione un Solicitante', value:''}])
    const [dataIncidente, setdataIncidente]=useState([{label:'Seleccione un Tipo de Incidente', value:''}])
    const [dataTareahis, setdataTareahis] = useState<{ descripcion: string; tipo_atencion: string, key: React.Key}[]>([]);
    const [Cod_Bodega, setCod_Bodega]=useState("")
    const [cod_solicitante, setcod_solicitante]=useState("")
    const [CodIncidente, setCodIncidente]=useState("")
    const [fecha_inicio, setfecha_inicio]=useState<Moment | null>(null)
    const [fecha_fin, setfecha_fin]=useState<Moment | null>(null)
    const [descripcion, setdescripcion]=useState("")
    const [tipo_atencion, settipo_atención]=useState("")
    const [descripcion_his, setdescripcion_his]=useState("")

    const getbodegas = async () => {
        try {
          const res = await fetch(`${url}fapifsg-pr/sistemas/getFarmacias`, optionsGet()).then((data) => data.json())
          //let respuesta=res.data;
          let newget = [{ value: "", label: "Seleccione una Farmacia" }]
          res.forEach((item: any) => {
            let add = {
              value: item.Cod_Bodega,
              label: item.Descripcion,
            }
            newget = [...newget, add]
          })
          setdatabodega(newget)
        } catch (error) {
          console.log(error)
        }
      }

      const onchangeBodega = async (value: string) => {
        setCod_Bodega(value)
        try {
          const res = await fetch(`${url}fapifsg-pr/sistemas/getUsuarioBodega`, optionsPost({ cod_bodega: value })).then(
            (data) => data.json()
          )
          //let respuesta=res.data;
          let newget = [{ value: "", label: "Seleccione un Solicitante" }]
          res.forEach((item: any) => {
            let add = {
              value: item.Cod_Usuario,
              label: item.Nombres,
            }
            newget = [...newget, add]
          })
          setdataSolicitante(newget)
        } catch (error) {
          console.log(error)
        }
      }

      const tiposIncidentes = async () => {
        try {
          const res = await fetch(`${url}fapifsg-pr/sistemas/getTipoIncidentes`, optionsGet()).then((data) => data.json())
          //let respuesta=res.data;
          let newget = [{ value: "", label: "Seleccione un Tipo de Incidente" }]
          res.forEach((item: any) => {
            let add = {
              value: item.Cod_Tipo_Incidente,
              label: item.Descripcion,
            }
            newget = [...newget, add]
          })
          setdataIncidente(newget)
        } catch (error) {
          console.log(error)
        }
      }


      const onchangeSolicitante = (value: string) => {
        setcod_solicitante(value)
      }
    
      const onchangeTipoIncidente = (value: string) => {
        setCodIncidente(value)
      }

      const onchangeTipoatencion = (value: string) => {
        settipo_atención(value)
      }
    

      useEffect(()=>{
        getbodegas()
        tiposIncidentes()
      },[])

      const onchagefechainicio = (date: any, dates: any) => {
        setfecha_inicio(date ?? moment())
      }

      const onchagefechafin = (date: any, dates: any) => {
        setfecha_fin(date ?? moment())
      }

      const onchangeDescripcion = (event: any) => {
        let newValue = event.target.value
        setdescripcion(newValue)
      }

      const onchangeDescripcionHis = (event: any) => {
        let newValue = event.target.value
        setdescripcion_his(newValue)
      }

      const btn_Agregar=()=>{
        try {
            if(descripcion_his===''|| descripcion_his===null || tipo_atencion==='' || tipo_atencion===null){
                swalWarning({text:'Faltan Datos para Agregar al Historial'})
                return
            }
            let add = {
                key: Number(dataTareahis.length>0?dataTareahis[dataTareahis.length-1].key:0)+1,
                descripcion: descripcion_his,
                tipo_atencion: tipo_atencion,
            }

            setdataTareahis([...dataTareahis, add])
            setdescripcion_his("")
            settipo_atención("")
            swalSuccess({text:'Se agregó con éxito'})
        } catch (error) {
            console.log(error)
        }  
      }

      const disableFutureDates = (current: any) => {
        return current && current > moment().endOf('day');
      };

      const disableFutureTimes = (selectedDate: any) => {
        if (!selectedDate) {
          return {};
        }
        const now = moment();
        if (selectedDate.isSame(now, 'day')) {
          return {
            disabledHours: () => Array.from({ length: 24 }, (_, i) => i).filter(hour => hour > now.hour()),
            disabledMinutes: (selectedHour: number) => {
              if (selectedHour === now.hour()) {
                // Si es la hora actual, bloquear minutos mayores al minuto actual
                return Array.from({ length: 60 }, (_, i) => i).filter(min => min > now.minute());
              }
              // Si es una hora diferente, no bloquear minutos
              return [];
            },
          };
        }
      
        return {};
      };

      const btn_Guardar=async()=>{
        try {
          if(fecha_fin===null || fecha_inicio===null || Cod_Bodega==='' || Cod_Bodega===null || CodIncidente===''|| CodIncidente===null ||cod_solicitante==='' || cod_solicitante===null
            || descripcion==='' || descripcion===null){
            swalWarning({text:'Faltan datos para Guardar'})
            return
          }

          const diferencia = fecha_fin.diff(fecha_inicio);

          if (diferencia<0) {
            swalWarning({text:'La fecha fin no puede ser menor a la fecha de inicio'})
            return
          }

          if(dataTareahis.length<=0){
            swalWarning({text: "Falta agregar información al Historial"})
            return
          }
          const res = await fetch(`${url}fapifsg-pr/sistemas/SaveTareaCompleta`, optionsPost({ fecha_inicio: fecha_inicio, cod_tipo_incidente:CodIncidente, 
                                                                                               cod_usuario_solicita:cod_solicitante, fecha_fin: fecha_fin, cod_bodega: Cod_Bodega, descripcion:descripcion,
                                                                                                estado:'FINALIZADO', tareaSSoporteHIS: dataTareahis}))
          
          if(res.status===200){
            swalSuccess({text:'Se guardo exitosamente la tarea'})
            setfecha_inicio(null)
            setfecha_fin(null)
            setcod_solicitante('')
            setCodIncidente('')
            setCod_Bodega('')
            setdescripcion('')
            setdataTareahis([])
          }          
        } catch (error) {
          console.log(error)
        }
      }

      const btn_Delete_his=(key:any)=>{
        const data= dataTareahis.filter((item)=>item.key!== key)
        setdataTareahis(data)
      }

    return{
    databodega,
    dataSolicitante,
    dataIncidente,
    onchangeBodega,
    Cod_Bodega,
    cod_solicitante,
    CodIncidente,
    onchangeTipoIncidente,
    onchangeSolicitante,
    onchagefechainicio,
    onchagefechafin,
    fecha_inicio,
    fecha_fin, 
    descripcion,
    onchangeDescripcion,
    tipo_atencion,
    onchangeTipoatencion,
    onchangeDescripcionHis,
    descripcion_his,
    dataTareahis,
    btn_Agregar,
    disableFutureDates,
    disableFutureTimes,
    btn_Guardar,
    btn_Delete_his
  }
}
