import React from "react"
import { Col, Row, Typography, Select, Modal, Input} from "antd"
import Button from "antd/es/button"
import { useLayout } from "hooks/otros/useLayout"
import { PlusOutlined, SaveOutlined } from "@ant-design/icons"
import TransferCrearKit from "components/rrhh/componentsRenovacionUniformes/TransferCrearKit"
import { useCrearKit } from "hooks/rrhh/renovacionuniforme/useCrearKit"

const { Title } = Typography

const breadcrumbs = [
  { Label: "Recursos Humanos", Url: "/rrhh" },
  { Label: "Renovación de Uniformes", Url: "/rrhh/renovacion-uniformes" },
  { Label: "Crear Kit" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export function CrearKit() {
  useLayout(breadcrumbs, openKeys, selectKeySider)
  const {
    renderFooter,
    onChange,
    handleChange,
    showModal,
    showModalKit,
    handleCancel,
    handleCancelKit,
    targetKeys,
    rightTableColumns,
    isModalOpen,
    isModalOpenKit,
    leftTableColumns,
    value,
    codTipoUniforme,
    setValue,
    saveTipoUniforme,
    valueKit, 
    setValueKit,
    saveNameKit,
    itemselect,
    tableData,
    TipoUniformemsg,
    Kitmsg,
    valor,
    handleInputValor
  } = useCrearKit()
  //con0sole.log(itemselect)
  const onSearch = (value: string) => {
  //console.log('search:', value);
};
  //console.log("use"+mockData)
  return (
    <>
      <Row wrap style={{ padding: "20px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
        <Col span={24}>
          <TransferCrearKit
            titles={[
              <Row justify="end">
                <Col sm={24} md={20} lg={20}>
                  <Title level={3} >Tipo de Uniforme</Title>
                </Col>
                <Col sm={24} md={4} lg={4} >
                  <Button type="primary" icon={<PlusOutlined />} onClick={showModal}></Button>
                </Col>
              </Row>,
              <Row justify="end">
                <Col span={8}>
                  <Title level={3}>Kit</Title>
                </Col>
                <Col sm={24} md={12} lg={12}>
                  <Select
                    showSearch
                    placeholder="Seleccione un Kit"
                    defaultValue={""}
                   
                    style={{ textAlign: "start", width: "100%", marginLeft: "10px" }}
                    onChange={handleChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={itemselect}
                  />
                </Col>
                <Col span={4}>
                  <Button type="primary" icon={<PlusOutlined />} onClick={showModalKit}></Button>
                </Col>
              </Row>,
            ]}
            dataSource={tableData}
            targetKeys={targetKeys}
            //disabled={disabled}
            showSearch
            onChange={onChange}
            filterOption={(inputValue, item) =>
              item.title!.indexOf(inputValue) !== -1 //|| item.tag.indexOf(inputValue) !== -1
            }
            leftColumns={leftTableColumns}
            rightColumns={rightTableColumns}
            listStyle={{
              width: "100%",
              height: 450,
            }}
            footer={renderFooter}
          />
        </Col>
      </Row>

      <Modal
        title="Crear Tipo de Uniforme"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cerrar
          </Button>,
          <Button type="primary" key="submit" icon={<SaveOutlined />} onClickCapture={saveTipoUniforme}>
            Guardar
          </Button>,
        ]}
      >
        <input defaultValue={codTipoUniforme} style={{display: "none"}}/>
        <Row>
          <Col xs={12} sm={12} md={12}><p>Nombre</p></Col>
          <Col xs={12} sm={12} md={12}><p>Valor</p></Col>
        </Row>
        <Row >
          <Col xs={12} sm={12} md={12}>   <Input style={{marginRight:'10px'}} placeholder="Nombre Tipo de Uniforme" value={value}
        onChange={(e) => setValue(e.target.value)} /></Col>
          <Col xs={12} sm={12} md={12}>    <Input style={{marginLeft:'10px', textAlign: 'end'}} placeholder="Valor en dólares" value={valor}
        onChange={(e) => handleInputValor(e)} /></Col>
        </Row>
       {TipoUniformemsg&&<label style={{color: 'red',fontSize: '10px'}}>*Ya exite el nombre del Tipo Uniforme. Por favor agregue otro</label>}
      </Modal>
      <Modal
        title="Crear Kit"
        open={isModalOpenKit}
        onCancel={handleCancelKit}
        footer={[
          <Button key="back" onClick={handleCancelKit}>
            Cerrar
          </Button>,
          <Button type="primary" key="submit" onClick={saveNameKit} icon={<SaveOutlined/>}>
            Guardar
          </Button>,
        ]}
      >
        <p>Nombre</p>
        <Input placeholder="Nombre del Kit"value={valueKit}
        onChange={(e) => setValueKit(e.target.value)} />
       {Kitmsg &&<label style={{color: 'red',fontSize: '10px'}}>*Ya exite el nombre del Kit. Por favor agregue otro</label>}
      </Modal>
    </>
  )
}
