import { createContext, useState } from "react";

interface IcContextType<T> {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  headTable: T;
  setHeadTable: React.Dispatch<React.SetStateAction<T>>;
  columnTable: T;
  setColumnTable: React.Dispatch<React.SetStateAction<T>>;
  dataChart: string[];
  setDataChart: React.Dispatch<React.SetStateAction<string[]>>;
  /**DAta de la chart 2  */
  dataChartLast: string[];
  setDataChartLast: React.Dispatch<React.SetStateAction<string[]>>;

  selectedRow: string[];
  setSelectedRow: React.Dispatch<React.SetStateAction<string[]>>;
  /**Estados del YDT */
  headTableYdt: T;
  setHeadTableYdt: React.Dispatch<React.SetStateAction<T>>;
  columnTableYdt: T;
  setColumnTableYdt: React.Dispatch<React.SetStateAction<T>>;
  /**Estados del Motivos y tiempos */
  columnTableMotivos: T;
  setColumnTableMotivos: React.Dispatch<React.SetStateAction<T>>;
  /**Estados del tiempos */
  columnTableTiempos: T;
  setColumnTableTiempos: React.Dispatch<React.SetStateAction<T>>;
  /*Datos a graficar principal*/
  graph: string[];
  setGraph: React.Dispatch<React.SetStateAction<string[]>>;
  handleChangeX1: (values: string[]) => void;
  /** Opciones del modal grafica 2 */
  graphX2: string[];
  setGraphX2: React.Dispatch<React.SetStateAction<string[]>>;
  showModalX2: boolean;
  setShowModalX2: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeX2: (values: string[]) => void;
}

interface ContextICProps<T> {
  children: JSX.Element | Array<JSX.Element>;
  value?: IcContextType<T>;
}

export const RotacionEmpleadoContext = createContext<IcContextType<any>>({} as IcContextType<any>);

export function ContextEmpleado<T>({ children }: ContextICProps<T>) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalX2, setShowModalX2] = useState<boolean>(false);
  const [headTable, setHeadTable] = useState<T | undefined>(undefined);
  const [columnTable, setColumnTable] = useState<T | undefined>(undefined);

  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  /** Estados del del grafico 2 */
  const [dataChartLast, setDataChartLast] = useState<string[]>([]);
  /** Estados del ydt */
  const [headTableYdt, setHeadTableYdt] = useState<T | undefined>(undefined);
  const [columnTableYdt, setColumnTableYdt] = useState<T | undefined>(undefined);
  /** Estados del motivos */
  const [columnTableMotivos, setColumnTableMotivos] = useState<T | undefined>(undefined);
  /** Estados tiempo salidas */
  const [columnTableTiempos, setColumnTableTiempos] = useState<T | undefined>(undefined);
  /*Datos a graficar principal*/
  const [graph, setGraph] = useState<string[]>([]);
  const [dataChart, setDataChart] = useState<string[]>([]);
  /*Datos a graficar secundaria*/
  const [graphX2, setGraphX2] = useState<string[]>([]);

  const handleChangeX1 = (value: string[]) => {
    setGraph(value);
  };

  const handleChangeX2 = (value: string[]) => {
    setGraphX2(value);
  };

  return (
    <RotacionEmpleadoContext.Provider
      value={{
        showModal,
        setShowModal,
        headTable,
        setHeadTable,
        columnTable,
        setColumnTable,
        dataChart,
        setDataChart,
        selectedRow,
        setSelectedRow,
        headTableYdt,
        setHeadTableYdt,
        columnTableYdt,
        setColumnTableYdt,
        columnTableMotivos,
        setColumnTableMotivos,
        columnTableTiempos,
        setColumnTableTiempos,
        dataChartLast,
        setDataChartLast,
        graph,
        setGraph,
        handleChangeX1,
        showModalX2,
        setShowModalX2,
        handleChangeX2,
        graphX2,
        setGraphX2,
      }}
    >
      {children}
    </RotacionEmpleadoContext.Provider>
  );
}
