import { useCallback } from "react"
import Swal from "sweetalert2"

import type { SweetAlertOptions } from "sweetalert2"

const timer = 2000

const showConfirmButton = false

const optionsSuccess: SweetAlertOptions = {
  title: 'Datos almacenados',
  icon: 'success',
  timer,
  showConfirmButton
}

const optionsError: SweetAlertOptions = {
  title: '¡Error!',
  icon: 'error',
  timer,
  showConfirmButton
}

const optionsQuestion: SweetAlertOptions = {
  icon: 'question',
  showCancelButton: true,
  confirmButtonColor: 'var(--primary)',
  confirmButtonText: 'Si',
  cancelButtonText: 'No'
}

export function useSwal() {
  
  const swalSuccess = useCallback((options: SweetAlertOptions) => Swal.fire({ ...optionsSuccess, ...options }),[])

  const swalError = useCallback((options: SweetAlertOptions) => Swal.fire({ ...optionsError, ...options }),[])

  const swalQuestion = useCallback((options: SweetAlertOptions) => Swal.fire({ ...options,...optionsQuestion }),[])

  return { swalSuccess, swalError, swalQuestion }

}