import { ModalForm } from "components/otros/ModalForm";
import { ButtonRightWithList } from "components/otros/ButtonRightWithList";

import { useContext } from "react";

import { ModalFormContext } from "context/ModalFormContext";
import { ListContext } from "context/ButtonRightwithListContext";

export function ButtonRightWithListWithModalForm() {
  const { updateModalProps, modalProps, onCancel } = useContext(ModalFormContext);

  const { textButton } = useContext(ListContext);

  const handleOnClick = () => updateModalProps((state) => ({ ...state, open: true }));

  const handleOnCancel = () => {
    onCancel && onCancel();
    if (modalProps.okText !== "Modificar") {
      updateModalProps((state) => ({ ...state, open: false }));
    } else {
      updateModalProps({ title: `Agregar ${textButton}`, okText: "Agregar", open: false });
    }
  };

  return (
    <>
      <ButtonRightWithList onClick={handleOnClick} />
      <ModalForm
        onCancel={handleOnCancel}
        formProps={{
          labelCol: { xs: { span: 24 }, sm: { span: 6 } },
          wrapperCol: { xs: { span: 24 }, sm: { span: 18 } },
        }}
      />
    </>
  );
}
