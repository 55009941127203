import { type ButtonProps, Collapse, Input, InputNumber, Statistic } from "antd"
import { type ColumnsTableFormListType, TableFormList } from "components/otros/table-form-list"
import { AddButtons } from "./add-buttons"

import {
  DescriptionsListformItems,
  type DescriptionsListformItemsProps,
} from "components/otros/descriptions-list-form-items"
import { useContext } from "react"
import { FormPagoContext, type GastosFijos } from "context/franquiciados/form-pago-context"

export type GastosTableProps = {
  numeroBodegas: number
  handleChangeValor(newValue: number, itemIndex: number): void
  handleChangeNumeroBodegas(nBodegas: number, itemIndex: number): void
  handleAddGasto: ButtonProps["onClick"]
  handleAddGastoInBlank: ButtonProps["onClick"]
  loading: boolean
  onRemoveItemTable(): void
  // indexSaldoEnContra?: number
}

export function GastosTable({
  numeroBodegas,
  handleChangeValor,
  handleChangeNumeroBodegas,
  handleAddGasto,
  handleAddGastoInBlank,
  loading,
  onRemoveItemTable,
}: // indexSaldoEnContra,
GastosTableProps) {
  const { form } = useContext(FormPagoContext)

  const columnsGastosFijos: ColumnsTableFormListType<GastosFijos>[] = [
    {
      title: "Gasto",
      name: "gasto",
      rules: [{ required: true, message: "Ingrese el nombre del gasto" }],
      render: (_, __, record) => {
        return record?.cod_gasto === -1 ? (
          <Input.TextArea autoSize size="small" style={{ maxWidth: "250px", minWidth: "100px" }} bordered={false} readOnly />
        ) : (
          <Input.TextArea autoSize size="small" style={{ maxWidth: "250px", minWidth: "100px" }} />
        )
      },
    },
    {
      title: "Valor",
      name: "valor",
      rules: [{ required: true, message: "Ingrese el valor del gasto" }],
      render: (index, _, record) =>
        record?.cod_gasto === -1 ? (
          <Statistic valueStyle={{ fontSize: "inherit" }} precision={2} />
        ) : (
          <InputNumber
            onChange={value => handleChangeValor(value ?? 0, index)}
            min={0}
            step={0.01}
            size="small"
            controls={false}
            placeholder="0.00"
          />
        ),
    },
    {
      title: "# bodegas",
      name: "n_bodegas",
      render:
        numeroBodegas === 1
          ? undefined
          : (index, _, record) =>
              record?.cod_gasto === -1 ? (
                <Statistic valueStyle={{ fontSize: "inherit" }} />
              ) : (
                <InputNumber
                  onChange={value => handleChangeNumeroBodegas(value ?? 0, index)}
                  min={1}
                  max={numeroBodegas}
                  size="small"
                />
              ),
    },
    {
      title: "Total",
      name: "total",
      render: () => <Statistic valueStyle={{ fontSize: "inherit" }} precision={2} />,
    },
  ]

  const itemsTotalGastos: DescriptionsListformItemsProps["items"] = [
    {
      label: "Total",
      itemName: "total_gastos_fijos",
      render: () => (
        <Statistic
          valueStyle={{
            fontSize: "inherit",
            textAlign: "right",
            fontWeight: "bold",
          }}
          precision={2}
        />
      ),
    },
  ]

  return (
    <Collapse defaultActiveKey={["1"]} style={{ marginBottom: "16px", padding: 0 }}>
      <Collapse.Panel key={"1"} header="Gastos">
        <TableFormList
          columns={columnsGastosFijos}
          nameList="gastos_fijos"
          loading={loading}
          canRemove
          onRemove={onRemoveItemTable}
          disabledRemove={record => record?.cod_gasto === -1}
          form={form}
        />
        <DescriptionsListformItems items={itemsTotalGastos} loading={loading} />
        <AddButtons handleAdd={handleAddGasto} handleAddInBlank={handleAddGastoInBlank} />
      </Collapse.Panel>
    </Collapse>
  )
}
