import { usePagosReporte } from "hooks/Franquiciados/usePagosReporte"

import { Col, DatePicker, Modal, Row, Table } from "antd"
import { ButtonWithQuestion } from "components/otros/ButtonWithQuestion"
import { ReportePDF } from "components/franquiciados/reporte/reporte-pdf"
import { PDFViewer } from "@react-pdf/renderer"

import { RiMailSendLine } from "react-icons/ri"

import moment from "moment"
import "moment/locale/es"

export function PagoReportes() {
  const {
    enviarCorreosMasivo,
    mes,
    setMes,
    columns,
    pagos,
    openModal,
    setOpenModal,
    footerDownload,
    dataPago,
    pagoSelected,
  } = usePagosReporte()

  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={12} md={14} lg={16} xl={18}>
        <DatePicker
          value={mes}
          picker="month"
          placeholder="Seleccionar mes"
          format="MMMM [del] YYYY"
          onChange={value => setMes(value ?? moment().subtract(1, "months"))}
          inputReadOnly
        />
      </Col>
      <Col xs={24} sm={12} md={10} lg={8} xl={6}>
        <ButtonWithQuestion
          buttonProps={{ block: true, type: "primary", icon: <RiMailSendLine className="iconbutton" /> }}
          popConfirmProps={{ title: "¿Desea enviar el correo a todos los franquiciados?" }}
          swalProps={{ title: "Envío masivo", text: "Se enviará el correo a todos los franquiciados" }}
          accion={enviarCorreosMasivo}
        >
          Enviar correos masivo
        </ButtonWithQuestion>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Table
          columns={columns}
          dataSource={pagos}
          scroll={{ x: true }}
          size="small"
          pagination={{ pageSize: 5, hideOnSinglePage: true, position: ["bottomCenter"] }}
        />
        <Modal
          open={openModal}
          onCancel={() => setOpenModal(false)}
          width={1000}
          title="Liquidación franquiciados"
          transitionName=""
          destroyOnClose
          style={{ top: "10px" }}
          footer={footerDownload(
            () => setOpenModal(false),
            <ReportePDF
              dataPago={dataPago.current.find(pago => pago.cod_pago === pagoSelected)}
              mes={mes}
              franquiciado={pagos?.find(pago => pago.key === pagoSelected)?.franquiciado ?? ""}
              codigo={pagos?.find(pago => pago.key === pagoSelected)?.cod_franquiciado ?? 0}
            />,
            `liquidación mes de ${mes.format("MMMM [del] YYYY")}.pdf`
          )}
        >
          <PDFViewer style={{ width: "100%", height: "70vh" }}>
            <ReportePDF
              dataPago={dataPago.current.find(pago => pago.cod_pago === pagoSelected)}
              mes={mes}
              franquiciado={pagos?.find(pago => pago.key === pagoSelected)?.franquiciado ?? ""}
              codigo={pagos?.find(pago => pago.key === pagoSelected)?.cod_franquiciado ?? 0}
            />
          </PDFViewer>
        </Modal>
      </Col>
    </Row>
  )
}

export default PagoReportes
