import { Key, useCallback, useContext, useEffect, useState } from "react";
import { url } from "../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";

import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { message, notification, TableColumnsType, Upload } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import type { RcFile, UploadFile } from "antd/lib/upload";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { ButtonCheckValido, ButtonUploadTableImage } from "components/otros/buttonUploadImage";
import Swal from "sweetalert2";
import { UserContext } from "context/UserContext";

type ActasReporte = {
  fecha: string;
  cliente: string;
  cedula: string;
  valor: number;
  bodega: string;
  codBodega: number;
  archivo?: UploadFile;
  llave?: string;
  key: React.Key;
};

const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "CxC pendientes" }];

const openKeys = [breadcrumbs[0].Label];
export function useCuentasPendientes() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);

  const [datos, setDatos] = useState<ActasReporte[]>([]);
  const [searchText] = useState("");
  const [valueselect, setvalueselect] = useState("1");
  const [filteredData, setFilteredData] = useState<ActasReporte[]>([]);
  const { getColumnSearchPropsNew } = useSearchTableNew<ActasReporte>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]); // Aquí especificamos que es un array de `Key`

  const { userData } = useContext(UserContext);
  const [estadouser, setuser] = useState<boolean>(false);
  const [estadoGuardar, setBottonGuardar] = useState<boolean>(false);
  const [estadoBoton, setBoton] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  let { optionsPost, getItem } = useLocalStorage();
  const { isError } = useVerifyResponse();

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSave = async () => {
    try {
      setBottonGuardar(true);
      let filtro = datos.filter(({ archivo }) => Boolean(archivo));
      let llave: string;
      for (let i = 0; i < filtro.length; i++) {
        if (filtro[i].archivo) {
          llave = await sendFile(filtro[i].archivo as RcFile, "actasConsiliatorias");
          if (!llave) throw new Error("No se pudo obtener la llave");
          // filtro = filtro.map((datosllave) => ({ ...datosllave, llave,archivo: undefined }));
          filtro[i].llave = llave;
          filtro[i].archivo = undefined;
        }
      }
      const isInvalidKey = filtro.some(({ llave }) => llave === "" || llave === undefined);
      if (isInvalidKey) {
        // Enviar a eliminar todo lo que tenga 'llave' vacía o indefinida
        for (let elemento of filtro) {
          if (elemento.llave === "" || elemento.llave === undefined) {
            await fetch(`${url}apiallku-private-eliminar/api/deletefromapiexterna`, optionsPost({ llave: elemento.llave }));
          }
        }
        throw new Error("Al menos un elemento tiene 'llave' vacía o indefinida");
      }
      const respuesta = await fetch(
        url + "apifsg-pr/inventarios/autorizar_actas_conciliatorias",
        optionsPost({ filtro,valueselect })
      ).then((datos) => datos.json());
      if (respuesta.msg === "ok") {
        setDatos([]);
        await getBodegas();
        notification.success({
          message: "OK",
          description: `Datos procesados correctamente`,
          placement: "bottomRight",
        });
      } else {
        for (let elemento of filtro) {
          if (elemento.llave === "" || elemento.llave === undefined) {
            await fetch(`${url}apiallku-private-eliminar/api/deletefromapiexterna`, optionsPost({ llave: elemento.llave }));
          }
        }
        notification.error({
          message: "Error",
          description: `Ocurrio un error,comunicar al departamento de sistemas, ${respuesta}`,
          placement: "bottomRight",
        });
      }
    } catch (error: any) {
      Swal.fire("Error", error.toString(), "error");
    }finally{
      setBottonGuardar(false);
    }
  };
  const sendFile = async (file: File, carpeta: string) => {
    const body = new FormData();
    try {
      body.append("uploadfile", file);
      body.append("archivo", carpeta);

      const requestSettings = {
        body,
        method: "POST",
        headers: {
          Accept: "application/json",
          "access-token": getItem("token") ?? "",
        },
      };

      let data = await fetch(`${url}apiallku-private/api/fromapiexterna2`, requestSettings).then((data) => data.json());

      return data.llave;
    } catch (error) {
      return error;
    }
  };

  const handleRemoveImage = async (key: Key) => {
    try {
      setDatos((state) =>
        state.map((dato) => {
          if (dato.key === key) {
            return { ...dato, archivo: undefined, llave: undefined };
          }
          return dato;
        })
      );
      // No es necesario actualizar selectedRowKeys aquí si se maneja en rowSelection.onChange
      return true;
    } catch (e) {
      return false;
    }
  };

  const getBodegas = useCallback(async () => {
    const shouldHideUpdateColumn = userData?.id === 7;
    setuser(shouldHideUpdateColumn);
    setBoton(false);
    setDatos([]);
    try {
      const res = await fetch(url + "apifsg-pr/inventarios/obtener_actas_pendientes", optionsPost({ valueselect }));
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: ActasReporte[] = respuesta.data;
        if (respuestas.length > 0) {
          setDatos(
            respuestas.map(({ key, fecha, cliente, cedula, valor, bodega, codBodega }) => ({
              key,
              fecha,
              cliente,
              cedula,
              valor,
              bodega,
              codBodega,
            }))
          );
        } else {
          setDatos([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsPost, isError, userData?.id, valueselect]);

  useEffect(() => {
    const newSelectedRowKeys = datos
      .filter((dato) => dato.archivo) // Asumiendo que `archivo` indica si la fila debe estar seleccionada
      .map((dato) => dato.key); // Extraemos las claves de las filas que deben estar seleccionadas

    setSelectedRowKeys(newSelectedRowKeys);
  }, [datos]);
  const handleChange = (value: string) => {
    //console.log(value)
    
    setvalueselect(value);
  };
  useEffect(() => {
    getBodegas();
  }, [getBodegas]);

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos);
    } else {
      const filtered = datos.filter((item) => item.bodega.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  const rowSelection = {
    selectedRowKeys, // Usamos el estado directamente
    onChange: (selectedKeys: Key[], selectedRows: typeof datos) => {
      // Filtrar las claves para asegurarse de que solo se puedan deseleccionar
      const isSelectingNewRow = selectedKeys.some((key) => !selectedRowKeys.includes(key));
      if (!isSelectingNewRow) {
        const newSelectedRowKeys = selectedKeys.filter((key) => selectedRowKeys.includes(key));

        setSelectedRowKeys(newSelectedRowKeys);

        const deselectedKeys = selectedRowKeys.filter((key) => !selectedKeys.includes(key));
        deselectedKeys.forEach((key) => {
          const id = datos.find((dato) => dato.key === key)?.key;
          if (id !== undefined) handleRemoveImage(id);
        });
        const anySelectedFile = datos.some((dato) => selectedKeys.includes(dato.key) && (dato.archivo || dato.llave));
        setBoton(anySelectedFile);
      }
    },
  };

  const beforeUpload = (file: RcFile, id: number) => {
    setDatos((state) =>
      state.map((s) => {
        if (s.key === id) {
          setBoton(true);
          return { ...s, archivo: file };
        } else {
          return s;
        }
      })
    );
  };

  const columns: TableColumnsType<ActasReporte> = [
    {
      title: "Farmacia",
      dataIndex: "bodega",
      key: "bodega",
      ...getColumnSearchPropsNew("bodega"),
    },

    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
      sorter: (a, b) => moment(a.fecha).unix() - moment(b.fecha).unix(),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Dependiente",
      dataIndex: "cliente",
      key: "cliente",
      ...getColumnSearchPropsNew("cliente"),
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      align:"right",
      render: (valor) => `$${valor}`,
    },
    {
      title: "Archivo",
      dataIndex: "key",
      width: "30px",
      fixed: "right",
      key: "key",
      align: "center",
      render: (value, record) => {
        // Condición para verificar si hay un archivo cargado
        const isFileUploaded = record.archivo !== undefined;

        return (
          <Upload
            accept=".jpg,.jpeg,.png,.webp,.pdf"
            maxCount={1}
            name={`file-image-${record.key}`}
            showUploadList={false}
            beforeUpload={(file) => {
              if (file.size >= 4180000) {
                message.error("El archivo excede el tamaño máximo permitido. Debe ser menor a 4MB");
                return Upload.LIST_IGNORE;
              }
              const tipo = file.type.toLocaleLowerCase();
              if (
                tipo === "image/jpg" ||
                tipo === "image/png" ||
                tipo === "image/jpeg" ||
                tipo === "image/webp" ||
                tipo === "application/pdf"
              ) {
                beforeUpload(file, value);
                return false;
              }
              message.error("Formato de archivo no admitido");
              return Upload.LIST_IGNORE;
            }}
            fileList={
              datos.find((dato) => dato.key === value)?.archivo
                ? [datos.find((dato) => dato.key === value)?.archivo ?? ({} as UploadFile)]
                : undefined
            }
            onRemove={() => {
              handleRemoveImage(value);
            }}
          >
            {isFileUploaded ? (
              <ButtonCheckValido /> // Cambia "newIcon" por el ícono que desees mostrar cuando haya un archivo
            ) : (
              <ButtonUploadTableImage /> // Ícono por defecto para subir archivo
            )}
          </Upload>
        );
      },
    },
  ];

  const columnsView: TableColumnsType<ActasReporte> = [
    {
      title: "Farmacia",
      dataIndex: "bodega",
      key: "bodega",
      ...getColumnSearchPropsNew("bodega"),
    },

    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
      sorter: (a, b) => moment(a.fecha).unix() - moment(b.fecha).unix(),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Dependiente",
      dataIndex: "cliente",
      key: "cliente",
      ...getColumnSearchPropsNew("cliente"),
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      render: (valor) => `$${valor}`,
    },
  ];

  return {
    columns,
    estadouser,
    columnsView,
    isModalOpen,
    rowSelection,
    filteredData,
    handleOk,
    handleCancel,
    handleSave,
    estadoGuardar,
    handleChange,
    estadoBoton,
  };
}
