import { Card, Col, Form, Row, Select, Table } from "antd";
import { useProductosPermisosEditarMinimos } from "hooks/productos/useProductosPermisosEditarMinimos";
const { Option } = Select;

const ProductosPermisosEditarMinimos = () => {
  const { form, handleChangeSelectEmpleado, headTable, handleSearch, options, dateTable } =
    useProductosPermisosEditarMinimos();
  return (
    <div>
      <Card>
        <Form form={form}>
          <Row gutter={[5, 5]}>
            <Col xs={24}>
              <Form.Item
                label='Buscar empleado:'
                name='cod_empleado'
                rules={[{ required: true, message: "Seleccione un empleado" }]}
              >
                <Select
                  showSearch
                  placeholder='Escribe para buscar por nombres...'
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearch}
                  notFoundContent={null}
                  allowClear
                  // options={options}
                  onChange={handleChangeSelectEmpleado}
                >
                  {options?.map((opcion) => (
                    <Option
                      key={opcion.value}
                      value={JSON.stringify({
                        key: opcion.value,
                        cod_usuario: opcion.value,
                        nombres: opcion.label,
                        ...opcion,
                      })}
                      // value={opcion.value}
                    >
                      {opcion.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Table
                title={() => <strong>PERSONAL CON PERMISOS DE EDICIÓN DE MÍNIMOS</strong>}
                columns={headTable}
                size='small'
                bordered
                dataSource={dateTable}
                pagination={false}
                scroll={{ x: 800, y: "60vh" }}
              />
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default ProductosPermisosEditarMinimos;
