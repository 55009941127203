import { Form } from "antd";
import { ColumnsType } from "antd/lib/table";
import { optionsPOST, url, url_interna } from "config/constants";
import { optionsGet } from "config/methods";
import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useExport } from "hooks/otros/useExport";
import { useCallback, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
import { useSearchTableNew } from "hooks/otros/useSearchTableNew";

const breadcrumbs = [{ Label: "Rotación productos" }];

interface PropsTableReport {
  key: number;
  Cod_Producto: number;
  Descripcion: string;
  laboratorio: string;
  cantidad: number;
  unidadMedida: number;
}
interface selectBodega {
  value: number;
  label: string;
}

export function useRotacionProductos() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);

  const [form] = Form.useForm();
  const [dataFiltrada, setDataFiltrada] = useState<PropsTableReport[]>([]);

  const { exportToExcel } = useExport();

  const [loader, setLoader] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { getColumnSearchPropsNew } = useSearchTableNew<PropsTableReport>();
  const [bodegas, setBodegas] = useState<selectBodega[]>([]);

  /** */
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > moment().endOf("day").subtract(1, "days");
  };

  const closeModal = () => {
    setOpenModal(false);
  };
  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };
  useBreadCrumbs(breadcrumbs);

  const headTableReport: ColumnsType<PropsTableReport> = [
    {
      title: "Cod Producto",
      dataIndex: "Cod_Producto",
      key: "Cod_Producto",
      width: "130px",
      ...getColumnSearchPropsNew("Cod_Producto"),
    },
    {
      title: "Producto",
      dataIndex: "Descripcion",
      key: "Descripcion",
      ...getColumnSearchPropsNew("Descripcion"),
      // sorter: (a, b) => a.num_clientes - b.num_clientes,
    },
    {
      title: "Laboratorio",
      dataIndex: "laboratorio",
      key: "laboratorio",
      ...getColumnSearchPropsNew("laboratorio"),
      // sorter: (a, b) => a.num_facturas - b.num_facturas,
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      align: "right",
      sorter: (a, b) => a.cantidad - b.cantidad,

      render: (_, record) => (
        <span style={{ color: record.cantidad === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.cantidad)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Unidad de medida",
      dataIndex: "unidadMedida",
      key: "unidadMedida",
      align: "right",
      sorter: (a, b) => a.unidadMedida - b.unidadMedida,
      
    },
  ];

  const handleClickGenerar = () => {
    setLoader(true);
    form
      .validateFields()
      .then(async (values) => {
        setLoader(true);
        setDataFiltrada([]);

        const respuesta = await fetch(
          `${url_interna}api/inventarios/rotacion_productos`,
          optionsPOST(JSON.stringify({ values }), {
            "api-token": "apitoken",
          })
        ).then((data) => data.json());

        if (!respuesta.msg) {
          Swal.fire("Error", "No existe información!", "error");
          setLoader(false);
          return;
        }
        setDataFiltrada(respuesta.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        Swal.fire("Error", "Complete los campos requeridos!", "error");
      });
  };

  useEffect(() => {
    setOpenKeys(["Inventarios", "Rotación Productos"]);
    setSelectedKeys(["Inventarios/Rotación Productos"]);
  }, [setOpenKeys, setSelectedKeys]);

  const handleData = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "apifsg-pr/reportes/bodegasPiloto/", optionsGet()).then((data) => data.json());
      if (respuesta.msg) {
        setBodegas(respuesta.data);
        form.setFieldsValue({ cod_bodegas: [0] });
      } else {
        Swal.fire("Error", "Ocurrio un error en la información", "error");
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [form]);

  useEffect(() => {
    handleData();
  }, [handleData]);

  const handleClickDownload = () => {
    if (!dataFiltrada) return;
    const datosParaExportar = dataFiltrada.map(({ key, ...resto }) => resto);
    exportToExcel(
      datosParaExportar,
      `ROTACION_PRODUCTOS_${form.getFieldsValue().cod_bodega}_${moment().format("YYYY-MM-DD_HH-mm-ss")}`
    );
  };

  return {
    disabledDate,
    handleClickGenerar,
    headTableReport,
    form,
    dataFiltrada,
    loader,
    closeModal,
    openModal,
    bodegas,
    handleClickDownload,
  };
}
