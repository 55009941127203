import { Card } from "antd";
import { PieChart, Pie, Cell, Label } from "recharts";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";

interface PropsTypeData {
  valorPrincipal: number;
  valorSecundario: number;
}

const formateaCantidad = (cantidad: number) => {
  const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return totalCostoFormateado;
};

const ChartPorcen = ({ valorPrincipal, valorSecundario }: PropsTypeData) => {
  const porcentajeSecundario = parseFloat(((valorSecundario / valorPrincipal) * 100).toFixed(2));

  const data = [
    {
      name: "Valor Secundario",
      value: porcentajeSecundario >= 100 ? 100 : porcentajeSecundario,
    },
    {
      name: "Resto",
      value: porcentajeSecundario >= 100 ? 0 : 100 - porcentajeSecundario,
    },
  ];

  return (
    <Card style={{ textAlign: "center", margin: "auto", width: "200px" }}>
      <span>PROYECCIÓN</span>

      <PieChart width={150} height={110} style={{ margin: "auto" }}>
        <Pie data={data} dataKey='value' cx='50%' cy='50%' innerRadius={35} outerRadius={55} fill='#8884d8'>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={index === 0 ? "#f0f0f0" : "white"} />
          ))}
        </Pie>
        <Pie data={data} dataKey='value' innerRadius={0} outerRadius={45} isAnimationActive={false}>
          {data.map((entry, index) => {
            const fillColor = entry.value >= 100 ? "#a8dba8" : "#f6a6a6";
            return <Cell key={`cell-${index}`} fill={fillColor} stroke={fillColor} />;
          })}
          <Label
            value={`${data[0].value}%`}
            position='center'
            fill='#555555'
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              fontFamily: "Roboto",
            }}
          />
        </Pie>
      </PieChart>
      <span>
        Total : {formateaCantidad(valorSecundario)}{" "}
        {valorSecundario > valorPrincipal ? <FaArrowUp color='#90ee90' /> : <FaArrowDown color='#ffcccb' />}
      </span>
    </Card>
  );
};

export default ChartPorcen;
