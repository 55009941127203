import { Col, Row, DatePicker, Table, Card, Spin, Button } from "antd";
import "moment/locale/es";

import { ButtonExcel } from "components/otros/buttons";
import { useVT } from "virtualizedtableforantd4";
import { useReportePsicotropicos } from "hooks/auditoriaProcesos/usePsicotropicosSisalem";

export function ReportePsicotropicosSisalem() {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);
  const {
    columns,
    datosInicial,
    handleClickDownloadData,
    setFechas,
    estadoBoton,
    dataEstado,
    disabledDate,
    fechaInicios,
    estadoBotones,
    handleChangeButton,
  } = useReportePsicotropicos();

  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <DatePicker.RangePicker
          //defaultValue={[fechaInicio, fechaFin]}
          //placeholder="Seleccionar mes"
          //format="MMMM [del] YYYY"
          onChange={(value) => {
            if (value && value.length === 2 && value[0] && value[1]) {
              setFechas([value[0], value[1]]);
              //setFechas([value[0], value[1]]);
            }
          }}
          disabledDate={disabledDate}
        />
        {fechaInicios !== null && fechaInicios !== null && (
          <Button
            type="primary"
            style={{ marginLeft: "5px" }}
            //icon={<PoweroffOutlined />}
            loading={estadoBotones}
            onClick={handleChangeButton}
          >
            Generar reporte
          </Button>
        )}
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Row gutter={[10, 10]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
          <Col xs={24} md={10}>
            {estadoBoton && <ButtonExcel buttonProps={{ onClick: handleClickDownloadData }}></ButtonExcel>}
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          {dataEstado && !estadoBotones && (
            <Table
              columns={columns}
              components={vt}
              dataSource={datosInicial ? datosInicial : []}
              scroll={{ x: 900, y: 450 }}
              //scroll={{ x: true }}
              size="small"
              pagination={false}
              bordered
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default ReportePsicotropicosSisalem;
