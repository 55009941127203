import { Button, type TableColumnsType, Tag } from "antd";
import { type Franquiciados } from "components/franquiciados/modal-franquiciado";
import { url } from "config/constants";
import { useLayout } from "hooks/otros/useLayout";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useSwal } from "hooks/otros/useSwal";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import { useCallback, useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { useSearchTable } from "hooks/otros/useSearchTable";
import { useExport } from "hooks/otros/useExport";

const breadcrumbs = [{ Label: "Franquiciados", Url: "/franquiciados" }, { Label: "Datos franquiciados" }];

const openKeys = [breadcrumbs[0].Label];

export function useFranquiciadosDatos() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);

  const [data, setData] = useState<Franquiciados[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedData, setSelectedData] = useState<React.Key>();
  const [pageSize, setPageSize] = useState(5);

  const { exportToExcel } = useExport();

  const { optionsGet } = useLocalStorage();

  const { getColumnSearchProps } = useSearchTable<Franquiciados>();

  const { swalError } = useSwal();

  const { isError } = useVerifyResponse();

  const reFetcher = useCallback(async () => {
    try {
      const result = await fetch(`${url}fapifsg-pr/franquiciados/listar-franquiciados`, optionsGet);
      if (isError(result)) throw new Error();
      const response = await result.json();
      setData(response);
    } catch (e) {
      swalError({ text: "No se pudo obtener la lista de los franquiciados" });
    }
  }, [isError, optionsGet, swalError]);

  useEffect(() => {
    reFetcher();
  }, [reFetcher]);

  const columns: TableColumnsType<Franquiciados> = [
    {
      title: "Cédula",
      dataIndex: "cedula",
      key: "cedula",
      ...getColumnSearchProps("cedula"),
    },
    {
      title: "Nombres",
      dataIndex: "label",
      key: "nombres",
      ...getColumnSearchProps("label"),
    },
    {
      title: "Correo",
      dataIndex: "correo",
      key: "correo",
      ...getColumnSearchProps("correo"),
    },
    {
      title: "Bodegas asignadas",
      dataIndex: "bodegas",
      key: "bodegas",
      render: (bodegas: Franquiciados["bodegas"]) => bodegas?.map(({ bodega }, index) => <Tag key={index}>{bodega}</Tag>),
      filters: data
        .map(({ bodegas }) => bodegas)
        .filter(Boolean)
        .flat()
        .sort((prev, next) => prev.cod_bodega - next.cod_bodega)
        .map(({ bodega }) => ({
          value: bodega,
          text: bodega,
        })),
      filterSearch: true,
      onFilter: (value, record) => record.bodegas?.some(({ bodega }) => bodega.startsWith(value as string)),
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      align: "center",
      render: (value: boolean) => (value ? <Tag color="success">Activo</Tag> : <Tag color="default">Inactivo</Tag>),
      filters: [
        {
          text: "Activo",
          value: true,
        },
        {
          text: "Inactivo",
          value: false,
        },
      ],
      onFilter: (value, record) => value === record.estado,
      
    },
    {
      title: "",
      dataIndex: "value",
      render: (value: React.Key) => (
        <Button
          icon={<EditOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          block
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          onClick={() => {
            setSelectedData(value);
            setOpenModal(true);
          }}
        />
      ),
    },
  ];

  const handleClickDownload = () => {
    const dataExport = data.map(({ cedula, label, correo, estado }) => ({
      Cedula: cedula,
      Nombres: label,
      Correo: correo,
      Estado: estado ? "Activo" : "Inactivo",
    }));
    exportToExcel(dataExport, "Lista de franquiciados");
  };

  const handleClickDownloadBodegas = () => {
    const dataExport = data.map(({ label, bodegas }) =>
      bodegas
        ? bodegas.map(({ bodega, cargo_empleados }) => ({
            Bodega: bodega,
            Franquiciado: label,
            "Empleados a su cargo": cargo_empleados,
          }))
        : []
    );
    exportToExcel(dataExport.flat(), "Lista de franquiciados");
  };

  return {
    setSelectedData,
    setOpenModal,
    selectedData,
    openModal,
    data,
    columns,
    reFetcher,
    pageSize,
    setPageSize,
    handleClickDownload,
    handleClickDownloadBodegas,
  };
}
