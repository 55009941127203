import { Button, type TableColumnsType, Tag } from "antd"
import { type RangePickerProps } from "antd/lib/date-picker"
import { LlamadoAtencion } from "components/rrhh/componentsllamadosatencion/generate-report"
import { url, url_alku } from "config/constants"
import { optionsGet, swalError } from "config/methods"
import { useLayout } from "hooks/otros/useLayout"
import { useSearchTable } from "hooks/otros/useSearchTable"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { GrDocumentPdf, GrScan } from "react-icons/gr"

const COLOR_MULTA = {
  0: "default",
  5: "blue",
  10: "gold",
}

type Vitacora = {
  key: React.Key
  empleado: number
  nombre_empleado: string
  solicitud: number
  numeracion: `RRHH-${number}-${number}`
  multa: keyof typeof COLOR_MULTA
  beneficiario?: string
  localidad?: string
  envio?: string
  recepcion?: string
  chofer?: string
  fecha_recibido: string
  archivo_pdf?: string
}

const breadcrumbs = [
  { Label: "Recursos Humanos", Url: "/rrhh" },
  { Label: "Llamados de atención", Url: "/rrhh/llamados-de-atencion" },
  { Label: "Bitácora de recibidos" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export function useBitacoraRecibidos() {
  useLayout(breadcrumbs, openKeys, selectKeySider)

  const [fechas, setFechas] = useState<RangePickerProps["value"]>([moment().subtract(1, "month"), moment()])
  const [vitacora, setVitacora] = useState<Vitacora[]>([])
  const [pageSize, setPageSize] = useState(7)
  const [openModalPdf, setOpenModalPdf] = useState(false)
  const [openModalReport, setOpenModalReport] = useState(false)

  const [selecteds, setSelecteds] = useState<{ empleado: number; solicitud: number; enlacePdf: string }>({
    empleado: 0,
    solicitud: 0,
    enlacePdf: "",
  })

  const [llamadoAtencion, setllamadoAtencion] = useState<LlamadoAtencion>()

  const { isError } = useVerifyResponse()

  const { getColumnSearchProps } = useSearchTable<Vitacora>()

  const reFetcher = useCallback(async () => {
    try {
      if (!fechas) return
      if (!fechas?.[0]) return
      if (!fechas?.[1]) return

      const inicio = encodeURIComponent(fechas[0].format("DD/MM/YYYY"))
      const fin = encodeURIComponent(fechas[1].format("DD/MM/YYYY"))
      const params = new URLSearchParams({ inicio, fin })
      const response = await fetch(`${url}fapifsg-pr/recursos-humanos/vitacora-recibidos?${params}`, optionsGet())

      if (isError(response)) throw new Error()

      const result = await response.json()

      setVitacora(result)
    } catch (e) {
      swalError({ text: "No se pudo obtener la lista de llamados de atención recibidos" })
    }
  }, [isError, fechas])

  useEffect(() => {
    reFetcher()
  }, [reFetcher])

  useEffect(() => {
    ;(async () => {
      try {
        if (selecteds.empleado === 0) return
        if (selecteds.solicitud === 0) return
        const { solicitud, empleado } = selecteds
        const res = await fetch(
          `${url}fapifsg-pr/recursos-humanos/llamado-atencion-empleado/${solicitud}/${empleado}`,
          optionsGet()
        )
        if (isError(res)) throw new Error()
        const data = await res.json()
        setllamadoAtencion(data)
      } catch (e) {
        swalError({ text: "No se pudo obtener los datos del llamado de atención" })
      }
    })()
  }, [selecteds, isError])

  useEffect(() => {
    if (!llamadoAtencion) return
    console.log("llega por aqui")
    setOpenModalReport(true)
  }, [llamadoAtencion])

  const columnsTable: TableColumnsType<Vitacora> = [
    {
      title: "Numeracion",
      dataIndex: "numeracion",
      key: "numeracion",
      ...getColumnSearchProps("numeracion"),
    },
    {
      title: "Empleado",
      dataIndex: "nombre_empleado",
      key: "empleado",
      ...getColumnSearchProps("nombre_empleado"),
    },
    {
      title: "Multa",
      dataIndex: "multa",
      key: "multa",
      render: (value: keyof typeof COLOR_MULTA) => <Tag color={COLOR_MULTA[value]}>{value}%</Tag>,
      filters: [
        {
          text: "0%",
          value: 0,
        },
        {
          text: "5%",
          value: 5,
        },
        {
          text: "10%",
          value: 10,
        },
      ],
      onFilter: (value, record) => record.multa === value,
    },
    {
      title: "Beneficiario",
      dataIndex: "beneficiario",
      key: "beneficiario",
      ...getColumnSearchProps("beneficiario"),
    },
    {
      title: "Localidad",
      dataIndex: "localidad",
      key: "localidad",
      ...getColumnSearchProps("localidad"),
    },
    {
      title: "Chofer",
      dataIndex: "chofer",
      key: "chofer",
      ...getColumnSearchProps("chofer"),
    },
    { title: "Fecha de envío", dataIndex: "envio", key: "envio" },
    { title: "Fecha de recepción", dataIndex: "recepcion", key: "recepcion" },
    { title: "Fecha de recibido", dataIndex: "fecha_recibido", key: "fecha_recibido" },
    {
      title: "Reporte",
      dataIndex: "solicitud",
      key: "solicitud",
      fixed: "right",
      render: (solicitud, { empleado }) => (
        <Button
          icon={<GrDocumentPdf style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={() => {
            setSelecteds(state => ({ ...state, empleado, solicitud }))
            // setOpenModalReport(true)
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
    },
    {
      title: "Escaneado",
      dataIndex: "archivo_pdf",
      key: "archivo_pdf",
      fixed: "right",
      render: (value: string) => (
        <Button
          icon={<GrScan style={{ fontSize: "1.3rem" }} />}
          disabled={!Boolean(value)}
          type="text"
          onClick={() => {
            setSelecteds({ empleado: 0, solicitud: 0, enlacePdf: `${url_alku}allku/api/${value}` })
            setOpenModalPdf(true)
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
    },
  ]

  return {
    vitacora,
    openModalPdf,
    openModalReport,
    setFechas,
    fechas,
    pageSize,
    setPageSize,
    columnsTable,
    setOpenModalPdf,
    selecteds,
    llamadoAtencion,
    setOpenModalReport,
  }
}
