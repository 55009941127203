import { url } from "config/constants";
import { table } from "console";
import { useEffect, useContext, useCallback } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";

export type EditableTableProps = Parameters<typeof table>[0];

export interface DataType {
  key: number;
  minimo: number;
  maximo: number;
  hasta: string;
  cod_categoria: number;
}

const breadCrumbs = [
  { Label: "Categorias por farmacia de acuerdo a la venta", Url: "politicas/categoria" },
];

export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export function useCategoriaFarmacia() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: "",
      dataIndex: "minimo",
      width: "200px",
      editable: true,
      align: "right",
    },
    {
      title: "Venta Mensual",
      dataIndex: "hasta",
      width: "0px",
      align: "center",
    },
    {
      dataIndex: "maximo",
      width: "200px",
      editable: true,
      align: "left",
    },
    {
      title: "Categoria #",
      dataIndex: "cod_categoria",
      width: "200px",
      align: "center",
    },
  ];
  useBreadCrumbs(breadCrumbs);
  useEffect(() => {
    // if (window.screen.width <= 992) return;

    setOpenKeys(["Políticas Comerciales/Manejo de Mínimos", "Políticas Comerciales"]);
    setSelectedKeys(["Manejo de Mínimos/Categoría por Farmacia"]);
  }, [setOpenKeys, setSelectedKeys]);

  const getCategoriaFarmacia = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "politicas/ventas_mensual", {
        method: "GET",
      }).then((res) => res.json());
      if (respuesta.msg) {
        const { data } = respuesta;
        return { data, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      // console.log(e)
      return { msg: "no_conexion", status: false };
    }
  }, []);

  type DataUpdate = {
    key: number;
    minimo: number;
    maximo: number | string | null;
    hasta: string;
  };

  const putVentaMensual = async (form: DataUpdate) => {
    try {
      if (form.maximo === "") {
        form.maximo = null;
      }
      const respuesta = await fetch(url + "politicas/actualiza_venta_mensual", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      }).then((res) => res.json());
      if (respuesta.ok) {
        return { data: respuesta.msg, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexión", status: false };
    }
  };

  return { getCategoriaFarmacia, defaultColumns, putVentaMensual };
}
