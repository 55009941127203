import { Col, Input, List, Modal, Row } from "antd"
import { useEffect, useState } from "react"

import { CloseCircleOutlined } from "@ant-design/icons"

import type { ModalProps } from "antd"
import type { SearchProps } from "antd/lib/input"
import type { DefaultOptionType } from "antd/lib/select"

export type ModalListSelectProps = {
  modalProps: ModalProps
  onChange(value: string | number): void
  dataSource: DefaultOptionType[]
}

export function ModalListSelect({ modalProps, onChange, dataSource }: ModalListSelectProps) {
  const [data, setData] = useState<typeof dataSource>([])

  const onSearch: SearchProps["onSearch"] = value => {
    setData(dataSource.filter(s => (s.label as string)?.toLowerCase().includes(value.toLowerCase())))
  }

  useEffect(() => setData(dataSource), [dataSource])

  return (
    <Modal
      footer={false}
      transitionName=""
      className="modalZeroPadding"
      style={{ top: "10px", padding: 0 }}
      closeIcon={<CloseCircleOutlined style={{ fontSize: "1.3rem" }} />}
      {...modalProps}
    >
      <Row style={{ padding: "16px", marginRight: "0px" }}>
        <Col xl={24} lg={24} md={24} xs={24} sm={24}>
          <Input.Search allowClear enterButton onSearch={onSearch} />
        </Col>
      </Row>
      <div className="scrollableDiv">
        <List
          id="SelectList"
          size="small"
          dataSource={data.length === 0 ? dataSource : data}
          renderItem={({ value, label }, index) => (
            <List.Item
              key={index}
              className="no-seleccionable hoverable"
              style={{ cursor: "pointer" }}
              onClick={() => {
                onChange(value as string | number)
                setData([])
              }}
            >
              {label}
            </List.Item>
          )}
        />
      </div>
    </Modal>
  )
}
