import { Button, Card, Col, DatePicker, Form, Row, Select, Table } from "antd";
import { ButtonExcel } from "components/otros/buttons";
import { useRotacionProductos } from "hooks/inventarios/reportes/rotacionProductos/useRotacionProductos";
import { useVT } from "virtualizedtableforantd4";

const RotacionProductos = () => {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);

  const { headTableReport, disabledDate, bodegas, handleClickGenerar, form, dataFiltrada, loader, handleClickDownload } =
    useRotacionProductos();
  return (
    <>
      <Card style={{ margin: "10px" }}>
        <Form
          form={form}
          layout='horizontal'
          // initialValues={{
          //   year: moment(),
          // }}
        >
          <Row gutter={[10, 10]}>
            <Col xs={24} md={10}>
              <Form.Item
                label='Seleccione una fecha:'
                name='fecha'
                rules={[{ required: true, message: "Por favor seleccione un rango de fecha" }]}
              >
                <DatePicker.RangePicker format='YYYY-MM-DD' disabledDate={disabledDate} style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item label='Bodegas:' name='cod_bodega' rules={[{ required: true, message: "Seleccione una opción" }]}>
                <Select
                  allowClear
                  optionFilterProp='label'
                  options={bodegas}
                  // onChange={handleChangeBodegas}
                  // value={selectedItems}
                  // style={{ maxHeight: "45px", overflowY: "scroll" }}
                  // autoClearSearchValue={false}
                ></Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Button type='primary' style={{ width: "100%" }} onClick={handleClickGenerar} loading={loader}>
                Generar
              </Button>
            </Col>
          </Row>
        </Form>
        <Row>
          {dataFiltrada.length > 0 && (
            <Table
              columns={headTableReport}
              dataSource={dataFiltrada}
              scroll={{ x: 900, y: 450 }}
              components={vt}
              //scroll={{ x: true }}
              size='small'
              pagination={false}
              bordered
            ></Table>
          )}
        </Row>
        <Row gutter={[5, 5]}>
          <Col xs={18}></Col>
          <Col xs={6}>
            {dataFiltrada.length > 0 && <ButtonExcel buttonProps={{ onClick: handleClickDownload }}></ButtonExcel>}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default RotacionProductos;
