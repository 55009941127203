import { Modal, Table } from "antd";
import { useListarConvenios } from "hooks/convenios/useListarConvenios";
import AdministrarConvenio from "components/convenios/AdministrarConvenio";

export const ListarConvenios = () => {
  // const { convenios, columnsTable, openModal, setOpenModal, modalRecord, openModalEdit, modalRecordEdit, setOpenModalEdit } =
  const { convenios, columnsTable, openModalEdit, modalRecordEdit, setOpenModalEdit } = useListarConvenios();

  return (
    <div style={{ padding: "10px" }}>
      <Table
        dataSource={convenios}
        columns={columnsTable}
        scroll={{ x: 900, y: 500 }}
        style={{ height: "600px" }}
        pagination={false}
        size='small'
      />
      {/* <PdfViewer visible={openModal} setVisible={setOpenModal} llave={llave} /> */}
      {/* <BodyConvenio visible={openModal} setVisible={setOpenModal} modalRecord={modalRecord} /> */}
      <Modal
        open={openModalEdit}
        onCancel={() => setOpenModalEdit(false)}
        destroyOnClose={true}
        footer={null}
        width='85%'
        transitionName=''
        style={{
          top: 64,
          position: "relative",
        }}
      >
        <AdministrarConvenio flag={1} modalRecord={modalRecordEdit}></AdministrarConvenio>
        {/* <BodyConvenioEdit modalRecord={modalRecordEdit} /> */}
      </Modal>
    </div>
  );
};
