import { Tooltip } from "antd";
import { PieChart, Pie, Cell } from "recharts";

const RADIAN = Math.PI / 180;
const data = [
  { name: "A", value: 100, color: "#ff0000" },
  { name: "A", value: 100, color: "yellow" },
  { name: "A", value: 100, color: "#00ff00" },
];

// const scaleFactor = 0.5;
const cx = 25;
const cy = 40;
const iR = 20;
const oR = 25;

const needle = (porcentaje: number, total: number, cx: number, cy: number, iR: number, oR: number, color: string) => {
  const ang = 180 * (1 - porcentaje / total);
  const length = (iR + oR) / 2;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 2.5;
  const x0 = cx;
  const y0 = cy;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle key='circle' cx={x0} cy={y0} r={r} fill={color} />,
    <path key='path' d={`M${xba} ${yba} L${xbb} ${ybb} L${xp} ${yp} Z`} fill={color} />,
  ];
};

const Velocimetro = ({ porcentaje }: any) => {
  const tooltipText = `${porcentaje.toFixed(2)}%`;
  return (
    <div className='centered-container' style={{ height: "100%" }} title={tooltipText}>
      <Tooltip title={tooltipText}>
        <div style={{ height: "50px", width: "50px", overflow: "visible" }}>
          <PieChart width={50} height={50} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
            <Pie
              dataKey='value'
              startAngle={180}
              endAngle={0}
              data={data}
              cx={cx}
              cy={cy}
              innerRadius={iR}
              outerRadius={oR}
              fill='#8884d8'
              stroke='none'
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            {needle(porcentaje, data[0].value, cx, cy, iR, oR, "#000000")}
          </PieChart>
        </div>
      </Tooltip>
    </div>
  );
};

export default Velocimetro;
