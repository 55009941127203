import { useCallback, useContext, useEffect, useState } from "react";
import { url } from "../../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";

import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import { Form, Input, notification, TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import Swal from "sweetalert2";
import { UserContext } from "context/UserContext";
import { optionsGet, swalError } from "config/methods";

type DatosUsuarioBodega = {
  Nombres: string;
  valorPedir: number;
  valorCaja: number;
  Cod_Usuario: number;
  key: React.Key;
};

type DatosBodega = {
  label: string;
  value: number;
};
interface FormValues {
  titulo: string;
  bodega: number; // Utilizando Moment.js para las fechas
  usuario: number; // Asegúrate de que el tipo aquí sea correcto, podría ser 'number' si los IDs de sección son numéricos
  valorPedir: number;
}
const breadcrumbs = [
  { Label: "Cajas", Url: "/cajas" },
  { Label: "Pago trámites", Url: "/cajas/pago-tramites" },
  { Label: "Solicitud" },
];

const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];
export function useSolicitud() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[1].Label + "/" + breadcrumbs[2].Label);
  const [form] = Form.useForm<FormValues>();
  const [datos, setDatos] = useState<DatosUsuarioBodega[]>([]);
  const [datosBodega, setDatosBodega] = useState<DatosBodega[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<DatosUsuarioBodega[]>([]);
  const { getColumnSearchPropsNew } = useSearchTableNew<DatosUsuarioBodega>();

  const { userData } = useContext(UserContext);
  const [estadoGuardar, setBottonGuardar] = useState<boolean>(false);
  const [estadoBoton, setBoton] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const datosFiltrados = datos.filter((item) => item.valorPedir > 0);

      const { Cod_Usuario, valorPedir } = datosFiltrados[0];

      const res = await fetch(
        url + "apifsg-pr/cajas/solicitud/enviar_solicitud_tramite",
        optionsPost({
          titulo: values.titulo,
          bodega: values.bodega,
          codUsuario: Cod_Usuario,
          valorPedir: valorPedir,
        })
      );

      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg === "ok") {
        if (!respuesta.data) {
          setDatos([]);
          setBoton(false);
          form.resetFields();
          notification.warning({
            message: "OK",
            description: `Solicitud enviada correctamente, pero no se pudo notificar`,
            placement: "bottomRight",
          });
        } else {
          setDatos([]);
          setBoton(false);
          form.resetFields();
          //await getConciliaciones();
          notification.success({
            message: "OK",
            description: `Solicitud enviada correctamente`,
            placement: "bottomRight",
          });
        }
      } else {
        notification.error({
          message: "Error",
          description: `Ocurrio un error,comunicar al departamento de sistemas, ${respuesta}`,
          placement: "bottomRight",
        });
      }
    } catch (error: any) {
      Swal.fire("Error", error.toString(), "error");
    } finally {
      setBottonGuardar(false);
    }
  };
  /*const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };*/
  const getBodegas = useCallback(async () => {
    //const shouldHideUpdateColumn = userData?.id === 7;
    setBoton(false);
    setDatosBodega([]);
    try {
      const res = await fetch(url + "apifsg-pr/bodegas/listar-bodegas-select", optionsGet());
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: DatosBodega[] = respuesta.data;
        if (respuestas.length > 0) {
          setDatosBodega(
            respuestas.map(({ label, value }) => ({
              label,
              value,
            }))
          );
        } else {
          setDatosBodega([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [isError]);
  const handleChangeBodega = async (value: number) => {
    setBoton(false);
    try {
      const result = await fetch(
        url + "apifsg-pr/cajas/solicitud/obtener_datos_bodegas",
        optionsPost({
          codBodega: value,
        })
      );
      if (isError(result)) throw new Error();
      const respuesta = await result.json();
      if (respuesta.msg !== "err") {
        const respuestas: DatosUsuarioBodega[] = respuesta.data;
        setDatos(
          respuestas.map(({ key, Cod_Usuario, Nombres, valorCaja, valorPedir }) => ({
            key,
            Cod_Usuario,
            Nombres,
            valorCaja,
            valorPedir,
          }))
        );
      } else {
        setBoton(false);
        setDatos([]);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      swalError({ text: "No se pudo obtener datos" });
    }
  };
  useEffect(() => {
    getBodegas();
  }, [getBodegas]);

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos);
    } else {
      const filtered = datos.filter((item) => item.Nombres.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, key: DatosUsuarioBodega) => {
    const newValue = parseFloat(e.target.value) || 0; // Convierte el valor de entrada a número y usa 0 como valor predeterminado en caso de una entrada no válida
    console.log(newValue);
    // Actualiza el estado 'datos' para reflejar el cambio en el valorPedir del usuario especificado y restablece los demás a 0
    setDatos((prevDatos) => {
      return prevDatos.map((usuario) => {
        if (usuario.key === key.key) {
          // Si es el usuario editado, establece valorPedir al nuevo valor
          if (newValue > 0) {
            setBoton(true);
          } else {
            setBoton(false);
          }
          form.setFieldsValue({
            usuario: usuario.Cod_Usuario,
            valorPedir: newValue,
          });
          return { ...usuario, valorPedir: newValue };
        } else {
          // Restablece valorPedir a 0 para los otros usuarios

          form.resetFields(["usuario", "valorPedir"]);
          return { ...usuario, valorPedir: 0 };
        }
      });
    });
  };
  const columns: TableColumnsType<DatosUsuarioBodega> = [
    {
      title: "Nombre",
      dataIndex: "Nombres",
      key: "Nombres",
      ...getColumnSearchPropsNew("Nombres"),
      onHeaderCell: () => {
        return {
          style: {
            backgroundColor: "#f0f0f0", // Un gris claro para resaltar un poco
            // fontWeight: 'bold', // Texto en negrita
            color: "#000", // Texto en color negro para más contraste
          },
        };
      },
    },
    {
      title: "Total caja",
      dataIndex: "valorCaja",
      key: "valorCaja",
      onHeaderCell: () => {
        return {
          style: {
            backgroundColor: "#f0f0f0", // Un gris claro para resaltar un poco
            // fontWeight: 'bold', // Texto en negrita
            color: "#000", // Texto en color negro para más contraste
          },
        };
      },
    },
    {
      title: "Valor a pedir",
      dataIndex: "valorPedir",
      key: "valorPedir",
      onHeaderCell: () => {
        return {
          style: {
            backgroundColor: "#f0f0f0", // Un gris claro para resaltar un poco
            fontWeight: "bold", // Texto en negrita
            color: "#000", // Texto en color negro para más contraste
          },
        };
      },
      render: (cantidad, key) => {
        return (
          <Input
            id={"key" + key.key}
            name="valorPedir"
            style={{ textAlign: "right", width: "70px" }}
            maxLength={5}
            onChange={(e) => handleInputChange(e, key)}
            type="number"
            min={0}
            value={cantidad}
          />
        );
      },
    },
  ];

  return {
    columns,
    isModalOpen,
    filteredData,
    handleOk,
    handleCancel,
    handleSave,
    estadoGuardar,
    estadoBoton,
    form,
    datosBodega,
    handleChangeBodega,
  };
}
