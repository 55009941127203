import { Col, Row, DatePicker, Segmented, Spin } from "antd";
import "moment/locale/es";

//import { useVT } from "virtualizedtableforantd4";
import { useInventarioEstadisticas } from "hooks/inventarios/reportes/useEstadisticas";
import SegmentoEstadistica from "components/indicadores/componentSegmented";
import moment from "moment";

export function EstadisticasInventario() {
  //const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);

  const {
    columns,
    //setFechas,
    filteredData,
    disabledDate,
    graph,
    datosPerdidos,
    columnProductosPerdidos,
    graphProductosPerdidos,
    columnProductosCaducados,
    graphProductosCaducados,
    datosCaducados,
    handleSegmentChange,
    getBodegas,
    segmentoSeleccionado,
    dataEstado,
  } = useInventarioEstadisticas();
  const fechaFin = moment(); // Fecha actual
  const fechaInicio = moment().subtract(1, "month"); // Un mes antes de la fecha actual

  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <DatePicker.RangePicker
          defaultValue={[fechaInicio, fechaFin]}
          onChange={(value) => {
            if (value && value.length === 2 && value[0] && value[1]) {
              
              getBodegas(value[0], value[1]);
              //setFechas([value[0], value[1]]);
            }
          }}
          disabledDate={disabledDate}
          style={{ width: "100%" }}
        />
      </Col>
      {!dataEstado && (
        <div style={{ textAlign: "center" }}>
          <div style={{ margin: "0 auto" }}>
            <Spin />
          </div>
        </div>
      )}
      {dataEstado && (
        <Segmented
          options={["Farmacias perdidas", "Productos perdidos", "Productos caducados"]}
          onChange={handleSegmentChange}
        />
      )}
      {segmentoSeleccionado === "Farmacias perdidas" && dataEstado && (
        <SegmentoEstadistica
          titulo={`Top ${filteredData.length} Farmacias con más pérdidas`}
          columns={columns}
          dataSource={filteredData}
          graphData={graph}
          graphConfig={{ size: 370, angle: -45 }}
        />
      )}
      {segmentoSeleccionado === "Productos perdidos" && dataEstado && (
        <SegmentoEstadistica
          titulo={`Top ${datosPerdidos.length} productos más perdidos`}
          columns={columnProductosPerdidos}
          dataSource={datosPerdidos}
          graphData={graphProductosPerdidos}
          graphConfig={{ size: 380, angle: -45 }}
        />
      )}
      {segmentoSeleccionado === "Productos caducados" && dataEstado && (
        <SegmentoEstadistica
          titulo={`Top ${datosCaducados.length} productos más caducados`}
          columns={columnProductosCaducados}
          dataSource={datosCaducados}
          graphData={graphProductosCaducados}
          graphConfig={{ size: 370, angle: -45 }}
        />
      )}
    </Row>
  );
}

export default EstadisticasInventario;
