import { Form, Input, DatePicker, Upload, Select, Card, Row, Col, Button, Tabs, Checkbox, Divider, Modal, Spin } from "antd";

import { SaveOutlined, EyeOutlined } from "@ant-design/icons";
import { useConvenios } from "hooks/convenios/useConvenios";

import { Tooltip } from "antd";

import { ButtonUpload } from "components/otros/ButtonUpload";
import moment from "moment";
import ListCuota from "./ListCuota";
import ListCuotaOut from "./ListCuotaOut";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { PropsTable } from "../../components/convenios/ViewTablaIn";

interface PropsConvenio {
  flag?: number;
  modalRecord?: PropsTable;
}

const AdministrarConvenio = ({ flag = 0, modalRecord }: PropsConvenio) => {
  const { TextArea } = Input;

  let {
    loaderGeneral,
    seleccion,
    totalRebate,
    totalRebateOut,
    convenios,
    onChangeSelectConvenio,
    afterConvenios,
    label,
    form,
    hidden,
    onChangeAfterConvenio,
    opcionesProductos,
    onChangeOpcionesProductos,
    opciones,
    dataTable,
    porcentaje,
    tipoPagos,
    checkIn,
    setCheckIn,
    handleClick,
    proveedores,
    setPorcentaje,
    resto,
    setResto,
    setSeleccion,
    setDataTable,
    loading,
    selection,
    formaPagos,
    restoOut,
    setRestoOut,
    porcentajeOut,
    setPorcentajeOut,
    checkOut,
    setCheckOut,
    seleccionOut,
    setSeleccionOut,
    checkSellIn,
    setCheckSellIn,
    checkSellOut,
    setCheckSellOut,
    onChangeOpcionesProductosOut,
    responsable,
    isLimited,
    radioValue,
    toggleLimit,
    onChangeEsDevoluciones,
    validateCheckboxes,
    isLimitedOut,
    toggleLimitOut,
    editKeyOut,
    setEditKeyOut,
    editKeyIn,
    setEditKeyIn,
    beforeUpload,
    previewVisiblePdf,
    setPreviewVisiblePdf,
    fileListPdf,
    handleChangePdf,
    previewPdf,
    handleEdit,
  } = useConvenios(flag, modalRecord);
  const currentYear = moment().year();
  const { RangePicker } = DatePicker;

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const items = [
    {
      label: (
        <div style={{ display: "flex", gap: "6px" }}>
          <p>Sell In</p>
          <Checkbox checked={checkSellIn} onChange={(e: CheckboxChangeEvent) => setCheckSellIn(e.target.checked)} />
        </div>
      ),
      key: "item-1",
      forceRender: true,
      children: checkSellIn && (
        <ListCuota
          resto={resto}
          setResto={setResto}
          porcentaje={porcentaje}
          setPorcentaje={setPorcentaje}
          checkIn={checkIn}
          setCheckIn={setCheckIn}
          totalRebate={totalRebate}
          hidden={hidden}
          opcionesProductos={opcionesProductos}
          opciones={opciones}
          onChangeOpcionesProductos={onChangeOpcionesProductos}
          dataTable={dataTable}
          setDataTable={setDataTable}
          seleccion={seleccion}
          setSeleccion={setSeleccion}
          form={form}
          isLimited={isLimited}
          radioValue={radioValue}
          toggleLimit={toggleLimit}
          onChangeEsDevoluciones={onChangeEsDevoluciones}
          validateCheckboxes={validateCheckboxes}
          editKeyIn={editKeyIn}
          setEditKeyIn={setEditKeyIn}
        />
      ),
    },
    {
      label: (
        <div style={{ display: "flex", gap: "6px" }}>
          <p>Sell Out</p>
          <Checkbox checked={checkSellOut} onChange={(e: CheckboxChangeEvent) => setCheckSellOut(e.target.checked)} />
        </div>
      ),
      key: "item-2",
      forceRender: true,
      children: checkSellOut && (
        <ListCuotaOut
          restoOut={restoOut}
          setRestoOut={setRestoOut}
          porcentajeOut={porcentajeOut}
          setPorcentajeOut={setPorcentajeOut}
          checkOut={checkOut}
          setCheckOut={setCheckOut}
          totalRebateOut={totalRebateOut}
          hidden={hidden}
          opcionesProductos={opcionesProductos}
          opciones={opciones}
          onChangeOpcionesProductos={onChangeOpcionesProductosOut}
          dataTable={dataTable}
          setDataTable={setDataTable}
          seleccionOut={seleccionOut}
          setSeleccionOut={setSeleccionOut}
          form={form}
          isLimited={isLimitedOut}
          toggleLimit={toggleLimitOut}
          editKeyOut={editKeyOut}
          setEditKeyOut={setEditKeyOut}
        />
      ),
    },
  ];

  return (
    <div style={{ margin: "5px" }}>
      {loaderGeneral ? (
        <div className='center_spin'>
          <Spin />
        </div>
      ) : (
        <Row wrap>
          <Col xs={24} lg={24}>
            <Card title={flag === 0 ? "INGRESO DE CONVENIO" : "EDITAR CONVENIO"} type='inner' size='default'>
              <Form form={form}>
                <Row wrap={true}>
                  <Col xs={24} xl={8}>
                    <Form.Item
                      initialValue={moment(`${currentYear}`, "YYYY")}
                      label='Año'
                      name='periodo'
                      style={{ width: "99%" }}
                      rules={[{ required: true, message: "El periodo del convenio es obligatorio" }]}
                    >
                      <DatePicker picker='year' placeholder='Seleccione año' style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item
                      // initialValue={moment(`${currentYear}`, "YYYY")}
                      label='Periodo'
                      name='range_periodo'
                      style={{ width: "99%" }}
                      rules={[{ required: true, message: "El periodo del convenio es obligatorio" }]}
                    >
                      <RangePicker style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item
                      label='Periodo de pago'
                      name='periodo_pago'
                      rules={[{ required: true, message: "Periodo de pago es obligatorio" }]}
                      style={{ width: "99%" }}
                    >
                      <Select
                        options={tipoPagos}
                        allowClear
                        showSearch
                        optionFilterProp='label'
                        placeholder='Seleccione una opción'
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col xs={24} xl={12}>
                    <Form.Item
                      label='Forma de pago'
                      name='formas_pago'
                      rules={[{ required: true, message: "La forma de pago es obligatoria" }]}
                    >
                      <Select
                        options={formaPagos}
                        allowClear
                        showSearch
                        optionFilterProp='label'
                        placeholder='Seleccione una opción'
                      ></Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} xl={12}>
                    <Row gutter={[10, 10]}>
                      <Col xs={18}>
                        <Form.Item
                          label='Archivo del convenio'
                          name='file'
                          valuePropName='file'
                          getValueFromEvent={normFile}
                          rules={[
                            {
                              required: previewPdf === "" ? true : false,
                              message: "Archivo del convenio es obligatorio!",
                            },
                          ]}
                        >
                          <Upload
                            accept='.pdf'
                            maxCount={1}
                            beforeUpload={beforeUpload}
                            name={`file`}
                            fileList={fileListPdf}
                            onChange={handleChangePdf}
                          >
                            <ButtonUpload />
                          </Upload>
                        </Form.Item>
                        <Modal
                          open={previewVisiblePdf}
                          footer={null}
                          onCancel={() => setPreviewVisiblePdf(false)}
                          transitionName=''
                        >
                          <iframe title='Visor' src={previewPdf} width='100%' height='400'></iframe>
                        </Modal>
                      </Col>
                      <Col xs={6}>
                        {previewPdf && <Button icon={<EyeOutlined />} onClick={() => setPreviewVisiblePdf(true)} />}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Form.Item
                  label='Responsable del convenio'
                  name='cod_responsable'
                  rules={[
                    {
                      required: true,
                      message: "El tipo de convenio es obligatorio",
                    },
                  ]}
                >
                  <Select
                    disabled={responsable.length === 1}
                    placeholder='Seleccione un responsable'
                    options={responsable}
                  ></Select>
                </Form.Item>
                <Form.Item
                  label='Proveedor'
                  name='cod_tipo_convenio'
                  rules={[
                    {
                      required: true,
                      message: "El tipo de convenio es obligatorio",
                    },
                  ]}
                >
                  <Select
                    placeholder='Seleccione un tipo de proveedor'
                    onChange={onChangeSelectConvenio}
                    allowClear
                    options={convenios}
                  ></Select>
                </Form.Item>

                <Form.Item
                  label={`Listado de ${label}`}
                  name='cod_seleccion_tipo_convenio'
                  rules={[
                    {
                      required: true,
                      message: "Esta opción es obligatoria",
                    },
                  ]}
                >
                  <Select
                    mode='multiple'
                    options={afterConvenios}
                    allowClear
                    value={selection}
                    onChange={onChangeAfterConvenio}
                    showSearch
                    optionFilterProp='label'
                    placeholder='Seleccione una opción'
                  ></Select>
                </Form.Item>
                {!hidden && (
                  <Form.Item
                    label={
                      <Tooltip title='Proveedor autorizado mediante el cual se efectuará el pago'>
                        Proveedor Autorizado...
                      </Tooltip>
                    }
                    name='cod_proveedor_autorizado'
                  >
                    <Select
                      options={proveedores}
                      allowClear
                      showSearch
                      optionFilterProp='label'
                      placeholder='Seleccione una opcion'
                    ></Select>
                  </Form.Item>
                )}
                <Row wrap={true}>
                  <Col xs={24} xl={24}>
                    <Form.Item
                      label='Observación'
                      name='observacion'
                      // rules={[{ required: true, message: "Por favor ingresa una descripción" }]}
                      style={{ width: "100%" }}
                    >
                      <TextArea placeholder='Escriba una observación del convenio' />
                    </Form.Item>
                  </Col>
                </Row>
                <>
                  <Divider dashed orientation='left' orientationMargin='0' style={{ margin: "0px" }}>
                    Tipo de convenio
                  </Divider>
                  <Tabs items={items} type='line' />
                </>
                {/* <Tabs items={items} type='line' /> */}
              </Form>
              <Button
                onClick={flag === 0 ? handleClick : handleEdit}
                type='primary'
                style={{
                  width: "100%",
                }}
                loading={loading}
                icon={<SaveOutlined className='iconbutton' />}
              >
                {flag === 0 ? "ENVIAR TODO" : "GUARDAR EDICIÓN"}
              </Button>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AdministrarConvenio;
