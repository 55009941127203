import { Button, Col, Row, Select, Table, Typography, Upload, Modal } from "antd";
import { SaveOutlined, PlusCircleOutlined, InboxOutlined, RollbackOutlined } from "@ant-design/icons";
import { useSolicitudReposicion, DataType, DataTypeSolicitud } from "hooks/rrhh/renovacionuniforme/useSolicitudReposicion";
import { useLayout } from "hooks/otros/useLayout";

const { Title } = Typography;
const { Dragger } = Upload;

const breadcrumbs = [
  { Label: "Recursos Humanos", Url: "/rrhh" },
  { Label: "Renovación de Uniformes", Url: "/rrhh/renovacion-uniformes" },
  { Label: "Solicitud Reposición de Uniformes" },
];
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`];
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`;

export function SolicitudReposicion() {
  useLayout(breadcrumbs, openKeys, selectKeySider);

  const {
    itemselectUniforme,
    onSearch,
    dataTable,
    onChange,
    addrow,
    columns,
    saveSolicitudReposicion,
    files,
    onChangeFile,
    onPreviewImage,
    disableRemoveIcon,
    handleRemove,
    checkFile,
    previewOpen,
    previewTitle,
    previewImage,
    handleCancel,
    DataSolicitudes,
    Solicitudes,
    visible,
    btnNuevo,
    btnRegresar,
    disabledDragger,
    disabledbutton,
  } = useSolicitudReposicion();

  return (
    <>
      {!visible && (
        <div>
          <Row style={{ background: "white", padding: "5px" }}>
            <Col sm={24} md={12} style={{ paddingLeft: "20px" }}>
              <Title level={3}>Solicitudes de Reposición de Uniformes</Title>
            </Col>
            <Col sm={24} md={12} style={{ paddingLeft: "20px" }}>
              <Row justify="end">
                <Button type="primary" style={{ marginTop: "1px", marginRight: "20px" }} onClick={() => btnNuevo()}>
                  <PlusCircleOutlined />
                  Nueva
                </Button>
              </Row>
            </Col>
          </Row>
          <Table
            columns={Solicitudes as DataTypeSolicitud[]}
            dataSource={DataSolicitudes}
            //pagination={false}
          />
        </div>
      )}
      {visible && (
        <div style={{ background: "#FFFFFF", height: "100vh" }}>
          <Row style={{ background: "white", padding: "5px" }}>
            <Col sm={24} md={12} style={{ paddingLeft: "20px" }}>
              <Title level={3}>Solicitud de Reposición de Uniformes</Title>
            </Col>
            <Col sm={24} md={12} style={{ paddingLeft: "20px" }}>
              <Row justify="end">
                <Button
                  type="primary"
                  style={{ marginTop: "1px", marginRight: "2px" }}
                  onClick={() => {
                    btnRegresar();
                  }}
                >
                  <RollbackOutlined />
                  Regresar
                </Button>
                <Button
                  disabled={disabledbutton}
                  type="primary"
                  style={{ marginTop: "1px", marginRight: "20px" }}
                  onClick={() => saveSolicitudReposicion()}
                >
                  <SaveOutlined />
                  Guardar
                </Button>
              </Row>
            </Col>
          </Row>
          <Row style={{ background: "white", paddingLeft: "10px" }}>
            <Col sm={24} md={15}>
              <Row>
                <Select
                  disabled={disabledDragger}
                  showSearch
                  style={{ textAlign: "start", width: "30%", marginLeft: "10px", marginBottom: "5px" }}
                  defaultValue=""
                  placeholder="Seleccione un Tipo Uniforme"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                  options={itemselectUniforme}
                />
                <Button
                  style={{ marginLeft: "5px" }}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  onClick={() => addrow()}
                ></Button>
              </Row>
              <Row>
                <Table
                  columns={columns as DataType[]}
                  dataSource={dataTable}
                  pagination={false}
                  footer={(data) => {
                    let total = dataTable.reduce((total, fila) => total + fila.vtotal, 0);
                    return (
                      <>
                        <Row style={{ textAlign: "right" }}>
                          <Col sm={18} md={20} style={{ padding: "5px" }}>
                            <Title level={4}>Total</Title>{" "}
                          </Col>
                          <Col sm={6} md={4} style={{ padding: "5px" }}>
                            <Title level={4}>{total.toFixed(2)}</Title>
                          </Col>
                        </Row>
                      </>
                    );
                  }}
                />
              </Row>
            </Col>
            <Col style={{ margin: "0px 20px  0px 10px", height: "120px", marginBottom: "5px" }} md={8}>
              <Row>
                <Title level={5}>Evidencia</Title>
              </Row>
              <Dragger
                disabled={disabledDragger}
                beforeUpload={checkFile}
                onChange={onChangeFile}
                onPreview={onPreviewImage}
                onRemove={handleRemove}
                accept="image/*"
                fileList={files}
                multiple={true}
                listType="picture-card"
                showUploadList={{
                  removeIcon: disableRemoveIcon,
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click aquí o suelte las imágenes en esta área</p>
              </Dragger>
            </Col>
          </Row>
        </div>
      )}
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
}
