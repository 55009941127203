import { Col, DatePicker, Form, Row, Select, Input, Upload, Switch, Modal, Button, InputNumber, message } from "antd"
import { useLlamadoAtencion } from "hooks/rrhh/llamadosatencion/useLlamadoAtencion"
import { InboxOutlined } from "@ant-design/icons"
import { url } from "config/constants"

const { TextArea } = Input
const { Dragger } = Upload

export function LlamadoAtencion() {
  const {
    empleados,
    departamentos,
    isWeb,
    form,
    bodegas,
    reglamentos,
    handleOnChangeDepartamento,
    handlePreview,
    previewImage,
    openModal,
    setOpenModal,
    getItem,
    handleRemoveImage,
    handleChangeUpload,
    fileList,
    handleCreate,
    disabledPorcentajeMulta,
    setDisabledPorcentajeMulta,
    usuarios,
    usuario,
    showRemoveIcon,
    disabledButtonSubmit,
  } = useLlamadoAtencion()

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        requiredMark="optional"
        style={isWeb ? { padding: "16px" } : { padding: 0 }}
        onFinish={handleCreate}
        initialValues={{ multa: false, porcentaje: 0, departamento: 5, responsables: [usuario] }}
      >
        <Row wrap style={{ padding: "10px", marginRight: 0, marginLeft: 0, backgroundColor: "#fff" }} gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Departamento" name="departamento" required>
              <Select options={departamentos} optionFilterProp="label" showSearch onChange={handleOnChangeDepartamento} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Bodega" name="bodega" rules={[{ required: true, message: "Escoja una bodega" }]}>
              <Select options={bodegas} showSearch optionFilterProp="label" placeholder="Escoja una bodega" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Empleados"
              name="empleados"
              rules={[{ required: true, message: "Escoja el/los empleados involucrados" }]}
            >
              <Select
                options={empleados}
                optionFilterProp="label"
                mode="multiple"
                showArrow
                placeholder="Empleados involucrados en la falta"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Reglamentos" name="reglamentos">
              <Select options={reglamentos} mode="multiple" optionFilterProp="label" showArrow />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Responsable del proceso"
              name="responsables"
              rules={[{ required: true, message: "Debe seleccionar al menos un responsable del proceso" }]}
            >
              <Select options={usuarios} mode="multiple" optionFilterProp="label" showArrow />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={12} lg={5} xl={6}>
            <Form.Item
              label="Fecha de la falta"
              name="fecha_falta"
              rules={[{ required: true, message: "Escoja la fecha de la falta" }]}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={12} lg={2} xl={2}>
            <Form.Item label="Multa" name="multa" valuePropName="checked" required>
              <Switch
                onChange={checked => {
                  setDisabledPorcentajeMulta(!checked)
                  form.setFieldValue("porcentaje", checked ? 10 : 0)
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={12} lg={5} xl={4}>
            <Form.Item label="Porcentaje de multa" name="porcentaje" required>
              <InputNumber min={0} max={100} step={5} disabled={disabledPorcentajeMulta} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item label="Descripción de la falta" name="descripcion" required>
              <TextArea autoSize={{ minRows: 5, maxRows: 20 }} maxLength={5000} showCount />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item label="Evidencias" valuePropName="File">
              <Dragger
                action={`${url}apiallku-private/api/fromapiexterna`}
                accept="image/*"
                maxCount={10}
                listType="picture"
                name="uploadfile"
                onPreview={handlePreview}
                data={{ archivo: "rrhh" }}
                headers={{ "access-token": getItem("token") ?? "" }}
                onChange={handleChangeUpload}
                onRemove={handleRemoveImage}
                fileList={fileList}
                showUploadList={{ showRemoveIcon: showRemoveIcon }}
                beforeUpload={file => {
                  if (file.size >= 4180000) {
                    message.error("La imágen excede el tamaño máximo permitido. Debe ser menor a 4MB")
                    return Upload.LIST_IGNORE
                  }
                  const tipo = file.type.toLocaleLowerCase()
                  if (tipo === "image/jpg" || tipo === "image/png" || tipo === "image/jpeg") return true
                  message.error("Formato de imagen no admitido")
                  return Upload.LIST_IGNORE
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click aquí o suelte las imágenes en esta área</p>
              </Dragger>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={disabledButtonSubmit} block>
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        open={openModal}
        title="Previsualización de imagen"
        footer={null}
        onCancel={() => setOpenModal(false)}
        transitionName=""
      >
        <img alt="Previsualización de imagen" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  )
}
