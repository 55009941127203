import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { url } from "../../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { Button, Form, TableColumnsType, Tag } from "antd";

import { useLayout } from "hooks/otros/useLayout";
import { EyeOutlined } from "@ant-design/icons";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";
import { optionsGet, swalWarning } from "config/methods";
interface PropsDescripcionInventario {
  key: React.Key;
  Descripcion: string;
  FechaInicio: string;
  FechaFin: string;
}
type InventarioReporte = {
  Descripcion: string;
  FechaInicio: string;
  FechaFin: string;
  key: React.Key;
};

const breadcrumbs = [
  { Label: "Inventarios", Url: "/inventario" },
  { Label: "Historicos", Url: "/inventarios/inventario-handheld" },
  { Label: "Inventario handheld" },
];
//const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Productividad" }];

const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];
export function useInventarioHandeld() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[1].Label + "/" + breadcrumbs[2].Label);
  const [mes, setMes] = useState<Moment>(moment());
  const [datos, setDatos] = useState<InventarioReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<InventarioReporte[]>([]);
  const { getColumnSearchPropsNew } = useSearchTableNew<InventarioReporte>();
  const [detalle, setDetalle] = useState<PropsDescripcionInventario[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { exportToExcel } = useExport();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };
  const getBodegasInventareadas = useCallback(async () => {
    try {
      const res = await fetch(url + "apifsg-pr/inventarios/obtener_bodegas_inventareadas", optionsGet());
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: InventarioReporte[] = respuesta.data;

        setDatos(
          respuestas.map(({ key, Descripcion, FechaInicio, FechaFin }) => ({
            key,
            Descripcion,
            FechaInicio,
            FechaFin,
          }))
        );
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [isError]);

  useEffect(() => {
    getBodegasInventareadas();
  }, [getBodegasInventareadas]);

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.Descripcion.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  const columns: TableColumnsType<InventarioReporte> = [
    {
      title: "Farmacia",
      dataIndex: "Descripcion",
      key: "Descripcion",
      ...getColumnSearchPropsNew("Descripcion"),
    },
    {
      title: "Fecha inicio",
      dataIndex: "FechaInicio",
      key: "FechaInicio",
      sorter: (a, b) => moment(a.FechaInicio).unix() - moment(b.FechaInicio).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Fecha fin",
      dataIndex: "FechaFin",
      key: "FechaFin",
      sorter: (a, b) => moment(a.FechaFin).unix() - moment(b.FechaFin).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Datos",
      dataIndex: "datos",
      key: "datos",
      render: (value, record) => (
        <Button
          icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={() => {
            /*if (record.datos && record.estado !== "Suspendido") {
              setDetalle(value);
              showModal();
            } else {
              swalWarning({ text: "Sin datos para calcular" });
            }*/
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
      fixed: "right",
    },
  ];
  const formatearTiempo = (totalMinutos: number) => {
    const horas = Math.floor(totalMinutos / 60);
    const minutos = totalMinutos % 60;
    return `${horas} horas ${minutos} minutos`;
  };

  const columsDatatableDetalle: TableColumnsType<PropsDescripcionInventario> = [
    {
      title: "Usuarios",
      dataIndex: "nombresRol",
      key: "Nombres_Rol",
      //...getColumnSearchPropsNew("laboratorios"),
    },
    {
      title: "Tiempo",
      dataIndex: "tiempo",
      key: "tiempo",
    },
    {
      title: "Unidades",
      dataIndex: "totalCantidad",
      key: "totalCantidad",
    },
    {
      title: "Productividad",
      dataIndex: "produccion",
      key: "produccion",
    },
  ];
  const handleClickDownloadData = () => {
    const datosParaExportar = detalle.map(({ key, ...resto }) => resto);
    exportToExcel(datosParaExportar, "productividadInventario");
  };

  return {
    columns,
    columsDatatableDetalle,
    isModalOpen,
    detalle,
    mes,
    setMes,
    filteredData,
    handleOk,
    handleCancel,
    form,
    handleClickDownloadData,
  };
}
