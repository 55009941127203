import { Card, Col, Row, Table, Button, Checkbox, Form, Input } from "antd";
import { useVerArchivos } from "hooks/rrhh/actualizacion/useVerArchivos";

import { PdfViewer } from "./PdfViewer";
import { ImgViewer } from "./ImgViewer";
import { SaveOutlined, EditFilled } from "@ant-design/icons";
import { FormInstance } from "antd/es/form";
import { PropsFormu } from "pages/contentrecursoshumanos/contentactualizacion/RevisarInformacion";

export interface PropArchivos {
  archivos: PropsLlaves[] | undefined;
  form: FormInstance<any>;
  handleOk: any;
  getEnviadas?: (value: number) => void;
  valuePeriodo?: number;
  enviadas: PropsFormu[] | undefined;
  setEnviadas?: (data: PropsFormu[]) => void;
}

export interface PropsLlaves {
  key: number;
  llave: string;
  estado: number;
}

const { Meta } = Card;

export const VerArchivos = ({
  archivos,
  form,
  handleOk,
  getEnviadas,
  valuePeriodo,
  enviadas,
  setEnviadas,
}: PropArchivos) => {
  const {
    columns,
    data,
    visible,
    url,
    setVisible,
    visibleImg,
    setVisibleImg,
    handleClickData,
    setEdit,
    edit,
    handleClickDataEdit,
    loading,
    loading2,
    downloadPDFReglamentos,
    downloadPDFMedios,
    downloadPDFDatosPersonales,
  } = useVerArchivos(archivos, form, handleOk, getEnviadas, valuePeriodo, enviadas, setEnviadas);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Card
          style={{ minHeight: "50px", width: "99%", bottom: "5px" }}
          hoverable
          title='Descargas de aceptación de reglamentos, medios electrónicos y datos personales'
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12} lg={8}>
              <Button
                type='primary'
                onClick={() =>
                  downloadPDFReglamentos(
                    form.getFieldsValue().apellidos + " " + form.getFieldsValue().nombres,
                    ["Reglamento Interno", "Reglamento de Higiene y Salud"],
                    "SOCIEDAD CIVIL DE HECHO DENOMINADO GRUPO USCOCOVICH",
                    form.getFieldsValue().id_luc
                  )
                }
              >
                Entrega de reglamentos
              </Button>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Button
                type='primary'
                onClick={() =>
                  downloadPDFMedios(
                    form.getFieldsValue().apellidos + " " + form.getFieldsValue().nombres,
                    "SOCIEDAD CIVIL DE HECHO DENOMINADO GRUPO USCOCOVICH",
                    form.getFieldsValue().id_luc
                  )
                }
              >
                Aceptación de medios electrónicos
              </Button>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Button
                type='primary'
                onClick={() =>
                  downloadPDFDatosPersonales(
                    form.getFieldsValue().apellidos + " " + form.getFieldsValue().nombres,
                    "SOCIEDAD CIVIL DE HECHO DENOMINADO GRUPO USCOCOVICH",
                    form.getFieldsValue().id_luc
                  )
                }
              >
                Aceptación de datos personales
              </Button>
            </Col>
          </Row>
        </Card>
      </Row>

      <div className='cards-container'>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={24}>
            <Meta title='Gestionar Archivos' description=' ' />
            <Card style={{ minHeight: "300px" }} hoverable>
              <Table dataSource={data} columns={columns} pagination={false} size={"small"} />
              <div style={{ marginTop: "16px" }}>
                <Checkbox checked={edit} onChange={(e) => setEdit(e.target.checked)}>
                  PERMITIR LA EDICION DE INFORMACION
                </Checkbox>
              </div>
            </Card>
          </Col>
          <PdfViewer setVisible={setVisible} visible={visible} llave={url} />
          <ImgViewer setVisible={setVisibleImg} visible={visibleImg} llave={url} />
          <div style={{ display: "flex", width: "100%", gap: 5 }}>
            <Button
              type='default'
              style={{
                width: "50%",
                color: "white",
                backgroundColor: "rgb(173 110 110)",
              }}
              icon={<EditFilled className='iconbutton' />}
              loading={loading}
              onClick={() => handleClickDataEdit(form)}
              disabled={loading || loading2}
            >
              PERMITIR EDICION
            </Button>
            <Button
              type='primary'
              style={{
                width: "50%",
              }}
              loading={loading2}
              icon={<SaveOutlined className='iconbutton' />}
              disabled={loading || loading2}
              onClick={() => handleClickData(form)}
            >
              GUARDAR COMO CORRECTO
            </Button>
          </div>
        </Row>
        <Form.Item name='cod_empleado' style={{ display: "none" }}>
          <Input type='hidden' />
        </Form.Item>
        <Form.Item name='id_luc' style={{ visibility: "hidden" }}>
          <Input type='hidden' />
        </Form.Item>
        <Form.Item name='coordenadas' style={{ visibility: "hidden" }}>
          <Input type='hidden' />
        </Form.Item>
        <Form.Item name='estado' style={{ visibility: "hidden" }}>
          <Input type='hidden' />
        </Form.Item>
        {/*  */}
        <Form.Item name='es_interno' style={{ visibility: "hidden" }}>
          <Input type='hidden' />
        </Form.Item>
        <Form.Item name='es_higiene' style={{ visibility: "hidden" }}>
          <Input type='hidden' />
        </Form.Item>
        <Form.Item name='es_aceptacion_me' style={{ visibility: "hidden" }}>
          <Input type='hidden' />
        </Form.Item>
        <Form.Item name='es_aceptacion_dp' style={{ visibility: "hidden" }}>
          <Input type='hidden' />
        </Form.Item>
        <Form.Item name='fecha_actualizacion' style={{ visibility: "hidden" }}>
          <Input type='hidden' />
        </Form.Item>
      </div>
    </>
  );
};
