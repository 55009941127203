import { Col, Row, DatePicker, Table, Card } from "antd";
import "moment/locale/es";

import moment from "moment";
import { ButtonExcel } from "components/otros/buttons";
import { useReporteInventarioProductividadUsuario } from "hooks/inventarios/reportes/productividad/useProductividadUsuarios";

export function ProductividadUsuario() {
  const {
    columns,
    mes,
    setMes,
    filteredData,
    handleClickDownloadData,
    calcularTotales,
  } = useReporteInventarioProductividadUsuario();
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
     <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <DatePicker
          value={mes}
          picker="month"
          placeholder="Seleccionar mes"
          format="MMMM [del] YYYY"
          onChange={(value) => setMes(value ?? moment())}
          inputReadOnly
        />
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Row gutter={[10, 10]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
          <Col xs={24} md={10}>
            {<ButtonExcel buttonProps={{ onClick: handleClickDownloadData }}></ButtonExcel>}
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <Table
            pagination={false}
            loading={columns ? false : true}
            scroll={{ x: 900 }}
            size="small"
            bordered
            columns={columns}
            dataSource={filteredData}
            summary={() => {
              const { tiempoFormateado, unid, promedioProduccion } = calcularTotales();
              return (
                <Table.Summary.Row style={{ backgroundColor: "#f0f0f0" }}>
                  <Table.Summary.Cell index={0} align="right">
                    Total
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>{tiempoFormateado}</Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="right">
                    {unid}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="right">
                    {promedioProduccion}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default ProductividadUsuario;
