import { Button, Tag } from "antd"

import { useState } from "react"
import { useLoaderData, useNavigate } from "react-router-dom"

import { useSearchTable } from "hooks/otros/useSearchTable"
import { useScreenSize } from "hooks/otros/useScreenSize"
import { useLayout } from "hooks/otros/useLayout"

import { EyeOutlined } from "@ant-design/icons"

import { llave } from "config/constants"

import type { ColumnsType } from "antd/lib/table"
import AES from "crypto-js/aes"

interface Solicitudes {
  key: number
  empleado: string
  fecha_aprobacion: string
  fecha_inicio: string
  fecha_fin: string
  periodo: string
  total_dias: number
  jefe_inmediato: string
  adelanto: boolean
}

const breadcrumbs = [
  { Label: "Recursos Humanos", Url: "/rrhh" },
  { Label: "Vacaciones", Url: "/rrhh/vacaciones" },
  { Label: "Revisión RRHH" },
]

const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]

const selectKey = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export function useListaSolicitudesAprobadasJefeInmediato() {
  useLayout(breadcrumbs, openKeys, selectKey)

  const solicitudes = useLoaderData() as Solicitudes[]

  const [pageSize, setPageSize] = useState(7)

  const navigate = useNavigate()

  const { getColumnSearchProps } = useSearchTable<Solicitudes>()

  const { width } = useScreenSize()

  const isWeb = width >= 778

  const columnsTable: ColumnsType<Solicitudes> = [
    {
      title: "Empleado",
      dataIndex: "empleado",
      key: "empleado",
      ...getColumnSearchProps("empleado"),
    },
    {
      title: "Jefe inmediato",
      dataIndex: "jefe_inmediato",
      key: "jefe",
      ...getColumnSearchProps("jefe_inmediato"),
    },
    { title: "Fecha Inicio", dataIndex: "fecha_inicio", key: "fecha_inicio" },
    { title: "Fecha fin", dataIndex: "fecha_fin", key: "fecha_fin" },
    { title: "Fecha de aprobación", dataIndex: "fecha_aprobacion", key: "fecha_aprobacion" },
    { title: "Periodo", dataIndex: "periodo", key: "periodo" },
    {
      title: "Solicitud",
      dataIndex: "adelanto",
      key: "adelanto",
      render: value => <Tag color={value ? "red" : "blue"}>{value ? "Adelanto" : "Normal"}</Tag>,
      filters: [
        { text: "Adelanto", value: true },
        { text: "Normal", value: false },
      ],
      onFilter: (value, { adelanto }) => value === adelanto,
    },
    {
      title: "",
      dataIndex: "key",
      key: "acciones",
      align: "center",
      fixed: "right",
      render: value => (
        <Button
          icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
          onClick={() =>
            navigate(`/rrhh/vacaciones/revision-rrhh/${encodeURIComponent(AES.encrypt(value.toString(), llave).toString())}`)
          }
          type="text"
        />
      ),
    },
  ]

  return {
    solicitudes,
    pageSize,
    setPageSize,
    isWeb,
    columnsTable,
  }
}
