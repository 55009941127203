import { useState, useEffect, useRef, useCallback } from "react"
import type { ColumnType, ColumnsType } from "antd/es/table"
import moment from "moment"
import { optionsGet, optionsPost, swalWarning } from "config/methods"
import { url } from "config/constants"
import { Button, Input, InputRef, Space, Table, Tag, Tooltip } from "antd"
import { SearchOutlined, EyeOutlined } from "@ant-design/icons"
import type { FilterConfirmProps } from "antd/es/table/interface"
import Highlighter from "react-highlight-words"
import { useExport } from "hooks/otros/useExport"

export interface DataType {
  key: React.Key
  cod_usuario: string
  nombre: string
  provincia: string
  ciudad: string
  direccion: string
  cod_farmacia: string
  fecha_ingreso: Date
  fecha_solicitud: Date
  codarticulo: string
  cod_estado: string
  dias: number
  cod_articulo_reposicion: number
  disabled: boolean
  tipo_articulo: string
  detalle: string[]
  imagellaves: string[]
  observacionrrhh: string
  observacionaprobacion: string
}

type DataIndex = keyof DataType

export interface DataUniforme {
  key: React.Key
  codtipouniforme: number
  nombre_tipouniforme: string
  cantidad: number
  codtalla: string
  vunitario: number
  keyrecord: string
  disabled: boolean
}

export function useReporteUniformes() {
  const [fechasSeleccionadas, setFechasSeleccionadas] = useState<[moment.Moment, moment.Moment]>([
    moment(moment().subtract(1, "month"), 'YYYY/MM/DD'), moment( moment().endOf("day"), 'YYYY/MM/DD')
  ])
  const [valueselect, setvalueselect] = useState("")
  const [itemselectTalla, setitemSelectTalla] = useState([{ value: "", label: "Talla" }])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [data, setData] = useState<DataType[]>([])
  const { exportToExcel } = useExport()
  const [searchText, setSearchText] = useState("")
  const [searchedColumn, setSearchedColumn] = useState("")
  const searchInput = useRef<InputRef>(null)
  const [visible, setVisible] = useState(false);
  const [llavesimages, setllavesimages]= useState<String[]>([])
  const [itemselect, setitemSelect]=useState([{Cod_Kit:"", Nombre: "seleccione un kit"}])
  const [ExpandedRowKeys,setExpandedRowKeys]=useState<string[]>([])
  const disabledDate = (current: any) => {
    // Si la fecha actual es mayor a la fecha seleccionada, deshabilita la fecha
    return current && current > moment().endOf("day")
  }

  const getTalla = async () => {
    const res = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/getTalla`, optionsGet()).then((data) => data.json())
    let respuesta = res.data
    let newget = [{ value: "", label: "Talla" }]
    respuesta.forEach((item: any) => {
      let add = {
        value: item.Cod_Talla,
        label: item.Talla,
      }
      newget = [...newget, add]
    })
    setitemSelectTalla(newget)
  }

  const getKit = async () => {
    const res = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/getKit`, optionsGet()).then((data) => data.json())
    setitemSelect(res.data)
  }

  const getReporteUniformes = useCallback(async () => {
   
    const res = await fetch(
      `${url}fapifsg-pr/rrhh/Uniforme/reporteuniformes`,
      optionsPost({ estado: valueselect, fechainicio: fechasSeleccionadas[0], fechafin: fechasSeleccionadas[1] })
    ).then((data) => data.json())

    let newdata: any = []
    if (res) {
      newdata = Array.from(res).map((item: any, key: any) => ({
        key: key.toString(),
        cod_usuario: item.Cod_Usuario,
        nombre: item.Nombres,
        provincia: item.Provincia,
        ciudad: item.Ciudad,
        direccion: item.Direccion,
        cod_farmacia: item.Cod_Farmacia,
        fecha_ingreso: item.Fecha_Ingreso,
        fecha_solicitud: item.Fecha_Solicitud,
        codarticulo: item.Cod_Articulo,
        cod_estado: item.Estado,
        dias: item.dias,
        cod_articulo_reposicion: item.Cod_Solicitud_Reposicion_Articulo,
        disabled: item.Estado === "" || item.Estado === "PE" || item.Estado === null || item.Estado === "RE" ? false : true,
        detalle:
          item.Detalle !== null
            ? JSON.parse(item.Detalle).map((item1: any, key: any) => ({
                key: key,
                codtipouniforme: item1.Cod_Tipo_Uniforme,
                nombre_tipouniforme: item1.Nombre,
                cantidad: item1.Cantidad,
                codtalla: item1.Cod_Talla,
                vunitario: item1.Valor_Unitario ? item1.Valor_Unitario : "0",
                keyrecord: key.toString(),
                disabled:
                  item.Estado === "" || item.Estado === "PE" || item.Estado === null || item.Estado === "RE" ? false : true,
              }))
            : [],
        tipo_articulo: item.Tipo_Articulo,
        imagellaves:
          item.Llaves !== null
            ? JSON.parse(item.Llaves).map((item1: any, key: any) => ({
                key: key,
                Llave: item1.Llave,
              }))
            : [],
        observacionrrhh: item.Observacion_RRHH,
        observacionaprobacion: item.Observacion_aprobacion,
      }))
      //console.log(newdata)
    }

    setData(newdata)
  },[fechasSeleccionadas,valueselect ])

  useEffect(() => {
    getTalla()
    getReporteUniformes()
    getKit() 
  }, [getReporteUniformes])

  

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    //console.log("selectedRowKeys changed: ", newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    getCheckboxProps: (item: any) => ({ disabled: item.disabled }),
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const columnsuniforme: ColumnsType<DataUniforme> = [
    {
      title: "Nombres",
      dataIndex: "nombre_tipouniforme",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
    },
    {
      title: "Talla",
      dataIndex: "codtalla",
      render: (item, key) => {
        let talla
        talla = itemselectTalla.filter((value) => value.value === key.codtalla)
        return <> {<label>{talla.length === 0 ? "" : talla[0].label}</label>}</>
      },
    },
  ]

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText("")
  }


  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  })

  const columns: ColumnsType<DataType> = [
    Table.EXPAND_COLUMN,
    {
      title: "Nombres",
      dataIndex: "nombre",
      width: "240px",
      ...getColumnSearchProps("nombre")
    },
    {
      title: "Provincia",
      dataIndex: "provincia",
      //width: "250px",
      sorter: (a, b) => a.provincia.localeCompare(b.provincia), 
    },
    {
      title: "Ciudad",
      dataIndex: "ciudad",
      //width: "250px",
      sorter: (a, b) => a.ciudad.localeCompare(b.ciudad), 
    },
    {
      title: "Farmacia",
      dataIndex: "cod_farmacia",
      render: (item)=>{
        let valorComoCadena = item.toString();
        // Calcular la cantidad de ceros que se deben agregar
        const cerosFaltantes = 3 - valorComoCadena.length;
        // Rellenar con ceros a la izquierda si es necesario
        if (cerosFaltantes > 0) {
          valorComoCadena = '0'.repeat(cerosFaltantes) + valorComoCadena;
        }
        return "FSG "+valorComoCadena}
    },
    {
      title: "F. Ingreso",
      dataIndex: "fecha_ingreso",     
    },
    {
      title: "F. Solicitud",
      dataIndex: "fecha_solicitud",
      width:'110px'
    },
    {
      title:"Kit",
      dataIndex:"",
      render: (item, key)=>{
        if(!key.codarticulo){
          key.codarticulo="";
        }
        
       const labelkit= itemselect.filter((item)=>item.Cod_Kit=key.codarticulo)//.map(({label})=>{label});
       //console.log(labelkit)
        return(<><label >{key.tipo_articulo==='KT'|| key.tipo_articulo===null?labelkit[0].Nombre
        :''}</label> </>)
    }
    },
    {
      title: "Solicitud Reposición",
      dataIndex: "",
      align: 'center',
      render: (keys, key)=>{
        return (key.tipo_articulo==="UF"?'Si':'No')//<CheckOutlined />:'')
      }
    },
    {
      title: "Estado",
      dataIndex: "cod_estado",
      width:'161px',
      align:'center',
      render: (cantidad, key) => {
        let estado, background, colorborder, color, colortag;
        if(key.cod_estado==='PE'){
          colortag='blue'
          colorborder = "#070E3B"//"#F15545"
          background="#D4DAFF"
            estado='PENDIENTE'
        } else if(key.cod_estado==='PP'){
          colortag='lime'
          colorborder = "#F4D03F"
          background='#FDFFBC'//'#F7FA92'
          estado='VALIDADO RRHH'
        }else if(key.cod_estado==='AP'){
          colortag='green'
          estado='APROBADO'
          background = "#D5F5E3 "
          colorborder="#27AE60"
        } else if(key.cod_estado==='RE'){
          colortag='red'
          colorborder ='#FC182D' //"#F99D3B"
          background= '#FFDCDF'//"#FFEAD4 "
          estado='RECHAZADO'
        }
        return (
          <>
            {key.cod_estado!=='PP'&&<Tag color={colortag} style={{color:color}}>{estado}</Tag>}
            {key.cod_estado==='PP'&& <label style={{fontSize: '11px',borderColor: colorborder,borderStyle: 'solid', color: color,borderRadius:'5px', padding:'2px', borderWidth:'1px', background: background}}> {estado}</label>}
          </>
        )
      }, //<Button icon={<DeleteOutlined />}></Button>,
    },
   
    {
      title: "",
      dataIndex: "",
      //width:'80px',
      render: (cantidad, key) => {
        return (
          <>
            {key.tipo_articulo==='UF'? <Tooltip title="Evidencia"><Button type={"primary"} size="small" onClick={() => {setVisible(true); setllavesimages(key.imagellaves===null?[]:key.imagellaves)}}><EyeOutlined /></Button></Tooltip>:''}
            </>
        )
      }, //<Button icon={<DeleteOutlined />}></Button>,
    },
  ]

  const handleDateChange = (dates: any, dateStrings: any) => {
    setFechasSeleccionadas(dateStrings)
  }

  const btnBuscar = () => {
    if (!fechasSeleccionadas[0] || !fechasSeleccionadas[1]) {
      swalWarning({ text: "Por favor Seleccione las fechas" })
      return
    }
    setExpandedRowKeys([])
    getReporteUniformes()
  }

  const handleChange=(value:string)=>{
    //console.log(value)
    setvalueselect(value)
  }

  const handleDownload = () => {
    const dataExport = data.map((item)=>{
      let addData: any;
      if(item.detalle!==null){
        item.detalle.forEach((item:any)=>{
          let nombre=item.nombre_tipouniforme.split(" ")
          //console.log(nombre.length);
          if(nombre.length>1){
            nombre=(nombre[0].charAt(0)+(nombre[1].charAt(0))).toUpperCase()
          }else{
            nombre=(nombre[0].charAt(0).toUpperCase())
          }
          
          const talla=itemselectTalla.filter((item1)=> {return item1.value === item.codtalla;})

          addData=({ ...addData,[item.nombre_tipouniforme]: item.cantidad, [nombre+"Talla"]: talla.length===0?'':talla[0].label});
        })
      }
       let  kit ="", estado
          itemselect.forEach((key) => {
          if (key.Cod_Kit === item.codarticulo && key.Cod_Kit !== "") {
            kit = key.Nombre;
          }
        });
        //console.log(kit)

        let valorComoCadena = item.cod_farmacia.toString();
        const cerosFaltantes = 3 - valorComoCadena.length;
        if (cerosFaltantes > 0) {
          valorComoCadena = '0'.repeat(cerosFaltantes) + valorComoCadena;
        }

      if(item.cod_estado==='PE'){
          estado='PENDIENTE'
      } else if(item.cod_estado==='PP'){
        estado='APROBADO RRHH'
      }else if(item.cod_estado==='AP'){
        estado='APROBADO'
      } else if(item.cod_estado==='RE'){
        estado='RECHAZADO'
      }
        return{
          Nombres: item.nombre,
          Provincia: item.provincia,
          Ciudad: item.ciudad,
          Direccion: item.direccion,
          FarmaciaN: "FSG "+valorComoCadena,
          Estado: estado,
          "Fecha de Ingreso": item.fecha_ingreso,
          "Fecha Solicitud": item.fecha_solicitud,
          Kit:kit,
          ...addData, 
        }
    })
    exportToExcel(dataExport, `Reporte_Solicitud_Uniformes_${moment().format("DDMMYYYY")}`)
  }

  const handleExpand = (expanded:boolean, record:DataType) => {
    //console.log("record"+ record.cod_estado)
    if (expanded) {
      setExpandedRowKeys([...ExpandedRowKeys, record.key.toString()])
    }else{
      setExpandedRowKeys([])
    }
  };

  
  return {
    handleDateChange,
    disabledDate,
    btnBuscar,
    onSelectChange,
    rowSelection,
    columns,
    data,
    columnsuniforme,
    llavesimages,
    visible,
    setVisible,
    handleChange,
    handleDownload,
    handleExpand,
    ExpandedRowKeys,
    fechasSeleccionadas
  }
}
