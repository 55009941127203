import { TableColumnsType, Tag } from "antd";
import { useLayout } from "hooks/otros/useLayout";
import { useCallback, useState, useEffect, useRef, useMemo } from "react";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import { optionsPOST, url_interna } from "config/constants";
import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
const breadcrumbs = [{ Label: "Productos", Url: "/productos" }, { Label: "Visor martillo" }];

const openKeys = [breadcrumbs[0].Label];

type VisorReporte = {
  Descripcion: string;
  LAB: string;
  PVP: string;
  PVPx: string;
  Descu: String;
  Stock: number;
  Minimos: number;
  Pedir: string;
  VENTA: number;
  R: String;
  Producto_Objetivo: String;
  key: React.Key;
};
type bodegasReporte = {
  key: React.Key;
  Cod_Bodega: number;
  Farmacia: string;
  CAJ: number;
  Frac: number;
  Min: number;
  MA: number;
  M1: number;
  M2: number;
  M3: number;
  M4: number;
  M5: number;
  M6: number;
  M7: number;
  M8: number;
};
export const useVisorMartillo = () => {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);
  const [datos, setDatos] = useState<VisorReporte[]>([]);
  const [datosBodega, setDatosBodega] = useState<bodegasReporte[]>([]);
  const { isError } = useVerifyResponse();

  const [searchText] = useState("");
  const [searchTextBodega] = useState("");
  const [searchTextLab] = useState("");

  const [filteredData, setFilteredData] = useState<VisorReporte[]>([]);
  const [filteredDataBodega, setFilteredDataBodega] = useState<bodegasReporte[]>([]);

  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<VisorReporte>();
  const { getColumnSearchPropsNew: getColumnSearchPropsNewBodegasReporte } = useSearchTableNew<bodegasReporte>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [isSecondTableVisible, setIsSecondTableVisible] = useState(false);

  const rowRefs = useRef<{ [key: string]: React.RefObject<HTMLTableRowElement> }>({});
  const tableContainerRef = useRef<HTMLDivElement>(null);



  const [activeRowIndex] = useState<number>(0);

  const getDataMartillo = useCallback(
    async (/*codProducto:React.Key*/) => {
      try {
        const res = await fetch(
          `${url_interna}api/productos/data_visor_martillo`,
          optionsPOST(JSON.stringify({}), {
            "api-token": "apitoken",
          })
        );
        if (isError(res)) throw new Error();
        const respuesta = await res.json();
        
        if (respuesta.msg !== "err") {
          const respuestas: VisorReporte[] = respuesta.data;

          setDatos(
            respuestas.map(
              ({ key, Descripcion, LAB, PVP, PVPx, Descu, Stock, Minimos, Pedir, VENTA, R, Producto_Objetivo }) => ({
                key,
                Descripcion,
                LAB,
                PVP,
                PVPx,
                Descu,
                Stock,
                Minimos,
                Pedir,
                VENTA,
                R,
                Producto_Objetivo,
              })
            )
          );
        } else {
          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        return { msg: "no_conexion", status: false };
      }
    },
    [isError]
  );

  useEffect(() => {
    getDataMartillo();
  }, [getDataMartillo]);

  const rowSelection = useMemo(
    () => ({
      onChange: (selectedRowKeys: React.Key[], selectedRows: VisorReporte[]) => {
        // Aquí puedes manejar cualquier lógica que necesites cuando cambia la selección
        
      },
    }),
    [
      /* dependencias si las hay */
    ]
  );

  const getDataBodega = useCallback(
    async (codProducto: React.Key) => {
      try {
        const res = await fetch(
          `${url_interna}api/productos/data_bodega_producto`,
          optionsPOST(JSON.stringify({ codProducto: codProducto }), {
            "api-token": "apitoken",
          })
        );
        if (isError(res)) throw new Error();
        const respuesta = await res.json();
        if (respuesta.msg !== "err") {
          const respuestas: bodegasReporte[] = respuesta.data;
         
          setDatosBodega(
            respuestas.map(({ Cod_Bodega, Farmacia, CAJ, Frac, Min, MA, M1, M2,  M3, M4, M5, M6, M7, M8,key }) => ({
              Cod_Bodega,
              Farmacia,
              CAJ,
              Frac,
              Min,
              MA,
              M1,
              M2,
              M3,
              M4,
              M5,
              M6,
              M7,
              M8,
              key
            }))
          );
        } else {
          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        return { msg: "no_conexion", status: false };
      }
    },
    [isError]
  );
  const modifiedRowSelection = useMemo(
    () => ({
      ...rowSelection,
      selectedRowKeys,
      onChange: (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
        getDataBodega(newSelectedRowKeys[0]);
        setIsSecondTableVisible(true);
        // Aquí puedes manejar cualquier otra lógica que necesites cuando cambia la selección
      },
    }),
    [rowSelection, selectedRowKeys, getDataBodega]
  );

  /*const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (tableContainerRef.current && (event.key === "ArrowDown" || event.key === "ArrowUp")) {
      const tableRect = tableContainerRef.current.getBoundingClientRect();
      let currentSelectedIndex = datos.findIndex(item => item.key === selectedRowKeys[0]);
  
      let newIndex = currentSelectedIndex;
      if (event.key === "ArrowDown") {
        newIndex = Math.min(currentSelectedIndex + 1, datos.length - 1);
      } else if (event.key === "ArrowUp") {
        newIndex = Math.max(currentSelectedIndex - 1, 0);
      }
  
      const newSelectedRowKey = datos[newIndex]?.key;
      if (newSelectedRowKey !== undefined) {
        modifiedRowSelection.onChange([newSelectedRowKey]); // Esto se mantiene para actualizar los datos de la otra tabla
  
        const newSelectedRowRef = rowRefs.current[newSelectedRowKey];
        if (newSelectedRowRef && newSelectedRowRef.current) {
          const rowRect = newSelectedRowRef.current.getBoundingClientRect();
          
          // Calcular si la fila está fuera del viewport.
          const isRowAbove = rowRect.bottom < tableRect.top + rowRect.height;
          const isRowBelow = rowRect.top > tableRect.bottom - rowRect.height;
  
          if (isRowAbove || isRowBelow) {
            newSelectedRowRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest'
            });
          }
        }
  
        // No es necesario llamar a setSelectedRowKeys si modifiedRowSelection.onChange ya maneja la selección
      }
    }
  }, [selectedRowKeys, datos, modifiedRowSelection, rowRefs, tableContainerRef]);*/
  
  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (tableContainerRef.current) {
      const tableScrollRect = tableContainerRef.current.getBoundingClientRect();
      let currentSelectedIndex = datos.findIndex(item => item.key === selectedRowKeys[0]);
      let nextIndex = currentSelectedIndex + (event.key === "ArrowDown" ? 1 : -1);
      nextIndex = Math.max(0, Math.min(nextIndex, datos.length - 1));
  
      const nextRowKey = datos[nextIndex]?.key;
      if (nextRowKey !== undefined) {
        const nextRowRef = rowRefs.current[nextRowKey];
  
        if (nextRowRef && nextRowRef.current) {
          const rowRect = nextRowRef.current.getBoundingClientRect();
          const rowIsNotFullyVisible =
            rowRect.top < tableScrollRect.top ||
            rowRect.bottom > tableScrollRect.bottom;
  
          if (rowIsNotFullyVisible) {
            const scrollPosition =
              tableContainerRef.current.scrollTop +
              (rowRect.top < tableScrollRect.top ? rowRect.top - tableScrollRect.top : rowRect.bottom - tableScrollRect.bottom);
  
            tableContainerRef.current.scrollTop = scrollPosition;
          }
        }
  
        modifiedRowSelection.onChange([nextRowKey]);
      }
    }
  }, [selectedRowKeys, datos, modifiedRowSelection, rowRefs, tableContainerRef]);
  
  
  
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  // Asegúrate de actualizar la selección de filas cuando cambie activeRowIndex
  useEffect(() => {
    const newSelectedRowKey = datos[activeRowIndex]?.key;
    if (newSelectedRowKey !== undefined) {
      setSelectedRowKeys([datos[activeRowIndex]?.key]);
      //setSelectedRowKeys([newSelectedRowKey]);
      getDataBodega(newSelectedRowKey);
      setIsSecondTableVisible(true);
    }
  }, [activeRowIndex, datos, getDataBodega]);

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos);
    } else {
      const filtered = datos.filter(
        (item) => item.Descripcion && item.Descripcion.toString().toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  useEffect(() => {
    if (!searchTextLab) {
      setFilteredData(datos);
    } else {
      const filtered = datos.filter(
        (item) => item.LAB && item.LAB.toString().toLowerCase().includes(searchTextLab.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchTextLab, datos]);

  useEffect(() => {
    if (!searchTextBodega) {
      setFilteredDataBodega(datosBodega);
    } else {
      const filtered = datosBodega.filter(
        (item) => item.Farmacia && item.Farmacia.toString().toLowerCase().includes(searchTextBodega.toLowerCase())
      );
      setFilteredDataBodega(filtered);
    }
  }, [searchTextBodega, datosBodega]);

  const columns: TableColumnsType<VisorReporte> = [
    {
      title: "Descripción",
      className: "letraPequenaVisor",
      dataIndex: "Descripcion",
      key: "Descripcion",
      width: "200px",
      fixed: "left",
      ...getColumnSearchPropsNewVisorReporte("Descripcion"),
    },
    {
      title: "LAB",
      dataIndex: "LAB",
      className: "letraPequenaVisor",
      key: "LAB",
      ...getColumnSearchPropsNewVisorReporte("LAB"),
    },
    {
      title: "PVP",
      dataIndex: "PVP",
      className: "letraPequenaVisor",
      key: "PVP",
      sortDirections: ["descend", "ascend"],

      sorter: (a, b) => a.PVP.localeCompare(b.PVP),
    },
    {
      title: "PVPx",
      dataIndex: "PVPx",
      className: "letraPequenaVisor",
      key: "PVPx",
      sorter: (a, b) => a.PVPx.localeCompare(b.PVPx),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Descu",
      dataIndex: "Descu",
      className: "letraPequenaVisor",
      key: "Descu",
      sorter: (a, b) => a.Descu.localeCompare(b.Descu.toString()),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Stock",
      dataIndex: "Stock",
      className: "letraPequenaVisor",
      key: "Stock",
      sorter: (a, b) => a.Stock - b.Stock,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Mínimos",
      dataIndex: "Minimos",
      className: "letraPequenaVisor",
      key: "Minimos",
      sorter: (a, b) => a.Minimos - b.Minimos,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Pedir",
      dataIndex: "Pedir",
      className: "letraPequenaVisor",
      key: "¨Pedir",
      sorter: (a, b) => a.Pedir.localeCompare(b.Pedir),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "VENTA",
      dataIndex: "VENTA",
      className: "letraPequenaVisor",
      key: "VENTA",
      sorter: (a, b) => a.VENTA - b.VENTA,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "R",
      dataIndex: "R",
      className: "letraPequenaVisor",
      key: "R",
      width: "40px",
    },
    {
      title: "Plan",
      dataIndex: "Producto_Objetivo",
      className: "letraPequenaVisor",
      key: "Producto_Objetivo",
      fixed: "right",
      width: "100px",
      filters: [
        {
          text: "Golpe bajo",
          value: "G",
        },
        { text: "Billeteate", value: "B" },
        { text: "Medicación", value: "M" },
      ],
      onFilter: (value, record) => record.Producto_Objetivo.indexOf(value as string) !== -1,

      render: (value: string) => {
        const uniqueChars = Array.from(new Set(value.split("")));
        return uniqueChars
          .map((char) => {
            switch (char) {
              case "B":
                return (
                  <Tag color="#AD352C" key="B">
                    Billeteate
                  </Tag>
                );
              case "G":
                return (
                  <Tag color="#6A538A" key="G">
                    Golpe bajo
                  </Tag>
                );
              case "M":
                return (
                  <Tag color="#8ed8e5" key="M">
                    Medicación
                  </Tag>
                );
              default:
                return null;
            }
          })
          .filter((tag) => tag !== null);
      },
    },
  ];

  const onRowClick = useCallback(
    (record: VisorReporte) => {
      setSelectedRowKeys([record.key]);
      getDataBodega(record.key);
      setIsSecondTableVisible(true);
    },
    [getDataBodega]
  );
  const columnsBodega: TableColumnsType<bodegasReporte> = [
    {
      title: "Farmacia",
      dataIndex: "Farmacia",
      className: "letraPequenaVisor",
      key: "Farmacia",
      width: "170px",
      fixed: "left",
      ...getColumnSearchPropsNewBodegasReporte("Farmacia"),
    },
    {
      title: "Caj",
      dataIndex: "CAJ",
      className: "letraPequenaVisor",
      key: "CAJ",
      sorter: (a, b) => a.CAJ - b.CAJ,
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchPropsNewBodegasReporte("CAJ"),
    },
    {
      title: "Frac",
      dataIndex: "Frac",
      className: "letraPequenaVisor",
      key: "Frac",
      sorter: (a, b) => a.Frac - b.Frac,
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchPropsNewBodegasReporte("Frac"),
    },
    {
      title: "Min",
      dataIndex: "Min",
      className: "letraPequenaVisor",
      key: "Min",
      sorter: (a, b) => a.Min - b.Min,
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchPropsNewBodegasReporte("Min"),
    },
    {
      title: "MA",
      dataIndex: "MA",
      className: "letraPequenaVisor",
      key: "MA",
      sorter: (a, b) => a.MA - b.MA,
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchPropsNewBodegasReporte("MA"),
    },
    {
      title: "M1",
      dataIndex: "M1",
      className: "letraPequenaVisor",
      key: "M1",
    },
    {
      title: "M2",
      dataIndex: "M2",
      className: "letraPequenaVisor",
      key: "M2",
    },
    {
      title: "M3",
      dataIndex: "M3",
      className: "letraPequenaVisor",
      key: "M3",
    },
    {
      title: "M4",
      dataIndex: "M4",
      className: "letraPequenaVisor",
      key: "M4",
    },
    {
      title: "M5",
      dataIndex: "M5",
      className: "letraPequenaVisor",
      key: "M5",
    },
    {
      title: "M6",
      dataIndex: "M6",
      className: "letraPequenaVisor",
      key: "M6",
    },
    {
      title: "M7",
      dataIndex: "M7",
      className: "letraPequenaVisor",
      key: "M7",
    },
    {
      title: "M8",
      dataIndex: "M8",
      className: "letraPequenaVisor",
      key: "M8",
    },
  ];
  return {
    columns,
    filteredData,
    modifiedRowSelection,
    onRowClick,
    isSecondTableVisible,
    columnsBodega,
    filteredDataBodega,
    activeRowIndex,
    tableContainerRef
  };
};
