import { ReportTable, type columnsTablePDF } from "components/reactreport/report-table"
import { GastosFijos } from "components/franquiciados/obtener-gastos"
import type { Style } from "@react-pdf/types"

type Props = {
  gastos: GastosFijos[]
  commonColumnStyle: Style
  rowHeaderStyle: Style
}

export function GastosTable({ gastos, commonColumnStyle, rowHeaderStyle }: Props) {
  const columnsGastos: columnsTablePDF<GastosFijos>[] = [
    {
      title: "Descripción",
      dataIndex: "gasto",
      key: "gasto",
      styleColumn: { ...commonColumnStyle, padding: 3, textAlign: "left", flexBasis: "75%" },
      styleColumnHeader: { ...commonColumnStyle, padding: 3, textAlign: "left", flexBasis: "75%" },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      styleColumn: { ...commonColumnStyle, padding: 3, textAlign: "right", flexBasis: "25%", margin: "auto 0" },
      styleColumnHeader: { ...commonColumnStyle, padding: 3, textAlign: "right", flexBasis: "25%" },
      fixed: true,
    },
  ]

  return (
    <ReportTable
      columns={columnsGastos}
      dataSource={gastos}
      styleContainer={{ width: "50%", paddingRight: "5px" }}
      styleRowHeader={rowHeaderStyle}
    />
  )
}
