import { useEffect, useState } from "react"
import { optionsPost, optionsGet, swalWarning, swalSuccess } from "config/methods"
import { url } from "config/constants"
import { Moment } from "moment"
import moment from "moment"

export function useRegistroTareas() {
  const [databodega, setdatabodega] = useState([{ label: "Seleccione una Farmacia", value: "" }])
  const [codBodega, setcodBodega] = useState("")
  const [dataUsuarios, setdataUsuarios] = useState([{ label: "Seleccione un Solicitante", value: "" }])
  const [codUsuario, setCodUsuario] = useState("")
  const [dataIncidente, setdataIncidente] = useState([{ label: "Seleccione un Tipo de Incidente", value: "" }])
  const [CodIncidente, setCodIncidente] = useState("")
  const [dataSoporte, setdataSoporte] = useState([{ label: "Seleccione un Usuario", value: "" }])
  const [codUsuarioSoporte, setcodUsuarioSoporte] = useState("")
  const [valueDescripcion, setvalueDescripcion] = useState('')
  const [valueFecha, setvalueFecha] = useState(moment())

  const getbodegas = async () => {
    try {
      const res = await fetch(`${url}fapifsg-pr/sistemas/getFarmacias`, optionsGet()).then((data) => data.json())
      //let respuesta=res.data;
      let newget = [{ value: "", label: "Seleccione una Farmacia" }]
      res.forEach((item: any) => {
        let add = {
          value: item.Cod_Bodega,
          label: item.Descripcion,
        }
        newget = [...newget, add]
      })
      setdatabodega(newget)
    } catch (error) {
      console.log(error)
    }
  }

  const getUsuarioSoporte = async () => {
    try {
      const res = await fetch(`${url}fapifsg-pr/sistemas/getUsuarioSoporte`, optionsGet()).then((data) => data.json())
      //let respuesta=res.data;
      let newget = [{ value: "", label: "Seleccione un Usuario" }]
      res.forEach((item: any) => {
        let add = {
          value: item.Cod_Usuario,
          label: item.Nombres,
        }
        newget = [...newget, add]
      })
      setdataSoporte(newget)
    } catch (error) {
      console.log(error)
    }
  }
  
  const onchangeSolicitante = (value: string) => {
    setCodUsuario(value)
  }

  const onchangeTipoIncidente = (value: string) => {
    setCodIncidente(value)
  }

  const onchangeUsuarioSoporte = (value: string) => {
    setcodUsuarioSoporte(value)
  }

  const onchangeBodega = async (value: string) => {
    setcodBodega(value)
    try {
      const res = await fetch(`${url}fapifsg-pr/sistemas/getUsuarioBodega`, optionsPost({ cod_bodega: value })).then(
        (data) => data.json()
      )
      //let respuesta=res.data;
      let newget = [{ value: "", label: "Seleccione un Solicitante" }]
      res.forEach((item: any) => {
        let add = {
          value: item.Cod_Usuario,
          label: item.Nombres,
        }
        newget = [...newget, add]
      })
      setdataUsuarios(newget)
    } catch (error) {
      console.log(error)
    }
  }

  const tiposIncidentes = async () => {
    try {
      const res = await fetch(`${url}fapifsg-pr/sistemas/getTipoIncidentes`, optionsGet()).then((data) => data.json())
      //let respuesta=res.data;
      let newget = [{ value: "", label: "Seleccione un Tipo de Incidente" }]
      res.forEach((item: any) => {
        let add = {
          value: item.Cod_Tipo_Incidente,
          label: item.Descripcion,
        }
        newget = [...newget, add]
      })
      setdataIncidente(newget)
    } catch (error) {
      console.log(error)
    }
  }

  const disableFutureDates = (current: Moment) => {
    // Deshabilitar fechas después de hoy
    return current && current > moment().endOf("day")
  }

  useEffect(() => {
    getbodegas()
    tiposIncidentes()
    getUsuarioSoporte()
  }, [])

  const handleFilter = (input: any, option: any) => {
    return option.label.toLowerCase().includes(input.toLowerCase())
  }

  const onchangeDescripcion = (event: any) => {
    let newValue = event.target.value
    setvalueDescripcion(newValue)
  }

  const onchagedate = (date: any, dates: any) => {
    console.log(dates)
    setvalueFecha(date ?? moment())
  }

  const btn_Guardar = async () => {
    try {
      if (
        codBodega === "" ||
        codUsuario === "" ||
        codUsuarioSoporte === "" ||
        valueFecha === null ||
        valueDescripcion === "" ||
        CodIncidente === ""
      ) {
        swalWarning({ text: "Faltan Datos para guardar" })
      }

      const res = await fetch(
        `${url}fapifsg-pr/sistemas/saveTareasSoporte`,
        optionsPost({
          fecha: valueFecha,
          cod_tipo_incidente: CodIncidente,
          cod_usuario_solicita: codUsuario,
          cod_bodega: codBodega,
          descripcion: valueDescripcion,
          estado: codUsuarioSoporte ? "EN PROCESO" : "",
          usuario_asignado: codUsuarioSoporte,
        })
      ) //.then((data) => data.json())

      if (res.status === 200) {
        swalSuccess({ text: "Tarea Registrada con éxito" })
        setvalueFecha(moment())
        setCodIncidente('')
        setCodUsuario('')
        setcodBodega('')
        setvalueDescripcion("")
        setcodUsuarioSoporte('')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    databodega,
    dataUsuarios,
    onchangeBodega,
    codBodega,
    codUsuario,
    dataIncidente,
    CodIncidente,
    dataSoporte,
    codUsuarioSoporte,
    onchangeSolicitante,
    onchangeTipoIncidente,
    onchangeUsuarioSoporte,
    disableFutureDates,
    handleFilter,
    onchangeDescripcion,
    valueDescripcion,
    onchagedate,
    valueFecha,
    btn_Guardar,
  }
}
