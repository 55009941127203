import MantenimientosAprobados from "pages/contentmantenimientos/aprobados";
import MantenimientosPendientes from "pages/contentmantenimientos/pendientes";
import { Principal } from "pages/contentprincipal/Principal";
import { RouteObject } from "react-router-dom";

export const rutasMantenimientos: RouteObject[] = [
  {
    path: "/mantenimientos",
    element: <Principal />,
  },
  {
    path: "/mantenimientos/pendientes",
    element: <MantenimientosPendientes />,
  },
  {
    path: "/mantenimientos/aprobados",
    element: <MantenimientosAprobados />,
  },
  
  /*{
    path: "/indicadores/cumplimiento-convenios",
    element: <CumplimientoConvenios />,
  },*/
];
