import { Moment } from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { /*llave,*/ url } from "../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";

import { useLayout } from "hooks/otros/useLayout";
//import { useExport } from "hooks/otros/useExport";
import { DatePickerProps, RangePickerProps } from "antd/lib/date-picker";
import { optionsGet, swalError, swalSuccess } from "config/methods";
import { EditOutlined, EyeOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
//import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Form, InputRef, Popconfirm } from "antd";
//import { AES } from "crypto-js";
import { useScreenSize } from "hooks/otros/useScreenSize";

import "./alertaEstilo.css";

type TopFarmaciasMasPerdidas = {
  id_cuestionario: number;
  idss: number;
  titulo: string;
  descripcion: string;
  activo: boolean;
  fecha_asignacion: string;
  fecha_limite: string;
};
interface FormValues {
  titulo: string;
  rangoFecha: [Moment | null, Moment | null]; // Utilizando Moment.js para las fechas
  seccion: number; // Asegúrate de que el tipo aquí sea correcto, podría ser 'number' si los IDs de sección son numéricos
}
interface Tarea {
  titulo: string;
  descripcion: string;
  fechaInicio: Moment | null;
  fechaFin: Moment | null;
  seccionId: number | undefined; // Guarda el ID de la sección
  seccionNombre: string; // Guarda el nombre de la sección para visualización
  preguntas: Pregunta[];
}
interface Pregunta {
  descripcion: string;
  tipoPreguntaId: number;
  categoriaId: number;
  respuestas?: number[]; // IDs de las respuestas seleccionadas, solo relevante si tipoPreguntaId permite múltiples respuestas
}
interface PreguntasInicial {
  id_pregunta: number;
  nombre_pregunta: string;
  tipo_pregunta: number;
}
interface TipoPreguntaInicial {
  value: number;
  label: string;
}
interface CategoriaInicial {
  value: number;
  label: string;
}

const breadcrumbs = [
  { Label: "Marketing", Url: "/marketing" },
  { Label: "Sherlock", Url: "/marketing/sherlock" },
  { Label: "Tareas" },
];
interface Seccion {
  id_seccion: number;
  nombre_seccion: string;
}
interface PreguntaSeleccionada {
  id: number;
  label: string;
}
interface Opcion {
  id_opcion: number;
  nombre_opcion: string;
}
interface DatosCuestionario {
  secciones: Seccion[];
  preguntas: PreguntasInicial[];
  opciones: Opcion[];
  categorias: CategoriaInicial[];
  tipos_pregunta: TipoPreguntaInicial[];
}
const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];
export function useAdminTareasSherlock() {
  //const navigate = useNavigate();
  useLayout(breadcrumbs, openKeys, breadcrumbs[1].Label + "/" + breadcrumbs[2].Label);
  const [datosInicial, setDatos] = useState<TopFarmaciasMasPerdidas[]>([]);
  const [dataEstado, setDataEstado] = useState<boolean>(false);
  const [form] = Form.useForm<FormValues>();
  const { width } = useScreenSize();
  const [tarea, setTarea] = useState<Tarea>({
    titulo: "",
    descripcion: "",
    fechaInicio: null,
    fechaFin: null,
    seccionId: undefined, // Cambiado de 0 a null
    seccionNombre: "",
    preguntas: [],
  });
  const isWeb = width >= 778;
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<TopFarmaciasMasPerdidas[]>([]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [segmentoSeleccionado, setSegmentoSeleccionado] = useState("Farmacias perdidas");
  const inputRef = useRef<InputRef>(null);
  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [datosCuestionario, setDatosCuestionario] = useState<DatosCuestionario>({
    secciones: [],
    preguntas: [],
    opciones: [],
    categorias: [],
    tipos_pregunta: [],
  });
  const [estadoBoton, setBoton] = useState<boolean>(false);

  // const { exportToExcel } = useExport();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();
  const [items] = useState<Seccion[]>([]);
  const [categorias] = useState<CategoriaInicial[]>([]);

  const [tiposPregunta] = useState<TipoPreguntaInicial[]>([]);
  const [itemsPreguntasInicial] = useState<PreguntasInicial[]>([]);
  const [opcionesRespuestas] = useState<Opcion[]>([]);
  const [respuestasSeleccionadas, setRespuestasSeleccionadas] = useState([]);

  const [preguntaSeleccionada, setPreguntaSeleccionada] = useState<PreguntaSeleccionada>();

  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState<number>(
    categorias.length > 0 ? categorias[0].value : -1
  );
  const [tipoPreguntaSeleccionada, setTipoPreguntaSeleccionada] = useState<number>(
    tiposPregunta.length > 0 ? tiposPregunta[0].value : -1
  );

  const [name, setName] = useState("");

  const getBodegas = useCallback(async () => {
    setDataEstado(false);
    try {
      const res = await fetch(url + "atencion_cliente/get_listado_cuestionarios", optionsGet());
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        if (respuesta.cuestionarios) {
          const respuestas: TopFarmaciasMasPerdidas[] = respuesta.cuestionarios;

          setDatos(
            respuestas.map(({ id_cuestionario, idss, titulo, descripcion, activo, fecha_asignacion, fecha_limite }) => ({
              id_cuestionario,
              idss,
              titulo,
              descripcion,
              activo,
              fecha_asignacion,
              fecha_limite,
            }))
          );
        } else {
          setDatos([]);
        }

        setDataEstado(true);
        setBoton(true);
      } else {
        setDataEstado(false);
        setDatos([]);
        setBoton(false);

        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setDataEstado(false);
      setDatos([]);
      setBoton(false);
      return { msg: "no_conexion", status: false };
    }
  }, [isError]);

  const getSeccionPreguntas = async (id_cuestionario: number): Promise<{ msg: string; status: boolean }> => {
    try {
      const response = await fetch(
        `${url}atencion_cliente/listar_secciones_preguntas_opciones/${id_cuestionario}`,
        optionsGet()
      );
      const data = await response.json();

      if (response.ok && data.msg !== "err") {
        if (data.respuesta && data.respuesta.length > 0) {
          const responseData = data.respuesta[0]; // Accede al primer elemento del array respuesta

          const mappedData: DatosCuestionario = {
            secciones: responseData.secciones.map((seccion: { id_seccion: number; nombre_seccion: string }) => ({
              id_seccion: seccion.id_seccion,
              nombre_seccion: seccion.nombre_seccion,
            })),
            preguntas: responseData.preguntas.map(
              (pregunta: { id_pregunta: number; nombre_pregunta: string; tipo_pregunta: number }) => ({
                id_pregunta: pregunta.id_pregunta,
                nombre_pregunta: pregunta.nombre_pregunta,
                tipo_pregunta: pregunta.tipo_pregunta,
              })
            ),
            opciones: responseData.opciones.map((opcion: { id_opcion: number; nombre_opcion: string }) => ({
              id_opcion: opcion.id_opcion,
              nombre_opcion: opcion.nombre_opcion,
            })),
            categorias: responseData.categorias.map((categoria: { value: number; label: string }) => ({
              value: categoria.value,
              label: categoria.label,
            })),
            tipos_pregunta: responseData.tipos_pregunta.map((tipo: { value: number; label: string }) => ({
              value: tipo.value,
              label: tipo.label,
            })),
          };

          setDatosCuestionario(mappedData);

          return { msg: "Datos cargados con éxito", status: true };
        } else {
          return { msg: "Datos no encontrados o estructura de respuesta incorrecta", status: false };
        }
      } else {
        return { msg: "Datos no encontrados", status: false };
      }
    } catch (error) {
      console.error("Error en getSeccionPreguntas:", error);
      return { msg: error instanceof Error ? error.message : "Error desconocido", status: false };
    }
  };

  /*const handleClickDelete = (Cod_Reembolso: number) => {
    Swal.fire({
      title: "¿Está seguro?",
      text: "Se eliminará para siempre",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "var(--primary)",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        //DeleteReembolso(Cod_Reembolso)
      }
    });
  };*/
  const showModal = async () => {
    setIsModalOpen(true);
    try {
      const datosCargados = await getSeccionPreguntas(0); // Suponemos que '0' es un ID dummy
      if (datosCargados.status) {
      } else {
        throw new Error(datosCargados.msg);
      }
    } catch (error) {
      console.error(
        "Error al cargar datos del cuestionario: ",
        error instanceof Error ? error.message : "Error desconocido"
      );
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No se pudieron cargar los datos del cuestionario!",
      });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    limpiar();
  };
  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const onNameChangePreguntas = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const onNameChangeCategorias = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const onNameChangeRespuestas = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (name !== "") {
      crearSeccion(0, name);
    } else {
      swalError({
        text: "Error, no puede ir vacía",
        customClass: {
          container: "swal2-custom",
        },
      });
    }
  };
  const crearSeccion = async (id_seccion: number, nombre_seccion: string) => {
    try {
      const result = await fetch(
        `${url}atencion_cliente/grabar_seccion`,
        optionsPost({ id: id_seccion, seccion: nombre_seccion })
      );

      if (isError(result)) throw new Error();
      const respuesta = await result.json();

      if (respuesta.msg !== "err") {
        // setItems([]);
        const respuestas: Seccion[] = respuesta.secciones;
        setDatosCuestionario((state) => ({
          ...state,
          secciones: respuestas.map(({ id_seccion, nombre_seccion }) => ({
            id_seccion,
            nombre_seccion,
          })),
        }));

        swalSuccess({
          text: "Registrado con éxito",
          customClass: {
            container: "swal2-custom",
          },
        });
      } else {
        swalError({
          text: "Error al registrar",
          customClass: {
            container: "swal2-custom",
          },
        });
        return { msg: "Ocurrio un error", status: false };
      }

      // reFetcher();
    } catch (e) {
      swalError({
        text: "No se pudo registrar",
        customClass: {
          container: "swal2-custom",
        },
      });
    } /*finally {
      setOkButtonDisabled(false);
    }*/
  };
  const crearCategoria = async (id_categoria: number, nombre_categoria: string) => {
    try {
      const result = await fetch(
        `${url}atencion_cliente/grabar_categoria`,
        optionsPost({ id: id_categoria, categoria: nombre_categoria })
      );

      if (isError(result)) throw new Error();
      const respuesta = await result.json();

      if (respuesta.msg !== "err") {
        // setItems([]);
        const respuestas: CategoriaInicial[] = respuesta.categorias;
        setDatosCuestionario((state) => ({
          ...state,
          categorias: respuestas.map(({ value, label }) => ({
            value,
            label,
          })),
        }));

        swalSuccess({
          text: "Registrado con éxito",
          customClass: {
            container: "swal2-custom",
          },
        });
      } else {
        swalError({
          text: "Error al registrar",
          customClass: {
            container: "swal2-custom",
          },
        });
        return { msg: "Ocurrio un error", status: false };
      }

      // reFetcher();
    } catch (e) {
      swalError({
        text: "No se pudo registrar",
        customClass: {
          container: "swal2-custom",
        },
      });
    } /*finally {
      setOkButtonDisabled(false);
    }*/
  };
  const crearPregunta = async (
    id_pregunta: number,
    nombre_pregunta: string,
    id_categoria: number,
    id_tipo_pregunta: number
  ) => {
    try {
      const result = await fetch(
        `${url}atencion_cliente/grabar_pregunta`,
        optionsPost({ pregunta: nombre_pregunta, id: id_pregunta, categoria: id_categoria, tipo: id_tipo_pregunta })
      );

      if (isError(result)) throw new Error();
      const respuesta = await result.json();

      if (respuesta.msg !== "err") {
        // setItems([]);
        const respuestas: PreguntasInicial[] = respuesta.preguntas;
        setDatosCuestionario((state) => ({
          ...state,
          preguntas: respuestas.map(({ id_pregunta, nombre_pregunta, tipo_pregunta }) => ({
            id_pregunta,
            nombre_pregunta,
            tipo_pregunta,
          })),
        }));

        swalSuccess({
          text: "Registrado con éxito",
          customClass: {
            container: "swal2-custom",
          },
        });
      } else {
        swalError({
          text: "Error al registrar",
          customClass: {
            container: "swal2-custom",
          },
        });
        return { msg: "Ocurrio un error", status: false };
      }

      // reFetcher();
    } catch (e) {
      swalError({
        text: "No se pudo registrar",
        customClass: {
          container: "swal2-custom",
        },
      });
    } /*finally {
      setOkButtonDisabled(false);
    }*/
  };
  const crearOpcion = async (id_opcion: number, nombre_opcion: string) => {
    try {
      const result = await fetch(
        `${url}atencion_cliente/grabar_opcion`,
        optionsPost({ id: id_opcion, opcion: nombre_opcion })
      );

      if (isError(result)) throw new Error();
      const respuesta = await result.json();

      if (respuesta.msg !== "err") {
        // setItems([]);
        const respuestas: Opcion[] = respuesta.opciones;
        setDatosCuestionario((state) => ({
          ...state,
          opciones: respuestas.map(({ id_opcion, nombre_opcion }) => ({
            id_opcion,
            nombre_opcion,
          })),
        }));

        swalSuccess({
          text: "Registrado con éxito",
          customClass: {
            container: "swal2-custom",
          },
        });
      } else {
        swalError({
          text: "Error al registrar",
          customClass: {
            container: "swal2-custom",
          },
        });
        return { msg: "Ocurrio un error", status: false };
      }

      // reFetcher();
    } catch (e) {
      swalError({
        text: "No se pudo registrar",
        customClass: {
          container: "swal2-custom",
        },
      });
    } /*finally {
      setOkButtonDisabled(false);
    }*/
  };
  const addItemPregunta = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (name !== "" && categoriaSeleccionada !== -1 && tipoPreguntaSeleccionada !== -1) {
      crearPregunta(0, name, categoriaSeleccionada, tipoPreguntaSeleccionada);
    } else {
      swalError({
        text: "No se puede agregar la pregunta",
        customClass: {
          container: "swal2-custom",
        },
      });
    }
  };
  const addItemCategorias = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (name !== "") {
      crearCategoria(0, name);
    } else {
      swalError({
        text: "Error, no puede ir vacía",
        customClass: {
          container: "swal2-custom",
        },
      });
    }
  };
  const addItemRespuesta = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (name !== "") {
      crearOpcion(0, name);
    } else {
      swalError({
        text: "Error, no puede ir vacía",
        customClass: {
          container: "swal2-custom",
        },
      });
    }
  };
  const actions = (idCuestionario: number) => {
    // const idCuestionarios = encodeURIComponent(AES.encrypt(idCuestionario.toString(), llave).toString());

    return [
      <EditOutlined
        key="edit"
        //onClick={() => navigate(`/editar-reembolso/${idCuestionarios}`)}
        style={{ padding: "8px" }}
      />,
      <EyeOutlined
        key="see"
        //onClick={() => navigate(`/ver-reembolso/${idCuestionarios}`)}
        style={{ padding: "8px" }}
      />,
      isWeb ? (
        <Popconfirm
          key="delete"
          title={"¿Está seguro de eliminar esta tarea?"}
          okText="Yes"
          cancelText="No"
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        >
          <DeleteOutlined style={{ padding: "8px" }} />
        </Popconfirm>
      ) : (
        <DeleteOutlined
          key="delete"
          //onClick={() => handleClickDelete(idCuestionario)}
          style={{ padding: "8px" }}
        />
      ),
    ];
  };
  const limpiar = () => {
    form.setFieldValue("tipoPregunta", null);
    form.setFieldValue("pregunta", null);
    form.setFieldValue("categoria", null);
    form.setFieldValue("respuestas", undefined);
    form.resetFields(["tipoPregunta", "pregunta", "categoria", "respuestas"]);

    setTipoPreguntaSeleccionada(-1);
    setCategoriaSeleccionada(-1);
    setPreguntaSeleccionada(undefined);
    setRespuestasSeleccionadas([]);
  };
  const agregarPregunta = useCallback(() => {
    if (!preguntaSeleccionada) {
      console.error("No se ha seleccionado ninguna pregunta.");
      return null; // Detener la ejecución si no hay pregunta seleccionada
    }

    const nuevaPregunta = {
      id_pregunta: preguntaSeleccionada.id,
      descripcion: preguntaSeleccionada.label,
      tipoPreguntaId: tipoPreguntaSeleccionada,
      categoriaId: categoriaSeleccionada,
      respuestas: respuestasSeleccionadas,
    };

    setTarea((prev) => ({
      ...prev,
      preguntas: [...prev.preguntas, nuevaPregunta],
    }));
    form.setFieldValue("tipoPregunta", null);
    form.setFieldValue("pregunta", null);
    form.setFieldValue("categoria", null);
    form.setFieldValue("respuestas", undefined);
    setTipoPreguntaSeleccionada(-1);
    setCategoriaSeleccionada(-1);
    setPreguntaSeleccionada(undefined);
    setRespuestasSeleccionadas([]);
  }, [preguntaSeleccionada, tipoPreguntaSeleccionada, categoriaSeleccionada, respuestasSeleccionadas, form]);

  useEffect(() => {
    getBodegas();
  }, [getBodegas]);
  const onChange: RangePickerProps["onChange"] = (dates, dateString) => {
    if (dates) {
      form.setFieldsValue({ rangoFecha: dates });
      setTarea((prev) => ({
        ...prev,
        fechaInicio: dates[0],
        fechaFin: dates[1],
      }));
    } else {
      setTarea((prev) => ({
        ...prev,
        fechaInicio: null,
        fechaFin: null,
      }));
    }
  };
  const handleSeccionChange = (value: number) => {
    const seccion = datosCuestionario.secciones.find((item) => item.id_seccion === value);

    if (seccion) {
      setTarea((prev) => ({ ...prev, seccionId: seccion.id_seccion, seccionNombre: seccion.nombre_seccion }));
    } else {
      setTarea((prev) => ({ ...prev, seccionId: undefined, seccionNombre: "" })); // Asignar undefined en lugar de null
    }
  };
  const handlePreguntaChange = (value: number) => {
    const seleccion = datosCuestionario.preguntas.find((item) => item.id_pregunta === value);

    if (seleccion) {
      setPreguntaSeleccionada({ id: seleccion.id_pregunta, label: seleccion.nombre_pregunta });
    }
  };

  const handleGuardar = async () => {
    try {
      if (tarea.fechaInicio && tarea.fechaFin) {
        const tareaModificada = {
          ...tarea,
          fechaInicio: tarea.fechaInicio.format("DD/MM/YYYY HH:mm:ss"),
          fechaFin: tarea.fechaFin.format("DD/MM/YYYY HH:mm:ss"),
        };

        const result = await fetch(`${url}atencion_cliente/crear-tarea`, optionsPost(tareaModificada));

        if (isError(result)) throw new Error();
        const respuesta = await result.json();
        if (respuesta.msg !== "err") {
          setIsModalOpen(false);
          limpiar();
          getBodegas();
          swalSuccess({
            text: "Tarea programada con éxito",
            customClass: {
              container: "swal2-custom",
            },
          });
        } else {
          swalError({
            text: "Error al registrar",
            customClass: {
              container: "swal2-custom",
            },
          });
          return { msg: "Ocurrio un error", status: false };
        }
      } else {
        swalError({
          text: "Las fechas no están definidas o son inválidas",
          customClass: {
            container: "swal2-custom",
          },
        });
      }
    } catch (e: any) {
      console.log(e);
      swalError({
        title: "Error al registrar",
        text: e.toString(),
        customClass: {
          container: "swal2-custom",
        },
      });
    }
  };

  useEffect(() => {
    if (tarea.titulo && tarea.fechaInicio && tarea.fechaFin && typeof tarea.seccionId !== "undefined") {
      form.setFieldsValue({
        titulo: tarea.titulo,
        rangoFecha: [tarea.fechaInicio, tarea.fechaFin],
        seccion: tarea.seccionId,
      });
    }
  }, [tarea, form, getBodegas]);

  const onValuesChange = useCallback(
    (changedValues: any, allValues: FormValues) => {
      // Aquí se actualiza el estado basado en los valores del formulario
      if (changedValues.titulo) {
        setTarea((prev) => ({ ...prev, titulo: changedValues.titulo }));
      }
      if (changedValues.rangoFecha) {
        const [start, end] = changedValues.rangoFecha;
        setTarea((prev) => ({
          ...prev,
          fechaInicio: start,
          fechaFin: end,
        }));
      }
      if (changedValues.descripcion) {
        setTarea((prev) => ({ ...prev, descripcion: changedValues.descripcion }));
      }
    },
    [setTarea]
  );

  // Asegúrate de pasar esta función a tu componente Form

  const onOk = (value: DatePickerProps["value"] | RangePickerProps["value"]) => {};
  const handleSegmentChange = (value: string | number) => {
    setSegmentoSeleccionado(value.toString());
  };

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datosInicial);
    } else {
      const filtered = datosInicial.filter((item) => item.id_cuestionario);
      setFilteredData(filtered);
    }
  }, [searchText, datosInicial]);

  const onCalendarChange: RangePickerProps["onCalendarChange"] = (dates) => {
    // Actualizar startDate cuando se selecciona la primera fecha
    if (dates?.[0] && !dates[1]) {
      setStartDate(dates[0]);
    }
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Deshabilitar fechas antes del día actual y más de dos días después de la fecha de inicio seleccionada
    if (!startDate) {
      return current && current < moment().startOf("day");
    }
    return current < moment().startOf("day") || current > startDate.clone().add(2, "days");
  };

  return {
    filteredData,
    actions,
    estadoBoton,
    disabledDate,
    handleSegmentChange,
    segmentoSeleccionado,
    dataEstado,
    form,
    showModal,
    isModalOpen,
    handleOk,
    handleCancel,
    onChange,
    onOk,
    items,
    inputRef,
    onNameChange,
    onNameChangePreguntas,
    addItem,
    onCalendarChange,
    tarea,
    onValuesChange,
    handleSeccionChange,
    categorias,
    tiposPregunta,
    itemsPreguntasInicial,
    addItemPregunta,
    addItemCategorias,
    onNameChangeCategorias,
    onNameChangeRespuestas,
    agregarPregunta,
    setCategoriaSeleccionada,
    categoriaSeleccionada,
    setTipoPreguntaSeleccionada,
    tipoPreguntaSeleccionada,
    opcionesRespuestas,
    setRespuestasSeleccionadas,
    respuestasSeleccionadas,
    preguntaSeleccionada,
    setPreguntaSeleccionada,
    handlePreguntaChange,
    datosCuestionario,
    addItemRespuesta,
    handleGuardar,
  };
}
