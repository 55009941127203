import ReactPDF, { Document, Page, StyleSheet, Text, Image, View, Link } from "@react-pdf/renderer"
import { Title } from "components/reactreport/Title"
import { SubTitle } from "components/reactreport/Subtitle"
import { Logo } from "components/reactreport/Logo"
import { Table } from "components/reactreport/Table"

import type { tableProps } from "components/reactreport/Table"

export type combinedPropsPDF = ReactPDF.ImageWithSrcProp & ReactPDF.TextProps & ReactPDF.ViewProps & ReactPDF.LinkProps & tableProps

export const elementsPDF = {
  logo: ({ src }: ReactPDF.ImageWithSrcProp) => <Logo src={src} />,
  title: ({ children, style }: ReactPDF.TextProps) => <Title children={ children } style={ style } />,
  subTitle: ({ children, style }: ReactPDF.TextProps) => <SubTitle children={ children } style={ style } />,
  table: ({ columns, dataSource, styleRow, styleRowHeader }: tableProps) => <Table columns={ columns } dataSource={ dataSource } styleRow={ styleRow } styleRowHeader={ styleRowHeader } />,
  text: ({ children, style, fixed }: ReactPDF.TextProps) => <Text style={ style } fixed={ fixed }>{ children }</Text>,
  image: ({ src, style }: ReactPDF.ImageWithSrcProp) => <Image src={ src } style={ style } />,
  link: ({ children, style, src }: ReactPDF.LinkProps) => <Link src={ src } style={ style }>{ children }</Link>,
  container: ({ children, wrap, style }: ReactPDF.ViewProps) => <View style={ style } wrap={ wrap }>{ children }</View>
}

export interface PDFElements {
  type: keyof typeof elementsPDF,
  elementProps: ReactPDF.ImageWithSrcProp | ReactPDF.TextProps | ReactPDF.ViewProps | ReactPDF.LinkProps | tableProps
}

interface reportProps {
  documentProps: ReactPDF.DocumentProps
  pageProps: ReactPDF.PageProps
  // elements: PDFElements[]
  children: JSX.Element | JSX.Element[]
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 70,
    paddingBottom: 65,
    paddingHorizontal: 60,
  },
  paragraph: {
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Helvetica',
    marginBottom: 15
  },
})

export function Report({ documentProps, pageProps, children }: reportProps) {
  
  return (
    <Document { ...documentProps }>
      <Page { ...pageProps } style={ styles.body }>
        {
          children
        }
        {/* <Table 
          columns={[
            { title: 'Cedula', dataIndex: 'cedula', styleColumn: { flexBasis: '100px' }, styleColumnHeader: { flexBasis: '100px' } },
            { title: 'Nombres', dataIndex: 'nombres', styleColumn: { flexBasis: '150px' }, styleColumnHeader: { flexBasis: '150px' } },
            { title: 'Direccion', dataIndex: 'direccion', styleColumn: { flexBasis: '250px' }, styleColumnHeader: { flexBasis: '250px' } }
          ]}
          dataSource={[
            { cedula: '1313445262', nombres: 'José Andrés Párraga García', direccion: 'calle robles y avenida manabí frente a parrilladas don pinto'}
          ]}
        /> */}
      </Page>
    </Document>
  )
}