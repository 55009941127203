import { useParams } from "react-router-dom"

import { optionsPOST, url_interna, llave } from "config/constants"
// import type { Estados } from "types"

import { EditOutlined, EyeOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons"

import { useNavigate } from "react-router-dom"

import Swal from "sweetalert2"
import AES from "crypto-js/aes"
// import { useFetchInEffect } from "hooks/useFetch"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { LayoutContext } from "context/LayoutContext"
import { Popconfirm } from "antd"
import { useScreenSize } from "hooks/otros/useScreenSize"
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs"
import { UserContext } from "context/UserContext"

interface reembolsos {
  Cod_Reembolso: number
  Fecha: string
  Justificacion: string
  numeroFacturas: number
  numeroNotaVentas: number
  numeroRecibos: number
  total: number
}

interface dataReembolsos {
  reembolsos: reembolsos[]
}

export function useReembolsos() {
  const navigate = useNavigate()

  const { estado: estadoUrl } = useParams()

  const { width } = useScreenSize()

  const isWeb = width >= 778

  useEffect(() => {
    estadoUrl !== "pendientes" &&
      estadoUrl !== "aprobados" &&
      estadoUrl !== "rechazados" &&
      navigate("/reembolsos/pendientes")
  }, [estadoUrl, navigate])

  const { userData: dataUser } = useContext(UserContext)

  const estado = estadoUrl === "pendientes" ? "PE" : estadoUrl === "aprobados" ? "AP" : "RE"

  const [reembolsos, setReembolsos] = useState<dataReembolsos>({ reembolsos: [] })

  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext)

  useBreadCrumbs(
    useMemo(
      () => [
        { Label: "Reembolsos", Url: estado !== "PE" ? "/reembolsos/pendientes" : undefined },
        {
          Label: estado === "PE" ? "Pendientes" : estado === "AP" ? "Aprobados" : "Rechazados",
        },
      ],
      [estado]
    )
  )

  useEffect(() => {
    setOpenKeys(["Reembolsos", "Reembolsos/Ver Reembolsos"])
    estado === "PE" && setSelectedKeys(["Ver Reembolsos/Pendientes"])
    estado === "AP" && setSelectedKeys(["Ver Reembolsos/Aprobados"])
    estado === "RE" && setSelectedKeys(["Ver Reembolsos/Rechazados"])
  }, [setOpenKeys, setSelectedKeys, estado, isWeb])

  const handleClickDelete = (Cod_Reembolso: number) => {
    Swal.fire({
      title: "¿Está seguro?",
      text: "Se eliminará para siempre",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "var(--primary)",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(result => {
      if (result.isConfirmed) {
        DeleteReembolso(Cod_Reembolso)
      }
    })
  }

  const DeleteReembolso = (Cod_Reembolso: number) => {
    fetch(
      `${url_interna}api/reembolsos/eliminar-reembolso`,
      optionsPOST(JSON.stringify({ Cod_Reembolso }), { "api-token": "apitoken" })
    )
      .then(_ => {
        Swal.fire("¡Eliminado!", "El reembolso de gastos se ha eliminado con éxito", "success")
        fillReembolsos()
      })
      .catch(e => {
        Swal.fire("¡Error!", "No se pudo eliminar el reembolso", "error")
        console.error(e)
      })
  }

  const actions = (reembolso: number) => {
    const enc_reembolso = encodeURIComponent(AES.encrypt(reembolso.toString(), llave).toString())

    return estadoUrl === "pendientes"
      ? [
          <EditOutlined
            key="edit"
            onClick={() => navigate(`/editar-reembolso/${enc_reembolso}`)}
            style={{ padding: "8px" }}
          />,
          <EyeOutlined key="see" onClick={() => navigate(`/ver-reembolso/${enc_reembolso}`)} style={{ padding: "8px" }} />,
          isWeb ? (
            <Popconfirm
              key="delete"
              title={"¿Está seguro de eliminar el reembolso?"}
              okText="Yes"
              cancelText="No"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => DeleteReembolso(reembolso)}
            >
              <DeleteOutlined style={{ padding: "8px" }} />
            </Popconfirm>
          ) : (
            <DeleteOutlined key="delete" onClick={() => handleClickDelete(reembolso)} style={{ padding: "8px" }} />
          ),
        ]
      : [<EyeOutlined key="see" onClick={() => navigate(`/ver-reembolso/${enc_reembolso}`)} style={{ padding: "8px" }} />]
  }

  const fillReembolsos = useCallback(() => {
    fetch(
      `${url_interna}api/reembolsos/ver-reembolsos-por-usuario`,
      optionsPOST(JSON.stringify({ Id_Usuario: dataUser?.id, estado }), { "api-token": "apitoken" })
    )
      .then(res => res.json())
      .then(res => {
        if (res.msg === "ok") setReembolsos(res.data)
        else
          Swal.fire({
            title: "Error de consulta",
            text: "Error al obtener la lista de reembolsos de gastos",
            icon: "error",
            timer: 2000,
            showConfirmButton: false,
          })
      })
      .catch(_ => {
        Swal.fire({
          title: "Sin Conexión",
          text: "No hay conexión con el servidor",
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        })
      })
  }, [dataUser?.id, estado])

  useEffect(() => {
    fillReembolsos()
  }, [fillReembolsos])

  // console.log('rerender')

  return {
    reembolsos: reembolsos.reembolsos,
    isLoading: reembolsos.reembolsos.length === 0,
    estado: estadoUrl,
    actions,
  }
}
