import { Col, Row, DatePicker, Table, Card, Select } from "antd";
import "moment/locale/es";

import { ButtonExcel } from "components/otros/buttons";
import { useReportePorGrupo } from "hooks/inventarios/reportes/useReporteGrupo";

export function ReporteGrupo() {
  const {
    columns,
    mes,
    filteredData,
    handleClickDownloadData,
    /*calcularTotales,*/ datosGrupo,
    handleChangeGrupo,
    handleChangeCalendar,
    columsDataExpanded,
    estadoBoton
  } = useReportePorGrupo();
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <DatePicker
          value={mes}
          picker="month"
          placeholder="Seleccionar mes"
          format="MMMM [del] YYYY"
          onChange={handleChangeCalendar}
          inputReadOnly
        />
        <Select
          options={datosGrupo}
          allowClear
          showSearch
          style={{ width: "40%", padding: "5px" }}
          optionFilterProp="label"
          placeholder="Seleccione un grupo"
          onChange={handleChangeGrupo}
        ></Select>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Row gutter={[10, 10]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
          <Col xs={24} md={10}>
            {estadoBoton && <ButtonExcel buttonProps={{ onClick: handleClickDownloadData }}></ButtonExcel>}
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <Table
            pagination={false}
            loading={columns ? false : true}
            scroll={{ x: 900 }}
            size="small"
            bordered
            columns={columns}
            dataSource={filteredData}
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <>
                    <Table
                      style={{ width: "100%", margin: "10px" }}
                      columns={columsDataExpanded}
                      dataSource={record.DetalleDias}
                      pagination={false}
                    />
                  </>
                );
              },
            }}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default ReporteGrupo;
