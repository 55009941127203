import { Button, Form } from "antd";
import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useContext, useEffect, useState } from "react";
import type { ColumnProps } from "antd/lib/table";

import "moment/locale/es";
import { PropsProductos, useTableProductos } from "./useTableProductos";
import { EyeFilled } from "@ant-design/icons";
import { url } from "config/constants";
import { optionsPost, swalWarning } from "config/methods";

export interface PropsDataHistory {
  key: number;
  cod_motivo: number;
  fecha: string;
  usuario: string;
  data_json: any;
  data_json_nueva: any;
}

const breadcrumbs = [{ Label: "Productos" }];

export function useProductos() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const [form] = Form.useForm();
  const [selectedRow, setSelectedRow] = useState<number[]>([]);
  const [dataNow, setDataNow] = useState<any>({});
  const [dataNowNew, setDataNowNew] = useState<any>({});

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [motivo, setMotivo] = useState<number>();
  const [titleModal, setTitleModal] = useState<string>("");
  const [dataHistory, setDataHistory] = useState<PropsDataHistory[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>("");

  //Para modal editar
  useEffect(() => {
    setOpenKeys(["Auditoría de Procesos", "Auditoría de Procesos"]);
    setSelectedKeys(["Auditoría de Procesos/Productos"]);
  }, [setOpenKeys, setSelectedKeys]);

  useBreadCrumbs(breadcrumbs);

  const closeModal = () => {
    setOpenModal(false);
    setSearchTerm("");
  };

  const handleClickActionEdit = async (record: PropsProductos) => {
    try {
      const respuesta = await fetch(url + "apifsg-pr/productos/historico-id", optionsPost({ cod_producto: record.id })).then(
        (data) => data.json()
      );
      if (!respuesta.msg) return swalWarning({ text: "Ocurrio un error" });

      let newData = respuesta.data.map((el: PropsDataHistory) => ({
        ...el,
        data_json: sortObjectByKey(JSON.parse(el.data_json)),
        data_json_nueva: el.data_json_nueva ? sortObjectByKey(JSON.parse(el.data_json_nueva)) : null,
      }));

      setDataHistory(newData);
      setTitleModal(record.producto);

      setOpenModal(true);
    } catch (error) {
      swalWarning({ text: "Ocurrio un error" });
    }
  };

  const renderActionsHistory: ColumnProps<any>["render"] = (_, record) => (
    <Button
      icon={<EyeFilled />}
      type='ghost'
      style={{ border: "none", color: "rgb(106 40 49)" }}
      onClick={() => handleClickActionEdit(record)}
    />
  );

  function sortObjectByKey<T>(obj: Record<string, T>): Record<string, T> {
    const keys: string[] = Object.keys(obj);
    keys.sort();

    const sortedObj: Record<string, T> = {};
    keys.forEach((key) => {
      sortedObj[key] = obj[key];
    });

    return sortedObj;
  }

  const handleRowSelect = async (record: PropsProductos) => {
    setDataNowNew({});

    if (selectedRow && selectedRow[0] === record.key) {
      setSelectedRow([]);
    } else {
      setDataNow(sortObjectByKey(JSON.parse(record.data_json)));
      JSON.parse(record.data_json_nueva) && setDataNowNew(sortObjectByKey(JSON.parse(record.data_json_nueva)));
      setMotivo(record.cod_motivo);
      let newSelection = [];
      newSelection.push(record.key);
      setSelectedRow(newSelection);
    }
  };

  const rowSelection = {
    type: "radio",
    onSelect: handleRowSelect,
    selectedRowKeys: selectedRow[0] ? [selectedRow[0]] : [],
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filterData = (data: { [key: string]: any }): { [key: string]: any } => {
    const filteredData: { [key: string]: any } = {};
    Object.keys(data).forEach((key: string) => {
      const value = data[key];
      if (
        key.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (typeof value === "string" && value.toLowerCase().includes(searchTerm.toLowerCase()))
      ) {
        filteredData[key] = value;
      }
    });
    return filteredData;
  };

  const filteredDataHistory = dataHistory.filter((el) => {
    const filteredDataJson = filterData(el.data_json);
    const filteredDataJsonNueva = el.cod_motivo === 2 ? filterData(el.data_json_nueva) : {};
    return Object.keys(filteredDataJson).length > 0 || Object.keys(filteredDataJsonNueva).length > 0;
  });

  const { columnsTable, productos } = useTableProductos("api/productos/listar_historico", renderActionsHistory);

  return {
    searchTerm,
    filterData,
    filteredDataHistory,
    handleSearch,
    dataHistory,
    dataNow,
    rowSelection,
    productos,
    form,
    openModal,
    setOpenModal,
    columnsTable,
    dataNowNew,
    motivo,
    closeModal,
    titleModal,
  };
}
