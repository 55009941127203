import { Col, Row, DatePicker, Table, Card, Spin } from "antd";
import "moment/locale/es";

import moment from "moment";
import { ButtonExcel } from "components/otros/buttons";
import { useVT } from "virtualizedtableforantd4";
import { useReporteTransferencias } from "hooks/inventarios/reportes/useReporteTransferencias";

export function ReporteTransferencias() {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);
  const {
    columns,
    columsDatatableDetalle,
    /*  mes,
    setMes,*/
    datosInicial,
    handleClickDownloadData,
    getBodegas,
    //calcularTotales,
    estadoBoton,
    dataEstado,
  } = useReporteTransferencias();
  const fechaInicio = moment().startOf("month");
  // Ajustar la fecha de fin al último día del mes actual
  const fechaFin = moment().endOf("month");

  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <DatePicker.RangePicker
          defaultValue={[fechaInicio, fechaFin]}
          //placeholder="Seleccionar mes"
          format="MMMM [del] YYYY"
          onChange={(value) => {
            if (value && value.length === 2 && value[0] && value[1]) {
              getBodegas(value[0], value[1]);
              //setFechas([value[0], value[1]]);
            }
          }}
          inputReadOnly
        />
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Row gutter={[10, 10]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
          <Col xs={24} md={10}>
            {estadoBoton && <ButtonExcel buttonProps={{ onClick: handleClickDownloadData }}></ButtonExcel>}
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          {!dataEstado && (
            <div style={{ textAlign: "center" }}>
              <div style={{ margin: "0 auto" }}>
                <Spin />
              </div>
            </div>
          )}
          {dataEstado && (
            <Table
              columns={columns}
              components={vt}
              dataSource={datosInicial ? datosInicial : []}
              scroll={{ x: 900, y: 450 }}
              //scroll={{ x: true }}
              size="small"
              pagination={false}
              bordered
              expandable={{
                expandedRowRender: (record) => {
                  return (
                    <Table
                      style={{ width: "100%", margin: "10px" }}
                      columns={columsDatatableDetalle}
                      dataSource={record.datos}
                      pagination={false}
                    />
                  );
                },
              }}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default ReporteTransferencias;
