import { View, Text, Image, StyleSheet } from "@react-pdf/renderer"
import { Form, Select } from "antd"
import { Report } from "components/reactreport/Report"
import { FormTipoConsultaAndTable } from "components/rrhh/componentsllamadosatencion/FormTipoConsultaAndTable"

import { useReporteLlamadosAtencion } from "hooks/rrhh/llamadosatencion/useReporteLlamadosAtencion"

// import { elementsPDF, combinedPropsPDF } from 'components/reactreport/Report'
import { Title } from "components/reactreport/Title"
import { url } from "config/constants"

import type { dataLlamadosAtencion } from "hooks/rrhh/llamadosatencion/useDataForTable"
// import moment from 'moment'
import { ModalReport } from "components/otros/ModalReport"

interface dataReglamentos {
  capitulo: string
  articulos: Array<{
    articulo: number
    descripcion: string
    apartado: string
    literales: Array<{
      literal: number
      descripcion: string
    }>
  }>
}

const fillZeros = (n: number, len: number) => "0".repeat(len - n?.toString().length) + n?.toString()

// const formatter = new Intl.ListFormat('es', { style: 'long', type: 'conjunction' })

const createPeriodo = (periodo?: string) => {
  if (!periodo) return ""
  const año = parseInt(periodo.substring(0, 4))
  const mes = parseInt(periodo.substring(4, 6)) - 1
  const fecha = new Date(año, mes, 1)
  return `${fecha.toLocaleString("default", { month: "long" })} del ${fecha.getFullYear()}`
}

export function ReporteLlamadosAtencion() {
  const {
    columnsTable,
    llamadosAtencion,
    selectedLlamadoAtencion,
    openModal,
    setOpenModal,
    selectedEmpleado,
    handleChangeEmpleado,
    selectedDates,
    handleChangeDates,
    selectedTipoConsulta,
    setSelectedTipoConsulta,
  } = useReporteLlamadosAtencion()

  return (
    <>
      <FormTipoConsultaAndTable
        selectedTipoConsulta={selectedTipoConsulta}
        setSelectedTipoConsulta={setSelectedTipoConsulta}
        selectedDates={selectedDates}
        handleChangeDates={handleChangeDates}
        columnsTable={columnsTable}
        data={llamadosAtencion}
      />
      <ModalReport
        openModal={openModal}
        setOpenModal={setOpenModal}
        titleModal="Reporte de Llamado de Atención"
        pdfName={`Llamado de atención #RRHH-${fillZeros(llamadosAtencion[selectedLlamadoAtencion]?.key, 5)}-${fillZeros(
          selectedEmpleado + 1,
          3
        )}`}
        extra={
          <Form>
            <Form.Item label="Empleados involucrados">
              <Select
                options={llamadosAtencion[selectedLlamadoAtencion]?.empleados.map((empleado, index) => ({
                  value: index,
                  label: empleado.nombres,
                }))}
                value={selectedEmpleado}
                onChange={handleChangeEmpleado}
              />
            </Form.Item>
          </Form>
        }
      >
        <Reporte
          llamadosAtencion={llamadosAtencion}
          selectedEmpleado={selectedEmpleado}
          selectedLlamadoAtencion={selectedLlamadoAtencion}
        />
      </ModalReport>
    </>
  )
}

interface propsReporte {
  llamadosAtencion: dataLlamadosAtencion[]
  selectedEmpleado: number
  selectedLlamadoAtencion: number
}

const styles = StyleSheet.create({
  text: { fontSize: 10, textAlign: "justify", marginTop: "15px", lineHeight: 1.3 },
  textBold: { fontSize: 10, marginTop: "15px", lineHeight: 1.3, fontFamily: "Helvetica-Bold" },
  numeration: { textAlign: "right", fontFamily: "Helvetica-Bold", fontSize: 10 },
  title: { border: "2px solid #000", paddingTop: "3px" },
  row: { display: "flex", flexDirection: "row", alignItems: "flex-start", paddingBottom: "5px" },
  columnTitle: { fontFamily: "Helvetica-Bold", fontSize: 10, flexBasis: `15%` },
  column: { fontSize: 10, flexBasis: `85%` },
})

function Reporte({ llamadosAtencion, selectedEmpleado, selectedLlamadoAtencion }: propsReporte) {
  return (
    <Report
      documentProps={{
        title: `Llamado de atención #RRHH-${fillZeros(llamadosAtencion[selectedLlamadoAtencion]?.key, 5)}-${fillZeros(
          selectedEmpleado + 1,
          3
        )}`,
      }}
      pageProps={{ size: "A4" }}
    >
      <Text style={styles.numeration}>
        RRHH-
        {`${fillZeros(llamadosAtencion[selectedLlamadoAtencion]?.key, 5)}-${fillZeros(selectedEmpleado + 1, 3)}`}
      </Text>
      <Title style={styles.title}>LLAMADO DE ATENCIÓN</Title>
      <View style={styles.row}>
        <Text style={styles.columnTitle}>FECHA:</Text>
        <Text style={styles.column}>
          {/* { moment().format('DD [de] MMMM [del] YYYY') } */}
          {llamadosAtencion[selectedLlamadoAtencion].fecha_aprobacion}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.columnTitle}>PARA:</Text>
        <Text style={styles.column}>{llamadosAtencion[selectedLlamadoAtencion]?.empleados[selectedEmpleado]?.nombres}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.columnTitle}>CARGO:</Text>
        <Text style={styles.column}>{llamadosAtencion[selectedLlamadoAtencion]?.empleados[selectedEmpleado]?.cargo}</Text>
      </View>
      <View style={{ ...styles.row, borderBottom: "1px solid #000" }}>
        <Text style={styles.columnTitle}>MOTIVO:</Text>
        <Text style={styles.column}>{llamadosAtencion[selectedLlamadoAtencion]?.motivo}</Text>
      </View>
      <Text style={styles.text}>
        La presente constituye un llamado de atención para el Sr(a).
        <Text style={{ fontFamily: "Helvetica-Bold", fontSize: 10 }}>
          &nbsp;{llamadosAtencion[selectedLlamadoAtencion]?.empleados[selectedEmpleado]?.nombres}
        </Text>
        , quien ha incumplido
        {(llamadosAtencion[selectedLlamadoAtencion]?.reglamentos as Array<dataReglamentos>)?.map(({ articulos }) =>
          articulos.map(
            ({ articulo, literales }) =>
              ` el ART. ${articulo} ${!isNaN(literales[0].literal) ? "numeral" : "literal"} ${literales
                .map(({ literal }) => literal)
                .join(", ")}, `
          )
        )}
        de acuerdo a información recibida en el Departamento de RRHH, por parte del Sr (a).{" "}
        {llamadosAtencion[selectedLlamadoAtencion]?.solicitante}, quien se desempeña como{" "}
        {llamadosAtencion[selectedLlamadoAtencion]?.cargo_solicitante}, en la que menciona lo siguiente:
      </Text>
      <Text style={styles.text}>
        Durante el periodo de {createPeriodo(llamadosAtencion[selectedLlamadoAtencion]?.periodo_actividad)}, se llevó a cabo
        una {llamadosAtencion[selectedLlamadoAtencion]?.tipo_actividad} en el punto de venta #
        {llamadosAtencion[selectedLlamadoAtencion]?.nbodega}, la misma que está ubicado en la localidad de{" "}
        {llamadosAtencion[selectedLlamadoAtencion]?.direccion}, el personal que realizó el proceso es el siguiente:
      </Text>
      <View style={{ paddingLeft: "20px", marginTop: "15px" }}>
        {llamadosAtencion[selectedLlamadoAtencion]?.responsables.map(({ nombres }, index) => (
          <Text style={{ fontSize: 10, textAlign: "justify", lineHeight: 1.3 }} key={index}>
            {`•    ${nombres}`}
          </Text>
        ))}
        {llamadosAtencion[selectedLlamadoAtencion]?.descripcion && (
          <Text style={{ fontSize: 10, textAlign: "justify", lineHeight: 1.3 }}>
            {`-    ${llamadosAtencion[selectedLlamadoAtencion]?.descripcion}`}
          </Text>
        )}
      </View>
      <Text style={{ ...styles.text, fontFamily: "Helvetica-BoldOblique" }}>
        Extracto de la solicitud de {llamadosAtencion[selectedLlamadoAtencion]?.solicitante}
      </Text>
      <Text style={{ ...styles.text, fontFamily: "Helvetica-BoldOblique", textAlign: "center" }}>
        "{llamadosAtencion[selectedLlamadoAtencion]?.observacion}"
      </Text>
      <Text style={styles.text}>
        Con lo expuesto, el Sr (a).
        <Text style={{ fontFamily: "Helvetica-Bold", fontSize: 10 }}>
          &nbsp;{llamadosAtencion[selectedLlamadoAtencion]?.empleados[selectedEmpleado]?.nombres}
        </Text>
        , ha incumplido lo estipulado en
        {(llamadosAtencion[selectedLlamadoAtencion]?.reglamentos as Array<dataReglamentos>)?.map(({ articulos }) =>
          articulos.map(
            ({ articulo, literales }) =>
              ` el ART. ${articulo} ${!isNaN(literales[0].literal) ? "numeral" : "literal"} ${literales
                .map(({ literal }) => literal)
                .join(", ")}, `
          )
        )}{" "}
        del Reglamento Interno de Trabajo de la Empresa.
      </Text>
      <View>
        {(llamadosAtencion[selectedLlamadoAtencion]?.reglamentos as Array<dataReglamentos>)?.map(
          ({ capitulo, articulos }, index1) =>
            articulos.map(({ articulo, apartado, literales }, index2) =>
              literales.map(({ literal, descripcion }, index3) => (
                <View key={`${index1}${index2}${index3}`}>
                  <Text style={styles.textBold}>
                    {`Capítulo ${capitulo}, Art. ${articulo}, ${isNaN(literal) ? "literal" : "numeral"} ${literal}.- ${
                      apartado.charAt(0) + apartado.substring(1).toLowerCase()
                    }`}
                  </Text>
                  <Text style={{ fontSize: 10, textAlign: "justify", lineHeight: 1.3 }}>{descripcion}</Text>
                </View>
              ))
            )
        )}
      </View>
      <View>
        {(llamadosAtencion[selectedLlamadoAtencion]?.reglamentos as Array<dataReglamentos>)?.map(
          ({ capitulo, articulos }, index1) =>
            articulos.map(({ articulo, descripcion }, index2) => (
              <Text style={styles.text} key={`${index1}${index2}`}>
                De acuerdo a lo que menciona el Reglamento Interno de Trabajo de la Empresa, Capítulo {capitulo}, ART.{" "}
                {articulo}.- {descripcion}
              </Text>
            ))
        )}
      </View>
      <Text style={styles.text}>
        <Text
          style={{
            fontSize: 10,
            textAlign: "justify",
            lineHeight: 1.3,
            fontFamily: "Helvetica-Bold",
          }}
        >
          {llamadosAtencion[selectedLlamadoAtencion]?.empleados[selectedEmpleado].nombres}
        </Text>
        , con su falta lesiona nuestro Reglamento Interno, se le aplicará la(s) siguiente(s) medida(s) disciplinaria(s):
      </Text>
      <View style={{ paddingLeft: "20px", paddingTop: "15px" }}>
        <Text style={{ fontSize: 10, textAlign: "justify", lineHeight: 1.3 }}>
          • Un llamado de atención, el mismo que irá a su carpeta personal.
        </Text>
        <Text style={{ fontSize: 10, textAlign: "justify", lineHeight: 1.3 }}>
          {llamadosAtencion[selectedLlamadoAtencion]?.multa &&
            `•    Multa máxima permitida por falta grave al incumplimiento del reglamento interno del ${llamadosAtencion[selectedLlamadoAtencion]?.porcentaje}% de su remuneración.`}
        </Text>
      </View>
      {llamadosAtencion[selectedLlamadoAtencion]?.evidencias ? (
        llamadosAtencion[selectedLlamadoAtencion]?.evidencias?.length ? (
          <View style={{ marginTop: "15px" }}>
            {llamadosAtencion[selectedLlamadoAtencion]?.evidencias?.map(({ llave_imagen }, index) => (
              <Image src={`${url}apiallku-public/api/${llave_imagen}`} key={index} />
            ))}
          </View>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      <Text
        style={{
          fontSize: 10,
          textAlign: "justify",
          lineHeight: 1.3,
          marginTop: "50px",
          fontFamily: "Helvetica-Bold",
        }}
      >
        Atentamente,
      </Text>
      <Text
        style={{
          fontSize: 10,
          textAlign: "justify",
          lineHeight: 1.3,
          marginTop: "50px",
          borderTop: "1px solid #000",
          width: "50%",
          paddingTop: "5px",
        }}
      >
        PSIC. GUILLERMO ASCENCIO ARREAGA
      </Text>
      <Text style={{ fontSize: 10, textAlign: "justify", lineHeight: 1.3 }}>DPTO. DE RRHH</Text>
      <Text
        style={{
          fontSize: 10,
          textAlign: "justify",
          lineHeight: 1.3,
          marginTop: "50px",
          fontFamily: "Helvetica-Bold",
        }}
      >
        Recibido por:
      </Text>
      <Text
        style={{
          fontSize: 10,
          textAlign: "justify",
          lineHeight: 1.3,
          marginTop: "70px",
          borderTop: "1px solid #000",
          width: "50%",
          paddingTop: "5px",
          fontFamily: "Helvetica-Bold",
        }}
      >
        {llamadosAtencion[selectedLlamadoAtencion].empleados[selectedEmpleado].nombres}
      </Text>
      <Text style={{ fontSize: 10, textAlign: "justify", lineHeight: 1.3 }}>
        CC: {llamadosAtencion[selectedLlamadoAtencion].empleados[selectedEmpleado].cedula}
      </Text>
      <Text style={{ fontSize: 10, textAlign: "justify", lineHeight: 1.3 }}>TRABAJADOR</Text>
    </Report>
  )
}
