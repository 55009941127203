import { Button, Col, Row, Table, Card } from "antd";
import { SaveOutlined, CheckOutlined, StopOutlined } from "@ant-design/icons";
import "moment/locale/es";
import { useRevision } from "hooks/cajas/pagoTramites/useRevision";

export function Revision() {
  const { columns, filteredData, rowSelection, handleSave, estadoGuardar, estadoBoton,onRowClick } = useRevision();

  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredData}
            scroll={{ x: true }}
            size="small"
            onRow={(record) => ({
                onClick: () => onRowClick(record),
              })}
           // pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
          />
        </Card>
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 16 }}>
          {estadoBoton && (
            <Button
              type="primary"
              style={{
                width: "190px",
              }}
              onClick={handleSave}
              loading={estadoGuardar}
              icon={<CheckOutlined className="iconbutton" />}
            >
              Aprobar
            </Button>
          )}
          {estadoBoton && (
            <Button
              type="default"
              style={{
                width: "190px",
                marginLeft: "5px",
              }}
              //onClick={handleSave}
              loading={estadoGuardar}
              icon={<StopOutlined className="iconbutton" />}
            >
              Rechazar
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default Revision;
