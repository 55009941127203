import { url } from 'config/constants'
import { optionsPost, swalQuestion, swalSuccess, swalWarning } from 'config/methods'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { UserContext } from "context/UserContext"
import moment from 'moment'
import { ColumnsType } from 'antd/lib/table'
import { Button,Tag, Tooltip } from 'antd'
import { DeleteOutlined, EyeOutlined} from "@ant-design/icons"
import { useSearchTableNew } from 'hooks/otros/useSearchTableNew'

export interface DataTypeTareas {
    key: React.Key
    cod_tipo_Incidente: string
    incidente: string
    fecha: Date
    cod_bodega: string
    bodega: string
    cod_usuario_solicita: string
    usuario_solicita: string
    cod_usuario_asignado: string
    usuario_asignado: string
    cod_usuario_registra: string
    usuario_registro: string
    descripcion: string
    fecha_inicio: Date
    estado: string
  }

  export interface DataTypeTareasHis {
    key: React.Key
    Descripcion: string
    Cod_Usuario_Registra: string
    Nombres: string
    Tipo_Atencion: string
    Fecha_Registra: Date

  }

export  function useTareasEnPrceso() {
    const { userData } = useContext(UserContext);
    const [dataTareas,setdataTareas]=useState<DataTypeTareas[]>([])
    const [disabled, setdisabled]=useState(false)
    const [Codtarea, setCodtarea]=useState("")
    const [Fecha, setFecha]=useState(moment())
    const [Estado,setEstado]=useState("")
    const [Farmacia, setFarmacia]=useState("")
    const [Fecha_Inicio, setFecha_Inicio]=useState(moment())
    const [usuario_asignado,setusuario_asignado]=useState("")
    const [usuario_solicita, setusuario_solicita]=useState("")
    const [descripcion_general, setdescripcion_general]=useState("")
    const [tipo_atencion, settipo_atención]=useState("")
    const [descripcion, setdescripcion]=useState("")
    const [dataTareasHis, setdataTareasHis]=useState<DataTypeTareasHis[]>([])
    const [incidente, setincidente]=useState("")
    const [disabledButton, setdisabledButton]=useState(false)
    const { getColumnSearchPropsNew } = useSearchTableNew<DataTypeTareas>()

    const columnsTareas: ColumnsType<DataTypeTareas>=[
        {
          title:'Tipo Incidente',
          dataIndex: "incidente",
          ...getColumnSearchPropsNew("incidente")
       
        },
        {
          title: "Farmacia",
          dataIndex: "bodega",
          ...getColumnSearchPropsNew("bodega")
        },
        {
           title:'Fecha de Registro',
           dataIndex:"fecha",
           render:(key)=>{
            return key!==null?moment.parseZone(key).format("DD/MM/YYYY"):''
           }
        },
        {
            title:'Fecha de Inicio',
            dataIndex:"fecha_inicio",
            render:(key)=>{
              return key!==null?moment.parseZone(key).format("DD/MM/YYYY"):''
             }
         },
        {
            title:'Descripción',
            dataIndex:'descripcion',
            ...getColumnSearchPropsNew("descripcion")
        },
        {
            title:'Estado',
            dataIndex:'estado',
            render:(key)=>{
              return (
                <>
               { key==='EN PROCESO'&&<Tag color="blue" >{key}</Tag>}
               { key!=='EN PROCESO'&&<Tag color="orange" >PENDIENTE</Tag>}
                </>
              )
             }
        },
        {
            title:'Acción',
            fixed:'right',
            width:'98px',
            render:(key)=>{
                return(
                    <>
                      <Tooltip title="Ver"><Button type={"primary"} size="small" style={{ marginRight: "2px", marginBottom:'1px' }} onClick={() => btn_Ver(key)}>
                      <EyeOutlined />
                    </Button></Tooltip>
                    <Tooltip title="Eliminar"><Button type={"primary"} size="small" style={{ marginRight: "2px" }} onClick={() => btn_Delete(key)}>
                        <DeleteOutlined/>
                    </Button></Tooltip>
                    </>
                )
            }
        }
      ]
    
    const getTareas = useCallback(async () => {
      try{
        const res = await fetch(`${url}fapifsg-pr/sistemas/getTareas`, optionsPost({estado:'EN PROCESO', perfil: userData?.id_perfil})).then((data) => data.json())
        //console.log(!res)
      let newdata:any=[]
      if(res){
          newdata = Array.from(res).map((item: any, key: any) => ({
            key: item.Cod_Tarea_Soporte,
            cod_tipo_Incidente: item.Cod_Tipo_Incidente,
            incidente: item.Incidente,
            fecha: item.Fecha,
            cod_bodega: item.Cod_Bodega,
            bodega: item.Bodega,
            cod_usuario_solicita: item.Cod_Usuario_Solicita,
            usuario_solicita: item.usuario_solicita,
            cod_usuario_asignado: item.Cod_Usuario_Asignado,
            usuario_asignado: item.usuario_asignado,
            cod_usuario_registra: item.Cod_Usuario_Registra,
            usuario_registro: item.usuario_registra,
            descripcion: item.Descripcion,
            fecha_inicio: item.Fecha_Inicio,
            estado: item.Estado
        }))
       // console.log(newdata)
       }
       setdataTareas(newdata)
      }catch(error){
        console.log(error)
      }
    },[userData])

    useEffect(()=>{
        getTareas()
    },[getTareas])

    const btn_Delete=(key:DataTypeTareas)=>{
        try{
        swalQuestion({
          text: `¿Esta seguro de eliminar la tarea de la ${key.bodega}?`,
          icon: "warning",
          showCancelButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await fetch(
              `${url}fapifsg-pr/sistemas/deleteTareas`,
              optionsPost({cod_tarea: key.key})
            )
            if (res.status===200){
              swalSuccess({text:"Tarea eliminada con éxito"})
              getTareas()
            }
          }
        })}catch(error){
          console.log(error)
        }
      }


      const btn_Ver=( key: DataTypeTareas )=>{
        setCodtarea((key.key).toString())
        setdisabled(true)
        setdisabledButton(false)
        setFecha(moment(key.fecha))
        setEstado(key.estado==='EN PROCESO'?key.estado:'')
        if(key.fecha_inicio){
          setFecha_Inicio(moment(key.fecha_inicio))
        }else{
          setFecha_Inicio(moment())
        }
        setFarmacia(key.bodega)
        setusuario_asignado(key.usuario_asignado)
        setusuario_solicita(key.usuario_solicita)
        setdescripcion_general(key.descripcion)
        getTareasHis((key.key).toString())
        setincidente(key.incidente)
      }

      const onchangeTipoAtencion = async (value: string) => {
        settipo_atención(value)
      }

      const onchangeDescripcion = (event: any) => {
        let newValue = event.target.value
        setdescripcion(newValue)
      }

      const onchangeEstado = async (value: string) => {

        if(value==='FINALIZADO' && dataTareasHis.length>0){
          try{
            swalQuestion({
              text: `¿Esta seguro de Finalizar la tarea Número ${Codtarea}?`,
              icon: "warning",
              showCancelButton: true,
            }).then(async (result) => {
              if (result.isConfirmed) {
                setEstado(value)
                const res = await fetch(
                  `${url}fapifsg-pr/sistemas/updateTareaEstado`,
                  optionsPost({cod_tarea_soporte: Codtarea, estado: value})
                )

                if(res.status===200){
                  swalSuccess({text:"Se finalizó la tarea con éxito"})
                  setdisabled(false)
                  getTareas()
                }
              }})
        }catch(error){
          console.log(error)
        }     
      }else{
        swalWarning({text:"Falta agregar una descripción al Historial"})
      }
    }

      const btn_Agregar=async()=>{
        setdisabledButton(true)
        try{
          if(descripcion===''||tipo_atencion===''){
            swalWarning({text:"Faltan datos para Guardar en el Historial"})
            setdisabledButton(false)
            return
            
          }
            const res = await fetch(
              `${url}fapifsg-pr/sistemas/saveTareaSoporteHis`,
              optionsPost({descripcion: descripcion, tipo_atencion: tipo_atencion, cod_tarea_soporte:Codtarea })
            )
            if (res.status===200){
              swalSuccess({text:"Se guardó con éxito"})
              getTareas()
              setdescripcion("")
              settipo_atención("")
              setdisabledButton(false)
              getTareasHis(Codtarea)
              if(Estado===''){
                setEstado("EN PROCESO")
              }
              if(usuario_asignado===null){
                setusuario_asignado(userData?.nombre?userData?.nombre:'')
              }
            }
            
        }catch(error){
          console.log(error)
          setdisabledButton(false)
        }
      }

      const getTareasHis=async(cod_tarea: string)=>{
        try{
            const res = await fetch(
              `${url}fapifsg-pr/sistemas/getTareasHis`,
              optionsPost({cod_tarea_soporte: cod_tarea })
            ).then(async(data) => await data.json())
            //console.log(res)
            setdataTareasHis(res?res:[])
        }catch(error){
          console.log(error)
        }
      }

      const btn_Delete_his=(key:DataTypeTareasHis)=>{
        try{
          if(dataTareasHis.length>1){
        swalQuestion({
          text: `¿Esta seguro de eliminar el historial de la tarea ${Codtarea}?`,
          icon: "warning",
          showCancelButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await fetch(
              `${url}fapifsg-pr/sistemas/updateTareaHisEstado`,
              optionsPost({cod_tarea_soporteh: key.key})
            )
            if (res.status===200){
              swalSuccess({text:"Tarea eliminada con éxito"})
              getTareas()
              getTareasHis(Codtarea)
            }
          }
        })
      }else{
        swalWarning({text:"No se puede eliminar porque la tarea no tendría registros en el Historial"})
      }
      
      }catch(error){
          console.log(error)
        }
      }

  return {
    dataTareas,
    columnsTareas,
    disabled,
    setdisabled,
    Codtarea,
    Fecha,
    Estado,
    Fecha_Inicio,
    Farmacia,
    usuario_asignado,
    usuario_solicita,
    descripcion_general,
    onchangeTipoAtencion,
    tipo_atencion,
    descripcion,
    onchangeDescripcion,
    btn_Agregar,
    dataTareasHis,
    incidente,
    onchangeEstado,
    getTareas,
    disabledButton,
    btn_Delete_his
  }
}
