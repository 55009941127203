import ReportePsicotropicosSisalem from "pages/contentauditoriaprocesos/PiscotropicosSisalem";
import Productos from "pages/contentauditoriaprocesos/Productos";
import { Principal } from "pages/contentprincipal/Principal";
import { RouteObject } from "react-router-dom";

export const rutasAuditoriaProcesos: RouteObject[] = [
  {
    path: "/auditoria",
    element: <Principal />,
  },
  {
    path: "/auditoria-productos",
    element: <Productos />,
  },
  {
    path: "/auditoria/reporte-psicotropicos-sisalem",
    element: <ReportePsicotropicosSisalem />,
  },
];
