import { useCallback, useEffect, useState } from "react";
import { useFetchInEffect, useFetchInFunction } from "hooks/otros/useFetch";

import { AutoCompleteProps, Form, FormInstance, InputProps, ModalProps, SelectProps, UploadProps } from "antd";

import { ModalFormInputsElements } from "context/ModalFormContext";

import { optionsGET, optionsPOST, url_interna, url, verificarucAlgoritmo } from "config/constants";

import moment from "moment";
import "moment/locale/es";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { useLocalStorage } from "hooks/otros/useLocalStorage";

interface documento {
  ruc: string;
  razonsocial: string;
  Cod_Documento?: number | null;
  documento: string;
  concepto: string;
  valor: number;
  tipoPago: string;
  provincia?: number;
}

export interface Recibos extends documento {
  fecha: string;
  pais: number;
  tipoGasto: number;
  ciudad: number | null;
}

export interface NotaVentas extends Recibos {
  numeroautorizacion: string;
}

export interface Facturas extends NotaVentas {
  servicio10: number;
  base0: number;
  base12: number;
  iva: number;
}

export interface FormRecibos extends documento {
  fecha: moment.Moment;
  pais: number;
  tipoGasto?: number;
  ciudad?: number;
}

export interface FormNotaVentas extends FormRecibos {
  numeroautorizacion: string;
}

export interface FormFacturas extends FormNotaVentas {
  servicio10: number;
  base0: number;
  base12: number;
  iva: number;
}

interface dataDatosIniciales {
  tipo_pagos: {
    Cod_Tipo_Pago: string;
    Tipo_Pago: string;
  }[];
  paises: {
    Cod_Pais: number;
    Pais: string;
  }[];
  provincias: {
    Cod_Provincia: number;
    Provincia: string;
  }[];
  tipo_gastos: {
    Cod_Tipo_Gasto: number;
    Tipo_Gasto: string;
  }[];
  razon_social_descripcion: {
    Razon_Social: string
  }[];
}

interface dataCiudades {
  Cod_Ciudad: number;
  Ciudad: string;
}

// CONSTANTES

const verificaRuc = async (_: any, value: string) => {
  if (value.length !== 13) {
    throw new Error();
  } else {
    if (!value.endsWith("001")) throw new Error("Ruc inválido");
    if (parseInt(value.slice(0, 2)) > 24 && parseInt(value.slice(0, 2)) !== 30)
      throw new Error("Ruc inválido");
    if (verificarucAlgoritmo(value)) return;

    const response = await fetch(
      `${url_interna}api/reembolsos/razon-social-por-ruc`,
      optionsPOST(JSON.stringify({ Ruc: value }), { "api-token": "apitoken" })
    )
      .then((res) => res.json())
      .catch(() => ({ msg: "err", descripcion: "No se pudo validar el ruc" }));

    if (response.msg === "ok") return;

    const isValid = await fetch(`${url}api/verificarruc/${value}`)
      .then((res) => res.json())
      .catch(() => false);
    if (!isValid) throw new Error("Ruc inválido");
  }
};

const verificaDocumento = async (_: any, value: string) => {
  if (value.length !== 15) throw new Error();
  if (parseInt(value) === 0) throw new Error("No es un número de documento válido");
};

const verificaNumeroAutorizacion = async (_: any, value: string) => {
  if (value.trim().length !== 49) throw new Error();
  const autorizacion = Array.from(value, Number);
  const mask = "765432765432765432765432765432765432765432765432";
  const suma = Array.from(mask, Number).reduce(
    (previous, current, currentIndex) => previous + current * autorizacion[currentIndex],
    0
  );
  if (11 - (suma % 11) !== autorizacion.at(-1))
    throw new Error("No es un número de autorización válido");
};

const verificaNumeroAutorizacionNotaVenta = async (_: any, value: string) => {
  if (value.length !== 10) throw new Error();
  if (parseInt(value) === 0) throw new Error("No es un número de autorización válido");
};

const verificaTotal = async (_: any, value: string) => {
  if (value === "0") throw new Error();
  if (parseFloat(value) === 0) throw new Error();
};

const rucs: ModalFormInputsElements = {
  elementType: "text",
  elementProps: { name: "ruc", label: "Ruc", hasFeedback: true },
  inputProps: { maxLength: 13 },
};
const razonesSociales: ModalFormInputsElements = {
  elementType: "autocomplete",
  elementProps: {
    name: "razonsocial",
    label: "Razón Social",
    rules: [{ required: true }],
    hasFeedback: true,
    help: false,
  },
  inputProps: { maxLength: 50 },
};
const documentos: ModalFormInputsElements = {
  elementType: "text",
  elementProps: {
    name: "documento",
    label: "Documento",
    rules: [{ required: true, validator: verificaDocumento }],
    hasFeedback: true,
    help: false,
  },
  inputProps: { maxLength: 15 },
};
const detalles: ModalFormInputsElements = {
  elementType: "textarea",
  elementProps: { name: "concepto", label: "Detalle" },
  inputProps: { maxLength: 60, autoSize: { minRows: 1, maxRows: 10 } },
};
const valor: ModalFormInputsElements = {
  elementType: "number",
  elementProps: {
    name: "valor",
    label: "Total",
    rules: [{ required: true, validator: verificaTotal }],
    hasFeedback: true,
    help: false,
  },
  inputProps: { prefix: "$", min: 0, style: { width: "100%" } },
};
const fechas: ModalFormInputsElements = {
  elementType: "datePicker",
  elementProps: {
    name: "fecha",
    label: "Fecha",
    rules: [{ required: true }],
    hasFeedback: true,
    help: false,
  },
};

const paises: ModalFormInputsElements = {
  elementType: "select",
  elementProps: {
    name: "pais",
    label: "País",
    rules: [{ required: true }],
    hasFeedback: true,
    help: false,
  },
};
const provincias: ModalFormInputsElements = {
  elementType: "select",
  elementProps: {
    name: "provincia",
    label: "Provincia",
    rules: [{ required: true }],
    hasFeedback: true,
    help: false,
  },
};
const ciudades: ModalFormInputsElements = {
  elementType: "select",
  elementProps: {
    name: "ciudad",
    label: "Ciudad",
    rules: [{ required: true }],
    hasFeedback: true,
    help: false,
  },
};
const tipoGastos: ModalFormInputsElements = {
  elementType: "select",
  elementProps: {
    name: "tipoGasto",
    label: "Tipo de Gasto",
    rules: [{ required: true }],
    hasFeedback: true,
    help: false,
  },
};

const tipoPagos: ModalFormInputsElements = {
  elementType: "select",
  elementProps: {
    name: "tipoPago",
    label: "Forma de Pago",
    rules: [{ required: true }],
    hasFeedback: true,
    help: false,
  },
};

const elementsFacturas: Array<ModalFormInputsElements> = [
  {
    elementType: "upload",
    elementProps: { label: "Archivo" },
    inputProps: { accept: ".xml", maxCount: 1, showUploadList: false },
  },
  JSON.parse(JSON.stringify(rucs)),
  JSON.parse(JSON.stringify(razonesSociales)),
  documentos,
  {
    elementType: "textarea",
    elementProps: {
      name: "numeroautorizacion",
      label: "Numero de Autorización",
      rules: [{ required: true, validator: verificaNumeroAutorizacion }],
      hasFeedback: true,
    },
    inputProps: { maxLength: 49, autoSize: { minRows: 1, maxRows: 10 } },
  },
  fechas,
  detalles,
  tipoPagos,
  JSON.parse(JSON.stringify(paises)),
  JSON.parse(JSON.stringify(provincias)),
  JSON.parse(JSON.stringify(ciudades)),
  tipoGastos,
  [
    {
      elementType: "number",
      elementProps: {
        labelCol: { span: 12 },
        name: "servicio10",
        label: "Servicio 10%",
        rules: [{ required: true }],
        hasFeedback: true,
        help: false,
      },
      inputProps: { prefix: "$", min: 0, style: { width: "100%" } },
    },
    {
      elementType: "number",
      elementProps: {
        labelCol: { span: 12 },
        name: "base12",
        label: "Base 12",
        rules: [{ required: true }],
        hasFeedback: true,
        help: false,
      },
      inputProps: { prefix: "$", min: 0, style: { width: "100%" } },
    },
  ] as Array<ModalFormInputsElements>,
  [
    {
      elementType: "number",
      elementProps: {
        labelCol: { span: 12 },
        name: "iva",
        label: "Iva",
        rules: [{ required: true }],
        hasFeedback: true,
        help: false,
      },
      inputProps: { prefix: "$", min: 0, style: { width: "100%" } },
    },
    {
      elementType: "number",
      elementProps: {
        labelCol: { span: 12 },
        name: "base0",
        label: "Base 0",
        rules: [{ required: true }],
        hasFeedback: true,
        help: false,
      },
      inputProps: { prefix: "$", min: 0, style: { width: "100%" } },
    },
  ] as Array<ModalFormInputsElements>,
  valor,
];

const uploadXML = elementsFacturas[0];
const rucFacturas = elementsFacturas[1];
const razonsocialFactura = elementsFacturas[2];
rucFacturas.elementProps.rules = [{ required: true, validator: verificaRuc }];
// elementsFacturas[1].elementProps.rules = [{ required: true }]
const paisesFactura = elementsFacturas[8];
const provinciasFactura = elementsFacturas[9];
const ciudadesFactura = elementsFacturas[10];
const servicio10 = (elementsFacturas[12] as unknown as Array<ModalFormInputsElements>)[0];
const base12 = (elementsFacturas[12] as unknown as Array<ModalFormInputsElements>)[1];
const iva = (elementsFacturas[13] as unknown as Array<ModalFormInputsElements>)[0];
const base0 = (elementsFacturas[13] as unknown as Array<ModalFormInputsElements>)[1];

const elementsNotaVentas: Array<ModalFormInputsElements> = [
  JSON.parse(JSON.stringify(rucs)),
  JSON.parse(JSON.stringify(razonesSociales)),
  documentos,
  {
    elementType: "text",
    elementProps: {
      name: "numeroautorizacion",
      label: "Numero de Autorización",
      rules: [{ required: true, validator: verificaNumeroAutorizacionNotaVenta }],
      hasFeedback: true,
      help: false,
    },
    inputProps: { maxLength: 10 },
  },
  fechas,
  tipoPagos,
  detalles,
  JSON.parse(JSON.stringify(paises)),
  JSON.parse(JSON.stringify(provincias)),
  JSON.parse(JSON.stringify(ciudades)),
  tipoGastos,
  valor,
];
const rucNotaVentas = elementsNotaVentas[0];
rucNotaVentas.elementProps.rules = [{ required: true, validator: verificaRuc }];

const razonsocialNotaVentas= elementsNotaVentas[1]
const paisesNotaVenta = elementsNotaVentas[7];
const provinciasNotaVenta = elementsNotaVentas[8];
const ciudadesNotaVenta = elementsNotaVentas[9];

const elementsRecibos: Array<ModalFormInputsElements> = [
  JSON.parse(JSON.stringify(rucs)),
  JSON.parse(JSON.stringify(razonesSociales)),
  {
    elementType: "text",
    elementProps: {
      name: "documento",
      label: "Documento",
      rules: [{ required: true }],
      hasFeedback: true,
      help: false,
    },
    inputProps: { maxLength: 15 },
  },
  fechas,
  tipoPagos,
  JSON.parse(JSON.stringify(paises)),
  JSON.parse(JSON.stringify(provincias)),
  JSON.parse(JSON.stringify(ciudades)),
  tipoGastos,
  {
    elementType: "text",
    elementProps: {
      name: "concepto",
      label: "Concepto",
      rules: [{ required: true }],
      hasFeedback: true,
      help: false,
    },
    inputProps: { maxLength: 50 },
  },
  valor,
];

const rucRecibos = elementsRecibos[0];
rucRecibos.elementProps.rules = [{ required: true, validator: verificaRuc }];

const razonsocialRecibo= elementsRecibos[1];
const paisesRecibo = elementsRecibos[5];
const provinciasRecibo = elementsRecibos[6];
const ciudadesRecibo = elementsRecibos[7];

const inputsModal = [elementsFacturas, elementsNotaVentas, elementsRecibos];

const resetFields = [
  () => {
    provinciasFactura.elementProps.rules = [{ required: true }];
    if ((provinciasFactura.inputProps as SelectProps)?.disabled)
      (provinciasFactura.inputProps as SelectProps).disabled = false;
    ciudadesFactura.elementProps.rules = [{ required: true }];
    if ((ciudadesFactura.inputProps as SelectProps)?.disabled)
      (ciudadesFactura.inputProps as SelectProps).disabled = false;
  },
  () => {
    provinciasNotaVenta.elementProps.rules = [{ required: true }];
    if ((provinciasNotaVenta.inputProps as SelectProps)?.disabled)
      (provinciasNotaVenta.inputProps as SelectProps).disabled = false;
    ciudadesNotaVenta.elementProps.rules = [{ required: true }];
    if ((ciudadesNotaVenta.inputProps as SelectProps)?.disabled)
      (ciudadesNotaVenta.inputProps as SelectProps).disabled = false;
  },
  () => {
    provinciasRecibo.elementProps.rules = [{ required: true }];
    if ((provinciasRecibo.inputProps as SelectProps)?.disabled)
      (provinciasRecibo.inputProps as SelectProps).disabled = false;
    ciudadesRecibo.elementProps.rules = [{ required: true }];
    if ((ciudadesRecibo.inputProps as SelectProps)?.disabled)
      (ciudadesRecibo.inputProps as SelectProps).disabled = false;
  },
];

const initialValuesRecibos: FormRecibos = {
  ruc: "",
  razonsocial: "",
  documento: "",
  concepto: "",
  pais: 226, //Ecuador
  tipoPago: "01", //Sin utilizacion del sistema financiero
  provincia: undefined,
  ciudad: undefined,
  tipoGasto: undefined,
  valor: 0,
  fecha: moment(),
};

const initialValuesNotaVentas: FormNotaVentas = {
  numeroautorizacion: "",
  ...initialValuesRecibos,
};

const initialValuesFactura: FormFacturas = {
  servicio10: 0,
  base12: 0,
  iva: 0,
  base0: 0,
  ...initialValuesNotaVentas,
};

export function useModalFormCrearReembolso() {
  const [modalPropsFactura, setModalPropsFactura] = useState<ModalProps>({
    title: "Agregar Factura",
    open: false,
    okText: "Agregar",
  });
  const [modalPropsNotaVenta, setModalPropsNotaVenta] = useState<ModalProps>({
    title: "Agregar Nota de venta",
    open: false,
    okText: "Agregar",
  });
  const [modalPropsRecibo, setModalPropsRecibo] = useState<ModalProps>({
    title: "Agregar Recibo",
    open: false,
    okText: "Agregar",
  });

  const [inputsState, setInputsState] =
    useState<Array<Array<ModalFormInputsElements>>>(inputsModal);

  const [formFacturas] = Form.useForm<FormFacturas>();
  const [formNotaVentas] = Form.useForm<FormNotaVentas>();
  const [formRecibos] = Form.useForm<FormRecibos>();

  const { optionsPost } = useLocalStorage();

  const updateTotal = useCallback(() => {
    const servicio10: number = formFacturas.getFieldValue("servicio10");
    const base0: number = formFacturas.getFieldValue("base0");
    const base12: number = formFacturas.getFieldValue("base12");
    const iva: number = formFacturas.getFieldValue("iva");

    formFacturas.setFieldValue(
      "valor",
      Math.round((servicio10 + base0 + base12 + iva) * 100) / 100
    );
  }, [formFacturas]);

  const getRazonSocial = useCallback(
    async (value: string, form: FormInstance) => {
      try {
        if (form.getFieldValue("razonsocial").trim()) return;
        if (value.length !== 13) return;
        if (!value.endsWith("001")) return;
        if (parseInt(value.slice(0, 2)) === 0) return;
        if (parseInt(value.slice(0, 2)) > 24 && parseInt(value.slice(0, 2)) !== 30) return;

        const response = await fetch(
          `${url}apifsg-pr/reembolsos/razon-social-por-ruc`,
          optionsPost({ Ruc: value })
        )
          .then((res) => res.json())
          .catch(() => ({ msg: "err", descripcion: "No se pudo obtener la razón social del ruc" }));
        // console.log(response)
        if (response.msg === "err") return;
        form.setFieldValue("razonsocial", response.data);
        // const response = await fetch(`${url}api/razonsocialporruc/${value}`)//.then(res => res.json()).catch()
        // if(!response.ok) return
        // const data = await response.json()
        // data?.contribuyente && form.setFieldValue('razonsocial', data.contribuyente.nombreComercial)
      } catch (e) {
        console.error(e);
      }
    },
    [optionsPost]
  );

  const changePaisFactura = useCallback((value: number) => {
    const disable = value !== 226;

    provinciasFactura.inputProps ??= {};
    ciudadesFactura.inputProps ??= {};
    (provinciasFactura.inputProps as SelectProps).disabled = disable;
    (ciudadesFactura.inputProps as SelectProps).disabled = disable;

    provinciasFactura.elementProps.rules = undefined;
    ciudadesFactura.elementProps.rules = undefined;

    setInputsState([...inputsModal]);
  }, []);

  const changePaisNotaVenta = useCallback((value: number) => {
    const disable = value !== 226;

    provinciasNotaVenta.inputProps ??= {};
    ciudadesNotaVenta.inputProps ??= {};
    (provinciasNotaVenta.inputProps as SelectProps).disabled = disable;
    (ciudadesNotaVenta.inputProps as SelectProps).disabled = disable;

    provinciasNotaVenta.elementProps.rules = undefined;
    ciudadesNotaVenta.elementProps.rules = undefined;

    setInputsState([...inputsModal]);
  }, []);

  const changePaisRecibo = useCallback((value: number) => {
    const disable = value !== 226;

    provinciasRecibo.inputProps ??= {};
    ciudadesRecibo.inputProps ??= {};
    (provinciasRecibo.inputProps as SelectProps).disabled = disable;
    (ciudadesRecibo.inputProps as SelectProps).disabled = disable;

    provinciasRecibo.elementProps.rules = undefined;
    ciudadesRecibo.elementProps.rules = undefined;

    setInputsState([...inputsModal]);
  }, []);


  const changeRazonSocial = useCallback(async(data: string, form: FormInstance) => {
    try{
    //form.setFieldValue("razonsocial", data);
    const response = await fetch(
      `${url}apifsg-pr/reembolsos/ruc-por-razon-social`,
      optionsPost({ Razonsocial: data })
    )
      .then((res) => res.json())
      .catch(() => ({ msg: "err", descripcion: "No se pudo obtener la razón social del ruc" }));
     //console.log(response)
    if (response.msg === "err") return;
    form.setFieldValue("ruc", response.data);
    
  }catch(error){
    console.log("error: ", error)
  }
  }, [optionsPost]);

/*   const onselectRazonSocial = async(data: string, form: FormInstance) => {
       
    form.setFieldValue("razonsocial", data);
    console.log("data", data)
    const response = await fetch(
      `${url}apifsg-pr/reembolsos/ruc-por-razon-social`,
      optionsPost({ Razonsocial: data })
    )
      .then((res) => res.json())
      .catch(() => ({ msg: "err", descripcion: "No se pudo obtener la razón social del ruc" }));
     console.log(response)
    if (response.msg === "err") return;
    form.setFieldValue("ruc", response.data);
    console.log("dfadfsdd select", data)
    setInputsState([...inputsModal]);
  };
  */

  useFetchInEffect(
    `${url_interna}api/reembolsos/listar-datos-iniciales`,
    optionsGET,
    useCallback((dataInic?: dataDatosIniciales, error?: Error) => {
      if (error) {
        console.error(error);
        return;
      }
  
      razonsocialFactura.inputProps ??= {};
      razonsocialNotaVentas.inputProps ??= {};
      razonsocialRecibo.inputProps ??= {};
      (razonsocialFactura.inputProps as AutoCompleteProps).options= dataInic?.razon_social_descripcion.map(
        (item)=> ({ label:item.Razon_Social, value: item.Razon_Social })
      );
      (razonsocialNotaVentas.inputProps as AutoCompleteProps).options= dataInic?.razon_social_descripcion.map(
        (item)=> ({ label:item.Razon_Social, value: item.Razon_Social })
      );
      (razonsocialRecibo.inputProps as AutoCompleteProps).options= dataInic?.razon_social_descripcion.map(
        (item)=> ({ label:item.Razon_Social, value: item.Razon_Social })
      );

      paisesFactura.inputProps ??= {};
      paisesNotaVenta.inputProps ??= {};
      paisesRecibo.inputProps ??= {};
      (paisesFactura.inputProps as SelectProps).options = dataInic?.paises.map(
        ({ Pais, Cod_Pais }) => ({ value: Cod_Pais, label: Pais })
      );
      (paisesNotaVenta.inputProps as SelectProps).options = dataInic?.paises.map(
        ({ Pais, Cod_Pais }) => ({ value: Cod_Pais, label: Pais })
      );
      (paisesRecibo.inputProps as SelectProps).options = dataInic?.paises.map(
        ({ Pais, Cod_Pais }) => ({ value: Cod_Pais, label: Pais })
      );

      provinciasFactura.inputProps ??= {};
      provinciasNotaVenta.inputProps ??= {};
      provinciasRecibo.inputProps ??= {};
      (provinciasFactura.inputProps as SelectProps).options = dataInic?.provincias.map(
        ({ Provincia, Cod_Provincia }) => ({ label: Provincia, value: Cod_Provincia })
      );
      (provinciasNotaVenta.inputProps as SelectProps).options = dataInic?.provincias.map(
        ({ Provincia, Cod_Provincia }) => ({ label: Provincia, value: Cod_Provincia })
      );
      (provinciasRecibo.inputProps as SelectProps).options = dataInic?.provincias.map(
        ({ Provincia, Cod_Provincia }) => ({ label: Provincia, value: Cod_Provincia })
      );

      tipoGastos.inputProps ??= {};
      (tipoGastos.inputProps as SelectProps).options = dataInic?.tipo_gastos.map(
        ({ Tipo_Gasto, Cod_Tipo_Gasto }) => ({ label: Tipo_Gasto, value: Cod_Tipo_Gasto })
      );
      tipoPagos.inputProps ??= {};
      (tipoPagos.inputProps as SelectProps).options = dataInic?.tipo_pagos.map(
        ({ Tipo_Pago, Cod_Tipo_Pago }) => ({ label: Tipo_Pago, value: Cod_Tipo_Pago })
      );
    }, [])
  );

  useEffect(() => {
    servicio10.inputProps ??= {};
    base0.inputProps ??= {};
    base12.inputProps ??= {};
    iva.inputProps ??= {};

    servicio10.inputProps.onChange = () => updateTotal();
    base0.inputProps.onChange = () => updateTotal();
    base12.inputProps.onChange = () => updateTotal();
    iva.inputProps.onChange = () => updateTotal();
  }, [updateTotal]);

  useEffect(() => {
    paisesFactura.inputProps ??= {};
    (paisesFactura.inputProps as SelectProps).onChange = changePaisFactura;
  }, [changePaisFactura]);

  useEffect(() => {
    paisesNotaVenta.inputProps ??= {};
    (paisesNotaVenta.inputProps as SelectProps).onChange = changePaisNotaVenta;
  }, [changePaisNotaVenta]);

  useEffect(() => {
    paisesRecibo.inputProps ??= {};
    (paisesRecibo.inputProps as SelectProps).onChange = changePaisRecibo;
  }, [changePaisRecibo]);

 useEffect(() => {
    rucFacturas.inputProps ??= {};
    (rucFacturas.inputProps as InputProps).onChange = (e) => {
      getRazonSocial(e.target.value, formFacturas);
    };
  }, [getRazonSocial, formFacturas]);

 useEffect(() => {
    rucNotaVentas.inputProps ??= {};
    (rucNotaVentas.inputProps as InputProps).onChange = (e) => {
      getRazonSocial(e.target.value, formNotaVentas);
    };
  }, [getRazonSocial, formNotaVentas]);

  useEffect(() => {
    rucRecibos.inputProps ??= {};
    (rucRecibos.inputProps as InputProps).onChange = (e) => {
      getRazonSocial(e.target.value, formRecibos);
    };
  }, [getRazonSocial, formRecibos]);
 


  useEffect(() => {
    razonsocialNotaVentas.inputProps ??= {};
    (razonsocialNotaVentas.inputProps as AutoCompleteProps).onChange=(value)=>{
      changeRazonSocial(value, formNotaVentas)};
  }, [changeRazonSocial, formNotaVentas]);


  useEffect(() => {
    razonsocialFactura.inputProps ??= {};
    (razonsocialFactura.inputProps as AutoCompleteProps).onChange=(value)=>{
      changeRazonSocial(value, formFacturas)};
  }, [changeRazonSocial, formFacturas]);


/*  useEffect(() => {
    razonsocialFactura.inputProps ??= {};
    (razonsocialFactura.inputProps as AutoCompleteProps).onSelect=(suggestion)=>{
      console.log("value", suggestion)
      onselectRazonSocial(suggestion, formFacturas)
    }
  }, [onselectRazonSocial, formFacturas]);
 */

  useEffect(() => {
    razonsocialRecibo.inputProps ??= {};
    (razonsocialRecibo.inputProps as AutoCompleteProps).onChange=(value)=>{
      changeRazonSocial(value, formRecibos)};
  }, [changeRazonSocial, formRecibos]);

  
/*  useEffect(() => {
    razonsocialNotaVentas.inputProps ??= {};
    (razonsocialNotaVentas.inputProps as AutoCompleteProps).onSelect=(suggestion)=>{
      console.log("value", suggestion)
      onselectRazonSocial(suggestion, formNotaVentas)}
  }, [onselectRazonSocial, formNotaVentas]); */
/* 
 
 */


/*  useEffect(() => {
    razonsocialRecibo.inputProps ??= {};
    (razonsocialRecibo.inputProps as AutoCompleteProps).onSelect=(suggestion)=>{
      console.log("value", suggestion)
      onselectRazonSocial(suggestion, formRecibos)}
  }, [onselectRazonSocial, formRecibos]);
 
  */
 
  const setDataFromXML = useCallback(
    (info: UploadChangeParam<UploadFile<any>>) => {
      if (!Boolean(info.fileList[0]?.originFileObj)) return;

      fetch(URL.createObjectURL(info.fileList[0].originFileObj as Blob))
        .then((res) => res.text())
        .then((data) => {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(data, "text/xml");
          console.log(xmlDoc);
          let xmlInnerDoc: Document;
          if (xmlDoc.getElementsByTagName("comprobante").length === 0) xmlInnerDoc = xmlDoc;
          else
            xmlInnerDoc = parser.parseFromString(
              xmlDoc.getElementsByTagName("comprobante")[0].childNodes[0].textContent ?? "",
              "text/xml"
            );

          const getByTag = (tag: string, pos: number, el?: Element | Document) =>
            el ? el.getElementsByTagName(tag)[pos] : xmlInnerDoc.getElementsByTagName(tag)[pos];
          console.log(xmlInnerDoc);
          if (getByTag("codDoc", 0).innerHTML !== "01") return;

          const totalConImpuestos = getByTag("totalConImpuestos", 0).children;
          const pagos = getByTag("pagos", 0).children;

          let base12 = 0;
          let base0 = 0;
          let iva = 0;

          for (let i = 0; i < totalConImpuestos.length; i++) {
            if (getByTag("codigoPorcentaje", 0, totalConImpuestos[i]).innerHTML === "2") {
              base12 += parseFloat(getByTag("baseImponible", 0, totalConImpuestos[i]).innerHTML);
              iva += parseFloat(getByTag("valor", 0, totalConImpuestos[i]).innerHTML);
            } else {
              base0 += parseFloat(getByTag("baseImponible", 0, totalConImpuestos[i]).innerHTML);
            }
          }

          formFacturas.setFieldsValue({
            numeroautorizacion: getByTag("claveAcceso", 0).innerHTML,
            razonsocial: getByTag("razonSocial", 0).innerHTML,
            ruc: getByTag("ruc", 0).innerHTML,
            documento:
              getByTag("estab", 0).innerHTML +
              getByTag("ptoEmi", 0).innerHTML +
              getByTag("secuencial", 0).innerHTML,
            base12: base12,
            iva: iva,
            base0: base0,
            servicio10: parseFloat(getByTag("propina", 0).innerHTML),
            tipoPago: pagos[0].getElementsByTagName("formaPago")[0].innerHTML,
            fecha: moment(getByTag("fechaEmision", 0).innerHTML, "DD MM YYYY"),
          });

          updateTotal();
        })
        .catch(console.error);
    },
    [formFacturas, updateTotal]
  );

  useEffect(() => {
    uploadXML.inputProps ??= {};
    (uploadXML.inputProps as UploadProps).onChange = setDataFromXML;
    setInputsState([...inputsModal]);
  }, [setDataFromXML]);

  const { fetchData: cargarCiudadesFacturas } = useFetchInFunction(
    `${url_interna}api/reembolsos/listar-ciudades`,
    optionsPOST,
    useCallback((dataInic?: Array<dataCiudades>, error?: Error) => {
      if (error) {
        console.error(error);
        return;
      }
      ciudadesFactura.inputProps ??= {};
      (ciudadesFactura.inputProps as SelectProps).options = dataInic?.map(
        ({ Ciudad, Cod_Ciudad }) => ({ label: Ciudad, value: Cod_Ciudad })
      );

      setInputsState([...inputsModal]);
    }, [])
  );

  const { fetchData: cargarCiudadesNotaVentas } = useFetchInFunction(
    `${url_interna}api/reembolsos/listar-ciudades`,
    optionsPOST,
    useCallback((dataInic?: Array<dataCiudades>, error?: Error) => {
      if (error) {
        console.error(error);
        return;
      }
      ciudadesNotaVenta.inputProps ??= {};
      (ciudadesNotaVenta.inputProps as SelectProps).options = dataInic?.map(
        ({ Ciudad, Cod_Ciudad }) => ({ label: Ciudad, value: Cod_Ciudad })
      );

      setInputsState([...inputsModal]);
    }, [])
  );

  const { fetchData: cargarCiudadesRecibos } = useFetchInFunction(
    `${url_interna}api/reembolsos/listar-ciudades`,
    optionsPOST,
    useCallback((dataInic?: Array<dataCiudades>, error?: Error) => {
      if (error) {
        console.error(error);
        return;
      }
      ciudadesRecibo.inputProps ??= {};
      (ciudadesRecibo.inputProps as SelectProps).options = dataInic?.map(
        ({ Ciudad, Cod_Ciudad }) => ({ label: Ciudad, value: Cod_Ciudad })
      );

      setInputsState([...inputsModal]);
    }, [])
  );

  useEffect(() => {
    provinciasFactura.inputProps ??= {};
    (provinciasFactura.inputProps as SelectProps).onChange = (value) => {
      cargarCiudadesFacturas(JSON.stringify({ Cod_Provincia: value }), { "api-token": "apitoken" });
    };
    provinciasNotaVenta.inputProps ??= {};
    (provinciasNotaVenta.inputProps as SelectProps).onChange = (value) => {
      cargarCiudadesNotaVentas(JSON.stringify({ Cod_Provincia: value }), {
        "api-token": "apitoken",
      });
    };
    provinciasRecibo.inputProps ??= {};
    (provinciasRecibo.inputProps as SelectProps).onChange = (value) => {
      cargarCiudadesRecibos(JSON.stringify({ Cod_Provincia: value }), { "api-token": "apitoken" });
    };
    setInputsState([...inputsModal]);
  }, [cargarCiudadesFacturas, cargarCiudadesNotaVentas, cargarCiudadesRecibos]);

  return {
    modalProps: [modalPropsFactura, modalPropsNotaVenta, modalPropsRecibo],
    setModalProps: [setModalPropsFactura, setModalPropsNotaVenta, setModalPropsRecibo],
    initialValues: [initialValuesFactura, initialValuesNotaVentas, initialValuesRecibos],
    inputsState,
    setInputsState,
    forms: [formFacturas, formNotaVentas, formRecibos],
    resetFields,
    cargarCiudades: [cargarCiudadesFacturas, cargarCiudadesNotaVentas, cargarCiudadesRecibos],
    inputsModal,
  };
}
