import { Table } from "antd";
import { url } from "config/constants";
import { useContext, useEffect, useCallback } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";

type EditableTableProps = Parameters<typeof Table>[0];

export interface DataType {
  key: number;
  cantidad: number;
  to: String;
  divi: String;
  descuento: number;
  promocion: number;
}
const breadCrumbs = [
  { Label: "Tabla de Descuentos en Medicación Continua", Url: "politicas/nuevos" },
];

export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export function usePromocionMc() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);

  useBreadCrumbs(breadCrumbs);

  useEffect(() => {
    // if (window.screen.width <= 992) return;

    setOpenKeys(["Políticas Comerciales/Descuentos y Rentabilidad", "Políticas Comerciales"]);
    setSelectedKeys(["Descuentos y Rentabilidad/PMC"]);
  }, [setOpenKeys, setSelectedKeys]);

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      width: "200px",
      editable: true,
      align: "center",
    },
    {
      title: "",
      dataIndex: "to",
      width: "20px",
      align: "center",
    },
    {
      title: "Bonificación",
      dataIndex: "promocion",
      width: "200px",
      editable: true,
      align: "center",
    },
    {
      title: "",
      dataIndex: "divi",
      width: "20px",
      align: "center",
    },
    {
      title: "Descuento %",
      dataIndex: "descuento",
      width: "260px",
      editable: true,
      align: "center",
    },
  ];

  const getPromocionPmc = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "politicas/descuentos_pmc", {
        method: "GET",
      }).then((res) => res.json());
      if (respuesta.msg) {
        const { data } = respuesta;
        return { data, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, []);

  type Data = {
    key: number;
    cantidad: number;
    promocion: number;
    descuento: number;
  };

  const putDescuentosPmc = async (form: Data) => {
    try {
      const respuesta = await fetch(url + "politicas/actualiza_pmc", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      }).then((res) => res.json());
      if (respuesta.ok) {
        return { data: respuesta.msg, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexión", status: false };
    }
  };

  return { getPromocionPmc, putDescuentosPmc, defaultColumns };
}
