import { Card, Col, Form, Row, DatePicker, InputNumber, Button, Table, Modal, Spin } from "antd";
import { ButtonExcel } from "components/otros/buttons";
import { useFacturasMultiples } from "hooks/inventarios/reportes/facturasMultiples/useFacturasMultiples";
import moment from "moment";
import { useVT } from "virtualizedtableforantd4";

const FacturasMultiples = () => {
  const {
    headTableReportDetails,
    dataFiltradaDetails,
    closeModal,
    openModal,
    form,
    disabledDate,
    handleClickGenerar,
    dataFiltrada,
    headTableReport,
    loader,
    bodegaSelect,
    handleClickDownload,
    loaderDetails,
    handleClickDownloadDetails,
    handleClickDownloadDataPrincipal,
    loaderDetailsFile,
  } = useFacturasMultiples();

  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);
  return (
    <>
      <Card>
        <Form
          form={form}
          layout='horizontal'
          initialValues={{
            year: moment(),
          }}
        >
          <Row gutter={[10, 10]}>
            <Col xs={24} md={10}>
              <Form.Item
                label='Seleccione una fecha:'
                name='fecha'
                rules={[{ required: true, message: "Por favor seleccione una fecha" }]}
              >
                <DatePicker.RangePicker format='YYYY-MM-DD' disabledDate={disabledDate} style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                label='# de facturas'
                name='facturas'
                rules={[{ required: true, message: "Por favor ingrese el número de facturas" }]}
              >
                <InputNumber style={{ width: "100%" }} min={1}></InputNumber>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Button type='primary' style={{ width: "100%" }} onClick={handleClickGenerar} loading={loader}>
                Generar
              </Button>
            </Col>
          </Row>
        </Form>
        <Row gutter={[5, 5]}>
          <Col xs={24}>
            {dataFiltrada.length > 0 && (
              <Table
                columns={headTableReport}
                // components={vt}
                dataSource={dataFiltrada}
                scroll={{ x: 900, y: 450 }}
                //scroll={{ x: true }}
                size='small'
                pagination={false}
                bordered
                loading={loader}
              />
            )}
          </Col>
        </Row>
        <Row gutter={[5, 5]}>
          <Col xs={18}></Col>
          <Col xs={6}>
            {dataFiltrada.length > 0 && <ButtonExcel buttonProps={{ onClick: handleClickDownloadDataPrincipal }}></ButtonExcel>}
          </Col>
        </Row>
      </Card>
      <Modal
        title={`Facturas multiples ${bodegaSelect}`}
        open={openModal}
        footer={null}
        width='80%'
        // style={{ height: "55%" }}
        onOk={closeModal}
        onCancel={closeModal}
        destroyOnClose
      >
        {loaderDetails && (
          <div className='center_spin'>
            <Spin />
          </div>
        )}
        {dataFiltradaDetails.length > 0 && (
          <Table
            components={vt}
            columns={headTableReportDetails}
            dataSource={dataFiltradaDetails}
            scroll={{ x: 900, y: 450 }}
            //scroll={{ x: true }}
            size='small'
            pagination={false}
            bordered
            style={{ marginBottom: "10px" }}
          />
        )}
        <Row gutter={[5, 5]}>
          <Col xs={12}></Col>
          <Col xs={6}>
            <ButtonExcel buttonProps={{ onClick: handleClickDownloadDetails, loading: loaderDetailsFile }}>
              Descargar detalles
            </ButtonExcel>
          </Col>
          <Col xs={6}>
            <ButtonExcel buttonProps={{ onClick: handleClickDownload }}></ButtonExcel>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default FacturasMultiples;
