import { Form, Divider, Row, Col, Input, InputNumber, Checkbox, Select, Radio, Tooltip } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import TablaConveniosProductos from "./TablaConveniosProductos";
import { CheckboxChangeEvent } from "antd/es/checkbox";
const ListCuota = ({
  porcentaje,
  resto,
  setPorcentaje,
  checkIn,
  setCheckIn,
  setResto,
  totalRebate,
  hidden,
  opcionesProductos,
  onChangeOpcionesProductos,
  opciones,
  dataTable,
  setSeleccion,
  setDataTable,
  seleccion,
  isLimited,
  // radioValue,
  toggleLimit,
  onChangeEsDevoluciones,
  // validateCheckboxes,
  editKeyIn,
  setEditKeyIn,
}: any) => {
  return (
    <>
      <Divider dashed orientation='left' orientationMargin='0' style={{ fontSize: "12px" }}>
        DEVOLUCIÓN
      </Divider>
      <Row wrap={true}>
        <Col xs={24} xl={8}>
          <Form.Item
            name='aplica_devolucion'
            label='Aplica devolución'
            rules={[{ required: true, message: "Debe marcar una opción" }]}
          >
            <Radio.Group onChange={onChangeEsDevoluciones}>
              <Radio value={true}>Si</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} xl={16}>
          {/* <Form.Item name='devoluciones' rules={[{ validator: validateCheckboxes }]}>
            <Checkbox.Group style={{ width: "100%" }}>
              <Row>
                <Col xs={24} xl={12}>
                  <Checkbox value='devolucion_vencimiento' disabled={!radioValue}>
                    El convenio tiene devolución por vencimiento
                  </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                  <Checkbox value='devolucion_otros' disabled={!radioValue}>
                    El convenio tiene devolución por otros motivos
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item> */}
        </Col>
      </Row>
      <Divider style={{ marginTop: "-5px" }}></Divider>

      <Form.List name='distribucionIn' initialValue={[{ es_monto: false, valor: "", rebate: "", es_divide: false }]} key={0}>
        {(fields = [], { add, remove }) => {
          return (
            <>
              <Divider dashed orientation='left' orientationMargin='0' style={{ fontSize: "12px" }}>
                CUOTA NEGOCIACIÓN
              </Divider>

              {fields.map(({ key, name, ...restField }) => {
                let max: any = [];

                if (porcentaje[name] && resto[name] !== null) {
                  max[name] = (porcentaje[name] ?? 0) - resto[name];
                  max[name] = max[name] + resto[name];
                }

                return (
                  <Row wrap={true} key={name}>
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item>
                        <Input.Group className='group_rebate'>
                          <Row wrap={true} gutter={7} style={{ width: "100%" }}>
                            {fields.length === 1 && (
                              <Col xs={24} lg={3}>
                                <Tooltip title='Active esta opción si el convenio es sobre el monto total de las compras o cuando no se establece un valor mínimo establecido.'>
                                  <Form.Item name={[name, "es_monto"]} valuePropName='checked'>
                                    <Checkbox
                                      // checked={!isLimited}
                                      onChange={(e) => toggleLimit(e.target.checked, name)}
                                      defaultChecked={!isLimited}
                                    >
                                      Monto total
                                    </Checkbox>
                                  </Form.Item>
                                </Tooltip>
                              </Col>
                            )}
                            <Col xs={24} lg={6}>
                              <Form.Item
                                {...restField}
                                name={[name, "valor"]}
                                rules={[
                                  {
                                    required: isLimited,
                                    message: "El valor es obligatorio",
                                  },
                                ]}
                              >
                                <InputNumber
                                  disabled={!isLimited}
                                  addonBefore='Valor'
                                  style={{ width: "100%" }}
                                  min={0}
                                  step={0.1}
                                  placeholder='Escriba el valor del convenio'
                                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={5}>
                              <Form.Item
                                {...restField}
                                name={[name, "rebate"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "El % rebate es obligatorio",
                                  },
                                ]}
                              >
                                <InputNumber
                                  placeholder='Escriba el porcentaje de rebate'
                                  onChange={(e: any) => setPorcentaje({ ...porcentaje, [name]: e })}
                                  addonBefore='Rebate %'
                                  style={{ width: "100%" }}
                                  min={0}
                                  max={100}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} xl={8}>
                              <Form.Item {...restField} name={[name, "es_divide"]} valuePropName='checked'>
                                <Checkbox
                                  onChange={(e: CheckboxChangeEvent) => setCheckIn({ ...checkIn, [name]: e.target.checked })}
                                  defaultChecked={checkIn[name]}
                                >
                                  El % de Rebate se subdivide
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            {fields.length === name + 1 && isLimited && (
                              <Col xs={24} lg={1}>
                                <PlusCircleOutlined onClick={() => add()} />
                              </Col>
                            )}
                            {fields.length === name + 1 && isLimited && (
                              <Col xs={24} lg={1}>
                                <MinusCircleOutlined
                                  onClick={() => {
                                    if (name > 0) {
                                      setCheckIn((prevObj: any) => {
                                        const newObj = { ...prevObj };
                                        delete newObj[name];
                                        return newObj;
                                      });
                                      return remove(name);
                                    }
                                  }}
                                />
                              </Col>
                            )}
                          </Row>
                        </Input.Group>
                      </Form.Item>
                    </Col>
                    <Row wrap={true} gutter={6}>
                      <Col xs={24} xl={24} style={{ display: !checkIn[name] ? "none" : "block" }}>
                        {/* <Col> */}
                        <Form.Item
                          label='Distribución Rebate'
                          style={{ width: "100%" }}
                          name={[name, "subdivide"]}
                          rules={[
                            {
                              validator: () => {
                                if (checkIn[name] && max[name] !== resto[name]) {
                                  return Promise.reject(
                                    "El rebate principal debe coincidir con el total de la distribución"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input.Group className='group_rebate'>
                            <Form.Item name={[name, "compras"]}>
                              <InputNumber
                                addonBefore='Compras'
                                style={{ width: "100%" }}
                                addonAfter='%'
                                min={0}
                                max={max[name]}
                                onChange={() => {
                                  setResto({ ...resto, [name]: totalRebate(name) });
                                }}
                              />
                            </Form.Item>
                            <Form.Item name={[name, "ventas"]}>
                              <InputNumber
                                addonBefore='Ventas'
                                addonAfter='%'
                                style={{ width: "100%" }}
                                min={0}
                                max={max[name]}
                                onChange={() => {
                                  setResto({ ...resto, [name]: totalRebate(name) });
                                }}
                              />
                            </Form.Item>
                            <Form.Item name={[name, "mercadeo"]}>
                              <InputNumber
                                addonBefore='Mercadeo'
                                addonAfter='%'
                                style={{ width: "100%" }}
                                min={0}
                                max={max[name]}
                                onChange={(values) => {
                                  // setResto(totalRebate(name));
                                  setResto({ ...resto, [name]: totalRebate(name) });
                                }}
                              />
                            </Form.Item>
                            <Form.Item name={[name, "supervisor"]}>
                              <InputNumber
                                addonBefore='Supervisores'
                                addonAfter='%'
                                style={{ width: "100%" }}
                                min={0}
                                max={max[name]}
                                onChange={() => {
                                  // setResto(totalRebate(name));
                                  setResto({ ...resto, [name]: totalRebate(name) });
                                }}
                              />
                            </Form.Item>
                            <Form.Item name={[name, "convencion"]}>
                              <InputNumber
                                addonBefore='Convencion'
                                addonAfter='%'
                                style={{ width: "100%" }}
                                min={0}
                                max={max[name]}
                                onChange={() => {
                                  // setResto(totalRebate(name));
                                  setResto({ ...resto, [name]: totalRebate(name) });
                                }}
                              />
                            </Form.Item>
                          </Input.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Divider style={{ marginTop: "-30px" }}></Divider>
                  </Row>
                );
              })}
            </>
          );
        }}
      </Form.List>
      <Col xs={24} xl={24}>
        {!hidden && (
          <Form.Item
            label={`Opciones por Productos`}
            // name='cod_opciones_por_productos'
            name={"cod_opciones_por_productos_in"}
            rules={[
              {
                required: !hidden,
                message: "Opciones por productos es obligatoria",
              },
            ]}
            style={{ width: "100%" }}
          >
            <Select
              options={opcionesProductos}
              allowClear
              // onChange={(e) => setOpciones2({ ...opciones2, [name]: e })}
              onChange={(e) => onChangeOpcionesProductos(e, 0)}
              showSearch
              optionFilterProp='label'
              placeholder='Seleccione una opcion'
            ></Select>
          </Form.Item>
        )}
      </Col>
      {opciones[0] !== 1 && opciones[0] && (
        <Form.Item name={"table"}>
          <TablaConveniosProductos
            dataTable={dataTable}
            opciones={opciones[0]}
            onChangeOpcionesProductos={onChangeOpcionesProductos}
            porcentaje={porcentaje[0]}
            setSeleccion={setSeleccion}
            setDataTable={setDataTable}
            seleccion={seleccion}
            name={0}
            editKeyIn={editKeyIn}
            setEditKeyIn={setEditKeyIn}
          />
        </Form.Item>
      )}
    </>
  );
};

export default ListCuota;
