import { Image, StyleSheet } from "@react-pdf/renderer"
import { Report } from "components/reactreport/Report"
import { Button, Col, Form, Row, Select, Table } from "antd"
import { ModalReport } from "components/otros/ModalReport"
import { Title } from "components/reactreport/Title"
import { columnsTablePDF, Table as TableReport } from "components/reactreport/Table"
import { FormInModal, valuesFormLlamadosAtencion } from "components/rrhh/componentsllamadosatencion/FormInModal"
import { ButtonPDF } from "components/otros/buttons"

import { useContext, useMemo, useState } from "react"
import { useDataForTable } from "hooks/rrhh/llamadosatencion/useDataForTable"
import { useFillStateWithFetch } from "hooks/otros/useFillStateWithFetch"
import { useLayout } from "hooks/otros/useLayout"

import { UserContext } from "context/UserContext"

import { url } from "config/constants"

import { EyeOutlined } from "@ant-design/icons"

import type { dataLlamadosAtencion } from "hooks/rrhh/llamadosatencion/useDataForTable"
import type { ColumnProps } from "antd/lib/table"
import type { DefaultOptionType } from "antd/lib/select"
import moment from "moment"

const breadcrumbs = [
  { Label: "Recursos Humanos", Url: "/rrhh" },
  { Label: "Llamados de atención", Url: "/rrhh/llamados-de-atencion" },
  { Label: "Reporte por empleado" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export function ReporteHistoricoPorEmpleado() {
  useLayout(breadcrumbs, openKeys, selectKeySider)

  const [openModalReport, setOpenModalReport] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedLlamadoAtencion, setSelectedLlamadoAtencion] = useState<number>(0)

  const [empleados, setEmpleados] = useState<DefaultOptionType[]>([])
  const [selectedEmpleado, setSelectedEmpleado] = useState<number>(0)

  const [form] = Form.useForm<valuesFormLlamadosAtencion>()

  const { userData } = useContext(UserContext)

  const handleClickAction = (
    value: number,
    {
      multa,
      porcentaje,
      periodo_actividad,
      tipo_actividad,
      bodega,
      departamento,
      observacion,
      fecha_falta,
      fecha_creacion,
      motivo,
      descripcion,
    }: dataLlamadosAtencion
  ) => {
    setSelectedLlamadoAtencion(llamadosAtencion.findIndex(ll => ll.key === value))
    setOpenModal(true)
    form.setFieldsValue({
      multa,
      porcentaje,
      tipo_actividad,
      bodega,
      departamento,
      observacion,
      fecha_falta,
      fecha_creacion,
      motivo,
      descripcion,
      periodo_actividad: moment(periodo_actividad, "YYYYMM"),
    })
  }

  const bodyGetData = useMemo(() => ({ cod_empleado: selectedEmpleado }), [selectedEmpleado])

  const renderActions: ColumnProps<dataLlamadosAtencion>["render"] = (value, record) => (
    // <Space direction="horizontal">
    //   <Button
    //     icon={<GrDocumentPdf style={{ fontSize: "1.3rem" }} />}
    //     type="text"
    //     onClick={() => setOpenModalReport(true)}
    //     style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    //     block
    //   />
    <Button
      icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
      type="text"
      onClick={() => handleClickAction(value, record)}
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      block
    />
    // </Space>
  )

  const { llamadosAtencion, columnsTable } = useDataForTable(
    "apifsg-pr/recursos-humanos/listar-llamados-atencion-por-empleado",
    userData?.menu
      .find(menu => menu.label === "Recursos Humanos")
      ?.children?.find(menu => menu.label === "Llamados de atención")
      ?.children?.find(menu => menu.label === "Reporte histórico") && renderActions,
    bodyGetData
  )

  useFillStateWithFetch(
    `${url}apifsg-pr/recursos-humanos/empleados-select`,
    setEmpleados,
    "No se pudo obtener los empleados"
  )

  return (
    <>
      <Row wrap style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form>
            <Form.Item label="Empleados">
              <Select
                options={empleados}
                value={selectedEmpleado || undefined}
                onChange={value => setSelectedEmpleado(value)}
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <ButtonPDF
            buttonProps={{
              onClick: () => setOpenModalReport(true),
              disabled: !Boolean(llamadosAtencion) || llamadosAtencion.length === 0,
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table scroll={{ x: true }} columns={columnsTable} dataSource={llamadosAtencion} />
        </Col>
      </Row>
      <ModalReport
        openModal={openModalReport}
        setOpenModal={setOpenModalReport}
        titleModal="Reporte de Llamado de Atención"
        pdfName={`Llamados de atención`}
      >
        <Reporte
          llamadosAtencion={llamadosAtencion}
          empleado={empleados.find(empleado => empleado.value === selectedEmpleado)?.label as string}
        />
      </ModalReport>
      <FormInModal
        openModal={openModal}
        form={form}
        handleCloseModal={() => setOpenModal(false)}
        llamadosAtencion={llamadosAtencion}
        selectedIndex={selectedLlamadoAtencion}
        titleModal="Llamado de atención"
        disabledAll
        showEmpleados={false}
      />
    </>
  )
}

interface propsReporte {
  llamadosAtencion: dataLlamadosAtencion[]
  empleado: string
}

const styles = StyleSheet.create({
  // text: { fontSize: 10, textAlign: 'justify', marginTop: '15px', lineHeight: 1.3 },
  // textBold: { fontSize: 10, textAlign: 'justify', marginTop: '15px', lineHeight: 1.3, fontFamily: 'Helvetica-Bold' },
  // numeration: { textAlign: 'right', fontFamily: 'Helvetica-Bold', fontSize: 10 },
  title: { paddingTop: "3px" },
  // row: { display: 'flex', flexDirection: 'row', alignItems: 'flex-start', paddingBottom: '5px' },
  // columnTitle: { fontFamily: 'Helvetica-Bold', fontSize: 10, flexBasis: `15%` },
  // column: { fontSize: 10, flexBasis: `85%` },
  logo: { width: "40%", margin: "0 auto 0 auto", objectFit: "scale-down" },
  columnTable: { borderRight: "1px solid #f0f0f0" },
})

export function Reporte({ llamadosAtencion, empleado }: propsReporte) {
  const columnsTable: columnsTablePDF[] = [
    {
      title: "Solicitante",
      dataIndex: "solicitante",
      styleColumnHeader: { ...styles.columnTable, flexBasis: "30%" },
      styleColumn: { ...styles.columnTable, flexBasis: "30%" },
    },
    {
      title: "Bodega",
      dataIndex: "bodega",
      styleColumnHeader: { ...styles.columnTable, flexBasis: "35%" },
      styleColumn: { ...styles.columnTable, flexBasis: "35%" },
    },
    {
      title: "Fecha de la falta",
      dataIndex: "fecha_falta",
      styleColumnHeader: { ...styles.columnTable, flexBasis: "16%" },
      styleColumn: { ...styles.columnTable, flexBasis: "16%" },
    },
    {
      title: "Fecha de solicitud",
      dataIndex: "fecha_creacion",
      styleColumnHeader: { flexBasis: "19%" },
      styleColumn: { flexBasis: "19%" },
    },
  ]

  return (
    <Report
      documentProps={{ title: `${empleado} llamados de atencion` }}
      pageProps={{ size: "A4", orientation: "landscape" }}
    >
      <Image src="/imagen/logoFSG.png" style={styles.logo} />
      <Title style={styles.title}>
        Historial de llamados de atención de{" "}
        {empleado
          .toLowerCase()
          .split(" ")
          .map(nombre => nombre.charAt(0).toUpperCase() + nombre.substring(1))
          .join(" ")}
      </Title>
      <TableReport
        columns={columnsTable}
        dataSource={llamadosAtencion.map(({ solicitante, bodega, fecha_falta, fecha_creacion }) => ({
          solicitante,
          bodega,
          fecha_falta,
          fecha_creacion,
        }))}
      />
    </Report>
  )
}
