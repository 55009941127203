import { Button, Card, Col, Typography, Row, Transfer } from "antd";
import { useLayout } from "hooks/otros/useLayout";
import { url } from "config/constants";
import { optionsPost, optionsGet, swalSuccess } from "config/methods";
import "./BodegaPOS.css";
import { SaveOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

const { Title } = Typography;

const breadcrumbs = [
  { Label: "Sistemas", Url: "/sistemas" },
  { Label: "Bodega POS", Url: "/sistemas/bodega-POS" },
];
const openKeys = [breadcrumbs[0].Label];
const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`;

interface RecordType {
  key: string;
  title: string;
  description: string;
  chosen: boolean;
}

export default function BodegaPOS() {
  useLayout(breadcrumbs, openKeys, selectKeySider);
  const [dataoptions, setdataoptions] = useState<RecordType[]>([]);
  const [dataoptionstrue, setdataoptionstrue] = useState<string[]>([]);

  const handleChange = (newTargetKeys: string[]) => {
    setdataoptionstrue(newTargetKeys);
  };
  const getbodegaPOS = async () => {
    const res = await fetch(`${url}fapifsg-pr/sistemas/getbodegaPOS`, optionsGet()).then((data) => data.json());
    //let respuesta=res.data;
    let newget: RecordType[] = [];
    res.forEach((item: any) => {
      let add = {
        key: item.Cod_Bodega,
        title: item.Descripcion,
        description: item.Descripcion,
        chosen: item.Tiene_Pos,
      };
      newget = [...newget, add];
    });
    const datatrue = res.filter((item: any) => item.Tiene_Pos === true).map((item: any) => item.Cod_Bodega);
    setdataoptionstrue(datatrue);
    //setdatainit(newget)
    setdataoptions(newget);
  };

  useEffect(() => {
    getbodegaPOS();
  }, []);

  const btn_Guardar = async () => {
    try {
      const cadenaConCorchetes: string = JSON.stringify(dataoptionstrue);
      const cadenaSinCorchetes: string = cadenaConCorchetes.replace("[", "").replace("]", "");

      const res = await fetch(`${url}fapifsg-pr/sistemas/updateConexionPOS`, optionsPost({ bodegas: cadenaSinCorchetes }));

      if (res.status === 200) {
        swalSuccess({ text: "Se guardó exitosamente" });
        getbodegaPOS();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterOption = (inputValue: string, option: RecordType) =>
    option.description.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  // const renderItem = (item: RecordType) => ({
  //   label: (
  //     <div style={{ pointerEvents: "none" }}>
  //       <span>{item.title}</span>
  //     </div>
  //   ),
  //   value: item.title,
  // });

  return (
    <>
      <Card style={{ width: "100%", border: "0px" }}>
        <Col>
          <Transfer
            className='tranfer-table'
            dataSource={dataoptions}
            showSearch
            filterOption={filterOption}
            targetKeys={dataoptionstrue}
            onChange={handleChange}
            listStyle={{ width: "100%", height: 450 }}
            //onSearch={handleSearch}
            render={(item) => item.title}
            titles={[
              "",
              <Row justify='end'>
                <Col md={18} style={{ paddingLeft: "20px", textAlign: "center" }}>
                  <Title level={4} className='title-transfer'>
                    Conexión POS
                  </Title>
                </Col>
                <Col md={6}>
                  <Button icon={<SaveOutlined />} type='primary' style={{ float: "right", margin: 5 }} onClick={btn_Guardar}>
                    Guardar
                  </Button>
                </Col>
              </Row>,
            ]}
          />
        </Col>
      </Card>
    </>
  );
}
