import { FormInstance } from "antd";
import { swalError } from "config/methods";
import { toPng } from "html-to-image";
import jsPDF from "jspdf";
import { PropsBilleteate, PropsBilleteateDetails } from "./useCumplimientoBilleteate";
import moment from "moment";

type ChartIds = "myChart" | "myChartUser" | "myChartUserDetails";

const formateaCantidad = (cantidad: number) => {
  const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return totalCostoFormateado;
};

export const useHandleReportPdf = (form: FormInstance) => {
  const convertChartToImage = async (nodeId: string) => {
    try {
      const node = document.getElementById(nodeId);
      const dataUrl = node && (await toPng(node));
      return dataUrl;
    } catch (error) {
      swalError({ text: "Ocurrio un error" });
      return null;
    }
  };

  const handleDownloadPdf = async (
    idChart: ChartIds = "myChart",
    headSelect: PropsBilleteate,
    dataBilleteateDetails: PropsBilleteateDetails[] | null,
    dataBilleteateUser: PropsBilleteate[] | null,
    dataDetailGraph: PropsBilleteate[] | null,
    dataDetailsUser: PropsBilleteate[] | null,
    dataFiltrada: PropsBilleteate[]
  ) => {
    try {
      const imgData = await convertChartToImage(idChart);

      const downloadFunctions: Record<ChartIds, (imgData: string) => Promise<void>> = {
        myChart: (imgData) => downloadPDFBodega(imgData, headSelect, dataBilleteateDetails),
        myChartUser: (imgData) => downloadPDFDependiente(imgData, dataFiltrada, dataBilleteateUser),
        myChartUserDetails: (imgData) =>
          downloadPDFDependienteDetails(imgData, headSelect, dataDetailsUser, dataDetailGraph),
      };

      if (imgData && downloadFunctions[idChart]) {
        await downloadFunctions[idChart](imgData);
      } else {
        swalError({ text: "Ocurrio un error" });
      }
    } catch (error) {
      swalError({ text: "Ocurrio un error" });
    }
  };

  const downloadPDFBodegaDependienteGeneral = async (
    imgData: string,
    title: string = "",
    h1: string = "",
    head: string[],
    filteredKeys: string[],
    allowedKeys: string[],
    dataFiltrada: PropsBilleteate[] | null
  ) => {
    try {
      const doc = new jsPDF();
      const img = new Image();
      img.src = "/imagen/images.jpg";

      img.onload = async () => {
        doc.setFontSize(10);
        doc.text(title, 15, 10);

        doc.line(15, 11, 200, 11);

        doc.addImage(img, "JPG", 170, 3, 30, 10);
        doc.setFontSize(18);
        doc.text(h1, 105, 25, { align: "center" });
        doc.setFontSize(12);
        doc.text(`PERIODO: ${form.getFieldsValue().year.format("YYYY")}`, 15, 32);
        doc.text(`FECHA EMISIÓN: ${moment().format("YYYY-MM-DD")}`, 138, 32);

        const tableYPosition = 39;
        const totalVentas = dataFiltrada?.reduce((sum, obj) => sum + obj.ventas, 0) || 0;
        const totalBilleteate = dataFiltrada?.reduce((sum, obj) => sum + obj.billetéate, 0) || 0;
        // @ts-ignore
        doc.autoTable({
          startY: tableYPosition,
          startX: 5,
          head: [head],
          body: [
            ...(dataFiltrada?.map((obj) =>
              Object.entries(obj)
                .filter(([key, value]) => !filteredKeys.includes(key))
                .map(([key, value]) => (allowedKeys.includes(key) ? formateaCantidad(value) : value))
            ) || []),
            [
              "",
              formateaCantidad(totalVentas),
              formateaCantidad(totalBilleteate),
              formateaCantidad((totalBilleteate / totalVentas) * 100),
            ],
          ],
          margin: { left: 15, right: 15 },
        });
        // @ts-ignore
        const imageYPosition = doc.previousAutoTable.finalY + 10;
        const imgDatad = await convertChartToImage(imgData);

        imgDatad && doc.addImage(imgDatad, "JPG", 10, imageYPosition, 180, 100);

        doc.save(`reporte_general_${form.getFieldsValue().year.format("YYYY")}.pdf`);
      };
      img.onerror = () => {
        swalError({ text: "Ocurrio un error" });
      };
    } catch (error) {
      swalError({ text: "Ocurrio un error" });
    }
  };

  const downloadPDFBodegaGeneral = async (dataFiltrada: PropsBilleteate[]) => {
    try {
      const doc = new jsPDF();
      const img = new Image();
      img.src = "/imagen/images.jpg";

      img.onload = () => {
        doc.setFontSize(10);
        doc.text("CUMPLIMIENTO BILLETÉATE", 15, 10);
        doc.line(15, 11, 200, 11);

        doc.addImage(img, "JPG", 170, 3, 30, 10);
        doc.setFontSize(18);
        doc.text(`REPORTE POR BODEGAS `, 105, 25, { align: "center" });
        doc.setFontSize(12);
        doc.text(`PERIODO: ${dataFiltrada[0].periodo}`, 15, 32);
        doc.text(`FECHA EMISIÓN: ${moment().format("YYYY-MM-DD")}`, 138, 32);

        const tableYPosition = 39;
        const totalVentas = dataFiltrada?.reduce((sum, obj) => sum + obj.ventas, 0) || 0;
        const totalBilleteate = dataFiltrada?.reduce((sum, obj) => sum + obj.billetéate, 0) || 0;
        // @ts-ignore
        doc.autoTable({
          startY: tableYPosition,
          startX: 5,
          head: [["Farmacia", "Supervisor", "Ventas", "Billetéate", "%"]],
          body: [
            ...(dataFiltrada?.map((obj) =>
              Object.entries(obj)
                .filter(([key, value]) => key !== "key" && key !== "periodo")
                .map(([key, value]) =>
                  key === "ventas" || key === "billetéate" || key === "cumplimiento" ? formateaCantidad(value) : value
                )
            ) || []),
            [
              "",
              "",
              formateaCantidad(totalVentas),
              formateaCantidad(totalBilleteate),
              formateaCantidad((totalBilleteate / totalVentas) * 100),
            ],
          ],
          margin: { left: 7, right: 7 },
        });
        doc.save(`reporte_bodegas_detalles_${dataFiltrada[0].periodo}.pdf`);
      };
      img.onerror = () => {
        swalError({ text: "Ocurrio un error" });
      };
    } catch (error) {
      swalError({ text: "Ocurrio un error" });
    }
  };

  const downloadPDFBodega = async (
    imgData: string,
    headSelect: PropsBilleteate,
    dataBilleteateDetails: PropsBilleteateDetails[] | null
  ) => {
    try {
      const doc = new jsPDF();
      const img = new Image();
      img.src = "/imagen/images.jpg";

      img.onload = () => {
        doc.setFontSize(10);
        doc.text("CUMPLIMIENTO BILLETÉATE", 15, 10);
        doc.line(15, 11, 200, 11);

        doc.addImage(img, "JPG", 170, 3, 30, 10);
        doc.setFontSize(18);
        doc.text(`${headSelect?.farmacia}`, 105, 25, { align: "center" });
        doc.setFontSize(12);
        doc.text(`PERIODO: ${headSelect.periodo}`, 15, 40);
        doc.text(`SUPERVISOR: ${headSelect?.supervisor}`, 15, 50);
        doc.text(`TOTAL VENTAS : $ ${headSelect?.ventas}`, 15, 60);
        doc.text(`BILLETÉATE: $ ${headSelect?.billetéate}`, 15, 70);
        doc.text(`CUMPLIMIENTO: ${headSelect?.cumplimiento} %`, 15, 80);
        const tableYPosition = 90;
        // @ts-ignore
        doc.autoTable({
          startY: tableYPosition,
          head: [["Dependiente", "Ventas", "Billetéate", "Cumplimiento"]],
          body: dataBilleteateDetails?.map((obj) =>
            Object.entries(obj)
              .filter(([key, value]) => key !== "key")
              .map(([key, value]) => value)
          ),
        });
        // @ts-ignore
        const imageYPosition = doc.previousAutoTable.finalY + 10;
        doc.addImage(imgData, "JPG", 10, imageYPosition, 180, 100);
        doc.save(`${headSelect?.farmacia}.pdf`);
      };
      img.onerror = () => {
        swalError({ text: "Ocurrio un error" });
      };
    } catch (error) {
      swalError({ text: "Ocurrio un error" });
    }
  };

  const downloadPDFDependiente = async (
    imgData: string,
    dataFiltrada: PropsBilleteate[],
    dataBilleteateUser: PropsBilleteate[] | null
  ) => {
    try {
      const doc = new jsPDF();
      const img = new Image();
      img.src = "/imagen/images.jpg";

      img.onload = () => {
        doc.setFontSize(10);
        doc.text("CUMPLIMIENTO BILLETÉATE", 15, 10);
        doc.line(15, 11, 200, 11);
        doc.addImage(img, "JPG", 170, 3, 30, 10);
        doc.setFontSize(18);
        doc.text(`REPORTE POR EMPLEADO`, 105, 25, { align: "center" });
        doc.setFontSize(12);
        dataBilleteateUser && doc.text(`PERIODO: ${dataBilleteateUser[0].periodo}`, 15, 35);
        const totalVentas = dataBilleteateUser?.reduce((sum, obj) => sum + obj.ventas, 0) || 0;
        const totalBilleteate = dataBilleteateUser?.reduce((sum, obj) => sum + obj.billetéate, 0) || 0;
        const tableYPosition = 40;
        // @ts-ignore
        doc.autoTable({
          startY: tableYPosition,
          head: [["Dependiente", "Ventas", "Billetéate", "Cumplimiento"]],
          body: [
            ...(dataBilleteateUser?.map((obj) =>
              Object.entries(obj)
                .filter(([key, value]) => key !== "key" && key !== "periodo")
                .map(([key, value]) =>
                  key === "ventas" || key === "billetéate" || key === "cumplimiento" ? formateaCantidad(value) : value
                )
            ) || []),
            [
              "",
              formateaCantidad(totalVentas),
              formateaCantidad(totalBilleteate),
              formateaCantidad((totalBilleteate / totalVentas) * 100),
            ],
          ],
          margin: { left: 15, right: 15 },
        });
        // @ts-ignore
        const imageYPosition = doc.previousAutoTable.finalY + 10;
        const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        const imageHeight = 100;

        // Verificar si la imagen cabe en la página; si no, añadir una nueva página
        if (imageYPosition + imageHeight > pageHeight) {
          doc.addPage();
          doc.addImage(imgData, "JPG", 10, 10, 180, imageHeight);
        } else {
          doc.addImage(imgData, "JPG", 10, imageYPosition, 180, imageHeight);
        }
        doc.save(`reporte_dependientes_${form.getFieldsValue().year.format("YYYY")}.pdf`);
      };

      img.onerror = () => {
        swalError({ text: "Ocurrio un error" });
      };
    } catch (error) {
      swalError({ text: "Ocurrio un error" });
    }
  };

  const downloadPDFDependienteDetails = async (
    imgData: string,
    headSelect: PropsBilleteate,
    dataDetailsUser: PropsBilleteate[] | null,
    dataDetailGraph: PropsBilleteate[] | null
  ) => {
    try {
      const doc = new jsPDF();
      const img = new Image();
      img.src = "/imagen/images.jpg";

      img.onload = () => {
        doc.setFontSize(10);
        doc.text("CUMPLIMIENTO BILLETÉATE", 15, 10);
        doc.line(15, 11, 200, 11);
        doc.addImage(img, "JPG", 170, 3, 30, 10);
        doc.setFontSize(18);
        doc.text(`REPORTE BILLETÉATE`, 105, 20, { align: "center" });
        doc.setFontSize(12);
        doc.text(`PERIODO: ${headSelect.periodo}`, 15, 27);
        doc.text(`EMPLEADO: ${headSelect?.nombres}`, 15, 34);
        doc.text(`TOTAL VENTAS: $ ${headSelect?.ventas}`, 15, 41);
        doc.text(`BILLETÉATE: $ ${headSelect?.billetéate}`, 15, 48);
        doc.text(`CUMPLIMIENTO: ${headSelect?.cumplimiento} %`, 15, 55);
        const tableYPosition = 60;
        // @ts-ignore
        doc.autoTable({
          startY: tableYPosition,
          head: [["Farmacia", "Ventas", "Billetéate", "Cumplimiento"]],
          body: dataDetailsUser?.map((obj) =>
            Object.entries(obj)
              .filter(([key, value]) => key !== "key")
              .map(([key, value]) => value)
          ),
        });
        // @ts-ignore
        const tableYPosition2 = doc.previousAutoTable.finalY + 5;
        // @ts-ignore
        doc.autoTable({
          startY: tableYPosition2,
          head: [["Mes", "Ventas", "Billetéate", "Cumplimiento"]],
          body: dataDetailGraph?.map((obj) =>
            Object.entries(obj)
              .filter(([key, value]) => key !== "key")
              .map(([key, value]) => value)
          ),
        });
        // @ts-ignore
        const imageYPosition = doc.previousAutoTable.finalY + 10;
        const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        const imageHeight = 100;

        // Verificar si la imagen cabe en la página; si no, añadir una nueva página
        if (imageYPosition + imageHeight > pageHeight) {
          doc.addPage();
          doc.addImage(imgData, "JPG", 10, 10, 180, imageHeight);
        } else {
          doc.addImage(imgData, "JPG", 10, imageYPosition, 180, imageHeight);
        }
        doc.save(`${headSelect?.nombres}_${headSelect.periodo}.pdf`);
      };
      img.onerror = () => {
        swalError({ text: "Ocurrio un error" });
      };
    } catch (error) {
      swalError({ text: "Ocurrio un error" });
    }
  };

  return {
    handleDownloadPdf,
    downloadPDFBodegaGeneral,
    downloadPDFBodegaDependienteGeneral,
  };
};
