import { RouteObject } from "react-router-dom";
import { MiddleProcessVoucher, ProcesarVoucherParcial } from "pages/contentcajas/procesar-voucher-parcial";
import { loaderRoute, sendAction } from "config/methods";
import { Principal } from "pages/contentprincipal/Principal";
import Conciliaciones from "pages/contentcajas/pagoTramites/conciliaciones";
import Solicitud from "pages/contentcajas/pagoTramites/solicitud";
import Revision from "pages/contentcajas/pagoTramites/revision";

const rutaPadre = "fapifsg-pr/cajas/";

export const rutasCajas: RouteObject[] = [
  {
    path: "/cajas",
    element: <Principal />,
  },
  {
    path: "/cajas/procesar-voucher-parcial",
    element: <ProcesarVoucherParcial />,
    loader: () => loaderRoute(`${rutaPadre}bodegas`),
    children: [
      {
        path: "/cajas/procesar-voucher-parcial/:inicio/:fin/:bodega",
        element: <MiddleProcessVoucher />,
        action: ({ request }) => sendAction(`${rutaPadre}procesar`, request),
        loader: async ({ params }) =>
          loaderRoute(`${rutaPadre}vouchers-parciales/${params.inicio}/${params.fin}/${params.bodega}`),
      },
    ],
  },
  {
    path: "/cajas/pago-tramites",
    element: <Principal />,
  },
  {
    path: "/cajas/pago-tramites/conciliacion",
    element: <Conciliaciones />,
  },
  {
    path: "/cajas/pago-tramites/solicitud",
    element: <Solicitud />,
  },
  {
    path: "/cajas/pago-tramites/revision-solicitudes",
    element: <Revision />,
  },
];
