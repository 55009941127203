import { Col, Row, Tag, Typography } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import { url } from 'config/constants'
import { optionsGet, optionsPost } from 'config/methods'
import React, { useEffect, useState,useCallback } from 'react'
import { DataDetSolicitud } from './useSolicitudesFundas'
import moment from "moment"
import { useExport } from "hooks/otros/useExport"



const { Title } = Typography


export interface DataTypeReportFundas {
    key: React.Key
    cod_farmacia: string
    usuario_solicita: string
    fecha_solicitud: Date
    usuario_revisa: string
    fecha_revision: Date
    usuario_autoriza: string
    fecha_autoriza: Date
    usuario_rechaza: string
    fecha_rechaza: Date
    estado:string
    tipo_solicitud: string
    observacion: string
    total_paquete: number
    detalle: string[]
  }


export function useReportFundas() {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [data, setdata]=useState<DataTypeReportFundas[]>([])
    const [farmacias, setfarmacias]=useState([{  value: "", label: "Todas", }])
    const [valueFarmacia, setvalueFarmacia]=useState("")
    const [valueEstado, setvalueEstado]=useState("")
    const [fechasSeleccionadas, setFechasSeleccionadas] = useState<[moment.Moment, moment.Moment]>([
      moment(moment().subtract(1, "month"), 'YYYY/MM/DD'), moment( moment().endOf("day"), 'YYYY/MM/DD')
    ])
    const [DataPdf, setDataPdf] = useState<DataTypeReportFundas[]>([])
    const [openModal, setopenModal]=useState(false)
    const { exportToExcel } = useExport()

    const disabledDate = (current: any) => {
      // Si la fecha actual es mayor a la fecha seleccionada, deshabilita la fecha
      return current && current > moment().endOf("day")
    }

    const columnsreport: ColumnsType<DataTypeReportFundas> = [
        Table.SELECTION_COLUMN,
        Table.EXPAND_COLUMN,
        {
          title: (
            <>
              <Row>
                <Col md={24}>
                  <Title style={{ fontSize: "12px" }}>Farmacia</Title>
                </Col>
              </Row>{" "}
            </>
          ),
          dataIndex: "cod_farmacia",
          width:'380px',
          className:'fontsize'
          
        },
        {
          title: (
            <>
              <Row>
                <Col md={24}>
                  <Title style={{ fontSize: "12px" }}>Solicitante</Title>
                </Col>
              </Row>{" "}
            </>
          ),
          dataIndex: "usuario_solicita",
          width:'200px',
          className:'fontsize'
        },
        {
          title: (
            <>
              <Row>
                <Col md={24}>
                  <Title style={{ fontSize: "12px" }}>Tipo</Title>
                </Col>
              </Row>{" "}
            </>
          ),
          dataIndex: "tipo_solicitud",
          //width:'200px',
          className:'fontsize'
        },

        {
          title: (
            <>
              <Row>
                <Col md={24} style={{textAlign:'center'}}>
                  <Title style={{ fontSize: "12px" }}>Fecha Solicitud</Title>
                </Col>
              </Row>{" "}
            </>
          ),
          dataIndex: "fecha_solicitud",
          className:'fontsize'
        },
        {
          title: (
            <>
              <Row>
                <Col md={24}>
                  <Title style={{ fontSize: "12px", textAlign:'center' }}>Aprobado</Title>
                </Col>
              </Row>{" "}
            </>
          ),
          dataIndex: "usuario_revisa",
          //align:'center',
          className:'fontsize',
          width:'200px'
        },
        {
          title: (
            <>
              <Row>
                <Col md={24}>
                  <Title style={{ fontSize: "12px" }}>Fecha Aprobación</Title>
                </Col>
              </Row>{" "}
            </>
          ),
          dataIndex: "fecha_revision",
          width: "129px",
          align:'center',
          className:'fontsize'
        },
        {
          title: (
            <>
              <Row>
                <Col md={24}>
                  <Title style={{ fontSize: "12px" }}>Autorizado</Title>
                </Col>
              </Row>{" "}
            </>
          ),
          dataIndex: "usuario_autoriza",
          align:'center',
          className:'fontsize',
          width:'200px'
        },
        {
          title: (
            <>
              <Row>
                <Col md={24}>
                  <Title style={{ fontSize: "12px" }}>Fecha Autorización</Title>
                </Col>
              </Row>{" "}
            </>
          ),
          dataIndex: "fecha_autoriza",
          width: "129px",
          align:'center',
          className:'fontsize'
        },
        {
          title: (
            <>
              <Row>
                <Col md={24}>
                  <Title style={{ fontSize: "12px" }}>Total de Paquetes</Title>
                </Col>
              </Row>{" "}
            </>
          ),
          dataIndex: "total_paquete",
          width: "129px",
          align:'center',
          render:(total_paquete, key) => {
            return(
              <>{total_paquete&&<Tag color={"gold"} style={{fontSize:'10px'}}>{total_paquete}</Tag>}
               
            </>)
          }
        },

        {
            title: (
              <>
                <Row>
                  <Col md={24}>
                    <Title style={{ fontSize: "14px" }}>Estado</Title>
                  </Col>
                </Row>{" "}
              </>
            ),
            dataIndex: "estado",
            width: "80px",
            align:'center',
            render:(estado, key) => {
              return(
                <>{estado==='PENDIENTE'&&<Tag color={"blue"} style={{fontSize:'10px'}}>{estado}</Tag>}
                 { estado==='APROBADO'&&<Tag color={"green"} style={{fontSize:'10px'}}>{estado}</Tag>}
                 { estado==='RECHAZADO'&&<Tag color={"red"} style={{fontSize:'10px'}}>{estado}</Tag>}
              </>)
            }
          },
      ]

      const columnsdetsolicitud: ColumnsType<DataDetSolicitud> = [
        {
          title: (
            <>
              <Row>
                <Col md={24}>
                  <Title style={{ fontSize: "10px" }}>N</Title>
                </Col>
              </Row>{" "}
            </>
          ),
          dataIndex: "key",
          align:"center",
          className:'fontsize'
        },
        {
          title: (
            <>
              <Row justify={'center'}>
                <Col md={24} style={{textAlign:'center'}}>
                  <Title style={{ fontSize: "10px" }}>Descripción</Title>
                </Col>
              </Row>{" "}
            </>
          ),
          dataIndex: "nombre_funda",
          className:'fontsize'
        },
        {
          title: (
            <>
              <Row>
                <Col md={24}>
                  <Title style={{ fontSize: "10px" }}>Cantidad en Paquetes</Title>
                </Col>
              </Row>{" "}
            </>
          ),
          dataIndex: "cantidad",
          align:'center',
          className:'fontsize'
        },
      ]
      
      const getfarmacias=async()=>{
        const res = await fetch(`${url}fapifsg-pr/bodegas/Fundas/getfarmacias`, optionsGet()).then((data) => data.json())
        console.log(res)
        let newget =[{value:"", label: "Todas"}]
        if(res.length>0){
        res.forEach((item: any)=>{  
        let add={
          value: item.Cod_Farmacia,
          label: item.Descripcion_Sri,
        }
        newget= [...newget, add];
        }
        )}
        setfarmacias(newget)
      }


      const getreportfundas=useCallback(async () => {
        let fechainicio, fechafin
        if (moment.isMoment(fechasSeleccionadas[0])||moment.isMoment(fechasSeleccionadas[1])) {
          fechainicio=fechasSeleccionadas[0].format("YYYY/MM/DD")
          fechafin=fechasSeleccionadas[1].format("YYYY/MM/DD")
        }else{
          fechainicio=fechasSeleccionadas[0]
          fechafin=fechasSeleccionadas[1]
        }

        const res = await fetch(`${url}fapifsg-pr/bodegas/Fundas/reporte-fundas`, optionsPost({farmacia: valueFarmacia, estado: valueEstado, fecha_inicio:fechainicio, fecha_fin:fechafin})).then((data) => data.json())
        //console.log(res)
        let newdata:any=[]
        if (res) {
          newdata = Array.from(res).map((item: any, key: any) => ({
            key: item.Cod_Solicitud,
            cod_farmacia: item.Descripcion_Sri,//item.Cod_Farmacia,
            usuario_solicita: item.usuario_solicita,
            fecha_solicitud: item.Fecha,
            usuario_revisa: item.usuario_revisa,
            fecha_revision: item.Fecha_Revision,
            usuario_autoriza: item.usuario_autoriza,
            fecha_autoriza: item.Fecha_Autoriza,
            usuario_rechaza: item.usuario_rechaza,
            fecha_rechaza: item.Fecha_Rechaza,
            tipo_solicitud: item.Tipo_Solicitud,
            estado:item.Estado,
            total_paquete: item.total_paquetes,
            observacion: item.Observacion,
            detalle: 
              item.detalle !== null
                ? JSON.parse(item.detalle).map((item1: any, key1: any) => ({
                    key: (1+key1).toString(),
                    cod_solicitud: item.Cod_Solicitud,
                    nombre_funda: item1.Descripcion,
                    cantidad: item1.Cantidad,
                  }))
                : [],
          }))
        }
        setdata(newdata)
      }, [fechasSeleccionadas,valueFarmacia, valueEstado])
      
      useEffect(() => {
        getreportfundas()
        getfarmacias()
      }, [getreportfundas])

      
  const handleDateChange = (dates: any, dateStrings: any) => {
    setFechasSeleccionadas(dateStrings)
  }

  const handleChangeFarmacia = (value: string, label: any) => {
    setvalueFarmacia(value)
  }

  const handleChangeEstado = (value: string, label: any) => {
    setvalueEstado(value)
  }

  const btn_Buscar=async()=>{
    getreportfundas()
  }


  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    //console.log("selectedRowKeys changed: ", newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    getCheckboxProps: (item:any) => ({ disabled: item.disabled }),
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const btn_PDF=()=>{
    if(selectedRowKeys.length>0){
      const datos=data.filter((item:DataTypeReportFundas)=>selectedRowKeys.includes(item.key))
      setDataPdf(datos)      
    }else{
      setDataPdf(data)
    }
    setopenModal(true)
  }

  const handleDownload = () => {
    const dataExport = data.map((item)=>{
      let addData: any;
      if(item.detalle!==null){
        item.detalle.forEach((item:any)=>{
          addData=({ ...addData,["F."+item.nombre_funda]: item.cantidad});
        })
      }

        let valorComoCadena = item.cod_farmacia.toString();
        const cerosFaltantes = 3 - valorComoCadena.length;
        if (cerosFaltantes > 0) {
          valorComoCadena = '0'.repeat(cerosFaltantes) + valorComoCadena;
        }

        return{
          Farmacia: item.cod_farmacia,
          Solicitante: item.usuario_solicita,
          Tipo: item.tipo_solicitud,
          "Fecha Solicitud": item.fecha_solicitud,
          Aprobado: item.usuario_revisa,
          "Fecha Aprobado": item.fecha_revision,
          "Autorización": item.usuario_autoriza,
          "Fecha Autorización": item.fecha_autoriza,
          "Rechazado": item.usuario_rechaza,
          "Fecha de Rechazo": item.fecha_rechaza,
          Estado: item.estado,
          "Total Paquetes": item.total_paquete,
          ...addData,
        }
    })
    exportToExcel(dataExport, `Reporte_Fundas_${moment().format("DDMMYYYY")}`)
  }

  return {
      columnsreport,
      setdata,
      data,
      columnsdetsolicitud,
      disabledDate,
      handleDateChange,
      fechasSeleccionadas,
      farmacias,
      handleChangeFarmacia,
      handleChangeEstado,
      btn_Buscar,
      handleDownload,
      openModal, setopenModal,
      DataPdf,
      rowSelection,
      btn_PDF
  }
}
