import { pdf } from "@react-pdf/renderer"
import { url } from "config/constants"
import { useCallback } from "react"

export function useSendPDF() {
  const sendPDFReport = useCallback(
    async (report: JSX.Element, ruta: string, filename: `${string}.${string}`, correo: string, extraData?: object) => {
      const formData = new FormData()
      const blob = await pdf(report).toBlob()
      formData.append("file", blob, filename)
      formData.append("correo", correo)
      if (extraData) {
        Object.entries(extraData).forEach(([key, value]) => {
          formData.append(key, value)
        })
      }

      const res = await fetch(`${url}enviar-correo/${ruta}`, {
        method: "POST",
        headers: {
          "access-token": localStorage.getItem("token") ?? "",
        },
        body: formData,
      })

      return res.ok
    },
    []
  )

  return { sendPDFReport }
}
