import { Card, Carousel, Col, Modal, Row, Select, Table } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Dragger from "antd/lib/upload/Dragger";

import styles from "pages/contentinventario/inventariosCalendario.module.css";
import { InboxOutlined } from "@ant-design/icons";
import { useMantenimientoAprobados } from "hooks/mantenimientos/useMantenimientosAprobados";
import { useVT } from "virtualizedtableforantd4";
import { useState } from "react";
//import "./VisorMartillo.css";
const MantenimientosAprobados = () => {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);

  const {
    columns,
    columnsTable,
    filteredData,
    tableContainerRef,
    rowSelection,
    isModalOpen,
    isModalSubir,
    selectedImages,
    setIsModalOpen,
    handleChange,
    handleCancel,
    checkFile,
    onChangeFile,
    handleRemove,
    onPreviewImage,
    previewOpen,
    previewTitle,
    previewImage,
    setPreviewOpen,
    files,
    handleok,
  } = useMantenimientoAprobados();
  const [textAreaValue, setTextAreaValue] = useState("");

  const handleChangeText = (e: any) => {
    setTextAreaValue(e.target.value);
  };
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 16 }}>
          <Select
            defaultValue="0"
            style={{ width: 130, marginRight: "10px", marginTop: "0px" }}
            onChange={handleChange}
            options={[
              {
                value: "0",
                label: "Todos",
              },
              {
                value: "1",
                label: "Urgentes",
              },
              {
                value: "2",
                label: "No urgentes",
              },
            ]}
          />
        </div>
        <Card style={{ margin: "5px" }}>
          <Row gutter={16}>
            <Col md={24}>
              <div ref={tableContainerRef} style={{ maxHeight: "435px" }}>
                {
                  <Table
                    ref={tableContainerRef}
                    components={vt}
                    pagination={false}
                    columns={columns}
                    scroll={{ x: 900, y: 450 }}
                    size="small"
                    dataSource={filteredData ? filteredData : []}
                    bordered
                    expandable={{
                      expandedRowRender: (record) => {
                        return (
                          <Table
                            style={{ width: "100%", margin: "10px" }}
                            columns={columnsTable}
                            dataSource={record.DetallesJSON}
                            pagination={false}
                            rowSelection={rowSelection(record.key, record.Descripcion)}
                          />
                        );
                      },
                    }}
                  />
                }
              </div>
            </Col>
          </Row>
        </Card>
        <Modal
          title="EVIDENCIAS"
          centered
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={null} // Quita el pie de página del modal si no necesitas botones allí
          // Ajusta el tamaño según necesites
          bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }} // Limita la altura máxima y agrega desbordamiento si es necesario
        >
          <Carousel autoplay dotPosition="bottom" autoplaySpeed={2000}>
            {selectedImages.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Imagen ${index + 1}`} style={{ width: "100%", height: "500px" }} />{" "}
                {/* Ajusta el estilo según necesites */}
              </div>
            ))}
          </Carousel>
        </Modal>
      </Col>
      <Modal title="Completar mantenimiento" open={isModalSubir} width={600} onCancel={handleCancel} onOk={()=>handleok(textAreaValue)}>
        <Col xs={24} xl={30}>
          <TextArea
            rows={4}
            placeholder="Ingrese una observación"
            className={styles.textareaSpacing}
            value={textAreaValue}
            onChange={handleChangeText}
          />
          <Dragger
            //disabled={disabledDragger}
            beforeUpload={checkFile}
            onChange={onChangeFile}
            onPreview={onPreviewImage}
            onRemove={handleRemove}
            accept="image/*"
            fileList={files}
            multiple={true}
            listType="picture-card"
            // showUploadList={{
            //   removeIcon: disableRemoveIcon,
            // }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click aquí o suelte las imágenes en esta área</p>
          </Dragger>
        </Col>
      </Modal>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Row>
  );
};

export default MantenimientosAprobados;
