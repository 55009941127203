import { useState } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

export function useArchivos() {
  const [hidden, setHidden] = useState<boolean>(false);
  const [hidden2, setHidden2] = useState<boolean>(false);
  const [hidden3, setHidden3] = useState<boolean>(false);

  const [isReglamentoInternoChecked, setIsReglamentoInternoChecked] = useState(false);
  const [isReglamentoHigieneChecked, setIsReglamentoHigieneChecked] = useState(false);

  const handleDownloadFile = (llave: string, name: string) => {
    fetch(`https://infofsg.com/allku/archivos/${llave}`).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = name;
        alink.click();
      });
    });
  };

  const handleReglamentoInternoClick = () => {
    setIsReglamentoInternoChecked(true);
    handleDownloadFile("otros_2024_04_09_313c797e-c32a-406a-b620-d74914610bf6.pdf", "reglamentoInterno.pdf");
  };

  const handleReglamentoHigieneClick = () => {
    setIsReglamentoHigieneChecked(true);
    handleDownloadFile("otros_2023_12_28_26615b91-1016-49c4-95c8-3d8a623a6ad5.pdf", "reglamentoHigiene.pdf");
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleCheckboxChange = (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  };

  const handleCheckboxChange2 = (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      setHidden2(true);
    } else {
      setHidden2(false);
    }
  };

  const handleCheckboxChange3 = (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      setHidden3(true);
    } else {
      setHidden3(false);
    }
  };

  return {
    hidden,
    hidden2,
    hidden3,
    isReglamentoInternoChecked,
    isReglamentoHigieneChecked,
    handleReglamentoInternoClick,
    handleReglamentoHigieneClick,
    normFile,
    handleCheckboxChange,
    handleCheckboxChange2,
    handleCheckboxChange3,
  };
}
