import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";

import { useLocalStorage } from "hooks/otros/useLocalStorage";

import { CheckOutlined } from "@ant-design/icons";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { Button, Form, Input, TableColumnsType, Tooltip } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { url } from "config/constants";
import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import Swal from "sweetalert2";
import { swalError, swalSuccess, swalWarning } from "config/methods";
interface PropsDescripcionProductos {
  key: React.Key;
  Descripcion: string;
  cajas: number;
  fracciones: number;
  codInventarioDet: number;
  division: number;
}

type InventarioReporte = {
  farmacia: string;
  fechaInicio: string;
  fechaFin: string;
  productos: PropsDescripcionProductos[];
  key: React.Key;
};

const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Control caducados" }];

const openKeys = [breadcrumbs[0].Label];
export function useControlCaducados() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);
  const [mes, setMes] = useState<Moment>(moment());
  const [datosInicial, setDatos] = useState<InventarioReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<InventarioReporte[]>([]);
  const [productosEditados, setProductosEditados] = useState<Array<React.Key>>([]); // Esto le dice a TypeScript que productosEditados es un array de React.Key.

  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<InventarioReporte>();
 
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();

  const [openModalPdf, setOpenModalPdf] = useState(false);
  const getProductos = useCallback(async () => {
    try {
      const res = await fetch(
        url + "apifsg-pr/inventarios/obtener_caducados_pendientes",
        optionsPost({ mes: mes.format("YYYYMM") })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        const respuestas: InventarioReporte[] = respuesta.data;
        setDatos(
          respuestas.map(({ key, farmacia, fechaInicio, fechaFin, productos }) => ({
            key,
            farmacia,
            fechaInicio,
            fechaFin,
            productos,
          }))
        );
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsPost, isError, mes]);

  useEffect(() => {
    getProductos();
  }, [getProductos]);
  const actualizarProductoCaducado = async (datos: PropsDescripcionProductos, descripcion: String) => {
    try {
      const result = await fetch(
        `${url}apifsg-pr/inventarios/actualizar_producto_caducado`,
        optionsPost({
          datos,
          descripcion,
        })
      );
      if (isError(result)) throw new Error();
      let text = await result.json();

      text = text["msg"];
      setProductosEditados([]);
      swalSuccess({ text });
      getProductos();
    } catch (e) {
      swalError({ text: "No se pudo actualizar el producto" });
    }
  };

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datosInicial); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datosInicial.filter((item) => item.farmacia.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datosInicial]);

  const columns: TableColumnsType<InventarioReporte> = [
    {
      title: "Farmacia",
      dataIndex: "farmacia",
      key: "farmacia",
      ...getColumnSearchPropsNewVisorReporte("farmacia"),
    },
    {
      title: "Fecha inicio",
      dataIndex: "fechaInicio",
      key: "fechaInicio",
      sorter: (a, b) => moment(a.fechaInicio).unix() - moment(b.fechaInicio).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Fecha fin",
      dataIndex: "fechaFin",
      key: "fechaFin",
      sorter: (a, b) => moment(a.fechaFin).unix() - moment(b.fechaFin).unix(),
      sortDirections: ["descend", "ascend"],
    },
  ];
  const columsDatatableDetalleProductos: TableColumnsType<PropsDescripcionProductos> = [
    {
      title: "Producto",
      dataIndex: "Descripcion",
      key: "Descripcion",
      width: "510px",
    },
    {
      title: "Cajas",
      dataIndex: "cajas",
      key: "cajas",
      render: (cantidad, key) => {
        return (
          <Input
            id={"key" + key.key}
            name="cajas"
            style={{ textAlign: "right", width: "70px" }}
            maxLength={2}
            onChange={(e) => handleInputChange(e, key)}
            type="number"
            min={0}
            value={cantidad}
          />
        );
      },
    },
    {
      title: "Fracciones",
      dataIndex: "fracciones",
      key: "fracciones",
      render: (cantidad, key) => {
        return (
          <Input
            disabled={key.division <= 1}
            name="fracciones"
            id={"key" + key.key}
            style={{ textAlign: "right", width: "70px" }}
            maxLength={2}
            min={0}
            type="number"
            onChange={(e) => handleInputChange(e, key)}
            value={cantidad}
          />
        );
      },
    },
    {
      title: "Actualizar",
      render: (record: PropsDescripcionProductos) => {
        if (productosEditados.includes(record.key)) {
          return (
            <Tooltip title="Actualizar">
              <Button
                type={"primary"}
                size="small"
                style={{ marginRight: "2px", marginTop: "1px" }}
                onClick={async () => {
                  const { value: motivo } = await Swal.fire({
                    title: "Actualizar caducado",
                    input: "textarea",
                    inputLabel: "¿Está seguro de actualizar este producto?",
                    inputPlaceholder: "Ingrese un motivo",
                    showCancelButton: true,
                    confirmButtonColor: "#8b0505",
                  });
                  if (motivo === null || motivo === undefined) {
                  } else if (!motivo) {
                    swalWarning({ text: "Ingrese un motivo, no puede ir vacío" });
                  } else {
                    actualizarProductoCaducado(record, motivo);
                  }
                }}
              >
                <CheckOutlined />
              </Button>
            </Tooltip>
          );
        }
        return null;
      },
    },
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, key: PropsDescripcionProductos) => {
    const newValue = Number(e.target.value);
    const field = e.target.name;
    const newData = datosInicial.map((inventario) => {
      if (inventario.productos && key && inventario.productos.some((producto) => producto.key === key.key)) {
        return {
          ...inventario,
          productos: inventario.productos.map((producto) => {
            if (producto.key === key.key) {
              return {
                ...producto,
                [field]: newValue,
              };
            }
            return producto;
          }),
        };
      }
      return inventario;
    });

    setDatos(newData);
    if (!productosEditados.includes(key.key)) {
      setProductosEditados([...productosEditados, key.key]);
    }
  };

  return {
    columns,
    datosInicial,
    openModalPdf,
    setOpenModalPdf,
    mes,
    setMes,
    filteredData,
    columsDatatableDetalleProductos,
  };
}
