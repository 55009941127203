import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { url} from "config/constants"
import { optionsGet, optionsPost, swalSuccess, swalWarning } from "config/methods"



import {
  Facturas,
  FormFacturas,
  FormNotaVentas,
  FormRecibos,
  NotaVentas,
  Recibos,
  useModalFormCrearReembolso,
} from "./useModalFormCrearReembolso";
import { useButtonRightListCrearReembolso } from "./useButtonRightListCrearReembolso";
import { useFetchInFunction } from "hooks/otros/useFetch";
import { optionsPOST, url_interna } from "config/constants";
import Swal from "sweetalert2";

import moment from "moment";
import "moment/locale/es";
import { LayoutContext } from "context/LayoutContext";
import { message } from "antd";




export function useCrearReembolsoOtros() {

  const {
    modalProps,
    setModalProps,
    initialValues,
    inputsState,
    forms,
    resetFields,
    cargarCiudades,
  } = useModalFormCrearReembolso();

  const { lists, setLists, contentDocuments, actionsList } = useButtonRightListCrearReembolso();

  const [setListFacturas, setListNotaVentas, setListRecibos] = setLists;

  const [JustificacionValue, setJustificacionValue] = useState("");
  const [dataSelect, setdataSelect]=useState([{label:"Seleccione una persona", value:""}])
  const [valueUsuario, setvalueUsuario]= useState("")
  const [FechaSelecionada, setfechaSeleccionada]=useState(
   moment().endOf("day")
);
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const disabledDate = (current: any) => {
    // Si la fecha actual es mayor a la fecha seleccionada, deshabilita la fecha
    return current && current > moment().endOf("day")
  }

  const handleDateChange = (dates: any, date:any) => {
    setfechaSeleccionada(date)
  }



  const getusuarios = async () => {
    const res = await fetch(`${url}fapifsg-pr/reembolsos/get-usuarios`, optionsGet()).then((data) => data.json())
    //console.log('res', res)
    //console.log(!res)
    let newget =[{value:"", label: "Seleccione una persona"}]
    res.forEach((item: any)=>{  
    let add={
      value: item.Cod_Usuario,
      label: item.Nombres,
    }
    newget= [...newget, add];
    }
    )
    setdataSelect(newget)
}


const handleChangeUsuario = (value: string) => {
  //console.log("value hsndle", value)
  setvalueUsuario(value);
}


  useEffect(() => {
    // if(window.screen.width <= 992) return
    getusuarios()
    setOpenKeys(["Reembolsos"]);
    setSelectedKeys(["Reembolsos/Crear Reembolsos Otros"]);
  }, [setOpenKeys, setSelectedKeys]);

  const handleOkModal = [
    (values: FormFacturas | FormNotaVentas | FormRecibos) => {
      resetFields[0]();
      (setListFacturas as React.Dispatch<React.SetStateAction<Facturas[]>>)((state) => {
        return [
          ...state,
          {
            numeroautorizacion: (values as FormFacturas).numeroautorizacion,
            razonsocial: (values as FormFacturas).razonsocial,
            ruc: (values as FormFacturas).ruc,
            documento: (values as FormFacturas).documento,
            base12: (values as FormFacturas).base12,
            iva: (values as FormFacturas).iva,
            base0: (values as FormFacturas).base0,
            servicio10: (values as FormFacturas).servicio10,
            tipoPago: (values as FormFacturas).tipoPago,
            tipoGasto: (values as FormFacturas).tipoGasto ?? 0,
            fecha: (values as FormFacturas).fecha.format("DD/MM/YYYY"),
            concepto: (values as FormFacturas).concepto,
            pais: (values as FormFacturas).pais,
            provincia: (values as FormFacturas).provincia,
            ciudad: (values as FormFacturas).ciudad ?? null,
            valor: (values as FormFacturas).valor,
          },
        ];
      });

      if (modalProps[0].okText === "Agregar")
        setModalProps[0]((state) => ({ ...state, open: false }));
      else setModalProps[0]({ title: "Agregar Factura", open: false, okText: "Agregar" });
    },
    (values: FormFacturas | FormNotaVentas | FormRecibos) => {
      resetFields[1]();
      (setListNotaVentas as React.Dispatch<React.SetStateAction<NotaVentas[]>>)((state) => {
        return [
          ...state,
          {
            numeroautorizacion: (values as FormNotaVentas).numeroautorizacion,
            razonsocial: (values as FormNotaVentas).razonsocial,
            ruc: (values as FormNotaVentas).ruc,
            documento: (values as FormNotaVentas).documento,
            tipoPago: (values as FormNotaVentas).tipoPago,
            tipoGasto: (values as FormNotaVentas).tipoGasto ?? 0,
            fecha: (values as FormNotaVentas).fecha.format("DD/MM/YYYY"),
            concepto: (values as FormNotaVentas).concepto,
            pais: (values as FormNotaVentas).pais,
            provincia: (values as FormNotaVentas).provincia,
            ciudad: (values as FormNotaVentas).ciudad ?? null,
            valor: (values as FormNotaVentas).valor,
          },
        ];
      });

      if (modalProps[1].okText === "Agregar")
        setModalProps[1]((state) => ({ ...state, open: false }));
      else setModalProps[1]({ title: "Agregar Nota de venta", open: false, okText: "Agregar" });
    },
    (values: FormFacturas | FormNotaVentas | FormRecibos) => {
      (setListRecibos as React.Dispatch<React.SetStateAction<Recibos[]>>)((state) => {
        return [
          ...state,
          {
            ruc: (values as FormRecibos).ruc,
            razonsocial: (values as FormRecibos).razonsocial,
            documento: (values as FormRecibos).documento,
            fecha: (values as FormRecibos).fecha.format("DD/MM/YYYY"),
            concepto: (values as FormRecibos).concepto,
            pais: (values as FormRecibos).pais,
            valor: (values as FormRecibos).valor,
            provincia: (values as FormRecibos).provincia,
            ciudad: (values as FormNotaVentas).ciudad ?? null,
            tipoPago: (values as FormNotaVentas).tipoPago,
            tipoGasto: (values as FormNotaVentas).tipoGasto ?? 0,
          },
        ];
      });

      if (modalProps[2].okText === "Agregar")
        setModalProps[2]((state) => ({ ...state, open: false }));
      else setModalProps[2]({ title: "Agregar Ticket", open: false, okText: "Agregar" });
    },
  ];

  const okModals = useRef(handleOkModal);

  const resetOkModal = (index: number) => {
    okModals.current[index] = handleOkModal[index];
  };

  const handleOnClickActionList = [
    {
      Editar: (index: number) => {
        forms[0].setFieldsValue({
          numeroautorizacion: (lists[0] as Facturas[])[index].numeroautorizacion,
          razonsocial: (lists[0] as Facturas[])[index].razonsocial,
          ruc: (lists[0] as Facturas[])[index].ruc,
          documento: (lists[0] as Facturas[])[index].documento,
          base12: (lists[0] as Facturas[])[index].base12,
          iva: (lists[0] as Facturas[])[index].iva,
          base0: (lists[0] as Facturas[])[index].base0,
          servicio10: (lists[0] as Facturas[])[index].servicio10,
          tipoPago: (lists[0] as Facturas[])[index].tipoPago,
          tipoGasto: (lists[0] as Facturas[])[index].tipoGasto,
          fecha: moment((lists[0] as Facturas[])[index].fecha.trim(), "DD MM YYYY"),
          concepto: (lists[0] as Facturas[])[index].concepto,
          pais: (lists[0] as Facturas[])[index].pais,
          provincia: (lists[0] as any[])[index].provincia,
          ciudad: (lists[0] as Facturas[])[index].ciudad ?? undefined,
          valor: (lists[0] as Facturas[])[index].valor,
        });

        (lists[0] as Facturas[])[index].ciudad &&
          cargarCiudades[0](
            JSON.stringify({ Cod_Provincia: (lists[0] as any[])[index].provincia }),
            { "api-token": "apitoken" }
          );

        setModalProps[0]({ title: "Modificar Factura", open: true, okText: "Modificar" });
        okModals.current[0] = (values: FormFacturas | FormNotaVentas | FormRecibos) => {
          resetFields[0]();
          (setListFacturas as React.Dispatch<React.SetStateAction<Facturas[]>>)((state) => {
            const newState = [...state];
            newState[index] = {
              numeroautorizacion: (values as FormFacturas).numeroautorizacion,
              razonsocial: (values as FormFacturas).razonsocial,
              ruc: (values as FormFacturas).ruc,
              documento: (values as FormFacturas).documento,
              base12: (values as FormFacturas).base12,
              iva: (values as FormFacturas).iva,
              base0: (values as FormFacturas).base0,
              servicio10: (values as FormFacturas).servicio10,
              tipoPago: (values as FormFacturas).tipoPago,
              tipoGasto: (values as FormFacturas).tipoGasto ?? 0,
              fecha: (values as FormFacturas).fecha.format("DD/MM/YYYY"),
              concepto: (values as FormFacturas).concepto,
              pais: (values as FormFacturas).pais,
              provincia: (values as FormFacturas).provincia,
              ciudad: (values as FormFacturas).ciudad ?? null,
              valor: (values as FormFacturas).valor,
            };
            return [...newState];
          });
          okModals.current[0] = handleOkModal[0];
          setModalProps[0]({ title: "Agregar Factura", open: false, okText: "Agregar" });
        };
      },
      Eliminar: (index: number) =>
        (setListFacturas as React.Dispatch<React.SetStateAction<Facturas[]>>)((state) => {
          const newState = [...state];
          newState.splice(index, 1);
          return newState;
        }),
    },
    {
      Editar: (index: number) => {
        forms[1].setFieldsValue({
          numeroautorizacion: (lists[1] as NotaVentas[])[index].numeroautorizacion,
          razonsocial: (lists[1] as NotaVentas[])[index].razonsocial,
          ruc: (lists[1] as NotaVentas[])[index].ruc,
          documento: (lists[1] as NotaVentas[])[index].documento,
          tipoPago: (lists[1] as NotaVentas[])[index].tipoPago,
          tipoGasto: (lists[1] as NotaVentas[])[index].tipoGasto,
          fecha: moment((lists[1] as NotaVentas[])[index].fecha.trim(), "DD MM YYYY"),
          concepto: (lists[1] as NotaVentas[])[index].concepto,
          pais: (lists[1] as NotaVentas[])[index].pais,
          provincia: (lists[1] as any[])[index].provincia,
          ciudad: (lists[1] as NotaVentas[])[index].ciudad ?? undefined,
          valor: (lists[1] as NotaVentas[])[index].valor,
        });
        (lists[1] as NotaVentas[])[index].ciudad &&
          cargarCiudades[1](
            JSON.stringify({ Cod_Provincia: (lists[1] as NotaVentas[])[index].provincia }),
            { "api-token": "apitoken" }
          );

        setModalProps[1]({ title: "Modificar Nota de Venta", open: true, okText: "Modificar" });
        okModals.current[1] = (values: FormFacturas | FormNotaVentas | FormRecibos) => {
          resetFields[1]();
          (setListNotaVentas as React.Dispatch<React.SetStateAction<NotaVentas[]>>)((state) => {
            const newState = [...state];
            newState[index] = {
              numeroautorizacion: (values as FormNotaVentas).numeroautorizacion,
              razonsocial: (values as FormNotaVentas).razonsocial,
              ruc: (values as FormNotaVentas).ruc,
              documento: (values as FormNotaVentas).documento,
              tipoPago: (values as FormNotaVentas).tipoPago,
              tipoGasto: (values as FormNotaVentas).tipoGasto ?? 0,
              fecha: (values as FormNotaVentas).fecha.format("DD/MM/YYYY"),
              concepto: (values as FormNotaVentas).concepto,
              pais: (values as FormNotaVentas).pais,
              provincia: (values as FormNotaVentas).provincia,
              ciudad: (values as FormNotaVentas).ciudad ?? null,
              valor: (values as FormNotaVentas).valor,
            };
            return [...newState];
          });
          okModals.current[1] = handleOkModal[1];
          setModalProps[1]({ title: "Agregar Nota de Venta", open: false, okText: "Agregar" });
        };
      },
      Eliminar: (index: number) =>
        (setListNotaVentas as React.Dispatch<React.SetStateAction<NotaVentas[]>>)((state) => {
          const newState = [...state];
          newState.splice(index, 1);
          return newState;
        }),
    },
    {
      Editar: (index: number) => {
        forms[2].setFieldsValue({
          ruc: (lists[2] as Recibos[])[index].ruc,
          razonsocial: (lists[2] as Recibos[])[index].razonsocial,
          documento: (lists[2] as Recibos[])[index].documento,
          fecha: moment((lists[2] as Recibos[])[index].fecha.trim(), "DD MM YYYY"),
          concepto: (lists[2] as Recibos[])[index].concepto,
          pais: (lists[2] as Recibos[])[index].pais,
          valor: (lists[2] as Recibos[])[index].valor,
          tipoPago: (lists[2] as Recibos[])[index].tipoPago,
          tipoGasto: (lists[2] as Recibos[])[index].tipoGasto,
          provincia: (lists[2] as Recibos[])[index].provincia,
          ciudad: (lists[2] as Recibos[])[index].ciudad,
        } as FormRecibos);

        (lists[2] as Recibos[])[index].ciudad &&
          cargarCiudades[2](
            JSON.stringify({ Cod_Provincia: (lists[2] as Recibos[])[index].provincia }),
            { "api-token": "apitoken" }
          );

        setModalProps[2]({ title: "Modificar Ticket", open: true, okText: "Modificar" });
        okModals.current[2] = (values: FormFacturas | FormNotaVentas | FormRecibos) => {
          (setListRecibos as React.Dispatch<React.SetStateAction<Recibos[]>>)((state) => {
            const newState = [...state];
            newState[index] = {
              ruc: (values as FormRecibos).ruc,
              razonsocial: (values as FormRecibos).razonsocial,
              documento: (values as FormRecibos).documento,
              fecha: (values as FormRecibos).fecha.format("DD/MM/YYYY"),
              concepto: (values as FormRecibos).concepto,
              pais: (values as FormRecibos).pais,
              valor: (values as FormRecibos).valor,
              tipoPago: (values as FormRecibos).tipoPago,
              tipoGasto: (values as FormRecibos).tipoGasto ?? 0,
              provincia: (values as FormRecibos).provincia,
              ciudad: (values as FormRecibos).ciudad ?? null,
            };
            return [...newState];
          });
          okModals.current[2] = handleOkModal[2];
          setModalProps[2]({ title: "Agregar Ticket", open: false, okText: "Agregar" });
        };
      },
      Eliminar: (index: number) =>
        (setListRecibos as React.Dispatch<React.SetStateAction<Recibos[]>>)((state) => {
          const newState = [...state];
          newState.splice(index, 1);
          return newState;
        }),
    },
  ];

 

  const btn_Guardar=async()=>{
   //console.log('hjhkhkj',FechaSelecionada.format('DD/MM/YYYY'))
    if(valueUsuario===""){
      message.error("Falta seleccionar una persona")
      return
    }

    if(FechaSelecionada===null){
      message.error("Falta seleccionar una fecha ")
      return
    }

    if (lists[0].length === 0 && lists[1].length === 0 && lists[2].length === 0) {
      message.error("No hay datos para guardar")
      return
    }
    const FacturasCopy: Facturas[] = JSON.parse(JSON.stringify(lists[0]))
    const NotaVentasCopy: NotaVentas[] = JSON.parse(JSON.stringify(lists[1]))
    const RecibosCopy: Recibos[] = JSON.parse(JSON.stringify(lists[2]))
    FacturasCopy.forEach((element) => {
      element.provincia = undefined
      element.Cod_Documento = undefined
    })
    NotaVentasCopy.forEach((element) => {
      element.provincia = undefined
      element.Cod_Documento = undefined
    })
    RecibosCopy.forEach((element) => {
      element.Cod_Documento = undefined
    })
    const valorfacturas=FacturasCopy?.reduce((accumulator, currentValue) => accumulator + currentValue.valor,0,);
    const valorNotasVentas=NotaVentasCopy?.reduce((accumulator, currentValue) => accumulator + currentValue.valor,0,);
    const valorRecibos=RecibosCopy?.reduce((accumulator, currentValue) => accumulator + currentValue.valor,0,);
    let valor=(valorfacturas?valorfacturas:0) + (valorNotasVentas?valorNotasVentas:0) + (valorRecibos?valorRecibos:0)
    //console.log('valor', valor, '=', valorfacturas, '+', valorNotasVentas, '+', valorRecibos)

   
    try {
      const res = await fetch(`${url}fapifsg-pr/reembolsos/crear-reembolso-otros`, optionsPost({ Id_Usuario: valueUsuario,
        justificacion: JustificacionValue,
        fecha: new Date(),
        facturas: FacturasCopy,
        notaventas: NotaVentasCopy,
        recibos: RecibosCopy,
        valor:valor,
        Fecha_Documento: FechaSelecionada,
      }))
  
      if(res.status===200){
        setJustificacionValue("");
        setListFacturas([]);
        setListNotaVentas([]);
        setListRecibos([]);
        handleChangeUsuario("")
        //setfechaSeleccionada(moment().endOf("day"))
        swalSuccess({text:'Se guardo exitosamente'})
        
      }else{
        swalWarning({text:'No se pudo registrar'})
      }
      
    } catch (error) {
      swalWarning({text: `${error}` })
    }
}

  const { fetchData: GrabarReembolso } = useFetchInFunction(
    `${url_interna}api/reembolsos/crear-reembolso`,
    optionsPOST,
    useCallback(
      (response: any, error?: Error) => {
        if (error) {
          console.error(error);
          return;
        }
        Swal.fire({
          title: "Datos almacenados",
          text: "Reembolso de gastos almacenado con éxito",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        setJustificacionValue("");
        setListFacturas([]);
        setListNotaVentas([]);
        setListRecibos([]);
      },
      [setListFacturas, setListNotaVentas, setListRecibos]
    )
  );

  return {
    handleOkModal: okModals.current,
    inputsState,
    handleOnClickActionList,
    modalProps,
    setModalProps,
    lists,
    setLists,
    contentDocuments,
    actionsList,
    GrabarReembolso,
    forms,
    initialValues,
    resetFields,
    JustificacionValue,
    setJustificacionValue,
    resetOkModal,
    dataSelect,
    handleChangeUsuario,
    valueUsuario,
    btn_Guardar,
    disabledDate,
    handleDateChange,
    FechaSelecionada
  };
}
