import { Col, Row, Form, Collapse, Statistic, Typography, Input, InputNumber, Select } from "antd"
import { type DefaultOptionType } from "antd/lib/select"
import { BusquedaBodegaFranquiciado } from "components/franquiciados/busqueda-bodega-franquiciado"
import { ButtonRemove, ButtonSave } from "components/otros/buttons"
import { ColumnsTableFormListType, TableFormList } from "components/otros/table-form-list"
import { url } from "config/constants"
import { optionsGet, optionsPost, swalError, swalSuccess } from "config/methods"
// import { DependientesDataPrincipal } from "context/franquiciados/form-pago-context"
import { TipoSeleccion } from "hooks/Franquiciados/usePago"
import { useLayout } from "hooks/otros/useLayout"
import { useScreenSize } from "hooks/otros/useScreenSize"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { useCallback, useState } from "react"
import { useLoaderData } from "react-router-dom"

type DataInicial = {
  franquiciados: DefaultOptionType[]
  bodegas: {
    value: number
    label: string
    franquiciado: number
  }[]
}

type DataDependientes = {
  cod_usuario: number
  dependiente: string
  total: number
  porcentaje_ingresos: number
}

type DataPrincipal = {
  cod_bodega: number
  bodega: string
  cargo_empleados: number
  multiplica_por?: number
  hasta_venta_maxima?: number
  restriccion: 0 | 1
  disable_restriccion: boolean
  dependientes: DataDependientes[]
}

type ValuesForm = {
  bodega: number
  franquiciado: number
  bodegas: DataPrincipal[]
}

const breadcrumbs = [{ Label: "Franquiciados", Url: "/franquiciados" }, { Label: "Configuración" }]

const openKeys = [breadcrumbs[0].Label]
const selectKey = breadcrumbs[0].Label + "/" + breadcrumbs[1].Label

export function Configuracion() {
  useLayout(breadcrumbs, openKeys, selectKey)

  const { isError } = useVerifyResponse()

  const { width } = useScreenSize()

  const [tipoSeleccion, setTipoSeleccion] = useState<TipoSeleccion>(TipoSeleccion.Bodega)
  const [tipoRestriccion, setTipoRestriccion] = useState<Array<0 | 1>>([])
  const [disableRestriccion, setDisableRestriccion] = useState<Array<boolean>>([])
  // const [dataPrincipal, setDataPrincipal] = useState<DataPrincipal[]>([])
  const [form] = Form.useForm<ValuesForm>()

  const dataInicial = useLoaderData() as DataInicial

  const columnsTableDependientes: (index: number) => ColumnsTableFormListType<DataDependientes>[] = index1 => [
    {
      title: "Dependientes",
      name: "dependiente",
      render: () => <Input.TextArea bordered={false} readOnly autoSize size="small" />,
    },
    {
      title: "Total Ingreso",
      name: "total",
      render: () => <Statistic valueStyle={{ fontSize: "inherit" }} precision={2} />,
    },
    {
      title: "Porcentaje",
      name: "porcentaje_ingresos",
      render: () => <InputNumber min={0} step={0.01} size="small" controls={false} addonAfter="%" onChange={value => {}} />,
    },
  ]

  const fillDataPrincipal = useCallback(
    async (tipo: TipoSeleccion) => {
      if (!Boolean(form.getFieldValue(tipo === TipoSeleccion.Bodega ? "bodega" : "franquiciado"))) return
      const franquiciado =
        form.getFieldValue("franquiciado") ??
        dataInicial.bodegas.find(({ value }) => value === form.getFieldValue("bodega"))?.franquiciado
      try {
        const result = await fetch(`${url}fapifsg-pr/franquiciados/configuracion/${franquiciado}`, optionsGet())
        if (isError(result)) throw new Error()
        const res = (await result.json()) as DataPrincipal[]
        res.forEach(({ dependientes, multiplica_por }, index) => {
          if (dependientes.length <= 0) return
          if (dependientes.some(({ porcentaje_ingresos }) => porcentaje_ingresos < 100)) {
            form.setFieldValue(["bodegas", index, "restriccion"], 1)
            res[index].restriccion = 1
            res[index].disable_restriccion = true
            return
          }
          res[index].disable_restriccion = Boolean(multiplica_por)
          res[index].multiplica_por ??= res[index].cargo_empleados
        })
        setTipoRestriccion(res.map(({ restriccion }) => restriccion))
        setDisableRestriccion(res.map(({ disable_restriccion }) => disable_restriccion))
        // setDataPrincipal(res)
        form.setFieldsValue({
          bodegas: res,
        })
      } catch (e) {
        swalError({ text: "No se pudo obtener los datos" })
      }
    },
    [form, isError, dataInicial]
  )

  const handleEliminarRestriccion = async (bodega: number) => {
    try {
      const franquiciado =
        form.getFieldValue("franquiciado") ??
        dataInicial.bodegas.find(({ value }) => value === form.getFieldValue("bodega"))?.franquiciado
      const result = await fetch(
        `${url}fapifsg-pr/franquiciados/eliminar-restriccion`,
        optionsPost({ bodega, franquiciado })
      )
      const text = await result.json()
      swalSuccess({ text })
      form.resetFields()
      await fillDataPrincipal(TipoSeleccion.Bodega)
    } catch (e) {
      swalError({ text: "No se pudo eliminar la restricción" })
    }
  }

  const handleGragar = async (index: number) => {
    try {
      const franquiciado =
        form.getFieldValue("franquiciado") ??
        dataInicial.bodegas.find(({ value }) => value === form.getFieldValue("bodega"))?.franquiciado
      const data = {
        franquiciado,
        bodega: form.getFieldValue(["bodegas", index, "cod_bodega"]),
        multiplica_por: form.getFieldValue(["bodegas", index, "multiplica_por"]),
        hasta_venta_maxima: form.getFieldValue(["bodegas", index, "hasta_venta_maxima"]),
        dependientes: (form.getFieldValue(["bodegas", index, "dependientes"]) as DataDependientes[]).map(dependiente => ({
          cod_usuario: dependiente.cod_usuario,
          porcentaje_ingresos: dependiente.porcentaje_ingresos,
        })),
      }
      const result = await fetch(`${url}fapifsg-pr/franquiciados/registrar-restriccion`, optionsPost(data))
      const text = await result.json()
      swalSuccess({ text })
      form.resetFields()
      await fillDataPrincipal(TipoSeleccion.Bodega)
    } catch (e) {
      console.log(e)
      swalError({ text: "No se pudo registrar la restricción" })
    }
  }

  return (
    <Form form={form}>
      <Row wrap gutter={[16, 16]} style={{ marginRight: 0, padding: "16px" }}>
        <BusquedaBodegaFranquiciado
          tipoSeleccion={tipoSeleccion}
          onChangeTipoSeleccion={e => setTipoSeleccion(e.target.value)}
          onChangeSelect={() => {
            // setDisabledGrabar(true)
            if (tipoSeleccion === TipoSeleccion.Bodega)
              form.setFieldValue(
                "franquiciado",
                dataInicial.bodegas.find(({ value }) => value === form.getFieldValue("bodega"))?.franquiciado
              )
            fillDataPrincipal(tipoSeleccion)
          }}
          bodegas={dataInicial.bodegas.map(({ value, label }) => ({ value, label }))}
          franquiciados={dataInicial.franquiciados}
        />
        {/* <Col xs={24} sm={24} md={24} lg={24} xl={9}>
          <ButtonSave
            buttonProps={{
              async onClick() {
                try {
                  await form.validateFields()
                  form.submit()
                } catch (e) {}
              },
            }}
          />
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.List name="bodegas">
            {fields =>
              fields.map(({ key, name }) => (
                <Collapse key={key} defaultActiveKey={[key]} style={{ marginBottom: "16px" }}>
                  <Collapse.Panel
                    key={key}
                    header={
                      <Form.Item name={[name, "bodega"]} style={{ margin: 0, padding: 0 }}>
                        <Statistic
                          style={{ margin: 0, cursor: "pointer", padding: 0 }}
                          valueStyle={{ fontSize: "inherit" }}
                        />
                      </Form.Item>
                    }
                    style={{ padding: 0 }}
                    extra={
                      <Form.Item
                        label={
                          width >= 576 ? (
                            <Typography.Text style={{ cursor: "pointer" }}>Empleados a su cargo</Typography.Text>
                          ) : undefined
                        }
                        name={[name, "cargo_empleados"]}
                        style={{ margin: 0, padding: 0 }}
                      >
                        <Statistic valueStyle={{ fontSize: "14px" }} style={{ padding: 0, margin: 0 }} />
                      </Form.Item>
                    }
                  >
                    <Row style={{ marginRight: 0 }} gutter={[5, 5]}>
                      <Col xs={24} sm={24} md={24} lg={10} xl={8}>
                        <Form.Item label="Restricción por" name={[name, "restriccion"]}>
                          <Select
                            options={[
                              { label: "Bodega", value: 0 },
                              { label: "Dependientes", value: 1 },
                            ]}
                            onChange={value =>
                              setTipoRestriccion(state => {
                                const newState = state.slice()
                                newState[name] = value
                                return newState
                              })
                            }
                            disabled={disableRestriccion[name]}
                          />
                        </Form.Item>
                      </Col>
                      {tipoRestriccion && tipoRestriccion[name] === 0 && (
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <Form.Item name={[name, "multiplica_por"]} label="Dividir por">
                            <InputNumber min={0} step={0.01} controls={false} />
                          </Form.Item>
                        </Col>
                      )}
                      {tipoRestriccion && tipoRestriccion[name] === 1 && (
                        <Col xs={24} sm={24} md={14} lg={12} xl={10}>
                          <Form.Item
                            name={[name, "hasta_venta_maxima"]}
                            label="Aplicar restricción hasta máximo ventas farmacia"
                          >
                            <InputNumber min={0} step={0.01} placeholder="0.00" controls={false} />
                          </Form.Item>
                        </Col>
                      )}
                      {disableRestriccion[name] && (
                        <Col xs={24} sm={24} md={10} lg={24} xl={6}>
                          <ButtonRemove
                            buttonProps={{
                              onClick() {
                                handleEliminarRestriccion(form.getFieldValue(["bodegas", name, "cod_bodega"]))
                              },
                            }}
                          >
                            Eliminar restricción
                          </ButtonRemove>
                        </Col>
                      )}
                    </Row>
                    {tipoRestriccion && tipoRestriccion[name] === 1 && (
                      <TableFormList
                        columns={columnsTableDependientes(name)}
                        nameList={[name, "dependientes"]}
                        loading={false}
                      />
                    )}
                    {!disableRestriccion[name] && (
                      <ButtonSave
                        buttonProps={{
                          async onClick() {
                            handleGragar(name)
                          },
                          style: { marginTop: "10px" },
                        }}
                      />
                    )}
                  </Collapse.Panel>
                </Collapse>
              ))
            }
          </Form.List>
        </Col>
      </Row>
    </Form>
  )
}
