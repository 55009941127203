import { Table } from "antd";
import { url } from "config/constants";
import { useContext, useEffect, useCallback } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";

export interface DataType {
  key: number;
  rentabilidad_bruta: number;
  to: string;
  descuento: number;
}

const breadCrumbs = [{ Label: "Descuentos Para Promociones Días Lunes", Url: "politicas/lunes" }];

export type EditableTableProps = Parameters<typeof Table>[0];
export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export function useDescuentosLunes() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);

  useBreadCrumbs(breadCrumbs);

  useEffect(() => {
    // if (window.screen.width <= 992) return;
    setOpenKeys(["Políticas Comerciales/Descuentos y Rentabilidad", "Políticas Comerciales"]);
    setSelectedKeys(["Descuentos y Rentabilidad/Promoción Lunes"]);
  }, [setOpenKeys, setSelectedKeys]);

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: "Rentabilidad Bruta %",
      dataIndex: "rentabilidad_bruta",
      width: "220px",
      editable: true,
      align: "right",
    },
    {
      title: "",
      dataIndex: "to",
      width: "40px",
      align: "center",
    },
    {
      title: "Descuento %",
      dataIndex: "descuento",
      width: "200px",
      editable: true,
      align: "left",
    },
  ];

  const getDescuentosLunes = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "politicas/descuentos_lunes", {
        method: "GET",
      }).then((res) => res.json());
      if (respuesta.msg) {
        const { data } = respuesta;
        return { data, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexión", status: false };
    }
  }, []);

  type Data = {
    key: number;
    rentabilidad_bruta: number;
    descuento: number;
  };

  const putDescuentosLunes = async (form: Data) => {
    try {
      const respuesta = await fetch(url + "politicas/actualiza_lunes", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      }).then((res) => res.json());
      if (respuesta.ok) {
        return { data: respuesta.msg, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexión", status: false };
    }
  };

  return { getDescuentosLunes, putDescuentosLunes, defaultColumns };
}
