import { Button, type TableColumnsType, Tag } from "antd"
import { swalError, swalSuccess } from "config/methods"
import { useLayout } from "hooks/otros/useLayout"
import { useScreenSize } from "hooks/otros/useScreenSize"
import { useSearchTableNew } from "hooks/otros/useSearchTableNew"
import { useEffect, useState } from "react"
import { useActionData, useLoaderData, useLocation, useSubmit } from "react-router-dom"

import { EyeOutlined } from "@ant-design/icons"

export type Sueldos = {
  mes: string
  sueldo: number
}

interface Solicitudes {
  key: number
  empleado: string
  periodo: string
  dias_pagar: number
  sueldos: Sueldos[]
}

const breadcrumbs = [
  { Label: "Recursos Humanos", Url: "/rrhh" },
  { Label: "Vacaciones", Url: "/rrhh/vacaciones" },
  { Label: "Pago" },
]

const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]

const selectKey = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export function usePago() {
  useLayout(breadcrumbs, openKeys, selectKey)

  const { width } = useScreenSize()

  const isWeb = width >= 778

  const [pageSize, setPageSize] = useState(7)

  let submit = useSubmit()
  const location = useLocation()
  const actionData = useActionData() as { msg: string; ok: boolean }

  const solicitudes = useLoaderData() as Solicitudes[]

  const [selectedSolicitud, setSelectedSolicitud] = useState<number>()
  const [openModal, setOpenModal] = useState<boolean>(false)

  const { getColumnSearchPropsNew } = useSearchTableNew<Solicitudes>()

  const columnsTable: TableColumnsType<Solicitudes> = [
    {
      title: "Empleado",
      dataIndex: "empleado",
      key: "empleado",
      ...getColumnSearchPropsNew("empleado"),
    },
    {
      title: "Periodo",
      dataIndex: "periodo",
      key: "periodo",
      ...getColumnSearchPropsNew("periodo"),
    },
    {
      title: "Días a pagar",
      dataIndex: "dias_pagar",
      key: "dias_pagar",
      align: "right",
      render: value => <Tag color="default">{value}</Tag>,
    },
    {
      title: "",
      dataIndex: "key",
      key: "acciones",
      align: "center",
      fixed: "right",
      render: value => (
        <Button
          icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
          onClick={() => {
            setSelectedSolicitud(value)
            setOpenModal(true)
          }}
          type="text"
        />
      ),
    },
  ]

  const columnsSueldos: TableColumnsType<Sueldos> = [
    {
      title: "Mes",
      dataIndex: "mes",
      key: "mes",
    },
    {
      title: "Sueldo",
      dataIndex: "sueldo",
      key: "sueldo",
      align: "right",
      render: value => value.toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,"),
    },
  ]

  const total = solicitudes
    .find(({ key }) => selectedSolicitud === key)
    ?.sueldos.reduce((acum, next) => acum + next.sueldo, 0)

  const promedio = (total ?? 0) / (solicitudes.find(({ key }) => selectedSolicitud === key)?.sueldos.length ?? 1)

  const handleOkModal = () => {
    try {
      submit(
        {
          data: JSON.stringify({
            cod_solicitud: selectedSolicitud,
            dias_pagados: solicitudes.find(({ key }) => selectedSolicitud === key)?.dias_pagar,
            pago: ((promedio / 15) * (solicitudes.find(({ key }) => selectedSolicitud === key)?.dias_pagar ?? 0)).toFixed(2),
          }),
        },
        {
          method: "POST",
          action: location.pathname,
        }
      )
      setOpenModal(false)
      setSelectedSolicitud(undefined)
    } catch (e) {
      console.error(e)
      swalError({ text: "No se pudo realizar el pago" })
    }
  }

  useEffect(() => {
    if (!actionData) return
    if (!actionData.msg) return
    if (actionData.ok) {
      swalSuccess({ text: actionData.msg })
      return
    }
    swalError({ text: actionData.msg })
  }, [actionData])

  return {
    isWeb,
    pageSize,
    setPageSize,
    openModal,
    columnsTable,
    solicitudes,
    columnsSueldos,
    handleOkModal,
    setOpenModal,
    selectedSolicitud,
    promedio,
  }
}
