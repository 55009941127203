import { Card, Form, Select, Divider, Input, Button, Row, Col, Tooltip, Tag } from "antd"

import { useProductosIncentivos } from "hooks/productos/useProductosIncentivos"

import { SaveOutlined } from "@ant-design/icons"
import TransferComponent from "components/otros/TransferComponent"

export const ProductosIncentivos = () => {
  const {
    onSearchProducto,
    dataSelect,
    handleProducto,
    dataTransfer,
    handleChange,
    productsKeys,
    form,
    handleSendInfo,
    loading,
  } = useProductosIncentivos()

  return (
    <Row>
      <Col xs={24}>
        <div style={{ margin: "10px" }}>
          <Card title="Administrar productos">
            <Form form={form}>
              <Row gutter={[10, 0]}>
                <Col sm={12} xs={24}>
                  <Form.Item
                    label="Productos:"
                    name="cod_producto"
                    rules={[{ required: true, message: "Seleccione un producto" }]}
                  >
                    <Select
                      options={dataSelect}
                      allowClear
                      showSearch
                      optionFilterProp="label"
                      placeholder="Seleccione una opción"
                      onChange={handleProducto}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col sm={12} xs={24}>
                  <Form.Item label="Buscar producto:" name="producto">
                    <Input.Search
                      placeholder="Escriba el nombre del producto a buscar..."
                      onSearch={onSearchProducto}
                      enterButton
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider style={{ margin: "10px" }} />

              <TransferComponent
                dataTransfer={dataTransfer}
                productsKeys={productsKeys}
                handleChange={handleChange}
                titles={[
                  !dataTransfer[0] ? (
                    ""
                  ) : (
                    <Tooltip title="Clasificación Atc 4">
                      <Tag color="success">{dataTransfer[0].descripcion}</Tag>
                    </Tooltip>
                  ),
                  <Tag color="default">SUSTITUTOS</Tag>,
                ]}
              />
              <Divider style={{ margin: "10px" }} />
              <Row gutter={[10, 0]}>
                <Col xs={24}>
                  <Form.Item
                    label="Descripción de la categoría Atc Fsg:"
                    name="desc_atc"
                    rules={[{ required: true, message: "La descripción es obligatoria" }]}
                  >
                    <Input placeholder="Descripción Atc personalizada" />
                  </Form.Item>
                </Col>
              </Row>
              <Button
                onClick={handleSendInfo}
                type="primary"
                style={{
                  width: "100%",
                }}
                loading={loading}
                icon={<SaveOutlined className="iconbutton" />}
              >
                GUARDAR
              </Button>
            </Form>
          </Card>
        </div>
      </Col>
    </Row>
  )
}

export default ProductosIncentivos
