import { Col, DatePicker, Form, Row, Select, Table } from "antd"

import type { RangePickerProps } from "antd/lib/date-picker"
import { ColumnsType } from "antd/lib/table"
import type { Moment } from "moment"

const { RangePicker } = DatePicker

interface Props<T> {
  selectedTipoConsulta: number
  setSelectedTipoConsulta: React.Dispatch<React.SetStateAction<number>>
  selectedDates: [Moment, Moment]
  handleChangeDates: RangePickerProps["onChange"]
  columnsTable: ColumnsType<T>
  data: Array<T>
}

export function FormTipoConsultaAndTable<T extends object>({
  selectedTipoConsulta,
  setSelectedTipoConsulta,
  selectedDates,
  handleChangeDates,
  columnsTable,
  data,
}: Props<T>) {
  return (
    <Row wrap style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Form layout="horizontal">
          <Row gutter={[16, 16]} style={{ marginRight: 0 }}>
            <Col xs={24} sm={13} md={10} lg={9} xl={8}>
              <Form.Item label="Consultar por">
                <Select
                  options={[
                    { value: 0, label: "Fecha de solicitud" },
                    { value: 1, label: "Fecha de la falta" },
                    { value: 2, label: "Fecha de aprobación" },
                  ]}
                  value={selectedTipoConsulta}
                  onChange={value => setSelectedTipoConsulta(value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={11} md={9} lg={8} xl={8}>
              <Form.Item>
                <RangePicker value={selectedDates} onChange={handleChangeDates} format="DD/MM/YYYY" allowClear={false} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Table scroll={{ x: true }} columns={columnsTable} dataSource={data} />
      </Col>
    </Row>
  )
}
