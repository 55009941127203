import ReactPDF, { Image, StyleSheet } from "@react-pdf/renderer"

// interface logoProps {
//   src: ReactPDF.ImageProps
// }

const styles = StyleSheet.create({
  logo: {
    position: 'absolute',
    left: 20,
    top: 20,
    width: 100,
    height: 100,
    objectFit: 'scale-down'
  },
})

export const Logo = ({ src }: ReactPDF.ImageWithSrcProp) => <Image src={ src } style={ styles.logo } />