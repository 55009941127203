import { Form, Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table";
import { url } from "config/constants";
import { optionsGet, optionsPost, swalError } from "config/methods";
import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";

const breadcrumbs = [{ Label: "Permisos editar mínimo" }];

export interface PropTablePersonal {
  key: number;
  cod_usuario: number;
  nombres: string;
  cedula: string;
  estado: boolean;
}

export interface OptionType {
  value: number;
  label: string;
}

export const useProductosPermisosEditarMinimos = () => {
  useBreadCrumbs(breadcrumbs);
  const [form] = Form.useForm();
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [dateTable, setDateTable] = useState<PropTablePersonal[]>([]);

  useEffect(() => {
    setOpenKeys(["Productos", "Productos"]);
    setSelectedKeys(["Productos/Permisos editar mínimo"]);
  }, [setOpenKeys, setSelectedKeys]);

  const handleSearch = async (value: string) => {
    if (value.length >= 3) {
      try {
        const respuesta = await fetch(url + "apifsg-pr/productos/listar-empleados/", optionsPost({ value })).then((data) =>
          data.json()
        );
        if (respuesta.msg) {
          setOptions(respuesta.data);
        } else {
          setOptions([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOptions([]);
      }
    } else {
      setOptions([]);
    }
  };

  const headTable: ColumnsType<PropTablePersonal> = [
    {
      title: "Id",
      dataIndex: "cod_usuario",
      key: "cod_usuario",
      width: "50px",
    },
    {
      title: "Nombres",
      dataIndex: "nombres",
      key: "nombres",
      width: "auto",
    },
    {
      title: "Cédula",
      dataIndex: "cedula",
      key: "cedula",
      width: "200px",
    },
    {
      title: "Eliminar",
      dataIndex: "estado",
      key: "estado",
      width: "150px",
      align: "center",
      render: (_, record: PropTablePersonal) => (
        <Popconfirm
          title='¿Quieres quitar el permiso de editar mínimos a este empleado?'
          onConfirm={() => handleChangeEstado(record)}
          okText='Sí'
          cancelText='No'
        >
          <Button type='link' icon={<DeleteOutlined />} style={{ width: "100%", border: "none" }}></Button>
        </Popconfirm>
      ),
    },
  ];

  async function handleChangeEstado(record: PropTablePersonal) {
    try {
      if (record) {
        const respuesta = await fetch(url + "apifsg-pr/productos/elimina-permiso/", optionsPost({ newData: record })).then(
          (data) => data.json()
        );
        if (respuesta.msg) {
          const nuevosDatos: PropTablePersonal[] = dateTable.filter((item: PropTablePersonal) => item.key !== record.key);
          setDateTable(nuevosDatos);
          Swal.fire({
            title: "Eliminado",
            text: "El registro se ha eliminado correctamente.",
            icon: "success", // Muestra un ícono de éxito
            confirmButtonText: "Ok",
          });
        } else {
          swalError({ text: "Ocurrio un error al eliminar" });
        }
      }
    } catch (error) {
      swalError({ text: "Ocurrio un error" });
    }
  }

  const handleDataTable = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "apifsg-pr/productos/listar-permisos", optionsGet()).then((data) => data.json());
      setDateTable(respuesta.data);
    } catch (error) {}
  }, []);

  const handleChangeSelectEmpleado = async (value: string) => {
    try {
      if (value) {
        let newData: PropTablePersonal = JSON.parse(value);
        newData.estado = true;
        const respuesta = await fetch(url + "apifsg-pr/productos/inserta-permiso/", optionsPost({ newData })).then((data) =>
          data.json()
        );
        if (respuesta.msg) {
          setDateTable((prevState) => (Array.isArray(prevState) ? [newData, ...prevState] : [newData]));
          // setDateTable((prevState) => [newData, ...prevState]);
          form.setFieldsValue({ cod_empleado: null });
          setOptions((options) => options?.filter((option: OptionType) => option.value !== newData.key));
        }
      }
    } catch (error) {
      swalError({ text: "Ocurrio un error" });
    }
  };

  useEffect(() => {
    handleDataTable();
  }, [handleDataTable]);

  return {
    headTable,
    handleSearch,
    options,
    dateTable,
    handleChangeSelectEmpleado,
    form,
  };
};
