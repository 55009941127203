import { ColumnType, ColumnsType } from 'antd/lib/table'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { url} from "config/constants"
import { optionsPost} from "config/methods"
import moment from 'moment'
import { useExport } from 'hooks/otros/useExport'
import { Button,  Input, InputRef, Space } from 'antd'
import {SearchOutlined} from "@ant-design/icons"
import { FilterConfirmProps } from 'antd/lib/table/interface'
import Highlighter from 'react-highlight-words'



export interface DataTypeCxC {
    key: React.Key
    Bodega: string
    Ruc: string
    Nombres: string
    NumeroFactura: string
    Fecha: Date
    Total_Factura: Number
    NumComprobantePago: Number
    Abono: Number
    Saldo:Number
  }

type DataIndex = keyof DataTypeCxC


export default function UseCuentaporCobrar() {

    const[dataCxC, setdataCxC]=useState<DataTypeCxC[]>([])
    const { exportToExcel } = useExport()
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef<InputRef>(null)
    const [fechasSeleccionadas, setFechasSeleccionadas] = useState<[moment.Moment, moment.Moment]>()
    
  const [dataEstado, setDataEstado] = useState<boolean>(false);

    const handleDateChange = (dates: any, dateStrings: any) => {
      setFechasSeleccionadas(dateStrings)
    }

    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
      confirm()
      setSearchText(selectedKeys[0])
      setSearchedColumn(dataIndex)
    }
  
    const handleReset = (clearFilters: () => void) => {
      clearFilters()
      setSearchText("")
    }
  
  
    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataTypeCxC> => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Buscar ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Limpiar
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false })
                setSearchText((selectedKeys as string[])[0])
                setSearchedColumn(dataIndex)
              }}
            >
              Filtrar
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close()
              }}
            >
              Cerrar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      onFilterDropdownOpenChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100)
        }
      },
      render: text =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    })



    const columns: ColumnsType<DataTypeCxC> = [
        {
          title: "Farmacia",
          dataIndex: "Bodega",
          width: "200px",
          className:"fontsize",
        ...getColumnSearchProps('Bodega')
        },
        {
          title: "Ruc",
          dataIndex: "Ruc",
          className:"fontsize",
          ...getColumnSearchProps('Ruc')

        },
        {
          title: "Nombres",
          dataIndex: "Nombres",
          className:"fontsize",
        ...getColumnSearchProps('Nombres')
        },
        {
          title: "N. Factura",
          dataIndex: "NumeroFactura",
          className:"fontsize",
          ...getColumnSearchProps('NumeroFactura')  
        },
        {
          title: "Fecha",
          dataIndex: "Fecha",
          className:"fontsize",
        },
        {
          title: "Total Factura",
          dataIndex: "Total_Factura",
          className:"fontsize",
          align:'right'
          //sorter: (a, b) => a.fecha_solicitud.getTime() - b.fecha_solicitud.getTime(),
        },
        {
          title: "N. Comprobante",
          dataIndex: "NumComprobantePago",
          className:"fontsize",
          ...getColumnSearchProps('NumComprobantePago')  
          
          //sorter: (a, b) => a.fecha_solicitud.getTime() - b.fecha_solicitud.getTime(),
        },
        {
          title: "Abono",
          dataIndex: "Abono",
          className:"fontsize",
          align:'right',
          //sorter: (a, b) => a.fecha_solicitud.getTime() - b.fecha_solicitud.getTime(),
        },
        {
          title: "Saldo",
          dataIndex: "Saldo",
          className:"fontsize",
          align:'right'
          //sorter: (a, b) => a.fecha_solicitud.getTime() - b.fecha_solicitud.getTime(),
        },
      ]

      const getCuentasporCobrar =useCallback( async () => {
      try {
        setDataEstado(false);
        const res = await fetch(`${url}fapifsg-pr/contabilidad/cuentasporcobrar/get-CxC`, optionsPost({fechainicio: fechasSeleccionadas?fechasSeleccionadas[0]:"", fechafin:fechasSeleccionadas?fechasSeleccionadas[1]:""})).then((data) => data.json())
        //console.log('res', res)
      let newdata:any=[]
      if(res){
        setDataEstado(true);
          newdata = Array.from(res).map((item: any, key: any) => ({
            key: item.Cod_Venta,
            Bodega: item.Descripcion_Sri,
            Ruc: item.RUC,
            Nombres: item.Nombres,
            NumeroFactura: item.NumFactura,
            Fecha: item.Fecha,
            Total_Factura: (item.Total).toFixed(4),
            NumComprobantePago: item.numerocomprobante,
            Abono: (item.Abono).toFixed(4),
            Saldo: (item.Saldo).toFixed(4)
        }))
       }
       setdataCxC(newdata)
       setDataEstado(true);
        
      } catch (error) {
        console.log(error)
      }
    },[fechasSeleccionadas ])

    const handleDownload = () => {    
      const dataExport = dataCxC.map((item)=>{
          return{
            "Farmacia": item.Bodega,
            "Número Cedula": item.Ruc,
            "Nombres": item.Nombres,
            "# Factura": item.NumeroFactura,
            "Fecha": item.Fecha,
            "V/ Fact": item.Total_Factura,
            "Abono": item.NumComprobantePago,
            "# Comprobante de Pago ": item.NumComprobantePago,
            "Saldo": item.Saldo
          }
      })
      exportToExcel(dataExport, `Reporte_Cuentas_por_Cobrar${moment().format("DDMMYYYY")}`)
    }

      useEffect(() => {
        getCuentasporCobrar()
      },[getCuentasporCobrar])


  return {
    columns,
    dataCxC,
    handleDownload,
    fechasSeleccionadas,
    handleDateChange,
    dataEstado
  }
}


