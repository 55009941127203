import { Form } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { optionsPOST, url, url_interna } from "config/constants";
import { optionsGet, optionsPost } from "config/methods";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useExport } from "hooks/otros/useExport";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";

const breadcrumbs = [{ Label: "Ventas" }];

interface PropsTableReport {
  key: number;
  bodega: string;
  Provincia: string;
  ciudad: string;
  ruc: string;
  supervisor: string;
  nombres: string;
  telefono: string;
  direccion: string;
  correo: string;
  descripcion: string;
  total: number;
  fecha: string;
  cantidad: number;
  total_factura: number;
  dependiente: string;
  factura: string;
  n_interno: number;
}
interface PropSelect {
  value: number;
  label: string;
}
interface PropsFilter {
  text: string;
  value: string;
}

export function useReporteVentas() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const [columnsTableReport, setColumnsTableReport] = useState<PropsTableReport[]>([]);
  const [selectOptions, setSelectOptions] = useState<PropSelect[]>([]);
  const [bodegas, setBodegas] = useState<PropSelect[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectedItemsOptions, setSelectedItemsOptions] = useState<number[]>([]);
  const [form] = Form.useForm();
  const prevSelectedValuesRef = useRef<number[]>();
  const [dataFiltrada, setDataFiltrada] = useState<PropsTableReport[]>([]);
  const { exportToExcel } = useExport();
  const [filters, setFilters] = useState<PropsFilter[]>([]);
  const [filter2, setFilters2] = useState<PropsFilter[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  useBreadCrumbs(breadcrumbs);

  function truncarTexto(texto: string) {
    const segundoEspacio = texto?.indexOf(" ", texto?.indexOf(" ") + 1);
    if (segundoEspacio !== -1) {
      return texto?.substring(0, segundoEspacio);
    } else {
      return texto;
    }
  }

  const actualizarFiltros = useCallback((data: PropsTableReport[]) => {
    const uniqueFilters: any = {};

    const nuevosFiltros = data.reduce((accumulator: PropsFilter[], item: PropsTableReport) => {
      const key = truncarTexto(item.supervisor);
      if (!uniqueFilters[key]) {
        uniqueFilters[key] = true;
        accumulator.push({
          text: key,
          value: key,
        });
      }
      return accumulator;
    }, []);

    setFilters(nuevosFiltros);
  }, []);

  const actualizarFiltros2 = useCallback((data: PropsTableReport[]) => {
    const uniqueFilters: any = {};

    const nuevosFiltros = data.reduce((accumulator: PropsFilter[], item: PropsTableReport) => {
      const key = truncarTexto(item.Provincia);
      if (!uniqueFilters[key]) {
        uniqueFilters[key] = true;
        accumulator.push({
          text: key,
          value: key,
        });
      }
      return accumulator;
    }, []);

    setFilters2(nuevosFiltros);
  }, []);

  const headTableReport: ColumnsType<PropsTableReport> = [
    {
      title: "Bodega",
      dataIndex: "Bodega",
      key: "Bodega",
      className: "customTbl",
    },
    {
      title: "Provincia",
      dataIndex: "Provincia",
      key: "Provincia",
      className: "customTbl",
      filters: filter2,
      onFilter: (value: any, record) => record.Provincia?.indexOf(value) === 0,
    },
    {
      title: "Ciudad",
      dataIndex: "Ciudad",
      key: "Ciudad",
      className: "customTbl",
    },
    {
      title: "Supervisor",
      dataIndex: "supervisor",
      key: "supervisor",
      className: "customTbl",
      filters: filters,
      onFilter: (value: any, record) => record.supervisor?.indexOf(value) === 0,
    },
    {
      title: "Ruc",
      dataIndex: "Ruc",
      key: "Ruc",
      className: "customTbl",
    },
    {
      title: "Nombres",
      dataIndex: "Nombres",
      key: "Nombres",
      className: "customTbl",
    },
    {
      title: "Telefono",
      dataIndex: "Telefono",
      key: "Telefono",
      className: "customTbl",
    },
    {
      title: "Dirección",
      dataIndex: "Direccion",
      key: "Direccion",
      className: "customTbl",
    },
    {
      title: "Correo",
      dataIndex: "Correo",
      key: "Correo",
      className: "customTbl",
    },
    {
      title: "Descripción",
      dataIndex: "Descripcion",
      key: "Descripcion",
      className: "customTbl",
    },
    {
      title: "Laboratorio",
      dataIndex: "laboratorio",
      key: "laboratorio",
      className: "customTbl",
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
      align: "right",
      className: "customTbl",
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
      className: "customTbl",
    },
    {
      title: "Mes",
      dataIndex: "mes",
      key: "mes",
      className: "customTbl",
    },
    {
      title: "Dia",
      dataIndex: "dia",
      key: "dia",
      className: "customTbl",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      className: "customTbl",
      align: "right",
      render: (value) => value.toFixed(4).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,"),
    },
    {
      title: "Total Factura",
      dataIndex: "Total_Factura",
      key: "Total_Factura",
      className: "customTbl",
      align: "right",
    },
    {
      title: "Dependiente",
      dataIndex: "Dependiente",
      key: "Dependiente",
      className: "customTbl",
    },
    {
      title: "Factura",
      dataIndex: "Factura",
      key: "Factura",
      className: "customTbl",
    },
    {
      title: "N_Interno",
      dataIndex: "N_Interno",
      key: "N_Interno",
      className: "customTbl",
    },
  ];

  const handleChangeType = async (value: number) => {
    form.setFieldsValue({ optionSelectType: [] });
    setSelectOptions([]);
    const respuesta = await fetch(url + "apifsg-pr/reportes/tipo-venta/", optionsPost({ tipo: value })).then((data) =>
      data.json()
    );

    setSelectOptions(respuesta);
  };

  const handleChangeBodegas = (value: number[]) => {
    if (value.includes(0) && value.length > 1) {
      if (form.getFieldValue("cod_bodegas").includes(0)) {
        let dataFilter = value.filter((v) => v !== 0);
        form.setFieldsValue({
          cod_bodegas: dataFilter,
        });
        setSelectedItems(dataFilter);
      } else {
        setSelectedItems([0]);
        form.setFieldsValue({
          cod_bodegas: [0],
        });
      }
    } else {
      if (value.length >= selectedItems.length) {
        setSelectedItems(value);
      } else {
        setSelectedItems(value);
        prevSelectedValuesRef.current = value;
      }
    }
  };

  const handleChangeOption = (value: number[]) => {
    if (value.includes(0) && value.length > 1) {
      if (form.getFieldValue("optionSelectType").includes(0)) {
        let dataFilter = value.filter((v) => v !== 0);
        form.setFieldsValue({
          optionSelectType: dataFilter,
        });
        setSelectedItemsOptions(dataFilter);
      } else {
        setSelectedItemsOptions([0]);
        form.setFieldsValue({
          optionSelectType: [0],
        });
      }
    } else {
      if (value.length >= selectedItems.length) {
        setSelectedItems(value);
      } else {
        setSelectedItems(value);
        prevSelectedValuesRef.current = value;
      }
    }
  };

  const handleClickGenerar = () => {
    setLoader(true);
    form
      .validateFields()
      .then(async (values) => {
        setColumnsTableReport([]);
        actualizarFiltros([]);
        actualizarFiltros2([]);

        setDataFiltrada([]);
        // const respuesta = await fetch(url + "apifsg-pr/reportes/generar/", optionsPost({ values })).then((data) =>
        //   data.json()
        // );
        const respuesta = await fetch(
          `${url_interna}api/reportes/generar`,
          optionsPOST(JSON.stringify({ values }), {
            "api-token": "apitoken",
          })
        ).then((data) => data.json());

        if (respuesta.msg) {
          setColumnsTableReport(respuesta.data);
          setDataFiltrada(respuesta.data);
          actualizarFiltros(respuesta.data);
          actualizarFiltros2(respuesta.data);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
        Swal.fire("Error", "Complete los campos requeridos!", "error");
      });
  };

  const handleData = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "apifsg-pr/reportes/bodegas/", optionsGet()).then((data) => data.json());
      if (respuesta.msg) {
        setBodegas(respuesta.data);
        form.setFieldsValue({ cod_bodegas: [0] });
      } else {
        Swal.fire("Error", "Ocurrio un error en la información", "error");
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [form]);

  const initialOptions = [
    {
      value: 1,
      label: "Laboratorios",
    },
    {
      value: 2,
      label: "Productos",
    },
  ];

  const handleClickDownload = () => {
    if (!dataFiltrada) return;
    exportToExcel(dataFiltrada, ` Reporte ventas general_${moment().format("YYYY-MM-DD_HH-mm-ss")}`);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PropsTableReport> | SorterResult<PropsTableReport>[],
    extra: TableCurrentDataSource<PropsTableReport>
  ) => {
    if (extra.action === "filter") {
      setDataFiltrada(extra.currentDataSource);
    }
  };

  useEffect(() => {
    setOpenKeys(["Reportes", "Reportes"]);
    setSelectedKeys(["Reportes/Ventas"]);
  }, [setOpenKeys, setSelectedKeys]);

  useEffect(() => {
    handleData();
  }, [handleData]);

  return {
    initialOptions,
    handleClickGenerar,
    headTableReport,
    handleChangeType,
    selectOptions,
    form,
    bodegas,
    handleChangeBodegas,
    selectedItems,
    handleClickDownload,
    columnsTableReport,
    handleTableChange,
    dataFiltrada,
    loader,
    selectedItemsOptions,
    handleChangeOption,
  };
}
