import { ThreeDots } from "react-loader-spinner"

export function Loading() {
  
  return (
    <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <ThreeDots 
        height="200" 
        width="200" 
        radius="9"
        color="var(--primary)"
        visible={ true }
      />
    </div>
  )
}