import { createContext } from "react"

interface ContextListProps {
  children: JSX.Element | Array<JSX.Element>,
  iconButton?: JSX.Element
  textButton?: string 
  actionsList?: {
    icon?: JSX.Element
    text?: string
  }[]
  actionsForActionsList: {
    [key: string]: (index: number) => void
  }
  listData: Array<JSX.Element>
  visibleButton?: boolean
}

type ListContextType = {
  listData: Array<JSX.Element>
  iconButton?: JSX.Element
  textButton?: string 
  actionsList?: {
    icon?: React.ReactNode
    text?: string
  }[]
  actionsForActionsList: {
    [key: string]: (index: number) => void
  }
  visibleButton?: boolean
}

export const ListContext = createContext<ListContextType>({} as ListContextType)

export function ContextList({ children, iconButton, textButton, actionsList, actionsForActionsList, listData, visibleButton }: ContextListProps) {

  return (
    <ListContext.Provider value={{ listData, iconButton, textButton, actionsList, actionsForActionsList, visibleButton }}>
      { children }
    </ListContext.Provider>
  )
}