import { Col, Form, Row, Table, type TableColumnsType, Button, Select, DatePicker } from "antd"
import { useLayout } from "hooks/otros/useLayout"
import moment from "moment"
import { Suspense, useCallback, useEffect } from "react"
import {
  Await,
  Outlet,
  useActionData,
  useAsyncValue,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
  useSubmit,
} from "react-router-dom"

import { optionsPost, swalError, swalQuestion, swalSuccess } from "config/methods"
import { Loading } from "components/otros/Loading"
import { type DefaultOptionType } from "antd/lib/select"

import { CheckCircleOutlined } from "@ant-design/icons"
import { useSearchTableNew } from "hooks/otros/useSearchTableNew"

type Redireccionar = {
  inicio: string
  fin: string
  bodega: number
}

const breadcrumbs = [{ Label: "Cajas", Url: "/cajas" }, { Label: "Procesar voucher parcial" }]

const openKeys = [breadcrumbs[0].Label]

const selectKey = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`

export function ProcesarVoucherParcial() {
  useLayout(breadcrumbs, openKeys, selectKey)

  const { inicio, fin, bodega } = useParams()

  const bodegas = useLoaderData() as DefaultOptionType[]

  const [form] = Form.useForm()

  const navigate = useNavigate()

  const redireccionar = useCallback(
    ({ inicio, fin, bodega }: Redireccionar) => navigate(`/cajas/procesar-voucher-parcial/${inicio}/${fin}/${bodega}`),
    [navigate]
  )

  useEffect(() => {
    !inicio
      ? redireccionar({
          inicio: moment().subtract(1, "months").format("YYYY-MM-DD"),
          fin: moment().format("YYYY-MM-DD"),
          bodega: -1,
        })
      : form.setFieldsValue({
          fechas: [moment(inicio, "YYYY-MM-DD"), moment(fin, "YYYY-MM-DD")],
          bodega: parseInt(bodega ?? "-1"),
        })
  }, [navigate, fin, inicio, bodega, form, redireccionar])

  return (
    <Form style={{ padding: 0 }} form={form}>
      <Row
        wrap
        style={{ padding: "16px", marginRight: "0px", marginBottom: 0, marginLeft: 0, backgroundColor: "#fff" }}
        gutter={[16, 16]}
      >
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Form.Item label="Bodega" name="bodega">
            <Select
              showSearch
              options={bodegas}
              optionFilterProp="label"
              onChange={value =>
                redireccionar({
                  inicio: form.getFieldValue("fechas")[0].format("YYYY-MM-DD"),
                  fin: form.getFieldValue("fechas")[1].format("YYYY-MM-DD"),
                  bodega: value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Form.Item label="Fechas" name="fechas" initialValue={[moment().subtract(1, "months"), moment()]}>
            <DatePicker.RangePicker
              format="DD/MM/YYYY"
              onChange={values =>
                redireccionar({
                  inicio: values?.[0]?.format("YYYY-MM-DD") ?? "",
                  fin: values?.[1]?.format("YYYY-MM-DD") ?? "",
                  bodega: form.getFieldValue("bodega"),
                })
              }
              allowClear={false}
            />
          </Form.Item>
        </Col>
        <Outlet />
      </Row>
    </Form>
  )
}

export function MiddleProcessVoucher() {
  const datos = useLoaderData()

  return (
    <Suspense fallback={<Loading />}>
      <Await resolve={datos}>
        <Procesar />
      </Await>
    </Suspense>
  )
}

type Vouchers = {
  key: number
  bodega: string
  usuario: string
  valor: number
  lote: string
  referencia: string
  fecha: string
  tid: string
  mid: string
  numeroaprobacion: string
  tipo_credito: string
  emisor: string
}

function Procesar() {
  const vouchers = useAsyncValue() as Vouchers[]

  const { getColumnSearchPropsNew } = useSearchTableNew<Vouchers>()

  let submit = useSubmit()

  const location = useLocation()

  const actionData = useActionData() as { msg: string; ok: boolean }

  useEffect(() => {
    if (!actionData) return
    if (!actionData.msg) return
    if (actionData.ok) {
      swalSuccess({ text: actionData.msg })
      return
    }
    swalError({ text: actionData.msg })
  }, [actionData])

  const handleProcesar = async (voucher: number) => {
    try {
      const resp = await swalQuestion({ title: "¿Procesar voucher?" })
      if (!resp.isConfirmed) return
      submit(
        {
          data: JSON.stringify({ voucher }),
        },
        {
          method: "POST",
          action: location.pathname,
        }
      )
    } catch (e) {
      console.error(e)
      swalError({ text: "No se pudo procesar el voucher" })
    }
  }

  const columns: TableColumnsType<Vouchers> = [
    {
      title: "Bodega",
      dataIndex: "bodega",
      key: "bodega",
      filters: [...new Set(vouchers.map(({ bodega }) => bodega))].map(bodega => ({ text: bodega, value: bodega })),
      filterSearch: true,
    },
    {
      title: "Usuario",
      dataIndex: "usuario",
      key: "usuario",
      ...getColumnSearchPropsNew("usuario"),
    },
    {
      title: "Tipo Crédito",
      dataIndex: "tipo_credito",
      key: "tipo_credito",
      filters: [...new Set(vouchers.map(({ tipo_credito }) => tipo_credito))].map(tipo_credito => ({
        text: tipo_credito,
        value: tipo_credito,
      })),
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      align: "right",
    },
    {
      title: "Lote",
      dataIndex: "lote",
      key: "lote",
      align: "right",
      ...getColumnSearchPropsNew("lote"),
    },
    {
      title: "Referencia",
      dataIndex: "referencia",
      key: "referencia",
      align: "right",
      ...getColumnSearchPropsNew("referencia"),
    },
    {
      title: "Aprobación",
      dataIndex: "numeroaprobacion",
      key: "aprobacion",
      align: "right",
      ...getColumnSearchPropsNew("numeroaprobacion"),
    },
    {
      title: "Tid",
      dataIndex: "tid",
      key: "tid",
      ...getColumnSearchPropsNew("tid"),
    },
    {
      title: "Mid",
      dataIndex: "mid",
      key: "mid",
      ...getColumnSearchPropsNew("mid"),
    },
    {
      title: "Procesar",
      dataIndex: "key",
      key: "key",
      align: "center",
      fixed: "right",
      render: value => (
        <Button
          icon={<CheckCircleOutlined style={{ fontSize: "1.3rem" }} />}
          onClick={() => handleProcesar(value)}
          type="text"
        />
      ),
    },
  ]

  return (
    <>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Table size="small" columns={columns} dataSource={vouchers} scroll={{ x: true }} pagination={{ size: "default" }} />
      </Col>
    </>
  )
}
