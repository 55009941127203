import { Col, Row, DatePicker, Table, Card, Modal, Form } from "antd";
import "moment/locale/es";

import moment from "moment";
import { useReporteInventarioProductividad } from "hooks/inventarios/reportes/productividad/useProductividadGeneal";
import { ButtonExcel } from "components/otros/buttons";
import { useVT } from "virtualizedtableforantd4";

export function Productividad() {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);
  const {
    columns,
    columsDatatableDetalle,
    isModalOpen,
    mes,
    setMes,
    detalle,
    form,
    filteredData,
    handleCancelHistorico,
    isModalOpenHistorico,
    handleOk,
    handleCancel,
    handleClickDownloadData,
    calcularTotales,
    columsDatatableDetalleHistorico,
    filteredDataHistorico,
    handleClickDownloadDataHistorico,
    setRowClassName,
  } = useReporteInventarioProductividad();
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={12} md={14} lg={16} xl={18}>
        <DatePicker
          value={mes}
          picker="month"
          placeholder="Seleccionar mes"
          format="MMMM [del] YYYY"
          onChange={(value) => setMes(value ?? moment())}
          inputReadOnly
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <Table
            columns={columns}
            dataSource={filteredData}
            scroll={{ x: true }}
            size="small"
            pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
          />
        </Card>
      </Col>
      <Modal
        footer={false}
        transitionName=""
        title="Productividad de inventarios"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"90wh"}
      >
        <Form form={form}>
          <Row gutter={[10, 10]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
            <Col xs={24} md={4}>
              <ButtonExcel buttonProps={{ onClick: handleClickDownloadData }}></ButtonExcel>
            </Col>
          </Row>
        </Form>
        <Table
          pagination={false}
          loading={columsDatatableDetalle ? false : true}
          scroll={{ x: 900, y: "55vh" }}
          size="small"
          bordered
          columns={columsDatatableDetalle}
          dataSource={detalle}
          summary={() => {
            const { tiempoFormateado, unid, promedioProduccion } = calcularTotales();

            return (
              <Table.Summary.Row style={{ backgroundColor: "#f0f0f0" }}>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{tiempoFormateado}</Table.Summary.Cell>
                <Table.Summary.Cell index={2}>{unid}</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>{promedioProduccion}</Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </Modal>
      <Modal
        footer={false}
        transitionName=""
        title="Reporte inventario HandHeld"
        open={isModalOpenHistorico}
        //onOk={handleOk}
        onCancel={handleCancelHistorico}
        width={"90wh"}
      >
        <Form form={form}>
          <Row gutter={[10, 10]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
            <Col xs={24} md={4}>
              <ButtonExcel buttonProps={{ onClick: handleClickDownloadDataHistorico }}></ButtonExcel>
            </Col>
          </Row>
        </Form>
        <Table
          pagination={false}
          components={vt}
          loading={columsDatatableDetalleHistorico ? false : true}
          rowClassName={setRowClassName}
          scroll={{ x: 900, y: "55vh" }}
          size="small"
          bordered
          columns={columsDatatableDetalleHistorico}
          dataSource={filteredDataHistorico}
        />
      </Modal>
    </Row>
  );
}

export default Productividad;
