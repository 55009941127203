import { LayoutContext } from "context/LayoutContext"
import { useContext, useEffect } from "react"

import { HomeOutlined } from '@ant-design/icons'

export type BreadcrumbType = { 
  Label: string | JSX.Element | JSX.Element[],
  Url?: string
}

export function useBreadCrumbs(breadCrumbs: BreadcrumbType[]) {
  
  const { setBreadCrumbs } = useContext(LayoutContext)

  useEffect(() => {
    setBreadCrumbs([
      { Label: <HomeOutlined />, Url: '/'},
      ...breadCrumbs
    ])
  },[ setBreadCrumbs, breadCrumbs ])
}