import { Col, Row, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import { url } from "config/constants"
import { optionsPost } from "config/methods"
import moment from "moment"
import React, { useEffect, useState } from "react"
const { Title } = Typography

export interface DatatypeAnual {
  key: React.Key
  nombres: string
  ttm: number
  t_cta_principal: number
  f_a1: number
  t_cta_principala1: number
  f_a2: number
  t_cta_principala2: number
  f_a3: number
  t_cta_principala3: number
  children?: DatatypeAnual[]
}

export interface DatatypeTrimestre {
  key: React.Key
  nombres: string
  Cuenta_Mayor: string
  ttm: number
  f_t1: number
  f_t2: number
  f_t3: number
  f_t4: number
  children?: DatatypeTrimestre[]
}

export function useEstadoIngresoprueba() {
  const [visible, setvisible] = useState(true)
  const [t_anual, setT_anual] = useState<ColumnsType<DatatypeAnual>>([])
  const [t_trimestral, setT_trimestral] = useState<ColumnsType<DatatypeTrimestre>>([])
  const [dataAnual, setdataAnual] = useState<ColumnsType<DatatypeAnual>>([])
  const [dataTrimestral, setdataTrimestral] = useState<ColumnsType<DatatypeAnual>>([])
  const [dataexpand, setdataexpand] = useState([])
  const [dataexpandtri, setdataexpandtri] = useState([])
  
  const handleExpand = (expanded: any, record: any) => {
    let datos: any
    let datosexpand: any
    // Aquí puedes realizar la acción que deseas cuando se expande o contrae una fila
    if (expanded) {
      let exist = dataexpand.filter((item: DatatypeAnual) => item.key === record.key)
      const datanew: any = exist.length < 1 ? [...dataexpand, record] : dataexpand
      setdataexpand(datanew)
      const updatedData = dataAnual.map((item: any, key: any) => {
        if (item.nombres === record.nombres) {
          const exist = item.children.filter((children: any) => children.nombres === "Total de " + item.nombres)
          
          if (exist.length < 1) {
            item.children.push({
              key:key + "111",
              nombres: "Total de " + item.nombres,
              ttm: record.ttm,
              t_cta_principal: record.t_cta_principal,
              f_a1: record.f_a1,
              t_cta_principala1: record.t_cta_principal,
              f_a2: record.f_a2,
              t_cta_principala2: record.t_cta_principala2,
              f_a3: record.f_a3,
              t_cta_principala3: record.t_cta_principala3,
            })
          }
          datos = { ...item, ttm: "", f_a1: "", f_a2: "", f_a3: "", children: item.children }
          return datos
        }
        if (item.children !== undefined && item.children.length > 0) {
          item.children.foreach((element: any, key1: any) => {
            if (element.nombres === record.nombres) {
              const exist1 = element.children.filter((data: any) => data.nombres === "Total de " + record.nombres)
              if (exist1.length < 1) {
                element.children.push({
                  key: key1 + "" + (+item.children.length + 1)+"1111",
                  nombres: "Total de " + element.nombres,
                  ttm: record.ttm,
                  t_cta_principal: record.t_cta_principal,
                  f_a1: record.f_a1,
                  t_cta_principala1: record.t_cta_principal,
                  f_a2: record.f_a2,
                  t_cta_principala2: record.t_cta_principala2,
                  f_a3: record.f_a3,
                  t_cta_principala3: record.t_cta_principala3,
                })
              }
              item.children[key1] = { ...element, ttm: "", f_a1: "", f_a2: "", f_a3: "" }
            }
            //return element
          })
        }
        return item
      })
      setdataAnual(updatedData)
      //console.log(`Se ha expandido la fila con clave: ${record.key}`)
      // Realiza acciones adicionales según sea necesario
    } else {
      const updatedDataexpand = dataAnual.map((item: any, key: any) => {
        if (item.nombres === record.nombres) {
          const exist = dataexpand.filter((children: DatatypeAnual) => children.nombres === item.nombres)
          if (exist.length > 0) {
            datosexpand = {
              ...item,
              ttm: exist[0]["ttm"],
              f_a1: exist[0]["f_a1"],
              f_a2: exist[0]["f_a2"],
              f_a3: exist[0]["f_a3"],
              children: item.children,
            }
            const dataexpandnew = dataexpand.filter((children: DatatypeAnual) => children.nombres !== item.nombres)
            setdataexpand(dataexpandnew)
            return datosexpand
          }
        }
        if (item.children !== undefined && item.children.length > 0) {
          item.children.foreach((element: any, key1: any) => {
            if (element.nombres === record.nombres) {
              const exist = dataexpand.filter((children: any) => children.nombres === record.nombres)
              if (exist.length > 0) {
                item.children[key1] = {
                  ...element,
                  ttm: exist[0]["ttm"],
                  f_a1: exist[0]["f_a1"],
                  f_a2: exist[0]["f_a2"],
                  f_a3: exist[0]["f_a3"],
                }
                const dataexpandnew = dataexpand.filter((children: DatatypeAnual) => children.nombres !== record.nombres)
                setdataexpand(dataexpandnew)
              }
            }
            //return element
          })
        }
        return item
      })
      setdataAnual(updatedDataexpand)
      //console.log(`Se ha contraído la fila con clave: ${record.key}`)
      // Realiza acciones adicionales según sea necesario
    }
  }

  const handleExpandTri = (expanded: any, record: any) => {
    let datos: any
    let datosexpand: any
    // Aquí puedes realizar la acción que deseas cuando se expande o contrae una fila
    if (expanded) {
      let exist = dataexpandtri.filter((item: DatatypeTrimestre) => item.key === record.key)
      const datanew: any = exist.length < 1 ? [...dataexpandtri, record] : dataexpandtri
      
      setdataexpandtri(datanew)
      const updatedData = dataTrimestral.map((item: any, key: any) => {
        if (item.nombres === record.nombres) {
          const exist = item.children.filter((children: any) => children.nombres === "Total de " + item.nombres)
          if (exist.length < 1) {
            
            item.children.push({
              key: key + "111",
              nombres: "Total de " + item.nombres,
              ttm: record.ttm,
              f_t1: record.f_t1,
              f_t2: record.f_t2,
              f_t3: record.f_t3,
              f_t4: record.f_t4,
            })
          }
          datos = { ...item, ttm: "", f_t1: "", f_t2: "", f_t3: "", f_t4: "", children: item.children }
          return datos
        }
        if (item.children !== undefined && item.children.length > 0) {
          item.children.map((element: any, key1: any) => {
            if (element.nombres === record.nombres) {
              const exist1 = element.children.filter((data: any) => data.nombres === "Total de " + record.nombres)
              if (exist1.length < 1) {
                console.log( key + "" + (element.children.length + 1))
                element.children.push({
                  key: key1 + "" + (+item.children.length + 1)+"1111",
                  nombres: "Total de " + element.nombres,
                  ttm: record.ttm,
                  f_t1: record.f_t1,
                  f_t2: record.f_t2,
                  f_t3: record.f_t3,
                  f_t4: record.f_t4,
                })
              }
              item.children[key1] = { ...element, ttm: "", f_t1: "", f_t2: "", f_t3: "",f_t4: "" }
            }
            return element
          })
        }
        return item
      })
      setdataTrimestral(updatedData)
      //console.log(`Se ha expandido la fila con clave: ${record.key}`)
      // Realiza acciones adicionales según sea necesario
    } else {
      const updatedDataexpand = dataTrimestral.map((item: any, key: any) => {
        if (item.nombres === record.nombres) {
          const exist = dataexpandtri.filter((children: DatatypeTrimestre) => children.nombres === item.nombres)
          if (exist.length > 0) {
            datosexpand = {
              ...item,
              ttm: exist[0]["ttm"],
              f_t1: exist[0]["f_t1"],
              f_t2: exist[0]["f_t2"],
              f_t3: exist[0]["f_t3"],
              f_t4: exist[0]["f_t4"],
              children: item.children,
            }
            const dataexpandnew = dataexpandtri.filter((children: DatatypeTrimestre) => children.nombres !== item.nombres)
            setdataexpandtri(dataexpandnew)
            return datosexpand
          }
        }
        if (item.children !== undefined && item.children.length > 0) {
          item.children.map((element: any, key1: any) => {
            if (element.nombres === record.nombres) {
              const exist = dataexpandtri.filter((children: any) => children.nombres === record.nombres)
              if (exist.length > 0) {
                item.children[key1] = {
                  ...element,
                  ttm: exist[0]["ttm"],
                  f_t1: exist[0]["f_t1"],
                  f_t2: exist[0]["f_t2"],
                  f_t3: exist[0]["f_t3"],
                  f_t4: exist[0]["f_t4"],
                }
                const dataexpandnew = dataexpandtri.filter((children: DatatypeAnual) => children.nombres !== record.nombres)
                setdataexpandtri(dataexpandnew)
              }
            }
            return element
          })
        }
        return item
      })
      
      setdataTrimestral(updatedDataexpand)
      //console.log(`Se ha contraído la fila con clave: ${record.key}`)
      // Realiza acciones adicionales según sea necesario
    }
  }


  const btnAnual = async () => {
    const year = moment().format("YYYY")
    const res = await fetch(
      `${url}fapifsg-pr/contabilidad/estadoingreso/getestado-ingreso-prueba`,
      optionsPost({ year1: +year - 1, year2: +year - 2, year3: +year - 3 })
    ).then((data) => data.json())
    let datanew: any[] = []
    let ing_year1: number, ing_year2: number, ing_year3: number, ing_ttm: number
    res.forEach((item: any, key: any) => {
      let dato
      let res1 = datanew.filter((itemdata: any) => itemdata.Cuenta_Mayor === item.Cuenta_Mayor)
      if (item.clave !== "Gastos" && res1.length < 1) {
        if (item.Cuenta === "Ingresos") {
          ing_ttm = item.TTM
          ing_year1 = item.year1
          ing_year2 = item.year2
          ing_year3 = item.year3
        }

        dato = {
          key: key,
          Cuenta_Mayor: (item.Cuenta_Mayor).trim(),
          nombres:( item.Cuenta).trim(),
          ttm: item.TTM,
          t_cta_principal: "",
          f_a1: item.year1,
          t_cta_principala1: "",
          f_a2: item.year2,
          t_cta_principala2: "",
          f_a3: item.year3,
          t_cta_principala3: "",
        }
        datanew.push(dato)
      } else if (item.clave === "Gastos") {
        let updatedData = datanew.map((item2) => {
          if (item2.Cuenta_Mayor === "Total Gastos") {
            let resgasto = item2.children?.filter((dataitem: DatatypeAnual) => dataitem.nombres === item.Cuenta_Mayor.trim())
            if (resgasto === undefined || resgasto.length < 1) {
              let datayear = res.filter((year: any) => (year.Cuenta_Mayor).trim() === (item.Cuenta_Mayor).trim())
              let year1 = datayear.reduce((total: any, fila: any) => total + fila.year1, 0)//.toFixed(2)
              let year2 = datayear.reduce((total: any, fila: any) => total + fila.year2, 0)//.toFixed(2)
              let year3 = datayear.reduce((total: any, fila: any) => total + fila.year3, 0)//.toFixed(2)
              let ttm = datayear.reduce((total: any, fila: any) => total + fila.TTM, 0)//.toFixed(2)
              let datos = {
                key: key,
                Cuenta_Mayor: (item.Cuenta_Mayor).trim(),
                nombres: item.Cuenta_Mayor.trim(),
                ttm: ttm === "0.00" ? "" :ttm,
                t_cta_principal: "",
                f_a1: year1 === "0.00" ? "" : year1,
                t_cta_principala1: ing_year1,
                f_a2: year2 === "0.00" ? "" : year2,
                t_cta_principala2: ing_year2,
                f_a3: year3 === "0.00" ? "" : year3,
                t_cta_principala3: ing_year3,
                children: [
                  {
                    key: key + "111",
                    Cuenta_Mayor: (item.Cuenta_Mayor).trim(),
                    nombres: (item.Cuenta).trim(),
                    ttm: item.TTM,
                    t_cta_principal: "",
                    f_a1: item.year1,
                    t_cta_principala1: year1,
                    f_a2: item.year2,
                    t_cta_principala2: year2,
                    f_a3: item.year3,
                    t_cta_principala3: year3,
                  },
                ],
              }
              if (item2.children) {
                item2.children.push(datos)
              } else {
                item2.children = [datos]
              }
            } else {
              item2.children.forEach((children: any, key1: any) => {
                if (children.Cuenta_Mayor === (item.Cuenta_Mayor).trim()) {
                  dato = {
                    key: key,
                    Cuenta_Mayor: (item.Cuenta_Mayor).trim(),
                    nombres: (item.Cuenta).trim(),
                    ttm: item.TTM,
                    t_cta_principal: "",
                    f_a1: item.year1,
                    t_cta_principala1: children.f_a1,
                    f_a2: item.year2,
                    t_cta_principala2: children.f_a2,
                    f_a3: item.year3,
                    t_cta_principala3: children.f_a3,
                  }
                  item2.children[key1].children.push(dato)
                }
              })
            }
            return { ...item2, children: item2.children }
          }
          return item2
        })
        datanew = updatedData
      } else {
        let updatedData = datanew.map((item2) => {
          if (item2.Cuenta_Mayor === item.Cuenta_Mayor) {
            dato = {
              key: key,
              Cuenta_Mayor:(item.Cuenta_Mayor).trim(),
              nombres: (item.Cuenta).trim(),
              ttm: item.TTM,
              t_cta_principal: "",
              f_a1: item.year1,
              t_cta_principala1: item2.f_a1,
              f_a2: item.year2,
              t_cta_principala2: item2.f_a2,
              f_a3: item.year3,
              t_cta_principala3: item2.f_a3,
            }
            if (item2.children) {
              item2.children.push(dato)
            } else {
              item2.children = [dato]
            }
            //let datos={...item2.children, }
            return { ...item2, children: item2.children }
          }
          return item2
        })
        datanew = updatedData
      }
    })
    console.log(datanew)
   
    setvisible(true)
    setdataAnual(datanew)
    const newColumn: ColumnsType<DatatypeAnual> = [
      {
        title: (
          <>
            <Row>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>Desglose</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        dataIndex: "nombres",
        className: "bordercolumn fomattext",
        width: "400px",
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>TTM</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        dataIndex: "ttm",
        align: "right",
        className:"fontsize",
        render: (item, key) => {
          let numeroFormateado: string = ''
          if(item){
          numeroFormateado=(item).toLocaleString('en-US',{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          }
          return <> {<label>{numeroFormateado}</label>}</>
        },
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>%</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        width: "20px",
        className: "bordercolumn fontsize",
        align: "right",
        render: (item, key) => {
          let res = ""
          if (key.ttm && key.nombres !== "Ingresos") {
            res = ((key.ttm / ing_ttm) * 100).toFixed(4)
          }
          return <> {<label>{res}</label>}</>
        },
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>{+year - 1}</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        dataIndex: "f_a1",
        align: "right",
        className:"fontsize",
        render: (item, key) => {
          let numeroFormateado: string = ''
          if(item){
          numeroFormateado=(item).toLocaleString('en-US',{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          }
          return <> {<label>{numeroFormateado}</label>}</>
        },
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>%</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        width: "20px",
        className: "bordercolumn fontsize",
        align: "right",
        render: (item, key: DatatypeAnual) => {
          let res = ""
          /*    if(key.nombres==='GASTOS OPERACIONALES'){
                res=((key.f_a1/+ing_year1)*100*-1).toFixed(3)
              } */
          if (key.f_a1 && key.nombres !== "Ingresos") {
            res = ((key.f_a1 / ing_year1) * 100).toFixed(4)
          }
          return <> {<label>{res}</label>}</>
        },
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>{+year - 2}</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        dataIndex: "f_a2",
        className:"fontsize",
        align: "right",
        render: (item, key) => {
          let numeroFormateado: string = ''
          if(item){
          numeroFormateado=(item).toLocaleString('en-US',{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          }
          return <> {<label>{numeroFormateado}</label>}</>
        },
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>%</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        width: "20px",
        className: "bordercolumn fontsize",
        align: "right",
        render: (item, key: DatatypeAnual) => {
          let res = ""
          /*    if(key.nombres==='GASTOS OPERACIONALES'){
                  res=((key.f_a2/+ing_year2)*100*-1).toFixed(3)
                } */

          if (key.f_a2 && key.nombres !== "Ingresos") {
            res = ((key.f_a2 / ing_year2) * 100).toFixed(4)
          }
          return <> {<label>{res}</label>}</>
        },
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>{+year - 3}</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        dataIndex: "f_a3",
        align: "right",
        className:"fontsize",
        render: (item, key) => {
          let numeroFormateado: string = ''
          if(item){
          numeroFormateado=(item).toLocaleString('en-US',{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          }
          return <> {<label>{numeroFormateado}</label>}</>
        },
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>%</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        width: "20px",
        align: "right",
        className:"fontsize",
        render: (item, key: DatatypeAnual) => {
          let res = ""
          if (key.f_a3 && key.nombres !== "Ingresos") {
            res = ((key.f_a3 / ing_year3) * 100).toFixed(4)
          }
          return <> {<label>{res}</label>}</>
        },
      },
    ]
    setT_anual(newColumn)


  }

  const btnTrimestral = async () => {
    let t1:String, t2:String, t3:String, t4:String

    if(+moment().format("MM")>3 && +moment().format("MM")<=6){ 
      t1=moment().format("YYYY")+'03'
      t2=(+moment().format("YYYY")-1)+'12'
      t3=(+moment().format("YYYY")-1)+'09'
      t4=(+moment().format("YYYY")-1)+'06'
    }else if(+moment().format("MM")>6 && +moment().format("MM")<=9){ 
      t1=moment().format("YYYY")+'06'
      t2=moment().format("YYYY")+'03'
      t3=(+moment().format("YYYY")-1)+'12'
      t4=(+moment().format("YYYY")-1)+'9'
    }else if(+moment().format("MM")>9 && +moment().format("MM")<=12){ 
      t1=moment().format("YYYY")+'09'
      t2=moment().format("YYYY")+'06'
      t3=moment().format("YYYY")+'03'
      t4=(+moment().format("YYYY")-1)+'12'
    }else{
      t1=(+moment().format("YYYY")-1)+'12'
      t2=(+moment().format("YYYY")-1)+'09'
      t3=(+moment().format("YYYY")-1)+'06'
      t4=(+moment().format("YYYY")-1)+'03'
    }
    const res = await fetch(
      `${url}fapifsg-pr/contabilidad/estadoingreso/getestado-ingreso-trimestre-prueba`,
      optionsPost({ t1: t1, t2: t2, t3: t3, t4: t4})
    ).then((data) => data.json()) 
    let datanew: any[] = []
    setvisible(false)
    let ing_t1: number, ing_t2: number, ing_t3: number, ing_t4: number, ing_ttm: number

    res.forEach((item:any, key:any) =>{
      let dato
      let res1=datanew.filter((itemdata:any)=>itemdata.Cuenta_Mayor===item.Cuenta_Mayor.trim())
      if(item.clave!=='Gastos' &&res1.length<1){
        if(item.Cuenta === 'Ingresos'){
          ing_ttm=item.TTM
          ing_t1=item.t1
          ing_t2=item.t2
          ing_t3=item.t3
          ing_t4=item.t4
        }
        
        dato={
          key: key,
          nombres: (item.Cuenta).trim(),
          Cuenta_Mayor: (item.Cuenta_Mayor).trim(),
          ttm: item.TTM,
          f_t1: item.t1,
          f_t2: item.t2,
          f_t3: item.t3,
          f_t4: item.t4,
        }
        datanew.push(dato)
      }else if(item.clave === 'Gastos'){

        let updatedData = datanew.map((item2) => {
          if (item2.Cuenta_Mayor === 'Total Gastos'){
            let resgasto=item2.children?.filter((dataitem:DatatypeAnual)=>dataitem.nombres === (item.Cuenta_Mayor).trim())
            if(resgasto === undefined ||resgasto.length<1){
              let datayear=res.filter((year:any)=>(year.Cuenta_Mayor.trim()) === (item.Cuenta_Mayor).trim())
              let t1=datayear.reduce((total:any, fila:any) => total + fila.t1, 0)//.toFixed(2)
              let t2=datayear.reduce((total:any, fila:any) => total + fila.t2, 0)//.toFixed(2)
              let t3=datayear.reduce((total:any, fila:any) => total + fila.t3, 0)//.toFixed(2)
              let t4=datayear.reduce((total:any, fila:any) => total + fila.t4, 0)//.toFixed(2)
              let ttm=datayear.reduce((total:any, fila:any) => total + fila.TTM, 0)//.toFixed(2)
              let datos={
                key: key,
                Cuenta_Mayor:(item.Cuenta_Mayor.trim()),
                nombres: (item.Cuenta_Mayor).trim(),
                ttm: ttm === "0.00" ? "" :ttm,
                f_t1: t1 === "0.00" ? "" :t1,
                f_t2: t2  === "0.00" ? "" :t2,
                f_t3: t3  === "0.00" ? "" :t3,
                f_t4: t4  === "0.00" ? "" :t4,
                children:[{
                  key: key+'11',
                  nombres: (item.Cuenta).trim(),
                  Cuenta_Mayor: (item.Cuenta_Mayor).trim(),
                  ttm: item.TTM,
                  f_t1: item.t1,
                  f_t2: item.t2,
                  f_t3: item.t3,
                  f_t4: item.t4,
                }]
              }
              if(item2.children){
                item2.children.push(datos)
              }else{
                item2.children=[datos]
              }        
            }else {
              item2.children.forEach((children:any, key1:any)=>{
                if(children.Cuenta_Mayor === (item.Cuenta_Mayor).trim()){
                  dato={
                    key: key,
                    Cuenta_Mayor:(item.Cuenta_Mayor).trim(),
                    nombres: (item.Cuenta.trim()),
                    ttm: item.TTM,
                    f_t1: item.t1,
                    f_t2: item.t2,
                    f_t3: item.t3,
                    f_t4: item.t4,
                  }         
                  item2.children[key1].children.push(dato)
                }
              })
            }
            return { ...item2, children:item2.children}
          }
          return item2
        })
        datanew=updatedData
      }
      else{
        let updatedData = datanew.map((item2) => {
          if (item2.Cuenta_Mayor === item.Cuenta_Mayor){
            dato={
              key: key+'111',
              Cuenta_Mayor:(item.Cuenta_Mayor).trim(),
              nombres: (item.Cuenta).trim(),
              ttm: item.TTM===null?'':item.TTM,
              f_t1: item.t1,
              f_t2: item.t2,
              f_t3: item.t3,
              f_t4: item.t4,
            }
            if(item2.children){
              item2.children.push(dato)
            }else{
              item2.children=[dato]
            }
              //let datos={...item2.children, }
              return { ...item2, children:item2.children}
          }
          return item2
        })
        datanew=updatedData
     }});
   
     setdataTrimestral(datanew)

    const newColumn: ColumnsType<DatatypeTrimestre> = [
      {
        title: (
          <>
            <Row>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>Desglose</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        dataIndex: "nombres",
        className: "bordercolumn fomattext",
        width: "400px",
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>TTM</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        dataIndex: "ttm",
        className:"fontsize",
        align: "right",
        render: (item, key) => {
          let numeroFormateado: string = ''
          if(item){
          numeroFormateado=(item).toLocaleString('en-US',{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          }
          return <> {<label>{numeroFormateado}</label>}</>
        },
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px"}}>%</Title>
              </Col>
            </Row>
          </>
        ),
        width: "20px",
        className: "bordercolumn fontsize",
        dataIndex: "f_a1",
        align: "right",
        render: (item, key: DatatypeTrimestre) => {
          let res = ""
          if (key.ttm && key.nombres !== "Ingresos") {
            res = ((key.ttm / ing_ttm) * 100).toFixed(4)
          }
          return <> {<label>{res}</label>}</>
        },
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>{t1}</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        className:"fontsize",
        dataIndex: "f_t1",
        align: "right",
        render: (item, key) => {
          let numeroFormateado: string = ''
          if(item){
          numeroFormateado=(item).toLocaleString('en-US',{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          }
          return <> {<label>{numeroFormateado}</label>}</>
        },
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>%</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        className: "bordercolumn fontsize",
        align: "right",
        render: (item, key: DatatypeTrimestre) => {
          let res = ""
          if (key.f_t1 && key.nombres !== "Ingresos") {
            res = ((key.f_t1 / ing_t1) * 100).toFixed(4)
          }
          return <> {<label>{res}</label>}</>
        },
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>{t2}</Title>
              </Col>
            </Row>
          </>
        ),
        className:"fontsize",
        dataIndex: "f_t2",
        align: "right",
        render: (item, key) => {
          let numeroFormateado: string = ''
          if(item){
          numeroFormateado=(item).toLocaleString('en-US',{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          }
          return <> {<label>{numeroFormateado}</label>}</>
        },
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>%</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        width: "20px",
        className: "bordercolumn fontsize",
        align: "right",
        render: (item, key: DatatypeTrimestre) => {
          let res = ""
          if (key.f_t2 && key.nombres !== "Ingresos") {
            res = ((key.f_t2 / ing_t2) * 100).toFixed(4)
          }
          return <> {<label>{res}</label>}</>
        },
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>{t3}</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        className:"fontsize",
        dataIndex: "f_t3",
        align: "right",
        render: (item, key) => {
          let numeroFormateado: string = ''
          if(item){
          numeroFormateado=(item).toLocaleString('en-US',{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          }
          return <> {<label>{numeroFormateado}</label>}</>
        },
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>%</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        width: "20px",
        align: "right",
        className:"bordercolumn fontsize",
        render: (item, key: DatatypeTrimestre) => {
          let res = ""
          if (key.f_t3 && key.nombres !== "Ingresos") {
            res = ((key.f_t3 / ing_t3) * 100).toFixed(4)
          }
          return <> {<label>{res}</label>}</>
        },
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>{t4}</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        className:"fontsize",
        dataIndex: "f_t4",
        align: "right",
        render: (item, key) => {
          let numeroFormateado: string = ''
          if(item){
          numeroFormateado=(item).toLocaleString('en-US',{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          }
          return <> {<label>{numeroFormateado}</label>}</>
        },
      },
      {
        title: (
          <>
            <Row style={{ textAlign: "center" }}>
              <Col md={24}>
                <Title style={{ fontSize: "14px" }}>%</Title>
              </Col>
            </Row>{" "}
          </>
        ),
        width: "20px",
        className: "bordercolumn fontsize",
        align: "right",
        render: (item, key: DatatypeTrimestre) => {
          let res = ""
          if (key.f_t4 && key.f_t4!==null&& key.nombres !== "Ingresos") {
            res = ((key.f_t4 / ing_t4) * 100).toFixed(4)
          }
          return <> {<label>{res}</label>}</>
        },
      },
    ]
    setT_trimestral(newColumn)
  }

  useEffect(() => {
    btnAnual()
  }, [])

  return {
    t_anual,
    t_trimestral,
    visible,
    dataTrimestral,
    btnAnual,
    btnTrimestral,
    dataAnual,
    handleExpand,
    handleExpandTri
  }
}
