import { Form, Space, Input, Button, Row, Col, Divider } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

export const Referencias = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
      <Form.List
        name='referencias'
        initialValue={[{ nombres_referencias: "", direccion_referencia: "", telefono_referencia: "" }]}
      >
        {(fields = [], { add, remove }) => {
          return (
            <>
              <h3 style={{ textAlign: "center", color: "var(--primary)" }}>
                Añada al menos tres referencias laborales(No incluya familiares)
              </h3>
              {fields.map(({ key, name, ...restField }) => {
                return (
                  <Row wrap={true} key={key}>
                    <Divider>{name + 1}</Divider>
                    <Col xs={24} md={24} lg={24}>
                      <Space key={key} style={{ display: "flex", marginBottom: 12 }} align='baseline'>
                        <Row wrap={true}>
                          <Col xs={24} xl={8}>
                            <Form.Item
                              {...restField}
                              name={[name, "nombres_referencias"]}
                              label={`Nombres de la referencia #${name + 1}`}
                              style={{ width: "97%" }}
                            >
                              <Input placeholder='Escriba los nombres completos' />
                            </Form.Item>
                          </Col>
                          <Col xs={24} xl={8}>
                            <Form.Item
                              {...restField}
                              name={[name, "direccion_referencia"]}
                              label={`Dirección de la referencia #${name + 1}`}
                              style={{ width: "97%" }}
                            >
                              <Input placeholder='Escriba los nombres completos' />
                            </Form.Item>
                          </Col>
                          <Col xs={24} xl={8}>
                            <Form.Item
                              {...restField}
                              name={[name, "telefono_referencia"]}
                              label={`Teléfono de la referencia #${name + 1}`}
                              style={{ width: "97%" }}
                            >
                              <Input placeholder='Escriba los nombres completos' maxLength={10} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <MinusCircleOutlined
                          onClick={() => {
                            if (name >= 0) return remove(name);
                          }}
                        />
                      </Space>
                    </Col>
                  </Row>
                );
              })}
              <Form.Item>
                <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                  Agregar una nueva referencia
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </div>
  );
};
