import { useCallback, useContext, useEffect, useState } from "react"
import { LayoutContext } from "context/LayoutContext"
import { url } from "../../config/constants"
import { useLocalStorage } from "../../hooks/otros/useLocalStorage"
import { useForm } from "antd/lib/form/Form"
import { notification } from "antd"
import { UserContext } from "context/UserContext"
import { useLayout } from "hooks/otros/useLayout"

interface DataProcesos {
  value: number
  label: string
}

interface Error_Proceso {
  name: string[]
  errors: string[]
  warnings: string[]
}

const breadcrumbs = [{ Label: "Procesos", Url: "/procesos"  }, { Label: "Crear" }]
const openKeys = ["Procesos"]
const selectedKeys = "Procesos/Crear Procesos"

export function useCrearProcesos() {
  const { setShowBack, setBreadCrumbs } = useContext(LayoutContext)
  const [ procesos, setProcesos ] = useState<DataProcesos[]>([])
  const [ form ] = useForm()
  const data_error = ["descripcion_procesos", "tipo_procesos"]
  const { userData: dataUser } = useContext(UserContext)
  const [loader, setLoader] = useState(false)

  const { optionsGet, optionsPost } = useLocalStorage()

  useContext(LayoutContext)
  useLayout(breadcrumbs, openKeys, selectedKeys)

  useEffect(() => {
    setShowBack(true)
    if (window.screen.width <= 992) return
  }, [setShowBack, setBreadCrumbs])

  const sendData = () => {
    setLoader(true)
    form
      .validateFields()
      .then(async data => {
        data.id = dataUser?.id
        const respuesta = await fetch(url + "procesos/guardar-proceso", optionsPost(data))
        let json = await respuesta.json()
        if (json.msg) {
          notification.success({
            message: "Info",
            description: String.raw`${json.data}. Para Visualizar el Proceso dirigirse a 'Ver Procesos>En Ejecución'`,
            placement: "bottomRight",
          })
          form.resetFields()
          setLoader(false)
        }
      })
      .catch(error => {
        setLoader(false)
        error.errorFields.forEach((el: Error_Proceso) => {
          if (data_error.includes(el.name[0])) {
            notification.error({
              message: "Error",
              description: `${el.errors[0]}`,
              placement: "bottomRight",
            })
          }
          return
        })
      })
  }

  const getEstados = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "procesos/tipos-procesos", optionsGet).then(data => data.json())
      if (respuesta.msg) {
        const { data } = respuesta
        setProcesos(data)
      } else {
        return { msg: "Ocurrio un error", status: false }
      }
    } catch (e) {
      return { msg: "no_conexion", status: false }
    }
  }, [optionsGet])

  useEffect(() => {
    getEstados()
  }, [getEstados])

  return {
    procesos,
    form,
    sendData,
    loader,
  }
}
