import { Button, TableColumnsType, Tag } from "antd";
import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import {  useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { url } from "config/constants";
import { optionsPost, swalSuccess } from "config/methods";
import { DefaultOptionType } from "antd/lib/select";
import moment from "moment";
import { llave } from "config/constants"
import AES from "crypto-js/aes"

type Vouchers = {
  key: number;
  bodega: string;
  usuario: string;
  sesion: number;
  caja: number;
  tipo_credito: string;
  cuotas: number;
  valor: number;
  fecha_inicio: string;
  fecha_fin: string;
  lote: string;
  referencia: string;
  estado: "PE" | "PP" | "PR" | "CM" | "AN" | "RE";
  numero_aprobacion: string;
  tid: string;
  mid: string;
  emisor: string;
  cod_resultado: string;
  texto_resultado: string;
  log_proceso: number;
};

const ESTADOS = {
  PE: <Tag color='blue'>PENDIENTE</Tag>,
  PP: <Tag color='cyan'>PROCESO PARCIAL</Tag>,
  PR: <Tag color='green'>PROCESADO</Tag>,
  CM: <Tag color='magenta'>CAMBIADO</Tag>,
  RE: <Tag color='red'>RECHAZADO</Tag>,
  AN: <Tag>ANULADO</Tag>,
};

export default function useRegistro() {
  const bodegas = useLoaderData() as DefaultOptionType[];
  const params = useParams();
  const navigate = useNavigate();
  const [desde, setDesde] = useState(moment()/* .subtract(1, "days" */)
  const [bodega, setBodega] = useState<number>()
  const [valor, setValor] = useState<number | null>()
  const vouchers = useLoaderData() as Vouchers[]
  const { getColumnSearchPropsNew } = useSearchTableNew<Vouchers>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedCode, setSelectedCode] = useState<number>()
  const [valueLote, setvalueLote] = useState("")
  const [valueReferencia, setvalueReferencia] = useState("")
  const [valueNumAprobacion, setvalueNumAprobacion] = useState("")
  const [disabled, setdisabled] = useState(false)
  const [MostrarAlert, setMostrarAlert] = useState(false)
  const [message, setmessage] = useState("")
  const [valueFarmacia, setvalueFarmacia] = useState("")
  const [valueFecha, setvalueFecha]=useState("")
  const [valueValor,setvalueValor]=useState("")
  const [valueEstado, setvalueEstado]=useState("")
  const [disabledEstado, setdisabledEstado]=useState(false)
  const [disabledButton, setdisabledButton]=useState(false)
 
  const handleClickButton = (key: Vouchers) => {
    setMostrarAlert(false)
    const fecha =
      moment(key.fecha_inicio, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY") === "Fecha inválida"
        ? ""
        : moment(key.fecha_inicio, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY").toString();
    setvalueValor(key.valor.toString());
    setvalueFarmacia(key.bodega);
    setvalueFecha(fecha);
    setSelectedCode(key.key);
    setOpenModal(true);
    setvalueLote(key.lote);
    setvalueReferencia(key.referencia);
    setvalueEstado(key.estado === "AN" ? "PP" : key.estado);
    setvalueNumAprobacion(key.numero_aprobacion);
    setdisabled(key.estado === "AN" || key.estado==='PE'? false : true);
    setdisabledEstado(key.estado === "AN" || key.estado === "PP" || key.estado ==='PE'? false : true);
    setdisabledButton(key.estado === "AN" || key.estado === "PP" || key.estado ==='PE' ? false : true);

  };

  const onchangeEstado = (value: string) => {
    setMostrarAlert(false)
    setvalueEstado(value);
  };

  const columnsVouchers: TableColumnsType<Vouchers> = [
    {
      title: "Fecha Inicio",
      dataIndex: "fecha_inicio",
      key: "fecha_inicio",
      align: "center",
      render: (value) =>
        moment(value, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY") === "Fecha inválida"
          ? ""
          : moment(value, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY").toString(),
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      align: "right",
      render: (value) => value.toFixed(2),
      ...getColumnSearchPropsNew("valor"),
    },
    { title: "Lote", dataIndex: "lote", key: "lote", align: "right", ...getColumnSearchPropsNew("lote"), },
    { title: "Referencia", dataIndex: "referencia", key: "referencia", align: "right", ...getColumnSearchPropsNew("referencia"), },
    { title: "Número aprobación", dataIndex: "numero_aprobacion", key: "numero_aprobacion", align: "right" },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      onFilter: (value: string | number | boolean, record: Vouchers) => {
        // Verifica si value es un string antes de comparar
        if (typeof value === "string") {
          return value === record.estado;
        }
        return false; // O ajusta según tus necesidades
      },
      render: (value) => ESTADOS[value as keyof typeof ESTADOS],
      filters: [
        { text: "Pendiente", value: "PE" },
        { text: "Proceso parcial", value: "PP" },
        { text: "Procesado", value: "PR" },
        { text: "Anulado", value: "AN" },
        { text: "Rechazado", value: "RE" },
        { text: "Cambiado", value: "CM" },
      ],
    },
    { title: "Bodega", dataIndex: "bodega", key: "bodega", ...getColumnSearchPropsNew("bodega") },
    { title: "Usuario", dataIndex: "usuario", key: "usuario", ...getColumnSearchPropsNew("usuario") },
    { title: "Sesión", dataIndex: "sesion", key: "sesion" },
    { title: "Caja", dataIndex: "caja", key: "caja" },
    {
      title: "Tipo crédito",
      dataIndex: "tipo_credito",
      key: "tipo_credito",
      filters: [...new Set(vouchers.map(({ tipo_credito }) => tipo_credito))].map((tipo) => ({ text: tipo, value: tipo })),
      onFilter: (value: string | number | boolean, record: Vouchers) => {
        // Verifica si value es un string antes de comparar
        if (typeof value === "string") {
          return value === record.tipo_credito;
        }
        return false; // O ajusta según tus necesidades
      },
    },
    { title: "Cuotas", dataIndex: "cuotas", key: "cuotas", align: "right" },

    /*  {
      title: "Fechas",
      key: "fechas",
      children: [
        { title: "Inicio", dataIndex: "fecha_inicio", key: "fecha_inicio", align: "center" },
        { title: "Fin", dataIndex: "fecha_fin", key: "fecha_fin", align: "center" },
      ],
    }, */

    { title: "TID", dataIndex: "tid", key: "tid" },
    { title: "MID", dataIndex: "mid", key: "mid" },
    { title: "Emisor", dataIndex: "emisor", key: "emisor" },
    { title: "Codigo resultado", dataIndex: "cod_resultado", key: "cod_resultado" },
    { title: "Texto resultado", dataIndex: "texto_resultado", key: "texto_resultado" },
    { title: "Log proceso", dataIndex: "log_proceso", key: "log_proceso" },
    {
      title: "",
      //dataIndex: "key",
      key: "codigo",
      render: (value) => (
        <Button
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          type='text'
          icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
          onClick={() => handleClickButton(value)}
        />
      ),
      fixed: "right",
    },
  ];



  const handleInputChange = (e: any, nombrefuncion: Function) => {
    setMostrarAlert(false);
    setmessage("");
    let newValue = e.target.value;
    if (/^[0-9]{0,10}$/.test(newValue)) {
      nombrefuncion(newValue);
    }
  };

  const btn_Guardar = async () => {
    if (valueEstado==='PP'&&(!valueLote || Number(valueLote) <= 0 || !valueReferencia || Number(valueReferencia) <= 0 )) {
      setmessage("Faltan Datos para Guardar"); //o los datos ingresados son iguales a 0"
      setMostrarAlert(true);
      return;
    }
    const count = vouchers.filter(
      (item) =>
        item.lote === valueLote &&
        item.referencia === valueReferencia &&
        item.bodega === valueFarmacia &&
        item.key !== selectedCode
    );
    if (count.length > 0 && valueEstado !== "AN") {
      setmessage("Ya existe un registro con el número de lote y referencia"); //o los datos ingresados son iguales a 0"
      setMostrarAlert(true);
      return;
    }

    try {
      const res = await fetch(
        `${url}fapifsg-pr/vouchers/update-voucher`,
        optionsPost({
          lote: valueLote,
          referencia: valueReferencia,
          numero_aprobacion: valueNumAprobacion,
          voucher: selectedCode,
          estado: valueEstado,
        })
      ); //.then((data) => data.json());

      if (res.status === 200) {
        setOpenModal(false);
        swalSuccess({ text: "Se guardó exitosamente" });
        const encBodega = bodega ? encodeURIComponent(AES.encrypt(bodega.toString(), llave).toString()) : 0
        navigate(`/sistemas/registro-vouchers/${desde.format("YYYY-MM-DD")}/${valor ?? 0}/${encBodega}`)
      }
    } catch (error) {
      console.log("Error: " + error);
    }
  };


  const onchagedate=(date: any, dates: any)=>{
    //console.log(dates)
    setDesde(date ?? moment().subtract(1, "days"))
  }


  return {
    columnsVouchers,
    vouchers,
    openModal,
    setOpenModal,
    valueLote,
    valueNumAprobacion,
    valueReferencia,
    disabled,
    handleInputChange,
    setvalueLote,
    setvalueNumAprobacion,
    setvalueReferencia,
    MostrarAlert,
    message,
    btn_Guardar,
    desde,
    setDesde,
    onchagedate,
    bodega,
    setBodega,
    valor,
    setValor,
    bodegas,
    params,
    navigate,
    valueFarmacia,
    valueFecha,
    valueValor,
    valueEstado,
    disabledEstado,
    onchangeEstado,
    disabledButton,
  };
}
