import { useCallback, useContext, useEffect, useState } from "react";

import { Button } from "antd";
import { EyeOutlined, HomeOutlined } from "@ant-design/icons";

import { useModalFormCrearReembolso } from "hooks/reembolsos/useModalFormCrearReembolso";
import { useButtonRightListCrearReembolso } from "hooks/reembolsos/useButtonRightListCrearReembolso";
import { useFetchInFunction } from "hooks/otros/useFetch";
import { useNavigate, useParams } from "react-router-dom";

import { ModalFormInputsElements } from "context/ModalFormContext";
import { LayoutContext } from "context/LayoutContext";

import { llave, optionsPOST, url_interna } from "config/constants";

import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/es";

import type { Facturas, NotaVentas, Recibos } from "hooks/reembolsos/useModalFormCrearReembolso";
import type { DatePickerProps, InputProps, SelectProps } from "antd";
// import { useBreadCrumbs } from "hooks/useBreadCrumbs"

interface fetchRecibos {
  Cod_Documento: number;
  Documento: string;
  Total: number;
  Fecha: string;
  Detalle: string;
  Cod_Pais: number;
  Cod_Provincia: number | null;
  Cod_Ciudad: number | null;
  Cod_Tipo_Pago: string;
  Cod_Tipo_Gasto: number;
  Razon_Social: string;
  Ruc: string;
}
interface fetchNotaVentas extends fetchRecibos {
  Clave_Acceso: string;
}

interface fetchFacturas extends fetchNotaVentas {
  Base_0: number;
  Base_12: number;
  Iva: number;
  Servicio_10: number;
}

interface fetchDataReembolso {
  msg: string;
  data: {
    Cod_Reembolso: number;
    Justificacion: string;
    Facturas: fetchFacturas[];
    NotaVentas: fetchNotaVentas[];
    Recibos: fetchRecibos[];
  };
}

const actionsList = [{ icon: <EyeOutlined />, text: "Ver documento" }];

export function useVerReembolso() {
  const navigate = useNavigate();

  const { reembolso } = useParams();

  const {
    modalProps,
    setModalProps,
    inputsState,
    setInputsState,
    forms,
    resetFields,
    cargarCiudades,
    inputsModal,
  } = useModalFormCrearReembolso();

  const [setModalPropsFactura, setModalPropsNotaVenta, setModalPropsRecibo] = setModalProps;

  const { lists, setLists, contentDocuments } = useButtonRightListCrearReembolso();

  const [JustificacionValue, setJustificacionValue] = useState("");

  const [setListFacturas, setListNotaVentas, setListRecibos] = setLists;

  const { setBreadCrumbs, setOpenKeys, setSelectedKeys } = useContext(LayoutContext);

  useEffect(() => {
    setBreadCrumbs((state) => [
      { Label: <HomeOutlined />, Url: "/" },
      { Label: "Reembolsos" },
      {
        Label: state[2].Label,
        Url:
          "/reembolsos/" +
          (state[2].Label as string)[0].toLocaleLowerCase() +
          (state[2].Label as string).substring(1),
      },
      { Label: "Ver reembolso" },
    ]);
  }, [setBreadCrumbs]);

  useEffect(() => {
    setOpenKeys(["Reembolsos", "Reembolsos/Ver Reembolsos"]);
    setSelectedKeys([]);
  }, [setOpenKeys, setSelectedKeys]);

  useEffect(() => {
    let eliminado: any;
    if (inputsModal[0].find((element) => element.elementType === "upload")) {
      eliminado = inputsModal[0].shift();
    }
    inputsModal[0].forEach((element) => {
      if (element.elementType) {
        element.inputProps ??= {};
        if (element.elementType === "select") {
          (element.inputProps as SelectProps).disabled = true;
        } else if (element.elementType === "datePicker") {
          (element.inputProps as DatePickerProps).disabled = true;
        } else {
          (element.inputProps as InputProps).readOnly = true;
        }
      } else {
        (element as unknown as Array<ModalFormInputsElements>).forEach((element2) => {
          element2.elementType = "text";
          element2.inputProps ??= {};
          (element2.inputProps as InputProps).readOnly = true;
        });
      }
    });
    inputsModal[1].forEach((element) => {
      element.inputProps ??= {};
      if (element.elementType === "select") {
        (element.inputProps as SelectProps).disabled = true;
      } else if (element.elementType === "datePicker") {
        (element.inputProps as DatePickerProps).disabled = true;
      } else {
        (element.inputProps as InputProps).readOnly = true;
      }
    });
    inputsModal[2].forEach((element) => {
      element.inputProps ??= {};
      if (element.elementType === "select") {
        (element.inputProps as SelectProps).disabled = true;
      } else if (element.elementType === "datePicker") {
        (element.inputProps as DatePickerProps).disabled = true;
      } else {
        (element.inputProps as InputProps).readOnly = true;
      }
    });
    setInputsState(() => [...inputsModal]);

    return () => {
      inputsModal[0].unshift(eliminado);
      inputsModal[0].forEach((element) => {
        if (element.elementType) {
          element.inputProps ??= {};
          if (element.elementType === "select") {
            (element.inputProps as SelectProps).disabled = undefined;
          } else if (element.elementType === "datePicker") {
            (element.inputProps as DatePickerProps).disabled = undefined;
          } else {
            (element.inputProps as InputProps).readOnly = undefined;
          }
        } else {
          (element as unknown as Array<ModalFormInputsElements>).forEach((element2) => {
            element2.elementType = "number";
            element2.inputProps ??= {};
            (element2.inputProps as InputProps).readOnly = undefined;
          });
        }
      });
      inputsModal[1].forEach((element) => {
        element.inputProps ??= {};
        if (element.elementType === "select") {
          (element.inputProps as SelectProps).disabled = undefined;
        } else if (element.elementType === "datePicker") {
          (element.inputProps as DatePickerProps).disabled = undefined;
        } else {
          (element.inputProps as InputProps).readOnly = undefined;
        }
      });
      inputsModal[2].forEach((element) => {
        element.inputProps ??= {};
        if (element.elementType === "select") {
          (element.inputProps as SelectProps).disabled = undefined;
        } else if (element.elementType === "datePicker") {
          (element.inputProps as DatePickerProps).disabled = undefined;
        } else {
          (element.inputProps as InputProps).readOnly = undefined;
        }
      });
      setInputsState(() => [...inputsModal]);
    };
  }, [setInputsState, inputsModal]);

  useEffect(() => {
    setModalPropsFactura({
      title: "Ver Factura",
      open: false,
      footer: [
        <Button
          key='cerrar'
          type='primary'
          onClick={() => setModalPropsFactura((state) => ({ ...state, open: false }))}
        >
          Cerrar
        </Button>,
      ],
    });
    setModalPropsNotaVenta({
      title: "Ver Nota de Venta",
      open: false,
      footer: [
        <Button
          key='cerrar'
          type='primary'
          onClick={() => setModalPropsNotaVenta((state) => ({ ...state, open: false }))}
        >
          Cerrar
        </Button>,
      ],
    });
    setModalPropsRecibo({
      title: "Ver Ticket",
      open: false,
      footer: [
        <Button
          key='cerrar'
          type='primary'
          onClick={() => setModalPropsRecibo((state) => ({ ...state, open: false }))}
        >
          Cerrar
        </Button>,
      ],
    });
  }, [setModalPropsFactura, setModalPropsNotaVenta, setModalPropsRecibo]);

  useEffect(() => {
    fetch(
      `${url_interna}api/reembolsos/ver-reembolso`,
      optionsPOST(
        JSON.stringify({
          Cod_Reembolso: AES.decrypt(decodeURIComponent(reembolso ?? ""), llave).toString(Utf8),
        }),
        { "api-token": "apitoken" }
      )
    )
      .then((res) => res.json())
      .then(({ msg, data }: fetchDataReembolso) => {
        if (msg === "ok") {
          setJustificacionValue(data.Justificacion);
          setListFacturas(
            data.Facturas.map(
              ({
                Cod_Documento,
                Documento,
                Detalle,
                Cod_Tipo_Pago,
                Servicio_10,
                Base_12,
                Iva,
                Base_0,
                Total,
                Fecha,
                Cod_Tipo_Gasto,
                Cod_Provincia,
                Cod_Ciudad,
                Clave_Acceso,
                Ruc,
                Razon_Social,
                Cod_Pais,
              }) => {
                return {
                  Cod_Documento: Cod_Documento,
                  documento: Documento,
                  concepto: Detalle,
                  valor: Total,
                  fecha: Fecha,
                  pais: Cod_Pais,
                  ruc: Ruc,
                  razonsocial: Razon_Social,
                  numeroautorizacion: Clave_Acceso,
                  tipoPago: Cod_Tipo_Pago,
                  tipoGasto: Cod_Tipo_Gasto,
                  provincia: Cod_Provincia ?? undefined,
                  ciudad: Cod_Ciudad ?? null,
                  servicio10: Servicio_10,
                  base0: Base_0,
                  base12: Base_12,
                  iva: Iva,
                };
              }
            )
          );
          (setListNotaVentas as React.Dispatch<React.SetStateAction<NotaVentas[]>>)(
            data.NotaVentas.map(
              ({
                Cod_Documento,
                Documento,
                Detalle,
                Cod_Tipo_Pago,
                Total,
                Fecha,
                Cod_Tipo_Gasto,
                Cod_Provincia,
                Cod_Ciudad,
                Clave_Acceso,
                Ruc,
                Razon_Social,
                Cod_Pais,
              }) => {
                return {
                  Cod_Documento: Cod_Documento,
                  documento: Documento,
                  concepto: Detalle,
                  valor: Total,
                  fecha: Fecha,
                  pais: Cod_Pais,
                  ruc: Ruc,
                  razonsocial: Razon_Social,
                  numeroautorizacion: Clave_Acceso,
                  tipoPago: Cod_Tipo_Pago,
                  tipoGasto: Cod_Tipo_Gasto,
                  provincia: Cod_Provincia ?? undefined,
                  ciudad: Cod_Ciudad ?? null,
                };
              }
            )
          );
          (setListRecibos as React.Dispatch<React.SetStateAction<Recibos[]>>)(
            data.Recibos.map(
              ({
                Ruc,
                Razon_Social,
                Cod_Documento,
                Documento,
                Detalle,
                Total,
                Fecha,
                Cod_Pais,
                Cod_Ciudad,
                Cod_Provincia,
                Cod_Tipo_Gasto,
                Cod_Tipo_Pago,
              }) => {
                return {
                  ruc: Ruc,
                  razonsocial: Razon_Social,
                  Cod_Documento: Cod_Documento,
                  documento: Documento,
                  concepto: Detalle,
                  valor: Total,
                  fecha: Fecha,
                  pais: Cod_Pais,
                  ciudad: Cod_Ciudad ?? null,
                  provincia: Cod_Provincia ?? undefined,
                  tipoGasto: Cod_Tipo_Gasto,
                  tipoPago: Cod_Tipo_Pago,
                };
              }
            )
          );
        } else {
          Swal.fire({
            title: "Error de consulta",
            text: "Error al obtener la lista de reembolsos de gastos",
            icon: "error",
            timer: 2000,
            showConfirmButton: false,
          });
        }
      })
      .catch((_) => {
        Swal.fire({
          title: "Sin Conexión",
          text: "No hay conexión con el servidor",
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  }, [reembolso, setListFacturas, setListNotaVentas, setListRecibos]);

  const handleOnClickActionList = [
    {
      "Ver documento": (index: number) => {
        forms[0].setFieldsValue({
          numeroautorizacion: (lists[0] as Facturas[])[index].numeroautorizacion,
          razonsocial: (lists[0] as Facturas[])[index].razonsocial,
          ruc: (lists[0] as Facturas[])[index].ruc,
          documento: (lists[0] as Facturas[])[index].documento,
          base12: (lists[0] as Facturas[])[index].base12,
          iva: (lists[0] as Facturas[])[index].iva,
          base0: (lists[0] as Facturas[])[index].base0,
          servicio10: (lists[0] as Facturas[])[index].servicio10,
          tipoPago: (lists[0] as Facturas[])[index].tipoPago,
          tipoGasto: (lists[0] as Facturas[])[index].tipoGasto,
          fecha: moment((lists[0] as Facturas[])[index].fecha.trim(), "DD MM YYYY"),
          concepto: (lists[0] as Facturas[])[index].concepto,
          pais: (lists[0] as Facturas[])[index].pais,
          provincia: (lists[0] as Facturas[])[index].provincia,
          ciudad: (lists[0] as Facturas[])[index].ciudad ?? undefined,
          valor: (lists[0] as Facturas[])[index].valor,
        });
        (lists[0] as Facturas[])[index].ciudad &&
          cargarCiudades[0](
            JSON.stringify({ Cod_Provincia: (lists[0] as Facturas[])[index].provincia }),
            { "api-token": "apitoken" }
          );
        setModalProps[0]((state) => ({ ...state, open: true }));
      },
    },
    {
      "Ver documento": (index: number) => {
        forms[1].setFieldsValue({
          numeroautorizacion: (lists[1] as NotaVentas[])[index].numeroautorizacion,
          razonsocial: (lists[1] as NotaVentas[])[index].razonsocial,
          ruc: (lists[1] as NotaVentas[])[index].ruc,
          documento: (lists[1] as NotaVentas[])[index].documento,
          tipoPago: (lists[1] as NotaVentas[])[index].tipoPago,
          tipoGasto: (lists[1] as NotaVentas[])[index].tipoGasto,
          fecha: moment((lists[1] as NotaVentas[])[index].fecha.trim(), "DD MM YYYY"),
          concepto: (lists[1] as NotaVentas[])[index].concepto,
          pais: (lists[1] as NotaVentas[])[index].pais,
          provincia: (lists[1] as any[])[index].provincia,
          ciudad: (lists[1] as NotaVentas[])[index].ciudad ?? undefined,
          valor: (lists[1] as NotaVentas[])[index].valor,
        });
        (lists[1] as NotaVentas[])[index].ciudad &&
          cargarCiudades[1](
            JSON.stringify({ Cod_Provincia: (lists[1] as NotaVentas[])[index].provincia }),
            { "api-token": "apitoken" }
          );
        setModalProps[1]((state) => ({ ...state, open: true }));
      },
    },
    {
      "Ver documento": (index: number) => {
        forms[2].setFieldsValue({
          ruc: (lists[2] as Recibos[])[index].ruc,
          razonsocial: (lists[2] as Recibos[])[index].razonsocial,
          documento: (lists[2] as Recibos[])[index].documento,
          fecha: moment((lists[2] as Recibos[])[index].fecha.trim(), "DD MM YYYY"),
          concepto: (lists[2] as Recibos[])[index].concepto,
          pais: (lists[2] as Recibos[])[index].pais,
          valor: (lists[2] as Recibos[])[index].valor,
          tipoPago: (lists[2] as Recibos[])[index].tipoPago,
          tipoGasto: (lists[2] as Recibos[])[index].tipoGasto,
          provincia: (lists[2] as Recibos[])[index].provincia,
          ciudad: (lists[2] as Recibos[])[index].ciudad ?? undefined,
        });
        (lists[2] as Recibos[])[index].ciudad &&
          cargarCiudades[2](
            JSON.stringify({ Cod_Provincia: (lists[2] as Recibos[])[index].provincia }),
            { "api-token": "apitoken" }
          );

        setModalProps[2]((state) => ({ ...state, open: true }));
      },
    },
  ];

  const { fetchData: ModificarReembolso } = useFetchInFunction(
    `${url_interna}api/reembolsos/editar-reembolso`,
    optionsPOST,
    useCallback(
      (_: any, error?: Error) => {
        if (error) {
          console.error(error);
          return;
        }
        Swal.fire({
          title: "Datos almacenados",
          text: "Reembolso de gastos almacenado con éxito",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        setJustificacionValue("");
        setListFacturas([]);
        setListNotaVentas([]);
        setListRecibos([]);
        navigate("/reembolsos/pendientes");
      },
      [setListFacturas, setListNotaVentas, setListRecibos, navigate]
    )
  );

  return {
    // handleOkModal: okModals.current,
    inputsState,
    handleOnClickActionList,
    modalProps,
    setModalProps,
    lists,
    setLists,
    contentDocuments,
    actionsList,
    ModificarReembolso,
    forms,
    resetFields,
    JustificacionValue,
    setJustificacionValue,
    // resetOkModal
  };
}
