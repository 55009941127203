import { createContext, useState } from "react";

interface IcContextType<T> {
  dataFilterConvenio: PropsFilter[];
  setDataFilterConvenio: React.Dispatch<React.SetStateAction<T>>;
  option: number;
  setOption: React.Dispatch<React.SetStateAction<number>>;
  headTable: T;
  setHeadTable: React.Dispatch<React.SetStateAction<T>>;
  columnTable: T;
  setColumnTable: React.Dispatch<React.SetStateAction<T>>;
  columnTableMonth: T;
  setColumnTableMonth: React.Dispatch<React.SetStateAction<T>>;
  columnTableMonthV: T;
  setColumnTableMonthV: React.Dispatch<React.SetStateAction<T>>;
  headTableMonth: T;
  setHeadTableMonth: React.Dispatch<React.SetStateAction<T>>;
  selectedRow: string[];
  setSelectedRow: React.Dispatch<React.SetStateAction<string[]>>;
  graph: string[];
  setGraph: React.Dispatch<React.SetStateAction<string[]>>;
  /**Cuotas tablas */
  // headTableQuota: T;
  // setHeadTableQuota: React.Dispatch<React.SetStateAction<T>>;
  columnTableQuota: T;
  setColumnTableQuota: React.Dispatch<React.SetStateAction<T>>;
  columnTableQuotaV: T;
  setColumnTableQuotaV: React.Dispatch<React.SetStateAction<T>>;
  selectedRowQuota: string[];
  setSelectedRowQuota: React.Dispatch<React.SetStateAction<string[]>>;
}

interface ContextICProps<T> {
  children: JSX.Element | Array<JSX.Element>;
  value?: IcContextType<T>;
}

interface PropsFilter {
  label: string;
  value: number;
}

export const CumplimientoConveniosContext = createContext<IcContextType<any>>({} as IcContextType<any>);

export function ContextConvenios<T>({ children }: ContextICProps<T>) {
  const [dataFilterConvenio, setDataFilterConvenio] = useState<PropsFilter[]>([]);
  const [option, setOption] = useState<number>(1);
  const [headTable, setHeadTable] = useState<T | undefined>(undefined);
  const [columnTable, setColumnTable] = useState<T | undefined>(undefined);
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [columnTableMonth, setColumnTableMonth] = useState<T | undefined>(undefined);
  const [columnTableMonthV, setColumnTableMonthV] = useState<T | undefined>(undefined);
  const [headTableMonth, setHeadTableMonth] = useState<T | undefined>(undefined);

  const [graph, setGraph] = useState<string[]>([]);

  // const [headTableQuota, setHeadTableQuota] = useState<T | undefined>(undefined);
  const [columnTableQuota, setColumnTableQuota] = useState<T | undefined>(undefined);
  const [columnTableQuotaV, setColumnTableQuotaV] = useState<T | undefined>(undefined);
  const [selectedRowQuota, setSelectedRowQuota] = useState<string[]>([]);
  return (
    <CumplimientoConveniosContext.Provider
      value={{
        columnTableQuotaV,
        setColumnTableQuotaV,
        columnTableMonthV,
        setColumnTableMonthV,
        dataFilterConvenio,
        setDataFilterConvenio,
        option,
        setOption,
        headTable,
        setHeadTable,
        columnTable,
        setColumnTable,
        selectedRow,
        setSelectedRow,
        columnTableMonth,
        setColumnTableMonth,
        headTableMonth,
        setHeadTableMonth,
        graph,
        setGraph,
        // headTableQuota,
        // setHeadTableQuota,
        columnTableQuota,
        setColumnTableQuota,
        selectedRowQuota,
        setSelectedRowQuota,
      }}
    >
      {children}
    </CumplimientoConveniosContext.Provider>
  );
}
