import { type FormInstance } from "antd"
import { type CalculosFinales } from "components/franquiciados/calculos-finales"
import { type Moment } from "moment"
import { createContext } from "react"

export type DependientesDataPrincipal = {
  cod_usuario: number
  dependiente: string
  sueldo: number
  fondos_reserva: number
  iess_12: number
  otros: number
  total: number
  dias: number
  porcentaje_ingresos: number
}

export type BodegasDataPrincipal = {
  cod_bodega: number
  bodega: string
  cargo_empleados: number
  multiplica_por?: number
  hasta_venta_maxima?: number
  dependientes: DependientesDataPrincipal[]
}

export type GastosFijos = {
  cod_gasto: number
  gasto: string
  valor: number
  n_bodegas: number
  total: number
}

export type Deudas = {
  cod_deuda: number
  deuda: string
  texto_deuda: string
  cuota: number
  cuotas: number
  monto: number
  valor_a_pagar: number
}

export type valuesForm = {
  mes: Moment
  franquiciado?: number
  bodega?: number
  bodegas: {
    cod_bodega: number
    bodega: string
    cargo_empleados: number
    dependientes: DependientesDataPrincipal[]
    total: number
    valor_asumido: number
    multiplica_por?: number
    hasta_venta_maxima?: number
  }[]
  gastos_fijos: GastosFijos[]
  deudas: Deudas[]
  total_gastos_fijos: number
  total_deudas: number
  total_asumido: number
  total: number
} & CalculosFinales

interface FormPagoContextType {
  form: FormInstance<valuesForm>
  loading: boolean
}

interface ContextFormPagoProps extends FormPagoContextType {
  children: JSX.Element | Array<JSX.Element>
}

export const FormPagoContext = createContext<FormPagoContextType>({} as FormPagoContextType)

export function ContextFormPago({ children, form, loading }: ContextFormPagoProps) {
  return <FormPagoContext.Provider value={{ form, loading }}>{children}</FormPagoContext.Provider>
}
