import { Button } from "antd"
import { UserContext } from "context/UserContext"
import moment from "moment"
import { useCallback, useContext, useEffect, useState } from "react"
import { url } from "config/constants"
import Swal from "sweetalert2"
import { useScreenSize } from "../../hooks/otros/useScreenSize"
import { llave } from "config/constants"
import { EditOutlined, LineChartOutlined } from "@ant-design/icons"
import AES from "crypto-js/aes"
import { useLocalStorage } from "../../hooks/otros/useLocalStorage"
import { useNavigate } from "react-router-dom"
import { useLayout } from "hooks/otros/useLayout"

interface procesos {
  cod_proceso: number
  cod_proceso_iniciado: number
  fecha: Date
  descripcion: string
  proceso: string
  estado: string
}

const breadCrumbs = [{ Label: "Procesos", Url: "/procesos" }, { Label: "Ver", Url: "/procesos/ver" }, { Label: "Todos" }]
const openKeys=["Procesos", "Procesos/Ver"]
const selectedKeys=""
export function useVerTodosProcesos() {
  
  useLayout(breadCrumbs, openKeys, selectedKeys)
  const { optionsPost } = useLocalStorage()
  const { userData: dataUser } = useContext(UserContext)
  const [procesos, setProcesos] = useState<procesos[]>([])
  const [actualPage, setActualPage] = useState(1)
  const { width } = useScreenSize()
  const isWeb = width > 768
  const [selectedState, setSelectedState] = useState<"all" | "PE" | "CO" | "SU" | "RE">("all")
  const [selectedDates, setSelectedDates] = useState<[string, string]>(["", ""])
  const navigate = useNavigate()

  const filteredData = () => {
    let procesosCopy = [...procesos]

    if (selectedState !== "all") procesosCopy = procesosCopy.filter(procesos => procesos.estado === selectedState)
    if (selectedDates[0] !== "")
      procesosCopy = procesosCopy.filter(
        procesos => moment(procesos.fecha, "DD/MM/YYYY") >= moment(selectedDates[0], "DD/MM/YYYY")
      )
    if (selectedDates[1] !== "")
      procesosCopy = procesosCopy.filter(
        procesos => moment(procesos.fecha, "DD/MM/YYYY") <= moment(selectedDates[1], "DD/MM/YYYY")
      )

    return procesosCopy
  }

  const fillProcesos = useCallback(async () => {
    fetch(url + "procesos/ver-procesos", optionsPost({ Id_Usuario: dataUser?.id }))
      .then(res => res.json())
      .then(res => {
        if (res.msg === "ok") {
          setProcesos(res.data)
        } else
          Swal.fire({
            title: "Error de consulta",
            text: "Error al obtener la lista de procesos",
            icon: "error",
            timer: 2000,
            showConfirmButton: false,
          })
      })
      .catch(_ => {
        Swal.fire({
          title: "Sin Conexión",
          text: "No hay conexión con el servidor",
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        })
      })
  }, [dataUser?.id, optionsPost])

  useEffect(() => {
    fillProcesos()
  }, [fillProcesos])

  const verificar_actions = (procesos: number) => {
    const enc_proceso = encodeURIComponent(AES.encrypt(procesos.toString(), llave).toString())
    fetch(url + "procesos/ver-actividades-departamentos", optionsPost({ cod_proceso_iniciado: procesos }))
      .then(res => res.json())
      .then(res => {
        if (res.msg === "ok") {
          if (!res.data[0].disabled) {
            navigate(`/editar-procesos/${enc_proceso}`)
          } else {
            Swal.fire({
              title: "Aún no puedes acceder a este proceso",
              text: "Quizás aún falten tareas por completar antes de realizar la suya",
              icon: "warning",
              timer: 2000,
              showConfirmButton: false,
            })
          }
        } else
          Swal.fire({
            title: "Error de consulta",
            text: "Error al obtener la lista de procesos",
            icon: "error",
            timer: 2000,
            showConfirmButton: false,
          })
      })
      .catch(_ => {
        Swal.fire({
          title: "Sin Conexión",
          text: "No hay conexión con el servidor",
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        })
      })
  }
  const actions = (estado: string, Cod_Proceso: number) => {
    if (estado === "PE" || estado === "CO") {
      return [
        <Button key="list-loadmore-edit" icon={<EditOutlined />} type="link" onClick={() => verificar_actions(Cod_Proceso)}>
          {estado === "PE" ? "Realizar" : "Revisar"}
        </Button>,
        <Button
          key="list-loadmore-more"
          icon={<LineChartOutlined />}
          type="link"
          onClick={() =>
            navigate(`/ver-procesos/${encodeURIComponent(AES.encrypt(Cod_Proceso.toString(), llave).toString())}`)
          }
        >
          Informe
        </Button>,
      ]
    } else {
      return [
        <Button
          key="list-loadmore-more"
          icon={<LineChartOutlined />}
          type="link"
          onClick={() =>
            navigate(`/ver-procesos/${encodeURIComponent(AES.encrypt(Cod_Proceso.toString(), llave).toString())}`)
          }
        >
          Ver
        </Button>,
      ]
    }
  }
  return {
    isWeb,
    actions,
    filteredData,
    selectedState,
    setSelectedState,
    selectedDates,
    setSelectedDates,
    actualPage,
    setActualPage,
  }
}
