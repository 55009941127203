import { Button, Form, Statistic } from "antd"
import type { FormItemProps } from "antd/es/form"
import type { FormInstance, FormListProps } from "antd/lib/form"
import { ButtonWithQuestion } from "./ButtonWithQuestion"

import { Fragment } from "react"

import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons"

export type ColumnsTableFormListType<T> = {
  title: string | JSX.Element
  name: keyof T
  render?: (index: number, value?: any, record?: T) => JSX.Element
  rules?: FormItemProps["rules"]
  noItem?: boolean
  width?: number | string
}

export type TableFormListProps<T, U> = {
  columns: ColumnsTableFormListType<U>[]
  nameList: FormListProps["name"]
  loading?: boolean
  canRemove?: boolean
  onRemove?: (record: U) => void
  disabledRemove?: (record: U) => boolean
  form?: FormInstance<T>
  canAdd?: boolean
  onAdd?: (recordClickedRow: U) => void
  disabledAdd?: (() => boolean) | boolean
  questionRemove?: string
}

export function TableFormList<T, U>({
  columns,
  nameList,
  loading,
  canRemove = false,
  onRemove,
  form,
  disabledRemove,
  canAdd = false,
  onAdd,
  disabledAdd,
  questionRemove = "¿Está seguro de quitar el gasto?",
}: TableFormListProps<T, U>) {
  return (
    <ContainerTable loading={loading ?? false}>
      <HeaderTable
        headerTable={canRemove ? [...columns.map(({ title }) => ({ title }))] : columns.map(({ title }) => ({ title }))}
        canRemove={canRemove}
        canAdd={canAdd}
      />
      <tbody className="ant-table-tbody">
        <Form.List name={nameList}>
          {(fields, { remove, add }) =>
            fields.map(({ key, name: nameField }, index) => (
              <Fragment key={key}>
                <tr className="ant-table-row ant-table-row-level-0">
                  {columns.map(({ name: nameColumn, render, rules, noItem, width }, index2) => (
                    <td className="ant-table-cell" key={index2} style={width ? { width } : undefined}>
                      {noItem ? (
                        render ? (
                          render(
                            index,
                            form?.getFieldValue(
                              Array.isArray(nameList)
                                ? [...nameList, nameField, nameColumn as string]
                                : [nameList, nameField, nameColumn as string]
                            ),
                            form?.getFieldValue(Array.isArray(nameList) ? [...nameList, nameField] : [nameList, nameField])
                          )
                        ) : (
                          <Statistic valueStyle={{ fontSize: "inherit" }} />
                        )
                      ) : (
                        <Form.Item name={[nameField, nameColumn as string]} noStyle rules={rules}>
                          {render ? (
                            render(
                              index,
                              form?.getFieldValue(
                                Array.isArray(nameList)
                                  ? [...nameList, nameField, nameColumn as string]
                                  : [nameList, nameField, nameColumn as string]
                              ),
                              form?.getFieldValue(Array.isArray(nameList) ? [...nameList, nameField] : [nameList, nameField])
                            )
                          ) : (
                            <Statistic valueStyle={{ fontSize: "inherit" }} />
                          )}
                        </Form.Item>
                      )}
                    </td>
                  ))}
                  {canAdd && (
                    <td className="ant-table-cell" style={{ position: "sticky", right: 0, backgroundColor: "white" }}>
                      <Button
                        icon={<PlusCircleOutlined style={{ fontSize: "1.3rem" }} />}
                        type={"text"}
                        block={true}
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        disabled={typeof disabledAdd === "boolean" ? disabledAdd : disabledAdd && disabledAdd()}
                        onClick={() => {
                          add(undefined, nameField + 1)
                          onAdd &&
                            onAdd(
                              form?.getFieldValue(Array.isArray(nameList) ? [...nameList, nameField] : [nameList, nameField])
                            )
                        }}
                      />
                    </td>
                  )}
                  {canRemove && (
                    <td className="ant-table-cell" style={{ position: "sticky", right: 0, backgroundColor: "white" }}>
                      <ButtonWithQuestion
                        buttonProps={{
                          icon: <MinusCircleOutlined style={{ fontSize: "1.3rem" }} />,
                          type: "text",
                          block: true,
                          style: { display: "flex", alignItems: "center", justifyContent: "center" },
                          disabled:
                            disabledRemove &&
                            disabledRemove(
                              form?.getFieldValue(Array.isArray(nameList) ? [...nameList, nameField] : [nameList, nameField])
                            ),
                        }}
                        popConfirmProps={{
                          title: questionRemove,
                          disabled:
                            disabledRemove &&
                            disabledRemove(
                              form?.getFieldValue(Array.isArray(nameList) ? [...nameList, nameField] : [nameList, nameField])
                            ),
                        }}
                        swalProps={{ title: questionRemove }}
                        accion={() => {
                          remove(nameField)
                          onRemove &&
                            onRemove(
                              form?.getFieldValue(Array.isArray(nameList) ? [...nameList, nameField] : [nameList, nameField])
                            )
                        }}
                      />
                    </td>
                  )}
                </tr>
              </Fragment>
            ))
          }
        </Form.List>
      </tbody>
    </ContainerTable>
  )
}

export function ContainerTable({ children, loading }: { children: JSX.Element | JSX.Element[]; loading: boolean }) {
  return (
    <div className="ant-table-wrapper">
      <div className="ant-spin-nested-loading">
        {loading && (
          <div>
            <div className="ant-spin ant-spin-spinning" aria-live="polite" aria-busy="true">
              <span className="ant-spin-dot ant-spin-dot-spin">
                <i className="ant-spin-dot-item"></i>
                <i className="ant-spin-dot-item"></i>
                <i className="ant-spin-dot-item"></i>
                <i className="ant-spin-dot-item"></i>
              </span>
            </div>
          </div>
        )}
        <div className={`ant-spin-container${loading && " ant-spin-blur"}`}>
          <div className="ant-table ant-table-small ant-table-scroll-horizontal">
            <div className="ant-table-container">
              <div className="ant-table-content" style={{ overflow: "auto hidden" }}>
                <table style={{ tableLayout: "auto" }}>{children}</table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

type HeaderTableProps = {
  headerTable: {
    title: string | JSX.Element
  }[]
  canRemove?: boolean
  canAdd?: boolean
}

export function HeaderTable({ headerTable, canRemove, canAdd }: HeaderTableProps) {
  return (
    <thead className="ant-table-thead">
      <tr>
        {headerTable.map(({ title }, index) => (
          <th className="ant-table-cell" key={index}>
            {title}
          </th>
        ))}
        {canAdd ? <th className="ant-table-cell" style={{ position: "sticky", right: 0 }} /> : undefined}
        {canRemove ? <th className="ant-table-cell" style={{ position: "sticky", right: 0 }} /> : undefined}
      </tr>
    </thead>
  )
}
