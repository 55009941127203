import { Modal } from "antd";

interface PropsModal {
  visible: any;
  setVisible: any;
  llave: string;
}

export const PdfViewer = ({ visible, setVisible, llave }: PropsModal) => {
  return (
    <>
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width='85%'
        destroyOnClose={true}
        transitionName=''
        style={{
          top: 64,
          position: "relative",
        }}
      >
        {/* <embed src={llave} title='PDF Viewer' style={{ width: "100%", height: "75%" }} /> */}
        <embed src={llave} type='application/pdf' width='100%' height='600px' style={{ paddingTop: "20px" }}></embed>
        {/* <Document file={llave} renderMode='canvas'>
          <Page pageNumber={1} />
        </Document> */}
      </Modal>
    </>
  );
};
