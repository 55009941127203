import { Key, useCallback, useContext, useEffect, useState } from "react";
import { url, url_alku } from "../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";

import { useVerifyResponse } from "hooks/otros/useVerifyReponse";

import { EyeOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import { Button, message, notification, TableColumnsType, Upload } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import type { RcFile, UploadFile } from "antd/lib/upload";

import { UserContext } from "context/UserContext";
import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { ButtonUploadPDF, ButtonUploadPDF2 } from "components/otros/buttonUploadImage";
import Swal from "sweetalert2";

type ActasReporte = {
  fecha: string;
  fechaAprobacion: string;
  cliente: string;
  cedula: string;
  valor: number;
  bodega: string;
  codBodega: number;
  archivo?: UploadFile;
  llaveImagen: string;
  llave?: string;
  key: React.Key;
};

const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "CxC aprobadas" }];

const openKeys = [breadcrumbs[0].Label];
export function useArchivosCuentasPendientes() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);

  const [datos, setDatos] = useState<ActasReporte[]>([]);
  const [searchText] = useState("");
  
  const [valueselect, setvalueselect] = useState("1");
  const [filteredData, setFilteredData] = useState<ActasReporte[]>([]);
  const { getColumnSearchPropsNew } = useSearchTableNew<ActasReporte>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]); // Aquí especificamos que es un array de `Key`

  const [mes, setMes] = useState<Moment>(moment());

  const [estadouser, setuser] = useState<boolean>(false);
  
  const [estadoBoton, setBoton] = useState<boolean>(false);
  const [estadoBotonUpdate, setBotonUpdate] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { userData } = useContext(UserContext);
  const [openModalPdf, setOpenModalPdf] = useState(false);
  const [selecteds, setSelecteds] = useState<{ empleado: number; solicitud: number; enlacePdf: string }>({
    empleado: 0,
    solicitud: 0,
    enlacePdf: "",
  });
  let { optionsPost, getItem } = useLocalStorage();
  const { isError } = useVerifyResponse();

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleUpdate = async () => {
    
    let filtro = datos.filter(({ archivo }) => Boolean(archivo));
    try {
      setBotonUpdate(true);
      let llave: string;
      for (let i = 0; i < filtro.length; i++) {
        if (filtro[i].archivo) {
          llave = await sendFile(filtro[i].archivo as RcFile, "actasConsiliatorias");
          if (!llave) throw new Error("No se pudo obtener la llave");
          // filtro = filtro.map((datosllave) => ({ ...datosllave, llave,archivo: undefined }));
          filtro[i].llave = llave;
          filtro[i].archivo = undefined;
        } else {
          Swal.fire("Error", "error");
        }
      }
      const isInvalidKey = filtro.some(({ llave }) => llave === "" || llave === undefined);
      if (isInvalidKey) {
        // Enviar a eliminar todo lo que tenga 'llave' vacía o indefinida
        for (let elemento of filtro) {
          await fetch(`${url}apiallku-private-eliminar/api/deletefromapiexterna`, optionsPost({ llave: elemento.llave }));
        }
        throw new Error("Al menos un elemento tiene 'llave' vacía o indefinida");
      }
      const respuesta = await fetch(
        url + "apifsg-pr/inventarios/actualizar_archivo_actas_conciliatorias",
        optionsPost({ filtro })
      ).then((datos) => datos.json());
      if (respuesta.msg === "ok") {
        for (let elemento of filtro) {
          await fetch(
            `${url}apiallku-private-eliminar/api/deletefromapiexterna`,
            optionsPost({ llave: elemento.llaveImagen })
          );
        }
        setBoton(false);
        setDatos([]);
        await getBodegas();
        notification.success({
          message: "OK",
          description: `Archivos actualizados correctamente`,
          placement: "bottomRight",
        });
      } else {
        for (let elemento of filtro) {
          if (elemento.llave === "" || elemento.llave === undefined) {
            await fetch(`${url}apiallku-private-eliminar/api/deletefromapiexterna`, optionsPost({ llave: elemento.llave }));
          }
        }
        notification.error({
          message: "Error",
          description: `Ocurrio un error,comunicar al departamento de sistemas, ${respuesta}`,
          placement: "bottomRight",
        });
      }
    } catch (error: any) {
      // Enviar a eliminar todo lo que tenga 'llave' vacía o indefinida
      for (let elemento of filtro) {
        await fetch(`${url}apiallku-private-eliminar/api/deletefromapiexterna`, optionsPost({ llave: elemento.llave }));
      }
      Swal.fire("Error", error.toString(), "error");
    }finally {
      setBotonUpdate(false);
    }
  };
  const sendFile = async (file: File, carpeta: string) => {
    const body = new FormData();
    try {
      body.append("uploadfile", file);
      body.append("archivo", carpeta);

      const requestSettings = {
        body,
        method: "POST",
        headers: {
          Accept: "application/json",
          "access-token": getItem("token") ?? "",
        },
      };

      let data = await fetch(`${url}apiallku-private/api/fromapiexterna2`, requestSettings).then((data) => data.json());

      return data.llave;
    } catch (error) {
      return error;
    }
  };

  const handleRemoveImage = async (key: Key) => {
    try {
      setDatos((state) =>
        state.map((dato) => {
          if (dato.key === key) {
            return { ...dato, archivo: undefined, llave: undefined };
          }
          return dato;
        })
      );
      // No es necesario actualizar selectedRowKeys aquí si se maneja en rowSelection.onChange
      return true;
    } catch (e) {
      return false;
    }
  };

  const getBodegas = useCallback(async () => {
    const shouldHideUpdateColumn = userData?.id === 7;
    setuser(shouldHideUpdateColumn);
    setBoton(false);
    setDatos([]);
    try {
      const res = await fetch(
        url + "apifsg-pr/inventarios/obtener_archivos_actas",
        optionsPost({ mes: mes.format("YYYYMM"),valueselect })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        const respuestas: ActasReporte[] = respuesta.data;
        if (respuestas.length > 0) {
          setDatos(
            respuestas.map(({ key, fecha, fechaAprobacion, cliente, cedula, valor, bodega, codBodega, llaveImagen }) => ({
              key,
              fecha,
              fechaAprobacion,
              cliente,
              cedula,
              valor,
              bodega,
              codBodega,
              llaveImagen,
            }))
          );
        } else {
          setDatos([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsPost, isError, mes, userData?.id,valueselect]);

  useEffect(() => {
    const newSelectedRowKeys = datos
      .filter((dato) => dato.archivo) // Asumiendo que `archivo` indica si la fila debe estar seleccionada
      .map((dato) => dato.key); // Extraemos las claves de las filas que deben estar seleccionadas

    setSelectedRowKeys(newSelectedRowKeys);
  }, [datos]);

  useEffect(() => {
    getBodegas();
  }, [getBodegas]);

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos);
    } else {
      const filtered = datos.filter((item) => item.bodega.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  const rowSelection = {
    selectedRowKeys, // Usamos el estado directamente
    onChange: (selectedKeys: Key[]) => {
      // Filtrar las claves para asegurarse de que solo se puedan deseleccionar
      const isSelectingNewRow = selectedKeys.some((key) => !selectedRowKeys.includes(key));
      if (!isSelectingNewRow) {
        const newSelectedRowKeys = selectedKeys.filter((key) => selectedRowKeys.includes(key));

        setSelectedRowKeys(newSelectedRowKeys);

        const deselectedKeys = selectedRowKeys.filter((key) => !selectedKeys.includes(key));
        deselectedKeys.forEach((key) => {
          const id = datos.find((dato) => dato.key === key)?.key;
          if (id !== undefined) handleRemoveImage(id);
        });
        const anySelectedFile = datos.some((dato) => selectedKeys.includes(dato.key) && (dato.archivo || dato.llave));
        setBoton(anySelectedFile);
      }
    },
  };
  const handleChange = (value: string) => {
    setvalueselect(value);
  };
  const beforeUpload = (file: RcFile, id: number) => {
    setDatos((state) =>
      state.map((s) => {
        if (s.key === id) {
          setBoton(true);
          return { ...s, archivo: file };
        } else {
          return s;
        }
      })
    );
  };

  const columns: TableColumnsType<ActasReporte> = [
    {
      title: "Farmacia",
      dataIndex: "bodega",
      key: "bodega",
      ...getColumnSearchPropsNew("bodega"),
    },

    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
      sorter: (a, b) => moment(a.fecha).unix() - moment(b.fecha).unix(),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Dependiente",
      dataIndex: "cliente",
      key: "cliente",
      ...getColumnSearchPropsNew("cliente"),
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      render: (valor) => `$${valor}`,
    },
    {
      title: "Ver",
      dataIndex: "key",
      fixed: "right",
      key: "key",
      align: "center",
      render: (value, record) => (
        <Button
          icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
          onClick={async () => {
            setSelecteds({ empleado: 0, solicitud: 0, enlacePdf: `${url_alku}allku/api/${record.llaveImagen}` });
            setOpenModalPdf(true);
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
    },
    {
      title: "Actualizar",
      dataIndex: "key",
      width: "30px",
      fixed: "right",
      key: "key",
      align: "center",
      render: (value, record) => {
        // Condición para verificar si hay un archivo cargado
        const isFileUploaded = record.archivo !== undefined;

        return (
          <Upload
            accept=".pdf"
            maxCount={1}
            name={`file-image-${record.key}`}
            showUploadList={false}
            beforeUpload={(file) => {
              if (file.size >= 4180000) {
                message.error("El archivo excede el tamaño máximo permitido. Debe ser menor a 4MB");
                return Upload.LIST_IGNORE;
              }
              const tipo = file.type.toLocaleLowerCase();
              if (tipo === "application/pdf") {
                beforeUpload(file, value);
                return false;
              }
              message.error("Solo se permiten archivos PDF");
              return Upload.LIST_IGNORE;
            }}
            fileList={
              datos.find((dato) => dato.key === value)?.archivo
                ? [datos.find((dato) => dato.key === value)?.archivo ?? ({} as UploadFile)]
                : undefined
            }
            onRemove={() => {
              handleRemoveImage(value);
            }}
          >
            {isFileUploaded ? (
              <ButtonUploadPDF2 /> // Cambia "newIcon" por el ícono que desees mostrar cuando haya un archivo
            ) : (
              <ButtonUploadPDF /> // Ícono por defecto para subir archivo
            )}
          </Upload>
        );
      },
    },
  ];
  const columnasVisual: TableColumnsType<ActasReporte> = [
    {
      title: "Farmacia",
      dataIndex: "bodega",
      key: "bodega",
      ...getColumnSearchPropsNew("bodega"),
    },

    {
      title: "Fecha inventario",
      dataIndex: "fecha",
      key: "fecha",
      sorter: (a, b) => moment(a.fecha).unix() - moment(b.fecha).unix(),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Fecha aprobación",
      dataIndex: "fechaAprobacion",
      key: "fechaAprobacion",
      sorter: (a, b) => moment(a.fechaAprobacion).unix() - moment(b.fechaAprobacion).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Dependiente",
      dataIndex: "cliente",
      key: "cliente",
      ...getColumnSearchPropsNew("cliente"),
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      render: (valor) => `$${valor}`,
    },
    {
      title: "Ver",
      dataIndex: "key",
      fixed: "right",
      key: "key",
      align: "center",
      render: (value, record) => (
        <Button
          icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
          onClick={async () => {
            setSelecteds({ empleado: 0, solicitud: 0, enlacePdf: `${url_alku}allku/api/${record.llaveImagen}` });
            setOpenModalPdf(true);
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
    },
  ];
  return {
    columns,
    columnasVisual,
    estadouser,
    isModalOpen,
    rowSelection,
    filteredData,
    handleOk,
    handleCancel,
    handleUpdate,
    estadoBoton,
    estadoBotonUpdate,
    mes,
    setMes,
    openModalPdf,
    setOpenModalPdf,
    selecteds,
    handleChange
  };
}
