import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useCallback, useContext, useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import { url } from "config/constants";
import { optionsPost, swalWarning } from "config/methods";
import { Form } from "antd";
import { useSearchTable } from "hooks/otros/useSearchTable";

const breadcrumbs = [{ Label: "Fracciones sin movimiento" }];

export interface DataTypeColumnsBodega {
  key: number;
  bodega: string;
  ciudad: string;
  totalProductos: number;
  totalCosto: number;
}

export interface DataTypeColumnsBodegaDetails {
  key: number;
  producto: string;
  laboratorio: string;
  caja: number;
  fracciones: number;
  cantR: number;
  minimo: number;
  total: number;
  dias: number;
  costo: number;
  fvendio: number;
}

export interface PropsRespuestaMain {
  msg: true;
  data: DataTypeColumnsBodega[];
}

export interface PropsRespuestaIdBodega {
  msg: true;
  data: DataTypeColumnsBodegaDetails[];
}

export const useNovedadesUltimaVenta = () => {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const [dataTableBodega, setDataTableBodega] = useState<DataTypeColumnsBodega[]>([]);
  const [dataTableBodegaDetails, setDataTableBodegaDetails] = useState<DataTypeColumnsBodegaDetails[]>([]);
  const [loaderTbl1, setLoaderTbl1] = useState(false);
  const [loaderTbl2, setLoaderTbl2] = useState(false);
  const { getColumnSearchProps } = useSearchTable<DataTypeColumnsBodega>();
  const { getColumnSearchProps: getColumnSearchProps2 } = useSearchTable<DataTypeColumnsBodegaDetails>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [form] = Form.useForm();

  /**Configuracion url y nav */
  useBreadCrumbs(breadcrumbs);

  useEffect(() => {
    setOpenKeys(["Productos", "Productos"]);
    setSelectedKeys(["Productos/Fracciones sin movimiento"]);
  }, [setOpenKeys, setSelectedKeys]);

  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };

  const columnsBodega: ColumnsType<DataTypeColumnsBodega> = [
    {
      title: "Bodega",
      dataIndex: "bodega",
      key: "bodega",
      ...getColumnSearchProps("bodega"),
    },
    {
      title: "Ciudad",
      dataIndex: "ciudad",
      key: "ciudad",
      ...getColumnSearchProps("ciudad"),
    },
    {
      title: "Total Productos",
      dataIndex: "totalProductos",
      key: "totalProductos",
      align: "right",
      sorter: (a, b) => a.totalProductos - b.totalProductos,
    },
    {
      title: "Costo Fracciones",
      dataIndex: "totalCosto",
      key: "totalCosto",
      align: "right",
      render: (_, record) => <>{formateaCantidad(record.totalCosto)}</>,
      sorter: (a, b) => a.totalCosto - b.totalCosto,
    },
  ];

  const columnsBodegaId: ColumnsType<DataTypeColumnsBodegaDetails> = [
    {
      title: "Producto",
      dataIndex: "producto",
      key: "producto",
      width: "290px",
      ...getColumnSearchProps2("producto"),
    },
    {
      title: "Laboratorio",
      dataIndex: "laboratorio",
      key: "laboratorio",
      width: "250px",
      ...getColumnSearchProps2("laboratorio"),
    },
    {
      title: "Cajas",
      dataIndex: "caja",
      key: "caja",
      align: "right",
    },
    {
      title: "Fracciones",
      dataIndex: "fracciones",
      key: "fracciones",
      align: "right",
    },
    {
      title: "Minimo",
      dataIndex: "minimo",
      key: "minimo",
      align: "right",
    },
    // {
    //   title: "Costo",
    //   dataIndex: "costo",
    //   key: "costo",
    //   align: "right",
    //   render: (_, record) => <>{formateaCantidad(record.costo)}</>,
    //   sorter: (a, b) => a.costo - b.costo,
    // },
    {
      title: "Costo Fracciones",
      dataIndex: "total",
      key: "total",
      align: "right",
      render: (_, record) => <>{formateaCantidad(record.total)}</>,
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: "DíasSm",
      dataIndex: "dias",
      key: "dias",
      align: "right",
      sorter: (a, b) => a.dias - b.dias,
    },
    {
      title: "#Farmacias Vendio",
      dataIndex: "fvendio",
      key: "fvendio",
      align: "right",
      sorter: (a, b) => a.fvendio - b.fvendio,
    },
  ];
  const handleChangeDias = (dias: number | null) => {
    if (dias) {
      handleDataMain(dias);
    }
  };

  const handleDataMain = useCallback(async (dias: number) => {
    try {
      setLoaderTbl1(true);
      setDataTableBodega([]);
      setDataTableBodegaDetails([]);
      setExpandedRowKeys([]);
      const respuesta: PropsRespuestaMain = await fetch(
        url + "apifsg-pr/productos/novedades-ultima-venta/",
        optionsPost({ dias })
      ).then((data) => data.json());
      if (respuesta.msg) setDataTableBodega(respuesta.data);
      else swalWarning({ text: "Ocurrio un error al cargar la información" });
      setLoaderTbl1(false);
    } catch (e) {
      setLoaderTbl1(false);
      swalWarning({ text: "Ocurrio un error al cargar la información" });
    }
  }, []);

  const handleDataIdBodega = useCallback(
    async (cod_bodega: number) => {
      try {
        setLoaderTbl2(true);
        setDataTableBodegaDetails([]);
        const respuesta: PropsRespuestaIdBodega = await fetch(
          url + "apifsg-pr/productos/novedades-ultima-venta-id/",
          optionsPost({ cod_bodega, dias: form.getFieldsValue().dias })
        ).then((data) => data.json());
        if (respuesta.msg) setDataTableBodegaDetails(respuesta.data);
        else swalWarning({ text: "Ocurrio un error al cargar la información" });
        setLoaderTbl2(false);
      } catch (e) {
        setLoaderTbl2(false);

        swalWarning({ text: "Ocurrio un error al cargar la información" });
      }
    },
    [form]
  );

  const handleExpandChange = async (expanded: boolean, record: DataTypeColumnsBodega) => {
    if (expanded) {
      setExpandedRowKeys([record.key]);
      await handleDataIdBodega(record.key);
    } else if (expanded) {
      setExpandedRowKeys([record.key]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  useEffect(() => {
    handleDataMain(150);
  }, [handleDataMain]);

  return {
    columnsBodega,
    dataTableBodega,
    handleExpandChange,
    expandedRowKeys,
    dataTableBodegaDetails,
    columnsBodegaId,
    form,
    handleChangeDias,
    loaderTbl1,
    loaderTbl2,
    formateaCantidad,
  };
};
