import { Form } from "antd";
import { url } from "config/constants";
import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useEffect, useContext, useCallback, useState } from "react";
import Swal from "sweetalert2";

const breadcrumbs = [{ Label: "Crear Atc" }];

export interface dataSelect {
  value: number;
  label: string;
}

interface DataTransfer {
  key: string;
  label: string;
  descripcion?: String;
}

export const useProductosIncentivos = () => {
  const [form] = Form.useForm();
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);

  const [dataSelect, setDataSelect] = useState<dataSelect[]>([]);
  const [dataTransfer, setDataTransfer] = useState<DataTransfer[]>([]);

  const [productsKeys, setProductsKeys] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [id_producto, setId_producto] = useState<number>();

  let { optionsGet, optionsPost } = useLocalStorage();

  useBreadCrumbs(breadcrumbs);

  useEffect(() => {
    setOpenKeys(["Productos", "Productos"]);
    setSelectedKeys(["Productos/Crear Atc"]);
  }, [setOpenKeys, setSelectedKeys]);

  const getProductos = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "apifsg-pr/incentivos/productos", optionsGet).then((data) => data.json());
      if (respuesta.msg) {
        setDataSelect(respuesta.data);
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsGet]);

  const handleProducto = async (value: string) => {
    try {
      setId_producto(parseInt(value));
      setDataTransfer([]);
      setProductsKeys([]);
      const respuesta = await fetch(url + "apifsg-pr/incentivos/atc-fsg", optionsPost({ cod_producto: value })).then(
        (data) => data.json()
      );
      if (respuesta.msg) {
        setDataTransfer(respuesta.data);
      } else {
        Swal.fire("Error", "Este producto no tiene productos para emparejar ", "error");
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  };

  const handleChange = (targetKeys: string[]) => {
    setProductsKeys(targetKeys);
  };

  const handleSendInfo = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        if (productsKeys.length <= 0) {
          setLoading(false);
          Swal.fire("Error", "La lista de productos a emparejar no puede estar vacía!", "error");
          throw new Error("Error , lista vacía!");
        }
        const respuesta = await fetch(
          url + "apifsg-pr/incentivos/create-atc-fsg",
          optionsPost({ values, id_productos: JSON.stringify(productsKeys) })
        ).then((data) => data.json());
        if (respuesta.msg) {
          Swal.fire("Ok", "Se guardó correctamente la clasificación personalizada de los productos!", "success");
          setDataTransfer([]);
          setDataSelect([]);
          form.resetFields();
          setLoading(false);
          await getProductos();
        } else {
          setLoading(false);
          return { msg: "Ocurrio un error", status: false };
        }
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const onSearchProducto = async (value: string) => {
    try {
      if (value.trim().length < 1)
        return Swal.fire("Advertencia", "Debes escribir al menos 1 caracteres para buscar", "warning");
      const respuesta = await fetch(
        url + "apifsg-pr/incentivos/atc-fsg-others",
        optionsPost({ producto: value, cod_producto: id_producto })
      ).then((data) => data.json());
      if (respuesta.msg) {
        setDataTransfer((prevData: DataTransfer[]) => {
          const uniqueKeys = new Set();
          const updatedData: DataTransfer[] = [];

          respuesta.data.forEach((item: DataTransfer) => {
            if (!uniqueKeys.has(item.key)) {
              uniqueKeys.add(item.key);
              updatedData.push(item);
            }
          });
          prevData.forEach((item: DataTransfer) => {
            if (!uniqueKeys.has(item.key)) {
              uniqueKeys.add(item.key);
              updatedData.push(item);
            }
          });
          return updatedData;
        });
      } else {
        Swal.fire("Error", "Este producto no tiene productos para emparejar ", "error");
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (error) {
      Swal.fire("Error", "Ocurrrio un error intente nuevamente", "error");
    }
  };

  useEffect(() => {
    getProductos();
  }, [getProductos]);

  return {
    dataSelect,
    handleProducto,
    dataTransfer,
    handleChange,
    productsKeys,
    form,
    handleSendInfo,
    loading,
    onSearchProducto,
  };
};
