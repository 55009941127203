import React, { useEffect, useState } from "react"
import { getBase64, optionsGet, optionsPost, swalError, swalSuccess, swalWarning, swalQuestion } from "config/methods"
import { url, url_alku } from "config/constants"
import { ColumnsType } from "antd/lib/table"
import { Button, Input, Select, Tag, Tooltip, UploadFile, UploadProps } from "antd"
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons"
import Upload, { RcFile } from "antd/lib/upload"
import moment from "moment"

export interface DataType {
  key: React.Key
  codtipouniforme: number
  nombre_tipouniforme: string
  codtalla: string
  cantidad: number
  vunitario: number
  vtotal: number 
}
 
export interface DataTypeSolicitud {
  key: React.Key
  codsolicitudreposicion: number
  estado: string
  nombre_rrhh: string
  nombre_aprobado: string
  fecha_solicitud: number
  disabled: boolean,
  observacion: string
  //vtotal: number
}

export function useSolicitudReposicion() {
  const [itemselectUniforme, setitemSelectUniforme] = useState([{ value: "", label: "Tipo de Uniforme", precio: 0 }])
  const [dataTable, setdataTable] = useState<DataType[]>([])
  const [count, setCount] = useState(0)
  const [valueselect, setvalueselect] = useState<number>()
  const [itemselectTalla, setitemSelectTalla] = useState([{ value: "", label: "Seleccione la Talla" }])
  const [files, setFiles] = useState<UploadFile[]>()
  const [disableRemoveIcon, setDisableRemoveIcon] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [DataSolicitudes, setDataSolicitudes]=useState([]);
  const [visible, setVisible] = useState(false);
  const [codSolicitud, setcodSolicitud]= useState<Number>(0);
  const [Estado, setEstado]= useState('PE')
  const [disabledDragger, setdisabledDragger]= useState(false)
  const [disabledbutton, setdisabledbutton]=useState(false)

  const handleCancel = () => setPreviewOpen(false)

  const handleInputChange = (e: any, key: any) => {
    let newValue = e.target.value
    const updatedData = dataTable.map((item) => {
      if (item.key === key) {
        if (/^[0-9]{0,10}$/.test(newValue)) {
          return { ...item, cantidad: newValue, vtotal: newValue * item.vunitario }
        }
      }
      return item
    })
    setdataTable(updatedData)
    //console.log(updatedData)
  }
  const deleteSolicitud=async(cod_solicitud: number)=>{
    swalQuestion({ 
      text: "¿Esta seguro de Eliminar la solicitud Numero "+cod_solicitud+"?",
      icon: "warning",
      showCancelButton: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      const resdata= await fetch(
        `${url}fapifsg-pr/rrhh/Uniforme/deleteSolicitud`,
        optionsPost({ "cod_solicitud": cod_solicitud})
      ).then((data) => data.json());
  
      if(resdata==='ok'){
        swalSuccess({text:"Se eliminó correctamente la Solicitud"})
        getsolicitaruniforme()
      }
    }
  });
  }

  const Solicitudes: ColumnsType<DataTypeSolicitud> = [
    {
      title: "N°",
      dataIndex: "key",
      render: (key)=>{return +key+1}
      //width: "400px",
    },
    {
      title: "Solicitud",
      dataIndex: "codsolicitudreposicion",
      //width: "400px",
    },
    {
      title: "Fecha_Solicitud",
      dataIndex: "fecha_solicitud",
      //width: "400px",
    },
    {
      title: "Validado por RRHH",
      dataIndex: "nombre_rrhh",
      width: "200px",
    },
    {
      title: "Aprobado por Gerencia",
      dataIndex: "nombre_aprobado",
      width: "200px",
    },
    {
      title: "Observación",
      dataIndex: "observacion",
      //width: "400px",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      width:'161px',
      align:'center',
      render: (estado, key) => {
        let nombre_estado, background, colorborder, color, colortag;
        if(estado==='PE'){
          colortag='blue'
          colorborder = "#070E3B"//"#F15545"
          background="#D4DAFF"
          nombre_estado='PENDIENTE'
        } else if(estado==='PP'){
          colortag='lime'
          colorborder = "#F4D03F"
          background='#FDFFBC'//'#F7FA92'
          nombre_estado='VALIDADO RRHH'
        }else if(estado==='AP'){
          colortag='green'
          nombre_estado='APROBADO'
          background = "#D5F5E3 "
          colorborder="#27AE60"
        } else if(estado==='RE'){
          colortag='red'
          colorborder ='#FC182D' //"#F99D3B"
          background= '#FFDCDF'//"#FFEAD4 "
          nombre_estado='RECHAZADO'
        }
        return (
          <>
             {estado!=='PP'&&<Tag color={colortag} style={{color:color}}>{nombre_estado}</Tag>}
            {estado==='PP'&& <label style={{fontSize: '11px',borderColor: colorborder,borderStyle: 'solid', color: color,borderRadius:'5px', padding:'2px', borderWidth:'1px', background: background}}> {nombre_estado}</label>}
          </>
        )
      }
    },
    {
      title: "Acción",
      dataIndex: "",
      width:'100PX',
      render:(key: DataTypeSolicitud)=>{
        //console.log(key)
        return( <><Tooltip title="Ver Solicitud"> <Button type="primary" size="small" onClick={()=>viewsolicitud(key)}><EyeOutlined/></Button></Tooltip>
        {key.estado==='PE'?<Tooltip title="Eliminar"><Button type="primary" size="small" style={{marginLeft:'3px'}} onClick={()=>deleteSolicitud(key.codsolicitudreposicion)}><DeleteOutlined /></Button></Tooltip>:''}
        </>)}
    },
  ]

  const viewsolicitud=async(key:DataTypeSolicitud)=>{
    
    setVisible(true);
    setcodSolicitud(key.codsolicitudreposicion)
    setEstado(key.estado)
    setDisableRemoveIcon(key.estado==='PE'||key.estado==='RE'?false:true)
    setdisabledbutton(key.estado==='PE'||key.estado==='RE'?false:true)
    const resdata=await fetch(
      `${url}fapifsg-pr/rrhh/Uniforme/getDetalleSolicitudUsuario`,
      optionsPost({ "cod_solicitud": key.codsolicitudreposicion})
    ).then((data) => data.json());
      const newdatatable=resdata.map((item:any, key:any)=>({
          key: key.toString(),
          codtipouniforme: item.Cod_Tipo_Uniforme,
          nombre_tipouniforme: item.Nombre,
          codtalla:item.Cod_Talla,
          cantidad: item.Cantidad,
          vunitario: item.Valor_Unitario,
          vtotal: item.Cantidad* item.Valor_Unitario,
      }))
      setdataTable(newdatatable)
      //console.log(key.estado)
      setdisabledDragger(key.estado==='PE'||key.estado==='RE'?false:true)
      //console.log(newdatatable)
      setFiles([])
      const resimage=await fetch(
          `${url}fapifsg-pr/rrhh/Uniforme/getSolicitudImagesUsuario`,
          optionsPost({ "cod_solicitud": key.codsolicitudreposicion})
        ).then((data) => data.json());
      const images=resimage.map((item:any, key:any)=>({
          uid: key,
          name: item.Llave,
          status: 'done',
          url: `${url}apiallku-public/api/${item.Llave}`,
          response:item.Llave ,
      }))
      
      setFiles(images)
      //console.log(files)
      localStorage.setItem(
        "RenovacionUniforme",
        JSON.stringify(files?.map(({ uid, name, response, originFileObj }) => ({ uid, name, response, originFileObj})))
      ) 
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Nombres",
      dataIndex: "nombre_tipouniforme",
      width: "400px",
    },
    {
      title: "Talla",
      dataIndex: "codtalla",
      width: "100px",
      render: (codtalla, key) => {
        return (
          <>
            <Select
              disabled={Estado==='PE'||Estado==='RE'?false:true}
              showSearch
              style={{ textAlign: "start", marginBottom: "5px" }}
              defaultValue={codtalla}
              //value={codtalla}
              optionFilterProp="children"
              onChange={(value) => onChangeTalla(value, key)}
              onSearch={onSearch}
              filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
              options={itemselectTalla}
            />
          </>
        )
      },
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      align: "center",
      render: (cantidad, key) => {
        return (
          <Input
            disabled={Estado==='PE'||Estado==='RE'?false:true}
            style={{ textAlign: "right", width: "50px" }}
            maxLength={2}
            onChange={(e) => handleInputChange(e, key.key)}
            value={cantidad}
          />
        )
      }, //<Button icon={<DeleteOutlined />}></Button>,
    },
    {
      title: "V. Unitario",
      dataIndex: "vunitario",
      width: "250px",
      align: "right",
      render: (vunitario) => {
        return vunitario.toFixed(2)
      },
    },
    {
      title: "V. Total",
      dataIndex: "vtotal",
      width: "250px",
      align: "right",
      render: (vtotal, key) => {
        return vtotal.toFixed(2)
      },
    },
    {
      dataIndex: "",
      title: "",
      width: '50px',
      render: (cantidad, key) => {
        return <Button icon={<DeleteOutlined />} onClick={() => deleteUniforme(key)}></Button>
      }, //<Button icon={<DeleteOutlined />}></Button>,
    },
  ]

  const deleteUniforme = (key: any) => {
    const newData = dataTable.filter((item) => item.key !== key.key)
   // console.log(key)
    setdataTable(newData)
  }

  const onChange = (value: string) => {
    setvalueselect(value as any)
    //console.log(`selected ${value}`)
  }

  const onSearch = (value: string) => {
    //console.log("search:", value)
  }

  const addrow = () => {
    //console.log(valueselect)
    const datos = itemselectUniforme.filter((item) => +item.value === valueselect)
    const exist = dataTable.filter((item) => item.codtipouniforme === valueselect)
    if (exist.length > 0) {
      swalWarning({ text: "Ya esta agregado a la lista de solicitud" })
      return
    }
    const rownew = [
      {
        key: count.toString(),
        codtipouniforme: +datos[0].value,
        nombre_tipouniforme: datos[0].label,
        codtalla: "",
        cantidad: 0,
        vunitario: datos[0].precio,
        vtotal: 0,
      },
    ]

    setCount(count + 1)
    setdataTable([...dataTable, ...rownew])
    // console.log([...dataTable, ...rownew])
  }

  const getTalla = async () => {
    const res = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/getTalla`, optionsGet()).then((data) => data.json())
    let respuesta = res.data
    let newget = [{ value: "", label: "Talla" }]
    respuesta.forEach((item: any) => {
      let add = {
        value: item.Cod_Talla,
        label: item.Talla,
      }
      newget = [...newget, add]
    })
    setitemSelectTalla(newget)
  }

  useEffect(() => {
    const getTipoUniforme = async () => {
      const res = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/getTipoUniforme`, optionsGet()).then((data) => data.json())
      //console.log(res)
      let newget = [{ value: "", label: "Seleccione Tipo Uniforme", precio: 0 }]
      res.data.forEach((item: any) => {
        let add = {
          value: item.Cod_Tipo_Uniforme,
          label: item.Nombre,
          precio: item.Valor,
        }
        newget = [...newget, add]
      })
      setitemSelectUniforme(newget)
    }
    getTipoUniforme()
    getTalla()
  }, [])

  const onChangeTalla = (value: string, key: DataType) => {
    const newdata = dataTable.map((item) => {
      if (item.key === key.key) {
        return { ...item, codtalla: value.toString(), cantidad: item.cantidad }
      } else {
        return item
      }
    })
    //console.log(newdata)
    setdataTable(newdata)
  }

  const saveSolicitudReposicion = async () => {
    setdisabledbutton(true)

    const vacios = dataTable.filter((item: DataType) => item.cantidad === +"" || item.codtalla === "" || item.cantidad < 1)

    if (vacios.length > 0 || dataTable.length === 0) {
      swalWarning({ text: "Faltan datos para guardar" })
      setdisabledbutton(false)
      return
    }

    let images = localStorage.getItem("RenovacionUniforme")

    if (images === null || images.length===0) {
      swalWarning({ text: "No ha subido la evidencia" })
      setdisabledbutton(false)
      return
    }

    const data = [
      {
        codsolicitudreposicion: codSolicitud,
        tipoarticulo: "UF",
        codusuario: "",
        codarticulo: "",
        estado: Estado,
        codusuariosolicitud: "",
        codusuarioaprobacionrrhh: "",
        codusuarioaprobacion: "",
        detalle: dataTable,
        evidencia: files?.map(({ response, uid }) => ({ response, uid })),
        nombre: '',
        nombrekit: '',
        fecha: moment().format("DD/MM/YYYY")
      },
    ]
    //console.log(data)
    const res = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/saveSolicitudUniformes`, optionsPost({ solicitudes: data, correo: "mariacedeno@sangregorio.com.ec" , opcion:"Validación Uniformes RRHH", url:`https://gestion.fsg.ec/rrhh/renovacion-uniformes/validacion-uniformes-rrhh` }))

    if (res.status === 200) {
      swalSuccess({ text: "Se guardo exitosamente la solicitud" })
      localStorage.removeItem("RenovacionUniforme")
      setFiles([])
      setdataTable([])
      setcodSolicitud(0)
      setVisible(false); getsolicitaruniforme();
    }
    setdisabledbutton(false)
  }

  const onChangeFile: UploadProps["onChange"] = async ({ file, fileList }) => {
    console.log(fileList.length)
    if (file.status === "removed") return
    //if (file.status !== "done" && file.status !== "success") return
    try {
      if(fileList.length<4){
      const formData = new FormData()
      formData.append("uploadfile", file as RcFile)
      formData.append("archivo", "solicitud-uniformes")
      const res = await fetch(`${url_alku}allku/api/crear-archivo`, {
        method: "POST",
        body: formData,
      })

      if (!res.ok) throw new Error()
      const { llave } = await res.json()
      const newFile = fileList.find((item) => item.uid === file.uid)
      const newFiles = [
        ...(files ?? []),
        { uid: newFile?.uid ?? "", name: newFile?.name ?? "", response: llave, originFileObj: newFile?.originFileObj },
      ]
      setFiles((state) => [
        ...(state ?? []),
        { uid: newFile?.uid ?? "", name: newFile?.name ?? "", response: llave, originFileObj: newFile?.originFileObj },
      ])
      localStorage.setItem(
        "RenovacionUniforme",
        JSON.stringify(newFiles.map(({ uid, name, response, originFileObj }) => ({ uid, name, response, originFileObj })))
      )
      }else{
        swalWarning({text:"No se puede subir mas de 3 imagenes"})
        return
      }
    } catch (e) {
      swalError({ text: "No se pudo subir la imagen" })
    }
  }

  const onPreviewImage = async (file: UploadFile) => {

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  }

  const handleRemove: UploadProps["onRemove"] = async ({ uid }) => {
    let result = true
    try {
      setDisableRemoveIcon(true)
      const res = await fetch(
        `${url}apiallku-private-eliminar/api/deletefromapiexterna`,
        optionsPost({ files: files?.filter(({ uid: uid2 }) => uid2 === uid).map(({ response }) => response) })
      )

      const Llave=files?.filter(({ uid: uid2 }) => uid2 === uid).map(({ response }) => response)
      
      const resimage = await fetch(
        `${url}fapifsg-pr/rrhh/Uniforme/deleteImageEvidencia`,
        optionsPost({ "Llave": Llave })
      ).then((data) => data.json());

      if (!res.ok && resimage!=='ok') throw new Error()

      const newFiles = files?.filter(({ uid: uid2 }) => uid2 !== uid) ?? []
      setFiles(newFiles)
      //console.log(newFiles)
      if (newFiles.length === 0) {
        localStorage.removeItem("RenovacionUniforme")
        return
      }
      localStorage.setItem(
        "RenovacionUniforme",
        JSON.stringify(newFiles.map(({ uid, name, status, response }) => ({ uid, name, status, response })))
      )
    } catch (e) {
      result = false
    } finally {
      setDisableRemoveIcon(false)
      return result
    }
  }

  const checkFile: UploadProps["beforeUpload"] = (file) => {
/*     console.log("checkfile")
    console.log(file) */
    const isImage = file.type.startsWith("image/")
    if (!isImage) {
      swalWarning({ text: "Solo se permiten imágenes." })
      return Upload.LIST_IGNORE
    }
    return !isImage
  }
  useEffect(() => {
    getsolicitaruniforme()
  },[])


  const getsolicitaruniforme = async () => {
    const res = await fetch(`${url}fapifsg-pr/rrhh/Uniforme/getSolicitudUsuario`, optionsGet()).then((data) => data.json())
    //console.log(res)
  let newdata:any=[]
   if(res){
      newdata = Array.from(res).map((item: any, key: any) => ({
      key: key.toString(),
      codsolicitudreposicion: item.Cod_Solicitud_Reposicion_Articulo,
      estado: item.Estado,
      nombre_rrhh: item.RRHH,
      nombre_aprobado: item.UsuarioAprobacion,
      fecha_solicitud: item.Fecha_Solicitud,
      disabled: item.Estado==='PE'?true:false,
      observacion: item.Observacion_RRHH !== null?item.Observacion_RRHH:item.Observacion_aprobacion
    }))
    //console.log(newdata)
   }
   setDataSolicitudes(newdata)
   //setData(newdata)
}

  const btnNuevo=()=>{
    setVisible(true)
    setdataTable([])
    setFiles([])
    setcodSolicitud(0)
    setEstado('PE')
    localStorage.removeItem("RenovacionUniforme")
    setdisabledDragger(false)
    setdisabledbutton(false)
  }

  const btnRegresar=()=>{
    
    if(codSolicitud!==0 && files?.length===0){
      swalWarning({text:"Por favor agregue la evidencia y guarde"})
      return
    }
    setVisible(false); getsolicitaruniforme();
  }

  return {
    itemselectUniforme,
    onSearch,
    dataTable,
    onChange,
    addrow,
    columns,
    itemselectTalla,
    onChangeTalla,
    saveSolicitudReposicion,
    files,
    onChangeFile,
    onPreviewImage,
    disableRemoveIcon,
    handleRemove,
    checkFile,
    previewOpen,
    previewTitle,
    previewImage,
    handleCancel,
    DataSolicitudes,
    Solicitudes,
    visible, 
    setVisible,
    btnNuevo,
    getsolicitaruniforme,
    btnRegresar,
    Estado,
    disabledDragger,
    disabledbutton
  }
}
