import { Button, Col, Row, Table, Card, Select } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import "moment/locale/es";
import { useCuentasPendientes } from "hooks/inventarios/useActasPendientes";

export function CuentasPendientes() {
  const { columns, estadouser, columnsView, filteredData, rowSelection, handleSave,estadoGuardar, estadoBoton,handleChange } = useCuentasPendientes();

  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 16 }}>
          <Select
            defaultValue="1"
            style={{ width: 130, marginRight: "10px", marginTop: "0px" }}
            onChange={handleChange}
            options={[
              {
                value: "1",
                label: "Inventario",
              },
              {
                value: "2",
                label: "Caducados",
              },
              /*{
                value: "3",
                label: "Otros",
              },*/
            ]}
          />
          {estadoBoton && (
            <Button
              type="primary"
              style={{
                width: "190px",
              }}
              onClick={handleSave}
              loading={estadoGuardar}
              icon={<SaveOutlined className="iconbutton" />}
            >
              Aprobar
            </Button>
          )}
        </div>
        <Card>
          <Table
            rowSelection={!estadouser ? rowSelection : undefined}
            columns={!estadouser ? columns : columnsView}
            dataSource={filteredData}
            scroll={{ x: true }}
            size="small"
            pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default CuentasPendientes;
