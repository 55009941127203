import type { Style } from "@react-pdf/types"
import { ReportTable, columnsTablePDF } from "components/reactreport/report-table"
import { VentasFarmacia } from "components/franquiciados/calculos-finales"

type Props = {
  ventas_farmacias: VentasFarmacia[]
  commonColumnStyle: Style
  rowHeaderStyle: Style
  borderBottom?: boolean
}

export function VentasFarmaciaTable({ ventas_farmacias, commonColumnStyle, rowHeaderStyle, borderBottom = false }: Props) {
  const columnsVentasComision: columnsTablePDF<VentasFarmacia>[] = [
    {
      title: "Farmacia",
      dataIndex: "farmacia",
      key: "farmacia",
      styleColumn: { ...commonColumnStyle, padding: 3, flexBasis: "50%", textAlign: "left" },
      styleColumnHeader: { ...commonColumnStyle, padding: 3, flexBasis: "50%", textAlign: "left" },
    },
    {
      title: "Venta",
      dataIndex: "ventas",
      key: "ventas",
      styleColumn: { ...commonColumnStyle, padding: 3, margin: "auto 0", flexBasis: "25%", textAlign: "right" },
      styleColumnHeader: { ...commonColumnStyle, padding: 3, flexBasis: "25%", textAlign: "right" },
      fixed: true,
    },
    {
      title: "Comision",
      dataIndex: "comision",
      key: "comision",
      styleColumn: { ...commonColumnStyle, padding: 3, margin: "auto 0", flexBasis: "25%", textAlign: "right" },
      styleColumnHeader: { ...commonColumnStyle, padding: 3, flexBasis: "25%", textAlign: "right" },
      styleCell: index =>
        borderBottom && index === ventas_farmacias.length - 1 ? { borderBottom: "1.3px solid #000" } : {},
      fixed: true,
    },
  ]

  return <ReportTable columns={columnsVentasComision} dataSource={ventas_farmacias} styleRowHeader={rowHeaderStyle} />
}
