import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Table, Upload } from "antd";
import { useCobrosAddDocument } from "hooks/convenios/useCobrosAddDocument";
import { SaveOutlined, EyeOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { ButtonUpload } from "components/otros/ButtonUpload";

function ListarCobros() {
  const {
    normFile,
    columnsTable,
    cobros,
    openModal,
    handleOkCobros,
    handleCancelCobros,
    beforeUpload,
    fileListPdf,
    handleChangePdf,
    previewPdf,
    previewVisiblePdf,
    setPreviewVisiblePdf,
    form,
    saveNewPay,
  } = useCobrosAddDocument();

  return (
    <Card>
      <Table
        columns={columnsTable}
        dataSource={cobros}
        pagination={false}
        bordered
        size='small'
        scroll={{ x: "400px", y: "500px" }}
      />
      <Modal
        footer={null}
        open={openModal}
        onOk={handleOkCobros}
        onCancel={handleCancelCobros}
        forceRender={true}
        destroyOnClose
        transitionName=''
        width={"600px"}
      >
        <Card title='Detalles del nuevo documento'>
          {/* {!loaderModal ? ( */}
          <Form layout='horizontal' labelCol={{ span: 6 }} wrapperCol={{ span: 34 }} form={form}>
            <Row gutter={[5, 5]}>
              <Form.Item hidden name='cod_cobro'>
                <Input></Input>
              </Form.Item>

              <Col xs={24}>
                <Form.Item label='Proveedor' name='proveedor'>
                  <Input readOnly></Input>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label='Descripción'
                  name='descripcion'
                  rules={[{ required: true, message: "Por favor ingrese una descripción!" }]}
                >
                  <TextArea></TextArea>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label='Valor recibido'
                  style={{ width: "100%" }}
                  name='valor'
                  rules={[{ required: true, message: "Por favor ingrese el valor recibido!" }]}
                >
                  <InputNumber style={{ width: "100%" }} min={1} precision={2}></InputNumber>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label='Fecha recibido'
                  style={{ width: "100%" }}
                  name='fecha'
                  rules={[{ required: true, message: "Por favor ingrese la fecha que recibío el valor!" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Row gutter={[0, 0]}>
                  <Col xs={22}>
                    <Form.Item
                      label='Documento'
                      name='file'
                      valuePropName='file'
                      getValueFromEvent={normFile}
                      rules={[
                        {
                          required: previewPdf === "" ? true : false,
                          message: "Archivo del convenio es obligatorio!",
                        },
                      ]}
                    >
                      <Upload
                        accept='.pdf'
                        maxCount={1}
                        beforeUpload={beforeUpload}
                        name={`file`}
                        fileList={fileListPdf}
                        onChange={handleChangePdf}
                      >
                        <ButtonUpload />
                      </Upload>
                    </Form.Item>
                    <Modal
                      open={previewVisiblePdf}
                      footer={null}
                      onCancel={() => setPreviewVisiblePdf(false)}
                      transitionName=''
                      destroyOnClose
                    >
                      <iframe title='Visor' src={previewPdf} width='100%' height='400'></iframe>
                    </Modal>
                  </Col>
                  <Col xs={2}>
                    <Button icon={<EyeOutlined />} onClick={() => previewPdf && setPreviewVisiblePdf(true)} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Button
              onClick={saveNewPay}
              type='primary'
              style={{
                width: "100%",
              }}
              // loading={loadingModal}
              icon={<SaveOutlined className='iconbutton' />}
            >
              GUARDAR
            </Button>
          </Form>
        </Card>
      </Modal>
    </Card>
  );
}

export default ListarCobros;
