import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import { useContext } from "react"

import { Layout } from "layouts/Layout";

import { Login } from "pages/contentlogin/Login";
import { Principal } from "pages/contentprincipal/Principal";

import { ProtectedRoute } from "components/otros/ProtectedRoute";

// import { UserContext } from "context/UserContext"

import "theme/antd-customized.css";

import { rutasLlamadosAtencion } from "routes/llamados-atencion";
import { rutasActualizarInformacion } from "routes/actualizar-informacion";
import { rutasConvenios } from "routes/convenios";
import { rutasFranquiciados } from "routes/franquiciados";
import { rutasIndicadores } from "routes/indicadores";
import { rutasMenu } from "routes/configuraciones";
import { rutasPoliticasComerciales } from "routes/politicas-comerciales";
import { rutasProductos } from "routes/productos";
import { rutasReembolsoGastos } from "routes/reembolso-gastos";
import { rutasVacaciones } from "routes/vacaciones";
import { rutasProcesos } from "routes/procesos";
import { rutasInventarios } from "routes/inventarios";
import { ErrorPage } from "pages/error-page";
import { rutasUniformes } from "routes/renovacion-uniformes";
import { rutasCompras } from "routes/compras";
import { rutasContabilidad } from "routes/contabilidad";
import { rutasReportes } from "routes/reportes-general";
import { rutasBodega } from "routes/bodega";
import { rutasCajas } from "routes/cajas";
import { rutasAuditoriaProcesos } from "routes/auditoria-procesos";
import { rutasMantenimientos } from "routes/mantenimientos";
import { rutasMarketing } from "routes/marketing";
import { rutasSistemas } from "routes/sistemas";

// ---------------------------------------------------------------------------------------
// Procedure : PROCESOS
// Author    : CRISTOPHER VILLARREAL
// Date      : 09/11/2022
// Purpose   : CONTROL DE LAS PROCESOS DE LOCALES, SUBIDA DE ARCHIVOS Y FLUJO DE PROCESOS
// ---------------------------------------------------------------------------------------

//Politicas Comerciales
// ---------------------------------------------------------------------------------------
// Procedure : Politicas comerciales
// Author    : Andrés Escobar
// Date      : 09/11/2022
// Purpose   : Tablas de Descuentos y rentabilidad , Manejos de Minimos
// ---------------------------------------------------------------------------------------

// const renderPages = (rutas: rutasSistema[]): RouteObject[] =>
//   rutas.map(r => {
//     const Componente = lazy(() => import(`./pages/${r.ruta_componente}`))
//     return {
//       path: r.enlace_ruta,
//       errorElement: <></>,
//       element: (
//         <Suspense fallback={<Loading />}>
//           <Componente />
//         </Suspense>
//       ),
//     }
//   })

function App() {
  const router = createBrowserRouter([
    {
      element: <ProtectedRoute />,
      errorElement: <ErrorPage />,
      children: [
        {
          element: <Layout />,
          children: [
            { path: "/", element: <Principal /> },
            {
              path: "/rrhh",
              element: <Principal />,
            },
            // ...(userData?.rutas ? renderPages(userData?.rutas ?? []) : []),
            ...rutasFranquiciados,
            ...rutasMenu,
            ...rutasReembolsoGastos,
            ...rutasLlamadosAtencion,
            ...rutasConvenios,
            ...rutasIndicadores,
            ...rutasProductos,
            ...rutasPoliticasComerciales,
            ...rutasActualizarInformacion,
            ...rutasVacaciones,
            ...rutasProcesos,
            ...rutasInventarios,
            ...rutasSistemas,
            ...rutasUniformes,
            ...rutasCompras,
            ...rutasContabilidad,
            ...rutasReportes,
            ...rutasBodega,
            ...rutasCajas,
            ...rutasAuditoriaProcesos,
            ...rutasMantenimientos,
            ...rutasMarketing,
                    ],
        },
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "*",
      element: <ErrorPage title='404' subTitle='Página no encontrada' status='404' />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
