import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Col, Row, Typography } from 'antd'
import { useState } from 'react'
import { Facturas, NotaVentas, Recibos } from './useModalFormCrearReembolso'

const actionsList = [ 
  { icon: <EditOutlined />, text: 'Editar' },
  { icon: <DeleteOutlined />, text: 'Eliminar' }
]

const contentDocuments = [
  (value: Facturas | NotaVentas | Recibos) => {
    const { documento, valor, razonsocial, ruc, numeroautorizacion } = value as Facturas
    return (
      <>
        <Row>
          <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
            <Typography.Title level={ 5 }>Documento: { documento }</Typography.Title>
          </Col>
          <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
            <Typography.Title style={{textAlign: 'right'}} level={ 5 }>Valor: { (Math.round(valor * 100) / 100).toFixed(2) } </Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 }>
            <Typography.Text strong>Identificación: </Typography.Text>
            <Typography.Text>{ ruc }</Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 }>
            <Typography.Text strong>Razón Social: </Typography.Text >
            <Typography.Paragraph>{ razonsocial }</Typography.Paragraph >
          </Col>
        </Row>
        <Row>
          <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 }>
            <Typography.Text strong>Autorización: </Typography.Text>
            <Typography.Text>{ numeroautorizacion }</Typography.Text>
          </Col>
        </Row>
      </>
      // <Descriptions title={ `Documento: ${ documento } Valor: ${ valor }`} size='small' column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
      //   <Descriptions.Item label='Identificación'>{ ruc }</Descriptions.Item>
      //   <Descriptions.Item label='Razón Social'>{ razonsocial }</Descriptions.Item>
      //   <Descriptions.Item label='Autorización'>{ numeroautorizacion }</Descriptions.Item>
      // </Descriptions>
    )
  },
  (value: Facturas | NotaVentas | Recibos) => {
    const { documento, valor, razonsocial, ruc, numeroautorizacion } = value as NotaVentas
    return (
      <>
        <Row>
          <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
            <Typography.Title level={ 5 }>Documento: { documento }</Typography.Title>
          </Col>
          <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
            <Typography.Title style={{textAlign: 'right'}} level={ 5 }>Valor: { (Math.round(valor * 100) / 100).toFixed(2) } </Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 }>
            <Typography.Text strong>Identificación: </Typography.Text>
            <Typography.Text>{ ruc }</Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 }>
            <Typography.Text strong>Razón Social: </Typography.Text >
            <Typography.Paragraph>{ razonsocial }</Typography.Paragraph >
          </Col>
        </Row>
        <Row>
          <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 }>
            <Typography.Text strong>Autorización: </Typography.Text>
            <Typography.Text>{ numeroautorizacion }</Typography.Text>
          </Col>
        </Row>
      </>
      // <Descriptions title={ `Documento: ${ documento } Valor: ${ valor }` } >
      //   <Descriptions.Item label='Identificación'>{ ruc }</Descriptions.Item>
      //   <Descriptions.Item label='Razón Social'>{ razonsocial }</Descriptions.Item>
      // </Descriptions>
    )
  },
  (value: Facturas | NotaVentas | Recibos) => {
    const { valor, concepto, documento, ruc, razonsocial } = value as Recibos    
    return (
      <>
        <Row>
          <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
            <Typography.Title level={ 5 }>Documento: { documento }</Typography.Title>
          </Col>
          <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
            <Typography.Title style={{textAlign: 'right'}} level={ 5 }>Valor: { (Math.round(valor * 100) / 100).toFixed(2) } </Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 }>
            <Typography.Text strong>Identificación: </Typography.Text>
            <Typography.Text>{ ruc }</Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 }>
            <Typography.Text strong>Razón Social: </Typography.Text >
            <Typography.Paragraph>{ razonsocial }</Typography.Paragraph >
          </Col>
        </Row>
        <Row>
          <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 }>
            <Typography.Text strong>Concepto: </Typography.Text>
            <Typography.Text>{ concepto }</Typography.Text>
          </Col>
        </Row>
      </>
      // <Descriptions title={  `Documento: ${ documento } Valor: ${ valor }` } >
      //   <Descriptions.Item label='Concepto'>{ concepto }</Descriptions.Item>
      // </Descriptions>
    )
  }
]

export function useButtonRightListCrearReembolso() {
  const [ listFacturas, setListFacturas ] = useState<Array<Facturas>>([])
  const [ listNotaVentas, setListNotaVentas ] = useState<Array<NotaVentas>>([])
  const [ listRecibos, setListRecibos ] = useState<Array<Recibos>>([])

  return {
    actionsList,
    contentDocuments,
    lists: [
      listFacturas,
      listNotaVentas,
      listRecibos
    ],
    setLists: [
      setListFacturas,
      setListNotaVentas,
      setListRecibos
    ]
  }
}