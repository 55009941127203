import type { InputRef } from "antd";
import { Form, Input, Table } from "antd";
import type { FormInstance } from "antd/es/form";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ColumnTypes, DataType } from "../../hooks/politicas/useDescuentoLunes";
import Swal from "sweetalert2";

export interface DataTypeTable {
  key: React.Key;
  descripcion: string;
  laboratorio: string;
  porcentaje: number | null;
}
const EditableContext = React.createContext<FormInstance<any> | null>(null);

export interface Item {
  key: React.Key;
  descripcion: string;
  laboratorio: string;
  porcentaje: number | null;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      for (let clave in values) {
        values[`${clave}`] = parseFloat(values[clave]);
        if (isNaN(values[`${clave}`])) {
          return Swal.fire("Error!", "Solo Valores Numericos", "warning");
        }
      }
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      // console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} es obligatorio.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className='editable-cell-value-wrap' style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const TablaConveniosProductosOut = ({
  dataTable,
  opciones,
  onChangeOpcionesProductos,
  porcentajeOut,
  setSeleccionOut,
  setDataTable,
  seleccionOut,
  name,
  setEditKeyOut,
  editKeyOut,
}: any) => {
  useEffect(() => {
    onChangeOpcionesProductos(opciones, name);
  }, [porcentajeOut, opciones, onChangeOpcionesProductos, name]);

  const rowSelection2 = {
    selectedRowKeys: editKeyOut,
    selectedRows: seleccionOut,
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataTypeTable[]) => {
      setEditKeyOut(selectedRowKeys);
      setSeleccionOut(selectedRows);
    },
  };

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: "Descripcion",
      dataIndex: "descripcion",
      width: "50%",
    },
    {
      title: "Laboratorio",
      dataIndex: "laboratorio",
      width: "40%",
    },

    {
      title: "% de Convenio",
      dataIndex: "porcentaje",
      width: "10%",
      editable: opciones === 4 ? true : false,
    },
  ];

  const handleSave = async (row: DataType) => {
    const newData = [...dataTable[1]];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    const newData2 = [...seleccionOut];

    newData2.find((obj) => {
      if (obj.key === row.key) {
        const index2 = newData2.findIndex((item2) => row.key === item2.key);
        const item2 = newData2[index2];
        newData2.splice(index, 1, {
          ...item2,
          ...row,
        });
        setSeleccionOut(newData2);
      }
      return true;
    });

    await setDataTable({ ...dataTable, [name]: newData });
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <Table
        style={{ height: "400px" }}
        pagination={false}
        components={components}
        size='small'
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataTable[1]}
        columns={columns as any}
        scroll={{ x: 800, y: 300 }}
        rowSelection={{
          type: "checkbox",
          ...rowSelection2,
        }}
        key={1}
      />
    </div>
  );
};
export default TablaConveniosProductosOut;
