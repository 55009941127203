import { Form, Input, Table, InputNumber, InputRef, Row, Col } from "antd"
import type { FormInstance } from "antd/es/form"
import React, { useContext, useEffect, useRef, useState } from "react"
import Swal from "sweetalert2"
import Parrafos from "../../components/politicas/Parrafos"
import { ColumnTypes, DataType, useNuevosDescuentos } from "../../hooks/politicas/useNuevoDescuento"

const formItemLayout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {},
}

const EditableContext = React.createContext<FormInstance<any> | null>(null)

interface Item {
  key: string
  porcentaje: number | string
  margen_m_c: number | string
  margen_l_c: number | string
}

interface EditableRowProps {
  index: number
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

interface EditableCellProps {
  title: React.ReactNode
  editable: boolean
  children: React.ReactNode
  dataIndex: keyof Item
  record: Item
  handleSave: (record: Item) => void
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef<InputRef>(null)
  const form = useContext(EditableContext)!

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({ [dataIndex]: record[dataIndex] })
  }

  const save = async () => {
    try {
      let values = await form.validateFields()
      toggleEdit()

      //recorrer objeto y convierte elemento a numerico
      for (let clave in values) {
        values[`${clave}`] = parseFloat(values[clave])
        if (isNaN(values[`${clave}`])) {
          return Swal.fire("Error!", "Solo Valores Numericos", "warning")
        }
      }

      handleSave({ ...record, ...values })
    } catch (errInfo) {}
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} es obligatorio.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

export const NuevosDescuentos: React.FC = () => {
  const [form] = Form.useForm()
  const [dataSource, setDataSource] = useState<DataType[]>([])

  let { getNuevosDescuentos, defaultColumns, putNuevosDescuentos, putOtrosDescuentos } = useNuevosDescuentos()

  useEffect(() => {
    const getDescuentos = async () => {
      let res = await getNuevosDescuentos()
      res.data[0].map((el: DataType) => {
        el.divi = "/"
        return el
      })
      setDataSource(res.data[0])
      form.setFieldsValue({
        Genericos: res.data[1][1].porcentaje,
        A: res.data[1][0].porcentaje,
        Hospitalarios: res.data[1][2].porcentaje,
        Varios: res.data[1][3].porcentaje,
      })
    }
    getDescuentos()
  }, [getNuevosDescuentos, form])

  const handleSave = async (row: DataType) => {
    let res = await putNuevosDescuentos(row)
    Swal.fire("Ok!", res.data, "success")
    const newData = [...dataSource]
    const index = newData.findIndex(item => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row,
    })
    setDataSource(newData)
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    }
  })
  const handleBlur = async () => {
    await putOtrosDescuentos(form.getFieldsValue())
  }
  return (
    <div style={{ height: "83vh" }}>
      <Parrafos
        title={`• Política para recalcular descuentos por laboratorio ordenados por la venta del último mes descendente.`}
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Table
          pagination={false}
          components={components}
          size="small"
          // rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource}
          columns={columns as ColumnTypes}
          style={{
            padding: "10px",
            width: "390px",
          }}
          rowClassName={(record, index) =>
            index + 1 === 1 ? "yellow" : index + 1 === 2 ? "blue" : index + 1 === 3 ? "green" : "red"
          }
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Form
          {...formItemLayout}
          form={form}
          onBlur={handleBlur}
          onKeyPress={async e => {
            if (e.key === "Enter") {
              await handleBlur()
            }
          }}
        >
          <Row>
            <Col span={12}>
              <Form.Item name="Genericos" label="Genéricos">
                <InputNumber size="small" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Varios" label="Varios">
                <InputNumber size="small" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name="Hospitalarios" label="Hospitalario">
                <InputNumber size="small" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="A" label="A">
                <InputNumber size="small" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}
