import { Col, Row, Table } from "antd"
import { ModalMenu } from "components/configuraciones/modal-menu"
import { ModalSortMenu } from "components/configuraciones/modal-sort-menu"
import { ButtonAdd, ButtonChangeOrder } from "components/otros/buttons"

import { useMenu } from "hooks/configuraciones/useMenu"

export function Menus() {
  const {
    menu,
    columns,
    handleAddButton,
    menuSelected,
    openModal,
    setOpenModal,
    setMenuSelected,
    openModalSort,
    setOpenModalSort,
    getMenu,
    pageSize,
    setPageSize,
  } = useMenu()

  return (
    <>
      <Row style={{ padding: "16px 16px", marginLeft: 0, marginRight: 0 }} justify="end" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={7} lg={7} xl={7}>
          <ButtonChangeOrder buttonProps={{ onClick: () => setOpenModalSort(true) }} />
        </Col>
        <Col xs={24} sm={24} md={5} lg={5} xl={5}>
          <ButtonAdd buttonProps={{ onClick: handleAddButton }}>Añadir menú</ButtonAdd>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            dataSource={menu}
            columns={columns}
            size="small"
            pagination={{
              pageSize: pageSize,
              pageSizeOptions: [6, 10, 20, 50, 100],
              size: "default",
              showSizeChanger: true,
              onShowSizeChange: (_, size) => setPageSize(size),
            }}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
      <ModalMenu
        open={openModal}
        onCancel={() => {
          setOpenModal(false)
          setMenuSelected(undefined)
        }}
        data={menuSelected ? menu?.find(({ key }) => key === menuSelected) : undefined}
        onOk={getMenu}
      />
      <ModalSortMenu open={openModalSort} onCancel={() => setOpenModalSort(false)} menu={menu ?? []} onOk={getMenu} />
    </>
  )
}
