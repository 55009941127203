import { Card, Table } from "antd";
import { useCobrosCruces } from "hooks/convenios/useCobrosCruces";

const CrucesCobros = () => {
  const { headTablePayment, dataCobros } = useCobrosCruces();
  return (
    <div>
      <Card>
        <Table columns={headTablePayment} dataSource={dataCobros}></Table>
      </Card>
    </div>
  );
};

export default CrucesCobros;
