// Importamos los componentes necesarios de antd, moment, react y nuestros hooks y estilos personalizados
import {
  Badge,
  BadgeProps,
  Calendar,
  Card,
  Col,
  Modal,
  Row,
  Form,
  Select,
  Radio,
  InputNumber,
  RadioChangeEvent,
  SelectProps,
  Checkbox,
} from "antd";
import { useEventosCalendario, getListData, DataPersonal } from "hooks/inventarios/useEventosCalendario";
import { Moment } from "moment";
import "moment/locale/es";
import styles from "pages/contentinventario/inventariosCalendario.module.css";
import { useEffect, useState } from "react";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";

import { url } from "config/constants";
import { useSwal } from "hooks/otros/useSwal";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import { useLocalStorage } from "hooks/otros/useLocalStorage";

export function Inventarios() {
  // Extraemos las funciones y variables necesarias desde el hook useEventosCalendario
  const {
    showModal,
    handleCancel,
    monthCellRender,
    isModalOpen,
    setIsModalOpen,
    addEvent,
    events,
    bodegas: items,
    notificars,
    personal,
    motivo,
    laboratorios,
    tipos,

    getDataBodegaCalendario,
    setNotificar,
    form,
    editingEvent,
    resetEvents,
    cambiarLista,
  } = useEventosCalendario();

  const [inventoryType, setInventoryType] = useState<number>();
  const [inventoryTypeLabel, setInventoryTypeLabel] = useState<string>("");
  const [inventoryTypeStatus, setInventoryTypeStatus] = useState<boolean>(false);
  const [isLaboratoriesDisabled, setLaboratoriesDisabled] = useState(false);
  const [isLaboratoriesSelected, setLaboratoriesSelected] = useState(false);
  const [isFarmaciaSelected, setIsFarmaciaSelected] = useState<boolean>(false);
  const [isPersonalSelected, setIsPersonalSelected] = useState<boolean>(false);

  const [isMotivoSelected, setIsmotivoSelected] = useState<boolean>(false);
  const [isOkButtonDisabled, setOkButtonDisabled] = useState(true);
  const { swalError, swalSuccess } = useSwal();
  const { isError } = useVerifyResponse();
  const { optionsPost } = useLocalStorage();
  // Creamos una función que renderiza los eventos en las celdas del calendario
  const dateCellRender = (value: Moment) => {
    const listData = getListData(value, events); // Obtenemos los eventos para una fecha en particular
    return (
      <ul className={styles.events}>
        {listData.map(
          (
            item,
            index // Mapeamos cada evento a un elemento de lista con un Badge
          ) => (
            <li key={index}>
              <Badge status={item.type as BadgeProps["status"]} text={item.content} />
            </li>
          )
        )}
      </ul>
    );
  };
  const disabledDates = (currentDate: Moment) => {
    // Deshabilita fechas anteriores a hoy y fechas posteriores a hoy + 7 días
    return currentDate.isBefore(moment().startOf("day")) || currentDate.isAfter(moment().add(7, "days").endOf("day"));
  };
  const handleOk = async () => {
    const values = await form.validateFields();
    setOkButtonDisabled(true);
    try {
      const result = await fetch(
        `${url}apifsg-pr/inventarios/registrar_inventario`,
        optionsPost({
          ...values,
          nombre_bodega: items.find((item) => item.value === values.bodega)?.label,
          notificars: notificars,
          calendario: values.calendario.format("DD/MM/YYYY HH:mm:ss"),
          fecha: values.calendario,
          personal: (values.personal.map((p) => personal.find((p2) => p2.value === p)) as DataPersonal[]).map(
            ({ value, correo, token }) => ({ value, correo, token })
          ),
        })
      );
      if (isError(result)) throw new Error();
      let text = await result.json();

      addEvent(
        inventoryTypeLabel,
        text.codInventarioCab,
        values.personal.map((per) => ({ Cod_Usuario: per })),
        values.laboratorios.map((lab) => ({ Cod_Laboratorio: lab })),
        values.observacion,
        values.tipo,
        values.motivos
      );
      text = text["msg"];

      swalSuccess({ text });

      setIsmotivoSelected(false);
      form.resetFields(["personal", "laboratorios", "observacion", "cantidad", "tipo", "notificars", "motivos"]);
      setIsPersonalSelected(false);
      setLaboratoriesSelected(false);
      setInventoryTypeStatus(false);
      setNotificar(0);
    } catch (e) {
      swalError({ text: "No se pudo registrar el inventario" });
    } finally {
      setOkButtonDisabled(false);
    }
  };
  const handleEdit = async () => {
    const codInventario = editingEvent?.codInventario;
    setOkButtonDisabled(true);
    const values = await form.validateFields();

    try {
      const result = await fetch(
        `${url}apifsg-pr/inventarios/actualizar_inventario`,
        optionsPost({
          ...values,
          nombre_bodega: items.find((item) => item.value === values.bodega)?.label,
          calendario: values.calendario.format("DD/MM/YYYY HH:mm:ss"),
          fecha: values.calendario,
          personal: (values.personal.map((p) => personal.find((p2) => p2.value === p)) as DataPersonal[]).map(
            ({ value, correo, token }) => ({ value, correo, token })
          ),
          codInventario,
        })
      );
      if (isError(result)) throw new Error();
      let text = await result.json();

      text = text["msg"];

      swalSuccess({ text });
      form.resetFields(["personal", "laboratorios", "observacion", "cantidad", "tipo", "notificars", "motivos"]);
      setIsmotivoSelected(false);
      setIsPersonalSelected(false);
      setLaboratoriesSelected(false);
      setInventoryTypeStatus(false);
      const inicioMes = moment().startOf("month").format("DD/MM/YYYY");
      const finMes = moment().add(1, "month").startOf("month").format("DD/MM/YYYY");
      const codBodega = form.getFieldValue("bodega");
      //console.log(inicioMes, finMes);
      getDataBodegaCalendario(codBodega ?? 0, inicioMes, finMes);
      setIsModalOpen(false);
      // reFetcher();
    } catch (e) {
      swalError({ text: "No se pudo registrar el inventario" });
    } finally {
      setOkButtonDisabled(false);
    }
  };
  useEffect(() => {
    if (editingEvent && inventoryType === editingEvent?.Cod_Tipo) {
      const laboratorios = editingEvent.laboratorios.map((item) => item.Cod_Laboratorio);

      form.setFieldsValue({ laboratorios: laboratorios });
      setLaboratoriesDisabled(false);
      setOkButtonDisabled(false);
    } else {
      if (inventoryType === 1) {
        form.setFieldValue(
          "laboratorios",
          laboratorios.map((l) => l.value)
        );
        setLaboratoriesDisabled(true);
        setLaboratoriesSelected(true);
      } else if (inventoryType === 3) {
        form.setFieldValue("laboratorios", []);
        setLaboratoriesDisabled(true);
      } else if (inventoryType === 2) {
        form.setFieldValue("laboratorios", []);
        setLaboratoriesDisabled(false);
        //}
      }
    }
  }, [editingEvent, inventoryType, isPersonalSelected, form, laboratorios, isMotivoSelected]);

  useEffect(() => {
    if (isLaboratoriesSelected) {
      setOkButtonDisabled(false);
    } else {
      setOkButtonDisabled(true);
    }
  }, [isLaboratoriesSelected]);

  const handlChangeOptions = (value: number) => {
    const selectedType = tipos.find((tipo) => tipo.value === value);
    if (selectedType) {
      setInventoryTypeLabel(selectedType.label);
    }

    setInventoryTypeStatus(value > 0);
    setLaboratoriesSelected(false);
    setInventoryType(value);
  };
  const onChange = (value: number | null) => {
    if (value !== null && value > 0) {
      const selectedLaboratories = getRandomSubarray(laboratorios, value).map((l) => l.value);
      form.setFieldsValue({ laboratorios: selectedLaboratories }); // nota que he cambiado setFieldValue por setFieldsValue
      setLaboratoriesSelected(true);
    }
  };
  const getRandomSubarray = (arr: any[], count: number) => {
    var shuffled = arr.slice(0),
      i = arr.length,
      min = i - count,
      temp,
      index;
    while (i-- > min) {
      index = Math.floor((i + 1) * Math.random());
      temp = shuffled[index];
      shuffled[index] = shuffled[i];
      shuffled[i] = temp;
    }
    return shuffled.slice(min);
  };
  const handleFarmaciaChange = (value: number | null) => {
    const inicioMes = moment().startOf("month").format("DD/MM/YYYY");
    const finMes = moment().add(1, "month").startOf("month").format("DD/MM/YYYY");
    //console.log(inicioMes, finMes);
    getDataBodegaCalendario(value ?? 0, inicioMes, finMes);

    setIsFarmaciaSelected(Boolean(value) || value === 0); // Si value no es null ni undefined, entonces se seleccionó una farmacia.
    setIsPersonalSelected(false);
    setIsmotivoSelected(false);
    form.resetFields(["personal", "laboratorios", "observacion", "cantidad", "tipo", "notificars", "motivos"]);
    resetEvents();
    setInventoryTypeStatus(false);
  };
  const handlePersonalChange = (value: number[] | null) => {
    if (editingEvent) {
      setOkButtonDisabled(false);
    } else {
      if (value) {
        setIsPersonalSelected(value.length > 0);
      } else {
        setIsPersonalSelected(false);
      }
    }
  };
  const handleMotivoChange = (value: number | null) => {
    if (editingEvent) {
      setOkButtonDisabled(false);
    } else {
      if (value) {
        setIsmotivoSelected(Boolean(value) || value === 0);
      } else {
        setIsmotivoSelected(false);
      }
    }
  };
  const handleLaboratorioChange: SelectProps["onChange"] = (value) => {
    if (value) {
      setLaboratoriesSelected(value.length > 0);
    } else {
      setLaboratoriesSelected(false);
    }
  };
  return (
    <Form form={form}>
      <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card className={styles.card_prueba}>
            <Row>
              <Col xs={24} xl={12}>
                <Form.Item
                  label="Farmacias"
                  name="bodega"
                  rules={[{ required: true, message: "La Farmacias es obligatoria" }]}
                >
                  <Select
                    onChange={handleFarmaciaChange}
                    options={items}
                    allowClear
                    showSearch
                    optionFilterProp="label"
                    placeholder="Seleccione una opción para comenzar"
                  />
                </Form.Item>
                {!isFarmaciaSelected && (
                  <div style={{ color: "red", paddingLeft: "10px" }}>
                    Por favor, seleccione una farmacia antes de elegir una fecha.
                  </div>
                )}
              </Col>
            </Row>
            <Form.Item name="calendario">
              <Calendar
                disabledDate={isFarmaciaSelected ? disabledDates : () => true}
                validRange={[
                  moment().subtract(3, "months").startOf("month"), // Inicio: 3 meses antes del inicio del mes actual
                  moment().add(7, "days").endOf("day"), // Final: 7 días después de la fecha actual
                ]}
                onSelect={showModal} // Cuando se selecciona una fecha, se abre el Modal para agregar un evento
                dateCellRender={dateCellRender} // Utilizamos nuestra función personalizada para renderizar las fechas
                monthCellRender={monthCellRender}
                //headerRender={() => null}// Utilizamos la función del hook para renderizar el mes
              />
            </Form.Item>
            <Modal
              title={editingEvent ? "Editar inventario" : "Nuevo inventario"}
              open={isModalOpen} // El Modal será visible cuando isModalOpen sea verdadero
              onCancel={handleCancel} // Cuando se cancele el Modal, se llamará a handleCancel
              onOk={editingEvent ? handleEdit : handleOk} // Cuando se presione OK, se llamará a handleOk
              width={500}
              okText={editingEvent ? "Actualizar" : "Guardar"}
              okButtonProps={{ disabled: isOkButtonDisabled }}
            >
              <Row className={styles.separacion}>
                <Col xs={24} xl={30}>
                  {!editingEvent && (
                    <Form.Item name="notificars" valuePropName="checked">
                      <Checkbox onChange={cambiarLista}>Activación inmediata</Checkbox>
                    </Form.Item>
                  )}
                  <Form.Item
                    label="Motivo del inventario"
                    name="motivos"
                    rules={[{ required: true, message: "Seleccione un motivo" }]}
                  >
                    <Select
                      onChange={handleMotivoChange}
                      options={motivo}
                      allowClear
                      showSearch
                      optionFilterProp="label"
                      placeholder="Seleccione una opción"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Equipo de inventario"
                    name="personal"
                    rules={[{ required: true, message: "Seleccione una persona" }]}
                  >
                    <Select
                      onChange={handlePersonalChange}
                      options={personal}
                      mode="multiple"
                      allowClear
                      showSearch
                      optionFilterProp="label"
                      placeholder="Seleccione una opción"
                    />
                  </Form.Item>
                  {(isPersonalSelected || editingEvent) && (isMotivoSelected || editingEvent) && (
                    <Form.Item name="tipo">
                      <Radio.Group
                        onChange={(e: RadioChangeEvent) => handlChangeOptions(e.target.value)}
                        buttonStyle="solid"
                        style={{ marginBottom: "20px" }}
                      >
                        {tipos.map((tipo) => (
                          <Radio.Button key={tipo.value} value={tipo.value}>
                            {tipo.label}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  )}
                  {(inventoryTypeStatus || editingEvent) && (
                    <>
                      {inventoryType === 3 && (
                        <Form.Item
                          label="Ingrese un valor"
                          name="cantidad"
                          rules={[{ required: true, message: "Ingrese un valor" }]}
                          initialValue={1}
                        >
                          <InputNumber min={1} max={laboratorios.length} onChange={onChange} />
                        </Form.Item>
                      )}
                      <Form.Item
                        label="Laboratorios"
                        name="laboratorios"
                        rules={[{ required: true, message: "Seleccione un laboratorio" }]}
                      >
                        <Select
                          onChange={handleLaboratorioChange}
                          maxTagCount={inventoryType === 1 ? 10 : undefined}
                          options={laboratorios}
                          mode="multiple"
                          allowClear
                          showSearch
                          optionFilterProp="label"
                          placeholder="Seleccione una opción"
                          disabled={isLaboratoriesDisabled}
                        />
                      </Form.Item>
                      <Form.Item label="Observación" name="observacion">
                        <TextArea rows={4} />
                      </Form.Item>
                    </>
                  )}
                </Col>
              </Row>
            </Modal>
          </Card>
        </Col>
      </Row>
    </Form>
  );
}

export default Inventarios;
