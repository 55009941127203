import ReporteVentas from "pages/contentReportesGeneral/ReporteVentas";
import { Principal } from "pages/contentprincipal/Principal";
import { RouteObject } from "react-router-dom";

export const rutasReportes: RouteObject[] = [
  {
    path: "/reportes",
    element: <Principal />,
  },
  {
    path: "/reportes/ventas",
    element: <ReporteVentas />,
  },
];
