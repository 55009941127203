import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { Form, Space, Input, Button, DatePicker, Card, Row, Col, Typography, Divider, Select } from "antd";

const sexos = [
  {
    value: 1,
    label: "Masculino",
  },
  {
    value: 2,
    label: "Femenino",
  },
];
interface PropsHijos {
  fileSelected: boolean;
}
export const AgregarHijos = ({ fileSelected }: PropsHijos) => {
  function disabledDate(current: any) {
    if (current.year() < 2006) {
      return true;
    }
    return current > moment();
  }
  return (
    <Card title='Información hijos menores de 18 años' type='inner' size='small'>
      <Form.List
        name='hijos'
        initialValue={[{ nombres_hijos: "", fecha_nacimiento_hijo: "", cedula_hijos: "", sexo_hijos: "" }]}
        key={1}
      >
        {(fields = [], { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }) => {
                return (
                  <Row wrap={true} key={key}>
                    <Divider>{name + 1}</Divider>
                    <Col xs={24} md={24} lg={24}>
                      <Space
                        // key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align='baseline'
                      >
                        <Row wrap={true}>
                          <Col xs={12} xl={6}>
                            <Form.Item
                              {...restField}
                              rules={[
                                {
                                  required: true,
                                  message: "nombres_hijos es obligatorio",
                                },
                              ]}
                              name={[name, "nombres_hijos"]}
                              label={
                                <Typography.Text
                                  ellipsis={{
                                    tooltip: `Nombres completos de su hijo #${name + 1}`,
                                  }}
                                >
                                  {`Nombres completos de su hijo #${name + 1}`}
                                </Typography.Text>
                              }
                            >
                              <Input placeholder='Escriba los nombres completos' style={{ width: "97%" }} />
                            </Form.Item>
                          </Col>
                          <Col xs={12} xl={6}>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "fecha es obligatorio",
                                },
                              ]}
                              required={fileSelected}
                              label={
                                <Typography.Text
                                  ellipsis={{
                                    tooltip: `Fecha de nacimiento de su hijo #${name + 1}`,
                                  }}
                                >
                                  {`Fecha de nacimiento de su hijo #${name + 1}`}
                                </Typography.Text>
                              }
                              {...restField}
                              name={[name, "fecha_nacimiento_hijo"]}
                            >
                              <DatePicker style={{ width: "99%" }} disabledDate={disabledDate} />
                            </Form.Item>
                          </Col>
                          <Col xs={12} xl={6}>
                            <Form.Item
                              required={true}
                              {...restField}
                              name={[name, "cedula_hijos"]}
                              rules={[
                                {
                                  required: true,
                                  message: "cedula_hijos es obligatorio",
                                },
                                {
                                  pattern: /^[0-9]{10}$/,
                                  message: "Ingrese un número de cédula válido",
                                },
                              ]}
                              label={
                                <Typography.Text
                                  ellipsis={{
                                    tooltip: `Cédula de su hijo #${name + 1}`,
                                  }}
                                >
                                  {`Cédula de su hijo #${name + 1}`}
                                </Typography.Text>
                              }
                            >
                              <Input maxLength={10} placeholder='Escriba el # de cédula' style={{ width: "97%" }} />
                            </Form.Item>
                          </Col>
                          <Col xs={12} xl={6}>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "sexo_hijos es obligatorio",
                                },
                              ]}
                              required={true}
                              {...restField}
                              name={[name, "sexo_hijos"]}
                              label={
                                <Typography.Text
                                  ellipsis={{
                                    tooltip: `Sexo de su hijo #${name + 1}`,
                                  }}
                                >
                                  {`Sexo de su hijo #${name + 1}`}
                                </Typography.Text>
                              }
                            >
                              <Select
                                placeholder='SELECCIONE UN SEXO'
                                options={sexos}
                                showSearch
                                style={{ width: "98%" }}
                              ></Select>
                              {/* <Input placeholder='Seleccione el sexo' style={{ width: "97%" }} /> */}
                            </Form.Item>
                          </Col>
                        </Row>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    </Col>
                  </Row>
                );
              })}
              <Form.Item>
                <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                  Añadir Hijos
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </Card>
  );
};
