import { Modal, Calendar, List } from "antd"
import type { ListProps, CalendarProps, ModalProps } from 'antd'

import type { Moment } from 'moment';

export interface elementType {
  elementType: 'calendar' | 'list'
  elementProps: CalendarProps<Moment> | ListProps<any>
}

interface ModalCustomProps {
  modalProps: ModalProps
  elements: elementType
}

const renderElement = {
  'calendar': (elementProps: CalendarProps<Moment> | ListProps<any>) => <Calendar {...elementProps as CalendarProps<Moment>} />,
  'list': (elementProps: CalendarProps<Moment> | ListProps<any>) => <List { ...elementProps as ListProps<any> } />
}

export function ModalCustom({ modalProps, elements }: ModalCustomProps) {
  
  return (
    <Modal { ...modalProps } transitionName=''>
      {
        renderElement[elements.elementType as keyof typeof renderElement](elements.elementProps)
      }
    </Modal>
  )
}