import { Tag, type TableColumnsType, Button, Table } from "antd"
import { GastosFijosPorBodega } from "hooks/Franquiciados/useGastosFranquiciados"

import { EditOutlined } from "@ant-design/icons"

type Props = {
  dataSource: GastosFijosPorBodega[]
  onClickButtonEdit(record: GastosFijosPorBodega): void
}

export function SubTableGastos({ dataSource, onClickButtonEdit }: Props) {
  const columns: TableColumnsType<GastosFijosPorBodega> = [
    {
      title: "Bodega",
      dataIndex: "bodega",
      key: "bodega",
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      align: "center",
      render: (value: boolean) => (value ? <Tag color="success">Activo</Tag> : <Tag color="default">Inactivo</Tag>),
    },
    {
      title: "Editar",
      dataIndex: "cod_bodega",
      key: "editar",
      align: "center",
      render: (_, record) => (
        <Button
          icon={<EditOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          block
          onClick={() => onClickButtonEdit(record)}
        />
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      size="small"
      pagination={false}
      scroll={{ x: true }}
      rowKey="cod_bodega"
    />
  )
}
